import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatAutocompleteSelectedEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { comparableString, LoggerService, EntityFormComponent } from 'app/shared';
import { WorkbookOfficeService, EntityOffice } from 'app/workbook-core';

@Component({
  selector: 'app-workbook-office-info',
  templateUrl: './officeInfo.component.html',
  styleUrls: ['./officeInfo.component.scss']
})
export class WorkbookOfficeInfoComponent extends EntityFormComponent implements OnChanges {
  autoSave = false;
  @Input() officeList: EntityOffice[];
  public entity: EntityOffice;
  public selectableOffices: EntityOffice[];

  mainOfficeList: EntityOffice[] = [];
  protected _mainOfficeSubscription: Subscription;


  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: WorkbookOfficeService
  ) {
    super(dialog, _logger, _entityService);
  }

  _init(data) {
    super._init(data);
    if (this.entity && this.entity.parent_id && !this._mainOfficeSubscription) {
      this._mainOfficeSubscription = this._entityService.getChildren(this.entity.parent_id).subscribe((list) => {
        if (list) {
          this.mainOfficeList = list.filter((e) => (e._id !== this.entity._id)).sort((a, b) => a.name.localeCompare(b.name));
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.refreshSelectableOffices();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._mainOfficeSubscription) {
      this._mainOfficeSubscription.unsubscribe();
    }
  }

  getOfficeLabel(office: EntityOffice) {
    let str = '';
    if (office) {
      str = office.name;
      if (office.zipCode || office.city) {
        str += ' (' + (office.zipCode ? office.zipCode + ' - ' : '') + office.city + ')';
      }
      return str;
    }
  }

  //soit le contact selectionné, soit le name affiché...
  displayName(c): string {
    return c ? (c.name ? c.name : c) : '';
  }
  updateEntity(event: MatAutocompleteSelectedEvent = null): void {

    if (event && event.option && event.option.value) {
      const o = this.officeList.find((e) => (e.name === event.option.value));
      if (o) {
        this.entity.copyFrom(o, true);
        this.entity.repo_id = o._id;
        this.entity.main = '';
        this.entity.parent_id = this.parent_id;
        this.entity.contacts = this.entity.contacts.filter((e) => (e.isDefault));
        this.entity.documents = this.entity.documents.filter((e) => (e.isDefault));
        this.entity.operators = this.entity.operators.filter((e) => (e.isDefault));
      }
    }
  }

  refreshSelectableOffices() {
    //eviter le premier appel de l'autocomplete qui passe office dans name...
    if (this.entity) {
      this.selectableOffices = this.officeList.filter((c: EntityOffice) => {
        if (this.entity && this.entity.name && c && c.name) {
          return (
            // non filtré par name
            (!this.entity.name || comparableString(c.name).indexOf(comparableString(this.entity.name)) !== -1)
            // non filtré par job
            && (!this.entity.city || comparableString(c.city).indexOf(comparableString(this.entity.city)) !== -1)
            // non filtré par phone
            && (!this.entity.zipCode || c.zipCode.indexOf(this.entity.zipCode) !== -1)
          );
        } else {
          return true;
        }
      }
      );
    }
  }
}
