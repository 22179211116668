import {Component, OnDestroy, Input, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {UUID} from 'angular2-uuid';
import {Subscription} from 'rxjs';
import {comparableString, promiseAll, LoggerService, ConfirmDialogComponent} from 'app/shared';
import {
  ActivityService, PreventionPlanService, SituationService,
  RiskService, EntityRisk,
  MeasureService, EntityMeasure, EntityWorkbookMeasure, EntitySituation, EntityActivity
} from 'app/workbook-core';
import {AbstractWorkbookChildFormComponent} from '../abstractWorkbookChildForm.component';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbook-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss']
})

export class WorkbookRisksComponent extends AbstractWorkbookChildFormComponent implements OnDestroy {
  entity: EntityActivity;
  @Input() global: boolean;

  selectedMeasureNames: {[measure_id: string]: string} = {};
  selectedDeletedMeasures: string[] = [];

  autoSave = false;
  protected _measuresSubscription: Subscription;
  measureList: {[risk_id: string]: EntityMeasure[]} = {};
  protected _risksSubscription: Subscription;
  risks: EntityRisk[] = [];
  riskList: {[risk_id: string]: EntityRisk} = {};
  riskFilter: string = '';
  riskFiltered: {[risk_id: string]: boolean} = {};
  situationFiltered: {[situation_id: string]: boolean} = {};

  protected _situationsSubscription: Subscription;
  situations: EntitySituation[] = [];
  situationList: {[risk_id: string]: EntitySituation[]} = {};
  newSituation: {[risk_id: string]: string} = {};
  newSituationFp: {[risk_id: string]: boolean} = {};
  newSituationSir: {[risk_id: string]: boolean} = {};
  situationsSelected: {[risk_id: string]: EntitySituation[]} = {};
  selectedSituationNames: {[risk_id: string]: string} = {};

  protected _localMeasuresSubscription: Subscription;
  localMeasures: {[risk_id: string]: EntityWorkbookMeasure[]} = {};
  newMeasure: {[risk_id: string]: string} = {};
  newMeasureSelected: {[risk_id: string]: boolean} = {};
  firstChange: boolean = true;

  @Input() selectedRisk: string = '';
  selectedRiskName: string = '';
  @Input() selectedSituation: string = '';
  selectedSituationName: string = '';

  // risksSelected: string[] = [];

  measuresSelected: {[risk_or_situation_id: string]: string[]} = {};

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _situationService: SituationService,
    protected _workbookService: PreventionPlanService,
    protected _riskService: RiskService,
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _entityService: ActivityService

  ) {
    super(dialog, _logger, _entityService);
    //const start = new Date().getMilliseconds();
    this._risksSubscription = this._riskService.getAll().subscribe((list) => {
      if (list) {
        //const startr = new Date().getMilliseconds();
        const newMeasures = {};
        const newSituation = {};
        const newSituationFp = {};
        const newSituationSir = {};
        this.risks = list.sort((a, b) => (this.numalphaCompare(a.name, b.name)));
        this.risks.forEach((r) => {
          this.riskList[r._id] = r;
          newMeasures[r._id] = (this.newMeasure && this.newMeasure[r._id]) ? this.newMeasure[r._id] : '';
          newSituation[r._id] = (this.newSituation && this.newSituation[r._id]) ? this.newSituation[r._id] : '';
          newSituationFp[r._id] = (this.newSituationFp && this.newSituationFp[r._id]) ? this.newSituationFp[r._id] : false;
          newSituationSir[r._id] = (this.newSituationSir && this.newSituationSir[r._id]) ? this.newSituationSir[r._id] : false;
        });
        this.newMeasure = newMeasures;
        this.newSituation = newSituation;
        this.newSituationFp = newSituationFp;
        this.newSituationSir = newSituationSir;
        this._cd.markForCheck();
        //console.log('(_init) _riskService.getAll(): ' + ((new Date().getMilliseconds() - startr) / 1000) + 's', list);
      }
    });
    this._situationsSubscription = this._situationService.getAll().subscribe((list) => {
      if (list) {
        //const starts = new Date().getMilliseconds();
        this.situationList = {};
        this.situations = list.sort((a, b) => a.name.localeCompare(b.name));
        this.situations.forEach((s) => {
          if (!this.situationList[s.risk_id]) {
            this.situationList[s.risk_id] = [];
          }
          if (!this.newMeasure[s._id]) {
            this.newMeasure[s._id] = '';
          }
          //          if (this.situationList[s.risk_id].findIndex((_s)=>(_s._id===s._id))===-1){
          this.situationList[s.risk_id].push(s);
          //          }
        });
        if (this.entity) {
          this.situationsSelected = {};
          this.entity.situations.forEach((situation: EntitySituation) => {
            if (!this.situationList[situation.risk_id]) {
              this.situationList[situation.risk_id] = [];
            }
            if (this.situationList[situation.risk_id].findIndex((_s) => (_s._id === situation._id)) === -1) {
              this.situationList[situation.risk_id].push(situation);
            }
            if (!this.situationsSelected[situation.risk_id]) {
              this.situationsSelected[situation.risk_id] = [];
            }
            if (this.situationsSelected[situation.risk_id].findIndex((_s) => (_s._id === situation._id)) === -1) {
              this.situationsSelected[situation.risk_id].push(situation);
            }
            if (!this.measureList[situation._id] || !this.measureList[situation._id].length) {
              this.measureList[situation._id] = [...this.measureList[situation.risk_id]].sort((a, b) => a.name.localeCompare(b.name));
            }
            if (!this.newMeasure[situation._id]) {
              this.newMeasure[situation._id] = '';
            }
          });
        }
        this._cd.markForCheck();
        //console.log('(_init) _situationService.getAll(): ' + ((new Date().getMilliseconds() - starts) / 1000) + 's', list);
      }
    });
    this._measuresSubscription = this._measureService.getAll().subscribe((list) => {
      if (list) {
        //const startm = new Date().getMilliseconds();
        this.measureList = {};
        // TODO: order ?
        let toComplete: {[situation_id: string]: string} = {};
        Object.keys(this.situationList).forEach((r_id) => {
          this.situationList[r_id].forEach((s) => {
            toComplete[s._id] = r_id;
          });

        });
        list.forEach((measure: EntityMeasure) => {
          if (measure.type !== '' && measure.type !== 'global' && !toComplete[measure.type]) {
            toComplete[measure.type] = measure.risk_id;
          }
          if (!this.measureList[(measure.type === '' || measure.type === 'global') ? measure.risk_id : measure.type]) {
            this.measureList[(measure.type === '' || measure.type === 'global') ? measure.risk_id : measure.type] = [];
          }
          this.measureList[(measure.type === '' || measure.type === 'global') ? measure.risk_id : measure.type].push(measure);
          this.measureList[(measure.type === '' || measure.type === 'global') ? measure.risk_id : measure.type].sort((a, b) => a.name.localeCompare(b.name));
        });
        Object.keys(toComplete).forEach((situation_id) => {
          if (this.measureList[toComplete[situation_id]]) {
            this.measureList[situation_id] = [
              ...(this.measureList[situation_id] ? this.measureList[situation_id] : []),
              ...(this.measureList[toComplete[situation_id]] ? this.measureList[toComplete[situation_id]] : [])
            ].sort((a, b) => a.name.localeCompare(b.name));
          }
        });
        this._updateSituationSelected();
        //console.log('(_init) _measureService.getAll(): ' + ((new Date().getMilliseconds() - startm) / 1000) + 's', list);
      }
    });

    //console.log('WorkbookRisksComponent.constructor: ' + ((new Date().getMilliseconds() - start) / 1000) + 's');
  }
  _updateSituationSelected() {
    if (this.entity && this.entity.situations) {
      this.situationsSelected = {};
      this.entity.situations.forEach((situation: EntitySituation) => {
        if (!this.situationList[situation.risk_id]) {
          this.situationList[situation.risk_id] = [];
        }
        if (this.situationList[situation.risk_id].findIndex((_s) => (_s._id === situation._id)) === -1) {
          this.situationList[situation.risk_id].push(situation);
        }
        if (!this.situationsSelected[situation.risk_id]) {
          this.situationsSelected[situation.risk_id] = [];
        }
        if (this.situationsSelected[situation.risk_id].findIndex((_s) => (_s._id === situation._id)) === -1) {
          this.situationsSelected[situation.risk_id].push(situation);
        }
        if (!this.measureList[situation._id] || !this.measureList[situation._id].length) {
          this.measureList[situation._id] = this.measureList[situation.risk_id] ? [...this.measureList[situation.risk_id]].sort((a, b) => a.name.localeCompare(b.name)) : [];
        }
        if (!this.newMeasure[situation._id]) {
          this.newMeasure[situation._id] = '';
        }
        this.selectedSituationNames[situation._id] = situation.name;
      });
      this.updateRiskFilter();
    }
  }
  updateRiskFilter() {
    if (this.riskFilter) {
      Object.keys(this.riskList).forEach((risk_id) => {
        this.riskFiltered[risk_id] = (this.riskList[risk_id].name && comparableString(this.riskList[risk_id].name).indexOf(comparableString(this.riskFilter)) === -1);
        if (this.situationList[risk_id] && this.situationList[risk_id].length) {
          this.situationList[risk_id].forEach((situation) => {
            if (situation.name && comparableString(situation.name).indexOf(comparableString(this.riskFilter)) === -1) {
              this.situationFiltered[situation._id] = true;
            } else {
              this.situationFiltered[situation._id] = false;
              this.riskFiltered[situation.risk_id] = false;
            }
          });
        }
      });
    } else {
      this.situationFiltered = {};
      this.riskFiltered = {};
    }
    this._cd.markForCheck();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._risksSubscription) {
      this._risksSubscription.unsubscribe();
    }
    if (this._situationsSubscription) {
      this._situationsSubscription.unsubscribe();
    }
    if (this._measuresSubscription) {
      this._measuresSubscription.unsubscribe();
    }
  }

  protected _init(data) {
    //const start = new Date().getMilliseconds();
    //const updateEntity = (data && (!this.entity || !this.entity._rev || this.entity._rev !== data._rev));
    super._init(data);

    if (this.entity) {
      if (this.entity.parent_id && !this._localMeasuresSubscription) {
        this._localMeasuresSubscription = this._workbookService.getMeasureList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            //const startm = new Date().getMilliseconds();
            this.localMeasures = {};
            // TODO: order ?
            list.forEach((measure: EntityWorkbookMeasure) => {
              if (measure.type && measure.type !== 'global') {
                if (!this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type]) {
                  this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type] = [];
                }
                if (this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].findIndex((e) => e._id === measure._id) === -1) {
                  this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].push(measure);
                }
              }
            });
            if (this.entity.measures) {
              this.selectedMeasureNames = {};
              // local selected deleted
              this.entity.measures.forEach((measure: EntityWorkbookMeasure) => {
                this.selectedMeasureNames[measure.repo_id + (measure.type === 'local' ? '' : measure.type)] = measure.name;
                if (measure.type && measure.type === 'local') {
                  if (!this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type]) {
                    this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type] = [];
                  }
                  if (this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].findIndex((e) => e._id === measure.repo_id) === -1) {
                    const local_measure = this._measureService.factory(measure);
                    local_measure._id = local_measure.repo_id;
                    local_measure.repo_id = '';
                    this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].push(local_measure);
                    this.selectedDeletedMeasures.push(local_measure._id);
                  }
                }
              });
            }
            //console.log('(_init) _workbookService.getMeasureList: ' + ((new Date().getMilliseconds() - startm) / 1000) + 's', list);
          }
        });
      }
      this.measuresSelected = {};
      //this.risksSelected = [];
      this.entity.measures.forEach((measure: EntityWorkbookMeasure) => {
        this.selectMeasure(measure);
        this.selectedMeasureNames[measure.repo_id + (measure.type === 'local' ? '' : measure.type)] = measure.name;

        if (measure.type && measure.type === 'local') {
          if (!this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type]) {
            this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type] = [];
          }
          if (this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].findIndex((e) => e._id === measure.repo_id) === -1) {
            const local_measure = this._measureService.factory(measure);
            local_measure._id = local_measure.repo_id;
            local_measure.documentType = 'repo_measure';
            local_measure.repo_id = '';
            this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].push(local_measure);
            this.selectedDeletedMeasures.push(local_measure._id);
          }
        }

      });

      this._updateSituationSelected();
    }
    if (this.selectedRisk && this.riskList && this.riskList[this.selectedRisk]) {
      this.selectedRiskName = this.riskList[this.selectedRisk].name;
      if (this.selectedSituation && this.situationList[this.selectedRisk]) {
        const s = this.situationList[this.selectedRisk].find((s) => (s._id === this.selectedSituation));
        if (s) {
          this.selectedSituationName = s.name;
        }
      } else {
        this.selectedSituation = '';
        this.selectedSituationName = '';
      }
    }
    this._cd.markForCheck();
    //console.log('(init) WorkbookRisksComponent.init ' + ((new Date().getMilliseconds() - start) / 1000) + 's', data);
  }


  updateLocalMeasure(measure) {
    if (measure._id && measure.type === 'local') {
      this._workbookService.measureService.save(measure).then((e) => {
        this.afterUpdateLocalMeasure(measure);
      });
    }
  }

  afterUpdateLocalMeasure(measure) {
    if (this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type]) {
      const x = this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type].findIndex((e) => e._id === measure._id);
      if (x !== -1) {
        this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type][x] = measure;
      }
    }
  }
  isSituationSelected(situation: EntitySituation) {
    return this.situationsSelected[situation.risk_id] && this.situationsSelected[situation.risk_id].findIndex((e) => (e._id === situation._id)) !== -1;
  }
  onSelectSituations(event) {
    if (this.entity
      && event && event.option && event.option.value && event.option.value._id
      && event.option.value.risk_id && this.situationList && this.situationList[event.option.value.risk_id]) {
      if (event.option.selected) {
        this.entity.situations.push(this._situationService.getNewEntity(event.option.value));
        if (event.option.value.measures && event.option.value.measures.length && this.measureList[event.option.value._id]) {
          this.measureList[event.option.value._id]
            .filter((m) => (event.option.value.measures.indexOf(m._id) !== -1))
            .forEach((m) => {
              let addedMeasure = this._measureService.getNewEntity(m);
              addedMeasure.repo_id = m._id;
              addedMeasure._id = UUID.UUID();
              addedMeasure.type = event.option.value._id;
              this.entity.measures.push(addedMeasure);
              this.selectMeasure(addedMeasure);
            });
          //this.measuresSelected[event.option.value._id] = event.option.value.measures.map((id) => (id));
        }
        if (this.entity.risks.findIndex((r) => (r._id === event.option.value.risk_id)) === -1) {
          this.entity.risks.push(this.riskList[event.option.value.risk_id])
        }
        //this.entity.update_risks_situations();
        this.selectSituation(event.option.value);
      } else {
        const i = this.entity.situations.findIndex((e) => (e._id === event.option.value._id));
        if (this.measuresSelected[event.option.value._id]) {
          delete this.measuresSelected[event.option.value._id];
        }
        if (i !== -1) {
          this.entity.situations.splice(i, 1);
          this.entity.update_risks_situations();
          //const j = this.entity.risks.findIndex((r) => (r._id === event.option.value.risk_id));
          //if (j !== -1 && this.entity.situations.findIndex((e) => (e.risk_id === event.option.value.risk_id)) === -1) {
          //  this.entity.risks.splice(j, 1);
          //}
        }
        this.unselectSituation(event.option.value);
      }
    }
  }
  /**
   * select measure
   */
  selectSituation(s: EntitySituation) {
    if (s && s.risk_id) {
      if (!this.situationsSelected[s.risk_id]) {
        this.situationsSelected[s.risk_id] = [];
      }
      this.situationsSelected[s.risk_id].push(s);
      if (!this.situationList[s.risk_id]) {
        this.situationList[s.risk_id] = [];
      }
      this.selectedSituationNames[s._id] = this.selectedSituationNames[s._id] ? this.selectedSituationNames[s._id] : s.name;

      this.newSituation[s.risk_id] = '';
      this.newSituationFp[s.risk_id] = false;
      this.newSituationSir[s.risk_id] = false;
      this.selectedSituation = s._id;
      this.selectedSituationName = s.name;
      // ajouter les mesures sélectionnées
      //this.selectRisk(m.risk_id);
    }
  }
  /**
   * select measure
   */
  unselectSituation(m: EntitySituation) {
    if (m && m.risk_id) {
      if (this.situationsSelected[m.risk_id]) {
        const selectedIndex = this.situationsSelected[m.risk_id].findIndex((e) => (e._id === m._id));
        if (selectedIndex !== -1) {
          this.situationsSelected[m.risk_id].splice(selectedIndex, 1);
          if (this.situationsSelected[m.risk_id].length === 0) {
            delete this.situationsSelected[m.risk_id];
            //this.unselectRisk(m.risk_id)
          }
        }
      }
    }
  }
  onSelectMeasures(event) {
    if (this.entity
      && event && event.option && event.option.value && event.option.value._id
      && event.option.value.risk_id && this.riskList && this.riskList[event.option.value.risk_id]) {
      if (event.option.selected) {
        const i = this.entity.measures.findIndex((m) => {
          return (m.repo_id === event.option.value._id
            && ((!this.selectedSituation && (m.type === '' || m.type === 'local' || m.type === 'global')) || (this.selectedSituation && m.type === this.selectedSituation)));
        });
        if (i === -1) {
          let addedMeasure = this._workbookService.measureService.factory(event.option.value, true);
          addedMeasure._id = UUID.UUID();
          addedMeasure.documentType = 'wb_measure';
          addedMeasure.repo_id = event.option.value._id;
          if (this.selectedSituation) {
            addedMeasure.type = this.selectedSituation;
          }
          this.entity.measures.push(addedMeasure);
          this.selectMeasure(addedMeasure);
        }
        if (this.entity.risks.findIndex((r) => (r._id === event.option.value.risk_id)) === -1) {
          this.entity.risks.push(this.riskList[event.option.value.risk_id])
        }
        this.entity.update_risks_situations();
      } else {
        const i = this.entity.measures.findIndex((m) => {
          return (m.repo_id === event.option.value._id
            && ((!this.selectedSituation && (m.type === '' || m.type === 'local' || m.type === 'global')) || (this.selectedSituation && m.type === this.selectedSituation)))
        });
        if (i !== -1) {
          this.unselectMeasure(this.entity.measures[i]);
          this.entity.measures.splice(i, 1);
          //const j = this.entity.risks.findIndex((r) => (r._id === event.option.value.risk_id));
          //if (j !== -1 && this.entity.measures.findIndex((e) => (e.risk_id === event.option.value.risk_id)) === -1) {
          //  this.entity.risks.splice(j, 1);
          //}
          this.entity.update_risks_situations();
        }
        // this.unselectMeasure(event.option.value);
      }
    }
  }
  /**
   * select measure
   */
  selectMeasure(m: EntityWorkbookMeasure) {
    if (m && m.risk_id) {
      const itemId = m.repo_id + (this.selectedSituation ? this.selectedSituation : (m.type === '' || m.type === 'global' || m.type === 'local') ? '' : m.type);
      const listId = (this.selectedSituation && m.type === this.selectedSituation) ? this.selectedSituation : (m.type === '' || m.type === 'global' || m.type === 'local') ? m.risk_id : m.type;
      if (!this.measuresSelected[listId]) {
        this.measuresSelected[listId] = [];
      }
      this.measuresSelected[listId].push(m.repo_id);
      if (!this.measureList[listId]) {
        this.measureList[listId] = [];
      }
      // ajouter les mesures sélectionnées mais supprimées
      if ((m.type === '' || m.type === 'global') && this.measureList[listId].findIndex((e) => e._id === m.repo_id) === -1
        && (!this.localMeasures[listId] || this.localMeasures[listId].findIndex((e) => e._id === m._id) === -1)
        && m.risk_id === listId) {
        console.log("mesure supprimée", m);
        this.measureList[listId].push(m);
      }
      this.selectedMeasureNames[itemId] = this.selectedMeasureNames[itemId] ? this.selectedMeasureNames[itemId] : m.name;
      //this.selectRisk(m.risk_id);
    }
  }
  /**
   * select measure
   */
  unselectMeasure(m: EntityMeasure) {
    if (m && m.risk_id) {
      let listId = (this.selectedSituation && m.type === this.selectedSituation) ? this.selectedSituation : (m.type === '' || m.type === 'global' || m.type === 'local') ? m.risk_id : m.type;
      if (this.measuresSelected[listId]) {
        const measuresSelectedIndex = this.measuresSelected[listId].indexOf(m.repo_id);
        if (measuresSelectedIndex !== -1) {
          this.measuresSelected[listId].splice(measuresSelectedIndex, 1);
          if (this.measuresSelected[listId].length === 0) {
            delete this.measuresSelected[listId];
            //this.unselectRisk(m.risk_id)
          }
        }
      }
    }
  }
  /*
  selectRisk(risk_id) {
    if (this.risksSelected.indexOf(risk_id) === -1) {
      this.risksSelected.push(risk_id);
    }
  }
  unselectRisk(risk_id) {
    if ((!this.measuresSelected[risk_id] || this.measuresSelected[risk_id].length === 0)
      && !this.newMeasureSelected[this.selectedRisk]) {
      const risksSelectedIndex = this.risksSelected.indexOf(risk_id)
      if (risksSelectedIndex !== -1) {
        this.risksSelected.splice(risksSelectedIndex, 1);
      }
    }
  }
  */
  /**
   * Add new measure
   */
  addNewMeasure() {
    if (this.entity && this.entity.parent_id
      && this.selectedRisk
      && this.measureList[this.selectedSituation ? this.selectedSituation : this.selectedRisk]
      && this.measureList[this.selectedSituation ? this.selectedSituation : this.selectedRisk].findIndex(e => e.name === '') !== -1) {
      this.snackBar.open('Une mesure est déjà en attente de saisie', '', {
        duration: 2000
      })
    } else {
      const measure: EntityWorkbookMeasure = this._workbookService.measureService.getNewEntity({
        parent_id: this.entity.parent_id,
        risk_id: this.selectedRisk,
        type: this.selectedSituation ? this.selectedSituation : 'local',
        name: this.newMeasure[this.selectedSituation ? this.selectedSituation : this.selectedRisk],
        global: !!this.global
      });
      this._workbookService.measureService.save(measure).then((e) => {
        if (!this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type]) {
          this.localMeasures[measure.type === 'local' ? measure.risk_id : measure.type] = [];
        }
        if ((!this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk] || this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk].findIndex((e) => e._id === measure._id) === -1)) {
          this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk].push(measure);
        }
        if (this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {

          if (this.riskList[measure.risk_id] && this.entity.risks.findIndex((r) => (r._id === measure.risk_id)) === -1) {
            this.entity.risks.push(this.riskList[measure.risk_id])
          }
          const _measure = this._workbookService.measureService.getNewEntity(measure);
          _measure.repo_id = measure._id;
          _measure._id = UUID.UUID();
          this.entity.measures.push(_measure);
          this.selectMeasure(_measure);
        }
        this.newMeasure[measure.type === 'local' ? measure.risk_id : measure.type] = '';
        this.newMeasureSelected[measure.type === 'local' ? measure.risk_id : measure.type] = false;
        this._cd.markForCheck();
      });
    }
  }
  addNewSituation() {
    const situation: EntitySituation = this._situationService.getNewEntity({
      _id: UUID.UUID(),
      parent_id: this.entity.parent_id,
      risk_id: this.selectedRisk,
      name: this.newSituation[this.selectedRisk],
      sir: this.newSituationSir[this.selectedRisk],
      fp: this.newSituationFp[this.selectedRisk]
    });
    this.entity.situations.push(situation);
    if (!this.situationList[situation.risk_id]) {
      this.situationList[situation.risk_id] = [];
    }
    this.situationList[situation.risk_id].push(situation);
    this.measureList[situation._id] = this.measureList[situation.risk_id] ? [...this.measureList[situation.risk_id]].sort((a, b) => a.name.localeCompare(b.name)) : [];
    this.newMeasure[situation._id] = '';
    if (!this.situationsSelected[situation.risk_id]) {
      this.situationsSelected[situation.risk_id] = [];
    }
    this.selectSituation(situation);
    //this.situationsSelected[situation.risk_id].push(situation);
  }
  /**
   * Remove measure
   * @param measureId
   */
  removeLocalMeasure(measure: EntityMeasure) {
    if (measure.risk_id && this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {
      let dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer cette mesure de la liste ?';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          const i = this.entity.measures.findIndex((e) => (e._id === measure._id));
          if (i !== -1) {
            this.entity.measures.splice(i, 1);
          }
          this.unselectMeasure(measure);
          // suppression de la mesure locale
          this._workbookService.measureService.remove(measure).then((e) => {
            if (this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {
              const localMeasureIndex = this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk].findIndex(m => m._id === measure._id);
              if (localMeasureIndex !== -1) {
                this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk].splice(localMeasureIndex, 1);
              }
            }
            this._cd.markForCheck();
          });
        }
        dialogRefDel = null;
      });
    }
  }
  /**
   * When risk selected, update measures list linked
   * @param riskId
   */
  onClickRisk(risk: EntityRisk, anchor, event = null) {
    if (event && (!this.selectedRisk || this.selectedSituation)) {
      event.stopPropagation()
    }
    this.selectedRisk = risk._id;
    this.selectedRiskName = risk.name;
    this.selectedSituation = '';
    this.selectedSituationName = '';
    this.scrollTo(anchor);
  }

  onClickSituation(risk: EntityRisk, situation: EntitySituation, anchor) {
    this.selectedRisk = risk._id;
    this.selectedRiskName = risk.name;
    this.selectedSituation = situation._id;
    this.selectedSituationName = situation.name;
    this.scrollTo(anchor);
  }

  changeNewMeasureDown(event) {
    event.stopPropagation();
    if (event
      && event.srcElement
      && event.srcElement.value === '') {
      // debut de saisie => forcer selection
      this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk] = true;
      //this.selectRisk(this.selectedRisk);
    }
  }
  changeNewMeasureUp(event) {
    event.stopPropagation();
    if (!this.newMeasure[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {
      // retour à vide => forcer désélection
      this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk] = false;
      //this.unselectRisk(this.selectedRisk);
    }
  }
  checkNewMeasure(event) {
    this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk] = !this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk];
    /*
    if (this.newMeasureSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {
      this.selectRisk(this.selectedRisk);
    } else {
      this.unselectRisk(this.selectedRisk);
    }
    */
  }
  saveNewMeasures(): Promise<any> {
    return new Promise((resolve, reject) => {
      const situation_risk: {[situation_id: string]: string} = {};
      Object.keys(this.situationsSelected).forEach((risk_id) => {
        this.situationsSelected[risk_id].forEach((s) => {
          situation_risk[s._id] = risk_id;
        });
      });
      const tosave = Object.keys(this.newMeasure)
        .filter((e) => (!!this.newMeasure[e]))
        .map((e) => this._workbookService.measureService.getNewEntity({
          parent_id: this.entity.parent_id,
          risk_id: situation_risk[e] ? situation_risk[e] : e,
          type: situation_risk[e] ? e : 'local',
          name: this.newMeasure[e],
          global: !!this.global
        })
        );
      promiseAll(tosave.map((m) => this._workbookService.measureService.save(m))).then((result) => {
        result.forEach((e) => {
          if (e && e['id']) {
            const savedMeasure = tosave.find((m) => (m._id === e['id']));
            if (savedMeasure && savedMeasure['risk_id'] && this.newMeasureSelected[savedMeasure['type'] === 'local' ? savedMeasure['risk_id'] : savedMeasure['type']]) {
              if (this.riskList[savedMeasure['risk_id']] && this.entity.risks.findIndex((r) => (r._id === savedMeasure['risk_id'])) === -1) {
                this.entity.risks.push(this.riskList[savedMeasure['risk_id']])
              }
              const _savedMeasure = this._workbookService.measureService.getNewEntity(savedMeasure);
              _savedMeasure.repo_id = savedMeasure._id;
              _savedMeasure._id = UUID.UUID();
              this.entity.measures.push(_savedMeasure);
            }
          }
        });
        resolve(true);
      }).catch((err) => {reject(err);});
    });
  }
  changeMeasureName(measure_id, event) {
    let i = this.entity.measures.findIndex((m) => (
      (m.repo_id === measure_id)
      && (this.selectedSituation
        ? (m.type === this.selectedSituation)
        : (m.type === '' || m.type === 'local' || m.type === 'global'))
    ));
    if (i !== -1) {
      this.entity.measures[i].name = event.srcElement.value;
    }
  }

  changeSituationName(situation_id, event) {
    let i = this.entity.situations.findIndex((s) => (s._id === situation_id));
    if (i !== -1) {
      this.entity.situations[i].name = event.srcElement.value;
    }
  }

  changeSituationSir(situation_id, event) {
    let i = this.entity.situations.findIndex((s) => (s._id === situation_id));
    if (i !== -1) {
      this.entity.situations[i].sir = event.checked;
    }
  }

  changeSituationFp(situation_id, event) {
    let i = this.entity.situations.findIndex((s) => (s._id === situation_id));
    if (i !== -1) {
      this.entity.situations[i].fp = event.checked;
    }
  }
  selectAll() {
    if (!this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk]) {
      this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk] = [];
    }
    const notselected = [...(this.selectedRisk ? this.measureList[this.selectedSituation ? this.selectedSituation : this.selectedRisk] : []),
    ...(this.selectedRisk ? this.localMeasures[this.selectedSituation ? this.selectedSituation : this.selectedRisk] : [])].filter((measure) =>
      (measure && this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk].indexOf(measure._id) === -1)
    );
    if (notselected.length) {
      notselected.forEach((measure) => {
        const i = this.entity.measures.findIndex((m) => {
          return (m._id === measure._id
            && ((!this.selectedSituation && (m.type === '' || m.type === 'local' || m.type === 'global')) || (this.selectedSituation && m.type === this.selectedSituation)));
        });
        if (i === -1) {
          let addedMeasure: EntityMeasure = this._measureService.getNewEntity(measure);
          if (this.selectedSituation) {
            addedMeasure.type = this.selectedSituation;
          }
          if (this.global) {
            addedMeasure.global = true;
          }
          this.entity.measures.push(addedMeasure);
          this.selectMeasure(addedMeasure);
        }
        if (this.entity.risks.findIndex((r) => (r._id === measure.risk_id)) === -1) {
          this.entity.risks.push(this.riskList[measure.risk_id])
        }
      });
      this.entity.update_risks_situations();
    }
  }
  unselectAll() {

    if (this.selectedRisk
      && this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk]
      && this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk].length) {
      const selected = this.measuresSelected[this.selectedSituation ? this.selectedSituation : this.selectedRisk].map((e) => (e));
      selected.forEach((measure_id) => {
        const i = this.entity.measures.findIndex((m) => {
          return (m._id === measure_id
            && ((!this.selectedSituation && (m.type === '' || m.type === 'local' || m.type === 'global')) || (this.selectedSituation && m.type === this.selectedSituation)))
        });
        if (i !== -1) {
          this.unselectMeasure(this.entity.measures[i]);
          this.entity.measures.splice(i, 1);
        }
      });

      this.entity.update_risks_situations();
    }

  }
}
