import { PermissionsService } from 'app/shared/auth';
import { DbCommonService } from 'app/shared/db';
import { EntityOwnedService } from 'app/shared/services/entityOwned.service';
import { LoadingService } from './loading.service';
import { EntityDocumentMeta } from 'app/shared/models';
import { getIcon, formatBytes } from 'app/shared/utils';

export class DocumentMetaService extends EntityOwnedService {

  static ENTITY_FIELDS: string[] = ['data_id', 'name', 'displayName', 'description', 'mime', 'ext', 'size', 'icon', 'category', 'categoryPicto', 'createdAt', 'createdName', 'updatedAt', 'updatedName', 'isInline'];


  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityDocumentMeta {
    return new EntityDocumentMeta(data, clone);
  }

  getFromFile(file: File) {
    return {
      name: this.getFileName(file),
      ext: this.getFileExt(file),
      displayName: file.name,
      mime: file.type,
      size: formatBytes(file.size),
      icon: getIcon(file.type)

    }
  }

  formatBytes(a, b = 1): string { return formatBytes(a, b); }
  getIcon(mime: string): string { return getIcon(mime); }
  getFileSize(file: File) { return formatBytes(file.size); }
  getFileName(file: File) { return this.getFilenameName(file.name); }
  getFileExt(file: File) { return this.getFilenameExt(file.name); }
  getFilenameName(filename: string) { return filename.substr(0, filename.lastIndexOf('.')).substr(0, 80); }
  getFilenameExt(filename: string) { return filename.substr(filename.lastIndexOf('.') + 1); }

}
