import {Component, Inject, ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoggerService, CommonComponent} from 'app/shared';
import {EntityAnalysable, EntityWorkbook} from 'app/workbook-core';
import {WorkbookRisksComponent} from './risks.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbook-risks-dialog',
  templateUrl: './risks-dialog.component.html',
  styleUrls: ['./risks-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookRisksDialogComponent extends CommonComponent {

  @ViewChild('entityForm') entityForm: WorkbookRisksComponent;
  id: string;
  workbook: EntityWorkbook;
  parent_id: string;
  entity: EntityAnalysable;
  selectedRisk: string;
  selectedSituation: string;
  title: string;
  global: boolean;
  constructor(
    public dialogRef: MatDialogRef<WorkbookRisksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService
  ) {
    super(_logger);
    if (data['id']) {
      this.id = data['id'];
    }
    if (data['entity']) {
      this.entity = data['entity'];
    }
    if (data['workbook']) {
      this.workbook = data['workbook'];
    }
    if (data['parent_id']) {
      this.parent_id = data['parent_id'];
    }
    if (data['selectedRisk']) {
      this.selectedRisk = data['selectedRisk'];
    }
    if (data['selectedSituation']) {
      this.selectedSituation = data['selectedSituation'];
    }
    if (data['activity']) {
      this.title = 'Activité : <span>' + data['activity'] + '</span>';
    }
    if (data['zone']) {
      this.title = 'Zone : <span>' + data['zone'] + '</span>';
    }
    this.global = !!data['global'];

  }
  close() {

    if (this.entityForm) {
      this.entityForm.saveNewMeasures().then((e) => {
        //no save, see openRisksDialog
        this.dialogRef.close(this.entityForm.entity);
        //super.save();
      });
    }
  }
}
