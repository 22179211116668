import {Observable, empty} from 'rxjs';
import {map} from 'rxjs/operators';
import {UUID} from 'angular2-uuid';
import {DbReadWriteService} from 'app/shared';
import {Entity} from 'app/shared/models';
import {formatBytes} from 'app/shared/utils'

export abstract class EntityBaseService {
  onlyChildren: boolean = false;

  static ENTITY_VERSION: string;
  static ENTITY_SINGLE: boolean = false;
  //si on ne gère qu'un type
  static ENTITY_TYPE: string = '';
  //si c'est une liste hétérogène
  static ENTITY_TYPES: string[] = [];
  static ENTITY_FIELDS: string[] = [];
  static ENTITY_BASE_FIELDS: string[] = ['_id', 'documentType', '_rev', '_conflicts', 'parent_id', 'repo_id',];

  static ENTITY_URL = '';
  static ENTITY_URL_ADD = 'new';
  static ENTITY_URL_EDIT = ':id';

  protected _entityTypes: string[];
  protected _entitySingle: boolean;
  protected _entityFields: string[];


  constructor(
    protected _dbService: DbReadWriteService
  ) {
    const entityVersion = (<typeof EntityBaseService> this.constructor).ENTITY_VERSION;
    const entityType = (<typeof EntityBaseService> this.constructor).ENTITY_TYPE + (entityVersion ? '_' + entityVersion : '');
    if (entityType) {
      this.setEntityType(entityType);
      this._entitySingle = (<typeof EntityBaseService> this.constructor).ENTITY_SINGLE;

    } else {
      this.setEntityTypes((<typeof EntityBaseService> this.constructor).ENTITY_TYPES);
    }
    this._entityFields = [...EntityBaseService.ENTITY_BASE_FIELDS, ...(<typeof EntityBaseService> this.constructor).ENTITY_FIELDS];
  }

  getUrl() {
    return '/' + (<typeof EntityBaseService> this.constructor).ENTITY_URL;
  }
  getUrlEntity(element: Entity = null) {
    return this.getUrl() + '/' + (element ? element._id : this.getUrlAdd());
  }
  getUrlEntityById(id: string) {
    return this.getUrl() + '/' + (id ? id : this.getUrlAdd());
  }
  getUrlAdd() {
    return (<typeof EntityBaseService> this.constructor).ENTITY_URL_ADD;
  }

  getEntityTypes(): string[] {
    return this._entityTypes;
  }
  getEntityFields() {
    return this._entityFields;
  }
  setEntityTypes(entityTypes: string[]) {
    this._entityTypes = entityTypes;
  }
  getEntityType(): string {
    return this._entityTypes && this._entityTypes[0] ? this._entityTypes[0] : 'none';
  }
  setEntityType(entityType: string) {
    this._entityTypes = [entityType];
  }
  setEntityFields(entityFields: string[]) {
    this._entityFields = entityFields;
  }
  isEntitySingle(): boolean {
    return this._entitySingle;
  }
  setEntitySingle(single: boolean = true) {
    this._entitySingle = single;
  }
  /**
  * Get new Entity (for single)
  * @param data
  * @returns Entity
  */
  getNewEntity(data = {}, clone = false): any {
    if (!data) {
      data = {};
    }
    // if (this.isEntitySingle()) {
    data['documentType'] = this.getEntityType();
    // }
    return this.factory(data, clone);
  }
  /**
  * Get new Entity (=> override in extended classes)
  * @param data
  * @returns Entity
  */
  factory(data = {}, clone = false) {
    return new Entity(data, clone);
  }
  /**
   * Get Entity from PouchDB by id
   * @param id
   * @param dbConflict
   * @param dbRev
   * @param lastVersion
   * @returns {Promise<Entity>}
   */
  get(id, dbConflict?: boolean, dbRev?: string, lastVersion?: boolean): Observable<any> {
    return this._dbService.get(id, dbConflict, dbRev, lastVersion)
      .pipe(map(data => this.getNewEntity(data)));

  }
  getDeleted(doc: Entity): Observable<any> {
    if (doc._deleted && doc._rev && doc._revisions && doc._revisions.ids && doc._revisions.ids.length > 1 && doc._revisions.start) {
      return this._dbService.bulkGet(doc._id, (doc._revisions.start - 1) + '-' + doc._revisions.ids[1])
        .pipe(map((data) => {
          if (data && data.documentType && this.getEntityTypes().indexOf(data.documentType) !== -1) {
            data._rev = doc._rev;
            data._deleted = doc._deleted;
            return this.getNewEntity(data);
          } else {
            return null
          }
        }));
    }
    return empty();
  }
  bulkGet(id, dbRev: string): Observable<any> {
    return this._dbService.bulkGet(id, dbRev)
      .pipe(map((data) => {
        if (data && data.documentType && this.getEntityTypes().indexOf(data.documentType) !== -1) {
          return this.getNewEntity(data);
        } else {
          return null
        }
      }));

  }
  getSingle() {
    return this._getSingle(this.getEntityType());
  }
  protected _getSingle(docType: string): Observable<any> {
    return this._dbService.getSingle(docType)
      .pipe(map(data => this.getNewEntity(data)));

  }
  /**
   * Get specific fields from all pp Objects
   * @returns {any}
   *
   */
  getList(): Observable<any[]> {
    //    if (this.onlyChildren) {
    //      console.info('_getList on onlychildren ' + this.constructor.name);
    //    }

    //console.log('getList ' + this.getEntityType());
    return this._getList()
      .pipe(map(list =>
        list.map(data => this.factory(data)
        )))

  }
  _getList(): Observable<any[]> {
    return this._dbService.getList(this._entityTypes, this._entityFields);
  }
  /**
   * Get specific fields from all pp Objects
   * @returns {any}
   *
   */
  getChildList(parent_id: string): Observable<any[]> {
    return this._dbService.getChildList(parent_id, this._entityTypes, this._entityFields)
      .pipe(map(list => list.map(data => this.factory(data))))

  }
  /**
   * Save Entity in PouchDB
   * @param Entity
   * @returns {Promise<any>}
   */
  save(entity: Entity) {
    return this._save(entity)
      .toPromise() as Promise<Entity>;
  };
  _save(entity: Entity) {
    if (!entity._id) {
      entity._id = UUID.UUID();
      entity.documentType = this.getEntityType();
    }
    return this._dbService.save(entity);
  };

  /**
    * Delete Object in PouchDB
    * @param fp
    * @returns {Promise<any>}
    */
  remove(entity: Entity) {
    return this.removeById(entity._id);
  }
  removeById(id: string) {
    return new Promise((resolve, reject) => {
      this.get(id).toPromise()
        .then((e) => {
          this._dbService.remove(e).toPromise()
            .then((r) => {
              resolve(r)
            })
            .catch(error => reject(error))
        })
        .catch(error => reject(error))
    });
  }

  /**
   * save attachment
   * @param documentUploaded
   * @param documentMetadata
   * @param file
   */
  public saveAttachmentFile(entity: Entity, mime: string, file: File) {
    return this._dbService.saveAttachment(entity._id, entity._rev, file.name, mime, file)
      .toPromise() as Promise<any>;
  }

  /**
   * get attachment
   * @param document_id
   * @param attachment
   */
  public getAttachmentFile(entity_id: string, attachment: string) {
    return this._dbService.getAttachment(entity_id, attachment)
      .toPromise() as Promise<any>;
  }

  /**
   * remove attachment
   * @param documentUploaded
   * @param attachment
   */
  public removeAttachmentFile(entity: Entity, attachment: string) {
    return this._dbService.removeAttachment(entity._id, entity._rev, attachment)
      .toPromise();
  }
  getDbInfos(): {
    count: number,
    count_del: number,
    size: string
  } {
    return {
      count: this._dbService.info_count,
      count_del: this._dbService.info_count_del,
      size: formatBytes(this._dbService.info_size)
    }
  }
}
