import {Component} from '@angular/core';
import {LoggerService, CommonComponent, SignableService} from 'app/shared';

@Component({
  selector: 'app-config-edit',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})

export class ConfigComponent extends CommonComponent {

  testResult: string = '';

  constructor(
    protected _signableService: SignableService,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }
  testAPI() {
    this._signableService.test().then((result) => {
      //console.log('test', result);
      this.testResult = '<h3>Test API</h3>';
      if (result) {
        if (result.testConfig && ((result.testConfig.docs && result.testConfig.docs.length) || result.testConfig._id)) {
          this.testResult += '<p>Lecture base Config : <b class="success">OK</b></p>';
        } else {
          this.testResult += '<p><b class="error">Attention :</b> : Problème de lecture base Config' + (result.testConfig.message ? ('<br>=> Erreur: ' + result.testConfig.message) : '') + '</p>';
        }
        if (result.testApp && ((result.testApp.docs && result.testApp.docs.length) || result.testApp._id)) {
          this.testResult += '<p>Lecture/ecriture base App : <b class="success">OK</b></p>';
        } else {
          this.testResult += '<p><b class="error">Attention :</b> : Problème de lecture/ecriture base App' + (result.testApp.message ? ('<br>=> Erreur: ' + result.testApp.message) : '') + '</p>';
        }
        if (result.testDocs && ((result.testDocs.docs && result.testDocs.docs.length) || result.testDocs._id)) {
          this.testResult += '<p>Lecture/ecriture base Docs : <b class="success">OK</b></p>';
        } else {
          this.testResult += '<p><b class="error">Attention :</b> Problème de lecture/ecriture base Docs' + (result.testDocs.message ? ('<br>=> Erreur: ' + result.testDocs.message) : '') + '</p>';
        }
      }
    }).catch((err) => {
      this.testResult += '<p><b class="error">Erreur</b>' + (err && err.message ? ('<br>=> Message: ' + err.message) : '') + '</p>';

    })
  }
}
