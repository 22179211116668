import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityEditComponent, LoggerService } from 'app/shared';
import { EmergencyInstructionsService } from 'app/workbook-core';

@Component({
  selector: 'app-repository-emergencyInstructions-edit',
  templateUrl: './emergencyInstructionsEdit.component.html',
  styleUrls: ['../repository.scss']
})

export class EmergencyInstructionsEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EmergencyInstructionsService
  ) {
    super(_route, _router, _logger, _entityService);
  }
}
