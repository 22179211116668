import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SafeStyle} from '@angular/platform-browser';
import {comparableString, LoggerService, EntityCompany, Contact, Operator} from 'app/shared';
import {
  PreventionPlanService,
  SignablePermitService,
  EntitySignablePermit, EntityPermitContent,
  EntityPreventionPlan, DocumentationService,
  EntityOffice, EntityExternalCompany, WorkbookOfficeService, WorkbookExternalCompanyService,
  SiteService, EntitySite, EntityZone, ContributorGroup, AttendanceGroup, ContentGroup, Implementation,
  EntityWorkbookMeasure, EntityRisk, EntitySituation
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from '../abstractSignableInfo.component';

@Component({
  selector: 'app-signable-permit-info',
  templateUrl: './signablePermitInfo.component.html',
  styleUrls: ['./signablePermitInfo.component.scss'],
  animations: [
    trigger('collapse', [
      state('fold', style({
        height: 0, overflow: 'hidden'
      })),
      state('unfold', style({
        height: '*', overflow: 'hidden'
      })),
      transition('unfold <=> fold', animate('400ms ease-in-out'))
    ])
  ]
})
export class SignablePermitInfoComponent extends AbstractSignableInfoComponent {
  static FLAG_SUFFIX: string = 'pt';
  autoSave = false;
  public entity: EntitySignablePermit;
  workbook: EntityPreventionPlan;
  //@Input() activities: Activity[];
  @Input() implementation: Implementation;
  @Input() permitContent: EntityPermitContent;
  permitContentContentFormStyle: SafeStyle = '';
  permitContentContentGroupFormStyle: SafeStyle[] = [];

  minDateStart: Date;
  maxDate: Date;
  maxDateEnd: Date;
  dateStartError: string = '';
  dateEndError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  oeexList: (EntityOffice | EntityExternalCompany)[] = [];
  contactList: {[oeex_id: string]: Contact[]} = {};
  operatorList: {[oeex_id: string]: Operator[]} = {};

  protected _contributorChangeSubscription: {[groupIndex: number]: Subscription} = {};
  filteredContributors: (EntityOffice | EntityExternalCompany)[];

  protected _contributorContactChangeSubscription: {[groupIndex: number]: Subscription} = {};
  filteredContributorContacts: Contact[];

  protected _operatorChangeSubscription: {[groupIndex: number]: Subscription} = {};
  filteredOperators: (EntityOffice | EntityExternalCompany)[];
  //operatorBirthdays: {[group: string]: string[]} = {};
  protected _operatorContactChangeSubscription: {[groupIndex: number]: Subscription} = {};
  filteredOperatorContacts: Operator[];

  // offices: EntityOffice[] = [];
  // eexs: EntityExternalCompany[] = [];
  // oeexList: {[oeex_id: string]: EntityOffice | EntityExternalCompany} = {};
  protected _oeexSubscription: Subscription;

  protected newContent: {[group: string]: string} = {};
  protected contentKeys: {[group: string]: string[]} = {};

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  placeHolderStartDate: string = 'Début';
  placeHolderEndDate: string = 'Fin';

  isCollapsed: {[id: string]: boolean} = {};
  location_site_id: string = '';
  locations: EntitySite[];
  locationRisks: {
    [zone_id: string]: {
      [risk_id: string]: {
        risk_situations: EntityRisk | EntitySituation,
        measures: EntityWorkbookMeasure[]
      }
    }
  } = {};


  fieldsErrors: {[group: string]: string[][]} = {};
  fieldsError: boolean = false;

  constructor(
    protected _siteService: SiteService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: SignablePermitService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list;
        this.initSearchSite();
      }
    });
  }
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (changes) {
      if (changes['workbook'] && this.workbook) {
        this.minDateStart = new Date(this.workbook.dateStart);
        this.maxDate = new Date(this.workbook.dateEnd);
        this.maxDateEnd = new Date(this.workbook.dateEnd);
        this.updateMaxDateEnd();
      }
    }
  }
  protected _init(data) {
    super._init(data);
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.oeexList = list;
        this.contactList = {};
        this.oeexList.forEach((oeex) => {
          this.contactList[oeex._id] = oeex.contacts;
          this.operatorList[oeex._id] = oeex.operators;
        });
        if (this.entity && !this.entity._id) {
          let services = this.oeexList.filter((e) => (e.documentType === WorkbookOfficeService.ENTITY_TYPE));
          let eexs = this.oeexList.filter((e) => (e.documentType === WorkbookExternalCompanyService.ENTITY_TYPE));
        }
      }
    });
    if (this.entity) {
      if (!this.entity._id && this.workbook && this.workbook.dateStart && this.workbook.dateEnd && this.entity.dateStart && this.entity.dateEnd) {
        if (this.entity.dateStart.getTime() < this.workbook.dateStart.getTime()) {
          this.entity.dateStart = new Date(this.workbook.dateStart);
        }
        if (this.entity.dateStart.getTime() > this.workbook.dateEnd.getTime()) {
          this.entity.dateStart = new Date(this.workbook.dateEnd);
        }
        this.entity.dateEnd = new Date(this.entity.dateStart);
      }
      if (!this.entity._id && this.permitContent) {
        this.entity.permitContent = this.permitContent;
      }
      if (this.entity.permitContent) {
        if (this.entity.permitContent.contentFormStyle) {
          this.permitContentContentFormStyle = this.bypassSecurityTrustStyle(this.entity.permitContent.contentFormStyle);
        }
        if (this.entity.permitContent.contentGroups) {
          this.entity.permitContent.contentGroups.forEach((cg) => {
            this.permitContentContentGroupFormStyle.push(cg.formStyle ? this.bypassSecurityTrustStyle(cg.formStyle) : '');
          });
        }
      }

      this.fieldsErrors = {};
      Object.keys(this.entity.operators).forEach((groupName) => {
        if (!this.fieldsErrors[groupName]) {
          this.fieldsErrors[groupName] = [];
        }
        this.entity.operators[groupName].forEach((op) => {
          this.fieldsErrors[groupName].push([]);
        });
      });
      Object.keys(this.entity.permitContents).forEach((groupName) => {
        if (!this.newContent[groupName]) {
          this.newContent[groupName] = '';
        }
        if (!this.contentKeys[groupName]) {
          this.contentKeys[groupName] = Object.keys(this.entity.permitContents[groupName]);
        }
      });
      this.refreshLocations();
      this.updateMaxDateEnd();
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._contributorChangeSubscription) {
      Object.keys(this._contributorChangeSubscription).forEach((index) => {this._contributorChangeSubscription[index].unsubscribe()});
    }
    if (this._contributorContactChangeSubscription) {
      Object.keys(this._contributorContactChangeSubscription).forEach((index) => {this._contributorContactChangeSubscription[index].unsubscribe()});
    }
    if (this._operatorChangeSubscription) {
      Object.keys(this._operatorChangeSubscription).forEach((index) => {this._operatorChangeSubscription[index].unsubscribe()});
    }
    if (this._operatorContactChangeSubscription) {
      Object.keys(this._operatorContactChangeSubscription).forEach((index) => {this._operatorContactChangeSubscription[index].unsubscribe()});
    }
  }
  switchDoc(event, doc) {
    if (this.entity) {
      if (event.checked) {
        if (this.entity.documents.findIndex((e) => (e._id === doc._id)) === -1) {
          this.entity.documents.push(doc);
        }
      } else {
        let i = this.entity.documents.findIndex((e) => (e._id === doc._id));
        if (i !== -1) {
          this.entity.documents.splice(i, 1)
        }
      }
    }
  }
  switchActivity(event, id) {
    if (this.entity && this.entity.activities) {
      if (event.checked) {
        if (this.entity.activities.indexOf(id)) {
          this.entity.activities.push(id);
        }
      } else {
        let i = this.entity.activities.indexOf(id);
        if (i !== -1) {
          this.entity.activities.splice(i, 1)
        }
      }
    }
  }
  switchLocalisation(event, id) {
    if (this.entity && this.entity.localisations) {
      if (event.checked) {
        if (this.entity.localisations.indexOf(id)) {
          this.entity.localisations.push(id);
        }
      } else {
        let i = this.entity.localisations.indexOf(id);
        if (i !== -1) {
          this.entity.localisations.splice(i, 1)
        }
      }
    }
  }
  changeLocationSite(event) {
    //console.log('changeLocationSite', event);
    if (event && event.value && this.locations && this.locations.length) {
      const s = this.locations.find((e) => (e._id === event.value));
      if (s) {
        this.entity.site = new EntitySite(s);
        this.entity.site.zones = [];
        this.entity.site.documents = [];
        this.refreshLocations();
      }
    }
  }
  refreshLocations() {
    this.locationRisks = {};
    if (this.entity && this.entity.implementation && this.entity.implementation.step) {
      this.locations = this.entity.implementation.step.getLocations();
      this.locations.forEach((s: EntitySite) => {
        if (s.zones && s.zones.length) {
          s.zones.forEach((z: EntityZone) => {
            this.locationRisks[z._id] = {};
            if (z.risks && z.risks.length) {
              z.risks.forEach((r) => {
                this.locationRisks[z._id][r._id] = {
                  risk_situations: r,
                  measures: []
                }
              });
            }
            if (z.situations && z.situations.length) {
              z.situations.forEach((rs) => {
                this.locationRisks[z._id][rs._id] = {
                  risk_situations: rs,
                  measures: []
                }
              });
            }
            if (z.measures && z.measures.length) {
              z.measures.forEach((m) => {
                if (m.type && m.type && this.locationRisks[z._id][m.type]) {
                  this.locationRisks[z._id][m.type].measures.push(m);
                } else if (this.locationRisks[z._id][m.risk_id]) {
                  this.locationRisks[z._id][m.risk_id].measures.push(m);
                }
              });
            }
          });
        }
      });
    }
    if (this.entity && this.entity.site && this.location_site_id !== this.entity.site._id) {
      this.location_site_id = this.entity.site._id;
    }
  }
  initSearchSite() {
    this.filteredSites = this.getFilteredByName(this.siteList, ((this.entity && this.entity.site) ? this.entity.site.name : ''));
    if (this.entity && this.entity.implementation && this.entity.implementation.step && this.entity.implementation.step.strictLocations) {
      const autorizedSites = this.entity.implementation.step.getLocations().map((e) => (e._id));
      this.filteredSites = this.filteredSites.filter((e) => (autorizedSites.indexOf(e._id) !== -1));
    }
    if (this.filteredSites && this.filteredSites.length > 200) {
      this.filteredSites = [];
    }
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(value => {
          this.filteredSites = this.getFilteredByName(this.siteList, value);
          if (this.entity && this.entity.implementation && this.entity.implementation.step && this.entity.implementation.step.strictLocations) {
            const autorizedSites = this.entity.implementation.step.getLocations().map((e) => (e._id));
            this.filteredSites = this.filteredSites.filter((e) => (autorizedSites.indexOf(e._id) !== -1));
          }
          if (this.filteredSites && this.filteredSites.length > 200) {
            this.filteredSites = [];
          }
        });
    }
  }
  initSearchContributor(contributorGroupName: string, contributorGroupIndex: number, contributorIndex: number) {
    this.filteredContributors = this.getFilteredByName(this.oeexList,
      ((this.entity && this.entity.contributors && this.entity.contributors[contributorGroupName] && this.entity.contributors[contributorGroupName][contributorIndex] && this.entity.contributors[contributorGroupName][contributorIndex].company) ?
        this.entity.contributors[contributorGroupName][contributorIndex].company.name : ''));
    if (this.filteredContributors && this.filteredContributors.length > 200) {
      this.filteredContributors = [];
    }
    if (!this._contributorChangeSubscription[contributorGroupIndex] && this.form && this.form.controls && this.form.controls['contributor_' + contributorGroupIndex + '_' + contributorIndex + '_name']) {
      this._contributorChangeSubscription[contributorGroupIndex] = this.form.controls['contributor_' + contributorGroupIndex + '_' + contributorIndex + '_name'].valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(value => {
          this.filteredContributors = this.getFilteredByName(this.oeexList, value);
          if (this.filteredContributors && this.filteredContributors.length > 200) {
            this.filteredContributors = [];
          }
        });
    }
  }
  initSearchContributorContact(contributorGroupName: string, contributorGroupIndex: number, contributorIndex: number, signatoryPermit: boolean = true) {
    this.filteredContributorContacts = this.getFilteredByName(
      (this.entity && this.entity.contributors && this.entity.contributors[contributorGroupName] && this.entity.contributors[contributorGroupName][contributorIndex] && this.entity.contributors[contributorGroupName][contributorIndex].company) ?
        this.contactList[this.entity.contributors[contributorGroupName][contributorIndex].company._id] : [],
      ((this.entity && this.entity.contributors) ? this.entity.originatorContact.name : ''), signatoryPermit);
    if (this.filteredContributorContacts && this.filteredContributorContacts.length > 200) {
      this.filteredContributorContacts = [];
    }
    if (!this._contributorContactChangeSubscription[contributorGroupIndex] && this.form && this.form.controls && this.form.controls['contributorContact_' + contributorGroupIndex + '_' + contributorIndex + '_name']) {
      this._contributorContactChangeSubscription[contributorGroupIndex] = this.form.controls['contributorContact_' + contributorGroupIndex + '_' + contributorIndex + '_name'].valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(value => {
          this.filteredContributorContacts = this.getFilteredByName((this.entity && this.entity.contributors && this.entity.contributors[contributorGroupName] && this.entity.contributors[contributorGroupName][contributorIndex] && this.entity.contributors[contributorGroupName][contributorIndex].company) ?
            this.contactList[this.entity.contributors[contributorGroupName][contributorIndex].company._id] : [], value, signatoryPermit);
          if (this.filteredContributorContacts && this.filteredContributorContacts.length > 200) {
            this.filteredContributorContacts = [];
          }
        });
    }
  }
  initSearchOperator(operatorGroupName: string, operatorGroupIndex: number, operatorIndex: number) {
    this.filteredOperators = this.getFilteredByName(this.oeexList,
      ((this.entity && this.entity.operators && this.entity.operators[operatorGroupName] && this.entity.operators[operatorGroupName][operatorIndex] && this.entity.operators[operatorGroupName][operatorIndex].company) ?
        this.entity.operators[operatorGroupName][operatorIndex].company : ''));
    if (this.filteredOperators && this.filteredOperators.length > 200) {
      this.filteredOperators = [];
    }
    if (!this._operatorChangeSubscription[operatorGroupIndex] && this.form && this.form.controls && this.form.controls['operator_' + operatorGroupIndex + '_' + operatorIndex + '_company']) {
      this._operatorChangeSubscription[operatorGroupIndex] = this.form.controls['operator_' + operatorGroupIndex + '_' + operatorIndex + '_company'].valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(value => {
          this.filteredOperators = this.getFilteredByName(this.oeexList, value);
          if (this.filteredOperators && this.filteredOperators.length > 200) {
            this.filteredOperators = [];
          }
        });
    }
  }
  initSearchOperatorContact(operatorGroupName: string, operatorGroupIndex: number, operatorIndex: number, field: string = 'name') {
    this.filteredOperatorContacts = this.getFilteredOperator(
      (this.entity && this.entity.operators && this.entity.operators[operatorGroupName] && this.entity.operators[operatorGroupName][operatorIndex] && this.entity.operators[operatorGroupName][operatorIndex].company) ?
        this.operatorList[this.entity.operators[operatorGroupName][operatorIndex].company_id] : [],
      ((this.entity && this.entity.operators && this.entity.operators[operatorGroupName] && this.entity.operators[operatorGroupName][operatorIndex] && this.entity.operators[operatorGroupName][operatorIndex][field]) ? this.entity.operators[operatorGroupName][operatorIndex][field] : ''), field);
    if (this.filteredOperatorContacts && this.filteredOperatorContacts.length > 200) {
      this.filteredOperatorContacts = [];
    }
    if (!this._operatorContactChangeSubscription[operatorGroupIndex] && this.form && this.form.controls && this.form.controls['operatorContact_' + operatorGroupIndex + '_' + operatorIndex + '_' + field]) {
      this._operatorContactChangeSubscription[operatorGroupIndex] = this.form.controls['operatorContact_' + operatorGroupIndex + '_' + operatorIndex + '_' + field].valueChanges
        .pipe(
          debounceTime(500)
        )
        .subscribe(value => {
          this.filteredOperatorContacts = this.getFilteredOperator((this.entity && this.entity.operators && this.entity.operators[operatorGroupName] && this.entity.operators[operatorGroupName][operatorIndex] && this.entity.operators[operatorGroupName][operatorIndex].company) ?
            this.operatorList[this.entity.operators[operatorGroupName][operatorIndex].company_id] : [], value, field);
          if (this.filteredOperatorContacts && this.filteredOperatorContacts.length > 200) {
            this.filteredOperatorContacts = [];
          }
        });
    }
  }

  addContributor(contributorGroup: ContributorGroup) {
    if (this.entity && contributorGroup && this.entity.contributors && this.entity.contributors[contributorGroup.name]) {
      const tab = this.entity.contributors[contributorGroup.name].map((e) => (Object.assign({}, e)));
      tab.push({company: new EntityCompany(), contact: new Contact});
      setTimeout(() => {this.entity.contributors[contributorGroup.name] = tab;});
    }
  }
  removeContributor(contributorGroup: ContributorGroup, contributorIndex: number) {
    if (this.entity && contributorGroup && this.entity.contributors && this.entity.contributors[contributorGroup.name] && this.entity.contributors[contributorGroup.name][contributorIndex]) {
      const tab = this.entity.contributors[contributorGroup.name].map((e) => (Object.assign({}, e)));
      tab.splice(contributorIndex, 1);
      setTimeout(() => {this.entity.contributors[contributorGroup.name] = tab;});
    }
  }
  setContributorCompany(contributorGroup: ContributorGroup, contributorIndex: number, value: EntityOffice | EntityExternalCompany, event) {
    if (event && event.isUserInput && this.entity && this.entity.contributors && this.entity.contributors[contributorGroup.name] && this.entity.contributors[contributorGroup.name][contributorIndex]) {
      this.entity.contributors[contributorGroup.name][contributorIndex].company = new EntityCompany(value);
    }
  }
  setContributorContact(contributorGroup: ContributorGroup, contributorIndex: number, value: Contact, event) {
    if (event && event.isUserInput && this.entity && this.entity.contributors && this.entity.contributors[contributorGroup.name] && this.entity.contributors[contributorGroup.name][contributorIndex]) {
      this.entity.contributors[contributorGroup.name][contributorIndex].contact = Object.assign(new Contact(), value);
    }
  }
  addPermitContent(contentGroup: ContentGroup) {
    if (this.entity && contentGroup && this.entity.permitContents) {
      if (!this.entity.permitContents[contentGroup.name]) {
        this.entity.permitContents[contentGroup.name] = {};
      }
      if (this.newContent[contentGroup.name]) {
        this.entity.permitContents[contentGroup.name][this.newContent[contentGroup.name]] = contentGroup.checkbox ? true : '';
        if (!this.contentKeys[contentGroup.name]) {
          this.contentKeys[contentGroup.name] = [];
        }
        this.contentKeys[contentGroup.name].push(this.newContent[contentGroup.name]);
        this.newContent[contentGroup.name] = '';
      }
    }
  }
  removePermitContent(contentGroup: ContentGroup, contentIndex: string) {
    if (this.entity && contentGroup && this.entity.permitContents && this.entity.permitContents[contentGroup.name] && this.entity.permitContents[contentGroup.name][contentIndex] !== undefined) {
      delete this.entity.permitContents[contentGroup.name][contentIndex];
      if (this.contentKeys[contentGroup.name]) {
        const i = this.contentKeys[contentGroup.name].indexOf(contentIndex);
        if (i !== -1) {
          this.contentKeys[contentGroup.name].splice(i, 1);
        }
      }
    }
  }
  addOperator(operatorGroup: AttendanceGroup) {
    if (operatorGroup && this.entity.operators[operatorGroup.name]) {
      const tab = this.entity.operators[operatorGroup.name].map((e) => (new Operator(e)));
      let i = tab.findIndex((op) => (!op.name && !op.job));
      if (i !== -1 && i !== (tab.length - 1)) {
        tab.splice(i, 1);
        /*
        if (this.operatorBirthdays && this.operatorBirthdays[operatorGroup.name] && this.operatorBirthdays[operatorGroup.name][i]) {
          this.operatorBirthdays[operatorGroup.name].splice(i, 1);
        }
        */
      }
      i = tab.findIndex((op) => (!op.name && !op.job));
      if (i === -1) {
        tab.push(new Operator());
        if (this.fieldsErrors && !this.fieldsErrors[operatorGroup.name]) {
          this.fieldsErrors[operatorGroup.name] = [];
        }
        this.fieldsErrors[operatorGroup.name].push([]);

      }
      setTimeout(() => {this.entity.operators[operatorGroup.name] = tab;})
    }
  }
  removeOperator(operatorGroup: AttendanceGroup, operatorIndex: number) {
    if (this.entity && operatorGroup && this.entity.operators && this.entity.operators[operatorGroup.name] && this.entity.operators[operatorGroup.name][operatorIndex]) {
      const tab = this.entity.operators[operatorGroup.name].map((e) => (new Operator(e)));
      tab.splice(operatorIndex, 1);
      /*
      if (this.operatorBirthdays && this.operatorBirthdays[operatorGroup.name] && this.operatorBirthdays[operatorGroup.name].length > operatorIndex) {
        this.operatorBirthdays[operatorGroup.name].splice(operatorIndex, 1);
      }
      */
      setTimeout(() => {this.entity.operators[operatorGroup.name] = tab;})
    }
  }
  setOperator(operatorGroup: AttendanceGroup, index: number, value: Operator) {
    if (this.entity && this.entity.operators[operatorGroup.name] && this.entity.operators[operatorGroup.name][index]) {
      const companyName = this.entity.operators[operatorGroup.name][index].company;
      const companyId = this.entity.operators[operatorGroup.name][index].company_id;
      const companyRepoId = this.entity.operators[operatorGroup.name][index].company_repo_id;
      this.entity.operators[operatorGroup.name][index] = new Operator(value);
      this.entity.operators[operatorGroup.name][index].company = companyName;
      this.entity.operators[operatorGroup.name][index].company_id = companyId;
      this.entity.operators[operatorGroup.name][index].company_repo_id = companyRepoId;
    }
  }
  setOperatorCompany(operatorGroup: AttendanceGroup, index: number, value: EntityOffice) {
    if (this.entity && this.entity.operators[operatorGroup.name] && this.entity.operators[operatorGroup.name][index]) {
      //this.entity.operators[operatorGroup.name][index].company=value.name;
      this.entity.operators[operatorGroup.name][index].company_id = value._id;
      this.entity.operators[operatorGroup.name][index].company_repo_id = value.repo_id;
    }
  }
  protected getFilteredByName(collection: any[], value: string = '', signatoryPermit: boolean = false): any[] {
    return (collection && collection.length)
      ? (value ?
        collection.filter(e => ((!signatoryPermit || e.signatoryPermit) && e.name && comparableString(e.name).indexOf(comparableString(value)) !== -1))
        : collection.filter(e => (!signatoryPermit || e.signatoryPermit)))
      : [];
  }
  protected getFilteredOperator(collection: any[], value: string = '', field: string = 'name'): any[] {
    return (collection && collection.length)
      ? (value ?
        collection.filter(e => (e[field] && comparableString(e[field]).indexOf(comparableString(value)) !== -1))
        : collection)
      : [];
  }
  updateMaxDateEnd(startAttr: string = 'dateStart', endAttr: string = 'dateEnd') {
    setTimeout(() => {
      if (this.entity && this.entity[startAttr]) {
        if (this.entity && this.entity.permitContent && this.entity.permitContent.enableMaxDuration && this.entity.permitContent.maxDuration) {
          this.maxDateEnd = new Date(new Date(this.entity[startAttr]).getTime() + (1000 * 60 * 60 * 24 * (this.entity.permitContent.maxDuration - 1)));
          if (this.maxDate.getTime() < this.maxDateEnd.getTime()) {
            this.maxDateEnd = new Date(this.maxDate);
          }
          if (new Date(this.entity[endAttr]).getTime() > this.maxDateEnd.getTime()) {
            this.entity[endAttr] = this.maxDateEnd;
          }
          if (new Date(this.entity[startAttr]).getTime() > new Date(this.entity[endAttr]).getTime()) {
            this.entity[endAttr] = new Date(this.entity[startAttr]);
          }
        }
      }
    });
  }
  checkErrors() {
    this.fieldsError = !!Object.keys(this.fieldsErrors).filter((k) => (!!this.fieldsErrors[k].filter((e) => (!!e.filter((m) => (!!m)).length)).length)).length;
  }
}
