import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { Entity } from '../models';
import { AbstractEntityListComponent } from './abstractEntityList.component';

export class EntityEditChildListComponent extends AbstractEntityListComponent implements OnInit {
  protected _routeSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, snackBar, _logger, _entityService);
  }

  ngOnInit() {
    if (!this._entityService.isEntitySingle()) {
      this._routeSubscription = this._route.params.subscribe(params => {
        this._initFromRouteParams(params);
        if (this.entityListSubscriber) {
          this.entityListSubscriber.unsubscribe();
        }
        this.entityListSubscriber = this._getAll().subscribe((data) => {
          if (data) {
            this._init(data);
          }
        })
      });
    }
  }
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }

  protected _getSecureService() {
    return this._getChildService();
  }

  protected _getChildService() {
    return this._entityService;
  }
  protected _getAll() {
    return this._getChildService().getChildren(this.id);
  }
  protected _getUrlEntity(element: Entity = null) {
    return this._entityService.getUrlEntityById(element.parent_id) + '/' + this._getChildService().getUrlEntity(element);
  }
  selectElement(element: Entity) {
    this._router.navigateByUrl(this._getUrlEntity(element));
  }
  addElement() {
    this._router.navigateByUrl(this._getUrlEntity());
  }

}
