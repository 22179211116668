import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {comparableString, normalyzeEmail, normalyzePhone, promiseAll, LoggerService, EntityListPopupComponent, ExcelService, Contact, ConfirmDialogComponent} from 'app/shared';
import {ExternalCompanyService, EntityExternalCompany, JobService, EntityJob} from 'app/workbook-core';
import {ExternalCompanyDialogComponent} from './externalCompanyDialog.component';

@Component({
  selector: 'app-repository-externalCompany-list',
  templateUrl: './externalCompanyList.component.html',
  styleUrls: ['./externalCompanyList.component.scss']
})

export class ExternalCompanyListComponent extends EntityListPopupComponent {
  protected _dialogComponent = ExternalCompanyDialogComponent;

  entityList: EntityExternalCompany[];
  dataSource: MatTableDataSource<EntityExternalCompany>;
  displayedColumns: string[] = ['name', 'job', 'address', 'zipCode', 'city', 'internalCode', 'delete'];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette entreprise ?';
  addConfirmMessage = 'Nouvelle entreprise créée';
  fileData;

  protected _jobListSubscriber: Subscription;
  jobList: string[] = [];

  pageSize = 50;

  constructor(
    protected _jobService: JobService,
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: ExternalCompanyService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this._jobListSubscriber = this._jobService.getAll().subscribe((organizationList: EntityJob[]) => {
      if (organizationList) {
        this.jobList = organizationList.map((e) => (e.name)).sort((a, b) => a.localeCompare(b));
      }
    })

  }

  selectElement(element: EntityExternalCompany = null) {
    this._router.navigateByUrl(this._entityService.getUrlEntity(element));
  }

  protected _afterClose(element) {
    this.selectElement(element);
  }

  protected _getDataSourceFilterPredicate() {
    return (data, filter: string) => {
      return (!filter || filter.trim() === '' ||
        (data && (
          data.name && comparableString(data.name).indexOf(comparableString(filter)) !== -1
          || (data.siret && data.siret.indexOf(filter) !== -1)
          || (data.internalCode && data.internalCode.indexOf(filter) !== -1)
          || (data.contacts && data.contacts.length && data.contacts.findIndex((c) => (
            (c.name && comparableString(c.name).indexOf(comparableString(filter)) !== -1)
            || (c.email && comparableString(c.email).indexOf(comparableString(filter)) !== -1)
            || (c.phone && c.phone.indexOf(filter) !== -1)
            || (c.fax && c.fax.indexOf(filter) !== -1)
            || (c.job && comparableString(c.job).indexOf(comparableString(filter)) !== -1)
          )) !== -1)
          || (data.operators && data.operators.length && data.operators.findIndex((o) => (
            (o.name && comparableString(o.name).indexOf(comparableString(filter)) !== -1)
            || (o.email && comparableString(o.email).indexOf(comparableString(filter)) !== -1)
            || (o.phone && o.phone.indexOf(filter) !== -1)
            || (o.extra && JSON.stringify(o.extra).indexOf(filter) !== -1)
            || (o.job && comparableString(o.job).indexOf(comparableString(filter)) !== -1)
          )) !== -1)
        )));
    }
  }

  exportToExcel() {
    this._excelService.exportAsExcelFile({'Entreprises': this.getCompanyExport(this.entityList), 'Contacts': this.getContactExport(this.entityList), 'Opérateurs': this.getOperatorExport(this.entityList)}, 'EEx');
  }
  getCompanyExport(list) {
    return this.entityList ? this.entityList.map((eex) => (
      {
        id: eex._id,
        name: eex.name,
        job: eex.job,
        siret: eex.siret,
        internalCode: eex.internalCode,
        address: eex.address,
        zipCode: eex.zipCode,
        city: eex.city
      }
    )) : [];
  }
  getContactExport(list) {
    const returned = [];
    if (this.entityList) {
      this.entityList.forEach((eex) => {
        if (eex.contacts) {
          eex.contacts.forEach((c) => {
            returned.push({
              id: c.id,
              contact_name: c.name,
              contact_job: c.job,
              contact_email: c.email,
              contact_phone: c.phone,
              signataire_icp: c.signatory ? 1 : 0,
              signataire_pdp: c.signatoryPdP ? 1 : 0,
              signataire_permit: c.signatoryPermit ? 1 : 0,
              permit: c.signatoryPermit ? 1 : 0,
              cse: c.chsct ? 1 : 0,
              eex_id: eex._id,
              eex_name: eex.name,
              eex_internalCode: eex.internalCode,
              eex_job: eex.job,
              eex_siret: eex.siret,
              eex_address: eex.address,
              eex_zipCode: eex.zipCode,
              eex_city: eex.city
            });
          });
        }
      });
    }
    return returned;
  }
  getOperatorExport(list) {
    const returned = [];
    if (this.entityList) {
      this.entityList.forEach((eex) => {
        if (eex.operators) {
          eex.operators.forEach((c) => {
            let line = {
              id: c.id,
              name: c.name,
              job: c.job,
              email: c.email,
              phone: c.phone,
              //contact_habilitation: (c.extra && c.extra.habilitation) ? c.extra.habilitation : '',
              //contact_birthday: (c.extra && c.extra.birthday) ? c.extra.birthday : '',
              //contact_birthplace: (c.extra && c.extra.birthplace) ? c.extra.birthplace : '',
              company_id: eex._id,
              company_name: eex.name,
              company_internalCode: eex.internalCode,
              company_job: eex.job,
              company_siret: eex.siret,
              company_address: eex.address,
              company_zipCode: eex.zipCode,
              company_city: eex.city
            };
            if (c.extra && Object.keys(c.extra).length) {
              Object.keys(c.extra).forEach((e) => {
                line[e] = c.extra[e];
              });
            }
            returned.push(line);
          });
        }
      });
    }
    return returned;
  }

  parseImport(list: any[]) {

    if (list && list.length) {
      const _eexUpdates: Promise<EntityExternalCompany>[] = [];
      const _eexUpdateContacts: {[eex_id: string]: {name: string, contacts: Contact[]}} = {};
      const parents: {[childname: string]: string} = {};
      const importList: EntityExternalCompany[] = [];
      const jobAddList: string[] = [];
      list.forEach((element) => {
        const contact = new Contact();
        contact.name = !!element['contact_name'] ? ('' + element['contact_name']) : (!element['contact_job'] && !element['contact_phone'] && !element['contact_email']) ? 'Aucun contact' : 'contact';
        contact.job = !!element['contact_job'] ? ('' + element['contact_job']) : '';
        contact.phone = !!element['contact_phone'] ? normalyzePhone(element['contact_phone']) : '';
        contact.email = !!element['contact_email'] ? normalyzeEmail(element['contact_email']) : '';
        contact.signatoryPdP = !!element['signataire_pdp'];
        contact.signatory = !!element['signataire_icp'];
        contact.signatoryPermit = !!element['signataire_permit'];
        contact.chsct = !!element['cse_cst'];
        const i = importList.findIndex((e) => (e.name === element['eex_name']));
        let change: boolean = false;
        if (i === -1) {
          if (element['parent']) {
            const x = this.entityList.findIndex((e) => (e.name === element['parent']));
            if (x !== -1) {
              element['parent'] = this.entityList[x]._id;
            } else {
              parents[element['eex_name']] = element['parent'];
            }
          } else {
            element['parent'] = ''
          }
          let _eex: EntityExternalCompany;
          let _eexUpdate: Promise<EntityExternalCompany>;
          const j = this.entityList.findIndex((e) => (e.name === element['eex_name'] || e.internalCode && element['internalCode'] && e.internalCode === element['internalCode']));
          if (j === -1) {
            change = true;
            _eex = this._entityService.getNewEntity({
              name: element['eex_name'],
              main: element['parent'],
              job: element['eex_job'],
              siret: element['eex_siret'] ? ('' + element['eex_siret']) : '',
              internalCode: element['eex_internalCode'] ? ('' + element['eex_internalCode']) : '',
              address: element['eex_address'] ? element['eex_address'] : '',
              zipCode: element['eex_zipCode'] ? element['eex_zipCode'] : '',
              city: element['eex_city'] ? element['eex_city'] : ''
            });
          } else {
            _eexUpdate = this._entityService.getCurrentOne(this.entityList[j]._id);
            _eexUpdates.push(_eexUpdate);
            if (!_eexUpdateContacts[this.entityList[j]._id]) {
              _eexUpdateContacts[this.entityList[j]._id] = {name: element['eex_name'], contacts: []};
            }
            if (contact.name !== 'Aucun contact') {
              change = true;
              _eexUpdateContacts[this.entityList[j]._id].contacts.push(contact);
            }
          }
          if (!!element['eex_job'] && element['eex_job'] !== 'undefined' && this.jobList.indexOf(element['eex_job']) === -1 && jobAddList.indexOf(element['eex_job']) === -1) {
            jobAddList.push(element['eex_job']);
          }
          if (_eex && _eex.contacts.findIndex((e) => (e.name === contact.name)) === -1) {
            if (contact.name !== 'Aucun contact') {
              change = true;
              _eex.contacts.push(contact);
            }
            if (change) {
              importList.push(_eex);
            }
          }
        } else {
          if (contact.name !== 'Aucun contact' && importList[i].contacts.findIndex((e) => (e.name === contact.name)) === -1) {
            importList[i].contacts.push(contact);
          }
        }
      });
      if (importList.length || Object.keys(_eexUpdateContacts).length) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '1600px',
        });


        dialogRef.componentInstance.confirmMessage = '<p>Importation</p>';
        if (importList.length) {
          dialogRef.componentInstance.confirmMessage += '<p>Vous allez importer les entreprises suivantes :</p><ul>';
          importList.forEach((eex) => {
            dialogRef.componentInstance.confirmMessage += '<li><b>' + eex.name + (eex._id ? '(' + eex._id + ')' : '') + '</b>' + (eex.siret ? '(' + eex.siret + ')' : '') + ' - ' + eex.job + ' - ' + eex.address + ' - ' + eex.zipCode + ' ' + eex.city;
            if (eex.contacts && eex.contacts.length) {
              dialogRef.componentInstance.confirmMessage += '<ul>';
              eex.contacts.forEach((c) => {
                dialogRef.componentInstance.confirmMessage += '<li>' + c.name + '(' + c.email + ')' + ' - ' + c.job + ' - ' + c.phone + '</li>';
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';
            }
            dialogRef.componentInstance.confirmMessage += '</li>';
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        if (Object.keys(_eexUpdateContacts).length) {
          dialogRef.componentInstance.confirmMessage += '<p>Vous allez ajouter les contacts suivants :</p><ul>';
          Object.keys(_eexUpdateContacts).forEach((o_id) => {
            dialogRef.componentInstance.confirmMessage += '<b>' + _eexUpdateContacts[o_id].name + '(' + o_id + ')</b>';
            if (_eexUpdateContacts[o_id].contacts && _eexUpdateContacts[o_id].contacts.length) {
              dialogRef.componentInstance.confirmMessage += '<ul>';
              _eexUpdateContacts[o_id].contacts.forEach((c) => {
                dialogRef.componentInstance.confirmMessage += '<li>' + c.name + '(' + c.email + ')' + ' - ' + c.job + ' - ' + c.phone + '</li>';
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';
            }
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        if (jobAddList.length) {
          dialogRef.componentInstance.confirmMessage += '<p>Et créer ces nouveaux metiers :</p><ul>';
          jobAddList.forEach((ot) => {
            dialogRef.componentInstance.confirmMessage += '<li>' + ot + '</li>';
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        dialogRef.componentInstance.confirmMessage += '<p>Souhaitez vous procéder à l\'importation ?</p>';
        dialogRef.componentInstance.cancelMessage = 'Non';
        dialogRef.componentInstance.buttonMessage = 'Oui';
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._spinner.show();
            promiseAll(jobAddList.map((job) => (this._jobService.save(this._jobService.getNewEntity({name: job}))))).then((res) => {
              promiseAll(importList.map((eex) => (this._entityService.save(eex)))).then((res) => {

                const _eexUpdatesParent: Promise<EntityExternalCompany>[] = [];
                const _eexParents: {[eex_id: string]: string} = {};
                if (Object.keys(parents).length) {
                  Object.keys(parents).forEach((_name) => {
                    const a = this.entityList.findIndex((e) => (e.name === _name));
                    if (a !== -1) {
                      const b = this.entityList.findIndex((e) => (e.name === parents[_name]));
                      if (b !== -1) {

                        _eexUpdatesParent.push(this._entityService.getCurrentOne(this.entityList[a]._id));
                        _eexParents[this.entityList[a]._id] = this.entityList[b]._id;
                      }
                    }
                  });
                }
                promiseAll(_eexUpdates.map((_op) => {
                  return _op.then((o) => {
                    if (o.contacts && _eexUpdateContacts[o._id] && _eexUpdateContacts[o._id].contacts && _eexUpdateContacts[o._id].contacts.length) {
                      _eexUpdateContacts[o._id].contacts.forEach((_c) => {
                        if (o.contacts.findIndex((_d) => (_c.name === _d.name)) === -1) {
                          o.contacts.push(_c);
                        }
                      });
                    }
                    o.contacts = o.contacts.filter((e) => (!((e.name === 'contact' || e.name === '') && (e.job === '' || e.job === 'Responsable') && e.email === '' && e.phone === '')));
                    return this._entityService.save(o);
                  })
                })).then((res) => {

                  promiseAll(_eexUpdatesParent.map((_op) => {
                    return _op.then((o) => {
                      if (o.main && _eexParents[o._id]) {
                        o.main = _eexParents[o._id];
                      }
                      return this._entityService.save(o);
                    })
                  })).then((res) => {
                    this._spinner.hide();
                    this.snackBar.open('Importation terminée', '', {
                      duration: 2000
                    });
                  }).catch((err) => {
                    this._logger.error('eexList', 'import update', JSON.stringify(err));
                    this._spinner.hide();
                    this.snackBar.open('Erreur lors de l\'importation des des contacts dans des entreprises existants', '', {
                      duration: 2000
                    });
                  });

                }).catch((err) => {
                  this._logger.error('eexList', 'import update', JSON.stringify(err));
                  this._spinner.hide();
                  this.snackBar.open('Erreur lors de l\'importation des des contacts dans des entreprises existants', '', {
                    duration: 2000
                  });
                });

              }).catch((err) => {
                this._logger.error('eexList', 'import', JSON.stringify(err));
                this._spinner.hide();
                this.snackBar.open('Erreur lors de l\'importation des nouveaux entreprises', '', {
                  duration: 2000
                });
              });
            }).catch((err) => {
              this._logger.error('eexList', 'import job', JSON.stringify(err));
              this._spinner.hide();
              this.snackBar.open('Erreur lors de l\'importation des types de structures', '', {
                duration: 2000
              });
            });
          }
        });
      }
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        this.parseImport(list);
      }).catch((err) => {
        console.error(err);
      });

      if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
        fileInput.srcElement.value = '';
      }
    }
  }
}
