import { UUID } from 'angular2-uuid';
import { EntityAnalysable } from '../../entityAnalysable.model';
export class EntityZone extends EntityAnalysable {
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    if (!this._id) {
      this._id = UUID.UUID();
    }
  }
}
