import { Component } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PermissionsService, LoggerService, DocumentService, EntityDocumentMetadata, DocumentStatus, DocumentMetadataService, Contact } from 'app/shared';
import { WorkbookOfficeService, CategoryService, OfficeService, PreventionPlanService } from 'app/workbook-core';
import { AbstractWorkbookOfficeComponent } from '../abstractWorkbookOffice.component';

@Component({
  selector: 'app-workbook-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})

export class WorkbookOfficeComponent extends AbstractWorkbookOfficeComponent {

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce service ?';
  constructor(
    protected _preventionPlanService: PreventionPlanService,
    protected _documentMetadataService: DocumentMetadataService,
    protected _officeService: OfficeService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: WorkbookOfficeService

  ) {
    super(_preventionPlanService, _categoryService, _documentService, dialog, snackBar, _permissionService, _logger, _entityService);
  }
  addRepoDocument(doc: EntityDocumentMetadata) {
    if (this.entity) {
      this._spinnerService.show();
      if (this._officeService) {
        this._officeService.getCurrentOne(this.entity.repo_id).then((o) => {
          if (!o.documents) {
            o.documents = [];
          }
          const newDoc: EntityDocumentMetadata = this._documentMetadataService.getNewEntity(doc);
          newDoc.createdAt = null;
          newDoc.createdId = '';
          newDoc.createdName = '';
          newDoc.updatedAt = null;
          newDoc.updatedId = '';
          newDoc.updatedName = '';
          o.documents.push(this._documentMetadataService.beforeSave(newDoc));
          this._officeService.save(o).then((res) => {
            this._entityService.getCurrentOne(this.entity._id).then((oeex) => {
              const i = oeex.documents.findIndex((e) => (e._id === doc._id));
              if (i !== -1) {
                oeex.documents[i].repo_id = res.id;
                this._entityService.save(oeex).then((res) => {
                  this._init(oeex);
                  this._spinnerService.hide();
                }).catch((err) => {
                  console.error('WorkbookOfficeComponent.addRepoDocument error save oeex', JSON.stringify(err));
                  this._spinnerService.hide();
                });
              }
            }).catch((err) => {
              console.error('WorkbookOfficeComponent.addRepoDocument error get oeex', JSON.stringify(err));
              this._spinnerService.hide();
            });
          }).catch((err) => {
            console.error('WorkbookOfficeComponent.addRepoDocument error save repo_oeex', JSON.stringify(err));
            this._spinnerService.hide();
          });
        }).catch((err) => {
          console.error('WorkbookOfficeComponent.addRepoDocument error get repo_oeex', JSON.stringify(err));
          this._spinnerService.hide();
        });
      }
    }
  }
  addRepoContact(contact: Contact) {
    if (this.entity) {
      this._spinnerService.show();
      if (this._officeService) {
        this._officeService.getCurrentOne(this.entity.repo_id).then((o) => {
          if (!o.contacts) {
            o.contacts = [];
          }
          const newContact: Contact = new Contact();
          newContact.name = contact.name;
          newContact.email = contact.email;
          newContact.phone = contact.phone;
          newContact.fax = contact.fax;
          newContact.job = contact.job;
          newContact.isPresent = !!contact.isPresent;
          newContact.isConvened = !!contact.isConvened;
          newContact.signatory = !!contact.signatory;
          newContact.signatoryPdP = !!contact.signatoryPdP;
          newContact.signatoryPermit = !!contact.signatoryPermit;
          newContact.chsct = !!contact.chsct;
          newContact.user_id = contact.user_id;
          newContact.repo_id = '';
          o.contacts.push(newContact);
          this._officeService.save(o).then((res) => {
            const i = this.entity.contacts.findIndex((e) => (e.id === contact.id));
            if (i !== -1) {
              this.entity.contacts[i].repo_id = newContact.id;
              this._save().then((res) => {
                this._spinnerService.hide();
              }).catch((err) => {
                console.error('WorkbookOfficeComponent.addRepoContact error save oeex', JSON.stringify(err));
                this._spinnerService.hide();
              });
            }
          }).catch((err) => {
            console.error('WorkbookOfficeComponent.addRepoContact error save repo_oeex', JSON.stringify(err));
            this._spinnerService.hide();
          });
        }).catch((err) => {
          console.error('WorkbookOfficeComponent.addRepoContact error get repo_oeex', JSON.stringify(err));
          this._spinnerService.hide();
        });
      }
    }
  }
  save() {
    this._save().then((e) => {
      this._afterSave(e);
    });
  }
}
