import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ThemePalette } from '@angular/material/core';
import { LoggerService, EntityFormComponent } from 'app/shared';
import { IcpRecurrenceService, EntityIcpRecurrence, SafetyInstruction, Instruction } from 'app/workbook-core';
import { ColorEvent } from 'ngx-color';
@Component({
  selector: 'app-repository-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends EntityFormComponent {
  entity: EntityIcpRecurrence;
  newStatus: string = '';
  newPdpStatus: string = '';
  COLORPALETTE: string[] = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#FF0000', '#F78DA7', '#9900EF'];

  public color: ThemePalette = 'primary';
  public touchUi = false;
  showPicker: { [index: number]: boolean } = {};
  showPdpPicker: { [index: number]: boolean } = {};
  showValidPdpPicker: boolean;
  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: IcpRecurrenceService

  ) {
    super(dialog, _logger, _entityService);
    this.permissionR = IcpRecurrenceService.SPECIFIC_PERMISSION.VIEWDASHBOARDCONFIG;
    this.permissionU = IcpRecurrenceService.SPECIFIC_PERMISSION.UPDATEDASHBOARDCONFIG;
    this.rightR = this._getSecureService().hasPermission(this.permissionR[0]);
    this.rightU = this._getSecureService().hasPermission(this.permissionU[0]);
  }

  changeDelay(index, value, _from, event) {
    //console.log('changeDelay ' + _from + ' (' + index + '):' + value, event);
    if (this.entity && this.entity.status && this.entity.status[index]) {
      setTimeout(() => {
        this.entity.status[index].delay = value > 0 ? value : 1;
      });
    }
    event.stopPropagation();
  }
  changePdpDelay(index, value, _from, event) {
    //console.log('changeDelay ' + _from + ' (' + index + '):' + value, event);
    if (this.entity && this.entity.statusPdp && this.entity.statusPdp[index]) {
      setTimeout(() => {
        this.entity.statusPdp[index].delay = value > 0 ? value : 1;
      });
    }
    event.stopPropagation();
  }
  changeColor(index: number, event: ColorEvent) {
    if (event.color && this.entity.status && this.entity.status[index]) {
      this.entity.status[index].color = event.color.hex
      this._save().then((e) => { this._afterSave(e); });
    }
    this.showPicker[index] = false;
  }
  changePdpColor(index: number, event: ColorEvent) {
    if (index === null) {
      if (event.color && this.entity.validPdpColor) {
        this.entity.validPdpColor = event.color.hex
        this._save().then((e) => { this._afterSave(e); });
      }
      this.showValidPdpPicker = false;

    } else {
      if (event.color && this.entity.statusPdp && this.entity.statusPdp[index]) {
        this.entity.statusPdp[index].color = event.color.hex
        this._save().then((e) => { this._afterSave(e); });
      }
      this.showPdpPicker[index] = false;
    }
  }
  addStatus() {
    if (this.rightU && this.entity && this.entity.status && this.newStatus) {
      let delay: number = 0;
      this.entity.status.forEach((e) => {
        if (e.delay > delay) {
          delay = e.delay;
        }
      });
      this.entity.status.push({ name: this.newStatus, delay: (+delay + 1), color: '#FFFFFF' });
      this._save().then((e) => { this._afterSave(e); });
      this.newStatus = '';
    };
  }
  addPdpStatus() {
    if (this.rightU && this.entity && this.entity.statusPdp && this.newPdpStatus) {
      let delay: number = 0;
      this.entity.statusPdp.forEach((e) => {
        if (e.delay > delay) {
          delay = e.delay;
        }
      });
      this.entity.statusPdp.push({ name: this.newPdpStatus, delay: (+delay + 1), color: '#FFFFFF' });
      this._save().then((e) => { this._afterSave(e); });
      this.newPdpStatus = '';
    };
  }
  removeStatus(index: number) {
    if (this.rightU && this.entity && this.entity.status && this.entity.status[index]) {
      this.entity.status.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
  removePdpStatus(index: number) {
    if (this.rightU && this.entity && this.entity.statusPdp && this.entity.statusPdp[index]) {
      this.entity.statusPdp.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
}
