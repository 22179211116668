import {OnDestroy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {Observable} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DownloadComponent} from './download.component';
import {
  LoggerService,
  SignableService
} from 'app/shared';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-download-checkpoint',
  template: ''
})

export class DownloadCheckpointComponent extends DownloadComponent {
  constructor(
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_route, dialog, _logger, _spinnerService, _signableService);

  }
  protected _apiCall(data): Observable<any> {
    if (data['id'] && data['audit_id']) {
      this._signableService.configLoaded.subscribe((res) => {
        if (res) {
          this.getCheckpointDocumentById(data['id'], data['audit_id']);
        }
      });
    } else {
      return Observable.throw('no id');
    }
  }
  protected _init(data) {
  }
}
