import {Entity} from './entity.model';

export class EntityConfigServer extends Entity {
  public active: boolean = false;
  public url: string;
  public retry: number = 3;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.active = !!data.provider;
    this.url = (data && data.url) ? data.url : '';
    this.retry = (data && data.retry) ? data.retry : 3;
  }
}
