import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/shared';
import {WorkbooksComponent} from './workbooks.component';
import {WorkbooksStatsComponent} from './workbooksStats.component';
import {WorkbooksStatsStepComponent} from './workbooksStatsStep.component';
import {WorkbooksStatsOfficeComponent} from './workbooksStatsOffice.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: WorkbooksComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showPP'],
        redirectTo: '/401'
      }
    },
  },
  {
    path: 'stats',
    component: WorkbooksStatsComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['statsPP'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: 'stats/step',
    component: WorkbooksStatsStepComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['statsPP'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: 'stats/office',
    component: WorkbooksStatsOfficeComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['statsPP'],
        redirectTo: '/401'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkbooksRoutingModule {
}

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
