import {Injectable, Injector} from '@angular/core';
import {UUID} from 'angular2-uuid';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {mergeMap, first} from 'rxjs/operators';


import {storageGet, storageSet, storageClear, formattedDate, getDifferenceInMinutes} from '../utils';

import {IUser} from './user.model';
import {Auth0ConfigService} from './auth0-config.service';
import {Auth0Service} from './auth0.service';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class AuthService extends Auth0Service {
  static LABEL_ALL = 'Chargement des utilisateurs';
  static PER_PAGE = 100;
  static COMPLETE_UPDATE = 1;
  static DELAY = 150;
  initialized: boolean = false;
  updating: string = '';
  last_complete_update: Date;
  last_update: Date;
  current_update: Date;
  protected db_prefix: string = '';
  protected userId: string;
  protected roles: string[] = [];
  protected syncFilter: string[] = [];
  userProfile: IUser;
  userProfile$ = new ReplaySubject<IUser>(1);
  usersList: IUser[] = [];
  usersList$ = new ReplaySubject<IUser[]>(1);

  // Router via injector pour eviter dependances cyclic sur APP_INITIALIZE
  protected get _router() {
    return this.injector.get(Router);
  }
  constructor(
    protected _loadingService: LoadingService,
    protected config: Auth0ConfigService,
    protected injector: Injector,
    protected http: HttpClient
    //  protected _router: Router,
  ) {
    super(config, http);
  }

  public load(
    config,
    scope: string[] = ['openid', 'profile', 'read:roles', 'user_id', 'name', 'email', 'update:current_user_metadata', 'read:users', 'read:user_idp_tokens', 'create:users', 'delete:users', 'update:users', 'update:users_app_metadata', 'create:user_tickets', 'offline_access'],
    audience: string = '',
    responseType: string[] = ['token', 'id_token']
  ): Promise<any> {
    this.db_prefix = config && config.db_prefix ? config.db_prefix : '';
    return super.load(config.auth0, scope, audience, responseType);
  }
  public getDbPrefix() {
    return this.db_prefix;
  }
  protected getWebAuthConfig(config, scope: string[], audience: string, responseType: string[]) {
    const webConfig = super.getWebAuthConfig(config, scope, audience, responseType);
    webConfig['theme'] = {
      logo: config.logo,
      primaryColor: config.primaryColor
    };
    webConfig['languageDictionary'] = {
      title: config.title,
      mfaInputPlaceholder: 'Code',
      mfaLoginTitle: '2-Step Vérification',
      mfaLoginInstructions: 'Veuillez entrer le code de vérification généré par votre application mobile.',
      mfaSubmitLabel: 'S\'identifier',
      mfaCodeErrorHint: 'Utilisez des numéros',
      error: {
        login: {
          'lock.mfa_registration_required': 'L\'authentification multifactorielle est nécessaire, mais votre appareil n\'est pas inscrit.',
          'lock.mfa_invalid_code': 'Mauvais code. Veuillez réessayer.'
        }
      }
    };
    webConfig['language'] = config.language;
    return webConfig;
  }


  protected _setSession(authResult): void {
    super._setSession(authResult);
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    storageSet('access_token', authResult.accessToken);
    storageSet('id_token', authResult.idToken);
    storageSet('expires_at', expiresAt);

    this.retrieveProfile().catch((err) => {
      console.error('Error to retrieve user profile', err);
    });
  }

  protected _afterAthentication(authResult): void {
    // console.log('_afterAthentication');
    // this._router.navigate(['/dashboard']);
  }

  private get management() {
    return this.getManagement(this.auth0Config.domain, this.getAccessToken());
  }

  public updateProfile(userMeta): Promise<any> {
    return this.userProfile$.pipe(first(),
      mergeMap((profile: any) => {
        const userId: string = profile.user_id == null ? profile.sub : profile.user_id;
        return this.updateUserProfile(userId, userMeta)
      }))
      .toPromise();
  }

  public updateUserProfile(userId, userMeta) {
    return new Promise((resolve, reject) => {
      this.management.patchUserMetadata(userId, userMeta, (err, res) => {
        if (err) {
          reject(err)
        } else {
          this.userProfile.user_metadata = res.user_metadata;
          this.userProfile$.next(this.userProfile);
          resolve(res)
        }
      });
    });
  }

  public getAccessToken() {
    return storageGet('access_token');
  }

  public getIdToken() {
    return storageGet('id_token');
  }

  /**
   * Logout the app auth0
   */
  public logout(): void {
    storageClear();
    super.logout();
  }

  public retrieveProfile(): Promise<IUser> {
    return new Promise((resolve, reject) => {

      const accessToken = storageGet('access_token');
      if (!accessToken) {
        reject(new Error('Access token must exist to fetch profile'));
      }

      this.webAuth.client.userInfo(accessToken, (err, profile) => {
        if (err) {
          // Connection error, retrieve profile from localstorage
          const profileItem = storageGet('profile');

          if (profileItem) {
            this.userId = storageGet('userId');
            this.roles = storageGet('roles');
            this.syncFilter = storageGet('config-syncFilter');
            resolve(profileItem);
          } else {
            reject(err);
          }

        } else {
          // Store profile for disconnected mode
          let newProfileAppMeta = profile[window.location.origin.replace('/test.', '/staging.').replace('/local.', '/staging.') + '/app_metadata'];
          let newProfileUserMeta = profile[window.location.origin.replace('/test.', '/staging.').replace('/local.', '/staging.') + '/user_metadata'];
          if (newProfileUserMeta) {
            profile.user_metadata = newProfileUserMeta;
            this.syncFilter = newProfileUserMeta.syncFilter;
            storageSet('config-syncFilter', this.syncFilter);
            // console.log('authService:get roles from AppMeta', this.roles);
          }
          if (newProfileAppMeta) {
            profile.app_metadata = newProfileAppMeta;
            if (newProfileAppMeta.authorization
              && newProfileAppMeta.authorization.roles
              && newProfileAppMeta.authorization.roles.length) {
              this.userId = profile.sub;
              storageSet('userId', this.userId);
              this.roles = newProfileAppMeta.authorization.roles;
              storageSet('roles', this.roles);
            }
          }
          // TODO: sauvegarder uniquement usermetadata + email + userid et roles ?
          // ou uniquement user_metadata et passer email en attribut ?
          delete profile[window.location.origin.replace('/test.', '/staging.').replace('/local.', '/staging.') + '/app_metadata'];
          delete profile[window.location.origin.replace('/test.', '/staging.').replace('/local.', '/staging.') + '/user_metadata'];
          storageSet('profile', profile);
          resolve(profile);
        }
      });
    }).then((profile: IUser) => {
      this.userProfile$.next(profile);
      this.userProfile = profile;
      return profile;
    })
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = storageGet('expires_at');
    return new Date().getTime() < expiresAt;
  }
  /**
   * create a new user
   * @param Iuser

  public createUser(Iuser): void {
    const tenant = this.auth0Config.domain.split('.')[0];
    this.webAuth.signup({
      connection: tenant + 'Connection',
      email: Iuser.email,
      password: Iuser.password,
      user_metadata: Iuser.userMeta,
      app_metadata: Iuser.appMeta
    }, function (err, resp) {
      if (err) {
        // this.logger.error('AuthService', err.message);
        console.error('AuthService', err.message);
      } else {
        // this.logger.info('AuthService', resp);
      }
    });
  }
 */
  /**
   * send a email to user for change his password
   * @param email
   */
  public changePassword(email: string): void {
    const domain = this.auth0Config.domain.split('.')[0];
    const connection = domain.split('-trepied')[0] + 'Connection';
    this.webAuth.changePassword({
      connection: connection,
      email: email
    }, function (err, resp) {
      if (err) {
        // this.logger.error('AuthService', err.message);
        console.error('AuthService', err.message);
      } else {
        // this.logger.info('AuthService', resp);
      }
    });
  }

  /**
   * Get the user ID
   */
  public getUserId(): string {
    return this.userId;
  }
  public getUserEmail(): string {
    return this.userProfile.email;
  }

  /**
   * Get user roles
   */
  public getRoles(): string[] {
    return this.roles;
  }
  /**
   * Get user roles
   */
  public getSyncFilter(): string[] {
    return this.syncFilter;
  }
  protected _getLabelAll() {
    return AuthService.LABEL_ALL;
  }
  getUsers() {
    return this.usersList.filter((e) => (e.user_id.split('|').length === 2))
  }

  getUsersList(page: number = 0, per_page: number = AuthService.PER_PAGE, order: string = 'updated_at', desc: number = 1): Observable<any> {
    let q = 'users?page=' + page + '&per_page=' + per_page + '&' + order + '=' + desc + '&fields=user_id%2Cemail%2Cusername%2Cname%2Cnickname%2Cuser_metadata%2Capp_metadata.authorization%2Cemail_verified%2Cupdated_at%2Ccreated_at%2Clast_login&include_fields=true';
    if (this.last_update) {
      q += '&q=updated_at:[' + formattedDate(this.last_update, false, false, true) + ' TO *]';
    }
    //console.log('getUsersList(' + page + '):' + q);
    return this.getAPICall(q);
  }
  refreshUsersList(page = 0, modified: boolean = false, idList: string[] = [], forced: boolean = false): Promise<any> {
    if (page === 0) {
      this.current_update = new Date();
      if (this.last_update && getDifferenceInMinutes(this.current_update, this.last_complete_update) > AuthService.COMPLETE_UPDATE) {
        this.last_update = null;
      }
    }
    return new Promise((resolve, reject) => {
      //if (!this.last_update) {
      if (!this.updating || forced) {
        const _that = this;
        if (!this.updating) {
          this.updating = this._loadingService.addLoad(this._getLabelAll());
        }
        this.getUsersList(page).toPromise().then(async (list: IUser[]) => {
          if (list) {
            idList = [...idList, ...list.map((e) => (e.user_id))];
            list.forEach((u) => {
              const i = _that.usersList.findIndex((e) => (e.user_id === u.user_id));
              if (i === -1) {
                _that.usersList.push(u);
                modified = true;
              } else if (!_that.usersList[i]['updated_at'] || !u['updated_at'] || _that.usersList[i]['updated_at'] !== u['updated_at']) {
                this.usersList[i] = u;
                modified = true;
              }
            });
            if (list.length === AuthService.PER_PAGE) {
              setTimeout(async () => {
                await _that.refreshUsersList(page + 1, modified, idList, true).then((res) => {
                  if (modified || !_that.initialized) {
                    _that.initialized = true;
                    _that.usersList$.next(_that.getUsers());
                  }
                  if (this.updating) {
                    _that._loadingService.removeLoad(_that.updating);
                    _that.updating = '';
                  }
                  resolve(true);
                });
              }, AuthService.DELAY);
            } else {
              if (!_that.last_update) {
                const withoutDeleted = _that.usersList.filter((e) => (idList.indexOf(e.user_id) !== -1))
                if (withoutDeleted.length !== _that.usersList.length) {
                  _that.usersList = withoutDeleted;
                  modified = true;
                }
                _that.last_complete_update = _that.current_update;
              }
              if (modified || !_that.initialized) {
                _that.initialized = true;
                _that.usersList$.next(_that.getUsers());
              }
              _that.last_update = this.current_update;
              if (this.updating) {
                _that._loadingService.removeLoad(_that.updating);
                _that.updating = '';
              }
              resolve(true);
            }
          }
        })
          .catch((err) => {
            if (this.updating) {
              _that._loadingService.removeLoad(_that.updating);
              _that.updating = '';
            }
            reject(err);
          });
        //} else {
        //  resolve(true);
        //}
      } else {
        resolve(true);
      }
    });


  }

  getUserById(id: string): Observable<any> {
    //console.log('getUserById(' + id + ')');
    return this.getAPICall('users/' + id, {fields: 'user_id,email,username,name,nickname', include_fields: true});
  }
  getRolesList(access_token = null): Observable<any> {
    return access_token ? this.getAPI('roles', {}, access_token) : this.getAPICall('roles');
  }
  addUserRoles(userId, roles) {
    if (userId.indexOf('|') !== -1) {
      userId = userId.split('|')[1];
    }
    return this.patchAPICall('users/' + userId + '/roles', roles);
  }
  // Envoi de l'email de verification
  // Attention, fonctionne avec l'utilisateur connecté
  // TODO: bloquer l'utilisateur et renvoyer le mail en cas d'utilisateur non verifié mais authentifié ?
  sendEmailVerification(userId, access_token = null): Observable<any> {
    if (userId.indexOf('|') !== -1) {
      // TODO: voir si il faut la connexion ou non
      userId = userId.split('|')[1];
    }
    const data = {'result_url': window.location.origin, 'user_id': userId};
    return access_token ? this.postAPI('jobs/verification-email', data, {}, access_token) : this.postAPICall('jobs/verification-email', data);
  }
  emailVerificationTicket(userId, result_url = '', access_token = null): Observable<any> {
    if (userId.indexOf('|') !== -1) {
      //   userId = userId.split('|')[1];
    }
    return this.getTicket('email-verification', userId, result_url);
  }
  passwordChangeTicket(userId, result_url = '', access_token = null, ttl = (30 * 86400)): Observable<any> {
    if (userId.indexOf('|') !== -1) {
      //   userId = userId.split('|')[1];
    }
    return this.getTicket('password-change', userId, result_url, ttl, null, {'mark_email_as_verified': true});
  }
  updateUserData(user, access_token = null): Observable<any> {
    const meta: any = {
      user_metadata: user['user_metadata'],
      app_metadata: user['app_metadata']
    };
    if (user && user['user_id']) {
      const u = user['user_id'].split('|');
      if (u.length === 3) {
        meta.connection = u[1];
      }
      const oldUser = this.usersList.find((e) => (e.user_id === user['user_id']));
      if (oldUser && oldUser['email'] && user['email'] && oldUser['email'] !== user['email']) {
        meta.email = user['email'];
        meta.email_verified = false;
      }
    } else {
      const domain = this.auth0Config.domain.split('.')[0];
      meta.connection = domain.split('-trepied')[0] + 'Connection';
      meta.email = user['email'];
    }
    return access_token ? this.patchAPI('users/' + user['user_id'], meta, {}, access_token) : this.patchAPICall('users/' + user['user_id'], meta);
    //    }
  }
  updateUserMetadata(user, access_token = null): Observable<any> {
    //    if (user && user['user_id'] && user['user_metadata']) {
    // let userId = (user['user_id'].indexOf('|') === -1) ? user['user_id'] : user['user_id'].split('|')[1];
    const meta = {'user_metadata': user['user_metadata']};
    return access_token ? this.patchAPI('users/' + user['user_id'], meta, {}, access_token) : this.patchAPICall('users/' + user['user_id'], meta);
    //    }
  }
  deleteUser(userId): Promise<any> {
    return this._deleteUser(userId).toPromise().then(() => {
      const i = this.usersList.findIndex((e) => (e.user_id === decodeURI(userId)));
      if (i !== -1) {
        this.usersList.splice(i, 1);
        this.usersList$.next(this.getUsers());
      }
    });
  }

  _deleteUser(userId): Observable<any> {
    return this.deleteAPICall('users/' + userId);

  }
  createUser(email, password = '', user_metadata = {}, roles = [], access_token = null): Promise<any> {
    return this._createUser(email, password, user_metadata, roles, access_token).toPromise().then((user) => {
      if (this.usersList.findIndex((e) => (e.user_id === user.user_id))) {
        this.usersList = [user, ...this.usersList];
        this.usersList$.next(this.getUsers());
      }
      return user;
    });

  }
  _createUser(email, password = '', user_metadata = {}, roles = [], access_token = null): Observable<any> {
    const domain = this.auth0Config.domain.split('.')[0];
    const connection = domain.split('-trepied')[0] + 'Connection';
    const data = {
      'connection': connection,
      'email': email,
      'nickname': (user_metadata && user_metadata['given_name']) ? user_metadata['given_name'] : email,
      'name': (user_metadata && user_metadata['family_name']) ? user_metadata['family_name'] : email,
      'password': (password ? password : '@Z9z' + UUID.UUID()),
      'user_metadata': user_metadata,
      'email_verified': false,
      'verify_email': false,
      'app_metadata': {
        'authorization': {
          'roles': roles
        }
      }
    };
    return access_token ? this.postAPI('users', data, {}, access_token) : this.postAPICall('users', data);
  }
}
