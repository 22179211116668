import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { promiseAll, LoggerService, EntityEditDialogComponent, ConfirmDialogComponent } from 'app/shared';
import { ActivityService, EntityWorkbook, WorkbookStepService, EntityActivity } from 'app/workbook-core';
import { WorkbookActivityInfoComponent } from './activityInfo.component';

@Component({
  selector: 'app-workbook-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookActivityDialogComponent extends EntityEditDialogComponent {

  @ViewChild('entityForm') entityForm: WorkbookActivityInfoComponent;
  dateStart: Date;
  dateEnd: Date;
  workbook: EntityWorkbook;
  entity: EntityActivity;
  step_id: string;
  default_step_id: string;
  groups: string[];
  showGroup: boolean = false;
  showGroupRadio: number = 0;
  saveCurrentStep: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _workbookStepService: WorkbookStepService,
    public dialogRef: MatDialogRef<WorkbookActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: ActivityService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['step_id']) {
        this.step_id = data['step_id'];
      }
      if (data['default_step_id']) {
        this.default_step_id = data['default_step_id'];
      }
      if (data['saveCurrentStep']) {
        this.saveCurrentStep = data['saveCurrentStep'];
      }
      if (data['groups']) {
        this.groups = data['groups'];
      }
      this.showGroup = !!data['showGroup'];
      this.showGroupRadio = this.showGroup ? 1 : 0;
      if (data['workbook']) {
        this.dateStart = new Date(data['workbook']['dateStart']);
        this.dateEnd = new Date(data['workbook']['dateEnd']);
        this.workbook = data['workbook'];
      }
      if (data['entity']) {
        this.entity = data['entity'];
      }
    }
  }
  changeShowGroupRadio() {
    this.showGroup = this.showGroupRadio === 1;
  }
  save() {
    if (!this.showGroup && this.entityForm && this.entityForm.entity && this.entityForm.entity.group) {
      this.entityForm.entity.group = '';
    }
    if (this.entityForm && this.entityForm.form && this.entityForm.form.dirty && this.entityForm.selectedSteps && this.entityForm.steps && this.entityForm.templates && Object.keys(this.entityForm.selectedSteps).filter((step_id) => (!!this.entityForm.selectedSteps[step_id])).length > 1) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.buttonMessage = "Oui";
      dialogRefDel.componentInstance.cancelMessage = "Non";
      dialogRefDel.componentInstance.confirmMessage = "Vous vous apprêtez à modifier cette activité qui est commune aux " + ((this.workbook && this.workbook.type === 'annual') ? "chantiers type et " : "") + "interventions suivants:";
      dialogRefDel.componentInstance.confirmMessage += "<ul>";
      Object.keys(this.entityForm.selectedSteps).forEach((_step_id) => {
        if (this.entityForm.selectedSteps[_step_id]) {
          let index = this.entityForm.steps.findIndex((e) => (e._id === _step_id));
          if (index !== -1) {
            dialogRefDel.componentInstance.confirmMessage += '<li>' + this.entityForm.steps[index].name + '</li>';
          } else {
            let index = this.entityForm.templates.findIndex((e) => (e._id === _step_id));
            if (index !== -1) {
              dialogRefDel.componentInstance.confirmMessage += '<li>' + this.entityForm.templates[index].name + '</li>';
            }
          }
        }
      });
      dialogRefDel.componentInstance.confirmMessage += "</ul>";
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          super.save();
        }
      });
    } else {
      super.save();
    }
  }
  protected _afterSave(res) {
    if (this.entityForm && this.entityForm.entity && this.entityForm.selectedSteps && this.entityForm.steps) {
      let currentStep;
      const promises = [];
      [...this.entityForm.steps, ...this.entityForm.templates].forEach((step) => {
        let _selected = this.entityForm.selectedSteps[step._id];
        let _pos = step.activities.indexOf(this.entityForm.entity._id);
        if (_pos !== -1 && !_selected) {
          //remove
          if (step._id === this.step_id && !this.saveCurrentStep) {
            //pas de sauvegarde de currentStep (conflict)
            currentStep = step;
          } else {
            step.activities.splice(_pos, 1);
            promises.push(this._workbookStepService.setActivities(step._id, step.activities));
          }
        } else if (_pos === -1 && _selected) {
          const changeConfinedSpace = this.entityForm.entity.confinedSpace;
          //add
          if (step._id === this.step_id && !this.saveCurrentStep) {
            //pas de sauvegarde de currentStep (conflict)
            currentStep = step;
          } else {
            step.activities.push(this.entityForm.entity._id);
            promises.push(this._workbookStepService.setActivities(step._id, step.activities, changeConfinedSpace));
          }
        }
      });
      //TODO promise all then ?
      promiseAll(promises).then((res) => {
        this._spinnerService.hide();
        this.dialogRef.close(this.entityForm.entity);
      }).catch((err) => {
        this._spinnerService.hide();
        this.dialogRef.close(this.entityForm.entity);
      });

    }
  }
}
