import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySingleService, LoadingService} from 'app/shared';
import {EntityFirePermitContent} from '../models/entityFirePermitContent.model';

@Injectable({
  providedIn: 'root'
})
export class FirePermitContentService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement du contenu des permis feu";
  static LABEL_ALL = "chargement des contenu des permis feu ?";
  static LABEL_CHILDREN = "chargement des contenu des permis feu de l'opération ?";
  static LABEL_SAVE = "sauvegarde du contenu des permis feu";
  static LABEL_DELETE = "suppression du contenu des permis feu ?";

  static ENTITY_TYPE: string = 'repo_fpc';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showFirePermitContent'],
    CREATE: ['editFirePermitContent'],
    UPDATE: ['editFirePermitContent'],
    DELETE: []
  }

  static ENTITY_URL = 'repository/firePermitContent';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityFirePermitContent {
    return new EntityFirePermitContent(data, clone);
  }

}
