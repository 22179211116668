import { Entity } from 'app/shared';
export class EntityIcpRecurrence extends Entity {
  static OUTDATED = -1;
  static PRIOR = 0;

  public enabled: boolean;

  public dtdict: boolean;


  public exposures: { name: string, value: number }[];

  public severities: { name: string, value: number }[];

  public recurrences: { name: string, minimum: number, period: number, audits: number }[];
  accidentName: string;
  accidentDuration: number;
  accidentPeriod: number;
  accidentAudits: number;
  systematicCases: string[];

  public status: { name: string, color: string, delay: number }[];
  public statusPdp: { name: string, color: string, delay: number }[];
  public validPdpColor: string;
  public validPdpText: string;


  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.enabled = !!(data && data.enabled);
    this.dtdict = !!(data && data.dtdict);
    this.exposures = (data && data.exposures) ? data.exposures.map((e) => (Object.assign({}, e))) : [];
    this.severities = (data && data.severities) ? data.severities.map((e) => (Object.assign({}, e))) : [];
    this.recurrences = (data && data.recurrences) ? data.recurrences.map((e) => (Object.assign({ name: '', minimum: 0, period: 0, audits: 0 }, e))) : [];
    this.status = (data && data.status) ? data.status.map((e) => (Object.assign({}, e))) : [];
    this.accidentName = (data && data.accidentName) ? data.accidentName : 'Après accident';
    this.accidentDuration = (data && data.accidentDuration) ? data.accidentDuration : 0;
    this.accidentPeriod = (data && data.accidentPeriod) ? data.accidentPeriod : 0;
    this.accidentAudits = (data && data.accidentAudits) ? data.accidentAudits : 0;
    this.systematicCases = (data && data.systematicCases) ? data.systematicCases.map((e) => (e)) : [];
    if (this.status.findIndex((e) => e.delay === EntityIcpRecurrence.OUTDATED) === -1) {
      this.status.push({ name: 'En retard', color: '#ff0000', delay: EntityIcpRecurrence.OUTDATED });
    }
    if (this.status.findIndex((e) => e.delay === EntityIcpRecurrence.PRIOR) === -1) {
      this.status.push({ name: 'A réaliser avant intervention', color: '#999999', delay: EntityIcpRecurrence.PRIOR });
    }
    this.statusPdp = (data && data.statusPdp) ? data.statusPdp.map((e) => (Object.assign({}, e))) : [];
    if (this.statusPdp.findIndex((e) => e.delay === EntityIcpRecurrence.OUTDATED) === -1) {
      this.statusPdp.push({ name: 'En retard', color: '#ff0000', delay: EntityIcpRecurrence.OUTDATED });
    }
    if (this.statusPdp.findIndex((e) => e.delay === EntityIcpRecurrence.PRIOR) === -1) {
      this.statusPdp.push({ name: 'A réaliser avant intervention', color: '#999999', delay: EntityIcpRecurrence.PRIOR });
    }
    this.validPdpColor = (data && data.validPdpColor) ? data.validPdpColor : '#00d084';
    this.validPdpText = (data && data.validPdpText) ? data.validPdpText : 'PdP validé';

  }
  getPeriod(score: number, lastAccident: Date = null): number {
    const now = new Date();
    if (lastAccident && now.getTime() < ((new Date(lastAccident)).getTime() + (this.accidentDuration * 1000 * 24 * 3600))) {
      return this.accidentPeriod
    } else {
      let period = 0;
      this.recurrences.sort((a, b) => (a.minimum > b.minimum) ? 1 : ((b.minimum > a.minimum) ? -1 : 0))
        .forEach((r) => {
          if (score >= r.minimum) {
            period = r.period;
          }
        });
      return period;
    }
  }
  getAudits(score: number, lastAccident: Date = null): number {
    const now = new Date();
    if (lastAccident && now.getTime() < ((new Date(lastAccident)).getTime() + (this.accidentDuration * 1000 * 24 * 3600))) {
      return this.accidentAudits
    } else {
      let audits = 0;
      this.recurrences.sort((a, b) => (a.minimum > b.minimum) ? 1 : ((b.minimum > a.minimum) ? -1 : 0))
        .forEach((r) => {
          if (score >= r.minimum) {
            audits = r.audits;
          }
        });
      return audits;
    }
  }
  getStatus(score: number, lastAccident: Date = null) {
    const now = new Date();
    if (lastAccident && now.getTime() < ((new Date(lastAccident)).getTime() + (this.accidentDuration * 1000 * 24 * 3600))) {
      return this.accidentName;
    } else {
      let status = '';

      this.recurrences.sort((a, b) => (a.minimum > b.minimum) ? 1 : ((b.minimum > a.minimum) ? -1 : 0))
        .forEach((r) => {
          if (score >= r.minimum) {
            status = r.name;
          }
        });
      return status;
    }
  }
  //null=RAS
  getIcpStatus(deadline: Date, when: Date = new Date(), addDiff: number = 0) {
    let _before = (new Date(when)).getTime() < (new Date()).getTime();
    let status = null;
    let diff = (deadline)
      ? (addDiff + Math.ceil((new Date(deadline)).getTime() - when.getTime()) / (1000 * 3600 * 24))
      : 0;
    if (!_before) {
      if (diff > 0) {
        this.status.sort((b, a) => (a.delay > b.delay) ? 1 : ((b.delay > a.delay) ? -1 : 0))
          .forEach((r) => {
            if (diff < r.delay) {
              status = r;
            }
          });
      } else {
        status = this.status.find((s) => (s.delay === (diff === 0 ? EntityIcpRecurrence.PRIOR : EntityIcpRecurrence.OUTDATED)))
      }
    }
    //console.log('getIcpStatus:' + diff, status);

    return status ? Object.assign({}, status) : status;
  }
  getPdpStatus(deadline: Date, when: Date = new Date(), addDiff: number = 0) {
    let status = null;
    let diff = (deadline)
      ? (addDiff + Math.ceil((new Date(deadline)).getTime() - (new Date(when)).getTime()) / (1000 * 3600 * 24))
      : 0;
    if (diff > 0) {
      this.statusPdp.sort((b, a) => (a.delay > b.delay) ? 1 : ((b.delay > a.delay) ? -1 : 0))
        .forEach((r) => {
          if (diff < r.delay) {
            status = r;
          }
        });
    } else {
      status = this.statusPdp.find((s) => (s.delay === ((diff === 0 && !deadline) ? EntityIcpRecurrence.PRIOR : EntityIcpRecurrence.OUTDATED)))
    }
    //console.log('getPdpStatus:' + diff, status);

    return status ? Object.assign({}, status) : status;
  }
  getIcpLegend() {
    return this.status.map((e) => (e)).sort((a, b) => ((a.delay < b.delay) ? -1 : 1));
  }
  getPdpLegend() {
    const returned = this.statusPdp.map((e) => (e)).sort((a, b) => ((a.delay < b.delay) ? -1 : 1));
    returned.push({ name: this.validPdpText, color: this.validPdpColor, delay: null });
    return returned;
  }
}
