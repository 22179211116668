import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {EntityEditable} from '../models';
import {EntityComponent} from './entity.component';

export class EntityEditableComponent extends EntityComponent {

  entity: EntityEditable;
  constructor(
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }

}
