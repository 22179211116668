import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { Entity } from '../models';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AbstractEntityServiceComponent } from './abstractEntityService.component';

export abstract class AbstractEntityListComponent extends AbstractEntityServiceComponent implements OnInit, OnDestroy {
  entityListSubscriber: Subscription;
  entityList: Entity[] = [];

  rmConfirmMessage: string = 'Êtes-vous sûr de vouloir supprimer cet élément ?';
  addConfirmMessage: string = 'Nouvel élément créé';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }
  protected _init(data) {
    this._updateList(data);
  }
  protected _getAll() {
    return this._entityService.getAll();
  }
  ngOnInit() {
    //TODO: move to construct ?
    this.entityListSubscriber = this._getAll().subscribe((data) => {
      if (data) {
        this._init(data);
      }
    })
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.entityListSubscriber) {
      this.entityListSubscriber.unsubscribe();
    }
  }

  removeElement(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._beforeRemoveElement(entity);
        this._entityService.remove(entity).then((res) => {
          if (res['ok']) {
            entity._deleted = true;
            entity._rev = res['rev'];
            this._afterRemoveElement(entity);
          } else {
            //error message ?
          }
        }).catch((err) => {
          //error message ?
        });
      }
    });
  }

  protected _beforeRemoveElement(entity) {
  }

  protected _afterRemoveElement(entity) {
    this.updateElement(entity);
    /*
    const i = this.entityList.findIndex((e) => (this._cacheCompare(e, entity)));
    if (i !== -1) {
      this.entityList.splice(i, 1);
      this._init(this.entityList);
    }
    */
  }
  updateElement(element: Entity) {
    //if (element && this._updateListElement(element)) {
    //  this._init(this.entityList);
    //}
  }

}
