import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityEditComponent, LoggerService } from 'app/shared';
import { AuditCheckpointsService } from 'app/workbook-core';

@Component({
  selector: 'app-repository-auditCheckpoints-edit',
  templateUrl: './auditCheckpointsEdit.component.html',
  styleUrls: ['../repository.scss']
})

export class AuditCheckpointsEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: AuditCheckpointsService
  ) {
    super(_route, _router, _logger, _entityService);
  }
}
