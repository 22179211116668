import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  normalyzeEmail, normalyzePhone,
  PermissionsService,
  LoggerService,
  DocumentService,
  ExcelService,
  Operator,
  EntityDocumentMetadata,
  ConfirmDialogComponent, ExtraField,
  dateFromFrFormat, formattedDate, comparableString, ExtraConfigService, EntityExtraConfig
} from 'app/shared';
import {
  ExternalCompanyService,
  JobService,
  EntityExternalCompany,
  EntityJob,
  CategoryService
} from 'app/workbook-core';
import {RepositoryOperatorDialogComponent} from '../operator-dialog/operator-dialog.component';
import {AbstractOfficeComponent} from '../abstractOffice.component';


@Component({
  selector: 'app-repository-externalCompany',
  templateUrl: './externalCompany.component.html',
  styleUrls: ['./externalCompany.component.scss']
})

export class ExternalCompanyComponent extends AbstractOfficeComponent implements OnDestroy {

  entity: EntityExternalCompany;
  //internal false pour regexExternal dans contactdialog
  internal: boolean = false;

  jobListSubscriber: Subscription;
  jobList: EntityJob[] = [];
  selectedOperator: Operator;
  displayedOperatorColumns: string[] = ['name', 'job', 'phone', 'email', 'extra', 'delete'];
  operatorDataSource: MatTableDataSource<Operator> = new MatTableDataSource([]);
  @ViewChild('operatorSort') operatorSort: MatSort;

  fileData;

  _extraConfigSubsciption: Subscription;
  extraConfig: EntityExtraConfig;
  constructor(
    protected _extraConfigService: ExtraConfigService,
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: ExternalCompanyService,
    public jobService: JobService

  ) {
    super(_categoryService, _documentService, dialog, snackBar, _permissionService, _logger, _entityService);
    this._extraConfigSubsciption = this._extraConfigService.getSingleOne().subscribe((element) => {
      if (element) {
        this.extraConfig = element;
      }
    })
    this.jobListSubscriber = this.jobService.getAll().subscribe((jobList) => {
      if (jobList) {
        this.jobList = jobList.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.updateOperatorDataSource();
    }
  }

  ngOnDestroy() {
    if (this.jobListSubscriber) {
      this.jobListSubscriber.unsubscribe();
    }
    if (this._extraConfigSubsciption) {
      this._extraConfigSubsciption.unsubscribe();
    }
  }
  _getCreateUserPromise(email, password = '', meta = {}, sendmail = true) {
    return this._permissionService.createExternalUser(email, password, meta, sendmail, ['PP_EEX']);
  }
  protected setOperatorSort() {
    if (this.operatorSort) {
      this.operatorDataSource.sort = this.operatorSort;
    }
  }
  protected updateOperatorDataSource() {
    this.operatorDataSource = new MatTableDataSource((this.entity && this.entity.operators) ? this.entity.operators : []);
    setTimeout(() => {
      this.setOperatorSort();
    });
  }

  selectOperator(operator: Operator = new Operator()) {
    if (this.rightU) {
      this.selectedOperator = operator;
      const dialogRef = this.dialog.open(RepositoryOperatorDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: {
          operator: new Operator(this.selectedOperator)
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          this.upsertOperator(c);
        }
      });
    }
  }
  /**
	 * Create new sub company
	 * @param externalCompanyId
	 */
  upsertOperator(operator): void {
    this.updateSelectedOperator(operator);
    if (!this.entity.operators) {
      this.entity.operators = [];
    }
    const index = this.entity.operators.indexOf(this.selectedOperator);
    if (index === -1) {
      this.entity.operators.push(this.selectedOperator);
    }
    this.updateOperatorDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
  }
  updateSelectedOperator(operator: Operator) {
    this.selectedOperator.user_id = operator.user_id;
    this.selectedOperator.name = operator.name;
    this.selectedOperator.email = operator.email;
    this.selectedOperator.phone = operator.phone;
    this.selectedOperator.job = operator.job;
    //this.selectedOperator.birthday = operator.birthday ? new Date(operator.birthday) : null;
    //this.selectedOperator.habilitation = operator.habilitation;
    //this.selectedOperator.birthplace = operator.birthplace;
    this.selectedOperator.workforce = operator.workforce;
    this.selectedOperator.documents = operator.documents ? operator.documents.map((e) => (new EntityDocumentMetadata(e))) : [];
    this.selectedOperator.isDefault = operator.isDefault;
    this.selectedOperator.extra = Object.assign({}, operator.extra);
  }
  /**
 * Delete sub company
 * @param {string} externalCompanyId
 * @param {number} index
 */
  removeOperator(operator: Operator) {
    if (this.entity && this.entity.operators) {
      const index = this.entity.operators.indexOf(operator);
      if (index > -1) {
        // this.rmOperator(this.externalCompanySelected.id, index);
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cet opérateur ?';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.operators.splice(index, 1);
            this.updateOperatorDataSource();
            if (this.autoSave && this.form.valid) {
              this._save();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }
    }
  }
  parseOperatorImport(list: any[]) {
    if (list && list.length) {
      const _operators: Operator[] = [];
      const attributes = {
        name: 'name',
        job: 'job',
        //habilitation: 'habilitation',
        phone: 'phone',
        email: 'email',
        //birthday: 'birthday',
        //birthplace: 'birthplace',
        workforce: 'workforce',
        isDefault: 'isDefault'
      }
      const attributes_alias = {
        'nom': 'name',
        'nom prenom': 'name',
        'prenom nom': 'name',
        'fonction': 'job',
        'habilitation': 'habilitation',
        'email': 'email',
        'mail': 'email',
        'metier': 'job',
        'phone': 'phone',
        'telephone': 'phone',
        'date de naissance': 'birthday',
        'lieu de naissance': 'birthplace',
        'default': 'isDefault',
        'defaut': 'isDefault'
      }
      if (this.extraConfig && this.extraConfig.operator) {
        this.extraConfig.operator.forEach((xtra) => {
          attributes[xtra.name] = xtra.name;
          attributes_alias[xtra.label] = xtra.name;
        });
      }
      if (list && list[0]) {
        Object.keys(list[0]).forEach((attr) => {
          if (attributes_alias[comparableString(attr)]) {
            attributes[attributes_alias[comparableString(attr)]] = attr;
          }
        });
      }
      list.forEach((element) => {
        if (this.entity.operators.findIndex((e) => (
          e.name === element[attributes.name]
          && (!e.job || e.job === element[attributes.job])
        )
        ) === -1) {
          const _operator = new Operator({
            name: element[attributes.name],
            job: element[attributes.job],
            // habilitation: element[attributes.habilitation],
            phone: !!element[attributes.phone] ? normalyzePhone(element[attributes.phone]) : '',
            email: !!element[attributes.email] ? normalyzeEmail(element[attributes.email]) : '',
            // birthday: !!element[attributes.birthday] ? dateFromFrFormat(element[attributes.birthday]) : null,
            // birthplace: element[attributes.birthplace] ? element[attributes.birthplace] : '',
            workforce: element[attributes.workforce] ? element[attributes.workforce] : 1,
            company: this.entity.name,
            company_id: this.entity._id,
            company_repo_id: this.entity.repo_id,
            isDefault: !!element[attributes.isDefault],
            extra: {}
          });
          if (this.extraConfig && this.extraConfig.operator) {
            this.extraConfig.operator.forEach((xtra) => {
              const attribute_key = Object.keys(attributes).find((k) => (
                k === xtra.name
                ||
                comparableString(k) === comparableString(xtra.name)
                ||
                k === xtra.label
                ||
                comparableString(k) === comparableString(xtra.label)
              ));
              const element_key = Object.keys(element).find((k) => (
                k === xtra.name
                ||
                comparableString(k) === comparableString(xtra.name)
                ||
                k === xtra.label
                ||
                comparableString(k) === comparableString(xtra.label)
              ));
              if (!!attribute_key && !!element_key && !!element[element_key]) {
                if (xtra.type === ExtraField.TYPE_DATE || xtra.type === ExtraField.TYPE_DATEVALID) {
                  _operator.extra[xtra.name] = dateFromFrFormat(element[element_key]);
                } else if (xtra.type === ExtraField.TYPE_RADIO) {
                  const values = ('' + (xtra.options[ExtraField.OPTION_VALUES] ? xtra.options[ExtraField.OPTION_VALUES] : []))
                    .split('|');
                  _operator.extra[xtra.name] = (!!element[element_key] && values.indexOf(element[element_key]) !== -1)
                    ? element[element_key]
                    : (xtra.options[ExtraField.OPTION_DEFAULT] ? xtra.options[ExtraField.OPTION_DEFAULT] : '')
                } else if (xtra.type === ExtraField.TYPE_NUMBER) {
                  _operator.extra[xtra.name] = Number((xtra.options[ExtraField.OPTION_DEFAULT] ? xtra.options[ExtraField.OPTION_DEFAULT] : 0));
                } else {
                  _operator.extra[xtra.name] = element[element_key] ? element[element_key] : (xtra.options[ExtraField.OPTION_DEFAULT] ? xtra.options[ExtraField.OPTION_DEFAULT] : '');
                }
              }
            });
          }
          _operators.push(_operator);
        }
      });
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '1600px',
      });
      dialogRef.componentInstance.confirmMessage = '<p>Importation</p>';
      if (_operators.length) {
        dialogRef.componentInstance.confirmMessage += '<p>Vous allez importer les opérateurs suivants :</p><ul>';
        _operators.forEach((c) => {
          dialogRef.componentInstance.confirmMessage += '<li>'
            + (c.name ? c.name : ((c.workforce) ? (c.workforce) : ''))
            + (c.job ? (' - ' + c.job) : '')
            + ' (' + c.email + ')'
            + (c.phone ? (' - ' + c.phone) : '')
            + Object.keys(c.extra).map((k) => (c.extra[k] ? ('<br>- ' + k + ' : ' + ((c.extra[k] instanceof Date) ? formattedDate(new Date(c.extra[k])) : c.extra[k])) : ''))
            + '</li>';
        });
        dialogRef.componentInstance.confirmMessage += '</ul>';
      } else {
        dialogRef.componentInstance.confirmMessage += '<p>Aucun opérateur à importer (0/' + list.length + ')</p><ul>';
      }
      dialogRef.componentInstance.confirmMessage += '<p>Souhaitez vous procéder à l\'importation ?</p>';
      dialogRef.componentInstance.cancelMessage = 'Non';
      dialogRef.componentInstance.buttonMessage = 'Oui';
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          _operators.forEach((op) => {
            this.entity.operators.push(op);
          });
          this._save().then((e) => {
          });
          this.updateOperatorDataSource();
        }
      });
    }
  }


  operatorChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        this.parseOperatorImport(list);
      }).catch((err) => {
        console.error(err);
      });

      if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
        fileInput.srcElement.value = '';
      }
    }
  }
}
