import {MatDialog} from '@angular/material';
import {LoggerService} from '../logger';
import {getBase64, promiseAll} from '../utils';
import {EntitySecureService} from '../services';
import {DocumentService} from '../document';
import {EntityEditable, EntityDocumentMetadata} from '../models';
import {EntityFormComponent} from './entityForm.component';

export class EntityEditableFormComponent extends EntityFormComponent {
  entity: EntityEditable;
  selectableDocuments: {[type: string]: EntityDocumentMetadata[]} = {};
  withSelectableDocuments: boolean = false;
  documentsSelected: EntityDocumentMetadata[] = [];
  documentsInline: EntityDocumentMetadata[] = [];
  documentInline: {[data_id: string]: string} = {};
  documentInlineLoading: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(dialog, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (data) {
      this.refreshSelectableDocuments();
    }
  }

  //implémentation spécifique, par defaut pas de documents
  refreshSelectableDocuments() {
    this.selectableDocuments = {};
    this.withSelectableDocuments = false;
    this.documentsSelected = this.entity ? this.entity.documents.map((e) => e) : [];
    this.documentsInline = this.entity ? this.entity.getInlineDocuments() : [];
    this.loadInlineDocuments();
  }

  loadInlineDocuments() {
    if (!this.documentInlineLoading && this.documentsInline && this.documentsInline.length) {
      const _that = this;
      this.documentInlineLoading = true;
      promiseAll(
        this.documentsInline.map((d) => {
          return _that._documentService.documentFileService.getAttachmentFile(d.data_id, d.displayName).then((d_data) => {
            getBase64(d_data).then((b64) => {
              _that.documentInline[d.data_id] = b64.toString();
            });
          });
        })
      ).finally(() => {
        _that.documentInlineLoading = false;
      });
    }

  }

  getDocumentAbsoluteUrl(wb_id: string, id: string) {
    return window.location.origin + '/document/' + wb_id + '/' + id;
  }
  getEditableDocumentAbsoluteUrl(editable_id: string, id: string, documentType: string = 'checkpoint') {
    return window.location.origin + '/' + documentType + '/' + editable_id + '/' + id;
  }
}
