import {ElementRef, Inject, ViewChild, AfterViewInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Observable, Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef, MatTableDataSource, MatSelectionListChange} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import * as JSZip from 'jszip';
import {promiseAll/*, saveToPdf*/} from '../utils';
import {ErrorDialogComponent} from './error';
import {formattedDate} from '../utils';
import {EmailService, EmailDatasModel, EmailRecipient} from '../email';
import {LoggerService} from '../logger';
import {PrintingService} from '../print';
import {EmailMessagesService, EntitySecureService} from '../services';
import {DocumentMetadataDialogComponent} from './document'
import {DocumentService} from '../document';
import {EntityEditable, EditableStatus, EntityEmailMessages, EntityDocumentMetadata} from '../models';
import {EntityEditDialogComponent} from './entityEditDialog.component';
import {EntityEditableFormComponent} from './entityEditableForm.component';
import {DownloadSignableComponent} from './downloadSignable';

export abstract class EntityEditableDialogComponent extends EntityEditDialogComponent implements AfterViewInit {
  entityForm: EntityEditableFormComponent;
  @ViewChild('printContainer') printEl: ElementRef;
  name: string = 'export';
  entity: EntityEditable;
  emailDatas: EmailDatasModel = new EmailDatasModel();
  public usersList: EmailRecipient[] = [];
  public recipientSelectedValue: EmailRecipient[] = [];

  emailMessages: EntityEmailMessages;
  protected _emailMessagesSubscription: Subscription;

  from_name: string;
  from_names: string[] = [];
  reply_email: string;
  reply_emails: string[] = [];

  displayedDocumentColumns: string[] = ['icon', 'name', 'categoryPicto', 'category', 'date', 'inline', 'delete'];
  documentDataSource: MatTableDataSource<EntityDocumentMetadata> = new MatTableDataSource([]);
  selectDocuments: boolean = false;

  file_mimes: string[] = ['image/png', 'image/jpeg', 'image/gif'];

  constructor(
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EntityEditableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        this.entity = data['entity'];
        this.getEmailRecipientList(this.entity);
        this._emailMessagesSubscription = this._emailMessagesService.getSingleOne().subscribe((emailMessages) => {
          if (emailMessages) {
            this.emailMessages = emailMessages;
            setTimeout(() => {
              this.from_names = [this.emailMessages.fromName, this._entityService.getUserName()];
              this.from_name = (this.emailMessages.defaultSenderUser && this.from_names.length > 1) ? this.from_names[1] : this.emailMessages.fromName;
              this.reply_emails = [this.emailMessages.fromEmail, this._entityService.getUserEmail()];
              this.reply_email = (this.emailMessages.defaultReplyUser && this.reply_emails.length > 1) ? this.reply_emails[1] : this.emailMessages.fromEmail;
              this.emailDatas.subject = this.getEmailSubject();
              this.emailDatas.signature = this.emailMessages.signature;
              this.emailDatas.rgpdMail = this.emailMessages.rgpdMail;
              this.emailDatas.dpoName = this.emailMessages.dpoName;
              this.emailDatas.dpoMail = this.emailMessages.dpoMail;
            })
          }
        });
      }
    }
  }
  /*
    ngAfterViewInit() {
      super.ngAfterViewInit();
      setTimeout(() => {this.refreshDocumentDatasource();});
    }
  */

  selectAllDocuments(docList: EntityDocumentMetadata[]) {
    if (docList && docList.length && this.entityForm && this.entityForm.documentsSelected) {
      docList.forEach((d) => {
        if (this.entityForm.entity.documents.findIndex((e) => (e._id === d._id)) === -1) {
          this.entityForm.entity.documents.push(d);
        }
      });
    }
    this.save();
  }
  unselectAllDocuments(docList: EntityDocumentMetadata[]) {
    if (docList && docList.length && this.entityForm && this.entityForm.documentsSelected) {
      docList.forEach((d) => {
        const i = this.entityForm.entity.documents.findIndex((e) => (e._id === d._id))
        if (i !== -1) {
          this.entityForm.entity.documents.splice(i, 1);
        }
      });
    }
    this.save();
  }

  isSelectAllDocuments(docList: EntityDocumentMetadata[]) {
    return (docList && docList.length && this.entityForm && this.entityForm.documentsSelected
      && docList.filter((d) => (this.entityForm.documentsSelected.findIndex((e) => (e._id === d._id)) === -1)).length !== 0);
  }


  refreshDocumentDatasource() {
    if (this.entityForm && this.entityForm.entity) {
      this.documentDataSource = new MatTableDataSource(this.entityForm.entity.documents);
      if (this.entityForm.entity.documents.length === 0 && this.entityForm.entity.status === EditableStatus.DRAFT) {
        this.selectDocuments = true;
      } if (this.entityForm.entity.status !== EditableStatus.DRAFT) {
        this.selectDocuments = false;
      }
    }
  }

  dropDocument(event: CdkDragDrop<EntityDocumentMetadata[]>) {
    if (this.entityForm && this.entityForm.entity) {
      const prevIndex = this.entityForm.entity.documents.findIndex((d) => (d._id && event.item && event.item.data && event.item.data._id && d._id === event.item.data._id));
      if (event.previousContainer === event.container && prevIndex !== event.currentIndex) {
        //console.log('before', this.entityForm.entity.documents.map(e => e._id));
        moveItemInArray(this.entityForm.entity.documents, prevIndex, event.currentIndex);
        //console.log('after', this.entityForm.entity.documents.map(e => e._id));
        this.save();
        //this.documentTable.renderRows();
        this.refreshDocumentDatasource();

      }
    }
  }

  onDocumentsChange(event: MatSelectionListChange) {
    if (event.option && event.option.value && this.entityForm && this.entityForm.entity && this.entityForm.documentsSelected) {
      if (event.option && event.option.selected) {
        this.entityForm.entity.documents.push(event.option.value);
      } else {
        const i = this.entityForm.entity.documents.findIndex((e) => (e._id === event.option.value['_id']));
        if (i !== -1) {
          this.entityForm.entity.documents.splice(i, 1);
        }
      }
      this.save();
    }
  }

  removeDocument(document: EntityDocumentMetadata) {
    if (this.entityForm && this.entityForm.entity) {
      const i = this.entityForm.entity.documents.findIndex((e) => (e._id === document._id));
      if (i !== -1) {
        this.entityForm.entity.documents.splice(i, 1);
        this.save();
        this.refreshDocumentDatasource();
      }
    }
  }
  isImage(document: EntityDocumentMetadata) {
    return this.file_mimes.indexOf(document.mime) !== -1;
  }

  abstract getEmailRecipientList(entity: EntityEditable);

  abstract getEmailSubject(): string;

  getEmailSignature(): string {
    return 'Cordialement,\n';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._emailMessagesSubscription) {
      this._emailMessagesSubscription.unsubscribe();
    }
  }

  _updateEntityFromForm() {
    if (this.entity && this.entityForm && this.entityForm.entity) {
      this.entity.copyFrom(this.entityForm.entity);
    }
  }

  _afterSave(res) {
    this._updateEntityFromForm();
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.refreshSelectableDocuments();
    }
    this.refreshDocumentDatasource();
  }

  onExportChange() {
    this._updateEntityFromForm();
  }

  public validate(updateContent: boolean = true): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = EditableStatus.VALIDATED;
      if (updateContent) {
        setTimeout(() => {this.validateEditable();});
      } else {
        this.save();
      }
    }
  }
  public validateEditable(): void {
    if (this.entityForm && this.entityForm.entity && this.printEl && this.printEl.nativeElement) {
      this.entityForm.entity.validate(this.printEl.nativeElement.innerHTML);
      this.save();
    }
  }

  public updateContent(): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = EditableStatus.DRAFT;
      setTimeout(() => {
        this.invalidateEditable();
        this.validate();
      });
    }
  }

  public invalidate(): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = EditableStatus.DRAFT;
      setTimeout(() => {this.invalidateEditable();});
    }
  }

  public invalidateEditable(): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.content = '';
      this.save();
    }
  }
  /*
  saveToPdf() {
    saveToPdf(this.printEl.nativeElement.innerHTML, (this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? this.entityForm.entity.name : 'document');
  }
  */
  printPopup() {
    let d = window.location.origin;
    var mywindow = window.open('about:blank', '_blank', 'height=600px,width=800px');
    mywindow.document.write('<html><head><title>' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? this.entityForm.entity.name : '') + '</title>');
    mywindow.document.write('<link rel="stylesheet" href="' + d + '/stylesheets/pdf.css" type="text/css" />');
    mywindow.document.write('</head><body ><div class="pdpView">');
    mywindow.document.write(this.printEl.nativeElement.innerHTML);
    mywindow.document.write('</div></body></html>');
    var img = mywindow.document.createElement("img");
    img.onload = function () {
      setTimeout(function () {mywindow.print(); mywindow.close();}, 1000);
    }
    img.src = "/assets/images/trans.png";
    mywindow.document.body.appendChild(img);
    mywindow.focus();
    return true;
  }
  public exportPdf(): void {
    this._spinnerService.show();
    this._printingService.generatePdf(this.printEl).subscribe(
      (res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL);
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('EntityExportableDialogComponent', 'Export pdf error', err);
        this._spinnerService.hide();
        this._displayError('Une erreur s\'est produite lors de l\'export PDF, veuillez réessayer et sinon contacter le support.');
      }
    );
  }

  protected _displayError(msg: string) {
    this._spinnerService.hide();
    const dialogRefErrDelete: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
      disableClose: false,
      autoFocus: true,
      width: '600px',
    });
    dialogRefErrDelete.componentInstance.errorMessage = msg;
    dialogRefErrDelete.componentInstance.buttonMessage = 'Fermer';
  }

  protected _getDocument(jszip: JSZip, pdfObs: Observable<any> = null): Observable<JSZip> {
    return new Observable((observer) => {
      (pdfObs ? pdfObs : this._printingService.generatePdf(this.printEl)).subscribe(
        (res) => {
          observer.next(jszip.file(this.getFileName() + '.pdf', res));
        },
        (err) => {
          this._logger.error('EntityEditableDialog', 'Export pdf error', err);
          this._displayError('Une erreur s\'est produite lors de la génération du PDF, veuillez réessayer et sinon contacter le support.');
        }, () => {
          observer.complete();
        });
    });
  }

  _getCompleteZip(pdfObs: Observable<any> = null) {
    return new Promise((resolve, reject) => {
      const that = this;
      const jsZip = new JSZip();
      JSZip.support.nodebuffer = false;

      this._getDocument(jsZip, pdfObs).subscribe((documentInZip: JSZip) => {
        that._getAllAnnexes(documentInZip, 'annexes').subscribe((annexesInZip: JSZip) => {
          annexesInZip.generateAsync({type: 'blob'}).then((content) => {
            resolve(content);
          },
            (err) => {
              reject(err);
            });
        },
          (err) => {
            reject(err);
          });
      },
        (err) => {
          reject(err);
        });
    });
  }

  public downloadAllDocuments(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;
    this._getDocument(jsZip).subscribe((documentInZip: JSZip) => {
      this._getAllAnnexes(documentInZip, 'annexes').subscribe((annexesInZip: JSZip) => {
        annexesInZip.generateAsync({type: 'blob'}).then(function (content) {
          that._spinnerService.hide();
          const url = window.URL.createObjectURL(content);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = that.getFileName() + '.zip';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        });
      });
    });
  }

  public downloadAllAnnexes(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;
    this._getAllAnnexes(jsZip).subscribe((annexesInZip: JSZip) => {
      annexesInZip.generateAsync({type: 'blob'}).then(function (content) {
        that._spinnerService.hide();
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = that.getFileName() + '.zip';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    });
  }

  protected _getAnnexe(jszip: JSZip, doc: EntityDocumentMetadata, folder: string = ''): Promise<JSZip> {
    return new Promise((resolve, reject) => {
      this._documentService.getAttachmentFile(doc.data_id, doc.mime, doc.displayName).subscribe(
        (res) => {
          resolve(folder ? jszip.folder(folder).file(doc.name + '.' + doc.ext, res) : jszip.file(doc.name + '.' + doc.ext, res));
        },
        (err) => {
          this._logger.error('EntityEditableDialog', 'Export pdf error', err);
          this._displayError('Une erreur s\'est produite lors du téléchargement du document' + (doc && doc.name) ? ' ' + doc.name : '' + ', veuillez réessayer et sinon contacter le support.');
          reject(err);
        });
    });
  }


  protected _getAllAnnexes(jszip: JSZip, folder: string = ''): Observable<JSZip> {
    return new Observable((subscriber) => {
      if (this.entityForm && this.entityForm.entity) {
        let i = 0;
        let x = 0;
        this.entityForm.entity.documents.forEach((doc) => {
          x++;
          setTimeout(() => {
            this._getAnnexe(jszip, doc, folder).then(() => {
              i++;
              if (i === this.entityForm.entity.documents.length) {
                subscriber.next(jszip);
                subscriber.complete();
              }
            }).catch((err) => {
              i--;
              if (i === this.entityForm.entity.documents.length) {
                subscriber.next(jszip);
                subscriber.complete();
              }
            });
          }, (x * 1000));
        });
      }
    });
  }

  protected _getAllAnnexes0(jszip: JSZip, folder: string = ''): Observable<JSZip> {
    const promises = (this.entityForm && this.entityForm.entity) ? this.entityForm.entity.documents.map((doc) => (this._getAnnexe(jszip, doc, folder))) : [];
    return new Observable((subscriber) => {
      promiseAll(promises).then(() => {
        subscriber.next(jszip);
        subscriber.complete();
      });
    });
  }

  getUserContactEmail() {
    return '';
  }

  protected _sendEmail(text: string = '', files = null, file_name = '') {
    this.recipientSelectedValue.forEach((recipient) => {
      const emailDatas = new EmailDatasModel();
      emailDatas.date = new Date();
      emailDatas.subject = this.emailDatas.subject;
      emailDatas.text = text ? text : (this.emailDatas.text + (recipient.user_id ? ('\n\n' + this.emailDatas.usertext) : ''));
      emailDatas.signature = this.emailDatas.signature;
      emailDatas.rgpdMail = this.emailDatas.rgpdMail;
      emailDatas.dpoName = this.emailDatas.dpoName;
      emailDatas.dpoMail = this.emailDatas.dpoMail;
      emailDatas.email = recipient.email;
      emailDatas.to_name = recipient.name;
      emailDatas.from_name = this.from_name ? this.from_name : this.emailMessages.fromName;
      emailDatas.reply_to = this.reply_email ? this.reply_email : this.getUserContactEmail();
      //if (this.emailMessages.fromEmail) {
      //  emailDatas.from_email = this.emailMessages.fromEmail;
      //}
      const file = (recipient.zip && files && files[1])
        ? files[1]
        : ((recipient.pdf && files && files[0]) ? files[0] : null);
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const that = this;
        reader.onloadend = function () {
          that._emailService.sendEmail(emailDatas, '', reader.result, file_name + (recipient.zip ? '.zip' : '.pdf')).then((response) => {
            //that._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
            if (response.status === 200) {
              that.snackBar.open('Le message a été envoyé', '', {
                duration: 2000
              });
              that.recipientSelectedValue = [];
              that.emailDatas.subject = 'Plan de prévention ' + that.getFileName();
              that.emailDatas.text = '';
              that.emailDatas.usertext = '';
              // that.emailDatas.signature = 'Cordialement,<br>'
              //   + that._entityService.getUserName() + '<br>'
              //   + that._emailService.getCompanyName();
              //that.emailDatas.signature = 'Cordialement,\n';
            }
            that._spinnerService.hide();
          }, (err) => {
            that._spinnerService.hide();
            if (err && err.status && err.status === '412' && err.msg && err.msg.indexOf('Connection timeout') === -1) {
              that.snackBar.open('Erreur', 'Erreur dans l\'adresse du destinataire, le message n\'a pas pu être envoyé', {
                duration: 2000
              });
              that._logger.error('EmailService', 'Error 412 : Unable to send email', JSON.stringify(err));
            } else if (err && err.status && err.status === '426') {
              that.snackBar.open('Erreur', 'Pièce jointe trop volumineuse, le message n\'a pas pu être envoyé', {
                duration: 2000
              });
              that._logger.error('EmailService', 'Error 426 : Unable to send email', JSON.stringify(err));
            } else {
              that._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
              that.snackBar.open('Erreur', 'Le message n\'a pas pu être envoyé', {
                duration: 2000
              });
            }
            //this._snackBar('Erreur d\'envoi de la notification, contactez l\'administrateur');
          });
        };
      } else {
        this._emailService.sendEmail(emailDatas).then((response) => {
          //that._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
          if (response.status === 200) {
            this.snackBar.open('Le message a été envoyé', '', {
              duration: 2000
            });
            this.recipientSelectedValue = [];
            this.emailDatas.subject = 'Plan de prévention ' + this.getFileName();
            this.emailDatas.text = '';
            this.emailDatas.usertext = '';
            //this.emailDatas.signature = 'Cordialement,\n';
            // this.emailDatas.signature = 'Cordialement,<br>'
            //   + this._entityService.getUserName() + '<br>'
            //   + this._emailService.getCompanyName();
          }
          this._spinnerService.hide();
        }, (err) => {
          this._spinnerService.hide();
          if (err && err.status && err.status === '412' && err.msg && err.msg.indexOf('Connection timeout') === -1) {
            this.snackBar.open('Erreur', 'Erreur dans l\'adresse du destinataire, le message n\'a pas pu être envoyé', {
              duration: 2000
            });
            this._logger.error('EmailService', 'Error 412 : Unable to send email', JSON.stringify(err));
          } else if (err && err.status && err.status === '426') {
            this.snackBar.open('Erreur', 'Pièce jointe trop volumineuse, le message n\'a pas pu être envoyé', {
              duration: 2000
            });
            this._logger.error('EmailService', 'Error 426 : Unable to send email', JSON.stringify(err));
          } else {
            this._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
            this.snackBar.open('Erreur', 'Le message n\'a pas pu être envoyé', {
              duration: 2000
            });
          }
          //this._snackBar('Erreur d\'envoi de la notification, contactez l\'administrateur');
        })
      }
    })
  }

  /**
   * Format the date for export
   * @param d
   */
  public formattedDate(d: Date = new Date) {
    return formattedDate(d);
  }

  public sendEmail(text: string = '') {
    if (this.recipientSelectedValue && this.recipientSelectedValue.length && this.emailDatas) {
      this._spinnerService.show();
      let pdf = false;
      let zip = false;
      this.recipientSelectedValue.forEach((e) => {
        pdf = pdf || e.pdf;
        zip = zip || e.zip;
      });
      if (pdf || zip) {
        const pdfObs = this._printingService.generatePdf(this.printEl);
        const attachmentPromises: Promise<any>[] = [pdfObs.toPromise()];
        if (zip) {
          attachmentPromises.push(this._getCompleteZip(pdfObs));
        }
        promiseAll(attachmentPromises).then(
          (res) => {
            this._sendEmail(text, res, this.getFileName());
            //this._spinnerService.hide();
          },
          (err) => {
            this._logger.error('EntityEditableDialogComponent', 'Prepare attachment error', JSON.stringify(err));
            this._spinnerService.hide();
            this._displayError('Une erreur s\'est produite lors de la création de la pièce jointe, veuillez réessayer et sinon contacter le support.');
          }
        );
      } else {
        this._sendEmail(text);
        //this._spinnerService.hide();
      }
    }
  }

  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }

  /**
   * Open dialog to edit document metadata
   */
  public openDocumentDialog(doc: EntityDocumentMetadata) {
    this.dialog.open(DocumentMetadataDialogComponent, {
      disableClose: true,
      minWidth: '900px',
      maxHeight: '90%',
      data: {
        entity: doc
      }
    });
  }
  getFileName() {
    const name = this.entity.getFileName();
    return name ? name : this.name;
  }
  downloadSignable() {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity.documents && this.entityForm.entity.documents.length) {
      const dialogRef = this.dialog.open(DownloadSignableComponent, {
        disableClose: false,
        width: '600px',
        data: {
          documents: this.entityForm.entity.documents,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 'zip') {
            this.downloadAllDocuments();
          } else {
            this.exportPdf();
          }
        }
      });
    } else {
      this.exportPdf();
    }
  }

  setInline(doc_id: string, isIline: boolean = false) {
    if (this.entityForm && this.entityForm.selectableDocuments && this.entityForm.entity && this.entityForm.entity.documents && this.entityForm.entity.documents.length) {
      this.entityForm.refreshSelectableDocuments();
      this.save();
    }
  }
}
