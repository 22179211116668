import * as JSLZString from 'lz-string';
import * as md5 from 'md5';
//import html2pdf from 'html2pdf.js';
import * as SecureLS from 'secure-ls';
// TODO: to be tested for encrypted secret
// const ls = new SecureLS({encodingType: 'rabbit', isCompression: false, encryptionSecret: 's3cr3tPa$$w0rd@879'});
const ls = new SecureLS({encodingType: 'rabbit'});
//import pLimit = require('p-limit');
import * as pLimit from 'p-limit';
import {version} from '../../../../package.json';

export function getChecksum(string) {
  return md5(string);
}
export async function promisesWithDelay(promises: Promise<any>[], delay: number) {
  const results = [];
  for (const promise of promises) {
    await promise.then((res) => {results.push(res);});
    await new Promise(resolve => setTimeout(resolve, delay));
  }
  return results;
}
export function promiseAll(promises: Promise<any>[], limit: number = 5): Promise<any> {
  if (promises.length > limit) {
    const _limit = pLimit(limit);
    return Promise.all(promises.map((p) => (_limit(() => (p)))));
  } else {
    return Promise.all(promises);
  }
}
export function getDifferenceInDays(date1: Date = new Date(), date2: Date = new Date()) {
  const diffInMs = Math.abs(new Date(date2).getTime() - new Date(date1).getTime());
  return diffInMs / (1000 * 60 * 60 * 24);
}
export function getDifferenceInMinutes(date1: Date = new Date(), date2: Date = new Date()) {
  const diffInMs = Math.abs(new Date(date2).getTime() - new Date(date1).getTime());
  return diffInMs / (1000 * 60);
}

export function getWeek(d: Date = new Date): number {
  var onejan = new Date(d.getFullYear(), 0, 1);
  var today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  var dayOfYear = ((today.getTime() - onejan.getTime() + 86400000) / 86400000);
  return Math.ceil(dayOfYear / 7);
  /*
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    return weekNo;
    */
};
/**
 * Get date and return date in string format dd/mm/yyyy
 * @param {Date} d
 * @returns {string}
 */
export function formattedDate(d: Date = new Date, showHours = false, showSeconds = false, formatUS: boolean = false): string {
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  let hour = String(d.getHours());
  let minute = String(d.getMinutes());
  let second = String(d.getSeconds());
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return (formatUS ? `${year}-${month}-${day}` : `${day}/${month}/${year}`) + (showHours ? ` ${hour}:${minute}` + (showSeconds ? `:${second}` : '') : '');
}
export function dateFromFrFormat(date_fr: string) {
  if (date_fr && Object.prototype.toString.call(date_fr) === "[object String]") {
    const a = date_fr.split('/');
    if (a.length === 3 && a[0].length === 2 && a[1].length === 2 && a[2].length === 4) {
      return new Date(a[2] + '-' + a[1] + '-' + a[0]);
    }
  } else if (typeof date_fr === 'number') {
    //format excel = nb jours depuis 01/01/1900
    const d = new Date('1899-12-31');
    d.setDate(d.getDate() + date_fr - (date_fr < 60 ? 0 : 1));
    return d;
  }
  return new Date(date_fr);
}
/**
 * Get date and return time in string format HH:mm
 * @param {Date} d
 * @returns {string}
 */
export function formattedTime(d): string {
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  const hours = String(d.getHours() + 1);
  const minutes = String(d.getMinutes());
  return `${addZero(hours, 2)}:${addZero(minutes, 2)}`;
}

/**
 * Get date and return date in string format yyyymmdd
 * @param {Date} d
 * @returns {string}
 */
export function customFormattedDate(d = new Date): string {
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return `${year}${month}${day}`;
}

export function formattedDateFile(d = new Date): string {
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  let year = String(d.getFullYear());
  year = year.substring(year.length - 2);
  const hours = String(d.getHours() + 1);
  const minutes = String(d.getMinutes());

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return `${year}${month}${day}${addZero(hours, 2)}${addZero(minutes, 2)}`;
}
export function formattedDateCode(d = new Date): string {
  if (!d) {
    return '';
  }
  if (typeof d === 'string') {
    d = new Date(d);
  }
  let week = String(getWeek(d));
  let year = String(d.getFullYear());
  year = year.substring(year.length - 2);
  if (week.length < 2) {
    week = '0' + week;
  }
  return `${year}${week}`;
}

/**
 * Convert blob format to file format
 * @param {Blob} blob
 * @param {string} filename
 * @returns {Blob}
 */
export function b64toFile(b64Data: string, contentType: string, fileName: string): File {
  return blobtoFile(b64toBlob(b64Data, contentType), fileName);
}
/**
 * Convert blob format to file format
 * @param {Blob} blob
 * @param {string} filename
 * @returns {Blob}
 */
export function blobtoFile(blob: Blob, fileName: string): File {
  var b: any = blob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b['lastModifiedDate'] = new Date();
  b['name'] = fileName;
  //Cast to a File() type
  return <File> blob;
}

/**
 * Convert base64 format to blob format
 * @param {string} b64Data
 * @param {string} contentType
 * @returns {Blob}
 */
export function b64toBlob(b64Data: string, contentType: string): Blob {
  contentType = contentType || '';
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}
export function base64ToArrayBuffer(base64): Uint8Array {
  let binary_string = window.atob(unescape(encodeURIComponent(base64)));
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}
export function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type: mime});
}
/**
 * Convert File to base64 format
 * @param file
 * @returns {Promise<any>}
 */
export function getBase64(file): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export function getBase64FromUrl(url: string): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(xhr.response);
      reader.onerror = error => reject(error);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

/**
 * Sort an array by desc or asc
 * @param {Array<any>} array
 * @param {string} order
 */
export function sortArray(array: Array<any>, order: string = 'asc') {
  if (order === 'asc') {
    array.sort(function (a, b) {
      return new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime()
    });
  } else {
    array.sort(function (a, b) {
      return new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
    });
  }
}

export function ucFirst(lower: string): string {
  return lower.replace(/^\w/, c => c.toUpperCase());
}

export function sanitizeString(val: string): string {
  let dict = {'’': '\'', 'œ': 'oe'};
  let re = new RegExp(`[${Object.keys(dict).join('')}]`, 'g');
  return val.replace(re, (m => dict[m]));
  //return Buffer.from(val.replace(re, (m => dict[m])), 'utf-8').toString();
}

export function normalyzeFilename(name: string) {
  return name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}

export function normalyzePhone(phone: string) {
  let returned = (typeof phone === "string")
    ? phone
      .split(' ').join('')
      .split('.').join('')
      .split(',').join('')
      .split('-').join('')
      .split('/').join('')
      .split('(0)').join('')
      .split('+33').join('')
      .split('(').join('')
      .split(')').join('')
    : '' + phone;
  if (returned && returned.length === 9) {
    returned = '0' + returned;
  }
  return returned;
}
export function normalyzeEmail(email: string) {
  let returned = (typeof email === "string")
    ? email
      .split(' ').join('')
      .split(',').join('')

    : '';
  return returned.toLowerCase();
}
/**
 * add 0 to have the good digit
 * @param x
 * @param n
 * @returns {any}
 * @private
 */
export function addZero(x, n) {
  while (x.toString().length < n) {
    x = '0' + x;
  }
  return x;
}


/**
 * get grom local storage
 * @param k {string} : the key
 * @param with_parse {boolean} : set to false to bypass parse
 * @returns {any}
 */
export function storageGet(k: string, with_parse: boolean = false): any {
  return with_parse ? JSON.parse(ls.get(k)) : ls.get(k);
}
/**
 * get grom local storage
 * @param k {string} : the key
 * @param v {any} : the value
 * @param with_stringify {boolean} : set to false to bypass stringify
 */
export function storageSet(k: string, v: any, with_stringify: boolean = false) {
  ls.set(k, with_stringify ? JSON.stringify(v) : v);
}
/**
 * get grom local storage
 * @param k {string} : the key
 */
export function storageRemove(k: string) {
  ls.remove(k);
}

/**
 * Clear the local storage
 */
export function storageAllClear() {
  ls.clear();
}

/**
 * Clear local storage except local config
 */
export function storageClear(all: boolean = false) {
  if (all) {
    localStorage.clear();
  } else {
    ls.getAllKeys().forEach(obj => {
      if (!obj.includes('config-')) {
        ls.remove(obj);
      }
    });
  }
}
export function cacheClear() {
  const dbList = [];
  Object.keys(localStorage).forEach((key) => {
    if (key.lastIndexOf('_pouch_') === 0 && key !== "_pouch_check_localstorage") {
      dbList.push(key);
    }
    localStorage.removeItem(key);
  });
  dbList.forEach((db) => {
    indexedDB.deleteDatabase(db);
  });
  storageClear(true);
}
export function setVersion(): boolean {
  const v = version.split('.');
  if (v && v.length > 1) {
    const actualVersion = v[0] + "_" + v[1];
    const cookie = getCookie('currentVersion');
    if (!cookie || actualVersion !== cookie) {
      setCookie('currentVersion', actualVersion, 365);
      return true;
    }
  }
  return false;
}
export function checkNewVersion(): boolean {
  const v = version.split('.');
  if (v && v.length > 1) {
    const actualVersion = v[0] + "_" + v[1];
    const cookie = getCookie('currentVersion');
    return (!cookie || (actualVersion !== cookie));
  }
  return false;
}
export function getCookie(name: string) {
  let ca: Array<string> = document.cookie.split(';');
  let caLen: number = ca.length;
  let cookieName = `${name}=`;
  let c: string;

  for (let i: number = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) == 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
}

export function setCookie(name: string, value: string, expireDays: number, path: string = '/') {
  let d: Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires: string = `expires=${d.toUTCString()}`;
  let cpath: string = path ? `; path=${path}` : '';
  document.cookie = `${name}=${value}; ${expires}${cpath}` + (location.protocol === 'https:' ? "; secure" : "");
}

export const isDate = d => d instanceof Date;

export const isEmpty = o => Object.keys(o).length === 0;

export const isObject = o => o != null && typeof o === 'object';

export const properObject = o => isObject(o) && !o.hasOwnProperty ? {...o} : o;

export function lz_compress(data: string) {
  return JSLZString.compressToUTF16(data);
}
export function replaceArray(data: string, from: string[], to: string[]) {
  for (var i = 0; i < from.length; i++) {
    //let x = data.indexOf(from[i]);
    data = data.replace(from[i], to[i]);
    //let y = data.indexOf(to[i]);
  }
  return data;
}

export function lz_decompress(data: string) {
  return JSLZString.decompressFromUTF16(data);
  /*
    let decompressed = JSLZString.decompressFromUTF16(data);
    //hack pour decompresser si bug compression (safari/iPad ?)
    if (data && !decompressed) {
      let from = [
        '\u0FA1\u0FB7',
        '\u0F90\u0FB5',
        '\u0917\u093C',
        '\u092F\u093C',
        '\u0A17\u0A3C',
        '\u09AF\u09BC',
        '\u0300',
        '\u0301',
        '\u038A',
        '\u4A4F;',
        '\u1E74\u0303',
        '\u03CC',
        '\u0A2B\u0A3C',
        '\u0B21\u0B3C',
        '\u0A1B\u0A3C',
        '\u0A38\u0A3C',
        '\u038F',
        '\u0385',
        '\u3008',
        '\u0F71\u0F72',
        '\u0B22\u0B3C',
        '\u0F56\u0FB7',
      ];
      let to= [
        '\u0FA2',
        '\u0FB9',
        '\u095A',
        '\u095F',
        '\u0A5A',
        '\u09DF',
        '\u0340',
        '\u0341',
        '\u1FDB',
        '\u4A4F\u037E',
        '\u0168\u0330',
        '\u1F79',
        '\u0A5E',
        '\u0B5C',
        '\u0A5B',
        '\u0A36',
        '\u1FFB',
        '\u1FEE',
        '\u2329',
        '\u0F73',
        '\u0B5D',
        '\u0F57',

      ];
      decompressed = JSLZString.decompressFromUTF16(decompressed);
    }
    return decompressed;
    */
}

export function saveToPdf(element, name: string = 'document') {
  const opt = {
    pagebreak: {before: '.break-page', avoid: '.avoid'},
    margin: [10, 5],
    filename: name + '.pdf',
    image: {type: 'jpeg', quality: 0.95},
    html2canvas: {
      scale: 1, dpi: 300,
      letterRendering: true,
      useCORS: true
    },
    jsPDF: {unit: 'mm', format: [317, 220], orientation: 'portrait'}
  };
  /*
    // New Promise-based usage:
    html2pdf().from(element).set(opt).toPdf().get('pdf').then(function(pdf) {
      //html2pdf().from(element).set(opt).then((pdf) => {
      var totalPages = pdf.internal.getNumberOfPages();
      const h = pdf.internal.pageSize.getHeight();
      const w = pdf.internal.pageSize.getWidth();
      for (let i = 2; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.setTextColor(0);
        pdf.text(5, h - 3, "Plan de prévention (Décret n°92-158 du 20 février 1992) - " + name);
        pdf.text(w - 15, h - 3, "Page " + i);
        //pdf.addImage("YOUR_IMAGE", 'JPEG', pdf.internal.pageSize.getWidth() - 1.1, pdf.internal.pageSize.getHeight() - 0.25, 1, 0.2);
      }
      pdf.save(name + '.pdf');
    });
    */
}
export function formatBytes(a, b = 1): string {if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}
export function getIcon(mime: string): string {
  let icon = '';
  switch (mime) {
    case 'application/msword':
      icon = 'file-word';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = 'file-word';
      break;
    case 'application/vnd.ms-powerpoint':
      icon = 'file-powerpoint';
      break;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      icon = 'file';
      break;
    case 'application/pdf':
      icon = 'file-pdf';
      break;
    case 'image/png':
      icon = 'file-image';
      break;
    case 'image/gif':
      icon = 'file-image';
      break;
    case 'image/jpg':
      icon = 'file-image';
      break;
    case 'image/jpeg':
      icon = 'file-image';
      break;
    default: // generic icon
      icon = 'file-alt';
      break;
  }
  return icon;
}
export function comparableString(str: string, tolower: boolean = true): string {
  if ("object" == typeof str) {
    return str;
  }
  var accent = [
    /[\300-\306]/g, /[\340-\346]/g, // A, a
    /[\310-\313]/g, /[\350-\353]/g, // E, e
    /[\314-\317]/g, /[\354-\357]/g, // I, i
    /[\322-\330]/g, /[\362-\370]/g, // O, o
    /[\331-\334]/g, /[\371-\374]/g, // U, u
    /[\321]/g, /[\361]/g, // N, n
    /[\307]/g, /[\347]/g, // C, c
  ];
  var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];
  for (var i = 0; i < accent.length; i++) {
    str = str.replace(accent[i], noaccent[i]);
  }
  return (tolower ? str.toLowerCase() : str).trim();
}
export function copyToClipboard(val: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}
export function deepCompare(obj1, obj2, level = 0, ignore = [], maxlevel = 2): boolean {
  /* debut log
if (level === 0) {
  console.log('deepCompare', obj1, obj2);
}
//fin log */
  if (maxlevel > 0 && level > maxlevel) {
    console.log('deepCompare maxlevel (' + level + '>' + maxlevel + ')', obj1, obj2);
    return true;
  }
  level++;
  // Handle the 3 simple types, and null or undefined
  if (null == obj1
    || null == obj2
    || (("object" != typeof obj1) && ("object" != typeof obj2))) {
    return (obj1 == obj2);
    /* debut log
    if (!same) {
      console.log('deepCompare:value difference', obj1, obj2, level);
    }
    //fin log */
    //return same;
  }

  // Handle Date
  if ((obj1 instanceof Date) || (obj2 instanceof Date)) {
    return (new Date(obj1).getTime() === new Date(obj2).getTime());
    /* debut log
    if (!same) {
      console.log('deepCompare:date difference', obj1, obj2, level);
    }
    //fin log */
    //return same;
  }

  // Handle Array
  if ((obj1 instanceof Array) && (obj2 instanceof Array)) {
    let i = 0;
    let same = (obj1.length === obj2.length);
    /* debut log
    if (!same) {
      console.log('deepCompare:array size difference', obj1, obj2, level);
    }
    //fin log */
    //si pas le meme nombre d'element => formarray dirty, on ne traite pas chaque element
    while (same && i < obj1.length) {
      same = deepCompare(obj1[i], obj2[i], level, ignore, maxlevel);
      i++;
    }
    /* debut log
    if (!same) {
      console.log('deepCompare:array[' + i + '] difference', obj1, obj2, level);
    }
    //fin log */
    return same;
  }

  // Handle Object
  if (("object" == typeof obj1) && ("object" == typeof obj2)) {

    for (var attr in obj1) {
      if ((!!obj1.hasOwnProperty(attr) !== !!obj2.hasOwnProperty(attr))
        || (ignore.indexOf(attr) === -1 && !deepCompare(obj1[attr], obj2[attr], level, ignore, maxlevel))) {
        /* debut log
        let test1 = (obj1.hasOwnProperty(attr));
        let test2 = (obj2.hasOwnProperty(attr));
        let test3 = deepCompare(obj1[attr], obj2[attr], level, ignore, maxlevel);
        console.log('deepCompare:attribute difference', obj1, obj2, level, attr, test1, test2, test3);
        //fin log */
        return false;
      }
    }
    return true;
  }
  throw new Error("Unable to compare obj1 and obj2! Its type isn't supported.");
}