import {Component, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  PermissionsService, LoggerService,
  DocumentService, DocumentMetadataService, EntityDocumentMetadata,
  Operator, ExcelService, ConfirmDialogComponent, normalyzeEmail, normalyzePhone,
  dateFromFrFormat, formattedDate, comparableString, ExtraConfigService, EntityExtraConfig
} from 'app/shared';
import {WorkbookExternalCompanyService, EntityExternalCompany, CategoryService, ExternalCompanyService, PreventionPlanService} from 'app/workbook-core';
import {WorkbookOfficeComponent} from '../office/office.component';
import {WorkbookExternalCompanyDialogComponent} from './externalCompany-dialog.component';
import {WorkbookOperatorDialogComponent} from '../operator-dialog';

@Component({
  selector: 'app-workbook-external-company',
  templateUrl: './externalCompany.component.html',
  styleUrls: ['./externalCompany.component.scss']
})

export class WorkbookExternalCompanyComponent extends WorkbookOfficeComponent {
  entity: EntityExternalCompany;
  internal: boolean = false;

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette entreprise ?';


  selectedOperator: Operator;
  displayedOperatorColumns: string[] = ['name', 'job', 'phone', 'email', 'extra', 'actions'];
  operatorDataSource: MatTableDataSource<Operator> = new MatTableDataSource([]);
  @ViewChild('operatorSort') operatorSort: MatSort;

  _extraConfigSubsciption: Subscription;
  extraConfig: EntityExtraConfig;

  constructor(
    protected _extraConfigService: ExtraConfigService,
    protected _excelService: ExcelService,
    protected _preventionPlanService: PreventionPlanService,
    protected _documentMetadataService: DocumentMetadataService,
    protected _officeService: ExternalCompanyService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: WorkbookExternalCompanyService

  ) {
    super(_preventionPlanService, _documentMetadataService, _officeService, _spinnerService, _categoryService, _documentService, dialog, snackBar, _permissionService, _logger, _entityService);
    this._extraConfigSubsciption = this._extraConfigService.getSingleOne().subscribe((element) => {
      if (element) {
        this.extraConfig = element;
      }
    })
  }

  ngOnDestroy() {
    if (this._extraConfigSubsciption) {
      this._extraConfigSubsciption.unsubscribe();
    }
  }
  _init(data) {
    super._init(data);
    if (this.entity) {
      this.updateOperatorDataSource();
    }
  }

  _getCreateUserPromise(email, password = '', meta = {}, sendmail = true) {
    return this._permissionService.createExternalUser(email, password, meta, sendmail, ['PP_EEX']);
  }

  public openDialog() {
    if (this.rightU) {
      const dialogRef = this.dialog.open(WorkbookExternalCompanyDialogComponent, {
        disableClose: true,
        minWidth: '960px',
        data: {
          id: this.entity._id
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
          if (c._rev !== this.entity._rev) {
            this.entity.copyFrom(c);
          }
          this.updateContactDataSource();
          // this.updateSubCompanyDataSource();
          this.onSave.emit(this.entity);
        }
      });
    }
  }
  protected setOperatorSort() {
    if (this.operatorSort) {
      this.operatorDataSource.sort = this.operatorSort;
    }
  }
  protected updateOperatorDataSource() {
    this.operatorDataSource = new MatTableDataSource((this.entity && this.entity.operators) ? this.entity.operators : []);
    setTimeout(() => {
      this.setOperatorSort();
    });
  }
  removeOperator(operator: Operator) {
    if (this.entity && this.entity.operators) {
      const index = this.entity.operators.indexOf(operator);
      if (index > -1) {
        // this.rmOperator(this.externalCompanySelected.id, index);
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cet opérateur ?';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.operators.splice(index, 1);
            this.updateOperatorDataSource();
            this._save().then((e) => {
              this._afterSave(e);
            });
          }
        });
      }

    }
  }
  selectOperator(operator: Operator = new Operator()) {
    if (this.rightU) {
      this.selectedOperator = operator;
      const dialogRef = this.dialog.open(WorkbookOperatorDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: {
          operator: new Operator(this.selectedOperator),
          eex: this.entity
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          c.company = this.entity.name;
          c.company_id = this.entity._id;
          c.company_repo_id = this.entity.repo_id;
          this.upsertOperator(c);
          this._save().then((e) => {
            this._afterSave(e);
          });
          this.onSave.emit(this.entity);
        }
      });
    }
  }
  /**
	 * Create new sub company
	 * @param externalCompanyId
	 */
  upsertOperator(operator): void {
    this.updateSelectedOperator(operator);
    if (!this.entity.operators) {
      this.entity.operators = [];
    }
    const index = this.entity.operators.indexOf(this.selectedOperator);
    if (index === -1) {
      this.entity.operators.push(this.selectedOperator);
    }
    this.updateOperatorDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
  }
  updateSelectedOperator(operator: Operator) {
    this.selectedOperator.company = operator.company;
    this.selectedOperator.company_id = operator.company_id;
    this.selectedOperator.company_repo_id = operator.company_repo_id;
    this.selectedOperator.user_id = operator.user_id;
    this.selectedOperator.name = operator.name;
    this.selectedOperator.email = operator.email;
    this.selectedOperator.phone = operator.phone;
    this.selectedOperator.job = operator.job;
    //this.selectedOperator.habilitation = operator.habilitation;
    //this.selectedOperator.birthday = operator.birthday ? new Date(operator.birthday) : null;
    //this.selectedOperator.birthplace = operator.birthplace;
    this.selectedOperator.workforce = operator.workforce;
    this.selectedOperator.documents = operator.documents ? operator.documents.map((e) => (new EntityDocumentMetadata(e))) : [];
    this.selectedOperator.extra = Object.assign({}, operator.extra);

  }

  parseOperatorImport(list: any[]) {
    if (list && list.length) {
      const _operators: Operator[] = [];
      const attributes = {
        name: 'name',
        job: 'job',
        //habilitation: 'habilitation',
        phone: 'phone',
        email: 'email',
        //birthday: 'birthday',
        //birthplace: 'birthplace',
        workforce: 'workforce'
      }
      const attributes_alias = {
        'nom': 'name',
        'nom prenom': 'name',
        'prenom nom': 'name',
        'fonction': 'job',
        'habilitation': 'habilitation',
        'email': 'email',
        'mail': 'email',
        'metier': 'job',
        'phone': 'phone',
        'telephone': 'phone',
        'date de naissance': 'birthday',
        'lieu de naissance': 'birthplace'
      }
      if (this.extraConfig && this.extraConfig.operator) {
        this.extraConfig.operator.forEach((xtra) => {
          attributes[xtra.name] = xtra.name;
          attributes_alias[xtra.label] = xtra.name;
        });
      }
      if (list && list[0]) {
        Object.keys(list[0]).forEach((attr) => {
          if (attributes_alias[comparableString(attr)]) {
            attributes[attributes_alias[comparableString(attr)]] = attr;
          }
        });
      }
      list.forEach((element) => {
        if (this.entity.operators.findIndex((e) => (
          e.name === element[attributes.name]
          && (!e.job || e.job === element[attributes.job])
        )
        ) === -1) {
          const contact = new Operator({
            name: element[attributes.name],
            job: element[attributes.job],
            // habilitation: element[attributes.habilitation],
            phone: !!element[attributes.phone] ? normalyzePhone(element[attributes.phone]) : '',
            email: !!element[attributes.email] ? normalyzeEmail(element[attributes.email]) : '',
            // birthday: !!element[attributes.birthday] ? dateFromFrFormat(element[attributes.birthday]) : null,
            // birthplace: element[attributes.birthplace] ? element[attributes.birthplace] : '',
            workforce: element[attributes.workforce] ? element[attributes.workforce] : 1,
            company: this.entity.name,
            company_id: this.entity._id,
            company_repo_id: this.entity.repo_id,
            extra: {}
          });
          if (this.extraConfig && this.extraConfig.operator) {
            this.extraConfig.operator.forEach((xtra) => {
              const attribute_key = Object.keys(attributes).find((k) => (
                k === xtra.name
                ||
                comparableString(k) === comparableString(xtra.name)
                ||
                k === xtra.label
                ||
                comparableString(k) === comparableString(xtra.label)
              ));
              const element_key = Object.keys(element).find((k) => (
                k === xtra.name
                ||
                comparableString(k) === comparableString(xtra.name)
                ||
                k === xtra.label
                ||
                comparableString(k) === comparableString(xtra.label)
              ));
              if (!!attribute_key && !!element_key && !!element[element_key]) {
                contact.extra[xtra.name] = (xtra.type === 'date' || xtra.type === 'dateValid') ? dateFromFrFormat(element[element_key]) : element[element_key];
              }
            });
          }
          _operators.push(contact);
        }
      });
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '1600px',
      });
      dialogRef.componentInstance.confirmMessage = '<p>Importation</p>';
      if (_operators.length) {
        dialogRef.componentInstance.confirmMessage += '<p>Vous allez importer les opérateurs suivants :</p><ul>';
        _operators.forEach((c) => {
          dialogRef.componentInstance.confirmMessage += '<li>'
            + (c.name ? c.name : ((c.workforce) ? (c.workforce) : ''))
            + (c.job ? (' - ' + c.job) : '')
            + ' (' + c.email + ')'
            + (c.phone ? (' - ' + c.phone) : '')
            + Object.keys(c.extra).map((k) => (c.extra[k] ? ('<br>- ' + k + ' : ' + ((c.extra[k] instanceof Date) ? formattedDate(new Date(c.extra[k])) : c.extra[k])) : ''))
            + '</li>';
        });
        dialogRef.componentInstance.confirmMessage += '</ul>';
      } else {
        dialogRef.componentInstance.confirmMessage += '<p>Aucun opérateur à importer (0/' + list.length + ')</p><ul>';
      }
      dialogRef.componentInstance.confirmMessage += '<p>Souhaitez vous procéder à l\'importation ?</p>';
      dialogRef.componentInstance.cancelMessage = 'Non';
      dialogRef.componentInstance.buttonMessage = 'Oui';
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          _operators.forEach((op) => {
            this.entity.operators.push(op);
          });
          this._save().then((e) => {
          });
          this.updateOperatorDataSource();
        }
      });
    }
  }

  operatorChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        this.parseOperatorImport(list);
      }).catch((err) => {
        console.error(err);
      });

      if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
        fileInput.srcElement.value = '';
      }
    }
  }

  addRepoOperator(operator: Operator) {
    if (this.entity && this.entity.repo_id) {
      this._spinnerService.show();
      if (this._officeService) {
        this._officeService.getCurrentOne(this.entity.repo_id).then((o) => {
          if (!o.operators) {
            o.operators = [];
          }
          const newOperator: Operator = new Operator({
            user_id: operator.user_id,
            contact_id: operator.contact_id,
            company_id: operator.company_id,
            company: o.name,
            company_repo_id: this.entity.repo_id,
            name: operator.name,
            email: operator.email,
            phone: operator.phone,
            job: operator.job,
            workforce: operator.workforce,
            extra: Object.assign({}, operator.extra)
          });
          o.operators.push(newOperator);
          this._officeService.save(o).then((res) => {
            const i = this.entity.operators.findIndex((e) => (e.id === operator.id));
            if (i !== -1) {
              this.entity.operators[i].repo_id = newOperator.id;
              this._save().then((res) => {
                this._spinnerService.hide();
              }).catch((err) => {
                console.error('WorkbookExternalCompanyComponent.addRepoOperator error save oeex', JSON.stringify(err));
                this._spinnerService.hide();
              });
            } else {
              console.error('WorkbookExternalCompanyComponent.addRepoOperator enable to find operator', operator);
              this._spinnerService.hide();
            }
          }).catch((err) => {
            console.error('WorkbookExternalCompanyComponent.addRepoOperator error save repo_oeex', JSON.stringify(err));
            this._spinnerService.hide();
          });
        }).catch((err) => {
          console.error('WorkbookExternalCompanyComponent.addRepoOperator error get repo_oeex', JSON.stringify(err));
          this._spinnerService.hide();
        });
      }
    }
  }
}
