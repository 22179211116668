import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http';
// TODO: pourquoi avec et sans alias ?
import { throwError, throwError as observableThrowError, Subject, Observable, of } from 'rxjs';
import { map, timeout, catchError, retry } from 'rxjs/operators';
import { ConfigService } from 'app/shared/config/config.service';
import { LoggerService } from 'app/shared/logger';
import { b64toBlob, getBase64 } from 'app/shared/utils';
import { UploadServerModel, UploadDbModel } from './upload.model';
import { DocumentUploaded, DocumentMetadata } from './documentUploaded.model';
import { DocumentUploadedService } from './documentUploaded.service';

@Injectable()
export class UploadService {

  configUploadDb: UploadDbModel;
  configUploadServer: UploadServerModel;
  docBlob: Blob;
  documentsMetadata: Array<DocumentMetadata> = [];
  documentMetadata: DocumentMetadata;
  iconsDoc: any = [];

  constructor(private http: HttpClient,
    private _config: ConfigService,
    protected _document: DocumentUploadedService,
    private _logger: LoggerService) {
    this._config.getConfigLocal().then((data) => {
      this.configUploadDb = data.uploadDb;
      this.configUploadServer = data.uploadServer;
    })
      .catch((e) => {
        _logger.error('UploadService', 'unable to get config', JSON.stringify(e));
      });
  }

  /**
   * Get document uploaded in CouchDB by its id
   * @param document_id
   */
  public getDocLocal(document_id) {
    return this._document.getDocLocal(document_id);
  }

  /**
   * Upload files to server
   * @returns {{[p: string]: Observable<number>}}
   * @param file
   * @param newFileName
   * @param progress
   */
  private _uploadFilesToServer(file: File, newFileName: string, progress: Subject<number>) {
    // this will be the our resulting map
    this.documentsMetadata = [];

    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append('file', file, newFileName);

    // create a http-post request and pass the form
    // tell it to report the upload progress

    const req = new HttpRequest('POST', this.configUploadServer.url, formData, {
      reportProgress: true
    });

    // send the http-request and subscribe for progress-updates
    this.http.request(req).pipe(
      timeout(200),
      retry(this.configUploadServer.retry),
      catchError(this._handleError)
    ).subscribe(event => {
      switch (event.type) {
        case HttpEventType.Sent:
          progress.next(0);
          break;
        case HttpEventType.UploadProgress:
          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
          break;
        case HttpEventType.Response:
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
          break;
        default:
          break;
      }
    }
    );
  }

  /**
   * DEPRECIATED : upload to server the doc
   * @param {Set<File>} files
   * @returns {{[p: string]: Observable<number>}}
   * @private
   */
  // private _uploadFilesToServerOld(files: Set<File>): {[key: string]: Observable<number>} {
  //     // this will be the our resulting map
  //     const status = {};
  //     this.documentsUploaded = [];
  //
  //     files.forEach(file => {
  //         // create a new multipart-form for every file
  //         const date = new Date();
  //         const prefix = date.getDate() + date.getMonth() + date.getFullYear() + date.getTime();
  //         const newFileName = prefix + '_' + file.name;
  //         const formData: FormData = new FormData();
  //         formData.append('file', file, newFileName);
  //
  //         // create a http-post request and pass the form
  //         // tell it to report the upload progress
  //
  //         const req = new HttpRequest('POST', this.configUploadServer.url, formData, {
  //             reportProgress: true
  //         });
  //
  //         // create a new progress-subject for every file
  //         const progress = new Subject<number>();
  //
  //         // send the http-request and subscribe for progress-updates
  //         // this.http.request(req).retry(this.nbrRetry).catch(this.handleError).subscribe(event => {
  //         this.http.request(req).pipe(
  //             timeout(200),
  //             retry(this.configUploadServer.retry),
  //             catchError(this._handleError)
  //         ).subscribe(event => {
  //             switch (event.type) {
  //                 case HttpEventType.Sent:
  //                     progress.next(0);
  //                     break;
  //                 case HttpEventType.UploadProgress:
  //                     // calculate the progress percentage
  //                     const percentDone = Math.round(100 * event.loaded / event.total);
  //
  //                     // pass the percentage into the progress-stream
  //                     progress.next(percentDone);
  //                     break;
  //                 case HttpEventType.Response:
  //                     // Close the progress-stream if we get an answer form the API
  //                     // The upload is complete
  //                     progress.complete();
  //                     const documentUploaded = new DocumentUploaded();
  //                     documentUploaded.name = newFileName;
  //                     documentUploaded.displayName = newFileName.slice(14, 200);
  //                     documentUploaded.mime = file.type;
  //                     this.documentsUploaded.push(documentUploaded);
  //                     break;
  //                 default:
  //                     break;
  //             }
  //          }
  //         );
  //
  //         // Save every progress-observable in a map of all observables
  //         status[file.name] = {
  //             progress: progress.asObservable()
  //         };
  //     });
  //     // return the map of progress.observables
  //     return status;
  // }

  /**
   * Upload files to couchDB (in pdp object)
   * @param {Set<File>} files
   * @returns {{[p: string]: Observable<number>}}
   */
  public uploadFiles(files: Set<File>): { [key: string]: Observable<number> } {
    const status = {};
    this.documentsMetadata = [];

    files.forEach(file => {
      const progress = new Subject<number>();
      const date = new Date();

      const prefix = date.getDate() + date.getMonth() + date.getFullYear() + date.getTime();
      const newFileName = prefix + '_' + file.name;

      // if active, upload doc to server with node plugin
      if (this.configUploadServer.active) {
        this._uploadFilesToServer(file, newFileName, progress);
      }


      const documentUploaded = new DocumentUploaded('');
      this._document.saveDocLocal(documentUploaded).then(() => {
        progress.next(0);
        const documentMetadata = new DocumentMetadata();
        documentMetadata.name = newFileName;
        documentMetadata.data_id = documentUploaded._id;
        documentMetadata.displayName = file.name;
        documentMetadata.mime = file.type;
        documentMetadata.icon = this._document._iconDocumentsUploaded(file.type);
        this.documentsMetadata.push(documentMetadata);
        this._document.getDocLocal(documentUploaded._id).then((documentUploadedSaved) => {
          this._setAttachment(documentUploadedSaved, documentMetadata, file)
            .then(() => {
              progress.complete();
            })
            .catch((e) => {
              this._logger.error('UploadService', 'An error occurred during attachment document', JSON.stringify(e));
              progress.complete();
            });
        });
        progress.complete();
      });

      // getBase64(file).then(
      //   data => {
      //     progress.next(0);
      //     const a = data.toString().indexOf(',');
      //     const b64 = data.toString().substring((a + 1), data.toString().length);
      //     const documentUploaded = new DocumentUploaded({});
      //     documentUploaded.data = b64;
      //     this._document.saveDocLocal(documentUploaded).then(() => {
      //       const documentMetadata = new DocumentMetadata();
      //       documentMetadata.name = newFileName;
      //       documentMetadata.data_id = documentUploaded._id;
      //       documentMetadata.displayName = file.name;
      //       documentMetadata.mime = file.type;
      //       documentMetadata.icon = this._document._iconDocumentsUploaded(file.type);
      //       this.documentsMetadata.push(documentMetadata);
      //     });
      //     progress.complete();
      //   }
      // );
      status[file.name] = {
        progress: progress.asObservable()
      };
    });
    return status;
  }


  protected _setAttachment(documentUploaded, documentMetadata, file) {
    return this._document.saveAttachment(documentUploaded, documentMetadata.mime, file);
  }

  /**
   * Upload image to server
   * @param {string} returnedDataType
   * @param {string} downloadData
   * @returns {Observable<boolean>}
   */
  public uploadImage(returnedDataType: string = 'image/png', downloadData?: string): Observable<boolean> {
    const date = new Date();
    const prefix = date.getDate() + date.getMonth() + date.getFullYear() + date.getTime();
    const newFileName = prefix + '_image' + this._generateDataType(returnedDataType);
    const formData: FormData = new FormData();
    const block = downloadData.split(';');
    const realData = block[1].split(',')[1];

    const documentUploaded = new DocumentUploaded({});
    documentUploaded.data = realData;
    this._document.saveDocLocal(documentUploaded).then(() => {
    });
    const documentMetadata = new DocumentMetadata();
    documentMetadata.name = newFileName;
    documentMetadata.data_id = documentUploaded._id;
    documentMetadata.mime = returnedDataType;
    documentMetadata.icon = this._document._iconDocumentsUploaded(returnedDataType);
    this.documentMetadata = documentMetadata;

    // create object
    // const documentMetadata = new DocumentMetadata();
    // documentMetadata.name = newFileName;
    // documentMetadata.mime = returnedDataType;
    // documentMetadata.data = realData;
    // this.documentMetadata = documentMetadata;

    // Convert it to a blob to upload
    if (this.configUploadServer.active) {
      const blob = b64toBlob(realData, returnedDataType);
      this.docBlob = blob;
      formData.append('file', blob, newFileName);
      const req = new HttpRequest('POST', this.configUploadServer.url, formData, {
        reportProgress: true
      });

      return this.http.request(req).pipe(timeout(200)).pipe(
        retry(this.configUploadServer.retry)
      ).pipe(map(res => {
        return res.type === 4;
      }),
        catchError((error: any) => observableThrowError(error.error || 'Error Server')));
    } else {
      return of(true);
    }
  }

  /**
   * Create the extension of image
   * @param {string} returnedDataType
   * @returns {string}
   * @private
   */
  private _generateDataType(returnedDataType: string): string {
    if (returnedDataType) {
      return '.' + returnedDataType.split('/')[1];
    }
    return '';
  }

  /**
   * Handle errors for http requests
   * @param {HttpErrorResponse} error
   * @returns {Observable<never>}
   * @private
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this._logger.error('UploadService', 'An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this._logger.error('UploadService',
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  /**
   * get DocumentUploaded array objects
   * @returns {Array<any>}
   */
  public getDocumentsMetadata(): Array<DocumentMetadata> {
    return this.documentsMetadata;
  }

  /**
   * get DocumentMetadata object
   * @returns {DocumentUploaded}
   */
  public getDocumentMetadata(): DocumentMetadata {
    return this.documentMetadata;
  }

  /**
   * get DocumentMetadata object (Async function)
   * @returns {DocumentUploaded}
   */
  async getDocumentMetadataAsync(): Promise<DocumentMetadata> {
    return await this.documentMetadata;
  }

  /**
   * get doc in blob format
   * @returns {Blob}
   */
  public getBlob(): Blob {
    return this.docBlob;
  }

  /**
   * Delete document from server
   * @param {string} name
   */
  public deleteServerDoc(name: string) {
    this.http.delete(this.configUploadServer.url + '/' + name/*, this.httpOptions*/)
      .subscribe(
        data => {
          this._logger.info('UploadService', 'DELETE Request is successful', data);
        },
        error => {
          this._logger.error('UploadService', 'Error', JSON.stringify(error));
        }
      );
  }

  /**
   * Delete document from DB
   * @param {number} id
   * @param obj
   */
  public deleteDbDoc(id: string, obj: DocumentMetadata[]): Promise<boolean> {
    const removeMeta = obj.find(DocumentMetadata => DocumentMetadata.id === id);
    return this._document.getDocLocal(removeMeta.data_id).then(async (doc: DocumentUploaded) => {
      if (doc.data && doc.data.length > 0) {
        try {
          await this._document.removeDocLocal(doc);
          return true;
        } catch (error) {
          this._logger.error('UploadService', 'error remove doc', JSON.stringify(error));
          return false;
        }
      } else {
        return this._document.removeAttachment(doc, removeMeta.displayName).then(async () => {
          return this._document.getDocLocal(removeMeta.data_id).then(async (docAttachmentRemoved: DocumentUploaded) => {
            try {
              await this._document.removeDocLocal(docAttachmentRemoved);
              return true;
            } catch (error) {
              this._logger.error('UploadService', 'error remove doc', JSON.stringify(error));
              return false;
            }
          });
        }).catch((error) => {
          this._logger.error('UploadService', 'error remove attachment', JSON.stringify(error));
          return false;
        })
      }
    });
  }

}
