import {Component, Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService} from 'app/shared';
import {
  SignablePermitService, PermitContentService,
  EntityPreventionPlan,
  EntitySignablePermit, EntityPermitContent,
  WorkbookStepService,
  EntityStep,
  EntitySite,
  Implementation,
  DocumentationService, PreventionPlanService,
  RiskEntry, EntityActivity
} from 'app/workbook-core';
import {AbstractSignableInfoDialogComponent} from '../abstractSignableInfo-dialog.component';
import {SignablePermitInfoComponent} from './signablePermitInfo.component'

@Component({
  selector: 'app-signable-permit-info-dialog',
  templateUrl: './signablePermitInfo-dialog.component.html',
  styleUrls: ['./signablePermitInfo-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class SignablePermitInfoDialogComponent extends AbstractSignableInfoDialogComponent implements AfterViewInit {
  entityForm: SignablePermitInfoComponent;
  entity: EntitySignablePermit;
  workbook: EntityPreventionPlan;
  implementation: Implementation;
  selectedStep: string;


  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  stepList: {[step_id: string]: EntityStep} = {};
  protected _stepsSubscription: Subscription;

  permitContent: EntityPermitContent;

  stepActivities: EntityActivity[] = [];
  protected _stepActivitiesSubscription: Subscription;
  stepRisks: {[activity_id: string]: {[risk_name: string]: RiskEntry}} = {};
  stepRiskSituationName: {[situation_id: string]: string} = {};
  stepActivitiesLoaded: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    protected _preventionPlanService: PreventionPlanService,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _permitContentService: PermitContentService,
    protected _stepService: WorkbookStepService,
    public dialogRef: MatDialogRef<SignablePermitInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: SignablePermitService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        this.entity = data['entity'];
        if (data['implementation']) {
          this.implementation = data['implementation'];
        }
      }
      if (data['permitContent']) {
        this.permitContent = data['permitContent'];
      }
      if (!this.id) {
        if (!this.entity) {
          this.entity = this._entityService.getNewEntity({permitContent: this.permitContent, parent_id: (this.workbook ? this.workbook._id : '')});
        }
        if (this.implementation && this.implementation.step) {
          this.selectedStep = this.implementation.step._id;
          this.selectStep();
        } else if (this.workbook && this.workbook.simple) {
          this.selectStep();
        }

      }

      this._stepsSubscription = this._stepService.getChildren(this.parent_id).subscribe((list: EntityStep[]) => {
        if (list) {
          this.stepList = {};
          let _templates: EntityStep[] = [];
          let _steps: EntityStep[] = [];
          list
            .forEach((e) => {
              this.stepList[e._id] = e;
              if (e.template) {
                _templates.push(e);
              } else {
                _steps.push(e);
              }
            });
          this.templates = _templates//.filter((e) => (e.implementation))
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
          this.steps = _steps//.filter((e) => (e.implementation || (e.template_id && this.stepList[e.template_id] && this.stepList[e.template_id].implementation)))
            .sort((a, b) =>
              (this.workbook && this.workbook.type === 'annual') ?
                ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime())
                : ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
            );

          this.selectStep();
        }
      });

      this._stepActivitiesSubscription = this._preventionPlanService.getActivityList(this.parent_id).subscribe((list) => {
        if (list) {
          this.stepRiskSituationName = {};
          this.stepActivities = list;
          this.stepActivities.forEach((activity: EntityActivity) => {
            if (activity.measures) {
              const entry: {[risk_situation_id: string]: RiskEntry} = {};
              activity.risks_situations.forEach((risk_situation_id) => {
                const situation = activity.situations.find((s) => (s._id === risk_situation_id));
                const risk = activity.risks.find((s) => (s._id === (situation ? situation.risk_id : risk_situation_id)));
                if (risk) {
                  this.stepRiskSituationName[situation ? situation._id : risk._id] = (risk.name + (situation ? ' - ' + situation.name : ''));
                  entry[risk_situation_id] = new RiskEntry(
                    this.stepRiskSituationName[situation ? situation._id : risk._id],
                    activity.measures.filter((measure) => (
                      measure.risk_id === risk._id
                      && (situation
                        ? measure.type === situation._id
                        : (measure.type === '' || measure.type === 'local' || measure.type === 'global')
                      )
                    )),
                    (situation && situation.sir),
                    (situation && situation.fp)
                  );
                }
              });
              this.stepRisks[activity._id] = entry;
            }
          });
          this.stepActivitiesLoaded = true;
          this.selectStep();
        }
      });

    }

  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.id && this.entityForm && this.entityForm.entity && this.entityForm.entity.step) {
      this.selectedStep = this.entityForm.entity.step;
    }
    this.selectStep();
    this.updatePermitContent();

  }
  ngOnDestroy() {
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }
  updatePermitContent() {
    if (this.permitContent && this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.title = this.permitContent.title;
      this.entityForm.entity.summary = this.permitContent.description;

      if (!this.id) {
        this.permitContent.operatorGroups.forEach((e) => {

        });

      }

    }
  }
  selectStep() {
    if (this.workbook && this.workbook.simple) {
      if (this.steps && this.steps.length) {
        this.selectedStep = this.steps[0]._id;
      } else if (this.templates && this.templates.length) {
        this.selectedStep = this.templates[0]._id;
      }
    }
    if (this.entityForm && this.entityForm.entity && this.selectedStep && this.stepList[this.selectedStep] && (this.entityForm.entity.step || this.selectedStep != this.entityForm.entity.step)) {
      this.entityForm.entity.step = this.selectedStep;
      if (this.stepList[this.selectedStep]) {
        if (this.stepList[this.selectedStep].implementation) {
          this.implementation = this.stepList[this.selectedStep].implementation
        } else {
          this.implementation = //null;
            Implementation.generate(this.stepList[this.selectedStep],
              this.stepActivities,
              this.stepRisks);
        }
      } else {
        this.implementation = null;
      }
      this.entityForm.entity.implementation = this.implementation;
      if (this.stepList[this.selectedStep].hourStart && !this.entityForm.entity.hourStart) {
        this.entityForm.entity.hourStart = this.stepList[this.selectedStep].hourStart;
      }
      if (this.stepList[this.selectedStep].hourEnd && !this.entityForm.entity.hourEnd) {
        this.entityForm.entity.hourEnd = this.stepList[this.selectedStep].hourEnd;
      }
      if (this.implementation) {
        if (this.implementation.step.site) {
          const s = new EntitySite(this.implementation.step.site);
          s.zones = [];
          s.documents = [];
          this.entityForm.entity.site = s;
          this.entityForm.initSearchSite();

        }
        //this.entityForm.entity.point = this.implementation.step.point ? this.implementation.step.point : '';
        /*
        this.activities = this.implementation.step.activities ?
          this.implementation.step.activities
            .filter((activity_id) => (this.implementation.activities[activity_id]))
            .map((activity_id) => (this.implementation.activities[activity_id]))
          : [];
          */
      }
      this.entityForm.refreshLocations();
    }
  }
  save() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.entity._id) {
      if (this.userProfile) {
        this.entityForm.entity.editor = this._entityService.getUserName();
        this.entityForm.entity.editorEmail = this.userProfile.email;
        this.entityForm.entity.editorDate = new Date();
      }
      if (this.workbook && this.workbook._id) {
        this._entityService.getNewCode(this.workbook._id).then((code) => {
          this.entityForm.entity.parent_code = this.workbook.code;
          this.entityForm.entity.code = code;
          this.entityForm.entity.name = this.entityForm.entity.getName();
          super.save();
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      }
    } else {
      super.save();
    }
  }
}
