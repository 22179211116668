import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService, EntityEditDialogComponent} from 'app/shared';
import {WorkbookOfficeService, EntityOffice} from 'app/workbook-core';

@Component({
  selector: 'app-workbook-office-dialog',
  templateUrl: './office-dialog.component.html',
  // styleUrls: ['./office-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookOfficeDialogComponent extends EntityEditDialogComponent {

  public officeList: EntityOffice[] = [];


  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<WorkbookOfficeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: WorkbookOfficeService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data && data['officeList']) {
      this.officeList = data['officeList'];
    }
  }
}
