import {Entity} from 'app/shared';

export class ContributorGroup {
  name: string;
  //class css pour formulaire
  formClass: string;
  //class css pour edition
  editionClass: string;
  //doit signer le permis (validation)
  isSignatory: boolean;
  //peut faire signer les emargements sur sa session
  isAttendanceManager: boolean;
  isUnique: boolean;
  required: boolean;
  constructor(data = null) {
    this.name = (data && data.name) ? data.name : 'Nouveau type d\'intervenant';
    this.formClass = (data && data.formClass) ? data.formClass : '';
    this.editionClass = (data && data.editionClass) ? data.editionClass : '';
    this.isSignatory = !!(data && data.isSignatory);
    this.isAttendanceManager = !!(data && data.isAttendanceManager);
    this.isUnique = !!(data && data.isUnique);
    this.required = !!(data && data.required);
  }
}
export class AttendanceCheckList {
  //button label
  name: string;
  signature: boolean;
  checklist: {
    label: string,
    value: string | number | boolean
  }[];
  constructor(data = null) {
    this.name = (data && data.name) ? data.name : 'Signer';
    this.signature = !!(data && data.signature);
    this.checklist = (data && data.checklist) ? data.checklist.map((e) => (Object.assign({}, e))) : [];
  }
}
export class AttendanceGroup {
  name: string;
  //class css pour formulaire
  formClass: string;
  //class css pour edition
  editionClass: string;
  isUnique: boolean;
  required: boolean;
  nominative: boolean;
  signature: boolean;
  checkpoints: string[];
  //checkpoints: AttendanceCheckList[];
  constructor(data = null) {
    this.name = (data && data.name) ? data.name : 'Nouveau type d\'émargement';
    this.formClass = (data && data.formClass) ? data.formClass : '';
    this.editionClass = (data && data.editionClass) ? data.editionClass : '';
    this.isUnique = !!(data && data.isUnique);
    this.nominative = !!(data && data.nominative);
    this.required = !!(data && data.required);
    this.signature = !!(data && data.signature);
    this.checkpoints = (data && data.checkpoints) ? data.checkpoints.map((e) => (e)) : [];
  }

}
export class ContentGroupItem {
  value: string;
  picto?: string;
  default: boolean;
  constructor(data = null) {
    if (typeof data === 'string') {
      this.value = data;
      this.default = false;
    } else {
      this.value = (data && data.value) ? data.value : '';
      if (data && data.picto) {
        this.picto = data.picto;
      }
      this.default = !!(data && data.default);
    }
  }
}

export class ContentGroup {
  name: string;
  //class css pour formulaire
  formClass: string;
  //class css pour edition
  editionClass: string;
  //class css pour formulaire
  formStyle: string;
  //class css pour edition
  editionStyle: string;
  required: boolean;
  modifiable: boolean;
  checkbox: boolean;
  contents: ContentGroupItem[];
  constructor(data = null) {
    this.name = (data && data.name) ? data.name : 'Nouveau groupe de contenu';
    this.formClass = (data && data.formClass) ? data.formClass : '';
    this.editionClass = (data && data.editionClass) ? data.editionClass : '';
    this.formStyle = (data && data.formStyle) ? data.formStyle : '';
    this.editionStyle = (data && data.editionStyle) ? data.editionStyle : '';
    this.required = !!(data && data.required);
    this.modifiable = !!(data && data.modifiable);
    this.checkbox = !!(data && data.checkbox);
    this.contents = (data && data.contents) ? data.contents.map((e) => (new ContentGroupItem(e))) : [];
  }
}
/*
label
text/check
required
*/

export class EntityPermitContent extends Entity {
  //Nom
  public name: string;
  //Titre
  public title: string;
  //Description
  public description: string;
  public summary: string;
  //with step implementation
  public allowImplementation: boolean;
  //step implementation required
  public requireImplementation: boolean;
  //Modify activity risks/measures
  public allowModifyImplementation: boolean;
  //Modify activity risks/measures
  public enableMaxDuration: boolean;
  public maxDuration: number;
  //groupes de signataires (donneur d'ordre, intervenants...
  public contributorGroups: ContributorGroup[];
  //Opérateur désignés par le permis
  public operatorGroups: AttendanceGroup[];
  //Groupes de contenus
  public contentGroups: ContentGroup[];
  public contentFormClass: string;
  public contentEditionClass: string;
  public contentFormStyle: string;
  public contentEditionStyle: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : 'Permis';
    this.title = (data && data.title) ? data.title : 'Permis';
    this.description = (data && data.description) ? data.description : '';
    this.summary = (data && data.summary) ? data.summary : '';
    this.allowImplementation = !!(data && data.allowImplementation);
    this.requireImplementation = !!(data && data.requireImplementation);
    this.allowModifyImplementation = !!(data && data.allowModifyImplementation);
    this.enableMaxDuration = !!(data && data.enableMaxDuration);
    this.maxDuration = (data && data.maxDuration) ? data.maxDuration : 0;
    this.contributorGroups = (data && data.contributorGroups) ? data.contributorGroups.map((e) => (new ContributorGroup(e))) : [];
    this.operatorGroups = (data && data.operatorGroups) ? data.operatorGroups.map((e) => (new AttendanceGroup(e))) : [];
    this.contentGroups = (data && data.contentGroups) ? data.contentGroups.map((e) => (new ContentGroup(e))) : [];
    this.contentFormClass = (data && data.contentFormClass) ? data.contentFormClass : '';
    this.contentEditionClass = (data && data.contentEditionClass) ? data.contentEditionClass : '';
    this.contentFormStyle = (data && data.contentFormStyle) ? data.contentFormStyle : '';
    this.contentEditionStyle = (data && data.contentEditionStyle) ? data.contentEditionStyle : '';

  }
  getContentPicto(contentGroup_name: string, content_name: string) {
    const group = this.contentGroups.find((e) => (e.name === contentGroup_name));
    if (group && group.contents && group.contents.length) {
      const content = group.contents.find((e) => (e.value === content_name));
      if (content && content.picto) {
        return content.picto;
      }
    }
    return '';
  }
}
