
import {of as observableOf, Observable, BehaviorSubject} from 'rxjs';

// Simple data source for using it in mat-table
export class SimpleDatasource<T> {

  logs: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private data: any[];

  setData(newData: any[]) {
    this.data = newData;
  }

  connect(): Observable<any[]> {
    return observableOf(this.data);
  }

  disconnect(): void {}
}
