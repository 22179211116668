import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoggerService } from 'app/shared';
import { PreventionPlanService, EntityStep } from 'app/workbook-core';
import { WorkbookStepDialogComponent } from './step-dialog.component';
import { WorkbookChildListComponent } from '../abstractWorkbookChildList.component';

export abstract class AbstractWorkbookStepListComponent extends WorkbookChildListComponent {

  entityList: EntityStep[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette intervention ?';
  addConfirmMessage = 'Nouvelle intervention créée';

  stepList: EntityStep[] = [];
  templateList: EntityStep[] = [];

  selectedStepIndex: number = 0;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _init(data) {
    super._init(data);
    this.stepList = [];
    this.templateList = [];
    if (this.entity && this.entity.type === 'annual') {
      this.entityList
        .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()))
        .forEach((step) => {
          if (step.template) {
            this.templateList.push(step);
          } else if (!step.template_id) {
            this.stepList.push(step);
          }
        });
    } else {
      this.stepList = this.entityList.sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
    }
  }
  protected _getChildService() {
    return this._entityService.stepService;
  }


  addStep(template: boolean = false, value: EntityStep = null, clone = false, specific: boolean = false) {
    let _entity = {
      name: value ? ((clone ? 'Copie de ' : (template ? '' : 'Intervention copie de ')) + value.name) : '',
      parent_id: this.entity._id,
      template: template,
      template_id: (!specific && !template && value && value.template) ? value._id : '',
      dateStart: value ? (clone ? value.dateStart : null) : this.entity.dateStart,
      dateEnd: value ? (clone ? value.dateEnd : null) : this.entity.dateEnd,
      hourStart: value ? value.hourStart : this.entity.hourStart,
      hourEnd: value ? value.hourEnd : this.entity.hourEnd,
      workConditions: value ? value.workConditions : this.entity.workConditions,
      point: value ? value.point : this.entity.point,
      site: value ? value.site : this.entity.site,
      zone: value ? value.zone : this.entity.zone,
      locations: value ? value.locations : [],
      activities: value ? value.activities : [],
      //offices: value ? value.offices : [],
      //externalCompanies: value ? value.externalCompanies : [],
      implementation: (!template && !specific && value && value.implementation) ? value.implementation : null,
      eex_id: value ? value.eex_id : ''
    };

    const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        entity: this._entityService.stepService.getNewEntity(_entity),
        parent_id: this.entity._id,
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this._afterCreateStep(c);
      }
    });
  }
  _afterCreateStep(c) {
    this.updateElement(c);
    this.selectedStepIndex = this.stepList.findIndex((e) => (e._id === c._id));
  }
  /*
    addStep(template: boolean = false) {
    const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        entity: this._getChildService().getNewEntity({
          template: template,
          parent_id: this.entity._id,
          dateStart: this.entity.dateStart,
          dateEnd: this.entity.dateEnd,
          hourStart: this.entity.hourStart,
          hourEnd: this.entity.hourEnd,
          site: this.entity.site,
          point: this.entity.point
        }),
        parent_id: this.entity._id,
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      this.updateElement(c);
    });
  }
  */
  public selectStep(step) {
    return ['/' + this._entityService.getUrlWorkbookStep(this.entity.documentType, this.entity._id, step._id)];

  }
  updateElement(element: EntityStep) {
    if (element._deleted) {
      this._entityService.updateWorkbookInspectionStep(this.entity, element);
    }
    if (element && this._updateListElement(element)) {
      this._init(this.entityList);
    }
  }
}
