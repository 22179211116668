import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, EntityFormComponent} from 'app/shared';
import {FirePermitContentService, EntityFirePermitContent, SafetyInstruction, Instruction} from 'app/workbook-core';

@Component({
  selector: 'app-repository-firePermitContent',
  templateUrl: './firePermitContent.component.html',
  styleUrls: ['./firePermitContent.component.scss']
})

export class FirePermitContentComponent extends EntityFormComponent {
  entity: EntityFirePermitContent;
  newHotSpotWork: string = '';
  newEquipment: string = '';
  newAttestation: string = '';
  newWarningDevice: string = '';
  newInterventionDevice: string = '';
  newEmergency: string = '';
  newSafetyInstruction: string = '';
  newInstructions: string[] = [];

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: FirePermitContentService

  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.rightU && this.entity && this.entity.safetyInstructions) {
      this.newInstructions = this.entity.safetyInstructions.map((e) => '');
    }
  }
  addHotSpotWork() {
    if (this.rightU && this.entity && this.newHotSpotWork) {
      if (!this.entity.hotSpotWorks) {
        this.entity.hotSpotWorks = [];
      }
      this.entity.hotSpotWorks.push(this.newHotSpotWork);
      this._save().then((e) => {this._afterSave(e);});
      this.newHotSpotWork = '';
    };
  }
  removeHotSpotWork(index: number) {
    if (this.rightU && this.entity && this.entity.hotSpotWorks && this.entity.hotSpotWorks.length > index) {
      this.entity.hotSpotWorks.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addEquipment() {
    if (this.rightU && this.entity && this.newEquipment) {
      if (!this.entity.equipments) {
        this.entity.equipments = [];
      }
      this.entity.equipments.push(this.newEquipment);
      this._save().then((e) => {this._afterSave(e);});
      this.newEquipment = '';
    };
  }
  removeEquipment(index: number) {
    if (this.rightU && this.entity && this.entity.equipments && this.entity.equipments.length > index) {
      this.entity.equipments.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addAttestation() {
    if (this.rightU && this.entity && this.newAttestation) {
      if (!this.entity.attestations) {
        this.entity.attestations = [];
      }
      this.entity.attestations.push(this.newAttestation);
      this._save().then((e) => {this._afterSave(e);});
      this.newAttestation = '';
    };
  }
  removeAttestation(index: number) {
    if (this.rightU && this.entity && this.entity.attestations && this.entity.attestations.length > index) {
      this.entity.attestations.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addWarningDevice() {
    if (this.rightU && this.entity && this.newWarningDevice) {
      if (!this.entity.warningDevices) {
        this.entity.warningDevices = [];
      }
      this.entity.warningDevices.push(this.newWarningDevice);
      this._save().then((e) => {this._afterSave(e);});
      this.newWarningDevice = '';
    };
  }
  removeWarningDevice(index: number) {
    if (this.rightU && this.entity && this.entity.warningDevices && this.entity.warningDevices.length > index) {
      this.entity.warningDevices.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addInterventionDevice() {
    if (this.rightU && this.entity && this.newInterventionDevice) {
      if (!this.entity.interventionDevices) {
        this.entity.interventionDevices = [];
      }
      this.entity.interventionDevices.push(this.newInterventionDevice);
      this._save().then((e) => {this._afterSave(e);});
      this.newInterventionDevice = '';
    };
  }
  removeInterventionDevice(index: number) {
    if (this.rightU && this.entity && this.entity.interventionDevices && this.entity.interventionDevices.length > index) {
      this.entity.interventionDevices.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addEmergency() {
    if (this.rightU && this.entity && this.newEmergency) {
      if (!this.entity.emergency) {
        this.entity.emergency = [];
      }
      this.entity.emergency.push(this.newEmergency);
      this._save().then((e) => {this._afterSave(e);});
      this.newEmergency = '';
    };
  }
  removeEmergency(index: number) {
    if (this.rightU && this.entity && this.entity.emergency && this.entity.emergency.length > index) {
      this.entity.emergency.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addSafetyInstruction() {
    if (this.rightU && this.entity && this.newSafetyInstruction) {
      if (!this.entity.safetyInstructions) {
        this.entity.safetyInstructions = [];
      }
      this.entity.safetyInstructions.push(new SafetyInstruction(this.newSafetyInstruction));
      this._save().then((e) => {this._afterSave(e);});
      this.newSafetyInstruction = '';
    };
  }
  removeSafetyInstruction(index: number) {
    if (this.rightU && this.entity && this.entity.safetyInstructions && this.entity.safetyInstructions.length > index) {
      this.entity.safetyInstructions.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addInstruction(index: number) {
    if (this.rightU && this.entity && this.newInstructions && this.newInstructions[index] && this.entity.safetyInstructions && this.entity.safetyInstructions[index]) {
      if (!this.entity.safetyInstructions[index].instructions) {
        this.entity.safetyInstructions[index].instructions = [];
      }
      this.entity.safetyInstructions[index].instructions.push(new Instruction(this.newInstructions[index]));
      this._save().then((e) => {this._afterSave(e);});
      this.newInstructions[index] = '';
    };
  }

  removeInstruction(index: number, j: number) {
    if (this.rightU && this.entity
      && this.entity.safetyInstructions
      && this.entity.safetyInstructions[index]
      && this.entity.safetyInstructions[index].instructions
      && this.entity.safetyInstructions[index].instructions[j]) {
      this.entity.safetyInstructions[index].instructions.splice(j, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
}
