import {Injectable} from '@angular/core';
import {DbDocsService} from '../db';
import {EntityBaseService} from './entityBase.service';
import {EntityDocumentFile} from '../models';

@Injectable()
export class DocumentFileService extends EntityBaseService {

  autoInit = false;

  static LABEL_ONE = "chargement du fichier joint";
  static LABEL_ALL = "chargement des fichiers joints ?";
  static LABEL_CHILDREN = "chargement des fichiers joints de l'opération ?";
  static LABEL_SAVE = "sauvegarde du fichier joint";
  static LABEL_DELETE = "suppression du fichier joint";

  static ENTITY_TYPE: string = 'docUploaded';
  static ENTITY_FIELDS: string[] = [];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['editPP'],
    UPDATE: ['editPP'],
    DELETE: ['editPP']
  }

  static ENTITY_URL = 'attachment';

  constructor(protected _db: DbDocsService) {
    super(_db);
  }
  factory(data, clone: boolean = false): EntityDocumentFile {
    return new EntityDocumentFile(data, clone);
  }
}
