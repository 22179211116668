import { Component, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MatSelect } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  ConfirmDialogComponent,
  EmailService,
  EmailDatasModel,
  LoggerService,
  EntityComponent,
  EntityExploitingCompany,
  EntityEmailMessages,
  EmailMessagesService,
  ExploitingCompanyService, TreeElement
} from 'app/shared';
import { EntityWorkbook, PreventionPlanService, OfficeService, EntityOffice } from 'app/workbook-core';
import { WorkbookSelectComponent } from '../workbook/workbookSelect.component';
import { WorkbookExportDialogComponent } from '../export-dialog/export-dialog.component';
import { NotifyDialogComponent } from '../notify-dialog/notify-dialog.component';

@Component({
  selector: 'app-workbook-toolbar',
  templateUrl: './workbookToolbar.component.html',
  styleUrls: ['./workbookToolbar.component.scss']
})
export class WorkbookToolbarComponent extends EntityComponent implements OnDestroy {

  @Input() saveError: boolean;

  entity: EntityWorkbook;


  exploitingCompany: EntityExploitingCompany;
  protected _exploitingCompanySubscription: Subscription;

  offices: EntityOffice[];
  level: { [id: string]: number } = {};
  protected _officesSubscription: Subscription;
  officeScope: { [id: string]: string[] };
  scopedOffice: { [id: string]: boolean };
  showVisibility: boolean = false;
  allVisibility: boolean = false;
  noneVisibility: boolean = false;

  emailMessages: EntityEmailMessages;
  protected _emailMessagesSubscription: Subscription;
  userEmail: string = '';

  dialogRef: MatDialogRef<WorkbookExportDialogComponent>;
  dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  dialogRefNotify: MatDialogRef<NotifyDialogComponent>;

  _rmConfirmMessage = {
    annual: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce contrat ?',
    occasional: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer cette opération ?',
    protocol: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce protocole ?'
  }

  isWorkbookSync: boolean = false;
  isWorkbookSyncing: boolean = false;
  isWorkbookSyncUserSelected: boolean = false;


  constructor(
    // protected _documentService: DocumentService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _officeService: OfficeService,
    protected _exploitingCompanyService: ExploitingCompanyService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    //protected route: ActivatedRoute,
    protected router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(_logger, _entityService);
    this.rightC = false;
    this.rightU = false;
    this.rightD = false;
    this.scopedOffice = {};
    this._exploitingCompanySubscription = this._exploitingCompanyService.getSingleOne().subscribe((data) => {
      if (data) {
        this.exploitingCompany = data;
      }
    });
    this._emailMessagesSubscription = this._emailMessagesService.getSingleOne().subscribe((data) => {
      if (data) {
        this.emailMessages = data;
      }
    });

    this._officesSubscription = this._officeService.getAll().subscribe((list: EntityOffice[]) => {
      if (list) {
        this.offices = TreeElement.orderTreeView(list, 'main');
        this.level = TreeElement.getLevels(list, 'main');
        this.officeScope = OfficeService.getOfficeScope(list);
        this.refreshScopedOffice();
        const userId = this._entityService.getUserId();
        this.userEmail = this._entityService.getUserEmail();
        let found = false;
        list.forEach((office) => {
          if (!found && office.contacts && office.contacts.length) {
            office.contacts.forEach((contact) => {
              if (!found && contact.user_id === userId && contact.email) {
                found = true;
                this.userEmail = contact.email;
              }
            });
          }
        });
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity._id) {
      this._updatePermissions(this.entity._id);
      this._isSyncUpdate();
    }
    this.refreshScopedOffice();
  }
  refreshScopedOffice(reset: boolean = false, none: boolean = false) {
    if (this.entity) {
      this.scopedOffice = {};
      if (reset) {
        this.entity.offices = null;
      } else if (none) {
        this.entity.offices = [];
      } else if (this.officeScope && this.entity && this.entity.offices) {
        this.entity.offices.forEach((id) => {
          if (this.officeScope[id]) {
            this.officeScope[id].forEach((scoped_id) => {
              this.scopedOffice[scoped_id] = true;
            });
          }
        });
      }
      setTimeout(() => {
        this.allVisibility = (!this.entity.offices);
        this.noneVisibility = (this.entity.offices && this.entity.offices.length === 0);
      });
    }
  }
  ngOnDestroy() {
    if (this._exploitingCompanySubscription) {
      this._exploitingCompanySubscription.unsubscribe();
    }
    if (this._emailMessagesSubscription) {
      this._emailMessagesSubscription.unsubscribe();
    }
    if (this._officesSubscription) {
      this._officesSubscription.unsubscribe();
    }
  }

  changeWorkbookVisibility(selector: MatSelect) {
    this._save().then((e) => {
      selector.close();
      this._isSyncUpdate();
    });
  }
  /**
   * open dialog and display pp in html format
   */
  exportWorkbook() {
    /*
    this.dialogRef = this.dialog.open(WorkbookExportDialogComponent, {
      disableClose: false,
      width: '890px',
      height: '90%',
      data: {id: this.entity._id}
    });
    */
    this.router.navigateByUrl(this._entityService.getUrlWorkbook(this.entity.documentType, this.entity._id, 'pdp'));
  }

  /**
   * open dialog popup to send email
   */
  notifyWorkbook() {
    this.dialogRefNotify = this.dialog.open(NotifyDialogComponent, {
      data: { entity: this.entity },
      minWidth: '60%'
    });
    this.dialogRefNotify.afterClosed().subscribe(result => {
      if (result) {
        const recipients = result[1];
        const email = result[0];
        recipients.forEach((recipient) => {
          const emailDatas = new EmailDatasModel();
          emailDatas.date = new Date();
          emailDatas.subject = email.subject;
          emailDatas.text = email.text;
          emailDatas.signature = email.signature;
          emailDatas.rgpdMail = email.rgpdMail;
          emailDatas.dpoName = email.dpoName;
          emailDatas.dpoMail = email.dpoMail;
          emailDatas.email = recipient.email;
          emailDatas.to_name = recipient.name;
          emailDatas.reply_to = this.userEmail;
          emailDatas.from_name = this.emailMessages.fromName;
          if (this.emailMessages.fromEmail) {
            emailDatas.from_email = this.emailMessages.fromEmail;
          }
          this._emailService.sendEmail(emailDatas).then((response) => {
            this._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
            if (response.status === 200) {
              this._snackBar('La notification a été envoyée');
            }
          }, (err) => {
            this._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
            this._snackBar('Erreur d\'envoi de la notification, contactez l\'administrateur');
          });
        })
      }
    })
  }

  /**
   * open dialog to duplicate this pp
   * @param pp_id
   */
  duplicateWorkbook() {
    const typeval = ((this.entity.type === 'annual' && this.entity.simple) ? 'protocol' : this.entity.type);
    this.dialog.open(WorkbookSelectComponent, {
      disableClose: true,
      data: { id: this.entity._id, workbook: this.entity, typeVal: typeval },
      minWidth: '500px'
    });
  }

  /**
   * Open dialog popup and delete pdp
   */
  deleteWorkbook() {
    this.dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    this.dialogRefDel.componentInstance.confirmMessage = this._rmConfirmMessage[this.entity.type];
    //'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce Plan de prévention ?';

    this.dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._entityService.remove(this.entity).then(() => {
          this._logger.info('ToolbarPpComponent ', 'Plan de prévention supprimé');
          this.router.navigate(['/dashboard']);
        }).catch((err) => {
          this._logger.error('ToolbarPpComponent: unable to remove pp: ', JSON.stringify(err));
        });
      }
    });
  }

  /**
   * Display material snackbar
   * @param message
   * @private
   */
  private _snackBar(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000
    });

  }


  onSyncStateChange(res) {
    if (this.entity && this.entity._id) {
      //console.log('WorkbookToolbarComponent onSyncStateChange ' + this.entity._id, res);
      this._updatePermissions(this.entity._id);
      this._isSyncUpdate();
    }
  }

  protected _updatePermissions(parent_id: string = '') {
    super._updatePermissions(parent_id);

  }
  protected _isSyncUpdate() {
    if (this.entity && this.entity._id) {
      this.isWorkbookSync = this._entityService.isInSync(this.entity._id);
      this.isWorkbookSyncing = this._entityService.isSyncing(this.entity._id);
      this.isWorkbookSyncUserSelected = this.isWbUserSelected(this.entity._id);
      // console.log('isSyncUpdate ' + this.isWorkbookSync + '/' + this.isWorkbookSyncing + '/' + this.isWorkbookSyncUserSelected);
    }
  }

  isWbUserSelected(id) {
    return this._entityService.userWorkbooks && this._entityService.userWorkbooks.indexOf(id) !== -1;
  }

  removeSelectedWorkbook(document_id: string) {
    if (this.isWorkbookSync && this.isWbUserSelected(document_id)) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        minWidth: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Souhaitez-vous décharger cette opération ?';
      dialogRefDel.componentInstance.cancelMessage = 'Non';
      dialogRefDel.componentInstance.buttonMessage = 'Oui';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          //this._spinnerService.show();
          //if (this.wbUserSelected.indexOf(document._id) === -1) {
          //  this.wbUserSelected.push(document._id);
          //  storageSet('config-wbUserSelected', this.wbUserSelected);
          //}
          this._entityService.removeUserWorkbook(document_id).then((res) => {
            //this._spinnerService.hide();
          });
          this._isSyncUpdate();
        }

      });
    }
  }

  addSelectedWorkbook(document_id: string) {
    if (this.hasPermission('syncAllDocuments')) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        minWidth: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Souhaitez-vous charger le contenu cette opération ?';
      dialogRefDel.componentInstance.cancelMessage = 'Non';
      dialogRefDel.componentInstance.buttonMessage = 'Oui';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          //this._spinnerService.show();
          //if (this.wbUserSelected.indexOf(document._id) === -1) {
          //  this.wbUserSelected.push(document._id);
          //  storageSet('config-wbUserSelected', this.wbUserSelected);
          //}
          this._spinnerService.show();
          this._entityService.loadChildrenRemote(document_id).then((res) => {
            this._entityService.addUserWorkbook(document_id).then((res) => {
            });
            this._spinnerService.hide();
            this._isSyncUpdate();
          }).catch((err) => {
            this._spinnerService.hide();
            //TODO Erreur / offline
          });
        }

      });
    }
  }
}
