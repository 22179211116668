import { LoggerService } from 'app/shared/logger';
import { ucFirst } from 'app/shared/utils';

export class CommonComponent {

  constructor(protected _logger: LoggerService) {
  }

  initInputTime(event) {
    if (!event.srcElement.value && !event.srcElement.readOnly && !event.srcElement.disabled) {
      event.srcElement.value = "00:00";
    }
  }

  isValidDate(d) {
    return !isNaN(d) && d instanceof Date;
  }

  log(txt: string, value: any) {
    console.log(txt, value)
  }


  /**
   * Upper the first letter of word
   * @param {string} value
   * @returns {string}
   */
  public ucFirst(value: string): string {
    return value ? ucFirst(value) : '';
  }
  public numalphaCompare(a, b) {
    const an = parseFloat(a);
    const bn = parseFloat(b);
    return (((an ? an : 0) - (bn ? bn : 0)) !== 0) ? ((an ? an : 0) - (bn ? bn : 0)) : a.localeCompare(b);
  }
}
