import {Component, Input, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import {UUID} from 'angular2-uuid';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ConfirmDialogComponent, comparableString, AuthService, LoggerService, EntityFormComponent, TreeElement, formattedDate, Contact} from 'app/shared';
import {
  OfficeService,
  SiteService,
  PreventionPlanService,
  EntityOffice,
  EntitySite, EntityZone,
  EntityWorkbook
} from 'app/workbook-core';
import {
  ZoneDialogComponent
} from './localisation';
@Component({
  selector: 'app-workbook',
  templateUrl: './workbook.component.html',
  styleUrls: ['./workbook.component.scss']
})

export class WorkbookComponent extends EntityFormComponent implements OnDestroy, AfterViewInit {

  entity: EntityWorkbook;
  autoSave = false;
  //@Input() workbookType: string;
  @Input() options;

  public formNotValid: boolean = false;
  public errorMessage: string = '';
  public checked: boolean = false;
  public selectedType: string = 'occasional';
  public placeHolderStartDate: string = 'Début de l\'opération';
  public placeHolderEndDate: string = 'Fin de l\'opération';
  public placeHolderDescription: string = 'Nature de l\'opération';

  //userOffices: EntityOffice[] = [];
  offices: EntityOffice[] = [];
  level: {[id: string]: number} = {};
  protected _userOfficesSubscription: Subscription;
  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  siteSelection: boolean = false;

  protected _zoneChangeSubscription: Subscription;
  zoneList: {[site_id: string]: EntityZone[]}
  //filteredZones: EntityZone[] = [];
  zoneSelection: boolean = false;

  public allZones: boolean = true;
  public allowAllZones: boolean = true;

  today: Date;
  isLoading: boolean = false;
  saveError: boolean = false;

  dateStartError: string = '';
  dateEndError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  showLocalisation: boolean = false;


  zonesDataSource: MatTableDataSource<EntityZone> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  zonesDisplayedColumns = ['name', 'actions']

  constructor(
    public snackBar: MatSnackBar,
    protected _auth: AuthService,
    protected _siteService: SiteService,
    protected _officeService: OfficeService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, _logger, _entityService);

    this.rightU = false;

    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredSites = this.getFilteredSites((this.entity && this.entity.site && this.entity.site.name) ? this.entity.site.name : '');
        this.zoneList = {};
        this.siteList.forEach((s) => {
          this.zoneList[s._id] = s.zones.sort((a, b) => a.name.localeCompare(b.name));
        });
        // this.updateAllZones();
      }
    });
    this.today = new Date();
  }
  /*
  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => { this._initSearchSite(); }, 200);

  }
*/
  protected _init(data) {
    super._init(data);

    if (this.entity) {
      //if (this.workbookType && this.entity.documentType !== this.workbookType) {
      //  this.entity.documentType = this.workbookType;
      //}
      if (this.options) {

        if (this.options['type']) {
          this.entity.type = this.options['type'];
        }
        if (this.options['simple']) {
          this.entity.simple = this.options['simple'];
        }
      }
      this.setPlacHolder();
      this._updatePermissions(this.entity._id ? this.entity._id : '');
      this.showLocalisation = !!this.entity.simple;
    }

    // this.userOffices = [];
    const userId = this._auth.getUserId();

    if (this.entity) {
      if (!this.entity.editor) {
        this.entity.editor = this._entityService.getUserName();
        this.entity.editorEmail = this.userProfile.email;
        this.entity.editorId = this._entityService.getUserId();
      }
      if (!this.entity._id) {
        // Create the reference code for this document
        /*
        this._entityService.getNewCode().then((code) => {
          this.entity.code = code;
          this.entity.name = this._entityService.getName(this.entity);
        });
        */
        // add current user like creator document and user authorized to see this document
        this.entity.users.push(this._entityService.getUserId());
        // TODO: à revoir
        //    if (this.entity.editor === this.entity.exploitingCompany.responsibleName && this.entity.editorEmail === this.entity.exploitingCompany.responsibleEmail) {
        //      this.checked = true;
        //    }

      }
      this.refreshDataSource();
      // this.updateAllZones();
    }


    if (!this._userOfficesSubscription) {
      this._userOfficesSubscription = this._officeService.getAll().subscribe((list: EntityOffice[]) => {
        if (list) {
          this.offices = TreeElement.orderTreeView(list, 'main');
          this.level = TreeElement.getLevels(list, 'main');
          /*
          list.forEach((office: EntityOffice) => {
            if (office.contacts) {
              if (office.contacts.findIndex((c: Contact) => c.user_id === userId)!==-1) {
                const userOffice = this._officeService.factory(office);
                userOffice.contacts = office.contacts.filter((c) => (c.user_id === this.getUserId()));
                this.userOffices.push(userOffice);
              }
            }
          });
          */
        }
      });
    }
  }

  refreshDataSource() {
    if (this.entity.site && this.entity.site.zones) {
      this.zonesDataSource = new MatTableDataSource(this.entity.site.zones);

      if (this.paginator) {
        this.zonesDataSource.paginator = this.paginator;
      }
      if (this.sort) {
        this.zonesDataSource.sort = this.sort;
      }
    }
  }

  addZone() {
    this.selectZone(null);
  }
  selectZone(zone: EntityZone) {
    const dialogRef = this.dialog.open(ZoneDialogComponent, {
      disableClose: false,
      width: '600px',
      data: {
        zone: zone,
        site: this.entity.site,
        allowedZones: this.getFilteredZones(),
        allowAllZones: ((zone && zone.name === '') || (this.entity.site.zones.findIndex((e) => (e.name === '')) === -1))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.entity.addZone(result);
        this.refreshDataSource();
      }
    }
    );
  }

  removeZone(zone) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer cette zone ?';
    if (zone.measures && zone.measures.length) {
      dialogRef.componentInstance.confirmMessage += '<br><b>Attention, cette zone comporte des mesures de prévention qui seront également supprimée</b>';
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.entity.removeZone(zone);
        this.refreshDataSource();
      }
    });

  }

  getEmptyZone() {
    let returned: EntityZone = new EntityZone();
    if (this.entity && this.entity.site && this.entity.site.repo_id) {
      const i: number = this.zoneList[this.entity.site._id] ? this.zoneList[this.entity.site._id].findIndex((z) => (z.name === '')) : -1;
      if (i !== -1) {
        returned = new EntityZone(this.zoneList[this.entity.site._id][i], true);
        returned.repo_id = this.zoneList[this.entity.site._id][i]._id;
      }
    }
    return returned;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._userOfficesSubscription) {
      this._userOfficesSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._zoneChangeSubscription) {
      this._zoneChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
  }


  protected save() {
    if (this.form.valid) {
      if (!this.entity._id) {
        // Create the reference code for this document
        this._entityService.getNewCode().then((code) => {
          this.entity.code = code;
          this.entity.name = this._entityService.getName(this.entity);
          this._save().then((e) => {
            this._afterSave(e);
          });
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      } else {
        this._save().then((e) => {
          this._afterSave(e);
        });
      }
      this.saveError = false;
    } else {
      this.saveError = true;
    }
  }
  /**
   * Search site
   * @private
   */
  protected _initSearchSite() {
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls[this.autocompleteoff + 'site_name']) {
      this.filteredSites = this.getFilteredSites((this.entity && this.entity.site && this.entity.site.name) ? this.entity.site.name : '');
      if (this.filteredSites && this.filteredSites.length > 200) {
        this.filteredSites = [];
      }
      this._siteChangeSubscription = this.form.controls[this.autocompleteoff + 'site_name'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.siteSelection) {
            this.siteSelection = false;
          } else {
            //TODO confirm si zones avec analyse de risque
            if (this.entity && this.entity.site && this.entity.site.repo_id) {
              this.entity.site.repo_id = '';
              if (this.entity.site.zones && this.entity.site.zones.length) {
                this.entity.site.zones = this.entity.site.zones.filter((z) => (!z.repo_id));
              }
              if (this.entity.site.zones && !this.entity.site.zones.length) {
                this.entity.site.zones.push(new EntityZone());
              }
              //this.entity.zone = null;
              //this.entity.point = '';
              //this.filteredZones = [];
              this.refreshDataSource();
              //   this.updateAllZones(false);
            }
            this.filteredSites = this.getFilteredSites(value);
          }
        });
    }
    /*
    if (!this._zoneChangeSubscription && this.form && this.form.controls && this.form.controls[this.autocompleteoff + 'point']) {
      this.filteredZones = this.getFilteredZones((this.entity && this.entity.point) ? this.entity.point : '');
      if (this.filteredZones && this.filteredZones.length > 200) {
        this.filteredZones = [];
      }
      this._zoneChangeSubscription = this.form.controls[this.autocompleteoff + 'point'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.zoneSelection) {
            this.zoneSelection = false;
          } else if (this.entity && this.entity.site) {
            this.entity.zone = null;
            this.filteredZones = this.getFilteredZones(value);
            this.updateAllZones();
          }
        });
    }
    */
  }
  /**
   * auto fills inputs for site data
   * @param site
   */
  public siteSelected(event, site: EntitySite) {
    //TODO confirm si zones avec analyse de risque
    if (event && event.isUserInput && site) {
      const entitySite: EntitySite = this._siteService.getNewEntity(site, true);
      entitySite._id = UUID.UUID();
      entitySite.repo_id = site._id;
      entitySite.zones = [new EntityZone(this.zoneList[site._id] ? this.zoneList[site._id].find((z) => (!z.name)) : new EntityZone())];
      this.entity.site = entitySite;
      if (this.entity.site.zones && !this.entity.site.zones.length) {
        this.entity.site.zones.push(new EntityZone());
      }
      //this.filteredZones = this.getFilteredZones((this.entity && this.entity.point) ? this.entity.point : '');
      /*
      if (!this.entity.zone || (this.entity.zone.repo_id && this.filteredZones.findIndex((e) => (e._id === this.entity.zone.repo_id)) === -1)) {
        this.entity.zone = this.getEmptyZone();
        this.entity.point = '';
      }
      */
      this.siteSelection = true;
      //      this.updateAllZones();
    }
  }
  /*
  public zoneSelected(event, zone: EntityZone) {
    if (zone) {
      this.entity.point = zone.name;
      const repo_id = zone._id;
      this.entity.zone = new EntityZone(zone, true);
      this.entity.zone.repo_id = repo_id;
      this.zoneSelection = true;
      this.updateAllZones();
    }
  }
*/
  protected getFilteredSites(value: string): EntitySite[] {
    let returned = [];
    if (this.entity && this.siteList) {
      returned = value ? this.siteList.filter(site => (comparableString(site.name).indexOf(comparableString(value)) !== -1)) : this.siteList;
    }
    return returned.length > 200 ? [] : returned;
  }
  protected getFilteredZones(): EntityZone[] {
    let returned = [];
    if (this.entity && this.entity.site && this.entity.site.zones && this.entity.site.repo_id && this.zoneList[this.entity.site.repo_id]) {
      returned = this.zoneList[this.entity.site.repo_id].filter(zone => (this.entity.site.zones.findIndex((e) => (e.name === zone.name)) === -1));
    }
    return returned.length > 200 ? [] : returned;
  }

  public _isArchived() {
    return this.entity && this.entity.status === 'Archivé';
  }
  onSyncStateChange(res) {
    if (this.entity && this.entity._id) {
      //console.log('WorkbookComponent onSyncStateChange ' + this.entity._id, res);
      this._updatePermissions(this.entity._id);
    }
  }

  public isModifiable() {
    return this.entity && this.entity.isModifiable();
  }
  public setPlacHolder() {
    if (this.entity.type === 'annual') {
      if (this.entity.simple) {
        this.placeHolderStartDate = 'Début de validité';
        this.placeHolderEndDate = 'Fin de validité';
        this.placeHolderDescription = 'Objet du protocole';
      } else {
        this.placeHolderStartDate = 'Début du contrat';
        this.placeHolderEndDate = 'Fin du contrat';
        this.placeHolderDescription = 'Nature de l\'opération';
      }
    } else {
      this.placeHolderStartDate = 'Début de l\'opération';
      this.placeHolderEndDate = 'Fin de l\'opération';
      this.placeHolderDescription = 'Nature de l\'opération';
    }
  }


  onChangeTimeStart(event) {
    //this.disableHourEnd = !this.entity.hourStart;
    this.entity.hourStart = event;
    if (this.entity && this.entity._id && this.form.valid) {
      this._save().then((e) => {
        this._afterSave(e);
      });
    }
  }

  onChangeTimeEnd(event) {
    this.entity.hourEnd = event;
    if (this.entity && this.entity._id && this.form.valid) {
      this._save().then((e) => {
        this._afterSave(e);
      });
    }
  }
  getUserList() {
    this._entityService
  }
}
