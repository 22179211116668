import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpRequest, HttpEventType, HttpErrorResponse} from '@angular/common/http';
// TODO: pourquoi avec et sans alias ?
import {throwError, throwError as observableThrowError, Subject, Observer, Observable, of, pipe} from 'rxjs';
import {map, timeout, catchError, retry, mergeMap} from 'rxjs/operators';
import {promiseAll} from 'app/shared/utils';
import {ConfigServerService, DocumentFileService, DocumentMetadataService} from 'app/shared/services';
import {LoggerService} from 'app/shared/logger';
import {b64toBlob, b64toFile} from 'app/shared/utils';
import {EntityConfigServer, EntityDocumentFile, EntityDocumentMetadata} from 'app/shared/models';

@Injectable()
export class DocumentService {

  configUploadServer: EntityConfigServer;
  docBlob: Blob;
  documentsMetadata: EntityDocumentMetadata[] = [];
  documentMetadata: EntityDocumentMetadata;
  iconsDoc: any = [];

  constructor(
    protected http: HttpClient,
    protected _configServerService: ConfigServerService,
    public documentFileService: DocumentFileService,
    public documentMetadataService: DocumentMetadataService,
    protected _logger: LoggerService,
    protected _route: ActivatedRoute
  ) {

    this._configServerService.getSingleOne().toPromise()
      .then((data: EntityConfigServer) => {
        this.configUploadServer = data;
      })
      .catch((e) => {
        _logger.error('DocumentService', 'unable to get config', JSON.stringify(e));
      });
  }
  getDocDBInfos(): {
    count: number,
    count_del: number,
    size: string
  } {
    return this.documentFileService.getDbInfos();
  }
  public getDocumentMetadataChildren(parent_id) {
    return this.documentMetadataService.getChildren(parent_id);
  }
  public getDocumentMetadataChildList(parent_id) {
    return this.documentMetadataService.getChildList(parent_id);
  }
  public getAttachmentFile(documentFile_id: string, mime: string, attachment: string) {
    return this.getDocumentFile(documentFile_id).pipe(
      mergeMap((documentFile: EntityDocumentFile) => {
        if (documentFile && documentFile.data && documentFile.data.length) {
          return Observable.create(function (observer: Observer<File>) {
            observer.next(b64toFile(documentFile.data, mime, attachment));
            observer.complete();
          });
        } else {
          return this.documentFileService.getAttachmentFile(documentFile_id, attachment);
        }
      }));
  }
  public downloadDocument(metadata_id: string) {
    return this.getDocumentMetada(metadata_id).toPromise().then((docMeta: EntityDocumentMetadata) => {
      this.downloadDocumentFromMetadata(docMeta);
    });
  }
  public downloadDocumentFromMetadata(docMeta: EntityDocumentMetadata) {
    if (docMeta.data_id, docMeta.mime, docMeta.displayName) {
      this.getAttachmentFile(docMeta.data_id, docMeta.mime, docMeta.displayName).toPromise().then((blob) => {
        this.downloadFile(blob, docMeta.name + (docMeta.ext ? '.' + docMeta.ext : ''));
      }).catch((error) => {
        this._logger.error('DocumentService', 'Error during get attachment', JSON.stringify(error));
      });
    }
  }
  public downloadFile(blob, name) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
  }
  public getChildrenDocumentFile(parent_id: string) {
    return this.getDocumentMetadataChildList(parent_id).pipe(
      mergeMap((metas) => {
        var promises = metas.map(function (meta: EntityDocumentMetadata) {
          return this.getDocumentFile(meta.data_id);
        });
        return promiseAll(promises);
      }));
  }
  public getDocumentFileByMetadataId(metadata_id) {
    return this.getDocumentMetada(metadata_id).pipe(
      mergeMap((meta) => {
        return this.getDocumentFile(meta['data_id']);
      }));
  }
  /**
   * Get document uploaded in CouchDB by its id
   * @param document_id
   */
  public getDocumentFile(file_id) {
    return this.documentFileService.get(file_id);
  }
  /**
     * Get document uploaded in CouchDB by its id
     * @param document_id
     */
  public getDocumentMetada(id) {
    return this.documentMetadataService.get(id);
  }

  public getNewDocumentMetada(data = {}, clone = false) {
    return this.documentMetadataService.getNewEntity(data, clone);
  }

  /**
   * Upload files to server
   * @returns {{[p: string]: Observable<number>}}
   * @param file
   * @param newFileName
   * @param progress
   */
  protected _uploadFilesToServer(file: File, newFileName: string, progress: Subject<number>) {

    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append('file', file, encodeURI(newFileName));

    // create a http-post request and pass the form
    // tell it to report the upload progress

    const req = new HttpRequest('POST', this.configUploadServer.url, formData, {
      reportProgress: true
    });

    // send the http-request and subscribe for progress-updates
    this.http.request(req).pipe(
      timeout(200),
      retry(this.configUploadServer.retry),
      catchError(this._handleError)
    ).subscribe(event => {
      switch (event.type) {
        case HttpEventType.Sent:
          progress.next(0);
          break;
        case HttpEventType.UploadProgress:
          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);
          // pass the percentage into the progress-stream
          progress.next(percentDone);
          break;
        case HttpEventType.Response:
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
          break;
        default:
          break;
      }
    }
    );
  }

  /**
   * Upload files to couchDB (in pdp object)
   * @param {Set<File>} files
   * @returns {{[p: string]: Observable<number>}}
   */
  public uploadFiles(files: Set<File>, context: string = '', parent_id: string = ''): {[key: string]: Observable<number>} {
    const status = {};

    files.forEach(file => {
      const date = new Date();
      const prefix = date.getDate() + date.getMonth() + date.getFullYear() + date.getTime();
      const newFileName = prefix + '_' + file.name;
      status[file.name] = {
        progress: this.uploadFile(file, newFileName, context, parent_id).asObservable()
      };
    });
    return status;
  }

  public uploadAttachment(file: File, documentFile_id: string = ''): Subject<any> {
    const progress = new Subject<number>();
    progress.next(0);
    this.documentFileService.get(documentFile_id).toPromise().then((savedDocumentFile) => {
      this.documentFileService.saveAttachmentFile(savedDocumentFile, file.type, file).then((sa) => {
        progress.next(savedDocumentFile);
        progress.complete();
      }).catch((e) => {
        this._logger.error('DocumentService', 'An error occurred during save document attachment', JSON.stringify(e));
        progress.complete();
      });
    }).catch((e) => {
      this._logger.error('DocumentService', 'An error occurred during get saved document', JSON.stringify(e));
      progress.complete();
    });
    return progress;
  }
  /**
     * Upload files to couchDB (in pdp object)
     * @param {Set<File>} files
     * @returns {{[p: string]: Observable<number>}}
     */
  public uploadFile(file: File, newFileName: string, context: string = '', parent_id: string = null, documentMetadata: EntityDocumentMetadata = null): Subject<any> {


    const progress = new Subject<number>();
    const documentFile = this.documentFileService.getNewEntity();
    // patch pour ecriture remote directe... à revoir
    delete documentFile._rev;
    this.documentFileService.save(documentFile).then((sf) => {
      //progress.next(0);
      // if active, upload doc to server with node plugin
      if (this.configUploadServer.active) {
        this._uploadFilesToServer(file, newFileName, progress);
      }
      //TODO : refaire le get by id ?

      this.documentFileService.get(documentFile._id).toPromise().then((savedDocumentFile) => {
        this.documentFileService.saveAttachmentFile(savedDocumentFile, file.type, file).then((sa) => {
          if (parent_id) {
            const documentMetadata = this.documentMetadataService.getNewEntityFromFile(file, context, parent_id, savedDocumentFile._id);
            this.documentMetadataService.save(documentMetadata).then((sm) => {
              progress.complete();
            }).catch((e) => {
              this._logger.error('DocumentService', 'An error occurred during save document metadata', JSON.stringify(e));
              progress.complete();
            });
          } else {
            progress.next(savedDocumentFile);
            progress.complete();
          }
        }).catch((e) => {
          this._logger.error('DocumentService', 'An error occurred during save document attachment', JSON.stringify(e));
          progress.complete();
        });
      }).catch((e) => {
        this._logger.error('DocumentService', 'An error occurred during get saved document', JSON.stringify(e));
        progress.complete();
      });
    }).catch((e) => {
      this._logger.error('DocumentService', 'An error occurred during save document', [JSON.stringify(documentFile), JSON.stringify(e)]);
      progress.complete();
    });
    return progress;
  }

  public uploadFilePromise(file: File, newFileName, context, parent_id = null, data_id: string = null): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data_id) {
        this.documentFileService.get(data_id).toPromise().then((savedDocumentFile) => {
          //console.log(savedDocumentFile);
          this.documentFileService.saveAttachmentFile(savedDocumentFile, file.type, file).then((sa) => {
            if (parent_id) {
              const documentMetadata = this.documentMetadataService.getNewEntityFromFile(file, context, parent_id, savedDocumentFile._id);
              this.documentMetadataService.save(documentMetadata).then((sm) => {
                resolve(true);
              }).catch((e) => {
                this._logger.error('DocumentService', 'An error occurred during save document metadata', JSON.stringify(e));
                reject();
              });
            } else {
              setTimeout(() => {resolve(savedDocumentFile)}, 1000);
            }
          }).catch((e) => {
            this._logger.error('DocumentService', 'An error occurred during save document attachment', JSON.stringify(e));
            reject(savedDocumentFile);
          });
        }).catch((e) => {
          this._logger.error('DocumentService', 'An error occurred during get saved document', JSON.stringify(e));
          reject(data_id);
        });
      } else {

        const newDocumentFile = this.documentFileService.getNewEntity();
        // patch pour ecriture remote directe... à revoir
        delete newDocumentFile._rev;
        this.documentFileService.save(newDocumentFile).then((sf) => {
          this.uploadFilePromise(file, newFileName, context, parent_id, newDocumentFile._id)
            .then((res) => {resolve(res)})
            .catch((e) => {
              reject(e);
            })
        }).catch((e) => {
          this._logger.error('DocumentService', 'An error occurred during save document', [JSON.stringify(newDocumentFile), JSON.stringify(e)]);
          reject(newDocumentFile);
        });
      }
    });

  }

  /**
   * Upload image to server
   * @param {string} returnedDataType
   * @param {string} downloadData
   * @returns {Observable<boolean>}
   */
  public uploadImage(returnedDataType: string = 'image/png', downloadData?: string): Observable<boolean> {
    const pathArray: string[] = this._route.snapshot.url.map(p => p.path);
    const parent_id = pathArray && pathArray.length > 1 ? pathArray[1] : '';
    const context = pathArray.join('/');

    const date = new Date();
    const prefix = date.getDate() + date.getMonth() + date.getFullYear() + date.getTime();
    const newFileName = prefix + '_image' + this._generateDataType(returnedDataType);
    const formData: FormData = new FormData();
    const block = downloadData.split(';');
    const realData = block[1].split(',')[1];

    const documentFile = this.documentFileService.getNewEntity({data: realData});
    this.documentFileService.save(documentFile).then((rf) => {
      const documentMetadata = this.documentMetadataService.getNewEntity({
        name: this.documentMetadataService.getFilenameName(newFileName),
        ext: this.documentMetadataService.getFilenameExt(newFileName),
        data_id: documentFile._id,
        mime: returnedDataType,
        size: this.documentMetadataService.formatBytes(realData.length),
        icon: this.documentMetadataService.getIcon(returnedDataType),
        parent_id: parent_id,
        context: context
      });
      this.documentMetadataService.save(documentMetadata);
    });

    // create object
    // const documentMetadata = new DocumentMetadata();
    // documentMetadata.name = newFileName;
    // documentMetadata.mime = returnedDataType;
    // documentMetadata.data = realData;
    // this.documentMetadata = documentMetadata;

    // Convert it to a blob to upload
    if (this.configUploadServer.active) {
      const blob = b64toBlob(realData, returnedDataType);
      this.docBlob = blob;
      formData.append('file', blob, newFileName);
      const req = new HttpRequest('POST', this.configUploadServer.url, formData, {
        reportProgress: true
      });

      return this.http.request(req).pipe(timeout(200)).pipe(
        retry(this.configUploadServer.retry)
      ).pipe(map(res => {
        return res.type === 4;
      }),
        catchError((error: any) => observableThrowError(error.error || 'Error Server')));
    } else {
      return of(true);
    }
  }

  /**
   * Create the extension of image
   * @param {string} returnedDataType
   * @returns {string}
   * @private
   */
  private _generateDataType(returnedDataType: string): string {
    if (returnedDataType) {
      return '.' + returnedDataType.split('/')[1];
    }
    return '';
  }

  /**
   * Handle errors for http requests
   * @param {HttpErrorResponse} error
   * @returns {Observable<never>}
   * @private
   */
  private _handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this._logger.error('UploadService', 'An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this._logger.error('UploadService',
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  /**
   * Delete document from server
   * @param {string} name
   */
  public deleteServerDoc(name: string) {
    this.http.delete(this.configUploadServer.url + '/' + name/*, this.httpOptions*/)
      .subscribe(
        data => {
          this._logger.info('UploadService', 'DELETE Request is successful', data);
        },
        error => {
          this._logger.error('UploadService', 'Error', JSON.stringify(error));
        }
      );
  }

  /**
   * Delete documentMetadata and documentFile from DBs
   * @param {string} id
   */
  public remove(id: string) {
    return this.documentMetadataService.get(id)
      .pipe(map((meta) => {
        this.removeMetadata(meta);
      })).toPromise();

  }
  /**
   * Delete document from DB
   * @param {EntityDocumentMetadata} meta
   */
  public removeMetadata(meta: EntityDocumentMetadata, removeFile = true) {
    if (meta['data_id'] && removeFile) {
      this.documentFileService.get(meta['data_id']).toPromise().then((file) => {
        if (this.configUploadServer.active) {
          this.deleteServerDoc(meta.displayName);
        }
        this.documentFileService.remove(file).then((res) => {
          this.documentMetadataService.remove(meta);
        });
      }).catch((err) => {
        //deleted file ?
        this._logger.warn('removeMetadata: no file', err);
        //TODO : remove meta ? set data_id ?
      });
    } else {
      this.documentMetadataService.remove(meta);
    }

  }
}
