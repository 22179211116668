import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, PermissionsConfig, EntityFormComponent} from 'app/shared';
import {RoleService} from 'app/shared/services';
import {EntityRole} from 'app/shared/models';

@Component({
  selector: 'app-config-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent extends EntityFormComponent {

  entity: EntityRole;
  autoSave: false;
  groupList: string[] = [];
  permissionsConfig: PermissionsConfig[] = [];
  permissionsList: string[][][] = [];
  permissionsArray: {[permission: string]: boolean} = {};
  // gestion des open/close detail
  rolesDetail: boolean = false;
  showModule: {[module_name: string]: boolean} = {};
  constructor(
    public dialog: MatDialog,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: RoleService,
    protected _permissionsService: PermissionsService

  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if ((this.entity.name === 'PP_ADMIN_TREPIED' || this.entity.name === 'PP_ADMIN') && !this._permissionsService.hasRole('PP_ADMIN_TREPIED')) {
      this.rightU = false;
      this.rightD = false;
    }
    if (data) {
      this.permissionsConfig = this._permissionsService.permissionsConfig;
      this.groupList = this._permissionsService.permissionsConfig.map(g => g.name);
      this.rolesDetail = false;
      if (this.entity && this.entity.permissions) {
        this.updatePermissionsArray();
      }
    }
  }
  canModifyPermission(name: string) {
    /*
    if (
      !(this.entity.name === 'PP_ADMIN' || this.entity.name === 'PP_ADMIN_TREPIED')
      && PermissionsService.ADMIN_ONLY.indexOf(name) !== -1
      && !this.entity.permissions[name]
    ) {
      console.log('disable ' + name, [!(this.entity.name === 'PP_ADMIN' || this.entity.name === 'PP_ADMIN_TREPIED'), PermissionsService.ADMIN_ONLY.indexOf(name) !== -1, !this.entity.permissions[name]]);
    }
    */
    return !(
      !(this.entity.name === 'PP_ADMIN' || this.entity.name === 'PP_ADMIN_TREPIED')
      && PermissionsService.ADMIN_ONLY.indexOf(name) !== -1
      && !this.entity.permissions[name]
    )
      && (this._permissionsService.hasRole('PP_ADMIN_TREPIED') || (this.rightU && this._permissionsService.hasPermission(name)));
  }
  canModifyName(name: string) {
    return !(EntityRole.ROLES_ORDER.indexOf(name) !== -1 && this._permissionsService.hasRole('PP_ADMIN_TREPIED'));
  }
  isShowPermission(name: string) {
    return name && name.substring(0, 4) === 'show';
  }
  /**
     * update permissionsArray from permissions (role/permissionsList)
     */
  private updatePermissionsArray() {
    this.permissionsList = this.permissionsConfig
      .map((_m) => (_m.groups
        .map((_g) => (Object.keys(_g.permissions)))
      )
      );
    this.permissionsArray = this.getPermissionsArray(this.entity.permissions);
  }
  /**
     * return permissions parameters as boolrean array based on permissionsList
     * @param {string[]} permissions
     * @return {boolean[]} permissionsArray line
     */
  private getPermissionsArray(permissions: string[] = []): {[permission: string]: boolean} {
    let returned: {[permission: string]: boolean} = {};
    this._permissionsService.permissionsConfig
      .forEach((_m) => {
        _m.groups.forEach((_g) => {
          Object.keys(_g.permissions).forEach((p: string) => {
            returned[p] = (permissions.indexOf(p) !== -1);
          })
        })
      });
    return returned;
    // return this.permissionsList.map((p) => (permissions.indexOf(p) !== -1));
  }
  /**
  * add permission name on role i
  * @param i
  * @param name
  */
  private addPermission(name: string) {
    if (this.entity
      && this.entity.permissions
      && this.entity.permissions.indexOf(name) === -1
      && this.canModifyPermission(name)) {
      this.entity.permissions.push(name);
    }
  }

  /**
   * remove permission j on role i
   * @param i
   * @param name
   */
  private removePermission(name: string) {
    if (this.entity
      && this.entity.permissions
      && this.entity.permissions.indexOf(name) !== -1
      && this.canModifyPermission(name)) {
      const index = this.entity.permissions.indexOf(name);
      if (index !== -1) {
        this.entity.permissions.splice(index, 1);
      }
    }
  }
  /*
     *   toggle permission j on role i
      * @param {number} role index (permissions)
      * @param {number} permission index (permissionList
      * @param {MatSlideToggleChange } permission index (permissionList
      */
  togglePermission(name: string, event) {
    if (event && event.checked) {
      this.addPermission(name);
    } else {
      this.removePermission(name);
    }
    if (this.entity && this.entity._id && this.form.valid && this.autoSave) {
      this._save();
    }
  }

  public addPermissionGroup(i: number, j: number) {
    if (this.permissionsList[i] && this.permissionsList[i][j] && this.permissionsList[i][j].length) {
      this.permissionsList[i][j].forEach((perm) => {
        this.addPermission(perm);
      });
      this.updatePermissionsArray();
    }
  }
  public removePermissionGroup(i: number, j: number) {
    if (this.permissionsList[i] && this.permissionsList[i][j] && this.permissionsList[i][j].length) {
      this.permissionsList[i][j].forEach((perm) => {
        this.removePermission(perm);
      });
      this.updatePermissionsArray();
    }
  }
}
