import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AuthService } from 'app/shared/auth';
import { LoggerService } from 'app/shared/logger';
import { LoggerConfigService } from 'app/shared/logger/loggerConfig.service';
import { ErrorDialogComponent } from 'app/shared/components';
import { LogService } from 'app/shared/logger/log.service';
import { Log } from './log.model';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  log: Log;

  public currentUser: { given_name, family_name } = {
    given_name: '',
    family_name: ''
  };

  constructor(public dialog: MatDialog,
    // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    private injector: Injector
  ) {
  }

  handleError(error: Error | HttpErrorResponse): void {

    const logService = this.injector.get(LogService);
    const configService = this.injector.get(LoggerConfigService);
    const auth = this.injector.get(AuthService);
    const logger = this.injector.get(LoggerService);
    const logObj = new Log({});

    auth.userProfile$
      .subscribe((user) => {
        this.currentUser = {
          given_name: user.user_metadata && user.user_metadata.given_name ? user.user_metadata.given_name : 'Prénom',
          family_name: user.user_metadata && user.user_metadata.family_name ? user.user_metadata.family_name : 'Nom'
        };
      });
    logObj.user = this.currentUser.given_name + ' ' + this.currentUser.family_name;

    configService.getSingleOne().toPromise().then((data) => {
      if (data.logDb) {
        // TODO : problem with close dialog which take lot of time
        if (error instanceof HttpErrorResponse) {
          // Server or connection error happened
          if (!navigator.onLine) {
            // Handle offline error
            // this._dialogError(error);
            logObj.status = error.status;
            logObj.errorMsg = 'Erreur de connexion internet: ' + error.message;
          } else {
            // Handle Http Error (error.status === 403, 404...)
            // this._dialogError(error);
            logObj.status = error.status;
            logObj.errorMsg = error.message;
          }
        } else {
          // Handle Client Error (Angular Error, ReferenceError...)
          // this._dialogError(error);
          logObj.errorMsg = error.message;
        }
        logService.save(logService.getNewEntity(logObj))
          .catch((error) => {
            console.log('ErrorsHandler save error', [error, logObj]);
          });
      }
      // Log the error anyway
      const getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
              return;
            }
            seen.add(value);
          }
          return value;
        };
      };
      logger.error('ErrorsHandler', 'Error: ', error);
      logger.error('ErrorsHandler', 'Error: ', JSON.stringify(error, getCircularReplacer()));
    })
      .catch((e) => {
        logger.error('ErrorsHandler', 'Unable to get config', JSON.stringify(e));
      });
  }

  private _dialogError(error: any) {
    const test = this.injector.get(MatDialog);
    let msg = '';
    const dialogRefErr: MatDialogRef<ErrorDialogComponent> = test.open(ErrorDialogComponent, {
      disableClose: false,
      autoFocus: true,
      width: '600px',
    });
    if (error instanceof HttpErrorResponse) {
      msg = 'Erreur de connexion réseau : ';
      dialogRefErr.componentInstance.errorMessage = msg + error.message;
    } else {
      msg = 'Erreur : ';
      dialogRefErr.componentInstance.errorMessage = msg + error.toString();
    }
  }
}
