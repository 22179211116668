import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {LoggerService, EntitySimpleListComponent, ConfirmDialogComponent} from 'app/shared';
import {CategoryService, EntityCategory} from 'app/workbook-core';

@Component({
  selector: 'app-repository-category-list',
  templateUrl: './categoryList.component.html',
  styleUrls: ['./categoryList.component.scss']
})

export class CategoryListComponent extends EntitySimpleListComponent {

  entityList: EntityCategory[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce metier ?';
  addConfirmMessage = 'Nouveau métier créé';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: CategoryService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }


}
