import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {JobService, EntityJob} from 'app/workbook-core';

@Component({
  selector: 'app-repository-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent extends EntityFormComponent {

  entity: EntityJob;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: JobService

  ) {
    super(dialog, _logger, _entityService);
  }

}
