import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, LoadingService} from 'app/shared';
import {EntityExternalCompany} from '../../repository';
import {WorkbookOfficeService} from './workbookOffice.service';

@Injectable({
  providedIn: 'root'
})
export class WorkbookExternalCompanyService extends WorkbookOfficeService {
  onlyChildren = true;
  static LABEL_ONE = "chargement de l'entreprise de l'opération";
  static LABEL_ALL = "chargement des entreprises de l'opération ?";
  static LABEL_CHILDREN = "chargement des entreprises de l'opération";
  static LABEL_SAVE = "sauvegarde de l'entreprise de l'opération";
  static LABEL_DELETE = "suppression de l'entreprise de l'opération";

  static ENTITY_TYPE: string = 'wb_eex';
  static ENTITY_FIELDS: string[] = ['name', 'address', 'zipCode', 'city', 'job', 'siret', 'internalCode', 'workforce_max', 'workforce_total', 'workforce_volume', 'main', 'contacts', 'operators', 'documents'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showEexPP'],
    CREATE: ['createEexPP'],
    UPDATE: ['editEexPP'],
    DELETE: ['removeEexPP']
  }

  static ENTITY_URL = 'externalCompany';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityExternalCompany {
    return new EntityExternalCompany(data, clone);
  }

}
