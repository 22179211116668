import {Entity, IDocumentCategory} from 'app/shared';

export class EntityCategory extends Entity implements IDocumentCategory {
  public name: string;
  public picto: string;
  public limited: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.picto = (data && data.picto) ? data.picto : '';
    this.limited = (data && data.limited);
  }
}
