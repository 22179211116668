import {Entity} from './entity.model';

export class ExtraField {
  static TYPE_TEXT: string = 'text';
  static TYPE_NUMBER: string = 'number';
  static TYPE_CHECKBOX: string = 'checkbox';
  static TYPE_RADIO: string = 'radio';
  static TYPE_DATE: string = 'date';
  static TYPE_DATEVALID: string = 'dateValid';
  static TYPES: string[] = [
    ExtraField.TYPE_TEXT,
    ExtraField.TYPE_NUMBER,
    ExtraField.TYPE_CHECKBOX,
    ExtraField.TYPE_RADIO,
    ExtraField.TYPE_DATE,
    ExtraField.TYPE_DATEVALID
  ];

  static OPTION_DEFAULT: string = 'default';
  static OPTION_TYPE: string = 'type';
  static OPTION_PATTERN: string = 'pattern';
  static OPTION_MIN: string = 'min';
  static OPTION_MAX: string = 'max';
  static OPTION_STEP: string = 'step';
  static OPTION_VALUES: string = 'values';
  static TYPE_OPTIONS_LABEL: {[type: string]: string} = {
    'default': 'Valeur par défaut',
    'type': 'Type de contenu (input type)',
    'pattern': 'Pattern (regexp de validation)',
    'min': 'Minimum',
    'max': 'Maximum',
    'step': 'Incrément',
    'values': 'Liste des valeurs'
  };
  static TYPE_OPTIONS: {[type: string]: string[]} = {
    'text': [ExtraField.OPTION_TYPE, ExtraField.OPTION_PATTERN, ExtraField.OPTION_DEFAULT],
    'number': [ExtraField.OPTION_MIN, ExtraField.OPTION_MAX, ExtraField.OPTION_STEP, ExtraField.OPTION_PATTERN, ExtraField.OPTION_DEFAULT],
    'checkbox': [ExtraField.OPTION_DEFAULT],
    'radio': [ExtraField.OPTION_VALUES, ExtraField.OPTION_DEFAULT],
    'date': [/*, ExtraField.OPTION_DEFAULT*/],
    'dateValid': [/*, ExtraField.OPTION_DEFAULT*/]
  };
  name: string;
  type: 'checkbox' | 'text' | 'number' | 'radio' | 'date' | 'dateValid';
  repository_field: boolean;
  repository_value: boolean;
  workbook_field: boolean;
  workbook_value: boolean;
  permit_field: boolean;
  permit_value: boolean;
  required: boolean;
  label: string;
  options: {[key: string]: string | number | boolean};
  constructor(data: any = null) {
    this.name = (data && data['name']) ? data['name'] : '';
    this.repository_field = !!(data && data['repository_field']);
    this.repository_value = !!(data && data['repository_value']);
    this.workbook_field = !!(data && data['workbook_field']);
    this.workbook_value = !!(data && data['workbook_value']);
    this.permit_field = !!(data && data['permit_field']);
    this.permit_value = !!(data && data['permit_value']);
    this.required = !!(data && data['required']);
    this.type = (data && data['type']) ? data['type'] : ExtraField.TYPE_TEXT;
    this.label = (data && data['label']) ? data['label'] : '';
    this.options = {};
    if (data && data['options'] && Object.keys(data['options']).length) {
      Object.keys(data['options']).forEach((k) => {
        this.options[k] = data['options'][k];
      });
    }

  }
}
export class EntityExtraConfig extends Entity {
  static TYPES: string[] = ['operator'];
  public operator: ExtraField[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.operator = (data && data.operator && data.operator.length) ? data.operator.map((xtra) => (new ExtraField(xtra))) : [];
  }
}
