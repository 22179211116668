import {UUID} from 'angular2-uuid';
import {
  EntityCodable,
  formattedDateCode,
  addZero, Operator, Contact
} from 'app/shared';
import {EntityExploitingCompany, SignableStatus} from 'app/shared/models';
import {EntitySite, EntityZone, EntityIcpRecurrence} from '../../repository';
import {ICPState} from './entityIcp.model';
import {AuditState} from './entityAudit.model';

export class EntityWorkbook extends EntityCodable {

  static PREFIXES = {
    occasional: 'OO',
    annual: 'OP'
  };

  static STATUS_ARCHIVE: string = 'Archivé';
  static STATUS_DRAFT: string = 'Préparation';
  static STATUS_PROGRESS: string = 'En cours';
  static STATUS_COMPLETE: string = 'Terminé';
  static STATUS_COLOR: {[status: string]: string} = {
    'Archivé': '#909AA0',
    'Préparation': '#f9b233',
    'En cours': '#14aea7',
    'Terminé': '#6f6f6e'
  };
  static STATUS_CODE: {[status: string]: string} = {
    'Archivé': 'archived',
    'Préparation': 'prepared',
    'En cours': 'progress',
    'Terminé': 'done'
  };
  public name: string;
  public reference: string;
  public description: string;
  // status : in_preparation, in_progress, completed, archived
  public status: string;
  // annual, occasional
  public type: string;
  // only one step
  public simple: boolean;

  public editorId: string;
  public editor: string;
  public editorEmail: string;

  public dateStart: Date;
  public dateEnd: Date;
  public hourStart: string;
  public hourEnd: string;
  public workConditions: string;
  public site: EntitySite;

  public point: string;
  public zone: EntityZone
  // saved when archived
  public exploitingCompany: EntityExploitingCompany;
  // TODO: sign_agreement ? dans les signatures ? => export si heterogène ?
  public signAgreement: string;
  //eex principale
  public eex_name: string;
  public eex_id: string;
  //EEx et leur eventuel repo_id
  public eexs: {[eex_name: string]: string};
  //autorized users
  public users: string[];
  //operators
  public operators: Operator[];
  //contacts
  public eex_contacts: {[oeex_name: string]: Contact[]};
  public office_contacts: {[oeex_name: string]: Contact[]};
  //visibility
  public offices: string[];
  //icpRecurrence (template)
  public inspections: {
    //pour chaque step necessitant ICP
    //tout sauf interventions liées à un CT et la step des protocoles ("annuel simple")
    [step_id: string]: {
      //info step pour affichage et lien
      name: string,
      template: boolean,
      dateStart: Date,
      dateEnd: Date,
      //liste des ICP
      scheduled: {
        [icp_id: string]: {
          name: string,
          date: Date,
          status: number,
          steps: string[]
        }
      },
      audit: {
        [audit_id: string]: {
          name: string,
          date: Date,
          status: number,
          step: string
        }
      },
      //interventions liées (si chantier type)
      interventions: {
        [intervention_id: string]: {
          name: string,
          dateStart: Date,
          dateEnd: Date
        }
      },
      //0 et pas nextIcp => avant prochaine intervention
      score: number,
      //mode accident => autre period (parametrable) pendant durée (parametrable)
      lastAccident: Date | null
    }
  };
  public pdps: {
    //pour chaque step necessitant ICP
    //tout sauf interventions liées à un CT et la step des protocoles ("annuel simple")
    [step_id: string]: {
      //info step pour affichage et lien
      name: string,
      template: boolean,
      dateStart: Date,
      dateEnd: Date,
      //liste des ICP
      editions: {
        [pdp_id: string]: {
          name: string,
          date: Date,
          status: number,
          steps: string[],
          icps: string[]
        }
      }
    }
  };
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.reference = (data && data.reference) ? data.reference : '';
    this.description = (data && data.description) ? data.description : '';
    this.type = (data && data.type) ? data.type : 'occasional';
    this.simple = !!(data && data.simple);
    this.editorId = (data && data.editorId) ? data.editorId : ((data && data.createdId) ? data.createdId : '');
    this.editor = (data && data.editor) ? data.editor : ((data && data.createdName) ? data.createdName : '');
    this.editorEmail = (data && data.editorEmail) ? data.editorEmail : '';
    this.dateStart = (data && data.dateStart) ? new Date(data.dateStart) : null;
    this.dateEnd = (data && data.dateEnd) ? new Date(data.dateEnd) : null;
    this.hourStart = (data && data.hourStart) ? data.hourStart : '';
    this.hourEnd = (data && data.hourEnd) ? data.hourEnd : '';
    this.workConditions = (data && data.workConditions) ? data.workConditions : '';
    this.site = (data && data.site) ? new EntitySite(data.site) : new EntitySite();
    if (!this.site._id) {
      this.site._id = UUID.UUID();
    }
    this.point = (data && data.point) ? data.point : '';
    this.zone = (data && data.zone) ? new EntityZone(data.zone) : new EntityZone({name: this.point});
    if (this.site.zones.findIndex((e) => (e.name === this.zone.name)) === -1) {
      this.site.zones.push(this.zone);
    }

    this.exploitingCompany = (data && data.exploitingCompany) ? Object.assign({}, data.exploitingCompany) : new EntityExploitingCompany();
    this.signAgreement = (data && data.signAgreement) ? data.signAgreement : '';

    this.eex_name = (data && data.eex_name) ? data.eex_name : '';
    this.eex_id = (data && data.eex_id) ? data.eex_id : '';
    this.eexs = (data && data.eexs) ? Object.assign({}, data.eexs) : {};
    //rattrapage mais sans repo_id du coup...
    //if (this.eex_name && !this.eexs[this.eex_name]) {
    //  this.eexs[this.eex_name] = '';
    //}

    this.users = (data && data.users) ? data.users.map((e) => e) : [];
    this.operators = (data && data.operators) ? data.operators.map((e) => new Operator(e)) : [];
    this.eex_contacts = {};
    if (data && data.eex_contacts) {
      Object.keys(data.eex_contacts).forEach((oeex_name) => {
        this.eex_contacts[oeex_name] = data.eex_contacts[oeex_name] ? data.eex_contacts[oeex_name].map((e) => Object.assign(new Contact(), e)) : [];
      });
    }
    this.office_contacts = {};
    if (data && data.office_contacts) {
      Object.keys(data.office_contacts).forEach((oeex_name) => {
        this.office_contacts[oeex_name] = data.office_contacts[oeex_name] ? data.office_contacts[oeex_name].map((e) => Object.assign(new Contact(), e)) : [];
      });
    }
    this.offices = (data && data.offices) ? data.offices.map((e) => e) : null;

    this.status = (data && data.status) ? data.status : '';
    this.status = this.getStatus();

    this.inspections = (data && data.inspections) ? Object.assign({}, data.inspections) : {};
    this.pdps = (data && data.pdps) ? Object.assign({}, data.pdps) : {};

  }
  getWorkbookType() {
    return (this.type === 'occasional')
      ? (this.simple ? 'simple' : 'occasional')
      : (this.simple ? 'protocol' : 'annual');
  }
  getStatusColor() {
    return EntityWorkbook.STATUS_COLOR[this.getStatus()];
  }
  isModifiable() {
    return this.getStatus() !== EntityWorkbook.STATUS_ARCHIVE;
    // let start = new Date(this.dateStart);
    // let now = new Date();
    //    const readonlyStatus = [EntityWorkbook.STATUS_ARCHIVE, EntityWorkbook.STATUS_COMPLETE]
    //    return /*(now.getTime() < start.getTime()) && */readonlyStatus.indexOf(this.getStatus()) === -1;
  }
  getStatus() {
    return EntityWorkbook.getWorkbookStatus(this);
  }
  static getWorkbookStatus(entity: EntityWorkbook) {
    return EntityWorkbook.getValueStatus(entity.status, entity.dateStart ? new Date(entity.dateStart) : null, entity.dateEnd ? new Date(entity.dateEnd) : null, entity.hourStart, entity.hourEnd);
  }
  static getValueStatus(status: string, dateStart: Date, dateEnd: Date, hourStart: string = '00:00', hourEnd: string = '23:59') {
    const now = new Date();
    let hour: number = 0;
    let min: number = 0;
    if (hourStart) {
      const t = hourStart.split(':');
      if (t[0] && +t[0]) {
        hour = +t[0];
      }
      if (t[1] && +t[1]) {
        min = +t[1];
      }
    }
    const start = dateStart ? new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate(), hour, min) : null;

    hour = 23;
    min = 59;
    if (hourEnd) {
      const t = hourEnd.split(':');
      if (t[0] && +t[0]) {
        hour = +t[0];
      }
      if (t[1] && +t[1]) {
        min = +t[1];
      }
    }
    const end = dateEnd ? new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(), hour, min, 59) : null;
    return status === EntityWorkbook.STATUS_ARCHIVE
      ? EntityWorkbook.STATUS_ARCHIVE
      : (!start || now < start) ? EntityWorkbook.STATUS_DRAFT
        : (!end || now <= end) ? EntityWorkbook.STATUS_PROGRESS
          : EntityWorkbook.STATUS_COMPLETE

  }
  isComplete() {
    return (this.getStatus() === EntityWorkbook.STATUS_COMPLETE);
  }
  static getNameOf(entity: EntityWorkbook) {
    //TODO: PREFIX par type
    return EntityWorkbook.PREFIXES[entity.type]
      + formattedDateCode(entity.createdAt) + '-' + addZero(entity.code, 3);
  }

  addZone(zone: EntityZone) {
    if (this.site && this.site.zones && this.site.zones.findIndex((e) => (e.name === zone.name)) === -1) {
      this.site.zones.push(zone);
    }
  }
  removeZone(zone: EntityZone) {
    if (this.site && this.site.zones) {
      const i = this.site.zones.findIndex((e) => (e.name === zone.name));
      if (i !== -1) {
        this.site.zones.splice(i, 1);
      }
      if (!this.site.zones.length) {
        this.point = '';
        this.zone = new EntityZone();
        this.site.zones.push(new EntityZone(this.zone));
      } else if (this.zone && this.zone.name === zone.name) {
        this.zone = new EntityZone(this.site.zones[0]);
      }
    }
  }
  getLastIcpInfos(step_id: string, minStatus: number = SignableStatus.VALIDATED): {id: string, name: string, date: Date, status: number} {
    let date: Date;
    const now = new Date();
    let infos: {id: string, name: string, date: Date, status: number};
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() < now.getTime()
            && (!date || icpDate.getTime() > date.getTime())
            && this.inspections[step_id].scheduled[icp_id].status >= minStatus) {
            date = icpDate;
            infos = {
              id: icp_id,
              name: this.inspections[step_id].scheduled[icp_id].name,
              date: new Date(this.inspections[step_id].scheduled[icp_id].date),
              status: this.inspections[step_id].scheduled[icp_id].status
            };
          }
        }
      });
    }
    return infos;
  }
  getNextIcpInfos(step_id: string): {id: string, name: string, date: Date, status: number} {
    let date: Date;
    const now = new Date();
    let infos: {id: string, name: string, date: Date, status: number};
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() > now.getTime()
            && (!date || icpDate.getTime() < date.getTime())) {
            date = icpDate;
            infos = {
              id: icp_id,
              name: this.inspections[step_id].scheduled[icp_id].name,
              date: new Date(this.inspections[step_id].scheduled[icp_id].date),
              status: this.inspections[step_id].scheduled[icp_id].status
            };
          }
        }
      });
    }
    return infos;
  }

  getLastPdpInfos(step_id: string, minStatus: number = SignableStatus.VALIDATED): {id: string, name: string, date: Date, status: number} {
    let date: Date;
    const now = new Date();
    let infos: {id: string, name: string, date: Date, status: number};
    if (this.pdps
      && this.pdps[step_id]
      && this.pdps[step_id].editions
      && Object.keys(this.pdps[step_id].editions).length) {
      Object.keys(this.pdps[step_id].editions).forEach((icp_id) => {
        if (this.pdps[step_id].editions[icp_id].status >= minStatus && this.pdps[step_id].editions[icp_id].status !== SignableStatus.OUTDATED) {
          const icpDate = new Date(this.pdps[step_id].editions[icp_id].date)
          if (icpDate.getTime() < now.getTime()
            && (!date || icpDate.getTime() > date.getTime())
            //&& this.pdps[step_id].editions[icp_id].status > SignableStatus.VALIDATED
          ) {
            date = icpDate;
            infos = {
              id: icp_id,
              name: this.pdps[step_id].editions[icp_id].name,
              date: new Date(this.pdps[step_id].editions[icp_id].date),
              status: this.pdps[step_id].editions[icp_id].status
            };
          }
        }
      });
    }
    return infos;
  }
  getNextPdpInfos(step_id: string): {id: string, name: string, date: Date, status: number} {
    let date: Date;
    //const now = new Date();
    let infos: {id: string, name: string, date: Date, status: number};
    if (this.pdps
      && this.pdps[step_id]
      && this.pdps[step_id].editions
      && Object.keys(this.pdps[step_id].editions).length) {
      Object.keys(this.pdps[step_id].editions).forEach((pdp_id) => {
        if (this.pdps[step_id].editions[pdp_id].status < SignableStatus.SIGNED &&
          this.pdps[step_id].editions[pdp_id].status !== SignableStatus.OUTDATED) {
          const pdpDate = new Date(this.pdps[step_id].editions[pdp_id].date)
          if (//pdpDate.getTime() > now.getTime() &&
            (!date || pdpDate.getTime() < date.getTime())) {
            date = pdpDate;
            infos = {
              id: pdp_id,
              name: this.pdps[step_id].editions[pdp_id].name,
              date: new Date(this.pdps[step_id].editions[pdp_id].date),
              status: this.pdps[step_id].editions[pdp_id].status
            };
          }
        }
      });
    }
    return infos;
  }
  getLastIcpDate(step_id: string, minStatus = SignableStatus.VALIDATED) {
    let date: Date;
    const now = new Date();
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED
          && this.inspections[step_id].scheduled[icp_id].status >= minStatus) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() < now.getTime()
            && (!date || icpDate.getTime() > date.getTime())) {
            date = icpDate;
          }
        }
      });
    }
    return date;
  }
  getLastIcpId(step_id: string, minStatus = SignableStatus.VALIDATED) {
    let date: Date;
    const now = new Date();
    let id = '';
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED
          && this.inspections[step_id].scheduled[icp_id].status >= minStatus) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() < now.getTime()
            && (!date || icpDate.getTime() > date.getTime())) {
            date = icpDate;
            id = icp_id;
          }
        }
      });
    }
    return id;
  }

  getNextIcpDate(step_id: string) {
    let date: Date;
    const now = new Date();
    let id = '';
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() > now.getTime()
            && (!date || icpDate.getTime() < date.getTime())) {
            date = icpDate;
          }
        }
      });
    }
    return date;
  }
  getNextIcpId(step_id: string) {
    let date: Date;
    const now = new Date();
    let id = '';
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].scheduled
      && Object.keys(this.inspections[step_id].scheduled).length) {
      Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
        if (this.inspections[step_id].scheduled[icp_id].status !== SignableStatus.OUTDATED) {
          const icpDate = new Date(this.inspections[step_id].scheduled[icp_id].date)
          if (icpDate.getTime() > now.getTime()
            && (!date || icpDate.getTime() < date.getTime())) {
            date = icpDate;
            id = icp_id;
          }
        }
      });
    }
    return id;
  }
  getLastInterventionDate(step_id: string) {
    let date: Date = null;
    const now = new Date();
    let id = '';
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].interventions
      && Object.keys(this.inspections[step_id].interventions).length) {
      Object.keys(this.inspections[step_id].interventions).forEach((intervention_id) => {
        const interventionDate = new Date(this.inspections[step_id].interventions[intervention_id].dateStart)
        if (interventionDate.getTime() < now.getTime()
          && (!date || interventionDate.getTime() > date.getTime())) {
          date = interventionDate;
        }
      });
    }
    return date;
  }
  getNextInterventionDate(step_id: string) {
    let date: Date = null;
    const now = new Date();
    let id = '';
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].interventions
      && Object.keys(this.inspections[step_id].interventions).length) {
      Object.keys(this.inspections[step_id].interventions).forEach((intervention_id) => {
        const interventionDate = new Date(this.inspections[step_id].interventions[intervention_id].dateStart)
        if (interventionDate.getTime() > now.getTime()
          && (!date || interventionDate.getTime() < date.getTime())) {
          date = interventionDate;
        }
      });
    }
    return date;
  }
  getNextInterventionInfos(step_id: string) {
    let date: Date;
    const now = new Date();
    let infos: {id: string, date_start: Date, date_end: Date, name: string} = null;
    if (this.inspections
      && this.inspections[step_id]) {
      if (this.inspections[step_id].template) {
        if (this.inspections[step_id].interventions
          && Object.keys(this.inspections[step_id].interventions).length) {
          Object.keys(this.inspections[step_id].interventions)
            .filter((intervention_id) => (!this.inspections[step_id].interventions[intervention_id].dateEnd || !(((new Date(this.inspections[step_id].interventions[intervention_id].dateEnd)).getTime() + 86400000) < now.getTime())))
            .forEach((intervention_id) => {
              const icpDate = new Date(this.inspections[step_id].interventions[intervention_id].dateStart)
              if (//icpDate.getTime() > now.getTime() &&
                (!date || icpDate.getTime() < date.getTime())) {
                date = icpDate;
                infos = {
                  id: intervention_id,
                  date_start: new Date(this.inspections[step_id].interventions[intervention_id].dateStart),
                  date_end: new Date(this.inspections[step_id].interventions[intervention_id].dateEnd),
                  name: this.inspections[step_id].interventions[intervention_id].name
                };
              }
            });
        }
      } else {
        infos = {
          id: step_id,
          date_start: new Date(this.inspections[step_id].dateStart),
          date_end: new Date(this.inspections[step_id].dateEnd),
          name: this.inspections[step_id].name
        };
      }
    }
    return infos;
  }

  getLastInterventionInfos(step_id: string) {
    let date: Date;
    const now = new Date();
    let infos: {id: string, date_start: Date, date_end: Date, name: string} = null;
    if (this.inspections
      && this.inspections[step_id]) {
      if (this.inspections[step_id].template) {
        if (this.inspections[step_id].interventions
          && Object.keys(this.inspections[step_id].interventions).length) {
          Object.keys(this.inspections[step_id].interventions)
            .filter((intervention_id) => (!this.inspections[step_id].interventions[intervention_id].dateEnd
              || !(((new Date(this.inspections[step_id].interventions[intervention_id].dateEnd)).getTime()) > now.getTime())))
            .forEach((intervention_id) => {
              const icpDate = new Date(this.inspections[step_id].interventions[intervention_id].dateStart)
              if (//icpDate.getTime() > now.getTime() &&
                (!date || icpDate.getTime() > date.getTime())) {
                date = icpDate;
                infos = {
                  id: intervention_id,
                  date_start: new Date(this.inspections[step_id].interventions[intervention_id].dateStart),
                  date_end: new Date(this.inspections[step_id].interventions[intervention_id].dateEnd),
                  name: this.inspections[step_id].interventions[intervention_id].name
                };
              }
            });
        }
      } else {
        infos = {
          id: step_id,
          date_start: new Date(this.inspections[step_id].dateStart),
          date_end: new Date(this.inspections[step_id].dateEnd),
          name: this.inspections[step_id].name
        };
      }
    }
    return infos;
  }


  //null = avant prochaine intervention
  getLastAudits(step_id: string, from: Date = null): {
    name: string,
    date: Date,
    status: number,
    step: string
  }[] {
    let audits: {
      name: string,
      date: Date,
      status: number,
      step: string
    }[] = [];
    if (this.inspections
      && this.inspections[step_id]
      && this.inspections[step_id].audit
      && Object.keys(this.inspections[step_id].audit).length > 0) {
      Object.keys(this.inspections[step_id].audit).forEach((audit_id) => {
        if (this.inspections[step_id].audit[audit_id].status > 0 && (!from || new Date(this.inspections[step_id].audit[audit_id].date).getTime() > new Date(from).getTime())) {
          audits.push(this.inspections[step_id].audit[audit_id]);
        }
      });
    }
    return audits.sort((a, b) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()));
  }


  //null = avant prochaine intervention
  getNextPlannedIcpDate(icpRecurrence: EntityIcpRecurrence, step_id: string) {
    let date: Date = null;
    if (this.inspections
      && this.inspections[step_id]) {
      if (icpRecurrence.enabled && this.inspections[step_id].score) {
        //score>0 => il faut calculer la periode pour les CT
        if (this.inspections[step_id].template
          && this.inspections[step_id].scheduled
          && Object.keys(this.inspections[step_id].scheduled).length) {

          const period = icpRecurrence.getPeriod(this.inspections[step_id].score, this.inspections[step_id].lastAccident);
          //Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
          if (period) {
            const icpDate = this.getLastIcpDate(step_id);
            if (icpDate) {
              const deadlinems = (icpDate.getTime() + (period * 1000 * 24 * 3600));
              if (!date || date.getTime() > deadlinems) {
                date = new Date(deadlinems);
              }
            }
          } else {
            //inspection systematique
            let _d = this.getNextInterventionDate(step_id)
            if (_d) {
              date = new Date(_d);
            }
          }
          //});
        } else {
          // = score mais aucune icp = avant prochaine intervention
          let _d = this.inspections[step_id].template ? this.getNextInterventionDate(step_id) : null;
          if (!_d) {
            //pas CT ou CT sans nextinter => date de la step
            _d = this.inspections[step_id].dateStart;
          }
          if (_d) {
            date = new Date(_d);
          }
        }
      } else {
        //inspection avant intervention
        let _d = this.inspections[step_id].template ? this.getNextInterventionDate(step_id) : null;
        if (!_d) {
          if (this.inspections[step_id].interventions && Object.keys(this.inspections[step_id].interventions).length) {
            const lint = this.getLastInterventionDate(step_id);
            const licp = this.getLastIcpDate(step_id)
            if (!(lint && licp && licp.getTime() > lint.getTime())) {
              _d = lint;
            }
          } else {
            //pas CT ou CT sans nextinter => date de la step
            _d = this.inspections[step_id].dateStart;
          }
        }
        if (_d) {
          date = new Date(_d);
        }
      }
    }
    return date
  }
  removeDeletedIcp(icps: string[], modified: boolean = false) {
    Object.keys(this.inspections).forEach((step_id) => {
      if (this.inspections[step_id].scheduled) {
        this.inspections[step_id].scheduled
        Object.keys(this.inspections[step_id].scheduled).forEach((icp_id) => {
          if (icps.indexOf(icp_id) === -1) {
            delete this.inspections[step_id].scheduled[icp_id];
            modified = true;
          }
        });
      }
    });
    return modified;
  }
  updateIcp(icp, modified: boolean = false) {
    if (icp) {
      Object.keys(this.inspections).forEach((step_id) => {
        const relatedStep: boolean = (!!icp['steps'] && !!icp['steps'].length && (icp['steps'].indexOf(step_id) !== -1));
        if (this.inspections[step_id].scheduled
          && this.inspections[step_id].scheduled[icp['_id']]
          && (icp['_deleted'] || !relatedStep)) {
          console.log('updateIcp delete scheduled ' + step_id, {value: icp, from: this.inspections[step_id].scheduled[icp['_id']]});
          delete this.inspections[step_id].scheduled[icp['_id']];
          modified = true;
        } else if (relatedStep) {
          const icpDateStatus: {
            name: string,
            steps: string[],
            date: Date,
            status: number
          } = {
            name: icp['name'],
            steps: icp['steps'] ? icp['steps'] : [],
            date: new Date(icp['iPDate']),
            status: (icp['status'] === ICPState.SIGNING && icp['withoutSignatories']) ? ICPState.VALIDATED : icp['status']
          };
          if (!this.inspections[step_id].scheduled) {
            this.inspections[step_id].scheduled = {};
          }
          if (!this.inspections[step_id].scheduled[icp['_id']]
            || (icpDateStatus.name != this.inspections[step_id].scheduled[icp['_id']].name)
            || !this.inspections[step_id].scheduled[icp['_id']].steps
            || (icpDateStatus.steps.join(',') !== this.inspections[step_id].scheduled[icp['_id']].steps.join(','))
            || ((new Date(this.inspections[step_id].scheduled[icp['_id']].date)).getTime() != icpDateStatus.date.getTime())
            || (icpDateStatus.status !== this.inspections[step_id].scheduled[icp['_id']].status)
          ) {
            console.log('updateIcp update scheduled of ' + step_id, {value: icp, from: this.inspections[step_id].scheduled[icp['_id']], to: icpDateStatus});
            this.inspections[step_id].scheduled[icp['_id']] = icpDateStatus;
            modified = true;
          }
        }
      });
    }
    return modified;
  }
  updateSteps(steps: any[], removeMissing: boolean = false) {
    let modified = removeMissing && this.removeMissing(steps.map((e) => (e._id)));
    steps.forEach((step) => {
      modified = this.updateStep(step) || modified;
      modified = this.removeUntemplated(step) || modified;
    });
    return modified
  }
  updateStep(step) {
    let modified = false;
    if (step['template_id'] && this.inspections[step['template_id']]) {
      if (step['_deleted']) {
        if (this.inspections[step['template_id']]
          && this.inspections[step['template_id']].interventions
          && this.inspections[step['template_id']].interventions[step['_id']]) {
          delete this.inspections[step['template_id']].interventions[step['_id']];
          //console.log('updateStep delete intervention', step);
          modified = true;
        }//else nothing to do
      } else {
        if (!this.inspections[step['template_id']].interventions) {
          this.inspections[step['template_id']].interventions = {};
        }
        if (this.inspections[step['template_id']].interventions[step['_id']]) {
          if (this.inspections[step['template_id']].interventions[step['_id']].name !== step['name']) {
            this.inspections[step['template_id']].interventions[step['_id']].name = step['name'];
            //console.log('updateStep modif intervention name', step);
            modified = true;
          }
          if ((new Date(this.inspections[step['template_id']].interventions[step['_id']].dateStart)).getTime() !== (new Date(step['dateStart'])).getTime()) {
            this.inspections[step['template_id']].interventions[step['_id']].dateStart = new Date(step['dateStart']);
            //console.log('updateStep modif intervention dateStart', step);
            modified = true;
          }
          if ((new Date(this.inspections[step['template_id']].interventions[step['_id']].dateEnd)).getTime() !== (new Date(step['dateEnd'])).getTime()) {
            this.inspections[step['template_id']].interventions[step['_id']].dateEnd = new Date(step['dateEnd']);
            //console.log('updateStep modif intervention dateEnd', step);
            modified = true;
          }
        } else {
          this.inspections[step['template_id']].interventions[step['_id']] = {
            name: step['name'],
            dateStart: new Date(step['dateStart']),
            dateEnd: new Date(step['dateEnd'])
          };
          //console.log('updateStep add intervention', step);
          modified = true;
        }
      }
    } else if (!step['template_id']) {
      //cas CT ou intervention specifique
      if (step['_deleted']) {
        if (this.inspections[step['_id']]) {
          delete this.inspections[step['_id']];
          //console.log('updateStep delete step', step);
          modified = true;
        }//else nothing to do
        if (this.pdps[step['_id']]) {
          delete this.pdps[step['_id']];
          //console.log('updateStep delete step', step);
          modified = true;
        }//else nothing to do
      } else {
        if (this.pdps[step['_id']]) {
          if (this.pdps[step['_id']].name !== step['name']) {
            this.pdps[step['_id']].name = step['name'];
            //console.log('updateStep modif step name', step);
            modified = true;
          }
          if (!!this.pdps[step['_id']].template !== !!step['template']) {
            this.pdps[step['_id']].template = !!step['template'];
            //console.log('updateStep modif step template', step);
            modified = true;
          }
          if (!this.pdps[step['_id']].dateStart || (new Date(this.pdps[step['_id']].dateStart)).getTime() !== (new Date(step['dateStart'])).getTime()) {
            this.pdps[step['_id']].dateStart = new Date(step['dateStart']);
            //console.log('updateStep modif step dateStart', step);
            modified = true;
          }
          if (!this.pdps[step['_id']].dateEnd || (new Date(this.pdps[step['_id']].dateEnd)).getTime() !== (new Date(step['dateEnd'])).getTime()) {
            this.pdps[step['_id']].dateEnd = new Date(step['dateEnd']);
            //console.log('updateStep modif step dateEnd', step);
            modified = true;
          }
        } else {
          this.pdps[step['_id']] = {
            name: step['name'],
            template: !!step['template'],
            dateStart: new Date(step['dateStart']),
            dateEnd: new Date(step['dateEnd']),
            editions: {}
          };
          this.removeUntemplated(step);
          //console.log('updateStep add inspection', step);
          modified = true;
        }
        if (this.inspections[step['_id']]) {
          if (this.inspections[step['_id']].name !== step['name']) {
            this.inspections[step['_id']].name = step['name'];
            //console.log('updateStep modif step name', step);
            modified = true;
          }
          if (!!this.inspections[step['_id']].template !== !!step['template']) {
            this.inspections[step['_id']].template = !!step['template'];
            //console.log('updateStep modif step template', step);
            modified = true;
          }
          if (!this.inspections[step['_id']].dateStart || (new Date(this.inspections[step['_id']].dateStart)).getTime() !== (new Date(step['dateStart'])).getTime()) {
            this.inspections[step['_id']].dateStart = new Date(step['dateStart']);
            //console.log('updateStep modif step dateStart', step);
            modified = true;
          }
          if (!this.inspections[step['_id']].dateEnd || (new Date(this.inspections[step['_id']].dateEnd)).getTime() !== (new Date(step['dateEnd'])).getTime()) {
            this.inspections[step['_id']].dateEnd = new Date(step['dateEnd']);
            //console.log('updateStep modif step dateEnd', step);
            modified = true;
          }
          const lastStepAccident = this.getStepLastAccident(step);
          const lastInspectionAccident = this.inspections[step['_id']].lastAccident
            ? new Date(this.inspections[step['_id']].lastAccident) : null;
          if ((lastStepAccident && lastInspectionAccident
            && lastStepAccident.getTime() !== lastInspectionAccident.getTime())
            || lastStepAccident !== lastInspectionAccident) {
            this.inspections[step['_id']].lastAccident = lastStepAccident;
            //console.log('updateStep modif step lastAccident:' + lastStepAccident, step);
            modified = true;
          }
          const score = this.getStepScore(step);
          if (score !== this.inspections[step['_id']].score) {
            this.inspections[step['_id']].score = score;
            //console.log('updateStep modif step score:' + score, step);
            modified = true;
          }
        } else {
          this.inspections[step['_id']] = {
            name: step['name'],
            template: !!step['template'],
            dateStart: new Date(step['dateStart']),
            dateEnd: new Date(step['dateEnd']),
            scheduled: {},
            audit: {},
            interventions: {},
            score: this.getStepScore(step),
            lastAccident: this.getStepLastAccident(step)
          };
          this.removeUntemplated(step);
          //console.log('updateStep add inspection', step);
          modified = true;
        }
      }
    }
    return modified;
  }
  removeMissing(steps: string[], modified: boolean = false) {
    Object.keys(this.inspections).forEach((step_id) => {
      if (steps.indexOf(step_id) === -1) {
        delete this.inspections[step_id];
        modified = true;
      } else if (this.inspections[step_id].interventions
        && this.inspections[step_id].interventions[step_id]) {
        delete this.inspections[step_id].interventions[step_id];
        modified = true;
      }
    });
    Object.keys(this.pdps).forEach((step_id) => {
      if (steps.indexOf(step_id) === -1) {
        delete this.pdps[step_id];
        modified = true;
      }
    });
    return modified;
  }
  removeUntemplated(step, modified: boolean = false) {
    Object.keys(this.inspections).forEach((step_id) => {
      if (step['template_id']) {
        if (this.inspections[step['_id']]) {
          delete this.inspections[step['_id']];
          //console.log('removeTemplated from inspection', step);
          modified = true;
        }
        if (this.pdps[step['_id']]) {
          delete this.pdps[step['_id']];
          //console.log('removeTemplated from inspection', step);
          modified = true;
        }
      } else {
        if (this.inspections[step_id].interventions
          && this.inspections[step_id].interventions[step['_id']]) {
          delete this.inspections[step_id].interventions[step['_id']];
          //console.log('removeUntemplated from inspection ' + step_id, step);
          modified = true;
        }
      }
    });
    return modified;
  }
  getStepLastAccident(step) {
    if (step['accidents'] && step['accidents'].length) {
      return new Date(step['accidents'].sort((a, b) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()))[0].date);
    }
    return null
  }
  getStepScore(step) {
    return step['notPlanned'] ? 0 : (step['systematicInspection'] && step['exposure'] && step['severity']) ? 0 : (step['exposure'] * step['severity']);
  }

  removeDeletedPdp(pdps: string[], modified: boolean = false) {
    Object.keys(this.pdps).forEach((step_id) => {
      if (this.pdps[step_id].editions) {
        this.pdps[step_id].editions
        Object.keys(this.pdps[step_id].editions).forEach((pdp_id) => {
          if (pdps.indexOf(pdp_id) === -1) {
            delete this.pdps[step_id].editions[pdp_id];
            modified = true;
          }
        });
      }
    });
    return modified;
  }
  updatePdp(pdp, modified: boolean = false) {
    if (pdp) {
      Object.keys(this.pdps).forEach((step_id) => {
        let relatedStep: boolean = (!!pdp['steps'] && !!pdp['steps'].length && pdp['steps'].indexOf(step_id) !== -1);
        if (!relatedStep && pdp['icps'] && pdp['icps'].length) {
          if (this.inspections && Object.keys(this.inspections).length) {
            //Object.keys(this.inspections).forEach((s_id) => {
            if (this.inspections[step_id] && this.inspections[step_id].scheduled && Object.keys(this.inspections[step_id].scheduled).length) {
              Object.keys(this.inspections[step_id].scheduled).forEach((i_id) => {
                relatedStep = relatedStep || (pdp['icps'].indexOf(i_id) !== -1);
              });
            }
            //});
          }
        }
        if (this.pdps[step_id].editions
          && this.pdps[step_id].editions[pdp['_id']]
          && (pdp['_deleted'] || !relatedStep)) {
          console.log('updatePdp delete editions of ' + step_id, {value: pdp, from: this.pdps[step_id].editions[pdp['_id']]});
          delete this.pdps[step_id].editions[pdp['_id']];
          modified = true;
        } else if (relatedStep) {
          const pdpDateStatus: {name: string, date: Date, status: number, icps: string[], steps: string[]} = {
            name: pdp['name'],
            date: new Date(pdp['editorDate']),
            status: pdp['status'],
            icps: pdp['icps'] ? pdp['icps'] : [],
            steps: pdp['steps'] ? pdp['steps'] : []
          };
          if (!this.pdps[step_id].editions) {
            this.pdps[step_id].editions = {};
          }
          if (!this.pdps[step_id].editions[pdp['_id']]
            || !this.pdps[step_id].editions[pdp['_id']].name
            || ((new Date(this.pdps[step_id].editions[pdp['_id']].date)).getTime() != pdpDateStatus.date.getTime())
            || (pdpDateStatus.status !== this.pdps[step_id].editions[pdp['_id']].status)
            || (this.pdps[step_id].editions[pdp['_id']].icps && (pdpDateStatus.icps.sort().join(',') !== this.pdps[step_id].editions[pdp['_id']].icps.sort().join(',')))
            || (this.pdps[step_id].editions[pdp['_id']].steps && (pdpDateStatus.steps.sort().join(',') !== this.pdps[step_id].editions[pdp['_id']].steps.sort().join(',')))
          ) {
            console.log('updatePdp update editions of ' + step_id, {value: pdp, from: this.pdps[step_id].editions[pdp['_id']], to: pdpDateStatus});
            this.pdps[step_id].editions[pdp['_id']] = pdpDateStatus;
            modified = true;
          }
        }
      });
    }
    return modified;
  }
  removeDeletedAudit(audits: string[], modified: boolean = false) {
    Object.keys(this.inspections).forEach((step_id) => {
      if (this.inspections[step_id].audit) {
        Object.keys(this.inspections[step_id].audit).forEach((audit_id) => {
          if (audits.indexOf(audit_id) === -1) {
            delete this.inspections[step_id].audit[audit_id];
            modified = true;
          }
        });
      }
    });
    return modified;
  }
  updateAudit(audit, modified: boolean = false) {
    if (audit) {
      Object.keys(this.inspections).forEach((step_id) => {
        const relatedStep: boolean = !!audit['step']
          && ((audit['step'] === step_id)
            || (this.inspections[step_id].interventions && Object.keys(this.inspections[step_id].interventions).indexOf(audit['step']) !== -1));
        if (this.inspections[step_id].audit
          && this.inspections[step_id].audit[audit['_id']]
          && (audit['_deleted'] || !relatedStep)) {
          console.log('updateAudit delete audit of ' + step_id, {value: audit, from: this.inspections[step_id].audit[audit['_id']]});
          delete this.inspections[step_id].audit[audit['_id']];
          modified = true;
        } else if (relatedStep) {
          const auditDateStatus: {
            name: string,
            step: string,
            date: Date,
            status: number
          } = {
            name: audit['name'],
            step: audit['step'] ? audit['step'] : [],
            date: new Date(audit['iPDate']),
            status: (audit['status'] === AuditState.SIGNING && audit['withoutSignatories']) ? AuditState.VALIDATED : audit['status']
          };
          if (!this.inspections[step_id].audit) {
            this.inspections[step_id].audit = {};
          }
          if (!this.inspections[step_id].audit[audit['_id']]
            || (auditDateStatus.name != this.inspections[step_id].audit[audit['_id']].name)
            || !this.inspections[step_id].audit[audit['_id']].step
            || (auditDateStatus.step !== this.inspections[step_id].audit[audit['_id']].step)
            || ((new Date(this.inspections[step_id].audit[audit['_id']].date)).getTime() != auditDateStatus.date.getTime())
            || (auditDateStatus.status !== this.inspections[step_id].audit[audit['_id']].status)
          ) {
            console.log('updateAudit update audit of ' + step_id, {value: audit, from: this.inspections[step_id].audit[audit['_id']], to: auditDateStatus});
            this.inspections[step_id].audit[audit['_id']] = auditDateStatus;
            modified = true;
          }
        }
      });
    }
    return modified;
  }
}
