import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {comparableString, normalyzeEmail, normalyzePhone, promiseAll, LoggerService, EntityListPopupComponent, TreeElement, ExcelService, Contact, ConfirmDialogComponent} from 'app/shared';
import {OfficeService, EntityOffice, OrganizationService, EntityOrganization} from 'app/workbook-core';
import {OfficeDialogComponent} from './officeDialog.component';
import {OfficeUsersDialogComponent} from './officeUsersDialog.component';

@Component({
  selector: 'app-repository-office-list',
  templateUrl: './officeList.component.html',
  styleUrls: ['./officeList.component.scss']
})


export class OfficeListComponent extends EntityListPopupComponent {
  protected _dialogComponent = OfficeDialogComponent;

  entityList: EntityOffice[];
  dataSource: MatTableDataSource<EntityOffice>;
  displayedColumns: string[] = ['name', 'main', 'address', 'zipCode', 'city', 'users', 'delete'];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce service ?';
  addConfirmMessage = 'Nouveau service créé';
  level: {[id: string]: number} = {};

  protected _organizationListSubscriber: Subscription;
  organizationList: string[] = [];

  pageSize = 50;

  constructor(
    protected _organizationService: OrganizationService,
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: OfficeService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this._organizationListSubscriber = this._organizationService.getAll().subscribe((organizationList: EntityOrganization[]) => {
      if (organizationList) {
        this.organizationList = organizationList.map((e) => (e.name)).sort((a, b) => a.localeCompare(b));
      }
    })

  }

  selectElement(element: EntityOffice = null) {
    this._router.navigateByUrl(this._entityService.getUrlEntity(element));
  }

  protected _afterClose(element) {
    this.selectElement(element);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._organizationListSubscriber) {
      this._organizationListSubscriber.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.entityList) {
      this.level = TreeElement.getLevels(this.entityList, 'main');
    }
  }
  getEntityName(id) {
    const main = this.entityList.find((e) => (e._id === id));
    return main ? main.name : '';

  }
  _getDataSourceContent() {
    return TreeElement.orderTreeView(this.entityList, 'main');
  }
  selectOfficeUsers(element) {
    const dialogRef = this.dialog.open(OfficeUsersDialogComponent, {
      disableClose: true,
      minWidth: '1000px',
      data: {
        id: element._id
      }
    });

    dialogRef.afterClosed().subscribe(e => {
      if (e && e !== 'undefined') {
        this._afterClose(e);
      }
    });
  }

  protected _getDataSourceFilterPredicate() {
    return (data, filter: string) => {
      return (!filter || filter.trim() === '' ||
        (data && (
          data.name && comparableString(data.name).indexOf(comparableString(filter)) !== -1
          || (data.contacts && data.contacts.length && data.contacts.findIndex((c) => (
            (c.name && comparableString(c.name).indexOf(comparableString(filter)) !== -1)
            || (c.email && comparableString(c.email).indexOf(comparableString(filter)) !== -1)
            || (c.phone && c.phone.indexOf(filter) !== -1)
            || (c.fax && c.fax.indexOf(filter) !== -1)
            || (c.job && comparableString(c.job).indexOf(comparableString(filter)) !== -1)
          )) !== -1)
        )));
    }
  }

  parseImport(list: any[]) {

    if (list && list.length) {
      const _officeUpdates: Promise<EntityOffice>[] = [];
      const _officeUpdateContacts: {[office_id: string]: {name: string, contacts: Contact[]}} = {};
      const parents: {[childname: string]: string} = {};
      const importList: EntityOffice[] = [];
      const organizationAddList: string[] = [];
      list.forEach((element) => {
        const contact = new Contact();
        contact.name = !!element['contact_name'] ? ('' + element['contact_name']) : (!element['contact_job'] && !element['contact_phone'] && !element['contact_email']) ? 'Aucun contact' : 'contact';
        contact.job = !!element['contact_job'] ? ('' + element['contact_job']) : '';
        contact.phone = !!element['contact_phone'] ? normalyzePhone(element['contact_phone']) : '';
        contact.email = !!element['contact_email'] ? normalyzeEmail(element['contact_email']) : '';
        contact.signatoryPdP = !!element['signataire_pdp'];
        contact.signatory = !!element['signataire_icp'];
        contact.signatoryPermit = !!element['signataire_permit'];
        contact.chsct = !!element['cse_cst'];
        const i = importList.findIndex((e) => (e.name === element['office_name']));
        let change: boolean = false;
        if (i === -1) {
          if (element['parent']) {
            const x = this.entityList.findIndex((e) => (e.name === element['parent']));
            if (x !== -1) {
              element['parent'] = this.entityList[x]._id;
            } else {
              parents[element['office_name']] = element['parent'];
            }
          } else {
            element['parent'] = ''
          }
          let _office: EntityOffice;
          let _officeUpdate: Promise<EntityOffice>;
          const j = this.entityList.findIndex((e) => (e.name === element['office_name']));
          if (j === -1) {
            change = true;
            _office = this._entityService.getNewEntity({
              name: element['office_name'],
              main: element['parent'],
              job: element['office_organization'],
              address: element['office_address'] ? element['office_address'] : '',
              zipCode: element['office_zipCode'] ? element['office_zipCode'] : '',
              city: element['office_city'] ? element['office_city'] : ''
            });
          } else {
            _officeUpdate = this._entityService.getCurrentOne(this.entityList[j]._id);
            _officeUpdates.push(_officeUpdate);
            if (!_officeUpdateContacts[this.entityList[j]._id]) {
              _officeUpdateContacts[this.entityList[j]._id] = {name: element['office_name'], contacts: []};
            }
            if (contact.name !== 'Aucun contact') {
              change = true;
              _officeUpdateContacts[this.entityList[j]._id].contacts.push(contact);
            }
          }
          if (this.organizationList.indexOf(element['office_organization']) === -1 && organizationAddList.indexOf(element['office_organization']) === -1) {
            organizationAddList.push(element['office_organization']);
          }
          if (_office && _office.contacts.findIndex((e) => (e.name === contact.name)) === -1) {
            if (contact.name !== 'Aucun contact') {
              change = true;
              _office.contacts.push(contact);
            }
            if (change) {
              importList.push(_office);
            }
          }
        } else {
          if (contact.name !== 'Aucun contact' && importList[i].contacts.findIndex((e) => (e.name === contact.name)) === -1) {
            importList[i].contacts.push(contact);
          }
        }
      });
      if (importList.length || Object.keys(_officeUpdateContacts).length) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '1600px',
        });


        if (importList.length) {
          dialogRef.componentInstance.confirmMessage = '<p>Vous allez importer les services suivants :</p><ul>';
          importList.forEach((office) => {
            dialogRef.componentInstance.confirmMessage += '<b>' + office.name + (office._id ? '(' + office._id + ')' : '') + '</b> - ' + office.job + ' - ' + office.address + ' - ' + office.zipCode + ' ' + office.city;
            if (office.contacts && office.contacts.length) {
              dialogRef.componentInstance.confirmMessage += '<ul>';
              office.contacts.forEach((c) => {
                dialogRef.componentInstance.confirmMessage += '<li>' + c.name + '(' + c.email + ')' + ' - ' + c.job + ' - ' + c.phone + '</li>';
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';
            }
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        if (Object.keys(_officeUpdateContacts).length) {
          dialogRef.componentInstance.confirmMessage += '<p>Vous allez ajouter les contacts suivants :</p><ul>';
          Object.keys(_officeUpdateContacts).forEach((o_id) => {
            dialogRef.componentInstance.confirmMessage += '<b>' + _officeUpdateContacts[o_id].name + '(' + o_id + ')</b>';
            if (_officeUpdateContacts[o_id].contacts && _officeUpdateContacts[o_id].contacts.length) {
              dialogRef.componentInstance.confirmMessage += '<ul>';
              _officeUpdateContacts[o_id].contacts.forEach((c) => {
                dialogRef.componentInstance.confirmMessage += '<li>' + c.name + '(' + c.email + ')' + ' - ' + c.job + ' - ' + c.phone + '</li>';
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';
            }
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        if (organizationAddList.length) {
          dialogRef.componentInstance.confirmMessage += '<p>Et créer ces nouveaux types de structure :</p><ul>';
          organizationAddList.forEach((ot) => {
            dialogRef.componentInstance.confirmMessage += '<li>' + ot + '</li>';
          });
          dialogRef.componentInstance.confirmMessage += '</ul>';
        }
        dialogRef.componentInstance.confirmMessage += '<p>Souhaitez vous procéder à l\'importation ?</p>';
        dialogRef.componentInstance.cancelMessage = 'Non';
        dialogRef.componentInstance.buttonMessage = 'Oui';
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._spinner.show();
            promiseAll(organizationAddList.map((job) => (this._organizationService.save(this._organizationService.getNewEntity({name: job}))))).then((res) => {
              promiseAll(importList.map((eex) => (this._entityService.save(eex)))).then((res) => {

                const _officeUpdatesParent: Promise<EntityOffice>[] = [];
                const _officeParents: {[office_id: string]: string} = {};
                if (Object.keys(parents).length) {
                  Object.keys(parents).forEach((_name) => {
                    const a = this.entityList.findIndex((e) => (e.name === _name));
                    if (a !== -1) {
                      const b = this.entityList.findIndex((e) => (e.name === parents[_name]));
                      if (b !== -1) {

                        _officeUpdatesParent.push(this._entityService.getCurrentOne(this.entityList[a]._id));
                        _officeParents[this.entityList[a]._id] = this.entityList[b]._id;
                      }
                    }
                  });
                }
                promiseAll(_officeUpdates.map((_op) => {
                  return _op.then((o) => {
                    if (o.contacts && _officeUpdateContacts[o._id] && _officeUpdateContacts[o._id].contacts && _officeUpdateContacts[o._id].contacts.length) {
                      _officeUpdateContacts[o._id].contacts.forEach((_c) => {
                        if (o.contacts.findIndex((_d) => (_c.name === _d.name)) === -1) {
                          o.contacts.push(_c);
                        }
                      });
                    }
                    return this._entityService.save(o);
                  })
                })).then((res) => {

                  promiseAll(_officeUpdatesParent.map((_op) => {
                    return _op.then((o) => {
                      if (o.main && _officeParents[o._id]) {
                        o.main = _officeParents[o._id];
                      }
                      return this._entityService.save(o);
                    })
                  })).then((res) => {
                    this._spinner.hide();
                    this.snackBar.open('Importation terminée', '', {
                      duration: 2000
                    });
                  }).catch((err) => {
                    this._logger.error('officeList', 'import update', JSON.stringify(err));
                    this._spinner.hide();
                    this.snackBar.open('Erreur lors de l\'importation des des contacts dans des services existants', '', {
                      duration: 2000
                    });
                  });

                }).catch((err) => {
                  this._logger.error('officeList', 'import update', JSON.stringify(err));
                  this._spinner.hide();
                  this.snackBar.open('Erreur lors de l\'importation des des contacts dans des services existants', '', {
                    duration: 2000
                  });
                });

              }).catch((err) => {
                this._logger.error('officeList', 'import', JSON.stringify(err));
                this._spinner.hide();
                this.snackBar.open('Erreur lors de l\'importation des nouveaux services', '', {
                  duration: 2000
                });
              });
            }).catch((err) => {
              this._logger.error('officeList', 'import organization', JSON.stringify(err));
              this._spinner.hide();
              this.snackBar.open('Erreur lors de l\'importation des types de structures', '', {
                duration: 2000
              });
            });
          }
        });
      }
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        this.parseImport(list);
      }).catch((err) => {
        console.error(err);
      });

      if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
        fileInput.srcElement.value = '';
      }
    }
  }
}
