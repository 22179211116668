import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll, LoggerService, EntitySimpleListComponent, ExcelService, ConfirmDialogComponent, sanitizeString} from 'app/shared';
import {MeasureService, RiskService, EntityMeasure, EntityRisk, EntitySituation, SituationService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-measure-list',
  templateUrl: './measureList.component.html',
  styleUrls: ['./measureList.component.scss']
})

export class MeasureListComponent extends EntitySimpleListComponent implements OnDestroy {

  entityList: EntityMeasure[];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette mesure ?';
  addConfirmMessage = 'Nouvelle mesure créée';


  riskListSubscriber: Subscription;
  riskList: EntityRisk[] = [];
  risks: {[id: string]: string} = {};
  selectedRisk: string = '';

  situationListSubscriber: Subscription;
  //situation names
  situations: {[situation_id: string]: string} = {};
  //situations
  situationList: EntitySituation[] = [];
  situationUsed: string[] = [];
  used: string[] = [];
  constructor(
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    protected _situationService: SituationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: MeasureService,
    protected _riskService: RiskService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this.situationListSubscriber = this._situationService.getAll().subscribe((situationList: EntitySituation[]) => {
      if (situationList) {
        this.used = [];
        this.situations = {};
        this.situationList = [];
        situationList.forEach((situation) => {
          this.situations[situation._id] = situation.name;
          this.situationList.push(situation);
          situation.measures.forEach((id) => {
            if (this.used.indexOf(id) === -1) {
              this.used.push(id);
            }
          });
        });
      }
    });
    this.riskListSubscriber = this._riskService.getAll().subscribe((riskList) => {
      if (riskList) {
        this.riskList = riskList.sort((a, b) => (this.numalphaCompare(a.name, b.name)));
        this.riskList.forEach((risk: EntityRisk) => {
          this.risks[risk._id] = risk.name;
        });
        this.filter(this.value);
      }
    })
  }
  protected _init(data) {
    super._init(data);
  }
  ngOnDestroy() {
    if (this.riskListSubscriber) {
      this.riskListSubscriber.unsubscribe();
    }
    if (this.situationListSubscriber) {
      this.situationListSubscriber.unsubscribe();
    }
  }


  addElement() {
    if (this.value && !this.valueExists && this.selectedRisk) {
      const newEntity = this._entityService.getNewEntity({name: this.value, risk_id: this.selectedRisk, type: ''});
      this._entityService.save(newEntity).then(() => {
        this.updateElement(newEntity);
        //   this.snackBar('La mesure a été ajoutée');
      });
      this.value = '';
    }
  }
  /**
   * Filter measure
   * @param value
   */
  filter(value: string = '') {
    this.entityList.sort((a, b) => {
      return a.risk_id && this.risks[a.risk_id]
        ? b.risk_id && this.risks[b.risk_id]
          ? this.risks[a.risk_id].localeCompare(this.risks[b.risk_id]) === 0
            ? a.name.localeCompare(b.name)
            : this.risks[a.risk_id].localeCompare(this.risks[b.risk_id])
          : -1
        : 1
    });
    this.valueExists = false;
    if (value) {
      value = value.toLowerCase();
      let i = 0;
      this.listHidden = this.entityList.map((e) => {
        const name = e.name.toLowerCase();
        const sameRisk = !this.selectedRisk || this.selectedRisk === e.risk_id;
        this.valueExists = this.valueExists
          || (value === name && this.selectedRisk === e.risk_id);
        i++;
        return (!sameRisk || name.indexOf(value) === -1) ? 0 : i;
      });
    } else {
      this.listHidden = this.entityList.map((e, index) => (this.selectedRisk && this.selectedRisk !== e.risk_id) ? 0 : index + 1);
    }
  }
  // TODO : test in v1.1 optimization ngFor with trackBy
  public trackByFn(index, item: EntityMeasure) {
    if (!item) {return null;}
    return item._id;
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        if (list && list.length) {
          const importList: {[risk_name: string]: EntityMeasure[]} = {};
          list.forEach((element) => {
            if (element['risk_name'] && element['measure_name']) {
              const newMeasure: EntityMeasure = this._entityService.getNewEntity({
                name: sanitizeString(element['measure_name'])
              });
              let _rn = sanitizeString(element['risk_name']);
              if (!importList[_rn]) {
                importList[_rn] = [newMeasure]
              } else {
                importList[_rn].push(newMeasure);
              }
            }
          });
          if (Object.keys(importList).length) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: false,
              width: '1600px',
            });

            dialogRef.componentInstance.confirmMessage = '<p>Vous allez importer les mesures suivantes :</p><ul>';
            Object.keys(importList).forEach((risk_name) => {
              if (importList[risk_name].length) {
                dialogRef.componentInstance.confirmMessage += '<b>' + risk_name + '</b>';
                dialogRef.componentInstance.confirmMessage += '<ul>';
                importList[risk_name].forEach((m) => {
                  dialogRef.componentInstance.confirmMessage += '<li>' + m.name + '</li>';
                });
                dialogRef.componentInstance.confirmMessage += '</ul>';
              }
            });
            dialogRef.componentInstance.confirmMessage += '</ul><p>Souhaitez vous procéder à l\'enregistrement de ces mesures ?</p>';
            dialogRef.componentInstance.cancelMessage = 'Non';
            dialogRef.componentInstance.buttonMessage = 'Oui';
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this._spinner.show();
                const risksSaver: Promise<any>[] = [];
                const measuresSaver: Promise<any>[] = [];
                Object.keys(importList).forEach((risk_name) => {
                  const risk = this.riskList.find((e) => (e.name === risk_name));
                  if (risk) {
                    importList[risk_name].forEach((m) => {
                      m.risk_id = risk._id;
                      if (this.entityList.findIndex((x) => (x.name === m.name && x.risk_id === m.risk_id)) === -1) {
                        measuresSaver.push(this._entityService.save(m));
                      }
                    });
                  } else {
                    risksSaver.push(
                      this._riskService.save(this._riskService.getNewEntity({
                        name: risk_name
                      }))
                        .then((r) => {
                          importList[risk_name].forEach((m) => {
                            m.risk_id = r.id;
                            if (this.entityList.findIndex((x) => (x.name === m.name && x.risk_id === m.risk_id)) === -1) {
                              measuresSaver.push(this._entityService.save(m));
                            }
                          });
                        })
                    );
                  }
                });

                promiseAll(risksSaver).then((res) => {
                  promiseAll(measuresSaver).then((result) => {
                    this._spinner.hide();
                    this.snackBar.open('Importation terminée', '', {
                      duration: 2000
                    });
                  }).catch((err) => {
                    this._logger.error('MeasureList', 'import measure', JSON.stringify(err));
                    this._spinner.hide();
                    this.snackBar.open('Erreur lors de l\'importation des mesures', '', {
                      duration: 2000
                    });
                  });
                }).catch((err) => {
                  this._logger.error('MeasureList', 'import risques', JSON.stringify(err));
                  this._spinner.hide();
                  this.snackBar.open('Erreur lors de l\'importation des risques', '', {
                    duration: 2000
                  });
                });
              }
            });
          }
        }
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
      }).catch((err) => {
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
        console.error(err);
      });
    }
  }

  exportToExcel() {
    this._excelService.exportAsExcelFile(
      {
        'measure': this.getMeasureExport(),
        'risk': this.getRiskExport(),
        'situation': this.getSituationExport(),
        'situationMeasure': this.getSituationMeasureExport()
      }
      , 'Risques');
  }
  getMeasureExport() {
    return this.entityList ? this.entityList.map((measure) => (
      {
        measure_id: measure._id,
        measure_name: measure.name,
        measure_description: measure.description,
        risk_name: this.risks[measure.risk_id],
        risk_id: measure.risk_id
      }
    )) : [];
  }
  getRiskExport() {
    return this.riskList.map((risk) => (
      {
        risk_id: risk._id,
        risk_name: risk.name,
        risk_description: risk.description,
        fp: risk.requiresFirePermit
      }
    ));
  }

  getSituationExport() {
    return this.situationList.map((situation) => (
      {
        situation_id: situation._id,
        situation_name: situation.name,
        situation_description: situation.description,
        risk_name: this.risks[situation.risk_id],
        risk_id: situation.risk_id,
        sir: !!situation.sir ? 1 : 0,
        fp: !!situation.fp ? 1 : 0
      }
    ));
  }
  getSituationMeasureExport() {
    const returned = [];
    this.situationList.forEach((situation) => {
      situation.measures.forEach((measure_id) => {
        const i = this.entityList.findIndex((e) => (e._id === measure_id));
        if (i !== -1) {
          returned.push(
            {
              measure_id: measure_id,
              measure_name: this.entityList[i].name,
              situation_id: situation._id,
              situation_name: situation.name,
              situation_description: situation.description,
              risk_name: this.risks[situation.risk_id],
              risk_id: situation.risk_id,
              sir: !!situation.sir ? 1 : 0,
              fp: !!situation.fp ? 1 : 0
            }
          );
        }
      })
    });
    return returned;
  }

}
