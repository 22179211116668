import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, PermissionsService, getBase64, EntityFormComponent} from 'app/shared';
import {ExtraConfigService} from 'app/shared/services';
import {EntityExtraConfig, ExtraField} from 'app/shared/models';

@Component({
  selector: 'app-extra-config',
  templateUrl: './extraConfig.component.html',
  styleUrls: ['./extraConfig.component.scss']
})
export class ExtraConfigComponent extends EntityFormComponent {
  autoSave = false;
  entity: EntityExtraConfig;
  fieldTypes = ExtraField.TYPES;
  fieldTypeOptions = ExtraField.TYPE_OPTIONS;
  fieldTypeOptionsLabel = ExtraField.TYPE_OPTIONS_LABEL;
  extraConfigTypes = EntityExtraConfig.TYPES;
  newOptions: {[group: string]: string[]} = {
    operator: []
  };
  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: ExtraConfigService

  ) {
    super(dialog, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (this.entity) {
      if (!this.entity._id && this.entity.operator && this.entity.operator.length === 0) {
        this.entity.operator = [
          new ExtraField({
            "name": "habilitation",
            "repository_field": true,
            "repository_value": true,
            "workbook_field": true,
            "workbook_value": true,
            "permit_field": true,
            "permit_value": true,
            "required": true,
            "type": "text",
            "label": "Habilitation",
            "options": {}
          }),
          new ExtraField({
            "name": "birthday",
            "repository_field": true,
            "repository_value": true,
            "workbook_field": true,
            "workbook_value": true,
            "permit_field": true,
            "permit_value": true,
            "required": false,
            "type": "date",
            "label": "Date de naissance",
            "options": {}
          }),
          new ExtraField({
            "name": "birthplace",
            "repository_field": true,
            "repository_value": true,
            "workbook_field": true,
            "workbook_value": true,
            "permit_field": true,
            "permit_value": true,
            "required": false,
            "type": "text",
            "label": "Lieu de naissance",
            "options": {}
          })
        ];
      }
      if (this.extraConfigTypes) {
        this.extraConfigTypes.forEach((group) => {
          this.newOptions[group] = [];
          if (this.entity[group]) {
            this.entity[group].forEach((field) => {
              this.newOptions[group].push('');
            });
          }
        });
      }
    }
  }
  addField(group: string) {
    if (this.entity && this.entity[group]) {
      this.entity[group].push(new ExtraField());
      this.newOptions[group].push('');
    }
  }
  removeField(group: string, index) {
    if (this.entity && this.entity[group] && this.entity[group][index]) {
      this.entity[group].splice(index, 1);
      this.newOptions[group].splice(index, 1);
    }
  }
  addFieldOption(group: string, index: number, name: string) {
    if (this.entity && this.entity[group] && this.entity[group][index] && this.entity[group][index].options) {
      this.entity[group][index].options[name] = '';
      this.newOptions[group][index] = '';
    }
  }
  removeFieldOption(group: string, index: number, name: string) {
    if (this.entity && this.entity[group]
      && this.entity[group][index]
      && this.entity[group][index].options
      && typeof this.entity[group][index].options[name] !== 'undefined') {
      delete this.entity[group][index].options[name];
      this.form.controls['operator_' + index + '_newOption'].setValue('');
    }
  }
}
