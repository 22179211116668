import { Injectable } from '@angular/core';
import { PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntityDocumentsRequest } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentsRequestService extends EntitySecureService {
  static LABEL_ONE = "chargement de la demande type de documents";
  static LABEL_ALL = "chargement des demandes type de documents";
  static LABEL_CHILDREN = "chargement des demandes type de documents de l'opération ???";
  static LABEL_SAVE = "sauvegarde de la demande type de documents";
  static LABEL_DELETE = "suppression de la demande type de documents";

  static ENTITY_TYPE: string = 'repo_documentsRequest';
  static ENTITY_FIELDS: string[] = ['name', 'documents'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showRepoDocumentsRequest'],
    CREATE: ['createRepoDocumentsRequest'],
    UPDATE: ['editRepoDocumentsRequest'],
    DELETE: ['deleteRepoDocumentsRequest']
  }

  static ENTITY_URL = 'repository/docsRequest';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityDocumentsRequest {
    return new EntityDocumentsRequest(data, clone);
  }

}
