import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {PermissionsService, LoggerService, SidenavComponent, LicencesService} from 'app/shared';

@Component({
  selector: 'app-config-sidenav',
  templateUrl: '../../shared/components/sidenav/sidenav.component.html',
  styleUrls: ['../../shared/components/sidenav/sidenav.component.scss']
})
export class ConfigSidenavComponent extends SidenavComponent implements OnInit {
  name = 'sidenavconfig';
  title = "Administration";
  public generalSelected: boolean = false;
  public ppSelected: boolean = false;
  public fpSelected: boolean = false;
  public activeModulePp: boolean = false;
  public activeModuleFp: boolean = false;

  constructor(
    protected _permissionsService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _licence: LicencesService) {
    super(_permissionsService, _router, _route, _logger);
  }

}
