import {Component, Input, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService} from 'app/shared/logger';
import {DocumentsCommonComponent} from './documentsCommon.component';
import {ConfirmDialogComponent} from 'app/shared/components/confirm-dialog';
import {DocumentMetadataService} from 'app/shared/services';
import {DocumentService} from 'app/shared/document';
import {EntityDocumentMetadata} from 'app/shared/models';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documentsUpload.component.html',
  styleUrls: ['./documentInfo.component.scss']
})
export class DocumentsUploadComponent extends DocumentsCommonComponent implements OnChanges {

  @Input() showDefault: boolean = false;

  uploads: {file: File, entity: EntityDocumentMetadata}[] = [];

  constructor(
    protected _router: Router,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_router, _documentService, dialog, _logger, _entityService);
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }

  public uploadFile(file: File) {
    return this._documentService.uploadFile(file, file.name, this._router.url);
  }
  public uploadFilePromise(file: File) {
    return this._documentService.uploadFilePromise(file, file.name, this._router.url);
  }

  public fileRemoveEvent(fileInput: File) {
    const i = this.uploads.findIndex((e) => (e.file === fileInput));
    if (i !== -1) {
      this.uploads.splice(i, 1);
      this.showDates.splice(i, 1);
      this.showMetas.splice(i, 1);
      this.dateStartTxt.splice(i, 1);
      this.dateEndTxt.splice(i, 1);
    }
  }
  public fileChangeEvent(event: any) {
    const max = 32;
    let count = 0;
    const addedFiles: File[] = [];
    const excludedFiles: string[] = [];
    event.addedFiles.forEach((f) => {
      count++;
      if (count < max) {
        addedFiles.push(new File([f], f.name, {type: f.type}));
      } else {
        excludedFiles.push(f.name);
      }
    });
    if (excludedFiles.length === 0) {
      this.fileChange(addedFiles);
    } else {
      const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        minWidth: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Vous ne pouvez ajouter que ' + max + ' documents à la fois, les documents suivants seront ignorés :';
      excludedFiles.forEach((name) => {
        dialogRefDel.componentInstance.confirmMessage += '<br> - ' + name;
      });
      dialogRefDel.componentInstance.buttonMessage = 'Valider';

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this.fileChange(addedFiles);
        }
      })
    }
  }
  public fileChange(addedFiles: File[]) {
    const addedUploads = addedFiles.map((f) => {
      return {
        file: f,
        entity: this._entityService.getNewEntity({
          name: this._entityService.getFileName(f),
          ext: this._entityService.getFileExt(f),
          mime: f.type,
          icon: this._entityService.getIcon(f.type),
          displayName: f.name,
          size: this._entityService.getFileSize(f),
          context: this.context,
          parent_id: this.parent_id
        })
      };
    });
    this.uploads.push(...addedUploads);
    this.showDates.push(...addedUploads.map((e) => (false)));
    this.showMetas.push(...addedUploads.map((e) => (false)));
    this.dateStartTxt.push(...addedUploads.map((e) => ('')));
    this.dateEndTxt.push(...addedUploads.map((e) => ('')));
  }
}
