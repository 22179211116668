import {Component} from '@angular/core';
import {LoadingService} from '../../services/loading.service';
import {AbstractErrorComponent} from '../error/abstractError.component';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['../error/error.component.scss']
})
export class Error404Component extends AbstractErrorComponent {
  constructor(protected _loadingService: LoadingService) {
    super(_loadingService);
  }
}
