import {Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {PopupDialogComponent} from '../popup-dialog';
import {EntityEmailMessages} from '../../models'
import {EmailMessagesService} from '../../services';

@Component({
  selector: 'app-rgpd-dialog',
  templateUrl: '../popup-dialog/popup-dialog.component.html',
  styleUrls: ['../popup-dialog/popup-dialog.component.scss']
})
export class RgpdDialogComponent extends PopupDialogComponent implements OnDestroy {

  _emailMessageSubscription: Subscription;
  popupTitle = 'Gestion de données personnelles';

  constructor(
    protected _emailMessagesService: EmailMessagesService,
    public dialogRef: MatDialogRef<RgpdDialogComponent>) {
    super(dialogRef);
    this._emailMessagesService.getSingleOne().subscribe((em: EntityEmailMessages) => {
      if (em && em.rgpdText) {
        this.popupMessage = em.rgpdText;
      }
    });
  }
  ngOnDestroy() {
    if (!this._emailMessageSubscription) {
      this._emailMessageSubscription.unsubscribe();
    }
  }
}
