import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, getBase64, EntityFormComponent} from 'app/shared';
import {ConfigLoggerService} from 'app/shared/services';
import {EntityConfigLogger} from 'app/shared/models';

@Component({
  selector: 'app-config-logger',
  templateUrl: './configLogger.component.html',
  styleUrls: ['./configLogger.component.scss']
})
export class ConfigLoggerComponent extends EntityFormComponent {

  entity: EntityConfigLogger;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: ConfigLoggerService

  ) {
    super(dialog, _logger, _entityService);
  }

}
