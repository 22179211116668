import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {storageGet, storageSet} from 'app/shared';
import {
  DbFilteredService,
  PermissionsService, LoadingService, IUser,
  ExploitingCompanyService,
  CommentService,
  DocumentService
} from 'app/shared';
import {
  IcpRecurrenceService, OfficeService, EntityOffice
} from '../../repository';
import {
  WorkbookService,
  WorkbookOfficeService,
  WorkbookExternalCompanyService,
  ActivityService,
  WorkbookMeasureService,
  SignatureService, IcpService, SignablePermitService,
  WorkbookStepService, FirePermitService, SecurityProtocolService, AuditService
} from '../../workbook';
import {EntityPreventionPlan, EntitySignablePreventionPlan} from '../models';
import {PreventionPlanAgreementService} from './preventionPlanAgreement.service';
import {SignablePreventionPlanService} from './signablePreventionPlan.service';

@Injectable({
  providedIn: 'root'
})
export class PreventionPlanService extends WorkbookService {

  //autoInit = false;

  static LABEL_ONE = "chargement de l'opération";
  static LABEL_ALL = "chargement des opérations";
  static LABEL_CHILDREN = "chargement des opérations de l'opération ?";
  static LABEL_SAVE = "sauvegarde de l'opération";
  static LABEL_DELETE = "suppression de l'opération";

  static ENTITY_TYPES: string[] = [];
  static ENTITY_TYPE: string = 'pp';
  //static ENTITY_FIELDS: string[] = ['type', 'name', 'description', 'editor', 'dateStart', 'point', 'status', 'users', 'offices'];
  static ENTITY_FIELDS: string[] = ['type', 'simple', 'name', 'reference', 'code', 'description', 'eex_name', 'eexs', 'dateStart', 'dateEnd', 'point', 'site'
    , 'status', 'users', 'offices', 'operators', 'eex_contacts', 'office_contacts',
    'editor', 'editorEmail', 'editorId',
    'createdAt', 'createdId', 'createdName',
    'updatedAt', 'updatedId', 'updatedName',
    'inspections', 'pdps'];
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['createPP'],
    UPDATE: ['editSumPP'],
    DELETE: []
  }

  static ENTITY_URL = 'pp';
  user: IUser;
  userOffices: string[];
  userWorkbooks: string[];
  protected _profileSubscription: Subscription;
  protected _officesSubscription: Subscription;
  officeScope: {[id: string]: string[]};
  //tous les service id
  officeList: string[];
  //les service_id synchro
  userVisibilityOffices: string[];

  constructor(
    protected _officeService: OfficeService,
    public icpRecurrenceService: IcpRecurrenceService,
    public signablePreventionPlanService: SignablePreventionPlanService,
    public preventionPlanAgreementService: PreventionPlanAgreementService,
    public exploitingCompanyService: ExploitingCompanyService,
    public stepService: WorkbookStepService,
    public officeService: WorkbookOfficeService,
    public externalCompanyService: WorkbookExternalCompanyService,
    public activityService: ActivityService,
    public measureService: WorkbookMeasureService,
    public signatureService: SignatureService,
    public commentService: CommentService,
    public documentService: DocumentService,
    public icpService: IcpService,
    public firePermitService: FirePermitService,
    public securityProtocolService: SecurityProtocolService,
    public auditService: AuditService,
    public signablePermitService: SignablePermitService,
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(
      icpRecurrenceService,
      exploitingCompanyService,
      stepService,
      officeService,
      externalCompanyService,
      activityService,
      measureService,
      signatureService,
      commentService,
      icpService,
      firePermitService,
      securityProtocolService,
      auditService,
      signablePermitService,
      documentService,
      _dbService,
      _permissionsService,
      _loadingService);
    this.setUserWorkbooks(storageGet('config-wbUserSelected') ? storageGet('config-wbUserSelected') : []);
    this._officesSubscription = this._officeService.getAll().subscribe((list: EntityOffice[]) => {
      if (list) {
        this.officeList = list.map((e) => (e._id));
        this.officeScope = OfficeService.getOfficeScope(list);
        //console.info('updateUserOffices from offices', this.officeList);
        this.updateUserOffices();
      }
    });
    this._profileSubscription = this._permissionsService.auth.userProfile$
      .subscribe((user: IUser) => {
        this.user = user;
        //this.userOffices = user.user_metadata && user.user_metadata.syncFilter ? user.user_metadata.syncFilter : null;
        //console.info('updateUserOffices from profile', this.userOffices);
        this.updateUserOffices();
      });
  }
  updateUserOffices() {
    if (this.officeList && this.user) {
      const userVisibilityOffices = this.officeList.filter((oid) => (this.matchingUserOffice(oid)));
      this.setUserOffices(userVisibilityOffices);
    }
  }
  matchingOffice(offices: string[]) {
    let found: boolean = !offices || !offices.length;
    if (!found) {
      let i = 0
      while (!found && i < offices.length) {
        found = this.matchingUserOffice(offices[i]);
        i++;
      }
    }
    return found;
  }
  matchingUserOffice(office: string) {
    const userMetaOffices = this.user.user_metadata && this.user.user_metadata.syncFilter ? this.user.user_metadata.syncFilter : null;
    let found: boolean = !userMetaOffices;
    if (!found) {
      let i = 0
      while (!found && i < userMetaOffices.length) {
        found = userMetaOffices[i] && this.officeScope[userMetaOffices[i]] && this.officeScope[userMetaOffices[i]].indexOf(office) !== -1;
        //console.log('matchingUserOffice (' + found + ')', office);
        i++;
      }
    }
    return found;
  }

  factory(data = {}, clone = false) {
    return new EntityPreventionPlan(data, clone);
  }
  addNewParent(id: string) {
    this._dbService.addNewParent(id);
  }
  setUserWorkbooks(userWorkbooks: string[]) {
    if (!this.userWorkbooks
      || this.userWorkbooks.length !== userWorkbooks.length
      || this.userWorkbooks.slice().sort().join() !== userWorkbooks.slice().sort().join()) {
      this.userWorkbooks = userWorkbooks.map((e) => (e));
      if (this._entityList && this._entityList.value) {
        this._setEntityList(this._entityList.value);
      }
      storageSet('config-wbUserSelected', this.userWorkbooks);
    }
  }
  addUserWorkbook(workbook_id) {
    return new Promise((resolve, reject) => {
      if (this.userWorkbooks.indexOf(workbook_id) === -1) {
        this.userWorkbooks.push(workbook_id);
        this._setEntityList(this._entityList.value);
        storageSet('config-wbUserSelected', this.userWorkbooks);
        const sub = this._dbService.syncStateChange
          .subscribe((res) => {
            if (res === DbFilteredService.SYNSTATE_SYNC) {
              resolve(res);
            }
          }, (err) => {
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  }
  removeUserSelectedWorkbook(workbook_id) {
    const i = this.userWorkbooks.indexOf(workbook_id);
    if (i !== -1) {
      this.userWorkbooks.splice(i, 1);
      storageSet('config-wbUserSelected', this.userWorkbooks);
    }
  }
  removeUserWorkbook(workbook_id) {
    return new Promise((resolve, reject) => {
      const i = this.userWorkbooks.indexOf(workbook_id);
      if (i !== -1) {
        this.userWorkbooks.splice(i, 1);
        this._setEntityList(this._entityList.value);
        storageSet('config-wbUserSelected', this.userWorkbooks);
        const sub = this._dbService.syncStateChange
          .subscribe((res) => {
            if (res === DbFilteredService.SYNSTATE_SYNC) {
              resolve(res);
            }
          }, (err) => {
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  }
  addUserWorkbooks(wbids: string[]) {
    return new Promise((resolve, reject) => {
      wbids.forEach((workbook_id) => {
        if (this.userWorkbooks.indexOf(workbook_id) === -1) {
          this.userWorkbooks.push(workbook_id);
        }
      });
      this._setEntityList(this._entityList.value);
      storageSet('config-wbUserSelected', this.userWorkbooks);
      const sub = this._dbService.syncStateChange
        .subscribe((res) => {
          if (res === DbFilteredService.SYNSTATE_SYNC) {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
  }
  removeUserWorkbooks(wbids: string[]) {
    return new Promise((resolve, reject) => {
      wbids.forEach((workbook_id) => {
        const i = this.userWorkbooks.indexOf(workbook_id);
        if (i !== -1) {
          this.userWorkbooks.splice(i, 1);
        }
      });
      this._setEntityList(this._entityList.value);
      storageSet('config-wbUserSelected', this.userWorkbooks);
      const sub = this._dbService.syncStateChange
        .subscribe((res) => {
          if (res === DbFilteredService.SYNSTATE_SYNC) {
            resolve(res);
          }
        }, (err) => {
          reject(err);
        });
    });
  }
  setUserOffices(userOffices: string[]) {
    if (!this.userOffices
      || this.userOffices.length !== userOffices.length
      || this.userOffices.slice().sort().join() !== userOffices.slice().sort().join()) {
      this.userOffices = userOffices;
      if (this._entityList && this._entityList.value) {
        this._setEntityList(this._entityList.value);
      } else {
        //this.init();
      }
    }
  }
  _setEntityList(val: EntityPreventionPlan[]) {
    if (val && this.userOffices && this.userWorkbooks) {
      const _uid = this.getUserId();
      this._dbService.setParents(val.filter((e) => (
        e.status !== 'Archivé'
        && (this.userWorkbooks.indexOf(e._id) !== -1
          || (e.users.indexOf(_uid) !== -1)
          || (this.userOffices
            && this.userOffices.length
            && (!e.offices
              || (e.offices && e.offices.filter((oid) => (this.userOffices.indexOf(oid) !== -1)).length > 0)
            )
          )
        )
      )).map((e) => (e._id)));
      super._setEntityList(val);
    }
  }
  getSignAgreement(entity: EntityPreventionPlan): Promise<string> {
    return new Promise((resolve) => {
      if (entity
        //&& preventionPlan.status === 'Archivé'
        && entity.signAgreement) {
        resolve(entity.signAgreement)
      } else {
        this.preventionPlanAgreementService.getSingleOne().subscribe((sa) => {
          if (sa) {
            resolve(sa.text);
          }
        })
      }
    });
  }

  getSignableList(id: string): BehaviorSubject<EntitySignablePreventionPlan[]> {
    return this.signablePreventionPlanService.getChildren(id);
  }

  getSignableIcp(documentType: string, id: string, spdp_id: string) {
    return documentType + '/' + id + '/pdp/' + spdp_id;
  }
  getCurrentSignableList(entity: EntityPreventionPlan): Promise<EntitySignablePreventionPlan[]> {
    return new Promise((resolve, reject) => {
      if (entity && entity._id) {
        this.signablePreventionPlanService.getChildren(entity._id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.icpService.getChildList(entity._id).toPromise();
  }
  export() {
    this.getAll().toPromise().then((wbList: EntityPreventionPlan[]) => {
      if (wbList && wbList.length) {
        const lines = [];
        wbList.forEach((wb) => {
          const line = wb.name;
        });
      }
    });
  }
}
