import {AfterViewInit, ChangeDetectorRef, Directive, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {PermissionsService} from '../auth/';

@Directive({
  selector: '[appDisableControl]'
})
export class DisableControlDirective implements AfterViewInit {

  right: string = '';

  @Input() archived: boolean;

  @Input() set appDisableControl( right: string ) {
    this.right = right;
  }


  constructor( private ngControl: NgControl,
               protected permissionsService: PermissionsService,
               private cdRef: ChangeDetectorRef) {
  }
  ngAfterViewInit() {
    const authorized = this.permissionsService.hasPermission(this.right);
    if (authorized) {
      this.archived ? this.ngControl.control['disable']() : this.ngControl.control['enable']();
    } else {
      this.ngControl.control['disable']();
    }
    this.cdRef.detectChanges();
  }
}
