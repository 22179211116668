import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoggerService, EntityFormComponent } from 'app/shared';
import { DocumentationService, EntityDocumentation } from 'app/workbook-core';

@Component({
  selector: 'app-repository-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})

export class DocumentationComponent extends EntityFormComponent {

  @Input() entity: EntityDocumentation;

  file_mimes: string[] = ['image/png', 'image/jpeg', 'image/gif'];

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DocumentationService

  ) {
    super(dialog, _logger, _entityService);
  }

  protected _save() {
    if (this.entity) {
      if (!this.isImage() && this.entity.meta && this.entity.meta.isInline) {
        this.entity.meta.isInline = false;
      }
      if (!this.entity.oo) {
        this.entity.oo_pdp = false;
        this.entity.oo_icp = false;
        this.entity.oo_pf = false;
        this.entity.oo_ps = false;
      }
      if (!this.entity.op) {
        this.entity.op_pdp = false;
        this.entity.op_icp = false;
        this.entity.op_pf = false;
        this.entity.op_ps = false;
      }
      if (!this.entity.ps) {
        this.entity.ps_ps = false;
      }
    }
    return super._save();
  }
  checkboxChange(field: string) {
    this.entity[field] = !this.entity[field];
    this._save();
  }
  inlineChange() {
    if (this.isImage()) {
      this.entity.meta.isInline = !this.entity.meta.isInline;
      this._save();
    } else if (!!this.entity && !!this.entity.meta && this.entity.meta.isInline) {
      this.entity.meta.isInline = false;
      this._save();
    }
  }
  isImage() {
    return !!this.entity && !!this.entity.meta && this.file_mimes.indexOf(this.entity.meta.mime) !== -1
  }

}
