import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, ConfirmDialogComponent, SignableStatus} from 'app/shared';
import {WorkbookSignableListComponent, WorkbookIcpDialogComponent} from 'app/workbook-shared';
import {PreventionPlanService, EntityPreventionPlan, IcpService, EntityIcp, ICPState, EntityStep, EntitySignablePreventionPlan, DocumentationService} from 'app/workbook-core';
import {WorkbookIcpSignableDialogComponent} from './icpSignable-dialog.component';
import {SignablePreventionPlanDialogComponent} from '../signablePreventionPlan/signablePreventionPlan-dialog.component';


@Component({
  selector: 'app-prevention-plan-icp-list',
  templateUrl: './icpList.component.html',
  styleUrls: ['./icpList.component.scss']
})

export class PreventionPlanIcpListComponent extends WorkbookSignableListComponent {

  entity: EntityPreventionPlan;
  entityList: EntityIcp[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette inspection ?';
  addConfirmMessage = 'Nouvelle inspection créée';

  stepList: {[step_id: string]: EntityStep} = {};
  steps: EntityStep[] = [];
  protected _stepsSubscription: Subscription;

  lastPdp: {[icp_id: string]: EntitySignablePreventionPlan} = {};
  allPdp: {[icp_id: string]: EntitySignablePreventionPlan[]} = {};
  states: {[icp_id: string]: number} = {};
  protected _pdpSubscription: Subscription;

  dataSource: MatTableDataSource<EntityIcp> = new MatTableDataSource([]);
  displayedColumns = ['name', 'iPDate', 'iPSite', 'editor', 'steps', 'lastpdp', 'state', 'actions']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';
  withStep: boolean = false;
  /*
    protected _icpRecurrenceSubscription: Subscription;
    icpRecurrence: EntityIcpRecurrence;
  */
  constructor(
    //preload
    protected _documentationService: DocumentationService,
    protected _childService: IcpService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
    /*
        this._icpRecurrenceSubscription = this._icpRecurrenceService.getSingleOne().subscribe((ir: EntityIcpRecurrence) => {
          if (ir) {
            this.icpRecurrence = ir;
          }
        });
        */
  }
  protected _init(data) {
    super._init(data);
    if (this.entityList && this.entity) {
      let modified = this.entity.removeDeletedIcp(this.entityList.map((e) => (e._id)));
      this.entityList.forEach((icp: EntityIcp) => {
        modified = this.entity.updateIcp(icp, modified)
      });
      if (modified) {
        this._entityService.save(this.entity);
      }
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list: EntityStep[]) => {
      if (list) {
        this.withStep = this.withStep || !!list.length;
        this.stepList = {};
        list
          .forEach((step) => {
            this.stepList[step._id] = step;
          });
        this.steps = list;
      }
    });
    if (this._pdpSubscription) {
      this._pdpSubscription.unsubscribe();
    }
    this._pdpSubscription = this._entityService.signablePreventionPlanService.getChildren(this.entity._id).subscribe((list) => {
      if (list) {
        this.lastPdp = {};
        this.allPdp = {};
        list
          .forEach((pdp: EntitySignablePreventionPlan) => {
            if (pdp.icps && pdp.icps.length) {
              pdp.icps.forEach((icp_id) => {
                if (!this.lastPdp[icp_id]
                  || this.lastPdp[icp_id].status < pdp.status
                  || (this.lastPdp[icp_id].status === pdp.status
                    && new Date(this.lastPdp[icp_id].editedAt).getTime() < new Date(pdp.editedAt).getTime())
                ) {
                  this.lastPdp[icp_id] = pdp;
                }
                if (!this.allPdp[icp_id]) {
                  this.allPdp[icp_id] = [];
                }
                this.allPdp[icp_id].push(pdp);
                this.allPdp[icp_id] = this.allPdp[icp_id].sort((a, b) => ((new Date(a.editedAt)).getTime() - (new Date(b.editedAt)).getTime()));
              });
            }
          });

        this.refreshStates()
      }
    });
    this.refreshDataSource();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._pdpSubscription) {
      this._pdpSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    /*
    if (this._icpRecurrenceSubscription) {
      this._icpRecurrenceSubscription.unsubscribe();
    }
    */
  }

  refreshStates() {
    if (this.entityList && this.entity) {
      this.states = {};
      this.entityList.forEach((icp) => {
        this.states[icp._id] = this._getChildService().getState(icp, this.entity.status, this.lastPdp[icp._id] ? this.lastPdp[icp._id].status : 0);
      });
    }
  }


  getState(icp: EntityIcp) {
    return icp && icp._id && this.states[icp._id] ? this.states[icp._id] : 0;

  }
  getLabelState(icp: EntityIcp) {
    return ICPState.getLabel(this.getState(icp));

  }
  getDescriptionState(icp: EntityIcp) {
    return ICPState.getDescription(this.getState(icp));

  }
  protected _getChildService(): IcpService {
    return this._entityService.icpService;
  }

  public viewIcp(icp: EntityIcp) {
    if (icp.status > 1) {
      this.openSignableIcp(icp);
    } else {
      this._router.navigate(['/' + this._entityService.getUrlIcp(this.entity.documentType, this.entity._id, icp._id)]);
    }
  }
  public openSignableIcp(icp) {
    // const dialogRef: MatDialogRef<WorkbookExportDialogComponent> =
    const dialogRef = this.dialog.open(WorkbookIcpSignableDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        entity: icp,
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      //console.log(c);
    });
  }

  openPdP(pdp: EntitySignablePreventionPlan) {
    this.dialog.open(SignablePreventionPlanDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {entity: pdp}
    });
  }

  public editIcp(icp: EntityIcp) {
    return ['/' + this._entityService.getUrlIcp(this.entity.documentType, this.entity._id, icp._id)];
  }
  /**
   * Create dialog popup inspection
   */
  public duplicateIcp(element) {
    this._getChildService().getCurrentOne(element._id).then((icp) => {
      const newElement: EntityIcp = this._getChildService().getNewEntity(icp, true);
      newElement.status = 0;
      newElement.signatures = [];
      newElement.iPOEex = [];
      newElement.iPDate = null;
      newElement.iPHourStart = null;
      newElement.iPHourEnd = null;
      newElement.content = '';
      newElement.createdAt = null;
      newElement.createdId = '';
      newElement.createdName = '';
      newElement.editor = '';
      newElement.editorEmail = '';
      newElement.implementations = {};
      newElement.withoutSignatories = false;
      newElement.notifications = [];
      newElement.iPOEex = [];
      this.selectIcp(newElement);
    });
  }
  /**
   * Create dialog popup inspection
   */
  public selectIcp(icp = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(WorkbookIcpDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        id: icp ? icp._id : '',
        entity: icp && !icp._id ? icp : null,
        duplication: (icp && !icp._id),
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this._entityService.updateWorkbookInspection(this.entity, c, this.steps);
      }
    });
  }

  outdateSignable(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = "Confirmer";
    dialogRefDel.componentInstance.confirmMessage = "Voulez-vous rendre cette édition obsolète ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().get(entity._id).toPromise().then((signable: EntityIcp) => {
          if (signable) {
            signable.status = SignableStatus.OUTDATED;
            this._getChildService().save(signable).then((e) => {
              this.updateElement(signable);
            });
          }
        });
      }
    });
  }

  updateElement(element: EntityIcp) {
    if (element._deleted) {
      this._entityService.updateWorkbookInspection(this.entity, element, this.steps);
    }
    super.updateElement(element);
    if (element && this._updateListElement(element)) {
      this._init(this.entityList);
    }
  }

}
