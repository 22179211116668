import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService } from 'app/shared';
import { EntityFirePermit, FPState, FPStatus } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FirePermitService extends EntitySignableService {

  entity: EntityFirePermit;
  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement du permis feu";
  static LABEL_ALL = "chargement des permis feu ?";
  static LABEL_CHILDREN = "chargement des permis feu de l'opération";
  static LABEL_SAVE = "sauvegarde du permis feu";
  static LABEL_DELETE = "suppression du permis feu";

  static ENTITY_TYPE: string = 'sfp';
  static ENTITY_FIELDS: string[] = [
    'name',
    'code',
    'editor',
    'editorEmail',
    'editorDate',
    'editedAt',
    'status',
    'description',
    'dateStart',
    'dateEnd',
    'point',
    'site',
    'step',
    'originator',
    'performer',
    'operators',
    'notifications'
  ];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showFirePermit'],
    CREATE: ['createFirePermit'],
    UPDATE: ['editFirePermit'],
    DELETE: ['removeFirePermit']
  }

  static ENTITY_URL = 'fp';
  static PREFIX = 'PF';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityFirePermit {
    return new EntityFirePermit(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.editorDate).getTime() === new Date(b.editorDate).getTime())
        ? 0
        : (new Date(a.editorDate).getTime() > new Date(b.editorDate).getTime()) ? -1 : 1)));

  }

  getState(entity: EntityFirePermit, workbook_status) {
    let state = (workbook_status && workbook_status === 'archived') ? FPState.ARCHIVED : FPState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === FPStatus.VALIDATED) {
        state = FPState.PREPARED;
      } else if (entity.status === FPStatus.SIGNING) {
        state = FPState.SIGNING;
      } else if (entity.status === FPStatus.SIGNED) {
        let now = new Date;
        let start = new Date(entity.dateStart);
        let end = new Date((start.getTime() + (1000 * 24 * 3600)));
        state = ((now.getTime() > start.getTime()) && (now.getTime() < end.getTime())) ? FPState.VALID : FPState.SIGNED;
      } else if (entity.status === FPStatus.OUTDATED) {
        state = FPState.OUTDATED;
      }

    }
    return state;
  }
}
