import {DbCommonService, PermissionsService} from 'app/shared';
import {LoadingService} from './loading.service';
import {EntitySecureService} from './entitySecure.service';

export abstract class EntitySingleService extends EntitySecureService {

  static ENTITY_SINGLE: boolean = true;
  static ENTITY_FIELDS: string[] = [];

  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
}
