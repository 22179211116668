import {ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {formattedDate} from 'app/shared';
import {
  PermissionsService,
  LoggerService,
  ConfirmDialogComponent,
  EntityFormComponent,
  Contact,
  EntitySecureService,
  EntityDocumentMetadata,
  DocumentService,
  DocumentMetadataDialogComponent
} from 'app/shared';
import {EntityOffice, EntityCategory, OfficeService, CategoryService} from 'app/workbook-core';
import {RepositoryContactDialogComponent} from './contact-dialog';
import {DocumentsRequestDialogComponent, DocumentsUploadDialogComponent} from './document';


export class AbstractOfficeComponent extends EntityFormComponent {
  entity: EntityOffice;
  //internal pour regexInternal dans contactdialog
  internal: boolean = true;

  selectedDocument: EntityDocumentMetadata;
  displayedDocumentColumns: string[] = ['categoryPicto', 'name', 'category', 'date', 'download', 'delete'];
  documentDataSource: MatTableDataSource<EntityDocumentMetadata> = new MatTableDataSource([]);
  @ViewChild('documentSort') documentSort: MatSort;

  selectedContact: Contact;
  displayedContactColumns: string[] = ['name', 'job', 'phone', 'email', 'actions', 'delete'];
  contactDataSource: MatTableDataSource<Contact> = new MatTableDataSource([]);
  @ViewChild('contactSort') contactSort: MatSort;

  userListSub: Subscription;
  usersList = [];

  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionsService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, _logger, _entityService);

    this.userListSub = this._permissionsService.auth.usersList$.subscribe((list) => {
      if (list) {
        this.usersList = list.sort((a, b) => {
          const a_name = (a.user_metadata && (a.user_metadata.family_name || a.user_metadata.given_name)) ? (a.user_metadata.family_name ? a.user_metadata.family_name : '' + a.user_metadata.given_name ? a.user_metadata.given_name : '') : (a.name ? a.name : a.email);
          const b_name = (b.user_metadata && (b.user_metadata.family_name || b.user_metadata.given_name)) ? (b.user_metadata.family_name ? b.user_metadata.family_name : '' + b.user_metadata.given_name ? b.user_metadata.given_name : '') : (b.name ? b.name : b.email);
          const test = (a_name.localeCompare(b_name));
          //console.log('tri ' + a_name + ' / ' + b_name, test);
          return test;
        });
      }
    });
    this.refreshUserList();
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      if (!this._categorySubscription) {
        this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
          if (list) {
            this.categoryList = list;
          }
        });
      }
      this.updateDocumentDataSource();
      this.updateContactDataSource();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.userListSub) {
      this.userListSub.unsubscribe();
    }
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
  }

  /**
	 * Refresh user List
	 */
  refreshUserList() {

    if (this.hasPermission('showUsers')) {
      this._permissionsService.auth.refreshUsersList().then((res) => {
        //        this.usersList = auth0UserList;
        this.usersLoaded();
      },
        error => {
          this._logger.error('AbstractOfficeComponent', 'error get users list', JSON.stringify(error));
        }
      );
    }
  }

  usersLoaded() {}

  protected setContactSort() {
    if (this.contactSort) {
      this.contactDataSource.sort = this.contactSort;
    }
  }
  protected updateContactDataSource() {
    this.contactDataSource = new MatTableDataSource((this.entity && this.entity.contacts) ? this.entity.contacts : []);
    setTimeout(() => {
      this.setContactSort();
    });
  }
  clearContactForm() {
    this.selectedContact = new Contact();
  }
  selectContact(contact: Contact = new Contact()) {
    if (this.rightU) {
      this.selectedContact = contact;
      const dialogRef = this.dialog.open(RepositoryContactDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: {
          internal: this.internal,
          contact: Object.assign({}, this.selectedContact),
          usersList: this.usersList
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {

          if (this.hasPermission('showUsers')) {
            this._permissionsService.auth.refreshUsersList().then((list) => {
              //              this.usersList = list;
              this.upsertContact(c);
            })
          } else {
            this.upsertContact(c);
          }
        }
      });
    }
  }
  /**
	 * Create new sub company
	 * @param externalCompanyId
	 */
  upsertContact(contact): void {

    let existingUser = true;
    this.updateSelectedContact(contact);
    let _existingUser = this.selectedContact.user_id
      ? this.usersList.find(e => e.user_id === this.selectedContact.user_id)
      : this.usersList.find(e => e.email.toLowerCase() === this.selectedContact.email.toLowerCase());

    // userlist vide = pas de droits showusers ou scope insuffisant
    if (this.usersList.length && !this.selectedContact.user_id && this.selectedContact.email) {
      if (_existingUser) {
        this.selectedContact.user_id = _existingUser.user_id;
      } else {
        existingUser = false;
      }
    }
    if (!this.entity.contacts) {
      this.entity.contacts = [];
    }
    const index = this.entity.contacts.indexOf(this.selectedContact);
    if (index === -1) {
      this.entity.contacts.push(this.selectedContact);
    }
    this.updateContactDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
    if (!existingUser && this.selectedContact.email) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });

      dialogRef.componentInstance.confirmMessage = 'Votre contact a bien été ajouté.<br>'
        + 'Souhaitez-vous dès maintenant lui créer un compte, et donc lui envoyer une invitation à rejoindre l\'application ?<br>' +
        'Vous avez aussi la possibilité de faire cela plus tard, dans ce cas cliquez sur “Non”.';
      dialogRef.componentInstance.cancelMessage = 'Non';
      dialogRef.componentInstance.buttonMessage = 'Oui';
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const family_name_array = this.selectedContact.name ? this.selectedContact.name.split(' ') : [];
          let given_name = '';
          if (family_name_array.length > 1) {
            family_name_array.shift();
            given_name = this.selectedContact.name.split(' ')[0];
          }
          const meta = {
            'family_name': family_name_array.join(' '),
            'given_name': given_name,
            'phone_number': this.selectedContact.phone,
            'syncFilter': []
          };
          if (this.entity.contactSyncFilter && this.entity.documentType === OfficeService.ENTITY_TYPE) {
            meta.syncFilter.push(this.entity._id);
          }
          this._getCreateUserPromise(this.selectedContact.email, '', meta).then(
            (u) => {
              this.refreshUserList();
              this.selectedContact.user_id = u.user_id;
              this.updateContactDataSource();
              this.snackBar.open('Utilisateur créé', '', {
                duration: 2000
              });
              if (this.autoSave && this.form.valid) {
                this._save();
              }
            },
            (error) => {
              if (error && error.status && error.status === 409) {
                this.refreshUserList();
              }
              this._logger.error('AbstractOfficeComponent', 'error create user', JSON.stringify(error));
              this.snackBar.open('Erreur', 'L\'utilisateur n\'a pas pu être créé', {
                duration: 2000
              });
            })
        }
      });
    } else if (_existingUser
      && _existingUser['user_metadata']) {
      if (!_existingUser['user_metadata']['syncFilter']) {
        _existingUser['user_metadata']['syncFilter'] = [];
      }
      //TODO: changer ca => un parametre par service / pas de popup
      if (this.entity.contactSyncFilter && this.entity.documentType === OfficeService.ENTITY_TYPE && _existingUser['user_metadata']['syncFilter'].indexOf(this.entity._id) === -1) {

        _existingUser['user_metadata']['syncFilter'].push(this.entity._id);

        this._getUpdateUserMetadataPromise(_existingUser).then(
          (u) => {
            this.refreshUserList();
            this.snackBar.open('Cet utilisateur synchronisera tous les dossiers du service', '', {
              duration: 2000
            });
          },
          (error) => {
            if (error && error.status && error.status === 409) {
              this.refreshUserList();
            }
            this._logger.error('AbstractOfficeComponent', 'error adding office to user', JSON.stringify(error));
            this.snackBar.open('Erreur', 'L\'utilisateur n\'a pas pu être associé au service', {
              duration: 2000
            });
          })
      }
    }
  }
  _getUpdateUserMetadataPromise(user) {
    return this._permissionsService.updateUserMetadata(user);
  }
  _getCreateUserPromise(email, password = '', meta = {}, sendmail = true) {
    return this._permissionsService.createExternalUser(email, password, meta, sendmail, ['PP_CHOP']);
  }
  updateSelectedContact(contact: Contact) {
    this.selectedContact.user_id = contact.user_id;
    this.selectedContact.name = contact.name;
    this.selectedContact.email = contact.email;
    this.selectedContact.phone = contact.phone;
    this.selectedContact.fax = contact.fax;
    this.selectedContact.job = contact.job;
    this.selectedContact.signatory = contact.signatory;
    this.selectedContact.signatoryPdP = contact.signatoryPdP;
    this.selectedContact.signatoryPermit = contact.signatoryPermit;
    this.selectedContact.chsct = contact.chsct;
    this.selectedContact.repo_id = contact.repo_id;
    this.selectedContact.isDefault = contact.isDefault;
  }
  /**
 * Delete sub company
 * @param {string} externalCompanyId
 * @param {number} index
 */
  removeContact(contact: Contact) {
    if (this.entity && this.entity.contacts) {
      const index = this.entity.contacts.indexOf(contact);
      if (index > -1) {
        // this.rmContact(this.externalCompanySelected.id, index);
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce contact ?';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.contacts.splice(index, 1);
            this.updateContactDataSource();
            if (this.autoSave && this.form.valid) {
              this._save();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }

    }
  }

	/**
	 * create user
	 * @param user
	 * @param clearForm
	 */
  createUser(user, clearForm = false) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Voulez-vous créer l\'utilisateur ? <br>Attention si vous confirmez, un email sera envoyé immédiatement à l’utilisateur avec un lien pour rejoindre l’application.<br>' +
      'Vous avez la possibilité de créer l’utilisateur du contact ultérieurement, pour cela cliquez sur Annuler.';
    dialogRef.componentInstance.buttonMessage = 'Confirmer';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const existingUser = this.usersList.find(e => e.email.toLowerCase() === user.email.toLowerCase());
        if (existingUser) {
          user.user_id = existingUser.user_id;
          this.snackBar.open('Utilisateur existant ajouté', '', {
            duration: 2000
          });
          this._save();
          if (clearForm) {
            setTimeout(() => {this.clearContactForm();});
          }
        } else {
          const family_name_array = user.name ? user.name.split(' ') : [];
          let given_name = '';
          if (family_name_array.length > 0) {
            family_name_array.shift();
            given_name = user.name.split(' ')[0];
          }
          const meta = {
            'family_name': family_name_array.join(' '),
            'given_name': given_name,
            'phone_number': user.phone
          };
          this._getCreateUserPromise(user.email, '', meta).then((u) => {
            this.refreshUserList();
            user.user_id = u.user_id;
            this.snackBar.open('Utilisateur créé', '', {
              duration: 2000
            });
            this._save();
            if (clearForm) {
              setTimeout(() => {this.clearContactForm();});
            }
          }, (error) => {

            if (error && error.status && error.status === 409) {
              this.refreshUserList();
            }
            this._logger.error('ExternalCompaniesComponent', 'error to create user', JSON.stringify(error));
            this.snackBar.open('Erreur', 'L\'utilisateur n\'a pas pu être créé', {
              duration: 2000
            });
            if (clearForm) {
              setTimeout(() => {this.clearContactForm();});
            }
          });
        }
      } else {
        if (clearForm) {
          setTimeout(() => {this.clearContactForm();});
        }
      }
    });
  }

	/**
	 * Send confirmation email
	 * @param user
	 */
  sendConfirmEmail(user) {
    this._permissionsService.emailVerificationTicket(user.user_id, user, true).then((e) => {
      this.snackBar.open('Le message a été envoyé', '', {
        duration: 2000
      });
    });
  }

  userExists(user_id: string) {
    return !!user_id && (!this.usersList.length || this.usersList.findIndex(e => e.user_id === user_id) !== -1);
  }

  getUserInfo(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return user ? user.name + (user.name !== user.email ? ' (' + user.email + ')' : '') : '';
  }

  getUserTooltip(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return user ? (user.email_verified ? 'Dernière connexion/modification: \n' : ((user.created_at === user.updated_at) ? 'Compte créé: \n' : 'Dernière modification: \n')) + formattedDate(user.updated_at, true) : '';
  }
  getEmailVerified(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return (user && !!user.email_verified);
  }

  protected setDocumentSort() {
    if (this.documentSort) {
      this.documentDataSource.sort = this.documentSort;
    }
  }

  protected updateDocumentDataSource() {
    this.documentDataSource = new MatTableDataSource((this.entity && this.entity.documents) ? this.entity.documents : []);
    setTimeout(() => {
      this.setDocumentSort();
    });
  }
  openDocumentDialog(document: EntityDocumentMetadata = null) {
    if (this.rightU) {
      if (document) {
        this.selectedDocument = document;
      } else {
        this.selectedDocument = null;
      }
      const dialogRef = this.dialog.open(DocumentMetadataDialogComponent, {
        disableClose: true,
        minWidth: '900px',
        maxHeight: '90%',
        data: {
          entity: this._documentService.getNewDocumentMetada(document ? document : null),
          categoryList: this.categoryList,
          requestList: this.entity.contacts.filter((e) => (!!e.email)),
          selectedEex: this.entity,
          showDefault: true
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          this.upsertDocument(c);
        }
      });
    }
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }

  upsertDocument(document: EntityDocumentMetadata): void {
    if (!this.entity.documents) {
      this.entity.documents = [];
    }
    let index = -1;
    if (this.selectedDocument) {
      this.updateSelectedDocument(document);
      index = this.entity.documents.indexOf(this.selectedDocument);
    } else {
      this.selectedDocument = document;
    }
    if (index === -1) {
      this.entity.documents.push(this.selectedDocument);
    }
    this.updateDocumentDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
  }

  updateSelectedDocument(document: EntityDocumentMetadata) {
    this.selectedDocument.isDefault = document.isDefault;
    this.selectedDocument.name = document.name;
    this.selectedDocument.ext = document.ext;
    this.selectedDocument.status = document.status;
    this.selectedDocument.description = document.description;
    this.selectedDocument.category = document.category;
    this.selectedDocument.categoryPicto = document.categoryPicto;
    this.selectedDocument.dateStart = document.dateStart;
    this.selectedDocument.dateEnd = document.dateEnd;
    this.selectedDocument.createdAt = document.createdAt;
    this.selectedDocument.createdName = document.createdName;
    this.selectedDocument.createdId = document.createdId;
    this.selectedDocument.updatedAt = document.updatedAt;
    this.selectedDocument.updatedName = document.updatedName;
    this.selectedDocument.updatedId = document.updatedId;
    this.selectedDocument.requestedAt = document.requestedAt;
    this.selectedDocument.requestedName = document.requestedName;
    this.selectedDocument.requestedId = document.requestedId;
    this.selectedDocument.requestedSent = document.requestedSent.map((e) => (e));
    this.selectedDocument.request = document.request.map((e) => (e));
    this.selectedDocument.notifications = document.notifications.map((e) => (e));
    //if (!this.selectedDocument.data_id && document.data_id) {
    this.selectedDocument.data_id = document.data_id;
    this.selectedDocument.imageState = document.imageState;
    this.selectedDocument.data_origin = document.data_origin;
    this.selectedDocument.displayName = document.displayName;
    this.selectedDocument.mime = document.mime;
    this.selectedDocument.ext = document.ext;
    this.selectedDocument.icon = document.icon;
    this.selectedDocument.uploadedAt = document.uploadedAt;
    this.selectedDocument.uploadedName = document.uploadedName;
    this.selectedDocument.uploadedId = document.uploadedId;
    this.selectedDocument.context = document.context;

    //}
  }

  removeDocument(document: EntityDocumentMetadata) {
    if (this.entity && this.entity.documents) {
      const index = this.entity.documents.indexOf(document);
      if (index > -1) {
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document ?';
        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.documents.splice(index, 1);
            this.updateDocumentDataSource();
            if (this.autoSave && this.form.valid) {
              this._save();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }

    }
  }

  public addDocumentsDialog() {
    const dialogRef = this.dialog.open(DocumentsUploadDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        entity_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        multiple: true,
        selectedEex: this.entity,
        showDefault: true
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.updateDocumentDataSource();
        this.onSave.emit(this.entity);
      }
    });

  }
  public requestDocumentsDialog() {
    const dialogRef = this.dialog.open(DocumentsRequestDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        entity_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        multiple: true,
        selectedEex: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.updateDocumentDataSource();
        this.onSave.emit(this.entity);
      }
    });

  }
}
