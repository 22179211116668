import {Component, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog, MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import {UUID} from 'angular2-uuid';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {comparableString, LoggerService, EntityFormComponent, formattedDate, ConfirmDialogComponent} from 'app/shared';
import {WorkbookStepService, EntityStep, EntityOffice, SiteService, EntitySite, EntityZone, EntityWorkbook, IcpRecurrenceService, EntityIcpRecurrence} from 'app/workbook-core';
import {ZoneDialogComponent} from '../localisation'

@Component({
  selector: 'app-workbook-step-info',
  templateUrl: './stepInfo.component.html',
  styleUrls: ['./stepInfo.component.scss']
})
export class WorkbookStepInfoComponent extends EntityFormComponent implements OnChanges, OnDestroy {
  autoSave = false;
  // @Input() officeList: EntityOffice[];
  public entity: EntityStep;

  public placeHolderStartDate: string = 'Début';
  public placeHolderEndDate: string = 'Fin';
  public placeHolderDescription: string = 'Nom';
  @Input() maxDateEnd: Date;
  @Input() minDateStart: Date;
  @Input() simple: boolean = false;
  @Input() workbook: EntityWorkbook;
  workbookType: string = "";

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  isLoading: boolean = false;
  siteSelection: boolean = false;

  protected _zoneChangeSubscription: Subscription;
  zoneList: {[site_id: string]: EntityZone[]}
  filteredZones: EntityZone[] = [];
  zoneSelection: boolean = false;

  protected _stepsSubscription: Subscription;
  stepNames: string[] = [];

  template: EntityStep;
  protected _templateSubscription: Subscription;

  protected _icpRecurrenceSubscription: Subscription;
  icpRecurrence: EntityIcpRecurrence;
  showIcpRecurrence: boolean = true;
  disabledSystematic: boolean = true;

  dateStartError: string = '';
  dateEndError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  location_site_id: string;
  //location_zone_id: string;
  locationZones: {[site_id: string]: EntityZone[]} = {};
  locations: EntitySite[] = [];

  public allZones: boolean = true;
  public allowAllZones: boolean = true;

  zonesDataSource: MatTableDataSource<EntityZone> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  zonesDisplayedColumns = ['name', 'actions']

  constructor(
    protected _icpRecurrenceService: IcpRecurrenceService,
    protected _siteService: SiteService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredSites = this.getFilteredSites((this.entity && this.entity.site && this.entity.site.name) ? this.entity.site.name : '');
        this.zoneList = {};
        this.siteList.forEach((s) => {
          this.zoneList[s._id] = s.zones.sort((a, b) => a.name.localeCompare(b.name));
        });
      }
    });
    this._icpRecurrenceSubscription = this._icpRecurrenceService.getSingleOne().subscribe((ir: EntityIcpRecurrence) => {
      if (ir) {
        this.icpRecurrence = ir;
        this.updateIcpRecurrence();
      }
    });
  }
  protected updateIcpRecurrence() {
    if (this.workbook && this.workbook.type === 'annual' && !this.workbook.simple && this.icpRecurrence) {
      this.showIcpRecurrence = this.icpRecurrence.enabled;
      this.disabledSystematic = !!(this.icpRecurrence.systematicCases && this.icpRecurrence.systematicCases.length);
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.workbook) {
      this.workbookType = this.workbook.type;
      if (this.entity && !this._stepsSubscription) {
        this._stepsSubscription = this._entityService.getChildren(this.workbook._id).subscribe((list) => {
          if (list) {
            this.stepNames = list
              .filter((e) => (!this.entity._id || e._id !== this.entity._id))
              .map((e) => (e.name));
          }
        });
      }
      this.updateIcpRecurrence();
    }
    if (this.simple) {
      if (this.workbookType === "annual") {
        this.placeHolderStartDate = 'Début de validité';
        this.placeHolderEndDate = 'Fin de validité';
        this.placeHolderDescription = 'Objet du protocole';
      } else {
        this.placeHolderStartDate = 'Début de l\'opération';
        this.placeHolderEndDate = 'Fin de l\'opération';
        this.placeHolderDescription = 'Nature de l\'opération';

      }
    }
    if (this.entity) {
      if (this.entity.site && this.entity.site._id) {
        this.location_site_id = this.entity.site._id
      }

      if (this.entity.template_id) {
        if (this._templateSubscription) {
          this._templateSubscription.unsubscribe();
        }
        this._templateSubscription = this._entityService.getOne(this.entity.template_id).subscribe((t) => {
          if (t) {
            this.locationZones = {};
            this.template = t;
            this.locations = this.template.getLocations()
            this.locations.forEach((s) => {
              this.locationZones[s._id] = s.zones;
            });
          }
        });
      }
      this.refreshDataSource();
      this.updateAllZones();
    }

  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {this._initSearchSite();});

  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._zoneChangeSubscription) {
      this._zoneChangeSubscription.unsubscribe();
    }
    if (this._templateSubscription) {
      this._templateSubscription.unsubscribe();
    }
    if (this._icpRecurrenceSubscription) {
      this._icpRecurrenceSubscription.unsubscribe();
    }
  }
  refreshDataSource() {
    if (this.entity.site && this.entity.site.zones) {
      this.zonesDataSource = new MatTableDataSource(this.entity.site.zones);

      if (this.paginator) {
        this.zonesDataSource.paginator = this.paginator;
      }
      if (this.sort) {
        this.zonesDataSource.sort = this.sort;
      }
    }
  }

  addZone() {
    this.selectZone(null);
  }
  selectZone(zone: EntityZone) {
    const dialogRef = this.dialog.open(ZoneDialogComponent, {
      disableClose: false,
      width: '600px',
      data: {
        zone: zone,
        site: this.entity.site,
        allowedZones: this.getFilteredZones(),
        allowAllZones: ((zone && zone.name === '') || (this.entity.site.zones.findIndex((e) => (e.name === '')) === -1))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.entity.addZone(result);
        this.refreshDataSource();
      }
    }
    );
  }

  removeZone(zone: EntityZone) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer cette zone ?';
    if (zone.measures && zone.measures.length) {
      dialogRef.componentInstance.confirmMessage += '<br><b>Attention, cette zone comporte des mesures de prévention qui seront également supprimée</b>';
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.entity.removeZone(zone);
        this.refreshDataSource();
      }
    });
  }
  updateAllZones(autocheck: boolean = true) {
    if (this.entity) {
      this.allowAllZones = this.entity && !this.entity.point;

      //console.log("updateAllZones " + this.allowAllZones ? 'allowed' : 'notallowed', this.entity);
      /*
        && !(this.entity.site && this.entity.site.repo_id
          && this.zoneList[this.entity.site.repo_id]
          && this.zoneList[this.entity.site.repo_id].filter((z) => (!z.name)).length !== -1)
          */
      this.allZones = autocheck && this.allowAllZones;
    }
  }
  protected _initSearchSite() {
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls[this.autocompleteoff + 'site_name']) {
      this.filteredSites = this.getFilteredSites((this.entity && this.entity.site && this.entity.site.name) ? this.entity.site.name : '');
      if (this.filteredSites && this.filteredSites.length > 200) {
        this.filteredSites = [];
      }
      this._siteChangeSubscription = this.form.controls[this.autocompleteoff + 'site_name'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.siteSelection) {
            this.siteSelection = false;
          } else {
            if (this.entity && this.entity.site && this.entity.site.repo_id) {
              this.entity.site.repo_id = '';
              const i = this.entity.locations.findIndex((e) => (e.name === value));
              if (i !== -1) {
                this.entity.site._id = this.entity.locations[i]._id;
              } else {
                this.entity.site._id = '';
              }
              if (this.entity.site.zones && this.entity.site.zones.length) {
                this.entity.site.zones = this.entity.site.zones.map((z) => {
                  if (z.repo_id) {
                    const _z = new EntityZone(z);
                    _z.repo_id = '';
                    return _z;
                  } else {
                    return z
                  }
                });
              }
              if (this.entity.site.zones && !this.entity.site.zones.length) {
                this.entity.site.zones.push(new EntityZone());
              }
              //this.entity.zone = null;
              this.filteredZones = [];
            }
            this.filteredSites = this.getFilteredSites(value);
            this.refreshDataSource();
            this.updateAllZones(false);
          }
        });
    }
    /*
    if (!this._zoneChangeSubscription && this.form && this.form.controls && this.form.controls[this.autocompleteoff + 'point']) {
      this.filteredZones = this.getFilteredZones((this.entity && this.entity.point) ? this.entity.point : '');
      if (this.filteredZones && this.filteredZones.length > 200) {
        this.filteredZones = [];
      }
      this._zoneChangeSubscription = this.form.controls[this.autocompleteoff + 'point'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.zoneSelection) {
            this.zoneSelection = false;
          } else if (this.entity && this.entity.site) {
            this.entity.zone = this.entity.point ? new EntityZone({ name: this.entity.point }) : this.getEmptyZone();
            this.filteredZones = this.getFilteredZones(value);
            this.siteSelection = true;
            this.updateAllZones();
          }
        });
    }
    */
  }
  /**
   * auto fills inputs for site data
   * @param site
   */
  public siteSelected(event, site: EntitySite) {
    //console.log('siteSelected', [event, site]);
    if (event && event.isUserInput && site) {
      const _oldZones = this.entity.site.zones.map((z) => {
        if (z.repo_id) {
          const _z = new EntityZone(z);
          _z.repo_id = '';
          return _z;
        } else {
          return z
        }
      });
      const entitySite: EntitySite = this._siteService.getNewEntity(site);
      entitySite.zones = _oldZones;
      entitySite._id = UUID.UUID();
      entitySite.repo_id = site._id;
      this.entity.site = entitySite;
      if (this.entity.site.zones && !this.entity.site.zones.length) {
        this.entity.site.zones.push(new EntityZone());
      }

      /*
      this.filteredZones = this.getFilteredZones((this.entity && this.entity.point) ? this.entity.point : '');
      if (!this.entity.zone || (this.entity.zone.repo_id && this.filteredZones.findIndex((e) => (e._id === this.entity.zone.repo_id)) === -1)) {
        this.entity.zone = this.getEmptyZone();
        this.entity.point = '';
      }
      */
      this.siteSelection = true;
      this.refreshDataSource();
      this.updateAllZones();
    }
  }
  public zoneSelected(event, zone: EntityZone) {
    //console.log('zoneSelected', [event, zone]);
    if (zone) {
      this.entity.point = zone.name;
      const repo_id = zone._id;
      this.entity.zone = new EntityZone(zone, true);
      this.entity.zone.repo_id = repo_id;
      this.zoneSelection = true;
      this.updateAllZones();
    }
  }

  protected getFilteredSites(value: string): EntitySite[] {
    let returned = [];
    if (this.entity && this.siteList) {
      returned = value ? this.siteList.filter(site => (comparableString(site.name).indexOf(comparableString(value)) !== -1)) : this.siteList;
    }
    return returned.length > 200 ? [] : returned;
  }
  protected getFilteredZones(): EntityZone[] {
    let returned = [];
    if (this.entity && this.entity.site && this.entity.site.zones && this.entity.site.repo_id && this.zoneList[this.entity.site.repo_id]) {
      returned = this.zoneList[this.entity.site.repo_id].filter(zone => (this.entity.site.zones.findIndex((e) => (e.name === zone.name)) === -1));
    }
    return returned.length > 200 ? [] : returned;
  }
  getEmptyZone() {
    let returned: EntityZone = new EntityZone();
    if (this.entity && this.entity.site && this.entity.site.repo_id) {
      const i: number = this.zoneList[this.entity.site._id].findIndex((z) => (z.name === ''));
      if (i !== -1) {
        returned = new EntityZone(this.zoneList[this.entity.site._id][i], true);
        returned.repo_id = this.zoneList[this.entity.site._id][i]._id;
      }
    }
    return returned;
  }
  changeAllZone(event) {
    if (event && event.checked) {
      this.entity.point = '';
      this.entity.zone = this.getEmptyZone();
    }
    this.zoneSelection = true;
    //this.updateAllZones()
  }
  getOfficeLabel(office: EntityOffice) {
    let str = '';
    if (office) {
      str = office.name;
      if (office.zipCode || office.city) {
        str += ' (' + (office.zipCode ? office.zipCode + ' - ' : '') + office.city + ')';
      }
      return str;
    }
  }

  onChangeTimeStart($event) {
    this.entity.hourStart = $event;
  }

  onChangeTimeEnd($event) {
    this.entity.hourEnd = $event;
  }
  changeDtdict() {
    if (this.entity && !this.entity.withDt && this.entity.systematicInspection && !this.entity.systematicCases.length) {
      this.entity.systematicInspection = false;
    }
    if (this.entity && this.entity.withDt && this.icpRecurrence.dtdict && !this.entity.systematicInspection) {
      this.entity.systematicInspection = true;
    }
  }
  changeLocationSite(event) {
    //console.log('changeLocationSite', event);
    if (event && event.value) {
      const s = this.template.locations.find((e) => (e._id === event.value));
      if (s) {
        this.entity.site = new EntitySite(s);
        this.entity.site.zones = [];
        this.entity.point = '';
        this.entity.zone = new EntityZone();
      }
    }
  }
  changeLocationZone(event) {
    //console.log('changeLocationZone', event);
    if (event && event.value && this.locationZones[this.location_site_id]) {
      const s = this.locationZones[this.location_site_id].find((e) => (e._id === event.value));
      if (s) {
        this.entity.zone = new EntityZone(s);
        if (s.name !== this.entity.point) {
          this.entity.point = s.name;
        }
        if (!!s.name) {
          const tz = this.locationZones[this.location_site_id].find((e) => (!e.name));
          if (tz) {
            this.entity.zone.mergeAnalysis(tz);
          }
        }
      }
    }
  }
}
