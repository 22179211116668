import {Input} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  customFormattedDate,
  addZero,
  LoggerService,
  EntityComponent
} from 'app/shared';
import {PreventionPlanService, EntityWorkbook} from 'app/workbook-core';

export abstract class AbstractWorkbookComponent extends EntityComponent {
  entity: EntityWorkbook;

  public selectedType: string = 'occasional';
  public checked: boolean = false;

  public errorMessage: string = null;
  public formNotValid: boolean = false;

  protected workbookType: string;

  protected prefix: string = '';

  protected constructor(
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService,
  ) {
    super(_logger, _entityService);
  }

  protected _init(data: any): any {
    super._init(data);
    if (this.entity) {
      if (!this.entity.editor) {
        let editor = '';
        if (this.userProfile && this.userProfile.user_metadata && this.userProfile.user_metadata.given_name && this.userProfile.user_metadata.family_name && this.userProfile.email) {
          editor = `${this.userProfile.user_metadata.given_name} ${this.userProfile.user_metadata.family_name}`;
        } else if (this.userProfile && this.userProfile.email) {
          editor = this.userProfile.email;
        }
        this.entity.editor = editor;
        this.entity.editorEmail = this.userProfile.email;
      }
      const today = new Date();
      // Create the reference code for this document
      this.entity.name = this.prefix + customFormattedDate(today) + addZero(today.getMilliseconds(), 3);
      // add current user like creator document and user authorized to see this document
      this.entity.users.push(this._entityService.getUserId());
      // TODO: à revoir
      //    if (this.entity.editor === this.entity.exploitingCompany.responsibleName && this.entity.editorEmail === this.entity.exploitingCompany.responsibleEmail) {
      //      this.checked = true;
      //    }
      this.selectedType = this.entity.type;
    }
  }



}
