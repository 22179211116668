import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  public errorTitle: string = 'Erreur détecté';
  public errorMessage: string;
  public buttonMessage: string = 'OK';

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>) {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
