import {Component, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep, EntityIcp} from 'app/workbook-core';
import {StepSelectComponent, AbstractWorkbookStepListComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-step-list',
  templateUrl: './stepInterventionList.component.html',
  styleUrls: ['./stepList.component.scss']
})

export class WorkbookStepListComponent extends AbstractWorkbookStepListComponent {
  hideTemplates: boolean = true;
  hideSteps: boolean = false;
  displayedStepColumns: string[] = ['name', 'dateStart', 'dateEnd', 'schedule', 'site_name', 'site_zipCode', 'site_city', 'actions'];
  stepDataSource: MatTableDataSource<EntityStep> = new MatTableDataSource([]);
  @ViewChild('stepSort') stepSort: MatSort;

  displayedTemplateColumns: string[] = ['name', 'site_name', 'site_zipCode', 'site_city', 'actions'];
  templateDataSource: MatTableDataSource<EntityStep> = new MatTableDataSource([]);
  @ViewChild('templateSort') templateSort: MatSort;

  linkedIcps: {[step_id: string]: boolean} = {};
  protected _icpSubscription: Subscription;
  loadedIcp: boolean = false;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _init(data) {
    super._init(data);
    if (this.entity) {
      if (this._icpSubscription) {
        this._icpSubscription.unsubscribe();
      }
      this._icpSubscription = this._entityService.icpService.getChildren(this.entity._id).subscribe((data) => {
        if (data) {
          this.loadedIcp = true;
          data
            .forEach((icp: EntityIcp) => {
              if (icp.steps && icp.steps.length) {
                icp.steps.forEach((step_id) => {
                  this.linkedIcps[step_id] = true;
                  /*
                                   if (!this.linkedIcps[step_id]){
                                      this.linkedIcps[step_id]=[];
                                    }
                                      this.linkedIcps[step_id].push(icp);
                                      */
                });
              }
            });

        }
      });
      if (this.entity.type === 'annual') {
        this.updateTemplateDataSource();
      }
      this.updateStepDataSource();
    }
  }
  protected setStepSort() {
    if (this.stepSort) {
      this.stepDataSource.sort = this.stepSort;
    }
  }
  protected updateStepDataSource() {
    this.stepDataSource = new MatTableDataSource(this.stepList);
    //this.showTemplates = (this.stepList.length === 0);
    setTimeout(() => {
      this.setStepSort();
    });
  }
  protected setTemplateSort() {
    if (this.templateSort) {
      this.templateDataSource.sort = this.templateSort;
    }
  }
  protected updateTemplateDataSource() {
    this.templateDataSource = new MatTableDataSource(this.templateList);
    setTimeout(() => {
      this.setTemplateSort();
    });
  }
  createStep() {
    if (this.entity && this.entity.type === 'annual') {
      const dialogRef = this.dialog.open(StepSelectComponent, {
        disableClose: true,
        minWidth: '450px',
        data: {
          templates: this.templateList,
          allowNone: this.hasPermission('createSpecificStep')
          //          workbook: this.entity
        }
      });
      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined') {
          if (c === true) {
            this.addStep();
          } else {
            this.addStep(false, c, false, true);
          }
        }
      });
    } else {
      this.addStep();
    }
  }

  cloneStep(step: EntityStep) {
    this.addStep(step.template, this._getChildService().getNewEntity(step), true, (!step.template && !step.template_id));
  }
  updateElement(element: EntityStep) {
    super.updateElement(element);
  }
}
