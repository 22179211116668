import {Entity} from 'app/shared';

export class EntityMeasure extends Entity {
  public name: string;
  public description: string;
  public risk_id: string;
  public period: string;
  public type: string;
  public global: boolean;
  public prior: boolean;
  public after: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.description = (data && data.description) ? data.description : '';
    this.risk_id = (data && data.risk_id) ? data.risk_id : '';
    this.period = (data && data.period) ? data.period : '';
    this.type = (data && data.type) ? data.type : '';
    this.prior = !!data.prior;
    this.after = !!data.after;
    this.global = !!data.global;
  }
}
