export class EmailDatasModel {
  email: string = '';
  to_name: string = '';
  from_name: string = '';
  from_email: string = '';
  reply_to: string = '';
  signature: string = '';
  rgpdMail: string = '';
  dpoName: string = '';
  dpoMail: string = '';
  date: Date = new Date();
  subject: string = '';
  text: string = '';
  usertext: string = '';
  type: string = '';
}

export interface EmailRecipient {
  id?: string;
  name: string;
  email: string;
  company?: string;
  isOffice?: boolean;
  job?: string;
  right?: string[];
  pdf?: boolean;
  zip?: boolean;
  user_id?: string;
}