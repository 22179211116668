import {UUID} from 'angular2-uuid';
import {Entity} from 'app/shared';
import {EntitySite, EntityZone} from '../../repository';
import {RiskEntry} from './entityMeasure.model';
import {EntityActivity} from './entityActivity.model';
import {CompanyActivity} from '../../entityAnalysable.model';

export class Implementation {
  step: EntityStep;
  activities: {[activity_id: string]: Activity};
  companies: {[company_id: string]: CompanyActivity};
  risks: {[activity_id: string]: {[risk_name: string]: RiskEntry}};
  pdp_id: string;
  pdp_name: string;
  icp_id: string;
  icp_name: string;

  constructor(data) {
    this.step = new EntityStep(data['step']);
    this.activities = data['activities'] ? data['activities'] : {};
    this.companies = data['companies'] ? data['companies'] : {};
    this.risks = data['risks'] ? data['risks'] : {};
    this.pdp_id = data['pdp_id'] ? data['pdp_id'] : '';
    this.pdp_name = data['pdp_name'] ? data['pdp_name'] : '';
    this.icp_id = data['icp_id'] ? data['icp_id'] : '';
    this.icp_name = data['icp_name'] ? data['icp_name'] : '';

  }

  static generate(
    step: EntityStep,
    activities: EntityActivity[] = [],
    risks: {[activity_id: string]: {[risk_name: string]: RiskEntry}} = {},
    oeexs: any[] = [],
    icp_id: string = '',
    icp_name: string = '',
    pdp_id: string = '',
    pdp_name: string = '',
    //Pour ajouter les company des gens uniquement convoqués à l'ICP mais non impliqués
    extraOeexs: any[] = []): Implementation {
    const obj = {};
    obj['step'] = new EntityStep(step);
    obj['step'].implementation = null;
    obj['activities'] = {};
    obj['companies'] = {};
    obj['risks'] = {};

    obj['icp_id'] = icp_id;
    obj['icp_name'] = icp_name;
    obj['pdp_id'] = pdp_id;
    obj['pdp_name'] = pdp_name;
    if (obj['step'].activities && obj['step'].activities.length) {
      obj['step'].activities.forEach((activity_id) => {
        const activity = activities.find((e) => (e._id === activity_id));
        if (activity) {
          obj['activities'][activity_id] = new Activity(activity);
          if (activity.companies) {
            activity.companies.forEach((ac) => {
              if (!obj['companies'][ac.id]) {
                obj['companies'][ac.id] = ac;
              }
            });
          }
          if (risks[activity_id]) {
            obj['risks'][activity_id] = risks[activity_id];
          }
        }
      });
    }

    if (step.scopedMeasures) {
      Object.keys(step.scopedMeasures).forEach((activity_id) => {
        if (step.scopedMeasures[activity_id]) {
          Object.keys(step.scopedMeasures[activity_id]).forEach((measure_id) => {
            if (step.scopedMeasures[activity_id][measure_id] && step.scopedMeasures[activity_id][measure_id].length) {
              step.scopedMeasures[activity_id][measure_id].forEach((company_id) => {
                if (!obj['companies'][company_id]) {
                  const company = oeexs.find((office) => (office._id && office._id === company_id));
                  if (company) {
                    obj['companies'][company_id] = new CompanyActivity({id: company._id, name: company.name});
                  }
                }
              });
            }
          });
        }
      });
    }
    if (extraOeexs && extraOeexs.length) {
      extraOeexs.forEach((_oeex) => {
        if (!obj['companies'][_oeex._id]) {
          obj['companies'][_oeex._id] = new CompanyActivity({id: _oeex._id, name: _oeex.name});
        }
      });
    }

    return new Implementation(obj);
  }

}
export class EntityStep extends Entity {

  static PREFIXES = {
    occasional: 'OO',
    annual: 'OP'
  };

  static STATUS_DRAFT: string = 'Préparation';
  static STATUS_PROGRESS: string = 'En cours';
  static STATUS_COMPLETE: string = 'Terminée';
  static STATUS_COLOR: {[status: string]: string} = {
    'Préparation': '#f9b233',
    'En cours': '#14aea7',
    'Terminée': '#6f6f6e'
  };
  public name: string;
  public reference: string;
  public template: boolean;
  public template_id: string;
  public dateStart: Date;
  public dateEnd: Date;
  public hourStart: string;
  public hourEnd: string;
  public workConditions: string;

  public site: EntitySite;
  public point: string;
  public zone: EntityZone;

  public locations: EntitySite[];
  public strictLocations: boolean;

  public withDt: boolean;
  public dtdictNumber: string;

  public activities: string[];
  //OEEx_ids par activity_id et measure_id
  public scopedMeasures: {[activity_id: string]: {[measure_id: string]: string[]}};
  //TODO: usage ?
  //public offices: string[];
  //public externalCompanies: string[];
  //eex responsable
  public eex_id: string;

  public implementation: Implementation;

  exposure: number;
  severity: number;
  systematicInspection: boolean;
  systematicCases: string[];
  notPlanned: boolean;
  accidents: {date: Date, text: string}[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.reference = (data && data.reference) ? data.reference : '';
    this.template = (data && data.template);
    this.template_id = (data && data.template_id) ? data.template_id : '';
    this.dateStart = (data && data.dateStart) ? new Date(data.dateStart) : null;
    this.dateEnd = (data && data.dateEnd) ? new Date(data.dateEnd) : null;
    this.hourStart = (data && data.hourStart) ? data.hourStart : '';
    this.hourEnd = (data && data.hourEnd) ? data.hourEnd : '';
    this.workConditions = (data && data.workConditions) ? data.workConditions : '';
    this.site = (data && data.site) ? new EntitySite(data.site) : new EntitySite();
    this.point = (data && data.point) ? data.point : '';
    this.zone = data.zone ? new EntityZone(data.zone) : new EntityZone({name: this.point});
    if (!this.site.zones.length ||
      (data.zone && this.template && this.site.zones.findIndex((e) => (e.name === this.zone.name)) === -1)) {
      this.site.zones.push(this.zone);
    }
    this.locations = (data && data.locations) ? data.locations.map((e) => new EntitySite(e)) : [];
    this.strictLocations = !!(data && data.strictLocations);
    this.withDt = !!(data && data.withDt);
    this.dtdictNumber = (data && data.dtdictNumber) ? data.dtdictNumber : '';
    this.activities = (data && data.activities) ? data.activities.map(e => e) : [];
    this.scopedMeasures = {};
    if (data && data.scopedMeasures) {
      Object.keys(data.scopedMeasures).forEach((a_id) => {
        if (this.activities.indexOf(a_id) !== -1
          || this.getAnalysisZoneIds().indexOf(a_id) !== -1) {
          const x: {[measure_id: string]: string[]} = {};
          Object.keys(data.scopedMeasures[a_id]).forEach((m_id) => {
            x[m_id] = data.scopedMeasures[a_id][m_id];
          });
          this.scopedMeasures[a_id] = x;
        }
      });
    }
    //this.offices = (data && data.offices) ? data.offices.map(e => e) : [];
    //this.externalCompanies = (data && data.externalCompanies) ? data.externalCompanies.map(e => e) : [];
    this.eex_id = (data && data.eex_id) ? data.eex_id : '';

    this.implementation = (data && data.implementation)
      ? new Implementation(
        data.implementation

      )
      //Object.assign({}, data.implementation)
      : null;

    this.exposure = (data && data.exposure) ? +data.exposure : 0;
    this.severity = (data && data.severity) ? +data.severity : 0;
    this.systematicInspection = !!(data && data.systematicInspection);
    this.systematicCases = (data && data.systematicCases) ? data.systematicCases.map((e) => (e)) : [];

    this.notPlanned = !!(data && data.notPlanned);
    this.accidents = (data && data.accidents) ? data.accidents.map(e => e) : [];

  }
  getScore() {
    return this.systematicInspection ? 0 : this.exposure * this.severity;
  }
  getStatusColor() {
    return EntityStep.STATUS_COLOR[this.getStatus()];
  }
  getStatus() {
    const now = new Date();
    let hour: number = 0;
    let min: number = 0;
    if (this.hourStart) {
      const t = this.hourStart.split(':');
      if (t[0] && +t[0]) {
        hour = +t[0];
      }
      if (t[1] && +t[1]) {
        min = +t[1];
      }
    }
    const start = this.dateStart ? new Date(this.dateStart.getFullYear(), this.dateStart.getMonth(), this.dateStart.getDate(), hour, min) : null;

    hour = 23;
    min = 59;
    if (this.hourEnd) {
      const t = this.hourEnd.split(':');
      if (t[0] && +t[0]) {
        hour = +t[0];
      }
      if (t[1] && +t[1]) {
        min = +t[1];
      }
    }
    const end = this.dateEnd ? new Date(this.dateEnd.getFullYear(), this.dateEnd.getMonth(), this.dateEnd.getDate(), hour, min, 59) : null;
    return (!start || now < start) ? EntityStep.STATUS_DRAFT
      : (!end || now <= end) ? EntityStep.STATUS_PROGRESS
        : EntityStep.STATUS_COMPLETE

  }
  isComplete() {
    return (this.getStatus() === EntityStep.STATUS_COMPLETE);
  }
  getLastAccident() {
    if (this.accidents.length) {
      return new Date(this.accidents.sort((a, b) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()))[0].date);
    }
    return null
  }
  setMeasureScope(activity_id: string, measure_id: string, companies: string[]) {
    //if(this.activities.indexOf(activity_id) !== -1){
    if (companies && companies.length) {
      if (!this.scopedMeasures[activity_id]) {
        this.scopedMeasures[activity_id] = {};
      }
      this.scopedMeasures[activity_id][measure_id] = companies.map((e) => e);
    } else if (this.scopedMeasures[activity_id]) {
      if (this.scopedMeasures[activity_id][measure_id]) {
        delete this.scopedMeasures[activity_id][measure_id];
      }
      if (Object.keys(this.scopedMeasures[activity_id]).length === 0) {
        delete this.scopedMeasures[activity_id];
      }
    }
    //}
  }
  getActivityMeasureScope(activity_id: string, measure_id: string): string[] {
    return (activity_id && measure_id && this.scopedMeasures[activity_id] && this.scopedMeasures[activity_id][measure_id])
      ? this.scopedMeasures[activity_id][measure_id]
      : [];
  }
  isExistingScope(id) {
    return (this.activities.indexOf(id) !== -1
      //  || (this.zone && this.zone._id === id)
      || (this.locations && this.locations.findIndex((s) => (s.zones && s.zones.findIndex((z) => (z._id === id))) !== -1)))
  }
  getActivityScopes(activity_id: string = null): {[measure_id: string]: string[]} {
    const returned: {[measure_id: string]: string[]} = {};
    if (activity_id) {
      Object.keys(this.scopedMeasures)
        .filter((a_id) => (this.isExistingScope(a_id)))
        .forEach((a_id) => {
          Object.keys(this.scopedMeasures[a_id]).forEach((m_id) => {
            if (!returned[m_id]) {
              returned[m_id] = [];
            }
            this.scopedMeasures[a_id][m_id].forEach((o_id) => {
              if (returned[m_id].indexOf(o_id) === -1) {
                returned[m_id].push(o_id);
              }
            });
          });
        });
    } else if (this.isExistingScope(activity_id) && this.scopedMeasures[activity_id]) {
      Object.keys(this.scopedMeasures[activity_id]).forEach((m_id) => {
        if (!returned[m_id]) {
          returned[m_id] = [];
        }
        this.scopedMeasures[activity_id][m_id].forEach((o_id) => {
          if (returned[m_id].indexOf(o_id) === -1) {
            returned[m_id].push(o_id);
          }
        });
      });
    }
    return returned;
  }
  getAnalysisZoneIds(): string[] {
    let returned: string[] = [];
    this.getLocationsAnalysis().forEach((l) => {
      returned = [...returned, ...l.zones.map((e) => (e._id))];
    })
    return returned;
  }
  getLocationsAnalysis(): EntitySite[] {
    const locations = this.getLocations();
    locations.forEach((s) => {
      s.zones = s.zones.filter((z) => (z.measures.length));
    });
    return locations.filter((s) => (s.zones.length));
  }
  getLocations(): EntitySite[] {
    let returned: EntitySite[] = [];
    if (this.template) {
      returned = this.locations.map((e) => (e));
    }
    if (this.site && this.site.name /*&& this.zone*/) {
      const i = returned.findIndex((e) => (e._id === this.site._id));
      if (i === -1) {
        const s = new EntitySite(this.site);
        //s.zones.push(new EntityZone(this.zone));
        returned = [...[s], ...returned];
      } else if (returned[i].zones && this.zone) {
        const j = returned[i].zones.findIndex((e) => (e._id === this.zone._id));
        if (j === -1) {
          returned[i].zones = [...[new EntityZone(this.zone)], ...returned[i].zones];
        } else {
          //quelle zone garder ?
        }
      }
    }
    return returned;
  }
  addZone(zone: EntityZone) {
    if (this.site && this.site.zones) {
      const i = this.site.zones.findIndex((e) => (e._id === zone._id || e.name === zone.name));
      if (i === -1) {
        this.site.zones.push(zone);
      } else {
        this.site.zones[i] = zone;
      }
    }
  }
  removeZone(zone: EntityZone) {
    if (this.site && this.site.zones) {
      const i = this.site.zones.findIndex((e) => (e.name === zone.name));
      if (i !== -1) {
        this.site.zones.splice(i, 1);
      }
      if (!this.site.zones.length) {
        this.point = '';
        this.zone = new EntityZone();
        this.site.zones.push(new EntityZone(this.zone));
      } else if (this.zone && this.zone.name === zone.name) {
        this.zone = new EntityZone(this.site.zones[0]);
      }
    }
  }
  updateZone(zone: EntityZone) {
    if (this.template) {
      this.locations.forEach((s) => {
        if (s.zones && s.zones.length) {
          const i = s.zones.findIndex((z) => (z._id === zone._id));
          if (i !== -1) {
            s.zones[i] = zone;
          }
        }
      });
    }
    if (this.site && this.site.zones && this.site.zones.length) {
      const i = this.site.zones.findIndex((z) => (z._id === zone._id));
      if (i !== -1) {
        this.site.zones[i] = zone;
      }
    }
    if (this.zone && this.zone._id === zone._id) {
      this.zone = zone;
    }
  }
  removeLocation(site: EntitySite) {
    if (this.template && this.locations) {
      const i = this.locations.findIndex((e) => (e._id === site._id));
      if (i !== -1) {
        this.locations.splice(i, 1);
      }
    }
    if (this.site && site._id === this.site._id) {
      this.site = new EntitySite();
      this.point = '';
      this.zone = new EntityZone();
      this.site.zones.push(this.zone);

    }
  }
  updateLocation(site: EntitySite) {
    if (!site._id) {
      site._id = UUID.UUID();
    }
    if (this.template) {

      if (this.site && this.site._id === site._id) {
        this.site = new EntitySite(site);
        if (site.zones && site.zones.length && this.zone && this.zone._id) {
          const j = site.zones.findIndex((e) => (e._id === this.zone._id));
          if (j !== -1) {
            this.zone = new EntityZone(site.zones[j]);
            this.point = this.zone.name;
          }
          this.site.zones = site.zones
            //.filter((e) => (e._id !== this.zone._id))
            .map((e) => (new EntityZone(e)));

        }
      } else {
        const i = this.locations.findIndex((e) => (e._id === site._id));
        if (i === -1) {
          //console.log('updateLocation (add)', site);
          this.locations.push(site);
        } else {
          //console.log('updateLocation', site);
          this.locations[i] = site;
        }
      }
    }
  }
}

export class Activity {
  public _id: string;
  public name: string;
  public group: string;
  public risks_situations: string[];
  public companies: string[];
  public comments: string;
  public confinedSpace: boolean;
  public smr: boolean;
  public firePermit: boolean;
  constructor(activity: EntityActivity) {
    this._id = activity._id;
    this.name = activity.name;
    this.group = activity.group;
    this.risks_situations = activity.risks_situations ? activity.risks_situations.map((e) => (e)) : []
    this.companies = activity.companies ? activity.companies.map((e) => (e.id)) : [];
    this.comments = activity.comments;
    this.confinedSpace = !!activity.confinedSpace;
    this.smr = !!activity.smr;
    this.firePermit = !!activity.firePermit;
  }
}


