import { Component, Input, OnChanges } from '@angular/core';

import {
  LoggerService, formattedDate,
  CommonComponent
} from 'app/shared';
import {
  EntityStep
} from '../models/entityStep.model';
import {
  EntitySite
} from '../../repository/models/entitySite.model';

@Component({
  selector: 'app-step-export',
  templateUrl: './stepExport.component.html'
})
export class StepExportComponent extends CommonComponent implements OnChanges {

  @Input() step: EntityStep;
  @Input() locationFilter: string[] = null;
  locations: EntitySite[];

  constructor(protected _logger: LoggerService) {
    super(_logger);
  }
  ngOnChanges(changes: any) {
    if (changes && (changes['step'] || changes['locationFilter']) && this.step) {
      this.locations = [];
      this.step.getLocations().forEach((s) => {
        if (this.locationFilter) {
          if (s.zones) {
            const _zones = s.zones.filter((z) => (this.locationFilter.indexOf(z._id) !== -1));
            if (_zones.length) {
              const _site = new EntitySite(s);
              _site.zones = _zones;
              this.locations.push(_site);
            }
          }
        } else {
          this.locations.push(new EntitySite(s));
        }
      });
    }
  }

  public formattedDate(d: Date) {
    return d ? formattedDate(d) : '';
  }
}
