import {ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';


import {
  LoggerService, DocumentFileService, getChecksum
} from 'app/shared';
import {
  PreventionPlanService, EntityWorkbook,
  IcpRecurrenceService,
  OfficeService, ExternalCompanyService, OrganizationService, SiteService,
  EntityOffice
} from 'app/workbook-core';
import {StatsService} from './stats.service';
import {WorkbooksCommonComponent} from './workbooksCommon.component';



export abstract class WorkbooksStatsCommonComponent extends WorkbooksCommonComponent {

  abstract activeNav: number;

  wbFilter: {
    start: Date,
    end: Date
  };
  useCreatedAt: boolean = true;
  minDate: Date;
  maxDate: Date;


  showGrid: boolean = true;

  chartTypes: any[] = [];
  availableRows: string[] = [
    'step_name',
    'step_start',
    'step_end',
    'wb_code',
    'wb_type',
    'wb_name',
    'wb_ref',
    'wb_editor',
    'wb_created',
    'wb_start',
    'wb_end',
    'wb_site',
    'wb_eex'
  ];
  availableCols: string[] = [
    'step_name',
    'step_start',
    'step_end',
    'wb_code',
    'wb_type',
    'wb_name',
    'wb_ref',
    'wb_editor',
    'wb_created',
    'wb_start',
    'wb_end',
    'wb_site',
    'wb_eex'
  ];
  availableDatas: string[] = [
    'step_quantity',
    'step_icp_quantity',
    'step_pdp_quantity',
    'wb_quantity',
    'wb_pdp_quantity',
    'wb_step_quantity'
  ];
  availableChartTypes = StatsService.ALL_CHARTS;//['bar', 'stackedbar', 'line', 'spline', 'fullstackedbar'];
  chartType = 'bar';
  fieldsRows: string[] = [];//['service', 'wb_type'];
  fieldsCols: string[] = [];//['wb_start'];
  fieldsDatas: string[] = [];// ['wb_pdp_quantity'];


  confRow: string;// = 'wb_editor';
  confCol: string;// = 'wb_start';
  confData: string;// = 'pdp_quantity';



  source: any[] = [];
  fields: any[] = [];

  persistKey: string = "workbooksStats";
  allowStateStorage: boolean = true;
  rotated: boolean = false;
  showArgumentGrid: boolean = false;
  showValueGrid: boolean = false;
  showLegend: boolean = false;

  //disponible (par defaut)
  fieldsList: string[] = [];
  fieldsFilter: string[] = [];
  selectRows: string[] = [];
  selectCols: string[] = [];
  selectDatas: string[] = [];
  //fieldsOther: string[]=[];
  selectableRows: any[] = [];
  selectableCols: any[] = [];
  selectableDatas: any[] = [];

  //parametres d'affichage
  allowSortingBySummary: boolean = true;
  allowSorting: boolean = true;
  allowFiltering: boolean = true;
  showBorders: boolean = true;
  showColumnGrandTotals: boolean = false;
  showRowGrandTotals: boolean = false;
  showRowTotals: boolean = false;
  showColumnTotals: boolean = false;
  panelShowRowFields: boolean = false;
  panelShowDataFields: boolean = false;
  panelShowColumnFields: boolean = false;
  panelShowFilterFields: boolean = false;
  panelAllowFieldDragging: boolean = true;
  //button dans pivotgrid
  panelVisible: boolean = true;
  showFieldChooser: boolean = true;
  showExport: boolean = true;

  organizationList: string[] = [];

  _officeListSubscriber: Subscription;
  officeList: {[id: string]: EntityOffice} = {};
  userOffices: {[user_id: string]: string[]} = {};
  parentFields: {[id: string]: {[orgaType: string]: string}} = {};

  listMd5: string = '';
  docDbInfos: {
    count: number,
    count_del: number,
    size: string
  };
  showDocStats: boolean = false;
  constructor(
    protected _documentFileService: DocumentFileService,
    protected _organizationService: OrganizationService,
    public statsService: StatsService,
    //preload
    protected _spinnerService: Ng4LoadingSpinnerService,
    //private _auth: AuthService,
    protected _cd: ChangeDetectorRef,
    protected _externalCompanyService: ExternalCompanyService,
    protected _officeService: OfficeService,
    protected _siteService: SiteService,
    protected _icpRecurrenceService: IcpRecurrenceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService//WorkbookService
  ) {
    super(_cd, _externalCompanyService, _officeService, _siteService, _icpRecurrenceService, dialog, snackBar, _router, _logger, _entityService);
    var now = new Date();
    this.wbFilter = {
      start: new Date(),
      end: new Date()
    };
    this.wbFilter.start.setDate(now.getDate() - 365);
    //this.wbFilter.end.setDate(now.getDate());

    this.minDate = new Date(this.wbFilter.start);
    this.maxDate = new Date(this.wbFilter.end);

    this.fields = this.statsService.getFields(this.fieldsRows, this.fieldsCols, this.fieldsDatas, this.getFieldsOther());
    this._officeListSubscriber = this._officeService.getAll().subscribe((list) => {
      if (list) {
        list.forEach((e) => {
          this.officeList[e._id] = e;
        });
        this.updateOffices();
      }
    });
  }
  _init(data) {
    if (!this.docDbInfos) {
      this.docDbInfos = this._documentFileService.getDbInfos();
    }
    if (data && data.length) {
      const _listMd5 = getChecksum(data.map((e) => (e._id + e._rev)).join());
      if (this.listMd5 !== _listMd5) {
        super._init(data);
        this.refreshData();
      }
    }

  }
  refreshData() {
    if (this.entityList && this.entityList.length) {
      this.entityList.forEach((wb => {
        if (wb.dateStart && this.minDate && wb.dateStart.getTime() < this.minDate.getTime()) {
          this.minDate = new Date(wb.dateStart);
        }
        if (wb.dateEnd && this.maxDate && wb.dateEnd.getTime() > this.maxDate.getTime()) {
          this.maxDate = new Date(wb.dateEnd);
        }
      }))
      this.applyFilter();
      this.initFields();
    }
  }

  protected _getDataSourceFilterPredicate() {
    return (data: EntityWorkbook, filter: string) => {
      return this.useCreatedAt
        ? ((!data.createdAt || !this.wbFilter.start || data.createdAt.getTime() > this.wbFilter.start.getTime())
          &&
          (!data.createdAt || !this.wbFilter.end || data.createdAt.getTime() < (this.wbFilter.end.getTime() + (24 * 60 * 60 * 1000))))
        : ((!data.dateStart || !this.wbFilter.start || data.dateStart.getTime() > this.wbFilter.start.getTime())
          &&
          (!data.dateEnd || !this.wbFilter.end || data.dateEnd.getTime() < (this.wbFilter.end.getTime() + (24 * 60 * 60 * 1000))));
    }
  }
  updateOffices() {
    const directChildren: {[id: string]: string[]} = {};
    //const officesByType: {[orgaType: string]: string[]} = {};
    this.parentFields = {};
    this.organizationList = ['EU-Service'];
    Object.keys(this.officeList).forEach((oid) => {
      if (this.officeList[oid].contacts && this.officeList[oid].contacts.length) {
        this.officeList[oid].contacts.forEach((c) => {
          if (c.user_id) {
            if (!this.userOffices[c.user_id]) {
              this.userOffices[c.user_id] = [];
            }
            this.userOffices[c.user_id].push(oid);
          }
        });
      }
      if (this.officeList[oid].main) {
        if (!directChildren[this.officeList[oid].main]) {
          directChildren[this.officeList[oid].main] = [];
        }
        directChildren[this.officeList[oid].main].push(oid);
      }
      const job = 'EU-' + (this.officeList[oid].job ? this.officeList[oid].job : 'Aucun');
      if (this.organizationList.indexOf(job) === -1) {
        this.organizationList.push(job);
      }
      this.parentFields[oid] = {};
      this.parentFields[oid][job] = this.officeList[oid].name;
    });
    Object.keys(this.officeList).forEach((oid: string) => {
      if (this.officeList[oid].job) {
        const job = this.officeList[oid].job ? this.officeList[oid].job : 'Aucun';
        this._getChildren(directChildren, oid).forEach((child_oid) => {
          this.parentFields[child_oid][job] = this.officeList[oid].name;
        });
      }
    });
    this.initFields();
  }
  initFields() {

    this.fieldsList = this.availableRows.concat(this.availableDatas);
    this.chartTypes = this.statsService.getChartTypes(this.availableChartTypes);

    if (this.confRow) {
      this.selectRows = this.statsService.selectRows;
      //  this.fieldsRows = this.statsService.confRows[this.confRow] ? this.statsService.confRows[this.confRow] : [];
    } else {
      this.selectableRows = this.availableRows.length ? this.statsService.getSelectableFields(this.availableRows.filter((e) => ((this.fieldsDatas.indexOf(e) === -1) && (this.fieldsCols.indexOf(e) === -1)))) : [];
    }
    if (this.confCol) {
      this.selectCols = this.statsService.selectCols;
      //  this.fieldsCols = this.statsService.confCols[this.confCol] ? this.statsService.confCols[this.confCol] : [];
    } else {
      this.selectableCols = this.availableCols.length ? this.statsService.getSelectableFields(this.availableCols.filter((e) => ((this.fieldsDatas.indexOf(e) === -1) && (this.fieldsRows.indexOf(e) === -1)))) : [];
    }
    if (this.confData) {
      this.selectDatas = this.statsService.selectDatas;
      // this.fieldsDatas = this.statsService.confDatas[this.confData] ? this.statsService.confDatas[this.confData] : [];
    } else {
      this.selectableDatas = this.availableDatas.length ? this.statsService.getSelectableFields(this.availableDatas.filter((e) => ((this.fieldsCols.indexOf(e) === -1) && (this.fieldsRows.indexOf(e) === -1)))) : [];
    }

    //console.log('ReportingComponent:initFields:before', this.fieldsDatas, this.fields);
    this.fields = this.statsService.getFields(this.fieldsRows, this.fieldsCols, this.fieldsDatas, this.getFieldsOther());

    //console.log('ReportingComponent:initFields', this.fieldsDatas, this.fields);
  }
  getFieldsOther() {
    return [
      ...this.statsService.setExtraFields(this.organizationList),
      ...this.fieldsList
    ].filter(array_diff(this.fieldsRows.concat(this.fieldsCols).concat(this.fieldsDatas).concat(this.fieldsFilter)));
  }
  getField(element: string, area: string = '') {
    var field: any = null;
    if (this.fields[element]) {
      field = Object.assign({}, this.fields[element]);
      //if (area!==''){
      field['area'] = area;
      //}
    }
    //console.log('ReportingService:getField(' + element + ',' + area + ')', field);
    return field;
  }

  getFields(rows: string[], cols: string[], datas: string[], others: string[]) {
    var returned: any[] = [];
    var i: number = 0;
    var field: any;
    while (i < rows.length) {
      field = this.getField(rows[i], 'row');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < cols.length) {
      field = this.getField(cols[i], 'column');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < datas.length) {
      field = this.getField(datas[i], 'data');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < others.length) {
      field = this.getField(others[i]);
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    return returned;
  }
  applyFilter() {
    super.applyFilter();
    this.source = this.getSource(this.dataSource.filteredData);
  }
  abstract getSource(list: EntityWorkbook[]);



  getWbStepQuantity(wb: EntityWorkbook) {
    let all: string[] = [];
    Object.keys(wb.inspections).forEach((k) => {
      if (all.indexOf(k) === -1) {
        all.push(k);
      }
    });
    Object.keys(wb.pdps).forEach((k) => {
      if (all.indexOf(k) === -1) {
        all.push(k);
      }
    });
    return all.length;
  }
  getWbIcpQuantity(wb: EntityWorkbook) {
    let all: string[] = [];
    Object.keys(wb.inspections).forEach((k) => {
      Object.keys(wb.inspections[k].scheduled).forEach((x) => {
        if (all.indexOf(x) === -1 && wb.inspections[k].scheduled[x].status > 0) {
          all.push(x);
        }
      });
    });
    return all.length;
  }
  getWbPdPQuantity(wb: EntityWorkbook) {
    let all: string[] = [];
    Object.keys(wb.pdps).forEach((k) => {
      Object.keys(wb.pdps[k].editions).forEach((x) => {
        if (all.indexOf(x) === -1 && wb.pdps[k].editions[x].status > 0) {
          all.push(x);
        }
      });
    });
    return all.length;
  }

}

function array_diff(exclude_array) {
  return function (element) {
    return exclude_array.indexOf(element) < 0;
  }
}
function array_filter(include_array) {
  return function (element) {
    return include_array.indexOf(element) >= 0;
  }
}