import * as emailjs from 'emailjs-com';
import {Injectable} from '@angular/core';
import {Subscription} from 'rxjs';
import {DbConfigService} from 'app/shared/db/dbConfig.service';

import {EntityConfigEmail, EntityEmailMessages, MessageModel} from 'app/shared/models';
import {EmailDatasModel} from './email-datas.model';
@Injectable()
export class EmailService {
  protected _configEmailSubscription: Subscription;
  protected _configEmail: EntityConfigEmail;
  protected _exploitingCompanySubscription: Subscription;
  protected _companyName: string = '';
  protected _companyLogo: string = '';
  public fromName: string;
  public fromEmail: string;
  public defaultSenderUser: boolean;
  public defaultReplyUser: boolean;

  protected _configEmailMessagesSubscription: Subscription;
  protected _configEmailMessages: EntityEmailMessages;

  constructor(
    // protected _logger: LoggerService,
    //protected _dbService: DbService,
    protected _dbConfigService: DbConfigService
  ) {
    this._configEmailSubscription = this._dbConfigService.getSingle('config_email').subscribe((data) => {
      if (data) {
        this._configEmail = data;
      }
    });
    this._configEmailMessagesSubscription = this._dbConfigService.getSingle('emails').subscribe((data) => {
      if (data) {
        this._configEmailMessages = data;
        this.fromName = data && data.fromName ? data.fromName : '';
        this.fromEmail = data && data.fromEmail ? data.fromEmail : '';
        this.defaultSenderUser = !!data.defaultSenderUser;
        this.defaultReplyUser = !!data.defaultReplyUser;
      }
    });
    // TODO: dépendance vers repo => mal placé, à mettre dans config ?
    this._exploitingCompanySubscription = this._dbConfigService.getSingle('company').subscribe((data) => {
      this._companyName = data && data.name ? data.name : '';
      this._companyLogo = data && data.logo ? data.logo : '';
    });
  }
  updateConfigEmail(data) {
    this._configEmail = data;
  }
  getCompanyName() {
    return this._companyName;
  }
  getCompanyLogo() {
    return this._companyLogo;
  }
  updateCompany(data) {
    this._companyName = data && data.name ? data.name : '';
    this._companyLogo = data && data.logo ? data.logo : '';
  }
  setCompanyName(name: string) {
    this._companyName = name;
  }
  setCompanyLogo(logo: string) {
    this._companyLogo = logo;
  }
  /**
   * Get the specific email data from Config in PouchDB
   * @param {number} id
   * @returns {Promise<MessageModel>}
   */
  public getEmailDatas(id: number): Promise<MessageModel> {
    // TODO: dépendance vers repo => mal placé, à mettre dans config ?
    return this._dbConfigService.getSingle('emails').toPromise()
      .then((data: EntityEmailMessages) => {
        const objectA = data.messages.find(emailMessages => emailMessages.id === id);
        const objectB = {fromName: data.fromName, fromEmail: data.fromEmail, defaultSenderUser: data.defaultSenderUser, defaultReplyUser: data.defaultReplyUser, signature: data.signature, rgpdMail: data.rgpdMail, dpoName: data.dpoName, dpoMail: data.dpoMail};
        return {...objectA, ...objectB};
      });
  }
  public getEmailData(data: EntityEmailMessages, id: number): MessageModel {
    const objectA = data.messages.find(emailMessages => emailMessages.id === id);
    const objectB = {fromName: data.fromName, fromEmail: data.fromEmail, defaultSenderUser: data.defaultSenderUser, defaultReplyUser: data.defaultReplyUser, signature: data.signature, rgpdMail: data.rgpdMail, dpoName: data.dpoName, dpoMail: data.dpoMail};
    return {...objectA, ...objectB};
  }

  /**
   * Send email with email js server
   * @param templateParams
   * @param templateId
   */
  sendEmail(templateParams: EmailDatasModel, templateId?: string, attachment = null, attachment_name = ''): Promise<any> {
    // Fix emailJS back to line
    templateParams.text = templateParams.text.split('\n').join('<br>')
      + '<br><br>' + templateParams.usertext.split('\n').join('<br>');
    if (templateParams.signature) {
      templateParams.signature = templateParams
        .signature.split('\n').join('<br>')
        .split('@EUNAME').join(this._companyName)
        .split('@EULOGO').join(this._companyLogo)
        .split('@RGPD').join(templateParams.rgpdMail)
        .split('@DPONAME').join(templateParams.dpoName)
        .split('@DPOMAIL').join(templateParams.dpoMail)
        .split('@HOME').join(window.location.origin);
    } else {
      templateParams.signature = 'Cordialement,<br>' + (this._companyName ? this._companyName : '');
    }
    // default footer trepied.co
    //templateParams.signature = templateParams.signature + '<br><br><p style="margin:15px 0 10px;padding:12px 15px;font-size:10px;color:#909AA0;">Cet email a été envoyé par l\'application <a href="https://trepied.co" target="_new">trepied.co</a>.</p>';
    if (attachment) {
      templateParams['attachment'] = attachment;
      if (attachment_name) {
        templateParams['attachment_name'] = attachment_name;
      }
    }
    if (!templateParams['from_email']) {
      templateParams['from_email'] = this._configEmailMessages.fromEmail;
    }
    if (!templateParams['from_name']) {
      templateParams['from_name'] = this._configEmailMessages.fromName;
    }
    if (!templateParams['reply_to'] && templateParams['from_email']) {
      templateParams['reply_to'] = templateParams['from_email'];
    }
    return emailjs.send(
      this._configEmail.serviceId,
      templateId ? templateId : this._configEmail.templateId,
      templateParams,
      this._configEmail.userId
    );
  }

}
export class EmailMessages {
  static ICP_CONVOCATION: number = 1;
  static ICP_ANNULATION: number = 2;
  static SIGN_UP: number = 3;
  static USER_CREATE: number = 4;
  static USER_CHECK: number = 5;
  static USER_DELETE: number = 6;
  static SIGN_ICP_REQUEST: number = 7;
  static SIGN_ICP: number = 8;
  static SIGN_ICP_CONTACT: number = 12;
  static SIGN_PDP_REQUEST: number = 9;
  static SIGN_PDP: number = 10;
  static SIGN_PDP_CONTACT: number = 13;
  static DOC_REQUEST: number = 11;
  static DOCUMENTS_REQUEST: number = 20;
  static DOCUMENTS_REPO_REQUEST: number = 25;
  static DOCUMENT_REJECT: number = 21;
  static SIGN_SFP_REQUEST: number = 14;
  static SIGN_SFP: number = 16;
  static SIGN_SFP_CONTACT: number = 15;
  static SIGN_SPS_REQUEST: number = 17;
  static SIGN_SPS: number = 19;
  static SIGN_SPS_CONTACT: number = 18;
  static SIGN_SVC_REQUEST: number = 22;
  static SIGN_SVC: number = 23;
  static SIGN_SVC_CONTACT: number = 24;
  static SIGN_SPT_REQUEST: number = 26;
  static SIGN_SPT: number = 27;
  static SIGN_SPT_CONTACT: number = 28;
  static SIGN_SPT_OPERATOR: number = 29;
}
