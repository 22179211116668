import { Injectable } from '@angular/core';
import { PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntitySite } from '../../repository';
import { EntityStep } from '../models';
@Injectable({
  providedIn: 'root'
})
export class WorkbookStepService extends EntitySecureService {
  onlyChildren = true;
  static LABEL_ONE = "chargement de la phase";
  static LABEL_ALL = "chargement des phases";
  static LABEL_CHILDREN = "chargement des phases de l'opération";
  static LABEL_SAVE = "sauvegarde de la phase";
  static LABEL_DELETE = "suppression de la phase";

  static ENTITY_TYPE: string = 'step';
  static ENTITY_FIELDS: string[] = ['user_id', 'name', 'dateStart', 'dateEnd', 'hourStart', 'hourEnd', 'site', 'point', 'zone', 'locations', 'strictLocations', 'withDt', 'dtdictNumber', 'exposure', 'severity', 'notPlanned', 'systematicInspection', 'systematicCases', 'accidents', 'template', 'template_id', 'implementation', 'activities', 'scopedMeasures'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['editPP'],
    UPDATE: ['editPP'],
    DELETE: ['editPP']
  }

  static ENTITY_URL = 'step';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityStep {
    return new EntityStep(data, clone);
  }
  setActivities(id: string, activities: string[], confinedSpace: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getCurrentOne(id).then((step: EntityStep) => {
        step.activities = activities;
        // if (confinedSpace) {}
        resolve(this.save(step));
      }).catch((err) => {
        reject(err);
      });
    });
  }
}
