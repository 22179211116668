import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Operator, ConfigEmailService, EntityConfigEmail, EntityDocumentMetadata} from 'app/shared';
import {ExternalCompanyService} from 'app/workbook-core';

@Component({
  selector: 'app-operator-dialog',
  templateUrl: './operator-dialog.component.html',
  styleUrls: ['./operator-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookOperatorDialogComponent implements OnDestroy {

  public operator: Operator;
  protected _configEmailSubscription: Subscription;
  public regexEmail: string;
  public regexEmailInvalid: string;
  public msgEmail: string;
  public internal: boolean;

  //operatorBirthday: string = '';

  protected _repoEexSubscription: Subscription;
  repoEexOperators: Operator[] = [];
  eexOperatorIds: string[] = [];

  fieldsErrors: string[] = [];

  constructor(
    protected _configEmailService: ConfigEmailService,
    public dialogRef: MatDialogRef<WorkbookOperatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _externalCompanyService: ExternalCompanyService
  ) {
    this.internal = !!data['internal'];
    this._configEmailSubscription = this._configEmailService.getSingleOne().subscribe((ce: EntityConfigEmail) => {
      if (ce) {
        this.regexEmail = this.internal ? ce.regexInternal : ce.regexExternal;
        this.msgEmail = this.internal ? ce.msgInternal : ce.msgExternal;
        this.regexEmailInvalid = EntityConfigEmail.EMAIL_REGEX;
      }
    });
    this.operator = data['operator'] ? data['operator'] : new Operator();
    //this.operatorBirthday = this.operator.birthday ? formattedDate(this.operator.birthday) : '';
    // this.operatorList = data['operatorList'] ? data['operatorList'] : [];
    if (data['eex'] && data['eex']['repo_id']) {
      if (data['eex']['operators'] && data['eex']['operators'].length) {
        this.eexOperatorIds = data['eex']['operators'].map((e: Operator) => (e.id));
      }
      if (data['eex']['repo_id']) {
        this._repoEexSubscription = this._externalCompanyService.getOne(data['eex']['repo_id']).subscribe((eex) => {
          if (eex && eex.operators && eex.operators.length) {
            this.repoEexOperators = eex.operators.filter((e) => (this.eexOperatorIds.indexOf(e.id) === -1));
          }
        });
      }
    }
  }
  ngOnDestroy() {
    if (this._configEmailSubscription) {
      this._configEmailSubscription.unsubscribe();
    }
    if (this._repoEexSubscription) {
      this._repoEexSubscription.unsubscribe();
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.operator);
    }
  }


  getEmailError() {
    if (this.operator && this.operator.email) {
      let reg = this.regexEmailInvalid;
      const regex = RegExp(reg);
      let res = regex.exec(this.operator.email);
      if (res && res.length) {
        return this.msgEmail;
      } else {
        return EntityConfigEmail.EMAIL_INVALID;
      }
    }
  }
  getFilteredOperators() {
    const x = this.repoEexOperators.filter((e) => (
      (!this.operator.name || e.name.indexOf(this.operator.name) !== -1)
      && (!this.operator.job || e.job.indexOf(this.operator.job) !== -1)

    ));
    return x;
  }
  updateOperator(operator: Operator) {
    //    this.operator.id = operator.id;
    this.operator.user_id = operator.user_id;
    this.operator.name = operator.name;
    this.operator.email = operator.email;
    this.operator.phone = operator.phone;
    this.operator.job = operator.job;
    this.operator.repo_id = operator.id;
    //this.operator.habilitation = operator.habilitation;
    //this.operator.birthday = operator.birthday ? new Date(operator.birthday) : null;
    //this.changeBirthday();
    //this.operator.birthplace = operator.birthplace;
    this.operator.workforce = operator.workforce;
    this.operator.extra = Object.assign({}, operator.extra);
    this.operator.documents = operator.documents ? operator.documents.map((e) => (new EntityDocumentMetadata(e))) : [];
  }
}
