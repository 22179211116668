import {ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatTableDataSource, MatDialog, MatSnackBar} from '@angular/material';


import {
  SignableStatus,
  EntityListComponent,
  LoggerService,
  Operator, Contact,
  formattedDate, getDifferenceInDays, getChecksum
} from 'app/shared';
import {
  PreventionPlanService, EntityWorkbook,
  IcpRecurrenceService, EntityIcpRecurrence, ICPState, PDPState,
  OfficeService, ExternalCompanyService, SiteService
} from 'app/workbook-core';


export class Todo {
  date: Date;
  shown: number[];
  wb: EntityWorkbook;
  step_id: string;
  step_template: boolean;
  step_name: string;
  step_start: Date | null;
  step_end: Date | null;
  icp_id?: string;
  pdp_id?: string;
  audit?: boolean;
  //  icp_state?: StepIcpState;
  //  pdp_state?: StepPdpState;
  text: string;
  text_suite?: string;
  state?: {name: string, color: string, delay: number};
}
export class Event {
  date: Date;
  shown: number[];
  wb: EntityWorkbook;
  step_id?: string;
  step_template?: boolean;
  step_name?: string;
  step_start?: Date | null;
  step_end?: Date | null;
  event_id: string;
  text: string;
  text_suite?: string;
  state?: {name: string, color: string, delay: number};
}
export class StepIcpState {
  step_id: string;
  step_template: boolean;
  step_name: string;
  step_start: Date | null;
  step_end: Date | null;
  //id, date et status derniere ICP
  lastIcp: {id: string, date: Date, status: number};
  //id, date et status derniere ICP
  lastIcpValid: {id: string, date: Date, status: number};
  //date et status prochaine ICP
  nextIcp: {id: string, date: Date, status: number};
  //date et nom prochaine intervention
  nextIntervention: {id: string, date_start: Date, date_end: Date, name: string};
  //pour chantier type, date dernier ICP + periode recurrence
  plannedIcp: Date;
  //Etat principal step
  state: {name: string, color: string, delay: number}
  beforeIntervention: boolean;
}
export class StepPdpState {
  step_id: string;
  step_template: boolean;
  step_name: string;
  step_start: Date | null;
  step_end: Date | null;
  //id, date et status derniere ICP
  lastPdp: {id: string, date: Date, status: number};
  //date et status prochaine ICP
  nextPdp: {id: string, date: Date, status: number};
  //date et nom prochaine intervention
  nextIntervention: {id: string, date_start: Date, date_end: Date, name: string};
  //Etat principal step
  state: {name: string, color: string, delay: number}
}


export abstract class WorkbooksCommonComponent extends EntityListComponent {

  entityList: EntityWorkbook[] = [];
  dataSource: MatTableDataSource<EntityWorkbook> = new MatTableDataSource([]);

  neutralColor: string = '#C4C4C4';

  userId: string;

  _userContactsOfficesSubscription: Subscription;
  userContactsOffices: string[] = [];
  //signature du tableau entityList pour ne pas recalculer pour rien
  wbListMd5: string;
  protected _icpRecurrenceSubscription: Subscription;
  icpRecurrence: EntityIcpRecurrence;
  icpLegend: {name: string, color: string, delay: number}[];
  pdpLegend: {name: string, color: string, delay: number}[];
  icpStates: {[workbook_id: string]: {name: string, color: string, delay: number}} = {};
  icpList: {[workbook_id: string]: StepIcpState[]} = {};
  pdpStates: {[workbook_id: string]: {name: string, color: string, delay: number}} = {};
  pdpList: {[workbook_id: string]: StepPdpState[]} = {};

  todoList: Todo[] = [];
  inprogressList: Todo[] = [];
  upcomingList: Event[] = [];

  postInitialized: boolean = false;

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _externalCompanyService: ExternalCompanyService,
    protected _officeService: OfficeService,
    protected _siteService: SiteService,
    protected _icpRecurrenceService: IcpRecurrenceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _router, _logger, _entityService);


    this.userId = this._entityService.getUserId();
    //preload
    this._entityService.getCurrentExploitingCompany();
    this._icpRecurrenceSubscription = this._icpRecurrenceService.getSingleOne().subscribe((ir: EntityIcpRecurrence) => {
      if (ir) {
        this.icpRecurrence = ir;

        this.icpLegend = this.icpRecurrence.getIcpLegend();
        this.pdpLegend = this.icpRecurrence.getPdpLegend();
        setTimeout(() => {
          this.updateIcpRecurrence();
        });
      }
    });
    this._userContactsOfficesSubscription = this._officeService.getUserContactsOffices().subscribe((list) => {
      if (list) {
        this.userContactsOffices = list.map((e) => (e._id));
        setTimeout(() => {
          this.applyFilter();
        });
      }
    });
    if (this.hasRole('PP_ADMIN_TREPIED')) {
      setTimeout(() => {
        this._entityService.refreshUserList();
      });
    }
  }
  protected _getChildren(directChildren: {[id: string]: string[]}, id: string): string[] {
    let returned: string[] = [];
    let children = directChildren[id];
    if (children) {
      children.forEach((x) => {
        returned.push(x);
        returned = [...returned, ...this._getChildren(directChildren, x)];
      });
    }
    return returned
  }
  postInit() {
    if (!this.postInitialized) {
      this._siteService.getAll();
      this._externalCompanyService.getAll();
      this.postInitialized = true;
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._icpRecurrenceSubscription) {
      this._icpRecurrenceSubscription.unsubscribe();
    }
    if (this._userContactsOfficesSubscription) {
      this._userContactsOfficesSubscription.unsubscribe();
    }
  }
  protected updateIcpRecurrence() {
    if (this.icpRecurrence && this.entityList) {
      const now = new Date();
      const list = this.entityList
        .filter((wb: EntityWorkbook) => (!(wb.type == 'annual' && wb.simple) && !(wb.status === EntityWorkbook.STATUS_COMPLETE) && !(wb.status === EntityWorkbook.STATUS_ARCHIVE)));
      const wbListMd5 = getChecksum(list.map((e) => (e._id + e._rev)).join());
      if (!this.wbListMd5 || this.wbListMd5 !== wbListMd5) {
        this.wbListMd5 = wbListMd5;
        //console.log('updateIcpRecurrence', this.entityList);
        this.todoList = [];
        //const inprogressList = [];
        const upcomingList = [];
        list.forEach((wb: EntityWorkbook) => {
          //if (wb._id === '1fb49ad3-d45c-a854-0e01-9c4398cf03e1') {
          //  console.log('test');
          //}

          this.pdpStates[wb._id] = null;
          const wbpdps: StepPdpState[] = [];
          //console.log('updateIcpRecurrence pdps ' + wb.name, !!wb.pdps);
          //console.log('updateIcpRecurrence pdps length ' + wb.name, (Object.keys(wb.pdps).length > 0));
          if (!!wb.pdps && (Object.keys(wb.pdps).length > 0)) {
            Object.keys(wb.pdps).forEach((step_id) => {
              //if (step_id === '44281b57-b3ef-2b6f-f07a-5597033368b6') {
              //  console.log('test');
              //}
              //console.log('updateIcpRecurrence step', step_id)
              let nextPdp: {id: string, name: string, date: Date, status: number} = null;
              let _state: {name: string, color: string, delay: number} = null;
              const lastPdpValid: {id: string, name: string, date: Date, status: number} = wb.getLastPdpInfos(step_id, SignableStatus.SIGNED);
              const nextIntervention: {id: string, date_start: Date, date_end: Date, name: string} = wb.pdps[step_id].template ? wb.getNextInterventionInfos(step_id) : null;
              nextPdp = wb.getNextPdpInfos(step_id);
              // console.log('debut (' + wb._id + '/' + step_id + '):', wb.pdps[step_id].dateEnd);
              if (!wb.pdps[step_id].dateEnd || !(((new Date(wb.pdps[step_id].dateEnd)).getTime() + 86400000) < now.getTime())) {
                // console.log('update PDP state:' + wb._id + '(' + step_id + ')', [nextPdp, lastPdpValid, nextIntervention, wb]);
                if (lastPdpValid) {
                  //CT avec PdP validée
                  _state = {
                    name: this.icpRecurrence.validPdpText,
                    color: this.icpRecurrence.validPdpColor,
                    delay: 0
                  };
                  // console.log('PdP valide (' + wb._id + '/' + step_id + '):', [nextPdp, lastPdpValid]);
                  /*
                if (nextPdp){ // ou pour tous et pdp_id optionnel ?
                  _state = this.icpRecurrence.getPdpStatus((nextIntervention ? nextIntervention.date_start : (wb.pdps[step_id].template ? null : wb.pdps[step_id].dateStart)), lastPdpValid.date);
                  if (nextIntervention) {
                    this.todoList.push({
                      date: nextIntervention.date_start,
                      owner: ((wb.users.indexOf(this.userId) !== -1) ? this.SCOPEOWNER : (((!wb.offices)
                        || (wb.offices && wb.offices.length && this.matchingUserContactsOffices(wb.offices))) ? this.SCOPEOFFICE : this.SCOPEALL)),
                      wb: wb,
                      step_id: step_id,
                      step_template: wb.inspections[step_id].template,
                      step_name: wb.inspections[step_id].name,
                      step_start: new Date(wb.inspections[step_id].dateStart),
                      step_end: new Date(wb.inspections[step_id].dateEnd),
                      icp_id: null,
                      pdp_id: nextPdp.id,
                      text: 'Plan de prévention à signer/valider avant le ' + formattedDate(nextIntervention.date_start),
                      state: _state
                    });
                  }
                }
                */
                } else {
                  //pas de lastPdpValid
                  if (wb.pdps[step_id].template) {
                    //cas CT
                    if (!nextIntervention) {
                      //_state = this.icpRecurrence.getPdpStatus(null, nextPdp ? nextPdp.date : null);
                      _state = this.icpRecurrence.getPdpStatus(null, null);
                      // console.log('CT sans intervention prevue (' + wb._id + '/' + step_id + '):', [nextPdp]);
                    } else {
                      //_state = this.icpRecurrence.getPdpStatus(nextIntervention.date_start, nextPdp ? nextPdp.date : new Date());
                      _state = this.icpRecurrence.getPdpStatus(nextIntervention.date_start);
                      if (nextPdp) {
                        // console.log('CT avec PdP en preparation et intervention prevue (' + wb._id + '/' + step_id + '):', [nextPdp]);
                      } else {
                        // console.log('CT sans PdP et intervention prevue (' + wb._id + '/' + step_id + '):', [nextPdp]);
                      }
                    }
                  } else {
                    //cas phase
                    _state = this.icpRecurrence.getPdpStatus(wb.pdps[step_id].dateStart, ((nextPdp && nextPdp.status === 3) ? nextPdp.date : now));
                    // console.log('Phase:(' + wb._id + '/' + step_id + '):', [nextPdp]);
                  }
                  if (!(wb.pdps[step_id].template && !nextIntervention)) {
                    this.todoList.push({
                      date: nextIntervention ? nextIntervention.date_start : wb.pdps[step_id].dateStart,
                      shown: this.getShown(wb),
                      wb: wb,
                      step_id: step_id,
                      step_template: wb.pdps[step_id].template,
                      step_name: wb.pdps[step_id].name,
                      step_start: new Date(wb.pdps[step_id].dateStart),
                      step_end: new Date(wb.pdps[step_id].dateEnd),
                      icp_id: null,
                      pdp_id: (nextPdp ? nextPdp.id : ''),
                      text: 'Plan de prévention ' + (nextPdp ? 'à signer/valider' : 'à réaliser'),
                      text_suite: 'avant le ' + formattedDate(nextIntervention ? nextIntervention.date_start : wb.pdps[step_id].dateStart),
                      state: _state
                    });
                  }

                }
                if (!this.pdpStates[wb._id] || (_state && _state.delay !== 0 && ((_state.delay < this.pdpStates[wb._id].delay) || this.pdpStates[wb._id].delay === 0))) {
                  this.pdpStates[wb._id] = _state;
                }
                if (wb.pdps[step_id].template) {
                  // console.log('template');
                  if (nextIntervention) {
                    const evt: Event = {
                      date: nextIntervention.date_start,
                      shown: this.getShown(wb),
                      event_id: nextIntervention.id,
                      step_id: step_id,
                      step_template: wb.pdps[step_id].template,
                      step_name: wb.pdps[step_id].name,
                      step_start: new Date(wb.pdps[step_id].dateStart),
                      step_end: new Date(wb.pdps[step_id].dateEnd),
                      wb: wb,
                      text: nextIntervention.name,
                      state: _state
                    };
                    /*
                    if (nextIntervention.date_start && nextIntervention.date_start.getTime() < now.getTime()) {
                      inprogressList.push(evt);
                    } else {
                      upcomingList.push(evt);
                    }
                    */
                    upcomingList.push(evt);
                  }
                } else {
                  // console.log('not template');
                  if (!wb.pdps[step_id].dateEnd || (new Date(wb.pdps[step_id].dateEnd).getTime() + 86400000) > now.getTime()) {
                    const evt: Event = {
                      date: new Date(wb.pdps[step_id].dateStart),
                      shown: this.getShown(wb),
                      wb: wb,
                      event_id: step_id,
                      step_id: step_id,
                      step_template: wb.pdps[step_id].template,
                      step_name: wb.pdps[step_id].name,
                      step_start: new Date(wb.pdps[step_id].dateStart),
                      step_end: new Date(wb.pdps[step_id].dateEnd),
                      text: wb.pdps[step_id].name,
                      state: _state
                    };
                    /*
                    if (wb.pdps[step_id].dateStart && (new Date(wb.pdps[step_id].dateStart).getTime()) < now.getTime()) {
                      inprogressList.push(evt);
                    } else {
                      upcomingList.push(evt);
                    }
                    */
                    upcomingList.push(evt);
                  }
                }
              }
              wbpdps.push({
                step_id: step_id,
                step_template: wb.pdps[step_id].template,
                step_name: wb.pdps[step_id].name,
                step_start: new Date(wb.pdps[step_id].dateStart),
                step_end: new Date(wb.pdps[step_id].dateEnd),
                lastPdp: lastPdpValid,
                nextPdp: nextPdp,
                nextIntervention: nextIntervention,
                state: _state
              });
            });
          }
          this.pdpList[wb._id] = wbpdps;

          this.icpStates[wb._id] = null;
          const wbicps: StepIcpState[] = [];
          //console.log('updateIcpRecurrence inspections ' + wb.name, !!wb.inspections);
          //console.log('updateIcpRecurrence inspections length ' + wb.name, (Object.keys(wb.inspections).length > 0));
          if (!!wb.inspections && (Object.keys(wb.inspections).length > 0)) {
            Object.keys(wb.inspections).forEach((step_id) => {
              //if (step_id === '769b8992-53eb-db40-9b14-6918f41a118e') {
              //  console.log('test');
              //}
              //console.log('updateIcpRecurrence step', step_id)

              let nextIcp: {id: string, name: string, date: Date, status: number} = null;
              //let pendingIcp: {id: string, date: Date, status: number} = null;
              let plannedIcp: Date = null;
              let _state: {name: string, color: string, delay: number} = null;
              const lastIcpValid: {id: string, name: string, date: Date, status: number} = wb.getLastIcpInfos(step_id, SignableStatus.SIGNING);
              let lastIcp: {id: string, name: string, date: Date, status: number} = wb.getLastIcpInfos(step_id, 0);
              if (lastIcpValid && lastIcp && lastIcp.date.getTime() <= lastIcpValid.date.getTime()) {
                lastIcp = null;
              }
              let nextIntervention: {id: string, date_start: Date, date_end: Date, name: string};// = wb.getNextInterventionInfos(step_id);
              // console.log('debut (' + wb._id + '/' + step_id + '):', wb.inspections[step_id].dateEnd);
              if (!wb.inspections[step_id].dateEnd || !(((new Date(wb.inspections[step_id].dateEnd)).getTime() + 86400000) < now.getTime())) {
                nextIntervention = wb.getNextInterventionInfos(step_id);
                //pendingIcp = wb.getLastIcpInfos(step_id, 0);
                nextIcp = wb.getNextIcpInfos(step_id);
                //CT ou pas encore d'icp valide
                if (wb.inspections[step_id].template || !lastIcpValid) {
                  plannedIcp = wb.getNextPlannedIcpDate(this.icpRecurrence, step_id);
                  if (wb.inspections[step_id].template && plannedIcp && (lastIcpValid || lastIcp) && wb.inspections[step_id].score) {
                    //gestion audits
                    let audits = this.icpRecurrence.getAudits(wb.inspections[step_id].score, wb.inspections[step_id].lastAccident);
                    const lastAudits: {
                      name: string,
                      date: Date,
                      status: number,
                      step: string
                    }[] = wb.getLastAudits(step_id, plannedIcp);
                    if (lastAudits.length < audits) {
                      let nbdays = getDifferenceInDays((lastAudits.length ? lastAudits[0].date : (lastIcpValid ? lastIcpValid.date : lastIcp.date)), plannedIcp);
                      nbdays = Math.round(nbdays / (1 + audits - lastAudits.length));
                      const nextAudit = new Date(lastAudits.length ? lastAudits[0].date : (lastIcpValid ? lastIcpValid.date : lastIcp.date))
                      nextAudit.setDate(nextAudit.getDate() + nbdays);
                      this.todoList.push({
                        date: nextAudit,
                        shown: this.getShown(wb),
                        wb: wb,
                        step_id: step_id,
                        step_template: wb.inspections[step_id].template,
                        step_name: wb.inspections[step_id].name,
                        step_start: new Date(wb.inspections[step_id].dateStart),
                        step_end: new Date(wb.inspections[step_id].dateEnd),
                        icp_id: null,
                        pdp_id: null,
                        audit: true,
                        text: 'Visite de contrôle à réaliser',
                        text_suite: '(' + lastAudits.length + '/' + audits + ' du ' + formattedDate(lastIcpValid ? lastIcpValid.date : lastIcp.date) + ' au ' + formattedDate(plannedIcp) + ')',
                        state: this.icpRecurrence.getIcpStatus(nextAudit)

                      });
                    }
                  }
                  //plannedIcp = (wb.inspections[step_id].template && (!nextIntervention || new Date(nextIntervention.date_start).getTime() < now.getTime())) ? null : wb.getNextPlannedIcpDate(this.icpRecurrence, step_id);
                  if (plannedIcp && nextIcp && nextIcp.date) {
                    //cas prochaine ICP planifiée
                    if (nextIcp.date.getTime() > plannedIcp.getTime()) {
                      //ICP prevu et preparée en retard
                      _state = this.icpRecurrence.getIcpStatus((wb.inspections[step_id].score ? (nextIcp.date.getTime() > plannedIcp.getTime() ? nextIcp.date : plannedIcp) : null), nextIcp.date);
                      if (_state && _state.delay === 0 && wb.inspections[step_id].score === 0) {
                        _state.color = this.neutralColor;
                      } else {
                        this.todoList.push({
                          date: nextIcp.date,
                          shown: this.getShown(wb),
                          wb: wb,
                          step_id: step_id,
                          step_template: wb.inspections[step_id].template,
                          step_name: wb.inspections[step_id].name,
                          step_start: new Date(wb.inspections[step_id].dateStart),
                          step_end: new Date(wb.inspections[step_id].dateEnd),
                          icp_id: nextIcp.id,
                          pdp_id: null,
                          text: 'Inspection commune',
                          text_suite: '(prévue avant le ' + formattedDate(plannedIcp) + ')',
                          state: _state
                        });
                      }
                      // console.log('cas1 (' + wb._id + '/' + step_id + '):', [_state, plannedIcp, nextIcp.date]);
                    } else {
                      /*
                      if (lastIcpValid) {
                        _state = this.icpRecurrence.getIcpStatus((nextIntervention ? nextIntervention.date_start : null), lastIcpValid.date);
                      }
                      */
                      //ICP prevu et preparée avant
                      this.todoList.push({
                        date: nextIcp.date,
                        shown: this.getShown(wb),
                        wb: wb,
                        step_id: step_id,
                        step_template: wb.inspections[step_id].template,
                        step_name: wb.inspections[step_id].name,
                        step_start: new Date(wb.inspections[step_id].dateStart),
                        step_end: new Date(wb.inspections[step_id].dateEnd),
                        icp_id: nextIcp.id,
                        pdp_id: null,
                        text: 'Inspection commune planifiée',
                        text_suite: '(' + wb.inspections[step_id].name + ')',
                        state: _state
                      });
                    }
                  } else if ((plannedIcp || nextIntervention) && !nextIcp && !lastIcp) {
                    _state = this.icpRecurrence.getIcpStatus(((wb.inspections[step_id].score && plannedIcp) ? plannedIcp : (nextIntervention ? nextIntervention.date_start : null)));
                    if (_state && _state.delay === 0 && wb.inspections[step_id].score === 0) {
                      _state.color = this.neutralColor;
                    } else {
                      this.todoList.push({
                        date: plannedIcp,
                        shown: this.getShown(wb),
                        wb: wb,
                        step_id: step_id,
                        step_template: wb.inspections[step_id].template,
                        step_name: wb.inspections[step_id].name,
                        step_start: new Date(wb.inspections[step_id].dateStart),
                        step_end: new Date(wb.inspections[step_id].dateEnd),
                        icp_id: '',
                        pdp_id: null,
                        text: 'Inspection à réaliser',
                        text_suite: 'avant le ' + formattedDate(plannedIcp),
                        state: _state
                      });
                    }
                    // console.log('cas2 (' + wb._id + '/' + step_id + '):', [_state, plannedIcp]);
                  } else if (!(plannedIcp && nextIcp && nextIcp.date.getTime() < plannedIcp.getTime())
                    && !(lastIcpValid && !plannedIcp)) {
                    //!planned = avant intervention && nextIntervention
                    _state = this.icpRecurrence.getIcpStatus(
                      (plannedIcp ? plannedIcp : (nextIntervention ? nextIntervention.date_start : (wb.inspections[step_id].template ? null : wb.inspections[step_id].dateStart))),
                      (lastIcpValid ? lastIcpValid.date : (lastIcp ? lastIcp.date : (nextIcp ? nextIcp.date : new Date())))
                    );
                    //  _state = this.icpRecurrence.getIcpStatus(nextIntervention.date_start, nextIcp ? nextIcp.date : new Date());
                    // console.log('cas3 (' + wb._id + '/' + step_id + '):', [nextIntervention, nextIcp]);
                    if (lastIcpValid || lastIcp) {
                      if (lastIcp || (lastIcpValid && lastIcpValid.status < SignableStatus.SIGNED)) {
                        this.todoList.push({
                          date: new Date(lastIcp ? lastIcp.date : lastIcpValid.date),
                          shown: this.getShown(wb),
                          wb: wb,
                          step_id: nextIntervention ? nextIntervention.id : step_id,
                          step_template: wb.inspections[step_id].template,
                          step_name: wb.inspections[step_id].name,
                          step_start: new Date(wb.inspections[step_id].dateStart),
                          step_end: new Date(wb.inspections[step_id].dateEnd),
                          icp_id: lastIcp ? lastIcp.id : lastIcpValid.id,
                          pdp_id: null,
                          text: 'Inspection commune à signer/valider',
                          text_suite: '(' + formattedDate(lastIcp ? lastIcp.date : lastIcpValid.date) + ')',

                          state: _state
                        });
                      } else if (_state) {
                        this.todoList.push({
                          date: nextIntervention ? nextIntervention.date_start : wb.inspections[step_id].dateStart,
                          shown: this.getShown(wb),
                          wb: wb,
                          step_id: nextIntervention ? nextIntervention.id : step_id,
                          step_template: wb.inspections[step_id].template,
                          step_name: wb.inspections[step_id].name,
                          step_start: new Date(wb.inspections[step_id].dateStart),
                          step_end: new Date(wb.inspections[step_id].dateEnd),
                          icp_id: '',
                          pdp_id: null,
                          text: 'Inspection à réaliser',
                          text_suite: 'avant l\'intervention du ' + formattedDate(nextIntervention ? nextIntervention.date_start : wb.inspections[step_id].dateStart),
                          state: _state
                        });
                      }
                    }
                  }
                } else if (lastIcpValid && lastIcpValid.status < SignableStatus.SIGNED) {
                  _state = this.icpRecurrence.getIcpStatus(wb.inspections[step_id].dateStart);
                  this.todoList.push({
                    date: lastIcpValid.date,
                    shown: this.getShown(wb),
                    wb: wb,
                    step_id: step_id,
                    step_template: wb.inspections[step_id].template,
                    step_name: wb.inspections[step_id].name,
                    step_start: new Date(wb.inspections[step_id].dateStart),
                    step_end: new Date(wb.inspections[step_id].dateEnd),
                    icp_id: lastIcpValid.id,
                    pdp_id: null,
                    text: 'Inspection commune à signer/valider',
                    text_suite: '(' + formattedDate(lastIcpValid.date) + ')',
                    state: _state
                  });
                  // console.log('cas non CT avec lastICPValid non signée (' + wb._id + '/' + step_id + ')');
                } else {
                  // console.log('cas non CT avec lastICPValid signée (' + wb._id + '/' + step_id + ')');
                }
                if (!this.icpStates[wb._id]
                  || (_state
                    && _state.color !== this.neutralColor
                    && ((_state.delay !== 0 && _state.delay < this.icpStates[wb._id].delay)
                      || (_state.delay === 0 && this.icpStates[wb._id].delay === 0))
                  )
                ) {
                  this.icpStates[wb._id] = _state;
                }
              }
              wbicps.push({
                step_id: step_id,
                step_template: wb.inspections[step_id].template,
                step_name: wb.inspections[step_id].name,
                step_start: new Date(wb.inspections[step_id].dateStart),
                step_end: new Date(wb.inspections[step_id].dateEnd),
                lastIcpValid: lastIcpValid,
                lastIcp: lastIcp,
                nextIcp: nextIcp,
                nextIntervention: (nextIntervention && ((nextIntervention.date_end.getTime()) > now.getTime())) ? nextIntervention : null,
                plannedIcp: (wb.inspections[step_id].template && !nextIcp) ? plannedIcp : null,
                state: _state,
                beforeIntervention: (
                  wb.inspections[step_id].template && !nextIcp
                  && (!wb.inspections[step_id].score || (plannedIcp && nextIntervention && nextIntervention.date_start
                    && plannedIcp.getTime() === nextIntervention.date_start.getTime())))
              });

              if (wb.inspections[step_id].scheduled) {
                Object.keys(wb.inspections[step_id].scheduled).forEach((icp_id) => {
                  if (new Date(wb.inspections[step_id].scheduled[icp_id].date).getTime() > now.getTime() - 86400000) {
                    const evt: Event = {
                      date: new Date(wb.inspections[step_id].scheduled[icp_id].date),
                      shown: this.getShown(wb),
                      wb: wb,
                      event_id: icp_id,
                      text: 'Inspection commune du ' + formattedDate(new Date(wb.inspections[step_id].scheduled[icp_id].date)),
                      state: null
                    };
                    /*
                    if (new Date(wb.inspections[step_id].scheduled[icp_id].date).getTime() > (now.getTime())) {
                      if (upcomingList.findIndex((e) => (e.event_id === icp_id)) === -1) {
                        upcomingList.push(evt);
                      }
                    } else {
                      if (inprogressList.findIndex((e) => (e.event_id === icp_id)) === -1) {
                        inprogressList.push(evt);
                      }
                    }
                    */
                    if (upcomingList.findIndex((e) => (e.event_id === icp_id)) === -1) {
                      upcomingList.push(evt);
                    }
                  }
                });
              }

            });
          }
          this.icpList[wb._id] = wbicps;

        });
        this.inprogressList = this.todoList.filter((e) => (!e.state)).sort((a, b) => ((new Date(a.date)).getTime() - (new Date(b.date)).getTime()));
        this.todoList = this.todoList.filter((e) => (!!e.state)).sort((a, b) => ((new Date(a.date)).getTime() - (new Date(b.date)).getTime()));
        this.upcomingList = upcomingList.sort((a, b) => ((new Date(a.date)).getTime() - (new Date(b.date)).getTime()));
        this.filterTodoEvent();

        //setTimeout(() => {
        //console.log('updateIcpRecurrence markForCheck');
        this._cd.markForCheck();
        //});
        //console.log('updateIcpRecurrence initialized', list);
        setTimeout(() => {
          this.postInit();
        });
      }
      //else {
      //  console.log('updateIcpRecurrence same md5');
      //}
    }
    //else {
    //  console.log('updateIcpRecurrence not ready')
    //}
  }

  getShown(data): number[] {
    return [];
  }
  filterTodoEvent() {
  }

  _init(data) {
    super._init(data);
    setTimeout(() => {
      this.updateIcpRecurrence();
    });
  }
  getTypeVal(data) {
    return (data.type === 'annual' && data.simple) ? 'protocol' : data.type;
  }

  getIcpStatusLabel(status: number) {
    return ICPState.LABELS[status] ? ICPState.LABELS[status] : status;
  }
  getPdpStatusLabel(status: number) {
    return PDPState.LABELS[status] ? PDPState.LABELS[status] : status;
  }

  protected isCompleted(wb: EntityWorkbook) {
    return wb.getStatus() === EntityWorkbook.STATUS_COMPLETE;
  }
  protected isArchived(wb: EntityWorkbook) {
    return wb.getStatus() === EntityWorkbook.STATUS_ARCHIVE;
  }
  isPast(date: Date): boolean {
    return (new Date(date).getTime() < (new Date().getTime()));
  }

  getUserWorkbooks() {
    return JSON.stringify(this._entityService.userWorkbooks);
  }
  matchingUserContactsOffices(officeIds: string[]) {
    return this.userContactsOffices && this.userContactsOffices.length
      && officeIds && officeIds.length
      && this.userContactsOffices.findIndex((e) => (
        officeIds.indexOf(e) !== -1
      )) !== -1
  }
  getEexs(wb: EntityWorkbook, all: boolean = true) {
    return (!!wb.eexs ? (wb.eex_name + ((Object.keys(wb.eexs).length > 1) ? '\n' : '')) : '')
      + Object.keys(wb.eexs).filter((e) => (e !== wb.eex_name)).sort((a, b) => a.localeCompare(b)).join('\n');
  }
  getEexsCell(wb: EntityWorkbook) {
    //Attention css dans app.css car css worbooks non applicable
    if (wb.eexs) {
      const returned = Object.keys(wb.eexs).filter((e) => (e !== wb.eex_name)).sort((a, b) => a.localeCompare(b));
      return (!!wb.eexs
        ? (
          ('<b class="light" title="' + wb.eex_name + '">' + wb.eex_name + '</b>')
          + ((returned.length > 2) ? ('<span>+ ' + returned.length + ' entreprises</span>') : returned.map(e => ('<span title="' + e + '">' + e + '</span>')).join(''))
        ) : (
          (returned.length > 3) ? ('<span title="' + returned[0] + '">' + returned[0] + '</span><span title="' + returned[1] + '">' + returned[1] + '</span><span>' + (returned.length - 2) + ' entreprises</span>') : returned.map(e => ('<span title="' + e + '">' + e + '</span>')).join('')
        )
      );
    }
    return '';
  }
  getEexsInfo(wb: EntityWorkbook): {name: string, bold?: boolean, operators: MatTableDataSource<Operator>, contacts: MatTableDataSource<Contact>}[] {
    const eexList: {name: string, principal?: boolean, operators: MatTableDataSource<Operator>, contacts: MatTableDataSource<Contact>}[] = [];
    if (!!wb.eex_name) {
      const eex_contacts = (wb.eex_contacts && wb.eex_contacts[wb.eex_name]) ? wb.eex_contacts[wb.eex_name] : [];
      eexList.push({
        name: wb.eex_name,
        principal: true,
        operators: new MatTableDataSource(wb.operators ? wb.operators
          .filter((e) => (e.company === wb.eex_name || (wb.eexs && wb.eexs[wb.eex_name] === e.company_repo_id)))
          .sort((a, b) => a.name.localeCompare(b.name))
          : []),
        contacts: new MatTableDataSource(eex_contacts)

      });
    }
    if (wb.eexs) {
      Object.keys(wb.eexs)
        .filter((e) => (e !== wb.eex_name))
        .sort((a, b) => a.localeCompare(b))
        .forEach((c) => {
          const eex_contacts = (wb.eex_contacts && wb.eex_contacts[c]) ? wb.eex_contacts[c] : [];
          eexList.push({
            name: c,
            operators: new MatTableDataSource(wb.operators ? wb.operators
              .filter((e) => (e.company === c || (wb.eexs && wb.eexs[c] === e.company_repo_id)))
              .sort((a, b) => a.name.localeCompare(b.name))
              : []),
            contacts: new MatTableDataSource(eex_contacts)
          });
        });
    }
    return eexList;
  }

  getAutorizedUsers(workbook: EntityWorkbook) {
    let returned = [];
    const userList = this._entityService.getUserList();
    if (userList && userList.length && workbook && workbook.users && workbook.users.length) {
      returned = userList.filter((e) => (workbook.users.indexOf(e.user_id) !== -1));
    }
    return returned;

  }
}
