import {Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges} from '@angular/core';
import {DxDateBoxComponent} from "devextreme-angular";
import frMessages from 'locale/devextreme/fr_FR';
import {locale, loadMessages} from "devextreme/localization";

export class DateTimeCommonComponent implements OnChanges {
  @ViewChild('field') field: DxDateBoxComponent;

  @Input() value: Date | null;
  @Output() valueChange = new EventEmitter<Date | null>();
  @Input() class: string;
  @Input() isValid: boolean = true;
  @Output() isValidChange = new EventEmitter<boolean>();
  @Input() error: any;
  @Output() errorChange = new EventEmitter<any>();
  validationError: any = {};
  @Input() type: string;
  @Input() displayFormat: string;
  @Input() pickerType: string;
  @Input() showClearButton: boolean;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() min: Date;
  @Input() max: Date;

  // @Input() dateInvalid: boolean = false;
  // @Output() dateInvalidChange = new EventEmitter<boolean>();

  initChange: boolean;// = true;

  constructor(protected _cd: ChangeDetectorRef) {
    loadMessages(frMessages);
    locale(navigator.language);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.initChange = this.initChange || (changes && changes['value'] && changes['value'].firstChange && !!this.value);
    setTimeout(() => {this.checkError();}, 100);
  }
  checkErrorRequired() {
    if (this.required && !this.value) {
      this.isValid = false;
    }
  }
  checkError() {
    this.checkErrorRequired();
    let error = (this.validationError && this.validationError.message)
      ? this.validationError.message
      : ((this.required && !this.value) ? 'Obligatoire' : (this.isValid ? '' : 'Valeur incorrecte'));
    if (error !== this.error || (this.error && this.initChange)) {
      this.error = error;
      this.errorChange.emit(this.error);
    }
    this.isValidChange.emit(this.isValid);
    this._cd.markForCheck();
  }
  emitValue(value) {
    this.valueChange.emit(value);
  }
  onValueChanged(event) {
    if (this.initChange) {
      this.initChange = false;
    } else {
      if (this.field && this.field.isValid) {
        this.emitValue((event && event.value) ? event.value : null);
      }
      this.checkError()
    }
  }
}