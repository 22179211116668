import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {WorkbookMeasureService, EntityWorkbookMeasure} from 'app/workbook-core';

@Component({
  selector: 'app-workbook-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss']
})
export class WorkbookMeasureComponent extends EntityFormComponent {
  @Output() valueChange: EventEmitter<EntityWorkbookMeasure> = new EventEmitter<EntityWorkbookMeasure>();
  @Input() disabled: boolean = false;
  entity: EntityWorkbookMeasure;
  scoped: boolean = false;
  scope: string = '';
  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: WorkbookMeasureService

  ) {
    super(dialog, _logger, _entityService);
  }

  protected _init(data) {
    super._init(data);
    this.rightU = this.rightU && !this.disabled;
  }
}
