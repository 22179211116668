export class Message {
  id: number;
  name: string;
  type: string[];
  subject: string;
  text: string;
  usertext: string;
  //signature: string;
}
export class MessageModel extends Message {
  fromName: string;
  fromEmail: string;
  defaultSenderUser: boolean;
  defaultReplyUser: boolean;
  signature: string;
  rgpdMail: string;
  dpoName: string;
  dpoMail: string;
}

