import {EntityOwned} from './entity.model';
export interface IDocumentCategory {
  name: string;
  picto: string;
  limited: boolean;
}
export interface IDocumentable {
  documents: EntityDocumentMeta[];
}

export class DocumentStatus {
  static VALIDATED = 0;
  static REQUESTED = 1;
  static PROPOSED = 2;
  static LABELS: string[] = [
    'Validé',
    'Demandé',
    'À valider'
  ];

  static getLabels() {
    return DocumentStatus.LABELS;
  }
  static getLabel(status: number) {
    return DocumentStatus.LABELS[status] ? DocumentStatus.LABELS[status] : 'status-' + status;
  }
}
export class EntityDocumentMeta extends EntityOwned {

  public data_id: string;
  public name: string;
  public ext: string;
  public displayName: string;
  public mime: string;
  public size: string;
  public icon: string;
  public description: string;
  public category: string;
  public categoryPicto: string;
  public isInline: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.data_id = data && data.data_id ? data.data_id : '';
    this.name = data && data.name ? data.name : '';
    this.ext = data && data.ext ? data.ext : '';
    this.displayName = data && data.displayName ? data.displayName : '';
    this.mime = data && data.mime ? data.mime : '';
    this.size = data && data.size ? data.size : '';
    this.icon = data && data.icon ? data.icon : '';
    this.description = data && data.description ? data.description : '';
    this.category = data && data.category ? data.category : '';
    this.categoryPicto = data && data.categoryPicto ? data.categoryPicto : '';
    this.isInline = (data && data.isInline);
  }
}
export class EntityDocumentMetadata extends EntityDocumentMeta {

  public status: number;
  public reason: string;
  public notifications: string[];
  public request: string[];
  public requestedSent: string[];
  public requestedAt: Date;
  public requestedName: string;
  public requestedId: string;
  public uploadedAt: Date;
  public uploadedName: string;
  public uploadedId: string;
  public context: string;
  public dateStart: Date;
  public dateEnd: Date;
  public isDefault: boolean;
  //id fichier d'origine (pour ne pas modifier les originaux)
  public data_origin: string;
  //state = annotations/stickers
  public imageState: string;
  //Attention, l'image de fond est enregistrée dans le meme docfile
  //en utilisant comme nom de piece jointe

  //modèle pour demande de documents
  public template_id: string;
  public template_name: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.status = data && data.status ? data.status : 0;
    this.reason = data && data.reason ? data.reason : '';
    this.notifications = data && data.notifications ? data.notifications.map(e => e) : [];
    this.uploadedAt = data && data.uploadedAt ? new Date(data.uploadedAt) : this.data_id ? new Date(this.updatedAt) : null;
    this.uploadedName = data && data.uploadedName ? data.uploadedName : '';
    this.uploadedId = data && data.uploadedId ? data.uploadedId : '';
    this.request = data && data.request ? data.request.map(e => e) : [];
    this.requestedSent = data && data.requestedSent ? data.requestedSent.map(e => e) : [];
    this.requestedAt = data && data.requestedAt ? new Date(data.requestedAt) : null;
    this.requestedName = data && data.requestedName ? data.requestedName : '';
    this.requestedId = data && data.requestedId ? data.requestedId : '';
    this.context = data && data.context ? data.context : '';
    this.dateStart = data && data.dateStart ? new Date(data.dateStart) : null;
    this.dateEnd = data && data.dateEnd ? new Date(data.dateEnd) : null;
    this.isDefault = (data && data.isDefault);
    //vider data_origin sur clonage
    this.data_origin = (data && data.data_origin && !clone) ? data.data_origin : '';
    this.imageState = (data && data.imageState) ? data.imageState : '';
    this.template_id = (data && data.template_id) ? data.template_id : '';
    this.template_name = (data && data.template_name) ? data.template_name : '';
  }
}

