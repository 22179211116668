import {Entity} from 'app/shared';

export class Instruction {
  name: string;
  status: number;
  constructor(name: string, status: number = 0) {
    this.name = name;
    this.status = status;
  }
}
export class SafetyInstruction {
  group: string;
  instructions: Instruction[];
  constructor(group: string, instructions: Instruction[] = []) {
    this.group = group;
    this.instructions = instructions;
  }
}
export class EntityFirePermitContent extends Entity {
  //Titre
  public title: string;
  //liste des travaux selectionnables
  public description: string;
  //liste des travaux selectionnables
  public hotSpotWorks: string[];
  //liste du materiel selectionnables
  public equipments: string[];
  //liste de documents selectionnables
  public attestations: string[];

  public warningDevices: string[];
  public interventionDevices: string[];
  public emergency: string[];

  public safetyInstructions: SafetyInstruction[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.title = (data && data.title) ? data.title : 'Permis feu';
    this.description = (data && data.description) ? data.description : '';
    this.hotSpotWorks = (data && data.hotSpotWorks) ? data.hotSpotWorks.map((e) => (e)) : [];
    this.equipments = (data && data.equipments) ? data.equipments.map((e) => (e)) : [];
    this.attestations = (data && data.attestations) ? data.attestations.map((e) => (e)) : [];

    this.warningDevices = (data && data.warningDevices) ? data.warningDevices.map((e) => (e)) : [];
    this.interventionDevices = (data && data.interventionDevices) ? data.interventionDevices.map((e) => (e)) : [];
    this.emergency = (data && data.emergency) ? data.emergency.map((e) => (e)) : [];

    this.safetyInstructions = (data && data.safetyInstructions) ? data.safetyInstructions.map((e) => (Object.assign({}, e))) : [];

  }
}
