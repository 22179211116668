import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService, SignableStatus } from 'app/shared';
import { EntitySignablePreventionPlan, PDPState } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SignablePreventionPlanService extends EntitySignableService {

  entity: EntitySignablePreventionPlan;
  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement du plan de prévention";
  static LABEL_ALL = "chargement des plans de prévention ?";
  static LABEL_CHILDREN = "chargement des plans de prévention de l'opération";
  static LABEL_SAVE = "sauvegarde du plan de prévention";
  static LABEL_DELETE = "suppression du plan de prévention";

  static ENTITY_TYPE: string = 'pdp';
  static ENTITY_FIELDS: string[] = ['name', 'code', 'editor', 'editorEmail', 'editorDate', 'editedAt', 'startDate', 'expectedDate', 'steps', 'icps', 'status', 'withoutSignatories', 'notifications'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showSignablePdP'],
    CREATE: ['createSignablePdP'],
    UPDATE: ['editSignablePdP'],
    DELETE: ['removeSignablePdP']
  }

  static ENTITY_URL = 'pdp';
  static PREFIX = 'PP';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntitySignablePreventionPlan {
    return new EntitySignablePreventionPlan(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.editorDate).getTime() === new Date(b.editorDate).getTime())
        ? 0
        : (new Date(a.editorDate).getTime() > new Date(b.editorDate).getTime()) ? -1 : 1)));

  }

  getState(entity: EntitySignablePreventionPlan, workbook_status) {
    let state = (workbook_status && workbook_status === 'archived') ? PDPState.ARCHIVED : PDPState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === SignableStatus.VALIDATED) {
        state = PDPState.PREPARED;
      } else if (entity.status === SignableStatus.SIGNING) {
        state = PDPState.SIGNING;
      } else if (entity.status === SignableStatus.SIGNED) {
        state = entity.isValid() ? PDPState.VALID : PDPState.SIGNED;
      } else if (entity.status === SignableStatus.OUTDATED) {
        state = PDPState.OUTDATED;
      }

    }
    return state;
  }
}
