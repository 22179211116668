import { Injectable } from '@angular/core';
import { PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntityRisk } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RiskService extends EntitySecureService {
  static LABEL_ONE = "chargement du risque";
  static LABEL_ALL = "chargement des risques";
  static LABEL_CHILDREN = "chargement des risques de l'opération ?";
  static LABEL_SAVE = "sauvegarde du risque";
  static LABEL_DELETE = "suppression du risque";

  static ENTITY_TYPE: string = 'repo_risk';
  static ENTITY_FIELDS: string[] = ['name'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showRisks'],
    CREATE: ['createRisk',],
    UPDATE: ['editRisks', 'editRisk'],
    DELETE: ['removeRisk', 'editRisks']
  }

  static ENTITY_URL = 'repository/pp/risk';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityRisk {
    return new EntityRisk(data, clone);
  }

}
