
export class UploadServerModel {
    active: boolean = false;
    url: string;
    retry: number = 3;
}

export class UploadDbModel {
    active: boolean = true;
}
