import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {AbstractEntityEditComponent} from './abstractEntityEdit.component';

export class EntityEditComponent extends AbstractEntityEditComponent {
  protected _routeSubscription: Subscription;
  protected _routerUrl;
  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }
  ngOnInit() {
    let paramsPos = this._router.url.indexOf('?');
    this._routerUrl = (paramsPos === -1) ? this._router.url : this._router.url.substring(0, paramsPos);
    if (!this._entityService.isEntitySingle()) {
      this._routeSubscription = this._route.params.subscribe(params => {
        this._initFromRouteParams(params);
      });
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }
  remove() {
    if (this.entityForm && this.entityForm.entity) {
      this._entityService.remove(this.entityForm.entity)
        .then(res => this.backToList())
        .catch((error) => {
          this._logger.error('EntityEditComponent', 'unable to remove entity', JSON.stringify(error));
          //console.log('EntityEditComponent remove error', [error, this.entityForm.entity]);
          if (this.entityForm.entity._id) {
            this._entityService.refreshOne(this.entityForm.entity._id).then((e) => {
              // TODO : copyfrom ? diff ?
              this.entityForm.entity = e;
            }).catch((err) => {
              this._logger.error('EntityEditComponent', 'unable to refreshOne after error', JSON.stringify(err));
              // console.log('EntityEditComponent refreshOne error', [err, this.entityForm.entity]);
            });
          }
        });
    }
  }
  hasTobackToList() {
    return !(this.entityForm && this.entityForm.autoSave && this.entityForm.entity && this.entityForm.entity._id && !this.entityForm.entity._deleted);
  }
  save() {
    if (this.entityForm && this.entityForm.entity) {
      this._entityService.save(this.entityForm.entity)
        .then(res => {
          if (this.hasTobackToList()) {
            this.backToList();
          }
        })
        .catch((error) => {
          //this._logger.error('EntityEditComponent', 'unable to save entity', error);
          this._logger.error(this.constructor.name + '(EntityEditComponent)', 'unable to save entity', JSON.stringify(error));
          console.log('EntityEditComponent save error ' + (this.entityForm.entity && this.entityForm.entity._id ? this.entityForm.entity._id : ''), [error, this.entityForm.entity]);
          if (this.entityForm.entity._id) {
            this._entityService.refreshOne(this.entityForm.entity._id).then((e) => {
              // TODO : copyfrom ? diff ?
              this.entityForm.entity = e;
            }).catch((err) => {
              this._logger.error(this.constructor.name + '(EntityEditComponent)', 'unable to refreshOne after error', JSON.stringify(err));
              console.error('EntityEditComponent refreshOne error ' + (this.entityForm.entity && this.entityForm.entity._id ? this.entityForm.entity._id : ''), [err, this.entityForm.entity]);
            });
          }
        });
    }
  }
  backToList() {
    this._router.navigateByUrl(this._entityService.getUrl());
  }
}
