import {Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {PermissionsService, LoggerService, SidenavComponent, LicencesService} from 'app/shared';

export class WorkbookSidenavComponent extends SidenavComponent {


  @Input() workbookSimple: boolean = false;
  @Input() workbookType: string = '';
  toggle: string = "hiddenMenu";
  constructor(
    protected _permissionsService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _licence: LicencesService) {
    super(_permissionsService, _router, _route, _logger);
  }
  toggleMenu(el) {
    if (this.toggle) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.toggle = this.toggle ? '' : 'hiddenMenu';
  }
}
