import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent, EntityAgreement} from 'app/shared';
import {PreventionPlanAgreementService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-pp-agreement',
  templateUrl: './preventionPlanAgreement.component.html',
  styleUrls: ['./preventionPlanAgreement.component.scss']
})
export class PreventionPlanAgreementComponent extends EntityFormComponent {

  entity: EntityAgreement;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanAgreementService

  ) {
    super(dialog, _logger, _entityService);
  }

}
