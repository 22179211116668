import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService} from 'app/shared';
import {AbstractSignableInfoDialogComponent} from 'app/workbook-shared';
import {SignablePreventionPlanService, EntityPreventionPlan, EntitySignablePreventionPlan, DocumentationService} from 'app/workbook-core';
import {SignablePreventionPlanInfoComponent} from './signablePreventionPlanInfo.component'

@Component({
  selector: 'app-signable-prevention-plan-info-dialog',
  templateUrl: './signablePreventionPlanInfo-dialog.component.html',
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class SignablePreventionPlanInfoDialogComponent extends AbstractSignableInfoDialogComponent {
  entityForm: SignablePreventionPlanInfoComponent;
  workbook: EntityPreventionPlan;
  selectedSteps: string[];
  selectedIcps: string[];
  entity: EntitySignablePreventionPlan;
  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<SignablePreventionPlanInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: SignablePreventionPlanService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['selectedSteps']) {
        this.selectedSteps = data['selectedSteps'];
      }
      if (data['selectedIcps']) {
        this.selectedIcps = data['selectedIcps'];
      }
    }
  }

  save() {
    if (this.entityForm && this.entityForm.entity) {
      if (!this.entityForm.entity._id) {
        if (this.userProfile) {
          this.entityForm.entity.editor = this._entityService.getUserName();
          this.entityForm.entity.editorEmail = this.userProfile.email;
          this.entityForm.entity.editorDate = new Date();
        }
        if (this.workbook && this.workbook._id) {
          this._entityService.getNewCode(this.workbook._id).then((code) => {
            this.entityForm.entity.parent_code = this.workbook.code;
            this.entityForm.entity.code = code;
            this.entityForm.entity.name = this.entityForm.entity.getName();
            super.save();
          }).catch((error) => {
            this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
              duration: 2000
            });
          });
        }
      } else {
        if (this.workbook && this.workbook.simple && this.entityForm.entity.icps && this.entityForm.entity.steps) {
          if (this.entityForm.entity.icps.length === 0) {
            this.entityForm.entity.steps = this.entityForm.steps.map((e) => (e._id));
          } else {
            this.entityForm.entity.steps = [];
          }
        }
        super.save();
      }
    }
  }
}
