import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { LoggerService, DocumentService, ConfirmDialogComponent } from 'app/shared';
import { SiteService, CategoryService, EntitySite, EntityZone } from 'app/workbook-core';
import { AbstractCompanyDocumentableComponent } from '../abstractCompanyDocumentable.component';
import { RepoZoneDialogComponent } from './zone-dialog.component';

@Component({
  selector: 'app-repository-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})

export class SiteComponent extends AbstractCompanyDocumentableComponent {
  entity: EntitySite;

  zonesDataSource: MatTableDataSource<EntityZone> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  zonesDisplayedColumns = ['name', 'actions']

  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: SiteService

  ) {
    super(_categoryService, _documentService, dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.refreshDataSource();
    }
  }
  addZone() {
    if (this.entity) {
      if (!this.entity.zones) {
        this.entity.zones = [];
      }
      this.entity.zones.push(new EntityZone())
      if (this.autoSave && this.form.valid) {
        this._save();
        this.refreshDataSource();
      } else if (this.form && !this.form.dirty) {
        this.form.control.markAsDirty();
      }
    }
  }
  selectZone(zone: EntityZone = null) {

    const dialogRef = this.dialog.open(RepoZoneDialogComponent, {
      disableClose: false,
      width: '600px',
      data: {
        zone: zone,
        site: this.entity,
        allowAllZones: ((zone && zone.name === '') || (this.entity.zones.findIndex((e) => (e.name === '')) === -1))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const i = this.entity.zones.findIndex((e) => (result._id === e._id));
        if (i !== -1) {
          this.entity.zones[i] = result;
        } else {
          this.entity.zones.push(result);
        }
        this._save();
        this.refreshDataSource();
      }
    }
    );
  }
  removeZone(zone: EntityZone) {
    if (this.entity && this.entity.zones) {
      const index = this.entity.zones.findIndex((e) => (e._id === zone._id));
      if (index !== -1) {
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cette zone ?';
        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.zones.splice(index, 1);
            if (this.autoSave && this.form.valid) {
              this._save();
              this.refreshDataSource();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }

    }
  }
  refreshDataSource() {
    if (this.entity && this.entity.zones) {
      this.zonesDataSource = new MatTableDataSource(this.entity.zones);
      setTimeout(() => {
        this.setDatasourceSort();
      });
    }
  }
  setDatasourceSort() {
    if (this.paginator) {
      this.zonesDataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.zonesDataSource.sort = this.sort;
    }
  }
}
