import {EntityDeed} from './entityDeed.model';
import {Operator} from 'app/shared/models/operator.model';

export class EntityPermit extends EntityDeed {
  //opérateurs autorisés
  public operators: Operator[];
  public workConditions: string;

  public activities: string[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.operators = (data && data.operators) ? data.operators.map((e) => (new Operator(e))) : [new Operator()];

    this.workConditions = (data && data.workConditions) ? data.workConditions : '';

    this.activities = (data && data.activities) ? data.activities.map(e => e) : [];

  }
}
