import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
  OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { LoggerService } from 'app/shared/logger';
import { CommonComponent } from '../common.component';

@Component({
  selector: 'app-signature-field',
  templateUrl: './signature-field.component.html',
  styleUrls: ['./signature-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SignatureFieldComponent extends CommonComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  data: string;

  @Input()
  editable: boolean = false;

  @Input()
  isClear: boolean = false;

  @Input()
  height: number;

  @Input()
  width: number;

  @Output()
  onChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signaturePadOptions = {
    'canvasWidth': 300, // 500
    'canvasHeight': 150 // 300
  };

  constructor(
    protected _logger: LoggerService
  ) {
    super(_logger);
  }

  ngOnInit() {
    if (this.width || this.height) {
      this.signaturePadOptions = {
        'canvasWidth': this.width ? this.width : 300,
        'canvasHeight': this.height ? this.height : 150
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editable'].previousValue !== undefined) {
      if (!this.editable) {
        this.signaturePad.off();
      } else {
        this.signaturePad.on();
      }
    }
  }

  ngAfterViewInit() {
    if (!this.editable) {
      this.signaturePad.off();
    } else {
      this.signaturePad.on();
    }
    //this.signaturePad.resizeCanvas();
    if (this.data) {
      this.signaturePad.fromDataURL(this.data);
    }
  }

  clear() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.onChange.next(this.signaturePad.toDataURL());
  }

}
