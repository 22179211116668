import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {LdcmService, EntityLdcm, SubLdcm} from 'app/workbook-core';

@Component({
  selector: 'app-repository-ldcm',
  templateUrl: './ldcm.component.html',
  styleUrls: ['./ldcm.component.scss']
})
export class LdcmComponent extends EntityFormComponent {

  entity: EntityLdcm;
  multiple: boolean = false;
  name: string = '';
  comments: string = '';
  toggle: boolean = false;
  checked: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: LdcmService

  ) {
    super(dialog, _logger, _entityService);
  }

  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.multiple = !!this.entity.reliefOrg && this.entity.reliefOrg.length > 0;
    }
  }
  removeLdcm(id) {
    if (this.entity.reliefOrg && this.entity.reliefOrg.length > 0) {
      let i = this.entity.reliefOrg.findIndex((e) => (e.id === id));
      if (i !== -1) {
        this.entity.reliefOrg.splice(i, 1);
        this._save();
      }
    }
  }
  addLdcm() {
    if (this.name && this.entity.reliefOrg) {
      this.entity.reliefOrg.push(new SubLdcm({name: this.name, comments: this.comments, toggle: this.toggle}));
      this.name = '';
      this.comments = '';
      this.toggle = false;
      this._save();
    }
  }
  changeMultiple() {
    if (!this.multiple) {
      this.entity.reliefOrg = [];
      this.name = '';
      this.toggle = false;
      this._save();
    }
  }
}
