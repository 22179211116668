import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {
  LoggerService,
  CanvasDialogComponent,
  ConfirmDialogComponent,
  EntityEditComponent,
  EntityComment,
  CommentService,
  DocumentDialogComponent,
  EntityDocumentMetadata,
  DocumentService
} from 'app/shared';
import {PreventionPlanService, EntityWorkbook} from 'app/workbook-core';

@Component({
  selector: 'app-workbook-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('*', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class WorkbookSlideMenuComponent extends EntityEditComponent implements OnInit {


  @Input() menuStateSlide: string = 'out';
  @Input() tabIndex: number = 0;

  entity: EntityWorkbook;
  protected _entitySubscription: Subscription;
  documentMetadataList: EntityDocumentMetadata[] = [];
  // nbrUploaded: number;
  protected _documentSubscription: Subscription;
  commentList: EntityComment[] = [];
  // nbrNotes: number;
  protected _commentSubscription: Subscription;
  status: string = '';

  user: string;
  path: string;
  commentVal: string = null;
  configUpload: any;

  iconsDoc: any = [];

  documentsRightR: boolean;
  documentsRightC: boolean;
  documentsRightD: boolean;
  commentsRightR: boolean;
  commentsRightC: boolean;
  commentsRightD: boolean;

  constructor(
    //protected _auth: AuthService,
    protected _http: HttpClient,
    protected _commentService: CommentService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService,
  ) {
    super(_route, _router, _logger, _entityService);
    this.documentsRightR = this._entityService.hasPermission('showDocumentsPP');
    this.documentsRightC = this._entityService.hasPermission('addDocumentsPP');
    this.documentsRightD = this._entityService.hasPermission('removeDocumentsPP');

    this.commentsRightR = this._entityService.hasPermission('showCommentsPP');
    this.commentsRightC = this._entityService.hasPermission('addCommentsPP');
    this.commentsRightD = this._entityService.hasPermission('removeCommentsPP');

  }


  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (this.id) {
      if (this._entitySubscription) {
        this._entitySubscription.unsubscribe();
      }
      this._entitySubscription = this._entityService.get(this.parent_id ? this.parent_id : this.id).subscribe((entity) => {
        this.entity = entity;

        const isModifiable = this.isModifiable();
        this.documentsRightC = this.documentsRightC && isModifiable;
        this.documentsRightD = this.documentsRightD && isModifiable;
        this.commentsRightC = this.commentsRightC && isModifiable;
        this.commentsRightD = this.commentsRightD && isModifiable;
      });
      if (this._commentSubscription) {
        this._commentSubscription.unsubscribe();
      }
      this._commentSubscription = this._commentService.getChildren(this.parent_id ? this.parent_id : this.id).subscribe((comments) => {
        if (comments) {
          this._updateList(comments
            .filter(e => e.context.indexOf(this._routerUrl) !== -1)
            .sort((a, b) => a.date - b.date)
            , 'commentList');
          // this.nbrNotes = this.commentList.length;
        }
      });

      if (this._documentSubscription) {
        this._documentSubscription.unsubscribe();
      }
      this._documentSubscription = this._documentService.getDocumentMetadataChildren(this.parent_id ? this.parent_id : this.id).subscribe((documents) => {
        if (documents) {
          this._updateList(documents
            .filter(e => e.context.indexOf(this._routerUrl) !== -1)
            .sort((a, b) => a.createdAt - b.createdAt)
            , 'documentMetadataList');
          // this.nbrUploaded = this.documentMetadataList.length;
        }
      });
    }
  }



  ngOnInit() {
    // Get plan prevention data object
    super.ngOnInit();
    // Get user profile data object
    if (this.userProfile && this.userProfile.user_metadata && this.userProfile.user_metadata.given_name && this.userProfile.user_metadata.family_name && this.userProfile.email) {
      this.user = `${this.userProfile.user_metadata.given_name} ${this.userProfile.user_metadata.family_name}`;
    } else if (this.userProfile && this.userProfile.email) {
      this.user = this.userProfile.email;
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._entitySubscription) {
      this._entitySubscription.unsubscribe();
    }
    if (this._commentSubscription) {
      this._commentSubscription.unsubscribe();
    }
    if (this._documentSubscription) {
      this._documentSubscription.unsubscribe();
    }
  }

  public isModifiable() {
    return this.entity && this.entity.isModifiable() && this.entity._id && this._entityService.isSynced(this.entity._id);
  }
  /**
   * Check if pdp has status archived
   * @private
   */
  protected _isArchived(): boolean {
    return this.entity && this.entity.status === 'Archivé';
  }

  /**
   * Used to close slide menu
   */
  closeSlideMenu() {
    this.menuStateSlide = 'out';
  }
  /**
  * Open the right slide menu
  * @param {string} tabIndex
  */
  toggleMenu(tabIndex: number = 0) {
    if (this.menuStateSlide === 'out') {
      this.menuStateSlide = 'in';
      this.tabIndex = tabIndex;
    } else if (this.tabIndex === tabIndex) {
      this.menuStateSlide = 'out';
    } else {
      this.tabIndex = tabIndex;
    }
  }


  /**
   * Open dialog to upload documents
   */
  public openUploadDialog() {
    this.dialog.open(DocumentDialogComponent, {
      data: {
        parent_id: this.parent_id ? this.parent_id : this.id,
        context: this._routerUrl
      },
      minWidth: '50%',
      height: '50%'
    });
  }

  /**
   * Open dialog to create canvas on picture
   */
  public openCanvasDialog() {
    this.dialog.open(CanvasDialogComponent, {width: '90%', height: '95%'});

  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(id: string) {
    this._documentService.downloadDocument(id);
  }

  /**
   * Open dialog to confirm delete and delete document uploaded
   * @param id
   */
  deleteDoc(doc: EntityDocumentMetadata) {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._documentService.remove(doc._id).then((e) => {
          const i = this.documentMetadataList.indexOf(doc);
          if (i !== -1) {
            this.documentMetadataList.splice(i, 1);
          }
        });
      }
    });
  }

  /**
   * Delete a comment by id
   * @param id
   */
  deleteComment(comment) {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce commentaire ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._commentService.remove(comment).then((e) => {
          const i = this.commentList.indexOf(comment);
          if (i !== -1) {
            this.commentList.splice(i, 1);
          }
        }).catch((error) => {
          console.error('WorkbookSlideMenuComponent.removeComment remove error', [error, comment]);
        });
      }
    });
  }

  /**
   * save the comment to pp (not use ngModel due to not display in textarea)
   * @param {string} comment
   */
  public createComment(comment: string): void {
    if (comment) {
      const commentObj = this._commentService.getNewEntity({
        parent_id: this.entity._id,
        created_by: this.user,
        text: comment,
        context: this._routerUrl
      });
      this._commentService.save(commentObj).then(() => {
        this.commentVal = '';
        this.commentList.push(commentObj);
      }).catch((error) => {
        console.error('WorkbookSlideMenuComponent.createComment save error', [error, commentObj]);
      });
    }
  }

}
