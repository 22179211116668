import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, EntitySimpleListComponent} from 'app/shared';
import {LdcmService, EntityLdcm} from 'app/workbook-core';

@Component({
  selector: 'app-repository-ldcm-list',
  templateUrl: './ldcmList.component.html',
  styleUrls: ['./ldcmList.component.scss']
})

export class LdcmListComponent extends EntitySimpleListComponent {
  entityList: EntityLdcm[];

  // rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette mesure ?';
  // addConfirmMessage = 'Nouvelle mesure créée';

  groupList: string[] = [];
  groups: {[id: string]: string} = {};
  selectedGroup: string = '';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: LdcmService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this.groupList = LdcmService.LDCM_GROUP;
    this.groupList.forEach((g) => {
      this.groups[g] = LdcmService.LDCM_LABELS[g];
    });

    this.filter(this.value);
  }


  addElement() {
    if (this.value && !this.valueExists && this.selectedGroup) {
      const newEntity = this._entityService.getNewEntity({name: this.value, group: this.selectedGroup});
      this._entityService.save(newEntity).then(() => {
        this.updateElement(newEntity);
        //   this.snackBar('La mesure a été ajoutée');
      });
      this.value = '';
    }
  }
  /**
   * Filter measure
   * @param value
   */
  filter(value: string = '') {
    this.entityList.sort((a, b) => {
      return a.group && this.groups[a.group]
        ? b.group && this.groups[b.group]
          ? this.groups[a.group].localeCompare(this.groups[b.group]) === 0
            ? a.name.localeCompare(b.name)
            : this.groups[a.group].localeCompare(this.groups[b.group])
          : -1
        : 1
    });
    this.valueExists = false;
    if (value) {
      value = value.toLowerCase();
      let i = 0;
      this.listHidden = this.entityList.map((e) => {
        const name = e.name.toLowerCase();
        const sameRisk = !this.selectedGroup || this.selectedGroup === e.group;
        this.valueExists = this.valueExists
          || (value === name && this.selectedGroup === e.group);
        i++;
        return (!sameRisk || name.indexOf(value) === -1) ? 0 : i;
      });
    } else {
      if (this.selectedGroup) {
        this.listHidden = this.entityList.map((e, index) => (this.selectedGroup && this.selectedGroup !== e.group) ? 0 : index + 1);
      } else {
        this.listHidden = [];
      }
    }
  }
  // TODO : test in v1.1 optimization ngFor with trackBy
  public trackByFn(index, item: EntityLdcm) {
    if (!item) {return null;}
    return item._id;
  }
}
