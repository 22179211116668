import {Entity, ISignature} from 'app/shared';

export class EntitySignature extends Entity implements ISignature {
  public contact_id: string;
  public user_id: string;
  public name: string;
  public email: string;
  public job: string;
  public company_id: string;
  public company: string;
  public companyType: string;
  public data: string;
  public signedAt: Date;
  public display: boolean;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.contact_id = data && data.contact_id ? data.contact_id : '';
    this.user_id = data && data.user_id ? data.user_id : '';
    this.name = data && data.name ? data.name : '';
    this.email = data && data.email ? data.email : '';
    this.job = data && data.job ? data.job : '';
    this.company_id = data && data.company_id ? data.company_id : '';
    this.company = data && data.company ? data.company : '';
    this.companyType = data && data.companyType ? data.companyType : '';
    this.data = data && data.data ? data.data : '';
    this.signedAt = data && data.signedAt ? data.signedAt : null;
    this.display = data && data.display;
  }
}
