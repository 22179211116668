import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {EntityService} from 'app/shared/services/entity.service';

@Injectable()
export class EntityListResolver implements Resolve<Observable<any[]>> {

  constructor(protected _entityService: EntityService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._entityService.getAll();
  }
}
