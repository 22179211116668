import {Entity} from './entity.model';

export class EntityLog extends Entity {
  public host: string;
  public user: string;
  public date: Date;
  public status: number;
  public errorMsg: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.host = data.host || window.location.origin;
    this.user = data.user;
    this.date = data.date ? new Date(data.date) : new Date();
    this.status = data.status;
    this.errorMsg = data.errorMsg;
    this.documentType = 'log';
  }
}



