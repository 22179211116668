import { Component, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UUID } from 'angular2-uuid';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  promiseAll,
  LoggerService,
  EmailService, EntityEmailMessages, EmailMessages, EmailDatasModel, EmailMessagesService,
  EntityEditDialogComponent,
  DocumentMetadataService, EntityDocumentMetadata, EntityDocumentFile, DocumentStatus, IDocumentCategory,
  Contact, DocumentsUploadComponent
} from 'app/shared';
import {
  EntityOffice, OfficeService, ExternalCompanyService
} from 'app/workbook-core';
@Component({
  selector: 'app-documentsUpload-dialog',
  templateUrl: './documentsUpload-dialog.component.html',
  styleUrls: ['../../shared/components/document/documentMetadata-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class DocumentsUploadDialogComponent extends EntityEditDialogComponent {
  workbook;
  entityForm: DocumentsUploadComponent;
  entity: EntityDocumentMetadata;
  //office ou eex
  entity_id: string;
  categoryList: IDocumentCategory[] = [];
  requestList: Contact[] = [];
  notificationList: Contact[] = [];
  context: string;
  showEex: boolean;
  multiple: boolean;
  selectedEex: EntityOffice;
  eexList: EntityOffice[];
  eexToSave: EntityOffice;
  showDefault: boolean = false;

  constructor(
    protected _officeService: OfficeService,
    protected _externalCompanyService: ExternalCompanyService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity_id']) {
        this.entity_id = data['entity_id'];
      }
      if (data['parent_id']) {
        this.parent_id = data['parent_id'];
      }
      if (data['entity']) {
        this.entity = data['entity'];
      }
      if (data["categoryList"]) {
        this.categoryList = data["categoryList"];
      }
      if (data["context"]) {
        this.context = data["context"];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
        if (!this.parent_id) {
          this.parent_id = this.workbook._id;
        }
      }

      this.showEex = !!data["showEex"];
      this.multiple = !!data["multiple"];
      this.showDefault = !!data["showDefault"];

      if (data["selectedEex"]) {
        this.selectedEex = data["selectedEex"];
      }
      if (data['eexList']) {
        this.eexList = data['eexList'];
      }

    }
  }
  save() {
    if (this.entityForm && this.entityForm.uploads && this.entityForm.uploads.length) {
      this._spinnerService.show();
      const filesSaver = this.entityForm.uploads.map((upld) => (
        this.entityForm.uploadFile(upld.file).toPromise().then((docfile: EntityDocumentFile) => {
          upld.entity.data_id = docfile._id;
          //TODO not eex
          if (this.entity_id) {
            return this._entityService.beforeSave(upld.entity);
          }
        })
      ));
      promiseAll(filesSaver, 1).then((metas: EntityDocumentMetadata[]) => {
        if (this.selectedEex) {
          const oeexService = (this.selectedEex.documentType === 'repo_office') ? this._officeService : this._externalCompanyService;
          const elementsToAdd: EntityDocumentMetadata[] = metas.map((e) => {
            e.parent_id = '';
            e._id = UUID.UUID();
            return this._entityService.getNewEntity(this._entityService.beforeSave(e));
          });
          oeexService.getCurrentOne(this.selectedEex._id).then((eexToSave: EntityOffice) => {
            eexToSave.documents.push(...elementsToAdd);
            oeexService.save(eexToSave);
            this._spinnerService.hide();
            this.dialogRef.close(eexToSave);
          });
        } else {
          const metasSaver = metas.map((meta) => (this._entityService.save(meta)));
          promiseAll(metasSaver).then((res) => {
            this._spinnerService.hide();
            this.dialogRef.close();
          });
        }
      });
    }
  }
}