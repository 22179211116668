import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityOrganization} from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends EntitySecureService {
  static LABEL_ONE = "chargement du type de structure";
  static LABEL_ALL = "chargement des types de structure";
  static LABEL_CHILDREN = "chargement des types de structure de l'opération";
  static LABEL_SAVE = "sauvegarde du type de structure";
  static LABEL_DELETE = "suppression du type de structure";

  static ENTITY_TYPE: string = 'repo_organization';
  static ENTITY_FIELDS: string[] = ['name'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['editOrganization'],
    CREATE: ['createOrganization'],
    UPDATE: ['editOrganization'],
    DELETE: ['removeOrganization']
  }

  static ENTITY_URL = 'repository/organization';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityOrganization {
    return new EntityOrganization(data, clone);
  }

}
