import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule, APP_INITIALIZER, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler} from '@angular/core';

// Material
import {DateAdapter, MatPaginatorIntl} from '@angular/material';

import {CustomDateAdapter, CustomMatPaginatorIntl} from './shared';

// for i18n pipes
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

// Gesture Support
import 'hammerjs';

import {routing} from './app-routing.module';
// import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {
  Auth0ConfigService,
  AuthService,
  // configServiceFactory,
  SharedModule,
  // TokenInterceptor,
  ErrorsHandler,
  LoggerService,
  ConsoleLoggerService,
  PermissionsService
} from 'app/shared';

//import {InitRepositoryService} from 'app/shared/db/initRepository.service';

// Modules & root component
import {AppComponent} from './app.component';
import {ProfileComponent} from './profile';
import {RepositoryModule} from './repository';
import {ConfigModule} from './config';
import {WorkbooksModule} from './workbooks';
import {PPModule} from './preventionPlan';
import {SignablesModule} from './signables';

// loading spinner
import {Ng4LoadingSpinnerModule} from 'ng4-loading-spinner';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFilePdf,
  faFileImage,
  faFileAlt,
  faFolder,
  faCog,
  faFileContract,
  faFileInvoice,
  faFile,
  faHardHat,
  faCalendarCheck,
  faPaperclip,
  faBan,
  faUser,
  //faUserMd,
  faUniversalAccess,
  faMedkit,
  faFire,
  faIdCard,
  faShareAlt,
  faLink,
  faExclamationTriangle,
  faChevronDown,
  faBuilding,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';


// Add an icon to the library for convenient access in other components
library.add(faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFileImage, faFileAlt, faFileContract, faFileInvoice, faFile, faPaperclip, faHardHat, faCalendarCheck, faBan, faUser, faUniversalAccess, faMedkit, faFire, faIdCard, faShareAlt, faLink, faExclamationTriangle, faChevronDown, faBuilding, faInfoCircle);
export function loadServiceFactory(auth0ConfigService: Auth0ConfigService, authService: AuthService): Function {
  return () => auth0ConfigService.load().then(
    (conf) => authService.load(conf)
    // TODO: then dbConfig.load()
  );
}
@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SharedModule,
    routing,
    WorkbooksModule, SignablesModule,
    PPModule,
    RepositoryModule,
    ConfigModule,
    Ng4LoadingSpinnerModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // TODO: comment with get user list tests (conflict possible with header)
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: loadServiceFactory,
      deps: [Auth0ConfigService, AuthService],
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    {provide: LoggerService, useClass: ConsoleLoggerService},
    {provide: ErrorHandler, useClass: ErrorsHandler},
    //{provide: InitRepositoryService, useClass: InitRepositoryService},
    {provide: PermissionsService, useClass: PermissionsService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
