import {EntityAnalysable} from '../../entityAnalysable.model';

export class EntityActivity extends EntityAnalysable {

  public group: string;
  public comments: string;
  public smr: boolean;
  public firePermit: boolean;

  updateFrom(data: any = {}) {
    super.updateFrom(data);
    this.group = (data && data.group) ? data.group : '';
    this.comments = (data && data.comments) ? data.comments : '';
    this.smr = (data && data.smr);
    this.firePermit = (data && data.firePermit);
  }

  update_risks_situations() {
    super.update_risks_situations();
    this.situations.forEach((s) => {
      if (s.sir && !this.smr) {
        this.smr = true;
      }
      if (s.fp && !this.firePermit) {
        this.firePermit = true;
      }
    });
  }
}
