import {UUID} from 'angular2-uuid';

export class DocumentMetadata {
    public id: string;
    public data_id: string;
    public name: string;
    public displayName: string;
    public mime: string;
    public icon: string;
    public createdAt: Date = new Date();

    constructor() {
        this.id = UUID.UUID();
        this.createdAt = new Date();
    }
}

export class DocumentUploaded {
  public _id: string;
  public _rev: string;
  public documentType: string;
  public data: string;

  constructor(docData) {
    this._id = UUID.UUID();
    this.documentType = 'docUploaded';
    this.data = docData;
  }
}
