import {Injectable, OnDestroy} from '@angular/core';
import {Subscription, Observable, BehaviorSubject, forkJoin, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  EmailRecipient,
  DbFilteredService,
  PermissionsService,
  EntityCodableService, LoadingService,
  ExploitingCompanyService,
  EntityExploitingCompany,
  CommentService, EntityComment,
  DocumentService, EntityDocumentMetadata,
  Contact
} from 'app/shared';
import {
  EntityOffice,
  EntityExternalCompany,
  IcpRecurrenceService, EntityIcpRecurrence
} from '../../repository';
import {
  EntityWorkbook,
  EntityStep,
  EntitySignature,
  EntityActivity,
  EntityWorkbookMeasure,
  EntityIcp
} from '../models';

import {
  EntitySignablePreventionPlan
} from '../../preventionPlan/models';
import {WorkbookOfficeService} from './workbookOffice.service';
import {WorkbookExternalCompanyService} from './workbookExternalCompany.service';
import {ActivityService} from './activity.service';
import {WorkbookMeasureService} from './workbookMeasure.service';
import {SignatureService} from './signature.service';
import {WorkbookStepService} from './step.service';
import {IcpService} from './icp.service';
import {FirePermitService} from './firePermit.service';
import {SecurityProtocolService} from './securityProtocol.service';
import {AuditService} from './audit.service';
import {SignablePermitService} from './signablePermit.service';



@Injectable({
  providedIn: 'root'
})
export class WorkbookService extends EntityCodableService implements OnDestroy {
  static LABEL_ONE = "chargement de l'opération -";
  static LABEL_ALL = "chargement des opérations -";
  static LABEL_CHILDREN = "chargement des opérations de l'opération ? -";
  static LABEL_SAVE = "sauvegarde de l'opération -";
  static LABEL_DELETE = "suppression de l'opération -";

  static ENTITY_TYPE: string = 'pp';
  static ENTITY_FIELDS: string[] = ['type', 'simple', 'name', 'code', 'description', 'eex_name', 'eexs', 'editor', 'dateStart', 'dateEnd', 'point', 'site', 'status', 'users', 'editorId', 'offices', 'operators', 'inspections', 'pdps'];
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['createPP'],
    UPDATE: ['editPP'],
    DELETE: []
  }

  static ENTITY_URL = 'pp';
  icpRecurrence: EntityIcpRecurrence;
  _icpRecurrenceSubscription: Subscription;

  showAllDocuments: boolean = false;
  constructor(
    public icpRecurrenceService: IcpRecurrenceService,
    public exploitingCompanyService: ExploitingCompanyService,
    public stepService: WorkbookStepService,
    public officeService: WorkbookOfficeService,
    public externalCompanyService: WorkbookExternalCompanyService,
    public activityService: ActivityService,
    public measureService: WorkbookMeasureService,
    public signatureService: SignatureService,
    public commentService: CommentService,
    public icpService: IcpService,
    public firePermitService: FirePermitService,
    public securityProtocolService: SecurityProtocolService,
    public auditService: AuditService,
    public signablePermitService: SignablePermitService,
    public documentService: DocumentService,
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
    this._icpRecurrenceSubscription = this.icpRecurrenceService.getSingleOne().subscribe((data) => {
      if (data) {
        this.icpRecurrence = data;
      }
    });
  }
  ngOnDestroy() {
    if (this._icpRecurrenceSubscription) {
      this._icpRecurrenceSubscription.unsubscribe();
    }
  }
  factory(data = {}, clone = false) {
    return new EntityWorkbook(data, clone);
  }
  _getList() {
    if (this.hasPermission('showAllDocuments')) {
      this.showAllDocuments = true;
      return super._getList();
    } else {
      console.log('WorkbookService !showAllDocuments', this._permissionsService.getPermissions());
      return this._dbService.find({
        selector: {
          documentType: {$in: this._entityTypes},
          users: {$in: [this.getUserId()]} // Filter list by users id
        },
        fields: this._entityFields
      });
    }
  }
  /**
   * ??
   * @param params
   * @param userId
   */
  public isAuthorized(params, userId) {
    return true;
  }
  getUrlWorkbook(documentType: string, id: string, page: string = 'info') {
    return documentType + '/' + id + '/' + page;
  }
  getUrlWorkbookStep(documentType: string, id: string, step_id: string) {
    return documentType + '/' + id + this.stepService.getUrlEntityById(step_id);
  }

  getExploitingCompany(workbook: EntityWorkbook): Promise<EntityExploitingCompany> {
    if (workbook && workbook.status === 'Archivé' && workbook.exploitingCompany) {
      new Promise((resolve) => resolve(workbook.exploitingCompany));
    } else {
      return this.getCurrentExploitingCompany();
    }
  }
  getCurrentExploitingCompany(): Promise<EntityExploitingCompany> {
    return new Promise((resolve, reject) => {
      this.exploitingCompanyService.getSingleOne().subscribe((data) => {
        if (data) {
          resolve(data);
        }
      }, (err) => {
        reject(err);
      });
    });

  }
  /*
  getChildList(child: string, workbook_id: string): BehaviorSubject<EntityOffice[]> {
    if (this[child + 'Service']) {
      return this[child + 'Service'].getChildren(workbook_id);
    }
  }
  getUrlChild(child: string, entity: Entity): string {
    if (this[child + 'Service']) {
      return this.getUrlEntityById(entity.parent_id) + '/' + this[child + 'Service'].getUrlEntity(entity);
    }
  }
  getCurrentChildList(child: string, workbook_id: string): Promise<EntityOffice[]> {
    if (this[child + 'Service']) {
      return this[child + 'Service'].getChildList(workbook_id).toPromise();
    }
  }
*/
  getOEexSignatoryList(workbook_id: string): Observable<EntityOffice[]> {
    return this.getOEexList(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        if (_OEex) {
          return _OEex
            .map((office) => {
              office.contacts = office.contacts
                .filter((c: Contact) => (c.signatoryPdP));
              return office;
            })
            .filter((office) => (office.contacts.length > 0));
        } else {
          return [];
        }
      }));
  }
  getOfficeList(workbook_id: string): BehaviorSubject<EntityOffice[]> {
    return this.officeService.getChildren(workbook_id);
  }
  getUrlOffice(entity: EntityOffice): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.officeService.getUrlEntity(entity);
  }
  getCurrentOfficeList(workbook_id: string): Promise<EntityOffice[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getOfficeList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.officeService.getChildList(workbook_id).toPromise();
  }
  getExternalCompanyList(workbook_id: string): BehaviorSubject<EntityExternalCompany[]> {
    return this.externalCompanyService.getChildren(workbook_id);
  }
  getUrlExternalCompany(entity: EntityExternalCompany): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.externalCompanyService.getUrlEntity(entity);
  }
  getCurrentExternalCompanyList(workbook_id: string): Promise<EntityExternalCompany[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getExternalCompanyList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.externalCompanyService.getChildList(workbook_id).toPromise();
  }

  getStepList(workbook_id: string): BehaviorSubject<EntityStep[]> {
    return this.stepService.getChildren(workbook_id);
  }
  getUrlStep(entity: EntityStep): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.stepService.getUrlEntity(entity);
  }
  getCurrentStepList(workbook_id: string): Promise<EntityStep[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getStepList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
  }

  getActivityList(workbook_id: string): BehaviorSubject<EntityActivity[]> {
    return this.activityService.getChildren(workbook_id);
  }
  getUrlActivity(entity: EntityActivity): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.activityService.getUrlEntity(entity);
  }
  getCurrentActivityList(workbook_id: string): Promise<EntityActivity[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getActivityList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.activityService.getChildList(workbook_id).toPromise();
  }
  getMeasureList(workbook_id: string): BehaviorSubject<EntityWorkbookMeasure[]> {
    return this.measureService.getChildren(workbook_id);
  }
  getUrlMeasure(entity: EntityWorkbookMeasure): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.measureService.getUrlEntity(entity);
  }
  getCurrentMeasureList(workbook_id: string): Promise<EntityWorkbookMeasure[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getMeasureList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.measureService.getChildList(workbook_id).toPromise();
  }
  getSignatureList(workbook_id: string): BehaviorSubject<EntitySignature[]> {
    return this.signatureService.getChildren(workbook_id);
  }
  getUrlSignature(entity: EntitySignature): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.signatureService.getUrlEntity(entity);
  }
  getCurrentSignatureList(workbook_id: string): Promise<EntitySignature[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getSignatureList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.signatureService.getChildList(workbook_id).toPromise();
  }
  getCommentList(workbook_id: string): BehaviorSubject<EntityComment[]> {
    return this.commentService.getChildren(workbook_id);
  }
  getUrlComment(entity: EntityComment): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.commentService.getUrlEntity(entity);
  }
  getCurrentCommentList(workbook_id: string): Promise<EntityComment[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getCommentList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.commentService.getChildList(workbook_id).toPromise();
  }
  getDocumentMetaDataList(workbook_id: string): BehaviorSubject<EntityDocumentMetadata[]> {
    return this.documentService.getDocumentMetadataChildren(workbook_id);
  }
  getUrlDocumentMetadata(entity: EntityDocumentMetadata): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.documentService.documentMetadataService.getUrlEntity(entity);
  }
  getCurrentDocumentMetadataList(workbook_id: string): Promise<EntityDocumentMetadata[]> {
    return new Promise((resolve, reject) => {
      if (workbook_id) {
        this.getDocumentMetaDataList(workbook_id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.documentService.getDocumentMetadataChildList(workbook_id).toPromise();
  }
  // (no cache)
  getCurrentEmailRecipientList(workbook: EntityWorkbook): Promise<EmailRecipient[]> {
    let officeList: Observable<EmailRecipient[]> = this.officeService.getChildList(workbook._id)
      .pipe(map((offices: EntityOffice[]) => {
        const recipientList: EmailRecipient[] = [{id: '', name: workbook.editor, email: workbook.editorEmail}];
        offices.forEach((office: EntityOffice) => {
          office.contacts.forEach((contact) => {
            recipientList.push({id: contact.id, name: contact.name, email: contact.email, isOffice: true});
          });
        });
        return recipientList;
      }));
    let eexList: Observable<EmailRecipient[]> = this.externalCompanyService.getChildList(workbook._id)
      .pipe(map((offices: EntityExternalCompany[]) => {
        const recipientList: EmailRecipient[] = [];
        offices.forEach((office: EntityExternalCompany) => {
          office.contacts.forEach((contact) => {
            recipientList.push({id: contact.id, name: contact.name, email: contact.email});
          });
        });
        return recipientList;
      }));

    return forkJoin(officeList, eexList).pipe(map(([s1, s2]) => [...s1, ...s2])).toPromise();
  }
  getEmailRecipients(_OEex: EntityOffice[]): EmailRecipient[] {

    const recipientList: EmailRecipient[] = [];
    _OEex.forEach((office: EntityOffice) => {
      office.contacts.forEach((contact) => {
        const r = [];
        if (contact.signatory) {
          r.push('signatory');
        }
        if (contact.signatoryPdP) {
          r.push('signatoryPdP');
        }
        if (contact.signatoryPermit) {
          r.push('signatoryPermit');
        }
        if (contact.chsct) {
          r.push('chsct');
        }
        recipientList.push({
          id: contact.id,
          name: contact.name,
          email: contact.email,
          company: office.name,
          isOffice: (!!office.documentType && (office.documentType === 'wb_office' || office.documentType === 'repo_office')),
          job: contact.job,
          right: r,
          user_id: contact.user_id
        });
      });
    });
    return recipientList;
  }
  getEmailRecipientList(workbook_id): Observable<EmailRecipient[]> {
    return this.getOEexList(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        return this.getEmailRecipients(_OEex);
      }));
  }
  // liste complete des offices et eex triée (from cache)
  getAllChildrenOEexList(): Observable<any[]> {
    return combineLatest(
      this.officeService.getAllChildrenRemote(),
      this.externalCompanyService.getAllChildrenRemote())
      .pipe(map(([s1, s2]) => {
        if (!s1 || !s2) {
          return null;
        } else {
          //console.log('getAllChildrenOEexList: ' + s1.length + ' offices / ' + s2.length + ' eexs');
          return [...s1, ...s2];
        }
      }));
  }

  // liste complete des offices et eex triée (from cache)
  getOEexList(workbook_id): Observable<any[]> {
    return combineLatest(
      this.getOfficeList(workbook_id),
      this.getExternalCompanyList(workbook_id))
      .pipe(map(([s1, s2]) => {
        if (!s1 || !s2) {
          return null;
        } else {
          // console.log('getOEexList', [s1, s2]);
          return [...s1.sort((a, b) => a.name.localeCompare(b.name)), ...s2.sort((a, b) => a.name.localeCompare(b.name))];
        }
      }));
  }

  // liste complete des offices et eex triée (no cache)
  getCurrentOEex(workbook_id) {
    return this.getOEex(workbook_id).toPromise();
  }
  // liste complete des offices et eex triée(no cache)
  getOEex(workbook_id) {
    return forkJoin(
      this.officeService.getChildList(workbook_id),
      this.externalCompanyService.getChildList(workbook_id))
      .pipe(map(([s1, s2]) => {
        if (!s1) s1 = [];
        if (!s2) s2 = [];
        return [...s1.sort((a, b) => a.name.localeCompare(b.name)), ...s2.sort((a, b) => a.name.localeCompare(b.name))];
      }));
  }
  public getUpdatedIPOEex(workbook_id, iPOEex: EntityOffice[]) {
    return this.getOEex(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        const updatedIPOEex: EntityOffice[] = [];
        _OEex.forEach((office) => {
          // mise à jour des offices d'iPOffice en gardant les contacts
          let iPOffice: EntityOffice = iPOEex.find((o) => (o._id === office._id && o.contacts.length > 0));
          if (iPOffice) {
            // on garde les contact (triés)
            const contacts = iPOffice.contacts.sort((a, b) => a.name.localeCompare(b.name));
            // on met à jour les attributs d'iPOffice à partir de pPOffice
            iPOffice = Object.assign(iPOffice, office);
            iPOffice.contacts = contacts;
            updatedIPOEex.push(iPOffice);
          }
        });
        return updatedIPOEex;
      })).toPromise();
  }
  createSignature(contact: Contact, office: EntityOffice): EntitySignature {
    return this.signatureService.getNewEntity({
      parent_id: office.parent_id,
      company_id: office._id,
      company: office.name,
      companyType: office.documentType === this.externalCompanyService.getEntityType() ? 'EEX' : 'EE',
      contact_id: contact.id,
      email: contact.email,
      name: contact.name,
      job: contact.job,
      user_id: contact.user_id
    });
  }
  public getCurrentOEexSignatureList(workbook_id: string) {
    return this.getOEex(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        const list: EntitySignature[] = [];
        _OEex
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach((office) => {
            office.contacts
              .filter((c: Contact) => (c.signatoryPdP))
              .sort((a, b) => a.name.localeCompare(b.name))
              .forEach((contact: Contact) => {
                list.push(this.createSignature(contact, office));
              });
          });
        return list;
      })).toPromise();
  }
  public getOEexSignatureList(workbook_id: string): Observable<EntitySignature[]> {
    return this.getOEexList(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        const list: EntitySignature[] = [];
        _OEex
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach((office) => {
            office.contacts
              .filter((c: Contact) => (c.signatoryPdP))
              .sort((a, b) => a.name.localeCompare(b.name))
              .forEach((contact: Contact) => {
                list.push(this.createSignature(contact, office));
              });
          });
        return list;
      }));
  }
  public getOfficeSignatureList(workbook_id: string) {
    return this.getOfficeList(workbook_id)
      .pipe(map((_OEex: EntityOffice[]) => {
        const list: EntitySignature[] = [];
        _OEex
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach((office) => {
            office.contacts
              .filter((c: Contact) => (c.signatoryPdP))
              .sort((a, b) => a.name.localeCompare(b.name))
              .forEach((contact: Contact) => {
                list.push(this.createSignature(contact, office));
              });
          });
        return list;
      }));
  }
  mergeSignatures(oeexSignatures: EntitySignature[], signatures: EntitySignature[]): EntitySignature[] {
    const returned = oeexSignatures.filter((e) => !e._deleted);
    signatures.forEach((s) => {
      const i = returned.findIndex((e) => (e.contact_id === s.contact_id));
      if (i === -1) {
        returned.push(s);
      } else {
        returned[i] = s;
      }
    })
    return returned;
  }
  public getCurrentSignatures(workbook_id: string): Promise<EntitySignature[]> {
    return forkJoin(
      this.getCurrentOEexSignatureList(workbook_id),
      this.getCurrentSignatureList(workbook_id))
      .pipe(map(([s1, s2]) => {
        if (!s1) s1 = [];
        if (!s2) s2 = [];
        return this.mergeSignatures(s1, s2);
      }
      )).toPromise();
  }
  public getSignatures(workbook_id: string): Observable<EntitySignature[]> {
    return forkJoin(
      this.getOEexSignatureList(workbook_id),
      this.getSignatureList(workbook_id))
      .pipe(map(([s1, s2]) => {
        if (!s1) s1 = [];
        if (!s2) s2 = [];
        return this.mergeSignatures(s1, s2);
      }
      ));
  }
  public changeStatus(workbook_id: string, status: string = ''): Promise<EntityWorkbook> {
    return new Promise((resolve, reject) => {
      this.get(workbook_id).toPromise().then((wb: EntityWorkbook) => {
        //  console.log('before status save', wb)
        wb.status = status;
        return this.save(wb).then((e) => {
          //  console.log('after status save', e)
          resolve(wb);
        }).catch(err => reject(err));
      }).catch(err => reject(err));
    });
  }
  getSignAgreement(entity: EntityWorkbook): Promise<string> {
    return new Promise((resolve) => {
      resolve(entity && entity.signAgreement ? entity.signAgreement : '');
    });
  }

  getIcpList(id: string): BehaviorSubject<EntityIcp[]> {
    return this.icpService.getChildren(id);
  }

  getUrlIcp(documentType: string, id: string, icp_id: string) {
    return documentType + '/' + id + '/icp' + (icp_id ? ('/' + icp_id) : '');
  }
  /*
  getUrlIcp(entity: EntityIcp): string {
    return this.getUrlEntityById(entity.parent_id) + '/' + this.icpService.getUrlEntity(entity);
  }
*/
  getCurrentIcpList(entity: EntityWorkbook): Promise<EntityIcp[]> {
    return new Promise((resolve, reject) => {
      if (entity && entity._id) {
        this.icpService.getChildren(entity._id).subscribe((list) => {
          if (list) {
            resolve(list);
          }
        }, (err) => {
          reject(err);
        });
      } else {
        reject(null);
      }
    });
    //return this.icpService.getChildList(entity._id).toPromise();
  }


  getUrlFirePermit(documentType: string, id: string, fp_id: string) {
    return documentType + '/' + id + '/fp/' + fp_id;
  }

  //TODO : gérer suppression step icpinfo
  updateWorkbookInspectionStep(
    workbook: EntityWorkbook,
    //saved step
    step: EntityStep) {
    if (step && workbook && workbook.updateStep(step)) {
      this.save(workbook)
    }
  }

  //TODO : gérer suppression step icpinfo
  updateWorkbookInspection(
    workbook: EntityWorkbook,
    icp: EntityIcp,
    allSteps: EntityStep[]) {
    if (!(workbook.type === 'annual' && workbook.simple)
      && workbook.updateIcp(icp, workbook.updateSteps(allSteps))) {
      //TODO get avant ?
      this.save(workbook);
    }
  }

  updateWorkbookPdP(
    workbook: EntityWorkbook,
    pdp: EntitySignablePreventionPlan,
    allSteps: EntityStep[]) {
    if (!(workbook.type === 'annual' && workbook.simple)
      && workbook.updatePdp(pdp, workbook.updateSteps(allSteps))) {
      //TODO get avant ?
      this.save(workbook);
    }
  }
  updateNewWorkbook(
    workbook: EntityWorkbook,
    allSteps: EntityStep[]) {
  }
  updateStepActivities(step_id: string, activities: string[]): Promise<any> {
    return this.stepService.setActivities(step_id, activities);
  }
}

