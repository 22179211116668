import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';

import {LoggerService} from 'app/shared/logger';
import {UploadService} from 'app/shared/upload';

import {CommonComponent} from '../common.component';
import {UploadDialogComponent} from './dialog/upload-dialog.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends CommonComponent {
  constructor(
    public dialog: MatDialog,
    protected uploadService: UploadService,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }

  public openUploadDialog() {
    this.dialog.open(UploadDialogComponent, {minWidth: '50%', height: '50%'});
  }

}
