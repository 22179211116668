import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, Contact } from 'app/shared';
import { PreventionPlanService, EntityIcp } from 'app/workbook-core';

@Component({
  selector: 'app-reconvene-dialog',
  templateUrl: './reconvene-dialog.component.html',
  styleUrls: ['./reconvene-dialog.component.scss']
})
export class ReconveneDialogComponent {
  reply_emails: string[];
  from_names: string[];
  from_name: string;
  reply_email: string;
  subject: string;
  text: string;
  icp: EntityIcp;
  OEex: any[] = [];
  currentOEex: any[] = [];
  contactIdInICP: string[] = [];
  contactsSelectedList: Contact[] = [];
  contactChecked: { [contact_id: string]: boolean } = {};
  oeexSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ReconveneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    protected _workbookService: PreventionPlanService) {
    this.reply_emails = this.data['reply_emails'];
    this.from_names = this.data['from_names'];
    this.from_name = this.data['from_name'];
    this.reply_email = this.data['reply_email'];
    this.subject = this.data['subject'];
    this.text = this.data['text'];
    this.icp = this.data['icp'];
    this.contactChecked = {};
    if (this.data['selectedContacts']) {
      this.data['selectedContacts'].forEach((c_id) => {
        this.contactChecked[c_id] = true;
      });
    }
    if (this.icp.iPOEex && this.icp.iPOEex.length) {
      this.icp.iPOEex.forEach((o) => {
        if (o.contacts && o.contacts) {
          o.contacts.forEach((c) => {
            if (c.id && c.name) {
              if (!this.data['selectedContacts'] && !c.isConvened) {
                this.contactChecked[c.id] = true;
              }
              if (this.contactChecked[c.id]) {
                this.contactsSelectedList.push(c);
              }
            }
          });
        }
      });
    }

  }


  /**
   * Add / remove contact in list
   * @param event
   */
  onSelectContacts(event) {
    if (event.option.selected) {
      this.contactsSelectedList.push(event.option.value);
      this.contactChecked[event.option.value.id] = true;
    } else {
      const removeIndex = this.contactsSelectedList.findIndex(icp => icp.id === event.option.value.id);
      this.contactsSelectedList.splice(removeIndex, 1);
      this.contactChecked[event.option.value.id] = false;
    }
  }

  onSubmit(form) {

    this.dialogRef.close({
      from_name: this.from_name,
      reply_email: this.reply_email,
      subject: this.subject,
      text: this.text,
      contacts: this.contactsSelectedList
    });
  }

}
