import {Entity} from 'app/shared';

export class EntityDefaultVisibility extends Entity {
  public static ALL: string = '*';
  public static TYPES: string[] = ['occasional', 'annual', 'protocol'];
  public occasional: string;
  public annual: string;
  public protocol: string;


  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.occasional = (data && data.occasional) ? data.occasional : '';
    this.annual = (data && data.annual) ? data.annual : '';
    this.protocol = (data && data.protocol) ? data.protocol : '';
  }
  getVisibility(type: string) {
    if (type && EntityDefaultVisibility.TYPES.indexOf(type) !== -1) {
      return this[type];
    }
    return '';
  }
}
