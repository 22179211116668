import {ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, EntitySignable} from 'app/shared';
import {PreventionPlanService} from 'app/workbook-core';
import {WorkbookChildListComponent} from './abstractWorkbookChildList.component';

export abstract class WorkbookSignableListComponent extends WorkbookChildListComponent {

  entityList: EntitySignable[];

  dataSource: MatTableDataSource<EntitySignable> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];


  protected constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }


  protected _getDataSource() {
    const ds = new MatTableDataSource(this._getDataSourceContent());

    if (ds) {
      if (this.paginator) {
        ds.paginator = this.paginator;
      }
      if (this.sort) {
        ds.sort = this.sort;
      }
    }
    ds.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    return ds;
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && (o[p] && (o[p] instanceof Date)) ? o[p].getTime() : ((typeof o[p] === 'string') ? o[p].toLowerCase() : o[p]), obj)
  )
  refreshDataSource() {
    if (this.entityList) {
      this.dataSource = this._getDataSource();
    }
  }
  protected _getDataSourceContent() {
    this.refreshStates();
    return this.entityList;

  }

  abstract refreshStates();

}
