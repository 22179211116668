import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, EntityListComponent } from 'app/shared';
import { DocumentsRequestService, EntityDocumentsRequest, CategoryService, EntityCategory } from 'app/workbook-core';

@Component({
  selector: 'app-repository-documentsRequest-list',
  templateUrl: './documentsRequestList.component.html',
  styleUrls: ['./documentsRequestList.component.scss']
})

export class DocumentsRequestListComponent extends EntityListComponent {

  entityList: EntityDocumentsRequest[];
  dataSource: MatTableDataSource<EntityDocumentsRequest>;
  displayedColumns: string[] = ['name', 'documents', 'delete'];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette demande de documents ?';
  addConfirmMessage = 'Nouvelle demande de documents créée';


  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  constructor(
    protected _categoryService: CategoryService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: DocumentsRequestService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
      if (list) {
        this.categoryList = list;
      }
    });
  }


  addElement() {
    const newEntity = this._entityService.getNewEntity({ name: 'Nouvelle demande' });
    this._entityService.save(newEntity).then(() => {
      this.updateElement(newEntity);
      //   this.snackBar('La mesure a été ajoutée');
    });
  }
}
