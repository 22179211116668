import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/shared';
import {LogsService, RoleService, EmailMessagesService, ExploitingCompanyService, ConfigEmailService, PinturaConfigService, ExtraConfigService} from 'app/shared/services';
import {ConfigService} from './config.service';
import {ConfigComponent} from './config.component';
import {PinturaConfigEditComponent} from './pinturaConfig';
import {ExtraConfigEditComponent} from './extraConfig';
import {LogListComponent} from './logs';
import {RoleListComponent} from './role';
import {UsersComponent} from './user';
import {EmailEditComponent, RgpdEditComponent, RestrictionEditComponent} from './email';
import {ExploitingCompanyEditComponent} from './exploitingCompany';

const routes: Routes = [
  // ExploitingCompany
  {
    path: ExploitingCompanyService.ENTITY_URL,
    component: ExploitingCompanyEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ExploitingCompanyService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 1,
        title: 'Mon entreprise',
        icon: 'business',
        orderGroup: 1,
        group: 'Mon entreprise'
      }
    }
  },
  // users
  {
    path: 'config/user',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUsers'],
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 2,
        title: 'Utilisateurs',
        icon: 'business',
        orderGroup: 1,
        group: 'Mon entreprise'
      }
    }
  },
  // email
  {
    path: EmailMessagesService.ENTITY_URL,
    component: EmailEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: EmailMessagesService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 1,
        title: 'Emails',
        icon: 'business',
        orderGroup: 2,
        group: 'Mon entreprise'
      }
    }
  },
  // email
  {
    path: EmailMessagesService.RGPD_URL,
    component: RgpdEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: EmailMessagesService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 2,
        title: 'Données personnelles',
        icon: 'business',
        orderGroup: 1,
        group: 'Mon entreprise'
      }
    }
  },
  // configs
  {
    path: ConfigService.ENTITY_URL,
    component: ConfigComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ConfigService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 3,
        title: 'Paramètres',
        icon: 'candlestick_chart',
        orderGroup: 2,
        group: 'Configuration'
      }
    }
  },

  // logs
  {
    path: LogsService.ENTITY_URL,
    component: LogListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: LogsService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 4,
        title: 'Log',
        icon: 'candlestick_chart',
        orderGroup: 2,
        group: 'Configuration'
      }
    }
  },
  // logs
  {
    path: PinturaConfigService.ENTITY_URL,
    component: PinturaConfigEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: PinturaConfigService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 5,
        title: 'Pintura',
        icon: 'candlestick_chart',
        orderGroup: 2,
        group: 'Configuration'
      }
    }
  },
  // extras
  {
    path: ExtraConfigService.ENTITY_URL,
    component: ExtraConfigEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ExtraConfigService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 6,
        title: 'Champs spécifiques',
        orderGroup: 2,
        group: 'Configuration'
      }
    }
  },
  // roles
  {
    path: RoleService.ENTITY_URL,
    component: RoleListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: RoleService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 2,
        title: 'Rôles',
        icon: 'edit_attributes',
        orderGroup: 3,
        group: 'Permissions'
      }
    }
  },
  // email
  {
    path: ConfigEmailService.ENTITY_URL,
    component: RestrictionEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: EmailMessagesService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavconfig: {
        order: 2,
        title: 'Domaines autorisés',
        icon: 'edit_attributes',
        orderGroup: 3,
        group: 'Permissions'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ConfigRoutingModule {
}

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
