import {Component, } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AuthService, LoggerService} from 'app/shared';
import {
  EntitySite, EntityOffice,
  OfficeService,
  SiteService,
  EntityPreventionPlan,
  PreventionPlanService
} from 'app/workbook-core';
import {WorkbookComponent} from 'app/workbook-shared';
import {PreventionPlanInfosDialogComponent} from './preventionPlanEdit'

@Component({
  selector: 'app-prevention-plan',
  templateUrl: './preventionPlan.component.html',
  styleUrls: ['./preventionPlan.component.scss']
})

export class PreventionPlanComponent extends WorkbookComponent {
  autoSave = true;
  entity: EntityPreventionPlan;
  userOffices: EntityOffice[] = [];
  filteredSites: EntitySite[];
  today: Date;
  isLoading: boolean = false;

  constructor(
    protected _router: Router,
    public snackBar: MatSnackBar,
    protected _auth: AuthService,
    protected _siteService: SiteService,
    protected _officeService: OfficeService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(snackBar, _auth, _siteService, _officeService, dialog, _logger, _entityService);
  }

  openDialog() {
    const dialogRef = this.dialog.open(PreventionPlanInfosDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        id: this.entity._id,
        parent_id: this.entity.parent_id
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.onSave.emit(this.entity);
      }
    });
  }
  setWithSteps() {
    if (this.entity) {
      this.entity.simple = false;
      this._save().then((e) => {
        this._router.navigateByUrl(this._entityService.getUrlWorkbook('pp', this.entity._id, 'steps'));
      });
    }
  }

}
