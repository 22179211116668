import { UUID } from 'angular2-uuid';

export class Contact {
  constructor(
    public id: string = UUID.UUID(),
    public user_id: string = '',
    public name: string = '',
    public email: string = '',
    public phone: string = '',
    public fax: string = '',
    public job: string = '',
    public isPresent: boolean = false,
    public isConvened: boolean = false,
    public signatory: boolean = false,
    public signatoryPdP: boolean = false,
    public signatoryPermit: boolean = false,
    public chsct: boolean = false,
    public repo_id: string = '',
    public isDefault: boolean = false
  ) { }
}
