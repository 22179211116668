import {UUID} from 'angular2-uuid';

export class ExploitingCompanyModel {
  constructor(
    public id: string = UUID.UUID(),
    public name: string = '',
    public address: string = '',
    public zipCode: string = '',
    public city: string = '',
    public logo: string = '',
    // champs pour pp
    public management: string = '',
    public service: string = '',
    public responsibleName: string = '',
    public responsibleJob: string = '',
    public responsibleEmail: string = '',
    public responsiblePhone: string = '',
    public responsibleFax: string = '') {

  }
}
