import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, LoadingService} from 'app/shared';
import {EntityExternalCompany} from '../models';
import {OfficeService} from './office.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalCompanyService extends OfficeService {
  autoInit: boolean = false;
  static LABEL_ONE = "chargement de l'entreprise";
  static LABEL_ALL = "chargement des entreprises";
  static LABEL_CHILDREN = "chargement des entreprises de l'opération ?";
  static LABEL_SAVE = "sauvegarde de l'entreprise";
  static LABEL_DELETE = "suppression de l'entreprise";

  static ENTITY_TYPE: string = 'repo_eex';
  static ENTITY_FIELDS: string[] = ['name', 'job', 'siret', 'internalCode', 'address', 'zipCode', 'city', 'contacts', 'operators', 'documents'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showEEX'],
    CREATE: ['createEEX'],
    UPDATE: ['editEEX'],
    DELETE: ['removeEEX']
  }

  static ENTITY_URL = 'repository/externalCompany';
  // TODO / Pour memoire : fonctionne pour routing, acceder à la static héritée non
  static ENTITY_URL_EDIT = ':id';
  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityExternalCompany {
    return new EntityExternalCompany(data, clone);
  }

}
