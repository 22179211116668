import { Component, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UUID } from 'angular2-uuid';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  promiseAll,
  LoggerService,
  EmailService, EntityEmailMessages, EmailMessages, EmailDatasModel, EmailMessagesService,
  EntityEditDialogComponent,
  DocumentMetadataService, EntityDocumentMetadata, EntityDocumentFile, DocumentStatus, IDocumentCategory,
  Contact, DocumentsUploadComponent
} from 'app/shared';
import {
  EntityOffice, WorkbookOfficeService, WorkbookExternalCompanyService
} from 'app/workbook-core';
@Component({
  selector: 'app-workbookDocumentsUpload-dialog',
  templateUrl: './documentsUpload-dialog.component.html',
  styleUrls: ['../../shared/components/document/documentMetadata-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookDocumentsUploadDialogComponent extends EntityEditDialogComponent {
  workbook;
  entityForm: DocumentsUploadComponent;
  entity: EntityDocumentMetadata;
  categoryList: IDocumentCategory[] = [];
  requestList: Contact[] = [];
  notificationList: Contact[] = [];
  context: string;
  showEex: boolean;
  multiple: boolean;
  selectedEex: EntityOffice;
  eexList: EntityOffice[];
  eexToSave: EntityOffice;
  uploadCounter: number = 0;
  uploadedCount: number = 0;
  constructor(
    protected _workbookOfficeService: WorkbookOfficeService,
    protected _workbookExternalCompanyService: WorkbookExternalCompanyService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['parent_id']) {
        this.parent_id = data['parent_id'];
      }
      if (data['entity']) {
        this.entity = data['entity'];
      }
      if (data["categoryList"]) {
        this.categoryList = data["categoryList"];
      }
      if (data["context"]) {
        this.context = data["context"];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
        if (!this.parent_id) {
          this.parent_id = this.workbook._id;
        }
      }

      this.showEex = !!data["showEex"];
      this.multiple = !!data["multiple"];

      if (data["selectedEex"]) {
        this.selectedEex = data["selectedEex"];
      }
      if (data['eexList']) {
        this.eexList = data['eexList'];
      }

    }
  }
  save() {
    if (this.entityForm && this.entityForm.uploads && this.entityForm.uploads.length) {
      this._spinnerService.show();
      this.uploadCounter = this.entityForm.uploads.length;
      this.uploadedCount = 0;
      this.uploadFiles().then((metas: EntityDocumentMetadata[]) => {
        if (this.selectedEex) {
          const oeexService = (this.selectedEex.documentType === 'wb_office') ? this._workbookOfficeService : this._workbookExternalCompanyService;
          const elementsToAdd: EntityDocumentMetadata[] = metas.map((e) => {
            e.parent_id = '';
            e._id = UUID.UUID();
            return this._entityService.getNewEntity(this._entityService.beforeSave(e));
          });
          oeexService.getCurrentOne(this.selectedEex._id).then((eexToSave: EntityOffice) => {
            eexToSave.documents.push(...elementsToAdd);
            oeexService.save(eexToSave);
            this.uploadCounter = 0;
            this.uploadedCount = 0;
            this._spinnerService.hide();
            this.dialogRef.close(eexToSave);
          });
        } else {
          const metasSaver = metas.map((meta) => (this._entityService.save(meta)));
          promiseAll(metasSaver).then((res) => {
            this.uploadCounter = 0;
            this.uploadedCount = 0;
            this._spinnerService.hide();
            this.dialogRef.close();
          }).catch((err) => {
            console.log('Error saving metas', err);
            this._spinnerService.hide();
          });
        }
      }).catch((err) => {
        console.log('Error saving files', err);
        this._spinnerService.hide();
      });


      /*
      const filesSaver = this.entityForm.uploads.map((upld) => (
        //this.entityForm.uploadFile(upld.file).toPromise().then((docfile: EntityDocumentFile) => {
        this.entityForm.uploadFilePromise(upld.file).then((docfile: EntityDocumentFile) => {
          this.uploadedCount++;
          upld.entity.data_id = docfile._id;
          //TODO not eex
          if (this.parent_id) {
            return this._entityService.beforeSave(upld.entity);
          }
        })
      ));

        promiseAll(filesSaver, 1).then((metas: EntityDocumentMetadata[]) => {
          if (this.selectedEex) {
            const oeexService = (this.selectedEex.documentType === 'wb_office') ? this._workbookOfficeService : this._workbookExternalCompanyService;
            const elementsToAdd: EntityDocumentMetadata[] = metas.map((e) => {
              e.parent_id = '';
              e._id = UUID.UUID();
              return this._entityService.getNewEntity(this._entityService.beforeSave(e));
            });
            oeexService.getCurrentOne(this.selectedEex._id).then((eexToSave: EntityOffice) => {
              eexToSave.documents.push(...elementsToAdd);
              oeexService.save(eexToSave);
              this.uploadCounter = 0;
              this.uploadedCount = 0;
              this._spinnerService.hide();
              this.dialogRef.close(eexToSave);
            });
          } else {
            const metasSaver = metas.map((meta) => (this._entityService._save(meta).toPromise()));
            promiseAll(metasSaver).then((res) => {
              this.uploadCounter = 0;
              this.uploadedCount = 0;
              //this._spinnerService.hide();
              //this.dialogRef.close();
            }).catch((err) => {
              console.log('Error saving metas', err);
            });
          }
        }).catch((err) => {
          console.log('Error saving files', err);
        });
  */
    }
  }
  async uploadFiles() {
    const returned = [];
    for (const upld of this.entityForm.uploads) {
      const result = await this.entityForm.uploadFilePromise(upld.file).then((docfile: EntityDocumentFile) => {
        this.uploadedCount++;
        upld.entity.data_id = docfile._id;
        //TODO not eex
        if (this.parent_id) {
          return this._entityService.beforeSave(upld.entity);
        }
      });
      //console.log(result);
      returned.push(result);
    }
    return returned;
  }
}