import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {LoggerService, ConfirmDialogComponent, TreeElement} from 'app/shared';
import {PreventionPlanService, OfficeService, EntityOffice} from 'app/workbook-core';
import {WorkbookOfficeListComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-prevention-plan-office-list',
  templateUrl: './officeList.component.html',
  styleUrls: ['../../workbook-shared/office/officeList.component.scss']
})

export class PreventionPlanOfficeListComponent extends WorkbookOfficeListComponent {

  entityList: EntityOffice[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce service ?';
  addConfirmMessage = 'Nouveau service créé';

  constructor(
    protected _officeService: OfficeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_officeService, dialog, snackBar, _route, _router, _logger, _entityService);

  }
  updateElement(element: EntityOffice) {
    super.updateElement(element);
    if (element._deleted) {
      this._afterRemoveElement(element);
    }
  }

  protected _afterRemoveElement(element: EntityOffice) {
    if (this.entity
      && element.repo_id
      && this.entity.offices
      && this.entity.offices.indexOf(element.repo_id) !== -1) {
      if (this.hasPermission('setOfficeVisibility') && this.entity.offices) {
        const i = this.entity.offices.indexOf(element.repo_id);
        if (i !== -1) {
          this.entity.offices.splice(i, 1);
          this._save();
        }
      } else if (this.hasPermission('editVisibility')) {
        const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = "Souhaitez-vous enlever la visibilité de cette opération aux utilisateurs de ce service ?";
        dialogRefDel.componentInstance.buttonMessage = "Oui";
        dialogRefDel.componentInstance.cancelMessage = "Non";

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            const i = this.entity.offices.indexOf(element.repo_id);
            if (i !== -1) {
              this.entity.offices.splice(i, 1);
              this._save();
            }
          }
        });
      }
    }
  }
  protected _afterCreateElement(element: EntityOffice) {
    if (this.entity && element.repo_id && this.officeList && this.officeList.length) {
      const repoOffice = this.officeList.find((e) => (e._id === element.repo_id));
      if (repoOffice) {
        const ancestrors = TreeElement.getAncestrors(repoOffice, this.officeList, 'main');
        let matchingOffice = this.entity.offices && this.entity.offices
          .findIndex((_id) => (_id === element.repo_id || ancestrors.indexOf(_id) !== -1)) !== -1;
        if (!matchingOffice) {
          if (this.hasPermission('setOfficeVisibility') && this.entity.offices) {
            this.entity.offices.push(element.repo_id);
            this._save();
          } else if (this.hasPermission('editVisibility')
            && (!this.entity.offices || this.entity.offices.indexOf(element.repo_id) === -1)) {
            const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
              disableClose: true,
              width: '600px',
            });
            dialogRefDel.componentInstance.confirmMessage = "Souhaitez-vous rendre visible cette opération aux utilisateurs de ce service ?";
            dialogRefDel.componentInstance.buttonMessage = "Oui";
            dialogRefDel.componentInstance.cancelMessage = "Non";

            dialogRefDel.afterClosed().subscribe(result => {
              if (result) {
                if (!this.entity.offices) {
                  this.entity.offices = [];
                }
                this.entity.offices.push(element.repo_id);
                this._save();
              }
            });
          }
        }
      }
    }

  }
}
