import {EntityCompanyDocumentable, Contact, Operator, EntityDocumentMetadata} from 'app/shared';

export class EntityOffice extends EntityCompanyDocumentable {
  main: string;
  job: string;
  public contacts: Contact[];
  public operators: Operator[];
  public defaultAdd: string[];
  public defaultVisibilityOccasional?: string[];
  public defaultVisibilityAnnual?: string[];
  public defaultVisibilityProtocol?: string[];
  public contactSyncFilter: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.main = (data && data.main) ? data.main : '';
    this.job = (data && data.job) ? data.job : '';
    this.contacts = (data && data.contacts) ? data.contacts.map((e) => Object.assign({}, e)) : [];
    this.operators = (data && data.operators) ? data.operators.map((e) => new Operator(e)) : [];
    this.defaultVisibilityOccasional = (data && data.defaultVisibilityOccasional) ? data.defaultVisibilityOccasional.map((e) => (e)) : null;
    this.defaultVisibilityAnnual = (data && data.defaultVisibilityAnnual) ? data.defaultVisibilityAnnual.map((e) => (e)) : null;
    this.defaultVisibilityProtocol = (data && data.defaultVisibilityProtocol) ? data.defaultVisibilityProtocol.map((e) => (e)) : null;
    this.defaultAdd = (data && data.defaultAdd) ? data.defaultAdd.map((e) => (e)) : [];
    this.contactSyncFilter = !!(data && data.contactSyncFilter);
  }
  getVisibilityField(type: string) {
    return (type === 'protocol')
      ? 'defaultVisibilityProtocol'
      : ((type === 'annual')
        ? 'defaultVisibilityAnnual'
        : 'defaultVisibilityOccasional');
  }
  addVisibility(type: string, id: string) {
    const f = this.getVisibilityField(type);
    if (id === '') {
      this[f] = null;
    } else if (id === 'all') {
      this[f] = ['*'];
    } else if (id === 'none') {
      this[f] = [];
    } else {
      if (!this[f]) {
        this[f] = [];
      }
      if (this[f].indexOf(id) === -1) {
        this[f].push(id);
        const i = this[f].indexOf('*');
        if (i !== -1) {
          this[f].splice(i, 1);
        }
      }
    }
  }
  removeVisibility(type: string, id: string) {
    const f = this.getVisibilityField(type);
    if (id === '') {
      this[f] = null;
    } else if (id === 'all') {
      this[f] = null;
    } else if (id === 'none') {
      this[f] = null;
    } else {
      if (this[f]) {
        const i = this[f].indexOf(id);
        if (i !== -1) {
          this[f].splice(i, 1);
        }
      }
    }
  }
}
