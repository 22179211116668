import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityWorkbookMeasure} from '../models';

@Injectable({
  providedIn: 'root'
})
export class WorkbookMeasureService extends EntitySecureService {
  onlyChildren = true;

  autoInit = false;

  static LABEL_ONE = "chargement de la mesure";
  static LABEL_ALL = "chargement des mesures ?";
  static LABEL_CHILDREN = "chargement des mesures de l'opération";
  static LABEL_SAVE = "sauvegarde de la mesure";
  static LABEL_DELETE = "suppression de la mesure";

  static ENTITY_TYPE: string = 'wb_measure';
  static ENTITY_FIELDS: string[] = ['name', 'type', 'risk_id'];// description


  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showRisksPP'],
    CREATE: ['editRisksPP',],
    UPDATE: ['editRisksPP'],
    DELETE: ['removeRiskPP']
  }
  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityWorkbookMeasure {
    return new EntityWorkbookMeasure(data, clone);
  }

}
