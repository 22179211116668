import {Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep, EntityActivity} from 'app/workbook-core';
import {WorkbookActivityListComponent, WorkbookActivityDialogComponent} from 'app/workbook-shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-step-activity-list',
  templateUrl: './stepActivityList.component.html',
  styleUrls: ['../../workbook-shared/activity/activityList.component.scss']
})

export class PreventionPlanStepActivityListComponent extends WorkbookActivityListComponent {
  step: EntityStep;
  step_id: string;
  params: Params;
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_cd, _spinnerService, dialog, snackBar, _route, _router, _logger, _entityService);
    this.params = this._route.snapshot.queryParams;
  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (params['step_id']) {
      this.step_id = params['step_id'];
      this._entityService.stepService.getCurrentOne(this.step_id).then((data) => {
        if (data) {
          this.step = data;
          this.filter();
        }
      });
    }

  }
  isHidden(id) {
    return super.isHidden(id) || this.activitiesWithoutStep.indexOf(id) !== -1;
  }
  filter() {
    if (this.step) {
      this.steps = this.steps.filter((e) => (e._id === this.step_id));
      this.templates = this.templates.filter((e) => (e._id === this.step_id));
      super.filter();
    }
  }
  addActivity(entity: EntityActivity = null) {
    let groups: string[] = [];
    if (this.entityList && this.entityList.length) {
      this.entityList.forEach((activity) => {
        if (activity && activity.group && groups.indexOf(activity.group) === -1) {
          groups.push(activity.group);
        }
      });
    }

    const dialogRef = this.dialog.open(WorkbookActivityDialogComponent, {
      disableClose: true,
      minWidth: '600px',
      width: '900px',
      data: {
        entity: entity ? this._entityService.activityService.getNewEntity(entity, true) : null,
        parent_id: this.entity._id,
        workbook: this.entity,
        groups: groups,
        step_id: this.step_id,
        saveCurrentStep: true,
        showGroup: (entity && !!entity.group)
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      this.updateElement(c);
    });
  }


  getActivityTooltip() {
    let msg = '';
    if (this.entity && this.entity.isComplete()) {
      msg = 'Ce dossier est terminé';
    } else if (!this.rightC) {
      msg = 'Vous n\'avez pas les droits pour créer une nouvelle activité';
    } else if (this.withActiveSteps) {
      if (!this.companies.length) {
        msg = 'Vous devez saisir au moins un intervenant pour pouvoir créer des activités.';
      } else if (this.completedSteps[this.step_id]) {
        msg = 'Le chantier est terminé';
      }
    } else {
      msg = 'Aucun chantier sélectionnable';
    }
    return msg;
  }
}
