import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Contact, IUser, ConfigEmailService, EntityConfigEmail } from 'app/shared';
import { OfficeService } from 'app/workbook-core';

@Component({
  selector: 'app-repository-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class RepositoryContactDialogComponent implements OnDestroy {

  public contact: Contact;
  // public contactList: [Contact];
  public usersList: IUser[];
  public emails: string[];
  linkAnyUser: boolean;
  protected _configEmailSubscription: Subscription;
  public regexEmail: string;
  public regexEmailInvalid: string;
  public msgEmail: string;
  public internal: boolean;

  constructor(
    protected _configEmailService: ConfigEmailService,
    public dialogRef: MatDialogRef<RepositoryContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _officeService: OfficeService
  ) {
    this.internal = !!data['internal'];
    this._configEmailSubscription = this._configEmailService.getSingleOne().subscribe((ce: EntityConfigEmail) => {
      if (ce) {
        this.regexEmail = this.internal ? ce.regexInternal : ce.regexExternal;
        this.msgEmail = this.internal ? ce.msgInternal : ce.msgExternal;
        this.regexEmailInvalid = EntityConfigEmail.EMAIL_REGEX;
      }
    });
    this.contact = data['contact'] ? data['contact'] : new Contact();
    // this.contactList = data['contactList'] ? data['contactList'] : [];
    this.usersList = data['usersList'] ? data['usersList']
      .sort((a, b) => {
        let _a = '';
        if (a && a.user_metadata && (a.user_metadata.given_name || a.user_metadata.family_name)) {
          _a = a.user_metadata.given_name ? a.user_metadata.given_name + ' ' : ''
          _a += a.user_metadata.family_name ? a.user_metadata.family_name : ''
        } else {
          _a = a.email;
        }
        let _b = '';
        if (b && b.user_metadata && (b.user_metadata.given_name || b.user_metadata.family_name)) {
          _b = b.user_metadata.given_name ? b.user_metadata.given_name + ' ' : ''
          _b += b.user_metadata.family_name ? b.user_metadata.family_name : ''
        } else {
          _b = b.email;
        }
        return (_a.toLowerCase().localeCompare(_b.toLowerCase()))
      }) : [];

    this.linkAnyUser = this._officeService.hasPermission('linkAnyUser');
    if (this.contact && this.contact.user_id && this.usersList.findIndex((e) => (e['user_id'] === this.contact.user_id)) == -1) {
      this.onEmailChange();
    } else {
      this.refreshEmails();
    }
  }
  ngOnDestroy() {
    if (this._configEmailSubscription) {
      this._configEmailSubscription.unsubscribe();
    }
  }
  refreshEmails() {
    const _emails: string[] = [];
    this.usersList.forEach((u) => {
      if (u.email
        && (!this.contact.email
          || u.email.indexOf(this.contact.email) !== -1)
      ) {
        _emails.push(u.email);
      }
    });
    this.emails = _emails.sort();
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.contact);
    }
  }

  onUserChange() {
    if (!this.contact.email && this.contact.user_id) {
      const user = this.usersList.find(e => (e['user_id'] === this.contact.user_id));
      if (user && user['email']) {
        this.contact.email = user['email'];
      }
    }
  }
  onEmailChange() {
    if (this.contact.email && (!this.contact.user_id || !this.linkAnyUser)) {
      const user = this.usersList.find((e) => (e['email'].toLowerCase() === this.contact.email.toLowerCase()));
      if (user) {
        this.contact.user_id = user['user_id'];
      } else if (!this.linkAnyUser) {
        this.contact.user_id = '';
      }
    }
    this.refreshEmails();
  }

  getEmailError() {
    if (this.contact && this.contact.email) {
      let reg = this.regexEmailInvalid;
      const regex = RegExp(reg);
      let res = regex.exec(this.contact.email);
      if (res && res.length) {
        return this.msgEmail;
      } else {
        return EntityConfigEmail.EMAIL_INVALID;
      }
    }
  }
}
