import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, ConfirmDialogComponent } from 'app/shared';
import { AbstractWorkbookEditComponent, WorkbookStepDialogComponent } from 'app/workbook-shared';
import { WorkbookStepService, PreventionPlanService, EntityWorkbook } from 'app/workbook-core';
import { PreventionPlanStepComponent } from './step.component';

@Component({
  selector: 'app-prevention-plan-step-edit',
  templateUrl: './stepEdit.component.html'
})

export class PreventionPlanStepEditComponent extends AbstractWorkbookEditComponent {

  entityForm: PreventionPlanStepComponent;
  params = {};
  protected _workbookSubscription: Subscription;
  workbook: EntityWorkbook;
  constructor(
    public dialog: MatDialog,
    protected _workbookService: PreventionPlanService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService

  ) {
    super(_route, _router, _logger, _entityService);

  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (this.parent_id) {
      this._workbookSubscription = this._workbookService.getOne(this.parent_id).subscribe((wb) => {
        if (wb) {
          this.workbook = wb;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this._workbookSubscription) {
      this._workbookSubscription.unsubscribe();
    }
  }
  backToList() {
    this.backToParent();
  }
  backToParent() {
    let url: string;
    let qp = {};
    if (this.entityForm && this.workbook) {
      if (this.entityForm.paramInfo) {
        qp['info'] = true;
        url = this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id)
      } else {
        if (this.entityForm.params && this.entityForm.params['icp']) {
          qp['icp'] = this.entityForm.params['icp'];
        }
        if (this.entityForm.params && this.entityForm.params['date']) {
          qp['date'] = this.entityForm.params['date'];
        }
      }
      if (this.entityForm.entity.template_id) {
        url = this._workbookService.getUrlWorkbookStep(this.workbook.documentType, this.workbook._id, this.entityForm.entity.template_id);
      }
    }

    this._router.navigateByUrl(url ? url : this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id, 'step'), { queryParams: qp });

    //  this._router.navigate(['../../' + 'step'], {relativeTo: this._route, fragment: 'steps'})
  }
  removeTemplateId() {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity.template_id) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous convertir cette intervention en une nouvelle intervention spécifique qui reprendra l’analyse des risques de ce chantier type pour la réutiliser ? Dans ce cas là une nouvelle inspection commune et un nouveau plan de prévention seront nécessaires.';
      dialogRef.componentInstance.buttonMessage = 'Oui';
      dialogRef.componentInstance.cancelMessage = 'Non';
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.entityForm.entity.template_id = '';
          this.entityForm.entity.template = null;
          this.entityForm.entity.implementation = null;
          this._workbookService.updateWorkbookInspectionStep(this.workbook, this.entityForm.entity);
          this.save();
        }
      });
    }
  }
  onSave(element) {
    if (element) {
      this._workbookService.updateWorkbookInspectionStep(this.workbook, element);
      if (element._deleted) {
        this.backToParent();
      }
    }
  }

  cloneStep() {
    if (this.workbook && this.entityForm && this.entityForm.entity && this.entityForm.entity._id) {
      //this.addStep(step.template, this._getChildService().getNewEntity(step), true, (!step.template && !step.template_id));
      let _entity = {
        name: 'Copie de ' + this.entityForm.entity.name,
        parent_id: this.workbook._id,
        template: this.entityForm.entity.template,
        dateStart: this.entityForm.entity.dateStart,
        dateEnd: this.entityForm.entity.dateEnd,
        hourStart: this.entityForm.entity.hourStart,
        hourEnd: this.entityForm.entity.hourEnd,
        workConditions: this.entityForm.entity.workConditions,
        point: this.entityForm.entity.point,
        site: this.entityForm.entity.site,
        locations: this.entityForm.entity.locations,
        activities: this.entityForm.entity.activities,
        eex_id: this.entityForm.entity.eex_id
      };

      const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
        disableClose: true,
        width: '900px',
        data: {
          entity: this._entityService.getNewEntity(_entity),
          parent_id: this.workbook._id,
          workbook: this.workbook
        }
      });
      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined') {
          this._router.navigateByUrl('pp/' + c.parent_id + '/step/' + c._id);
        }
      });
    }
  }
}
