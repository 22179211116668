import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  LoggerService,
  ISignature
} from 'app/shared';


@Component({
  selector: 'app-signatory-select',
  templateUrl: './signatory-select.component.html',
  styleUrls: ['./signatory-select.component.scss']
})
export class SignatorySelectDialogComponent {

  addedSignatures: ISignature[] = [];
  officeSignatures: ISignature[] = [];
  eexSignatures: ISignature[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService
  ) {
    if (data) {
      if (data['officeSignatures']) {
        this.officeSignatures = data['officeSignatures'];
      }
      if (data['eexSignatures']) {
        this.eexSignatures = data['eexSignatures'];
      }
    }
  }
}


