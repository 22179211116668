import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll, LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep, EntityActivity} from 'app/workbook-core';


@Component({
  selector: 'app-split-activity-dialog',
  templateUrl: './splitActivity-dialog.component.html',
  styleUrls: ['./splitActivity-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class SplitActivityDialogComponent implements OnDestroy {
  protected _activitySubscription: Subscription;
  activity: EntityActivity;
  protected _stepsSubscription: Subscription;
  stepList: EntityStep[];
  selectedSteps: string[];
  newActivity: string;
  //non désélectionnable
  step_id: string;
  completedSteps: {[step_id: string]: boolean} = {};

  activitieUniqueNames: string[] = [];
  protected _activitiesSubscription: Subscription;

  submited: boolean;
  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _logger: LoggerService,
    public dialogRef: MatDialogRef<SplitActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _entityService: PreventionPlanService
  ) {
    this.selectedSteps = [];
    if (data['step_id']) {
      this.step_id = data['step_id'];
    }
    if (data['id'] && data['parent_id']) {
      this._activitySubscription = this._entityService.activityService.getOne(data['id']).subscribe((activity: EntityActivity) => {
        if (activity) {
          this.activity = activity;
          this.newActivity = 'Copie de ' + activity.name;
        }
      });
      this._activitiesSubscription = this._entityService.activityService.getChildren(data['parent_id']).subscribe((list: EntityActivity[]) => {
        if (list) {
          this.activitieUniqueNames = list
            .filter((a) => (a._id !== data['_id']))
            .map((a) => (a.group + '-' + a.name));
        }
      });
      this._stepsSubscription = this._entityService.getStepList(data['parent_id']).subscribe((steps: EntityStep[]) => {
        if (steps) {
          this.completedSteps = {};
          this.stepList = [];
          const now = (new Date()).getTime();
          steps
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()))
            .forEach((s) => {
              if (!s.template_id && s.activities.indexOf(this.activity._id) !== -1) {
                if (s.isComplete()) {
                  this.completedSteps[s._id] = true;
                } else {
                  this.selectedSteps.push(s._id)
                }
                this.stepList.push(s);
              }
            });
          //this.stepList = steps.filter((s) => (!s.template_id && s.activities.indexOf(this.activity._id) !== -1));
          //if (this.selectedSteps.length === 0) {
          //  this.selectedSteps = this.stepList.map((e) => (e._id));
          //}
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._activitySubscription) {
      this._activitySubscription.unsubscribe();
    }
    if (this._activitiesSubscription) {
      this._activitiesSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }
  changeSelectedStep() {
    if (Object.keys(this.completedSteps).length
      && this.selectedSteps.filter((e) => (!this.completedSteps[e])).length === (this.stepList.length - Object.keys(this.completedSteps).length)) {
      setTimeout(() => {this.selectedSteps = this.stepList.map((e) => (e._id)).filter((id) => (Object.keys(this.completedSteps).indexOf(id) === -1))});
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      if (this.stepList.length === this.selectedSteps.length) {
        this.dialogRef.close(this.activity);
      } else {
        this.submited = true;
        this._spinnerService.show();
        const _activity: EntityActivity = this._entityService.activityService.getNewEntity(this.activity, true);
        _activity.name = this.newActivity;
        this._entityService.activityService.save(_activity).then((res) => {
          const stepSaver = [];
          this.stepList.forEach((step) => {
            if (this.selectedSteps.indexOf(step._id) !== -1) {
              //remplacer l'ancienne activité
              const activities = step.activities.map((activity_id) => (activity_id === this.activity._id) ? _activity._id : activity_id);
              stepSaver.push(this._entityService.updateStepActivities(step._id, activities));
            }
          });
          if (stepSaver.length) {
            promiseAll(stepSaver)
              .then((allres) => {
                this._spinnerService.hide();
                this.dialogRef.close(_activity);
                this.submited = false;
              })
              .catch((err) => {
                this._logger.error('SplitActivityDialogComponent', 'error saving steps', JSON.stringify(err));
                this._spinnerService.hide();
                this.submited = false;
              })
          }
        })
          .catch((err) => {
            this._logger.error('SplitActivityDialogComponent', 'error saving activity', JSON.stringify(err));
            this._spinnerService.hide();
            this.submited = false;
          });
      }
    }
  }
}
