import {Entity} from './entity.model';

export class EntityConfigLogger extends Entity {

  public logging: boolean;
  public logDb: boolean;
  public logLevel: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.logging = !!data.logging;
    this.logDb = !!data.logDb;
    this.logLevel = (data && data.logLevel) ? data.logLevel : '';
  }
}
