import { Entity } from 'app/shared';
export class EmergencyContact {
  name: string;
  important: string;
  phone: string;
  address: string;
  email: string;
  constructor(data = null) {
    this.name = data && data.name ? data.name : '';
    this.important = data && data.important ? data.important : '';
    this.phone = data && data.phone ? data.phone : '';
    this.address = data && data.address ? data.address : '';
    this.email = data && data.email ? data.email : '';
  }
}
export class EmergencyCase {
  name: string;
  contacts: EmergencyContact[];
  instructions: string[];
  constructor(data = null) {
    this.name = data && data.name ? data.name : '';
    this.contacts = data && data.contacts ? data.contacts.map((e) => (new EmergencyContact(e))) : [];
    this.instructions = data && data.instructions ? data.instructions.map((e) => (e)) : [];
  }
}
export class EntityEmergencyInstructions extends Entity {
  enabled: boolean;
  enabledIcp: boolean;
  title: string;
  emergencyCases: EmergencyCase[];
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.enabled = (data && !!data.enabled);
    this.enabledIcp = (data && !!data.enabledIcp);
    this.title = (data && data.title) ? data.title : 'En cas d\'urgence';
    this.emergencyCases = (data && data.emergencyCases) ? data.emergencyCases.map((e) => new EmergencyCase(e)) : [];
  }
}
