import {Inject, OnDestroy, AfterViewInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoadingService, ConfirmDialogComponent, EntityEmailMessages, EmailDatasModel, ISignature, EmailRecipient,
  EntitySignableDialogComponent, EntitySecureService,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService,
  SignableStatus, EntityCompany,
  formattedDate, Operator, AttendanceDialogComponent, Attendance
} from 'app/shared';
import {
  PreventionPlanService, EntityWorkbook,
  AbstractPdpExportComponent, DocumentationService, AttendanceGroup
} from 'app/workbook-core';
import {EmailAttachmentComponent} from './emailAttachment';
import {SignatorySelectDialogComponent} from './signature/signatory-select.component';

export abstract class AbstractSignableDialogComponent extends EntitySignableDialogComponent implements OnDestroy, AfterViewInit {
  static FLAG_SUFFIX: string;

  abstract SIGNATORY_RIGHT: string;

  abstract SIGNING_NOTIFY: number;
  abstract SIGNED_NOTIFY: number;
  NOTIFY_SIGNING_TEXT: string = 'en attente de signature';
  NOTIFY_SIGNED_TEXT: string = 'signé';

  entityForm: AbstractPdpExportComponent;

  workbook: EntityWorkbook;

  signableStatus = SignableStatus.LABELS;

  protected _loadingSubscription: Subscription;
  isLoading: boolean;
  isDocumentationLoading: boolean;
  protected _oeexSubscription: Subscription;
  protected _notify: number = 0;
  attachment: number = 0;
  attachmentFormat: number = 0;
  tabIndex: number = 0;

  documentationToAdd: string[] = [];
  protected _documentationSubscription: Subscription;

  selectedNotification: {[recipient_id: string]: boolean} = {};


  showAttendances: {[id: string]: boolean} = {};

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EntitySignableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService) {
    super(snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
    this.isDocumentationLoading = true;
    this._loadingSubscription = this._loadingService.loading.subscribe((msgList) => {
      if (msgList) {
        const list = Object.keys(msgList);
        //TODO filtrer uniquement les service utiles
        //.filter((k) => msgList[k]);
        this.isLoading = (list.length > 0);
      }
    });
    if (this.entity) {
      this.getEmailRecipientList(this.entity);
    }
    if (data && data['workbook']) {
      this.workbook = data['workbook'];
      this._documentationSubscription = this._documentationService.getAll().subscribe((documentations) => {
        if (documentations) {
          this.isDocumentationLoading = false;
          //this.documentations = documentations;
          const suffix = (<typeof AbstractSignableDialogComponent> this.constructor).FLAG_SUFFIX;
          if (this.workbook && suffix) {
            const flag = (this.workbook.type === 'annual'
              ? (this.workbook.simple ? 'ps' : 'op')
              : 'oo')
              + '_'
              + suffix;
            this.documentationToAdd = documentations
              .filter((d) => (d[flag] && d['meta']))
              .map((d) => (d['meta']['_id']));
          }
        }
      });

    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.prepareEmail();
  }
  isExpired(date) {
    return (!!date && !isNaN(date) && (new Date(date).getTime() < new Date().getTime()));
  }
  isAddedDocumentation(id) {
    return this.entityForm && this.entityForm.entity && this.entityForm.entity.documents.findIndex((e) => (e._id === id && !!e.repo_id && this.documentationToAdd.indexOf(e.repo_id) !== -1)) !== -1;
  }

  getNotifyText(_notify: number): string {
    const statusText = (_notify === this.SIGNING_NOTIFY)
      ? this.NOTIFY_SIGNING_TEXT
      : this.NOTIFY_SIGNED_TEXT
    return 'Veuillez trouver ci-joint le document ' + this.entityForm.entity.getName() + ' ' + statusText;
  }

  getUserContactEmail() {
    const uid = this._entityService.getUserId();
    let userEmail = this._entityService.getUserEmail();
    if (uid && this.usersList && this.usersList.length) {
      const i = this.usersList.findIndex((e) => (e.user_id === uid));
      if (i !== -1) {
        userEmail = this.usersList[i].email;
      }
    }
    return userEmail;
  }
  getEmailRecipientList(entity) {
    if (this._workbookService && !this._oeexSubscription) {
      this._oeexSubscription = this._workbookService.getEmailRecipientList(entity.parent_id).subscribe((res) => {
        this.usersList = [];
        const emails: string[] = [];
        res.forEach((contact) => {
          if (contact.email && emails.indexOf(contact.email) === -1) {
            emails.push(contact.email);
            this.usersList.push(contact);
          }
        });
        this.addSpecifictEmailRecipient(entity);
        this.updateSelectedNotifications(entity);
      });
    }
  }
  addSpecifictEmailRecipient(entity) {
  }
  updateSelectedNotifications(entity) {
    this.selectedNotification = {};
    this.usersList.forEach((r) => {
      this.selectedNotification[r.email] = entity.notifications && entity.notifications.findIndex((n) => (n.email && n.email === r.email)) !== -1;
    });
  }
  createEmailRecipient(company, contact, signatory: boolean = false) {
    const r = [];
    if (signatory || contact.signatory) {
      r.push('signatory');
    }
    if (contact.signatoryPdP) {
      r.push('signatoryPdP');
    }
    if (contact.signatoryPermit) {
      r.push('signatoryPermit');
    }
    if (contact.chsct) {
      r.push('chsct');
    }
    return {
      id: contact.id,
      name: contact.name,
      email: contact.email,
      company: company.name,
      isOffice: (!!company.documentType && company.documentType === 'wb_office'),
      job: contact.job,
      right: ['signatory'],
      user_id: contact.user_id
    };
  }
  isRecipientSelected(recipient) {
    return this.recipientSelectedValue.findIndex((e) => (this.compareId(e, recipient))) !== -1;
  }
  compareId(a, b): boolean {
    return a.id === b.id;
  }
  ngOnDestroy() {
    if (this._loadingSubscription) {
      this._loadingSubscription.unsubscribe();
    }
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
  }

  protected resetSignatures() {
    if (this.entityForm && this.entityForm.entity) {
      super.resetSignatures();
      this.entityForm.refreshSignatories();
    }
  }

  invalidate() {
    super.invalidate();
    this.prepareEmail();
  }

  setSigning() {
    this._notify = (this.entityForm && this.entityForm.entity && this.entityForm.entity.withoutSignatories) ? this.SIGNED_NOTIFY : this.SIGNING_NOTIFY;
    super.setSigning();
    this.entityForm.refreshSignableStatus(!this.entityForm.entity.withoutSignatories);

  }
  getUnsigned(): EntityCompany[] {
    const unsignedOffices = this.entityForm.offices
      .filter((o) => this.entityForm.signatoriesOffice.findIndex((e) => (e.data && e.company_id === o._id)) === -1);
    const unsignedEexs = this.entityForm.externalCompanies
      .filter((o) => this.entityForm.signatoriesEex.findIndex((e) => (e.data && e.company_id === o._id)) === -1);
    return [...unsignedOffices, ...unsignedEexs];
  }
  protected _setSigned() {
    super.setSigned();
    this.entityForm.refreshSignableStatus(!this.entityForm.entity.withoutSignatories);
  }
  setSigned() {
    if (this.entityForm && this.entityForm.entity) {
      const unsigned = this.getUnsigned();
      if (unsigned.length > 0) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        // console.log('unisgned', [...unsignedOffices, ...unsignedEexs]);
        const list = '<ul><li>' + unsigned.map((e) => (e.name)).join('</li><li>') + '</li></ul>';
        dialogRef.componentInstance.confirmMessage = 'Il n’y a aucun signataire pour les intervenants suivants :' + list + 'Souhaitez vous quand meme passer ce document au statut "Signé" ?';
        dialogRef.componentInstance.buttonMessage = 'Confirmer';

        dialogRef.afterClosed().subscribe(result => {
          this._spinnerService.show();
          if (result) {
            this._notify = this.SIGNED_NOTIFY;
            this._setSigned();
            this.entityForm.refreshSignatories();
          } else {
            this._updateEntityFromForm();
          }
          this._spinnerService.hide();

        });
      } else {
        this._spinnerService.show();
        this._notify = this.SIGNED_NOTIFY;
        this._setSigned();
        this.entityForm.refreshSignatories();
        this._spinnerService.hide();

      }
    }
  }

  setOutdated() {
    if (this.entityForm && this.entityForm.entity) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRef.componentInstance.confirmMessage = 'Souhaitez vous passer ce document au statut obsolète ? Ce document restera consultable comme archive mais ne sera plus actif';
      dialogRef.componentInstance.buttonMessage = 'Confirmer';

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          super.setOutdated();
        } else {
          this._updateEntityFromForm();
        }
      });
    }
  }

  public validate(updateContent: boolean = true, updateSignAgreement: boolean = true): void {
    super.validate(updateContent, updateSignAgreement);
    this.entityForm.refreshSignatories();
    this.prepareEmail();
  }

  changeStatus(event) {
    if (this.entity) {
      if (this.entity.status === SignableStatus.DRAFT) {
        this.invalidate();
      } else if (this.entity.status === SignableStatus.VALIDATED) {
        this.validate((!this.entityForm || !this.entityForm.entity || !this.entityForm.entity.content));
        this.entityForm.refreshSignatories();
      } else if (this.entity.status === SignableStatus.SIGNING) {
        this.setSigning();
      } else if (this.entity.status === SignableStatus.SIGNED) {
        this.setSigned();
      } else if (this.entity.status === SignableStatus.OUTDATED) {
        this.setOutdated();
      }
    }
  }

  _afterSave(res) {
    super._afterSave(res);
    this.optionalSendEmail(this._notify);
    this._optionalAfterSave(this._notify);
    this._notify = 0;
    setTimeout(() => {this._spinnerService.hide();});
  }

  protected _optionalAfterSave(_notify) {
  }
  _hasSignatories() {
    return (this.entityForm
      && this.entityForm.signatoriesOffice
      && this.entityForm.signatoriesEex
      && this.entityForm.entity
      && this.entityForm.entity.signatures
      && (this.entityForm.signatoriesOffice.length + this.entityForm.signatoriesEex.length + this.entityForm.entity.signatures.length) > 0)
  }
  //les signataires des services impliqués
  _getSignatoriesOffice() {
    return (this.entityForm && this.entityForm.signatoriesOffice) ? this.entityForm.signatoriesOffice : [];
  }
  //les signataires des Eexs impliquées
  _getSignatoriesEex() {
    return (this.entityForm && this.entityForm.signatoriesEex) ? this.entityForm.signatoriesEex : [];
  }
  //les autres signataires des Eexs (non-impliquées)
  _getSignatoriesOfficeOther() {
    return (this.entityForm && this.entityForm.signatoriesOfficeOther) ? this.entityForm.signatoriesOfficeOther : [];
  }
  _getSignatoriesEexOther() {
    return (this.entityForm && this.entityForm.signatoriesEexOther) ? this.entityForm.signatoriesEexOther : [];
  }

  prepareEmail(loaded: boolean = true) {
    if (this.entityForm && this.entityForm.entity && loaded) {
      if (this.entityForm.entity.status < SignableStatus.SIGNING) {
        this.emailDatas.subject = this.getEmailSubject();
        this.emailDatas.text = '';
        this.emailDatas.usertext = '';
        this.recipientSelectedValue = [];
        this.from_names = [this._emailService.fromName, this._entityService.getUserName()];
        this.from_name = (this._emailService.defaultSenderUser && this.from_names.length > 1) ? this.from_names[1] : this._emailService.fromName;
        this.reply_emails = [this._emailService.fromEmail, this._entityService.getUserEmail()];
        this.reply_email = (this._emailService.defaultReplyUser && this.reply_emails.length > 1) ? this.reply_emails[1] : this._emailService.fromEmail;

      } else if (this.entityForm.entity.status === SignableStatus.SIGNING && !this.entityForm.entity.withoutSignatories) {
        this._setEmailDatas(this.SIGNING_NOTIFY, this._getEmailContacts(this.SIGNING_NOTIFY));
      } else if (this.entityForm.entity.status < SignableStatus.OUTDATED) {
        this._setEmailDatas(this.SIGNED_NOTIFY, this._getEmailContacts(this.SIGNED_NOTIFY));
      }
    }
  }

  optionalSendEmail(_notify) {
    if (_notify) {
      const contacts: any[] = this._getEmailContacts(_notify);
      if (contacts && contacts.length) {
        this._setEmailDatas(_notify, contacts);
        this._optionalSendEmail(_notify, contacts);
      }
      //if (contactsWithoutUser && contactsWithoutUser.length) {
      //this._optionalSendEmailWithPdf(_notify, contactsWithoutUser);
      //}
    }
  }

  protected _getEmailContacts(_notify, onlyWithUser: boolean = false) {
    const contacts: any[] = [];
    const contactsWithoutUser: any[] = [];
    if (_notify === this.SIGNING_NOTIFY) {
      const officeContactIds = this._getSignatoriesOffice().filter((e) => (!e.data)).map((e) => (e.contact_id));
      this.entityForm.offices.forEach((o) => {
        o.contacts.forEach((c) => {
          if (officeContactIds.indexOf(c.id) !== -1) {
            if (c.user_id) {
              contacts.push(c);
            } else {
              contactsWithoutUser.push(c);
            }
          }
        });
      });
      const eexContactIds = this._getSignatoriesEex().filter((e) => (!e.data)).map((e) => (e.contact_id));
      this.entityForm.externalCompanies.forEach((o) => {
        o.contacts.forEach((c) => {
          if (eexContactIds.indexOf(c.id) !== -1) {
            if (c.user_id) {
              contacts.push(c);
            } else {
              contactsWithoutUser.push(c);
            }
          }
        });
      });
    } else if (_notify === this.SIGNED_NOTIFY) {
      this.entityForm.offices.forEach((o) => {
        o.contacts.forEach((c) => {
          if (c.user_id) {
            contacts.push(c);
          } else {
            contactsWithoutUser.push(c);
          }
        });
      });
      this.entityForm.externalCompanies.forEach((o) => {
        o.contacts.forEach((c) => {
          if (c.user_id) {
            contacts.push(c);
          } else {
            contactsWithoutUser.push(c);
          }
        });
      });
    }
    return onlyWithUser ? contacts : [...contacts, ...contactsWithoutUser];
  }
  /*
    protected _optionalSendEmailWithPdf(type: number, contacts?: any[]) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRef.componentInstance.buttonMessage = 'Envoyer';
      dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous envoyer ce document au format PDF aux contacts n\'ayant pas d\'accès à la plateforme ?';

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.recipientSelectedValue = contacts;
          this.sendEmail(this.getNotifyText(type), true, false);
        }
      });
    }
  */
  protected _optionalSendEmail(EmailType: number, contacts?: any[]) {
    this._spinnerService.hide();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.buttonMessage = 'Oui';
    dialogRef.componentInstance.cancelMessage = 'Non';
    dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous envoyer un message aux utilisateurs concernés ?';
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.entityForm) {
        this.tabIndex = ((this.entityForm.entity.status > 1 && this.entityForm.entity.documents.length)
          || (this.entityForm.entity.status < 2 && this.entityForm.withSelectableDocuments)) ? 2 : 1;

        //this.entityForm.sendNotification(type, contacts, showSnackbar);
      }
    });
  }
  protected _setEmailDatas(emailType: number, contacts?: any[]) {
    this._spinnerService.show();
    this._emailService.getEmailDatas(emailType).then(emailObj => {
      this.from_names = [emailObj.fromName, this._entityService.getUserName()];
      this.from_name = (emailObj.defaultSenderUser && this.from_names.length > 1) ? this.from_names[1] : emailObj.fromName;
      this.reply_emails = [emailObj.fromEmail, this._entityService.getUserEmail()];
      this.reply_email = (emailObj.defaultReplyUser && this.reply_emails.length > 1) ? this.reply_emails[1] : emailObj.fromEmail;

      this.emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.text, this.entityForm.preventionPlan, this.entityForm.entity);
      this.emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.usertext, this.entityForm.preventionPlan, this.entityForm.entity);
      this.recipientSelectedValue = [];
      contacts.forEach((c) => {
        const i = this.usersList.findIndex((x) => (x.id === c.id));
        if (i !== -1) {
          this.recipientSelectedValue.push(this.usersList[i]);
        }
      });
      this.emailDatas.subject = this.getEmailSubject();
      this._spinnerService.hide();
    });
  }

  getEmailSubject() {
    if (this.entity && (this.entity.status === SignableStatus.SIGNING || this.entity.status === SignableStatus.SIGNED)) {
      const emailModel = this._emailService.getEmailData(
        this.emailMessages,
        ((this.entity.withoutSignatories && this.entity.status === SignableStatus.SIGNING) || this.entity.status === SignableStatus.SIGNED) ? this.SIGNED_NOTIFY : this.SIGNING_NOTIFY
      );
      return EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailModel.subject, this.workbook, this.entity);
    }
    return this.getDefaultEmailSubject();
  }
  abstract getDefaultEmailSubject();
  checkNotification(recipient: EmailRecipient) {
    return this.entityForm && this.entityForm.entity && this.entityForm.entity.notifications
      && this.entityForm.entity.notifications.findIndex((e) => (e.email === recipient.email)) !== -1;
  }
  switchNotification(recipient: EmailRecipient) {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity.notifications) {
      if (recipient.email && !this.selectedNotification[recipient.email]) {
        this.entityForm.entity.notifications.push(recipient);
        this.save();
        this.selectedNotification[recipient.email] = true;
      } else {
        const i = this.entityForm.entity.notifications.findIndex((e) => (e.email === recipient.email));
        if (i !== -1) {
          this.entityForm.entity.notifications.splice(i, 1);
          this.save();
          this.selectedNotification[recipient.email] = false;
        }
      }
    }
  }

  selectedDoc(id) {
    return this.entityForm && this.entityForm.entity && this.entityForm.entity.documents && this.entityForm.entity.documents.findIndex((e) => (e._id === id)) !== -1;
  }
  openAttachmentDialog(recipient: EmailRecipient) {
    if (this.entityForm
      && this.entityForm.entity
      && this.entityForm.entity.documents) {
      const dialogRef = this.dialog.open(EmailAttachmentComponent, {
        disableClose: false,
        width: '600px',
        data: {
          recipient: Object.assign({}, recipient),
          documents: this.entityForm.entity.documents,
          entity: this.entityForm.entity,
          signatory: recipient.right.indexOf(this.SIGNATORY_RIGHT) !== -1
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          recipient.pdf = !!result['pdf'];
          recipient.zip = !!result['zip'];
        }
      });
    }
  }
  addSignatures() {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity.signatures) {
      const dialogRef = this.dialog.open(SignatorySelectDialogComponent, {
        minWidth: '35%',
        autoFocus: false,
        data: {
          officeSignatures: this._getSignatoriesOfficeOther(),
          eexSignatures: this._getSignatoriesEexOther()
        }
      });

      dialogRef.afterClosed().subscribe(addedSignatures => {
        if (addedSignatures && addedSignatures !== 'undefined') {
          addedSignatures.forEach((s) => {
            if (this.entityForm.entity.signatures.findIndex((e) => (s.contact_id === e.contact_id)) === -1) {
              this.entityForm.entity.signatures.push(s);
            }
          });
          this.save();
          this.entityForm.refreshSignatories();
        }
      });
    }
  }
  hasSignatures() {
    return this.entityForm && this.entityForm.hasSignatures();
  }


  getAttendances(checkpoint: string): string {
    return (this.entityForm && this.entityForm.entity && this.entityForm.entity.attendances)
      ? this.entityForm.entity.attendances
        .filter((a) => (a.checkpoint === checkpoint))
        .map((a) => (formattedDate(a.signedAt, true))).join('\n')
      : '';
  }
  addAttendance(operator: Operator, operatorGroup: AttendanceGroup, checkpointIndex: number) {
    const attendance = new Attendance(operator);
    const checkpointName = operatorGroup.checkpoints[checkpointIndex];
    const dialogRef = this.dialog.open(AttendanceDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        signature: attendance,
        withSignature: operatorGroup.signature,
        checkpoint: operatorGroup.checkpoints[checkpointIndex]
      }
    });

    dialogRef.afterClosed().subscribe((dialogSignature) => {
      if (dialogSignature && this.entityForm && this.entityForm.entity) {
        dialogSignature.checkpoint = checkpointName;
        dialogSignature.companyType = operatorGroup.name;
        dialogSignature.user_id = this._entityService.getUserId();
        this.entityForm.entity.attendances.push(dialogSignature);
        this.entityForm.refreshOeexList();
        this.save();
      }
    });
  }

  public trackByValue(name, index, item: string) {
    return name ? (name + '|' + index) : index;
  }
}
