import {Component, Input} from '@angular/core';

import {
  LoggerService,
  CommonComponent,
  EntityExploitingCompany
} from 'app/shared';

@Component({
  selector: 'app-workbook-eu-export',
  templateUrl: './exploitingCompanyExport.component.html'
})
export class ExploitingCompanyExportComponent extends CommonComponent {

  @Input() exploitingCompany: EntityExploitingCompany;

  // previousStatus: number = 0;
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

}
