import {Component, ViewChild, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute, Params, UrlSegment} from '@angular/router';
import {CdkDragDrop, moveItemInArray, CdkDropList} from '@angular/cdk/drag-drop';
import {MatTable, MatDialog, MatSnackBar, MatTableDataSource, MatSort, MatPaginator, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {promiseAll, PermissionsService, LoggerService, ConfirmDialogComponent, SignableStatus} from 'app/shared';
import {
  AbstractWorkbookChildFormComponent,
  WorkbookActivityDialogComponent,
  WorkbookStepDialogComponent,
  StepAccidentDialogComponent,
  WorkbookIcpDialogComponent,
  FirePermitInfoDialogComponent,
  FirePermitDialogComponent,
  WorkbookStepImplementationDialogComponent,
  LocationDialogComponent
} from 'app/workbook-shared/';

import {
  WorkbookStepService,
  EntityStep, EntitySite, EntityZone,
  PreventionPlanService,
  EntityActivity,
  EntityOffice,
  EntityIcp,
  EntityFirePermit,
  Implementation, IcpRecurrenceService, EntityIcpRecurrence,
  EntitySignablePreventionPlan, SignablePreventionPlanService
} from 'app/workbook-core';
import {SignablePreventionPlanDialogComponent} from '../signablePreventionPlan/signablePreventionPlan-dialog.component'

@Component({
  selector: 'app-prevention-plan-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})

export class PreventionPlanStepComponent extends AbstractWorkbookChildFormComponent {

  @ViewChild('activityTable') activityTable: MatTable<any>;
  @ViewChild('activityList') activityList: CdkDropList;

  entity: EntityStep;
  @Output() onClone: EventEmitter<EntityStep> = new EventEmitter();
  @Input() withClone: boolean = false;

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette intervention ?';

  selectedActivity: EntityActivity;
  displayedActivityColumns: string[] = ['group', 'rank', 'name', 'companies', 'actions', 'delete'];
  activityDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('activitySort') activitySort: MatSort;

  activities: EntityActivity[] = [];
  protected _activitySubscription: Subscription;
  //pas initialisé pour n'afficher message qu'une fois chargé
  oeexList: EntityOffice[];
  protected _companiesSubscription: Subscription;

  lastIcp: EntityIcp;
  lastIcpValid: EntityIcp;
  nextIcp: EntityIcp;
  protected _icpSubscription: Subscription;
  loadedIcp: boolean = false;


  lastFps: EntityFirePermit[] = [];
  nextFps: EntityFirePermit[] = [];
  protected _firePermitSubscription: Subscription;
  loadedFp: boolean = false;
  withFirePermit: boolean = false;

  templatedSteps: EntityStep[] = [];
  lastStep: EntityStep;
  currentSteps: EntityStep[];
  nextStep: EntityStep;
  protected _stepSubscription: Subscription;
  loadedStep: boolean = false;
  displayedStepsColumns: string[] = ['name', 'dateStart', 'dateEnd', 'hourStart', 'site.name', 'status', 'site.zipCode', 'site.city'];
  stepsDataSource: MatTableDataSource<EntityStep> = new MatTableDataSource([]);
  @ViewChild('stepsSort') stepsSort: MatSort;
  @ViewChild('stepsPaginator') stepsPaginator: MatPaginator;

  selectableActivities: EntityActivity[] = [];
  newActivityStep: string[] = [];
  activitySteps: {[activity_id: string]: string[]} = {};
  steps: {[step_id: string]: EntityStep} = {};

  protected _templateSubscription: Subscription;
  implementation: Implementation;
  template: EntityStep;
  protected _pdpSubscription: Subscription;
  signablePreventionPlan: EntitySignablePreventionPlan;

  protected _icpRecurrenceSubscription: Subscription;
  icpRecurrence: EntityIcpRecurrence;
  showIcpRecurrence: boolean = true;
  nextIcpRecurrence: Date = null;

  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];

  protected _routeUrlSubscription: Subscription;
  protected _routeQueryParamsSubscription: Subscription;
  params: Params;
  url: UrlSegment[];
  paramInfo: boolean;

  recurrenceStatus: string;
  recurrencePeriod: number;


  displayedLocationsColumns: string[] = ['name', 'address', 'zipCode', 'city', 'zones', 'actions'];
  locationsDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('locationsSort') locationsSort: MatSort;
  //  @ViewChild('locationsPaginator') locationsPaginator: MatPaginator;

  hideStepLocations: boolean = true;
  hideStepIcps: boolean = true;
  hideStepFps: boolean = true;
  hideStepActivities: boolean = true;
  hideStepSteps: boolean = true;
  constructor(
    protected _signablePreventionPlanService: SignablePreventionPlanService,
    protected _route: ActivatedRoute,
    protected _icpRecurrenceService: IcpRecurrenceService,
    protected _router: Router,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(dialog, _logger, _entityService);
    this._icpRecurrenceSubscription = this._icpRecurrenceService.getSingleOne().subscribe((ir: EntityIcpRecurrence) => {
      if (ir) {
        this.icpRecurrence = ir;
        this.updateIcpRecurrence();
      }
    });
    this._routeQueryParamsSubscription = this._route.queryParams.subscribe((params: Params) => {
      if (params) {
        this.params = params;
      }
      this.paramInfo = (this.params && this.params['info'])
        || (this.url && this.url.length && this.url[this._route.snapshot.url.length - 1].path === 'info');
    });
    this._routeUrlSubscription = this._route.url.subscribe((url) => {
      if (url) {
        this.url = url;
      }
      this.paramInfo = (this.params && this.params['info'])
        || (this.url && this.url.length && this.url[this._route.snapshot.url.length - 1].path === 'info');
    });

  }
  protected _init(data) {
    super._init(data);
    this.template = null;
    this.implementation = null;
    if (this.entity) {
      if (this.entity.implementation) {
        this.implementation = this.entity.implementation;
        if (this.entity.implementation.pdp_id && (!this.signablePreventionPlan || this.signablePreventionPlan._id !== this.entity.implementation.pdp_id)) {
          if (this._pdpSubscription) {
            this._pdpSubscription.unsubscribe();
          }
          this._pdpSubscription = this._signablePreventionPlanService.getOne(this.entity.implementation.pdp_id).subscribe((data) => {
            if (data) {
              this.signablePreventionPlan = data;
            }
          });
        }
      }
      if (this.entity.template) {
        this.rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce chantier type ?';
      }

      if (this.entity.template_id) {
        if (this._templateSubscription) {
          this._templateSubscription.unsubscribe();
        }
        this._templateSubscription = this._entityService.getOne(this.entity.template_id).subscribe((data) => {
          if (data) {
            this.template = data;
            this.implementation = data["implementation"];
          }
        });
      }
      if (this.workbook) {
        //if (this._stepSubscription) {
        //  this._stepSubscription.unsubscribe();
        //}
        if (this._stepSubscription) {
          this._stepSubscription.unsubscribe();
        }
        this._stepSubscription = this._entityService.getChildren(this.workbook._id).subscribe((data: EntityStep[]) => {
          if (data) {
            this.activitySteps = {};
            this.steps = {};
            data.forEach((s) => {
              if (!s.template_id) {
                this.steps[s._id] = s;
                if (s.activities && s.activities.length) {
                  s.activities.forEach((activity_id) => {
                    if (!this.activitySteps[activity_id]) {
                      this.activitySteps[activity_id] = [];
                    }
                    this.activitySteps[activity_id].push(s._id);
                  });
                }
              }
            });
            if (this.entity.template) {
              this.templatedSteps = data
                .filter((e) => (!e.template && e.template_id === this.entity._id))
                .sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()));
              this.templatedSteps.forEach((s) => {
                this.lastStep = null;
                this.nextStep = null;
                this.currentSteps = [];
                this.updateLastNextStep(s);
              });
              this.updateIcpRecurrence();
              this.updateStepsSource();
            }
            this.loadedStep = true;
          }
        });
        this.updateLocationsSource();
        this.updateActivityDataSource();
      }
    }
  }
  updateLastNextStep(s: EntityStep) {
    const now = (new Date()).getTime();
    const start = (new Date(s.dateStart)).getTime();
    const end = (new Date(s.dateEnd)).getTime();
    if (end < (now - 86400000)) {
      //past
      if (!this.lastStep || (new Date(this.lastStep.dateEnd)).getTime() < end) {
        this.lastStep = s;
      }
    } else {
      if (start < now) {
        //currents
        this.currentSteps.push(s);
      } else {
        //futur
        if (!this.nextStep || (new Date(this.nextStep.dateStart)).getTime() > start) {
          this.nextStep = s;
        }
      }
    }
  }
  public selectStep(step) {
    return ['/' + (this.workbook ? this.workbook.documentType : 'pp') + '/' + this.entity.parent_id + this._entityService.getUrlEntity(step)];
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['parent_id'] || changes['workbook'] || changes['id']) {
      if (changes && changes['workbook'] && this.workbook) {
        this.parent_id = this.workbook._id;
        //cas de la modification du score sur le workbook
        this.updateIcpRecurrence();
      }
      if (changes && ((changes['parent_id'] && this.parent_id) || changes['id'])) {
        if (this._activitySubscription) {
          this._activitySubscription.unsubscribe();
        }
        this._activitySubscription = this._workbookService.activityService.getChildren(this.parent_id).subscribe((data) => {
          if (data) {
            this._updateList(data.sort((a, b) => a.name.localeCompare(b.name)), 'activities');
            //this.activities = data;
            this.updateActivityDataSource();
          }
        });

        if (this._companiesSubscription) {
          this._companiesSubscription.unsubscribe();
        }
        this._companiesSubscription = this._workbookService.getOEexList(this.parent_id).subscribe((data) => {
          if (data) {
            if (this.oeexList && this.oeexList.length > 0) {
              this._updateList(data, 'oeexList');
            } else {
              this.oeexList = data;
            }
          }
        });
        if (this.id) {
          if (this._icpSubscription) {
            this._icpSubscription.unsubscribe();
          }
          this._icpSubscription = this._workbookService.icpService.getChildren(this.parent_id).subscribe((data: EntityIcp[]) => {
            if (data) {
              this.loadedIcp = true;
              let now = new Date();
              this.nextIcp = null;
              this.lastIcp = null;
              this.lastIcpValid = null;
              data
                .filter((icp) => (icp.status !== SignableStatus.OUTDATED && icp.steps && icp.steps.indexOf(this.id) !== -1))
                .forEach((icp) => {
                  //passé
                  if ((new Date(icp.iPDate)).getTime() < now.getTime()) {
                    //ICP de reference
                    if (icp.status > SignableStatus.VALIDATED) {
                      if (!this.lastIcpValid || new Date(icp.iPDate).getTime() > new Date(this.lastIcpValid.iPDate).getTime()) {
                        this.lastIcpValid = icp;
                      }
                      if (this.lastIcp && new Date(icp.iPDate).getTime() < new Date(this.lastIcpValid.iPDate).getTime()) {
                        this.lastIcp = null;
                      }
                    } else if ((!this.lastIcpValid || new Date(icp.iPDate).getTime() > new Date(this.lastIcpValid.iPDate).getTime())
                      && (!this.lastIcp || new Date(icp.iPDate).getTime() > new Date(this.lastIcp.iPDate).getTime())) {
                      this.lastIcp = icp;
                    }

                  } else
                    //futur
                    if ((!this.nextIcp || new Date(icp.iPDate).getTime() < new Date(this.nextIcp.iPDate).getTime())) {
                      this.nextIcp = icp;
                    }

                });
              this.updateIcpRecurrence();
            }
          });
          if (this._firePermitSubscription) {
            this._firePermitSubscription.unsubscribe();
          }
          this._firePermitSubscription = this._workbookService.firePermitService.getChildren(this.parent_id).subscribe((data) => {
            if (data) {
              this.loadedFp = true;
              let now = new Date();
              this.nextFps = [];
              this.lastFps = [];
              data
                .filter((fp) => (fp.step === this.id))
                .forEach((fp) => {
                  if (fp.status === SignableStatus.SIGNED
                    //&& (new Date(fp.dateStart)).getTime() <= now.getTime()
                    && (!fp.dateEnd || (new Date(fp.dateEnd).getTime() >= (now.getTime() - 86400000)))
                  ) {
                    this.lastFps.push(fp);
                  }
                  if (fp.status < SignableStatus.SIGNED
                    && (!fp.dateEnd || (new Date(fp.dateEnd).getTime() >= (now.getTime() - 86400000)))
                  ) {
                    this.nextFps.push(fp);
                  }
                });
            }
          });
        }
      }
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._activitySubscription) {
      this._activitySubscription.unsubscribe();
    }
    if (this._companiesSubscription) {
      this._companiesSubscription.unsubscribe();
    }
    if (this._icpSubscription) {
      this._icpSubscription.unsubscribe();
    }
    if (this._templateSubscription) {
      this._templateSubscription.unsubscribe();
    }
    if (this._icpRecurrenceSubscription) {
      this._icpRecurrenceSubscription.unsubscribe();
    }
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
    if (this._routeUrlSubscription) {
      this._routeUrlSubscription.unsubscribe();
    }
    if (this._routeQueryParamsSubscription) {
      this._routeQueryParamsSubscription.unsubscribe();
    }
  }
  protected _save() {
    if (this.entity && this.workbook) {
      const recurrence: boolean = (this.workbook.type === 'annual' && !this.workbook.simple && !!this.icpRecurrence);
      if (recurrence) {
        this.updateSystematicInspection();
      }
      this._workbookService.updateWorkbookInspection(
        this.workbook,
        this.lastIcp,
        [this.entity]);
      if (recurrence) {
        this.updateIcpRecurrence();
      }
    }
    return super._save();
  }

  protected _afterSave(e) {
    let templateSaver = [];
    if (this.entity && this.entity.template) {
      templateSaver = this.templatedSteps
        .filter((s) => (!s.implementation))
        .map((s) => (this._entityService.setActivities(s._id, this.entity.activities.map((e) => (e)))));
    }
    if (templateSaver.length) {
      promiseAll(templateSaver)
        .then((x) => {
          // console.log('PreventionPlanStepComponent templated steps saved', x);
          super._afterSave(e);
        })
        .catch((err) => {
          this._logger.error('PreventionPlanStepComponent', 'error saving templated steps', JSON.stringify(err));
          super._afterSave(e);
        });
    } else {
      //console.log('PreventionPlanStepComponent no templated steps to save');
      super._afterSave(e);
    }
  }
  updateSystematicInspection() {
    this.entity.systematicInspection =
      //si pas de cas specifique possibilité de forcer dans step info
      (this.entity.systematicInspection && !this.icpRecurrence.systematicCases.length)
      // des cas specifiques selectionnés
      || !!this.entity.systematicCases.length
      //DICT
      || (this.entity.withDt && this.icpRecurrence.dtdict);

  }
  updateIcpRecurrence() {
    this.recurrenceStatus = this.getRecurrenceStatus();
    this.recurrencePeriod = this.getRecurrencePeriod();
    this.nextIcpRecurrence = null;
    if (this.entity && this.entity.template && this.workbook && this.workbook.type === 'annual' && !this.workbook.simple
      && this.icpRecurrence && this.icpRecurrence.enabled
      && this.icpRecurrence.exposures && this.icpRecurrence.exposures.length
      && this.icpRecurrence.severities && this.icpRecurrence.severities.length
      && this.loadedIcp) {
      this.showIcpRecurrence = true;
      if (!this.nextIcp && (this.lastIcpValid || this.lastIcp) && this.workbook.inspections && this.workbook.inspections[this.entity._id]) {
        this.nextIcpRecurrence = (this.entity.withDt && this.icpRecurrence.dtdict)
          ? (this.nextStep ? this.nextStep.dateStart : null)
          : this.workbook.getNextPlannedIcpDate(this.icpRecurrence, this.entity._id);
      }
    } else {
      this.showIcpRecurrence = false;
    }
  }
  getRecurrenceStatus() {
    if (this.entity && this.icpRecurrence) {
      return this.icpRecurrence.getStatus((this.entity.exposure * this.entity.severity), this.entity.getLastAccident());
    }
    return '';
  }
  getRecurrencePeriod() {
    if (this.entity && this.icpRecurrence && !(this.entity.withDt && this.icpRecurrence.dtdict)) {
      return this.icpRecurrence.getPeriod(this.entity.notPlanned ? 0 : this.entity.getScore(), this.entity.getLastAccident());
    }
    return 0;
  }

  getAccidentPeriod(index: number) {
    if (this.icpRecurrence && this.icpRecurrence.enabled && this.entity
      && this.entity.accidents && this.entity.accidents[index]) {
      const lastAccident = new Date(this.entity.accidents[index].date);
      const now = new Date();
      if (lastAccident && now.getTime() < (lastAccident.getTime() + (this.icpRecurrence.accidentDuration * 1000 * 24 * 3600))) {
        return this.icpRecurrence.accidentPeriod
      }
    }
    return 0;
  }
  getActivityRank(activity_id): number {
    return (this.entity && this.entity.activities && this.entity.activities.length)
      ? this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1)).indexOf(activity_id) + 1
      : 0;
  }

  protected setLocationsSort() {
    //    if (this.locationsPaginator) {
    //      this.locationsDataSource.paginator = this.locationsPaginator;
    //    }
    if (this.locationsSort) {
      this.locationsDataSource.sort = this.locationsSort;
    }
  }
  protected updateLocationsSource() {
    this.locationsDataSource = new MatTableDataSource(this.entity.getLocations());
    setTimeout(() => {
      this.setLocationsSort();
    });
  }

  protected setStepsSort() {
    if (this.stepsPaginator) {
      this.stepsDataSource.paginator = this.stepsPaginator;
    }
    if (this.stepsSort) {
      this.stepsDataSource.sort = this.stepsSort;
    }
  }
  protected updateStepsSource() {
    this.stepsDataSource = new MatTableDataSource(this.templatedSteps);
    setTimeout(() => {
      this.setStepsSort();
    });
  }

  protected setActivitySort() {
    if (this.activitySort) {
      this.activityDataSource.sort = this.activitySort;
      this.activityDataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        if (sortHeaderId === 'rank') {
          return (data && data['rank']) ? data['rank'] : 0;
        } else if (sortHeaderId === 'companies') {
          return (data && data['data'] && data['data']['companies'] && data['data']['companies'].length)
            ? data['data']['companies']
              .sort((a, b) => a.name.localeCompare(b.name))[0]['name']
            : '';
        } {
          return (data && data['data'] && data['data'][sortHeaderId])
            ? ((typeof data['data'][sortHeaderId] === "string") ? data['data'][sortHeaderId].toLowerCase() : data['data'][sortHeaderId])
            : '';
        }
      };
    }
  }
  protected updateActivityDataSource() {
    if (this.entity && this.entity._id) {
      let data = [];
      this.selectableActivities = [];
      this.withFirePermit = (this.lastFps && this.lastFps.length > 0) || (this.nextFps && this.nextFps.length > 0);
      if (this.entity.template_id && this.entity.implementation && this.entity.implementation.activities && this.entity.implementation.activities) {
        let rank = 0;
        Object.keys(this.entity.implementation.activities).forEach((activity_id) => {
          this.withFirePermit = this.withFirePermit || this.entity.implementation.activities[activity_id].firePermit;
          data.push({
            data: this.entity.implementation.activities[activity_id],
            groupClass: '',
            groupRank: '',
            groupedRank: '',
            rank: rank
          });
          rank++;
        });
      } else {
        this.activities.forEach((e) => {
          let rank = this.getActivityRank(e._id);
          if (rank) {
            this.withFirePermit = this.withFirePermit || e.firePermit;
            data.push({
              data: e,
              groupClass: '',
              groupRank: '',
              groupedRank: '',
              rank: rank
            });
          } else {
            this.selectableActivities.push(e);
          }
        });
        this.selectableActivities.sort((a, b) => a.name.localeCompare(b.name));
      }
      //compteur
      let i = 0;
      //affichage de groupe
      let groupIndex = 0;
      //indice dans le groupe
      let groupIndice = 0;
      let prevGroup = '';
      data = data
        .sort((a, b) => (a.rank - b.rank))
        .map((d) => {
          if (d.data.group) {
            if (d.data.group === prevGroup) {
              d.groupClass = 'inGroup group' + i;
              groupIndice++;
              d.groupRank = groupIndex;
              d.groupedRank = groupIndex + '.' + groupIndice;
            } else {
              i++;
              d.groupClass = 'withGroup group' + i;
              groupIndex++;
              groupIndice = 1;
              d.groupRank = groupIndex;
              d.groupedRank = groupIndex + '.' + groupIndice;
            }
          }
          else {
            d.groupClass = 'withoutGroup';
            groupIndex++;
            d.groupedRank = groupIndex;

          }
          prevGroup = d.data.group;
          return d;
        });
      this.activityDataSource = new MatTableDataSource(data);

      setTimeout(() => {
        this.setActivitySort();
      });
    }
  }
  dropActivity(event: CdkDragDrop<string[]>) {
    if (this.activityDataSource.sort
      && this.activityDataSource.sort.active
      && this.activityDataSource.sort.active !== 'rank') {
      const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Vous ne pouvez pas ordonner les activités sur un tableau trié, souhaitez-vous supprimer le tri ?';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result && this.activitySort && this.activitySort.active) {
          this.activitySort.active = 'rank';
          this.activitySort.direction = 'asc';
          setTimeout(() => {
            this.setActivitySort();
          });
        }
      });
    } else if (event.previousContainer === event.container && event.previousIndex !== event.currentIndex) {
      if (!this.activityDataSource.sort
        || !this.activityDataSource.sort.active
        || this.activityDataSource.sort.active === 'rank') {
        //supprimer d'abord les orphelins
        //this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
        this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
        if (this.activityDataSource.sort
          && this.activityDataSource.sort.active
          && this.activityDataSource.sort.active === 'rank'
          && this.activityDataSource.sort.direction
          && this.activityDataSource.sort.direction === 'desc') {
          this.changeActivityRank(this.entity.activities.length - event.previousIndex, this.entity.activities.length - event.currentIndex);
        } else {
          this.changeActivityRank(event.previousIndex, event.currentIndex);
        }
      }
    }
  }
  moveActivityUp(index: number) {
    this.moveActivity(index, -1);
  }
  moveActivityDown(index: number) {
    this.moveActivity(index, 1);
  }
  moveActivity(index: number, increment: number = 1) {
    let previousIndex = index;
    //this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
    let currentGroupActivityId: string = this.entity.activities[index];
    let currentGroupActivity: EntityActivity = this.activities.find((activity) => (activity._id === currentGroupActivityId));
    index = index + increment;
    let groupActivityId: string = this.entity.activities[index];
    let groupActivity: EntityActivity = this.activities.find((activity) => (activity._id === groupActivityId));
    //TODO : premier dernier
    if (index !== 0 && index !== (this.entity.activities.length - 1)
      && currentGroupActivity
      && groupActivity
      && currentGroupActivity.group !== groupActivity.group) {
      let group = groupActivity.group;
      while (groupActivity && group === groupActivity.group) {
        index = index + increment;
        groupActivityId = this.entity.activities[index];
        groupActivity = this.activities.find((activity) => (activity._id === groupActivityId));

      }
      index = index - increment;
    }
    this.changeActivityRank(previousIndex, index);
  }
  changeActivityRank(previousIndex: number, currentIndex: number) {
    //this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
    moveItemInArray(this.entity.activities, previousIndex, currentIndex);
    this.updateActivityDataSource();
    this._save().then((e) => {
      this._afterSave(e);
    });
  }
  moveGroupRankUp(previousIndex: number) {
    this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
    let groupActivityId: string = this.entity.activities[previousIndex];
    let groupActivity: EntityActivity = this.activities.find((activity) => (activity._id === groupActivityId));
    if (groupActivity) {
      const group: string = groupActivity.group;
      let next: boolean = true;
      let groupSize = 0;
      while (next) {
        groupSize++;
        groupActivityId = this.entity.activities[previousIndex + groupSize];
        groupActivity = this.activities.find((activity) => (activity._id === groupActivityId));
        next = groupActivity && (groupActivity.group === group);
      }
      let i = 0;
      while (i < groupSize) {
        moveItemInArray(this.entity.activities, previousIndex, previousIndex + groupSize);
        i++;
      }
      this.updateActivityDataSource();
      this._save().then((e) => {
        this._afterSave(e);
      });
    }

  }
  moveGroupRankDown(previousIndex: number) {
    this.entity.activities = this.entity.activities.filter((activity_id) => (this.activities.findIndex((activity) => (activity._id === activity_id)) !== -1));
    let groupActivityId: string = this.entity.activities[previousIndex];
    let groupActivity: EntityActivity = this.activities.find((activity) => (activity._id === groupActivityId));
    if (groupActivity) {
      const group: string = groupActivity.group;
      while (groupActivity && (groupActivity.group === group)) {
        moveItemInArray(this.entity.activities, previousIndex, previousIndex - 1);
        previousIndex++;
        groupActivityId = this.entity.activities[previousIndex];
        groupActivity = this.activities.find((activity) => (activity._id === groupActivityId));
      }
      this.updateActivityDataSource();
      this._save().then((e) => {
        this._afterSave(e);
      });
    }
  }

  createActivity(step_id, group = '') {
    let activity = null;
    if (group) {
      activity = this._workbookService.activityService.getNewEntity({parent_id: this.entity.parent_id, group: group}, true)
    }
    this.selectActivity(activity, step_id, !!group);
  }

  selectActivity(activity = null, step_id = null, showGroup = false) {
    if (!this.entity.template_id && !this.entity.isComplete()) {
      let groups: string[] = [];
      if (this.entity.activities && this.entity.activities.length) {
        this.entity.activities.forEach((activity_id) => {
          let activity = this.activities.find((e) => (e._id === activity_id));
          if (activity && activity.group && groups.indexOf(activity.group) === -1) {
            groups.push(activity.group);
          }
        });
      }
      const dialogRef = this.dialog.open(WorkbookActivityDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        width: '900px',
        data: {
          id: activity ? activity._id : null,
          parent_id: this.entity.parent_id,
          entity: activity && !activity._id ? activity : null,
          workbook: this.workbook,
          step_id: step_id,
          default_step_id: step_id,
          showGroup: showGroup || (activity && !!activity.group),
          groups: groups
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && c._id) {
          const previousActivity = this.activities.find((e) => (e._id === c._id));
          //ajout
          let pos = this.entity.activities.indexOf(c._id);
          if (pos > -1 && previousActivity && previousActivity.group !== c.group) {
            //changement de groupe => supprimer de la liste pour réinserer au bon endroit
            this.entity.activities.splice(pos, 1);
            pos = -1;
          }
          if (pos === -1) {
            //ajout simple
            if (!c.group || groups.indexOf(c.group) === -1) {
              this.entity.activities.push(c._id);
            } else {
              let lastGroupPos = -1;
              //ajout dans le group
              this.entity.activities.forEach((activity_id, index) => {
                const a = this.activities.find((e) => (e._id === activity_id));
                if (a && a.group === c.group && (lastGroupPos === -1 || lastGroupPos === index - 1)) {
                  lastGroupPos = index;
                }
              });
              this.entity.activities.splice(lastGroupPos + 1, 0, c._id);
            }
          }
          this._updateListElement(c, 'activities');
          //rattraper le calcul auto selon situations
          const i = this.activities.findIndex((a) => (a._id === c._id));
          if (i !== -1) {
            this.activities[i].firePermit = c.firePermit;
            this.activities[i].smr = c.smr;
            this.activities[i].confinedSpace = c.confinedSpace;
          }
          this.updateSystematicInspection();
          this.updateActivityDataSource();
          this._save().then((e) => {
            this._afterSave(e);
          });
        }
      });
    }
  }
  cloneActivity(activity: EntityActivity) {
    const clone = this._workbookService.activityService.getNewEntity(activity, true);
    //this._workbookService.activityService.save(clone).then((e) => {
    this.selectActivity(clone, this.entity._id, !!activity.group);
    //});
  }
  removeActivity(activity) {
    if (this.workbook && activity && activity._id) {
      let remove = false;
      const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      if (this.activitySteps[activity._id] && this.activitySteps[activity._id].length > 1) {
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir retirer cette activité';
        if (this.entity.template) {
          dialogRefDel.componentInstance.confirmMessage += ' de ce chantier type';
        } else if (this.workbook.simple) {
          dialogRefDel.componentInstance.confirmMessage += ' de cette opération';
        } else {
          dialogRefDel.componentInstance.confirmMessage += ' de cette intervention';
        }
        dialogRefDel.componentInstance.buttonMessage = 'Valider'
      } else {
        remove = true;
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer définitivement cette activité';
      }
      dialogRefDel.componentInstance.confirmMessage += ' ?';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          const i = this.entity.activities.indexOf(activity._id);
          if (i !== -1) {
            this.entity.activities.splice(i, 1);
            if (this.entity.scopedMeasures[activity._id]) {
              delete this.entity.scopedMeasures[activity._id]
            }
            this._save().then((e) => {
              if (remove) {
                //if (this.workbook && this.workbook.type === 'occasional' && this.workbook.simple) {
                this._workbookService.activityService.remove(activity).then((r) => {

                  const i = this.activities.findIndex((a) => (a._id === activity._id));
                  if (i !== -1) {
                    this.activities.splice(i, 1);
                    delete this.activitySteps[activity._id];
                  }
                  this.updateActivityDataSource();
                  this._afterSave(e);
                });
              } else {
                const j = this.activitySteps[activity._id].indexOf(this.entity._id);
                if (j !== -1) {
                  this.activitySteps[activity._id].splice(i, 1);
                }
                this.updateActivityDataSource();
                this._afterSave(e);
              }
            });
          }
        }
      });
    }
  }

  addTemplatedStep() {
    let _entity = {
      name: 'Intervention copie de ' + this.entity.name,
      parent_id: this.entity.parent_id,
      template: false,
      template_id: this.entity._id,
      dateStart: null,
      dateEnd: null,
      hourStart: this.entity.hourStart,
      hourEnd: this.entity.hourEnd,
      workConditions: this.entity.workConditions,
      point: this.entity.point,
      site: this.entity.site,
      zone: this.entity.zone,
      activities: this.entity.activities,
      implementation: this.entity.implementation,
      eex_id: this.entity.eex_id,
      withDt: this.entity.withDt,
      dtdictNumber: this.entity.dtdictNumber
    };

    const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        entity: this._entityService.getNewEntity(_entity),
        parent_id: this.entity.parent_id,
        workbook: this.workbook,
        template_id: this.entity._id
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        //this._afterCreateStep(c);
        if (this.templatedSteps.findIndex((s) => (s._id === c['_id'])) === -1) {
          this.updateLastNextStep(c);
          this.templatedSteps.push(c);
          this.templatedSteps = this.templatedSteps.sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()));
          this.updateIcpRecurrence();
          this.updateStepsSource();
        }
      }
    });
  }
  addSpecificStep() {
    const confirmDialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    confirmDialogRef.componentInstance.buttonMessage = 'Oui';
    confirmDialogRef.componentInstance.cancelMessage = 'Non';
    confirmDialogRef.componentInstance.confirmMessage = 'Souhaitez-vous créer une nouvelle intervention spécifique qui reprendra l’analyse des risques de ce chantier type pour la réutiliser ? Dans ce cas là une nouvelle inspection commune et un nouveau plan de prévention seront nécessaires.';
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let _entity = {
          name: 'Intervention spécifique copie de ' + this.entity.name,
          parent_id: this.entity.parent_id,
          template: false,
          template_id: '',
          dateStart: null,
          dateEnd: null,
          hourStart: this.entity.hourStart,
          hourEnd: this.entity.hourEnd,
          workConditions: this.entity.workConditions,
          point: this.entity.point,
          site: this.entity.site,
          activities: this.entity.activities,
          eex_id: this.entity.eex_id,
          withDt: this.entity.withDt,
          dtdictNumber: this.entity.dtdictNumber
        };

        const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
          disableClose: true,
          width: '900px',
          data: {
            entity: this._entityService.getNewEntity(_entity),
            parent_id: this.entity.parent_id,
            workbook: this.workbook
          }
        });
        dialogRef.afterClosed().subscribe(c => {
          if (c && c !== 'undefined') {
            this._router.navigate(this.selectStep(c));
            //this._afterCreateStep(c);
          }
        });
      }
    });
  }
  addActivities() {
    if (this.newActivityStep && this.newActivityStep.length) {
      this.newActivityStep.forEach((id => {
        this.entity.activities.push(id);
      }));
      this._save().then((e) => {
        this.updateActivityDataSource();
        this.newActivityStep = [];
        this._afterSave(e);
      });
    }
  }
  openDialog() {
    if (this.rightU) {
      const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
        disableClose: true,
        width: '900px',
        data: {
          id: this.entity._id,
          entity: this.entity._id ? null : this.entity,
          parent_id: this.entity.parent_id,
          workbook: this.workbook,
          template: this.entity.template,
          template_id: this.entity.template_id
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
          if (c._rev !== this.entity._rev) {
            this.entity.copyFrom(c);
          }
          this.onSave.emit(this.entity);
        }
      });
    }
  }
  public editAccident(index: number = -1) {
    const dialogRef = this.dialog.open(StepAccidentDialogComponent, {
      disableClose: true,
      width: '400px',
      data: {
        index: index,
        accident: (index !== -1 && this.entity.accidents && this.entity.accidents[index]) ? this.entity.accidents[index] : null
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (index !== -1 && this.entity.accidents[index]) {
          if (c === 'delete') {
            this.entity.accidents.splice(index, 1);
          } else {
            this.entity.accidents[index] = c;
          }
        } else {
          this.entity.accidents.push(c);
        }
      }
      this._save().then((res) => {
        this.onSave.emit(this.entity);
      });
      this.updateIcpRecurrence();
    });
  }

  public createIcp() {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection

    if (this.parent_id && this.workbook) {
      const userId = this.getUserId();
      //  const notifs = this._workbookService.getEmailRecipients(this.oeexList).filter((er) => ((er.user_id && er.user_id === userId) || (er.user_id && er.user_id === this.workbook.createdId)))
      const dialogRef = this.dialog.open(WorkbookIcpDialogComponent, {
        disableClose: true,
        width: '900px',
        data: {
          entity: this._workbookService.icpService.getNewEntity({
            parent_id: this.parent_id,
            iPSite: (this.entity && this.entity.site) ? this.entity.site.name : '',
            iPAddress: (this.entity && this.entity.site) ? this.entity.site.address : '',
            iPZipCode: (this.entity && this.entity.site) ? this.entity.site.zipCode : '',
            iPCity: (this.entity && this.entity.site) ? this.entity.site.city : '',
            iPPhone: (this.entity && this.entity.site) ? this.entity.site.phone : ''
            // ,notifications: notifs
          }),
          parent_id: this.parent_id,
          workbook: this.workbook,
          selectedSteps: [this.id]
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c._id) {
          this.nextIcp = c;
          this.editIcp(c);
        }
      });

    }
  }
  public editIcp(icp = null) {
    if (this.workbook && this.id) {
      this._router.navigate(['/' + this._workbookService.getUrlIcp(this.workbook.documentType, this.workbook._id, icp._id)]);
    }
  }

  public editFirePermit(firePermit = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(FirePermitDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        id: firePermit ? firePermit._id : '',
        entity: firePermit,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {

      }
    });

  }

  public createFirePermit() {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection

    if (this.parent_id && this.workbook) {
      const userId = this.getUserId();
      const notifs = this._workbookService.getEmailRecipients(this.oeexList).filter((er) => ((er.user_id && er.user_id === userId) || (er.user_id && er.user_id === this.workbook.createdId)))
      const dialogRef = this.dialog.open(FirePermitInfoDialogComponent, {
        disableClose: true,
        width: '920px',
        maxWidth: '90vw',
        data: {
          entity: this._workbookService.firePermitService.getNewEntity({
            parent_id: this.parent_id,
            notifications: notifs
          }),
          parent_id: this.parent_id,
          workbook: this.workbook,
          implementation: this.implementation
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c._id) {
          this.editFirePermit(c);
        }
      });

    }
  }
  getExposureName(value) {
    const element = this.icpRecurrence.exposures.find((e) => (e.value === value));
    return element ? element.name : '';
  }
  getSeverityName(value) {
    const element = this.icpRecurrence.severities.find((e) => (e.value === value));
    return element ? element.name : '';
  }
  openStepActivities() {
    const stepUrl = 'pp/' + this.parent_id + this._workbookService.stepService.getUrlEntityById(this.id);
    this._router.navigateByUrl(
      this._router.createUrlTree(
        [stepUrl + '/activity'],
        {queryParams: this._route.snapshot.queryParams}
      )
    );
  }
  openStepImplementation() {
    if (this.entity && this.entity.implementation) {
      const dialogRef = this.dialog.open(WorkbookStepImplementationDialogComponent, {
        disableClose: true,
        width: '900px',
        data: {
          step: this.entity
        }
      });
      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined') {
          if (this.signablePreventionPlan && this.signablePreventionPlan._id === c)
            this.dialog.open(SignablePreventionPlanDialogComponent, {
              disableClose: true,
              width: '900px',
              data: {
                id: c,
                entity: this.signablePreventionPlan
              }
            });

        }
      });
    }
  }

  clone() {
    this.onClone.emit(this.entity);
  }

  getIcpState(icp: EntityIcp) {
    return SignableStatus.getLabel(icp.status, !icp.withoutSignatories);
  }
  removeLocation(site: EntitySite) {
    if (this.entity) {
      const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cette localisation (site et zones) ?';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this.entity.removeLocation(site);
          this._save().then((res) => {
            this.updateLocationsSource();
          });
        }
      });
    }
  }
  selectLocation(site: EntitySite = null) {
    if (this.workbook && this.entity) {
      this._router.navigateByUrl(this._workbookService.getUrlWorkbookStep(this.workbook.documentType, this.workbook._id, this.entity._id) + '/site/' + site._id);
    }
  }
  editLocation(site: EntitySite = null) {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      disableClose: true,
      width: '900px',
      data: {
        step_id: this.entity._id,
        site: site ? site : null
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.entity.updateLocation(c);
        this._save().then((res) => {
          this.updateLocationsSource();
          this.selectLocation(c);
        });
      }
    });
  }
}
