import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoadingService, EmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService, EntityCompany
} from 'app/shared';
import {
  PreventionPlanService,
  SignablePermitService,
  EntitySignablePermit, FPStatus,
  SignablePermitExportComponent, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableDialogComponent} from '../abstractSignable-dialog.component';


@Component({
  selector: 'app-signable-signablePermit-dialog',
  templateUrl: './signablePermit-dialog.component.html',
  styleUrls: ['./signablePermit-dialog.component.scss']
})
export class SignablePermitDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  static FLAG_SUFFIX: string = 'pt';
  SIGNATORY_RIGHT: string = 'signatoryPermit';

  SIGNING_NOTIFY = EmailMessages.SIGN_SPT_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_SPT;

  entity: EntitySignablePermit;
  entityForm: SignablePermitExportComponent;

  signableStatus = FPStatus.LABELS;

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SignablePermitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: SignablePermitService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);

  }

  _afterSave(res) {
    super._afterSave(res);
    this.entityForm._init(this.entityForm.entity);
  }

  addSpecifictEmailRecipient(entity: EntitySignablePermit) {
    if (entity.originator && entity.originatorContact
      && this.usersList.findIndex((u) => (
        u.name === entity.originator.name
        && u.email === entity.originatorContact.email
        && u.company === entity.originator.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.originator, entity.originatorContact, true));
    }
    if (entity.performer && entity.performerContact
      && this.usersList.findIndex((u) => (
        u.name === entity.performer.name
        && u.email === entity.performerContact.email
        && u.company === entity.performer.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.performer, entity.performerContact, true));
    }
    /*
        if (entity.operators && entity.operators.length) {
          entity.operators.forEach((operator) => {
            if (entity.performer && this.usersList.findIndex((u) => (
              u.name === operator.name
              && u.email === operator.email
              && u.company === entity.performer.name
            )) === -1) {
              this.usersList.push(this.createEmailRecipient(entity.performer, operator, true));
            }
          });
        }
        */
  }

  getDefaultEmailSubject() {
    return ('Permis feu' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? (' ' + this.entityForm.entity.name) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }

  _getSignatoriesOffice() {
    let signatories = [];
    if (this.entityForm && this.entityForm.contributorSignup && this.entityForm.offices) {
      Object.keys(this.entityForm.contributorSignup).forEach((groupName) => {
        signatories = [...signatories, ...this.entityForm.contributorSignup[groupName].filter((e) => (this.entityForm.offices.findIndex((x) => (x._id === e.company_id)) !== -1))];
      });
    }
    return signatories;
  }
  _getSignatoriesEex() {
    let signatories = [];
    if (this.entityForm && this.entityForm.contributorSignup && this.entityForm.offices) {
      Object.keys(this.entityForm.contributorSignup).forEach((groupName) => {
        signatories = [...signatories, ...this.entityForm.contributorSignup[groupName].filter((e) => (this.entityForm.offices.findIndex((x) => (x._id === e.company_id)) === -1))];
      });
    }
    return signatories;
  }
  getUnsigned(): EntityCompany[] {
    const unsigned: EntityCompany[] = [];
    if (this.entityForm && this.entityForm.entity) {
      let performerSelected: boolean = false;
      if (this.entityForm.entity.originator && this.entityForm.entity.originator.name
        && this.entityForm.entity.originatorContact && this.entityForm.entity.originatorContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.originator._id
          && s.contact_id === this.entityForm.entity.originatorContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.originator);
      }
      if (this.entityForm.entity.performer && this.entityForm.entity.performer.name
        && this.entityForm.entity.performerContact && this.entityForm.entity.performerContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.performer._id
          && s.contact_id === this.entityForm.entity.performerContact.id
        )) === -1) {
        performerSelected = true;
        unsigned.push(this.entityForm.entity.performer);
      }
      /*
      if (this.entityForm.entity.operators && this.entityForm.entity.operators.length) {
        this.entityForm.entity.operators.forEach((op) => {
          if (!performerSelected && op.name) {
            performerSelected = true;
            unsigned.push(this.entityForm.entity.performer);
          }
        });
      }
*/
    }
    return unsigned;
  }

  hasAlreadyUniqueAttendance(group: string, op_id: string) {
    return this.entityForm
      && this.entityForm.entity
      && this.entityForm.entity.permitContent
      && this.entityForm.entity.permitContent.operatorGroups
      && this.entityForm.entity.permitContent.operatorGroups.findIndex((e) => (e.name === group && e.isUnique)) !== -1
      && this.entityForm.attendances
      && this.entityForm.attendances[op_id]
      && this.entityForm.attendances[op_id].findIndex((e) => (e.companyType === group)) !== -1
      ;
  }
}
