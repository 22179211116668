import {Component, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';


import {LoggerService, DocumentFileService} from 'app/shared';
import {
  PreventionPlanService, EntityWorkbook,
  IcpRecurrenceService,
  OfficeService, ExternalCompanyService, OrganizationService, SiteService
} from 'app/workbook-core';
import {StatsService} from './stats.service';
import {WorkbooksStatsCommonComponent} from './workbooksStatsCommon.component';



@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbooks-statsOffice',
  templateUrl: './workbooksStats.component.html',
  styleUrls: ['./workbooksStats.component.scss']
})

export class WorkbooksStatsOfficeComponent extends WorkbooksStatsCommonComponent {

  activeNav = 3;

  availableRows: string[] = [
    'office_name',
    'office_job',
    'contact_name',
    'contact_email',
    'contact_job',
    'contact_user'
  ];
  availableCols: string[] = [
    'office_name',
    'office_job',
    'contact_name',
    'contact_email',
    'contact_job',
    'contact_user'
  ];
  availableDatas: string[] = [
    'wb_quantity',
    'oo_quantity',
    'op_quantity',
    'ps_quantity',
    'icp_quantity',
    'pdp_quantity'
  ];
  availableChartTypes = StatsService.ALL_CHARTS;//['bar', 'stackedbar', 'line', 'spline', 'fullstackedbar'];
  chartType = 'bar';
  fieldsRows: string[] = ['contact_name'];
  fieldsCols: string[] = ['office_name'];
  fieldsDatas: string[] = ['wb_quantity'];

  displayedSourceColumns: string[] = [

    'office',
    'extra',
    //    'office_name',
    //          'office_job',
    'contact',
    //          'contact_name',
    //         'contact_email',
    //         'contact_job',
    //         'contact_user',
    'wb_quantity',
    'oo_quantity',
    'op_quantity',
    'ps_quantity',
    'icp_quantity',
    'pdp_quantity'
  ];

  constructor(
    protected _documentFileService: DocumentFileService,
    protected _organizationService: OrganizationService,
    public statsService: StatsService,
    //preload
    protected _spinnerService: Ng4LoadingSpinnerService,
    //private _auth: AuthService,
    protected _cd: ChangeDetectorRef,
    protected _externalCompanyService: ExternalCompanyService,
    protected _officeService: OfficeService,
    protected _siteService: SiteService,
    protected _icpRecurrenceService: IcpRecurrenceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService//WorkbookService
  ) {
    super(_documentFileService, _organizationService, statsService, _spinnerService, _cd, _externalCompanyService, _officeService, _siteService, _icpRecurrenceService, dialog, snackBar, _router, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
  }

  getSource(list: EntityWorkbook[]) {
    const wbUsers: {
      [user_id: string]: {
        wb_code: string,
        wb_type: string,
        wb_icp_quantity: number,
        wb_pdp_quantity: number,
        wb_step_quantity: number
      }[]
    } = {};
    const src = [];
    list.forEach((wb) => {
      const wbObj = {
        wb_code: wb.name,
        wb_type: (wb.type === 'annual') ? (wb.simple ? 'ps' : 'op') : 'oo',
        wb_icp_quantity: this.getWbIcpQuantity(wb),
        wb_pdp_quantity: this.getWbPdPQuantity(wb),
        wb_step_quantity: this.getWbStepQuantity(wb)
      };

      if (!wbUsers[wb.editorId]) {
        wbUsers[wb.editorId] = [];
      }
      wbUsers[wb.editorId].push(wbObj);
    });
    Object.keys(this.officeList).forEach((office_id) => {
      this.officeList[office_id].contacts.forEach((c) => {

        const wbObj = {
          office_name: this.officeList[office_id].name,
          office_job: this.officeList[office_id].job,
          contact_name: c.name,
          contact_email: c.email,
          contact_job: c.job,
          contact_user: !!c.user_id,
          wb_quantity: 0,
          oo_quantity: 0,
          op_quantity: 0,
          ps_quantity: 0,
          icp_quantity: 0,
          pdp_quantity: 0
        };
        if (!!c.user_id) {
          this.organizationList.forEach((oz, index) => {
            wbObj['extra_' + index] = (
              this.userOffices[c.user_id] && this.userOffices[c.user_id].length
              && this.parentFields[this.userOffices[c.user_id][0]] && this.parentFields[this.userOffices[c.user_id][0]][oz])
              ? this.parentFields[this.userOffices[c.user_id][0]][oz]
              : 'Aucun';
          });
          if (wbUsers[c.user_id] && wbUsers[c.user_id].length) {
            wbObj['wb_quantity'] = wbUsers[c.user_id].length;
            wbUsers[c.user_id].forEach((wbo) => {
              wbObj[wbo.wb_type + '_quantity']++;
              wbObj['icp_quantity'] += wbo.wb_icp_quantity
            })
          }
        }
        src.push(wbObj);
      });
    });

    return src;
  }
}
