import {Component, Input, OnChanges} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared';
import {
  PreventionPlanService,
  SignablePreventionPlanService,
  EntitySignablePreventionPlan,
  EntityPreventionPlan,
  EntityStep,
  EntityIcp,
  DocumentationService,
  EmergencyInstructionsService,
  EntityEmergencyInstructions
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from 'app/workbook-shared'
@Component({
  selector: 'app-signable-prevention-plan-info',
  templateUrl: './signablePreventionPlanInfo.component.html',
  styleUrls: ['./signablePreventionPlanInfo.component.scss']
})
export class SignablePreventionPlanInfoComponent extends AbstractSignableInfoComponent implements OnChanges {
  static FLAG_SUFFIX: string = 'pdp';
  autoSave = false;
  public entity: EntitySignablePreventionPlan;
  workbook: EntityPreventionPlan;

  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  @Input() selectedSteps: string[];
  protected _stepsSubscription: Subscription;

  icps: EntityIcp[] = [];
  @Input() selectedIcps: string[];
  protected _icpsSubscription: Subscription;

  // withDocument: boolean = false;
  // protected _documentsSubscription: Subscription;
  // documents: {[item: string]: EntityDocumentMetadata[]} = {};
  protected _oeexSubscription: Subscription;

  step_ids: string[] = [];
  icpStep_ids: {[icp_id: string]: string[]} = {};
  notSelectable: string[] = [];
  protected _emergencyInstructionsSubscription: Subscription;
  emergencyInstructions: EntityEmergencyInstructions;

  constructor(
    protected _emergencyInstructionsService: EmergencyInstructionsService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: SignablePreventionPlanService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (!this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription = this._emergencyInstructionsService.getSingleOne().subscribe((data) => {
        if (data) {
          this.emergencyInstructions = data;
          if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions.enabled) {
            this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
          }
        }
      });
    }
    if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions && this.emergencyInstructions.enabled) {
      this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
    }

    if (this._icpsSubscription) {
      this._icpsSubscription.unsubscribe();
    }
    this._icpsSubscription = this._workbookService.icpService.getChildren(this.entity.parent_id).subscribe((list) => {
      this.icps = list;
      this.icpStep_ids = {};
      this.icps.forEach((icp) => {
        this.icpStep_ids[icp._id] = icp.steps;
      });
      if (!this.id && this.entity && this.workbook) {
        if (this.selectedIcps) {
          this.entity.icps = this.selectedIcps;
        }
      }
      this.updateNotSelectable();
    });
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._workbookService.stepService.getChildren(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.step_ids = [];
        let _templates = [];
        let _steps = [];
        list.forEach((e) => {
          this.step_ids.push(e._id);
          if (e.template) {
            _templates.push(e);
          } else if (!e.template_id) {
            _steps.push(e);
          }
        });
        this.templates = _templates.sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
        this.steps = _steps.sort((a, b) =>
          (this.workbook && this.workbook.type === 'annual') ?
            ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime())
            : ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
        );
        if (!this.id && this.entity && this.workbook) {
          if (this.selectedSteps) {
            this.entity.steps = this.selectedSteps;
          }
          /*else if (this.workbook.simple && this.steps.length) {
            this.entity.steps = this.steps.map((e) => (e._id));
          }*/
          /* else {
            if (this.workbook.type === 'annual') {
              this.entity.steps = this.templates.map((e) => (e._id));
            } else {
              this.entity.steps = this.steps.map((e) => (e._id));
            }
          }
*/
        }
        this.updateNotSelectable();
      }
    });
    /*
        if (this._documentsSubscription) {
          this._documentsSubscription.unsubscribe();
        }
        this._documentsSubscription = this._workbookService.getDocumentMetaDataList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            this.withDocument = list.length > 0;
            this.documents['Annexes'] = list.filter((e) => (this.workbook && e.context && e.context.indexOf(this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id, 'document')) === 1));
          }
        });
    */
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        list.filter((e) => (e.documents && e.documents.length))
          .forEach((oeex) => {
            this.withDocument = true;
            this.documents[oeex.name] = oeex.documents;
          });
      }
    });

  }
  protected _save() {
    if (this.workbook && this.workbook.simple && this.entity.icps && this.entity.steps) {
      if (this.entity.icps.length === 0) {
        this.entity.steps = this.steps.map((e) => (e._id));
      } else {
        this.entity.steps = [];
      }
    }
    return super._save()
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._icpsSubscription) {
      this._icpsSubscription.unsubscribe();
    }
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    if (this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription.unsubscribe();
    }
  }
  isNotSelectable(id) {
    return !this.entity || this.entity.status > 0 || this.notSelectable.indexOf(id) !== -1;
  }
  updateNotSelectable() {
    if (this.entity && this.step_ids && this.step_ids.length) {
      this.notSelectable = [];
      let usedStep: string[] = (this.workbook && this.workbook.simple) ? [] : this.entity.steps;
      this.entity.icps.forEach((icp_id) => {
        if (this.icpStep_ids[icp_id]) {
          usedStep = [...usedStep, ...this.icpStep_ids[icp_id]];
        }
      });

      //not selectable step : not selected used steps
      this.notSelectable = this.step_ids.filter((id) => (
        ((this.workbook && this.workbook.simple) || this.entity.steps.indexOf(id) === -1)
        && usedStep.indexOf(id) !== -1)
      );
      //not selectable icp : icps not selected with selected step
      Object.keys(this.icpStep_ids)
        //not selected
        .filter((icp_id) => (this.entity.icps.indexOf(icp_id) === -1))
        //with usedstep
        .forEach((icp_id) => {
          if (this.icpStep_ids[icp_id]
            && this.icpStep_ids[icp_id].filter((step_id) => (usedStep.indexOf(step_id) !== -1)).length > 0) {
            this.notSelectable.push(icp_id);
          }
        });
    }
  }

  switchDoc(event, doc) {
    if (this.entity && this.entity.steps) {
      if (event.checked) {
        if (this.entity.documents.findIndex((e) => (e._id === doc._id)) === -1) {
          this.entity.documents.push(doc);
        }
      } else {
        let i = this.entity.documents.findIndex((e) => (e._id === doc._id));
        if (i !== -1) {
          this.entity.documents.splice(i, 1)
        }
      }
    }
  }

  switchStep(event, step_id) {
    if (this.entity && this.entity.steps) {
      if (event.checked) {
        if (this.entity.steps.indexOf(step_id)) {
          this.entity.steps.push(step_id);
        }
      } else {
        let i = this.entity.steps.indexOf(step_id);
        if (i !== -1) {
          this.entity.steps.splice(i, 1)
        }
      }
      this.updateNotSelectable();
    }
  }
  switchIcp(event, icp_id) {
    if (this.entity && this.entity.icps) {
      if (event.checked) {
        if (this.entity.icps.indexOf(icp_id)) {
          this.entity.icps.push(icp_id);
        }
      } else {
        let i = this.entity.icps.indexOf(icp_id);
        if (i !== -1) {
          this.entity.icps.splice(i, 1)
        }
      }
      this.updateNotSelectable();
    }
  }
}
