import {Component, Input} from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import {
  EntityOffice
} from '../../repository/models/entityOffice.model';

@Component({
  selector: 'app-workbook-offices-export',
  templateUrl: './officesExport.component.html'
})
export class OfficesExportComponent extends CommonComponent {

  @Input() offices: EntityOffice[] = [];

  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

}
