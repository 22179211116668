import {OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoggerService} from 'app/shared/logger';
import {CommonComponent} from './common.component';

export abstract class AbstractRouteDataComponent extends CommonComponent implements OnInit {

  constructor(
    protected _route: ActivatedRoute,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }
  protected abstract _init(data)

  ngOnInit() {
    // Get route data object
    this._route.data.subscribe((data) => {
      this._init(data);
    })
  }

}
