import {Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {ExtraField} from '../../models';
import {formattedDate} from '../../utils'

export class ExtraFieldCommonComponent implements OnChanges {

  @Input() value: string | number | Date | null;
  @Input() extraField: ExtraField;

  @Input() dateInvalid: boolean = false;
  @Output() dateInvalidChange = new EventEmitter<boolean>();

  values: string[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes && (changes['extraField'] || changes['value'])) {
      this.init();
    }
  }

  init() {
    if (this.extraField) {
      if (this.extraField.type === 'radio' && this.extraField.options && this.extraField.options.values) {
        this.values = (this.extraField.options.values as string).split('|');
        if (this.extraField.options.default && !this.value) {
          this.value = this.extraField.options.default as string;
        }
      }
      if (['date', 'dateValid'].indexOf(this.extraField.type) !== -1 && this.value) {
        setTimeout(() => {this.checkDateInvalid();});
      }
    }
  }
  checkDateInvalid() {
    if (!this.dateInvalid
      && this.extraField
      && this.extraField.type === 'dateValid'
      && (new Date(this.value)).getTime() < (new Date()).getTime()) {
      this.dateInvalid = true;
      this.dateInvalidChange.emit(this.dateInvalid);
      return true
    }
    return false;
  }

}