import {SafeUrl, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared/logger';
import {EntitySecureService} from 'app/shared/services';
import {Entity} from 'app/shared/models';
import {CommonComponent} from './common.component';

export abstract class AbstractEntityServiceComponent extends CommonComponent {

  id: string;
  parent_id: string;
  userProfile;

  // droits:
  permissionC: string[];
  permissionR: string[];
  permissionU: string[];
  permissionD: string[];
  rightR: boolean;
  rightC: boolean;
  rightU: boolean;
  rightD: boolean;
  protected _syncCompleteSubscription: Subscription;

  constructor(
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger);
    this._updatePermissions();
    this.userProfile = this._entityService.getProfile();
    this._syncCompleteSubscription = this._entityService.getSyncStateChange().subscribe((res) => {
      this.onSyncStateChange(res);
    });
  }

  hasRole(role: string) {
    return this._entityService.hasRole(role);
  }
  onSyncStateChange(res) {
    if (this.parent_id) {
      //console.log('AbstractEntityServiceComponent onSyncStateChange ' + this.parent_id, res);
      this._updatePermissions(this.parent_id);
    }
  }

  ngOnDestroy() {
    if (this._syncCompleteSubscription) {
      this._syncCompleteSubscription.unsubscribe();
    }
  }
  isParentSync(parent_id: string) {
    return this._getSecureService().isSynced(parent_id);
  }
  isOffline() {
    return this._getSecureService().isOffline();
  }

  isSynced(id: string) {
    return this._entityService.isSynced(id);
  }

  isSyncing(id: string) {
    return this._entityService.isSyncing(id);
  }
  protected _updatePermissions(parent_id: string = '') {
    this.permissionR = this._getSecureService().getPermissionsRead();
    this.permissionC = this._getSecureService().getPermissionsCreate();
    this.permissionU = this._getSecureService().getPermissionsUpdate();
    this.permissionD = this._getSecureService().getPermissionsDelete();
    const isSync = (!parent_id || this.isParentSync(parent_id));
    this.rightR = this._getSecureService().hasReadPermission();
    this.rightC = this._getSecureService().hasCreatePermission() && isSync;
    this.rightU = this._getSecureService().hasUpdatePermission() && isSync;
    this.rightD = this._getSecureService().hasDeletePermission() && isSync;
  }
  protected _getSecureService(): EntitySecureService {
    return this._entityService;
  }
  /**
   * Get the user id
   */
  getUserId() {
    return this._entityService.getUserId();
  }

  getUserOffices() {
    return this._entityService.getUserOffices();
  }

  hasPermission(right: string) {
    return this._entityService.hasPermission(right);
  }

  protected _initFromRouteParams(params) {
    if (params['id'] && params['id'] !== this._entityService.getUrlAdd()) {
      this.id = params['id'];
    }
    if (params['parent_id']) {
      this.parent_id = params['parent_id'];
    }
    window.scrollTo(0, 0);
  }
  protected _updateList(data, attribute = 'entityList') {
    if (data && this[attribute]) {
      const removed = [
        ...data.filter((e) => (e._deleted)),
        ...this[attribute].filter(e => (data.findIndex(x => this._cacheCompare(x, e)) === -1))
      ];
      removed.forEach(e => {
        const index = this[attribute].findIndex(x => this._cacheCompare(x, e));
        if (index > -1) {
          this[attribute].splice(index, 1);
        }
      });
      data.filter((e) => (!e._deleted)).forEach(e => {
        const index = this[attribute].findIndex(x => (this._cacheCompare(x, e)));
        if (index > -1) {
          if ((this[attribute][index]._rev !== e._rev) || !e._rev) {
            this[attribute][index].copyFrom(e);
            this[attribute][index]._id = e._id;
          }
        } else {
          this[attribute].push(e);
        }
      });
    }
  }
  protected _cacheCompare(a, b) {
    return a._id && a._id === b._id;
  }
  compareFn(a, b): boolean {
    return a._id && a._id === b._id;
  }
  protected _updateListElement(element: Entity, attribute = 'entityList') {
    let change = true;
    const i = this[attribute].findIndex((e) => (this._cacheCompare(e, element)));
    if (i !== -1) {
      if (element._deleted) {
        this[attribute].splice(i, 1);
      } else {
        if ((this[attribute][i]._rev !== element._rev) || !element._rev) {
          this[attribute][i].copyFrom(element);
        } else {
          change = false
        }
      }
    } else {
      this[attribute].push(element);
    }
    return change;
  }

  /**
   * To launch scroll in view
   * @param el
   */
  public scrollTo(el) {
    if (el) {
      setTimeout(() => {el.scrollIntoView({behavior: 'smooth'});});
    }
  }

  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this._entityService.bypassSecurityTrustUrl(value);
  }
  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this._entityService.bypassSecurityTrustHtml(value);
  }
  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this._entityService.bypassSecurityTrustStyle(value);
  }
  bypassSecurityTrustScript(value: string): SafeScript {
    return this._entityService.bypassSecurityTrustScript(value);
  }
  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this._entityService.bypassSecurityTrustResourceUrl(value);
  }
}
