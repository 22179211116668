import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as JSZip from 'jszip';
import {
  promiseAll, EmailDatasModel,
  EmailRecipient,
  EntityEditDialogComponent,
  ErrorDialogComponent,
  formattedDate,
  LoggerService,
  PrintingService,
  EntityDocumentMetadata,
  DocumentService,
  EntityEmailMessages,
  EmailMessagesService,
  EmailService
} from 'app/shared';
import {
  PreventionPlanService
} from 'app/workbook-core';


@Component({
  selector: 'workbook-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class WorkbookExportDialogComponent extends EntityEditDialogComponent {

  @ViewChild('printContainer') printEl: ElementRef;
  name: string = 'export';
  workbookType: string;
  listAnnexes: { metadata: EntityDocumentMetadata, file: File }[] = [];
  listFiles: { metadata: EntityDocumentMetadata, file: File }[] = [];

  emailDatas: EmailDatasModel = new EmailDatasModel();
  public usersPPList: EmailRecipient[] = [];
  public recipientSelectedValue: EmailRecipient[] = [];

  emailMessages: EntityEmailMessages;
  protected _emailMessagesSubscription: Subscription;

  constructor(
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    public dialogRef: MatDialogRef<WorkbookExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService) {

    super(_spinnerService, dialogRef, data, _logger, _entityService);
    this._emailMessagesSubscription = this._emailMessagesService.getSingleOne().subscribe((emailMessages: EntityEmailMessages) => {
      if (emailMessages) {
        this.emailMessages = emailMessages;
        this.emailDatas.signature = emailMessages.signature;
      }
    });
    this._entityService.get(this.id).toPromise()
      .then((entity) => {
        if (entity['name']) {
          this.name = entity['name'];
        }
        if (entity['documentType']) {
          this.workbookType = entity['documentType'];
        }
        this._entityService.getCurrentEmailRecipientList(entity).then((res) => {
          this.usersPPList = [];
          const emails: string[] = [];
          res.forEach((contact) => {
            if (contact.email && emails.indexOf(contact.email) === -1) {
              emails.push(contact.email);
              this.usersPPList.push(contact);
            }
          });
        });
        this.emailDatas.subject = 'Plan de prévention ' + entity.name;
        this.emailDatas.signature = 'Cordialement,\n';
      })
      .catch((error) => {
        this._logger.error('ExportComponent', 'Error during get entity', JSON.stringify(error));
        this._displayError();
      });
    this._documentService.getDocumentMetadataChildList(this.id).toPromise()
      .then((docs) => {
        docs.forEach((doc: EntityDocumentMetadata) => {
          if (doc.context && doc.data_id) {
            this._documentService.getAttachmentFile(doc.data_id, doc.mime, doc.displayName).toPromise()
              .then((file: File) => {
                this.listAnnexes.push({
                  metadata: doc,
                  file: file
                });
              })
              .catch((error) => {
                this._logger.error('ExportComponent', 'Error during get attachment', JSON.stringify(error));
                this._displayError();
              });

          }
        });
      })
      .catch((error) => {
        this._logger.error('ExportComponent', 'Error during get documents', JSON.stringify(error));
        this._displayError();
      });
  }

  ngOnDestroy() {
    if (this._emailMessagesSubscription) {
      this._emailMessagesSubscription.unsubscribe();
    }
  }

  public exportPdf(): void {
    this._spinnerService.show();
    this._printingService.generatePdf(this.printEl).subscribe(
      (res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL);
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('ExportComponent', 'Export pdf error', JSON.stringify(err));
        this._spinnerService.hide();
        this._displayError();
      }
    );
  }


  protected _displayError() {
    this._spinnerService.hide();
    const dialogRefErrDelete: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
      disableClose: false,
      autoFocus: true,
      width: '600px',
    });
    dialogRefErrDelete.componentInstance.errorMessage = 'Une erreur s\'est produite lors de l\'export PDF, veuillez réessayer et sinon contacter le support.';
    dialogRefErrDelete.componentInstance.buttonMessage = 'Fermer';
  }

  public downloadAllDocuments(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;

    this._getDocument(jsZip).subscribe((documentInZip: JSZip) => {
      this._getAllAnnexes(documentInZip).subscribe((annexesInZip: JSZip) => {
        annexesInZip.generateAsync({ type: 'blob' }).then(function(content) {
          that._spinnerService.hide();
          const url = window.URL.createObjectURL(content);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = that.name + '.zip';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        });
      });
    });
  }

  public downloadAllAnnexes(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;

    this._getAllAnnexes(jsZip).subscribe((annexesInZip: JSZip) => {
      annexesInZip.generateAsync({ type: 'blob' }).then(function(content) {
        that._spinnerService.hide();
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = that.name + '.zip';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    });
  }

  protected _getDocument(jszip: JSZip): Observable<JSZip> {
    return new Observable((observer) => {
      this._printingService.generatePdf(this.printEl).subscribe(
        (res) => {
          observer.next(jszip.file(this.name + '.pdf', res));
        },
        (err) => {
          this._logger.error('ExportComponent', 'Export pdf error', JSON.stringify(err));
          this._displayError();
        }, () => {
          observer.complete();
        });

    });

  }

  protected _getAllAnnexes(jszip: JSZip): Observable<JSZip> {
    const promises = [];
    this.listAnnexes.forEach((entry) => {
      if (entry && entry.file && entry.metadata && entry.metadata.name) {
        promises.push(
          jszip.folder('annexes').file(entry.metadata.name + (entry.metadata.ext ? '.' + entry.metadata.ext : ''), entry.file)
          /*
         .catch((error) => {
          this._logger.error('ExportComponent', 'Error during get annexes', error);
          this._displayError();
        });
        */
        );
      }
    });
    return new Observable((subscriber) => {
      promiseAll(promises).then(() => {
        subscriber.next(jszip);
        subscriber.complete();
      });
    });
  }

  /**
   * Format the date for export
   * @param d
   */
  public formattedDate(d: Date = new Date) {
    return formattedDate(d);
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(id: string) {
    const entry = this.listAnnexes.find(e => e && e.metadata && e.metadata._id === id);
    if (entry && entry.metadata && entry.file) {
      this._documentService.downloadFile(entry.file, entry.metadata.displayName);
    }
  }
  public sendEmail(withAnnexes = false) {
    this._spinnerService.show();
    if (this.recipientSelectedValue.length && this.recipientSelectedValue && this.emailDatas) {
      this._spinnerService.show();
      let attachmentPromise = withAnnexes
        ? this._getCompleteZip()
        : this._printingService.generatePdf(this.printEl).toPromise();
      attachmentPromise.then(
        (res) => {
          this._sendEmail(res, this.name + (withAnnexes ? '.zip' : '.pdf'));
        },
        (err) => {
          this._logger.error('ExportComponent', 'Prepare attachment error', JSON.stringify(err));
          this._spinnerService.hide();
          this._displayError();
        }
      );
    }
  }
  _getCompleteZip() {

    return new Promise((resolve, reject) => {
      const that = this;
      const jsZip = new JSZip();
      JSZip.support.nodebuffer = false;

      this._getDocument(jsZip).subscribe((documentInZip: JSZip) => {
        this._getAllAnnexes(documentInZip).subscribe((annexesInZip: JSZip) => {
          annexesInZip.generateAsync({ type: 'blob' }).then((content) => {
            resolve(content);
          },
            (err) => {
              reject(err);
            });
        },
          (err) => {
            reject(err);
          });
      },
        (err) => {
          reject(err);
        });
    });
  }
  protected _sendEmail(file, file_name) {
    this.recipientSelectedValue.forEach((recipient) => {
      const emailDatas = new EmailDatasModel();
      emailDatas.date = new Date();
      emailDatas.subject = this.emailDatas.subject;
      emailDatas.text = this.emailDatas.text;
      emailDatas.signature = this.emailDatas.signature;
      emailDatas.rgpdMail = this.emailDatas.rgpdMail;
      emailDatas.dpoName = this.emailDatas.dpoName;
      emailDatas.dpoMail = this.emailDatas.dpoMail;
      emailDatas.email = recipient.email;
      emailDatas.to_name = recipient.name;
      emailDatas.from_name = this.emailMessages.fromName;
      if (this.emailMessages.fromEmail) {
        emailDatas.from_email = this.emailMessages.fromEmail;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const that = this;
      reader.onloadend = function() {
        that._emailService.sendEmail(emailDatas, '', reader.result, file_name).then((response) => {
          that._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
          if (response.status === 200) {
            that.snackBar.open('Le message a été envoyé', '', {
              duration: 2000
            });
            that.recipientSelectedValue = [];
            that.emailDatas.subject = 'Plan de prévention ' + that.name;
            that.emailDatas.text = '';
            //that.emailDatas.signature = 'Cordialement,\n';
          }
          that._spinnerService.hide();
        }, (err) => {
          that._spinnerService.hide();
          if (err && err.status && err.status === '412' && err.msg && err.msg.indexOf('Connection timeout') === -1) {
            that.snackBar.open('Erreur', 'Erreur dans l\'adresse du destinataire, le message n\'a pas pu être envoyé', {
              duration: 2000
            });
            that._logger.error('EmailService', 'Error 412 : Unable to send email', JSON.stringify(err));
          } else if (err && err.status && err.status === '426') {
            that.snackBar.open('Erreur', 'Pièce jointe trop volumineuse, le message n\'a pas pu être envoyé', {
              duration: 2000
            });
            that._logger.error('EmailService', 'Error 426 : Unable to send email', JSON.stringify(err));
          } else {
            that._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
            that.snackBar.open('Erreur', 'Le message n\'a pas pu être envoyé', {
              duration: 2000
            });
          }
          //this._snackBar('Erreur d\'envoi de la notification, contactez l\'administrateur');
        });
      };

    })
  }

}
