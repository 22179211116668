import {Component, OnDestroy, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoggerService} from 'app/shared';
import {
  ActivityService,
  EntityActivity,
  PreventionPlanService,
  RiskService,
  MeasureService,
  EntityStep
} from 'app/workbook-core';
import {AbstractAnalysableComponent} from '../abstractAnalysable.component';
import {WorkbookActivityDialogComponent} from './activity-dialog.component';
import {WorkbookRisksDialogComponent} from './risks-dialog.component';
import {WorkbookMeasureScopeDialogComponent} from './measureScope-dialog.component';
import {SplitActivityDialogComponent} from './splitActivity-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbook-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: [
    trigger('collapse', [
      state('fold', style({
        height: 0, overflow: 'hidden'
      })),
      state('unfold', style({
        height: '*', overflow: 'hidden'
      })),
      transition('unfold <=> fold', animate('400ms ease-in-out'))
    ])
  ]
})

export class WorkbookActivityComponent extends AbstractAnalysableComponent implements OnChanges, OnDestroy {
  @Input() entity: EntityActivity;

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette activité ?';

  @Output() onClone: EventEmitter<EntityActivity> = new EventEmitter();
  @Output() onSplit: EventEmitter<{activity: EntityActivity, risk_id: string, situation_id: string}> = new EventEmitter();

  unlocked: boolean = false;
  withlock: boolean = false;
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _workbookService: PreventionPlanService,
    protected _riskService: RiskService,
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _entityService: ActivityService
  ) {
    super(_cd, _workbookService, _riskService, _measureService, dialog, snackBar, _logger, _entityService);
  }

  updateRights() {
    super.updateRights();
    //supprimer rightU si toutes terminées
    if (this.steps && this.steps.length && this.steps.filter((s) => (!s.isComplete())).length === 0) {
      this.rightU = false;
    }
    //bouton lock si rightU et plusieurs steps
    this.withlock = this.rightU && this.steps && this.steps.length > 1 && this.steps.filter((s) => (!s.isComplete())).length !== 0;
    //unlock le cas 1 seule step, laisser unlock si existant
    this.unlocked = this.unlocked || (this.rightU && this.steps && this.steps.length === 1);
  }
  unlock() {
    if (this.withlock && !this.unlocked) {
      const risksDialogRef = this.dialog.open(SplitActivityDialogComponent, {
        disableClose: true,
        width: '800px',
        data: {
          id: this.entity._id,
          parent_id: this.entity.parent_id,
          workbook: this.workbook,
          step_id: this.step_id
        }
      });

      risksDialogRef.afterClosed().subscribe((c) => {
        if (c && c !== 'undefined') {
          if (c._id && c._id === this.entity._id) {
            this.unlocked = this.rightU;
    this._cd.markForCheck();
          } else {
            this.onSplit.emit({activity: c, risk_id: '', situation_id: ''});
          }
        }
      });

    }
  }
  lock() {
    this.unlocked = false;
    this._cd.markForCheck();
  }
  protected _afterSave(e) {
    //TODO => necessaire ? pas d'init après MAJ cache ?
    this._updateListElement(this.entity, 'activityList');
    super._afterSave(e);
  }
  getFilteredSteps(): EntityStep[] {
    return (this.stepList && this.stepList.length) ? this.stepList.filter((step) => (step.activities && step.activities.indexOf(this.entity._id) > -1)) : [];
  }

  openRisksDialog(risk_id: string = null, situation_id: string = null) {
    const risksDialogRef = this.dialog.open(WorkbookRisksDialogComponent, {
      disableClose: true,
      width: '1200px',
      height: '90vh',
      data: {
        id: this.entity._id,
        parent_id: this.entity.parent_id,
        workbook: this.workbook,
        selectedRisk: risk_id,
        selectedSituation: situation_id,
        activity: this.entity.name
      }
    });

    risksDialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.entity.copyFrom(c);
        this.refreshRisks();
        this._save();
        //        this.onSave.emit(this.entity);
      }
    });
  }
  openMeasureScopeDialog(measure) {
    const dialogRef = this.dialog.open(WorkbookMeasureScopeDialogComponent, {
      disableClose: true,
      minWidth: '900px',
      data: {
        id: this.entity._id,
        parent_id: this.workbook._id,
        measure_id: measure._id,
        activityOEEx: this.entity.companies.map((e) => (e.name)).sort(),
        stepOEEx: this.getScopeList(measure, true)
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      const c = r[0];
      const newScope = r[1];
      if (c && c !== 'undefined') {
        //this.entity.copyFrom(c);
        /*
        if (newScope) {
          Object.keys(newScope).forEach((step_id) => {
            let _step = this.steps.find((s) => (s._id === step_id));
            if (_step && _step.scopedMeasures) {
              // console.log(newScope[step_id]);
              if (newScope[step_id]) {
                if (!_step.scopedMeasures[this.entity._id]) {
                  _step.scopedMeasures[this.entity._id] = {};
                }
                _step.scopedMeasures[this.entity._id][measure._id] = newScope[step_id];
                //ajout des scopes
              } else {
                //suppression des scopes
                if (_step.scopedMeasures[this.entity._id] && _step.scopedMeasures[this.entity._id][measure._id]) {
                  delete _step.scopedMeasures[this.entity._id][measure._id];
                  if (Object.keys(_step.scopedMeasures[this.entity._id]).length === 0) {
                    delete _step.scopedMeasures[this.entity._id];
                  }
                }
              }
            }
          });
        }
        */
        //this.refreshSteps();
        //this.refreshRisks();
        //this._save();
        //this.onSave.emit(this.entity);
      }
    });
  }

  editRisks(risk_id: string = null, situation_id: string = null) {
    if (this.stepList.filter((s) => (s.activities.indexOf(this.entity._id) !== -1)).length === 1) {
      this.openRisksDialog(risk_id, situation_id);
    } else {
      const risksDialogRef = this.dialog.open(SplitActivityDialogComponent, {
        disableClose: true,
        width: '800px',
        data: {
          id: this.entity._id,
          parent_id: this.entity.parent_id,
          workbook: this.workbook,
          step_id: this.step_id
        }
      });

      risksDialogRef.afterClosed().subscribe((c) => {
        if (c && c !== 'undefined') {
          if (c._id && c._id === this.entity._id) {
            this.openRisksDialog(risk_id, situation_id);
          } else {
            this.onSplit.emit({activity: c, risk_id: risk_id, situation_id: situation_id});
          }
        }
      });

    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(WorkbookActivityDialogComponent, {
      disableClose: true,
      minWidth: '600px',
      width: '900px',
      data: {
        id: this.entity._id,
        parent_id: this.parent_id ? this.parent_id : this.entity.parent_id,
        showGroup: !!this.entity.group,
        workbook: this.workbook
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.onSave.emit(this.entity);
      }
    });
  }
  clone() {
    this.onClone.emit(this.entity);
  }
}
