import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';
import {LoggerService, PermissionsService, EntityFormComponent, ConfirmDialogComponent} from 'app/shared';
import {SituationService, EntitySituation, EntityMeasure, MeasureService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-situation',
  templateUrl: './situation.component.html'
  , styleUrls: ['./situation.component.scss']
})
export class SituationComponent extends EntityFormComponent {

  entity: EntitySituation;

  measureListSubscriber: Subscription;
  measureList: {[risk_id: string]: EntityMeasure[]} = {};
  measures: EntityMeasure[] = [];
  measureName: {[measure_id: string]: string} = {};
  newMeasure: string = '';
  hideMeasures: boolean = true;
  selectMeasures: boolean = false;

  constructor(
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: SituationService

  ) {
    super(dialog, _logger, _entityService);
  }

  ngOnDestroy() {
    if (this.measureListSubscriber) {
      this.measureListSubscriber.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.selectMeasures = !this.entity.measures.length;
    }
    if (!this.measureListSubscriber) {
      this.measureListSubscriber = this._measureService.getAll().subscribe((list) => {
        if (list) {
          this.measureList = {};
          if (this.entity) {
            this.measureList[this.entity._id] = [];
            this.measureList[this.entity.risk_id] = [];
          }
          list.forEach((m) => {
            this.measureName[m._id] = m.name;
            if (m.type === '' || m.type === 'global') {
              if (!this.measureList[m.risk_id]) {
                this.measureList[m.risk_id] = [];
              }
            } else if (!this.measureList[m.type]) {
              this.measureList[m.type] = [];
            }
            this.measureList[(m.type === '' || m.type === 'global') ? m.risk_id : m.type].push(m);
          });
          if (this.entity) {
            this._updateList([...this.measureList[this.entity._id], ...this.measureList[this.entity.risk_id]].sort((a, b) => a.name.localeCompare(b.name)), 'measures');
          }
        }
      });
    }
  }
  removeMeasure(measure) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = "Êtes-vous sûr de vouloir supprimer cette mesure ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._measureService.remove(measure);
        let i = this.entity.measures.indexOf(measure._id);
        if (i !== -1) {
          this.entity.measures.splice(i, 1);
          this._save().then((e) => {
            this._afterSave(e);
          });
        }
      }
    });
  }
  addMeasure() {
    let addedMeasure = this._measureService.getNewEntity({
      type: this.entity._id,
      risk_id: this.entity.risk_id,
      name: this.newMeasure
    });
    //console.log('add specific measure', addedMeasure);
    this._measureService.save(addedMeasure).then((result) => {
      //console.log('added specific measure', addedMeasure);
      this.entity.measures.push(addedMeasure._id);
      //console.log('save situation', this.entity);
      this._save().then((e) => {
        //console.log('saved situation', this.entity);
        this._afterSave(e);
        this.newMeasure = '';
      });
    })
  }
  isSelectedMeasure(id: string) {
    return (this.entity && this.entity.measures.indexOf(id) !== -1);
  }
  switchMeasure(event) {
    if (event && event.source && event.source.value) {
      if (event.checked) {
        if (this.entity.measures.indexOf(event.source.value) === -1) {
          this.entity.measures.push(event.source.value);
          this._save().then((e) => {
            this._afterSave(e);
          });
        }
      } else {
        let i = this.entity.measures.indexOf(event.source.value);
        if (i !== -1) {
          this.entity.measures.splice(i, 1);
          this._save().then((e) => {
            this._afterSave(e);
          });
        }
      }
    }
  }
  dropMeasure(event: CdkDragDrop<string[]>) {
    const prevIndex = this.entity.measures.findIndex((d) => (event.item && event.item.data && event.item.data === d));

    if (prevIndex !== -1 && event.previousContainer === event.container && prevIndex !== event.currentIndex) {
      this.moveMeasure(prevIndex, event.currentIndex)
      /*
      moveItemInArray(this.entity.measures, prevIndex, event.currentIndex);
      this._save().then((e) => {
        this._afterSave(e);
      });
      */
    }
  }
  moveMeasure(fromIndex, ToIndex) {
    moveItemInArray(this.entity.measures, fromIndex, ToIndex);
    this._save().then((e) => {
      this._afterSave(e);
    });

  }
}
