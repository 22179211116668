import {Component, OnDestroy, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import {EntityDocumentMeta, EmailRecipient, SignableStatus, EntitySignable} from 'app/shared';
import {OfficeService, DefaultVisibilityService, EntityDefaultVisibility} from 'app/workbook-core';

@Component({
  selector: 'app-workbook-email-attachment',
  templateUrl: './emailAttachment.component.html',
  styleUrls: ['./emailAttachment.component.scss']
})
export class EmailAttachmentComponent {
  attachment: string = '';
  recipient: EmailRecipient;
  documents: EntityDocumentMeta[];
  entity: EntitySignable;
  signatory: boolean;
  disableZip: boolean;

  constructor(
    protected _defaultVisibilityService: DefaultVisibilityService,
    protected _officeService: OfficeService,
    public dialogRef: MatDialogRef<EmailAttachmentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
    //,protected _licence: LicencesService
  ) {
    if (data) {
      if (data['recipient']) {
        this.recipient = data['recipient'];
        if (this.recipient.zip && !this.recipient.user_id) {
          this.attachment = 'zip'
        } else if (this.recipient.pdf || (this.recipient.zip && this.recipient.user_id)) {
          this.attachment = 'pdf';
        }
        if (data['documents']) {
          this.documents = data['documents'];
        }
        if (data['entity']) {
          this.entity = data['entity'];
        }
        this.signatory = !!data['signatory'];

        this.disableZip = this.entity
          && !this.recipient.user_id
          && ((this.entity.status < SignableStatus.SIGNING)
            || (!this.signatory && this.entity.status === SignableStatus.SIGNING && !this.entity.withoutSignatories));
      }
    }
  }
  submit() {
    this.dialogRef.close(this.recipient);
  }
  radioChange(event) {
    this.recipient.zip = (this.attachment === 'zip');
    this.recipient.pdf = (this.attachment === 'pdf');

  }
}

