import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoadingService,
  AuthService,
  Auth0ConfigService,
  PermissionsService,
  CommonComponent,
  LoggerService,
  DbFilteredService
} from 'app/shared';
import {AppConfig} from './app.config';
import {slideInAnimation} from './animations';
//import {InitRepositoryService} from 'app/shared/db/initRepository.service';


@Component({
  selector: 'app-root',
  providers: [],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent extends CommonComponent implements OnInit {

  error: boolean;
  message: string;

  static LABEL_LOADING = "chargement de l'application";

  constructor(
    protected _dbFilteredService: DbFilteredService,
    protected _loadingService: LoadingService,
    protected _snackBar: MatSnackBar,
    private _router: Router,
    public auth: AuthService,
    public permissionsService: PermissionsService,
    private _auth0Config: Auth0ConfigService,
    //private _initRepositoryService: InitRepositoryService,
    private _spinnerService: Ng4LoadingSpinnerService,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }

  ngOnInit() {

    this._spinnerService.show();
    const _loadingId = this._loadingService.addLoad(AppComponent.LABEL_LOADING);
    let url = '/home';
    let initial = true;
    this._router.events.subscribe((e: any) => {
      if (initial) {
        let i = e.url.indexOf('uri=');
        let j = e.url.indexOf('#access_token');
        if (i > 0 && j > 0) {
          url = decodeURIComponent(e.url.substring(i + 4, j));
        } else if (e.url !== '/' && j !== 1) {
          let k = e.url.indexOf('?');
          if (k !== -1) {
            url = e.url.substring(0, i);
          } else {
            url = e.url;
          }
        }
        initial = false;
      }
    });
    if (!this._auth0Config.data) {
      this.error = true;
      this.message = 'unable to retrieve config informations.'
    } else {
      this.auth.handleAuthentication()
        .then((authenticated) => {
          if (!authenticated && !this.auth.isAuthenticated()) {
            this.auth.login();
          } else {
            this.auth.retrieveProfile()
              .then((p) => {
                //console.log('app retrieveProfile', p);
                this.permissionsService.init(AppConfig.PERMISSIONS).then((x) => {
                  //console.log('app permissionsService.init', x);
                  if (!this.permissionsService.canConnect()) {
                    this.auth.login();
                  } else {
                    this._dbFilteredService.load().then(() => {
                      if (url === '/home') {
                        url = this.permissionsService.hasRole('PP_EEX') ? '/docs' : '/dashboard';
                      }
                      this._router.navigate([url]);
                      this._spinnerService.hide();
                      this._loadingService.removeLoad(_loadingId);
                    });
                  }
                })
                  .catch((err) => {
                    this._logger.error('AppComponent', 'Unable to init permissions', JSON.stringify(err));
                  });
              })
              .catch((err) => {
                //this.auth.renewToken();
                this.auth.login();
                this._logger.error('AppComponent', 'Unable to retrieve profile', JSON.stringify(err));
              });
          }
        })
        .catch(err => {
          this.error = true;
          this._logger.error('AppComponent', 'Error handleAuthentication', JSON.stringify(err));
          // console.log('Error handleAuthentication', err);
          // logout and redirect to clean old token access
          this.auth.logout();
          // throw err;
        })
    }
    //this.auth.renewToken();
    // check if the lists of config datas exist, if not create them
    //this._initRepositoryService.init();
  }

  // TODO : test v1.1 for animation with route
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }
}


