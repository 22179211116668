import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {ConfirmDialogComponent, LoggerService, EntitySimpleListComponent, } from 'app/shared';
import {RiskService, EntityRisk, MeasureService, EntityMeasure} from 'app/workbook-core/';

@Component({
  selector: 'app-repository-risk-list',
  templateUrl: './riskList.component.html',
  styleUrls: ['./riskList.component.scss']
})

export class RiskListComponent extends EntitySimpleListComponent {

  entityList: EntityRisk[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce risque ?';
  addConfirmMessage = 'Nouveau risque créé';

  protected _measuresSubscription: Subscription;
  usedRisks: string[] = [];
  risksLoaded: boolean = false;
  constructor(
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: RiskService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this._measuresSubscription = this._measureService.getAll().subscribe((list) => {
      this.usedRisks = [];
      if (list) {
        // TODO: order ?
        list.forEach((measure: EntityMeasure) => {
          if (!this.isUsedRisk(measure.risk_id)) {
            this.usedRisks.push(measure.risk_id);
          }
        });
      }
      this.risksLoaded = true;
    });
  }

  ngOnDestroy() {
    if (this._measuresSubscription) {
      this._measuresSubscription.unsubscribe();
    }
  }
  isUsedRisk(id) {
    return this.usedRisks.indexOf(id) !== -1;
  }
  removeElement(entity) {
    if (!this.isUsedRisk(entity._id)) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this._entityService.remove(entity).then((res) => {
            this._afterRemoveElement(entity);
          });
        }
      });
    }
  }
}
