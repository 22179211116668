import {Pipe, PipeTransform} from '@angular/core';
import * as marked from 'marked';

@Pipe({
  name: 'markdown'
})

export class MarkdownPipe implements PipeTransform {
  transform(value: any, args?: any[]): any {
    if (value && value.length > 0) {
      const _marked = marked.marked;
      _marked.use({
        async: false,
        pedantic: false,
        gfm: true,
        breaks: true
      });
      return this.removeUniquePTag(_marked.parse(value.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, "")));
    }
    return value;
  }
  removeUniquePTag(input: string): string {
    const pattern = /<p>([^<]+)<\/p>/g;
    const matches = input.match(pattern);

    if (matches && matches.length === 1) {
      const content = matches[0].replace(pattern, '$1');
      return content;
    }

    return input;
  }

}