import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import {
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService, EmailMessages,
  Contact, Signature
} from 'app/shared';
import {
  PreventionPlanService,
  SignablePreventionPlanService
} from '../services';
import {
  FirePermitService,
  EntityFirePermit, EntityStep, Implementation, FPStatus
} from '../../workbook';
import { AbstractPdpExportComponent } from './abstractPdPExport.component';

@Component({
  selector: 'app-firePermit-export',
  templateUrl: './firePermitExport.component.html',
  styleUrls: ['./firePermitExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FirePermitExportComponent extends AbstractPdpExportComponent {

  SIGN_NOTIFY = EmailMessages.SIGN_SFP_CONTACT;
  entity: EntityFirePermit;

  signableStatus = FPStatus.LABELS;
  signableStatusDescritpions = FPStatus.DESCRIPTIONS;

  implementation: Implementation;
  withHotSpotWorks: boolean = false;
  withEquipments: boolean = false;
  withAttestations: boolean = false;
  originatorSignup: Signature[] = [];
  performerSignup: Signature[] = [];
  supervisorSignup: Signature[] = [];
  verifierSignup: Signature[] = [];
  operatorSignup: Signature[] = [];

  constructor(
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: FirePermitService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (this.entity) {

      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._preventionPlanService.stepService.getOne(this.entity.step).subscribe((data) => {
        if (data) {
          this.implementation = data.implementation;
        }
      });
      Object.keys(this.entity.hotSpotWorks).forEach((e) => { this.withHotSpotWorks = this.withHotSpotWorks || this.entity.hotSpotWorks[e] });
      Object.keys(this.entity.equipments).forEach((e) => { this.withEquipments = this.withEquipments || this.entity.equipments[e] });
      Object.keys(this.entity.attestations).forEach((e) => { this.withAttestations = this.withAttestations || this.entity.attestations[e] });
    }

  }
  refreshSignatories() {
    this.originatorSignup = this.getOriginatorSignup();
    this.performerSignup = this.getPerformerSignup();
    this.supervisorSignup = this.getSupervisorSignup();
    this.verifierSignup = this.getVerifierSignup();
    this.operatorSignup = this.getOperatorSignup();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }

  showOeex(id: string) {
    return this.entity && [
      this.entity.originator._id,
      this.entity.performer._id,
      this.entity.supervisor._id,
      this.entity.verifier._id
    ].indexOf(id) !== -1;
  }
  showInstructions(list) {
    return !!(list && list.length && list.filter((e) => (e.status)).length > 0);
  }
  _isSignatory(contact: Contact) {
    return contact.signatoryPermit;
  }
  getOriginatorSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'ORIGINATOR'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.originator) ? this.entity.originator._id : '',
      company: (this.entity && this.entity.originator) ? this.entity.originator.name : '',
      companyType: 'ORIGINATOR',
      contact_id: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.id : '',
      email: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.email : '',
      name: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.name : '',
      job: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.job : '',
      user_id: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.user_id : ''
    })];
  }
  getPerformerSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'PERFORMER'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.performer) ? this.entity.performer._id : '',
      company: (this.entity && this.entity.performer) ? this.entity.performer.name : '',
      companyType: 'PERFORMER',
      contact_id: (this.entity && this.entity.performerContact) ? this.entity.performerContact.id : '',
      email: (this.entity && this.entity.performerContact) ? this.entity.performerContact.email : '',
      name: (this.entity && this.entity.performerContact) ? this.entity.performerContact.name : '',
      job: (this.entity && this.entity.performerContact) ? this.entity.performerContact.job : '',
      user_id: (this.entity && this.entity.performerContact) ? this.entity.performerContact.user_id : ''
    })];
  }
  getSupervisorSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'SUPERVISOR'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.supervisor) ? this.entity.supervisor._id : '',
      company: (this.entity && this.entity.supervisor) ? this.entity.supervisor.name : '',
      companyType: 'SUPERVISOR',
      contact_id: (this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.id : '',
      email: (this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.email : '',
      name: (this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.name : '',
      job: (this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.job : '',
      user_id: (this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.user_id : ''
    })];
  }
  getVerifierSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'VERIFIER'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.verifier) ? this.entity.verifier._id : '',
      company: (this.entity && this.entity.verifier) ? this.entity.verifier.name : '',
      companyType: 'VERIFIER',
      contact_id: (this.entity && this.entity.verifierContact) ? this.entity.verifierContact.id : '',
      email: (this.entity && this.entity.verifierContact) ? this.entity.verifierContact.email : '',
      name: (this.entity && this.entity.verifierContact) ? this.entity.verifierContact.name : '',
      job: (this.entity && this.entity.verifierContact) ? this.entity.verifierContact.job : '',
      user_id: (this.entity && this.entity.verifierContact) ? this.entity.verifierContact.user_id : ''
    })];
  }
  getOperatorSignup() {
    let existing = [];
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      existing = this.entity.signatures.filter((s) => (s.companyType === 'OPERATOR'));
    }
    if (this.entity && this.entity.operators && this.entity.operators.length) {
      this.entity.operators.forEach((operator) => {
        if ((operator.name || operator.job) && existing.findIndex((e) => ((e.name === operator.name) && (e.job === operator.job))) === -1) {
          existing.push(this._preventionPlanService.signatureService.getNewEntity({
            parent_id: this.entity ? this.entity.parent_id : '',
            company_id: (this.entity && this.entity.performer) ? this.entity.performer._id : '',
            company: (this.entity && this.entity.performer) ? this.entity.performer.name : '',
            companyType: 'OPERATOR',
            contact_id: operator.contact_id,
            email: operator.email,
            name: operator.name,
            job: operator.job,
            user_id: operator.user_id
          }));
        }
      });
    }
    return existing;
  }
}
