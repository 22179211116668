import {Component, Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService, promiseAll} from 'app/shared';
import {
  AuditService,
  EntityPreventionPlan,
  EntityAudit,
  PreventionPlanService,
  EntityStep,
  EntitySite,
  Implementation,
  Activity, DocumentationService,
  RiskEntry, EntityActivity
} from 'app/workbook-core';
import {AbstractSignableInfoDialogComponent} from '../abstractSignableInfo-dialog.component';
import {AuditInfoComponent} from './auditInfo.component'

@Component({
  selector: 'app-signable-audit-info-dialog',
  templateUrl: './auditInfo-dialog.component.html',
  styleUrls: ['./auditInfo-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class AuditInfoDialogComponent extends AbstractSignableInfoDialogComponent implements AfterViewInit {
  entityForm: AuditInfoComponent;
  entity: EntityAudit;
  workbook: EntityPreventionPlan;
  step: EntityStep;
  //implementation: Implementation;
  selectedStep: string;
  activities: Activity[];

  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  stepList: {[step_id: string]: EntityStep} = {};
  protected _stepsSubscription: Subscription;

  stepActivities: EntityActivity[] = [];
  protected _stepActivitiesSubscription: Subscription;
  stepRisks: {[activity_id: string]: {[risk_name: string]: RiskEntry}} = {};
  stepRiskSituationName: {[situation_id: string]: string} = {};
  stepActivitiesLoaded: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _preventionPlanService: PreventionPlanService,
    public dialogRef: MatDialogRef<AuditInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: AuditService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        if (data['step']) {
          this.step = data['step'];
        }
      }
      if (!this.id) {
        if (this.step) {
          this.selectedStep = this.step._id;
        }
      } else {
        if (data['selectedStep']) {
          this.selectedStep = data['selectedStep'];
        }
      }
      this._stepsSubscription = this._preventionPlanService.getStepList(this.parent_id).subscribe((list: EntityStep[]) => {
        if (list) {
          this.stepList = {};
          let _templates: EntityStep[] = [];
          let _steps: EntityStep[] = [];
          list
            .forEach((e) => {
              this.stepList[e._id] = e;
              if (e.template) {
                _templates.push(e);
              } else {
                _steps.push(e);
              }
            });
          this.templates = _templates//.filter((e) => (e.implementation))
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
          this.steps = _steps//.filter((e) => (e.implementation || (e.template_id && this.stepList[e.template_id] && this.stepList[e.template_id].implementation)))
            .sort((a, b) =>
              (this.workbook && this.workbook.type === 'annual') ?
                ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime())
                : ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
            );
          this.selectStep();
        }
      });
      this._stepActivitiesSubscription = this._preventionPlanService.getActivityList(this.parent_id).subscribe((list) => {
        if (list) {
          this.stepRiskSituationName = {};
          this.stepActivities = list;
          this.stepActivities.forEach((activity: EntityActivity) => {
            if (activity.measures) {
              const entry: {[risk_situation_id: string]: RiskEntry} = {};
              activity.risks_situations.forEach((risk_situation_id) => {
                const situation = activity.situations.find((s) => (s._id === risk_situation_id));
                const risk = activity.risks.find((s) => (s._id === (situation ? situation.risk_id : risk_situation_id)));
                if (risk) {
                  this.stepRiskSituationName[situation ? situation._id : risk._id] = (risk.name + (situation ? ' - ' + situation.name : ''));
                  entry[risk_situation_id] = new RiskEntry(
                    this.stepRiskSituationName[situation ? situation._id : risk._id],
                    activity.measures.filter((measure) => (
                      measure.risk_id === risk._id
                      && (situation
                        ? measure.type === situation._id
                        : (measure.type === '' || measure.type === 'local' || measure.type === 'global')
                      )
                    )),
                    (situation && situation.sir),
                    (situation && situation.fp)
                  );
                }
              });
              this.stepRisks[activity._id] = entry;
            }
          });
          this.stepActivitiesLoaded = true;
          this.selectStep();
        }
      });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.selectStep();
  }

  ngOnDestroy() {
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }
  selectStep() {
    if (this.workbook && this.workbook.simple) {
      if (this.steps && this.steps.length) {
        this.selectedStep = this.steps[0]._id;
      } else if (this.templates && this.templates.length) {
        this.selectedStep = this.templates[0]._id;
      }
    }
    if (this.entityForm && this.selectedStep && this.stepList[this.selectedStep]) {
      if (!this.id && this.entityForm.entity) {
        this.entityForm.entity.step = this.selectedStep;
      }
      this.activities = [];
      if (this.stepList[this.selectedStep]) {
        if (this.stepList[this.selectedStep].implementation) {
          this.step = this.stepList[this.selectedStep];
          this.entityForm.entity.step_name = this.step.name;
          this.entityForm.entity.pdp_id = this.step.implementation.pdp_id;
          this.entityForm.entity.pdp_name = this.step.implementation.pdp_name;
        } else if (this.stepActivitiesLoaded) {
          this.step.implementation = Implementation.generate(this.stepList[this.selectedStep],
            this.stepActivities,
            this.stepRisks
          );
          this.entityForm.entity.step_name = this.stepList[this.selectedStep].name;
        }
      }
      if (this.step.implementation) {
        if (!this.id && this.entityForm.entity) {
          if (this.step && this.step.site) {
            this.entityForm.entity.site = new EntitySite(this.step.site);
          }
          this.entityForm.entity.zone = this.step.point ? this.step.point : '';
        }
        this.activities = this.step.implementation.step.activities ?
          this.step.implementation.step.activities
            .map((activity_id) => (this.step.implementation.activities[activity_id]))
          : [];

      }

    }
  }
  save() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.entity._id) {
      if (this.userProfile) {
        this.entityForm.entity.editor = this._entityService.getUserName();
        this.entityForm.entity.editorEmail = this.userProfile.email;
        this.entityForm.entity.editorDate = new Date();
      }
      if (this.workbook && this.workbook._id) {
        this._entityService.getNewCode(this.workbook._id).then((code) => {
          this.entityForm.entity.parent_code = this.workbook.code;
          this.entityForm.entity.code = code;
          this.entityForm.entity.name = this.entityForm.entity.getName();
          this.saveWithFiles();
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      }
    } else {
      this.saveWithFiles();
    }
  }
  saveWithFiles() {
    let fileSavers = [];
    if (this.entityForm && this.entityForm.file && Object.keys(this.entityForm.file).length) {
      fileSavers = this.entityForm.getFileSavers();
    }
    promiseAll(fileSavers).then((res) => {
      //console.log(res);
      this.entityForm.setFiles(res);
      super.save();
    });
  }
}
