import { EntitySignable, EntityCompany, Contact } from 'app/shared';
import { EntitySite } from '../../repository/models/entitySite.model';

export class EntityDeed extends EntitySignable {

  public title: string;
  public summary: string;

  public editor: string;
  public editorEmail: string;
  public editorDate: Date;
  public description: string;
  public reference: string;

  //donneur d'ordre
  public originator: EntityCompany;
  public originatorContact: Contact;
  //entreprise intervenante
  public performer: EntityCompany;
  public performerContact: Contact;

  //lieu et dates
  public dateStart: Date;
  public dateEnd: Date;
  public hourStart: string;
  public hourEnd: string;

  public point: string;
  public site: EntitySite;

  public step: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.title = (data && data.title) ? data.title : '';
    this.summary = (data && data.summary) ? data.summary : '';

    this.editor = (data && data.editor) ? data.editor : '';
    this.editorEmail = (data && data.editorEmail) ? data.editorEmail : '';
    this.editorDate = (data && data.editorDate) ? new Date(data.editorDate) : new Date();
    this.description = (data && data.description) ? data.description : '';
    this.reference = (data && data.reference) ? data.reference : '';

    this.originator = (data && data.originator) ? new EntityCompany(data.originator) : new EntityCompany();
    this.originatorContact = (data && data.originatorContact) ? Object.assign({}, data.originatorContact) : new Contact();

    this.performer = (data && data.performer) ? new EntityCompany(data.performer) : new EntityCompany();
    this.performerContact = (data && data.performerContact) ? Object.assign({}, data.performerContact) : new Contact();

    this.dateStart = (data && data.dateStart) ? new Date(data.dateStart) : new Date();
    this.dateEnd = (data && data.dateEnd) ? new Date(data.dateEnd) : new Date();
    this.hourStart = (data && data.hourStart) ? data.hourStart : '';
    this.hourEnd = (data && data.hourEnd) ? data.hourEnd : '';
    this.point = (data && data.point) ? data.point : '';
    this.site = (data && data.site) ? new EntitySite(data.site) : new EntitySite();
    this.step = (data && data.step) ? data.step : '';

  }
}
