import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-workbook-documentadddSelect',
  templateUrl: './documentAddSelect-dialog.component.html',
  styleUrls: ['./documentAddSelect-dialog.component.scss']
})
export class DocumentAddSelectDialogComponent {

  addType: number = 1;

  constructor(
    public dialogRef: MatDialogRef<DocumentAddSelectDialogComponent>,
    public dialog: MatDialog
  ) {
  }
  valid() {
    this.close(this.addType);
  }
  close(value = null) {
    this.dialogRef.close(value);
  }
}