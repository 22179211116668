import { Component, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MatAutocompleteSelectedEvent, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { WorkbookStepService, EntitySite, EntityZone } from 'app/workbook-core';
import { LoggerService, AbstractEntityServiceComponent, comparableString } from 'app/shared';


@Component({
  selector: 'app-repository-zone-dialog',
  templateUrl: './zone-dialog.component.html',
  styleUrls: ['./zone-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class RepoZoneDialogComponent extends AbstractEntityServiceComponent implements OnDestroy {

  public newZone: boolean;
  public allZones: boolean;
  public allowAllZones: boolean;
  public zone: EntityZone;
  public site: EntitySite;
  public zoneNames: string[];

  constructor(
    public dialogRef: MatDialogRef<RepoZoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(_logger, _entityService);
    this.newZone = !(data && data.zone);
    this.zone = new EntityZone((data && data.zone) ? data.zone : null);
    this.site = (data && data.site) ? data.site : null;
    this.zoneNames = (data && data.site && data.site.zones)
      ? data.site.zones
        .map((e) => (e.name))
        .filter((name) => ((!name && this.zone && !this.zone.name) || (name && (!data.zone || (this.zone && this.zone.name && name !== this.zone.name)))))
      : [];
    this.allowAllZones = (data && data.allowAllZones);
    this.allZones = this.allowAllZones && (!this.zone || !this.zone.name);
  }
  /*
  ngAfterViewInit() {
    if (this.inputZone) {
      setTimeout(() => {
        this.inputZone.nativeElement.focus();
      });
    }
  }
  */
  changeAllZone(event) {
    if (event && event.checked) {
      this.zone.name = '';
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.zone);
    }
  }

}
