import {Inject, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService, EntityEditScrollDialogComponent} from 'app/shared';
import {PreventionPlanService} from 'app/workbook-core';
import {WorkbookComponent} from './workbook.component';

export abstract class AbstractWorkbookDialogComponent extends EntityEditScrollDialogComponent {
  entityForm: WorkbookComponent;
  public workbookType: string;
  public workbookSimple: boolean;

  today: Date;

  title: string = '';

  constructor(
    public snackBar: MatSnackBar,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AbstractWorkbookDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(_spinnerService, _cdRef, dialogRef, data, _logger, _entityService);
    if (data && data['workbook']) {
      this.workbookType = data['workbook']['type'];
      this.workbookSimple = !!data['workbook']['simple'];
    }
  }

  save() {
    if (this.entityForm && this.entityForm.entity) {
      this._spinnerService.show();
      if (!this.entityForm.entity._id) {
        // Create the reference code for this document
        this._entityService.getNewCode().then((code) => {
          this.entityForm.entity.code = code;
          this.entityForm.entity.name = this._entityService.getName(this.entityForm.entity);
          super.save();
        }).catch((error) => {
          if (error && error.status && error.status === 409) {
            this._entityService.getNewCode().then((code) => {
              this.entityForm.entity.code = code;
              this.entityForm.entity.name = this._entityService.getName(this.entityForm.entity);
              super.save();
            }).catch((err) => {
              this._spinnerService.hide();
              this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
                duration: 2000
              });
            });
          } else {
            this._spinnerService.hide();
            this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
              duration: 2000
            });
          }
        });
      } else {
        super.save();
      }
    }
  }
  protected _afterSave(res) {
    this._spinnerService.hide();
    super._afterSave(res);
    if (res && res.ok && res.id) {
      this._router.navigateByUrl(this._entityService.getUrlWorkbook('pp', res.id));
    }
  }
}
