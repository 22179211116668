import {addZero, formattedDateFile} from 'app/shared/utils'
import {EntityOwned} from './entity.model';


export abstract class EntityCodable extends EntityOwned {
  static PREFIX = '';
  name: string;
  parent_code: number;
  code: number;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = data && data.name ? data.name : '';
    this.code = data && data.code ? data.code : 0;
    this.parent_code = data && data.parent_code ? data.parent_code : 0;
  }

  getName(): string {
    const constructorClass = (<typeof EntityCodable> this.constructor);
    return constructorClass.getNameOf(this);
  }

  static getNameOf(entity: EntityCodable): string {

    const prefix = (<typeof EntityCodable> entity.constructor).PREFIX;
    return prefix
      + (entity.parent_code ? addZero(entity.parent_code, 3) : '')
      + '-' + addZero(entity.code, 3);
  }

  getFileName(): string {
    const constructorClass = (<typeof EntityCodable> this.constructor);
    return constructorClass.getFileNameOf(this);
  }


  static getFileNameOf(entity: EntityCodable): string {
    const constructorClass = (<typeof EntityCodable> entity.constructor);
    return constructorClass.getNameOf(entity)
      + '-' + formattedDateFile(entity.updatedAt);
  }

}
