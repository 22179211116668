import { EntityCompany, Contact, formattedDateFile, SignableStatus } from 'app/shared';
import { EntityPermit } from '../../workbook/models/entityPermit.model';
import { Instruction, SafetyInstruction } from '../../repository/models/entityFirePermitContent.model';

export class FPState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Cloturé',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est signée',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est valide',
    'L\'edition est cloturée',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return FPState.LABELS;
  }
  static getDescriptions() {
    return FPState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return FPState.LABELS[status] ? FPState.LABELS[status] : 'state' + status;
  }
  static getDescription(status: number) {
    return FPState.DESCRIPTIONS[status] ? FPState.DESCRIPTIONS[status] : 'state: ' + status;
  }
}

export class FPStatus extends SignableStatus {
  static SIGNING: number = 2;
  static SIGNED: number = 3;
  static OUTDATED: number = 4;
  static LABELS: string[] = [
    'En préparation',
    'Préparé',
    'À signer',
    'Signé',
    'Cloturé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est cloturée'
  ];

  static getLabels(withSignatories: boolean = true) {
    return FPStatus.LABELS;
  }
  static getDescriptions(withSignatories: boolean = true) {
    return FPStatus.DESCRIPTIONS;
  }
  static getLabel(status: number, withSignatories: boolean = true) {
    return FPStatus.LABELS[status] ? FPStatus.LABELS[status] : 'status' + status;
  }
  static getDescription(status: number, withSignatories: boolean = true) {
    return FPStatus.DESCRIPTIONS[status] ? FPStatus.DESCRIPTIONS[status] : 'status: ' + status;
  }
}
export class EntityFirePermit extends EntityPermit {
  static PREFIX: string = 'PF';

  //surveillant pendant travaux
  public supervisor: EntityCompany;
  public supervisorContact: Contact;
  //surveillant après travaux
  public verifier: EntityCompany;
  public verifierContact: Contact;

  public detail: string;

  //liste des travaux selectionnables
  public hotSpotWorks: { [name: string]: boolean };
  //liste du materiel selectionnables
  public equipments: { [name: string]: boolean };
  //liste de documents selectionnables
  public attestations: { [name: string]: boolean };

  public withAtex: boolean;
  public withAtexDescription: string;

  public nextToAtex: boolean;
  public nextToAtexDescription: string;

  public warningDevices: string[];
  public interventionDevices: string[];
  public emergency: string[];

  public safetyInstructions: SafetyInstruction[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.supervisor = (data && data.supervisor) ? new EntityCompany(data.supervisor) : new EntityCompany();
    this.supervisorContact = (data && data.supervisorContact) ? Object.assign({}, data.supervisorContact) : new Contact();

    this.verifier = (data && data.verifier) ? new EntityCompany(data.verifier) : new EntityCompany();
    this.verifierContact = (data && data.verifierContact) ? Object.assign({}, data.verifierContact) : new Contact();

    this.detail = (data && data.detail) ? data.detail : '';

    this.hotSpotWorks = (data && data.hotSpotWorks) ? Object.assign({}, data.hotSpotWorks) : {};
    this.equipments = (data && data.equipments) ? Object.assign({}, data.equipments) : {};
    this.attestations = (data && data.attestations) ? Object.assign({}, data.attestations) : {};

    this.withAtex = (data && data.withAtex);
    this.withAtexDescription = (data && data.withAtexDescription) ? data.withAtexDescription : '';
    this.nextToAtex = (data && data.nextToAtex);
    this.nextToAtexDescription = (data && data.nextToAtexDescription) ? data.nextToAtexDescription : '';


    this.warningDevices = (data && data.warningDevices) ? data.warningDevices.map((e) => (e)) : [];
    this.interventionDevices = (data && data.interventionDevices) ? data.interventionDevices.map((e) => (e)) : [];
    this.emergency = (data && data.emergency) ? data.emergency.map((e) => (e)) : [];

    this.safetyInstructions = (data && data.safetyInstructions) ? data.safetyInstructions.map((e) => (Object.assign({}, e))) : [];

  }

  getTitle() {
    return "Permis feu " + this.name;
  }
  getFileName() {
    return this.name + formattedDateFile(this.updatedAt) + (this.isValid() ? ('-' + this.status) : '');
  }
}
