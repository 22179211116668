import {Entity} from 'app/shared';
export class Illustrated {
  name: string;
  logo: string;
  selected: boolean;

  constructor(name: string, logo: string = '', selected: boolean = false) {
    this.name = name;
    this.logo = logo;
    this.selected = selected;
  }
}


export class EntityProtocolContent extends Entity {
  //Titre
  public title: string;
  //liste des travaux selectionnables
  public summary: string;

  //liste des natures de marchandise selectionnables
  public merchandiseNatures: string[];
  //liste des etats de marchandise selectionnables
  public merchandiseStates: string[];
  //liste des conditionnements de marchandise selectionnables
  public merchandisePackagings: string[];

  //liste des vehicules selectionnables
  public vehicles: string[];
  //liste des types de vehicules selectionnables
  public vehicleTypes: string[];
  //liste du materiel selectionnables
  public equipments: string[];

  //liste du materiel selectionnables
  public providedEquipments: string[];

  public warningDevices: string[];
  public interventionDevices: string[];
  public emergency: string[];

  public protectiveGears: Illustrated[];
  public interdictions: string[];
  public instructions: string[];

  public productFeatures: Illustrated[];
  //public specificPrecautions: string;

  public originatorInstructions: string[];
  public generalProvisions: string;


  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.title = (data && data.title) ? data.title : 'Permis feu';
    this.summary = (data && data.summary) ? data.summary : '';

    //liste des natures de marchandise selectionnables
    this.merchandiseNatures = (data && data.merchandiseNatures) ? data.merchandiseNatures.map((e) => (e)) : [];
    //liste des etats de marchandise selectionnables
    this.merchandiseStates = (data && data.merchandiseStates) ? data.merchandiseStates.map((e) => (e)) : [];
    //liste des conditionnements de marchandise selectionnables
    this.merchandisePackagings = (data && data.merchandisePackagings) ? data.merchandisePackagings.map((e) => (e)) : [];

    //liste des vehicules selectionnables

    this.vehicles = (data && data.vehicles) ? data.vehicles.map((e) => (e)) : [];
    //liste des types de vehicules selectionnablesthis.merchandisePackaging = (data && data.merchandisePackaging) ? data.merchandisePackaging.map((e) => (e)) : [];
    this.vehicleTypes = (data && data.vehicleTypes) ? data.vehicleTypes.map((e) => (e)) : [];

    this.equipments = (data && data.equipments) ? data.equipments.map((e) => (e)) : [];

    this.providedEquipments = (data && data.providedEquipments) ? data.providedEquipments.map((e) => (e)) : [];

    this.warningDevices = (data && data.warningDevices) ? data.warningDevices.map((e) => (e)) : [];
    this.interventionDevices = (data && data.interventionDevices) ? data.interventionDevices.map((e) => (e)) : [];
    this.emergency = (data && data.emergency) ? data.emergency.map((e) => (e)) : [];


    this.protectiveGears = (data && data.protectiveGears) ? data.protectiveGears.map((e) => (Object.assign({}, e))) : [];
    this.interdictions = (data && data.interdictions) ? data.interdictions.map((e) => (e)) : [];
    this.instructions = (data && data.instructions) ? data.instructions.map((e) => (e)) : [];
    this.productFeatures = (data && data.productFeatures) ? data.productFeatures.map((e) => (Object.assign({}, e))) : [];
    this.originatorInstructions = (data && data.originatorInstructions) ? data.originatorInstructions.map((e) => (e)) : [];

    this.generalProvisions = (data && data.generalProvisions) ? data.generalProvisions : '';


  }
}
