import { Directive, HostListener, EventEmitter, Output, Input, ElementRef, AfterContentInit } from '@angular/core';

export interface ScrollEvent {
  isReachingBottom: boolean,
  isReachingTop: boolean,
  originalEvent: Event,
  isWindowEvent: boolean
};
export interface InitEvent {
  hasScrollBar: boolean
};

declare const window: Window;

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements AfterContentInit {

  @Output() public onScroll = new EventEmitter<ScrollEvent>();
  @Output() public onInit = new EventEmitter<InitEvent>();
  @Input() public bottomOffset: number = 10;
  @Input() public topOffset: number = 10;

  constructor(public el: ElementRef) {

  }

  // handle host scroll
  @HostListener('scroll', ['$event']) public scrolled($event: Event) {
    this.elementScrollEvent($event);
  }

  // handle window scroll
  @HostListener('window:scroll', ['$event']) public windowScrolled($event: Event) {
    this.windowScrollEvent($event);
  }

  ngAfterContentInit() {
    const hasScrollBar = this.el.nativeElement.clientHeight < this.el.nativeElement.scrollHeight;
    const emitValue: InitEvent = {hasScrollBar: hasScrollBar};
    this.onInit.emit(emitValue);
  }

  protected windowScrollEvent($event: Event) {
    const target = <Document>$event.target;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const isReachingTop = scrollTop < this.topOffset;
    const isReachingBottom = ( target.body.offsetHeight - (window.innerHeight + scrollTop) ) < this.bottomOffset;
    const emitValue: ScrollEvent = {isReachingBottom, isReachingTop, originalEvent: $event, isWindowEvent: true};
    this.onScroll.emit(emitValue);
  }

  protected elementScrollEvent($event: Event) {
    const target = <HTMLElement>$event.target;
    const scrollPosition = target.scrollHeight - target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const isReachingTop = target.scrollTop < this.topOffset;
    const isReachingBottom = (scrollPosition - offsetHeight) < this.bottomOffset;
    const emitValue: ScrollEvent = {isReachingBottom, isReachingTop, originalEvent: $event, isWindowEvent: false};
    this.onScroll.emit(emitValue);
  }

}
