import { Component, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  formattedDate,
  CommonComponent,
  LoggerService, SignableService, EntitySignable,
  EntityExploitingCompany
} from 'app/shared';
import { EntityWorkbook } from 'app/workbook-core';


@Component({
  selector: 'app-signables-toolbar',
  templateUrl: './signablesToolbar.component.html',
  styleUrls: ['./signablesToolbar.component.scss']
})
export class SignablesToolbarComponent extends CommonComponent implements OnDestroy {

  @Input() workbook: EntityWorkbook;
  @Input() entity: EntitySignable;
  _routeSubscription: Subscription;
  _exploitingCompanySubscription: Subscription;
  exploitingCompany: EntityExploitingCompany;

  constructor(
    protected _route: ActivatedRoute,
    protected router: Router,
    protected _logger: LoggerService,
    protected _signableService: SignableService
  ) {
    super(_logger);
    //preload
    this._routeSubscription = this._route.params.subscribe(params => {
      this._logger.info('signablesToolbar', params);
    });

    this._exploitingCompanySubscription = this._signableService.getExploitingCompany().subscribe((eu) => {
      if (eu) {
        this.exploitingCompany = eu;
      }
    });
  }
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._exploitingCompanySubscription) {
      this._exploitingCompanySubscription.unsubscribe();
    }
  }
  /*
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['workbook'] && this.workbook.status === EntityWorkbook.STATUS_ARCHIVE && this.workbook.exploitingCompany && this.workbook.exploitingCompany._id) {
        this.exploitingCompany = this.workbook.exploitingCompany;
      }
    }
  }
*/


  public getEditionName(entity): string {
    if (entity && entity.documentType) {
      if (entity.documentType === "pdp") {
        return "Édition du PdP" + (entity.editorDate ? (' du ' + formattedDate(entity.editorDate)) : '');
      } else if (entity.documentType === "icp") {
        return "Inspection commune" + (entity.iPDate ? (' du ' + formattedDate(entity.iPDate)) : '');
      }
    }
    return "Édition";
  }

}
