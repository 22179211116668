import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, EntityFormComponent} from 'app/shared';
import {EmailMessagesService} from 'app/shared/services';
import {EntityEmailMessages} from 'app/shared/models';

@Component({
  selector: 'app-config-rgpd',
  templateUrl: './configRgpd.component.html',
  styleUrls: ['./configRgpd.component.scss']
})
export class ConfigRgpdComponent extends EntityFormComponent {

  entity: EntityEmailMessages;

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EmailMessagesService

  ) {
    super(dialog, _logger, _entityService);
  }


}
