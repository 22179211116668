import {OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {ExtraConfigService} from '../../services'
import {ExtraField, EntityExtraConfig} from '../../models'

export class ExtraFieldsCommonComponent implements OnDestroy, OnChanges {
  extraType: string = 'value';//field ou value
  @Input() type: string = '';
  @Input() context: string = '';
  @Input() value: {[key: string]: string | number | Date | null};

  fieldsDateInvalid: boolean[] = [];
  @Input() dateInvalid: boolean = false;
  @Output() dateInvalidChange = new EventEmitter<boolean>();

  protected _extraConfigSubsciption: Subscription;
  extraConfig: EntityExtraConfig;
  extraFields: ExtraField[] = [];

  constructor(protected _extraConfigService: ExtraConfigService) {
    this._extraConfigSubsciption = this._extraConfigService.getSingleOne().subscribe((element) => {
      if (element) {
        this.extraConfig = element;
        this.init();
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.type && (changes['type'] || changes['value'])) {
      this.init();
    }
    // this.checkDateInvalid();
  }
  init() {
    if (this.extraConfig && this.type && this.extraConfig[this.type]) {
      this.extraFields = this.extraConfig[this.type].filter((xf) => (this.filterExtraFields(xf, this.context)));
      this.fieldsDateInvalid = this.extraFields.map((e) => (false));
    }
  }
  filterExtraFields(extraField: ExtraField, context: string) {
    return !context || (this.extraConfig && extraField[context + '_' + this.extraType]);
  }
  ngOnDestroy() {
    if (this._extraConfigSubsciption) {
      this._extraConfigSubsciption.unsubscribe();
    }
  }

  checkDateInvalid() {
    if (this.fieldsDateInvalid.filter((e) => (!!e)).length) {
      this.dateInvalid = true;
      this.dateInvalidChange.emit(this.dateInvalid);
    }
  }
}