import {OnDestroy, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';
import {LoggerService, EntitySecureService, formattedDate, ConfirmDialogComponent} from 'app/shared';
import {
  EntityAnalysable,
  PreventionPlanService,
  RiskService,
  EntityRisk, EntitySituation,
  MeasureService,
  EntityOffice,
  EntityStep,
  EntityMeasure,
  EntityWorkbookMeasure, EntityActivity,
  CompanyActivity, WorkbookExternalCompanyService
} from 'app/workbook-core';
import {WorkbookStepDialogComponent} from './step/step-dialog.component';
import {WorkbookExternalCompanyDialogComponent} from './externalCompany/externalCompany-dialog.component'
import {WorkbookOfficeDialogComponent} from './office/office-dialog.component'
import {AbstractLocalMeasuresComponent} from './abstractLocalMeasures.component';

export abstract class AbstractAnalysableComponent extends AbstractLocalMeasuresComponent implements OnChanges, OnDestroy {
  entity: EntityAnalysable;

  @Input() selectedSteps: string[];
  @Input() step_id: string;
  @Input() isCollapsed: boolean = false;
  @Output() isCollapsedChange: EventEmitter<boolean> = new EventEmitter();

  textCollapse: string = 'Masquer';
  maskID: string = '';
  // mesures utilisées
  measures: {[risk_id: string]: EntityMeasure[]} = {};
  // les risques utilisés
  // risks: EntityRisk[] = [];
  // tous les risques
  protected _risksSubscription: Subscription;
  riskList: {[risk_id: string]: EntityRisk} = {};
  protected _stepsSubscription: Subscription;
  //steps ayant l'activité
  steps: EntityStep[] = [];
  scopedMeasures: {[step_id: string]: {[measure_id: string]: string[]}} = {};
  scopedMeasureList: string[] = [];
  //toutes les steps
  stepList: EntityStep[] = [];

  companies: EntityOffice[] = [];
  protected _companiesSubscription: Subscription;

  measureScopeList: {[measure_id: string]: {[step: string]: string[]}} = {};

  protected _activitiesSubscription: Subscription;
  //activités soeurs
  activityList: EntityActivity[] = [];
  situationList: {[situation_id: string]: EntitySituation} = {};

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _workbookService: PreventionPlanService,
    protected _riskService: RiskService,
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_workbookService, dialog, _logger, _entityService);
    this._risksSubscription = this._riskService.getAll().subscribe((list) => {
      if (list) {
        list.sort((a, b) => a.name.localeCompare(b.name)).forEach((r) => {
          this.riskList[r._id] = r;
        });
        this.refreshRisks();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['isCollapsed']) {
      this.updateMaskRisk();
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._risksSubscription) {
      this._risksSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._companiesSubscription) {
      this._companiesSubscription.unsubscribe();
    }
    if (this._activitiesSubscription) {
      this._activitiesSubscription.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      if (!this._companiesSubscription) {
        this._companiesSubscription = this._workbookService.getOEexList(this.workbook._id).subscribe((list) => {
          if (list) {
            this.companies = list;
            this.refreshMeasureScopeList();
          }
        });
      }
      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._workbookService.stepService.getChildren(this.workbook._id).subscribe((list: EntityStep[]) => {
        if (list) {
          this.stepList = list
            .filter((e) => (!e.template_id))
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
          this.refreshSteps();
        }
      });

      if (this._activitiesSubscription) {
        this._activitiesSubscription.unsubscribe();
      }
      this._activitiesSubscription = this._workbookService.activityService.getChildren(this.workbook._id).subscribe((list) => {
        if (list) {
          //TODO mettre à jour le courant en modif + updateelement
          this._updateList(list, 'activityList');
          this.refreshMeasureScopeList();
        }
      });
      this.refreshRisks();
    }
  }
  protected _afterSave(e) {
    this.refreshMeasureScopeList();
    super._afterSave(e);


  }
  refreshMeasureScopeList() {
    this.measureScopeList = {};
    this.entity.measures.forEach((measure) => {
      this.measureScopeList[measure._id] = this.getScopeList(measure);
    });
    this._cd.markForCheck();
  }
  abstract getFilteredSteps(): EntityStep[];
  refreshSteps() {
    this.steps = this.getFilteredSteps();
    this.scopedMeasures = {};
    this.scopedMeasureList = [];
    this.steps.forEach((step) => {
      this.scopedMeasures[step._id] = step.getActivityScopes((this.entity && this.entity._id) ? this.entity._id : this.id);
      Object.keys(this.scopedMeasures[step._id]).forEach((m_id) => {
        if (this.scopedMeasureList.indexOf(m_id) === -1) {
          this.scopedMeasureList.push(m_id);
        }
      });
    });
    this.refreshMeasureScopeList();
    this.updateRights();
  }

  isScoped(measure): boolean {
    return !measure.global && this.scopedMeasureList.indexOf(measure._id) !== -1;
  }
  getScopeList(measure, global: boolean = false): {[step: string]: string[]} {
    let returned = {};
    let scoped = false;
    this.steps.forEach((s: EntityStep) => {
      let companies: string[] = [];
      if (global || measure.global) {
        s.activities.forEach((activity_id) => {
          const a = this.activityList.find((e) => e._id === activity_id);
          if (a && a.companies && a.companies.length) {
            a.companies.forEach((c) => {
              if (companies.indexOf(c.name) === -1) {
                companies.push(c.name);
              }
            })
          }
        });
      } else if (this.scopedMeasures[s._id] && this.scopedMeasures[s._id][measure._id]) {
        this.scopedMeasures[s._id][measure._id].forEach((office_id) => {
          const o = this.companies.find((e) => (e._id === office_id));
          if (o) {
            scoped = true;
            companies.push(o.name);
          }
        });
      }
      if (companies.length) {
        returned[s.name + ' (' + formattedDate(s.dateStart) + ' - ' + formattedDate(s.dateEnd) + ')'] = companies.sort();
      }
    });
    return (global || measure.global || scoped) ? returned : null;//EntityWorkbookMeasure.getMeasureScope(measure);
  }
  getScope(measure): string {
    let txt = '';
    this.steps.forEach((s) => {
      txt += s.name + ' (' + formattedDate(s.dateStart) + ' - ' + formattedDate(s.dateEnd) + ')\n';
      if (measure.global) {
        txt += ' - Tous les intervenants\n';
      } else if (this.scopedMeasures[s._id] && this.scopedMeasures[s._id][measure._id]) {
        this.scopedMeasures[s._id][measure._id].forEach((office_id) => {
          const o = this.companies.find((e) => (e._id === office_id));
          if (o) {
            txt += ' - ' + o.name + '\n';
          }
        });
      } else {
        this.entity.companies.forEach((ac) => {
          txt += ' - ' + ac.name + '\n';
        });
      }
    });
    return txt;//EntityWorkbookMeasure.getMeasureScope(measure);
  }
  refreshRisks() {
    if (this.entity) {
      this.entity.risks.forEach((r) => {
        this.riskList[r._id] = r;
      });
      //console.log('refreshRisks', this.entity)
      this.measures = {};
      //const _risks = [];
      this.situationList = {};
      if (this.entity.situations && this.entity.situations.length) {
        this.entity.situations.forEach((s) => {
          this.situationList[s._id] = s;
        });
      }
      this.entity.measures.forEach((m) => {
        let listId = (m.type === '' || m.type === 'local' || m.type === 'global') ? m.risk_id : m.type;
        if (!this.measures[listId]) {
          this.measures[listId] = [];
        }
        this.measures[listId].push(m);
      });
      setTimeout(() => {this._cd.markForCheck();}, 100);
    }
  }
  /**
     * Display list companies for an activity
     * @param activityId
     * @param maxIndex
     */
  getCompanies(maxIndex: number): string {
    let text: string = '';
    if (this.entity) {
      this.entity.companies.forEach((company, index, array) => {
        if (index > maxIndex) {
          text += company.name + ' - Effectif : ' + company.size;
          index !== array.length - 1 ? text += ', ' : text += '';
        }
      });
    }
    return text;
  }

  editRisks(risk_id: string = null, situation_id: string = null) {
    this.openRisksDialog(risk_id, situation_id);

  }
  abstract openRisksDialog(risk_id: string, situation_id: string);
  abstract openMeasureScopeDialog(measure);


  openStepDialog(step) {
    const dialogRef = this.dialog.open(WorkbookStepDialogComponent, {
      disableClose: true,
      minWidth: '900px',
      data: {
        id: step._id,
        //entity: step,
        parent_id: step.parent_id,
        workbook: this.workbook
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined' && c._id) {
        const i = this.stepList.findIndex((e) => e._id === c._id);
        if (i !== -1) {
          this.stepList[i] = c;
          this.stepList = this.stepList.sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()));
          this.refreshSteps();
        }
      }
    });
  }

  openOEExDialog(companyActivity: CompanyActivity) {
    const oeex = this.companies.find((e) => (e._id === companyActivity.id));
    if (oeex) {
      const dialogRef = this.dialog.open((oeex.documentType === WorkbookExternalCompanyService.ENTITY_TYPE) ? WorkbookExternalCompanyDialogComponent : WorkbookOfficeDialogComponent, {
        disableClose: true,
        minWidth: '900px',
        data: {
          id: oeex._id,
          parent_id: oeex.parent_id,
          workbook: this.workbook
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && c._id) {
          const i = this.entity.companies.findIndex((e) => e.id === c._id)
          if (i !== -1 && c.name && this.entity.companies[i].name !== c.name) {
            this.entity.companies[i].name = c.name;
            this._save();
          }
          this._updateListElement(c, 'companies');
        }
      });
    }
  }


  addNewMeasure(riskId: string) {
    if (riskId && this.entity && this.measures[riskId] && this.measures[riskId].findIndex(e => e.name === '') !== -1) {
      this.snackBar.open('Une mesure est déjà en attente de saisie', '', {
        duration: 2000
      })
    } else {
      const measure: EntityWorkbookMeasure = this._workbookService.measureService.getNewEntity({
        parent_id: this.entity.parent_id,
        risk_id: riskId,
        type: 'local'
      });
      this._workbookService.measureService.save(measure).then((e) => {
        this.entity.measures.push(measure);
        this.refreshRisks();
        this._save();
      });
    }
  }


  removeMeasure(measureId: string) {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer cette mesure ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        const removeIndex = this.entity.measures.findIndex(measure => measure._id === measureId);
        if (removeIndex !== -1) {
          const risk_id = this.entity.measures[removeIndex].risk_id;
          this.entity.measures.splice(removeIndex, 1);
          const j = this.entity.risks.findIndex((r) => (r._id === risk_id));
          if (j !== -1 && this.entity.measures.findIndex((e) => (e.risk_id === risk_id)) === -1) {
            this.entity.risks.splice(j, 1);
          }
        }
        this.refreshRisks();
        this._save();
      }
    });
  }
  /**
   * Delete risk in activity
   * @param {string} activityId
   * @param {string} riskId
   */
  deleteRisk(risk_situation_id: string): void {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Attention : si vous supprimez ce risque du document, vous supprimez également toutes les mesures correspondantes dans ce document. Etes-vous sûr de vouloir continuer ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {

        let j = this.entity.risks.findIndex((r) => (r._id === risk_situation_id));
        if (j !== -1) {
          this.entity.measures = this.entity.measures.filter((m) => (!(m.risk_id === risk_situation_id && (m.type === '' || m.type === 'local' || m.type === 'global'))));
          if (this.entity.situations.findIndex((s) => (s.risk_id === risk_situation_id)) === -1) {
            this.entity.risks.splice(j, 1);
          }
        } else {
          j = this.entity.situations.findIndex((s) => (s._id === risk_situation_id));
          if (j !== -1) {
            const riskId = this.entity.situations[j].risk_id;
            this.entity.situations.splice(j, 1);
            this.entity.measures = this.entity.measures.filter((m) => (!(m.risk_id === riskId && m.type === risk_situation_id)));
          } else {
            //ni risque ni situation ? cas risque manquant ?
            this.entity.measures = this.entity.measures.filter((m) => (m.risk_id !== risk_situation_id));
          }
        }
        this.entity.update_risks_situations();
        this._save();
        this.refreshRisks();
      }
    });
  }

  /**
   * Fold/Unfold the measures
   */
  maskRisk() {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsedChange.emit(this.isCollapsed);
    this.updateMaskRisk();
  }
  updateMaskRisk() {
    this.maskID = this.isCollapsed ? 'fold' : 'unfold';
    this.textCollapse = this.isCollapsed ? 'Afficher les risques' : 'Masquer les risques';
    this._cd.markForCheck();

  }
  moveRisk(previousIndex: number, currentIndex: number) {
    moveItemInArray(this.entity.risks_situations, previousIndex, currentIndex);
    this._save();
  }
  dropRisk(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container && event.previousIndex !== event.currentIndex) {
      this.moveRisk(event.previousIndex, event.currentIndex);
    }
  }
  moveMeasure(previousIndex: number, currentIndex: number, risk_id: string) {
    if (this.measures[risk_id]) {
      const measureToMove = this.measures[risk_id][previousIndex];
      const measureAfter = this.measures[risk_id][currentIndex];
      if (measureToMove && measureAfter) {
        const previous = this.entity.measures.findIndex((m) => (m._id === measureToMove._id));
        const current = this.entity.measures.findIndex((m) => (m._id === measureAfter._id));
        moveItemInArray(this.entity.measures, previous, current);
        moveItemInArray(this.measures[risk_id], previousIndex, currentIndex);
        this._save();
      }
    }
  }
  dropMeasure(event: CdkDragDrop<any[]>, risk_id: string) {
    if (event.previousContainer === event.container && event.previousIndex !== event.currentIndex) {
      this.moveMeasure(event.previousIndex, event.currentIndex, risk_id);
    }
  }
}
