export class Log {

  public _id: string;
  public host: string;
  public user: string;
  public date: Date;
  public status: number;
  public errorMsg: string;
  public documentType: string;

  constructor(logData) {
    this._id = logData._id;
    this.host = logData.host || window.location.origin;
    this.user = logData.user;
    this.date = logData.date || new Date();
    this.status = logData.status;
    this.errorMsg = logData.errorMsg;
    this.documentType = 'log';
  }
}


