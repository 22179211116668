import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityEditComponent, LoggerService} from 'app/shared';
import {OfficeService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-office-edit',
  templateUrl: './officeEdit.component.html',
  // styleUrls: ['./officeEdit.component.scss']
})

export class OfficeEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: OfficeService

  ) {
    super(_route, _router, _logger, _entityService);
  }
}
