import { ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar, MatTableDataSource, MatSort, MatDialogRef } from '@angular/material';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  LoggerService,
  EmailService, EntityEmailMessages, EmailMessages, EmailDatasModel, EmailMessagesService,
  ConfirmDialogComponent,
  EntityDocumentMetadata, DocumentStatus,
  DocumentDialogComponent, promiseAll
} from 'app/shared';
import {
  PreventionPlanService,
  CategoryService, EntityCategory,
  SiteService, EntitySite,
  OfficeService, EntityOffice,
  ExternalCompanyService, EntityExternalCompany,
  WorkbookOfficeService, WorkbookExternalCompanyService,
  DocumentationService, EntityDocumentation
} from 'app/workbook-core';
import { WorkbookDocumentsUploadDialogComponent } from './documentsUpload-dialog.component';
import { WorkbookDocumentsRequestDialogComponent } from './documentsRequest-dialog.component';
import { DocumentEexMetadataDialogComponent } from './documentEexMetadata-dialog.component';
import { DocumentAddSelectDialogComponent } from './documentAddSelect-dialog.component';
import { WorkbookChildListComponent } from '../abstractWorkbookChildList.component';

export class DocEntry {
  document: EntityDocumentMetadata;
  originName;
  date: Date;
  site?: EntitySite;
  oeex?: EntityOffice;
}
export class WorkbookDocumentListComponent extends WorkbookChildListComponent {

  @ViewChild('dataSourceSort') dataSourceSort: MatSort;
  @ViewChild('selectableDocumentDataSourceSort') selectableDocumentDataSourceSort: MatSort;

  entityList: EntityDocumentMetadata[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document de l\'opération ?';
  addConfirmMessage = 'Nouvelle annexe créée';

  dataSource: MatTableDataSource<DocEntry> = new MatTableDataSource([]);
  selectableDisplayedColumns = ['document.categoryPicto', 'document.name', 'originName', 'document.category', 'date', 'download', 'actions'];
  displayedColumns = ['document.categoryPicto', 'document.name', 'originName', 'document.category', 'date', 'download', 'actions'];
  tabDisplayedColumns = [
    ['selector', 'document.categoryPicto', 'document.name', 'originName', 'document.category', 'date', 'download', 'actions'],
    ['selector', 'document.categoryPicto', 'document.name', 'originName', 'requestDate', 'resend', 'actions'],
    ['selector', 'document.categoryPicto', 'document.name', 'originName', 'date', 'validation', 'download', 'actions']
  ];
  docEntryList: DocEntry[] = [];
  docEntries: DocEntry[][] = [[], [], []];

  selectableDocumentDataSource: MatTableDataSource<DocEntry> = new MatTableDataSource([]);
  hideSelectable: boolean = true;

  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  //Offices et EEx de l'operation
  oeexList: EntityOffice[] = [];
  //documents issu du repo des Oeex de l'OP issues du repo
  oeexDocuments: { [oeex_id: string]: EntityDocumentMetadata[] } = {};
  protected _eexSubscription: Subscription;
  oeexLoaded: boolean = false;

  repoEexList: { [oeex_id: string]: EntityExternalCompany } = {};
  repoEexLoaded: boolean = false;
  repoOfficeList: { [oeex_id: string]: EntityOffice } = {};
  repoOfficeLoaded: boolean = false;
  protected _repoEeexSubscription: Subscription;
  protected _repoOfficeSubscription: Subscription;
  repoSelectableOeexDocuments: { [oeex_id: string]: EntityDocumentMetadata[] } = {};

  //pour changement de workbook sur meme composant ?
  //workbook_id: string = '';

  protected _stepSubscription: Subscription;

  //tous les sites du repo
  siteList: { [site_id: string]: EntitySite } = {};
  protected _siteSubscription: Subscription;
  //repo_id des sites utilisés
  relatedSites: string[] = [];
  //documents des sites (du repo) sélectionnable
  siteDocuments: { [site_id: string]: EntityDocumentMetadata[] } = {};
  siteLoaded: boolean = false;

  //tous les sites du repo
  documentationList: { [documentation_id: string]: EntityDocumentation } = {};
  protected _documentationSubscription: Subscription;
  //documents des documentation (du repo) sélectionnable
  documentationDocuments: { [documentation_id: string]: EntityDocumentMetadata } = {};
  documentationLoaded: boolean = false;
  tabIndex: number = 0;

  checkall: boolean = false;
  checked: DocEntry[] = [];
  //modele DOC_REQUEST = 1 document
  emailData;
  //modele DOCUMENTS_REQUEST = plusieurs documents
  emailDatas;

  disableSend: { [id: string]: boolean } = {};

  constructor(
    protected _officeService: OfficeService,
    protected _externalCompanyService: ExternalCompanyService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _documentationService: DocumentationService,
    protected _siteService: SiteService,
    protected _categoryService: CategoryService,
    //protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);

    this._emailService.getEmailDatas(EmailMessages.DOC_REQUEST).then(emailObj => {
      if (emailObj) {
        this.emailData = emailObj;
      }
    }).catch((error) => {
      this._logger.error('WorkbookDocumentListComponent', 'error get DOC_REQUEST email data', JSON.stringify(error));
    });
    this._emailService.getEmailDatas(EmailMessages.DOCUMENTS_REQUEST).then(emailObj => {
      if (emailObj) {
        this.emailDatas = emailObj;
      }
    }).catch((error) => {
      this._logger.error('WorkbookDocumentListComponent', 'error get DOCUMENTS_REQUEST email data', JSON.stringify(error));
    });
    this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
      if (list) {
        this.categoryList = list;
      }
    });
    this._repoEeexSubscription = this._externalCompanyService.getAll().subscribe((list: EntityExternalCompany[]) => {
      if (list) {
        //console.log('siteService.getAll');
        this.repoEexLoaded = true;
        this.repoEexList = {};
        list.forEach((s) => {
          //console.log('siteList[' + s._id + ']', s);
          this.repoEexList[s._id] = s;
        });
        this.updateRepoSelectableOeexDocuments();
        //this.refreshDataSource();
      }
    });
    this._repoOfficeSubscription = this._officeService.getAll().subscribe((list: EntityOffice[]) => {
      if (list) {
        //console.log('siteService.getAll');
        this.repoOfficeLoaded = true;
        this.repoOfficeList = {};
        list.forEach((s) => {
          //console.log('siteList[' + s._id + ']', s);
          this.repoOfficeList[s._id] = s;
        });
        this.updateRepoSelectableOeexDocuments();
        //this.refreshDataSource();
      }
    });
    this._siteSubscription = this._siteService.getAll().subscribe((list: EntitySite[]) => {
      if (list) {
        //console.log('siteService.getAll');
        this.siteLoaded = true;
        this.siteList = {};
        list.forEach((s) => {
          //console.log('siteList[' + s._id + ']', s);
          this.siteList[s._id] = s;
        });
        this.updateSiteDocuments();
        //this.refreshDataSource();
      }
    });
    this._documentationSubscription = this._documentationService.getAll().subscribe((list: EntityDocumentation[]) => {
      if (list) {
        //console.log('documentationService.getAll');
        this.documentationLoaded = true;
        this.documentationList = {};
        list.forEach((s) => {
          //console.log('documentationList[' + s._id + ']', s);
          this.documentationList[s._id] = s;
        });
        this.updateDocumentation();
        //this.refreshDataSource();
      }
    });

  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity._id) {
      if (!this._eexSubscription) {
        this._eexSubscription = this._entityService.getOEexList(this.entity._id).subscribe((list) => {
          if (list) {
            this.oeexList = list;
            this.oeexDocuments = {};
            this.oeexList
              .filter((e) => (!!e.repo_id))
              .forEach((e) => {
                this.oeexDocuments[e.repo_id] = e.documents.filter((e) => (!!e.repo_id));
              });
            this.oeexLoaded = true;
            this.updateRepoSelectableOeexDocuments();
            //this.refreshDataSource();
          }
        });
      }
    }
    if (this.entity) {
      //filtrer ceux déjà utilisés
      if (this._stepSubscription) {
        this._stepSubscription.unsubscribe();
      }
      this._stepSubscription = this._entityService.getStepList(this.entity._id).subscribe((steps) => {
        if (steps) {
          //console.log('getStepList _relatedSites init');
          const _relatedSites = [];
          steps.forEach((s) => {
            if (!s.template_id && s.site && s.site.repo_id && _relatedSites.indexOf(s.site.repo_id) === -1) {
              _relatedSites.push(s.site.repo_id);
              //console.log('add step site to _relatedSites ' + s.site.repo_id);
            }
          });
          //console.log('update relatedSites ', _relatedSites);
          this.relatedSites = _relatedSites;
          this.updateSiteDocuments();
        }
      });
    }
    this.updateDocumentation(false);
    this.updateSiteDocuments(false);
    this.refreshDataSource();

  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
    if (this._eexSubscription) {
      this._eexSubscription.unsubscribe();
    }
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
    if (this._siteSubscription) {
      this._siteSubscription.unsubscribe();
    }
  }
  updateDocumentation(refreshDataSource: boolean = true) {
    if (this.entity && Object.keys(this.documentationList).length) {
      this.documentationDocuments = {};

      //console.log('updateDocumentation');
      Object.keys(this.documentationList).forEach((documentation_id) => {
        if ((
          (this.entity.type !== 'annual' && this.documentationList[documentation_id].oo)
          || (this.entity.type === 'annual' && !this.entity.simple && this.documentationList[documentation_id].op)
          || (this.entity.type === 'annual' && this.entity.simple && this.documentationList[documentation_id].ps)
        )
          && (this.documentationList[documentation_id].meta
            && this.entityList.findIndex((e) => (e.repo_id && e.repo_id === this.documentationList[documentation_id].meta._id)) === -1)) {
          this.documentationDocuments[documentation_id] = this.documentationList[documentation_id].meta;

          //console.log('documentationDocuments[' + documentation_id + ']', this.documentationDocuments[documentation_id]);
        }
      });
      this.updateSelectableDocumentDataSource(refreshDataSource);
    }
  }
  getRepoOeex(oeex_id) {
    return (this.repoOfficeList[oeex_id] ?
      this.repoOfficeList[oeex_id] :
      (this.repoEexList[oeex_id] ? this.repoEexList[oeex_id] : null)
    )
  }
  updateRepoSelectableOeexDocuments(refreshDataSource: boolean = true) {
    if (this.entity && this.oeexLoaded && this.repoOfficeLoaded && this.repoEexLoaded && this.oeexDocuments && Object.keys(this.oeexDocuments).length) {
      this.repoSelectableOeexDocuments = {};
      Object.keys(this.oeexDocuments).forEach((oeex_id) => {
        if (!this.repoSelectableOeexDocuments[oeex_id]) {
          let _repoOeex = this.getRepoOeex(oeex_id);
          if (_repoOeex && _repoOeex.documents && _repoOeex.documents.length) {
            const _selectableDocuments = _repoOeex.documents.filter((doc) => (!doc.status && doc.data_id && this.oeexDocuments[oeex_id].findIndex((de) => (de.repo_id && de.repo_id === doc._id)) === -1));
            if (_selectableDocuments.length) {
              this.repoSelectableOeexDocuments[oeex_id] = _selectableDocuments;
            }
          }
        }
      });
      this.updateSelectableDocumentDataSource(refreshDataSource);
    }
  }
  updateSiteDocuments(refreshDataSource: boolean = true) {
    if (this.entity && this.relatedSites && Object.keys(this.siteList).length) {
      this.siteDocuments = {};
      if (this.entityList && this.entity.site && this.entity.site && this.entity.site.repo_id
        && this.siteList[this.entity.site.repo_id] && this.siteList[this.entity.site.repo_id].documents && this.siteList[this.entity.site.repo_id].documents.length) {
        const _selectableDocuments = this.siteList[this.entity.site.repo_id].documents.filter((doc) => (this.entityList.findIndex((d) => (d.repo_id && d.repo_id === doc._id)) === -1));
        if (_selectableDocuments.length) {
          this.siteDocuments[this.entity.site.repo_id] = _selectableDocuments;
          //console.log('(workbook) siteDocuments[' + this.entity.site.repo_id + ']', this.siteDocuments[this.entity.site.repo_id]);
        }
      }
      //console.log('updateSiteDocuments');
      this.relatedSites.forEach((site_id) => {
        //console.log('updateSiteDocuments relatedSites ' + site_id);
        if (!this.siteDocuments[site_id] && this.siteList[site_id] && this.siteList[site_id].documents && this.siteList[site_id].documents.length) {

          //console.log('this.siteList[' + site_id + '].documents', this.siteList[site_id].documents);
          const _selectableDocuments = this.siteList[site_id].documents.filter((doc) => (this.entityList.findIndex((d) => (d.repo_id && d.repo_id === doc._id)) === -1));
          if (_selectableDocuments.length) {
            this.siteDocuments[site_id] = _selectableDocuments;
            //console.log('siteDocuments[' + site_id + ']', this.siteDocuments[site_id]);
          }
        }
      });
      this.updateSelectableDocumentDataSource(refreshDataSource);
    }
  }
  updateSelectableDocumentDataSource(refreshDataSource: boolean = true) {
    const _data: DocEntry[] = [];
    //console.log('updateSelectableDocumentDataSource');
    Object.keys(this.repoSelectableOeexDocuments).forEach((oeex_id) => {
      //console.log('add oeexDocuments[' + oeex_id + ']');
      const _oeex = this.getRepoOeex(oeex_id);
      this.repoSelectableOeexDocuments[oeex_id].forEach((doc) => {
        //console.log('push', {document: doc,originName: this.oeexList[oeex_id].name,date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),oeex: this.oeexList[oeex_id]});
        _data.push({
          document: doc,
          originName: _oeex ? _oeex.name : '---',
          date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),
          oeex: _oeex
        });

      });
    });
    Object.keys(this.siteDocuments).forEach((site_id) => {
      //console.log('add siteDocuments[' + site_id + ']');
      this.siteDocuments[site_id].forEach((doc) => {
        //console.log('push', {document: doc,originName: this.siteList[site_id].name,date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),site: this.siteList[site_id]});
        _data.push({
          document: doc,
          originName: this.siteList[site_id].name,
          date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),
          site: this.siteList[site_id]
        });

      });
    });
    Object.keys(this.documentationDocuments).forEach((documentation_id) => {
      //console.log('add documentationDocuments[' + documentation_id + ']');
      //console.log('push', {document: this.documentationDocuments[documentation_id],originName: 'Consigne générale',date: new Date(this.documentationDocuments[documentation_id].uploadedAt ? this.documentationDocuments[documentation_id].uploadedAt : this.documentationDocuments[documentation_id].createdAt)});
      _data.push({
        document: this.documentationDocuments[documentation_id],
        originName: 'Consigne générale',
        date: new Date(this.documentationDocuments[documentation_id].uploadedAt ? this.documentationDocuments[documentation_id].uploadedAt : this.documentationDocuments[documentation_id].createdAt)
      });
    });
    this.selectableDocumentDataSource = new MatTableDataSource(_data);
    setTimeout(() => {
      this.setSelectableDocumentDataSourceSort();
    });
    if (refreshDataSource) {
      this.refreshDataSource();
    }
  }
  setSelectableDocumentDataSourceSort() {
    this.selectableDocumentDataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    if (this.selectableDocumentDataSourceSort) {
      this.selectableDocumentDataSource.sort = this.selectableDocumentDataSourceSort;
    }
  }
  getEexName(id: string) {
    const eex = this.getEex(id);
    return eex ? eex.name : '';
  }

  getEex(id: string) {
    const i = this.oeexList.findIndex((e) => (e.documents && e.documents.findIndex((d) => (d._id === id)) !== -1));
    if (i !== -1) {
      return this.oeexList[i];
    }
    return null;
  }
  getOriginName(document: EntityDocumentMetadata) {
    let name = '';
    if (document.repo_id) {
      Object.keys(this.siteList).forEach((site_id) => {
        if (this.siteList[site_id].documents && this.siteList[site_id].documents.findIndex((doc) => (doc._id === document.repo_id)) !== -1) {
          name = this.siteList[site_id].name;
        }
      });
      Object.keys(this.documentationList).forEach((id) => {
        if (this.documentationList[id].meta && this.documentationList[id].meta._id === document.repo_id) {
          name = 'Consigne générale';
        }
      });
    }
    return name;
  }
  refreshDataSource() {
    if (this.entityList && this.documentationLoaded && this.siteLoaded) {
      const list: DocEntry[] = [];
      this.entityList.forEach((doc) => {
        let originName = '';
        let site = null;
        if (doc.repo_id) {
          originName = 'Consigne générale (supprimée)';
          Object.keys(this.documentationList).forEach((id) => {
            if (this.documentationList[id].meta && this.documentationList[id].meta._id === doc.repo_id) {
              originName = 'Consigne générale';
            }
          });
          Object.keys(this.siteList).forEach((site_id) => {
            if (this.siteList[site_id].documents && this.siteList[site_id].documents.findIndex((_doc) => (_doc._id === doc.repo_id)) !== -1) {
              originName = this.siteList[site_id].name;
              site = this.siteList[site_id];
            }
          });
        }
        //TODO Oeex (du repo) ?
        list.push({
          document: doc,
          originName: originName,
          date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),
          site: site
        });
      });
      this.oeexList.forEach((eex) => {
        if (eex.documents && eex.documents.length) {
          eex.documents.forEach((doc) => {
            list.push({
              document: doc,
              originName: eex.name,
              date: new Date(doc.uploadedAt ? doc.uploadedAt : doc.createdAt),
              oeex: eex
            });
          });
        }
      });
      this.docEntryList = list;
      this._updateDatasource();

    }
  }
  protected _updateDatasource() {
    this.displayedColumns = this.tabDisplayedColumns[this.tabIndex];
    this.docEntries = [
      this.docEntryList.filter((data: DocEntry) => (data.document && !!data.document.data_id && !data.document.status)),
      this.docEntryList.filter((data: DocEntry) => (data.document && !data.document.data_id)),
      this.docEntryList.filter((data: DocEntry) => (data.document && !!data.document.data_id && !!data.document.status))
    ];
    this.dataSource = new MatTableDataSource(this.docEntries[this.tabIndex]);
    setTimeout(() => {
      this.setDataSourceSort();
    });
  }
  setTab(t: number) {
    if (this.tabIndex !== t) {
      this.checked = [];
      this.checkall = false;
    }
    this.tabIndex = t;
    this._updateDatasource();
  }
  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && (o[p] && (o[p] instanceof Date)) ? o[p].getTime() : ((typeof o[p] === 'string') ? o[p].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : o[p]), obj)
  )
  setDataSourceSort() {
    this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    if (this.dataSourceSort) {
      this.dataSource.sort = this.dataSourceSort;
    }
  }
  protected _updateList(data, attribute = 'entityList') {
    if (attribute == 'entityList' && this.entity) {
      data = data.filter((e) => (e.context && e.context.indexOf(this._entityService.getUrlWorkbook(this.entity.documentType, this.entity._id, 'document')) === 1));
    }
    super._updateList(data, attribute);
  }

  protected _getChildService() {
    return this._entityService.documentService.documentMetadataService;
  }
  public addDocuments() {
    const dialogRef = this.dialog.open(DocumentAddSelectDialogComponent, {
      minWidth: '640px'
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c === 2) {
          this.addDocumentsDialog();
        } else {
          this.openDocumentDialog();
        }
      }
    });
  }
  /**
   * Open dialog to upload documents
   */
  public openUploadDialog() {
    this.dialog.open(DocumentDialogComponent, {
      data: {
        parent_id: this.id,
        context: this._router.url
      },
      minWidth: '50%',
      maxHeight: '80%'
    });
  }
  /**
   * Open dialog to edit document metadata
   */
  public openDocumentDialog(doc: EntityDocumentMetadata = null, uploadOnly = false, eex = null, rejected: boolean = false) {
    if (!eex && doc && doc._id) {
      eex = this.getEex(doc._id);
    }
    // const dialogRef = this.dialog.open(eex ? DocumentEexMetadataDialogComponent : DocumentMetadataDialogComponent, {
    const dialogRef = this.dialog.open(DocumentEexMetadataDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        id: (doc && doc.parent_id) ? doc._id : null,
        entity: (doc && !doc.parent_id) ? this._entityService.documentService.documentMetadataService.getNewEntity(doc) : null,
        parent_id: (this.entity && (!doc || doc.parent_id)) ? this.entity._id : null,
        categoryList: this.categoryList,
        context: this._router.url,
        addOnly: !eex,
        uploadOnly: uploadOnly,
        readOnly: this.entity && !this.isSynced(this.entity._id),
        eexList: this.oeexList,
        selectedEex: eex,
        workbook: this.entity,
        rejected: rejected
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c.parent_id) {
          this._updateListElement(c);
        }
        if (!c.data_id) {
          if (this.tabIndex !== 1) {
            this.setTab(1);
          }
        } else if (c.status === DocumentStatus.PROPOSED) {
          if (this.tabIndex !== 2) {
            this.setTab(2);
          }
        } else if (this.tabIndex !== 0) {
          this.setTab(0);
        }
        this.refreshDataSource();
      }
    });

  }
  public addDocumentsDialog() {
    const dialogRef = this.dialog.open(WorkbookDocumentsUploadDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        parent_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        context: this._router.url,
        multiple: true,
        showEex: true,
        eexList: this.oeexList,
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (this.tabIndex !== 0) {
          this.setTab(0);
        }
        this.refreshDataSource();
      }
    });

  }
  public requestDocumentsDialog() {
    const dialogRef = this.dialog.open(WorkbookDocumentsRequestDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        parent_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        context: this._router.url,
        multiple: true,
        showEex: true,
        eexList: this.oeexList,
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (this.tabIndex !== 1) {
          this.setTab(1);
        }
        this.refreshDataSource();
      }
    });
  }
  addSelectableDocument(docEntry: DocEntry) {
    if (this.entity) {
      this._spinnerService.show();
      if (docEntry.oeex) {
        const __oeex = this.oeexList.find((o) => (o.repo_id === docEntry.oeex._id));
        //TODO => ajouter à l'Oeex
        const _oeexService =
          (docEntry.oeex.documentType === ExternalCompanyService.ENTITY_TYPE) ? this._entityService.externalCompanyService :
            ((docEntry.oeex.documentType === OfficeService.ENTITY_TYPE) ? this._entityService.officeService
              : null);
        if (_oeexService) {
          _oeexService.getCurrentOne(__oeex._id).then((oeex) => {
            const _doc: EntityDocumentMetadata = this._entityService.documentService.getNewDocumentMetada(docEntry.document, true);
            _doc._id = UUID.UUID();
            _doc.context = this._router.url;
            _doc.parent_id = '';
            _doc.repo_id = docEntry.document._id;
            oeex.documents.push(_doc);
            _oeexService.save(oeex).then((res) => {
              this.updateRepoSelectableOeexDocuments();
              this._spinnerService.hide();
            }).catch((e) => {
              this._spinnerService.hide();
            });
          }).catch((e) => {
            this._spinnerService.hide();
          });
        } else {
          this._spinnerService.hide();
        }
      } else {
        const _doc: EntityDocumentMetadata = this._entityService.documentService.getNewDocumentMetada(docEntry.document, true);
        _doc.context = this._router.url;
        _doc.parent_id = this.entity._id;
        _doc.repo_id = docEntry.document._id;
        this._entityService.documentService.documentMetadataService.save(_doc).then((res) => {
          if (_doc.parent_id) {
            this._updateListElement(_doc);
          }
          this.refreshDataSource();
          //if (docEntry.oeex) {
          //  this.updateOeexDocuments();
          //} else
          if (docEntry.site) {
            this.updateSiteDocuments();
          } else {
            this.updateDocumentation();
          }
          this._spinnerService.hide();
        }).catch((err) => {
          this._spinnerService.hide();
        });
      }
    }
  }
  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._entityService.documentService.downloadDocumentFromMetadata(docMeta);
  }

  /**
   * Open dialog to confirm delete and delete document uploaded
   * @param id
   */
  deleteDoc(entry: DocEntry) {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;//'Êtes-vous sûr de vouloir supprimer ce document ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._deleteDoc(entry.document, !!entry.site);
      }
    });
  }

  protected _deleteDoc(entity: EntityDocumentMetadata, updateSite: boolean = false) {
    if (entity && entity._id) {
      this._spinnerService.show();
      if (entity.parent_id) {
        this._entityService.documentService.removeMetadata(entity, !entity.repo_id);
        const i = this.entityList.findIndex((e) => (e._id === entity._id));
        if (i !== -1) {
          this.entityList.splice(i, 1);
          if (updateSite) {
            this.updateSiteDocuments();
          } else if (entity.repo_id) {
            this.updateDocumentation();
          }
        }
        this._spinnerService.hide();
      } else {
        const oeex = this.getEex(entity._id);
        if (oeex) {
          const _service = (oeex.documentType === 'wb_office') ? this._entityService.officeService : this._entityService.externalCompanyService;
          _service.getCurrentOne(oeex._id).then((o) => {
            if (!o.documents) {
              o.documents = [];
            }
            let index = o.documents.findIndex((d) => (d._id === entity._id));
            if (index !== -1) {
              o.documents.splice(index, 1);
              _service.save(o);
              if (this.repoSelectableOeexDocuments[oeex._id] && this.repoSelectableOeexDocuments[oeex._id].length) {
                let j = this.repoSelectableOeexDocuments[oeex._id].findIndex((d) => (d._id === entity._id));
                if (j !== -1) {
                  this.repoSelectableOeexDocuments[oeex._id].splice(index, 1);
                }
              }
              //this.updateSelectableDocumentDataSource(false);
              this.updateRepoSelectableOeexDocuments();
            }
            this._spinnerService.hide();
          }).catch((err) => {
            console.error('deleteDoc', err);
            this._spinnerService.hide();
          });
        } else {

          this._spinnerService.hide();
        }
      }
    }
  }
  isChecked(id) {
    return this.checked.findIndex((e) => (e.document && e.document._id === id)) !== -1;
  }
  changeCheck(event) {
    if (event && event.source && event.source.value) {
      this.checkall = false;
      if (event.checked) {
        if (this.checked.findIndex((e) => (event.source.value.document && event.source.value.document._id && event.source.value.document._id && e.document && e.document._id === event.source.value.document._id)) === -1) {
          this.checked.push(event.source.value);
        }
      } else {
        const i = this.checked.findIndex((e) => (event.source.value.document && event.source.value.document._id && e.document && e.document._id === event.source.value.document._id));
        if (i !== -1) {
          this.checked.splice(i, 1);
        }
      }
    }
  }
  changeCheckall(event) {
    if (event) {
      if (event.checked) {
        this.checked = this.dataSource.filteredData;
      } else {
        this.checked = [];
      }
    }
  }
  duplicateDocument(original, uploadOnly = false) {
    const eex = (original && original._id) ? this.getEex(original._id) : null;
    const duplicateElement: EntityDocumentMetadata = this._entityService.documentService.documentMetadataService.getNewEntity(original, true);
    duplicateElement.data_id = '';
    duplicateElement.parent_id = '';
    duplicateElement.displayName = '';
    duplicateElement.documentType = '';
    duplicateElement.ext = '';
    duplicateElement.icon = '';
    duplicateElement.createdAt = null;
    duplicateElement.createdId = '';
    duplicateElement.createdName = '';
    duplicateElement.updatedAt = null;
    duplicateElement.updatedId = '';
    duplicateElement.updatedName = '';
    duplicateElement.requestedAt = null;
    duplicateElement.requestedId = '';
    duplicateElement.requestedName = '';
    duplicateElement.requestedSent = [];
    duplicateElement.uploadedAt = null;
    duplicateElement.uploadedId = '';
    duplicateElement.uploadedName = '';
    duplicateElement.dateStart = null;
    duplicateElement.dateEnd = null;

    this.openDocumentDialog(duplicateElement, uploadOnly, eex);
  }
  isExpired(date) {
    //console.log('isExpired(' + date + ')', [!!date, isNaN(date), (!!date && !isNaN(date) && (new Date(date).getTime() < new Date().getTime()))]);
    return (!!date && (new Date(date).getTime() < new Date().getTime()));
  }
  acceptDocument(doc: DocEntry) {
    this.changeDocumentStatus(doc, DocumentStatus.VALIDATED);

  }
  rejectDocument(doc: DocEntry) {
    this.openDocumentDialog(doc.document, false, null, true)
    //this.changeDocumentStatus(doc, DocumentStatus.REQUESTED);
  }
  changeDocumentStatus(doc: DocEntry, status: number) {
    doc.document.status = status;
    if (doc.oeex) {
      const oeexService = (doc.oeex.documentType === 'wb_office') ? this._entityService.officeService : this._entityService.externalCompanyService;
      oeexService.getCurrentOne(doc.oeex._id).then((oeex: EntityOffice) => {
        const i = oeex.documents.findIndex((e) => (e._id === doc.document._id));
        if (i !== -1) {
          oeex.documents[i].parent_id = '';
          oeex.documents[i].status = status;
          if (status === DocumentStatus.REQUESTED) {
            this._entityService.documentService.documentFileService.removeById(oeex.documents[i].data_id).then((e) => {
              console.log('file removed');
            });
            oeex.documents[i].data_id = '';
            oeex.documents[i].displayName = '';
            oeex.documents[i].documentType = '';
            oeex.documents[i].ext = '';
            oeex.documents[i].icon = '';
            oeex.documents[i].requestedAt = null;
            oeex.documents[i].requestedId = '';
            oeex.documents[i].requestedName = '';
            oeex.documents[i].requestedSent = [];
          } else {
            oeex.documents[i].reason = '';
          }
          oeexService.save(oeex).then((e) => { this.refreshDataSource(); });
        }
      });
    } else {
      this._entityService.documentService.documentMetadataService.getCurrentOne(doc.document._id).then((e: EntityDocumentMetadata) => {
        e.parent_id = '';
        e.status = status;
        if (status === DocumentStatus.REQUESTED) {
          this._entityService.documentService.documentFileService.removeById(e.data_id).then((e) => {
            console.log('file removed');
          });
          e.data_id = '';
          e.displayName = '';
          e.documentType = '';
          e.ext = '';
          e.icon = '';
          e.requestedAt = null;
          e.requestedId = '';
          e.requestedName = '';
          e.requestedSent = [];
        } else {
          e.reason = '';
        }
        this._entityService.documentService.documentMetadataService.save(e).then((e) => { this.refreshDataSource(); });
      });
    }
  }
  resendNotifications(doc: DocEntry, displaySnack: boolean = true): Promise<any> {
    if (doc.document && doc.document._id && !this.disableSend[doc.document._id] && doc.oeex) {
      return this._sendNotifications(doc.document, doc.oeex, displaySnack);
    }
  }
  protected _sendNotifications(document: EntityDocumentMetadata, office: EntityOffice, displaySnack: boolean = true): Promise<any> {
    if (document && document.request && document.request.length && office && office.contacts && office.contacts.length) {
      if (displaySnack) {
        this.snackBar.open('Envoi en cours', '', {
          duration: 3000
        });
      }
      this.disableSend[document._id] = true;
      const emailPomises = [];
      document.request.forEach((contactEmail) => {
        const contact = office.contacts.find((e) => (e.email === contactEmail));
        if (contact && contact.email) {
          const emailDatas = new EmailDatasModel();
          emailDatas.email = contact.email;
          emailDatas.to_name = contact.name;
          emailDatas.from_name = this.emailData.fromName;
          if (this.emailData.fromEmail) {
            emailDatas.from_email = this.emailData.fromEmail;
          }
          emailDatas.date = new Date();
          emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.subject, this.entity, null, null, null, document);
          emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.text, this.entity, null, null, null, document);
          if (contact.user_id) {
            emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.usertext, this.entity, null, null, null, document);
          }
          emailDatas.signature = this.emailData.signature;
          emailDatas.rgpdMail = this.emailData.rgpdMail;
          emailDatas.dpoName = this.emailData.dpoName;
          emailDatas.dpoMail = this.emailData.dpoMail;
          emailPomises.push(this._emailService.sendEmail(emailDatas).then((e) => { this.disableSend[document._id] = false; }));


        }
      });
      return promiseAll(emailPomises).then((res) => {
        if (displaySnack) {
          this.snackBar.open('Les messages ont été envoyés', '', {
            duration: 3000
          });
        }
      }).catch((e) => {
        if (displaySnack) {
          this.snackBar.open('Les messages n\'ont pas été envoyés', 'Erreur', {
            duration: 3000
          });
        }
      });
    }
  }
  addRepoDocument(docEntry: DocEntry) {

    this._spinnerService.show();
    if (docEntry.oeex) {
      const _repoOeexService =
        (docEntry.oeex.documentType === WorkbookExternalCompanyService.ENTITY_TYPE) ? this._externalCompanyService :
          ((docEntry.oeex.documentType === WorkbookOfficeService.ENTITY_TYPE) ? this._officeService
            : null);
      const _oeexService =
        (docEntry.oeex.documentType === WorkbookExternalCompanyService.ENTITY_TYPE) ? this._entityService.externalCompanyService :
          ((docEntry.oeex.documentType === WorkbookOfficeService.ENTITY_TYPE) ? this._entityService.officeService
            : null);
      if (_repoOeexService) {
        _repoOeexService.getCurrentOne(docEntry.oeex.repo_id).then((o) => {
          if (!o.documents) {
            o.documents = [];
          }
          const newDoc: EntityDocumentMetadata = this._entityService.documentService.documentMetadataService.getNewEntity(docEntry.document);
          newDoc.createdAt = null;
          newDoc.createdId = '';
          newDoc.createdName = '';
          newDoc.updatedAt = null;
          newDoc.updatedId = '';
          newDoc.updatedName = '';
          o.documents.push(this._entityService.documentService.documentMetadataService.beforeSave(newDoc));
          _repoOeexService.save(o).then((res) => {
            _oeexService.getCurrentOne(docEntry.oeex._id).then((oeex) => {
              const i = oeex.documents.findIndex((e) => (e._id === docEntry.document._id));
              if (i !== -1) {
                oeex.documents[i].repo_id = res.id;
                _oeexService.save(oeex).then((res) => {
                  this.updateRepoSelectableOeexDocuments();
                  this._spinnerService.hide();
                }).catch((err) => {
                  console.error('WorkbookDocumentListComponent.addRepoDocument error save oeex', err);
                  this._spinnerService.hide();
                });
              }
            }).catch((err) => {
              console.error('WorkbookDocumentListComponent.addRepoDocument error get oeex', err);
              this._spinnerService.hide();
            });
          }).catch((err) => {
            console.error('WorkbookDocumentListComponent.addRepoDocument error save repo_oeex', err);
            this._spinnerService.hide();
          });
        }).catch((err) => {
          console.error('WorkbookDocumentListComponent.addRepoDocument error get repo_oeex', err);
          this._spinnerService.hide();
        });
      }
    }
  }

  hasDeletable() {
    return this.checked.findIndex((c) => (true)) !== -1;
  }
  deleteAll() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer les documents séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Supprimer';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        const oeexs: { [oeex_id: string]: EntityOffice } = {};
        const all: Promise<any>[] = [];
        this.checked
          .forEach((de: DocEntry) => {
            if (de.document.parent_id) {
              all.push(this._entityService.documentService.documentMetadataService.remove(de.document));
            } else {
              const oeex = this.getEex(de.document._id);
              if (oeex) {
                const i = oeex.documents.findIndex((e) => (e._id === de.document._id));
                if (i !== -1) {
                  oeex.documents.splice(i, 1);
                }
                if (!oeexs[oeex._id]) {
                  oeexs[oeex._id] = oeex;
                }
              }
            }
          });
        const oeexSaver: Promise<any>[] = Object.keys(oeexs).map((oeex_id) => {
          const _service = (oeexs[oeex_id].documentType === 'wb_office') ? this._entityService.officeService : this._entityService.externalCompanyService;
          return _service.getCurrentOne(oeexs[oeex_id]._id).then((o) => {
            o.documents = oeexs[oeex_id].documents;
            _service.save(o);
          });
        });
        promiseAll(all).then((e) => {
          promiseAll(oeexSaver).then((res) => {
            this.checkall = false;
            this.checked = [];
            this._spinnerService.hide();
          },
            (err) => {
              //TODO: errormessage?
              this._spinnerService.hide();
            });
        },
          (err) => {
            //TODO: errormessage?
            this._spinnerService.hide();
          });
      }
    });
  }
  editSelected() {
    if (this.checked && this.checked[0] && this.checked[0].document) {
      this.openDocumentDialog(this.checked[0].document);
    }
  }
  resendSelected() {
    this.snackBar.open('Envoi en cours', '', {
      duration: 3000
    });
    this.disableSend['all'] = true;
    const sendPromises: Promise<any>[] = this.checked
      .filter((de) => (!!de.document && !!de.document.request && !!de.document.request.length))
      .map((de) => (this.resendNotifications(de, false)));
    promiseAll(sendPromises).then((res) => {
      this.disableSend['all'] = false;
      this.checkall = false;
      this.checked = [];
      this.snackBar.open('Les messages ont été envoyés', '', {
        duration: 3000
      });
    }).catch((err) => {
      this.disableSend['all'] = false;
      this.snackBar.open(err['error'] ? err['error']['error'] : 'Error', err['error'] ? err['error']['message'] : err['message'], {
        duration: 5000
      });
    });
  }
  hasResendable() {
    return this.checked.findIndex((de) => (!!de.document && !!de.document.request && !!de.document.request.length)) !== -1;
  }
}
