import {Injectable} from '@angular/core';
import {DbConfigService, PermissionsService, LoadingService} from 'app/shared';
import {EntitySecureService, ConfigEmailService, ConfigLoggerService, ConfigServerService} from 'app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends EntitySecureService {
  static LABEL_ONE = "chargement du paramètre de configuration";
  static LABEL_ALL = "chargement des paramètres de configuration";
  static LABEL_CHILDREN = "chargement des paramètres de configuration de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètre de configuration ?";
  static LABEL_DELETE = "suppression du paramètre de configuration ?";

  static ENTITY_TYPES: string[] = [
    ConfigEmailService.ENTITY_TYPE,
    ConfigLoggerService.ENTITY_TYPE,
    ConfigServerService.ENTITY_TYPE
  ];
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showAdmin', 'showConfigs'],
    CREATE: ['editConfigs'],
    UPDATE: ['editConfigs'],
    DELETE: []
  }

  static ENTITY_URL = 'config/parameters';

  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }

}
