import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {comparableString, LoggerService, formattedDate} from 'app/shared';
import {
  PreventionPlanService,
  IcpService, EntityIcp,
  SiteService, DocumentationService,
  EntityStep, EntitySite, EntityZone,
  EmergencyInstructionsService, EntityEmergencyInstructions
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from '../abstractSignableInfo.component';

@Component({
  selector: 'app-workbook-icp-info',
  templateUrl: './icpInfo.component.html',
  styleUrls: ['./icpInfo.component.scss']
})
export class WorkbookIcpInfoComponent extends AbstractSignableInfoComponent implements OnChanges {
  static FLAG_SUFFIX: string = 'icp';
  autoSave = false;
  public entity: EntityIcp;
  maxDate: Date = new Date();
  today: Date = new Date();
  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  @Input() selectedSteps: string[];
  protected _stepsSubscription: Subscription;

  //protected _officeSubscription: Subscription;
  //protected _officeSignatoryList:EntityOffice[];

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  isLoading: boolean = false;
  siteSelection: boolean = false;

  protected _zoneChangeSubscription: Subscription;
  zoneList: {[site_id: string]: EntityZone[]}
  filteredZones: EntityZone[] = [];
  zoneSelection: boolean = false;

  dateError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  protected _emergencyInstructionsSubscription: Subscription;
  emergencyInstructions: EntityEmergencyInstructions;

  constructor(
    protected _emergencyInstructionsService: EmergencyInstructionsService,
    protected _siteService: SiteService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: IcpService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredSites = this.getFilteredSites((this.entity && this.entity.iPSite) ? this.entity.iPSite : '');
        this.zoneList = {};
        this.siteList.forEach((s) => {
          this.zoneList[s._id] = s.zones.sort((a, b) => a.name.localeCompare(b.name));
        });
      }
    });

  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['workbook']) {
      this.maxDate = new Date(this.workbook.dateEnd);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._zoneChangeSubscription) {
      this._zoneChangeSubscription.unsubscribe();
    }
    if (this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription.unsubscribe();
    }
    // if (this._officeSubscription) {
    //  this._officeSubscription.unsubscribe();
    //}

  }
  protected _init(data) {
    super._init(data);
    if (!this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription = this._emergencyInstructionsService.getSingleOne().subscribe((data) => {
        if (data) {
          this.emergencyInstructions = data;
          if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions.enabledIcp) {
            this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
          }
        }
      });
    }
    if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions && this.emergencyInstructions.enabled) {
      this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
    }

    if (this.entity) {
      if (!this.id) {
        let editor = '';
        if (this.userProfile && this.userProfile.user_metadata && this.userProfile.user_metadata.given_name && this.userProfile.user_metadata.family_name && this.userProfile.email) {
          editor = `${this.userProfile.user_metadata.given_name} ${this.userProfile.user_metadata.family_name}`;
        } else if (this.userProfile && this.userProfile.email) {
          editor = this.userProfile.email;
        }
        this.entity.editor = editor;
        this.entity.editorEmail = this.userProfile.email;
        if (this.workbook
          && (!this.entity.iPZone && !this.entity.iPSite && !this.entity.iPAddress)
        ) {
          this.entity.iPZone = this.workbook.point ? this.workbook.point : '';
          this.entity.iPSite_id = this.workbook.site && this.workbook.site.repo_id ? this.workbook.site.repo_id : '';
          this.entity.iPSite = this.workbook.site && this.workbook.site.name ? this.workbook.site.name : '';
          this.entity.iPAddress = this.workbook.site && this.workbook.site.address ? this.workbook.site.address : '';
          this.entity.iPZipCode = this.workbook.site && this.workbook.site.zipCode ? this.workbook.site.zipCode : '';
          this.entity.iPCity = this.workbook.site && this.workbook.site.city ? this.workbook.site.city : '';
          this.entity.iPPhone = this.workbook.site && this.workbook.site.phone ? this.workbook.site.phone : '';
        }
      }
      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._workbookService.stepService.getChildren(this.entity.parent_id).subscribe((list) => {
        if (list) {
          this.templates = [];
          this.steps = [];
          list.sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())).forEach((e) => {
            if (e.template) {
              this.templates.push(e);
            } else if (!e.template_id) {
              this.steps.push(e);
            }
          });
          if (this.workbook && this.workbook.type === 'annual') {
            this.steps = this.steps.sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()))
          }
          if (!this.id && this.entity && this.workbook) {
            if (this.selectedSteps) {
              this.entity.steps = this.selectedSteps;
            } else {
              if (this.workbook.type === 'annual') {
                if (this.templates.length === 1 && this.steps.length === 0) {
                  this.entity.steps = this.templates.map((e) => (e._id));
                }
              } else {
                if (this.steps.length === 1) {
                  this.entity.steps = this.steps.map((e) => (e._id));
                }
              }

            }
          }
        }
      });
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {this._initSearchSite();});

  }
  switchStep(event, step_id) {
    if (this.entity && this.entity.steps) {
      if (event.checked) {
        if (this.entity.steps.indexOf(step_id) == -1) {
          this.entity.steps.push(step_id);
        }
      } else {
        let i = this.entity.steps.indexOf(step_id);
        if (i !== -1) {
          this.entity.steps.splice(i, 1)
        }
      }
    }
  }
  onChangeTimeStart(event) {
    this.entity.iPHourStart = event;
  }

  onChangeTimeEnd(event) {
    this.entity.iPHourEnd = event;
  }
  protected _initSearchSite() {
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          this.filteredSites = this.getFilteredSites(value);
          if (this.filteredSites && this.filteredSites.length > 200) {
            this.filteredSites = [];
          }
        });
    }
    if (!this._zoneChangeSubscription && this.form && this.form.controls && this.form.controls['iPZipCode']) {
      this.filteredZones = this.getFilteredZones((this.entity && this.entity.iPZone) ? this.entity.iPZone : '');
      if (this.filteredZones && this.filteredZones.length > 200) {
        this.filteredZones = [];
      }
      this._zoneChangeSubscription = this.form.controls['iPZipCode'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.zoneSelection) {
            this.zoneSelection = false;
          } else if (this.entity && this.entity.iPSite_id) {
            this.filteredZones = this.getFilteredZones(value);
          }
        });
    }
  }
  public siteSelected(site: EntitySite) {
    if (site) {
      this.entity.iPSite_id = site.repo_id;
      this.entity.iPSite = site.name;
      this.entity.iPAddress = site.address;
      this.entity.iPZipCode = site.zipCode;
      this.entity.iPCity = site.city;
      this.entity.iPPhone = site.phone;
    }
  }


  protected getFilteredSites(value: string): EntitySite[] {
    let returned = [];
    if (this.entity && this.siteList) {
      returned = value ? this.siteList.filter(site => (comparableString(site.name).indexOf(comparableString(value)) !== -1)) : this.siteList;
    }
    return returned.length > 200 ? [] : returned;
  }

  protected getFilteredZones(value: string): EntityZone[] {
    if (this.entity && this.entity.iPSite_id && this.zoneList[this.entity.iPSite_id]) {
      return value ? this.zoneList[this.entity.iPSite_id].filter(zone => (comparableString(zone.name).indexOf(comparableString(value)) !== -1)) : (this.zoneList[this.entity.iPSite_id].length > 200 ? [] : this.zoneList[this.entity.iPSite_id]);
    }
    return [];
  }

}
