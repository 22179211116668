import {UUID} from 'angular2-uuid';
import {Contact} from './contact.model';
import {Subcontractor} from './subcontractor.model';

export class Office {
  constructor(
    public id: string = UUID.UUID(),
    public user_id: string = '',
    public name: string = '',
    public address: string = '',
    public zipCode: string = '',
    public city: string = '',
    public contacts: Contact[] = []
  ) {}
}

export class ExternalCompany extends Office {
  constructor(
    id: string = UUID.UUID(),
    user_id: string = '',
    name: string = '',
    address: string = '',
    zipCode: string = '',
    city: string = '',
    public job: string = '',
    // public management: string = '',
    // public service: string = '',
    public isMain: boolean = false,
    // public isSummoned: boolean = false,
    // public isPresent: boolean = false,
    contacts: Contact[] = [],
    public subCompanies: Subcontractor[] = []
  ) {
    super(id, user_id, name, address, zipCode, city, contacts);
  }
}

