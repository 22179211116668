import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService, EntityEditDialogComponent} from 'app/shared';
import {OfficeService} from 'app/workbook-core';
import {OfficeUsersComponent} from './officeUsers.component';

@Component({
  selector: 'app-repository-officeUsers-dialog',
  templateUrl: './officeUsersDialog.component.html',
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class OfficeUsersDialogComponent extends EntityEditDialogComponent {
  entityForm: OfficeUsersComponent;

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<OfficeUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: OfficeService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService)
  }
  save() {
    this.entityForm.saveUsers().then((e) => {
      this.dialogRef.close();
    });
  }
}
