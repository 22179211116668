import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ExtraConfigService} from '../../services';
import {ExtraFieldsCommonComponent} from './extraFieldsCommon.component'

@Component({
  selector: 'app-extra-fields',
  templateUrl: './extraFields.component.html',
  styleUrls: ['./extraFields.component.scss']
})
export class ExtraFieldsComponent extends ExtraFieldsCommonComponent {

  extraType: string = 'field';
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<{[key: string]: string | number | Date | null}>();

  fieldsError: string[] = [];
  @Input() errors: string[] = [];
  @Output() errorsChange = new EventEmitter<string[]>();

  constructor(protected _extraConfigService: ExtraConfigService) {
    super(_extraConfigService);
  }
  init() {
    super.init();
    if (this.extraFields) {
      this.fieldsError = this.extraFields.map((e) => (''));
    }
  }
  updateChanges() {
    this.valueChange.emit(this.value);
  }

  checkErrors() {
    if (this.fieldsError.filter((e) => (!!e)).length || this.errors.length) {
      this.errors = this.fieldsError.filter((e) => (!!e));
      this.errorsChange.emit(this.errors);
    }
  }
}