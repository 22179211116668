import { Injectable } from '@angular/core';
import { PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntityPermitContent } from '../models/entityPermitContent.model';

@Injectable({
  providedIn: 'root'
})
export class PermitContentService extends EntitySecureService {

  autoInit = false;

  static LABEL_ONE = "chargement du contenu des permis";
  static LABEL_ALL = "chargement des contenu des permis ?";
  static LABEL_CHILDREN = "chargement des contenu des permis de l'opération ?";
  static LABEL_SAVE = "sauvegarde du contenu des permis";
  static LABEL_DELETE = "suppression du contenu des permis ?";

  static ENTITY_TYPE: string = 'repo_permit';
  static ENTITY_FIELDS: string[] = ['name', 'title', 'description'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPermitContent'],
    CREATE: ['editPermitContent'],
    UPDATE: ['editPermitContent'],
    DELETE: ['removePermitContent']
  }

  static ENTITY_URL = 'repository/permitContent';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityPermitContent {
    return new EntityPermitContent(data, clone);
  }

}
