import {EntityEditable, EditableStatus} from './editable.model';
import {ISignature, Signature} from './signature.model';
import {EmailRecipient} from 'app/shared/email/email-datas.model'

export class SignableStatus extends EditableStatus {
  static SIGNING: number = 2;
  static SIGNED: number = 3;
  static OUTDATED: number = 4;
  static LABELS: string[] = [
    'En préparation',
    'Préparé',
    'À signer',
    'Signé',
    'Obsolète'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est obsolète, une edition plus recente la remplace'
  ];

  static getLabels(withSignatories: boolean = true) {
    return withSignatories//(signable && signable.signatures && signable.signatures.length)
      ? SignableStatus.LABELS
      : EditableStatus.LABELS;
  }
  static getDescriptions(withSignatories: boolean = true) {
    return withSignatories
      ? SignableStatus.DESCRIPTIONS
      : EditableStatus.DESCRIPTIONS;
  }
  static getLabel(status: number, withSignatories: boolean = true) {
    return withSignatories
      ? SignableStatus.LABELS[status] ? SignableStatus.LABELS[status] : 'status' + status
      : EditableStatus.getStatusLabel(status);
  }
  static getDescription(status: number, withSignatories: boolean = true) {
    return withSignatories
      ? SignableStatus.DESCRIPTIONS[status] ? SignableStatus.DESCRIPTIONS[status] : 'status: ' + status
      : EditableStatus.getStatusDescription(status);
  }
}
export interface ISignable {
  signAgreement: string;
  signatures: ISignature[];
  withoutSignatories: boolean;
  notifications: EmailRecipient[];
  isSigned();
  signUp(signature: ISignature);
  removeSignature(signature: ISignature);
}
export abstract class Signable extends EntityEditable implements ISignable {
  signAgreement: string;
  signatures: ISignature[];
  withoutSignatories: boolean = false;
  notifications: EmailRecipient[];
  isSigned() {
    return this.signatures && this.signatures.length > 0;
  }
  signUp(signature: ISignature) {
    let existingIndex = this.signatures.findIndex((e) => (e.contact_id === signature.contact_id && e.company_id === signature.company_id));
    if (existingIndex === -1) {
      this.signatures.push(signature);
    } else {
      this.signatures[existingIndex] = signature;
    }
    //this.status = SignableStatus.SIGNED;
  }
  removeSignature(signature: ISignature) {
    if (this.status === SignableStatus.VALIDATED) {
      let existingIndex = this.signatures.findIndex((e) => (e.contact_id === signature.contact_id && e.company_id === signature.company_id));
      if (existingIndex !== -1) {
        this.signatures.splice(existingIndex, 1);
      }
      if (this.signatures.length === 0) {
        this.status = SignableStatus.VALIDATED;
      }
    }
  }
}
export class Attendance extends Signature implements ISignature {
  checkpoint: string;
  checklist: {[name: string]: boolean | string} = {}
  constructor(data) {
    super(data);
    this.checkpoint = (data && data.checkpoint) ? data.checkpoint : '';
    this.checklist = (data && data.checklist) ? Object.assign({}, data.checklist) : {};
  }
}
export class EntitySignable extends EntityEditable implements ISignable {
  static PREFIX: string = 'ES';
  signAgreement: string;
  signatures: ISignature[];
  withoutSignatories: boolean;
  notifications: EmailRecipient[];
  attendances: Attendance[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.signAgreement = (data && data.signAgreement) ? data.signAgreement : '';
    this.withoutSignatories = (data && !!data.withoutSignatories);
    this.signatures = (data && data.signatures) ? data.signatures.map((e) => Object.assign({}, e)) : [];
    this.notifications = data && data.notifications ? data.notifications.map(e => Object.assign({}, e)) : [];
    this.attendances = (data && data.attendances && Array.isArray(data.attendances)) ? data.attendances.map((e) => (new Attendance(e))) : [];
  }
  isValid() {
    return this.status > SignableStatus.SIGNING || (this.withoutSignatories && this.status > SignableStatus.VALIDATED);
  }
  isSigned() {
    return this.signatures && this.signatures.length > 0;
  }
  signUp(signature: ISignature) {
    let existingIndex = this.signatures.findIndex((e) => (e.contact_id === signature.contact_id && e.company_id === signature.company_id && e.companyType === signature.companyType));
    if (existingIndex === -1) {
      this.signatures.push(signature);
    } else {
      this.signatures[existingIndex] = signature;
    }
    //this.status = SignableStatus.SIGNED;
  }
  removeSignature(signature: ISignature) {
    if (this.status === SignableStatus.VALIDATED) {
      let existingIndex = this.signatures.findIndex((e) => (e.contact_id === signature.contact_id && e.company_id === signature.company_id && e.companyType === signature.companyType));
      if (existingIndex !== -1) {
        this.signatures.splice(existingIndex, 1);
      }
      if (this.signatures.length === 0) {
        this.status = SignableStatus.VALIDATED;
      }
    }
  }
}

