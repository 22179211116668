import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {MeasureService, EntityMeasure} from 'app/workbook-core';

@Component({
  selector: 'app-repository-measure',
  templateUrl: './measure.component.html'
  // ,styleUrls: ['./measure.component.scss']
})
export class MeasureComponent extends EntityFormComponent {

  entity: EntityMeasure;
  isGlobal: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: MeasureService

  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.isGlobal = (this.entity.type === 'global');
    }
  }
  changeGlobal() {
    this.entity.type = this.isGlobal ? 'global' : '';
  }

}
