import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityOffice} from '../models';

@Injectable({
  providedIn: 'root'
})
export class OfficeService extends EntitySecureService {

  static LABEL_ONE = "chargement du service";
  static LABEL_ALL = "chargement des services";
  static LABEL_CHILDREN = "chargement des services de l'opération ?";
  static LABEL_SAVE = "sauvegarde du service";
  static LABEL_DELETE = "suppression du service";

  static ENTITY_TYPE: string = 'repo_office';
  static ENTITY_FIELDS: string[] = ['name', 'job', 'main', 'address', 'zipCode', 'city', 'contacts', 'documents', 'defaultVisibilityOccasional', 'defaultVisibilityAnnual', 'defaultVisibilityProtocol', 'defaultAdd'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showOffice'],
    CREATE: ['createOffice', 'editOffice'],
    UPDATE: ['editOffice'],
    DELETE: ['removeOffice', 'editOffice']
  }

  static ENTITY_URL = 'repository/office';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityOffice {
    return new EntityOffice(data, clone);
  }
  getUserContactsOffices() {
    return this.getAll()
      .pipe(map((list: EntityOffice[]) => {
        const officeList: EntityOffice[] = [];
        const userid = this.getUserId();
        if (list) {
          list.forEach((o) => {
            if (o.contacts.findIndex((c) => (c.user_id === userid)) !== -1) {
              const _office = this.factory(o);
              _office.contacts = o.contacts.filter((c) => (c.user_id === userid || c.isDefault));
              _office.documents = o.documents.filter((d) => (d.isDefault));
              officeList.push(_office);
            }
          })
        }
        return officeList;
      }));
  }
  static getOfficeScope(list: EntityOffice[]): {[id: string]: string[]} {
    const officeScope: {[id: string]: string[]} = {}
    const parent: {[id: string]: string} = {};
    const children: {[id: string]: string[]} = {};
    list
      .forEach((o) => {
        parent[o._id] = o.main;
      });
    Object.keys(parent).filter((e) => (e))
      .forEach((child_id) => {
        if (parent[child_id]) {
          if (children[parent[child_id]]) {
            children[parent[child_id]].push(child_id);
          } else {
            children[parent[child_id]] = [child_id];
          }
        }
      });
    let id;
    list.forEach((o) => {
      //enfants
      const x = OfficeService.getChildren(children, o._id);
      //element
      x.push(o._id)
      //ascendants
      id = o._id;
      while (parent[id] && id !== parent[id]) {
        if (x.indexOf(parent[id]) === -1) {
          x.push(parent[id]);
        }
        id = parent[id];
      }
      officeScope[o._id] = x;
    });
    return officeScope;
  }

  static getChildren(directChildren: {[id: string]: string[]}, id: string): string[] {
    let returned: string[] = [];
    let children = directChildren[id];
    if (children) {
      children.forEach((x) => {
        returned.push(x);
        if (x !== id) {
          returned = [...returned, ...OfficeService.getChildren(directChildren, x)];
        }
      });
    }
    return returned
  }
}