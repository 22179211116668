import { MatDialog } from '@angular/material';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { DocumentService } from '../document';
import { EntitySignable, SignableStatus } from '../models';
import { EntityEditableFormComponent } from './entityEditableForm.component';

export class EntitySignableFormComponent extends EntityEditableFormComponent {
  entity: EntitySignable;
  sign_agreement: string = '';

  withSignatories: boolean = true;
  signableStatus = SignableStatus.LABELS;
  signableStatusDescritpions = SignableStatus.DESCRIPTIONS;

  constructor(
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(dialog, _documentService, _logger, _entityService);

  }

  refreshSignableStatus(withSignatories = false) {
    this.withSignatories = withSignatories;
    this.signableStatus = SignableStatus.getLabels(withSignatories);
    this.signableStatusDescritpions = SignableStatus.getDescriptions(withSignatories);
  }
  getDescriptionStatus(status: number) {
    return this.signableStatusDescritpions[status] ? this.signableStatusDescritpions[status] : '';
  }

}
