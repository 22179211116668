import { Injectable, NgZone } from '@angular/core';
import { DbCommonService } from './dbCommon.service';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable()
export class DbConfigService extends DbCommonService {

  static DBCONFIG = 'couchDBConfig';

  constructor(protected authService: AuthService, protected zone: NgZone) {
    super(authService, zone);
    this._init().catch((error) => {
      console.error(error);
    });
  }
}
