import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UUID } from 'angular2-uuid';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService, EntityEditDialogComponent } from 'app/shared';
import { WorkbookStepService, EntityStep, EntityZone, EntityWorkbook, PreventionPlanService } from 'app/workbook-core';
import { WorkbookStepInfoComponent } from './stepInfo.component';

@Component({
  selector: 'app-workbook-step-dialog',
  templateUrl: './step-dialog.component.html',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookStepDialogComponent extends EntityEditDialogComponent {
  entityForm: WorkbookStepInfoComponent;
  dateStart: Date;
  dateEnd: Date;
  entity: EntityStep;
  title = 'INTERVENTION';
  simple: boolean = false;
  workbook: EntityWorkbook;
  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _workbookService: PreventionPlanService,
    public dialogRef: MatDialogRef<WorkbookStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        this.entity = data['entity'];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
        if (this.workbook.simple) {
          this.simple = true;
          this.title = (this.workbook.type === 'annual') ? 'PROTOCOLE' : 'OPÉRATION';
        } else {
          this.title = (data['template'] || (this.entity && this.entity.template))
            ? 'CHANTIER TYPE'
            : ('INTERVENTION' + ((this.workbook.type === 'annual' && !data['template_id'] && !(this.entity && this.entity.template_id)) ? ' SPÉCIFIQUE' : ''));
          this.dateStart = new Date(this.workbook.dateStart);
          this.dateEnd = new Date(this.workbook.dateEnd);
        }
      }
    }
  }
  protected _beforeSave(entity: EntityStep) {
    if (entity.site && !entity.site._id) {
      entity.site._id = UUID.UUID();
    }
    /*
    if (!entity.zone) {
      entity.zone = new EntityZone({ name: entity.point });
    } else if (entity.point !== entity.zone.name) {
      entity.zone.name = entity.point;
    }
    if (entity.template && entity.locations) {
      const i = entity.locations.findIndex((e) => (e._id === entity.site._id));
      if (i !== -1) {
        const j = entity.locations[i].zones.findIndex((e) => ((e.repo_id && e.repo_id === entity.zone.repo_id) || e._id === entity.zone._id || e.name === entity.zone.name));
        if (j !== -1) {
          entity.zone = entity.locations[i].zones[j];
          entity.locations[i].zones.splice(j, 1);
        }
        if (!entity.locations[i].zones.length) {
          entity.locations.splice(i, 1);
        }
      }
    }
    */
    return super._beforeSave(entity)
  }
  protected _afterSave(res) {
    if (this.workbook && this.entityForm && this.entityForm.entity) {
      this._workbookService.updateWorkbookInspectionStep(this.workbook, this.entityForm.entity);
    }
    super._afterSave(res);
  }
}
