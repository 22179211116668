import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Auth0Service} from './auth0.service';
import {Auth0ConfigService} from './auth0-config.service';

@Injectable()
export class AuthRoleService extends Auth0Service {

  constructor(
    protected config: Auth0ConfigService,
    protected http: HttpClient
  ) {
    super(config, http);
  }

  getUsersList(): Observable<any> {
    return this.getAPICall('users', {fields: 'user_id,email,username,name,nickname', include_fields: true});
  }
  getRolesList(): Observable<any> {
    return this.getAPICall('roles');
  }
  addUserRoles(userId, roles) {
    if (userId.indexOf('|') !== -1) {
      userId = userId.split('|')[1];
    }
    return this.patchAPICall('users/' + userId + '/roles', roles);
  }
}
