import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, EntitySimpleListComponent} from 'app/shared';
import {RoleService} from 'app/shared/services';
import {EntityRole} from 'app/shared/models';
import {RoleDialogComponent} from './roleDialog.component';


@Component({
  selector: 'app-config-role-list',
  templateUrl: './roleList.component.html',
  styleUrls: ['./roleList.component.scss']
})

export class RoleListComponent extends EntitySimpleListComponent {

  entityList: EntityRole[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce role ?';
  addConfirmMessage = 'Nouveau role créé';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: RoleService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);

  }
  canDelete(name: string) {
    return !(EntityRole.ROLES_ORDER.indexOf(name) !== -1 && !this._entityService.hasRole('PP_ADMIN_TREPIED'));
  }
  protected _sortList() {
    this.entityList.sort((a, b) => EntityRole.ROLES_ORDER.indexOf(b.name) - EntityRole.ROLES_ORDER.indexOf(a.name));
  }


  popupEntity(id: string, width = '80%') {
    const dialogRef = this.dialog.open(RoleDialogComponent, {
      disableClose: true,
      minWidth: width,
      data: {
        id: id
      }
    });

    dialogRef.afterClosed().subscribe(e => {
      if (e && e !== 'undefined') {
        // TODO:scroll down
      }
    });
  }
}
