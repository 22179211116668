import {Injectable, NgZone} from '@angular/core';
import {storageGet, storageSet} from '../utils';
import {DbCommonService} from './dbCommon.service';
import {AuthService} from 'app/shared/auth/auth.service';
import {LoadingService} from '../services/loading.service';

// DEBUGGING: enable/Disable pouchDB debug mode
// PouchDB.debug.enable('pouchdb:api');
// PouchDB.debug.disable();

@Injectable()
export class DbFilteredService extends DbCommonService {
  filter = 'app/filtered';
  nofilter = 'app/only_parents';
  parents: string[];
  skipCheckBrokenSync: boolean = true;
  initialized: boolean;

  constructor(
    protected _loadingService: LoadingService,
    protected authService: AuthService,
    protected zone: NgZone
  ) {
    super(authService, zone);
    //console.log('constructor DbFilteredService');
    this._loadingService.setSyncState(DbFilteredService.SYNSTATE_INIT);
  }
  protected async _init() {
    if (!this.initialized) {
      if (storageGet('config-syncParents')) {
        console.log('initial init DbFilteredService from storage');
        this.setParents(storageGet('config-syncParents'));
      } else {
        console.log('initial init DbFilteredService no storage');
        this.initialized = true;
      }
    }
    super._init();
  }




  getFilter() {
    return (this.parents && this.parents.length > 0) ? this.filter : this.nofilter;
  }

  addNewParent(parent: string) {
    if (parent && this.parents && this.parents.indexOf(parent) === -1) {
      this.setSyncComplete(parent);
      //console.log('addNewParent', [this.parents, this.query_params, this.syncCompletedChildren])
      this.parents.push(parent);
      this.parents = this.parents.sort();
      storageSet('config-syncParents', this.parents);
      this.query_params['parents'] = this.parents.join(',');
      this.updateLiveSync();
    }
  }

  setParents(parents: string[] = []) {
    console.log('setParents (' + (this.initialized ? 'initialized' : ('first' + (this.filterTooLong ? '/filterTooLong' : ''))) + ') : ', [parents, this.parents, this.query_params]);
    if (!this.parents
      || this.parents.length !== parents.length
      || this.parents.sort().join(',') !== parents.sort().join(',')
      || (!this.query_params['parents'] || this.query_params['parents'] !== parents.sort().join(','))
    ) {
      let toBeSynced = [];
      let unsynced = [];
      const emptyNoChange = ((!this.query_params || !this.query_params['parents']) && (!parents || !parents.length))
      if (this.initialized && !emptyNoChange) {
        if (this.parents) {
          toBeSynced = parents.filter((e) => (this.parents.indexOf(e) === -1));
          unsynced = this.parents.filter((e) => (parents.indexOf(e) === -1))
        } else {
          toBeSynced = parents;
        }
      }
      this.parents = parents.sort();
      storageSet('config-syncParents', this.parents);
      this.query_params['parents'] = this.parents.join(',')
        //  + ',aaaaaaaaaaaaaaaaaaaaaaaaaaaaa,bbbbbbbbbbbbbbbbbbbbbbbbbbb,ccccccccccccccccccccccccccccc,ddddddddddddddddddddddddddddddddddd,eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee,ffffffffffffffffffffffffffffffffff,gggggggggggggggggggggggggggggggggg,hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh,iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii,jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj,kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk,lllllllllllllllllllllllllllllllllllll,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn,oooooooooooooooooooooooooooooooooooooooooooo,ppppppppppppppppppppppppppppppppppppppppppp,qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq,rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr,ssssssssssssssssssssssssssssssssssssssss,tttttttttttttttttttttttttttttttttttttttt,uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu,vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv,wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww,xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy,zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz'
        //  + ',aaaaaaaaaaaaaaaaaaaaaaaaaaaaa,bbbbbbbbbbbbbbbbbbbbbbbbbbb,ccccccccccccccccccccccccccccc,ddddddddddddddddddddddddddddddddddd,eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee,ffffffffffffffffffffffffffffffffff,gggggggggggggggggggggggggggggggggg,hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh,iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii,jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj,kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk,lllllllllllllllllllllllllllllllllllll,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn,oooooooooooooooooooooooooooooooooooooooooooo,ppppppppppppppppppppppppppppppppppppppppppp,qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq,rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr,ssssssssssssssssssssssssssssssssssssssss,tttttttttttttttttttttttttttttttttttttttt,uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu,vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv,wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww,xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy,zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz'
        //  + ',aaaaaaaaaaaaaaaaaaaaaaaaaaaaa,bbbbbbbbbbbbbbbbbbbbbbbbbbb,ccccccccccccccccccccccccccccc,ddddddddddddddddddddddddddddddddddd,eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee,ffffffffffffffffffffffffffffffffff,gggggggggggggggggggggggggggggggggg,hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh,iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii,jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj,kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk,lllllllllllllllllllllllllllllllllllll,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn,oooooooooooooooooooooooooooooooooooooooooooo,ppppppppppppppppppppppppppppppppppppppppppp,qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq,rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr,ssssssssssssssssssssssssssssssssssssssss,tttttttttttttttttttttttttttttttttttttttt,uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu,vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv,wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww,xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy,zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz'
        //  + ',aaaaaaaaaaaaaaaaaaaaaaaaaaaaa,bbbbbbbbbbbbbbbbbbbbbbbbbbb,ccccccccccccccccccccccccccccc,ddddddddddddddddddddddddddddddddddd,eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee,ffffffffffffffffffffffffffffffffff,gggggggggggggggggggggggggggggggggg,hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh,iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii,jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj,kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk,lllllllllllllllllllllllllllllllllllll,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn,oooooooooooooooooooooooooooooooooooooooooooo,ppppppppppppppppppppppppppppppppppppppppppp,qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq,rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr,ssssssssssssssssssssssssssssssssssssssss,tttttttttttttttttttttttttttttttttttttttt,uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu,vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv,wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww,xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy,zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz'
        //  + ',aaaaaaaaaaaaaaaaaaaaaaaaaaaaa,bbbbbbbbbbbbbbbbbbbbbbbbbbb,ccccccccccccccccccccccccccccc,ddddddddddddddddddddddddddddddddddd,eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee,ffffffffffffffffffffffffffffffffff,gggggggggggggggggggggggggggggggggg,hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh,iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii,jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj,kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk,lllllllllllllllllllllllllllllllllllll,mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm,nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn,oooooooooooooooooooooooooooooooooooooooooooo,ppppppppppppppppppppppppppppppppppppppppppp,qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq,rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr,ssssssssssssssssssssssssssssssssssssssss,tttttttttttttttttttttttttttttttttttttttt,uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu,vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv,wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww,xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx,yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy,zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz'
        ;
      //console.log('setParents :' + (this.parents ? this.parents.length : 'none') + ' (' + (this.query_params && this.query_params['parents'] ? this.query_params['parents'].length : '-') + ')');
      if (this.initialized) {
        if (!(this.filterTooLong && this.query_params['parents'].length > DbFilteredService.PARENTS_LENGTH)
          && !emptyNoChange) {
          if (!toBeSynced.length) {
            console.log('setParents updateLiveSync', unsynced);
            //this.updateLiveSync();
            this._addSync(unsynced, false);
          } else if (toBeSynced.length > 10) {
            console.log('setParents reSync');
            this.last_seq = '';
            this.reSync();
          } else {
            console.log('setParents addSyncChildren', toBeSynced);
            this.addSyncChildren([...unsynced, ...toBeSynced]);
          }
        }
      } else {
        this.initialized = true;
      }
    }
    /*
     else {
      console.log('no change setParents', this.parents);
    }
    */
  }

  protected _setStateError() {
    super._setStateError();
    this._loadingService.setSyncState(DbFilteredService.SYNSTATE_ERROR);
  }
  protected _setStateSyncing() {
    super._setStateSyncing();
    this._loadingService.setSyncState(DbFilteredService.SYNSTATE_SYNCING);
  }
  protected _setStateSync() {
    super._setStateSync();
    this._loadingService.setSyncState(DbFilteredService.SYNSTATE_SYNC);
  }
  public async addSyncChildren(toBeResynced: string[]) {
    console.log('addSyncChildren', toBeResynced);
    if (this.replicator && toBeResynced.length) {
      this._setStateSyncing();
      let toSync = [];
      for (const parent_id of toBeResynced) {
        const res = await this.loadChildrenRemote(parent_id, true)
          .catch((error) => {
            console.log('error loadChildrenRemote(' + parent_id + ', true)', error);
          });
        if (res && res.length) {
          toSync = [...toSync, ...res];
        }
        await new Promise(resolve => setTimeout(resolve, 150));
      }
      //this.__localPouchDb.bulkDocs(toSync);
      //this._sync(this.__localPouchDb, this.__remotePouchDb, {retry: true, live: true});
      this._addSync(toSync.map((e) => (e._id)));
    }
  }

  protected _addSync(toBeSynced: string[], changeFrom: boolean = true) {
    const _component = this;
    //const start = new Date();
    const opt = {
      doc_ids: toBeSynced,
      retry: true,
      live: false,
      //checkpoint: true,
      style: 'main_only',
      batch_size: 200,
      batches_limit: 1
    }
    //if (this.replicator) {
    //  console.log('_sync cancel previous replication', this.dbName)
    //  this.replicator.cancel();
    //  this.replicator.removeAllListeners();
    //}
    //console.log((new Date()).toLocaleTimeString() + ': ' + 'addSync.from ' + this.dbName, opt);
    this._setStateSyncing();
    const _replicator = (changeFrom
      ? this.__localPouchDb.replicate.from(this.__remotePouchDb, opt)
      : this.__localPouchDb.replicate.to(this.__remotePouchDb, opt))
      .on('complete', function (cplt) {
        //const time = new Date().getTime() - start.getTime();
        //console.log('addSync complete (' + time + ') :' + _component.constructor.name, cplt);
        //delete opt.doc_ids;
        //_component.last_seq = cplt.last_seq;
        _component.zone.run(() => {
          if (_component.replicator) {
            console.log('_addSync cancel previous replication', _component.dbName);
            _replicator.removeAllListeners();
            _replicator.cancel();
            //_component.replicator.removeAllListeners();
            //_component.replicator.cancel();
            _component._sync(_component.__localPouchDb, _component.__remotePouchDb, {retry: true, live: true});
          }
        });
      }).on('error', (error) => {
        _component._setStateError();
        console.error('addSync  error :' + _component.constructor.name, error);
        _replicator.removeAllListeners();
        _replicator.cancel();
        if (error && error.status && error.status === 403 && error.message === "_writer access is required for this request") {
          if (_component.replicator) {
            _component.replicator.removeAllListeners();
            _component.replicator.cancel();
          }
          opt.live = false;
          _component._sync(_component.__localPouchDb, _component.__remotePouchDb, opt, true);
        }
      }).on('denied', function (x) {
        _component._setStateError();
        _replicator.removeAllListeners();
        _replicator.cancel();
        console.error('addSync denied :' + _component.constructor.name, x);
      })
      /*
      .on('paused', function(x) {
        _component.syncOnlineChange.next(false);
        console.error('sync paused :' + remoteDb.name, x);
      }).on('active', function(x) {
        _component.syncOnlineChange.next(true);
        console.error('sync active :' + remoteDb.name, x);
      })
      */
      ;
  }

}
