import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityJob} from '../models';

@Injectable({
  providedIn: 'root'
})
export class JobService extends EntitySecureService {
  static LABEL_ONE = "chargement du métier";
  static LABEL_ALL = "chargement des métiers";
  static LABEL_CHILDREN = "chargement des métiers de l'opération";
  static LABEL_SAVE = "sauvegarde du métier";
  static LABEL_DELETE = "suppression du métier";

  static ENTITY_TYPE: string = 'repo_job';
  static ENTITY_FIELDS: string[] = ['name'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showJobs', 'editJobs'],
    CREATE: ['createJob'],
    UPDATE: ['editJob', 'editJobs'],
    DELETE: ['removeJob', 'deleteJob']
  }

  static ENTITY_URL = 'repository/job';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityJob {
    return new EntityJob(data, clone);
  }

}
