import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, ExternalCompanyService, EntityExternalCompany} from 'app/workbook-core';
import {WorkbookExternalCompanyListComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-prevention-plan-external-company-list',
  templateUrl: './externalCompanyList.component.html',
  styleUrls: ['../../workbook-shared/externalCompany/externalCompanyList.component.scss']
})

export class PreventionPlanExternalCompanyListComponent extends WorkbookExternalCompanyListComponent {

  entityList: EntityExternalCompany[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette entreprise ?';
  addConfirmMessage = 'Nouvelle entreprise créée';

  constructor(
    protected _officeService: ExternalCompanyService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_officeService, dialog, snackBar, _route, _router, _logger, _entityService);
  }
}
