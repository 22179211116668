import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared';
import { WorkbookGuard } from './workbook.guard';
import {
  SiteService,
  OfficeService,
  ExternalCompanyService,
  JobService,
  OrganizationService,
  CategoryService,
  RiskService,
  MeasureService,
  LdcmService,
  FirePermitContentService,
  PermitContentService,
  ProtocolContentService,
  DefaultVisibilityService,
  DocumentationService,
  DocumentsRequestService,
  AuditCheckpointsService
} from './repository';
import {
  WorkbookService,
  WorkbookStepService,
  WorkbookOfficeService,
  WorkbookExternalCompanyService,
  WorkbookMeasureService,
  SignatureService,
  ActivityService,
  IcpService,
  FirePermitService,
  SignablePermitService,
  SecurityProtocolService,
  ExploitingCompanyExportComponent,
  OfficeExportComponent,
  ExternalCompanyExportComponent,
  OfficesExportComponent,
  ExternalCompaniesExportComponent,
  StepExportComponent,
  IcpInfoExportComponent,
  ImplementationExportComponent,
  IcInfoExportComponent,
  AuditService
} from './workbook';
import {
  PreventionPlanService,
  SignablePreventionPlanService,
  PreventionPlanAgreementService,
  PreventionPlanExportComponent,
  IcpExportComponent,
  PdpExportComponent,
  FirePermitExportComponent,
  SignablePermitExportComponent,
  AuditExportComponent,
  SecurityProtocolExportComponent
} from './preventionPlan';
import { UserSyncComponent } from './userSync/userSync.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    PreventionPlanExportComponent,
    IcpExportComponent,
    PdpExportComponent,
    FirePermitExportComponent,
    SignablePermitExportComponent,
    AuditExportComponent,
    SecurityProtocolExportComponent,
    ExploitingCompanyExportComponent,
    OfficeExportComponent,
    ExternalCompanyExportComponent,
    OfficesExportComponent,
    ExternalCompaniesExportComponent,
    StepExportComponent,
    IcpInfoExportComponent,
    IcInfoExportComponent,
    ImplementationExportComponent,
    UserSyncComponent
  ],
  exports: [
    PreventionPlanExportComponent,
    IcpExportComponent,
    PdpExportComponent,
    FirePermitExportComponent,
    SignablePermitExportComponent,
    AuditExportComponent,
    SecurityProtocolExportComponent,
    ExploitingCompanyExportComponent,
    OfficeExportComponent,
    ExternalCompanyExportComponent,
    OfficesExportComponent,
    ExternalCompaniesExportComponent,
    StepExportComponent,
    IcpInfoExportComponent,
    ImplementationExportComponent,
    IcInfoExportComponent,
    UserSyncComponent
  ],
  entryComponents: [
    UserSyncComponent
  ],
  providers: [
    WorkbookService,
    WorkbookStepService,
    WorkbookOfficeService,
    WorkbookExternalCompanyService,
    WorkbookMeasureService,
    ExternalCompanyService,
    JobService,
    OrganizationService,
    CategoryService,
    MeasureService,
    LdcmService,
    OfficeService,
    RiskService,
    SiteService,
    SignatureService,
    ActivityService,
    PreventionPlanService,
    IcpService,
    PreventionPlanAgreementService,
    SignablePreventionPlanService,
    FirePermitContentService,
    PermitContentService,
    FirePermitService,
    SignablePermitService,
    SecurityProtocolService,
    ProtocolContentService,
    DefaultVisibilityService,
    DocumentationService,
    DocumentsRequestService,
    AuditCheckpointsService,
    AuditService,
    WorkbookGuard
  ],
})
export class WorkbookCoreModule { }
