import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, ConfirmDialogComponent, SignableStatus} from 'app/shared';
import {WorkbookSignableListComponent, SecurityProtocolInfoDialogComponent, SecurityProtocolDialogComponent} from 'app/workbook-shared';
import {
  PreventionPlanService,
  EntityPreventionPlan,
  SecurityProtocolService,
  EntitySecurityProtocol, SPState,
  EntityStep, DocumentationService
} from 'app/workbook-core';

@Component({
  selector: 'app-signable-protocol-list',
  templateUrl: './securityProtocollList.component.html',
  styleUrls: ['./securityProtocollList.component.scss']
})

export class SecurityProtocolListComponent extends WorkbookSignableListComponent {

  entity: EntityPreventionPlan;
  entityList: EntitySecurityProtocol[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce protocole ?';
  addConfirmMessage = 'Nouveau protocole créée';

  stepList: {[step_id: string]: EntityStep} = {};
  protected _stepsSubscription: Subscription;
  states: {[icp_id: string]: number} = {};

  dataSource: MatTableDataSource<EntitySecurityProtocol> = new MatTableDataSource([]);
  displayedColumns = ['name', 'editor', 'step', 'dateStart', 'status', 'actions']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';


  statusLabels = SPState.getLabels();
  statusDescriptions = SPState.getDescriptions();

  constructor(
    //preload
    protected _documentationService: DocumentationService,
    protected _childService: SecurityProtocolService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list) => {
      if (list) {
        this.stepList = {};
        list
          .forEach((step) => {
            this.stepList[step._id] = step;
          });
      }
    });
    if (this.entityList) {
      this.refreshDataSource();
    }
  }
  refreshStates() {
    if (this.entityList && this.entity) {
      this.states = {};
      this.entityList.forEach((sps) => {
        this.states[sps._id] = this._getChildService().getState(sps, this.entity.status);
      });
    }
  }


  getState(sps: EntitySecurityProtocol) {
    return sps && sps._id && this.states[sps._id] ? this.states[sps._id] : 0;

  }
  getLabelState(sps: EntitySecurityProtocol) {
    return SPState.getLabel(this.getState(sps));

  }
  getDescriptionState(sps: EntitySecurityProtocol) {
    return SPState.getDescription(this.getState(sps));

  }

  protected _getChildService() {
    return this._entityService.securityProtocolService;
  }

  /**
   * Create dialog popup inspection
   */
  public selectSignable(securityProtocol: EntitySecurityProtocol = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(SecurityProtocolInfoDialogComponent, {
      disableClose: true,
      width: '920px',
      maxWidth: '90vw',
      data: {
        id: securityProtocol ? securityProtocol._id : '',
        entity: (securityProtocol && !securityProtocol._id) ? securityProtocol : null,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }
  public openDialog(protocol = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(SecurityProtocolDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        id: protocol ? protocol._id : '',
        entity: protocol,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }

  public duplicateSecurityProtocol(element) {
    this._getChildService().getCurrentOne(element._id).then((e) => {
      const newElement: EntitySecurityProtocol = this._getChildService().getNewEntity(e, true);
      newElement.status = 0;
      newElement.signatures = [];
      newElement.dateStart = null;
      newElement.dateEnd = null;
      newElement.hourStart = '';
      newElement.hourEnd = '';
      newElement.content = '';
      newElement.createdAt = null;
      newElement.createdId = '';
      newElement.createdName = '';
      newElement.editor = '';
      newElement.editorEmail = '';
      this.selectSignable(newElement);
    });
  }

  outdateSignable(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = "Confirmer";
    dialogRefDel.componentInstance.confirmMessage = "Voulez-vous rendre cette édition obsolète ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().get(entity._id).toPromise().then((signable: EntitySecurityProtocol) => {
          if (signable) {
            signable.status = SignableStatus.OUTDATED;
            this._getChildService().save(signable).then((e) => {
              this.updateElement(signable);
            });
          }
        });
      }
    });
  }
}
