import {Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AbstractRouteDataComponent} from '../abstractRouteData.component';
import {LoggerService} from 'app/shared/logger';
import {PermissionsService} from 'app/shared/auth';

export class SidenavComponent extends AbstractRouteDataComponent implements OnChanges {
  @Input() id: string;
  name = 'sidenav';
  title = '';
  icon = '';
  path: string;
  menu: Menu;


  constructor(
    protected _permissionsService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService) {
    super(_route, _logger);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['id']) {
      this._updateMenu();
    }
  }
  _init() {
    this._route.url.subscribe((urlf) => {
      this.path = this._route.snapshot.routeConfig.path;
      this._updateMenu();
    });
  }

  _updateMenu() {
    this.menu = new Menu(this.name, this.title);
    this._getRoutes().forEach(r => {
      const selected = this.path && this.path.indexOf(r.path) !== -1;
      if (r.data && r.data[this.name] && r.data[this.name]['group']) {
        let existingItem: MenuItem = this.menu.items.find(i => i.title === r.data[this.name]['group']);
        if (!existingItem) {
          existingItem = new MenuItem(
            r.data[this.name]['orderGroup'],
            r.data[this.name]['group'],
            r.data[this.name]['icon'],
            this._getPath(r.path),
            'mat-elevation-z0',
            selected
          );
          this.menu.items.push(existingItem);
        } else if (selected && !existingItem.selected) {
          existingItem.selected = selected;
        }
        if (r.data[this.name]['title']) {

          let existingSubItem: MenuSubItem = existingItem.subItems.find(i => i.title === r.data[this.name]['title']);
          if (!existingSubItem) {
            existingSubItem = new MenuSubItem(
              r.data[this.name]['order'],
              r.data[this.name]['title'],
              r.data[this.name]['icon'],
              this._getPath(r.path),
              '',
              selected
            );
            existingItem.subItems.push(existingSubItem);
          }
        }
      }
    });
    this.menu.items.forEach(i => {i.subItems.sort((a, b) => (a.order - b.order))});
    this.menu.items.sort((a, b) => (a.order - b.order));
  }
  isExpanded(item: MenuItem) {
    return item.selected || (item.subItems && item.subItems.findIndex((e) => (e.selected)) !== -1);
  }
  protected _getRoutes() {
    return this._router.config.filter(e => {
      return e.data
        && e.data[this.name]
        && this._permissionsService.canActivate(e.data['permissions']);
    });
  }
  protected _getPath(path: string) {
    return '/' + path.replace(':id', this.id);
  }

  hasPermission(right: string) {
    return this._permissionsService.hasPermission(right);
  }
}

export class MenuSubItem {
  order: number;
  title: string;
  icon: string;
  link: string;
  style: string;
  selected: boolean;
  constructor(
    order = 0,
    title = '',
    icon = '',
    link = '',
    style = '',
    selected = false
  ) {
    this.order = order;
    this.title = title;
    this.icon = icon;
    this.link = link;
    this.style = style;
    this.selected = selected;
  }
}

export class MenuItem extends MenuSubItem {
  subItems: MenuSubItem[];
  constructor(
    order = 0,
    title = '',
    icon = '',
    link = '',
    style = '',
    selected = false
  ) {
    super(order, title, icon, link, style, selected);
    this.subItems = [];
  }

}


export class Menu {
  name: string;
  title: string;
  icon: string;
  items: MenuItem[];
  constructor(name = 'sidebar', title = '', icon = '') {
    this.name = name;
    this.title = title;
    this.icon = icon;
    this.items = [];
  }
}