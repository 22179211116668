import {Component, ViewChild, ElementRef, OnDestroy, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Observable} from 'rxjs';
import * as JSZip from 'jszip';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  PrintingService, getBase64,
  SignatureDialogComponent,
  formattedDateFile,
  EmailMessages, EmailDatasModel, EmailService, EntityEmailMessages,
  promiseAll, LoggerService,
  EntitySignable, ISignature, Attendance,
  SignableService, SignableStatus, EntityDocumentMetadata, Operator, AttendanceDialogComponent
} from 'app/shared';
import {
  PermitState, AuditService,
  AttendanceGroup, ContributorGroup
} from 'app/workbook-core';
import {CommonSignableComponent} from '../commonSignable.component';

@Component({
  selector: 'app-signable',
  templateUrl: './signable.component.html',
  styleUrls: ['./signable.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SignableComponent extends CommonSignableComponent implements OnDestroy {
  @ViewChild('printContainer') printEl: ElementRef;
  @ViewChild(MatSort) documentSort: MatSort;
  entity: EntitySignable;
  //  signatoriesEex: ISignature[] = [];
  //  signatoriesOffice: ISignature[] = [];
  signatories: {[group: string]: ISignature[]} = {};
  signatoriesGroup: string[] = [];
  static COMPANYTYPES: {[group: string]: string} = {
    'ORIGINATOR': 'Donneur d\'ordre',
    'PERFORMER': 'Executant',
    'SUPERVISOR': 'Surveillant',
    'VERIFIER': 'Vérificateur',
    'OPERATOR': 'Opérateur',
    'EE': 'Entreprise Utilisatrice',
    'EEX': 'Entreprise Extérieure'
  }
  displayedDocumentColumns: string[] = ['icon', 'name', 'categoryPicto', 'category', 'date', 'actions'];
  documentDataSource: MatTableDataSource<EntityDocumentMetadata> = new MatTableDataSource([]);
  tempo: number = 1000;

  documentsInline: EntityDocumentMetadata[] = [];
  documentInline: {[data_id: string]: string} = {};
  documentInlineLoading: boolean = false;

  operatorGroups: AttendanceGroup[] = [];
  displayedOperatorsColumns: string[] = ['name', 'phone', 'extra', 'attendances', 'actions']
  operators: {[groupName: string]: MatTableDataSource<Operator>} = {};
  //liste des emargement uniques déja signés)
  //alreadyUniqueAttendance: {[groupName: string]: string[]} = {};
  isValid: boolean = false;
  isAttendanceManager = false;
  attendances: {[operator_id: string]: Attendance[]} = {};
  showAttendances: {[id: string]: boolean} = {};
  //audit_id of checkpoints documents
  checkpointDocuments: {[id: string]: string} = {};

  constructor(
    protected _emailService: EmailService,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _printingService: PrintingService,
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_route, dialog, _logger, _spinnerService, _signableService);
  }

  public scrollTo(el) {
    if (el) {
      setTimeout(() => {el.scrollIntoView({behavior: 'smooth'});});
    }
  }
  protected _apiCall(data): Observable<any> {
    if (data['id']) {
      return this._signableService.getSignable(data['id']);
    } else {
      return Observable.throw('no id');
    }
  }

  protected _initError(data) {
    super._initError(data);

    if (data['workbook']) {
      this.workbook = data['workbook'];
    }
  }
  protected _init(data) {
    if (data) {
      if (data["parent"] && data["parent"]["name"]) {
        this.name = data["parent"]["name"];
      }
      if (data["parent"]) {
        this.workbook = data["parent"];
      }
      if (data["document"]) {
        this.entity = data["document"];
        this.name = this.entity.name + "-" + formattedDateFile(this.entity.editedAt);
        /*
        this.signatoriesEex = this.entity.signatures ? this.entity.signatures.filter((s) => (s.companyType && s.companyType === 'EEX')) : [];
        this.signatoriesOffice = this.entity.signatures ? this.entity.signatures.filter((s) => (s.companyType && s.companyType === 'OFFICE')) : [];
        this.signatories = this.entity.signatures ? this.entity.signatures.filter((s) => (!s.companyType || (s.companyType !== 'EEX' && s.companyType !== 'OFFICE'))) : [];
        */
        if (this.entity['attendances']) {
          this.attendances = {};
          this.entity['attendances'].forEach((e) => {
            if (!this.attendances[e.id]) {
              this.attendances[e.id] = [];
            }
            this.attendances[e.id].push(e);
          });
        }
        this.signatories = {};
        this.signatoriesGroup = [];
        if (this.entity['permitContent']) {
          //this.alreadyUniqueAttendance = {};
          this.operators = {};
          this.isAttendanceManager = false;
          this.operatorGroups = [];
          this.attendances = {}
          if (this.entity['operators'] && Object.keys(this.entity['operators']).length
            && this.entity['permitContent']['operatorGroups'] && this.entity['permitContent']['operatorGroups'].length) {
            Object.keys(this.entity['operators']).forEach((k) => {
              const attendanceGroup: AttendanceGroup = this.entity['permitContent']['operatorGroups'].find((e: AttendanceGroup) => (e.name === k));
              if (attendanceGroup) {
                this.operators[k] = new MatTableDataSource(this.entity['operators'][k]);
                this.operatorGroups.push(attendanceGroup);
                this.entity['operators'][k].forEach((o) => {
                  if (!this.attendances[o.id]) {
                    this.attendances[o.id] = [];
                  }
                  //this.attendances[o.id] = this.entity['attendances'] ? this.entity['attendances'].filter((e) => (e.id === o.id)) : [];
                  //if (attendanceGroup.isUnique && this.attendances[o.id].length) {
                  //  if (!this.alreadyUniqueAttendance[k]) {
                  //    this.alreadyUniqueAttendance[k] = [];
                  //  }
                  //  this.alreadyUniqueAttendance[k].push(o.id);
                  //}
                });
              }
            });
          }
          if (this.entity['contributors'] && Object.keys(this.entity['contributors']).length
            && this.entity['permitContent']['contributorGroups'] && this.entity['permitContent']['contributorGroups'].length) {
            Object.keys(this.entity['contributors']).forEach((k) => {
              const attendanceManagerGroup = this.entity['permitContent']['contributorGroups'].find((e: ContributorGroup) => (e.name === k && e.isAttendanceManager));
              if (attendanceManagerGroup) {
                this.isAttendanceManager = this.isAttendanceManager || this.entity['contributors'][k]
                  .findIndex((c) => (c.contact && c.contact.user_id && c.contact.user_id === this.userId)) !== -1;
              }
            });
          }

        }
        if (data["operators"] && data["operators"].length) {
          const attendanceGroup = 'Émargement';
          this.operatorGroups = [
            new AttendanceGroup({
              name: attendanceGroup,
              mClass: '',
              editionClass: '',
              isUnique: true,
              nominative: true,
              required: true,
              signature: true,
              checkpoints: ['signer']
            })
          ];
          //this.alreadyUniqueAttendance[attendanceGroup] = this.entity.attendances.map((e) => (e.contact_id));;

          this.isAttendanceManager = true;
          this.operators[attendanceGroup] = new MatTableDataSource(data["operators"]);

          this.isValid = ((this.entity.status === 2 && !this.entity.withoutSignatories) || this.entity.status === 3)
            && (new Date(this.workbook.dateEnd).getTime() > ((new Date()).getTime() + ((60 * 60 * 24) * 1000)));

        } else if (this.entity.documentType === "spt") {
          this.isValid = PermitState.isValid(this.entity['status'], this.entity['dateStart'], this.entity['dateEnd']);
        }

        if (this.entity.signatures && this.entity.signatures.length) {
          this.entity.signatures.forEach((s) => {
            const companyType = SignableComponent.COMPANYTYPES[s.companyType] ? SignableComponent.COMPANYTYPES[s.companyType] : s.companyType;
            if (!this.signatories[companyType]) {
              this.signatories[companyType] = [];
              this.signatoriesGroup.push(companyType)
            }
            this.signatories[companyType].push(s);
          });
        }
        this.refreshDocumentDatasource();
      }
    }
  }
  hasAlreadyUniqueAttendance(group: string, op_id: string) {
    return this.operatorGroups
      && this.operatorGroups.findIndex((e) => (e.name === group && e.isUnique)) !== -1
      && this.attendances[op_id]
      && this.attendances[op_id].findIndex((e) => (e.companyType === group)) !== -1
      ;
  }
  refreshDocumentDatasource() {
    if (this.entity) {
      this.checkpointDocuments = {};
      this.documentsInline = [
        ...((this.entity && this.entity.documentType === AuditService.ENTITY_TYPE && this.entity['checkpoints'] && this.entity['locationCheckpoints']) ?
          [...this.entity['checkpoints'], ...this.entity['locationCheckpoints']]
            .filter((e) => (e.document && e.document.data_id))
            .map((e) => {
              this.checkpointDocuments[e.document._id] = this.entity._id;
              return e.document;
            }) : []),
        ...(this.entity ? this.entity.documents.filter((e) => (e.isInline)) : [])
      ];
      this.loadInlineDocuments();
      this.documentDataSource = new MatTableDataSource(this.entity.documents);
      this.documentDataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
      setTimeout(() => {
        this.setDocumentSort();
      });
    }
  }

  getProperty = (obj, path) => (
    (path === 'date')
      ? new Date(obj.uploadedAt ? obj.uploadedAt : obj.updateddAt).getTime()
      : path.split('.').reduce((o, p) => o && (o[p] && (o[p] instanceof Date)) ? o[p].getTime() : ((typeof o[p] === 'string') ? o[p].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : o[p]), obj)
  )
  setDocumentSort() {
    if (this.documentSort) {
      this.documentDataSource.sort = this.documentSort;
    }
  }
  loadInlineDocuments() {
    if (this.entity && this.entity.parent_id && !this.documentInlineLoading && this.documentsInline && this.documentsInline.length) {
      const _that = this;
      this.documentInlineLoading = true;
      promiseAll(
        this.documentsInline.map((d) => (
          !!this.checkpointDocuments[d._id]
            ? _that._signableService.getCheckpointDocument(d._id, this.checkpointDocuments[d._id]).toPromise()
              .then((d_data) => {
                getBase64(d_data).then((b64) => {
                  _that.documentInline[d.data_id] = b64.toString();
                });
              })
            : _that._signableService.getWorkbookDocument(this.entity.parent_id, d._id).toPromise()
              .then((d_data) => {
                getBase64(d_data).then((b64) => {
                  _that.documentInline[d.data_id] = b64.toString();
                });
              })
        ))
      ).finally(() => {
        _that.documentInlineLoading = false;
      });
    }
  }

  protected _getDocument(jszip: JSZip): Observable<JSZip> {
    return new Observable((observer) => {
      this._printingService.generatePdf(this.printEl).subscribe(
        (res) => {
          observer.next(jszip.file(this.name + '.pdf', res));
        },
        (err) => {
          this._logger.error('SignableComponent', 'Export pdf error', JSON.stringify(err));
          this._displayError('Une erreur s\'est produite lors de l\'export ZIP, veuillez réessayer et sinon contacter le support.');
        }, () => {
          observer.complete();
        });

    });

  }
  public exportPdf(): void {
    this._spinnerService.show();
    this._printingService.generatePdf(this.printEl).subscribe(
      (res) => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL);
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('EntityExportableDialogComponent', 'Export pdf error', JSON.stringify(err));
        this._spinnerService.hide();
        this._displayError('Une erreur s\'est produite lors de l\'export PDF, veuillez réessayer et sinon contacter le support.');
      }
    );
  }
  _getCompleteZip() {

    return new Promise((resolve, reject) => {
      const that = this;
      const jsZip = new JSZip();
      JSZip.support.nodebuffer = false;

      this._getDocument(jsZip).subscribe((documentInZip: JSZip) => {
        documentInZip.generateAsync({type: 'blob'}).then((content) => {
          resolve(content);
        },
          (err) => {
            reject(err);
          });
      },
        (err) => {
          reject(err);
        });
    });
  }

  public downloadAllDocuments(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;

    this._getDocument(jsZip).subscribe((documentInZip: JSZip) => {
      this._getAllAnnexes(documentInZip, 'annexes').subscribe((annexesInZip: JSZip) => {
        annexesInZip.generateAsync({type: 'blob'}).then(function (content) {
          that._spinnerService.hide();
          const url = window.URL.createObjectURL(content);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = that.name + '.zip';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        });
      });
    });
  }

  public downloadAllAnnexes(): void {
    this._spinnerService.show();
    const that = this;
    const jsZip = new JSZip();
    JSZip.support.nodebuffer = false;

    this._getAllAnnexes(jsZip).subscribe((annexesInZip: JSZip) => {
      annexesInZip.generateAsync({type: 'blob'}).then(function (content) {
        that._spinnerService.hide();
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = that.name + '.zip';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    });
  }


  protected _getAnnexe(workbook_id: string, jszip: JSZip, doc: EntityDocumentMetadata, folder: string = ''): Promise<JSZip> {
    return new Promise((resolve, reject) => {
      this._signableService.getWorkbookDocument(workbook_id, doc._id).subscribe(
        (res) => {
          resolve(folder ? jszip.folder(folder).file(doc.name + (doc.ext ? '.' + doc.ext : ''), res) : jszip.file(doc.name + (doc.ext ? '.' + doc.ext : ''), res));
        },
        (err) => {
          this._logger.error('SignableComponent', 'Export pdf error', JSON.stringify(err));
          this._displayError('Une erreur s\'est produite lors du téléchargement du document' + (doc && doc.name) ? ' ' + doc.name : '' + ', veuillez réessayer et sinon contacter le support.');
          reject(err);
        });

    });

  }

  protected _getAllAnnexes(jszip: JSZip, folder: string = ''): Observable<JSZip> {
    return new Observable((subscriber) => {
      if (this.entity) {
        let i = 0;
        let x = 0;
        this.entity.documents.forEach((doc) => {
          x++;
          setTimeout(() => {
            this._getAnnexe(this.entity.parent_id, jszip, doc, folder).then(() => {
              i++;
              if (i === this.entity.documents.length) {
                subscriber.next(jszip);
                subscriber.complete();
              }
            }).catch((err) => {
              i--;
              if (i === this.entity.documents.length) {
                subscriber.next(jszip);
                subscriber.complete();
              }
            });
          }, (x * this.tempo));
        });
      }
    });
  }
  protected _getAllAnnexes0(jszip: JSZip, folder: string = ''): Observable<JSZip> {

    const promises = this.entity.documents.map((doc) => (this._getAnnexe(this.entity.parent_id, jszip, doc, folder)));
    return new Observable((subscriber) => {
      promiseAll(promises).then(() => {
        subscriber.next(jszip);
        subscriber.complete();
      });
    });
  }

  public signUp(signature) {
    if (this.entity && this.entity._id && this.checkSignPermission(signature)) {
      const dialogRef = this.dialog.open(SignatureDialogComponent, {
        disableClose: true,
        width: '600px',
        data: [Object.assign({}, signature), this.entity.signAgreement],
      });

      dialogRef.afterClosed().subscribe((dialogSignature) => {
        if (dialogSignature && dialogSignature["data"]) {
          this._spinnerService.show();
          this._signableService.signUp(this.entity._id, dialogSignature).then((res) => {
            this._spinnerService.hide();
            const constName = ('SIGN_' + this.entity.documentType.toUpperCase() + '_CONTACT');
            const _emailmsg = EmailMessages[constName];
            if (_emailmsg) {
              this.sendSignNotification(dialogSignature, _emailmsg);
            }
            const i = this.entity.signatures.findIndex((e) => (e.user_id === this.userId && e.contact_id === signature.contact_id));
            if (res.document && res.document.signatures) {
              //add still to sign
              this.entity.signatures.forEach((s) => {
                //exclude signed
                if (res.document.signatures.findIndex((rs) =>
                  (rs.user_id && rs.user_id === this.userId
                    && rs.contact_id && rs.contact_id === s.contact_id
                    && rs.companyType && rs.companyType === s.companyType
                  )) === -1) {
                  res.document.signatures.push(s);
                }
              });
            }
            this._init(res);
          }).catch((err) => {
            this._spinnerService.hide();
            this._displayError('Une erreur s\'est produite lors de l\'envoi de la signature, veuillez réessayer et sinon contacter le support.');
          });
        }
      });
    }
  }

  public checkSignPermission(signature): boolean {
    return this.userId && signature.user_id && signature.user_id === this.userId
      && !!this.entity && this.entity.status === SignableStatus.SIGNING;
  }

  sendSignNotification(dialogSignature, emailModel: number) {
    if (this.entity.notifications && this.entity.notifications.length) {
      this.sendNotification(emailModel, this.entity.notifications, true, dialogSignature);
    }
  }
  sendNotification(type: number, contacts?: any[], showSnackbar: boolean = true, signature = null): void {
    this._emailService.getEmailDatas(type).then(emailObj => {
      if (contacts && contacts.length > 0) {
        contacts.forEach((contact) => {
          if (contact) {
            if (contact.email) {
              const emailDatas = new EmailDatasModel();
              emailDatas.email = contact.email;
              emailDatas.to_name = contact.name;
              emailDatas.from_name = emailObj.fromName;
              if (emailObj.fromEmail) {
                emailDatas.from_email = emailObj.fromEmail;
              }
              emailDatas.date = new Date();
              emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.subject, this.workbook, this.entity, signature);
              emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.text, this.workbook, this.entity, signature);
              if (contact.user_id) {
                emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.usertext, this.workbook, this.entity, signature);
              }
              emailDatas.signature = emailObj.signature;
              emailDatas.rgpdMail = emailObj.rgpdMail;
              emailDatas.dpoName = emailObj.dpoName;
              emailDatas.dpoMail = emailObj.dpoMail;
              /*
              emailDatas.signature = 'Cordialement,<br>'
                + this._entityService.getUserName() + '<br>'
                + this._emailService.getCompanyName();
              */
              this._emailService.sendEmail(emailDatas);
            }
          }
        });
        if (showSnackbar) {
          this.snackBar.open('Les messages ont été envoyés', '', {
            duration: 2000
          });
        }
      }
    }).catch((error) => {
      this._logger.error('abstractSignableDialog', 'error send email', JSON.stringify(error));
    });
  }
  addAttendance(operator: Operator, operatorGroup: AttendanceGroup, checkpointName: string) {
    const attendance = new Attendance(operator);
    const dialogRef = this.dialog.open(AttendanceDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        signature: attendance,
        withSignature: operatorGroup.signature,
        checkpoint: operatorGroup.checkpoints[checkpointName]
      }
    });

    dialogRef.afterClosed().subscribe((dialogSignature) => {
      if (dialogSignature && this.entity) {
        this._spinnerService.show();
        dialogSignature.checkpoint = checkpointName;
        dialogSignature.companyType = operatorGroup.name;
        dialogSignature.user_id = this.userId;

        this._signableService.attendancing(this.entity._id, dialogSignature).then((res) => {
          this._spinnerService.hide();
          this._init(res);
        }).catch((err) => {
          this._spinnerService.hide();
          this._displayError('Une erreur s\'est produite lors de l\'envoi de l\'emargement, veuillez réessayer et sinon contacter le support.');
        });
      }
    });
  }
}
