import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {LoggerService} from 'app/shared/logger';
import {ISignature, Contact} from 'app/shared/models';
import {CommonComponent} from '../common.component';
import {AgreementsDialogComponent} from '../agreements-dialog';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-signature-dialog',
  templateUrl: 'signature-dialog.component.html',
  styleUrls: ['signature-dialog.component.scss']
})

export class SignatureDialogComponent extends CommonComponent {

  isNewSignature: boolean;
  public signature: ISignature;
  // public contactList: Contact;
  public agreement: string = '';
  public checked: boolean = false;
  public isSigned: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AgreementsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    protected _logger: LoggerService
  ) {
    super(_logger);
    this.signature = data[0];
    this.agreement = data[1];
  }

  cancel(): void {
    this.signature.data = '';
    this.signature.signedAt = null;
    this.dialogRef.close();
  }

  clear() {
    this.signature.data = '';
  }

  signatureChanged(data) {
    this.signature.data = data;
    this.isSigned = true;
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.signature.signedAt = new Date();
      this.dialogRef.close(this.signature);
    }
  }

  changeCheckbox() {
    this.checked = true;
  }

  openAgreement() {
    this.dialog.open(AgreementsDialogComponent, {
      minWidth: '800px',
      data: this.agreement
    });
  }
}
