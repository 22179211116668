import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import {FormsModule} from '@angular/forms';
// import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'app/shared';
import { WorkbookCoreModule } from 'app/workbook-core';
import { WorkbookComponent } from './workbook.component';
import { WorkbookCreateDialogComponent } from './create-dialog';
import { WorkbookDuplicateDialogComponent } from './duplicate-dialog';
import { WorkbookExportDialogComponent } from './export-dialog';
import { WorkbookContactDialogComponent } from './contact-dialog';
import { WorkbookOperatorDialogComponent } from './operator-dialog';
import { EmailAttachmentComponent } from './emailAttachment';
import { NotifyDialogComponent } from './notify-dialog';
import { WorkbookToolbarComponent } from './toolbar';
import { WorkbookSelectComponent } from './workbook'
import { WorkbookSlideMenuComponent } from './slide-menu';
import { WorkbookOfficeComponent, WorkbookOfficeDialogComponent, WorkbookOfficeInfoComponent } from './office';
import { WorkbookExternalCompanyComponent, WorkbookExternalCompanyDialogComponent, WorkbookExternalCompanyInfoComponent } from './externalCompany';
import { WorkbookActivityComponent, WorkbookMeasureScopeDialogComponent, WorkbookActivityMeasureScopeComponent, WorkbookMeasureComponent, WorkbookActivityDialogComponent, WorkbookActivityInfoComponent, WorkbookRisksDialogComponent, WorkbookRisksComponent, SplitActivityDialogComponent } from './activity';
import { SignatorySelectDialogComponent } from './signature';
import { StepSelectComponent, WorkbookStepDialogComponent, WorkbookStepInfoComponent, StepAccidentDialogComponent, WorkbookStepImplementationDialogComponent } from './step';
import { WorkbookIcpInfoComponent, WorkbookIcpDialogComponent, LdcmDialogComponent, ReconveneDialogComponent, IcpContactsDialogComponent } from './icp';
import { FirePermitInfoDialogComponent, FirePermitInfoComponent, FirePermitDialogComponent } from './firePermit';
import { SignablePermitInfoDialogComponent, SignablePermitDialogComponent, SignablePermitInfoComponent, PermitContentChoiceDialogComponent } from './signablePermit';
import { SecurityProtocolDialogComponent, SecurityProtocolInfoComponent, SecurityProtocolInfoDialogComponent } from './securityProtocol';
import { AuditDialogComponent, AuditInfoComponent, AuditInfoDialogComponent } from './audit';
import { DocumentEexMetadataDialogComponent, DocumentAddSelectDialogComponent, WorkbookDocumentsRequestDialogComponent, WorkbookDocumentsUploadDialogComponent } from './document';
import { WorkbookLocalisationComponent, LocationDialogComponent, ZoneDialogComponent } from './localisation';

@NgModule({
  imports: [
    CommonModule,
    //   FormsModule,
    SharedModule,
    WorkbookCoreModule,
    RouterModule
    //    FontAwesomeModule
  ],
  declarations: [
    WorkbookComponent,
    WorkbookToolbarComponent,
    WorkbookSlideMenuComponent,
    NotifyDialogComponent,
    WorkbookCreateDialogComponent,
    WorkbookDuplicateDialogComponent,
    WorkbookExportDialogComponent,
    WorkbookContactDialogComponent, WorkbookOperatorDialogComponent,
    EmailAttachmentComponent,
    WorkbookExternalCompanyComponent, WorkbookExternalCompanyDialogComponent, WorkbookExternalCompanyInfoComponent,
    WorkbookOfficeComponent, WorkbookOfficeDialogComponent, WorkbookOfficeInfoComponent,
    SignatorySelectDialogComponent,
    WorkbookIcpInfoComponent, WorkbookIcpDialogComponent,
    FirePermitInfoDialogComponent, FirePermitDialogComponent, FirePermitInfoComponent,
    SignablePermitInfoDialogComponent, SignablePermitDialogComponent, SignablePermitInfoComponent, PermitContentChoiceDialogComponent,
    SecurityProtocolDialogComponent, SecurityProtocolInfoComponent, SecurityProtocolInfoDialogComponent,
    AuditDialogComponent, AuditInfoComponent, AuditInfoDialogComponent,
    LdcmDialogComponent,
    ReconveneDialogComponent, IcpContactsDialogComponent,
    StepSelectComponent, WorkbookStepDialogComponent, WorkbookStepInfoComponent, StepAccidentDialogComponent, WorkbookStepImplementationDialogComponent,
    WorkbookActivityComponent, WorkbookMeasureScopeDialogComponent, WorkbookActivityMeasureScopeComponent, WorkbookMeasureComponent, WorkbookActivityDialogComponent, WorkbookActivityInfoComponent, WorkbookRisksDialogComponent, WorkbookRisksComponent, SplitActivityDialogComponent,
    DocumentEexMetadataDialogComponent, DocumentAddSelectDialogComponent, WorkbookDocumentsRequestDialogComponent, WorkbookDocumentsUploadDialogComponent,
    WorkbookSelectComponent, WorkbookLocalisationComponent, LocationDialogComponent, ZoneDialogComponent
  ],
  entryComponents: [
    NotifyDialogComponent,
    WorkbookCreateDialogComponent,
    WorkbookDuplicateDialogComponent,
    WorkbookExportDialogComponent,
    WorkbookContactDialogComponent, WorkbookOperatorDialogComponent,
    EmailAttachmentComponent,
    WorkbookExternalCompanyDialogComponent,
    WorkbookOfficeDialogComponent,
    WorkbookActivityDialogComponent, WorkbookRisksDialogComponent, WorkbookMeasureScopeDialogComponent, SplitActivityDialogComponent,
    SignatorySelectDialogComponent,
    StepSelectComponent, WorkbookStepDialogComponent, StepAccidentDialogComponent, WorkbookStepImplementationDialogComponent,
    WorkbookIcpDialogComponent,
    FirePermitInfoDialogComponent, FirePermitDialogComponent,
    SignablePermitInfoDialogComponent, SignablePermitDialogComponent, PermitContentChoiceDialogComponent,
    SecurityProtocolDialogComponent, SecurityProtocolInfoDialogComponent,
    AuditDialogComponent, AuditInfoDialogComponent,
    LdcmDialogComponent,
    ReconveneDialogComponent, IcpContactsDialogComponent,
    DocumentEexMetadataDialogComponent, DocumentAddSelectDialogComponent, WorkbookDocumentsRequestDialogComponent, WorkbookDocumentsUploadDialogComponent,
    WorkbookSelectComponent, LocationDialogComponent, ZoneDialogComponent
  ],
  exports: [
    WorkbookCoreModule,
    WorkbookComponent,
    WorkbookToolbarComponent,
    WorkbookSlideMenuComponent,
    NotifyDialogComponent,
    WorkbookCreateDialogComponent,
    WorkbookDuplicateDialogComponent,
    WorkbookExportDialogComponent,
    WorkbookContactDialogComponent, WorkbookOperatorDialogComponent,
    EmailAttachmentComponent,
    WorkbookIcpInfoComponent, WorkbookIcpDialogComponent,
    FirePermitInfoDialogComponent, FirePermitDialogComponent, FirePermitInfoComponent,
    SignablePermitInfoDialogComponent, SignablePermitDialogComponent, PermitContentChoiceDialogComponent, SignablePermitInfoComponent,
    SecurityProtocolDialogComponent, SecurityProtocolInfoComponent, SecurityProtocolInfoDialogComponent,
    AuditDialogComponent, AuditInfoComponent, AuditInfoDialogComponent,
    LdcmDialogComponent,
    ReconveneDialogComponent, IcpContactsDialogComponent,
    WorkbookExternalCompanyComponent, WorkbookExternalCompanyDialogComponent,
    WorkbookOfficeComponent, WorkbookOfficeDialogComponent, WorkbookOfficeInfoComponent,
    SignatorySelectDialogComponent,
    StepSelectComponent, WorkbookStepDialogComponent, StepAccidentDialogComponent, WorkbookStepImplementationDialogComponent, WorkbookStepInfoComponent,
    WorkbookActivityComponent, WorkbookMeasureScopeDialogComponent, WorkbookActivityMeasureScopeComponent, WorkbookMeasureComponent, WorkbookActivityDialogComponent, WorkbookActivityInfoComponent, WorkbookRisksDialogComponent, WorkbookRisksComponent, SplitActivityDialogComponent,
    DocumentEexMetadataDialogComponent, DocumentAddSelectDialogComponent, WorkbookDocumentsRequestDialogComponent, WorkbookDocumentsUploadDialogComponent,
    WorkbookSelectComponent, WorkbookLocalisationComponent, LocationDialogComponent, ZoneDialogComponent
  ],
  providers: [
  ]
})

export class WorkbookSharedModule {
}
