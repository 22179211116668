import {EntityCompany} from './entityCompany.model';
import {EntityDocumentMetadata, IDocumentable} from './entityDocumentMetadata.model';

export class EntityCompanyDocumentable extends EntityCompany implements IDocumentable {
  public documents: EntityDocumentMetadata[];
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.documents = (data && data.documents) ? data.documents.map((e) => Object.assign({}, e)) : [];
  }
}
