import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService, EntityListPopupComponent } from 'app/shared';
import { PermitContentService, EntityPermitContent } from 'app/workbook-core';
import { PermitContentDialogComponent } from './permitContentDialog.component';

@Component({
  selector: 'app-repository-permitContent-list',
  templateUrl: './permitContentList.component.html',
  styleUrls: ['./permitContentList.component.scss']
})

export class PermitContentListComponent extends EntityListPopupComponent {

  protected _dialogComponent = PermitContentDialogComponent;

  entityList: EntityPermitContent[];
  dataSource: MatTableDataSource<EntityPermitContent>;
  displayedColumns: string[] = ['name', 'description', 'delete'];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce permis ?';
  addConfirmMessage = 'Nouveau permis créé';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PermitContentService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }

  selectElement(element: EntityPermitContent = null) {
    this._router.navigateByUrl(this._entityService.getUrlEntity(element));
  }

}
