export default [
  [
    'SGH',
    [
      {
        thumb: 'assets/pictos/ghs/explos.svg',
        alt: 'Explosif',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/explos.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/flamme.svg',
        alt: 'Inflammable',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/flamme.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/rondflam.svg',
        alt: 'Comburant',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/rondflam.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/bottle.svg',
        alt: 'Gaz sous pression',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/bottle.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/acid.svg',
        alt: 'Corrosif',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/acid.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/skull.svg',
        alt: 'Toxique',
        shape: {
          // a shape definition, the position of the sthape will be set by the editor
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/skull.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/exclam.svg',
        alt: 'Toxique, irritant, sensibilisant, narcotique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/exclam.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/silhouette.svg',
        alt: 'Sensibilisant, cancérogène, mutagène, reprotoxique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/silhouette.svg'
        }
      },
      {
        thumb: 'assets/pictos/ghs/pollu.svg',
        alt: 'Danger pour l\'environnement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/ghs/pollu.svg'
        }
      }
    ],
    {}
  ],
  [
    'EPI',
    [
      {
        thumb: 'assets/pictos/epi/casque.svg',
        alt: 'Casque de chantier',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/casque.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/chaussures.svg',
        alt: 'Chaussures de sécurité',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/chaussures.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/gants.svg',
        alt: 'Gants de chantier',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/gants.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/antibruit.svg',
        alt: 'Casque anti-bruit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/antibruit.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/combinaison.svg',
        alt: 'Protection vestimentaire',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/combinaison.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/lunettes.svg',
        alt: 'Lunettes',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/lunettes.svg'
        }
      },
      {
        thumb: 'assets/pictos/epi/visiere.svg',
        alt: 'Visière',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/epi/visiere.svg'
        }
      }
    ],
    {}
  ],
  [
    'Secours',
    [
           
      {
        thumb: 'assets/pictos/secours/issue-de-secours.svg',
        alt: 'Issue de secours',
        shape: {
           width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/secours/issue-de-secours.svg'
        }
      },
      
      {
        thumb: 'assets/pictos/secours/croix.svg',
        alt: 'croix',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/croix.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/telephone-urgence.svg',
        alt: 'telephone-urgence',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/telephone-urgence.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/point-rassemblement.svg',
        alt: 'point-rassemblement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/point-rassemblement.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/refuge-temporaire-evacuation.svg',
        alt: 'refuge-temporaire-evacuation',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/refuge-temporaire-evacuation.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/civiere.svg',
        alt: 'civiere',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/civiere.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/defibrillateur.svg',
        alt: 'defibrillateur',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/defibrillateur.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/douche-oeil.svg',
        alt: 'douche-oeil',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/douche-oeil.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/douche-securite.svg',
        alt: 'douche-securite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/douche-securite.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/sortie-vers-droite.svg',
        alt: 'sortie-vers-droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/sortie-vers-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/sortie-vers-gauche.svg',
        alt: 'sortie-vers-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/sortie-vers-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-droite.svg',
        alt: 'droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-bas-droit.svg',
        alt: 'bas-droit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-bas-droit.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-bas.svg',
        alt: 'bas',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-bas.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-bas-gauche.svg',
        alt: 'bas-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-bas-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-gauche.svg',
        alt: 'gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/secours/fleche-haut-gauche.svg',
        alt: 'haut-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/secours/fleche-haut-gauche.svg'
        }
      }
    ],
    {}
  ],
  [
    'Incendie',
    [
      {
        thumb: 'assets/pictos/incendie/point-chaud.svg',
        alt: 'Point chaud',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/incendie/point-chaud.svg'
        }
      },
       {
        thumb: 'assets/pictos/incendie/vanne-arret-gaz.svg',
        alt: 'Vanne arret gaz',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/incendie/vanne-arret-gaz.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/alarme.svg',
        alt: 'Alarme',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/alarme.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/echelle.svg',
        alt: 'Échelle',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/echelle.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/equipement.svg',
        alt: 'Équipement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/equipement.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/extincteur.svg',
        alt: 'Extincteur',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/extincteur.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/lance.svg',
        alt: 'Lance',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/lance.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/telephone.svg',
        alt: 'Téléphone',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/telephone.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-haut.svg',
        alt: 'haut',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-haut.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-haut-droit.svg',
        alt: 'haut-droit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-haut-droit.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-droite.svg',
        alt: 'droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-bas-droit.svg',
        alt: 'bas-droit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-bas-droit.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-bas.svg',
        alt: 'bas',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-bas.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-bas-gauche.svg',
        alt: 'bas-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-bas-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-gauche.svg',
        alt: 'gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/incendie/fleche-haut-gauche.svg',
        alt: 'haut-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/incendie/fleche-haut-gauche.svg'
        }
      }
    ],
    {}
  ],
  [
    'Danger',
    [
            {
        thumb: 'assets/pictos/danger/danger-retressicement.svg',
        alt: 'Danger retressicement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-retressicement.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-glissant.svg',
        alt: 'Danger glissant',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-glissant.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-jet-pierre.svg',
        alt: 'Danger jet pierre',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-jet-pierre.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-feu-tricolore.svg',
        alt: 'Danger feu tricolore',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-feu-tricolore.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-cassis.svg',
        alt: 'Danger cassis',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-cassis.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-chantier.svg',
        alt: 'Danger chantier',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-chantier.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/danger-electrique.svg',
        alt: 'Danger electrique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-electrique.svg'
        }
      },
      

      {
        thumb: 'assets/pictos/danger/tableau-elect.svg',
        alt: 'Danger tableau électrique',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/danger/tableau-elect.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/attention-travaux.svg',
        alt: 'Attention travaux',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/danger/attention-travaux.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/acces-chantier.svg',
        alt: 'Acces chantier',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/danger/acces-chantier.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/zone-stockage.svg',
        alt: 'Zone stockage',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/danger/zone-stockage.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/route-barre.svg',
        alt: 'Route barree',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/danger/route-barre.svg'
          }
      },
      {
        thumb: 'assets/pictos/danger/danger-bouchon.svg',
        alt: 'Danger bouchon',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/danger-bouchon.svg'
        }
      },
 
      {
        thumb: 'assets/pictos/danger/general.svg',
        alt: 'Danger',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/general.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/suspendue.svg',
        alt: 'Charge suspendue',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/suspendue.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/chariot.svg',
        alt: 'Chariot',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/chariot.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/chute.svg',
        alt: 'Chute',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/chute.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/trebuchement.svg',
        alt: 'Risque de trébuchement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/trebuchement.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/electricite.svg',
        alt: 'Electricité',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/electricite.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/biologique.svg',
        alt: 'Risque biologique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/biologique.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/comburant.svg',
        alt: 'Produit comburant',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/comburant.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/corrosif.svg',
        alt: 'Produit corrosif',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/corrosif.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/explosif.svg',
        alt: 'Produit explosif',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/explosif.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/explosive.svg',
        alt: 'Atmosphère explosive',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/explosive.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/gel.svg',
        alt: 'Froid / gèle',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/gel.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/inflammable.svg',
        alt: 'Produit inflammable',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/inflammable.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/laser.svg',
        alt: 'Laser',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/laser.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/magnetique.svg',
        alt: 'Magnetique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/magnetique.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/nocif.svg',
        alt: 'Produit nocif',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/nocif.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/radiations.svg',
        alt: 'Radiations non ionisantes',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/radiations.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/radioactivite.svg',
        alt: 'Radioactivité',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/radioactivite.svg'
        }
      },
      {
        thumb: 'assets/pictos/danger/toxique.svg',
        alt: 'Produit toxique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/danger/suspendue.svg'
        }
      },
        {
        thumb: 'assets/pictos/bande-signalisation.svg',
        alt: 'Bande de signalisation',
        shape: {
          width: 32,
          height: 128,
          backgroundImage: 'assets/pictos/bande-signalisation.svg'
        }
      }
    ],
    {}
  ],
  [
    'Signalisation',
    [
    
                 {
        thumb: 'assets/pictos/indication/security-simple.svg',
        alt: 'Poste securite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/security-simple.svg'
        }
      },
       {
        thumb: 'assets/pictos/indication/poste-securite.svg',
        alt: 'Poste securite',
        shape: {
          width: 170,
          height: 56,
          aspectRatio: 170/56,
          backgroundImage: 'assets/pictos/indication/poste-securite.svg'
       }
      },
      {
        thumb: 'assets/pictos/indication/arret.svg',
        alt: 'Zone d\'arrêt',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/arret.svg'
        }
      },
            {
        thumb: 'assets/pictos/indication/security.svg',
        alt: 'Sécurité',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/security.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/toilette.svg',
        alt: 'Toilette',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/toilette.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/arret-bus.svg',
        alt: 'Arrêt de bus',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/arret-bus.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/dos-ane.svg',
        alt: 'Dos d\'âne',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/dos-ane.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/impasse.svg',
        alt: 'Impasse',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/impasse.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/infos.svg',
        alt: 'Informations',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/infos.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/parking.svg',
        alt: 'Parking',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/parking.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/secours.svg',
        alt: 'Poste de secours',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/secours.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/sens-unique.svg',
        alt: 'Sens unique',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/sens-unique.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/telephone.svg',
        alt: 'Téléphone',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/telephone.svg'
        }
      },
      {
        thumb: 'assets/pictos/indication/passage-pieton.svg',
        alt: 'Passage piéton',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/indication/passage-pieton.svg'
        }
      }
    ],
    {}
  ],
  [
    'Interdiction',
        [
     {
        thumb: 'assets/pictos/interdit/travaux-chantier-interdiction.svg',
        alt: 'Interdiction chantier',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/interdit/travaux-chantier-interdiction.svg'
        }
      },
      {
        thumb: 'assets/pictos/interdit/travaux-point-chaud.svg',
        alt: 'Travaux point chaud',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/interdit/travaux-point-chaud.svg'
        }
      },
      {
        thumb: 'assets/pictos/interdit/coupure-eau.svg',
        alt: 'Coupure eau',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/interdit/coupure-eau.svg'
        }
      },
      {
        thumb: 'assets/pictos/interdit/interdiction-camion.svg',
        alt: 'Interdiction camion',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/interdit/interdiction-camion.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/15km.svg',
        alt: 'Limité à 15km/h',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/15km.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/30km.svg',
        alt: 'Limité à 30km/h',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/30km.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/50km.svg',
        alt: 'Limité à 50km/h',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/50km.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/arreter.svg',
        alt: 'Arrêt interdit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/arreter.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/demi-tour.svg',
        alt: 'Demi-tour interdit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/demi-tour.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/interdit.svg',
        alt: 'Interdit à tout véhicule',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/interdit.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/sens-interdit.svg',
        alt: 'Sens interdit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/sens-interdit.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/stationner.svg',
        alt: 'Stationnement interdit',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/satationner.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/stop.svg',
        alt: 'Stop',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/stop.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/tourner-droite.svg',
        alt: 'Interdiction de tourner à droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/tourner-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/tourner-gauche.svg',
        alt: 'Interdiction de tourner à gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/tourner-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/tout-vehicule.svg',
        alt: 'Interdit à tout véhicule motorisé',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/tout-vehicule.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/vehicule.svg',
        alt: 'Interdit à tout véhicule motorisé (permis)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/vehicule.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-interdit/velo.svg',
        alt: 'Interdit aux vélos',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-interdit/velo.svg'
        }
      }
    ],
    {}
  ],
  [
    'Obligation',
    [
      {
        thumb: 'assets/pictos/route-obligation/30.svg',
        alt: '30km/h minimum',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/30.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/30-fin.svg',
        alt: 'Fin 30km/h minimum',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/30-fin.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/allumez-feux.svg',
        alt: 'Allumez vos feux',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/allumez-feux.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/allumez-feux-fin.svg',
        alt: 'Fin d\'allumage des feux',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/allumez-feux.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/bas-droit.svg',
        alt: 'Passage à droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/bas-droit.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/bas-gauche.svg',
        alt: 'Passage à gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/bas-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/bus.svg',
        alt: 'Voie de bus',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/bus.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/bus-fin.svg',
        alt: 'Fin de voie de bus',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/bus-fin.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/chaines.svg',
        alt: 'Equipement neige obligatoire',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/chaines.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/chaines-fin.svg',
        alt: 'Fin d\'équipement neige obligatoire',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/chaines-fin.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/droite.svg',
        alt: 'Sens giratoire (droite)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/gauche.svg',
        alt: 'Sens giratoire (gauche)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/droite-ou-gauche.svg',
        alt: 'Passage à droite ou gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/droite-ou-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/enfant.svg',
        alt: 'Chemin piéton',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/enfant.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/enfant-fin.svg',
        alt: 'Fin de chemin piéton',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/enfant-fin.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/general.svg',
        alt: 'Prudence',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/general.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/haut.svg',
        alt: 'Passage obligatoire (tout droit)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/haut.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/haut-ou-droite.svg',
        alt: 'Passage obligatoire (tout droit ou à droite)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/haut-ou-droit.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/haut-ou-gauche.svg',
        alt: 'Passage obligatoire (tout droit ou à gauche)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/haut-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/notice.svg',
        alt: 'Notice obligatoire',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/notice.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/pieton.svg',
        alt: 'Chemin piéton',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/pieton.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/tourner-droite.svg',
        alt: 'Passage obligatoire (droite)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/tourner-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/tourner-gauche.svg',
        alt: 'Passage obligatoire (gauche)',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/tourner-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/tram.svg',
        alt: 'Voie de Tram',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/tram.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/velo.svg',
        alt: 'Piste cyclable',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/velo.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-obligation/velo-fin.svg',
        alt: 'Fin de piste cyclable',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-obligation/velo-fin.svg'
        }
      }
    ],
    {}
  ],
  [
    'Avertissement',
    [
       {
        thumb: 'assets/pictos/interdit/travaux-chantier-attention.svg',
        alt: 'Avertissement chantier',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/interdit/travaux-chantier-attention.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/barriere.svg',
        alt: 'Barrière',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/barriere.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/bosse.svg',
        alt: 'Dos d\'âne',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/bosse.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/bosses.svg',
        alt: 'Ralentisseurs',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/bosses.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/croisement.svg',
        alt: 'Croisement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/croisement.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/danger.svg',
        alt: 'Danger',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/danger.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/descente-10.svg',
        alt: 'Pente à 10%',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/descente-10.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/double-sens.svg',
        alt: 'Circulation à double sens',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/double-sens.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/enfants.svg',
        alt: 'Traverser d\'enfants',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/enfants.svg'
        }
      },
        {
        thumb: 'assets/pictos/route-danger/virages-gauche.svg',
        alt: 'virages-gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/virages-gauche.svg'
        }
      }, 
      {
        thumb: 'assets/pictos/route-danger/feu.svg',
        alt: 'Feu tricolore',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/feu.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/glisse.svg',
        alt: 'Chaussée glissante',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/glisse.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/passage-pieton.svg',
        alt: 'Passage piéton',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/passage-pieton.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/pierres.svg',
        alt: 'Chutte de pierres',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/pierres.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/pont-levi.svg',
        alt: 'Pont levi',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/pont-levi.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/quai.svg',
        alt: 'Quai',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/quai.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/retreci.svg',
        alt: 'Retrecissement',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/retreci.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/retreci-droite.svg',
        alt: 'Retrecissement à droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/retreci-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/retreci-gauche.svg',
        alt: 'Retrecissement à gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/retreci-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/rond-point.svg',
        alt: 'Rond point',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/rond-point.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/route-prioritaire.svg',
        alt: 'Route prioritaire',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/route-prioritaire.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/train.svg',
        alt: 'Train',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/train.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/tram.svg',
        alt: 'Tram',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/Tram.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/velo.svg',
        alt: 'Vélo',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/velo.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/vent.svg',
        alt: 'Vent',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/vent.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/virage-droite.svg',
        alt: 'Virage à droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/virage-droite.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/virage-gauche.svg',
        alt: 'Virage gauche',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/virage-gauche.svg'
        }
      },
      {
        thumb: 'assets/pictos/route-danger/virages-droite.svg',
        alt: 'virages-droite',
        shape: {
          width: 64,
          height: 64,
          aspectRatio: 1,
          backgroundImage: 'assets/pictos/route-danger/virages-droite.svg'
        }
      }
     
 
    ],
    {}
  ]
          /*
           ,
           [
           'Divers',
           [
           {
           thumb: 'assets/pictos/bande-
lisation.svg',
           alt: 'Bande de 
lisation',
           shape: {
           width: 32,
           height: 128,
           backgroundImage: 'assets/pictos/bande-
lisation.svg'
           }
           }
           ],
           {}
           ],
           [
           // required group label
           'Dangers (dataURL PNG)',
           [
           {
           thumb: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAMAAAA3HE0QAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABhQTFRF6OfnsqlshXxIVU43KiUl//BA+eZF////x0xmowAAAAh0Uk5T/////////wDeg71ZAAAA7klEQVR42nySgQ7CMAhEDyj4/3/sQVdXGjdiYlxvD14Rn16qxwMc52b6HhhD5SWgNph4DogN40ceA2wgciDQAepOhD4ECIjw6Ai0CdUjQhviDggPoqqpok2YgPCGwA5wHjLkuyp2QARFwn1XRVPku8R7NpEjUIoJH9lpU0VTzPNqdc+JbcJ8cSbcf6pYALkA3FYGZCHQAeyU05TqROCekA9NtfozsFRxKc6ATpOcl6r1/0QB5hJcbV60r5XMwNoiy0y0MPdK8JswS0RF5Pox58RUrArv36UKAuj+v/K2QG9uN29onJUBZYvX+nwFGADHTxHxWXkV8wAAAABJRU5ErkJggg==', // can also be an Emoji
           alt: 'Firefox logo',
           shape: {
           // a shape definition, the position of the sthape will be set by the editor
           width: 100,
           height: 88,
           aspectRatio: 1.136,
           backgroundImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABYCAMAAAA0hKKwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABhQTFRFWVM7y7tN6+rqlotL//E+JyQl+eZF////mZWtQAAAAAh0Uk5T/////////wDeg71ZAAAD9ElEQVR42ryagXLcIAxElxGo///HBduAAMkWV7vqJLlkHBahpwUuxZ/NSCmBNn8Hm49nBUJKX4pQCjFG5oQPRRJxAPKn9J0IUh4+B0Wkz0SO0UsETvSRSEKkQyO/2EplSyRcieRU4k4q+CWRdLz8QoRSRrcFxQ2MsV/1M5UdjOHHN4Ykgthfe/xQ9TNyKvSyCMBX1Ym2aw+3aTGuYtTy+zsSu/gGjtupeEXq/JE9+MqpdCReFOn4hiJSezI6McYevlQ0uCp6McZeInxE65iMcXhJJBdiSESk4nNjh0hofYhLgzvGLjfGFr5NZA9jbLivSITjDsbwVJ1k1edUHBhjA18eVDZqD7/78igS+sYS/lHkGGTAd14wRyr4Ad8JY350Y/jddw4/xnjE10zE78b4Ad+l9k8YY8d9F5WayoMbYxffIIsTfOcjuNxXmpZMyokxXPgOwyoY4x5jeKreEykieip3GGML33iMSrtujLur24wv5ZRoZK1jfJMKbvDlCd9Sj3Pmcv1aKnbt8YgvZAFw/jDPO8y1j7Ybw314OJcmXJdGQpxqf5MK7Av7YlpFpG5VEOJ4whgmvksfsrhpIQ8c3RjjAd/RtIYLSpPv3mNgDHcfxmZVaTq89EOAYWFw4sshv5xE3B0JA1+axwnLfU6W635jgdd980/GkpQ0gw9j3OK7bh2jCNpyNt41jOHEN2oioRW/YcwaxrjDVzmaDJWXhPWOhEPEOPtiHL3d5meMtY6Ehu/ShyEOIgGLSEuVldpjxffaRqRpgQayWBG5wxgrvrwcGYvHDyKkiPRFXlLBUnUF3xktwfNaNgVjPPehOF8dHl/0keyphAVjTInUCg/uW1eQy6hlrrFvkUoqC8Yw+lA7WpeBy0fIwwVeRdjCGE/uK8gp7Zx39zJw1rk2L+0UlicBU0RxX7kQgZuP5M9k72o0YQwd37iKoBy1pTzWRw03xsPhQYjE4bitF0/HGKr7aje3WVlbLQNjyHf7I4wbz/G748IY8xGpQBEx8a3zhlpi404cJMYQ+JqJXBWlqEzYuhNHgTFWfNXr4VGCvmB0c5PEcj7CnfuaKme3B/22um4s6HvubSLFNIDsWuVvWiidztF4sq16ewsc/Y9VdtWPBeqB66uRc/fxWns84ju++6CeU/mhI+HA97q/L2E+O2OMq+pPiXA8o349XpvP1vJeZ2N48N0OUfsqUsaG6r4/i9Qd48QY5+Hh3URmjCGqDrng/xj1VHDUHgJf0IshMUapunJifysOjLNVqI32VhSMj/v4h4mcqfwfkeKsxV/fiyS/oeIlKAQP7LWPx3+m/7Q3x8o3uemRe5E+jZwHzv8l8mFkgb8CDABSvcI6NAy6YgAAAABJRU5ErkJggg==',
           }
           }
           ],
           {}
           ]

           */
];
