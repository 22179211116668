import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {map, first, mergeMap, last} from 'rxjs/operators';
import PouchDB from 'pouchdb';
import {IDBParams} from 'app/shared/auth';
import {DbReadWriteService} from './dbReadWrite.service';


@Injectable()
export class DbLocalSyncService extends DbReadWriteService {
  static LOCALDB_PREFIX = 'localDB';
  static PARENTS_LENGTH = 7000;
  selector: any = null;
  localDB: ReplaySubject<PouchDB> = new ReplaySubject(1);
  changes: BehaviorSubject<any> = new BehaviorSubject([]);
  loadedChildrenRemote: BehaviorSubject<any> = new BehaviorSubject(null);

  static SYNSTATE_SYNC = 0;
  static SYNSTATE_SYNCING = 1;
  static SYNSTATE_INIT = 2;
  static SYNSTATE_ERROR = 3;
  syncStateChange: BehaviorSubject<number> = new BehaviorSubject(2);

  remoteDbAttr: string;
  localDbAttr: string;
  __remotePouchDb: PouchDB;
  __localPouchDb: PouchDB;

  replicator;
  filter: string;
  query_params: {[key: string]: string} = {};
  skipCheckBrokenSync: boolean = false;
  writeOnly: boolean = false;
  filterTooLong: boolean = false;
  last_seq: string = '';
  loadingChildrenRemote: {[parent_id: string]: number} = {};
  loadingChildrenDelay = 60000;

  syncCompletedChildren: string[] = [];

  constructor(
    protected zone: NgZone
  ) {
    super();
    this.dbPrefix = (<typeof DbLocalSyncService> this.constructor).LOCALDB_PREFIX;
  }
  //pas de synchro
  isOutOfSync(parent_id: string) {
    return (!this.filterTooLong && !!this.filter &&
      (
        this.filter === 'app/only_parents'
        || (
          this.filter === 'app/filtered'
          && (!this.query_params
            || !this.query_params['parents']
            || this.query_params['parents'].indexOf(parent_id) === -1)
        )
      )
    );
  }
  //synchro (potentiellement en cours)
  isInSync(parent_id: string) {
    return this.filterTooLong ||
      (!!this.filter
        && this.filter === 'app/filtered'
        && this.query_params
        && this.query_params['parents']
        && this.query_params['parents'].indexOf(parent_id) !== -1
      );
  }//en cours de synchro
  isSynced(parent_id: string) {
    return this.filterTooLong ||
      (!!this.filter &&
        (
          this.filter === 'app/filtered'
          && this.query_params
          && this.query_params['parents']
          && this.query_params['parents'].indexOf(parent_id) !== -1
          && (!this.syncCompletedChildren || this.syncCompletedChildren.indexOf(parent_id) !== -1)
        )
      );
  }
  //en cours de synchro
  isSyncing(parent_id: string) {
    return !this.filterTooLong &&
      (!!this.filter &&
        (
          this.filter === 'app/filtered'
          && (!this.query_params
            || !this.query_params['parents']
            || this.query_params['parents'].indexOf(parent_id) !== -1)
          && (!this.syncCompletedChildren || this.syncCompletedChildren.indexOf(parent_id) === -1)
        )
      );
  }
  connect(db) {
    super.connect(db);
    this._initLocalDB(db);
  }
  syncDB(db) {
    this._syncDB(db);
  }
  getFilter() {
    return this.filter;
  }


  /**
   * Initialize the Db connection with host
   * @protected
   */
  protected _initLocalDB(db) {
    this.localDB.next(this.getDB({name: db.name}));
  }
  protected detectBrokenSync(dbName) {
    return new Promise((resolve, reject) => {
      if (this.skipCheckBrokenSync) {
        resolve(true);
      } else {
        if (window.indexedDB) {
          //console.log('detectBrokenSync', dbName);
          // ouvre la connexion à la base de données
          var DBOpenRequest: IDBOpenDBRequest = window.indexedDB.open(dbName);

          var existed = true;
          DBOpenRequest.onupgradeneeded = function () {
            console.log('IndexedDB onupgradeneeded', dbName);
            existed = false;
          }
          DBOpenRequest.onblocked = function () {
            console.log('IndexedDB blocked', dbName);
            existed = false;
          }
          DBOpenRequest.onerror = function () {
            console.log('IndexedDB not found', dbName);
            reject(DBOpenRequest.error);
          }
          // Gère l'ouverture de la connexion
          DBOpenRequest.onsuccess = function () {

            //console.log('IndexedDB opened', dbName);
            // enregistre la connexion dans la variable db
            var db: IDBDatabase = DBOpenRequest.result;

            if (existed && db.objectStoreNames && db.objectStoreNames.contains("meta-store")) {

              // ouvre un transaction en mode lecture/écriture pour effectuer la suppression
              try {
                var transaction = db.transaction(["meta-store"], "readwrite");
              } catch (e) {
                console.log('Erreur à la creation de la transaction ' + dbName);
              }

              if (transaction) {
                transaction.onabort = function () {
                  console.log('Transaction abandonnée sur ' + dbName);
                  reject(transaction.error);
                  //db.close();
                };
                // affiche le succès de la transaction.
                transaction.oncomplete = function () {
                  console.log('Transaction effectuée: fin de la modification de la base de données ' + dbName);
                  //db.close();
                  resolve(true);
                };

                // affiche la cause de l’échec de la transaction.
                transaction.onerror = function () {
                  console.error('Échec de la transaction: ' + transaction.error + ' la base de données ' + dbName + ' n\'a pas été modifié');
                  reject(transaction.error);

                };
                // ouvre un accès au magasin d'objet
                var objectStore = transaction.objectStore("meta-store");
                // Retrouve l'enregistrement dont la clé est Walk dog
                var objectStoreRequest = objectStore.get("meta-store");

                objectStoreRequest.onerror = function () {
                  console.error('meta-store not found ' + objectStoreRequest.error + ' la base de données ' + dbName + ' n\'a pas été modifié');
                  reject(objectStoreRequest.error);
                };

                objectStoreRequest.onsuccess = function () {
                  //affecte la valeur de l'enregistrement à la variable
                  var myRecord = objectStoreRequest.result;
                  if (myRecord && myRecord[dbName + '_id'] && myRecord[dbName + '_id'] === "nan-undefinedundefined-undefinedundefined-undefinedundefined-undefinedundefinedundefinedundefinedundefinedundefined") {
                    console.log('Problème de synchronisation détécté sur ' + dbName);

                    var objectStoreDelete = objectStore.delete("meta-store");
                    objectStoreDelete.onerror = function () {
                      console.error('meta-store not deleted ' + objectStoreDelete.error + ' la base de données ' + dbName + ' n\'a pas été modifié');
                      reject(objectStoreDelete.error);
                    };
                    objectStoreDelete.onsuccess = function () {
                      console.log('Problème de synchronisation corrigé sur ' + dbName);
                      resolve(true);
                    }
                  } else {
                    resolve(false);
                  }
                };

              } else {
                console.log('no meta-store transaction ' + dbName);
                //db.close();
                resolve(false);
              }
            } else {
              console.log('no meta-store in ' + dbName);
              db.close();
              window.indexedDB.deleteDatabase(dbName);
              resolve(false);
            }
          };
        } else {
          console.log('indexedDB not supported in ' + dbName);
          resolve(false);
        }
      }
    });
  }
  /**
      * synchronize local/remote DB the Db connection with host
      * @protected
      */
  protected _syncDB(db: IDBParams, dbSuffix = '') {
    if (db && db.name) {
      const _that = this;
      this.dbName = db.name;
      this.remoteDbAttr = DbLocalSyncService.REMOTEDB_PREFIX + dbSuffix;
      this.localDbAttr = DbLocalSyncService.LOCALDB_PREFIX + dbSuffix;
      this.detectBrokenSync('_pouch_' + db.name).then((res) => {
        const remoteDb: PouchDB = this.getDB(db);
        const localDb: PouchDB = this.getDB({name: db.name});
        if (localDb && remoteDb) {
          localDb.info().then(function (x) {
            //console.log('DB (' + db.name + ') Info :' + db.name, x);
            _that._sync(localDb, remoteDb);
          }).catch(function (err) {
            console.error('DB (' + db.name + ') Info error :', err, localDb);
            _that._setStateError();
            // localDb = _that.getDB({name: db.name}, 'memory');
            // _that._sync(localDb, remoteDb, localDbAttr, remoteDbAttr);
          });
        }

      }).catch((err) => {
        console.log('detectBrokenSync error ' + db.name, err);
        _that._setStateError();
      });
    }
  }
  /**
      * synchronize local/remote DB the Db connection with host
      * @protected
      */
  protected _sync(localDb, remoteDb, opt = {
    retry: true,
    live: false
  }, isReadonly: boolean = false) {
    const _component = this;
    const start = new Date();

    if (this.replicator) {
      console.log('_sync cancel previous replication', this.dbName)
      this.replicator.cancel();
      this.replicator.removeAllListeners();
    }
    if (this.filter) {
      opt['filter'] = this.getFilter();
    }
    if (this.query_params && Object.keys(this.query_params).length) {
      opt['query_params'] = this.query_params;
    } else if (opt['query_params']) {
      delete opt['query_params'];
    }
    this.filterTooLong = false;
    if (opt['query_params'] && opt['query_params']['parents'] && opt['query_params']['parents'].length > DbLocalSyncService.PARENTS_LENGTH) {
      console.log('Trop de parent à synchroniser, suppression du filtre :' + opt['query_params']['parents'].split(',').length + ' parents / ' + opt['query_params']['parents'].length);
      delete opt['query_params']['parents'];
      delete opt['filter'];
      this.filterTooLong = true;
    }
    if (isReadonly) {
      opt['checkpoint'] = 'source';
    }
    opt['style'] = 'main_only';

    if (opt.live) {
      (this[this.localDbAttr] as ReplaySubject<PouchDB>).next(localDb);
      (this[this.remoteDbAttr] as ReplaySubject<PouchDB>).next(remoteDb);
      this.__localPouchDb = localDb;
      this.__remotePouchDb = remoteDb;
      //if (this.last_seq) {
      //opt['since'] = this.last_seq;
      //console.debug('live last seq:', this.last_seq);
      //}

      opt['batch_size'] = 200;
      opt['batches_limit'] = 2;
      opt['back_off_function'] = function (delay) {
        if (delay === 0) {
          delay = 1000;
        } else {
          console.log('back_off_function delay: ' + delay);
          delay = delay * 2;
          if (delay > 60000) {
            return 60000;
          }
        }
        return delay * 2;
      }
      console.log((new Date()).toLocaleTimeString() + ': start live sync :' + this.dbName, opt);
      this._setStateSync();
    } else {
      this.last_seq = '';
      //console.log('start initial sync :' + _component.constructor.name, [this.filter, this.query_params]);
      if (this.last_seq) {
        // opt['since'] = this.last_seq;
      }

      opt['batch_size'] = 250;
      opt['batches_limit'] = 2;
      if (opt['back_off_function']) {
        delete opt['back_off_function'];
      }
      if (this.selector) {
        opt["selector"] = this.selector;
      }
      console.log((new Date()).toLocaleTimeString() + ': ' + (isReadonly ? 'replicate.from ' : (this.writeOnly ? 'replicate.to ' : 'sync ')) + this.dbName, opt);
      this._setStateSyncing();
    }

    this.replicator = (isReadonly
      ? localDb.replicate.from(remoteDb, opt)
      : (this.writeOnly
        ? localDb.replicate.to(remoteDb, opt)
        : localDb.sync(remoteDb, opt)))
      .on('change', (chg) => {
        if (opt.live) {
          _component.zone.run(() => {
            // TODO: uniquement en direction="pull" ?
            // pas en cas d'erreurs ?
            const change = isReadonly ? chg : chg['change'];
            if (_component.localDbAttr === 'localDB' && change /*&& (isReadonly || chg['direction'] === 'pull' || chg['direction'] === 'push')*/ && change['ok'] && change['docs'] && change['docs'].length) {
              //console.log('live sync change :' + (isReadonly ? 'readonly' : chg['direction']) + ' on ' + remoteDb.name, chg);
              if (chg['direction'] === 'pull' && chg['change'] && chg['change']['last_seq']) {
                _component.last_seq = chg['change']['last_seq'];
                //console.log('live sync pull last_seq', _component.last_seq);
              }
              const lastrev: {[id: string]: string} = {};
              change['docs'].forEach(e => {
                if (e._id && e._rev && (!lastrev[e._id] || lastrev[e._id] < e._rev)) {
                  lastrev[e._id] = e._rev;
                }
              });
              //  _component.changes.next(chg['direction'] === 'pull' ? change['docs'].filter(e => (e._id && e._rev && lastrev[e._id] && lastrev[e._id] === e._rev)) : []);
              //if (chg['direction'] === 'pull')
              _component.changes.next(change['docs'].filter(e => (e._id && e._rev && lastrev[e._id] && lastrev[e._id] === e._rev)));
            } else {
              console.log('live sync other :' + _component.constructor.name, chg);
            }
          });
        } else {
          console.log((new Date()).toLocaleTimeString() + ': ' + 'initial sync change :' + _component.constructor.name, chg);
        }
        //}).on('checkpoint', function (cplt) {
        // console.error('checkpoint:' + _component.constructor.name, cplt);
      }).on('complete', function (cplt) {
        if (opt.live) {
          console.error('live sync cancel:' + _component.constructor.name, cplt);
        } else {
          const time = new Date().getTime() - start.getTime();
          console.log('initial sync complete (' + time + ') :' + _component.constructor.name, cplt);
          if (cplt && cplt['pull'] && cplt['pull']['last_seq']) {
            _component.last_seq = cplt['pull']['last_seq'];
            //console.debug('complete last seq:', _component.last_seq);
          }
          opt.live = true;
          _component.zone.run(() => {
            _component._sync(localDb, remoteDb, opt, isReadonly);
          });
        }
      }).on('error', (error) => {
        _component._setStateError();
        console.error('sync live data error :' + _component.constructor.name, error);
        if (error && error.status && error.status === 403 && error.message === "_writer access is required for this request") {
          if (_component.replicator && !isReadonly) {
            _component.replicator.removeAllListeners();
            _component.replicator.cancel();
          }
          opt.live = false;
          this.last_seq = '';
          _component._sync(localDb, remoteDb, opt, true);
        }
      }).on('denied', function (x) {
        _component._setStateError();
        console.error('sync denied :' + _component.constructor.name, x);
      })
      /*
      .on('paused', function(x) {
        _component.syncOnlineChange.next(false);
        console.error('sync paused :' + remoteDb.name, x);
      }).on('active', function(x) {
        _component.syncOnlineChange.next(true);
        console.error('sync active :' + remoteDb.name, x);
      })
      */
      ;
  }
  public saveRemote(obj, dbSuffix: string = '') {
    if (obj['_revisions']) {
      delete obj['_revisions'];
    }
    if (obj['_conflicts']) {
      delete obj['_conflicts'];
    }
    if (obj['_rev_tree']) {
      delete obj['_rev_tree'];
    }
    return this.remoteDB.pipe(first(),
      mergeMap(db => db.put(obj)));
  };

  public getRemote(id) {
    return this.remoteDB.pipe(first(),
      mergeMap((db: PouchDB) => db.get(id, {latest: true})));
  }
  public getListRemote(parent_id: string, docType: string[], docFields: string[]) {
    const selector = !parent_id
      ? {
        "$and": [
          {"parent_id": ''},
          {"documentType": (docType.length === 1 ? docType[0] : {$in: docType})}
        ]
      }
      : {
        "$and": [
          {"parent_id": ((parent_id === 'ALL_CHILDREN') ? {$gt: ''} : parent_id)},
          {"documentType": (docType.length === 1 ? docType[0] : {$in: docType})}
        ]
      };
    return (this.remoteDB as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        //console.log('query:', query);
        return db.find({
          selector: selector,
          fields: docFields
        })
      }),
      map((res: any) => {
        if (res && res.warning) {
          console.warn('db.find:', [{
            selector: selector,
            fields: docFields
          }, res]);
        }
        return res.docs;
      }));
  }
  public getChildrenRemote(parent_id: string, include_docs: boolean = true) {
    return (this.remoteDB as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        //console.log('query:', query);
        return db.query('by_parent_id', {
          key: parent_id,
          include_docs: include_docs
        })
      }),
      map((res: any) => {
        if (res && res.warning) {
          console.warn('db.query(_design/by_parent_id):' + parent_id, res);
        }
        return (res && res.rows) ? res.rows.map((e) => (
          include_docs ? e.doc : e.id
        )).filter((e) => (!!e)) : [];
      }));

  }
  public loadChildrenRemote(parent_id: string, force: boolean = false, include_docs: boolean = true): Promise<any[] | null> {
    return new Promise((resolve, reject) => {
      const now = (new Date()).getTime();
      if (force || !this.loadingChildrenRemote[parent_id] || now - this.loadingChildrenRemote[parent_id] > this.loadingChildrenDelay) {
        this.loadingChildrenRemote[parent_id] = now;
        this.getChildrenRemote(parent_id, include_docs).toPromise().then((docs) => {
          //console.log('loadChildrenRemote', docs);
          if (include_docs) {
            this.loadedChildrenRemote.next({parent_id: parent_id, docs: docs});
          }
          resolve(docs);
        }).catch((err) => {
          reject(err);
        });
      } else {
        //console.log('loadChildrenRemote no : ' + (now - this.loadingChildrenRemote[parent_id]) + ' <= ' + this.loadingChildrenDelay);
        resolve(null);
      }
    });
  }

  protected _setStateError() {
    this.syncStateChange.next(DbLocalSyncService.SYNSTATE_ERROR);
  }
  protected _setStateSyncing() {
    this.syncStateChange.next(DbLocalSyncService.SYNSTATE_SYNCING);
  }
  protected _setStateSync() {
    //console.log('sync complete before : ', this.syncCompletedChildren);
    this.syncCompletedChildren = (this.filterTooLong || !this.query_params['parents']) ? null : this.query_params['parents'].split(',');
    //console.log('sync complete after : ', this.syncCompletedChildren);
    this.syncStateChange.next(DbLocalSyncService.SYNSTATE_SYNC);
  }
  public setSyncComplete(id) {
    if (this.syncCompletedChildren && this.syncCompletedChildren.indexOf(id) === -1) {
      this.syncCompletedChildren.push(id);
    }
  }

}
