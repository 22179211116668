import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, Operator, deepCompare} from 'app/shared';
import {PreventionPlanService, ExternalCompanyService, EntityExternalCompany, WorkbookExternalCompanyService} from 'app/workbook-core';
import {WorkbookOfficeListComponent} from '../office';
import {WorkbookExternalCompanyDialogComponent} from './externalCompany-dialog.component';

export class WorkbookExternalCompanyListComponent extends WorkbookOfficeListComponent {

  entityList: EntityExternalCompany[];
  officeList: EntityExternalCompany[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette entreprise extérieure ?';
  addConfirmMessage = 'Nouvelle entreprise extérieure créée';
  eex_id: string = '';
  constructor(
    protected _officeService: ExternalCompanyService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_officeService, dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _entityUpdate(data) {
    super._entityUpdate(data);
    if (this.entity && this.entity.eex_id) {
      this.eex_id = this.entity.eex_id
    }
  }
  _init(data) {
    super._init(data);
    //this.updateWorkbook();
  }
  protected _getChildService() {
    return this._entityService.externalCompanyService;
  }

  getOfficeDialog(office: EntityExternalCompany) {
    const dialogRef = this.dialog.open(WorkbookExternalCompanyDialogComponent, {
      disableClose: true,
      minWidth: '960px',
      data: {
        id: office ? office._id : '',
        parent_id: this.id ? this.id : '',
        officeList: this._getOfficeList()
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c) {
        this.updateElement(c);
      }
    });
  }
  changePrincipal() {
    if (this.entity && this.eex_id) {
      const eex = this.entityList.find((e) => (e._id === this.eex_id));
      if (eex) {
        this.entity.eex_id = eex._id;
        this.entity.eex_name = eex.name;
        this._save();
      }
    }
  }
  updateElement(element: EntityExternalCompany) {
    let modified = false;
    if (this.entity && this.eex_id) {
      if (this.eex_id === element._id) {
        if (element._deleted) {
          this.eex_id = '';
          this.entity.eex_id = '';
          this.entity.eex_name = '';
          modified = true;
        } else if (this.entity.eex_name !== element.name) {
          this.entity.eex_name = element.name;
          modified = true;
        }
      } else if (this.entityList.findIndex((e) => (e._id === this.eex_id)) === -1) {
        this.eex_id = '';
        this.entity.eex_id = '';
        this.entity.eex_name = '';
        modified = true;
      }
    }
    super.updateElement(element);
    this._updateListElement(element, 'oeexList');
    //modified = this._updateListElement(element, 'oeexList') || modified;
    /*
    if (this.entityList.length && !this.eex_id) {
      this.eex_id = this.entityList[0]._id;
      this.entity.eex_id = this.entityList[0]._id;
      this.entity.eex_name = this.entityList[0].name;
      modified = true;
    }
    if (modified) {
      this.updateWorkbook(modified);
    }
    */
  }
  updateWorkbook(modified: boolean = false) {
    modified = this.updateUsers() || modified;
    modified = this.updateOperators() || modified;
    if (modified) {
      this._save();
    }
  }
  updateOperators() {
    let modified: boolean = false;
    if (this.entity && this.oeexList) {
      const list: string[] = [];
      const operatorList: Operator[] = [];
      const eexs: {[eex_name: string]: string} = {};
      this.oeexList
        .filter((o) => (!o._deleted && o.documentType === WorkbookExternalCompanyService.ENTITY_TYPE))
        .forEach((o) => {
          eexs[o.name] = o.repo_id;
          if (o.operators) {
            o.operators.forEach((operator) => {
              if (list.indexOf(operator.id) === -1) {
                list.push(operator.id);
                operatorList.push(operator);
                const i = this.entity.operators.findIndex((op) => (op.id === operator.id));
                modified = modified
                  || i === -1
                  || !deepCompare(this.entity.operators[i], operator);
              }
            });
          }
        });
      if (modified || JSON.stringify(this.entity.operators.map((e) => (e.id)).sort()) !== JSON.stringify(list.sort())) {
        this.entity.operators = operatorList;
      }
      if (!this.entity.eexs
        || Object.keys(this.entity.eexs).length !== Object.keys(eexs).length
        || Object.keys(this.entity.eexs).filter((e) => (this.entity.eexs[e] !== eexs[e])).length) {
        this.entity.eexs = eexs;
        modified = true;
      }
      if (this.entity.eex_id) {
        const eex = this.oeexList.find((e) => (e._id === this.eex_id));
        if (eex && this.entity.eex_name !== eex.name) {
          this.entity.eex_name = eex.name;
          modified = true;
        }
      }
    }
    return modified;
  }
}
