import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {EntitySecureService} from 'app/shared/services/entitySecure.service';
import {LoadingService} from './loading.service';
import {EntityPinturaConfig} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class PinturaConfigService extends EntitySecureService {

  autoInit = true;

  static LABEL_ONE = "chargement du paramètrage pintura";
  static LABEL_ALL = "chargement des paramètrages pintura ?";
  static LABEL_CHILDREN = "chargement des paramètrages pintura de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètrage pintura";
  static LABEL_DELETE = "suppression du paramètrage pintura";

  static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'pintura_config';
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showConfigs', 'showPinturaConfig'],
    CREATE: ['editConfigs', 'editPinturaConfig'],
    UPDATE: ['editConfigs', 'editPinturaConfig'],
    DELETE: []
  }

  static ENTITY_URL = 'config/pintura';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityPinturaConfig {
    return new EntityPinturaConfig(data, clone);
  }

}
