import { Component, Inject, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  promiseAll,
  LoggerService,
  EmailService, EntityEmailMessages, EmailMessages, EmailDatasModel, EmailMessagesService,
  EntityEditDialogComponent,
  DocumentMetadataService, EntityDocumentMetadata, EntityDocumentFile, DocumentStatus, IDocumentCategory,
  Contact, DocumentsRequestComponent
} from 'app/shared';
import {
  EntityOffice, OfficeService, ExternalCompanyService, DocumentsRequestService, EntityDocumentsRequest
} from 'app/workbook-core';
@Component({
  selector: 'app-documentsRequest-dialog',
  templateUrl: './documentsRequest-dialog.component.html',
  styleUrls: ['../../shared/components/document/documentMetadata-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class DocumentsRequestDialogComponent extends EntityEditDialogComponent {
  workbook;
  entityForm: DocumentsRequestComponent;
  entity: EntityDocumentMetadata;
  //office ou eex
  entity_id: string;
  categoryList: IDocumentCategory[] = [];
  requestList: Contact[] = [];
  notificationList: Contact[] = [];
  context: string;
  showEex: boolean;
  multiple: boolean;
  selectedEex: EntityOffice;
  eexList: EntityOffice[];
  eexToSave: EntityOffice;
  emailData;
  documentsRequest: EntityDocumentsRequest[] = [];
  documentsRequestSubscription: Subscription;

  constructor(
    protected _documentsRequestService: DocumentsRequestService,
    protected _officeService: OfficeService,
    protected _externalCompanyService: ExternalCompanyService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    this.documentsRequestSubscription = this._documentsRequestService.getAll().subscribe((list) => {
      if (list) {
        this.documentsRequest = list;
      }
    });
    if (data) {
      if (data['entity_id']) {
        this.entity_id = data['entity_id'];
      }
      if (data['parent_id']) {
        this.parent_id = data['parent_id'];
      }
      if (data['entity']) {
        this.entity = data['entity'];
      }
      if (data["categoryList"]) {
        this.categoryList = data["categoryList"];
      }
      if (data["context"]) {
        this.context = data["context"];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
        if (!this.parent_id) {
          this.parent_id = this.workbook._id;
        }
      }

      this.showEex = !!data["showEex"];
      this.multiple = !!data["multiple"];

      if (data["notificationList"]) {
        this.notificationList = data["notificationList"];
      }
      if (data["selectedEex"]) {
        this.selectedEex = data["selectedEex"];
        this.changeEex();
      }
      if (data['eexList']) {
        this.eexList = data['eexList'];
      }
      this._emailService.getEmailDatas(EmailMessages.DOCUMENTS_REPO_REQUEST).then(emailObj => {
        if (emailObj) {
          this.emailData = emailObj;
        }
      }).catch((error) => {
        this._logger.error('DocumentsRequestDialogComponent', 'error get email data', JSON.stringify(error));
      });

    }
  }
  ngOnDestroy() {
    if (this.documentsRequestSubscription) {
      this.documentsRequestSubscription.unsubscribe();
    }
  }
  changeEex() {
    this.requestList = this.selectedEex ? this.selectedEex.contacts.filter((e) => (!!e.email)) : [];
  }
  save() {
    if (this.entityForm && this.entityForm.entities && this.entityForm.entities.length) {
      this._spinnerService.show();

      if (this.selectedEex) {
        const oeexService = (this.selectedEex.documentType === 'repo_office') ? this._officeService : this._externalCompanyService;
        const elementsToAdd: EntityDocumentMetadata[] = this.entityForm.entities.map((e) => {
          e.parent_id = '';
          e.status = DocumentStatus.REQUESTED;
          e._id = UUID.UUID();
          return this._entityService.getNewEntity(this._entityService.beforeSave(e));
        });
        oeexService.getCurrentOne(this.selectedEex._id).then((eexToSave: EntityOffice) => {
          eexToSave.documents.push(...elementsToAdd);
          oeexService.save(eexToSave);
          this._sendNotifications();
          this._spinnerService.hide();
          this.dialogRef.close(eexToSave);
        });
      } else {
        const metasSaver = this.entityForm.entities.map((meta) => (this._entityService.save(meta)));
        promiseAll(metasSaver).then((res) => {
          this._spinnerService.hide();
          this.dialogRef.close();
        });
      }
    }
  }
  protected _sendNotifications(): void {
    if (this.entityForm.requestedList && this.entityForm.requestedList.length) {
      const emailPomises = [];
      this.entityForm.requestedList.forEach((contactId) => {
        const contact = this.entityForm.requestList.find((e) => (e.id === contactId));
        if (contact && contact.email) {
          const emailDatas = new EmailDatasModel();
          emailDatas.email = contact.email;
          emailDatas.to_name = contact.name;
          emailDatas.from_name = this.emailData.fromName;
          if (this.emailData.fromEmail) {
            emailDatas.from_email = this.emailData.fromEmail;
          }
          emailDatas.date = new Date();
          emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.subject, this.workbook, null, null, null, this.entityForm.entities);
          emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.text, this.workbook, null, null, null, this.entityForm.entities);
          if (contact.user_id) {
            emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.emailData.usertext, this.workbook, null, null, null, this.entityForm.entities);
          }
          emailDatas.signature = this.emailData.signature;
          emailDatas.rgpdMail = this.emailData.rgpdMail;
          emailDatas.dpoName = this.emailData.dpoName;
          emailDatas.dpoMail = this.emailData.dpoMail;
          emailPomises.push(this._emailService.sendEmail(emailDatas));


        }
      });
      promiseAll(emailPomises).then((res) => {
        this.snackBar.open('Les messages ont été envoyés', '', {
          duration: 3000
        });
      }).catch((e) => {
        this.snackBar.open('Les messages n\'ont pas été envoyés', 'Erreur', {
          duration: 3000
        });
      });
    }

  }
}