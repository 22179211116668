import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import {FormsModule} from '@angular/forms';
import { SharedModule } from 'app/shared';
import { PreventionPlanService } from 'app/workbook-core';
import { WorkbookSharedModule } from 'app/workbook-shared';
import { PreventionPlanRoutingModule } from './preventionPlan-routing.module';
import { PreventionPlanSidenavComponent } from './sidenav/sidenav.component';
import { PreventionPlanComponent } from './preventionPlan.component';
import { PreventionPlanEditComponent, PreventionPlanInfosDialogComponent } from './preventionPlanEdit';
import { PreventionPlanOfficeListComponent } from './office/officeList.component';
import { PreventionPlanExternalCompanyListComponent } from './externalCompany/externalCompanyList.component';
import {
  PreventionPlanIcpListComponent,
  PreventionPlanIcpEditComponent,
  PreventionPlanIcpActivityListComponent,
  WorkbookIcpSignableDialogComponent, WorkbookIcpComponent
} from './icp';
import {
  FirePermitListComponent,
} from './firePermit';
import {
  SignablePermitListComponent,
} from './signablePermit';
import {
  AuditListComponent,
} from './audit';
import {
  SecurityProtocolListComponent
} from './securityProtocol';
import { PreventionPlanActivityListComponent } from './activity';
import { PreventionPlanDocumentListComponent } from './document';
import {
  PreventionPlanStepComponent,
  PreventionPlanStepEditComponent,
  PreventionPlanStepListComponent,
  PreventionPlanStepActivityListComponent,
  WorkbookStepListComponent,
  WorkbookStepTemplateListComponent
} from './step';
import { PreventionPlanLocationComponent, PreventionPlanLocationEditComponent } from './location';
import { SignablePreventionPlanListComponent, SignablePreventionPlanInfoDialogComponent, SignablePreventionPlanDialogComponent, SignablePreventionPlanInfoComponent } from './signablePreventionPlan';


@NgModule({
  imports: [
    CommonModule,
    //  FormsModule,
    SharedModule,
    WorkbookSharedModule,
    PreventionPlanRoutingModule
  ],
  exports: [
    PreventionPlanSidenavComponent,
    PreventionPlanComponent,
    PreventionPlanEditComponent, PreventionPlanInfosDialogComponent,
    PreventionPlanOfficeListComponent,
    PreventionPlanExternalCompanyListComponent,
    PreventionPlanIcpListComponent, PreventionPlanIcpEditComponent, PreventionPlanIcpActivityListComponent, WorkbookIcpSignableDialogComponent, WorkbookIcpComponent,
    FirePermitListComponent,
    SignablePermitListComponent,
    AuditListComponent,
    SecurityProtocolListComponent,
    PreventionPlanActivityListComponent,
    SignablePreventionPlanListComponent, SignablePreventionPlanInfoDialogComponent, SignablePreventionPlanDialogComponent, SignablePreventionPlanInfoComponent,
    PreventionPlanDocumentListComponent,
    PreventionPlanStepComponent, PreventionPlanStepEditComponent, PreventionPlanStepListComponent, PreventionPlanStepActivityListComponent, WorkbookStepListComponent, WorkbookStepTemplateListComponent,
    PreventionPlanLocationComponent, PreventionPlanLocationEditComponent
  ],
  declarations: [
    PreventionPlanSidenavComponent,
    PreventionPlanComponent,
    PreventionPlanEditComponent, PreventionPlanInfosDialogComponent,
    PreventionPlanOfficeListComponent,
    PreventionPlanExternalCompanyListComponent,
    PreventionPlanIcpListComponent, PreventionPlanIcpEditComponent, PreventionPlanIcpActivityListComponent, WorkbookIcpSignableDialogComponent, WorkbookIcpComponent,
    FirePermitListComponent,
    SignablePermitListComponent,
    AuditListComponent,
    SecurityProtocolListComponent,
    PreventionPlanActivityListComponent,
    SignablePreventionPlanListComponent, SignablePreventionPlanInfoDialogComponent, SignablePreventionPlanDialogComponent, SignablePreventionPlanInfoComponent,
    PreventionPlanDocumentListComponent,
    PreventionPlanStepComponent, PreventionPlanStepEditComponent, PreventionPlanStepListComponent, PreventionPlanStepActivityListComponent, WorkbookStepListComponent, WorkbookStepTemplateListComponent,
    PreventionPlanLocationComponent, PreventionPlanLocationEditComponent
  ],
  entryComponents: [
    PreventionPlanInfosDialogComponent, SignablePreventionPlanDialogComponent, SignablePreventionPlanInfoDialogComponent,
    WorkbookIcpSignableDialogComponent
  ],
  providers: [
    PreventionPlanService
  ]
})
export class PPModule { }
