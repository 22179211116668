import {Component, Inject} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog} from '@angular/material';
import {UUID} from 'angular2-uuid';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll} from 'app/shared/utils';
import {LoggerService} from 'app/shared/logger';
import {EmailService, EmailMessages, EmailDatasModel} from 'app/shared/email';
import {EntityEditDialogComponent} from '../entityEditDialog.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {DocumentMetadataService, EmailMessagesService} from 'app/shared/services';
import {EntityDocumentMetadata, DocumentStatus, IDocumentCategory, Contact, EntityEmailMessages} from 'app/shared/models';
import {DocumentInfoComponent} from './documentInfo.component';
@Component({
  selector: 'app-documentMetadata-dialog',
  templateUrl: './documentMetadata-dialog.component.html',
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class DocumentMetadataDialogComponent extends EntityEditDialogComponent {
  workbook;
  entityForm: DocumentInfoComponent;
  entity: EntityDocumentMetadata;
  categoryList: IDocumentCategory[] = [];
  requestList: Contact[] = [];
  requestedList: string[] = [];
  notificationList: Contact[] = [];
  context: string;
  addOnly: boolean;
  uploadOnly: boolean;
  readOnly: boolean;
  hideDates: boolean;
  emailData;
  emailDataReject;
  rejected: boolean = false;
  showDefault: boolean = false;
  constructor(
    public dialog: MatDialog,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        this.entity = data['entity'];
      }
      if (data['rejected']) {
        this.rejected = data['rejected'];
      }
      if (data["categoryList"]) {
        this.categoryList = data["categoryList"];
      }
      if (data["requestList"]) {
        this.requestList = data["requestList"];
      }
      if (data["showDefault"]) {
        this.showDefault = data["showDefault"];
      }

      if (data["notificationList"]) {
        this.notificationList = data["notificationList"];
      }
      if (data["addOnly"]) {
        this.addOnly = data["addOnly"];
      }
      if (data["readOnly"]) {
        this.readOnly = data["readOnly"];
      }
      if (data["uploadOnly"]) {
        this.uploadOnly = data["uploadOnly"];
      }
      if (data["hideDates"]) {
        this.hideDates = data["hideDates"];
      }
      if (data["context"]) {
        this.context = data["context"];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
      }
    }
    this._emailService.getEmailDatas(EmailMessages.DOC_REQUEST).then(emailObj => {
      if (emailObj) {
        this.emailData = emailObj;
      }
    }).catch((error) => {
      this._logger.error('DocumentMetadataDialogComponent', 'error get email data', JSON.stringify(error));
    });
    this._emailService.getEmailDatas(EmailMessages.DOCUMENT_REJECT).then(emailObj => {
      if (emailObj) {
        this.emailDataReject = emailObj;
      }
    }).catch((error) => {
      this._logger.error('DocumentMetadataDialogComponent', 'error get email data', JSON.stringify(error));
    });
  }
  save() {
    if (this.entityForm && this.entityForm.entity) {
      if (!this.entityForm.entity._id) {
        this.entityForm.entity.status = this.entityForm.actionRadio === 2 ? DocumentStatus.REQUESTED : DocumentStatus.VALIDATED;
      }
      if (!this.entityForm.showDates) {
        this.entityForm.entity.dateStart = null;
        this.entityForm.entity.dateEnd = null;
      }
      if (!this.entityForm.entity.data_id && this.entityForm.file) {
        this._spinnerService.show();
        this.entityForm.uploadFile().then((docfile) => {
          this.entityForm.entity.data_id = docfile._id;
          if (this.entityForm.entity.status === DocumentStatus.REQUESTED) {
            this.entityForm.entity.status = DocumentStatus.PROPOSED;
          }
          if (this.entityForm.image_data && this.entityForm.modifiedImage) {
            this.entityForm.uploadImage().toPromise().finally(() => {
              this.__save();
            });
          } else {
            this.__save();
          }
        });
        //TODO erreur message ?
      } else if ((this.entityForm.modifiedFile || this.entityForm.modifiedImage) && this.entityForm.file) {
        this._spinnerService.show();
        this.entityForm.updateFile().toPromise().then(() => {
          if (this.entityForm.image_data && this.entityForm.modifiedImage) {
            this.entityForm.uploadImage().toPromise().finally(() => {
              this.__save();
            });
          } else {
            this.__save();
          }
        });
        //TODO erreur message ?
      } else if (this.entityForm.template) {
        this.entityForm.saveTemplate().then((templateFile) => {
          this.entityForm.entity.template_id = templateFile._id;
          this.__save();
        })
      } else {
        this.__save();
      }
    }
  }
  private __save() {
    if (this.parent_id) {
      super.save();
      this._spinnerService.hide();
    } else {
      if (!this.entityForm.entity._id) {
        this.entityForm.entity._id = UUID.UUID();
        this._sendNotifications();
        this.dialogRef.close(this._beforeSave(this.entityForm.entity));
        this._spinnerService.hide();
      } else if (this.entityForm.rejected) {
        this._sendNotifications(this.emailDataReject);
        this.dialogRef.close(this._beforeSave(this.entityForm.entity));
        this._spinnerService.hide();
      } else if (!this.entityForm.entity.data_id) {
        const _dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        _dialogRef.componentInstance.confirmMessage = 'Voulez-vous renvoyer l\'email de demande de document ?';
        _dialogRef.componentInstance.buttonMessage = 'Oui';
        _dialogRef.componentInstance.cancelMessage = 'Non';

        _dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.entityForm.requestedList = this.entityForm.requestList
              .filter((e) => (this.entityForm.entity.request.indexOf(e.email) !== -1))
              .map((e) => (e.id));
            this._sendNotifications(this.emailData)
          }
          this.dialogRef.close(this._beforeSave(this.entityForm.entity));
          this._spinnerService.hide();
        });
      } else {
        this.dialogRef.close(this._beforeSave(this.entityForm.entity));
        this._spinnerService.hide();
      }
    }
  }
  protected _beforeSave(entity: EntityDocumentMetadata) {
    if (this.entityForm.accepted) {
      this.entityForm.entity.status = DocumentStatus.VALIDATED;
    } else if (this.entityForm.rejected) {

      //TODO: Remove file
      entity.status = DocumentStatus.REQUESTED;
      entity.data_id = '';
      entity.ext = '';
      entity.icon = '';
      entity.size = '';
      entity.ext = '';
      entity.requestedAt = null;
      entity.requestedId = '';
      entity.requestedName = '';
      entity.uploadedAt = null;
      entity.uploadedId = '';
      entity.uploadedName = '';

    }
    return super._beforeSave(entity);
  }
  protected _sendNotifications(email_data = this.emailData): void {
    if (this.entityForm.requestedList && this.entityForm.requestedList.length) {
      const emailPomises = [];
      this.entityForm.requestedList.forEach((contactId) => {
        const contact = this.entityForm.requestList.find((e) => (e.id === contactId));
        if (contact && contact.email) {
          const emailDatas = new EmailDatasModel();
          emailDatas.email = contact.email;
          emailDatas.to_name = contact.name;
          emailDatas.from_name = email_data.fromName;
          if (email_data.fromEmail) {
            emailDatas.from_email = email_data.fromEmail;
          }
          emailDatas.date = new Date();
          emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), email_data.subject, this.workbook, null, null, null, this.entityForm.entity);
          emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), email_data.text, this.workbook, null, null, null, this.entityForm.entity);
          if (contact.user_id) {
            emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), email_data.usertext, this.workbook, null, null, null, this.entityForm.entity);
          }
          emailDatas.signature = email_data.signature;
          emailDatas.rgpdMail = email_data.rgpdMail;
          emailDatas.dpoName = email_data.dpoName;
          emailDatas.dpoMail = email_data.dpoMail;
          emailPomises.push(this._emailService.sendEmail(emailDatas));


        }
      });
      if (emailPomises.length) {
        this.entityForm.entity = this._entityService.beforeSendRequest(this.entityForm.entity);
        promiseAll(emailPomises).then((res) => {
          this.snackBar.open('Les messages ont été envoyés', '', {
            duration: 3000
          });
        }).catch((e) => {
          this.snackBar.open('Les messages n\'ont pas été envoyés', 'Erreur', {
            duration: 3000
          });
        });
      }
    }

  }
}
