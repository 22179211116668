import {EntityCompany, Contact, Operator, formattedDateFile, SignableStatus} from 'app/shared';
import {EntityDeed} from '../../workbook/models/entityDeed.model';
import {Implementation} from '../../workbook/models/entityStep.model';
import {EntityPermitContent, ContributorGroup, AttendanceGroup, ContentGroup} from '../../repository/models/entityPermitContent.model';

export class PermitState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static COMPLETED: number = 8;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Obsolète',
    'Archivé',
    'Terminé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est signée',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est valide',
    'L\'edition est obsolète',
    'L\'edition est archivée, l\'opération est archivée',
    'L\'edition est términée'
  ];

  static getLabels() {
    return PermitState.LABELS;
  }
  static getDescriptions() {
    return PermitState.DESCRIPTIONS;
  }
  static isValid(status: number, dateStart: Date = null, dateEnd: Date = null): boolean {
    return status === PermitStatus.SIGNED && dateStart
      && (new Date(dateStart).getTime() <= (new Date()).getTime() && (new Date(dateEnd ? dateEnd : dateStart).getTime() + (24 * 3600 * 1000)) >= (new Date()).getTime());
  }
  static isCompleted(status: number, dateStart: Date = null, dateEnd: Date = null): boolean {
    return status === PermitStatus.SIGNED && dateStart
      && ((new Date(dateEnd ? dateEnd : dateStart).getTime() + (24 * 3600 * 1000)) < (new Date()).getTime());
  }

  static getState(status: number, dateStart: Date = null, dateEnd: Date = null) {

    if (status === PermitStatus.DRAFT) {
      return PermitState.DRAFT;
    } else if (status === PermitStatus.VALIDATED) {
      return PermitState.PREPARED;
    } else if (status === PermitStatus.SIGNING) {
      return PermitState.SIGNING;
    } else if (status === PermitStatus.OUTDATED) {
      return PermitState.OUTDATED;
    } else {
      return PermitState.isValid(status, dateStart, dateEnd)
        ? PermitState.VALID
        : PermitState.isCompleted(status, dateStart, dateEnd) ? PermitState.COMPLETED : PermitState.SIGNED;
    }
  }

  static getLabel(status: number, dateStart: Date = null, dateEnd: Date = null) {
    const state = PermitState.getState(status, dateStart, dateEnd);
    return PermitState.LABELS[state] ? PermitState.LABELS[state] : 'state' + state;
  }
  static getDescription(status: number, dateStart: Date = null, dateEnd: Date = null) {
    const state = PermitState.getState(status, dateStart, dateEnd);
    return PermitState.DESCRIPTIONS[state] ? PermitState.DESCRIPTIONS[state] : 'state' + state;
  }
}

export class PermitStatus extends SignableStatus {
  static SIGNING: number = 2;
  static SIGNED: number = 3;
  static OUTDATED: number = 4;
  static LABELS: string[] = [
    'En préparation',
    'Préparé',
    'À signer',
    'Signé',
    'Obsolète'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est obsolète'
  ];

  static getLabels(withSignatories: boolean = true) {
    return PermitStatus.LABELS;
  }
  static getDescriptions(withSignatories: boolean = true) {
    return PermitStatus.DESCRIPTIONS;
  }
  static getLabel(status: number, withSignatories: boolean = true) {
    return PermitStatus.LABELS[status] ? PermitStatus.LABELS[status] : 'status' + status;
  }
  static getDescription(status: number, withSignatories: boolean = true) {
    return PermitStatus.DESCRIPTIONS[status] ? PermitStatus.DESCRIPTIONS[status] : 'status: ' + status;
  }
}
export class EntitySignablePermit extends EntityDeed {
  static PREFIX: string = 'PT';

  public detail: string;

  public permitContent: EntityPermitContent;

  public contributors: {[group_name: string]: {company: EntityCompany, contact: Contact}[]};

  public permitContents: {[group_name: string]: {[name: string]: string | boolean}};

  public operators: {[group_name: string]: Operator[]};

  public activities: string[];

  public localisations: string[];

  public implementation: Implementation;


  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.detail = (data && data.detail) ? data.detail : '';
    this.permitContent = new EntityPermitContent((data && data.permitContent) ? data.permitContent : {});
    this.contributors = (data && data.contributors) ? Object.assign({}, data.contributors) : {};
    this.permitContents = (data && data.permitContents) ? Object.assign({}, data.permitContents) : {};
    this.operators = {};
    if (data && data.operators) {
      Object.keys(data.operators).forEach((k) => {
        if (Array.isArray(data.operators[k])) {
          this.operators[k] = data.operators[k].map((o) => (new Operator(o)));
        }
      });
    }
    this.activities = (data && data.activities) ? data.activities.map((e) => (e)) : [];
    this.localisations = (data && data.localisations) ? data.localisations.map((e) => (e)) : [];
    if (this.permitContent.contributorGroups.length) {
      this.permitContent.contributorGroups.forEach((contributorGroup: ContributorGroup) => {
        if (!this.contributors[contributorGroup.name]) {
          this.contributors[contributorGroup.name] = [];
        }
        if (contributorGroup.required && !this.contributors[contributorGroup.name].length) {
          this.contributors[contributorGroup.name].push({company: new EntityCompany(), contact: new Contact()})
        }
      });
    }
    if (this.permitContent.contentGroups.length) {
      this.permitContent.contentGroups.forEach((contentGroup: ContentGroup) => {
        if (!this.permitContents[contentGroup.name]) {
          this.permitContents[contentGroup.name] = {};
        }
        if (!Object.keys(this.permitContents[contentGroup.name]).length) {
          contentGroup.contents.forEach((contentItem) => {
            this.permitContents[contentGroup.name][contentItem.value] = contentGroup.checkbox ? false : '';
          });
        }
      });
    }
    if (this.permitContent.operatorGroups.length) {
      this.permitContent.operatorGroups.forEach((attendanceGroup: AttendanceGroup) => {
        if (!this.operators[attendanceGroup.name]) {
          this.operators[attendanceGroup.name] = [];
        }
        if (attendanceGroup.required && !this.operators[attendanceGroup.name].length) {
          this.operators[attendanceGroup.name].push(new Operator())
        }
      });
    }
    this.implementation = (data && data.implementation) ? new Implementation(data.implementation) : null;
  }

  getTitle() {
    return this.title + " " + this.name;
  }
  getFileName() {
    return this.name + formattedDateFile(this.updatedAt) + (this.isValid() ? ('-' + this.status) : '');
  }
  isAttendanceManager(user_id) {
    return this.permitContent && this.permitContent.contributorGroups
      && this.permitContent.contributorGroups.findIndex((e) => (
        e.isAttendanceManager
        && this.contributors[e.name]
        && this.contributors[e.name].findIndex((c) => (
          c.contact
          && c.contact.user_id === user_id)) !== -1
      )) !== -1;
  }
}
