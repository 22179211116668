export class UserRole {

  constructor(
    public name: string = '',
    public permissions: string[] = []
  ) {}
  hasPermission(perm) {
    return this.permissions.indexOf(perm) !== -1;
  }
  clearPermissions() {
    this.permissions = [];
  }
  addPermission(perm) {
    if (!this.hasPermission(perm)) {
      this.permissions.push(perm);
    }
  }
  removePermission(perm) {
    if (!this.hasPermission(perm)) {
      this.permissions.splice(this.permissions.indexOf(perm), 1);
    }
  }
}
