import {EntityDocumentMetadata} from './entityDocumentMetadata.model';
export class EntityMap extends EntityDocumentMetadata {

  public mapData: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.mapData = data && data.mapData ? data.mapData : '';
  }
}

