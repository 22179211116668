import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {comparableString, LoggerService, Operator, EntityCompany, Contact, formattedDate} from 'app/shared';
import {
  PreventionPlanService,
  FirePermitService,
  EntityFirePermit,
  EntityPreventionPlan, DocumentationService,
  Activity, EntityOffice, EntityExternalCompany, WorkbookOfficeService, WorkbookExternalCompanyService,
  SiteService, EntitySite, Instruction
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from '../abstractSignableInfo.component';

@Component({
  selector: 'app-signable-firePermit-info',
  templateUrl: './firePermitInfo.component.html',
  styleUrls: ['./firePermitInfo.component.scss']
})
export class FirePermitInfoComponent extends AbstractSignableInfoComponent {
  static FLAG_SUFFIX: string = 'pf';
  autoSave = false;
  public entity: EntityFirePermit;
  workbook: EntityPreventionPlan;
  @Input() activities: Activity;
  minDateStart: Date;
  maxDateEnd: Date;

  dateStartError: string = '';
  dateEndError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  oeexList: (EntityOffice | EntityExternalCompany)[] = [];
  contactList: {[oeex_id: string]: Contact[]} = {};

  protected _originatorChangeSubscription: Subscription;
  filteredOriginators: (EntityOffice | EntityExternalCompany)[] = [];
  protected _performerChangeSubscription: Subscription;
  filteredPerformers: (EntityOffice | EntityExternalCompany)[] = [];
  protected _supervisorChangeSubscription: Subscription;
  filteredSupervisors: (EntityOffice | EntityExternalCompany)[] = [];
  protected _verifierChangeSubscription: Subscription;
  filteredVerifiers: (EntityOffice | EntityExternalCompany)[] = [];

  protected _originatorContactChangeSubscription: Subscription;
  filteredOriginatorContacts: (EntityOffice | EntityExternalCompany)[] = [];
  protected _performerContactChangeSubscription: Subscription;
  filteredPerformerContacts: (EntityOffice | EntityExternalCompany)[] = [];
  protected _supervisorContactChangeSubscription: Subscription;
  filteredSupervisorContacts: (EntityOffice | EntityExternalCompany)[] = [];
  protected _verifierContactChangeSubscription: Subscription;
  filteredVerifierContacts: (EntityOffice | EntityExternalCompany)[] = [];

  // offices: EntityOffice[] = [];
  // eexs: EntityExternalCompany[] = [];
  // oeexList: {[oeex_id: string]: EntityOffice | EntityExternalCompany} = {};
  protected _oeexSubscription: Subscription;
  /*
  withDocument: boolean = false;
  protected _documentsSubscription: Subscription;
  documents: {[item: string]: EntityDocumentMetadata[]} = {};
  */
  newEquipment: string = '';
  newHotSpotWork: string = '';
  newAttestation: string = '';
  newWarningDevice: string = '';
  newInterventionDevice: string = '';
  newEmergency: string = '';
  newSafetyInstruction: {[group: string]: string} = {};


  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  placeHolderStartDate: string = 'Début';
  placeHolderEndDate: string = 'Fin';
  constructor(
    protected _siteService: SiteService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: FirePermitService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list;
      }
    });
  }
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (changes) {
      if (changes['workbook'] && this.workbook) {
        this.minDateStart = new Date(this.workbook.dateStart);
        this.maxDateEnd = new Date(this.workbook.dateEnd);
      }
    }
  }
  protected _init(data) {
    super._init(data);

    if (this.entity && this.entity.safetyInstructions && this.entity.safetyInstructions.length) {
      this.newSafetyInstruction = {};
      this.entity.safetyInstructions.forEach((si) => {
        this.newSafetyInstruction[si.group] = '';
      });
    }
    /*
    if (this._documentsSubscription) {
      this._documentsSubscription.unsubscribe();
    }
    this._documentsSubscription = this._workbookService.getDocumentMetaDataList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.withDocument = list.length > 0;
        this.documents['Annexes'] = list.filter((e) => (this.workbook && e.context && e.context.indexOf(this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id, 'document')) === 1));
      }
    });
*/
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.oeexList = list;
        this.contactList = {};
        this.oeexList.forEach((oeex) => {
          this.contactList[oeex._id] = oeex.contacts;//.filter((c) => (c.signatoryPermit));
        });

        if (this.entity && !this.entity._id) {
          let services = this.oeexList.filter((e) => (e.documentType === WorkbookOfficeService.ENTITY_TYPE));
          if (services.length === 1) {
            this.entity.originator = new EntityCompany(services[0]);
            if (services[0].contacts && services[0].contacts.length === 1) {
              this.entity.originatorContact = Object.assign({}, services[0].contacts[0]);
            }
          }
          let eexs = this.oeexList.filter((e) => (e.documentType === WorkbookExternalCompanyService.ENTITY_TYPE));
          if (eexs.length === 1) {
            this.entity.performer = new EntityCompany(eexs[0]);
            if (eexs[0].contacts && eexs[0].contacts.length === 1) {
              this.entity.performerContact = Object.assign({}, eexs[0].contacts[0]);
            }
          }
        }
      }
    });

  }
  ngOnDestroy() {
    super.ngOnDestroy();
    /*
    if (this._documentsSubscription) {
      this._documentsSubscription.unsubscribe();
    }
    */
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._originatorChangeSubscription) {
      this._originatorChangeSubscription.unsubscribe();
    }
    if (this._performerChangeSubscription) {
      this._performerChangeSubscription.unsubscribe();
    }
    if (this._supervisorChangeSubscription) {
      this._supervisorChangeSubscription.unsubscribe();
    }
    if (this._verifierChangeSubscription) {
      this._verifierChangeSubscription.unsubscribe();
    }
    if (this._originatorContactChangeSubscription) {
      this._originatorContactChangeSubscription.unsubscribe();
    }
    if (this._performerContactChangeSubscription) {
      this._performerContactChangeSubscription.unsubscribe();
    }
    if (this._supervisorContactChangeSubscription) {
      this._supervisorContactChangeSubscription.unsubscribe();
    }
    if (this._verifierContactChangeSubscription) {
      this._verifierContactChangeSubscription.unsubscribe();
    }
  }
  /*
    ngAfterViewInit() {
      super.ngAfterViewInit();
      setTimeout(() => {
        this._initSearchSite();
        this._initSearchOriginator();
        this._initSearchPerformer();
        this._initSearchSupervisor();
        this._initSearchVerifier();
        this._initSearchOriginatorContact();
        this._initSearchPerformerContact();
        this._initSearchSupervisorContact();
        this._initSearchVerifierContact();
      });
    }
    */

  switchDoc(event, doc) {
    if (this.entity) {
      if (event.checked) {
        if (this.entity.documents.findIndex((e) => (e._id === doc._id)) === -1) {
          this.entity.documents.push(doc);
        }
      } else {
        let i = this.entity.documents.findIndex((e) => (e._id === doc._id));
        if (i !== -1) {
          this.entity.documents.splice(i, 1)
        }
      }
    }
  }
  addOperator() {
    let i = this.entity.operators.findIndex((op) => (!op.name && !op.job));
    if (i !== -1 && i !== (this.entity.operators.length - 1)) {
      this.entity.operators.splice(i, 1);
    }
    i = this.entity.operators.findIndex((op) => (!op.name && !op.job));
    if (i === -1) {
      this.entity.operators.push(new Operator({
        user_id: '',
        contact_id: '',
        name: '',
        email: '',
        job: '',
        extra: {}
      }));
    }
  }

  switchActivity(event, id) {
    if (this.entity && this.entity.activities) {
      if (event.checked) {
        if (this.entity.activities.indexOf(id)) {
          this.entity.activities.push(id);
        }
      } else {
        let i = this.entity.activities.indexOf(id);
        if (i !== -1) {
          this.entity.activities.splice(i, 1)
        }
      }
    }
  }
  addEquipment() {
    if (this.newEquipment) {
      this.entity.equipments[this.newEquipment] = true;
      this.newEquipment = '';
    }
  }
  addHotSpotWork() {
    if (this.newHotSpotWork) {
      this.entity.hotSpotWorks[this.newHotSpotWork] = true;
      this.newHotSpotWork = '';
    }
  }
  addAttestation() {
    if (this.newAttestation) {
      this.entity.attestations[this.newAttestation] = true;
      this.newAttestation = '';
    }
  }
  addWarningDevice() {
    if (this.newWarningDevice) {
      this.entity.warningDevices.push(this.newWarningDevice);
      this.newWarningDevice = '';
    }
  }
  removeWarningDevice(index) {
    if (index < this.entity.warningDevices.length) {
      this.entity.warningDevices.splice(index, 1);
    }
  }
  addInterventionDevice() {
    if (this.newInterventionDevice) {
      this.entity.interventionDevices.push(this.newInterventionDevice);
      this.newInterventionDevice = '';
    }
  }
  removeInterventionDevice(index) {
    if (index < this.entity.interventionDevices.length) {
      this.entity.interventionDevices.splice(index, 1);
    }
  }
  addEmergency() {
    if (this.newEmergency) {
      this.entity.emergency.push(this.newEmergency);
      this.newEmergency = '';
    }
  }
  removeEmergency(index) {
    if (index < this.entity.emergency.length) {
      this.entity.emergency.splice(index, 1);
    }
  }
  addSafetyInstruction(group: string) {
    if (group && this.newSafetyInstruction[group]) {
      const i = this.entity.safetyInstructions.findIndex((si) => (si.group === group));
      if (i !== -1) {
        this.entity.safetyInstructions[i].instructions.push(
          new Instruction(this.newSafetyInstruction[group], 1)
        );
        this.newSafetyInstruction[group] = '';
      }
    }
  }
  initSearchSite() {
    this.filteredSites = this.getFilteredByName(this.siteList, ((this.entity && this.entity.site) ? this.entity.site.name : ''));
    if (this.filteredSites && this.filteredSites.length > 200) {
      this.filteredSites = [];
    }
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredSites = this.getFilteredByName(this.siteList, value);
          if (this.filteredSites && this.filteredSites.length > 200) {
            this.filteredSites = [];
          }
        });
    }
  }
  initSearchOriginator() {
    this.filteredOriginators = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.originator) ? this.entity.originator.name : ''));
    if (this.filteredOriginators && this.filteredOriginators.length > 200) {
      this.filteredOriginators = [];
    }
    if (!this._originatorChangeSubscription && this.form && this.form.controls && this.form.controls['originator_name']) {
      this._originatorChangeSubscription = this.form.controls['originator_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredOriginators = this.getFilteredByName(this.oeexList, value);
          if (this.filteredOriginators && this.filteredOriginators.length > 200) {
            this.filteredOriginators = [];
          }
        });
    }
  }
  initSearchPerformer() {
    this.filteredPerformers = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.performer) ? this.entity.performer.name : ''));
    if (this.filteredPerformers && this.filteredPerformers.length > 200) {
      this.filteredPerformers = [];
    }
    if (!this._performerChangeSubscription && this.form && this.form.controls && this.form.controls['performer_name']) {
      this._performerChangeSubscription = this.form.controls['performer_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredPerformers = this.getFilteredByName(this.oeexList, value);
          if (this.filteredPerformers && this.filteredPerformers.length > 200) {
            this.filteredPerformers = [];
          }
        });
    }
  }
  initSearchSupervisor() {
    this.filteredSupervisors = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.supervisor) ? this.entity.supervisor.name : ''));
    if (this.filteredSupervisors && this.filteredSupervisors.length > 200) {
      this.filteredSupervisors = [];
    }
    if (!this._supervisorChangeSubscription && this.form && this.form.controls && this.form.controls['supervisor_name']) {
      this._supervisorChangeSubscription = this.form.controls['supervisor_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredSupervisors = this.getFilteredByName(this.oeexList, value);
          if (this.filteredSupervisors && this.filteredSupervisors.length > 200) {
            this.filteredSupervisors = [];
          }
        });
    }
  }

  initSearchVerifier() {
    this.filteredVerifiers = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.verifier) ? this.entity.verifier.name : ''));
    if (this.filteredVerifiers && this.filteredVerifiers.length > 200) {
      this.filteredVerifiers = [];
    }
    if (!this._verifierChangeSubscription && this.form && this.form.controls && this.form.controls['verifier_name']) {
      this._verifierChangeSubscription = this.form.controls['verifier_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredVerifiers = this.getFilteredByName(this.oeexList, value);
          if (this.filteredVerifiers && this.filteredVerifiers.length > 200) {
            this.filteredVerifiers = [];
          }
        });
    }
  }
  initSearchOriginatorContact(signatoryPermit: boolean = true) {
    this.filteredOriginatorContacts = this.getFilteredByName(this.contactList[this.entity.originator._id], ((this.entity && this.entity.originatorContact) ? this.entity.originatorContact.name : ''), signatoryPermit);
    if (this.filteredOriginatorContacts && this.filteredOriginatorContacts.length > 200) {
      this.filteredOriginatorContacts = [];
    }
    if (!this._originatorContactChangeSubscription && this.form && this.form.controls && this.form.controls['originatorContact_name']) {
      this._originatorContactChangeSubscription = this.form.controls['originatorContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredOriginatorContacts = this.getFilteredByName(this.contactList[this.entity.originator._id], value, signatoryPermit);
          if (this.filteredOriginatorContacts && this.filteredOriginatorContacts.length > 200) {
            this.filteredOriginatorContacts = [];
          }
        });
    }
  }
  initSearchPerformerContact(signatoryPermit: boolean = true) {
    this.filteredPerformerContacts = this.getFilteredByName(this.contactList[this.entity.performer._id], ((this.entity && this.entity.performerContact) ? this.entity.performerContact.name : ''), signatoryPermit);
    if (this.filteredPerformerContacts && this.filteredPerformerContacts.length > 200) {
      this.filteredPerformerContacts = [];
    }
    if (!this._performerContactChangeSubscription && this.form && this.form.controls && this.form.controls['performerContact_name']) {
      this._performerContactChangeSubscription = this.form.controls['performerContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredPerformerContacts = this.getFilteredByName(this.contactList[this.entity.performer._id], value, signatoryPermit);
          if (this.filteredPerformerContacts && this.filteredPerformerContacts.length > 200) {
            this.filteredPerformerContacts = [];
          }
        });
    }
  }
  initSearchSupervisorContact(signatoryPermit: boolean = true) {
    this.filteredSupervisorContacts = this.getFilteredByName(this.contactList[this.entity.supervisor._id], ((this.entity && this.entity.supervisorContact) ? this.entity.supervisorContact.name : ''), signatoryPermit);
    if (this.filteredSupervisorContacts && this.filteredSupervisorContacts.length > 200) {
      this.filteredSupervisorContacts = [];
    }
    if (!this._supervisorContactChangeSubscription && this.form && this.form.controls && this.form.controls['supervisorContact_name']) {
      this._supervisorContactChangeSubscription = this.form.controls['supervisorContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredSupervisorContacts = this.getFilteredByName(this.contactList[this.entity.supervisor._id], value, signatoryPermit);
          if (this.filteredSupervisorContacts && this.filteredSupervisorContacts.length > 200) {
            this.filteredSupervisorContacts = [];
          }
        });
    }
  }

  initSearchVerifierContact(signatoryPermit: boolean = true) {
    this.filteredVerifierContacts = this.getFilteredByName(this.contactList[this.entity.verifier._id], ((this.entity && this.entity.verifierContact) ? this.entity.verifierContact.name : ''), signatoryPermit);
    if (this.filteredVerifierContacts && this.filteredVerifierContacts.length > 200) {
      this.filteredVerifierContacts = [];
    }
    if (!this._verifierContactChangeSubscription && this.form && this.form.controls && this.form.controls['verifierContact_name']) {
      this._verifierContactChangeSubscription = this.form.controls['verifierContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredVerifierContacts = this.getFilteredByName(this.contactList[this.entity.verifier._id], value, signatoryPermit);
          if (this.filteredVerifierContacts && this.filteredVerifierContacts.length > 200) {
            this.filteredVerifierContacts = [];
          }
        });
    }
  }
  setCompanyAttribute(attribute: string, value: EntityOffice | EntityExternalCompany, event) {
    if (event && event.source && event.source.selected && attribute && this.entity && this.entity[attribute]) {
      this.entity[attribute] = new EntityCompany(value);
    }

  }
  setContactAttribute(attribute: string, value: Contact) {
    if (attribute && this.entity && this.entity[attribute]) {
      this.entity[attribute] = Object.assign(new Contact(), value);
    }

  }
  setOperator(index: number, value: Contact) {
    if (this.entity && this.entity.operators[index]) {
      this.entity.operators[index] = new Operator({
        user_id: value.user_id,
        contact_id: value.id,
        name: value.name,
        email: value.email,
        job: value.job
      });
    }
  }
  protected getFilteredByName(collection: any[], value: string = '', signatoryPermit: boolean = false): any[] {
    return (collection && collection.length)
      ? (value ?
        collection.filter(e => ((!signatoryPermit || e.signatoryPermit) && e.name && comparableString(e.name).indexOf(comparableString(value)) !== -1))
        : collection.filter(e => (!signatoryPermit || e.signatoryPermit)))
      : [];
  }
}
