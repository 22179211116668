import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Operator, ConfigEmailService, EntityConfigEmail, formattedDate} from 'app/shared';
import {OfficeService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-operator-dialog',
  templateUrl: './operator-dialog.component.html',
  styleUrls: ['./operator-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class RepositoryOperatorDialogComponent implements OnDestroy {

  public operator: Operator;
  protected _configEmailSubscription: Subscription;
  public regexEmail: string;
  public regexEmailInvalid: string;
  public msgEmail: string;
  public internal: boolean;
  fieldsErrors: string[] = [];
  //operatorBirthday: string = '';

  constructor(
    protected _configEmailService: ConfigEmailService,
    public dialogRef: MatDialogRef<RepositoryOperatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _officeService: OfficeService
  ) {
    this.internal = !!data['internal'];
    this._configEmailSubscription = this._configEmailService.getSingleOne().subscribe((ce: EntityConfigEmail) => {
      if (ce) {
        this.regexEmail = this.internal ? ce.regexInternal : ce.regexExternal;
        this.msgEmail = this.internal ? ce.msgInternal : ce.msgExternal;
        this.regexEmailInvalid = EntityConfigEmail.EMAIL_REGEX;
      }
    });
    this.operator = data['operator'] ? data['operator'] : new Operator();
    //this.operatorBirthday = this.operator.birthday ? formattedDate(this.operator.birthday) : '';
    // this.operatorList = data['operatorList'] ? data['operatorList'] : [];

  }
  ngOnDestroy() {
    if (this._configEmailSubscription) {
      this._configEmailSubscription.unsubscribe();
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.operator);
    }
  }


  getEmailError() {
    if (this.operator && this.operator.email) {
      let reg = this.regexEmailInvalid;
      const regex = RegExp(reg);
      let res = regex.exec(this.operator.email);
      if (res && res.length) {
        return this.msgEmail;
      } else {
        return EntityConfigEmail.EMAIL_INVALID;
      }
    }
  }
  /*
  changeBirthday() {
    if (this.operator && this.operator.birthday) {
      this.operatorBirthday = this.operator.birthday ? formattedDate(new Date(this.operator.birthday)) : '';
    }
  }
  updateBirthday() {
    if (this.operator && this.operatorBirthday) {
      const d = this.operatorBirthday.match('(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[0-9]|1[0-2])\/([1-2][0-9][0-9][0-9])')
      if (d && d.length === 4) {
        this.operator.birthday = new Date(+d[3], +d[2] - 1, +d[1]);
      } else {
        this.changeBirthday()
      }
    }
  }
  */
}
