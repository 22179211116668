import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {CategoryService, EntityCategory} from 'app/workbook-core';

@Component({
  selector: 'app-repository-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends EntityFormComponent {

  entity: EntityCategory;
  limited: boolean = false;
  pictos: string[];
  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: CategoryService

  ) {
    super(dialog, _logger, _entityService);
    this.pictos = CategoryService.PICTOS;
  }

}
