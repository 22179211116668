import { MatDialog, MatSnackBar } from '@angular/material';
import { LoggerService, EntitySecureService } from 'app/shared';
import { AbstractWorkbookChildFormComponent } from './abstractWorkbookChildForm.component';
import {
  EntityAnalysable,
  PreventionPlanService,
  MeasureService
} from 'app/workbook-core';

export class AbstractLocalMeasuresComponent extends AbstractWorkbookChildFormComponent {

  constructor(
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(dialog, _logger, _entityService);
  }

  updateLocalMeasure(measure) {
    if (measure._id && measure.type === 'local') {
      this._workbookService.measureService.save(measure).then((e) => {
        this.afterUpdateLocalMeasure(measure);
      });
    }
  }

  afterUpdateLocalMeasure(measure) {
  }
}
