import {UUID} from 'angular2-uuid';
import {EntityDocumentMetadata} from './entityDocumentMetadata.model';

export class Operator {
  id: string;
  user_id: string;
  contact_id: string;
  company: string;
  company_id: string;
  company_repo_id: string;
  name: string;
  email: string;
  phone: string;
  job: string;
  workforce: number;
  documents: EntityDocumentMetadata[];
  repo_id: string;
  isDefault: boolean;
  extra: {[key: string]: string | number | Date | null};

  //habilitation: string;
  //birthday: Date;
  //birthplace: string;

  constructor(data: any = null) {
    this.id = (data && data.id) ? data.id : UUID.UUID();
    this.user_id = (data && data.user_id) ? data.user_id : '';
    this.contact_id = (data && data.contact_id) ? data.contact_id : '';
    this.company_id = (data && data.company_id) ? data.company_id : '';
    this.company_repo_id = (data && data.company_repo_id) ? data.company_repo_id : '';
    this.company = (data && data.company) ? data.company : '';
    this.name = (data && data.name) ? data.name : '';
    this.email = (data && data.email) ? data.email : '';
    this.phone = (data && data.phone) ? data.phone : '';
    this.job = (data && data.job) ? data.job : '';
    this.workforce = (data && data.workforce) ? data.workforce : 1;
    this.repo_id === (data && data.repo_id) ? data.repo_id : '';
    this.isDefault = !!(data && data.isDefault);
    //this.habilitation = (data && data.habilitation) ? data.habilitation : '';
    //this.birthday = (data && data.birthday) ? new Date(data.birthday) : null;
    //this.birthplace = (data && data.birthplace) ? data.birthplace : '';
    this.extra = (data && data.extra) ? Object.assign({}, data.extra) : {};
    if (data && data.habilitation) {
      this.extra['habilitation'] = data.habilitation;
    }
    if (data && data.birthday) {
      this.extra['birthday'] = data.birthday;
    }
    if (data && data.birthplace) {
      this.extra['birthplace'] = data.birthplace;
    }

  }

}