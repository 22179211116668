import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityEditComponent, LoggerService} from 'app/shared';
import {IcpRecurrenceService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-dashboard-edit',
  templateUrl: './dashboardEdit.component.html',
  styleUrls: ['../repository.scss']
})

export class DashboardEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: IcpRecurrenceService

  ) {
    super(_route, _router, _logger, _entityService);
  }
}
