import {LOCALE_ID, ViewEncapsulation, Component, ViewChild, OnChanges, AfterViewInit, SimpleChanges, Input, EventEmitter, Output, ChangeDetectionStrategy} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {DxPivotGridComponent, DxChartComponent} from 'devextreme-angular';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import frMessages from 'locale/devextreme/fr_FR';
import {locale, loadMessages} from "devextreme/localization";

@Component({
  selector: 'app-stats',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./stats.scss'],
  templateUrl: './stats.html',
  providers: [
    {provide: LOCALE_ID, useValue: "fr-FR"}
  ]
  , changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsComponent implements OnChanges, AfterViewInit { //, OnInit

  @ViewChild(DxPivotGridComponent) pivotGrid: DxPivotGridComponent;
  @ViewChild(DxChartComponent) chart: DxChartComponent;

  pivotGridDataSource: PivotGridDataSource;

  @Input() persistKey: string = "PGPersist";
  storageKey: string;
  currentState: any;
  savedState: any;
  //currentFilters: any;
  //savedFilters: any;
  @Input() allowStateStorage?: boolean = true;
  //donnée
  @Input() source: any[] = [];
  //parametrage pivotgrid
  @Input() fields: Object[] = [];
  @Output() stateChange: EventEmitter<Object[]> = new EventEmitter<Object[]>();

  //type de graphique
  @Input() chartType: string;
  @Input() showGrid: boolean = true;

  @Input() allowSortingBySummary: boolean = true;
  @Input() allowSorting: boolean = true;
  @Input() allowFiltering: boolean = true;
  @Input() showBorders: boolean = true;
  @Input() showColumnGrandTotals: boolean = false;
  @Input() showRowGrandTotals: boolean = false;
  @Input() showRowTotals: boolean = true;
  @Input() showColumnTotals: boolean = false;
  @Input() showFieldChooser: boolean = false;
  @Input() showExport: boolean = false;
  @Input() panelShowRowFields: boolean = true;
  @Input() panelShowDataFields: boolean = false;
  @Input() panelShowColumnFields: boolean = false;
  @Input() panelShowFilterFields: boolean = true;
  @Input() panelAllowFieldDragging: boolean = true;
  @Input() panelVisible: boolean = true;
  @Input() rotated: boolean = false;
  @Input() allowArgumentDecimals: boolean = false;
  @Input() showArgumentGrid: boolean = true;
  @Input() showValueGrid: boolean = true;
  @Input() showLegend: boolean = true;
  @Input() zoom: string = 'none';//"all" | "touch" | "mouse" | "none"
  //@Input() filters;
  @Input() columnExpandedPaths = [];
  @Input() rowExpandedPaths = [];
  myPalette = ['#425563', '#768692', '#ffd900', '#005e5d', '#00b388', '#7bafd4', '#4f868e', '#5b5378', '#7dd1b8', '#4f868e', '#5b5378', '#7dd1b8', '#4f868e', '#425563', '#ff5e00'];
	/*
		{
			simpleSet: ['red', 'blue', '#6682bb', '#a37182', '#eeba69'], // for dxChart, dxPieChart and dxBarGauge
			indicatingSet: ['red', '#eeba69', '#a37182'], // for dxCircularGauge and dxLinearGauge
			gradientSet: ['red', '#eeba69'] // for dxVectorMap
		};
	*/
  viewFieldChooser = false;
  constructor() {
    loadMessages(frMessages);
    locale(navigator.language);

  }
  alert(name, event) {
    console.log('alert:' + name, event);
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log('PivotComponent:ngOnChanges', changes);
    this.panelVisible = this.panelVisible || this.panelShowRowFields || this.panelShowColumnFields || this.panelShowDataFields;
    this.currentState = this.pivotGridDataSource ? this.pivotGridDataSource.state() : {"fields": this.fields, "columnExpandedPaths": this.columnExpandedPaths, "rowExpandedPaths": this.rowExpandedPaths};
    if (!this.storageKey || changes['persistKey']) {
      this.storageKey = this.persistKey;
      //console.log('DataTableComponent:ngOnChanges:init storageKey', this.storageKey);
    }
    if (changes['persistKey']) {
      this.savedState = this.getSavedState(this.storageKey);
      if (this.savedState && this.savedState['fields']) {
        if (!changes['fields']) {
          this.stateChange.emit(this.savedState);
        }
      } else {
        //creation
        this.saveState(this.storageKey);
      }
    }
    if (this.allowStateStorage &&
      (changes['fields']
				/*
					|| changes['chartType']
					|| changes['showColumnGrandTotals']
					|| changes['showRowGrandTotals']
					|| changes['showRowTotals']
					|| changes['showColumnTotals']
					|| changes['panelShowRowFields']
					|| changes['panelShowDataFields']
					|| changes['panelShowColumnFields']
					|| changes['rotated']
					//|| changes['filters']*/
        || changes['columnExpandedPaths']
        || changes['rowExpandedPaths'])) {
      if (changes['fields']) {
        this.currentState['fields'] = this.fields;
      }
      if (changes['columnExpandedPaths']) {
        this.currentState['columnExpandedPaths'] = this.columnExpandedPaths;
      }
      if (changes['rowExpandedPaths']) {
        this.currentState['rowExpandedPaths'] = this.rowExpandedPaths;
      }
      this.saveState(this.storageKey);
    }
    if (changes['fields'] || changes['source']) {
      this.pivotGridDataSource = new PivotGridDataSource({
        //fields: changes['fields'] ? this.fields.map((e) => this.translateField(e)) : this.fields,
        fields: this.fields,
        store: this.source
      });
    }
  }
  getSavedState(storageKey) {
    return JSON.parse(localStorage.getItem(storageKey));
  }
  saveState(storageKey) {
		/*
		 this.currentState['chartType'] = this.chartType;
		this.currentState['showColumnGrandTotals'] = this.showColumnGrandTotals;
		this.currentState['showRowGrandTotals'] = this.showRowGrandTotals;
		this.currentState['showRowTotals'] = this.showRowTotals;
		this.currentState['showColumnTotals'] = this.showColumnTotals;
		this.currentState['panelShowRowFields'] = this.panelShowRowFields;
		this.currentState['panelShowDataFields'] = this.panelShowDataFields;
		this.currentState['panelShowColumnFields'] = this.panelShowColumnFields;
		this.currentState['rotated'] = this.rotated;
		//this.currentState['filters'] = this.filters;
		*/
    localStorage.setItem(storageKey, JSON.stringify(this.currentState));
  }
  //Attention !
  //<dxo-state-storing [enabled]="allowStateStorage" [customLoad]="loadState" [customSave]="saveState" type="custom" [storageKey]="persistKey"></dxo-state-storing>
  //this est ici le statestorage (composant appelant) et non le component
  // = accès en mode tableau pour eviter l'erreur de compilation
	/*
		loadState() {
			var state = JSON.parse(localStorage.getItem(this['storageKey']));
			//console.log('loadState ' + this['storageKey'], state);
		}
		saveState(s) {
			localStorage.setItem(this['storageKey'], JSON.stringify(s))
			//console.log('saveState ' + this['storageKey'], s);
		}
	*/
  translateField(e) {
    if (!e.i18n_caption) {
      e.i18n_caption = e.caption;
    }
    //console.log('PivotComponent:translateField:', e, this.translateService.instant(e.i18n_caption));
    //e.caption = this.translateService.instant(e.i18n_caption);
    return e;
  }
  //liaison graphique / pivotgrid
  ngAfterViewInit() {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      //dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false,
      putDataFieldsInto: "series"
    });
  }
  //tooltip pour graphique
  customizeTooltip(args) {
    return {
      html: args.seriesName + "<div class='currency'>" + new DecimalPipe('fr-FR').transform(args.originalValue, "1.0-0") + "</div>"
    };
  }
  //field chooser
  contextMenuPreparing(e) {
    var dataSource = e.component.getDataSource(),
      sourceField = e.field;

    if (sourceField) {
      if (!sourceField.groupName || sourceField.groupIndex === 0) {
        e.items.push({
          text: "Hide field",
          onItemClick: function () {
            var fieldIndex;
            if (sourceField.groupName) {
              fieldIndex = dataSource.getAreaFields(sourceField.area, true)[sourceField.areaIndex].index;
            } else {
              fieldIndex = sourceField.index;
            }

            dataSource.field(fieldIndex, {
              area: null
            });
            dataSource.load();
          }
        });
      }

      if (sourceField.dataType === "number") {
        var setSummaryType = function (args) {
          dataSource.field(sourceField.index, {
            summaryType: args.itemData.value
          });

          dataSource.load();
        },
          menuItems = [];

        e.items.push({text: "Summary Type", items: menuItems});

        for (let summaryType of ["Sum", "Avg", "Min", "Max"]) {
          var summaryTypeValue = summaryType.toLowerCase();

          menuItems.push({
            text: summaryType,
            value: summaryType.toLowerCase(),
            onItemClick: setSummaryType,
            selected: e.field.summaryType === summaryTypeValue
          });
        };
      }
    }
  }
  exportToExcel() {
    if (this.pivotGrid && this.pivotGrid.instance) {
      this.pivotGrid.instance.exportToExcel();
    }
  }
  toggleFieldChooser() {
    if (this.pivotGrid && this.pivotGrid.instance) {
      let fc = this.pivotGrid.instance.getFieldChooserPopup();
      if (fc) {
        this.viewFieldChooser = !this.viewFieldChooser;
        fc.toggle(this.viewFieldChooser);
      }
    }
  }
  expandAll() {
    //let rows = this.pivotGridDataSource.getAreaFields('row', true);
    //let cols = this.pivotGridDataSource.getAreaFields('row', true);
    //	this.pivotGridDataSource.expandAll('row');
  }
  collapseAll() {
    //	this.pivotGridDataSource.collapseAll();
  }
}
