import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { TreeElement, PermissionsService, LoggerService, DocumentService } from 'app/shared';
import { OfficeService, EntityOffice, OrganizationService, EntityOrganization, CategoryService } from 'app/workbook-core';
import { AbstractOfficeComponent } from '../abstractOffice.component';

@Component({
  selector: 'app-repository-office',
  templateUrl: './office.component.html',
  styleUrls: ['../office.component.scss']
})
export class OfficeComponent extends AbstractOfficeComponent {

  organizationListSubscriber: Subscription;
  organizationList: EntityOrganization[] = [];
  mainOfficeListSubscriber: Subscription;
  mainOfficeList: EntityOffice[] = [];
  mainOfficeListFiltered: boolean[] = [];

  displayedVisivilityColumns: string[] = ['label', 'occasional', 'annual', 'protocol'];
  visibilityDataSource: MatTableDataSource<VisibilityItem> = new MatTableDataSource([]);
  level: { [id: string]: number } = {};
  selectVisibilityToggle: boolean = false;
  modifiedVisibility: boolean = false;
  main_filter: string = '';

  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: OfficeService,
    public organizationService: OrganizationService

  ) {
    super(_categoryService, _documentService, dialog, snackBar, _permissionService, _logger, _entityService);
    this.organizationListSubscriber = this.organizationService.getAll().subscribe((organizationList) => {
      if (organizationList) {
        this.organizationList = organizationList.sort((a, b) => a.name.localeCompare(b.name));
      }
    })
    this.mainOfficeListSubscriber = this._entityService.getAll().subscribe((mainOfficeList) => {
      if (mainOfficeList) {
        this.level = TreeElement.getLevels(mainOfficeList, 'main');
        this.mainOfficeList = TreeElement.orderTreeView(mainOfficeList, 'main');
        this.filtrerMainList();
        this.updateVisibilityDatasource();
      }
    })
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.updateVisibilityDatasource();
    }
  }
  filtrerMainList() {
    this.mainOfficeListFiltered = this.mainOfficeList.map((e) => (!this.main_filter || e.name.toLocaleLowerCase().indexOf(this.main_filter.toLocaleLowerCase()) !== -1));
  }
  updateVisibilityDatasource() {
    if (this.entity && this.mainOfficeList) {
      const newVisibilities: VisibilityItem[] = [];
      let item: VisibilityItem = {
        id: '',
        name: 'Par défaut',
        occasional: !this.entity.defaultVisibilityOccasional,
        annual: !this.entity.defaultVisibilityAnnual,
        protocol: !this.entity.defaultVisibilityProtocol,
        level: 1,
        selected: (!this.entity.defaultVisibilityOccasional || !this.entity.defaultVisibilityAnnual || !this.entity.defaultVisibilityProtocol)
      };
      newVisibilities.push(item);
      item = {
        id: 'none',
        name: 'Aucun',
        occasional: !!(this.entity.defaultVisibilityOccasional && this.entity.defaultVisibilityOccasional.length === 0),
        annual: !!(this.entity.defaultVisibilityAnnual && this.entity.defaultVisibilityAnnual.length === 0),
        protocol: !!(this.entity.defaultVisibilityProtocol && this.entity.defaultVisibilityProtocol.length === 0),
        level: 1
      };
      item.selected = item.occasional || item.annual || item.protocol;
      newVisibilities.push(item);
      item = {
        id: 'all',
        name: 'Tous',
        occasional: !!(this.entity.defaultVisibilityOccasional && this.entity.defaultVisibilityOccasional.indexOf('*') !== -1),
        annual: !!(this.entity.defaultVisibilityAnnual && this.entity.defaultVisibilityAnnual.indexOf('*') !== -1),
        protocol: !!(this.entity.defaultVisibilityProtocol && this.entity.defaultVisibilityProtocol.indexOf('*') !== -1),
        level: 1
      };
      item.selected = item.occasional || item.annual || item.protocol;
      newVisibilities.push(item);
      this.mainOfficeList.forEach((office) => {
        item = {
          id: office._id,
          name: office.name,
          occasional: !!(this.entity.defaultVisibilityOccasional && this.entity.defaultVisibilityOccasional.indexOf(office._id) !== -1),
          annual: !!(this.entity.defaultVisibilityAnnual && this.entity.defaultVisibilityAnnual.indexOf(office._id) !== -1),
          protocol: !!(this.entity.defaultVisibilityProtocol && this.entity.defaultVisibilityProtocol.indexOf(office._id) !== -1),
          level: (this.level[office._id] ? this.level[office._id] : 1)
        };
        item.selected = item.occasional || item.annual || item.protocol;
        newVisibilities.push(item);
      });
      this.visibilityDataSource = new MatTableDataSource(newVisibilities);
    }

  }
  ngOnDestroy() {
    if (this.organizationListSubscriber) {
      this.organizationListSubscriber.unsubscribe();
    }
    if (this.mainOfficeListSubscriber) {
      this.mainOfficeListSubscriber.unsubscribe();
    }
  }
  changeVisibility(type: string, id: string, event) {
    if (event.checked) {
      this.entity.addVisibility(type, id);
    } else {
      this.entity.removeVisibility(type, id);
    }
    this.updateVisibilityDatasource();
    this.modifiedVisibility = true;
  }

  changeDefaultAdd(event) {
    if (this.entity.defaultAdd && this.entity.defaultAdd.indexOf('all') !== -1) {
      this.entity.defaultAdd = ['all'];
    }
    this._save();
  }
}

export class VisibilityItem {
  id: string;
  name: string;
  occasional: boolean;
  annual: boolean;
  protocol: boolean;
  level: number;
  selected?: boolean;
}