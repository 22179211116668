import {NgModule} from '@angular/core';
import {DxButtonModule, DxCheckBoxModule, DxPivotGridModule, DxChartModule} from 'devextreme-angular';
import {StatsComponent} from './stats.component';

@NgModule({
  imports: [
    DxPivotGridModule,
    DxChartModule,
    DxCheckBoxModule,
    DxButtonModule
  ],
  exports: [
    StatsComponent
  ],
  declarations: [
    StatsComponent
  ],
  providers: [],
})
export class StatsModule {}