import {EntityCompany, Operator, Contact, Entity} from 'app/shared';

export class Site extends EntityCompany {
  public phone: string;

}

export class Workbook extends Entity {
  static STATUS_ARCHIVE: string = 'Archivé';
  static STATUS_DRAFT: string = 'Préparation';
  static STATUS_PROGRESS: string = 'En cours';
  static STATUS_COMPLETE: string = 'Terminé';
  static STATUS_COLOR: {[status: string]: string} = {
    'Archivé': '#909AA0',
    'Préparation': '#f9b233',
    'En cours': '#14aea7',
    'Terminé': '#6f6f6e'
  };
  public name: string;
  public reference: string;
  public description: string;
  // status : in_preparation, in_progress, completed, archived
  public status: string;
  // annual, occasional
  public type: string;
  // only one step
  public simple: boolean;

  public created_at: Date;
  public updated_at: Date;
  public created_by: string;
  public updated_by: string;

  public editor: string;
  public editorEmail: string;

  public dateStart: Date;
  public dateEnd: Date;
  public hourStart: string;
  public hourEnd: string;
  public workConditions: string;
  public point: string;
  public site: Site;

  public eex_name: string;
  public eex_id: string;

  // TODO : getter ?
  public users: string[];
  public operators: Operator[];
  public eex_contacts: {[oeex_name: string]: Contact[]};
  public office_contacts: {[oeex_name: string]: Contact[]};

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = data.name ? data.name : '';
    this.reference = data.reference ? data.reference : '';
    this.description = data.description ? data.description : '';
    this.type = data.type ? data.type : 'occasional';
    this.simple = data.simple;
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
    this.created_by = data.created_by ? data.created_by : '';
    this.updated_by = data.updated_by ? data.updated_by : '';
    this.editor = data.editor ? data.editor : '';
    this.editorEmail = data.editorEmail ? data.editorEmail : '';
    this.dateStart = data.dateStart ? new Date(data.dateStart) : null;
    this.dateEnd = data.dateEnd ? new Date(data.dateEnd) : null;
    this.hourStart = data.hourStart ? data.hourStart : '';
    this.hourEnd = data.hourEnd ? data.hourEnd : '';
    this.workConditions = data.workConditions ? data.workConditions : '';
    this.point = data.point ? data.point : '';
    this.site = data.site ? Object.assign({}, data.site) : new Site();

    this.eex_name = data.eex_name ? data.eex_name : '';
    this.eex_id = data.eex_id ? data.eex_id : '';

    this.users = data.users ? data.users.map((e) => e) : [];

    this.status = data.status ? data.status : '';
    this.status = this.getStatus();
    this.operators = data.operators ? data.operators.map((e) => new Operator(e)) : [];
    this.eex_contacts = {};
    if (data && data.eex_contacts) {
      Object.keys(data.eex_contacts).forEach((oeex_name) => {
        this.eex_contacts[oeex_name] = data.eex_contacts[oeex_name] ? data.eex_contacts[oeex_name].map((e) => Object.assign(new Contact(), e)) : [];
      });
    }
    this.office_contacts = {};
    if (data && data.office_contacts) {
      Object.keys(data.office_contacts).forEach((oeex_name) => {
        this.office_contacts[oeex_name] = data.office_contacts[oeex_name] ? data.office_contacts[oeex_name].map((e) => Object.assign(new Contact(), e)) : [];
      });
    }
  }
  getStatusColor() {
    return Workbook.STATUS_COLOR[this.getStatus()];
  }
  getStatus() {
    const now = new Date();

    return this.status === Workbook.STATUS_ARCHIVE
      ? Workbook.STATUS_ARCHIVE
      : (!this.dateStart || now < this.dateStart) ? Workbook.STATUS_DRAFT
        : (!this.dateEnd || now <= this.dateEnd) ? Workbook.STATUS_PROGRESS
          : Workbook.STATUS_COMPLETE
  }
}
