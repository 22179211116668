import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {EntitySignable} from '../models';
import {EntityEditableComponent} from './entityEditable.component';

export class EntitySignableComponent extends EntityEditableComponent {

  entity: EntitySignable;
  constructor(
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }

}
