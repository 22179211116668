import {Inject, ChangeDetectorRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {EntityEditDialogComponent} from './entityEditDialog.component';

export class EntityEditScrollDialogComponent extends EntityEditDialogComponent {

  nextInBottom: string = 'out';
  nextInBottomInit: boolean = false;

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<EntityEditScrollDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
  }

  // TODO : duplicate code with create-pp.component & ldcm-dialog
  /**
   *  Init the scroll detection component
   * @param event
   */
  public handleInit(event: any) {
    if (event.hasScrollBar) {
      this.nextInBottom = 'in';
      this.nextInBottomInit = true;
      this._cdRef.detectChanges();
    } else {
      this.nextInBottom = 'out';
      this.nextInBottomInit = false;
    }
  }

  /**
   * Get event scroll positions
   * @param event
   */
  public handleScroll(event: any) {
    if (event.isReachingBottom) {
      this.nextInBottom = 'out';
    }
    if (event.isReachingTop) {
      this.nextInBottom = 'in';
    }
  }

}
