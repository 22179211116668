import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {EntitySecureService} from 'app/shared/services/entitySecure.service';
import {LoadingService} from './loading.service';
import {EntityRole} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends EntitySecureService {
  static LABEL_ONE = "chargement du rôle";
  static LABEL_ALL = "chargement des rôles";
  static LABEL_CHILDREN = "chargement des rôle de l'opération ?";
  static LABEL_SAVE = "sauvegarde du rôle";
  static LABEL_DELETE = "suppression du rôle";

  static ENTITY_TYPE: string = 'config_role';
  static ENTITY_FIELDS: string[] = ['name', 'description'];
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPermissions', 'showRoles'],
    CREATE: ['editPermissions', 'createRole'],
    UPDATE: ['editPermissions', 'editRole'],
    DELETE: ['editPermissions', 'removeRole']
  }

  static ENTITY_URL = 'config/role';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityRole {
    return new EntityRole(data, clone);
  }

  getList(): Observable<any[]> {
    if (this.hasRole('PP_ADMIN_TREPIED')) {
      return super.getList();
    } else {
      return super.getList()
        .pipe(map(list => list
          .filter((r) => (r.name !== 'PP_ADMIN_TREPIED'))));
    }
  }

}
