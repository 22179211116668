import {Component, Input, OnChanges} from '@angular/core';
import {ExtraFieldCommonComponent} from './extraFieldCommon.component'

@Component({
  selector: 'app-extra-value',
  templateUrl: './extraValue.component.html',
  styleUrls: ['./extraValue.component.scss']
})
export class ExtraValueComponent extends ExtraFieldCommonComponent implements OnChanges {

  @Input() showLabel: boolean = true;
  @Input() showAll: boolean = false;

}