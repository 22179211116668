import {Injectable} from '@angular/core';
import {AuthService} from '../auth';
import {LogService} from './log.service';
import {Logger} from './logger.service';
import {LoggerConfigService} from './loggerConfig.service';

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService implements Logger {

  isDebugMode: boolean = false;
  logToDb: boolean = false;
  level: string;

  public currentUser: {given_name, family_name} = {
    given_name: '',
    family_name: ''
  };

  constructor(
    protected _loggerConfigService: LoggerConfigService,
    protected _logService: LogService,
    protected _auth: AuthService
  ) {
    this._loggerConfigService.getSingleOne().subscribe((data) => {
      if (data) {
        this.isDebugMode = data.logging;
        this.level = data.logLevel;
        this.logToDb = data.logDb;
      } else {
        this.isDebugMode = true;
        this.level = 'error';
        this.logToDb = false;
      }
    });
    this._auth.userProfile$
      .subscribe((user) => {
        this.currentUser = {
          given_name: user.user_metadata && user.user_metadata.given_name ? user.user_metadata.given_name : 'Prénom',
          family_name: user.user_metadata && user.user_metadata.family_name ? user.user_metadata.family_name : 'Nom'
        };
      });

  }

  get info() {
    if (this.isDebugMode && this.level === 'info') {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.isDebugMode && this.level === 'warn') {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (this.isDebugMode && this.level === 'error') {
      const e = console.error.bind(console);
      return console.error = function () {
        e.apply(console, arguments);
        const logMessage = arguments[2] ? '[' + arguments[0] + '] ' + arguments[1] + ' ' + arguments[2] : '[' + arguments[0] + '] ' + arguments[1];
        if (this.logToDb) {
          this._logToDb(logMessage);
        }
      }
    } else {
      return noop;
    }
  }

  // invokeConsoleMethod(caller: string, type: string, args?: any): void {
  //   const log = caller ? ['[' + caller + ']'].concat(args) : args;
  //   const logFn: Function = (console)[type] || console.log || noop;
  //   // logFn.apply(console, [args]);
  //   logFn.apply(console, log);
  //   // Save to DB
  //   if (this.logToDb) {
  //     this._logToDb(type, log);
  //   }
  // }

  private _logToDb(errorMsg: string): void {
    this._logService.save(this._logService.getNewEntity({
      user: this.currentUser.given_name + ' ' + this.currentUser.family_name,
      errorMsg: errorMsg
    })).catch((error) => {
      console.error('_logToDb save error', [error, errorMsg]);
    });;
  }
}
