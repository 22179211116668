import {EntityOffice} from './entityOffice.model';

export class EntityExternalCompany extends EntityOffice {

  siret: string;
  internalCode: string;
  workforce_max: number;
  workforce_total: number;
  workforce_volume: number;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.internalCode = (data && data.internalCode) ? '' + data.internalCode : '';
    this.siret = (data && data.siret) ? '' + data.siret : '';
    this.workforce_max = (data && data.workforce_max) ? data.workforce_max : 0;
    this.workforce_total = (data && data.workforce_total) ? data.workforce_total : 0;
    this.workforce_volume = (data && data.workforce_volume) ? data.workforce_volume : 0;
  }
}

