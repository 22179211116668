import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  LoggerService,
  CommonComponent,
  ISignature
} from 'app/shared';
import {
  EntityIcp
} from '../models/entityIcp.model';
import {
  Ldcm
} from '../models/ldcm.model';

@Component({
  selector: 'app-icp-info-export',
  templateUrl: './icpInfoExport.component.html'
})
export class IcpInfoExportComponent extends CommonComponent implements OnChanges {

  @Input() icp: EntityIcp;

  ldcmLocals: Ldcm[] = [];
  ldcmDocuments: Ldcm[] = [];
  ldcmEquipments: Ldcm[] = [];
  ldcmGeneralInst: Ldcm[] = [];
  involved: { oeex: string, name: string, job: string, signature: Date, present: boolean }[] = [];
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes['icp']) {

      if (this.icp.IPLdcm) {
        this.ldcmLocals = this.icp.IPLdcm.filter((l) => l.group === 'locals');
        this.ldcmDocuments = this.icp.IPLdcm.filter((l) => l.group === 'documents');
        this.ldcmEquipments = this.icp.IPLdcm.filter((l) => l.group === 'equipment');
        this.ldcmGeneralInst = this.icp.IPLdcm.filter((l) => l.group === 'generalInst');
      } else {
        this.ldcmLocals = [];
        this.ldcmDocuments = [];
        this.ldcmEquipments = [];
        this.ldcmGeneralInst = [];
      }
      this.involved = [];
      this.icp.iPOEex.forEach((oeex) => {
        let signatures: ISignature[] = [];
        if (oeex.contacts && oeex.contacts.length) {
          signatures = this.icp.signatures.filter((e) => (e.company_id === oeex._id));
        }
        if (oeex.contacts && oeex.contacts.length) {
          oeex.contacts.forEach((c) => {
            let i = signatures.findIndex((e) => (e.contact_id === c.id));
            let signature: ISignature;
            if (i !== -1) {
              signature = signatures.splice(i, 1)[0];
            }
            this.involved.push({
              oeex: oeex.name,
              name: c.name,
              job: c.job,
              signature: signature && signature.signedAt ? new Date(signature.signedAt) : null,
              present: c.isPresent
            });
          });
          signatures.forEach((e) => {
            this.involved.push({
              oeex: oeex.name,
              name: e.name,
              job: e.job,
              signature: e.signedAt ? new Date(e.signedAt) : null,
              present: false
            });
          });
        }
      });

    }
  }
}
