import { Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { EntitySimple } from '../models';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AbstractEntityListComponent } from './abstractEntityList.component';

export class EntitySimpleListComponent extends AbstractEntityListComponent {
  entityList: EntitySimple[] = [];

  dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  // 0 = masqué / numero de ligne si visible
  listHidden: number[] = [];
  value: string = '';
  valueExists: boolean = false;


  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, snackBar, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    this._sortList();
    this.filter(this.value);
  }
  protected _sortList() {
    this.entityList.sort((a, b) => (this.numalphaCompare(a.name, b.name)));
  }
  filter(value: string) {
    value = value.toLowerCase();
    this.valueExists = false;
    if (value) {
      let i = 0;
      this.listHidden = this.entityList.map((e) => {
        const name = e.name.toLowerCase();
        this.valueExists = this.valueExists
          || value === name;
        i++;
        return (name.indexOf(value) === -1) ? 0 : i;
      });
    } else {
      this.listHidden = this.entityList.map((e, index) => index + 1);
    }
  }

  addElement() {
    if (this.value && !this.valueExists) {
      const newEntity = this._entityService.getNewEntity({ name: this.value });
      this._entityService.save(newEntity)
        .then(() => {
          this.value = '';
          this.filter('');
          this.updateElement(newEntity);
        })
        .catch((error) => {
          //this._logger.error('EntitySimpleListComponent', 'unable to save entity', error);
          this._logger.error(this.constructor.name + '(EntitySimpleListComponent)', 'unable to save entity', JSON.stringify(error));
          //console.log('EntitySimpleListComponent save error', [error, newEntity]);
        });
    }

  }


}
