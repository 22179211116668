import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoggerService, PermissionsService, getBase64, EntityFormComponent } from 'app/shared';
import { PinturaConfigService } from 'app/shared/services';
import { EntityPinturaConfig } from 'app/shared/models';

@Component({
  selector: 'app-pintura-config',
  templateUrl: './pinturaConfig.component.html',
  styleUrls: ['./pinturaConfig.component.scss']
})
export class PinturaConfigComponent extends EntityFormComponent {
  autoSave = false;
  entity: EntityPinturaConfig;

  public markupEditorToolStyles: string = '';
  public markupEditorToolbar: string = '';
  public defaultColorOptions: string = '';
  public defaultFontSizeOptions: string = '';
  public defaultFontScaleOptions: string = '';
  public defaultLineHeightOptions: string = '';
  public defaultLineHeightScaleOptions: string = '';
  public defaultStrokeWidthOptions: string = '';
  public defaultStrokeScaleOptions: string = '';
  public defaultLineEndStyleOptions: string = '';
  public defaultFontFamilyOptions: string = '';
  public defaultFontStyleOptions: string = '';
  public defaultTextAlignOptions: string = '';

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: PinturaConfigService

  ) {
    super(dialog, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (this.entity) {
      this.stringifyValue('markupEditorToolStyles');
      this.stringifyValue('markupEditorToolbar');
      this.stringifyValue('defaultColorOptions');
      this.stringifyValue('defaultFontSizeOptions');
      this.stringifyValue('defaultFontScaleOptions');
      this.stringifyValue('defaultLineHeightOptions');
      this.stringifyValue('defaultLineHeightScaleOptions');
      this.stringifyValue('defaultStrokeWidthOptions');
      this.stringifyValue('defaultStrokeScaleOptions');
      this.stringifyValue('defaultLineEndStyleOptions');
      this.stringifyValue('defaultFontFamilyOptions');
      this.stringifyValue('defaultFontStyleOptions');
      this.stringifyValue('defaultTextAlignOptions');
    }
  }
  parseValue(key: string) {
    if (key && this.entity && this[key]) {
      try {
        this.entity[key] = JSON.parse(this[key]);
      } catch (e) {
        this.snackBar.open('Erreur', 'La valeur est incorrecte: ' + JSON.stringify(e), {
          duration: 2000
        });
        if (this.form.controls[key]) {
          this.form.controls[key].setErrors({ 'parseError': 'Valeur incorrecte' });
        }
      }
    }
  }
  stringifyValue(key: string) {
    if (key && this.entity && this.entity[key]) {
      this[key] = this.entity[key] ? JSON.stringify(this.entity[key], null, 2) : '';
    }
  }
}
