import {OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
import {SafeUrl, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle} from '@angular/platform-browser';
import {Observable, Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ErrorDialogComponent, EntitySignable, formattedDate} from 'app/shared';
import {Workbook} from './workbook.model'

import {
  CommonComponent,
  LoggerService,
  SignableService
} from 'app/shared';

export abstract class CommonSignableComponent extends CommonComponent implements OnDestroy {
  workbook: Workbook;
  userId: string = '';
  errorMessage: string = '';
  infoMessage: string = '';
  name: string = 'document';
  _apiSubscription: Subscription;
  _routeSubscription: Subscription;
  loaded: boolean = false;
  constructor(
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_logger);
    this._spinnerService.show();
    this.userId = this._signableService.getUserId();
    //preload
    this._routeSubscription = this._route.params.subscribe(params => {
      this._initFromRouteParams(params);
    });
  }

  protected abstract _init(data);
  protected abstract _apiCall(data?): Observable<any>;

  protected _initError(data) {
    this.errorMessage = data['error'];
  }
  protected _initFromRouteParams(params) {
    if (this._apiSubscription) {
      this._apiSubscription.unsubscribe();
    }
    this._apiSubscription = this._apiCall(params).subscribe((data) => {
      if (data) {
        if (!data['error']) {
          this._init(data);
        } else {
          this._initError(data);
        }
        this.loaded = true;
        this._spinnerService.hide();
      }
    }, (error) => {
      this.errorMessage = error['message'];
      if (error['workbook']) {
        this.workbook = error['workbook'];
      }
      this.loaded = true;
      this._spinnerService.hide();
    });
  }
  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._apiSubscription) {
      this._apiSubscription.unsubscribe();
    }
  }


  public getDocument(doc, template: boolean = false): void {
    this._spinnerService.show();
    (this.workbook ? this._signableService.getWorkbookDocument(this.workbook._id, doc._id, template)
      : this._signableService.getDocument(doc._id, template))
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = (template ? doc.template_name : ((res.type && res.type === 'application/octet-stream') ? doc.displayName : doc.name));
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this._spinnerService.hide();
        },
        (err) => {
          this._logger.error('SignableComponent', 'download error', JSON.stringify(err));
          this._spinnerService.hide();
          this._displayError('Une erreur s\'est produite lors du téléchargement du document, veuillez réessayer et sinon contacter le support.');
        }
      );
  }
  public getDocumentById(doc_id): void {
    this._spinnerService.show();
    this._signableService.getDocument(doc_id).subscribe(
      (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('SignableComponent', 'download error', JSON.stringify(err));
        this._spinnerService.hide();
        this._displayError('Une erreur s\'est produite lors du téléchargement du document, ' + ((err && err.status && err.status === 403) ? 'votre utilisateur n\'a pas accès à cette opération' : 'veuillez réessayer et sinon contacter le support.'));
      }
    );
  }
  public getCheckpointDocumentById(doc_id: string, audit_id: string): void {
    this._spinnerService.show();
    this._signableService.getCheckpointDocument(doc_id, audit_id).subscribe(
      (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('SignableComponent', 'download checkpoint error', JSON.stringify(err));
        this._spinnerService.hide();
        this._displayError('Une erreur s\'est produite lors du téléchargement du document(VC), ' + ((err && err.status && err.status === 403) ? 'votre utilisateur n\'a pas accès à cette opération' : 'veuillez réessayer et sinon contacter le support.'));
      }
    );
  }

  public getWorkbookDocumentById(workbook_id, doc_id): void {
    this._spinnerService.show();
    this._signableService.getWorkbookDocument(workbook_id, doc_id).subscribe(
      (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
        this._spinnerService.hide();
      },
      (err) => {
        this._logger.error('SignableComponent', 'download error', JSON.stringify(err));
        this._spinnerService.hide();
        this._displayError('Une erreur s\'est produite lors du téléchargement du document, ' + ((err && err.status && err.status === 403) ? 'votre utilisateur n\'a pas accès à cette opération' : 'veuillez réessayer et sinon contacter le support.'));
      }
    );
  }
  protected _displayError(msg: string) {
    this._spinnerService.hide();
    const dialogRefErrDelete: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
      disableClose: false,
      autoFocus: true,
      width: '600px',
    });
    dialogRefErrDelete.componentInstance.errorMessage = msg;
    dialogRefErrDelete.componentInstance.buttonMessage = 'Fermer';
  }
  getName(signable: EntitySignable) {
    let name = signable._id;
    if (signable.documentType === 'pdp') {
      name = "Edition " + signable.code + " du plan de prévention";// + this.name;
    } else if (signable.documentType === 'icp') {
      name = "Inspection " + (signable['name'] ? (signable['name'] + " ") : "") + "du " + formattedDate(signable['iPDate']);
    } else if (signable.documentType === 'sfp') {
      name = "Permis feu " + (signable['name'] ? (signable['name'] + " ") : "") + "du " + formattedDate(signable['dateStart']) + ((signable['dateStart'] !== signable['dateEnd']) ? ' au ' + formattedDate(signable['dateEnd']) : '');
    } else if (signable.documentType === 'sps') {
      name = "Protocole " + (signable['name'] ? (signable['name'] + " ") : "") + "du " + formattedDate(signable['dateStart']) + ((signable['dateStart'] !== signable['dateEnd']) ? ' au ' + formattedDate(signable['dateEnd']) : '');
    } else if (signable.documentType === 'audit') {
      name = "Visite de contrôle " + (signable['name'] ? (signable['name'] + " ") : "") + "du " + formattedDate(signable['date']);
    } else if (signable.documentType === 'spt') {
      name = (signable['permitContent'] && signable['permitContent']['title'] ? signable['permitContent']['title'] : "Permis") + (signable['name'] ? (" " + signable['name']) : "") + " du " + formattedDate(signable['dateStart']) + ((signable['dateStart'] !== signable['dateEnd']) ? ' au ' + formattedDate(signable['dateEnd']) : '');
    }
    return name;

  }
  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this._signableService.bypassSecurityTrustUrl(value);
  }
  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this._signableService.bypassSecurityTrustHtml(value);
  }
  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this._signableService.bypassSecurityTrustStyle(value);
  }
  bypassSecurityTrustScript(value: string): SafeScript {
    return this._signableService.bypassSecurityTrustScript(value);
  }
  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this._signableService.bypassSecurityTrustResourceUrl(value);
  }
}
