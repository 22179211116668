import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {ConfigEmailService} from 'app/shared/services';
import {EntityConfigEmail} from 'app/shared/models';

@Component({
  selector: 'app-config-restriction-email',
  templateUrl: './restrictionEmail.component.html',
  styleUrls: ['./restrictionEmail.component.scss']
})
export class ConfigRestrictionEmailComponent extends EntityFormComponent {

  entity: EntityConfigEmail;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: ConfigEmailService

  ) {
    super(dialog, _logger, _entityService);
  }

}
