import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MatAutocompleteSelectedEvent, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {comparableString, Contact, IUser, ConfigEmailService, EntityConfigEmail} from 'app/shared';
import {EntityOffice, OfficeService} from 'app/workbook-core';


@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookContactDialogComponent implements OnDestroy {

  public contactId: string;
  public contact: Contact;
  protected _repoOfficeSubscription: Subscription;
  public contactList: Contact[] = [];
  public selectableContacts: Contact[] = [];

  public usersList: IUser[];
  public emails: string[];
  linkAnyUser: boolean;
  autocompleteoff: string;
  protected _configEmailSubscription: Subscription;
  public regexEmail: string;
  public regexEmailInvalid: string;
  public msgEmail: string;
  public internal: boolean;
  constructor(
    protected _configEmailService: ConfigEmailService,
    public dialogRef: MatDialogRef<WorkbookContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _officeService: OfficeService
  ) {
    this.internal = !!data['internal'];
    this._configEmailSubscription = this._configEmailService.getSingleOne().subscribe((ce: EntityConfigEmail) => {
      if (ce) {
        this.regexEmail = this.internal ? ce.regexInternal : ce.regexExternal;
        this.msgEmail = this.internal ? ce.msgInternal : ce.msgExternal;
        this.regexEmailInvalid = EntityConfigEmail.EMAIL_REGEX;
      }
    });

    this.autocompleteoff = (new Date()).getTime() + '-';
    this.contact = data['contact'] ? data['contact'] : new Contact();
    this.contactId = this.contact.id;
    this.usersList = data['usersList']
      ? data['usersList'].sort((a, b) => {
        let _a = '';
        if (a && a.user_metadata && (a.user_metadata.given_name || a.user_metadata.family_name)) {
          _a = a.user_metadata.given_name ? a.user_metadata.given_name + ' ' : ''
          _a += a.user_metadata.family_name ? a.user_metadata.family_name : ''
        } else {
          _a = a.email;
        }
        let _b = '';
        if (b && b.user_metadata && (b.user_metadata.given_name || b.user_metadata.family_name)) {
          _b = b.user_metadata.given_name ? b.user_metadata.given_name + ' ' : ''
          _b += b.user_metadata.family_name ? b.user_metadata.family_name : ''
        } else {
          _b = b.email;
        }
        return (_a.toLowerCase().localeCompare(_b.toLowerCase()))
      })
      : [];
    if (data['repo_id']) {
      this._repoOfficeSubscription = this._officeService.get(data['repo_id']).subscribe((office: EntityOffice) => {
        this.contactList = office && office.contacts
          ? ((data['selected_emails'] && data['selected_emails'].length)
            ? office.contacts.filter((e) => {
              // console.log(data['selected_emails'].indexOf(e.email));
              return (data['selected_emails'].indexOf(e.email) === -1)
            })
            : office.contacts)
          : [];
        this.refreshSelectableContacts();
      });
    }

    this.linkAnyUser = this._officeService.hasPermission('linkAnyUser');

    this.linkAnyUser = this._officeService.hasPermission('linkAnyUser');
    if (this.contact && this.contact.user_id && this.usersList.findIndex((e) => (e['user_id'] === this.contact.user_id)) == -1) {
      this.onEmailChange();
    } else {
      this.refreshEmails();
    }
  }
  ngOnDestroy() {
    if (this._configEmailSubscription) {
      this._configEmailSubscription.unsubscribe();
    }
  }
  refreshEmails() {
    const _emails = [];
    this.usersList.forEach((u) => {
      if (u.email
        && (!this.contact.email
          || u.email.toLowerCase().indexOf(this.contact.email.toLowerCase()) !== -1)
      ) {
        _emails.push(u.email);
      }
    })
    this.emails = _emails.sort();
  }

  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.contact);
    }
  }
  //soit le contact selectionné, soit le name affiché...
  displayName(c): string {
    return c ? (c['name'] ? c['name'] : c) : '';
  }
  updateContact(event: MatAutocompleteSelectedEvent = null): void {

    if (event && event.option && event.option.value) {
      this.contact.id = event.option.value['id'];
      this.contact.repo_id = event.option.value['id'];
      this.contact.user_id = event.option.value['user_id'];
      this.contact.name = event.option.value['name'];
      this.contact.email = event.option.value['email'];
      this.contact.phone = event.option.value['phone'];
      this.contact.fax = event.option.value['fax'];
      this.contact.job = event.option.value['job'];
      this.contact.signatory = event.option.value['signatory'];
      this.contact.signatoryPdP = event.option.value['signatoryPdP'];
      this.contact.signatoryPermit = event.option.value['signatoryPermit'];
      this.contact.chsct = event.option.value['chsct'];
    }
  }

  refreshSelectableContacts() {
    //eviter le premier appel de l'autocomplete qui passe contact dans name...
    if (this.contact && this.contactList) {
      setTimeout(() => {
        this.selectableContacts = this.contactList.filter((c: Contact) => {
          if (this.contact && this.contact.name && c && c.name) {
            return (
              // non filtré par name
              (!this.contact.name || comparableString(c.name).indexOf(comparableString(this.contact.name)) !== -1)
              // non filtré par job
              && (!this.contact.job || comparableString(c.job).indexOf(comparableString(this.contact.job)) !== -1)
              // non filtré par phone
              && (!this.contact.phone || c.phone.indexOf(this.contact.phone) !== -1)
              // non filtré par fax
              //&& (!this.contact.fax || c.fax.indexOf(this.contact.fax) !== -1)
              // non filtré par email
              && (!this.contact.email || comparableString(c.email).indexOf(comparableString(this.contact.email)) !== -1)
            );
          } else {
            return true;
          }
        }
        );
      });
    }
  }
  onUserChange() {
    if (!this.contact.email && this.contact.user_id) {
      const user = this.usersList.find(e => (e['user_id'] === this.contact.user_id));
      if (user && user['email']) {
        this.contact.email = user['email'];
      }
    }
  }
  onEmailChange() {
    if (this.contact.email && (!this.contact.user_id || !this.linkAnyUser)) {
      const user = this.usersList.find((e) => (e['email'].toLowerCase() === this.contact.email.toLowerCase()));
      if (user) {
        this.contact.user_id = user['user_id'];
      } else if (!this.linkAnyUser) {
        this.contact.user_id = '';
      }
    }
    this.refreshEmails();
  }
  getEmailError() {
    if (this.contact && this.contact.email) {
      let reg = this.regexEmailInvalid;
      const regex = RegExp(reg);
      let res = regex.exec(this.contact.email);
      if (res && res.length) {
        return this.msgEmail;
      } else {
        return EntityConfigEmail.EMAIL_INVALID;
      }
    }
  }
}
