import {SafeUrl, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle} from '@angular/platform-browser';
import {DbCommonService, PermissionsService} from 'app/shared';
import {LoadingService} from './loading.service';
import {EntityService} from './entity.service';

export abstract class EntitySecureService extends EntityService {

  // droits:
  static ENTITY_PERMISSION = {
    READ: [],
    CREATE: [],
    UPDATE: [],
    DELETE: []
  }

  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _loadingService);
  }
  pauseReplication() {
    this._dbService.pause();
  }
  unpauseReplication() {
    this._dbService.unpause();
  }

  public refreshUserList() {
    return this._permissionsService.auth.refreshUsersList();
  }
  public getUserList() {
    return this._permissionsService.auth.getUsers();
  }

  public getProfile() {
    return this._permissionsService.getProfile();
  }
  refreshProfile() {
    return this._permissionsService.auth.retrieveProfile();
  }
  renewToken() {
    this._permissionsService.auth.renewToken();
  }
  /**
   * Get the user id
   */
  getUserId() {
    return this._permissionsService.getUserId();
  }
  getUserEmail() {
    return this._permissionsService.getUserEmail();
  }
  getUserOffices() {
    return this._permissionsService.getSyncFilter();
  }
  /**
   * Get the user name
   */
  getUserName() {
    return this._permissionsService.getUserName();
    /*
    const u = this.getProfile();
    let name = u.name ? u.name : u.email;
    if (u.user_metadata && (u.user_metadata.given_name || u.user_metadata.family_name)) {
      name = (u.user_metadata.given_name ? u.user_metadata.given_name : '')
        + ((u.user_metadata.given_name && u.user_metadata.family_name) ? ' ' : '')
        + (u.user_metadata.family_name ? u.user_metadata.family_name : '')
    }
    return name;
    */
  }
  hasRole(role: string) {
    return this._permissionsService.hasRole(role);
  }
  hasPermission(right: string) {
    return this._permissionsService.hasPermission(right);
  }
  getPermissionsRead(): string[] {
    return (<typeof EntitySecureService> this.constructor).ENTITY_PERMISSION.READ;
  }
  getPermissionsCreate(): string[] {
    return (<typeof EntitySecureService> this.constructor).ENTITY_PERMISSION.CREATE;
  }
  getPermissionsUpdate(): string[] {
    return (<typeof EntitySecureService> this.constructor).ENTITY_PERMISSION.UPDATE;
  }
  getPermissionsDelete(): string[] {
    return (<typeof EntitySecureService> this.constructor).ENTITY_PERMISSION.DELETE;
  }
  hasReadPermission(): boolean {
    return this._permissionsService.hasOnePermission(this.getPermissionsRead());
  }
  hasCreatePermission(): boolean {
    return this._permissionsService.hasOnePermission(this.getPermissionsCreate());
  }
  hasUpdatePermission(): boolean {
    return this._permissionsService.hasOnePermission(this.getPermissionsUpdate());
  }
  hasDeletePermission(): boolean {
    return this._permissionsService.hasOnePermission(this.getPermissionsDelete());
  }

  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this._permissionsService.bypassSecurityTrustUrl(value);
  }
  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this._permissionsService.bypassSecurityTrustHtml(value);
  }
  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this._permissionsService.bypassSecurityTrustStyle(value);
  }
  bypassSecurityTrustScript(value: string): SafeScript {
    return this._permissionsService.bypassSecurityTrustScript(value);
  }
  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this._permissionsService.bypassSecurityTrustResourceUrl(value);
  }
}
