import {Component, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent {

  public popupMessage: string;
  public popupTitle: string = 'Information';
  public popupButton: string = 'OK';
  @ViewChild('dataContainer') dataContainer: ElementRef;

  constructor(public dialogRef: MatDialogRef<PopupDialogComponent>) {
  }
}
