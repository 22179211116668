import {EntityCompany} from './entityCompany.model';


export class EntityExploitingCompany extends EntityCompany {
  public logo: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.logo = (data && data.logo) ? data.logo : '';
  }
}
