import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, Contact} from 'app/shared';
import {PreventionPlanService, EntityIcp} from 'app/workbook-core';

@Component({
  selector: 'app-icp-contacts-dialog',
  templateUrl: './icpContacts-dialog.component.html',
  styleUrls: ['./icpContacts-dialog.component.scss']
})
export class IcpContactsDialogComponent implements OnDestroy {

  icp: EntityIcp;
  OEex: any[] = [];
  currentOEex: any[] = [];
  contactIdInICP: string[] = [];
  contactsSelectedList: Contact[] = [];
  contactChecked: Contact[] = [];
  oeexSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<IcpContactsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    protected _workbookService: PreventionPlanService) {
    this.icp = this.data['icp'];
    const contactIdInICP = [];
    if (this.icp.iPOEex && this.icp.iPOEex.length) {
      this.icp.iPOEex.forEach((o) => {
        if (o.contacts && o.contacts) {
          o.contacts.forEach((c) => {
            if (c.id && c.name) {
              contactIdInICP.push(c.id);
            }
          });
        }
      });
    }

    this.oeexSubscription = this._workbookService.getOEexList(this.icp.parent_id).subscribe((OEex) => {
      this.currentOEex = OEex;
      this.OEex = OEex.map((o) => ({
        name: o.name,
        contacts: o.contacts.filter((c) => (contactIdInICP.indexOf(c.id) === -1 && c.name))
      })).filter((_o) => (_o.contacts.length > 0));
    });
  }

  ngOnDestroy() {
    if (this.oeexSubscription) {
      this.oeexSubscription.unsubscribe();
    }
  }

  /**
   * check if contact in Eex is in ICP
   * @param id
   */
  contactInICP(id: string): boolean {
    return this.contactIdInICP.indexOf(id) !== -1;
  }

  /**
   * Add / remove contact in list
   * @param event
   */
  onSelectContacts(event) {
    if (event.option.selected) {
      this.contactsSelectedList.push(event.option.value);
    } else {
      const removeIndex = this.contactsSelectedList.findIndex(icp => icp.id === event.option.value.id);
      this.contactsSelectedList.splice(removeIndex, 1);
    }
  }

  onSubmit(form) {

    this.dialogRef.close(this.contactsSelectedList);
  }
  isSelected(contact) {
    return (this.contactsSelectedList.indexOf(contact) !== -1);
  }

  selectAll() {
    this.contactsSelectedList = [];
    this.OEex.forEach((eex) => {
      eex.contacts.forEach((c) => {
        this.contactsSelectedList.push(c);
      });
    });
  }
  unselectAll() {
    this.contactsSelectedList = [];
  }
}
