import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  LoadingService, EmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService, EntityCompany
} from 'app/shared';
import {
  PreventionPlanService,
  DocumentationService,
  SecurityProtocolService,
  EntitySecurityProtocol,
  SecurityProtocolExportComponent
} from 'app/workbook-core';
import { AbstractSignableDialogComponent } from '../abstractSignable-dialog.component';


@Component({
  selector: 'app-signable-securityProtocol-dialog',
  templateUrl: './securityProtocol-dialog.component.html',
  styleUrls: ['./securityProtocol-dialog.component.scss']
})
export class SecurityProtocolDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  static FLAG_SUFFIX: string = 'ps';
  SIGNATORY_RIGHT: string = 'signatoryPdP';

  SIGNING_NOTIFY = EmailMessages.SIGN_SPS_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_SPS;

  entity: EntitySecurityProtocol;
  entityForm: SecurityProtocolExportComponent;

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SecurityProtocolDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: SecurityProtocolService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
  }

  addSpecifictEmailRecipient(entity: EntitySecurityProtocol) {
    if (entity.originator && entity.originatorContact
      && this.usersList.findIndex((u) => (
        u.name === entity.originator.name
        && u.email === entity.originatorContact.email
        && u.company === entity.originator.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.originator, entity.originatorContact, true));
    }
    if (entity.performer && entity.performerContact
      && this.usersList.findIndex((u) => (
        u.name === entity.performer.name
        && u.email === entity.performerContact.email
        && u.company === entity.performer.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.performer, entity.performerContact, true));
    }
  }

  getDefaultEmailSubject() {
    return ('Protocole de sécurité' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? (' ' + this.entityForm.entity.name) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }

  _getSignatoriesOffice() {
    let signatories = [];
    if (this.entityForm && this.entityForm.originatorSignup) {
      signatories = this.entityForm.originatorSignup;
    }
    return signatories;
  }
  _getSignatoriesEex() {
    let signatories = [];
    if (this.entityForm && this.entityForm.performerSignup) {
      signatories = this.entityForm.performerSignup;
    }
    return signatories;
  }
  getUnsigned(): EntityCompany[] {
    const unsigned: EntityCompany[] = [];
    if (this.entityForm && this.entityForm.entity) {
      if (this.entityForm.entity.originator && this.entityForm.entity.originator.name
        && this.entityForm.entity.originatorContact && this.entityForm.entity.originatorContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.originator._id
          && s.contact_id === this.entityForm.entity.originatorContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.originator);
      }
      if (this.entityForm.entity.performer && this.entityForm.entity.performer.name
        && this.entityForm.entity.performerContact && this.entityForm.entity.performerContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.performer._id
          && s.contact_id === this.entityForm.entity.performerContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.performer);
      }
    }
    return unsigned;
  }
}
