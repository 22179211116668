import {Injectable, } from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {map, first, mergeMap, last} from 'rxjs/operators';
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';
import SimpleCryptor from 'simple-cryptor-pouch';
// import MemoryPouchPlugin from 'pouchdb-adapter-memory';
//PouchDB.plugin(PouchDB.IndexedDB)
//PouchDB.plugin(require('pouchdb-adapter-indexeddb').default);
PouchDB.plugin(SimpleCryptor);
PouchDB.plugin(PouchFind);
// PouchDB.plugin(MemoryPouchPlugin);


@Injectable()
export class DbReadService {

  static REMOTEDB_PREFIX = 'remoteDB';

  remoteDB: ReplaySubject<PouchDB> = new ReplaySubject(1);
  protected dbPrefix: string;
  protected dbName: string;

  info_count: number = 0;
  info_count_del: number = 0;
  info_size: number = 0;

  constructor() {
    this.dbPrefix = (<typeof DbReadService> this.constructor).REMOTEDB_PREFIX;
  }

  connect(db) {
    this._initRemoteDB(db);
  }

  /**
   * Get data by id of object
   * @param id
   * @param docConflicts
   * @param docRev
   * @param lastVersion
   */
  get(id, docConflicts = true, docRev = '', lastVersion = false) {
    return this._get(id, '', docConflicts, docRev, lastVersion);
  }
  bulkGet(id, docRev = '') {
    return this._bulkGet(id, '', docRev);
  }

  /**
   * Get data list(docfields) by documentType
   * @param docType
   * @param docFields
   */
  getList(docType: string[], docFields: string[]) {
    return this._getList('', docType, docFields);
  }
  /**
   * Get data list(docfields) by documentType
   * @param docType
   * @param docFields
   */
  getChildList(parent_id: string, docType: string[], docFields: string[]) {
    return this._getList(parent_id, docType, docFields);
  }
  /**
   * Get data single by documentType
   * @param docType
   */
  getSingle(docType: string) {
    return this._getSingle(docType);
  }
  /**
   * Get document attached by id of document and document name
   * @param id
   * @param attachment
   */
  getAttachment(id, attachment) {
    return this._getAttachment(id, '', attachment);
  }

  /**
   * Find last object
   * @param query
   */
  findLast(query) {
    return this._findLast(query);
  }

  /**
   * Find data objects
   * @param query
   */
  find(query) {
    return this._find(query);
  }

  protected _getDbPrefix() {
    return '';
  }
  /**
     * Initialize the Db connection with host
     * @protected
     */
  getDB(db, adapter = 'idb'): PouchDB {
    if (db && db['name']) {
      let params = {};
      let domain = '';
      if (db['host']) {
        // TODO: gestion schema (http/https) ?
        domain = 'https://' + db['host'] + '/';
        // remote DB
        params = {
          skip_setup: true,
          fetch: function (url, opts) {
            //if (url.indexOf('_changes?style=all_docs') !== -1) {
            url = url.replace('_changes?style=all_docs', '_changes?style=main_only');
            //  console.log('Fetch : ' + url, opts);
            //}
            return PouchDB.fetch(url, opts);
          }
        };
        if (db['key'] && db['password']) {
          // remote DB with authentication
          params['auth'] = {
            username: db['key'],
            password: db['password']
          };
        }
      } else {
        // local DB
        params = {
          adapter: adapter,
          revs_limit: 2,
          //purged_infos_limit: 2,
          auto_compaction: true
        };
      }
      return new PouchDB(domain + this._getDbPrefix() + db['name'], params);
    }
  }

  /**
   * Initialize the Db connection with host
   * @protected
   */
  protected _initRemoteDB(db, dbSuffix: string = '') {
    const cdb = this.getDB(db);
    cdb.info().then((res) => {
      if (res) {
        if (res.doc_count) {
          this.info_count = res.doc_count;
        }
        if (res.doc_del_count) {
          this.info_count_del = res.doc_del_count;
        }
        if (res.sizes && res.sizes.file) {
          this.info_size = res.sizes.file;
        }
      }
    });
    (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).next(cdb);
  }

  /**
   * Get data by id of object
   * @param id
   * @param dbSuffix
   * @param docConflicts
   * @param docRev
   * @param lastVersion
   */
  protected _get(id, dbSuffix: string = '', docConflicts, docRev, lastVersion) {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => db.get(id, {conflicts: docConflicts, rev: docRev, latest: lastVersion})));
  }

  protected _bulkGet(id, dbSuffix: string = '', docRev) {
    // console.log('db.bulkGet(' + id + ':' + docRev + ')');
    return (this[(<typeof DbReadService> this.constructor).REMOTEDB_PREFIX + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => db.bulkGet({docs: [{id: id, rev: docRev}]}))
      // pour debug

      , map((res: any) => {
        //  console.log('db.bulkGet(' + id + ':' + docRev + ') = ', res);
        if (res
          && res.results
          && res.results.length
          && res.results[0]
          && res.results[0].docs
          && res.results[0].docs.length
          && res.results[0].docs[0].ok) {
          return res.results[0].docs[0].ok;
        }
        else return {};
      })

    );
  }
  /**
   * Get data list(docfields) by documentType
   * @param docType
   * @param docFields
   */
  protected _getList(parent_id: string, docType: string[], docFields: string[], dbSuffix: string = '') {
    const selector = !parent_id
      ? {
        //        $or: [
        //          {
        //            parent_id: {$exists: false},
        //            documentType: (docType.length === 1 ? docType[0] : {$in: docType})
        //          },
        //          {
        "$and": [
          {"parent_id": ''},
          {"documentType": (docType.length === 1 ? docType[0] : {$in: docType})}
        ]
        //          },
        //          {
        //            parent_id: null,
        //            documentType: (docType.length === 1 ? docType[0] : {$in: docType})
        //          }
        //        ]
      }
      : {
        "$and": [
          {"parent_id": ((parent_id === 'ALL_CHILDREN') ? {$gt: ''} : parent_id)},
          {"documentType": (docType.length === 1 ? docType[0] : {$in: docType})}
        ]
      };
    return this._find({
      selector: selector,
      fields: docFields
    }, dbSuffix);
  }
  /**
     * Get data list(docfields) by documentType
     * @param docType
     * @param docFields
     */
  protected _getSingle(docType: string, dbSuffix: string = '') {
    return this._findSingle({
      selector: {
        "$and": [
          {"parent_id": ''},
          {"documentType": docType}
        ]
      }
    }, dbSuffix);
  }

  /**
   * Get document attached in a couchDB document
   * @param id
   * @param dbSuffix
   * @param attachment
   * @private
   */
  protected _getAttachment(id, dbSuffix: string = '', attachment) {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        return db.getAttachment(id, attachment);
      }));
  }



  /**
   * Find last object
   * @param query
   * @param dbSuffix
   */
  protected _findLast(query, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(last(),
      mergeMap((db: PouchDB) => {
        return db.find(query)
      }));
  }

  /**
   * Find data objects
   * @param query
   * @param dbSuffix
   */
  protected _find(query, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        //        console.log('query:', query);
        return db.find(query)
      }),
      map((res: any) => {
        if (res && res.warning) {
          console.warn('db._find:', [query, res]);
        }
        return res.docs;
      }));
  }

  /**
   * Find data objects
   * @param query
   * @param dbSuffix
   */
  protected _findSingle(query, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        return db.find(query)
      }),
      map((res: any) => {
        if (res && res.warning) {
          console.warn('db._findSingle:', [query, res]);
        }
        return (res && res.docs && res.docs.length) ? res.docs[0] : null;
      }));
  }
}
