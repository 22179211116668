import {Injectable} from '@angular/core';
import {DbDocsService} from '../db';
import {EntityBaseService} from '../services';
import {DocumentUploaded, DocumentMetadata} from './documentUploaded.model';

@Injectable()
export class DocumentUploadedService extends EntityBaseService {

  constructor(protected _db: DbDocsService) {
    super(_db);
  }

  /**
   * Save the document uploaded in couchDB
   * @param document
   */
  // TODO => save
  public saveDocLocal(document: DocumentUploaded) {
    return this._db.save(document)
      .toPromise() as Promise<DocumentUploaded>;
  };

  /**
   * Get the doc from PouchDB by it id
   * @param document_id
   * @returns {Promise<DocumentUploaded>}
   */
  // TODO => get
  public getDocLocal(document_id): Promise<DocumentUploaded> {
    return this._db.get(document_id)
      .toPromise() as Promise<DocumentUploaded>;
  }

  /**
   * Delete doc Object in PouchDB
   * @param document
   * @returns {Promise<any>}
   */
  // TODO => remove
  public removeDocLocal(document: DocumentUploaded) {
    return this._db.remove(document)
      .toPromise();
  }

  /**
   * Get list of documents uploaded and create new icons list
   * @private
   */
  public _iconDocumentsUploaded(mime: string): string {
    let icon = '';
    switch (mime) {
      case 'application/msword':
        icon = 'file-word';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'file-word';
        break;
      case 'application/vnd.ms-powerpoint':
        icon = 'file-powerpoint';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        icon = 'file';
        break;
      case 'application/pdf':
        icon = 'file-pdf';
        break;
      case 'image/png':
        icon = 'file-image';
        break;
      case 'image/gif':
        icon = 'file-image';
        break;
      case 'image/jpg':
        icon = 'file-image';
        break;
      case 'image/jpeg':
        icon = 'file-image';
        break;
      default: // generic icon
        icon = 'file-alt';
        break;
    }
    return icon;
  }

  /**
   * save attachment
   * @param documentUploaded
   * @param documentMetadata
   * @param file
   */
  public saveAttachment(documentUploaded: DocumentUploaded, documentMetadata: DocumentMetadata, file: File) {
    return this._db.saveAttachment(documentUploaded._id, documentUploaded._rev, file.name, documentMetadata.mime, file)
      .toPromise() as Promise<any>;
  }

  /**
   * get attachment
   * @param document_id
   * @param attachment
   */
  public getAttachment(document_id: string, attachment: string) {
    return this._db.getAttachment(document_id, attachment)
      .toPromise() as Promise<any>;
  }

  /**
   * remove attachment
   * @param documentUploaded
   * @param attachment
   */
  public removeAttachment(documentUploaded: DocumentUploaded, attachment: string) {
    return this._db.removeAttachment(documentUploaded._id, documentUploaded._rev, attachment)
      .toPromise();
  }
}
