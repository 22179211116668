import {Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, EntitySignableService, EntitySignable, EntityOwnedFormComponent, DocumentMetadataService, EntityDocumentMetadata} from 'app/shared';
import {PreventionPlanService, EntityWorkbook, DocumentationService} from 'app/workbook-core'


export class AbstractSignableInfoComponent extends EntityOwnedFormComponent {
  static FLAG_SUFFIX: string;
  autoSave = false;
  @Input() workbook: EntityWorkbook;
  public entity: EntitySignable;

  documentationToAdd: string[] = [];
  protected _documentationSubscription: Subscription;

  withDocument: boolean = false;
  protected _documentsSubscription: Subscription;
  documents: {[item: string]: EntityDocumentMetadata[]} = {};

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntitySignableService
  ) {
    super(dialog, _logger, _entityService);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._documentsSubscription) {
      this._documentsSubscription.unsubscribe();
    }
    if (this._documentationSubscription) {
      this._documentationSubscription.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.workbook && this.entity) {
      if (!this._documentsSubscription) {
        this._documentationSubscription = this._documentationService.getAll().subscribe((documentations) => {
          if (documentations) {
            //this.documentations = documentations;
            const suffix = (<typeof AbstractSignableInfoComponent> this.constructor).FLAG_SUFFIX;
            if (this.workbook && suffix) {
              const flag = (this.workbook.type === 'annual'
                ? (this.workbook.simple ? 'ps' : 'op')
                : 'oo')
                + '_'
                + suffix;
              this.documentationToAdd = documentations
                .filter((d) => (d[flag] && d['meta']))
                .map((d) => (d['meta']['_id']));
              this._addDocumentations();
            }
          }
        });
      }
      if (this._documentsSubscription) {
        this._documentsSubscription.unsubscribe();
      }
      this._documentsSubscription = this._workbookService.getDocumentMetaDataList(this.entity.parent_id).subscribe((list) => {
        if (list) {
          this.withDocument = list.length > 0;
          this.documents['Annexes'] = list.filter((e) => (this.workbook && e.context && e.context.indexOf(this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id, 'document')) === 1));
          this._addDocumentations();
        }
      });
    }
  }
  protected _addDocumentations() {
    if (this.entity && !this.entity._id && this.documentationToAdd.length && this.documents['Annexes'] && this.documents['Annexes'].length) {
      this.documents['Annexes']
        .filter((e) => (!!e.repo_id && this.documentationToAdd.indexOf(e.repo_id) !== -1))
        .forEach((e) => {
          if (this.entity.documents.findIndex((x) => (e._id === x._id)) === -1) {
            this.entity.documents.push(e);
          }
        });
    }
  }

  isAddedDocumentation(id) {
    return this.entity.documents.findIndex((e) => (e._id === id && !!e.repo_id && this.documentationToAdd.indexOf(e.repo_id) !== -1)) !== -1;
  }

  selectedDoc(id) {
    return this.entity.documents.findIndex((e) => (e._id === id)) !== -1;
  }

}
