import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySingleService, LoadingService} from 'app/shared';
import {EntityDefaultVisibility} from '../models/entityDefaultVisibility.model';

@Injectable({
  providedIn: 'root'
})
export class DefaultVisibilityService extends EntitySingleService {

  //autoInit = false;

  static LABEL_ONE = "chargement du paramètrage de la visibilité";
  static LABEL_ALL = "chargement des paramètrages de la visibilité ?";
  static LABEL_CHILDREN = "chargement des paramètrages de la visibilité de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètrage de la visibilité";
  static LABEL_DELETE = "suppression du paramètrage de la visibilité ?";

  //static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'repo_visibility';
  //static ENTITY_FIELDS: string[] = [];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showDefaultVisibility'],
    CREATE: ['editDefaultVisibility'],
    UPDATE: ['editDefaultVisibility'],
    DELETE: ['editDefaultVisibility']
  }

  static ENTITY_URL = 'repository/visibility';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityDefaultVisibility {
    return new EntityDefaultVisibility(data, clone);
  }

}
