import {Component, Inject, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialogRef} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll, LoggerService, EntityDocumentMetadata, EntityComment, Contact, Operator} from 'app/shared';
import {
  PreventionPlanService,
  EntityWorkbook,
  EntityStep, EntityActivity, EntityWorkbookMeasure, EntitySite, EntityZone,
  EntityOffice, EntityExternalCompany,
  OfficeService, ExternalCompanyService, SiteService,
  WorkbookOfficeService, WorkbookExternalCompanyService
} from 'app/workbook-core';
import {AbstractWorkbookDialogComponent} from '../abstractWorkbook-dialog.component';

@Component({
  selector: 'app-workbook-duplicate',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookDuplicateDialogComponent extends AbstractWorkbookDialogComponent {


  public entity: EntityWorkbook;

  public keepDocuments: boolean = false;
  public documents: EntityComment[] = [];
  public keepComments: boolean = false;
  public comments: EntityDocumentMetadata[] = [];
  public keepTemplates: boolean = true;
  public keepSteps: boolean = true;
  clonedSteps: EntityStep[] = [];

  public officeUsers: string[] = [];
  public eexUsers: string[] = [];

  _officeMatch: {[old_id: string]: string} = {};
  _officeMatchName: {[old_id: string]: string} = {};
  _eexMatch: {[old_id: string]: string} = {};
  _eexMatchName: {[old_id: string]: string} = {};
  _activityMatch: {[old_id: string]: string} = {};
  _measureMatch: {[old_id: string]: string} = {};

  //original oeexs
  protected _oeexSubsciption: Subscription;
  oeexLoaded: boolean = false;
  officeList: string[] = [];
  eexList: string[] = [];
  originalOeexs: {[id: string]: EntityOffice | EntityExternalCompany} = {};
  replacement: {[id: string]: string} = {};
  replacementCompany: {[id: string]: string} = {};



  protected _repoOfficesSubsciption: Subscription;
  repoOffices: EntityOffice[] = [];
  repoOfficesLoaded: boolean = false;
  protected _repoEexsSubsciption: Subscription;
  repoEexs: EntityExternalCompany[] = [];
  repoEexsLoaded: boolean = false;

  originalSteps: EntityStep[] = [];
  replacementSite: {[id: string]: string} = {};
  replacementSiteValue: {[id: string]: EntitySite} = {};
  stepsLoaded: boolean = false;
  protected _repoSitesSubsciption: Subscription;
  repoSites: EntityExternalCompany[] = [];
  repoSitesLoaded: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    protected _siteService: SiteService,
    protected _officeService: OfficeService,
    protected _externalCompanyService: ExternalCompanyService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<WorkbookDuplicateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(snackBar, _spinnerService, _cdRef, dialogRef, data, _router, _logger, _entityService);
    //TODO

    this.title = 'DUPLIQUER ' + (this.workbookType === 'annual' ? ((this.workbookSimple) ? 'LE PROTOCOLE' : 'LE CONTRAT') : 'L\'OPÉRATION');
    if (this.id) {
      this._entityService.get(this.id).toPromise().then((original) => {
        this.entity = this._entityService.getNewEntity(original, true);
        this.entity.name = '';
        this.entity.description = 'COPIE - ' + this.entity.description;
        this.entity.editor = this._entityService.getUserName();
        this.entity.editorEmail = this.userProfile.email;
        this.keepTemplates = this.entity.type === 'annual';
        this.keepSteps = (this.entity.type !== 'annual' && this.entity.simple) || !this.keepTemplates;
        this.entity.inspections = {};
        this.entity.pdps = {};
        this.entity.offices = (this.data && this.data['options'] && this.data['options']['offices']) ? this.data['options']['offices'] : null;
        this.entity.status = EntityWorkbook.STATUS_DRAFT;
        this.entity.status = EntityWorkbook.getWorkbookStatus(this.entity);

      });
    }


    this._oeexSubsciption = this._entityService.getOEexList(this.id).subscribe((oeexs) => {
      if (oeexs) {
        this.replacement = {};
        this.originalOeexs = {};
        this.officeList = [];
        this.eexList = [];
        oeexs.forEach((c) => {
          this.originalOeexs[c._id] = c;
          this.replacement[c._id] = 'keep';
          this.replacementCompany[c._id] = '';
          if (c.documentType === WorkbookOfficeService.ENTITY_TYPE) {
            this.officeList.push(c._id);
          } else {
            this.eexList.push(c._id);
          }
        });
        this.oeexLoaded = true;
      }
    });
    this._repoOfficesSubsciption = this._officeService.getAll().subscribe((offices) => {
      if (offices) {
        this.repoOffices = offices;
        this.repoOfficesLoaded = true;
      }
    });
    this._repoEexsSubsciption = this._externalCompanyService.getAll().subscribe((eexs) => {
      if (eexs) {
        this.repoEexs = eexs;
        this.repoEexsLoaded = true;
      }
    });
    this._repoSitesSubsciption = this._siteService.getAll().subscribe((sites) => {
      if (sites) {
        this.repoSites = sites;
        this.repoSitesLoaded = true;
      }
    });
    //preload step/activities/measures
    this._entityService.getCurrentStepList(this.id)
      .then((list: EntityStep[]) => {
        if (list) {
          this.originalSteps = (this.workbookType === 'annual' && !this.workbookSimple)
            ? list.filter((e) => (e.template))
            : list;

          this.originalSteps.forEach((c) => {
            this.replacementSite[c._id] = 'keep';
            this.replacementSiteValue[c._id] = null;
          });
        }
        this.stepsLoaded = true;
      });
    this._entityService.getCurrentActivityList(this.id)
      .then((list: EntityActivity[]) => {
      });
    this._entityService.getCurrentMeasureList(this.id)
      .then((list: EntityWorkbookMeasure[]) => {
      });
  }

  save() {
    this._entityService.pauseReplication();
    super.save();
  }
  protected _afterSave(res) {
    if (res && res.ok && res.id) {
      //forcer etat de synchro pour le considérer synced
      this._entityService.addNewParent(res.id);
      const promises = [
        this._duplicateOeexList(res.id),
        this._duplicateMeasureList(res.id)
      ];
      if (this.keepComments) {
        promises.push(this._duplicateCommentList(res.id));
      }
      if (this.keepDocuments) {
        promises.push(this._duplicateDocumentList(res.id));
      }
      promiseAll(promises).then((saveArrayPromises) => {
        let savePromises = [...saveArrayPromises[0], ...saveArrayPromises[1], ...saveArrayPromises[2]];
        if (this.keepComments && saveArrayPromises[3]) {
          savePromises = [...savePromises, ...saveArrayPromises[3]];
        }
        if (this.keepDocuments && saveArrayPromises[4]) {
          savePromises = [...savePromises, ...saveArrayPromises[4]];
        }
        promiseAll(savePromises).then((values) => {
          this._duplicateActivityList(res.id).then((saveActivityArrayPromises) => {
            promiseAll(saveActivityArrayPromises).then((activityIds) => {
              this._duplicateStepList(res.id).then((saveStepArrayPromises) => {
                promiseAll(saveStepArrayPromises).then((stepIds) => {
                  const oeexs = values.filter((e) => (e && e.documentType && [WorkbookOfficeService.ENTITY_TYPE, WorkbookExternalCompanyService.ENTITY_TYPE].indexOf(e.documentType) !== -1))
                  const list: string[] = [this.entityForm.entity.createdId];
                  const operatorList: Operator[] = [];
                  const eexs: {[eex_name: string]: string} = {};
                  const office_contacts: {[oeex_name: string]: Contact[]} = {};
                  const eex_contacts: {[oeex_name: string]: Contact[]} = {};
                  oeexs
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .forEach((o) => {
                      if (o.contacts) {
                        if (o.documentType === WorkbookOfficeService.ENTITY_TYPE) {
                          office_contacts[o.name] = [];
                          o.contacts
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .forEach((c) => {
                              if (c.user_id && list.indexOf(c.user_id) === -1) {
                                list.push(c.user_id);
                              }
                              office_contacts[o.name].push(Object.assign(new Contact(), c));
                            });
                        } else {
                          eexs[o.name] = o.repo_id;
                          if (o.operators) {
                            o.operators.forEach((operator) => {
                              operatorList.push(operator);
                            });
                          }
                          eex_contacts[o.name] = [];
                          o.contacts
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .forEach((c) => {
                              if (c.user_id && list.indexOf(c.user_id) === -1) {
                                list.push(c.user_id);
                              }
                              eex_contacts[o.name].push(Object.assign(new Contact(), c));
                            });
                        }
                      }
                    });

                  this.entityForm.entity.users = list;
                  this.entityForm.entity.eex_contacts = eex_contacts;
                  this.entityForm.entity.office_contacts = office_contacts;
                  this.entityForm.entity.eexs = eexs;
                  this.entityForm.entity.operators = operatorList;

                  if (this.entityForm && this.entityForm.entity) {
                    if (this.entityForm.entity.eex_id) {
                      if (this._eexMatch[this.entity.eex_id]) {
                        this.entityForm.entity.eex_name = this._eexMatchName[this.entity.eex_id];
                        this.entityForm.entity.eex_id = this._eexMatch[this.entity.eex_id];
                      } else if (this._eexMatch && Object.keys(this._eexMatch).length) {
                        const eex_id = Object.keys(this._eexMatch)[0];
                        this.entityForm.entity.eex_name = this._eexMatchName[eex_id];
                        this.entityForm.entity.eex_id = this._eexMatch[eex_id];
                      }
                    }
                    this.entityForm.entity.users = [this.getUserId()];
                    this.entityForm.entity.users = this.entityForm.entity.users.concat(this.officeUsers);
                    this.entityForm.entity.users = this.entityForm.entity.users.concat(this.eexUsers);
                    this.entityForm.entity.updateSteps(this.clonedSteps);
                    if (this.entityForm.entity.offices && this.entityForm.entity.offices.length) {
                      this.entityForm.entity.offices = this.entityForm.entity.offices.map((oid) => {
                        const originalId = Object.keys(this.originalOeexs).find((originalOeexId) => (!!this.originalOeexs[originalOeexId].repo_id && this.originalOeexs[originalOeexId].repo_id === oid));
                        return this._officeMatch[originalId] ? this._officeMatchName[originalId] : oid;
                      });
                    }
                    this._entityService.save(this.entityForm.entity).then((x) => {
                      super._afterSave(x);
                      this._entityService.unpauseReplication();
                    }).catch((e) => {
                      this._spinnerService.hide();
                      this._entityService.unpauseReplication();
                      this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave update workbook', JSON.stringify(e));
                    });
                  } else {
                    this._spinnerService.hide();
                    this._entityService.unpauseReplication();
                  }
                }).catch((e) => {
                  this._spinnerService.hide();
                  this._entityService.unpauseReplication();
                  this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave saveStep', JSON.stringify(e));
                });
              }).catch((e) => {
                this._spinnerService.hide();
                this._entityService.unpauseReplication();
                this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave duplicateStepList', JSON.stringify(e));
              });
            }).catch((e) => {
              this._spinnerService.hide();
              this._entityService.unpauseReplication();
              this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave saveActivities', JSON.stringify(e));
            });
          }).catch((e) => {
            this._spinnerService.hide();
            this._entityService.unpauseReplication();
            this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave', JSON.stringify(e));
          });
        }).catch((e) => {
          this._spinnerService.hide();
          this._entityService.unpauseReplication();
          this._logger.error('WorkbookDuplicateDialogComponent', '_afterSave duplicateActivityList', JSON.stringify(e));
        });
      });
    }
    //  super._afterSave(res);
  }
  _duplicateStepList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      this._entityService.getCurrentStepList(this.id)
        .then((list: EntityStep[]) => {
          this.clonedSteps = [];
          resolve(list
            .filter((element) => ((this.keepSteps && !element.template) || (this.keepTemplates && element.template)))
            .map((element) => {
              const clone = this._entityService.stepService.factory(element, true);
              clone.parent_id = workbook_id;
              if (this.entity && this.entity.simple) {
                clone.name = this.entity.description;
                clone.site = this.entity.site;
                clone.point = this.entity.point;
                clone.zone = this.entity.zone;
                clone.dateStart = this.entity.dateStart;
                clone.dateEnd = this.entity.dateEnd;
                clone.hourStart = this.entity.hourStart;
                clone.hourEnd = this.entity.hourEnd;
                clone.workConditions = this.entity.workConditions;
              } else {
                if ((new Date(clone.dateStart).getTime() < new Date(this.entity.dateStart).getTime())
                  || (new Date(clone.dateStart).getTime() > new Date(this.entity.dateEnd).getTime())) {
                  clone.dateStart = this.entity.dateStart;
                }
                if ((new Date(clone.dateEnd).getTime() > new Date(this.entity.dateEnd).getTime())
                  || (new Date(clone.dateEnd).getTime() < new Date(clone.dateStart).getTime())) {
                  clone.dateEnd = this.entity.dateEnd;
                }
                if ((this.keepSteps || this.keepTemplates) && this.replacementSite[element._id] === "replace" && this.replacementSiteValue[element._id]) {

                  clone.site = new EntitySite(this.replacementSiteValue[element._id]);
                  clone.site.repo_id = this.replacementSiteValue[element._id]._id;
                  clone.point = '';
                  clone.zone = new EntityZone();
                }
                if (this.keepTemplates && this.replacementSite[element._id] === "delete") {
                  clone.site = new EntitySite();
                  clone.point = '';
                  clone.zone = new EntityZone();
                }
              }
              clone.activities = element.activities.map((activity_id) => (this._activityMatch[activity_id]));
              clone.scopedMeasures = {};
              clone.implementation = null;
              Object.keys(element.scopedMeasures).forEach((activity_id) => {
                clone.scopedMeasures[this._activityMatch[activity_id]] = {};
                Object.keys(element.scopedMeasures[activity_id]).forEach((measure_id) => {
                  clone.scopedMeasures[this._activityMatch[activity_id]][this._measureMatch[measure_id] ? this._measureMatch[measure_id] : measure_id]
                    = element.scopedMeasures[activity_id][measure_id].map((id) => (this._officeMatch[id] ? this._officeMatch[id] : this._eexMatch[id]));
                });
              });
              return this._entityService.stepService.save(clone).then((c) => {
                this.clonedSteps.push(clone);
                return clone;
              });
            }));
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateStepList', JSON.stringify(e));
          reject(e);
        });
    });
  }
  _duplicateOeexList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      const list = [];
      const mapping = {};
      [...this.officeList, ...this.eexList]
        .forEach((c_id) => {
          if (this.replacement[c_id]) {
            if (this.replacement[c_id] === 'keep' && this.originalOeexs[c_id]) {
              if (!this.keepDocuments) {
                this.originalOeexs[c_id].documents = [];
              }
              list.push(this.originalOeexs[c_id]);
            } else if (this.replacement[c_id] === 'replace' && !(!this.replacementCompany || !this.replacementCompany[c_id])) {
              let _c = this.repoOffices.find((e) => (e._id === this.replacementCompany[c_id]));
              if (!_c) {
                _c = this.repoEexs.find((e) => (e._id === this.replacementCompany[c_id]));
              }
              if (_c) {
                const c = Object.assign({}, _c);
                c['contacts'] = (c['contacts'] && c['contacts'].length) ? c['contacts'].filter((e) => (e.isDefault)) : [];
                c['documents'] = (c['documents'] && c['documents'].length) ? c['documents'].filter((e) => (e.isDefault)) : [];
                mapping[c._id] = c_id;
                list.push(c);
              }
            }
          }
        });
      this.officeUsers = [];
      list.forEach((o) => {
        if (o.contacts) {
          o.contacts.forEach((c) => {
            if (c.user_id && this.eexUsers.indexOf(c.user_id) === -1) {
              this.officeUsers.push(c.user_id);
            }
          });
        }
      });
      resolve(list.map((element) => {
        if (element['documentType'] && (element['documentType'] === OfficeService.ENTITY_TYPE || element['documentType'] === ExternalCompanyService.ENTITY_TYPE)) {
          element['repo_id'] = element['_id'];
        }
        const clone = (element['documentType'] === OfficeService.ENTITY_TYPE || element['documentType'] === WorkbookOfficeService.ENTITY_TYPE) ? this._entityService.officeService.getNewEntity(element, true) : this._entityService.externalCompanyService.getNewEntity(element, true);
        clone.parent_id = workbook_id;
        return ((clone.documentType === WorkbookOfficeService.ENTITY_TYPE)
          ? this._entityService.officeService.save(clone).then((res) => {
            if (res['id']) {
              this._officeMatch[(mapping[element._id] ? mapping[element._id] : element._id)] = res['id'];
              this._officeMatchName[(mapping[element._id] ? mapping[element._id] : element._id)] = clone.name;
            }
            return clone;
          })
          : this._entityService.externalCompanyService.save(clone).then((res) => {
            if (res['id']) {
              this._eexMatch[(mapping[element._id] ? mapping[element._id] : element._id)] = res['id'];
              this._eexMatchName[(mapping[element._id] ? mapping[element._id] : element._id)] = clone.name;
            }
            return clone;
          })
        );
      }));

    });
  }

  _duplicateActivityList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      this._entityService.getCurrentActivityList(this.id)
        .then((list: EntityActivity[]) => {
          this._activityMatch = {};
          resolve(list.map((element) => {
            const clone = this._entityService.activityService.factory(element, true);
            clone.parent_id = workbook_id;
            clone.companies = clone.companies.filter((x) => (!this.replacement[x.id] || this.replacement[x.id] !== 'delete'));
            clone.companies.forEach((ca) => {
              ca.name = this._officeMatchName[ca.id] ? this._officeMatchName[ca.id] : this._eexMatchName[ca.id];
              ca.id = this._officeMatch[ca.id] ? this._officeMatch[ca.id] : this._eexMatch[ca.id];
            });
            clone.measures.forEach((m) => {
              m._id = this._measureMatch[m._id] ? this._measureMatch[m._id] : m._id;
            });
            return this._entityService.activityService.save(clone)
              .then((res) => {
                if (res['id']) {
                  this._activityMatch[element._id] = res['id'];
                }
                return clone
              });
          }));
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateActivityList', JSON.stringify(e));
          reject(e);
        });
    });
  }
  _duplicateMeasureList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      this._entityService.getCurrentMeasureList(this.id)
        .then((list: EntityWorkbookMeasure[]) => {
          this._measureMatch = {};
          resolve(list.map((element) => {
            const clone = this._entityService.measureService.factory(element, true);
            clone.parent_id = workbook_id;
            return this._entityService.measureService.save(clone)
              .then((res) => {
                if (res['id']) {
                  this._measureMatch[element._id] = res['id'];
                }
                return clone;
              });
          }));
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateMeasureList', JSON.stringify(e));
          reject(e);
        });
    });
  }

  _duplicateCommentList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      this._entityService.getCurrentCommentList(this.id)
        .then((list: EntityComment[]) => {
          resolve(list.map((element) => {
            const clone = this._entityService.commentService.factory(element, true);
            clone.parent_id = workbook_id;
            return this._entityService.commentService.save(clone);
          }));
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateCommentList', JSON.stringify(e));
          reject(e);
        });
    });
  }

  _duplicateDocumentList(workbook_id: string): Promise<Promise<any>[]> {
    return new Promise((resolve, reject) => {
      this._entityService.getCurrentDocumentMetadataList(this.id)
        .then((list: EntityDocumentMetadata[]) => {
          resolve(list.map((element) => {
            const clone = this._entityService.documentService.documentMetadataService.factory(element, true);
            clone.parent_id = workbook_id;
            clone.context = element.context.replace(this.id, workbook_id);
            return this._entityService.documentService.documentMetadataService.save(clone);
          }));
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateDocumentList', JSON.stringify(e));
          reject(e);
        });
    });
  }
  /*
    // execution simple
    _duplicateOffices(workbook_id: string) {
      this._entityService.getCurrentOfficeList(this.id)
        .then((list: EntityOffice[]) => {
          this._officeMatch = {};
          list.forEach((element) => {
            const clone = this._entityService.officeService.factory(element, true);
            clone.parent_id = workbook_id;
            this._entityService.officeService.save(clone)
              .then((res) => {
                if (res['id']) {
                  this._officeMatch[element._id] = res['id'];
                }
              })
              .catch((e) => { this._logger.error('WorkbookDuplicateDialogComponent', 'save office clone', [e, clone]) });
          });
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateOffices', JSON.stringify(e));
        });
    }
    _duplicateExternalCompanies(workbook_id: string) {
      this._entityService.getCurrentExternalCompanyList(this.id)
        .then((list: EntityExternalCompany[]) => {
          this._eexMatch = {};
          list.forEach((element) => {
            const clone = this._entityService.externalCompanyService.factory(element, true);
            clone.parent_id = workbook_id;
            this._entityService.externalCompanyService.save(clone)
              .then((res) => {
                if (res['id']) {
                  this._eexMatch[element._id] = res['id'];
                }
              })
              .catch((e) => { this._logger.error('WorkbookDuplicateDialogComponent', 'save externalCompany clone', [e, clone]) });
          });
        })
        .catch((e) => {
          this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateExternalCompanies', JSON.stringify(e));
        });
    }
    */
  _duplicateActivities(workbook_id: string) {
    this._entityService.getCurrentActivityList(this.id)
      .then((list: EntityActivity[]) => {
        this._activityMatch = {};
        list.forEach((element) => {
          const clone = this._entityService.activityService.factory(element, true);
          clone.parent_id = workbook_id;
          this._entityService.activityService.save(clone)
            .then((res) => {
              if (res['id']) {
                this._activityMatch[element._id] = res['id'];
              }
            })
            .catch((e) => {this._logger.error('WorkbookDuplicateDialogComponent', 'save activity clone', [e, clone])});
        });
      })
      .catch((e) => {
        this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateActivities', JSON.stringify(e));
      });
  }
  _duplicateMeasures(workbook_id: string) {
    this._entityService.getCurrentMeasureList(this.id)
      .then((list: EntityWorkbookMeasure[]) => {
        this._measureMatch = {};
        list.forEach((element) => {
          const clone = this._entityService.measureService.factory(element, true);
          clone.parent_id = workbook_id;
          this._entityService.measureService.save(clone)
            .then((res) => {
              if (res['id']) {
                this._measureMatch[element._id] = res['id'];
              }
            })
            .catch((e) => {this._logger.error('WorkbookDuplicateDialogComponent', 'save measure clone', [e, clone])});
        });
      })
      .catch((e) => {
        this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateMeasures', JSON.stringify(e));
      });
  }

  _duplicateComments(workbook_id: string) {
    this._entityService.getCurrentCommentList(this.id)
      .then((list: EntityComment[]) => {
        list.forEach((element) => {
          const clone = this._entityService.commentService.factory(element, true);
          clone.parent_id = workbook_id;
          this._entityService.commentService.save(clone)
            .catch((e) => {this._logger.error('WorkbookDuplicateDialogComponent', 'save comment clone', [e, clone])});
        });
      })
      .catch((e) => {
        this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateMeasures', JSON.stringify(e));
      });
  }

  _duplicateDocuments(workbook_id: string) {
    this._entityService.getCurrentDocumentMetadataList(this.id)
      .then((list: EntityDocumentMetadata[]) => {
        list.forEach((element) => {
          const clone = this._entityService.documentService.documentMetadataService.factory(element, true);
          clone.parent_id = workbook_id;
          // TODO getfile / savefile / set data_id then...
          this._entityService.documentService.documentMetadataService.save(clone)
            .catch((e) => {this._logger.error('WorkbookDuplicateDialogComponent', 'save measure clone', [e, clone])});
        });
      })
      .catch((e) => {
        this._logger.error('WorkbookDuplicateDialogComponent', '_duplicateMeasures', JSON.stringify(e));
      });
  }
}
