// import {UUID} from 'angular2-uuid';
export interface IEntity {
  _id: string;
  _deleted?: boolean;
  _rev?: string;
  _revisions?;
  documentType: string;
  parent_id?: string;
  repo_id?: string;
}
export class Entity implements IEntity {
  _id: string;
  _deleted?: boolean;
  _rev?: string;
  _revisions?;
  _conflicts?: string[];
  documentType: string;
  parent_id: string;
  repo_id: string;
  constructor(data: any = {}, clone = false) {
    this._id = (data && data._id && !clone) ? data._id : '';
    if (this._id && data && data._deleted) {
      this._deleted = data._deleted;
    }
    this.copyFrom(data, clone);
  }
  copyFrom(data: any = {}, clone = false) {
    if (this._id) {
      this._revisions = (data && data._revisions && !clone) ? data._revisions : {};
      this._conflicts = (data && data._conflicts && !clone) ? data._conflicts : [];
    } else if (!clone && data && data._id) {
      this._id = data._id;
    }
    // si pas de rev, pas d'evenement sync sur save local
    //si rev, pas possible de sauvegarder en remote
    // => rustine dans DocumentFileService:uploadFile
    this._rev = (data && data._rev && !clone) ? data._rev : '';
    //copie depuis repo
    if (this.documentType && data.documentType && this.documentType !== data.documentType) {
      this.repo_id = (data && data._id) ? data._id : '';
    } else {
      this.documentType = (data && data.documentType) ? data.documentType : '';
      this.repo_id = (data && data.repo_id) ? data.repo_id : '';
      this.parent_id = (data && data.parent_id) ? data.parent_id : '';
    }
  }
}

export class EntityOwned extends Entity {
  public createdAt: Date;
  public createdId: string;
  public createdName: string;
  public updatedAt: Date;
  public updatedId: string;
  public updatedName: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.updatedAt = data && data.updatedAt ? new Date(data.updatedAt) : new Date();
    this.updatedId = data && data.updatedId ? data.updatedId : '';
    this.updatedName = data && data.updatedName ? data.updatedName : '';
    this.createdAt = data && data.createdAt ? new Date(data.createdAt) : new Date(this.updatedAt);
    this.createdId = data && data.createdId ? data.createdId : '';
    this.createdName = data && data.createdName ? data.createdName : '';
  }
}

export class TreeElement {
  element: any;
  children: TreeElement[];
  constructor(
    element: any = null,
    children: TreeElement[] = []
  ) {
    this.element = element;
    this.children = children;
  }
  static getAncestrors(element: any, list: any[], parent_attr: string = 'parent_id', id_attr: string = '_id'): string[] {
    let returned = [];
    while (element) {
      element = list.find((e) => (e[id_attr] === element[parent_attr]));
      if (element) {
        returned.push(element[id_attr]);
      }
    }
    return returned;
  }
  static getFamily(element: any, list: any[], parent_attr: string = 'parent_id', id_attr: string = '_id'): string[] {
    return [
      ...TreeElement.getAncestrors(element, list, parent_attr, id_attr),
      element[id_attr],
      ...TreeElement.getArray(TreeElement.getTreeView(list, element[id_attr], parent_attr, id_attr)).map((e) => (e[id_attr]))
    ];
  }
  static getLevels(list: any[], parent_attr: string = 'parent_id', id_attr: string = '_id'): { [id: string]: number } {
    const returned: { [id: string]: number } = {};
    let level = 0;
    let _levelIds: string[] = [];
    while (!level || _levelIds.length) {
      const _children_ids = list
        .filter((e) => (level ? (_levelIds.indexOf(e[parent_attr]) !== -1) : (!e[parent_attr])))
        .map((e) => (e[id_attr]));
      level++;
      _children_ids
        .forEach((id) => {
          returned[id] = level;
        });
      _levelIds = _children_ids;
    }
    //deleted parents ?
    list
      .filter((e) => (!returned[e[id_attr]]))
      .forEach((e) => {
        returned[e[id_attr]] = 0;
      });
    return returned;
  }
  static getLevelArray(list: any[], parent_attr: string = 'parent_id', id_attr: string = '_id'): any[] {
    const returned = [];
    let level = [
      list
        .filter((e) => (!e[parent_attr]))
        .map((e) => (e[id_attr]))
    ];
    while (level.length) {
      returned.push(level);
      level = list
        .filter((e) => (level.indexOf(e[parent_attr]) !== -1))
        .map((e) => (e[id_attr]));
    }
    return returned;
  }
  static orderTreeView(list: any[], parent_attr: string = 'parent_id', id_attr: string = '_id'): any[] {
    const existing = list.map((e) => (e[id_attr]));
    const orphans = list.filter((e) => (!!e[parent_attr] && existing.indexOf(e[parent_attr]) === -1));
    return [...TreeElement.getArray(TreeElement.getTreeView(list, '', parent_attr, id_attr)), ...orphans];
  }
  static getTreeView(list: any[], parent_node: string = '', parent_attr: string = 'parent_id', id_attr: string = '_id'): TreeElement[] {
    return list
      .filter((e) => (parent_node === e[parent_attr]))
      .map((e) => (new TreeElement(
        e,
        TreeElement.getTreeView(list, e[id_attr], parent_attr, id_attr)
      )));
  }
  static getArray(treeView: TreeElement[] = []): any[] {
    let returned: any[] = [];
    treeView.forEach((te) => {
      returned.push(te.element);
      returned = [...returned, ...TreeElement.getArray(te.children)];
    });
    return returned;
  }
}