import { ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { Subscription } from 'rxjs';
import {
  LoggerService,
  EntityFormComponent,
  ConfirmDialogComponent,
  DocumentMetadataDialogComponent,
  EntityDocumentMetadata,
  EntitySecureService,
  DocumentService,
  EntityCompanyDocumentable
} from 'app/shared';
import { CategoryService, EntityCategory } from 'app/workbook-core';

export class AbstractCompanyDocumentableComponent extends EntityFormComponent {
  entity: EntityCompanyDocumentable;

  @ViewChild('documentSort') documentSort: MatSort;
  selectedDocument: EntityDocumentMetadata;
  displayedDocumentColumns: string[] = ['categoryPicto', 'name', 'category', 'date', 'download', 'delete'];
  documentDataSource: MatTableDataSource<EntityDocumentMetadata> = new MatTableDataSource([]);

  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, _logger, _entityService);

  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      if (this._categorySubscription) {
        this._categorySubscription.unsubscribe();
      }
      this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
        if (list) {
          this.categoryList = list;
        }
      });
      this.updateDocumentDataSource();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
  }

  protected setDocumentSort() {
    if (this.documentSort) {
      this.documentDataSource.sort = this.documentSort;
    }
  }
  protected updateDocumentDataSource() {
    this.documentDataSource = new MatTableDataSource((this.entity && this.entity.documents) ? this.entity.documents : []);
    setTimeout(() => {
      this.setDocumentSort();
    });
  }
  openDocumentDialog(document: EntityDocumentMetadata = null) {
    if (this.rightU) {
      if (document) {
        this.selectedDocument = document;
      } else {
        this.selectedDocument = null;
      }
      const dialogRef = this.dialog.open(DocumentMetadataDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        width: '900px',
        maxHeight: '90%',
        data: {
          entity: document ? Object.assign({}, document) : this._documentService.getNewDocumentMetada(),
          categoryList: this.categoryList,
          addOnly: true,
          uploadOnly: true,
          hideDates: true
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          this.upsertDocument(c);
        }
      });
    }
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }

  upsertDocument(document: EntityDocumentMetadata): void {
    if (!this.entity.documents) {
      this.entity.documents = [];
    }
    let index = -1;
    if (this.selectedDocument) {
      this.updateSelectedDocument(document);
      index = this.entity.documents.indexOf(this.selectedDocument);
    } else {
      this.selectedDocument = document;
    }
    if (index === -1) {
      this.entity.documents.push(this.selectedDocument);
    }
    this.updateDocumentDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
  }

  updateSelectedDocument(document: EntityDocumentMetadata) {
    this.selectedDocument.isDefault = document.isDefault;
    this.selectedDocument.name = document.name;
    this.selectedDocument.ext = document.ext;
    this.selectedDocument.status = document.status;
    this.selectedDocument.description = document.description;
    this.selectedDocument.category = document.category;
    this.selectedDocument.categoryPicto = document.categoryPicto;
    this.selectedDocument.dateStart = document.dateStart;
    this.selectedDocument.dateEnd = document.dateEnd;
    this.selectedDocument.createdAt = document.createdAt;
    this.selectedDocument.createdName = document.createdName;
    this.selectedDocument.createdId = document.createdId;
    this.selectedDocument.updatedAt = document.updatedAt;
    this.selectedDocument.updatedName = document.updatedName;
    this.selectedDocument.updatedId = document.updatedId;
    //if (!this.selectedDocument.data_id && document.data_id) {
    this.selectedDocument.data_id = document.data_id;
    this.selectedDocument.imageState = document.imageState;
    this.selectedDocument.data_origin = document.data_origin;
    this.selectedDocument.displayName = document.displayName;
    this.selectedDocument.mime = document.mime;
    this.selectedDocument.icon = document.icon;
    this.selectedDocument.uploadedAt = document.uploadedAt;
    this.selectedDocument.uploadedName = document.uploadedName;
    this.selectedDocument.uploadedId = document.uploadedId;
    this.selectedDocument.context = document.context;

    //}
  }

  removeDocument(document: EntityDocumentMetadata) {
    if (this.entity && this.entity.documents) {
      const index = this.entity.documents.indexOf(document);
      if (index > -1) {
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document ?';
        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.documents.splice(index, 1);
            this.updateDocumentDataSource();
            if (this.autoSave && this.form.valid) {
              this._save();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }

    }
  }
}
