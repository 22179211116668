import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  AuthGuard
} from 'app/shared';
import {
  SiteService,
  OfficeService,
  ExternalCompanyService,
  JobService,
  OrganizationService,
  CategoryService,
  RiskService,
  MeasureService,
  SituationService,
  LdcmService,
  PreventionPlanAgreementService,
  FirePermitContentService,
  PermitContentService,
  ProtocolContentService,
  IcpRecurrenceService,
  DefaultVisibilityService,
  DocumentationService,
  EmergencyInstructionsService,
  AuditCheckpointsService,
  DocumentsRequestService
} from 'app/workbook-core';
import {JobListComponent} from './job';
import {OrganizationListComponent} from './organization';
import {CategoryListComponent} from './category';
import {SiteListComponent, SiteEditComponent} from './site';
import {OfficeListComponent, OfficeEditComponent} from './office';
import {ExternalCompanyListComponent, ExternalCompanyEditComponent} from './externalCompany';
import {RiskListComponent} from './prevention-plan/risk';
import {MeasureListComponent} from './prevention-plan/measure';
import {SituationListComponent} from './prevention-plan/situation';
import {LdcmListComponent} from './ldcm';
import {PreventionPlanAgreementEditComponent} from './prevention-plan/preventionPlanAgreement';
import {FirePermitContentEditComponent} from './firePermitContent';
import {PermitContentListComponent, PermitContentEditComponent} from './permitContent';
import {ProtocolContentEditComponent} from './protocolContent';
import {IcpRecurrenceEditComponent} from './icpRecurrence';
import {DashboardEditComponent} from './dashboard';
import {DefaultVisibilityEditComponent} from './defautVisibility';
import {DocumentationListComponent} from './documentation';
import {EmergencyInstructionsEditComponent} from './emergencyInstructions';
import {AuditCheckpointsEditComponent} from './auditCheckpoints';
import {DocumentsRequestListComponent} from './documentsRequest';

const routes: Routes = [

  // Office
  {
    path: OfficeService.ENTITY_URL,
    component: OfficeListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: OfficeService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        //add a new property to the data object icon
        order: 1,
        title: 'Services EU',
        icon: 'people_alt',
        orderGroup: 1,
        group: 'Intervenants'
      }
    }
  },
  // Organizations
  {
    path: OrganizationService.ENTITY_URL,
    component: OrganizationListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: OrganizationService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 3,
        title: 'Types de structure',
        icon: 'people_alt',
        orderGroup: 1,
        group: 'Intervenants'
      }
    }
  },
  {
    path: OfficeService.ENTITY_URL + '/' + OfficeService.ENTITY_URL_ADD,
    component: OfficeEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: OfficeService.ENTITY_PERMISSION.CREATE,
        redirectTo: '/401'
      }
    }
  },
  {
    path: OfficeService.ENTITY_URL + '/:id', //' + OfficeService.ENTITY_URL_EDIT,
    component: OfficeEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: OfficeService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },
  // ExternalCompany
  {
    path: ExternalCompanyService.ENTITY_URL,
    component: ExternalCompanyListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ExternalCompanyService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 4,
        title: 'Entreprises extérieures',
        icon: 'people_alt',
        orderGroup: 2,
        group: 'Intervenants'
      }
    }
  },
  {
    path: ExternalCompanyService.ENTITY_URL + '/' + ExternalCompanyService.ENTITY_URL_ADD,
    component: ExternalCompanyEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ExternalCompanyService.ENTITY_PERMISSION.CREATE,
        redirectTo: '/401'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: ExternalCompanyService.ENTITY_URL + '/' + ExternalCompanyService.ENTITY_URL_EDIT,
    component: ExternalCompanyEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ExternalCompanyService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },
  // jobs
  {
    path: JobService.ENTITY_URL,
    component: JobListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: JobService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 5,
        title: 'Métiers',
        icon: 'people_alt',
        orderGroup: 2,
        group: 'Intervenants'
      }
    }
  },



  // documentation
  {
    path: DocumentsRequestService.ENTITY_URL,
    component: DocumentsRequestListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: DocumentsRequestService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 1,
        title: 'Demandes types',
        icon: 'collections_bookmark',
        orderGroup: 2,
        group: 'Documentation'
      }
    }
  },
  // documentation
  {
    path: DocumentationService.ENTITY_URL,
    component: DocumentationListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: DocumentationService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 2,
        title: 'Consignes générales',
        icon: 'collections_bookmark',
        orderGroup: 2,
        group: 'Documentation'
      }
    }
  },
  // category
  {
    path: CategoryService.ENTITY_URL,
    component: CategoryListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: CategoryService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 3,
        title: 'Types de document',
        icon: 'other_houses',
        orderGroup: 2,
        group: 'Documentation'
      }
    }
  },


  // Site
  {
    path: SiteService.ENTITY_URL,
    component: SiteListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: SiteService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 1,
        title: 'Localisations',
        icon: 'construction',
        orderGroup: 3,
        group: 'Intervention'
      }
    }
  },

  {
    path: SiteService.ENTITY_URL + '/' + SiteService.ENTITY_URL_ADD,
    component: SiteEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: SiteService.ENTITY_PERMISSION.CREATE,
        redirectTo: '/401'
      },

    }
  },

  {
    path: SiteService.ENTITY_URL + '/:id', //+ SiteService.ENTITY_URL_EDIT,
    component: SiteEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: SiteService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },




  // ldcm
  {
    path: LdcmService.ENTITY_URL,
    component: LdcmListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: LdcmService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 1,
        title: 'Inspections communes',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // permis feu
  {
    path: FirePermitContentService.ENTITY_URL,
    component: FirePermitContentEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: FirePermitContentService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 2,
        title: 'Permis feu',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // permis feu
  {
    path: PermitContentService.ENTITY_URL,
    component: PermitContentListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: PermitContentService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 2,
        title: 'Permis',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // frequence ICP
  {
    path: IcpRecurrenceService.ENTITY_URL,
    component: IcpRecurrenceEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: IcpRecurrenceService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 5,
        title: 'Fréquence IC',
        icon: 'other_houses',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  {
    path: PermitContentService.ENTITY_URL + '/:id',
    component: PermitContentEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: PermitContentService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },
  // protocoles
  {
    path: ProtocolContentService.ENTITY_URL,
    component: ProtocolContentEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ProtocolContentService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 3,
        title: 'Protocoles',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // protocoles
  {
    path: EmergencyInstructionsService.ENTITY_URL,
    component: EmergencyInstructionsEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: EmergencyInstructionsService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 4,
        title: 'Consignes d\'urgence',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // audits
  {
    path: AuditCheckpointsService.ENTITY_URL,
    component: AuditCheckpointsEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: AuditCheckpointsService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 4,
        title: 'Contrôle (visites)',
        icon: 'description',
        orderGroup: 5,
        group: 'Éditions'
      }
    }
  },
  // risk
  {
    path: RiskService.ENTITY_URL,
    component: RiskListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: RiskService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 2,
        title: 'Risques',
        icon: 'construction',
        orderGroup: 4,
        group: 'Intervention'
      }
    }
  },
  // measure
  {
    path: MeasureService.ENTITY_URL,
    component: MeasureListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: MeasureService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 3,
        title: 'Mesures',
        icon: 'construction',
        orderGroup: 4,
        group: 'Intervention'
      }
    }
  },
  // situation
  {
    path: SituationService.ENTITY_URL,
    component: SituationListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: SituationService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 4,
        title: 'Situations',
        icon: 'construction',
        orderGroup: 4,
        group: 'Intervention'
      }
    }
  },
  // dashboard
  {
    path: 'repository/dashboard',
    component: DashboardEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: IcpRecurrenceService.SPECIFIC_PERMISSION.VIEWDASHBOARDCONFIG,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 4,
        title: 'Tableau de bord',
        icon: 'settings',
        orderGroup: 6,
        group: 'Paramètres'
      }
    }
  },

  // agreement (PP)
  {
    path: PreventionPlanAgreementService.ENTITY_URL,
    component: PreventionPlanAgreementEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: PreventionPlanAgreementService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 7,
        title: 'Dispositions légales',
        icon: 'settings',
        orderGroup: 6,
        group: 'Paramètres'
      }
    }
  },
  // visibilité par defaut ICP
  {
    path: DefaultVisibilityService.ENTITY_URL,
    component: DefaultVisibilityEditComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: DefaultVisibilityService.ENTITY_PERMISSION.READ,
        redirectTo: '/401'
      },
      sidenavrepo: {
        order: 8,
        title: 'Visibilité par défaut',
        icon: 'settings',
        orderGroup: 6,
        group: 'Paramètres'
      }
    }
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RepositoryRoutingModule {
}

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
