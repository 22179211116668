import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'app/shared';
import {WorkbookListComponent} from './workbook';
import {SignablesComponent, SignableComponent} from './signable';
import {DownloadComponent} from './download.component';
import {DownloadCheckpointComponent} from './downloadCheckpoint.component';


import {WorkbookDownloadComponent} from './workbookDownload.component';

const routes: Routes = [
  {
    path: 'docs',
    component: WorkbookListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserWorkbook'],
        redirectTo: '/401'
      }
    },
  },
  {
    path: 'documents',
    component: WorkbookListComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserWorkbook'],
        redirectTo: '/401'
      }
    },
  },
  {
    path: 'docs/:id',
    component: SignablesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignables'],
        redirectTo: '/401'
      }
    },
  },
  {
    path: 'docs/:id/documents',
    component: SignablesComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignables'],
        redirectTo: '/401'
      }
    },
  },
  {
    path: 'doc/:id',
    component: SignableComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignable'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: 'document/:id',
    component: DownloadComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignable'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: 'checkpoint/:audit_id/:id',
    component: DownloadCheckpointComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignable'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: 'document/:workbook/:id',
    component: WorkbookDownloadComponent,
    canActivate: [AuthGuard],
    data: {
      permissions: {
        only: ['showUserSignable'],
        redirectTo: '/401'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignablesRoutingModule {
}

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
