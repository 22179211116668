import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ThemePalette } from '@angular/material/core';
import { LoggerService, EntityFormComponent } from 'app/shared';
import { IcpRecurrenceService, EntityIcpRecurrence, SafetyInstruction, Instruction } from 'app/workbook-core';
import { ColorEvent } from 'ngx-color';
@Component({
  selector: 'app-repository-icpRecurrence',
  templateUrl: './icpRecurrence.component.html',
  styleUrls: ['./icpRecurrence.component.scss']
})

export class IcpRecurrenceComponent extends EntityFormComponent {
  entity: EntityIcpRecurrence;
  newExposure: string = '';
  newSeverity: string = '';
  newRecurrence: string = '';
  newSystematicCase: string = '';

  public color: ThemePalette = 'primary';
  public touchUi = false;
  showPicker: { [index: number]: boolean } = {};
  showPdpPicker: { [index: number]: boolean } = {};
  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: IcpRecurrenceService

  ) {
    super(dialog, _logger, _entityService);
  }
  toggleState(event) {
    if (event && event.checked) {
      this.entity.enabled = true;
    } else {
      this.entity.enabled = false;
    }
    this._save().then((e) => { this._afterSave(e); });
  }
  toggleDtdict(event) {
    if (event && event.checked) {
      this.entity.dtdict = true;
    } else {
      this.entity.dtdict = false;
    }
    this._save().then((e) => { this._afterSave(e); });
  }
  addExposure() {
    if (this.rightU && this.entity && this.entity.exposures && this.newExposure) {
      let max = 0;
      this.entity.exposures.forEach((e) => {
        if (e.value > max) {
          max = e.value;
        }
      });
      this.entity.exposures.push({ name: this.newExposure, value: (max + 1) });
      this._save().then((e) => { this._afterSave(e); });
      this.newExposure = '';
    };
  }
  removeExposure(index: number) {
    if (this.rightU && this.entity && this.entity.exposures && this.entity.exposures[index]) {
      this.entity.exposures.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
  addSeverity() {
    if (this.rightU && this.entity && this.entity.severities && this.newSeverity) {
      let max = 0;
      this.entity.severities.forEach((e) => {
        if (e.value > max) {
          max = e.value;
        }
      });
      this.entity.severities.push({ name: this.newSeverity, value: (max + 1) });
      this._save().then((e) => { this._afterSave(e); });
      this.newSeverity = '';
    };
  }
  removeSeverity(index: number) {
    if (this.rightU && this.entity && this.entity.severities && this.entity.severities[index]) {
      this.entity.severities.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
  addRecurrence() {
    if (this.rightU && this.entity && this.entity.recurrences && this.newRecurrence) {
      let maxscore: number = 0;
      let minperiod: number = 365;
      this.entity.recurrences.forEach((e) => {
        if (e.minimum > maxscore) {
          minperiod = e.period;
          maxscore = e.minimum;
        }
      });
      this.entity.recurrences.push({
        name: this.newRecurrence,
        period: (+minperiod - 1),
        minimum: (+maxscore + 1),
        audits: 0
      });
      this._save().then((e) => { this._afterSave(e); });
      this.newRecurrence = '';
    };
  }
  removeRecurrence(index: number) {
    if (this.rightU && this.entity && this.entity.recurrences && this.entity.recurrences[index]) {
      this.entity.recurrences.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
  addSystematicCase() {
    if (this.rightU && this.entity && this.entity.systematicCases && this.newSystematicCase) {
      this.entity.systematicCases.push(this.newSystematicCase);
      this._save().then((e) => { this._afterSave(e); });
      this.newSystematicCase = '';
    }
  }
  removeSystematicCase(index: number) {
    if (this.rightU && this.entity && this.entity.systematicCases && this.entity.systematicCases[index]) {
      this.entity.systematicCases.splice(index, 1);
      this._save().then((e) => { this._afterSave(e); });
    }
  }
}
