import {Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';

import * as PDFJS from 'pdfjs-dist/legacy/build/pdf.min';

import {LoggerService} from 'app/shared/logger';
import {EntityFormComponent} from '../entityForm.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {DocumentMetadataService} from 'app/shared/services';
import {DocumentService} from 'app/shared/document';
import {EntityDocumentMetadata, DocumentStatus, IDocumentCategory, Contact} from 'app/shared/models';
import {getBase64, dataURLtoFile, base64ToArrayBuffer} from 'app/shared/utils';
import {AppPinturaComponent} from './pintura.component';

@Component({
  selector: 'app-document-info',
  templateUrl: './documentInfo.component.html',
  styleUrls: ['./documentInfo.component.scss']
})
export class DocumentInfoComponent extends EntityFormComponent implements OnChanges {
  @ViewChild("rejectReason") rejectReason: ElementRef;
  @ViewChild("pdfcanvas") pdfcanvas: ElementRef;
  autoSave = false;
  public entity: EntityDocumentMetadata;
  @Input() categoryList: IDocumentCategory[] = [];
  @Input() requestList: Contact[] = [];
  @Input() requestedList: string[] = [];
  @Input() notificationList: Contact[] = [];
  @Input() context: string = '';
  @Input() addOnly: boolean = false;
  @Input() uploadOnly: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() hideDates: boolean = false;
  @Input() showDefault: boolean = false;
  showDates: boolean = false;
  actionRadio: number = 0;
  file: File;
  file_size: number;
  @Input() rejected: boolean = false;
  accepted: boolean = false;
  modified: boolean = false;
  modifiedImage: boolean = false;
  modifiedFile: boolean = false;
  documentStatus = DocumentStatus.getLabels();

  dateStartValid: boolean;
  dateEndValid: boolean;

  file_mimes: string[] = ['image/png', 'image/jpeg', 'image/gif'];
  file_data: string = '';
  safe_data: SafeUrl = '';
  isImage: boolean = false;

  pdf_mimes: string[] = ['application/pdf'];
  file_arrayBuffer: Uint8Array;
  isPdf: boolean = false;
  //version PNG 64bit
  pdf_data: string;
  pdf_page: number = 0;
  //scale du viewport du canvas
  pdf_scale: number = 1;
  //largeur réelle du PDF
  pdf_width: number = 0;
  //largeur réelle du PDF
  viewport_width: number = 0;
  viewport_height: number = 0;
  //ration taille viewport/taille PDF
  pdf_quality: number = 0;
  pdf_rendering: boolean = false;
  pdf;

  resized: boolean = false;
  showPintura: boolean = false;
  pinturaMode: string = AppPinturaComponent.MODE_FULL;
  pinturaModeFull: string = AppPinturaComponent.MODE_FULL;
  fileLoading: boolean = false;

  imageLoading: boolean = false;
  image_data: string = '';

  rightE: boolean = false;

  tab: number = 0;

  template: File;


  constructor(
    private cdr: ChangeDetectorRef,
    protected sanitizer: DomSanitizer,
    protected _router: Router,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.context && this.entity && !this.entity._id && !this.entity.context) {
      this.entity.context = this.context;
    }
    if (this.entity) {
      this.isImage = (this.file_mimes.indexOf(this.entity.mime) !== -1);
      this.isPdf = (this.pdf_mimes.indexOf(this.entity.mime) !== -1);

      if (this.entity.data_id && this.entity.mime && this.entity.displayName) {
        this.fileLoading = true;
        this._documentService.getAttachmentFile(this.entity.data_id, this.entity.mime, this.entity.displayName)
          .toPromise()
          .then((file: File) => {
            this.updateFileData(file);
          }).finally(() => {
            this.fileLoading = false;
          });
        this.imageLoading = true;
        this._documentService.getAttachmentFile(this.entity.data_id, this.entity.mime, this.entity.data_id + '-image')
          .toPromise()
          .then((file: File) => {
            getBase64(file).then((data) => {
              this.image_data = data.toString();
            }).finally(() => {
              this.imageLoading = false;
            });
          }).catch(() => {
            this.imageLoading = false;
          });
      }
    }
    this.rightE = this.hasRightE();
    this.updateCategory();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['addOnly']) {
      if (this.addOnly) {
        this.actionRadio = 0;
      }
    }
    if (changes && changes['readOnly']) {
      if (this.readOnly) {
        this.rightU = false;
        this.rightC = false;
        this.rightD = false;
      }
    }
    if (changes && changes['notificationList'] && this.userProfile) {
      const i = this.notificationList.findIndex((c) => (c.email && this.userProfile && this.userProfile.email && c.email === this.userProfile.email));
      if (i === -1) {
        const c = new Contact();
        c.email = this.userProfile.email;
        c.name = this._entityService.getUserName();
        if (this.userProfile.user_metadata.phone_number) {
          c.phone = this.userProfile.user_metadata.phone_number
        }

        this.notificationList.push(c);
      }
      if (changes && changes['rejected'] && this.rejected) {
        this.setRejected();
      }
    }
  }
  hasRightE() {
    return !!this.file_data &&
      this._router.url.indexOf('/repository/') === 0
      ? this.hasPermission('modifyRepoImage')
      : this.hasPermission('modifyImagePP');
  }
  getFileBlob() {
    const b64 = this.file_data.toString().split(',');
    return base64ToArrayBuffer(b64[1] ? b64[1] : this.file_data.toString());
  }
  updateFileData(file: File) {
    if (file) {
      getBase64(file).then((data) => {
        this.file_data = data.toString();
        this.safe_data = this.sanitizer.bypassSecurityTrustUrl(this.file_data);
        this.isImage = (this.file_mimes.indexOf(this.entity.mime) !== -1);
        this.isPdf = (this.pdf_mimes.indexOf(this.entity.mime) !== -1);
        if (this.isPdf) {
          //const now = (new Date()).getTime();
          this.file_arrayBuffer = this.getFileBlob();
          //const now2 = (new Date()).getTime()
          //console.log('getFileBlob : ' + (now2 - now));
          PDFJS.getDocument(this.file_arrayBuffer).promise.then((pdf) => {
            //console.log('PDFJS.getDocument : ' + ((new Date()).getTime() - now2));
            //const now3 = (new Date()).getTime();
            //const number_of_pages = pdf.numPages;
            //for (let i = 1; i < number_of_pages + 1; i++) {
            if (pdf.numPages) {
              this.pdf = pdf;
              this.pdf_page = 1;
              this.pdfViewPage();
            }
          }).catch((error) => {
            console.error('error PDFJS.getDocument', error);
          });
        }
      });
    }
  }
  pdfViewPage() {
    if (this.pdf && this.pdf_page) {
      if (this.pdf_page <= this.pdf.numPages && this.pdf_page > 0) {
        const _that = this;
        //const now = (new Date()).getTime();
        this.pdf.getPage(this.pdf_page).then((page) => {
          //const now2 = (new Date()).getTime();
          //console.log('pdf.getPage(' + _that.pdf_page + ') : ' + (now2 - now));
          if (page.view && page.view[2]) {
            _that.pdf_width = page.view[2];
          }
          if (!_that.pdf_quality && _that.pdf_width && _that.pdf_width > 815) {
            this.pdf_scale = Math.round(100 * 815 / _that.pdf_width) / 100;
          }
          const viewport = page.getViewport({scale: _that.pdf_scale});
          if (page.view && page.view[2] && viewport.width) {
            _that.pdf_width = page.view[2];
            _that.viewport_width = Math.round(viewport.width);
            _that.viewport_height = Math.round(viewport.height);
            _that.pdf_quality = Math.round(100 * (_that.viewport_width / _that.pdf_width));
          }
          var context = _that.pdfcanvas.nativeElement.getContext('2d', {
            desynchronized: true,
            powerPreference: "high-performance"
          });
          _that.pdfcanvas.nativeElement.height = viewport.height;
          _that.pdfcanvas.nativeElement.width = viewport.width;
          var renderContext = {canvasContext: context, viewport: viewport};
          _that.pdf_rendering = true;
          page.render(renderContext).promise.then(function () {
            //console.log('page.render (x' + _that.pdf_scale + ') : ' + ((new Date()).getTime() - now2));
            _that.pdf_rendering = false;
            _that.pdf_data = _that.pdfcanvas.nativeElement.toDataURL('image/png');
            //_that.pdf_data = _that.pdfcanvas.nativeElement.toDataURL('image/png');
          }).catch((error) => {
            console.error('error page.render', error);
          });
        }).catch((error) => {
          console.error('error pdf.getPage', error);
        });
      } else {
        setTimeout(() => {this.pdf_page = this.pdf.numPages;});
      }
    }
  }
  pdfNextPage() {
    if (this.pdf.numPages && this.pdf_page < this.pdf.numPages) {
      this.pdf_page++;
      this.pdfViewPage();
    }
  }
  pdfPreviousPage() {
    if (this.pdf.numPages && this.pdf_page > 1) {
      this.pdf_page--;
      this.pdfViewPage();
    }
  }
  changeQuality() {
    if (this.pdf_width && this.viewport_width) {
      if (this.pdf_quality) {
        this.pdf_scale = Math.round(this.pdf_quality) / 100;
        this.pdfViewPage();
      } else {
        setTimeout(() => {this.pdf_quality = Math.round(this.pdf_scale * 100);});
      }
    }
  }
  showPinturaImage() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = 'Continuer';
    dialogRefDel.componentInstance.confirmMessage = 'Vous allez ajouter recouper, redimensionner ou affiner l\'image ,<br>le rendu sera utilisé comme image de fond par la suite.<br>Vous ne pourrez alors plus revenir sur ces modifications.';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this.pinturaMode = AppPinturaComponent.MODE_IMAGE;
        this.showPintura = true;
        this.resized = true;
      }
    });
  }
  showPinturaLayer() {
    if (this.entity) {
      if (this.entity.imageState || this.resized) {
        this.pinturaMode = AppPinturaComponent.MODE_FULL;
        this.showPintura = true;
      } else {
        const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.buttonMessage = 'Continuer';
        dialogRefDel.componentInstance.confirmMessage = 'Vous allez ajouter des annotations à cette image.<br>Si vous souhaitez recouper, redimensionner ou affiner l\'image de fond, il est conseillé de le faire avant.<br>Vous pourrez toujours ajuster l\'image par la suite mais ces modifications seront appliquées à chaque édition à partir de l\'image originale';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {

            this.pinturaMode = this.entity.imageState ? AppPinturaComponent.MODE_FULL : AppPinturaComponent.MODE_LAYER;
            this.showPintura = true;
          }
        });
      }
    }
  }
  onPinturaClose(data) {
    this.showPintura = false;
    this.cdr.detectChanges();
  }
  flattenPinturaImage() {
    if (this.entity && this.file_data) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.buttonMessage = 'Aplatir';
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir aplatir l\'image ?<br>Toutes les annotations et images insérées jusqu\'ici ne seront plus modifiables. L\'image actuelle servira ensuite d\'image de fond pour vos futures annotations.';

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this.updateBackgroundFile(this.file_data);
          this.updateImageState('');
        }
      });
    }
  }
  convertToPng() {
    if (this.entity && this.entity.displayName && this.entity.displayName.indexOf('.pdf') === (this.entity.displayName.length - 4) && this.pdf && this.pdf_page && this.pdf_data) {
      if (this.pdf_page <= this.pdf.numPages && this.pdf_page > 0) {
        const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.buttonMessage = 'Convertir';
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir convertir le fichier PDF en image (PNG) ?';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.displayName = this.entity.displayName.substring(0, this.entity.displayName.length - 4) + '.png';
            this.updateDataFile(this.pdf_data);
            setTimeout(() => {
              this.isImage = true;
              this.isPdf = false;
              this.tab = 1
            });

          }
        });
      } else {
        setTimeout(() => {this.pdf_page = this.pdf.numPages;});
      }
    }
  }
  updateImageState(data) {
    this.entity.imageState = data;
    this.modified = true;
  }
  updateBackgroundFile(data: string) {
    if (data) {
      this.modified = true;
      this.modifiedImage = true;
      this.image_data = data.toString();
    }
  }
  updateDataFile(data: string) {
    if (data) {
      this.modified = true;
      this.modifiedFile = true;
      //const pre_file_data = this.file_data;
      if (!this.image_data && this.entity.imageState) {
        this.image_data = this.file_data;
        this.modifiedImage = true;
      }
      this.file_data = data.toString();
      this.safe_data = this.sanitizer.bypassSecurityTrustUrl(this.file_data);
      const file = dataURLtoFile(data, this.entity.displayName);
      if (file) {
        if (!this.entity.data_origin && this.entity.data_id) {
          this.entity.data_origin = this.entity.data_id;
          this.entity.data_id = '';
        }
        this.file = file;
        this.entity.ext = this._entityService.getFileExt(this.file);
        this.entity.mime = this.file.type;
        this.entity.icon = this._entityService.getIcon(this.file.type);
        this.entity.size = this._entityService.getFileSize(this.file);
      }
    }
  }

  updateCategory() {
    if (this.entity && this.entity.category && this.categoryList && this.categoryList.length) {
      const selectedCategory = this.categoryList.find((e) => (e.name === this.entity.category));
      if (selectedCategory) {
        this.entity.category = selectedCategory.name;
        this.entity.categoryPicto = selectedCategory.picto;
        this.showDates = selectedCategory.limited;
      }
    } else {
      this.showDates = !!(this.entity && this.entity._id && (this.entity.dateStart || this.entity.dateEnd));
    }
  }
  toggleRequested(c: Contact, event) {
    const i = this.requestedList.indexOf(c.id);
    if (i === -1 && event.checked) {
      this.requestedList.push(c.id);
    } else if (i !== -1 && !event.checked) {
      this.requestedList.splice(i, 1);
    }
    const j = this.entity.request.indexOf(c.email);
    if (j === -1 && event.checked) {
      this.entity.request.push(c.email);
    } else if (j !== -1 && !event.checked) {
      this.entity.request.splice(j, 1);
    }
    this.modified = true;
  }

  toggleNotification(c: Contact, event) {
    const i = this.entity.notifications.indexOf(c.email);
    if (i === -1 && event.checked) {
      this.entity.notifications.push(c.email);
    } else if (i !== -1 && !event.checked) {
      this.entity.notifications.splice(i, 1);
    }
    this.modified = true;
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }
  public uploadFile() {
    return this._documentService.uploadFilePromise(this.file, this.file.name, this._router.url);
  }
  public updateFile() {
    return this._documentService.uploadAttachment(this.file, this.entity.data_id);
  }
  public uploadImage() {
    if (this.image_data && this.modified) {
      const file = dataURLtoFile(this.image_data, this.entity.data_id + '-image');
      return this._documentService.uploadAttachment(file, this.entity.data_id);
    }
  }
  public fileChangeEvent(event: any) {
    if (event.addedFiles && event.addedFiles[0]) {
      this.file = new File([event.addedFiles[0]], event.addedFiles[0].name, {type: event.addedFiles[0].type});
      if (this.entity) {
        if (!this.entity.name) {
          this.entity.name = this._entityService.getFileName(event.addedFiles[0]);
        }
        this.entity.ext = this._entityService.getFileExt(this.file);
        this.entity.mime = this.file.type;
        this.entity.icon = this._entityService.getIcon(this.file.type);
        this.entity.displayName = this.file.name;
        this.entity.size = this._entityService.getFileSize(this.file);
        this.entity.reason = '';
      }
      this.updateFileData(this.file);

      /*
      this.reader = new FileReader();
      this.reader.onload = function (e : any) {
      }
      this.reader.readAsDataURL(fileInput.target.files[0]);
      */
    }
    this.modified = true;
  }

  public fileRemoveEvent() {
    this.file = null;
    if (this.entity) {
      this.entity.ext = '';
      this.entity.mime = '';
      this.entity.icon = '';
      this.entity.displayName = '';
      this.entity.size = '';
    }
    this.modified = true;
  }
  setAccepted() {
    this.rejected = false;
    this.accepted = true;
    this.modified = true;
  }
  setRejected() {
    this.rejected = true;
    this.accepted = false;
    if (this.entity && this.entity.request) {
      this.requestedList = this.entity.request;
    }
    this.modified = true;
    setTimeout(() => {
      if (this.rejectReason) {
        this.rejectReason.nativeElement.focus();
      }
    }, 250);
  }

  templateRemoveEvent() {
    if (this.entity && this.entity.template_name) {
      this.template = null;
      this.entity.template_id = '';
      this.entity.template_name = '';
    }

  }
  templateChangeEvent(event) {
    if (event && event.addedFiles && event.addedFiles[0] && this.template) {
      this.entity.template_id = '';
      this.template = new File([event.addedFiles[0]], event.addedFiles[0].name, {type: event.addedFiles[0].type});
      this.entity.template_name = this.template.name;
    }

  }
  saveTemplate() {
    return this._documentService.uploadFile(this.template, this.template.name).toPromise();
  }
}
