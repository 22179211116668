import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {DomSanitizer, SafeUrl, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle} from '@angular/platform-browser';
import {Observable, ReplaySubject, throwError, Subscription} from 'rxjs';
import {catchError, retry, timeout, map} from 'rxjs/operators';
import {PrintConfigService} from './printConfig.service';
import {LoggerService} from 'app/shared/logger/logger.service';
import {Auth0ConfigService, AuthService} from 'app/shared/auth/';
import {TrepiedServerModel} from '../models/trepied.model';
import {EntitySignable, SignableStatus} from '../models/signable.model';
import {lz_compress, lz_decompress} from '../utils/utils';



@Injectable()
export class SignableService {

  configTrepiedServer: TrepiedServerModel;
  configSubscription: Subscription;
  //signables: Observable<any> = new Observable<any>();
  public exploitingCompany: ReplaySubject<any>;
  public workbookList: ReplaySubject<any>;
  public signables: {[id: string]: ReplaySubject<any>} = {};
  public signable: {[id: string]: ReplaySubject<any>} = {};
  public configLoaded: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public sanitizer: DomSanitizer,
    private _http: HttpClient,
    private _auth: AuthService,
    private _auth0Config: Auth0ConfigService,
    private _printConfig: PrintConfigService,
    private _logger: LoggerService) {
    this._printConfig.getSingleOne().subscribe((data) => {
      if (data) {
        this.configTrepiedServer = data;
        this.configLoaded.next(true);
        if (this.workbookList) {
          this.refreshWorkbookList();
        }
        Object.keys(this.signable).forEach((id) => {
          this.refreshSignable(id);
        });
        Object.keys(this.signables).forEach((id) => {
          this.refreshSignables(id);
        });
        if (this.exploitingCompany) {
          this.refreshExploitingCompany();
        }
      }
    },
      (e) => {
        this._logger.error('EditableService', 'unable to get config', JSON.stringify(e));
      });
  }

  public getUserId(): any {
    return this._auth.getUserId();
  }
  public getUserName(): string {
    return this._auth.userProfile
      ? (this._auth.userProfile.user_metadata
        && (this._auth.userProfile.user_metadata.given_name || this._auth.userProfile.user_metadata.family_name))
        ? this._auth.userProfile.user_metadata.given_name
        + ((this._auth.userProfile.user_metadata.given_name && this._auth.userProfile.user_metadata.family_name) ? ' ' : '')
        + this._auth.userProfile.user_metadata.family_name
        : (this._auth.userProfile.name ? this._auth.userProfile.name : this._auth.userProfile.email)
      : '';
  }
  public refreshExploitingCompany() {
    if (this.configTrepiedServer) {
      this._getExploitingCompany().toPromise().then((result) => {
        if (this.exploitingCompany && result && result['exploitingCompany']) {
          this.exploitingCompany.next(result['exploitingCompany']);
        }
      });
    }
  }

  public getExploitingCompany(): ReplaySubject<any> {
    if (!this.exploitingCompany) {
      this.exploitingCompany = new ReplaySubject(null);
    }
    this.refreshExploitingCompany();
    return this.exploitingCompany;

  }

  public refreshWorkbookList() {
    if (this.configTrepiedServer) {
      this._getWorkbookList().toPromise().then((workbookList) => {
        if (this.workbookList && workbookList) {
          this.workbookList.next(workbookList);
        }
      });
    }
  }

  public getWorkbookList(): ReplaySubject<any> {
    //if (!this.workbookList) {
    this.workbookList = new ReplaySubject(null);
    //}
    this.refreshWorkbookList();
    return this.workbookList;

  }

  public refreshSignables(id: string) {
    if (this.configTrepiedServer) {
      this._getSignables(id).toPromise().then((signables) => {
        if (this.signables[id] && signables) {
          this.signables[id].next(signables);
        }
      }).catch((err) => {
        console.log('error refreshSignables ' + id, err);
        this.signables[id].next(err);
      });
    }
  }

  public getSignables(id: string): ReplaySubject<any> {
    //if (!this.signables[id]) {
    this.signables[id] = new ReplaySubject(null);
    //}
    this.refreshSignables(id);
    return this.signables[id];
  }

  public refreshSignable(id: string) {
    if (this.configTrepiedServer) {
      this._getSignable(id).toPromise().then((signable) => {
        if (this.signable[id] && signable) {
          if (signable['document'] && signable['document']['content_lz'] && signable['document']['content']) {
            signable['document']['content'] = lz_decompress(signable['document']['content']);
          }
          signable['document']['content_lz'] = false;
          this.signable[id].next(signable);
        }
      }).catch((err) => {
        console.log('error refreshSignable ' + id, err);
        this.signable[id].next(err);
      });
    }
  }
  public getSignable(id: string): ReplaySubject<any> {
    //if (!this.signable[id]) {
    this.signable[id] = new ReplaySubject(null);
    //}
    this.refreshSignable(id);
    return this.signable[id];
  }

  public upload(id: string, file: Blob, data: any): Promise<any> {
    const formdata = new FormData();
    formdata.append('file', file);
    if (data) {
      Object.keys(data).forEach((k) => {
        formdata.append(k, data[k]);
      });
    }
    return this._serverPost('upload/' + id, formdata, '').toPromise();
  }


  public signUp(id: string, data): Promise<any> {
    return this._serverPost('signable/' + id, data).pipe(map(
      (signable) => {
        if (signable && signable['document'] && signable['document']['content_lz'] && signable['document']['content']) {
          signable['document']['content'] = lz_decompress(signable['document']['content']);
        }
        signable['document']['content_lz'] = false;
        return signable;
      }), catchError((err) => {
        return this.handleError(err);
      })).toPromise();
  }
  public attendancing(id: string, data): Promise<any> {
    return this._serverPost('attendance/' + id, data).pipe(map(
      (signable) => {
        if (signable && signable['document'] && signable['document']['content_lz'] && signable['document']['content']) {
          signable['document']['content'] = lz_decompress(signable['document']['content']);
        }
        signable['document']['content_lz'] = false;
        return signable;
      }), catchError((err) => {
        return this.handleError(err);
      })).toPromise();
  }

  public test(): Promise<any> {
    return this._serverGet('test').toPromise();
  }

  protected _serverGet(uri: string, params = new HttpParams()): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/html')
      .append('Authorization', `Bearer ${this._auth.getAccessToken()}`)
      .append('Domain', this._auth0Config.data.auth0.domain);
    return this._http.get(this.configTrepiedServer.url + '/' + uri, {headers, params}).pipe(
      timeout(50000),
      catchError((err) => {
        return this.handleError(err);
      })
      //,retry(this.configTrepiedServer.retry)
    )
      .pipe(map(
        (res) => {
          return res;
        }), catchError((err) => {
          return this.handleError(err);
        }));
  }
  protected _serverGetBlob(uri: string, params = new HttpParams()): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/html')
      .append('Authorization', `Bearer ${this._auth.getAccessToken()}`)
      .append('Domain', this._auth0Config.data.auth0.domain)
      .append('responseType', 'blob')
      .append('observe', 'response');
    return this._http.get(this.configTrepiedServer.url + '/' + uri, {headers, params, responseType: 'blob', observe: 'response'})
      .pipe(
        timeout(50000), catchError((err) => {
          return this.handleError(err, true);
        }
        )
        //,retry(this.configTrepiedServer.retry)
      )
      .pipe(map(
        (res: HttpResponse<Blob>) => {
          return res.body;
        }), catchError((err) => {
          return this.handleError(err);
        }));
  }
  protected _serverPost(uri: string, data, contentType: string = 'text/html', params = new HttpParams()): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this._auth.getAccessToken()}`)
      .append('Domain', this._auth0Config.data.auth0.domain);
    if (contentType) {
      headers = headers.append('Content-Type', contentType)

    }
    return this._http.post(this.configTrepiedServer.url + '/' + uri, data, {headers, params}).pipe(map(
      (res) => {
        return res;
      }), catchError((err) => {
        return this.handleError(err);
      }));
  }


  protected _getExploitingCompany(): Observable<any> {
    return this._serverGet(this._getUrlExploitingCompany());
  }
  protected _getWorkbookList(): Observable<any> {
    return this._serverGet(this._getUrlWorkbookList());
  }
  protected _getSignables(id: string): Observable<any> {
    return this._serverGet(this._getUrlSignables(id));
  }
  protected _getSignable(id: string): Observable<any> {
    return this._serverGet(this._getUrlSignable(id));
  }
  getDocument(id: string, template: boolean = false): Observable<any> {
    return this._serverGetBlob(this._getUrlDocument(id, template));
  }
  getCheckpointDocument(id: string, audit_id: string): Observable<any> {
    return this._serverGetBlob(this._getUrlCheckpointDocument(id, audit_id));
  }
  getWorkbookDocument(workbook_id: string, id: string, template: boolean = false): Observable<any> {
    return this._serverGetBlob(this._getUrlWorkbookDocument(workbook_id, id, template));
  }

  protected _getUrlExploitingCompany() {
    return 'eu';
  }
  protected _getUrlWorkbookList() {
    return 'workbook';
  }
  protected _getUrlSignables(id: string) {
    return 'workbook/' + id;
  }
  protected _getUrlSignable(id: string) {
    return 'signable/' + id;
  }
  protected _getUrlWorkbookDocument(workbook_id: string, id: string, template: boolean = false) {
    return (template ? 'template' : 'document') + '/' + workbook_id + '/' + id;
  }
  protected _getUrlDocument(id: string, template: boolean = false) {
    return (template ? 'template' : 'document') + '/' + id;
  }

  protected _getUrlCheckpointDocument(id: string, audit_id: string) {
    return 'checkpoint/' + audit_id + '/' + id;
  }

  getUrlHome() {
    return 'docs';
  }
  getUrlSignables(id: string) {
    return 'docs/' + id;
  }
  getUrlSignable(id: string) {
    return 'doc/' + id;
  }

  private handleError(error: any, isBlob: boolean = false) {
    let err = null;
    if (isBlob && error && error.error) {
      err = error;
      err.error = {
        message: error.statusText,
        status: error.status,
      }
    } else {
      if (error && error.error && error.error.message) {
        err = error.error;
      } else {
        err = {error: (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error'};
        //       if (error.workbook) {
        //         err.workbook = error.workbook;
        //       }
      }
    }
    return throwError(err);
  }
  getState(entity: EntitySignable, workbook_status, pdp_status) {
    let state = (workbook_status && workbook_status === 'archived') ? SignableState.ARCHIVED : SignableState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === SignableStatus.VALIDATED) {
        state = SignableState.PREPARED;
      } else if (entity.status === SignableStatus.OUTDATED) {
        state = SignableState.OUTDATED;
      } else if (entity.status === SignableStatus.SIGNING && !entity.withoutSignatories) {
        state = SignableState.SIGNING;
      } else {
        if (pdp_status && pdp_status === SignableStatus.SIGNED) {
          state = SignableState.VALID;
        } else if (pdp_status && pdp_status === SignableStatus.OUTDATED) {
          state = SignableState.OUTDATED;
        } else {
          state = entity.withoutSignatories ? SignableState.VALIDATED : ((entity.documentType === 'pdp' || (entity.documentType === 'spt' && this.isInProgess(entity))) ? SignableState.VALID : SignableState.SIGNED);
        }
      }
    }
    return state;
  }
  isInProgess(entity) {
    return entity.dateStart && (new Date(entity.dateStart).getTime() <= (new Date()).getTime() && (new Date(entity.dateEnd ? entity.dateEnd : entity.dateStart).getTime() + (24 * 3600 * 1000)) >= (new Date()).getTime());
  }

  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }
  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }
  bypassSecurityTrustScript(value: string): SafeScript {
    return this.sanitizer.bypassSecurityTrustScript(value);
  }
  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
//TODO: = PDPState / ICPState ?
export class SignableState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;
  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Obsolète',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications des activités, risques et mesures se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications des activités, risques et mesures ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est validée, en attente de signature du plan de prévention',
    'L\'edition a été signée par l\'ensemble des signataires, en attente de signature du plan de prévention',
    'L\'edition est valide, le plan de prévention est signé',
    'L\'edition est obsolète, une edition plus récente a valide',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return SignableState.LABELS;
  }
  static getDescriptions() {
    return SignableState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return SignableState.LABELS[status] ? SignableState.LABELS[status] : 'status' + status;
  }
  static getDescription(status: number) {
    return SignableState.DESCRIPTIONS[status] ? SignableState.DESCRIPTIONS[status] : 'status: ' + status;
  }

}
