import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UUID} from 'angular2-uuid';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {
  comparableString, ConfirmDialogComponent, EntityDocumentFile, EntityDocumentMetadata,
  LoggerService, Contact, DocumentService, ConfigEmailService, EntityConfigEmail, getBase64, dataURLtoFile
} from 'app/shared';
import {
  PreventionPlanService, OfficeService,
  AuditService,
  EntityAudit, AuditSignatory, EntityAuditCheckpoints, AuditCheckpoint, AuditCheckpointCompliance,
  AuditCheckpointsService, Checkpoint,
  EntityPreventionPlan, DocumentationService,
  EntityStep, Activity, EntityOffice, EntityExternalCompany, WorkbookOfficeService, WorkbookExternalCompanyService,
  SiteService, EntitySite, EntityZone, EntityWorkbookMeasure
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from '../abstractSignableInfo.component';

@Component({
  selector: 'app-signable-audit-info',
  templateUrl: './auditInfo.component.html',
  styleUrls: ['./auditInfo.component.scss'],
  animations: [
    trigger('collapse', [
      state('fold', style({
        height: 0, overflow: 'hidden'
      })),
      state('unfold', style({
        height: '*', overflow: 'hidden'
      })),
      transition('unfold <=> fold', animate('400ms ease-in-out'))
    ])
  ]
})
export class AuditInfoComponent extends AbstractSignableInfoComponent {
  static FLAG_SUFFIX: string = 'vc';
  autoSave = false;
  public entity: EntityAudit;
  workbook: EntityPreventionPlan;
  @Input() step: EntityStep;

  protected _onFormChange: Subscription;

  minDateStart: Date;
  maxDateEnd: Date;
  dateError: string = '';
  dateValid: boolean = true;
  timeStartError: string = '';
  timeEndError: string = '';


  oeexList: (EntityOffice | EntityExternalCompany)[] = [];
  oeexNames: {[oeex_id: string]: string} = {};
  contactList: {[oeex_id: string]: Contact[]} = {};

  protected _auditorChangeSubscription: Subscription;
  filteredAuditors: (EntityOffice | EntityExternalCompany)[] = [];

  protected _auditorContactChangeSubscription: Subscription;
  filteredAuditorContacts: (EntityOffice | EntityExternalCompany)[] = [];

  protected _oeexSubscription: Subscription;
  selectedOeex: string[] = [];
  selectedLocations: EntitySite[] = [];
  selectedActivities: string[] = [];
  selectedRisks: {[activity_id: string]: string[]} = {};
  selectedMeasures: {[activity_id: string]: {[risk_name: string]: EntityWorkbookMeasure[]}} = {};
  selectedCompanies: {[activity_id: string]: {[risk_name: string]: {[measure_id: string]: string}}} = {};

  protected _auditCheckpointsSubscription: Subscription;
  otherCheckpoints: Checkpoint[] = [];
  selectedCompaniesOther: {[checkpoint_id: string]: string} = {};
  newCheckpoint: string = '';
  selectedCompaniesNewCheckpoint: string = '';
  dangerInstructions: string = '';

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  autorizedSites: string[];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  placeHolderDate: string = 'Date de la visite';

  isCollapsed: {[id: string]: boolean} = {};

  synthesis: {[oeex_id: string]: {name: string, count: number, compliant: number, improper: number, nc: number}} = {};
  withSignatories: boolean = false;
  addSignatoryOeex: string = '';
  addSignatoryContact: string = '';
  addSignatoryEmail: string = '';

  protected _configEmailSubscription: Subscription;
  public regexEmailInvalid: string;
  public regexInternal: string;
  public regexExternal: string;
  public msgInternal: string;
  public msgExternal: string;

  file: {[auditCheckpoint_id: string]: File} = {};
  currentCheckpoint: AuditCheckpoint;
  pintura_file_data: string = '';

  file_mimes: string[] = ['image/png', 'image/jpeg', 'image/gif'];

  requireCheckpointLimit: boolean = false;
  requireCheckpointResponsable: boolean = false;

  protected _repoAuditorSubscription: Subscription;
  showMetas: {[ac_id: string]: boolean} = {};

  constructor(
    protected _router: Router,
    protected _documentService: DocumentService,
    protected _officeService: OfficeService,
    protected _configEmailService: ConfigEmailService,
    protected _auditCheckpointsService: AuditCheckpointsService,
    protected _siteService: SiteService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: AuditService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);

    this._configEmailSubscription = this._configEmailService.getSingleOne().subscribe((ce: EntityConfigEmail) => {
      if (ce) {
        this.regexInternal = ce.regexInternal;
        this.regexExternal = ce.regexExternal;
        this.msgInternal = ce.msgInternal;
        this.msgExternal = ce.msgExternal;
        this.regexEmailInvalid = EntityConfigEmail.EMAIL_REGEX;
      }
    });
    this._auditCheckpointsSubscription = this._auditCheckpointsService.getSingleOne().subscribe((ac: EntityAuditCheckpoints) => {
      if (ac) {

        this.requireCheckpointLimit = !!ac.requireCheckpointLimit;
        this.requireCheckpointResponsable = !!ac.requireCheckpointResponsable;
        this.otherCheckpoints = [];
        if (ac.enabled) {
          ac.checkpoints.forEach((e) => {
            this.otherCheckpoints.push(e);
            this.selectedCompaniesOther[e.id] = '';
          });
        }
        if (ac.enabledInstructions) {
          this.dangerInstructions = ac.instructions;
        }
      }
    });
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list;
      }
    });
  }
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (changes) {
      if (changes['step'] && this.step) {
        //this.selectedOeex = [];
        this.refreshCheckpoints();
        this.minDateStart = new Date(this.step.dateStart);
        this.maxDateEnd = new Date(this.step.dateEnd);
        const now = new Date();
        this.entity.date = (this.maxDateEnd && this.maxDateEnd.getTime() < now.getTime()) ? new Date(this.maxDateEnd) : now;
        if (this.step.strictLocations) {
          this.autorizedSites = this.step.getLocations().map((e) => (e._id));
        } else {
          this.autorizedSites = null;
        }

      }
    }
  }

  protected _init(data) {
    super._init(data);
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.oeexList = list;
        this.contactList = {};
        this.oeexNames = {};
        this.oeexList.forEach((oeex) => {
          this.oeexNames[oeex._id] = oeex.name;
          this.contactList[oeex._id] = oeex.contacts;//.filter((c) => (c.signatoryPermit));
        });

        if (this.entity) {
          if (!this.entity._id) {
            const user_id = this._entityService.getUserId();
            const userServices = this.oeexList.filter((e) => (
              e.documentType === WorkbookOfficeService.ENTITY_TYPE
              && e.contacts && e.contacts.filter((c) => (c.user_id === user_id)).length
            ));
            if (userServices.length) {
              this.entity.auditor = new EntityOffice(userServices[0]);
              const userContact = this.entity.auditor.contacts.find((c) => (c.user_id === user_id));
              if (userContact) {
                this.entity.auditorContact = Object.assign(new Contact(), userContact);
              }
            }
            if (!this.entity.auditorContact) {
              this.getRepoAuditor();
            }
            let eexs = this.oeexList.filter((e) => (e.documentType === WorkbookExternalCompanyService.ENTITY_TYPE));
            if (eexs.length === 1) {
              this.entity.oEex = eexs.map(e => e);
            }
          } else {
            this.selectedOeex = this.entity.oEex.map((e) => (e._id));
            this.refreshCheckpoints();
            this.withSignatories = !this.entity.withoutSignatories;
          }
        }
      }
    });

    if (this.entity) {
      if (!this.entity.date) {
        const now = new Date();
        if (this.step) {
          this.minDateStart = new Date(this.step.dateStart);
          this.maxDateEnd = new Date(this.step.dateEnd);
        }
        this.entity.date = (this.maxDateEnd && this.maxDateEnd.getTime() < now.getTime()) ? new Date(this.maxDateEnd) : now;
      }

      if (this.step) {
        this.minDateStart = new Date(this.step.dateStart);
        this.maxDateEnd = new Date(this.step.dateEnd);
        if (this.step.strictLocations) {
          this.autorizedSites = this.step.getLocations().map((e) => (e._id));
        } else {
          this.autorizedSites = null;
        }
      }
      this.selectedOeex = this.entity.oEex.map((e) => (e._id));
      this.onFormChange();
    }
  }
  getRepoAuditor() {
    if (!this._repoAuditorSubscription) {
      this._repoAuditorSubscription = this._officeService.getUserContactsOffices().subscribe((list) => {
        if (list && list.length && list[0] && list[0].contacts && list[0].contacts[0]) {
          const user_id = this._entityService.getUserId();
          const userOffice: EntityOffice = this._workbookService.officeService.getNewEntity(list[0], true);
          userOffice.repo_id = list[0]._id
          this.entity.auditor = userOffice;
          const userContact = list[0].contacts.find((c) => (c.user_id === user_id));
          if (userContact) {
            this.entity.auditorContact = Object.assign(new Contact(), userContact);
          }
        }
      })
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._auditCheckpointsSubscription) {
      this._auditCheckpointsSubscription.unsubscribe();
    }
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._auditorChangeSubscription) {
      this._auditorChangeSubscription.unsubscribe();
    }
    if (this._auditorContactChangeSubscription) {
      this._auditorContactChangeSubscription.unsubscribe();
    }
    if (this._repoAuditorSubscription) {
      this._repoAuditorSubscription.unsubscribe();
    }
    if (this._onFormChange) {
      this._onFormChange.unsubscribe();
    }
  }
  protected onFormChange() {
    if (this.form && !this._onFormChange) {
      const that = this;
      this._onFormChange = this.form.control.valueChanges
        .subscribe(() => {
          that.updateSynthesis();
        });
    }
  }

  onEmailChange() {

  }
  getEmailError() {
    if (this.addSignatoryEmail) {
      let reg = this.regexEmailInvalid;
      const regex = RegExp(reg);
      let res = regex.exec(this.addSignatoryEmail);
      if (res && res.length) {
        return (this.addSignatoryOeex
          && this.oeexList.findIndex((e) => (e._id === this.addSignatoryOeex && e.documentType === WorkbookOfficeService.ENTITY_TYPE)) !== -1)
          ? this.msgInternal
          : this.msgExternal;
      } else {
        return EntityConfigEmail.EMAIL_INVALID;
      }
    }
  }
  percentage(x: number, y: number) {
    return Math.round(100 * x / y);
  }
  refreshCheckpoints() {
    this.selectedCompanies = {};
    this.selectedLocations = [];
    this.selectedActivities = [];
    this.selectedRisks = {};
    this.selectedMeasures = {};
    if (this.step && this.step.implementation && this.step.implementation.step) {
      if (this.step.implementation.step.activities && this.step.implementation.activities) {
        this.step.implementation.step.activities.forEach((a) => {
          if (this.step.implementation.risks[a]) {
            Object.keys(this.step.implementation.risks[a]).forEach((risk_name) => {
              if (this.step.implementation.risks[a][risk_name].measures) {
                this.step.implementation.risks[a][risk_name].measures.forEach((m) => {
                  if (m.global ||
                    (//selectedOeex liées (directement ou via scopedMeasures)
                      this.step.implementation.activities[a]
                      && this.step.implementation.activities[a].companies
                      && this.step.implementation.activities[a].companies.filter((c) => (this.selectedOeex.indexOf(c) !== -1)).length
                    )
                    ||
                    (//mesure scopée
                      this.step.implementation.step.scopedMeasures
                      && this.step.implementation.step.scopedMeasures[a]
                      && Object.keys(this.step.implementation.step.scopedMeasures[a])
                        .filter((measure_id) => (this.step.implementation.step.scopedMeasures[a][measure_id].filter((c) => (this.selectedOeex.indexOf(c) !== -1))))
                        .length > 0
                    )) {
                    if (this.selectedActivities.indexOf(a) === -1) {
                      this.selectedActivities.push(a)
                    }
                    this.addMeasure(a, this.step.implementation.risks[a][risk_name].name, m);
                  }
                });
              }
            });
          }
        });
      }
      if (this.step && this.step.implementation && this.step.implementation.step) {
        const locations = this.step.implementation.step.getLocations();
        if (this.step && this.step.template_id) {
          const s = new EntitySite(this.step.site);
          const x = locations.findIndex((e) => (e._id === this.step.site._id));
          if (x !== -1) {
            s.zones = locations[x].zones.filter((z) => (z.name === '' || z._id === this.step.zone._id));
          }
          this.selectedLocations = [s];
        } else {
          this.selectedLocations = this.step.implementation.step.getLocations();
        }
        this.selectedLocations.forEach((s) => {
          s.zones.forEach((z) => {
            z.measures.forEach((m) => {
              const rsid = (m.type === '' || m.type === 'local' || m.type === 'global') ? m.risk_id : m.type;
              let rs: any = z.risks.find((e) => (e._id === rsid));
              if (!rs) {
                rs = z.situations.find((e) => (e._id === rsid));
              }
              if (rs && rs.name) {
                this.addMeasure(z._id, rs.name, m);
              }
            });
          });
        });
      }

      this.updateSynthesis();
    }
  }

  addMeasure(activity_id, risk_name, measure: EntityWorkbookMeasure) {
    if (!this.selectedRisks[activity_id]) {
      this.selectedRisks[activity_id] = [];
    }
    this.selectedRisks[activity_id].push(risk_name);
    if (!this.selectedMeasures[activity_id]) {
      this.selectedMeasures[activity_id] = {};
    }
    if (!this.selectedMeasures[activity_id][risk_name]) {
      this.selectedMeasures[activity_id][risk_name] = [];
    }
    this.selectedMeasures[activity_id][risk_name].push(measure);

    if (!this.selectedCompanies[activity_id]) {
      this.selectedCompanies[activity_id] = {};
    }
    if (!this.selectedCompanies[activity_id][risk_name]) {
      this.selectedCompanies[activity_id][risk_name] = {};
    }
    this.selectedCompanies[activity_id][risk_name][measure._id] = '';

    this.updateSynthesis();
  }

  switchDoc(event, doc) {
    if (this.entity) {
      if (event.checked) {
        if (this.entity.documents.findIndex((e) => (e._id === doc._id)) === -1) {
          this.entity.documents.push(doc);
        }
      } else {
        let i = this.entity.documents.findIndex((e) => (e._id === doc._id));
        if (i !== -1) {
          this.entity.documents.splice(i, 1)
        }
      }
    }
  }

  getAuditCheckpoints(activity_id: string, risk_name: string, measure_id: string) {
    return this.entity.checkpoints.filter((e) => (
      e.activity_id === activity_id
      && e.risk_name === risk_name
      && e.measure_id === measure_id
    ));
  }
  setAuditCheckpoints(activity_id: string, risk_name: string, measure_id: string, value) {
    const i = this.entity.checkpoints.findIndex((e) => (
      e.activity_id === activity_id
      && e.risk_name === risk_name
      && e.measure_id === measure_id
    ));
    if (i !== -1) {
      this.entity.checkpoints[i].compliance = value;
      this.updateSynthesis();
    }
  }
  getAuditLocationCheckpoints(activity_id: string, risk_name: string, measure_id: string) {
    return this.entity.locationCheckpoints.filter((e) => (
      e.activity_id === activity_id
      && e.risk_name === risk_name
      && e.measure_id === measure_id
    ));
  }

  getAuditLocationCheckpoint(activity_id: string, risk_name: string, measure_id: string, company_id: string) {

    return this.entity.locationCheckpoints.find((e) => (
      e.activity_id === activity_id
      && e.risk_name === risk_name
      && e.measure_id === measure_id
      && e.company_id === company_id
    ));
  }
  getAuditCheckpoint(activity_id: string, risk_name: string, measure_id: string, company_id: string) {

    return this.entity.checkpoints.find((e) => (
      e.activity_id === activity_id
      && e.risk_name === risk_name
      && e.measure_id === measure_id
      && e.company_id === company_id
    ));
  }

  addAuditCheckpoint(activity: Activity, risk_name: string, company_id: string, measure) {
    this.entity.checkpoints.push(new AuditCheckpoint({
      activity_id: activity._id,
      activity_name: activity.name,
      activity_group: activity.group,
      risk_name: risk_name,
      measure_id: measure._id,
      company_id: company_id,
      name: measure.name,
      compliance: AuditCheckpointCompliance.COMPLIANT
    }));
    setTimeout(() => {
      this.selectedCompanies[activity._id][risk_name][measure._id] = '';
    }, 0);
    this.updateSynthesis();
  }
  addLocationCheckpoint(site: EntitySite, zone: EntityZone, risk_name: string, company_id: string, measure) {
    this.entity.locationCheckpoints.push(new AuditCheckpoint({
      activity_id: zone._id,
      activity_name: zone.name,
      activity_group: site.name,
      risk_name: risk_name,
      measure_id: measure._id,
      company_id: company_id,
      name: measure.name,
      compliance: AuditCheckpointCompliance.COMPLIANT
    }));
    setTimeout(() => {
      this.selectedCompanies[zone._id][risk_name][measure._id] = '';
    }, 0);
    this.updateSynthesis();
  }

  hasInvolved(activity_id: string, risk_name: string, measure_id: string) {
    let returned = false;
    if (this.selectedOeex && this.selectedMeasures && this.selectedMeasures[activity_id] && this.selectedMeasures[activity_id][risk_name]) {
      const m = this.selectedMeasures[activity_id][risk_name].find((_m) => (_m._id === measure_id));
      if (m) {
        const scoped =
          this.step.implementation.step.scopedMeasures
          && this.step.implementation.step.scopedMeasures[activity_id]
          && this.step.implementation.step.scopedMeasures[activity_id][measure_id]
          && this.step.implementation.step.scopedMeasures[activity_id][measure_id].length;
        returned =
          (m.global ||
            (//selectedOeex liées (directement ou via scopedMeasures)
              !scoped && (
                (this.step.implementation.activities[activity_id]
                  && this.step.implementation.activities[activity_id].companies
                  && this.step.implementation.activities[activity_id].companies.filter((company_id) => (this.selectedOeex.indexOf(company_id) !== -1)).length > 0
                ) || (this.selectedLocations.findIndex((s) => (s.zones.findIndex((z) => (z._id === activity_id)) !== -1)) !== -1)
              )
            )
            ||
            (//mesure scopée
              scoped && this.step.implementation.step.scopedMeasures[activity_id][measure_id].filter((company_id) => (this.selectedOeex.indexOf(company_id) !== -1)).length > 0
            )
          );
      }
    }
    return returned;
  }

  isInvolved(activity_id: string, risk_name: string, measure_id: string, company_id: string) {
    let returned = false;
    if (this.selectedMeasures && this.selectedMeasures[activity_id] && this.selectedMeasures[activity_id][risk_name]) {
      const m = this.selectedMeasures[activity_id][risk_name].find((_m) => (_m._id === measure_id));
      if (m) {
        const scoped =
          this.step.implementation.step.scopedMeasures
          && this.step.implementation.step.scopedMeasures[activity_id]
          && this.step.implementation.step.scopedMeasures[activity_id][measure_id]
          && this.step.implementation.step.scopedMeasures[activity_id][measure_id].length;
        returned =
          (m.global ||
            (//selectedOeex liées (directement ou via scopedMeasures)
              !scoped && (
                (this.step.implementation.activities[activity_id]
                  && this.step.implementation.activities[activity_id].companies
                  && this.step.implementation.activities[activity_id].companies.indexOf(company_id) !== -1
                ) || (this.selectedLocations.findIndex((s) => (s.zones.findIndex((z) => (z._id === activity_id)) !== -1)) !== -1)
              )
            )
            ||
            (//mesure scopée
              scoped && this.step.implementation.step.scopedMeasures[activity_id][measure_id].indexOf(company_id) !== -1
            )
          );
      }
    }
    return returned;
  }

  updateSynthesis() {
    this.synthesis = this.entity.getSynthesis();

  }

  getOtherAuditCheckpoints(auditCheckpoint_id: string) {
    return this.entity.checkpoints.filter((e) => (
      e.auditCheckpoint_id === auditCheckpoint_id
      && e.activity_id === ''
      && e.risk_name === ''
      && e.measure_id === ''
    ));
  }

  getOtherAuditCheckpoint(auditCheckpoint_id: string, company_id: string, name: string = null) {
    return this.entity.checkpoints.find((e) => (
      e.auditCheckpoint_id === auditCheckpoint_id
      && e.company_id === company_id
      && e.activity_id === ''
      && e.risk_name === ''
      && e.measure_id === ''
      && (name === null || e.name === name)
    ));
  }

  addOtherAuditCheckpoint(checkpoint_id: string, company_id: string, measure_name) {
    this.entity.checkpoints.push(new AuditCheckpoint({
      auditCheckpoint_id: checkpoint_id,
      company_id: company_id,
      name: measure_name,
      compliance: AuditCheckpointCompliance.COMPLIANT
    }));
    setTimeout(() => {
      this.newCheckpoint = '';
      this.selectedCompaniesNewCheckpoint = '';
    }, 0);
    this.updateSynthesis();
  }
  removeLocationAuditCheckpoint(checkpoint_id) {
    const i = this.entity.locationCheckpoints.findIndex((e) => (e.id === checkpoint_id));
    if (i !== -1) {
      if (this.entity.locationCheckpoints[i].description || this.entity.locationCheckpoints[i].remark) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRef.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce point de contrôle ?';
        dialogRef.componentInstance.buttonMessage = 'Supprimer';

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.entity.locationCheckpoints.splice(i, 1);
            this.updateSynthesis();
          }
        });
      } else {
        this.entity.locationCheckpoints.splice(i, 1);
        this.updateSynthesis();
      }
    }
  }
  removeAuditCheckpoint(checkpoint_id) {
    const i = this.entity.checkpoints.findIndex((e) => (e.id === checkpoint_id));
    if (i !== -1) {
      if (this.entity.checkpoints[i].description || this.entity.checkpoints[i].remark) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRef.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce point de contrôle ?';
        dialogRef.componentInstance.buttonMessage = 'Supprimer';

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.entity.checkpoints.splice(i, 1);
            this.updateSynthesis();
          }
        });
      } else {
        this.entity.checkpoints.splice(i, 1);
        this.updateSynthesis();
      }
    }
  }
  /*
  addAudited() {
    let i = this.entity.audited.findIndex((op) => (!op.name && !op.job));
    if (i !== -1 && i !== (this.entity.audited.length - 1)) {
      this.entity.audited.splice(i, 1);
    }
    i = this.entity.audited.findIndex((op) => (!op.name && !op.job));
    if (i === -1) {
      this.entity.audited.push(new Audited());
    }
  }
  */
  getOeexName(id: string) {
    const _oeex = this.oeexList.find((e) => (e._id === id));
    return _oeex ? _oeex.name : id;
  }

  updateOeexs() {
    this.entity.oEex = this.selectedOeex.map((oeex_id) => (this.oeexList.find((e) => (e._id === oeex_id))));
    this.refreshCheckpoints();
  }

  switchOeex(event, id) {
    if (this.entity && this.selectedOeex) {
      if (event.checked) {
        if (this.selectedOeex.indexOf(id)) {
          this.selectedOeex.push(id);
          const _oeex = this.oeexList.find((e) => (e._id === id));
          if (_oeex && this.entity.oEex.findIndex((e) => (e._id === id)) === -1) {
            this.entity.oEex.push(_oeex);
          }
        }
      } else {
        let i = this.selectedOeex.indexOf(id);
        if (i !== -1) {
          this.selectedOeex.splice(i, 1);
        }
        i = this.entity.oEex.findIndex((e) => (e._id === id));
        if (i !== -1) {
          this.entity.oEex.splice(i, 1);
        }
      }
    }
    this.refreshCheckpoints();
  }
  /*
    switchActivity(event, id) {
      if (this.entity && this.entity.activities) {
        if (event.checked) {
          if (this.entity.activities.indexOf(id)) {
            this.entity.activities.push(id);
          }
        } else {
          let i = this.entity.activities.indexOf(id);
          if (i !== -1) {
            this.entity.activities.splice(i, 1)
          }
        }
      }
    }
    */
  initSearchSite() {
    this.filteredSites = this.getFilteredByName(this.siteList, ((this.entity && this.entity.site) ? this.entity.site.name : ''));
    if (this.autorizedSites) {
      this.filteredSites = this.filteredSites.filter((e) => (this.autorizedSites.indexOf(e._id) !== -1));
    }
    if (this.filteredSites && this.filteredSites.length > 200) {
      this.filteredSites = [];
    }
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredSites = this.getFilteredByName(this.siteList, value);
          if (this.autorizedSites) {
            this.filteredSites = this.filteredSites.filter((e) => (this.autorizedSites.indexOf(e._id) !== -1));
          }
          if (this.filteredSites && this.filteredSites.length > 200) {
            this.filteredSites = [];
          }
        });
    }
  }

  initSearchAuditor() {
    this.filteredAuditors = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.auditor) ? this.entity.auditor.name : ''));
    if (this.filteredAuditors && this.filteredAuditors.length > 200) {
      this.filteredAuditors = [];
    }
    if (!this._auditorChangeSubscription && this.form && this.form.controls && this.form.controls['auditor_name']) {
      this._auditorChangeSubscription = this.form.controls['auditor_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredAuditors = this.getFilteredByName(this.oeexList, value);
          if (this.filteredAuditors && this.filteredAuditors.length > 200) {
            this.filteredAuditors = [];
          }
        });
    }
  }

  initSearchAuditorContact() {
    this.filteredAuditorContacts = this.getFilteredByName(this.contactList[this.entity.auditor._id], ((this.entity && this.entity.auditorContact) ? this.entity.auditorContact.name : ''));
    if (this.filteredAuditorContacts && this.filteredAuditorContacts.length > 200) {
      this.filteredAuditorContacts = [];
    }
    if (!this._auditorContactChangeSubscription && this.form && this.form.controls && this.form.controls['auditorContact_name']) {
      this._auditorContactChangeSubscription = this.form.controls['auditorContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredAuditorContacts = this.getFilteredByName(this.contactList[this.entity.auditor._id], value);
          if (this.filteredAuditorContacts && this.filteredAuditorContacts.length > 200) {
            this.filteredAuditorContacts = [];
          }
        });
    }
  }

  setAuditor(value: EntityOffice, event) {
    if (event && event.source && event.source.selected && this.entity) {
      this.entity.auditor = new EntityOffice(value);
    }
  }

  setAuditorContact(value: Contact) {
    if (this.entity && this.entity.auditor) {
      this.entity.auditorContact = Object.assign(new Contact(), value);
    }
  }
  isSignatory(company_id: string, contact_id: string) {
    return this.entity.auditSignatories.findIndex((e) => (e.company && e.company._id === company_id && e.contact && e.contact.id === contact_id)) !== -1;
  }
  removeSignatory(company_id: string, contact_id: string) {
    let i = this.entity.auditSignatories.findIndex((e) => (e.company && e.company._id === company_id && e.contact && e.contact.id === contact_id));
    if (i !== -1) {
      this.entity.auditSignatories.splice(i, 1);
    }
  }
  switchSignatory(company_id: string, contact: Contact) {
    if (this.isSignatory(company_id, contact.id)) {
      this.removeSignatory(company_id, contact.id)
    } else {
      this.addSignatory(company_id, contact);
    }
  }
  addSignatory(company_id: string, contact: Contact) {
    let oeex = this.oeexList.find((e) => (e._id === company_id));
    if (oeex && contact) {
      this.entity.auditSignatories.push(new AuditSignatory({company: oeex, contact: contact}));
    }
  }
  addNewSignatory() {
    let oeex = this.oeexList.find((e) => (e._id === this.addSignatoryOeex));
    if (oeex) {
      let contact = new Contact();
      contact.name = this.addSignatoryContact;
      contact.email = this.addSignatoryEmail;
      this.entity.auditSignatories.push(new AuditSignatory({company: oeex, contact: contact, local: true}));
      this.addSignatoryOeex = '';
      this.addSignatoryContact = '';
      this.addSignatoryEmail = '';
    }
  }
  /*
  setOperator(index: number, oeex: EntityOffice, contact: Contact) {
    if (this.entity && this.entity.audited[index]) {
      this.entity.audited[index] = new Audited(
      );
    }
  }
  */
  protected getFilteredByName(collection: any[], value: string = '', signatoryPermit: boolean = false): any[] {
    return (collection && collection.length)
      ? (value ?
        collection.filter(e => ((!signatoryPermit || e.signatoryPermit) && e.name && comparableString(e.name).indexOf(comparableString(value)) !== -1))
        : collection.filter(e => (!signatoryPermit || e.signatoryPermit)))
      : [];
  }
  public fileRemoveEvent(checkpoint_id: string) {
    if (checkpoint_id && this.entity) {
      if (this.entity.checkpoints) {
        const i = this.entity.checkpoints.findIndex((e) => (e.id === checkpoint_id));
        if (i !== -1) {
          this.entity.checkpoints[i].document = null;
        }
        if (this.file[checkpoint_id]) {
          delete this.file[checkpoint_id];
        }
      }
      if (this.entity.locationCheckpoints) {
        const i = this.entity.locationCheckpoints.findIndex((e) => (e.id === checkpoint_id));
        if (i !== -1) {
          this.entity.locationCheckpoints[i].document = null;
        }
        if (this.file[checkpoint_id]) {
          delete this.file[checkpoint_id];
        }
      }
    }
  }
  public fileChangeEvent(checkpoint_id: string, event: any) {
    if (checkpoint_id && this.entity) {
      if (this.entity.checkpoints) {
        const i = this.entity.checkpoints.findIndex((e) => (e.id === checkpoint_id));
        if (i !== -1 && event && event.addedFiles && event.addedFiles[0]) {
          this.file[checkpoint_id] = event.addedFiles[0];
          this.entity.checkpoints[i].document = this._documentService.documentMetadataService.getNewEntityFromFile(this.file[checkpoint_id], this._router.url, '', this.entity.checkpoints[i].document ? this.entity.checkpoints[i].document.data_id : '');
          this.entity.checkpoints[i].document._id = UUID.UUID();
        }
        if (this.entity.locationCheckpoints) {
          const i = this.entity.locationCheckpoints.findIndex((e) => (e.id === checkpoint_id));
          if (i !== -1 && event && event.addedFiles && event.addedFiles[0]) {
            this.file[checkpoint_id] = event.addedFiles[0];
            this.entity.locationCheckpoints[i].document = this._documentService.documentMetadataService.getNewEntityFromFile(this.file[checkpoint_id], this._router.url, '', this.entity.locationCheckpoints[i].document ? this.entity.locationCheckpoints[i].document.data_id : '');
            this.entity.locationCheckpoints[i].document._id = UUID.UUID();
          }
        }
      }
    }
  }
  public getFileSavers() {
    return Object.keys(this.file)
      .filter((checkpoint_id) => (!!this.file[checkpoint_id]))
      .map((checkpoint_id) => {
        const dm = this.entity.checkpoints.find((cp) => (cp.id === checkpoint_id));
        return this._documentService.uploadFilePromise(this.file[checkpoint_id], this.file[checkpoint_id].name, this._router.url, '', dm && dm.document.data_id ? dm.document.data_id : null);
      }
      );
  }
  public setFiles(savedFiles: EntityDocumentFile[]) {
    Object.keys(this.file)
      .filter((checkpoint_id) => (!!this.file[checkpoint_id]))
      .forEach((checkpoint_id, index) => {
        if (savedFiles[index]) {
          const i = this.entity.checkpoints.findIndex((cp) => (cp.id === checkpoint_id));
          if (i !== -1) {
            if (this.entity.checkpoints[i].document) {
              this.entity.checkpoints[i].document.data_id = savedFiles[index]._id;
              delete this.file[checkpoint_id];
            }
          }
        }
      });
  }

  public showPintura(ac: AuditCheckpoint) {
    if (ac && ac.document && ac.id && this.isImage(ac.document)) {
      if (this.file[ac.id]) {
        getBase64(this.file[ac.id]).then((data_url) => {
          this.pintura_file_data = data_url.toString();
          this.currentCheckpoint = ac;
        });
      } else if (ac.document.data_id) {
        this._documentService.getAttachmentFile(ac.document.data_id, ac.document.mime, ac.document.displayName)
          .toPromise()
          .then((file: File) => {
            getBase64(file).then((data_url) => {
              this.pintura_file_data = data_url.toString();
              this.currentCheckpoint = ac;
            });
          });
      }
    }
  }
  onPinturaClose(data) {
    this.pintura_file_data = '';
  }
  public updatePinturaFile(data: string) {
    if (this.currentCheckpoint && this.currentCheckpoint.document) {
      const file = dataURLtoFile(data.toString(), this.currentCheckpoint.document.displayName);
      if (file) {
        this.file[this.currentCheckpoint.id] = file;
        this.currentCheckpoint.document = this._documentService.documentMetadataService.getNewEntityFromFile(file, this._router.url, '', this.currentCheckpoint.document ? this.currentCheckpoint.document.data_id : '');
        this.currentCheckpoint.document._id = UUID.UUID();
        this.pintura_file_data = '';
      }
    }
  }

  isImage(doc: EntityDocumentMetadata) {
    return !!doc && !!doc.mime && this.file_mimes.indexOf(doc.mime) !== -1
  }
}
