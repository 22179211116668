import { UUID } from 'angular2-uuid';

export interface ISignature {
  name: string;
  user_id: string;
  email: string;
  contact_id: string;
  company_id: string;
  data: string;
  signedAt: Date;
  company: string;
  job: string;
  companyType: string;
}

export class Signature implements ISignature {

  id: string;
  signedAt: Date;
  contact_id: string;
  user_id: string;
  name: string;
  email: string;
  job: string;
  company_id: string;
  company: string;
  companyType: string;
  data: string;
  display: boolean;

  constructor(data) {
    this.id = data.id || UUID.UUID();
    this.display = data.display || false;
    this.contact_id = data.contact_id;
    this.user_id = data.user_id;
    this.name = data.name;
    this.email = data.email;
    this.job = data.job;
    this.company_id = data.company_id;
    this.company = data.company;
    this.companyType = data.companyType;
    this.data = data.data;
    this.display = data.display;
    this.signedAt = data.signedAt ? new Date(data.signedAt) : null;
  }
}
