import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService, EmailMessages,
  SignableStatus,
  Contact
} from 'app/shared';
import {
  PreventionPlanService
} from '../services';
import {
  IcpService,
  EntityIcp, EntityStep,
  Ldcm
} from '../../workbook';
import {
  EmergencyInstructionsService,
  EntityEmergencyInstructions, EmergencyCase, EmergencyContact
} from '../../repository';
import {AbstractPdpExportComponent} from './abstractPdPExport.component';

@Component({
  selector: 'app-workbook-icp-export',
  templateUrl: './icpExport.component.html',
  styleUrls: ['./icpExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IcpExportComponent extends AbstractPdpExportComponent {

  SIGN_NOTIFY = EmailMessages.SIGN_ICP_CONTACT;

  @Input() onlySigned: boolean = false;
  @Output() onSave = new EventEmitter<EntityIcp>();
  @Output() onUpdate = new EventEmitter<EntityIcp>();

  entity: EntityIcp;
  stepIds: string[] = [];

  ldcmLocals: Ldcm[] = [];
  ldcmDocuments: Ldcm[] = [];
  ldcmEquipments: Ldcm[] = [];
  ldcmGeneralInst: Ldcm[] = [];

  protected _emergencyInstructionsSubscription: Subscription;
  emergencyInstructions: EntityEmergencyInstructions;
  newEmergencyCase: EmergencyCase = new EmergencyCase();
  newContacts: EmergencyContact[] = [];
  newInstructions: string[] = [];

  constructor(
    protected _emergencyInstructionsService: EmergencyInstructionsService,
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: IcpService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);
  }

  _init(data) {
    if (this.onlySigned && data && data.status && data.status !== SignableStatus.SIGNED) {
      console.log('onlySigned');
    } else {
      super._init(data);
      if (this.entity) {
        if (!this._stepsSubscription) {
          if (!this.entity.emergencyInstructions && this.emergencyInstructions && this.emergencyInstructions.enabledIcp) {
            this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
            this.initEmergencyInstructionsNew();
          } else {
            if (!this._emergencyInstructionsSubscription) {
              this._emergencyInstructionsSubscription = this._emergencyInstructionsService.getSingleOne().subscribe((data) => {
                if (data) {
                  this.emergencyInstructions = data;
                  if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions.enabledIcp) {
                    this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
                    this.initEmergencyInstructionsNew();
                  }
                }
              });
            }
            this.initEmergencyInstructionsNew();
          }
          this._stepsSubscription = this._preventionPlanService.getStepList(this.entity.parent_id).subscribe((list) => {
            if (list) {
              this.activityStepList = [];
              this.stepList = {};
              this.stepIds = [];
              list.sort((b, a) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()))
                //pour chaque step de l'icp
                .filter((e) => (this.entity.steps && this.entity.steps.indexOf(e._id) !== -1))
                .map((e) => (new EntityStep(e)))
                .forEach((o) => {
                  this.stepIds.push(o._id);
                  //oeex des scopes
                  if (o.scopedMeasures) {
                    Object.keys(o.scopedMeasures).forEach((a_id) => {
                      Object.keys(o.scopedMeasures[a_id]).forEach((m_id) => {
                        o.scopedMeasures[a_id][m_id].forEach((oeex_id) => {
                          if (this.oeexStepList.indexOf(oeex_id) === -1) {
                            this.oeexStepList.push(oeex_id);
                          }
                        });
                      });
                    });
                  }
                  //liste des activity concernés
                  if (o.activities && o.activities.length) {
                    o.activities.forEach((a) => {
                      if (this.activityStepList.indexOf(a) === -1) {
                        this.activityStepList.push(a);
                      }
                    });
                  }
                  if (o.template) {
                    this.templates.push(o);

                  } else {
                    this.steps.push(o);
                  }
                  this.stepList[o._id] = o;
                  this.updateLocationRisk(o);
                });
              this.refreshOeexList();
            }
          });
        }
        if (this.entity.IPLdcm) {
          this.ldcmLocals = this.entity.IPLdcm.filter((l) => l.group === 'locals');
          this.ldcmDocuments = this.entity.IPLdcm.filter((l) => l.group === 'documents');
          this.ldcmEquipments = this.entity.IPLdcm.filter((l) => l.group === 'equipment');
          this.ldcmGeneralInst = this.entity.IPLdcm.filter((l) => l.group === 'generalInst');
        } else {
          this.ldcmLocals = [];
          this.ldcmDocuments = [];
          this.ldcmEquipments = [];
          this.ldcmGeneralInst = [];
        }
      }
    }
  }

  initEmergencyInstructionsNew() {
    this.newContacts = [];
    this.newInstructions = [];
    if (this.entity && this.entity.emergencyInstructions && this.entity.emergencyInstructions.emergencyCases) {
      this.entity.emergencyInstructions.emergencyCases.forEach((ec) => {
        this.newContacts.push(new EmergencyContact());
        this.newInstructions.push('');
      });
    }
  }

  toggleEmergencyInstructions(event) {
    if (event && event.checked) {
      this.entity.emergencyInstructions.enabledIcp = true;
    } else {
      this.entity.emergencyInstructions.enabledIcp = false;
    }
    this._save().then((e) => {this._afterSave(e); this.initEmergencyInstructionsNew();});
  }


  addEmergencyCase() {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.newEmergencyCase) {
      if (!this.entity.emergencyInstructions.emergencyCases) {
        this.entity.emergencyInstructions.emergencyCases = [];
      }
      this.entity.emergencyInstructions.emergencyCases.push(this.newEmergencyCase);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
      this.newEmergencyCase = new EmergencyCase();
    };
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription.unsubscribe();
    }
  }
  showOeex(id: string) {
    return this.activeOeexList.indexOf(id) !== -1;
  }
  refreshOeexList(active = []) {
    if (this.entity && this.entity.iPOEex) {
      active = [...active, ...this.entity.iPOEex.map((oeex) => (oeex._id))];
    }
    super.refreshOeexList(active);
  }

  refreshSignableStatus(withSignatories = false) {
    super.refreshSignableStatus((this.entity && this.entity.withoutSignatories) ? false : withSignatories);
  }

  _isSignatory(contact: Contact) {
    return contact.signatory;
  }

}
