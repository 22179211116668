import {Component, AfterViewInit, OnDestroy, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {
  comparableString, ExcelService,
  formattedDate,
  LoggerService,
  PermissionsService,
  IUser, EntityRole,
  EmailService,
  EmailDatasModel, ConfirmDialogComponent, CommonComponent
} from 'app/shared';
import {UserSyncComponent} from 'app/workbook-core';
import {UserEmailDialogComponent} from './userEmail-dialog.component';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends CommonComponent implements AfterViewInit, OnDestroy {

  @ViewChild('userForm') form: NgForm;
  firstChange = true;
  changeSub: Subscription;
  usersList = [];
  userListSub: Subscription;
  displayedColumns: string[];
  userSelected;
  selectedRow = -1;
  dataSource: MatTableDataSource<IUser> = new MatTableDataSource([]); // SimpleDatasource<Role>;
  roles: string[] = [];
  rightC: boolean = false;
  rightU: boolean = false;
  rightD: boolean = false;
  existingEmail: boolean = false;
  previousRoles: string[] = [];
  inactivity: number;
  unverified: boolean = false;
  constructor(
    protected _excelService: ExcelService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _emailService: EmailService,
    protected _permissionsService: PermissionsService,
    protected _route: ActivatedRoute
  ) {
    super(_logger);
    this.displayedColumns = ['email', 'given_name', 'family_name', 'phone_number'];
    this.dataSource = new MatTableDataSource();
    this.rightC = this._permissionsService.hasPermission('createUser');
    this.rightU = this._permissionsService.hasPermission('editUser');
    this.rightD = this._permissionsService.hasPermission('removeUser');
    this.userListSub = this._permissionsService.auth.usersList$.subscribe((list) => {
      if (list) {
        this.usersList = list;
        this.dataSource = new MatTableDataSource(this.usersList);
      }
    });
    this._init();
    this.clearForm();
  }
  protected _init() {
    this._permissionsService.auth.refreshUsersList().then((res) => {
      //console.log('usersList loaded');
      //this.dataSource = new MatTableDataSource(this.usersList);
      this.dataSource.filterPredicate = this.createFilter();
      this.displayedColumns = ['email', 'given_name', 'family_name', 'phone_number'];
      this.roles = Object.keys(this._permissionsService.rolesPermissions)
        .sort((a, b) => EntityRole.ROLES_ORDER.indexOf(b) - EntityRole.ROLES_ORDER.indexOf(a))
        .filter((r) => (!(r === 'PP_ADMIN_TREPIED' && !this._permissionsService.hasRole('PP_ADMIN_TREPIED'))));
      this.displayedColumns = this.displayedColumns.concat(this.roles);
      if (this.rightD) {
        this.displayedColumns.push('delete');
      }
      if (this.hasPermission('manageUserSync')) {
        this.displayedColumns.push('userSync');
      }
    },
      error => {
        this._logger.error('UsersComponent', 'error get users list', JSON.stringify(error));
      });
  }
  ngAfterViewInit() {
    this.changeSub = this.form.control.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(() => {
        // Avoid saving data each time the form is loaded
        if (this.firstChange) {
          this.firstChange = false;
        } else {
          if (this.userSelected['user_id']) {
            this.dataSource.filter = '';
            this.changeUserData();
          } else {
            this.dataSource.filter = JSON.stringify(this.userSelected);
            this.existingEmail = this.userSelected['email'] && this.usersList.findIndex((e) => (e.email === this.userSelected['email'])) !== -1;
          }
        }
      });
  }
  ngOnDestroy() {
    if (this.changeSub) {
      this.changeSub.unsubscribe();
    }
    if (this.userListSub) {
      this.userListSub.unsubscribe();
    }
  }

  /**
   * Open dialog popup for create pdp
   */
  editUserSync(_user: IUser) {
    this._permissionsService.getUserById(_user.user_id).then((user) => {

      const dialogRef = this.dialog.open(UserSyncComponent, {
        minWidth: '35%',
        autoFocus: false,
        panelClass: 'createPP',
        data: {
          user_id: user.user_id,
          user_metadata: user.user_metadata,
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined') {
          user['user_metadata']['syncFilter'] = c;
          this._init();
        }
      });
    });

  }
  getSeniority(date: Date = new Date()) {
    const diff = Math.abs(new Date().getTime() - new Date(date).getTime());
    return Math.trunc(diff / (1000 * 60 * 60 * 24));
  }

  createFilter(): (data: any, filter: string) => boolean {
    const _that = this;
    const filterFunction = function (data, filter): boolean {
      let a = _that.getSeniority(data.updated_at);
      //console.log('getSeniority(' + data.updated_at + ') = ' + a + ' <= ' + _that.inactivity + ' => ' + ((_that.unverified && data['email_verified'])  || (_that.inactivity && _that.inactivity >= _that.getSeniority(data.updated_at))));
      if ((_that.unverified && data['email_verified'])
        || (_that.inactivity && _that.inactivity >= _that.getSeniority(data.updated_at))) {
        return false;
      }

      const searchTerms = JSON.parse(filter);
      let matchingRole = true;
      if (searchTerms['app_metadata'] && searchTerms['app_metadata']['authorization'] && searchTerms['app_metadata']['authorization']['roles'] && searchTerms['app_metadata']['authorization']['roles'].length) {
        matchingRole = data['app_metadata']
          && data['app_metadata']['authorization']
          && data['app_metadata']['authorization']['roles']
          && data['app_metadata']['authorization']['roles'].length;
        if (matchingRole) {
          searchTerms['app_metadata']['authorization']['roles'].forEach((r) => {
            matchingRole = matchingRole
              && data['app_metadata']['authorization']['roles'].indexOf(r) !== -1;
          });
        }
      }
      return matchingRole
        && (!searchTerms['email'] || (data['email'] && comparableString(data['email']).indexOf(comparableString(searchTerms['email'])) !== -1))
        && (!searchTerms['user_metadata'] || !searchTerms['user_metadata']['family_name'] || (data['user_metadata'] && data['user_metadata']['family_name'] && comparableString(data['user_metadata']['family_name']).indexOf(comparableString(searchTerms['user_metadata']['family_name'])) !== -1))
        && (!searchTerms['user_metadata'] || !searchTerms['user_metadata']['given_name'] || (data['user_metadata'] && data['user_metadata']['given_name'] && comparableString(data['user_metadata']['given_name']).indexOf(comparableString(searchTerms['user_metadata']['given_name'])) !== -1))
        && (!searchTerms['user_metadata'] || !searchTerms['user_metadata']['phone_number'] || (data['user_metadata'] && data['user_metadata']['phone_number'] && data['user_metadata']['phone_number'].trim().indexOf(searchTerms['user_metadata']['phone_number'].trim()) !== -1));
    };
    return filterFunction;
  }
  changeInactivityFilter(value) {
    this.inactivity = value;
    //console.log('refreshFilter: ' + this.inactivity + '/' + this.unverified);
    if (!this.userSelected['user_id']) {
      this.dataSource.filter = JSON.stringify(this.userSelected);
    }
  }
  clearForm() {
    this.selectedRow = -1;
    this.userSelected = {
      //    name: '',
      email: '',
      //    password: '',
      app_metadata: {
        authorization: {
          roles: []
        },
      },
      user_metadata: {
        given_name: '',
        family_name: '',
        phone_number: ''
      }
    };
  }
  selectUser(user) {
    if (this._permissionsService.hasPermission('editUser')) {
      if (!user['app_metadata']) {
        user['app_metadata'] = {};
      }
      if (!user['app_metadata']['authorization']) {
        user['app_metadata']['authorization'] = {};
      }
      if (!user['app_metadata']['authorization']['roles']) {
        user['app_metadata']['authorization']['roles'] = [];
      }
      this.setPreviousRoles(user['app_metadata']['authorization']['roles']);
      this.firstChange = true;
      this.userSelected = Object.assign({}, user);
    }
  }
  setPreviousRoles(roles: string[]) {
    //console.log(' setPreviousRoles from [' + this.previousRoles.join(',') + '] [' + roles.join(',') + ']');
    this.previousRoles = roles.map((e) => (e));
  }
  rmUser(user) {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        if (user && user['user_id']) {
          if (this.userSelected && this.userSelected['user_id'] === user['user_id']) {
            this.clearForm();
          }
          this._permissionsService.deleteUser(encodeURI(user['user_id'])).then(() => {
            this._sendEmail(6, user.email, user.nickname ? user.nickname + ' ' + user.name : user.name);
            this.snackBar.open('Utilisateur supprimé', '', {
              duration: 2000
            });
            this.dataSource.filter = '';
            /*
           this._permissionsService.auth.refreshUsersList().then((res) => {
             //this.dataSource.data = this._permissionsService.auth.usersList;
             this.dataSource.filter = '';
           },
             error => {
               this._logger.error('UsersComponent', 'error get users list', JSON.stringify(error));
             }
           );
           */
          },
            (err) => {
              this.snackBar.open(err['error'] ? err['error']['error'] : 'Error', err['error'] ? err['error']['message'] : err['message'], {
                duration: 5000
              });
            });
        }
      }
    });
  }
  createUser() {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Voulez-vous vraiment créer cet utilisateur ? <br>Attention si vous confirmez, un email sera envoyé immédiatement à l’utilisateur avec un lien pour rejoindre l’application.';
    dialogRefDel.componentInstance.buttonMessage = 'Créer';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._permissionsService.createUser(this.userSelected['email'], '', this.userSelected['user_metadata'], this.userSelected['app_metadata']['authorization']['roles'], true).then((user) => {
          // console.log('user created:', user);

          this._permissionsService.auth.refreshUsersList()
            //this._permissionsService.getUserList()
            .then((res) => {
              //this.usersList = auth0UserList;
              //this.dataSource.data = this._permissionsService.auth.usersList;
              this.dataSource.filter = '';
              this.userSelected = this.usersList.find((u) => u['user_id'] === user['user_id']);
            },
              error => {
                this._logger.error('UsersComponent', 'error get users list', JSON.stringify(error));
              }
            );
        }, (err) => {
          // console.log('user not created:', err);
          if (err && err['status'] && err['status'] === 409) {
            this.dataSource.filter = '';
            this.userSelected = this.usersList.find((u) => u['email'] === this.userSelected['email']);
            this.snackBar.open('Utilisateur existant pour ' + this.userSelected['email'], '', {
              duration: 2000
            });
          } else {
            this.snackBar.open(err['error'] ? err['error']['error'] : 'Error', err['error'] ? err['error']['message'] : err['message'], {
              duration: 5000
            });
          }
        });
      }
    });
  }
  isDisabledRole(role) {
    if (this.userSelected
      && this.userSelected['user_id']
      && this._permissionsService.hasPermission('editUser')
      && this.userSelected['app_metadata']
      && this.userSelected['app_metadata']['authorization']
      && this.userSelected['app_metadata']['authorization']['roles']
      && this.userSelected['app_metadata']['authorization']['roles'].length) {
      return (role === 'PP_EEX')
        ? this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_EEX') === -1
        : this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_EEX') !== -1;
    }
    return false;
  }
  toggleRole(role) {
    // console.log('toggleRole', role);
    if (this.userSelected && this._permissionsService.hasPermission('editUser')) {
      if (this.userSelected['app_metadata']
        && this.userSelected['app_metadata']['authorization']
        && this.userSelected['app_metadata']['authorization']['roles']) {

        const roleIndex = this.userSelected['app_metadata']['authorization']['roles'].indexOf(role);
        if (roleIndex !== -1) {
          this.userSelected['app_metadata']['authorization']['roles'].splice(roleIndex, 1);
        } else {
          this.userSelected['app_metadata']['authorization']['roles'].push(role);
        }
        if (this.userSelected['user_id']) {
          this.changeUserData();
        } else {
          this.dataSource.filter = JSON.stringify(this.userSelected);
        }
      }
    }
  }
  changeUserMetadata() {
    this._permissionsService.updateUserMetadata(this.userSelected).then((user) => {
      // console.log('onChangeUserMetadata:', user);
    }, (error) => {
      this._logger.error('UsersComponent', 'onChangeUserMetadata error:', JSON.stringify(error));
    });
  }

  changeUserData() {
    this._spinnerService.show();
    if (this.usersList && this.userSelected && this.userSelected.user_id) {
      const i = this.usersList.findIndex((x) => x.user_id === this.userSelected.user_id);
      if (i !== -1
        && this.userSelected['app_metadata']
        && this.userSelected['app_metadata']['authorization']
        && this.userSelected['app_metadata']['authorization']['roles']
        && this.previousRoles) {
        /*
        console.log('change roles from [' + this.previousRoles.join(',') + '] to [' + this.userSelected['app_metadata']['authorization']['roles'].join(',') + ']',
          [
            //pas previous admin
            (this.previousRoles.indexOf('PP_ADMIN') === -1),
            //devient admin
            (this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_ADMIN') !== -1),
            //previous admin
            (this.previousRoles.indexOf('PP_ADMIN') !== -1),
            //plus admin
            (this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_ADMIN') === -1)
          ]);
*/
        if (this.userSelected['app_metadata']['authorization']['roles'].length) {
          if ((this.previousRoles.indexOf('PP_ADMIN') === -1 && this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_ADMIN') !== -1)
            || (this.previousRoles.indexOf('PP_ADMIN') !== -1 && this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_ADMIN') === -1)) {
            //console.log(' reset couchDBConfig account');
            this.userSelected['app_metadata']['couchDBConfig'] = null;
          }
          if (this.previousRoles.indexOf('PP_EEX') === -1 && this.userSelected['app_metadata']['authorization']['roles'].indexOf('PP_EEX') !== -1) {
            //console.log(' reset couchDB and couchDBDocs accounts');
            this.userSelected['app_metadata']['couchDB'] = null;
            this.userSelected['app_metadata']['couchDBDocs'] = null;
            this.userSelected['app_metadata']['authorization']['roles'] = ['PP_EEX'];
          }
        } else {
          this.userSelected['app_metadata']['couchDB'] = null;
          this.userSelected['app_metadata']['couchDBDocs'] = null;
          this.userSelected['app_metadata']['couchDBConfig'] = null;
          this.userSelected['app_metadata']['couchDBLogs'] = null;
        }
      } else {
        //console.log(' changeUserData use not found', [this.userSelected, this.usersList]);
      }
    }
    this.setPreviousRoles(this.userSelected['app_metadata']['authorization']['roles']);
    this._permissionsService.updateUserData(this.userSelected).then((user) => {
      // console.log('onChangeEmail:', user);
      this._spinnerService.hide();
    }, (err) => {

      this._spinnerService.hide();
      this._logger.error('UsersComponent', 'changeUserData error:', JSON.stringify(err));
      this.snackBar.open(err && err['error'] ? err['error']['error'] : 'Error', err && err['error'] ? err['error']['message'] : err ? err['message'] : '', {
        duration: 5000
      });
    });
  }

  private _sendEmail(type: number, email: string, to_name: string): void {
    this._emailService.getEmailDatas(type).then(emailObj => {
      const emailDatas = new EmailDatasModel();
      emailDatas.email = email;
      emailDatas.to_name = to_name;
      emailDatas.from_name = emailObj.fromName;
      if (emailObj.fromEmail) {
        emailDatas.from_email = emailObj.fromEmail;
      }
      emailDatas.date = new Date();
      emailDatas.subject = emailObj.subject;
      emailDatas.text = emailObj.text;
      emailDatas.usertext = emailObj.usertext;
      emailDatas.signature = emailObj.signature;
      emailDatas.rgpdMail = emailObj.rgpdMail;
      emailDatas.dpoName = emailObj.dpoName;
      emailDatas.dpoMail = emailObj.dpoMail;
      this._emailService.sendEmail(emailDatas).catch((err) => {
        this._logger.error('UsersComponent', 'error sendEmail:', JSON.stringify(err));
      });
    })
  }
  editEmail() {
    if (this.userSelected) {
      const dialogRef = this.dialog.open(UserEmailDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: {
          user: Object.assign({}, this.userSelected),
          usersList: this.usersList
        }
      });

      dialogRef.afterClosed().subscribe(u => {
        // Add intervention to the intervention list
        if (u && u !== 'undefined') {
          const i = this.usersList.findIndex((x) => x.user_id === u.user_id);
          if (i !== -1) {
            this.userSelected = u;
            this.usersList[i] = this.userSelected;
            this.dataSource = new MatTableDataSource(this.usersList);
            this.firstChange = true;
          }
        }
      });
    }
  }
  hasPermission(right: string) {
    return this._permissionsService.hasPermission(right);
  }
  formattedDate(date) {
    return formattedDate(date, true);
  }
  exportToExcel() {
    this._excelService.exportAsExcelFile({
      users: this.getExport(this.dataSource.filteredData)
    }, 'export');
  }
  getExport(list): any[] {
    return list.map((user) => {
      const returned = {
        id: user.user_id,
        email: user.email,
        nickname: user.nickname,
        created_at: user.created_at,
        last_login: user.last_login,
        email_verified: user.email_verified ? 'X' : '',
        given_name: user.user_metadata && user.user_metadata.given_name ? user.user_metadata.given_name : '',
        family_name: user.user_metadata && user.user_metadata.family_name ? user.user_metadata.family_name : '',
        phone_number: user.user_metadata && user.user_metadata.phone_number ? user.user_metadata.phone_number : ''
      };
      this.roles.forEach((r) => {
        returned[r] = ((user.app_metadata
          && user.app_metadata.authorization
          && user.app_metadata.authorization.roles
          && user.app_metadata.authorization.roles.indexOf(r) !== -1) ? 'X' : '');
      });
      return returned;
    }
    );
  }
}
