import {Component, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {AuthService, CommonComponent, ConfirmDialogComponent, ErrorDialogComponent, LoggerService} from 'app/shared';
import {MatDialog, MatSnackBar} from '@angular/material';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormGroup, NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {cacheClear, storageClear} from 'app/shared/utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends CommonComponent implements OnInit, OnDestroy, AfterViewInit {

  userMeta = {
    family_name: '',
    given_name: '',
    phone_number: '',
    syncFilter: null
  };
  email: string;
  firstChange: boolean = true;
  @ViewChild('profileForm') profileForm: NgForm;
  changeProfile: Subscription;

  profilLoaded: boolean;
  viewLoaded: boolean;

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    public snackBar: MatSnackBar,
  ) {
    super(_logger);
  }

  ngOnInit() {
    this.auth.userProfile$
      .subscribe((profile) => {
        this.email = profile.email;
        this.userMeta = {
          family_name: !!profile.user_metadata ? profile.user_metadata.family_name : '',
          given_name: !!profile.user_metadata ? profile.user_metadata.given_name : '',
          phone_number: !!profile.user_metadata ? profile.user_metadata.phone_number : '',
          syncFilter: !!profile.user_metadata && profile.user_metadata.syncFilter ? profile.user_metadata.syncFilter.map(e => e) : null
        };

        this.profilLoaded = true;
        this.subscribeChange();
      });
  }
  ngOnDestroy() {
    if (this.changeProfile) {
      this.changeProfile.unsubscribe();
    }
  }
  /**
   * Autosave profile
   */
  ngAfterViewInit() {
    this.viewLoaded = true;
    this.subscribeChange();
  }
  subscribeChange() {
    if (this.profilLoaded && this.viewLoaded) {
      this.changeProfile = this.profileForm.control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(() => {
          // Avoid saving data each time the form is loaded
          if (this.firstChange) {
            this.firstChange = false;
          } else {
            if (this.profileForm.valid) {
              this.auth.updateProfile(this.userMeta);
            }
          }
        });
    }
  }

  /**
   * Asks to Auth0 to send an email for change password
   */
  newPassword() {
    const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Souhaitez-vous recevoir un email qui vous permettra de changer votre mot de passe ?';
    dialogRefDel.componentInstance.buttonMessage = 'Envoyer';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this.auth.changePassword(this.email);
        this._snackBar('Demande de modification de mot de passe effectuée, vous allez recevoir dans quelques minutes un email avec la procédure.');
      }
    })
  }

  /**
   * Display snackbar
   * @param message
   * @private
   */
  protected _snackBar(message: string): void {
    this.snackBar.open(message, '', {
      duration: 5000
    });
  }

  /**
   * on focus out check if control invalid
   * @param name
   * @param group
   */
  public checkFormValidation(name: string, group?: string) {
    this._checkFormValidation(name, group);
  }

  /**
   *
   * @param name
   * @param group
   */
  protected _checkFormValidation(name: string, group?: string) {
    let input;
    if (group) {
      input = (<FormGroup> this.profileForm.controls[group]).controls[name];
    } else {
      input = this.profileForm.controls[name];
    }
    if (input && input.invalid) {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: false,
        minWidth: '600px',
      });
      if (input.errors && input.errors.required) {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Le champ est obligatoire, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      } else if (input.errors && input.errors.pattern) {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Le format n\'est pas valide, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      } else {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Erreur de saisie du champ, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      }
    }
  }
  clearCache() {
    cacheClear();
    this.auth.logout();
  }
}
