import {Entity} from './entity.model';
import {formattedDate} from 'app/shared/utils';
//import {ISignature} from './signature.model';
import {Message} from './message.model'
//import {Icp} from './icp.model'

export class EntityEmailMessages extends Entity {
  public fromName: string;
  public fromEmail: string;
  public defaultSenderUser: boolean;
  public defaultReplyUser: boolean;
  public messages: Message[];
  public signature: string;
  public rgpdText: string;
  public rgpdMail: string;
  public dpoName: string;
  public dpoMail: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.fromName = (data && data.fromName) ? data.fromName : '';
    this.fromEmail = (data && data.fromEmail) ? data.fromEmail : '';
    this.defaultSenderUser = (data && !!data.defaultSenderUser);
    this.defaultReplyUser = (data && !!data.defaultReplyUser);
    this.messages = (data && data.messages) ? data.messages.map((e) => (e)) : [];
    this.signature = (data && data.signature) ? data.signature : '';
    this.rgpdText = (data && data.rgpdText) ? data.rgpdText : '';
    this.rgpdMail = (data && data.rgpdMail) ? data.rgpdMail : '';
    this.dpoName = (data && data.dpoName) ? data.dpoName : '';
    this.dpoMail = (data && data.dpoMail) ? data.dpoMail : '';
  }
  static _getIcpAttendees(signable, eex: boolean = true) {
    const icpattendees: string[] = [];
    if (signable && signable['iPOEex']) {
      signable['iPOEex']
        .filter((e) => (e['documentType'] && e['documentType'] === (eex ? 'wb_eex' : 'eex_office')))
        .forEach((eex) => {
          let line = eex['name'];
          if (eex['contacts'] && eex['contacts'].length) {
            eex['contacts'].forEach((c, index) => {
              if (c['name']) {
                if (index === 0) {
                  line += ' représentée par ';
                } else if (index === (eex['contacts'].length - 1)) {
                  line += ' et ';
                } else {
                  line += ', ';
                }
                line += c['name'];
              }
            });
            icpattendees.push(line);
          }
        })
    }
    return icpattendees;
  }
  static _getIcpLdcm(signable, group: string = 'generalInst') {
    const ldcms: string[] = [];
    if (signable && signable['IPLdcm']) {
      signable['IPLdcm']
        .filter((e) => (e['group'] && e['group'] === group))
        .forEach((ldcm) => {
          let line = '';
          if (ldcm['toggle'] && ldcm['checked']) {
            line = '\u2611' + ' ' + ldcm['name'];
          } else {
            if (ldcm['reliefOrg'] && ldcm['reliefOrg'].length) {
              line = '';
              ldcm['reliefOrg'].forEach((ldcmo) => {
                if (ldcmo['name']) {
                  if (ldcmo['toggle']) {
                    if (ldcmo['checked']) {
                      line += '\n' + '\u2611' + ' ' + ldcmo['name'];
                    }
                  } else if (ldcmo['comments']) {
                    line += '\n' + ldcmo['name'] + ':\n' + ldcmo['comments'];
                  }
                }
              });
              if (line) {
                line = ldcm['name'] + ': ' + line;
              }
            } else if (ldcm['comments']) {
              line = ldcm['name'] + ':\n' + ldcm['comments']
            }
          }
          if (line) {
            ldcms.push(line);
          }
        });
    }
    return ldcms;
  }
  /**
     * Replace in email text, the codes by the right values of pp
     * Codes used : @DATE, @TIME, @PLACE, @ZONE, @SIGNATORY
     * @param {string} text
     * @param {PlanPrevention} pp
     * @param {Icp} icp
     * @param {ISignature} sign
     * @returns {string}
     */
  public static modifyEmailText(euname: string, text: string, doc?: any, signable?: any, element?: any, url?: string, documentmeta?: any): string {
    if (!text) {
      return '';
    } else {
      const replaceData = [
        {id: '@ICPDATE', type: 'ICP', text: (signable && signable.iPDate) ? formattedDate(signable.iPDate) : ''},
        {id: '@ICPTIME', type: 'ICP', text: (signable && signable.iPHourStart) ? (signable.iPHourEnd) ? (signable.iPHourStart + ' - ' + signable.iPHourEnd) : (signable.iPHourStart) : ''},
        {id: '@ICPSTARTTIME', type: 'ICP', text: (signable && signable.iPHourStart) ? signable.iPHourStart : ''},
        {id: '@ICPENDTIME', type: 'ICP', text: (signable && signable.iPHourEnd) ? signable.iPHourEnd : ''},
        {id: '@ICPEEXATTENDEES', type: 'ICP', text: EntityEmailMessages._getIcpAttendees(signable).join('\n')},
        {id: '@ICPOFFICEATTENDEES', type: 'ICP', text: EntityEmailMessages._getIcpAttendees(signable, false).join('\n')},
        {id: '@ICPCONSIGNES', type: 'ICP', text: EntityEmailMessages._getIcpLdcm(signable).join('\n\n')},
        {id: '@ICPDOCUMENTS', type: 'ICP', text: EntityEmailMessages._getIcpLdcm(signable, 'documents').join('\n\n')},
        {id: '@ICPLOCAUX', type: 'ICP', text: EntityEmailMessages._getIcpLdcm(signable, 'locals').join('\n\n')},
        {
          id: '@ICPPLACE', type: 'ICP', text: (
            ((signable && signable['iPSite']) ? signable['iPSite'] : '')
            + ((signable && signable['iPAddress']) ? ' - ' + signable['iPAddress'] : '')
            + ((signable && signable['iPZipCode']) ? ' - ' + signable['iPZipCode'] : '')
            + ((signable && signable['iPCity']) ? ' ' + signable['iPCity'] : '')
          )
        },
        {id: '@ICPZONE', type: 'ICP', text: (signable && signable.iPZone) ? signable.iPZone : ''},
        {id: '@LINK', type: 'EEX', text: (signable && signable._id) ? window.location.origin + '/doc/' + signable._id : ''},
        {id: '@SIGNATORY', type: 'SIGNATORY', text: (element && element.name) ? (element.name + (element.company ? ' (' + element.company + ')' : '')) : ''},
        {id: '@SIGNDATE', type: 'SIGNATORY', text: (element && element.signedAt) ? formattedDate(element.signedAt) : ''},
        {id: '@URL', type: 'USER', text: url ? url : ''},
        {id: '@EDITIONSTARTDATE', type: 'DEED', text: ((signable && signable.dateStart) ? formattedDate(signable.dateStart) : ((signable && signable.date) ? formattedDate(signable.date) : ''))},
        {id: '@EDITIONENDDATE', type: 'DEED', text: ((signable && signable.dateEnd) ? formattedDate(signable.dateEnd) : '')},
        {id: '@EDITIONSTARTTIME', type: 'DEED', text: ((signable && signable.hourStart) ? signable.hourStart : '')},
        {id: '@EDITIONENDTIME', type: 'DEED', text: ((signable && signable.hourEnd) ? signable.hourEnd : '')},
        {id: '@EDITIONNAME', type: 'EEX', text: (signable ? signable.name : '')},
        {id: '@EDITIONDESC', type: 'EEX', text: (signable ? signable.description : '')},
        {id: '@EDITIONS', type: 'EEX', text: ((doc && doc._id) ? window.location.origin + '/docs/' + doc._id : '')},
        {id: '@PERMITNAME', type: 'EEX', text: (signable ? signable.title : '')},
        {id: '@DOCUMENTNAME', type: 'EEX', text: (documentmeta ? documentmeta.name : '')},
        {id: '@DOCUMENTDESC', type: 'EEX', text: (documentmeta ? documentmeta.description : '')},
        {id: '@REFUS', type: 'EEX', text: (documentmeta ? documentmeta.reason : '')},
        {id: '@DOCUMENTS', type: 'EEX', text: ((documentmeta && Array.isArray(documentmeta)) ? documentmeta.map((dm) => (' - ' + dm.name + (dm.description ? ('\n' + dm.description) : ''))).join('\n') : '')},
        {id: '@DOCTYPE', type: 'DOC', text: (doc && doc.documentType) ? doc.documentType.replace('pp', 'Plan de prévention').replace('fp', 'Permis de feu') : ''},
        {id: '@DOCDESC', type: 'DOC', text: (doc && doc.description) ? doc.description : ''},
        {id: '@DOCEEX', type: 'DOC', text: (doc && doc.eex_name) ? doc.eex_name : ''},
        {id: '@DOC', type: 'DOC', text: (doc && doc.name) ? doc.name : ''},
        {id: '@HOME', type: '*', text: window.location.origin},
        {id: '@OPERATIONS', type: '*', text: window.location.origin},
        {id: '@UPLOADS', type: 'EEX', text: (window.location.origin + ((doc && doc._id) ? '/docs/' + doc._id : '') + '/documents')},
        {id: '@EUNAME', type: '*', text: euname}
      ];
      replaceData.forEach((replace) => {
        text = text.split(replace.id).join(replace.text);
      });
      return text;
    }
  }
}
