import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, ConfirmDialogComponent, SignableStatus } from 'app/shared';
import { WorkbookSignableListComponent } from 'app/workbook-shared';
import {
  PreventionPlanService,
  EntityPreventionPlan,
  SignablePreventionPlanService,
  EntitySignablePreventionPlan,
  EntityIcp,
  EntityStep,
  ICPState, DocumentationService
} from 'app/workbook-core';
import { SignablePreventionPlanDialogComponent } from './signablePreventionPlan-dialog.component';
import { SignablePreventionPlanInfoDialogComponent } from './signablePreventionPlanInfo-dialog.component';

@Component({
  selector: 'app-signable-prevention-plan-list',
  templateUrl: './signablePreventionPlanList.component.html',
  styleUrls: ['./signablePreventionPlanList.component.scss']
})

export class SignablePreventionPlanListComponent extends WorkbookSignableListComponent {

  entity: EntityPreventionPlan;
  entityList: EntitySignablePreventionPlan[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette édition du plan de prévention ?';
  addConfirmMessage = 'Nouvelle édition du plan de prévention créée';

  icpList: { [icp_id: string]: EntityIcp } = {};
  protected _icpsSubscription: Subscription;
  icpSteps: { [icp_id: string]: string[] } = {};
  stepList: { [step_id: string]: EntityStep } = {};
  steps: EntityStep[] = [];
  protected _stepsSubscription: Subscription;

  dataSource: MatTableDataSource<EntitySignablePreventionPlan> = new MatTableDataSource([]);
  displayedColumns = ['name', 'editor', 'icps', 'steps', 'editorDate', 'state', 'actions']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';
  states: { [pdp_id: string]: number } = {};
  withStepOrIcp: boolean = false;

  openPdp: string;
  constructor(
    //preload
    protected _documentationService: DocumentationService,
    protected _childService: SignablePreventionPlanService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
    this._routeSubscription = this._route.queryParams.subscribe(params => {
      if (params['pdp']) {
        this.openPdp = params['pdp'];
        this.autoOpen();
      }
    });
  }

  protected _init(data) {
    super._init(data);
    let modified = this.entity.removeDeletedPdp(this.entityList.map((e) => (e._id)));
    this.entityList.forEach((pdp: EntitySignablePreventionPlan) => {
      modified = this.entity.updatePdp(pdp, modified)
    });
    if (modified) {
      this._entityService.save(this.entity);
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list) => {
      if (list) {
        this.withStepOrIcp = this.withStepOrIcp || !!list.length;
        this.stepList = {};
        list
          .forEach((step) => {
            this.stepList[step._id] = step;
          });
        this.steps = list;
      }
    });
    if (this._icpsSubscription) {
      this._icpsSubscription.unsubscribe();
    }
    this._icpsSubscription = this._entityService.icpService.getChildren(this.entity._id).subscribe((list: EntityIcp[]) => {
      if (list) {
        this.withStepOrIcp = this.withStepOrIcp || !!list.length;
        this.icpList = {};
        list
          .forEach((icp) => {
            this.icpList[icp._id] = icp;
            this.icpSteps[icp._id] = icp.steps;
          });
      }
    });
    if (this.entityList) {
      this.refreshDataSource();
      this.autoOpen();
    }
  }
  autoOpen() {
    if (this.entityList && this.entityList.length && this.openPdp) {
      const toOpen = this.entityList.find((e) => (e._id === this.openPdp));
      if (toOpen) {
        this.openDialog(toOpen);
        this.openPdp = '';
      }
    }
  }

  refreshStates() {
    if (this.entityList && this.entity) {
      this.states = {};
      this.entityList.forEach((pdp) => {
        this.states[pdp._id] = this._getChildService().getState(pdp, this.entity.status);
      });
    }
  }


  getState(pdp: EntityPreventionPlan) {
    return pdp && pdp._id && this.states[pdp._id] ? this.states[pdp._id] : 0;

  }
  getLabelState(pdp: EntityPreventionPlan) {
    return ICPState.getLabel(this.getState(pdp));

  }
  getDescriptionState(pdp: EntityPreventionPlan) {
    return ICPState.getDescription(this.getState(pdp));

  }

  protected _getChildService() {
    return this._entityService.signablePreventionPlanService;
  }

  /**
   * Create dialog popup inspection
   */
  public selectSignable(signablePreventionPlan = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(SignablePreventionPlanInfoDialogComponent, {
      disableClose: true,
      width: '890px',
      maxWidth: '90vw',
      data: {
        id: signablePreventionPlan ? signablePreventionPlan._id : '',
        selectedSteps: (Object.keys(this.icpList).length === 0 && Object.keys(this.stepList).length === 1) ? Object.keys(this.stepList) : [],
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this._entityService.updateWorkbookPdP(this.entity, c, this.steps);
        this.refreshDataSource();
      }
    });
  }
  public openDialog(signablePreventionPlan = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(SignablePreventionPlanDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        id: signablePreventionPlan ? signablePreventionPlan._id : '',
        entity: signablePreventionPlan,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this._entityService.updateWorkbookPdP(this.entity, c, this.steps);
        this.refreshDataSource();
      }
    });
  }

  public duplicateSignablePdP(element) {
    this._getChildService().getCurrentOne(element._id).then((pdp) => {
      const newPdP: EntitySignablePreventionPlan = this._getChildService().getNewEntity(pdp, true);
      newPdP.status = 0;
      newPdP.signatures = [];
      newPdP.content = '';
      newPdP.createdAt = null;
      newPdP.createdId = '';
      newPdP.createdName = '';
      newPdP.editor = '';
      newPdP.editorEmail = '';
      this.selectSignable(newPdP);
    });
  }
  outdateSignable(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = "Confirmer";
    dialogRefDel.componentInstance.confirmMessage = "Voulez-vous rendre cette édition obsolète ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().get(entity._id).toPromise().then((signable: EntitySignablePreventionPlan) => {
          if (signable) {
            signable.status = SignableStatus.OUTDATED;
            this._getChildService().save(signable).then((e) => {
              this.updateElement(signable);
            });
          }
        });
      }
    });
  }

  updateElement(element: EntitySignablePreventionPlan) {
    //    if (element._deleted) {
    //      this._entityService.updateWorkbookPdP(this.entity, element);
    //    }
    super.updateElement(element);
    if (element && this._updateListElement(element)) {
      this._init(this.entityList);
    }
  }
  /*
    removeElement(entity) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this._getChildService().remove(entity).then((res) => {
            entity._deleted = true;
            this._afterRemoveElement(entity);
          });
        }
      });
    }
    */
}
