import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, EntityFormComponent} from 'app/shared';
import {EmergencyInstructionsService, EntityEmergencyInstructions, EmergencyCase, EmergencyContact} from 'app/workbook-core';

@Component({
  selector: 'app-repository-emergencyInstructions',
  templateUrl: './emergencyInstructions.component.html',
  styleUrls: ['./emergencyInstructions.component.scss']
})

export class EmergencyInstructionsComponent extends EntityFormComponent {
  entity: EntityEmergencyInstructions;
  newEmergencyCase: EmergencyCase = new EmergencyCase();
  newContacts: EmergencyContact[] = [];
  newInstructions: string[] = [];
  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EmergencyInstructionsService
  ) {
    super(dialog, _logger, _entityService);

  }
  protected _init(data) {
    super._init(data);
    this._initNew();
  }
  protected _initNew() {
    this.newContacts = [];
    this.newInstructions = [];
    if (this.entity && this.entity.emergencyCases) {
      this.entity.emergencyCases.forEach((ec) => {
        this.newContacts.push(new EmergencyContact());
        this.newInstructions.push('');
      });
    }
  }

  toggleEmergencyInstructions(event) {
    if (event && event.checked) {
      this.entity.enabled = true;
    } else {
      this.entity.enabled = false;
    }
    this._save().then((e) => {this._afterSave(e);});
  }
  toggleEmergencyInstructionsIcp(event) {
    if (event && event.checked) {
      this.entity.enabledIcp = true;
    } else {
      this.entity.enabledIcp = false;
    }
    this._save().then((e) => {this._afterSave(e);});
  }

  addEmergencyCase() {
    if (this.rightU && this.entity && this.newEmergencyCase) {
      if (!this.entity.emergencyCases) {
        this.entity.emergencyCases = [];
      }
      this.entity.emergencyCases.push(this.newEmergencyCase);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
      this.newEmergencyCase = new EmergencyCase();
    };
  }
  removeEmergencyCase(index: number) {
    if (this.rightU && this.entity && this.entity.emergencyCases && this.entity.emergencyCases.length > index) {
      this.entity.emergencyCases.splice(index, 1);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
    };
  }

  addContact(index: number) {
    if (this.rightU && this.entity && this.newEmergencyCase && this.entity.emergencyCases[index]) {
      if (!this.entity.emergencyCases[index].contacts) {
        this.entity.emergencyCases[index].contacts = [];
      }
      this.entity.emergencyCases[index].contacts.push(this.newContacts[index]);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
      this.newContacts[index] = new EmergencyContact();
    };
  }
  removeContact(index: number, indexContact: number) {
    if (this.rightU && this.entity && this.entity.emergencyCases && this.entity.emergencyCases[index] && this.entity.emergencyCases[index].contacts && this.entity.emergencyCases[index].contacts[indexContact]) {
      this.entity.emergencyCases[index].contacts.splice(indexContact, 1);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
    };
  }
  addInstruction(index: number) {
    if (this.rightU && this.entity && this.newEmergencyCase && this.entity.emergencyCases[index]) {
      if (!this.entity.emergencyCases[index].instructions) {
        this.entity.emergencyCases[index].instructions = [];
      }
      this.entity.emergencyCases[index].instructions.push(this.newInstructions[index]);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
      this.newInstructions[index] = '';
    };
  }
  removeInstruction(index: number, indexInstruction: number) {
    if (this.rightU && this.entity && this.entity.emergencyCases && this.entity.emergencyCases[index] && this.entity.emergencyCases[index].instructions && this.entity.emergencyCases[index].instructions[indexInstruction]) {
      this.entity.emergencyCases[index].instructions.splice(indexInstruction, 1);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
    };
  }
  public trackByValue(name, index, item: string) {
    return name ? (name + '|' + index) : index;
  }
}