import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//import {FormsModule} from '@angular/forms';
import {SharedModule} from 'app/shared';
//import {WorkbookCoreModule} from 'app/workbook-core';
import {WorkbookSharedModule} from 'app/workbook-shared';
import {WorkbooksComponent} from './workbooks.component';
import {WorkbooksStatsComponent} from './workbooksStats.component';
import {WorkbooksStatsStepComponent} from './workbooksStatsStep.component';
import {WorkbooksStatsOfficeComponent} from './workbooksStatsOffice.component';
import {WorkbooksRoutingModule} from './workbooks-routing.module';
import {StatsModule} from 'app/stats';
import {StatsService} from './stats.service';

@NgModule({
  imports: [
    CommonModule,
    //    FormsModule,
    SharedModule,
    //    WorkbookCoreModule,
    WorkbookSharedModule,
    WorkbooksRoutingModule,
    StatsModule
  ],
  declarations: [
    WorkbooksComponent,
    WorkbooksStatsComponent,
    WorkbooksStatsStepComponent,
    WorkbooksStatsOfficeComponent
  ],
  entryComponents: [
  ],
  providers: [
    StatsService
  ]
})
export class WorkbooksModule {}
