import { Entity } from './entity.model';

export class EntityConfigEmail extends Entity {
  static EMAIL_REGEX = "^[a-zA-Z0-9_%+\\-\\.]+@[a-zA-Z0-9\\-\\.]+\\.[a-z][a-z]+$";
  static EMAIL_INVALID = 'Email invalide';
  public provider: string;
  public userId: string;
  public serviceId: string;
  public templateId: string;
  public regexInternal: string;
  public regexExternal: string;
  public msgInternal: string;
  public msgExternal: string;
  public idpInternal: boolean;
  public idpExternal: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.provider = (data && data.provider) ? data.provider : '';
    this.userId = (data && data.userId) ? data.userId : '';
    this.serviceId = (data && data.serviceId) ? data.serviceId : '';
    this.templateId = (data && data.templateId) ? data.templateId : '';
    this.regexInternal = (data && data.regexInternal) ? data.regexInternal : EntityConfigEmail.EMAIL_REGEX;
    this.regexExternal = (data && data.regexExternal) ? data.regexExternal : EntityConfigEmail.EMAIL_REGEX;
    this.msgInternal = (data && data.msgInternal) ? data.msgInternal : EntityConfigEmail.EMAIL_INVALID;
    this.msgExternal = (data && data.msgExternal) ? data.msgExternal : EntityConfigEmail.EMAIL_INVALID;
    this.idpInternal = (data && !!data.idpInternal);
    this.idpExternal = (data && !!data.idpExternal);
  }
}

