import {DbCommonService, PermissionsService} from 'app/shared';
import {LoadingService} from './loading.service';
import {EntityOwnedService} from './entityOwned.service';
import {EntityCodable} from 'app/shared/models';

export abstract class EntityCodableService extends EntityOwnedService {

  static SEQUENCE_PREFIXE = 'last-code-';

  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  getName(entity: EntityCodable) {
    const constructorClass = (<typeof EntityCodable> entity.constructor);
    return constructorClass.getNameOf(entity);
  }
  getFileName(entity: EntityCodable) {
    const constructorClass = (<typeof EntityCodable> entity.constructor);
    return constructorClass.getFileNameOf(entity);
  }
  _getMaxCode(list: EntityCodable[]) {
    let code = 0;
    list.forEach((e) => {
      if (e.code > code) {
        code = e.code;
      }
    });
    return code;

  }
  protected _getCodeId(parent_id = null): string {
    return (EntityCodableService.SEQUENCE_PREFIXE
      + this.getEntityType()
      + (parent_id ? ('-' + parent_id) : ''));
  }
  getNewCode(parent_id: string = null): Promise<number> {
    return new Promise((resolve, reject) => {
      const docId = this._getCodeId(parent_id);
      this.getObjectRemote(docId).toPromise()
        .then((sequence) => {
          if (sequence) {
            sequence.userId = this.getUserId();
            sequence.updated_at = new Date();
            sequence.value = sequence.value + 1;
            this._dbService.saveRemote(sequence)
              .toPromise()
              .then((newSequence) => {
                resolve(sequence.value);
              })
              .catch((error) => {
                reject(error)
              });
          } else {
            reject({
              docId: docId,
              error: "invalid",
              message: "invalid sequence",
              name: "invalid",
              reason: "invalid sequence",
              status: 500
            })
          }
        })
        .catch((error) => {
          if (error && error.status === 404) {
            this._createCode(parent_id)
              .then((newCode) => {
                resolve(newCode);
              })
              .catch((error) => {
                reject(error)
              });
          } else {
            reject(error);
          }
        });


    });
  }
  protected _createCode(parent_id): Promise<number> {
    return new Promise((resolve, reject) => {
      this._getNewCode(parent_id).then((newCode) => {
        const newSequenceObject = {
          _id: this._getCodeId(parent_id),
          documentType: 'lastcode',
          value: newCode,
          userId: this.getUserId(),
          updated_at: new Date()
        };
        this._dbService.save(newSequenceObject)
          .toPromise()
          .then((result) => {
            resolve(newCode);
          })
          .catch((error) => {
            reject(error)
          });
      }).catch((error) => {
        reject(error)
      });
    });
  }
  _getNewCode(parent_id = null): Promise<number> {
    return new Promise((resolve, reject) => {
      if (parent_id) {
        this.getChildren(parent_id)
          .subscribe((list) => {
            if (list) {
              resolve(this._getMaxCode(list) + 1);
            }
          }, (err) => {
            reject(err);
          });
      } else {
        this.getAll()
          .subscribe((list) => {
            if (list) {
              resolve(this._getMaxCode(list) + 1);
            }
          }, (err) => {
            reject(err);
          });
      }
    });
  }
}
