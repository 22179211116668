import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySingleService, LoadingService} from 'app/shared';
import {EntityProtocolContent} from '../models/entityProtocolContent.model';

@Injectable({
  providedIn: 'root'
})
export class ProtocolContentService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement du contenu du protocole";
  static LABEL_ALL = "chargement des contenus des protocoles ?";
  static LABEL_CHILDREN = "chargement des contenu des pprotocoles de l'opération ?";
  static LABEL_SAVE = "sauvegarde du contenu du protocole";
  static LABEL_DELETE = "suppression du contenu du protocole ?";

  static ENTITY_TYPE: string = 'repo_pc';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showProtocolContent'],
    CREATE: ['editProtocolContent'],
    UPDATE: ['editProtocolContent'],
    DELETE: ['editProtocolContent']
  }

  static ENTITY_URL = 'repository/protocol';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityProtocolContent {
    return new EntityProtocolContent(data, clone);
  }

}
