import {Output, EventEmitter} from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {
  formattedDate,
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService,
  EmailDatasModel, EntityEmailMessages,
  EntitySignableFormComponent, EntitySecureService,
  EntityExploitingCompany,
  ConfirmDialogComponent,
  SignatureDialogComponent,
  ISignature, SignableStatus, EntitySignable,
  Contact, EntityDocumentMetadata,
  Operator, Attendance
} from 'app/shared';
import {
  PreventionPlanService
} from '../services';
import {
  EntityPreventionPlan
} from '../models';
import {
  EntityOffice,
  EntityExternalCompany,
  EntitySite, EntityZone,
  AttendanceGroup
} from '../../repository';
import {
  EntityStep,
  EntityActivity,
  EntitySignature,
  RiskEntry,
  EntityWorkbookMeasure
} from '../../workbook';

export abstract class AbstractPdpExportComponent extends EntitySignableFormComponent {

  SIGN_NOTIFY: number = 0;
  SIGNUP_CHECKER: string = 'signupCheckerICP';

  @Output() onSave = new EventEmitter<EntitySignable>();
  @Output() onUpdate = new EventEmitter<EntitySignable>();
  @Output() onOeexChange = new EventEmitter<any>();
  preventionPlan: EntityPreventionPlan;
  protected _preventionPlanSubscription: Subscription;

  entity: EntitySignable;

  exploitingCompany: EntityExploitingCompany;
  sign_agreement: string = '';
  protected _signAgreementSubscription: Subscription;

  //icp steps and templates
  stepList: {[step_id: string]: EntityStep} = {};
  //icp steps
  steps: EntityStep[] = [];
  //icp templates
  templates: EntityStep[] = [];
  protected _stepsSubscription: Subscription;

  //active office and EEx ids
  activeOeexList: string[] = [];
  //Oeex from step's scope
  oeexStepList: string[] = [];

  //activity_id in icp's steps
  activityStepList: string[] = [];
  //oeex_id by activity
  oeexActivityList: {[activity_id: string]: string[]} = {};

  officeList: EntityOffice[] = [];
  protected _officesSubscription: Subscription;
  //uniquement office concernés
  offices: EntityOffice[] = [];

  externalCompanyList: EntityExternalCompany[] = [];
  protected _externalCompaniesSubscription: Subscription;
  //uniquement eex concernés
  externalCompanies: EntityExternalCompany[] = [];
  mainExternalCompanies: EntityExternalCompany[] = [];
  externalCompaniesName: {[main: string]: string} = {};
  subCompanies: {[main: string]: EntityExternalCompany[]} = {};
  subCompanyList: string[] = [];

  withSubcompanies: boolean = false;

  activities: EntityActivity[] = [];
  protected _activitiesSubscription: Subscription;
  risks: {[activity_id: string]: {[risk_name: string]: RiskEntry}} = {};
  riskSituationName: {[situation_id: string]: string} = {};
  withPrior: {[activity_id: string]: boolean} = {};
  withInProgress: {[activity_id: string]: boolean} = {};
  withAfter: {[activity_id: string]: boolean} = {};
  sir: {[oeex_id: string]: EntityActivity[]} = {};



  signatoriesEex: ISignature[] = [];
  signatoriesOffice: ISignature[] = [];
  signatoriesEexOther: ISignature[] = [];
  signatoriesOfficeOther: ISignature[] = [];
  loaded = false;
  loadedOffices = false;
  loadedEExs = false;

  //annexe
  protected _documentsSubscription: Subscription;
  workbookDocuments: EntityDocumentMetadata[] = [];

  //emargement
  operatorGroups: AttendanceGroup[] = [];
  displayedOperatorsColumns: string[] = ['name', 'phone', 'extra', 'attendances'];
  operators: {[groupName: string]: MatTableDataSource<Operator>} = {};

  isAttendanceManager: boolean = false;
  isValid: boolean = false;
  attendances: {[operator_id: string]: Attendance[]} = {};

  constructor(
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService) {
    super(dialog, _documentService, _logger, _entityService);
  }

  _init(data) {
    const _update = !!this.entity;
    super._init(data);
    if (this.entity) {
      // this.previousStatus = this.entity.status;
      // if (!this.entity.status) {
      if (!this._signAgreementSubscription) {
        this._signAgreementSubscription = this._preventionPlanService.preventionPlanAgreementService.getSingleOne().subscribe((sa) => {
          if (sa) {
            if (/*!this.entity.status && */sa.text) {
              this.sign_agreement = sa.text.split('\n').join('<br>');
            }
          }
        });
      }
      // } else {
      //  this.sign_agreement = this.entity.signAgreement.split('\n').join('<br>');
      //}
      if (!this._documentsSubscription) {
        this._documentsSubscription = this._preventionPlanService.getDocumentMetaDataList(this.entity.parent_id).subscribe((documents) => {
          if (documents) {
            this.workbookDocuments = documents;
            this.refreshSelectableDocuments();
          }
        });
      }
      if (!this._preventionPlanSubscription) {
        this._preventionPlanSubscription = this._preventionPlanService.getOne(this.entity.parent_id).subscribe((preventionPlan) => {
          this.preventionPlan = preventionPlan;
          this._preventionPlanService.getExploitingCompany(preventionPlan).then((data) => {
            if (data) {
              this.exploitingCompany = data;
            }
          });
          this.refreshSelectableDocuments();
        });
      }
      if (!this._activitiesSubscription) {
        this._activitiesSubscription = this._preventionPlanService.getActivityList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            //this.riskSituationName = {};
            this.oeexActivityList = {};
            this.sir = {};
            this.activities = list;
            this.activities.forEach((activity: EntityActivity) => {
              if (activity.smr) {
                activity.companies
                  .map((e) => (e.id))
                  .forEach((oeex_id) => {
                    if (this.sir[oeex_id]) {
                      this.sir[oeex_id].push(activity);
                    } else {
                      this.sir[oeex_id] = [activity];
                    }
                  });
              }
              if (activity.measures) {
                const entry: {[risk_situation_id: string]: RiskEntry} = {};
                activity.risks_situations.forEach((risk_situation_id) => {
                  const situation = activity.situations.find((s) => (s._id === risk_situation_id));
                  const risk = activity.risks.find((s) => (s._id === (situation ? situation.risk_id : risk_situation_id)));
                  if (risk) {
                    this.riskSituationName[situation ? situation._id : risk._id] = (risk.name + (situation ? ' - ' + situation.name : ''));
                    entry[risk_situation_id] = new RiskEntry(
                      this.riskSituationName[situation ? situation._id : risk._id],
                      activity.measures.filter((measure) => (
                        measure.risk_id === risk._id
                        && (situation
                          ? measure.type === situation._id
                          : (measure.type === '' || measure.type === 'local' || measure.type === 'global')
                        )
                      )),
                      (situation && situation.sir),
                      (situation && situation.fp)
                    );
                    this.withPrior[activity._id] = this.withPrior[activity._id] || entry[risk_situation_id].withPrior;
                    this.withInProgress[activity._id] = activity.measures.findIndex((e) => (!e.prior && !e.after)) !== -1;
                    this.withAfter[activity._id] = this.withAfter[activity._id] || entry[risk_situation_id].withAfter;
                  }
                });
                /*
                activity.risks.forEach((risk) => {
                  entry[risk._id] = new RiskEntry(risk.name, activity.measures.filter((measure) => (measure.risk_id === risk._id)));
                  //entry[risk._id].measures = activity.measures.filter((measure) => (measure.risk_id === risk._id));
                  this.withPrior[activity._id] = this.withPrior[activity._id] || entry[risk._id].withPrior;
                  this.withAfter[activity._id] = this.withAfter[activity._id] || entry[risk._id].withAfter;
                });
                */
                this.risks[activity._id] = entry;
                this.oeexActivityList[activity._id] = activity.companies.map((ac) => (ac.id));
              }
            });
            this.refreshOeexList();
          }
        });
      }
      if (!this._officesSubscription) {
        this._officesSubscription = this._preventionPlanService.getOfficeList(this.entity.parent_id)
          .subscribe((list) => {
            if (list) {
              this.loadedOffices = true;
              this.officeList = list;
              this.refreshOeexList();
            }
          });
      }
      if (!this._externalCompaniesSubscription) {
        this._externalCompaniesSubscription = this._preventionPlanService.getExternalCompanyList(this.entity.parent_id)
          .subscribe((list) => {
            if (list) {
              this.loadedEExs = true;
              this.externalCompanyList = list;
              this.externalCompanyList.forEach((eex) => {
                this.withSubcompanies = this.withSubcompanies || !!eex.main;
              });
              this.refreshOeexList();
            }
          });
      }

    }
    if (_update) {
      this.refreshOeexList();
      this.onUpdate.emit(this.entity);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._signAgreementSubscription) {
      this._signAgreementSubscription.unsubscribe();
    }
    if (this._documentsSubscription) {
      this._documentsSubscription.unsubscribe();
    }
    if (this._preventionPlanSubscription) {
      this._preventionPlanSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._activitiesSubscription) {
      this._activitiesSubscription.unsubscribe();
    }
    if (this._officesSubscription) {
      this._officesSubscription.unsubscribe();
    }
    if (this._externalCompaniesSubscription) {
      this._externalCompaniesSubscription.unsubscribe();
    }
  }

  refreshSelectableDocuments() {
    super.refreshSelectableDocuments();
    const officesWithDocuments = this.officeList
      .filter((o) => (o.documents && o.documents.length > 0 && o.documents.filter((d) => (d.status === 0 && d.data_id)).length > 0));
    const externalCompaniesWithDocuments = this.externalCompanyList
      .filter((o) => (o.documents && o.documents.length > 0 && o.documents.filter((d) => (d.status === 0 && d.data_id)).length > 0));
    const sitesWithDocuments = this.steps
      .filter((o) => (o.site && o.site.documents && o.site.documents.length > 0 && o.site.documents.filter((d) => (d.status === 0 && d.data_id)).length > 0));

    if (this.preventionPlan
      && (
        (this.workbookDocuments && this.workbookDocuments.length)
        || (officesWithDocuments && officesWithDocuments.length)
        || (externalCompaniesWithDocuments && externalCompaniesWithDocuments.length)
        || (sitesWithDocuments && sitesWithDocuments.length)
      )) {
      const annexes = this.workbookDocuments.filter((e) => (e.context.indexOf(this._preventionPlanService.getUrlWorkbook(this.preventionPlan.documentType, this.preventionPlan._id, 'document')) !== -1))
      if (annexes && annexes.length) {
        this.selectableDocuments['Annexes'] = annexes;
        this.withSelectableDocuments = true;
      }
      if (officesWithDocuments && officesWithDocuments.length) {
        this.withSelectableDocuments = true;
        officesWithDocuments.forEach((o) => {
          this.selectableDocuments[o.name] = o.documents.filter((d) => (d.status === 0 && d.data_id));
        });
      }
      if (externalCompaniesWithDocuments && externalCompaniesWithDocuments.length) {
        this.withSelectableDocuments = true;
        externalCompaniesWithDocuments.forEach((o) => {
          this.selectableDocuments[o.name] = o.documents.filter((d) => (d.status === 0 && d.data_id));
        });
      }
      /*
      if (sitesWithDocuments && sitesWithDocuments.length) {
        this.withSelectableDocuments = true;
        sitesWithDocuments.forEach((o) => {
          if (o.site && o.site.name && o.name) {
            this.selectableDocuments[o.site.name + ' (' + o.name + ')'] = o.site.documents.filter((d) => (d.status === 0 && d.data_id));
          }
        });
      }
      */
    }
  }
  /**
  * Format the date for export
  * @param d
  */
  public formattedDate(d: Date = new Date()) {
    return formattedDate(d);
  }
  showOeex(id: string) {
    return true;
    //  return this.activeOeexList.indexOf(id) !== -1;
  }
  refreshOeexList(active = []) {
    this.activeOeexList = active;
    this.oeexStepList.forEach((oeex_id) => {
      if (this.activeOeexList.indexOf(oeex_id) === -1) {
        this.activeOeexList.push(oeex_id);
      }
    });
    this.activityStepList.forEach((a) => {
      if (this.oeexActivityList[a] && this.oeexActivityList[a].length) {
        this.oeexActivityList[a].forEach((oeex_id) => {
          if (this.activeOeexList.indexOf(oeex_id) === -1) {
            this.activeOeexList.push(oeex_id);
          }
        });

      }
    });
    this.offices = this.officeList.filter((e) => (this.showOeex(e._id)));
    this.externalCompaniesName = {};
    this.officeList.forEach((e) => {
      this.externalCompaniesName[e._id] = e.name;
    });
    this.externalCompanyList.forEach((e) => {
      this.externalCompaniesName[e._id] = e.name;
    });
    this.subCompanies = {};
    this.externalCompanyList
      .filter((e) => (!!e.main && this.showOeex(e._id)))
      .forEach((e) => {
        if (!this.subCompanies[e.main]) {
          this.subCompanies[e.main] = [];
        }
        this.subCompanies[e.main].push(e);
      });

    this.externalCompanies = this.externalCompanyList
      .filter((e) => (this.showOeex(e._id) || Object.keys(this.subCompanies).indexOf(e._id) !== -1));
    this.mainExternalCompanies = this.externalCompanies
      .filter((e) => (!e.main));

    this.subCompanyList = Object.keys(this.subCompanies)
      .sort((a, b) => ((this.externalCompaniesName[a] && this.externalCompaniesName[b]) ? this.externalCompaniesName[a].localeCompare(this.externalCompaniesName[b]) : a.localeCompare(b)));

    this.refreshSelectableDocuments();
    this.refreshSignatories();
    this.onOeexChange.emit(this.loadedOffices && this.loadedEExs);
  }
  _isSignatory(contact: Contact) {
    return contact.signatoryPdP;
  }
  refreshSignatories() {
    if (this.entity) {
      this.signatoriesOffice = this.entity.signatures ? this.entity.signatures.filter((s) => (s.companyType === 'EE')) : [];
      this.signatoriesEex = this.entity.signatures ? this.entity.signatures.filter((s) => (s.companyType === 'EEX')) : [];
      if (this.entity.status < SignableStatus.SIGNED) {
        this.offices.forEach((o) => {
          o.contacts.forEach((c) => {
            if (this._isSignatory(c)) {
              if (this.signatoriesOffice.findIndex((s) => (s.contact_id === c.id)) === -1) {
                this.signatoriesOffice.push(this._preventionPlanService.createSignature(c, o));
              }
            }
          });
        });
        this.externalCompanies.forEach((o) => {
          o.contacts.forEach((c) => {
            if (this._isSignatory(c)) {
              if (this.signatoriesEex.findIndex((s) => (s.contact_id === c.id)) === -1) {
                this.signatoriesEex.push(this._preventionPlanService.createSignature(c, o));
              }
            }
          });
        });
      }
      this.refreshSignableStatus((this.entity.signatures.length + this.signatoriesOffice.length + this.signatoriesEex.length) > 0);
      this.signatoriesOfficeOther = this.getOtherSignatoriesOffice();
      this.signatoriesEexOther = this.getOtherSignatoriesEEx();
    }
  }
  getOtherSignatoriesOffice() {
    const returned = [];
    if (this.entity && this.entity.status < SignableStatus.SIGNED) {
      this.officeList.forEach((o) => {
        o.contacts.forEach((c) => {
          if (this._isSignatory(c)
            && this.signatoriesOffice.findIndex((s) => (s.contact_id === c.id)) === -1) {
            returned.push(this._preventionPlanService.createSignature(c, o));
          }
        });
      });
    }
    return returned;
  }
  getOtherSignatoriesEEx() {
    const returned = [];
    if (this.entity && this.entity.status < SignableStatus.SIGNED) {
      this.externalCompanyList.forEach((o) => {
        o.contacts.forEach((c) => {
          if (this._isSignatory(c) && this.signatoriesEex.findIndex((s) => (s.contact_id === c.id)) === -1) {
            returned.push(this._preventionPlanService.createSignature(c, o));
          }
        });
      });
    }
    return returned;
  }
  hasSignatures() {
    return (this.entity.signatures.filter((e) => (!!e.data)).length !== 0)
  }
  getStepLocations(step: EntityStep): EntitySite[] {
    let returned: EntitySite[] = [];
    if (step) {
      const locations = step.getLocationsAnalysis();
      if (step && step.template_id) {
        const s = new EntitySite(step.site);
        const x = locations.findIndex((e) => (e._id === step.site._id));
        if (x !== -1) {
          s.zones = locations[x].zones.filter((z) => (z.measures.length && (z.name === '' || z._id === step.zone._id)));
        }
        if (s.zones.length) {
          returned = [s];
        }
      } else {
        returned = step.getLocationsAnalysis();
      }
    }
    return returned.filter((s) => (s.zones.length));
  }

  getStepActivities(step: EntityStep) {
    if (step && step.activities) {
      return step.activities
        .map((activity_id) => this.activities.find((e) => e._id === activity_id))
        .filter((e) => (!!e));
    }
    return [];
  }
  getMeasureCompanies(measure: EntityWorkbookMeasure, activity: EntityActivity, step: EntityStep) {
    if (measure.global) {
      const returned = [];
      this.steps.forEach((s: EntityStep) => {
        s.activities.forEach((activity_id) => {
          const a = this.activities.find((e) => (e._id === activity_id));
          if (a) {
            a.companies.forEach((ca) => {
              if (returned.indexOf(ca.name) === -1) {
                returned.push(ca.name);
              }
            });
          }
        });
      });
      return returned;
    } else if (step.scopedMeasures && step.scopedMeasures[activity._id] && step.scopedMeasures[activity._id][measure._id]) {
      const oeex = [...this.officeList, ...this.externalCompanyList];
      return step.scopedMeasures[activity._id][measure._id]
        .map((company_id) => (oeex.find((e) => (e._id === company_id))))
        .filter((e) => !!e).map((e) => e.name);
    } else {
      return activity.companies.map((e) => e.name);
    }
  }
  getLocationMeasureCompanies(measure: EntityWorkbookMeasure, zone: EntityZone, step: EntityStep) {
    if (measure.global) {
      const returned = [];
      step.activities.forEach((activity_id) => {
        const a = this.activities.find((e) => (e._id === activity_id));
        if (a) {
          a.companies.forEach((ca) => {
            if (returned.indexOf(ca.name) === -1) {
              returned.push(ca.name);
            }
          });
        }
      });
      return returned;
    } else if (step.scopedMeasures && step.scopedMeasures[zone._id] && step.scopedMeasures[zone._id][measure._id]) {
      const oeex = [...this.officeList, ...this.externalCompanyList];
      return step.scopedMeasures[zone._id][measure._id]
        .map((company_id) => (oeex.find((e) => (e._id === company_id))))
        .filter((e) => !!e).map((e) => e.name);
    } else {
      return zone.companies.map((e) => e.name);
    }
  }

  hasPrior(list: any[]) {
    return list.findIndex((e) => (e['prior'])) !== -1
  }
  hasDuring(list: any[]) {
    return list.findIndex((e) => (!e['prior'] && !e['after'])) !== -1
  }
  hasAfter(list: any[]) {
    return list.findIndex((e) => (e['after'])) !== -1
  }
  public checkSignPermission(signature: EntitySignature): boolean {
    return (this.hasPermission(this.SIGNUP_CHECKER) || signature.user_id === this.getUserId())
      && this.entity && this.entity.status === SignableStatus.SIGNING
      && this.preventionPlan && this.preventionPlan.getStatus() !== EntityPreventionPlan.STATUS_COMPLETE;
    //      && !(this.preventionPlan && this.preventionPlan.type !== 'occasional' && this.preventionPlan.getStatus() !== EntityPreventionPlan.STATUS_COMPLETE);
  }

  /**
   * Delete a signature
   * @param {Signature} signature
   */
  public deleteSignature(signature: EntitySignature) {
    let dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer cette signature ?';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this.entity.removeSignature(signature);
        this._save().then((res) => {
          this.refreshSignatories();
          this.onSave.emit(this.entity);
        });
      }
      dialogRefDel = null;
    });

  }

  /**
     * Open popup dialog to create a signature
     * @param {Signature} signature
     */
  addSignature(signature: EntitySignature) {
    if (this.checkSignPermission(signature)) {
      const dialogRef = this.dialog.open(SignatureDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: [new EntitySignature(signature), this.sign_agreement],
      });

      dialogRef.afterClosed().subscribe((dialogSignature: EntitySignature) => {
        if (dialogSignature && dialogSignature.data) {
          this.entity.signUp(dialogSignature);
          this._save().then((res) => {
            this.sendSignNotification(dialogSignature);
            this.refreshSignatories();
            this.onSave.emit(this.entity);
          });
        }
      });
    }
  }
  sendSignNotification(dialogSignature: EntitySignature) {
    if (this.entity.notifications && this.entity.notifications.length) {
      this.sendNotification(this.SIGN_NOTIFY, this.entity.notifications, true, dialogSignature);
    }
  }
  sendNotification(type: number, contacts?: any[], send: boolean = true, signature: EntitySignature = null): void {
    this._emailService.getEmailDatas(type).then(emailObj => {
      if (contacts && contacts.length > 0) {
        contacts.forEach((contact) => {
          if (contact) {
            if (contact.email) {
              const emailDatas = new EmailDatasModel();
              emailDatas.email = contact.email;
              emailDatas.to_name = contact.name;
              emailDatas.from_name = emailObj.fromName;
              if (emailObj.fromEmail) {
                emailDatas.from_email = emailObj.fromEmail;
              }
              emailDatas.date = new Date();
              emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.subject, this.preventionPlan, this.entity, signature);
              emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.text, this.preventionPlan, this.entity, signature);
              if (contact.userId) {
                emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.usertext, this.preventionPlan, this.entity, signature);
              }
              emailDatas.signature = emailObj.signature;
              emailDatas.rgpdMail = emailObj.rgpdMail;
              emailDatas.dpoName = emailObj.dpoName;
              emailDatas.dpoMail = emailObj.dpoMail;
              /*
              emailDatas.signature = 'Cordialement,<br>'
                + this._entityService.getUserName() + '<br>'
                + this._emailService.getCompanyName();
              */
              this._emailService.sendEmail(emailDatas);
            }
          }
        });
        if (send) {
          this.snackBar.open('Les messages ont été envoyés', '', {
            duration: 2000
          });
        }
      }
    }).catch((error) => {
      this._logger.error('abstractSignableDialog', 'error send email', JSON.stringify(error));
    });
  }
  updateLocationRisk(step: EntityStep) {
    if (step) {
      const locations = step.getLocations();
      //this.locations_riskSituationName = {};
      locations.forEach((site: EntitySite) => {
        site.zones.forEach((zone: EntityZone) => {
          if (zone.measures) {
            const entry: {[risk_situation_id: string]: RiskEntry} = {};
            zone.risks_situations.forEach((risk_situation_id) => {
              const situation = zone.situations.find((s) => (s._id === risk_situation_id));
              const risk = zone.risks.find((s) => (s._id === (situation ? situation.risk_id : risk_situation_id)));
              if (risk) {
                this.riskSituationName[situation ? situation._id : risk._id] = (risk.name + (situation ? ' - ' + situation.name : ''));
                entry[risk_situation_id] = new RiskEntry(
                  this.riskSituationName[situation ? situation._id : risk._id],
                  zone.measures.filter((measure) => (
                    measure.risk_id === risk._id
                    && (situation
                      ? measure.type === situation._id
                      : (measure.type === '' || measure.type === 'local' || measure.type === 'global')
                    )
                  )),
                  (situation && situation.sir),
                  (situation && situation.fp)
                );
                this.withPrior[zone._id] = this.withPrior[zone._id] || entry[risk_situation_id].withPrior;
                this.withInProgress[zone._id] = zone.measures.findIndex((e) => (!e.prior && !e.after)) !== -1;
                this.withAfter[zone._id] = this.withAfter[zone._id] || entry[risk_situation_id].withAfter;
              }
            });
            this.risks[zone._id] = entry;
          }
        });
      });
    }

  }
}
