import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntitySite} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends EntitySecureService {
  autoInit: boolean = false;
  static LABEL_ONE = "chargement du site";
  static LABEL_ALL = "chargement des sites";
  static LABEL_CHILDREN = "chargement des sites de l'opération ?";
  static LABEL_SAVE = "sauvegarde du site";
  static LABEL_DELETE = "suppression du site";

  static ENTITY_TYPE: string = 'repo_site';
  static ENTITY_FIELDS: string[] = ['name', 'address', 'zipCode', 'city', 'phone', 'documents', 'zones', 'risks', 'situations', 'risks_situations', 'measures'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showSite'],
    CREATE: ['createSite', 'editSite'],
    UPDATE: ['editSite'],
    DELETE: ['removeSite']
  }

  static ENTITY_URL = 'repository/site';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }

  init() {
    super.init();
  }

  factory(data, clone: boolean = false): EntitySite {
    return new EntitySite(data, clone);
  }

}
