import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySingleService, LoadingService} from 'app/shared';
import {EntityEmergencyInstructions} from '../models/entityEmergencyInstructions.model';

@Injectable({
  providedIn: 'root'
})
export class EmergencyInstructionsService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement du contenu des consignes de sécurité";
  static LABEL_ALL = "chargement des consignes de sécurité ?";
  static LABEL_CHILDREN = "chargement des consignes de sécurité de l'opération ?";
  static LABEL_SAVE = "sauvegarde des consignes de sécurité";
  static LABEL_DELETE = "suppression des consignes de sécurité ?";

  static ENTITY_TYPE: string = 'repo_emergency';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showEmergencyInstructions'],
    CREATE: ['editEmergencyInstructions'],
    UPDATE: ['editEmergencyInstructions'],
    DELETE: ['editEmergencyInstructions']
  }

  static ENTITY_URL = 'repository/emergency';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityEmergencyInstructions {
    return new EntityEmergencyInstructions(data, clone);
  }

}
