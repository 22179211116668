import {PermissionsConfig} from 'app/shared';

export class AppConfig {
  public static DBVERSION: number = 1;

  public static PERMISSIONS: PermissionsConfig[] = [
    // Module Config
    {
      name: 'config',
      description: 'Module Configuration',
      groups: [
        {
          group_name: 'Gérer les informations de l\'entreprise utilisatrice',
          permissions: {
            'showEE': {
              title: 'Voir l\'entreprise utilisatrice',
              description: 'Permet de voir les informations de l\'entreprise utilisatrice'
            },
            'editEE': {
              title: 'Modifier l\'entreprise utilisatrice',
              description: 'Permet de modifier les informations de l\'entreprise utilisatrice'
            }
          }
        },
        {
          group_name: 'Gérer les modèles d\'emails',
          permissions: {
            'showEmails': {
              title: 'Voir les modèles d\'emails',
              description: 'Permet de voir les modèles d\'emails'
            },
            'editEmails': {
              title: 'Modifier les modèles d\'emails',
              description: 'Permet de modifier les modèles d\'emails'
            }
          }
        },
        {
          group_name: 'Gérer les paramètres',
          permissions: {
            'showConfigs': {
              title: 'Voir les paramètres',
              description: 'Permet de voir le menu des paramètres'
            },
            'editConfigs': {
              title: 'Modifier les paramètres',
              description: 'Permet de modifier les paramètres (serveur, login)'
            }
          }
        },
        {
          group_name: 'Voir les logs',
          permissions: {
            'showLogs': {
              title: 'Voir les logs',
              description: 'Permet de voir toutes les erreures enregistrées par l\'application'
            }
          }
        },
        {
          group_name: 'Gérer les utilisateurs',
          permissions: {
            'showUsers': {
              title: 'Voir les utilisateurs',
              description: 'Permet de voir la liste des utilisateurs'
            },
            'createUser': {
              title: 'Ajouter des utilisateurs',
              description: 'Permet d\'ajouter un nouvel utilisateur'
            },
            'editUser': {
              title: 'Modifier les utilisateurs',
              description: 'Permet de modifier les informations de l\'utilisateur'
            },
            'removeUser': {
              title: 'Supprimer des utilisateurs',
              description: 'Permet de supprimer les utilisateurs'
            },
            'manageUserSync': {
              title: 'Modifier les paramètres de synchronisation',
              description: 'Permet de modifier les paramètres de synchronisation des utilisateurs'
            }
          }
        },
        {
          group_name: 'Gérer les permissions',
          permissions: {
            'showPermissions': {
              title: 'Voir les permissions',
              description: 'Permet de voir les permissions de chaque rôle'
            },
            'editPermissions': {
              title: 'Modifier les permissions',
              description: 'Permet de modifier les permissions pour chaque rôle'
            }
          }
        },
        {
          group_name: 'Gérer les paramètres Pintura',
          permissions: {
            'showPinturaConfig': {
              title: 'Voir les paramètres Pintura',
              description: 'Permet de voir les paramètres Pintura'
            },
            'editPinturaConfig': {
              title: 'Modifier les paramètres Pintura',
              description: 'Permet de modifier les paramètres Pintura'
            }
          }
        },
        {
          group_name: 'Gérer les champs spécifiques',
          permissions: {
            'showExtraConfig': {
              title: 'Voir les champs spécifiques',
              description: 'Permet de voir le paramétrage des champs spécifiques'
            },
            'editExtraConfig': {
              title: 'Modifier les champs spécifiques',
              description: 'Permet de modifier le paramétrage des champs spécifiques'
            }
          }
        }
      ]
    },
    // Module Admin
    {
      name: 'admin',
      description: 'Module Admin',
      groups: [
        {
          group_name: 'Faire apparaitre le bouton paramétrage des documents',
          permissions: {
            'showAdmin': {
              title: 'Lien vers le paramétrage des documents',
              description: 'Permet d\'afficher le bouton vers le paramétrage des documents'
            },
          }
        },
        {
          group_name: 'Gérer tous les sites de l\'entreprise utilisatrice',
          permissions: {
            'showSite': {
              title: 'Voir les sites',
              description: 'Permet de voir la liste des sites de l\'entreprise utilisatrice'
            },
            'editSite': {
              title: 'Créer/Modifier des sites',
              description: 'Permet de créer/modifier les sites de l\'entreprise utilisatrice'
            },
            'removeSite': {
              title: 'Supprimer des sites',
              description: 'Permet de suprimer des sites de l\'entreprise utilisatrice'
            },
            'importSite': {
              title: 'Importer des sites',
              description: 'Permet d\'importer des sites de l\'entreprise utilisatrice'
            }
          }
        },
        {
          group_name: 'Gérer les sevices',
          permissions: {
            'showOffice': {
              title: 'Voir les services',
              description: 'Permet de voir la liste des services du réferentiel'
            },
            'editOffice': {
              title: 'Créer/Modifier les services',
              description: 'Permet de créer/modifier les services dans le réferentiel'
            },
            'importOffice': {
              title: 'Importer des services',
              description: 'Permet d\'importer des services'
            },
            'showOfficeDocuments': {
              title: 'Voir les documents sur les services',
              description: 'Permet de voir les documents liés à un service sur la fiche de ce service'
            },
            'createOfficeDocuments': {
              title: 'Créer/demander les documents depuis les services',
              description: 'Permet de créer/demander des documents à un service depuis la fiche de ce service'
            }
          }
        },
        {
          group_name: 'Gérer la visibilité au niveau des services',
          permissions: {
            'showOfficeVisibility': {
              title: 'Voir la visibilité des services',
              description: 'Permet de voir le paramétrage de la visibilité des opérations par service'
            },
            'editOfficeVisibility': {
              title: 'Modifier la visibilité des services',
              description: 'Permet de modifier le paramétrage de la visibilité des opérations par service'
            }
          }
        },
        {
          group_name: 'Gérer la synchronisation des utilisateurs sur les services',
          permissions: {
            'manageOfficeUsers': {
              title: 'Paramètrer la synchronisation des utilisateurs par service',
              description: 'Permet de modifier la liste des utilisateurs qui synchroniseront les opérations créees par le service'
            }
          }
        },
        {
          group_name: 'Gérer les types de structure',
          permissions: {
            'createOrganization': {
              title: 'Ajouter des types de structure EU',
              description: 'Permet d\'ajouter de nouveaux types de structure EU'
            },
            'editOrganization': {
              title: 'Modifier les types de structure EU',
              description: 'Permet de modifier les types de structure EU'
            },
            'removeOrganization': {
              title: 'Supprimer des types de structure EU',
              description: 'Permet de supprimer les types de strucutre EU'
            }
          }
        },
        {
          group_name: 'Gérer les documents de type consignes générales',
          permissions: {
            'showRepoDocument': {
              title: 'Voir les documents de type consignes générales',
              description: 'Permet de voir les documents ajoutés de type consignes générales'
            },
            'createRepoDocument': {
              title: 'Créer des documents de type consignes générales',
              description: 'Permet d\'ajouter des documents de type consignes générales'
            },
            'editRepoDocument': {
              title: 'Modifier les documents de type consignes générales',
              description: 'Permet de modifier les documents de type consignes générales'
            },
            'removeRepoDocument': {
              title: 'Supprimer des documents de type consignes générales',
              description: 'Permet de supprimer les documents de type consignes générales'
            }
          }
        },
        {
          group_name: 'Gérer les entreprises extérieures',
          permissions: {
            'showEEX': {
              title: 'Voir la liste des entreprises extérieures',
              description: 'Permet de voir et d\'exporter la liste des entreprises extérieures'
            },
            'createEEX': {
              title: 'Ajouter des entreprises extérieures',
              description: 'Permet d\'ajouter de nouvelles entreprises extérieures'
            },
            'editEEX': {
              title: 'Modifier des entreprises extérieures',
              description: 'Permet de modifier les entreprises extérieures'
            },
            'removeEEX': {
              title: 'Supprimer des entreprises extérieures',
              description: 'Permet de supprimer des entreprises extérieures'
            },
            'importEEX': {
              title: 'Importer des entreprises extérieures',
              description: 'Permet d\'importer des entreprises extérieures'
            },
            'showEEXDocuments': {
              title: 'Voir les documents sur les entreprises extérieures',
              description: 'Permet de voir les documents liés à une entreprise extérieure sur la fiche de cette entreprise'
            },
            'createEEXDocuments': {
              title: 'Créer/demander les documents depuis les  entreprises extérieures',
              description: 'Permet de créer/demander des documents à une entreprise extérieure depuis la fiche de cette entreprise'
            }
          }
        },
        {
          group_name: 'Gérer les métiers',
          permissions: {
            'createJob': {
              title: 'Ajouter des métiers',
              description: 'Permet d\'ajouter de nouveaux métiers'
            },
            'editJobs': {
              title: 'Modifier les métiers',
              description: 'Permet de modifier les métiers'
            },
            'deleteJob': {
              title: 'Supprimer des métiers',
              description: 'Permet de supprimer des métiers'
            }
          }
        },
        {
          group_name: 'Gérer les demandes types de documents',
          permissions: {
            'showRepoDocumentsRequest': {
              title: 'Voir les demandes types',
              description: 'Permet de voir la liste des demandes types de documents'
            },
            'createRepoDocumentsRequest': {
              title: 'Ajouter des demandes types de documents',
              description: 'Permet d\'ajouter des demandes types de documents'
            },
            'editRepoDocumentsRequest': {
              title: 'Modifier les demandes types de documents',
              description: 'Permet de modifier la liste des demandes types de documents'
            },
            'deleteRepoDocumentsRequest': {
              title: 'Supprimer des demandes types de documents',
              description: 'Permet de supprimer des demandes types de documents'
            }
          }
        },
        {
          group_name: 'Modification des images',
          permissions: {
            'modifyRepoImage': {
              title: 'Modifier les images (dessin sur plan)',
              description: 'Permet de modifier les images (édition en ligne, dessin sur plan)'
            }
          }
        },
        {
          group_name: 'Gérer les locaux documents consignes et matériel des inspections communes',
          permissions: {
            'showLdcm': {
              title: 'Voir les paramètres des inspections communes',
              description: 'Permet de voir la liste des locaux, documents, consignes et matériel'
            },
            'createLdcm': {
              title: 'Ajouter les paramètres des inspections communes',
              description: 'Permet d\'ajouter des locaux, document, consignes et matériel'
            },
            'editLdcm': {
              title: 'Modifier les paramètres des inspections communes',
              description: 'Permet de modifier la liste des locaux, document, consignes et matériel'
            },
            'deleteLdcm': {
              title: 'Supprimer les paramètres des inspections communes',
              description: 'Permet de supprimer des locaux, document, consignes et matériel'
            }
          }
        },
        {
          group_name: 'Gérer le contenu des permis feu',
          permissions: {
            'showFirePermitContent': {
              title: 'Voir le contenu des permis feu',
              description: 'Permet de voir le contenu qui sera utilisé dans les permis feu'
            },
            'editFirePermitContent': {
              title: 'Modifier le contenu des permis feu',
              description: 'Permet de modifier le contenu qui sera utilisé dans les permis feu'
            }
          }
        },
        {
          group_name: 'Gérer le contenu des permis ',
          permissions: {
            'showPermitContent': {
              title: 'Voir le contenu des permis ',
              description: 'Permet de voir le contenu qui sera utilisé dans les permis '
            },
            'editPermitContent': {
              title: 'Modifier le contenu des permis',
              description: 'Permet de modifier le contenu qui sera utilisé dans les permis'
            },
            'removePermitContent': {
              title: 'supprimer un contenu de permis',
              description: 'Permet de supprimer un type de permis'
            }
          }
        },
        {
          group_name: 'Gérer le contenu des protocoles de sécurité',
          permissions: {
            'showProtocolContent': {
              title: 'Voir le contenu des protocoles',
              description: 'Permet de voir le contenu qui sera utilisé dans les protocoles de sécurité'
            },
            'editProtocolContent': {
              title: 'Modifier le contenu des protocoles',
              description: 'Permet de modifier le contenu qui sera utilisé dans les protocoles de sécurité'
            }
          }
        },
        {
          group_name: 'Gérer les consignes d\'urgence (PdP)',
          permissions: {
            'showEmergencyInstructions': {
              title: 'Voir les consignes d\'urgence',
              description: 'Permet de voir les consignes d\'urgence qui seront utilisées dans les plans de prévention'
            },
            'editEmergencyInstructions': {
              title: 'Modifier les consignes d\'urgence',
              description: 'Permet de modifier les consignes d\'urgence qui seront utilisées dans les plans de prévention'
            }
          }
        },
        {
          group_name: 'Gérer les points de contrôle (Visite)',
          permissions: {
            'showAuditCheckpoints': {
              title: 'Voir les points de contrôle (Visite)',
              description: 'Permet de voir les points de contrôle qui seront utilisés dans les visites de contrôle'
            },
            'editAuditCheckpoints': {
              title: 'Modifier les points de contrôle (Visite)',
              description: 'Permet de modifier les points de contrôle qui seront utilisés dans les visites de contrôle'
            }
          }
        },
        {
          group_name: 'Gérer les risques utilisés pour les analyses de risque',
          permissions: {
            'showRisks': {
              title: 'Voir les risques',
              description: 'Permet de voir la liste des risques utilisés dans les analyses de risque'
            },
            'createRisk': {
              title: 'Ajouter des risques',
              description: 'Permet d\'ajouter des risques qui seront utilisés pour les analyses de risque'
            },
            'editRisks': {
              title: 'Modifier les risques',
              description: 'Permet de modifier les risques utilisés dans les analyses de risque'
            },
            'deleteRisk': {
              title: 'Supprimer des risques',
              description: 'Permet de supprimer des risques utilisés dans les analyses de risque'
            }
          }
        },
        {
          group_name: 'Gérer les mesures associées aux risques',
          permissions: {

            'editMeasures': {
              title: 'Modifier des mesures',
              description: 'Permet de modifier les mesures associées aux risques'
            },
            'createMeasure': {
              title: 'Ajouter des mesures',
              description: 'Permet de créer des mesures qui seront associées aux risques'
            },
            'deleteMeasure': {
              title: 'Supprimer des mesures',
              description: 'Permet de supprimer les mesures associées aux risques'
            },
            'importMeasure': {
              title: 'Importer des mesures',
              description: 'Permet d\'importer des mesures'
            }
          }
        },
        {
          group_name: 'Gérer les situations',
          permissions: {
            'showSituation': {
              title: 'Voir les situations',
              description: 'Permet de voir les situations'
            },
            'createSituation': {
              title: 'Ajouter des situations',
              description: 'Permet d\'ajouter de nouvelles situations'
            },
            'editSituation': {
              title: 'Modifier les situations',
              description: 'Permet de modifier les situations'
            },
            'removeSituation': {
              title: 'Supprimer des situations',
              description: 'Permet de supprimer des situations'
            }
          }
        },
        {
          group_name: 'Paramétrer les fréquences des inspections communes',
          permissions: {
            'showIcpRecurrence': {
              title: 'Voir les fréquences des inspections communes',
              description: 'Permet de voir la fréquence des inspections communes des opérations'
            },
            'editIcpRecurrence': {
              title: 'Modifier les fréquences des inspections communes',
              description: 'Permet de modifier la fréquence des inspections communes des opérations'
            }
          }
        },
        {
          group_name: 'Paramétrer le tableau de bord',
          permissions: {
            'showDashboardConfig': {
              title: 'Voir les paramètres du tableau de bord',
              description: 'Permet de voir les paramètres du tableau de bord'
            },
            'editDashboardConfig': {
              title: 'Modifier les paramètres du tableau de bord',
              description: 'Permet de modifier les paramètres du tableau de bord'
            }
          }
        },
        {
          group_name: 'Gérer les types de documents',
          permissions: {
            'showCategory': {
              title: 'Voir les types de document',
              description: 'Voir les types de document'
            },
            'createCategory': {
              title: 'Ajouter des types de document',
              description: 'Permet d\'ajouter de nouveaux types de document'
            },
            'editCategory': {
              title: 'Modifier les types de document',
              description: 'Permet de modifier les types de document'
            },
            'removeCategory': {
              title: 'Supprimer des types de document',
              description: 'Permet de supprimer les types de document'
            }
          }
        },
        {
          group_name: 'Gérer les dispositions générales:',
          permissions: {
            'showAgreements': {
              title: 'Modifier les dispositions générales',
              description: 'Permet de modifier les dispositions générales'
            }
          }
        },
        {
          group_name: 'Gérer la visibilité par défaut',
          permissions: {
            'showDefaultVisibility': {
              title: 'Voir la visibilité par défaut',
              description: 'Permet de voir la visibilité par défaut des opérations'
            },
            'editDefaultVisibility': {
              title: 'Modifier la visibilité par défaut',
              description: 'Permet de modifier la visibilité par défaut des opérations'
            }
          }
        }
      ]
    },
    // Module Workbooks
    {
      name: 'dashboard',
      description: 'Module Dashboard',
      groups: [
        {
          group_name: 'Gérer les opérations',
          permissions: {
            'showAllDocuments': {
              title: 'Voir toutes les opérations',
              description: 'Permet de voir toutes les opérations sur le tableau de bord (sans ce droit l\'utilisateur ne voit que les opérations auxquelles il est associé)'
            },
            'syncAllDocuments': {
              title: 'Charger les opérations d\'autres services',
              description: 'Permet de synchroniser les opérations d\'autrres services (sans ce droit l\'utilisateur ne voit pas la colonne synchro sur le dashboard)'
            },
            'createPP': {
              title: 'Créer une opération',
              description: 'Permet de créer des opérations'
            },
            'exportPP': {
              title: 'Exporter les opérations',
              description: 'Permet d\'exporter toutes les opérations'
            },
            'statsPP': {
              title: 'Voir les statistiques des opérations',
              description: 'Permet de voir les statistiques des opérations'
            }
          }
        },
        {
          group_name: 'Gérer les paramètres de synchronisation',
          permissions: {
            'showSyncFilter': {
              title: 'Voir les paramètres de synchronisation',
              description: 'Permet de voir ses paramètres de synchronisation depuis le tableau de bord'
            },
            'editSyncFilter': {
              title: 'Modifier les paramètres de synchronisation',
              description: 'Permet de modifier ses paramètres de synchronisation depuis le tableau de bord'
            }
          }
        }
      ]
    },
    // Module PP
    {
      name: 'pp',
      description: 'Module plan de prévention',
      groups: [
        {
          group_name: 'Gérer les opérations',
          permissions: {
            'showPP': {
              title: 'Afficher le dashboard',
              description: 'Permet d\'afficher le dashboard'
            },
            'editPP': {
              title: 'Modifier les opérations',
              description: 'Permet de modifier les opérations et leur phasage'
            },
            'removePP': {
              title: 'Supprimer une opération',
              description: 'Permet de supprimer les opérations'
            },
            'archivePP': {
              title: 'Archiver les opérations',
              description: 'Permet d\'archiver les opérations'
            },
            'duplicatePP': {
              title: 'Dupliquer une opération',
              description: 'Permet de duppliquer les opérations'
            },
            'editSumPP': {
              title: 'Modifier les informations générales des opérations',
              description: 'Permet de modifier les informations générales des opérations'
            },
            'notifyPP': {
              title: 'Notifier sur un plan',
              description: 'Permet de communiquer avec les intervenants d\'une opération'
            }
          }
        },
        {
          group_name: 'Gérer la visibilité des opérations',
          permissions: {
            'showVisibility': {
              title: 'Voir la visibilité des opérations',
              description: 'Permet de voir la visibilité des opérations'
            },
            'editVisibility': {
              title: 'Modifier la visibilité des opérations',
              description: 'Permet de modifier la visibilité des opérations'
            },
            'setOfficeVisibility': {
              title: 'Ajuster la visibilité aux services',
              description: 'Permet de ajouter/supprimer automatiquement les services à la visibilité'
            }
          }
        },
        {
          group_name: 'Gérer les utilisateurs',
          permissions: {
            'showUsersPP': {
              title: 'Afficher/créer des utilisateurs',
              description: 'Permet de voir la liste des utilisateurs à la création d\'un nouveau contact et de créer des comptes utilisateurs'
            },
            'linkAnyUser': {
              title: 'Associer des utilisateurs spécifiques',
              description: 'Permet de lier un contact à un compte utilisateur avec une adresse mail differente'
            },
            /*
             plus utilisé ?
            'editEexPPUser': {
              title: 'Modifier des comptes d\'entreprises extérieures',
              description: ''
            },
            */
          }
        },
        {
          group_name: 'Gérer les services de l\'entreprise utilisatrice',
          permissions: {
            'showEePP': {
              title: 'Voir les services de l\'entreprise utilisatrice',
              description: 'Permet de voir la liste des services impliqués dans l\'opération'
            },
            'editEePP': {
              title: 'Modifier les services de l\'entreprise utilisatrice',
              description: 'Permet de modifier les services impliqués dans l\'opération'
            }
          }
        },
        {
          group_name: 'Gérer les documents depuis les services',
          permissions: {
            'showOfficePPDocuments': {
              title: 'Voir les documents sur les services',
              description: 'Permet de voir les documents liés à un service sur la fiche de ce service'
            },
            'createOfficePPDocuments': {
              title: 'Créer/demander les documents depuis les services',
              description: 'Permet de créer/demander des documents à un service depuis la fiche de ce service'
            }
          }
        },
        {
          group_name: 'Gérer les entreprises extérieurs',
          permissions: {
            'showEexPP': {
              title: 'Voir les entreprises extérieures',
              description: 'Permet de voir la liste des entreprises extérieures de l\'opération'
            },
            'createEexPP': {
              title: 'Ajouter des entreprises extérieures',
              description: 'Permet d\'ajouter des entreprises extérieures à l\'opération'
            },
            'editEexPP': {
              title: 'Modifier des entreprises extérieures',
              description: 'Permet de modifier les entreprises extérieures de l\'opération'
            },
            'removeEexPP': {
              title: 'Supprimer des entreprises extérieures',
              description: 'Permet de supprimer les entreprises extérieures de l\'opération'
            }
          }
        },
        {
          group_name: 'Gérer les documents depuis les entreprises extérieures',
          permissions: {
            'showEexPPDocuments': {
              title: 'Voir les documents sur les entreprises extérieures',
              description: 'Permet de voir les documents liés à l\'entreprise extérieure sur la fiche de cette entreprise extérieure'
            },
            'createEexPPDocuments': {
              title: 'Créer/demander les documents depuis les entreprises extérieures',
              description: 'Permet de créer/demander des documents à l\'entreprise extérieure depuis la fiche de cette entreprise extérieure'
            }
          }
        },
        {
          group_name: 'Gérer la documentation',
          permissions: {
            'showAnnexesPP': {
              title: 'Voir la documentation',
              description: 'Permet de voir la partie documentation d\'une opération'
            },
            'createAnnexePP': {
              title: 'Ajouter de la documentation',
              description: 'Permet d\'ajouter des documents dans la documentation'
            },
            'removeAnnexePP': {
              title: 'Supprimer de la documentation',
              description: 'Permet de supprimer des documents de la documentation'
            },
            'modifyImagePP': {
              title: 'Modifier les images (dessin sur plan)',
              description: 'Permet de modifier les images (édition en ligne, dessin sur plan)'
            }
          }
        },
        {
          group_name: 'Modification des données issues du référentiel',
          permissions: {
            'removeRepoDocumentsPP': {
              title: 'Supprimer des consignes obligatoires',
              description: 'Permet de supprimer des consignes obligatoires'
            },
            'modifyRepoSite': {
              title: 'Modifier les sites issus du référentiel',
              description: 'Permet de modifier l\'adresse des sites issus du référentiel'
            },
            'modifyRepoµOffice': {
              title: 'Modifier les services issus du référentiel',
              description: 'Permet de modifier l\'adresse des services issus du référentiel'
            },
            'modifyRepoEex': {
              title: 'Modifier les entreprises exterieures issues du référentiel',
              description: 'Permet de modifier l\'adresse des entreprises exterieures issues du référentiel'
            }
          }
        },
        {
          group_name: 'Gérer les activités à risque d\'une opération',
          permissions: {
            'createActivityPP': {
              title: 'Créer des activités à risques',
              description: 'Permet de créer des activités dans une opération'
            },
            'editActivityPP': {
              title: 'Modifier des activités à risques',
              description: 'Permet de modifier les activités à risques d\'une opération'
            },
            'removeActivityPP': {
              title: 'supprimer des activités à risques',
              description: 'Permet de supprimer des activités à risques d\'une opération'
            }
          }
        },
        {
          group_name: 'Gérer les risques d\'une opération',
          permissions: {
            'showRisksPP': {
              title: 'Voir les risques',
              description: 'Permet d\'ajouter au menu d\'une opération le lien vers les risques de co-activités'
            },
            'editRisksPP': {
              title: 'Modifier des risques',
              description: 'Permet de modifier des risques de l\'opération'
            },
            'removeRiskPP': {
              title: 'Supprimer des risques',
              description: 'Permet de supprimer des risques de l\opération'
            },
          }
        },
        {
          group_name: 'Gérer les paramètres des contrats périodiques',
          permissions: {

            'createSpecificStep': {
              title: 'Créer des interventions hors chantier type',
              description: 'Permet de créer des interventions spécifiques qui ne reprennent pas l\'analyse de risque d\'un chantier type'
            },
            'hideRiskLevelOnSystematic': {
              title: 'Masquer niveau de risque (si inspection commune systématique)',
              description: 'Permet de masquer l\'encart du niveau de risque lorsque le chantier type est en inspection systématique'
            }
          }
        },
        {
          group_name: 'Gérer les inspections communes',
          permissions: {
            'showICP': {
              title: 'Voir l\'Inspection Commune',
              description: 'Permet de voir la liste des inspections communes de l\'opération'
            },
            'createICP': {
              title: 'Ajouter une Inspection Commune',
              description: 'Permet d\'ajouter des inspections communes à l\'opération'
            },
            'removeICP': {
              title: 'Supprimer une Inspection Commune',
              description: 'Permet de supprimer des inspections communes de l\'opération'
            }
          }
        },
        {
          group_name: 'Gérer le contenu d\'une inspection commune',
          permissions: {
            'reconveneICP': {
              title: 'Convoquer les utilisateurs d\'une inspection commune',
              description: 'Permet de convoquer des utilisateurs inscrits à l\'inspection commune'
            },
            'editICPPresent': {
              title: 'Modifier l\'émargement d\'une inspection commune',
              description: 'Permet de modifier l\'émargement des participants à l\'inspection commune'
            },
            'editICPRemark': {
              title: 'Modifier les remarques d’une inspection commune',
              description: 'Permet de modifier les remarques de l\'inspection commune'
            },
            'editICPLDCM': {
              title: 'Modifier locaux, documents, consignes et matériel',
              description: 'Permet de mofidier les locaux, documents, consignes et matériel de l\'inspection commune'
            }
          }
        },
        {
          group_name: 'Gérer les signatures de l\'inspection commune',
          permissions: {
            'signupCheckerICP': {
              title: 'Faire signer des inspections communes depuis sa session',
              description: 'Permet de faire signer l\'inspection commune par un autre signataire depuis sa session'
            },
            'manageICPSignup': {
              title: 'Gérer les signatures de l\'inspection commune',
              description: 'Permet de gérer les signature de l\'inspection commune'
            },
            'requestICPSignup': {
              title: 'Demander la signature de l\'inspection commune',
              description: 'Permet de gérer passer l\'inspection commune au statut "à signer" (ou "validé" pour les ICP sans signataires)'
            }
            /*
            plus utilisé
             'showSignPP': {
               title: 'Voir les signatures',
               description: ''
             },
             'editSignNamePP': {
               title: 'Modifier le signataire',
               description: ''
             },
             */
          }
        },
        {
          group_name: 'Gérer les éditions du plan de prévention',
          permissions: {
            'showSignablePdP': {
              title: 'Voir les editions du plan de prévention',
              description: 'Permet de voir la liste des éditions du plan de prévention'
            },
            'createSignablePdP': {
              title: 'Créer des editions du plan de prévention',
              description: 'Permet de créer des éditions du plan de prévention'
            },
            'createSignableStandalonePdP': {
              title: 'Créer des editions du plan de prévention sans inspection commune',
              description: 'Permet de créer des éditions du plan de prévention sans inspection commune associée'
            },
            'editSignablePdP': {
              title: 'Modifier des editions du plan de prévention',
              description: 'Permet de modifier l\'édition d\'un plan de prévention'
            },
            'removeSignablePdP': {
              title: 'Supprimer des editions du plan de prévention',
              description: 'Permet de supprimer des éditions du plan de prévention'
            },
            'attendanceSignablePdP': {
              title: 'Émargement sur les plans de préventions',
              description: 'Permet de faire émarger les opérateurs sur les plans de préventions'
            }
          }
        },
        {
          group_name: 'Gérer les signatures d\'un plan de prévention',
          permissions: {
            'manageSignablePdPSignup': {
              title: 'Gérer les signatures du plan',
              description: 'Permet de gérer les signatures du plan de prévention'
            },
            'signupCheckerPP': {
              title: 'Faire signer des plans depuis sa session',
              description: 'Permet de faire signer le plan de prévention par un autre signataire depuis sa session'
            },
            'requestPdPSignup': {
              title: 'Demander la signature du plan de prévention',
              description: 'Permet de gérer passer du plan de prévention au statut "à signer"'
            }
          }
        },
        {
          group_name: 'Gérer les statuts',
          permissions: {
            'manageICPStatus': {
              title: 'Gérer le statut de l\'inspection commune',
              description: 'Permet de gérer le statut des inspections communes (sélecteur)'
            },
            'manageSignablePdPStatus': {
              title: 'Gérer le statut des editions des plans et protocoles',
              description: 'Permet de gérer le statut des édition du plan de prévention et protocoles de sécurité (sélecteur)'
            },
            'manageDocumentStatus': {
              title: 'Gérer le statut des permis',
              description: 'Permet de gérer le statut des permis'
            }
          }
        },
        {
          group_name: 'Gérer les visites de contrôle',
          permissions: {
            'showAudit': {
              title: 'Voir les visites de contrôle',
              description: 'Permet de voir la liste des visites de contrôle'
            },
            'createAudit': {
              title: 'Créer des visites de contrôle',
              description: 'Permet de créer des visites de contrôle'
            },
            'editAudit': {
              title: 'Modifier les visites de contrôle',
              description: 'Permet de modifier les visites de contrôle'
            },
            'removeAudit': {
              title: 'Supprimer les visites de contrôle',
              description: 'Permet de supprimer les visites de contrôle'
            },
          }
        },
        {
          group_name: 'Gérer les protocoles de sécurité',
          permissions: {
            'showSecurityProtocol': {
              title: 'Voir les protocoles de sécurité',
              description: 'Permet de voir la liste des protocoles de sécurité'
            },
            'createSecurityProtocol': {
              title: 'Créer des protocoles de sécurité',
              description: 'Permet de créer des protocoles de sécurité'
            },
            'editSecurityProtocol': {
              title: 'Modifier les protocoles de sécurité',
              description: 'Permet de modifier les protocoles de sécurité'
            },
            'removeSecurityProtocol': {
              title: 'Supprimer les protocoles de sécurité',
              description: 'Permet de supprimer des protocoles de sécurité'
            }
          }
        },
        {
          group_name: 'Gérer les permis feu',
          permissions: {
            'showFirePermit': {
              title: 'Voir les permis feu',
              description: 'Permet de voir la liste des permis feu'
            },
            'createFirePermit': {
              title: 'Créer des permis feu',
              description: 'Permet de créer des permis feu'
            },
            'editFirePermit': {
              title: 'Modifier les permis feu',
              description: 'Permet de modifier les permis feu'
            },
            'removeFirePermit': {
              title: 'Supprimer les permis feu',
              description: 'Permet de supprimer les permis feu'
            },
          }
        },
        {
          group_name: 'Gérer les permis',
          permissions: {
            'showSignablePermit': {
              title: 'Voir les permis',
              description: 'Permet de voir la liste des permis'
            },
            'createSignablePermit': {
              title: 'Créer des permis',
              description: 'Permet de créer des permis'
            },
            'editSignablePermit': {
              title: 'Modifier les permis',
              description: 'Permet de modifier les permis'
            },
            'removeSignablePermit': {
              title: 'Supprimer les permis',
              description: 'Permet de supprimer les permis'
            },
          }
        },
        {
          group_name: 'Ajouter les protocoles au menu des opérations',
          permissions: {
            'showPdPProtocol': {
              title: 'Voir les protocoles sur les plans de prévention',
              description: 'Permet de faire apparaître les protocoles depuis le menu d\'une opération'
            }
          }
        },
        {
          group_name: 'Gérer les documents rattachés',
          permissions: {
            'showDocumentsPP': {
              title: 'Voir les documents rattachés',
              description: 'Permet de voir les documents rattachés'
            },
            'addDocumentsPP': {
              title: 'Ajouter des documents rattachés',
              description: 'Permet d\'ajouter des documents rattachés'
            },
            'removeDocumentsPP': {
              title: 'Supprimer des documents rattachés',
              description: 'Permet de supprimer des documents rattachés'
            }
          }
        },
        {
          group_name: 'Gérer les notes',
          permissions: {
            'showCommentsPP': {
              title: 'Voir les notes',
              description: 'Permet de voir les notes'
            },
            'createCommentsPP': {
              title: 'Ajouter des notes',
              description: 'Permet d\'ajouter des notes'
            },
            'removeCommentsPP': {
              title: 'Supprimer des notes',
              description: 'Permet de supprimer des notes'
            },


          }
        }
      ]
    },
    // Module Signables
    {
      name: 'signables',
      description: 'Module Utilisateur externe',
      groups: [
        {
          group_name: 'Gérer l\'accessibilité des utilisateurs externes',
          permissions: {
            'showUserWorkbook': {
              title: 'Voir les opérations accessibles',
              description: 'Permet aux utilisateurs externes de voir les opérations auxquelles il a accès'
            },
            'showUserSignables': {
              title: 'Voir les documents accessibles d\'une opération',
              description: 'Permet aux utilisateurs externes de voir les documents d\'une opération à laquelle il a accès'
            },
            'showUserSignable': {
              title: 'Voir un document accessible',
              description: 'Permet aux utilisateurs externes de voir des documents auxquels il a accès'
            }
          }
        },
        {
          group_name: 'Gérer les dépôts des documents',
          permissions: {
            'uploadEExDocument': {
              title: 'Déposer des documents demandés',
              description: 'Permet de déposer des documents demandés'
            },
            //pas encore utilisé => pour déposer des document dans la fiche du repo et non dans celle du workbook
            'uploadRepoEExDocument': {
              title: 'Déposer des documents demandés pour le réferentiel',
              description: 'Permet de déposer des documents demandés pour le référentiel'
            }
          }
        }
      ]
    },
    // Module profile
    {
      name: 'profile',
      description: 'Profil utilisateur',
      groups: [
        {
          group_name: 'Gérer son profil utilisateur',
          permissions: {
            'showProfile': {
              title: 'Voir le profil',
              description: 'Permet de voir son profil utilisateur'
            },
            'editProfile': {
              title: 'Modifier son profil',
              description: 'Permet de modifier son profil utilisateur'
            },
            'editPassword': {
              title: 'Modifier son mot de passe',
              description: 'Permet de réinitialiser son mot de passe'
            }
          }
        }
      ]
    }
  ];

}
