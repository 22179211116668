import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {EntityStep} from 'app/workbook-core';

@Component({
  selector: 'app-step-select',
  templateUrl: './stepSelect.component.html',
  styleUrls: ['./stepSelect.component.scss']
})
export class StepSelectComponent {
  //  workbook: EntityWorkbook;
  templates: EntityStep[];
  allowNone: boolean;
  selectedTemplate: number = -1;
  constructor(
    public dialogRef: MatDialogRef<StepSelectComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      // if (data['workbook']) {
      //   this.workbook = data['workbook'];
      // }
      if (data['templates']) {
        this.templates = data['templates'];
      }
      this.allowNone = !!data['allowNone'];
    }
  }
  onSubmit() {
    this.dialogRef.close((this.selectedTemplate !== -1 && this.templates[this.selectedTemplate]) ? this.templates[this.selectedTemplate] : true);
  }


}
