import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityCategory} from '../models';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends EntitySecureService {
  static LABEL_ONE = "chargement de la catégorie";
  static LABEL_ALL = "chargement des catégories";
  static LABEL_CHILDREN = "chargement des catégories de l'opération ???";
  static LABEL_SAVE = "sauvegarde de la catégorie";
  static LABEL_DELETE = "suppression de la catégorie";

  static ENTITY_TYPE: string = 'repo_category';
  static ENTITY_FIELDS: string[] = ['name', 'picto', 'limited'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showCategory'],
    CREATE: ['createCategory'],
    UPDATE: ['editCategory'],
    DELETE: ['removeCategory']
  }

  static ENTITY_URL = 'repository/category';

  static PICTOS = [
    'assignment',
    'description',
    'settings_applications',
    'settings',
    'photo_camera',
    'videocam',
    'map',
    'location_on',
    'account_tree',
    'assignment',
    'account_box',
    'account_circle',
    'perm_contact_calendar',
    'apartment',
    'local_hospital',
    'menu_book'
  ];
  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityCategory {
    return new EntityCategory(data, clone);
  }

}
