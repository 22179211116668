import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll, LoggerService, EntityEditDialogComponent} from 'app/shared';
import {ActivityService, EntityWorkbook, WorkbookStepService, EntityAnalysable} from 'app/workbook-core';

import {PreventionPlanService, EntityWorkbookMeasure, EntityStep, EntityOffice} from 'app/workbook-core';
import {WorkbookActivityMeasureScopeComponent} from './measureScope.component'
@Component({
  selector: 'app-measure-scope-dialog',
  templateUrl: './measureScope-dialog.component.html',
  styleUrls: ['./measureScope-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookMeasureScopeDialogComponent extends EntityEditDialogComponent {

  @ViewChild('entityForm') entityForm: WorkbookActivityMeasureScopeComponent;

  entity: EntityAnalysable;
  measure_id: string = '';

  protected _companiesSubscription: Subscription;
  companies: EntityOffice[] = [];
  protected _stepsSubscription: Subscription;
  steps: EntityStep[] = [];
  newScope: {[step_id: string]: string[]} = {};
  selectedScope: {[step_id: string]: string[]} = {};
  selectedScopeChecksum: {[step_id: string]: string} = {};
  allStepScope: {[company_id: string]: number} = {};
  scoped: boolean = false;

  activityOEEx: string[];
  stepOEEx: {[step: string]: string[]} = {};
  zoneStep: string = '';

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _workbookService: PreventionPlanService,
    public dialogRef: MatDialogRef<WorkbookMeasureScopeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: ActivityService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data && data['entity']) {
      this.entity = data['entity'];
    }
    if (!this.id && this.entity && this.entity._id) {
      this.id = this.entity._id;
    }
    if (data && data['measure_id'] && this.id && this.parent_id) {
      this.measure_id = data['measure_id'];
      if (data['activityOEEx']) {
        this.activityOEEx = data['activityOEEx'];
      }
      if (data['zoneStep']) {
        this.zoneStep = data['zoneStep'];
      }
      if (data['stepOEEx']) {
        this.stepOEEx = data['stepOEEx'];
      }
      this._companiesSubscription = this._workbookService.getOEexList(this.parent_id).subscribe((list) => {
        if (list) {
          this.companies = list.map((e) => e);
          this.updateAllStepScope();
        }
      });
      this._stepsSubscription = this._workbookService.stepService.getChildren(this.parent_id).subscribe((list) => {
        if (list) {
          const now = new Date();
          this.steps = list
            .filter((step: EntityStep) => (!step.template_id
              && !(this.zoneStep && step._id !== this.zoneStep)
              && (
                (step.activities && step.activities.indexOf(this.id) !== -1)
                //     || (step.zone && step.zone._id === this.id)
                || (step.site && step.site.zones && step.site.zones.findIndex((z) => (z._id === this.id)) !== -1)
                || (step.locations && step.locations.findIndex((s) => (s.zones.findIndex((z) => (z._id === this.id)) !== -1)) !== -1)
              )
            ))
            .sort((a, b) => ((a.template === b.template) ? (new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime() : (b.template ? 1 : -1)))
            .map((s) => s);

          this.selectedScope = {};
          this.steps.forEach((step) => {
            const scope = step.getActivityMeasureScope(this.id, this.measure_id);
            if (scope.length > 0) {
              this.selectedScope[step._id] = scope;
            }
          });
          this.selectedScopeChecksum = this.getChecksum(this.selectedScope);
          this.scoped = Object.keys(this.selectedScope).length > 0;
          this.updateAllStepScope();

        }
      });
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }


  }
  save() {

    if (this.activityOEEx) {
      super.save();
    } else {
      this._afterSave(null);
    }
  }


  protected _afterSave(res) {
    if (this.entityForm && this.entityForm.entity) {

      if (this.entityForm.scope !== WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED) {
        this.selectedScope = {};
      }
      if (this.entityForm.scope === WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED
        || Object.keys(this.selectedScopeChecksum).length) {
        this.newScope = {};
        const saver = this.getModifedSteps().map((step_id) => {
          return new Promise((resolve, reject) => {
            this._workbookService.stepService.get(step_id).toPromise()
              .then((step: EntityStep) => {
                step.setMeasureScope(this.entityForm.entity._id, this.measure_id, this.selectedScope[step_id] ? this.selectedScope[step_id] : []);
                this.newScope[step._id] = (step.scopedMeasures
                  && step.scopedMeasures[this.entityForm.entity._id]
                  && step.scopedMeasures[this.entityForm.entity._id][this.measure_id])
                  ? step.scopedMeasures[this.entityForm.entity._id][this.measure_id]
                  : null;
                resolve(this._workbookService.stepService.save(step));
              }).catch((err) => {reject(err);});
          });
        });
        promiseAll(saver).then((a) => {
          this._spinnerService.hide();
          this.dialogRef.close([this.entityForm.entity, this.newScope])
        });
      } else {
        this._spinnerService.hide();
        this.dialogRef.close([this.entityForm.entity])
      }
    }
  }
  switchScope(event, company_id, step_id) {
    if (event && event.checked) {
      if (!this.selectedScope[step_id]) {
        this.selectedScope[step_id] = [];
      }
      if (this.selectedScope[step_id].indexOf(company_id) === -1) {
        this.selectedScope[step_id].push(company_id);
      }
    } else if (this.selectedScope[step_id]) {
      let i = this.selectedScope[step_id].indexOf(company_id);
      if (i !== -1) {
        this.selectedScope[step_id].splice(i, 1);
      }
      if (this.selectedScope[step_id].length === 0) {
        delete this.selectedScope[step_id];
      }
    }
    this.updateAllStepScope();
  }
  addAllStepScope(event, company_id) {
    this.steps.forEach((s) => {this.switchScope(event, company_id, s._id);});
    this.updateAllStepScope();
  }
  updateAllStepScope() {
    this.allStepScope = {};
    this.companies.forEach((c) => {
      this.allStepScope[c._id] = this.steps
        .filter((s) => (this.selectedScope[s._id] && this.selectedScope[s._id].indexOf(c._id) !== -1))
        .length;
    });
  }
  getChecksum(selection) {
    const checksum: {[step_id: string]: string} = {};
    Object.keys(selection).forEach((step_id) => {
      checksum[step_id] = selection[step_id].sort((a, b) => (a > b ? -1 : 1)).join('/');
    });
    return checksum;
  }
  getModifedSteps(): string[] {
    const stepList: string[] = [];
    const checksum: {[step_id: string]: string} = this.getChecksum(this.selectedScope);
    const presentList = Object.keys(checksum);
    const previousList = Object.keys(this.selectedScopeChecksum);
    previousList.forEach((step_id) => {
      const i = presentList.indexOf(step_id);
      if (i === -1) {
        //removed scope
        stepList.push(step_id);
      } else {
        if (checksum[step_id] !== this.selectedScopeChecksum[step_id]) {
          stepList.push(step_id);
        }
        presentList.splice(i, 1);
      }
    });
    return [...stepList, ...presentList];
  }
}
