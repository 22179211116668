import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ConfigRoutingModule} from './config-routing.module';
import {SharedModule, PermissionsService} from 'app/shared';
import {LogsService, RoleService, ConfigEmailService, ConfigLoggerService, ConfigServerService, ExploitingCompanyService, EmailMessagesService, PinturaConfigService, ExtraConfigService} from 'app/shared/services';
import {ConfigSidenavComponent} from './sidenav';
import {ConfigComponent} from './config.component';
import {ConfigService} from './config.service';
import {LogListComponent} from './logs';
import {PinturaConfigComponent, PinturaConfigEditComponent} from './pinturaConfig';
import {ExtraConfigComponent, ExtraConfigEditComponent} from './extraConfig';
import {RoleListComponent, RoleDialogComponent, RoleComponent} from './role';
import {ConfigEmailComponent, EmailComponent, EmailEditComponent, RgpdEditComponent, ConfigRgpdComponent, ConfigRestrictionEmailComponent, RestrictionEditComponent} from './email';
import {ExploitingCompanyEditComponent, ExploitingCompanyComponent} from './exploitingCompany';
import {ConfigLoggerComponent} from './logger';
import {ConfigServerComponent} from './server';
import {UsersComponent, UserEmailDialogComponent} from './user';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ConfigRoutingModule,
  ],
  declarations: [
    ConfigSidenavComponent,
    ConfigComponent,
    ConfigEmailComponent, EmailComponent, EmailEditComponent, RgpdEditComponent, ConfigRgpdComponent, ConfigRestrictionEmailComponent, RestrictionEditComponent,
    ConfigLoggerComponent,
    ConfigServerComponent,
    ExploitingCompanyEditComponent, ExploitingCompanyComponent,
    LogListComponent, PinturaConfigComponent, PinturaConfigEditComponent, ExtraConfigComponent, ExtraConfigEditComponent,
    RoleListComponent, RoleDialogComponent, RoleComponent,
    UsersComponent, UserEmailDialogComponent
  ],
  entryComponents: [
    RoleDialogComponent, UserEmailDialogComponent
  ],
  providers: [
    PermissionsService,
    ConfigService,
    ConfigEmailService,
    ConfigLoggerService,
    ConfigServerService, ExploitingCompanyService, EmailMessagesService,
    LogsService, PinturaConfigService, ExtraConfigService,
    RoleService
  ]
})
export class ConfigModule {
}
