import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';

import {IUser} from './user.model';
import {AuthService} from './auth.service';

@Injectable()
export class ProfileResolver implements Resolve<IUser> {

  constructor(private auth: AuthService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IUser> | Promise<IUser> | IUser {
    return this.auth.userProfile$.asObservable().pipe(first());
  }
}
