import {Injectable} from '@angular/core';
import {DbLogsService} from 'app/shared/db';
import {EntityService} from 'app/shared/services/entity.service';
import {LoadingService} from 'app/shared/services/loading.service';
import {EntityLog} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class LogService extends EntityService {

  //autoInit = false;
  static ENTITY_TYPE: string = 'log';
  static ENTITY_FIELDS: string[] = ['host', 'user', 'date', 'status', 'errorMsg'];

  static LABEL_ONE = "chargement du log";
  static LABEL_ALL = "chargement des logs";
  static LABEL_CHILDREN = "chargement des logs de l'opération ?";
  static LABEL_SAVE = "sauvegarde du log";
  static LABEL_DELETE = "suppression du log";
  static ENTITY_URL = 'config/log';

  constructor(
    protected _dbService: DbLogsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _loadingService);

  }

  factory(data): EntityLog {
    return new EntityLog(data);
  }

}
