import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService } from 'app/shared';
import { EntitySignablePermit, PermitState, PermitStatus } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SignablePermitService extends EntitySignableService {

  entity: EntitySignablePermit;
  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement du permis";
  static LABEL_ALL = "chargement des permis ?";
  static LABEL_CHILDREN = "chargement des permis de l'opération";
  static LABEL_SAVE = "sauvegarde du permis";
  static LABEL_DELETE = "suppression du permis";

  static ENTITY_TYPE: string = 'spt';
  static ENTITY_FIELDS: string[] = [
    'name',
    'code',
    'editor',
    'editorEmail',
    'editorDate',
    'editedAt',
    'status',
    'description',
    'dateStart',
    'dateEnd',
    'point',
    'site',
    'step',
    'permitContent',
    'contributors',
    'operators',
    'notifications'
  ];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showSignablePermit'],
    CREATE: ['createSignablePermit'],
    UPDATE: ['editSignablePermit'],
    DELETE: ['removeSignablePermit']
  }

  static ENTITY_URL = 'permit';
  static PREFIX = 'PT';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntitySignablePermit {
    return new EntitySignablePermit(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.editorDate).getTime() === new Date(b.editorDate).getTime())
        ? 0
        : (new Date(a.editorDate).getTime() > new Date(b.editorDate).getTime()) ? -1 : 1)));

  }

  getState(entity: EntitySignablePermit, workbook_status) {
    return (workbook_status && workbook_status === 'archived')
      ? PermitState.ARCHIVED
      : PermitState.getState(entity.status, entity.dateStart, entity.dateEnd);
  }
}
