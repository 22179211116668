import {Component} from '@angular/core';
import {ExtraConfigService} from '../../services'
import {ExtraFieldsCommonComponent} from './extraFieldsCommon.component'

@Component({
  selector: 'app-extra-values',
  templateUrl: './extraValues.component.html',
  styleUrls: ['./extraValues.component.scss']
})
export class ExtraValuesComponent extends ExtraFieldsCommonComponent {
  constructor(protected _extraConfigService: ExtraConfigService) {
    super(_extraConfigService);
  }
}