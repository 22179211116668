import {Component, ViewChild, Input, SimpleChanges, OnChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  formattedDate, promiseAll,
  PermissionsService,
  LoggerService,
  EmailService,
  EntityOwnedFormComponent,
  Contact,
  ConfirmDialogComponent,
  EntityEmailMessages,
  EmailDatasModel,
  SignableStatus, EmailMessages, MessageModel
} from 'app/shared';
import {
  IcpService, EntityIcp, ICPState, PreventionPlanService, EntityWorkbook, EntityOffice, EntityStep, OfficeService,
  SignablePreventionPlanService, EntitySignablePreventionPlan, IcpRecurrenceService, EntityIcpRecurrence
} from 'app/workbook-core';
import {WorkbookIcpDialogComponent, LdcmDialogComponent, ReconveneDialogComponent, IcpContactsDialogComponent} from 'app/workbook-shared';
import {WorkbookIcpSignableDialogComponent} from './icpSignable-dialog.component';
import {SignablePreventionPlanDialogComponent, SignablePreventionPlanInfoDialogComponent} from '../signablePreventionPlan';

@Component({
  selector: 'app-workbook-icp',
  templateUrl: './icp.component.html',
  styleUrls: ['./icp.component.scss']
})

export class WorkbookIcpComponent extends EntityOwnedFormComponent implements OnChanges {

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette inspection ?';

  entity: EntityIcp;

  displayedContactColumns: string[] = ['isPresent', 'office', 'name', 'job', 'phone', 'actions', 'delete'];
  contactDataSource: MatTableDataSource<DSItem> = new MatTableDataSource([]);
  @ViewChild(MatSort) contactsSort: MatSort;

  @Input() workbook: EntityWorkbook;



  protected _oeexSubscription: Subscription;
  userEmail: string = '';
  //templates: EntityStep[] = [];
  //steps: EntityStep[] = [];
  selectedSteps: EntityStep[] = [];
  selectedTemplates: EntityStep[] = [];
  protected _stepsSubscription: Subscription;
  loadedSignablePdP: boolean = false;
  lastSignablePdP: EntitySignablePreventionPlan;
  nextSignablePdP: EntitySignablePreventionPlan[] = [];
  protected _signablePdPSubscription: Subscription;
  state: number = 0;
  convocationModel: MessageModel;

  _userContactsOfficesSubscription: Subscription;
  userContacts: Contact[] = [];
  /*
    protected _icpRecurrenceSubscription: Subscription;
    icpRecurrence: EntityIcpRecurrence;
  */
  constructor(
    //    protected _icpRecurrenceService: IcpRecurrenceService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signablePreventionPlanService: SignablePreventionPlanService,
    protected _router: Router,
    protected _workbookService: PreventionPlanService,
    protected _emailService: EmailService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: IcpService

  ) {
    super(dialog, _logger, _entityService);
    /*
        this._icpRecurrenceSubscription = this._icpRecurrenceService.getSingleOne().subscribe((ir: EntityIcpRecurrence) => {
          if (ir) {
            this.icpRecurrence = ir;
          }
        });
        */
    this._emailService.getEmailDatas(EmailMessages.ICP_CONVOCATION).then(emailObj => {
      this.convocationModel = emailObj;
    });

  }

  protected _init(data) {
    super._init(data);
    if (this.entity) {

      this._userContactsOfficesSubscription = this._workbookService.getOfficeList(this.entity.parent_id).subscribe((list) => {
        if (list) {
          this.userContacts = [];
          const userid = this.getUserId();
          list.forEach((o) => {
            if (o.contacts) {
              o.contacts
                .filter((c) => (c.user_id === userid))
                .forEach((c) => {
                  this.userContacts.push(c);
                });
            }
          })
        }
      });
      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._workbookService.stepService.getChildren(this.entity.parent_id).subscribe((list: EntityStep[]) => {
        if (list) {
          this.selectedSteps = list
            .filter((e) => (!e.template && this.entity.steps.indexOf(e._id) !== -1))
            .sort((a, b) => (
              //   (this.workbook && this.workbook.type === 'annual') ?
              //     ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()):
              ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
            ));
          this.selectedTemplates = list
            .filter((e) => (e.template && this.entity.steps.indexOf(e._id) !== -1))
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
        }
      });
      setTimeout(() => {
        this.refreshSelectableContacts();
        this.setContactsSort();
      });
      this.getSignablePdP(this.entity);
      this.refreshState();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['workbook'] && this.workbook) {
      const isModifiable = this.isModifiable() && !!this.workbook._id;
      this.rightC = this.rightC && isModifiable;
      this.rightU = this.rightU && isModifiable;
      this.rightD = this.rightD && isModifiable;
      this.updateUserEmail();
    }
  }
  updateUserEmail() {
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getEmailRecipientList(this.workbook.parent_id).subscribe((res) => {
      const userId = this._entityService.getUserId();
      this.userEmail = this._entityService.getUserEmail();
      let found = false;
      res.forEach((contact) => {
        if (!found && contact.user_id === userId && contact.email) {
          found = true;
          this.userEmail = contact.email;
        }
      });
    });

  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._signablePdPSubscription) {
      this._signablePdPSubscription.unsubscribe();
    }
    if (this._userContactsOfficesSubscription) {
      this._userContactsOfficesSubscription.unsubscribe();
    }
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
  }
  createPdP() {

    this.dialog.open(SignablePreventionPlanInfoDialogComponent, {
      disableClose: true,
      width: '890px',
      maxWidth: '90vw',
      data: {
        parent_id: this.entity && this.entity.parent_id ? this.entity.parent_id : '',
        workbook: this.workbook,
        selectedSteps: [],
        selectedIcps: this.entity && this.entity.parent_id ? [this.entity._id] : []
      }
    });

  }

  openPdP(pdp: EntitySignablePreventionPlan) {
    this.dialog.open(SignablePreventionPlanDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {entity: pdp}
    });
  }

  getSignablePdP(entity) {
    if (this._signablePdPSubscription) {
      this._signablePdPSubscription.unsubscribe();
    }
    this.loadedSignablePdP = false;
    this._signablePdPSubscription = this._signablePreventionPlanService.getChildren(entity.parent_id).subscribe((data) => {
      if (data) {
        this.loadedSignablePdP = true;
        this.nextSignablePdP = [];
        this.lastSignablePdP = null;
        this.nextSignablePdP = data
          .filter((e) => (e.status < SignableStatus.SIGNED && e.icps && e.icps.indexOf(entity._id) !== -1));
        data
          .filter((e) => (e.status > SignableStatus.SIGNING && e.icps && e.icps.indexOf(entity._id) !== -1))
          .forEach((pdp) => {
            if (!this.lastSignablePdP
              || (pdp.status < this.lastSignablePdP.status)
              || (pdp.status && new Date(pdp.editedAt).getTime() > new Date(this.lastSignablePdP.editedAt).getTime())
              || (new Date(pdp.editorDate).getTime() > new Date(this.lastSignablePdP.editorDate).getTime())) {
              this.lastSignablePdP = pdp;
            }
          });
        this.refreshState();
      }
    });
  }
  setContactsSort() {
    if (this.contactsSort) {
      this.contactDataSource.sort = this.contactsSort;
      this.contactDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'office':
            return item['office'];
          default:
            return item['contact'][property];
        }
      }
    }
  }

  refreshSelectableContacts() {
    const contacts: DSItem[] = [];
    if (this.entity && this.entity.iPOEex && this.entity.iPOEex.length) {
      this.entity.iPOEex.forEach((o) => {
        o.contacts.forEach((c) => {
          if (c.name && o.name) {
            contacts.push(new DSItem(c, o.name));
          }
        });
      });
    }
    this.contactDataSource = new MatTableDataSource(contacts);
  }

  public addLdcm(group: string) {
    const dialogRefLcdm = this.dialog.open(LdcmDialogComponent, {
      minWidth: '640px',
      panelClass: 'ldcmDialog',
      data: {
        IPLdcm: this.entity.IPLdcm.map((e) => (Object.assign({}, e))),
        group: group
      }
    });
    dialogRefLcdm.afterClosed().subscribe(ldcm => {
      if (ldcm) {
        this.entity.IPLdcm = ldcm;
        this._save().then((e) => {
          this._afterSave(e);
        });
      }
    });
  }


  public getOEexNameByContact(contact: Contact) {
    const oeex = this.entity.iPOEex.find((o) => {
      if (o.contacts) {
        return o.contacts.some((item) => {
          return item.id === contact.id;
        });
      }
    });
    return (oeex && oeex.name) ? oeex.name : '';
  }
  public sendEmail(contact: Contact): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Voulez-vous renvoyer l\'email de convocation à cet utilisateur ?';
    dialogRef.componentInstance.buttonMessage = 'Renvoyer';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._sendEmail(this.entity, EmailMessages.ICP_CONVOCATION, [contact]);
      }
    });
  }

  /**
   * For each External Entreprise send email for ICP
   * Type 1 : convocation, type 2 : canceled
   * @param {Icp} icp
   * @param {number} type
   * @param contacts
   * @private
   */
  private _sendEmail(icp: EntityIcp, type: number, contacts?: Contact[], showSnackbar: boolean = true): void {
    this._emailService.getEmailDatas(type).then(emailObj => {
      if (contacts && contacts.length > 0) {
        contacts.forEach((contact) => {
          if (contact) {
            if (contact.email) {
              const emailDatas = new EmailDatasModel();
              emailDatas.email = contact.email;
              emailDatas.to_name = contact.name;
              emailDatas.from_name = emailObj.fromName;
              emailDatas.reply_to = this.userEmail;
              if (emailObj.fromEmail) {
                emailDatas.from_email = emailObj.fromEmail;
              }
              emailDatas.date = new Date();
              emailDatas.subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.subject, this.workbook, this.entity);
              emailDatas.text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.text, this.workbook, this.entity);
              if (contact.user_id) {
                emailDatas.usertext = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), emailObj.usertext, this.workbook, this.entity);
              }
              emailDatas.signature = emailObj.signature;
              emailDatas.rgpdMail = emailObj.rgpdMail;
              emailDatas.dpoName = emailObj.dpoName;
              emailDatas.dpoMail = emailObj.dpoMail;
              // 'Cordialement,<br>'
              // + this._entityService.getUserName() + '<br>'
              // + this._emailService.getCompanyName();
              this._emailService.sendEmail(emailDatas);
              if (showSnackbar) {
                this.snackBar.open(type === EmailMessages.ICP_ANNULATION ? 'L\'email d\'annulation a été envoyé' : 'La convocation a été renvoyée', '', {
                  duration: 2000
                });
              }
            }
          }
        });
      }
    }).catch((error) => {
      this._logger.error('IcpComponent', 'error send email', JSON.stringify(error));
    });
  }


  changePresent(contact: Contact) {
    this.entity.iPOEex.forEach((o: EntityOffice) => {
      o.contacts.forEach((c: Contact) => {
        if (c.id === contact.id) {
          c.isPresent = contact.isPresent;
        }
      });
    });
  }

  addContacts() {
    const dialogRef = this.dialog.open(IcpContactsDialogComponent, {
      minWidth: '640px',
      data: {
        icp: this.entity,
        // OEex: OEex
      }
    });
    dialogRef.afterClosed().subscribe((contacts: Contact[]) => {
      if (contacts && contacts.length > 0) {
        this._spinnerService.show();
        contacts.forEach((contact) => {
          // ici on recoit le contact de pp2, on clone (gestion present par icp)
          const addContact = Object.assign(new Contact(), contact);
          const contactOEex: EntityOffice = dialogRef.componentInstance.currentOEex.find((o) => (o.contacts.findIndex((c) => (c.id === contact.id)) > -1));
          const exisingIPOEex: EntityOffice = this.entity.iPOEex.find((o) => (o._id === contactOEex._id));
          if (exisingIPOEex) {
            // si déjà un contact pour cet iPOEex, juste ajouter
            exisingIPOEex.contacts.push(addContact);
          } else {
            // sinon cloner avec uniquement ce contact dans contacts
            const addOffice: EntityOffice = Object.assign(new EntityOffice(), contactOEex);
            addOffice.contacts = [addContact];
            this.entity.iPOEex.push(addOffice);
          }
        });
        // mettre à jour les attributs des offices/eex et trier
        this._workbookService.getUpdatedIPOEex(this.entity.parent_id, this.entity.iPOEex).then((iPOEex) => {
          this.entity.iPOEex = iPOEex;
          this._save();
          this.refreshSelectableContacts();
          this.setContactsSort();
          this._spinnerService.hide();
        }).catch((e) => {
          this._spinnerService.hide();
        });
      }
    });
  }
  reconvene(selectedContact: string = null) {
    if (this.convocationModel && this.workbook && this.entity) {
      const _that = this;
      let subject = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.convocationModel.subject, this.workbook, this.entity);
      let text = EntityEmailMessages.modifyEmailText(this._emailService.getCompanyName(), this.convocationModel.text, this.workbook, this.entity);
      /*
      let from_name = this.convocationModel.fromName;
      let from_names = [this.convocationModel.fromName, this._entityService.getUserName()];
      let reply_email = this.convocationModel.fromEmail;
      let reply_emails = [this.convocationModel.fromEmail, this.userEmail];
      */

      let from_names = [this.convocationModel.fromName, this._entityService.getUserName()];
      let from_name = (this.convocationModel.defaultSenderUser && from_names.length > 1) ? from_names[1] : this.convocationModel.fromName;
      let reply_emails = [this.convocationModel.fromEmail, this._entityService.getUserEmail()];
      let reply_email = (this.convocationModel.defaultReplyUser && reply_emails.length > 1) ? reply_emails[1] : this._emailService.fromEmail;

      this.userContacts.forEach((c) => {
        if (from_names.indexOf(c.name) == -1) {
          from_names.push(c.name);
        }
        if (reply_emails.indexOf(c.email) == -1) {
          reply_emails.push(c.email);
        }
      });

      const dialogRefRecon = this.dialog.open(ReconveneDialogComponent, {
        minWidth: '640px',
        data: {
          selectedContacts: selectedContact ? [selectedContact] : null,
          from_name: from_name,
          from_names: from_names,
          reply_email: reply_email,
          reply_emails: reply_emails,
          subject: subject,
          text: text,
          icp: this.entity,
          // OEex: OEex
        }
      });
      dialogRefRecon.afterClosed().subscribe((result) => {
        if (result) {
          subject = result['subject'];
          text = result['text'];
          from_name = result['from_name'];
          reply_email = result['reply_email'];
          const contacts: Contact[] = result['contacts'];
          if (contacts && contacts.length > 0) {
            _that._spinnerService.show();
            promiseAll(contacts
              .filter((c) => (!!c.email))
              .map((c) => {
                const emailDatas = new EmailDatasModel();
                emailDatas.email = c.email;
                emailDatas.to_name = c.name;
                emailDatas.from_name = from_name;
                emailDatas.reply_to = reply_email;
                if (_that.convocationModel.fromEmail) {
                  emailDatas.from_email = _that.convocationModel.fromEmail;
                }
                emailDatas.date = new Date();
                emailDatas.subject = subject;
                emailDatas.text = text;
                emailDatas.signature = _that.convocationModel.signature;
                emailDatas.rgpdMail = _that.convocationModel.rgpdMail;
                emailDatas.dpoName = _that.convocationModel.dpoName;
                emailDatas.dpoMail = _that.convocationModel.dpoMail;
                return _that._emailService.sendEmail(emailDatas);
              })
            ).then(() => {
              _that.entity.iPOEex.forEach((office) => {
                if (office.contacts && office.contacts.length > 0) {
                  office.contacts.forEach((c) => {
                    if (contacts.findIndex((_c) => (_c.id === c.id)) !== -1) {
                      c.isConvened = true;
                    }
                  });
                }
              });
              _that._save();
              _that.refreshSelectableContacts();
              _that.setContactsSort();
              _that._spinnerService.hide();
              _that.snackBar.open('La convocation a été envoyée', '', {
                duration: 2000
              });
            }).catch((err) => {
              _that._spinnerService.hide();
              _that.snackBar.open('Erreur', 'Le message n\'a pas pu être envoyé', {
                duration: 2000
              });
            });
          }
        }
      });
    }
  }
  removeContact(contact: Contact) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce contact de l\'inspection ?';
    dialogRef.componentInstance.buttonMessage = 'Supprimer';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.entity.iPOEex.forEach((office) => {
          if (office.contacts && office.contacts.length > 0) {
            let removeIndex = office.contacts.findIndex((e) => (e.id === contact.id));
            if (removeIndex !== -1) {
              office.contacts.splice(removeIndex, 1);
            }
          }
        });
        this._workbookService.getUpdatedIPOEex(this.entity.parent_id, this.entity.iPOEex).then((iPOEex) => {
          this.entity.iPOEex = iPOEex;
          this._save();
          this.refreshSelectableContacts();
          this.setContactsSort();

          if (contact.isConvened) {

            const dialogRefMail = this.dialog.open(ConfirmDialogComponent, {
              disableClose: false,
              width: '600px',
            });
            dialogRefMail.componentInstance.confirmMessage = 'Ce contact avait reçu un email de convocation. Voulez-vous envoyer un email d\'annulation à cet utilisateur ?';
            dialogRefMail.componentInstance.buttonMessage = 'Envoyer';

            dialogRefMail.afterClosed().subscribe(result => {
              if (result) {
                this._sendEmail(this.entity, EmailMessages.ICP_ANNULATION, [contact]);
              }
              this.refreshSelectableContacts();
              this.setContactsSort();
            });
          } else {
            this.refreshSelectableContacts();
            this.setContactsSort();

          }
        });
      }
    });
  }
  public isModifiable() {
    return this.workbook && this.workbook.isModifiable() && this._entityService.isInSync(this.workbook._id);
  }
  public _isArchived() {
    return this.workbook && this.workbook.status && this.workbook.status === 'Archivé';
  }
  /**
   * Create dialog popup inspection
   */
  public openDialog() {
    if (this.rightU) {
      // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
      // TODO : if no externalCompanies then popup message not dialog for create inspection
      const dialogRef = this.dialog.open(WorkbookIcpDialogComponent, {
        width: '900px',
        data: {
          id: this.entity._id,
          workbook: this.workbook
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
          if (c._rev !== this.entity._rev) {
            this.entity.copyFrom(c);
          }
          if (this.workbook && this.entity && this.selectedTemplates && this.selectedSteps) {
            this._workbookService.updateWorkbookInspection(
              this.workbook,
              this.entity,
              [...this.selectedTemplates, ...this.selectedSteps]
            );
          }
          this.onSave.emit(this.entity);
        }
      });
    }
  }

  public editStep(step) {
    this._router.navigate(['/' + this._workbookService.getUrlStep(step)], {queryParams: {icp: this.entity._id, date: formattedDate(this.entity.iPDate)}});

  }
  public openSignableIcp() {
    // const dialogRef: MatDialogRef<WorkbookExportDialogComponent> =
    const dialogRef = this.dialog.open(WorkbookIcpSignableDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        entity: this.entity,
        workbook: this.workbook
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      this.refreshState();
    });
  }

  refreshState() {

    this.state = this._entityService.getState(
      this.entity,
      this.workbook ? this.workbook.status : 0,
      this.lastSignablePdP ? this.lastSignablePdP.status : 0
    );
    this.state = (this.workbook && this.workbook.status === 'archived') ? ICPState.ARCHIVED : ICPState.DRAFT;
    if (!this.state && this.entity && this.entity.status) {
      if (this.entity.status === SignableStatus.VALIDATED) {
        this.state = ICPState.PREPARED;
      } else if (this.entity.status === SignableStatus.SIGNING && !this.entity.withoutSignatories) {
        this.state = ICPState.SIGNING;
      } else if (this.entity.status === SignableStatus.OUTDATED) {
        this.state = ICPState.OUTDATED;
      } else {
        if (this.lastSignablePdP && this.lastSignablePdP.status === SignableStatus.SIGNED) {
          this.state = ICPState.VALID;
        } else if (this.lastSignablePdP && this.lastSignablePdP.status === SignableStatus.OUTDATED) {
          this.state = ICPState.OUTDATED;
        } else {
          this.state = this.entity.withoutSignatories ? ICPState.VALIDATED : ICPState.SIGNED;
        }
      }
    }
  }

  getLabelState() {
    return ICPState.getLabel(this.state);

  }
  getDescriptionState() {
    return ICPState.getDescription(this.state);


  }
}

export class DSItem {
  id: string;
  contact: Contact;
  office: string;
  constructor(c: Contact, office: string) {
    this.id = c.id;
    this.contact = c;
    this.office = office;
  }
}
