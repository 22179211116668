import { EntitySignable, formattedDateFile } from 'app/shared';
import { Implementation } from '../../workbook/models/entityStep.model';
import { EntityEmergencyInstructions } from '../../repository/models/entityEmergencyInstructions.model';

export class PDPState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Obsolète',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est signée',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est valide',
    'L\'edition est obsolète, une edition plus récente est valide',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return PDPState.LABELS;
  }
  static getDescriptions() {
    return PDPState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return PDPState.LABELS[status] ? PDPState.LABELS[status] : 'state' + status;
  }
  static getDescription(status: number) {
    return PDPState.DESCRIPTIONS[status] ? PDPState.DESCRIPTIONS[status] : 'state: ' + status;
  }
}
export class EntitySignablePreventionPlan extends EntitySignable {
  static PREFIX: string = 'PP';

  public editor: string;
  public editorEmail: string;
  public editorDate: Date;
  public icps: string[];
  public steps: string[];
  //public startDate: Date;
  //public expectedDate: Date;

  public implementations: { [step_id: string]: Implementation };
  public emergencyInstructions: EntityEmergencyInstructions;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.editor = (data && data.editor) ? data.editor : '';
    this.editorEmail = (data && data.editorEmail) ? data.editorEmail : '';
    this.editorDate = (data && data.editorDate) ? new Date(data.editorDate) : new Date();
    this.icps = (data && data.icps) ? data.icps.map((e) => e) : [];
    this.steps = (data && data.steps) ? data.steps.map((e) => e) : [];
    this.implementations = (data && data.implementations) ? Object.assign({}, data.implementations) : null;
    this.emergencyInstructions = (data && data.emergencyInstructions) ? new EntityEmergencyInstructions(data.emergencyInstructions) : null;
    //this.startDate = (data && data.startDate) ? new Date(data.startDate) : null;
    //this.expectedDate = (data && data.expectedDate) ? new Date(data.expectedDate) : null;
  }

  getTitle() {
    return "Plan de prévention " + this.name;
  }
  getFileName() {
    return this.name + formattedDateFile(this.updatedAt) + (this.isValid() ? ('-' + this.status) : '');
  }
  /*
    isValid() {
      let isvalid = super.isValid();
      if (this.startDate) {
        const now = new Date();
        isvalid = this.startDate.getTime() < now.getTime();
      }
      return isvalid;
    }
    */
}
