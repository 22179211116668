import {Component, Input, Output, OnChanges, OnDestroy, SimpleChanges, EventEmitter, ViewChild} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {PinturaConfigService} from 'app/shared/services';
import {EntityPinturaConfig} from 'app/shared/models';
import {getBase64} from 'app/shared/utils';

import {PinturaEditorComponent} from '@pqina/angular-pintura';
// Import the editor functionality
import {
  PinturaImageState,
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_crop,
  plugin_resize,
  plugin_finetune,
  plugin_annotate,
  plugin_sticker,

  // The user interface and plugin locale objects
  /*
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_resize_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_sticker_locale_en_gb,
*/
  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor and finetune plugins
  markup_editor_defaults,
  plugin_finetune_defaults,
  createMarkupEditorToolStyle, createMarkupEditorToolStyles,
  createMarkupEditorShapeStyleControls, createMarkupEditorToolbar,
  createNode, PinturaNode, LocaleString
} from '@pqina/pintura';

import {default as locale_fr_fr, MarkupEditor as markup_editor_locale_fr_fr} from 'locale/pintura/fr_FR/core/fr_FR';
import plugin_crop_locale_fr_fr from 'locale/pintura/fr_FR/crop/fr_FR';
import plugin_resize_locale_fr_fr from 'locale/pintura/fr_FR/resize/fr_FR';
import plugin_finetune_locale_fr_fr from 'locale/pintura/fr_FR/finetune/fr_FR';
import plugin_annotate_locale_fr_fr from 'locale/pintura/fr_FR/annotate/fr_FR';
import plugin_sticker_locale_fr_fr from 'locale/pintura/fr_FR/sticker/fr_FR';

const pintura_locale_fr = {
  ...markup_editor_locale_en_gb,
  ...locale_fr_fr,
  ...plugin_crop_locale_fr_fr,
  ...plugin_resize_locale_fr_fr,
  ...plugin_finetune_locale_fr_fr,
  ...plugin_annotate_locale_fr_fr,
  ...plugin_sticker_locale_fr_fr,
  ...markup_editor_locale_fr_fr
};
pintura_locale_fr.stickerLabel = 'Image';
pintura_locale_fr.shapeLabelToolSharpie = 'Crayon';
pintura_locale_fr.shapeLabelToolPath = 'Chemin';
pintura_locale_fr.labelButtonExport = 'Valider';
//console.log(pintura_locale_fr);
// This registers the plugins with Pintura Image Editor
setPlugins(plugin_crop, plugin_resize, plugin_finetune, plugin_annotate, plugin_sticker);

//console.log(markup_editor_defaults);

@Component({
  selector: 'app-pintura',
  templateUrl: './pintura.component.html',
  styleUrls: ['./pintura.component.scss']
})
export class AppPinturaComponent implements OnChanges, OnDestroy {
  static MODE_FULL: string = 'full';
  static MODE_IMAGE: string = 'image';
  static MODE_LAYER: string = 'layer';
  static UTIL_ANNOTATE: string = 'annotate';
  static UTIL_STICKER: string = 'sticker';
  static UTIL_CROP: string = 'crop';
  static UTIL_RESIZE: string = 'resize';
  static UTIL_FILTER: string = 'filter';
  static UTIL_FINETUNE: string = 'finetune';
  static UTIL_DECORATE: string = 'decorate';
  static UTIL_FRAME: string = 'frame';
  static UTIL_REDACT: string = 'redact';
  static UTILS_FULL: string[] = [
    AppPinturaComponent.UTIL_ANNOTATE,
    AppPinturaComponent.UTIL_STICKER,
    AppPinturaComponent.UTIL_CROP,
    AppPinturaComponent.UTIL_RESIZE,
    // AppPinturaComponent.UTIL_FILTER,
    AppPinturaComponent.UTIL_FINETUNE,
    // AppPinturaComponent.UTIL_DECORATE,
    // AppPinturaComponent.UTIL_FRAME,
    // AppPinturaComponent.UTIL_REDACT
  ];
  static UTILS_IMAGE: string[] = [
    AppPinturaComponent.UTIL_CROP,
    AppPinturaComponent.UTIL_RESIZE,
    // AppPinturaComponent.UTIL_FILTER,
    AppPinturaComponent.UTIL_FINETUNE,
    // AppPinturaComponent.UTIL_DECORATE,
    // AppPinturaComponent.UTIL_FRAME
  ];
  static UTILS_LAYER: string[] = [
    AppPinturaComponent.UTIL_ANNOTATE,
    AppPinturaComponent.UTIL_STICKER,
    // AppPinturaComponent.UTIL_REDACT
  ]

  @ViewChild('pintura') pintura?: PinturaEditorComponent<any> = undefined;
  @Output() onSave: EventEmitter<string> = new EventEmitter<string>();
  @Output() onStateSave: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onImageSave: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() file: File;
  @Input() dataUrl: string;
  @Input() mode: string = AppPinturaComponent.MODE_FULL;
  src: SafeUrl = '';
  @Input() imageState: string;
  @Input() dataImage: string;
  state: PinturaImageState;
  @Input() stickerEnableSelectImage = false;
  stickers: any = EntityPinturaConfig.STICKERS;

  fileChangeSubscription: Subscription;
  pinturaHideSubscription: Subscription;
  static fileChange: ReplaySubject<string>;
  static pinturaHide: ReplaySubject<boolean>;
  static firstCall: boolean;
  utils: string[] = AppPinturaComponent.UTILS_FULL;
  util: string = AppPinturaComponent.UTIL_ANNOTATE;
  willRenderToolbar = (
    toolbar: PinturaNode[],
    env: any,
    redraw: () => void
  ): PinturaNode[] => {
    const cancelButtonNode = createNode('Button', 'PinturaButtonCancel', {
      label: 'Annuler',
      class: 'PinturaButtonCancel',
      onclick: () => {
        AppPinturaComponent.pinturaHide.next(true);
      },
    });
    if (toolbar.length && toolbar[toolbar.length - 1].length === 4 && toolbar[toolbar.length - 1][3]) {
      toolbar[toolbar.length - 1][3] = [cancelButtonNode, ...toolbar[toolbar.length - 1][3]]
    }
    return toolbar;
  };
  // Our editor configuration
  options: any;

  pinturaConfig: EntityPinturaConfig;
  protected _pinturaConfigSubscription: Subscription;

  constructor(protected sanitizer: DomSanitizer, protected _pinturaConfigService: PinturaConfigService) {
    this._pinturaConfigSubscription = this._pinturaConfigService.getSingleOne().subscribe((data) => {
      if (data) {
        this.pinturaConfig = data;
      }
      this.initPintura();
    });
    AppPinturaComponent.firstCall = true;
    AppPinturaComponent.fileChange = new ReplaySubject();
    //   PinturaComponent.fileChange.next('');
    this.fileChangeSubscription = AppPinturaComponent.fileChange.subscribe((e) => {
      if (e) {
        this.src = e;
        this.onSave.emit(e);
      }
    });
    AppPinturaComponent.pinturaHide = new ReplaySubject();
    this.pinturaHideSubscription = AppPinturaComponent.pinturaHide.subscribe((e) => {
      if (e) {
        AppPinturaComponent.pinturaHide.next(false);
        this.onClose.emit(e);
      }
    });

    this.initPintura();
  }
  initPintura() {
    const markup_editor = markup_editor_defaults;
    markup_editor['markupEditorTextInputMode'] = 'modal';
    markup_editor['enableViewTool'] = true;
    markup_editor['enableMoveTool'] = true;

    const _markupEditorToolStyles = {};
    if (this.pinturaConfig && this.pinturaConfig.markupEditorToolStyles && this.pinturaConfig.markupEditorToolStyles.length) {
      this.pinturaConfig.markupEditorToolStyles.forEach((e) => {
        _markupEditorToolStyles[e.name] = createMarkupEditorToolStyle(e.type, e.shape ? e.shape : {}, e.options ? e.options : null);
      });
    }
    markup_editor['markupEditorToolStyles'] = createMarkupEditorToolStyles(_markupEditorToolStyles);
    /*
    {
      rectangle: createMarkupEditorToolStyle('rectangle', {
        backgroundColor: [0, 0, 0, 0.25],
        strokeColor: [0, 0, 0, 1],
        strokeWidth: "0.5%",
      }),
      zone1: createMarkupEditorToolStyle('rectangle', {
        strokeColor: [0, 0, 0, 1],
        strokeWidth: "0.25%",
        backgroundColor: [0, 1, 0, 0.25],
        backgroundImage: "assets/pictos/h1p.svg",
        backgroundSize: "cover",
        //disableStyle: ['cornerRadius'],
      }),
      zone2: createMarkupEditorToolStyle('rectangle', {
        strokeColor: [0, 0, 0, 1],
        strokeWidth: "0.25%",
        backgroundColor: [1, 0, 0, 0.25],
        backgroundImage: "assets/pictos/h2p.svg",
        backgroundSize: "cover",
        disableStyle: ['cornerRadius'],
      }),
      zone3: createMarkupEditorToolStyle('rectangle', {
        strokeColor: [0, 0, 0, 1],
        strokeWidth: "0.25%",
        backgroundColor: [0, 0, 1, 0.25],
        backgroundImage: "assets/pictos/h3p.svg",
        backgroundSize: "cover",
        //disableStyle: ['cornerRadius'],
      }),
      zone4: createMarkupEditorToolStyle('rectangle', {
        strokeColor: [0, 0, 0, 1],
        strokeWidth: "0.25%",
        backgroundImage: "assets/pictos/h4p.svg",
        backgroundSize: "cover",
        //disableStyle: ['cornerRadius'],
      }),
      */
    /*
          zoneh: createMarkupEditorToolStyle('rectangle', {
            strokeColor: [0, 0, 0, 1],
            strokeWidth: "0.25%",
            backgroundImage: "assets/pictos/hachure.svg",
            //backgroundImage: "data:image/svg+xml,%3Csvg height='200' width='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpattern id='diaHatch' width='1' height='20' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Cline x1='0' x2='100%25' y1='0' y2='0' stroke-width='5' stroke='black' /%3E%3C/pattern%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23diaHatch)'/%3E%3C/svg%3E",
            backgroundSize: "cover",
            disableStyle: ['backgroundColor', 'cornerRadius'],
          }),
    */
    //});
    /*
        markup_editor['markupEditorShapeStyleControls'] = createMarkupEditorShapeStyleControls({
          fontFamilyOptions: [
            //...markup_editor_defaults['markupEditorShapeStyleControls']['fontFamily'][1]['options'],
            ['arial', 'Arial'],
            ['Quicksand', 'sans-serif'],
            ['open-sans', 'Open Sans'],
            ['courier', 'Courier']
            // Define additional custom fonts here
          ],
        });
    */
    if (this.pinturaConfig && this.pinturaConfig.markupEditorToolbar && this.pinturaConfig.markupEditorToolbar.length) {
      markup_editor['markupEditorToolbar'] = createMarkupEditorToolbar(this.pinturaConfig.markupEditorToolbar);
    } else {
      markup_editor['markupEditorToolbar'] = createMarkupEditorToolbar([
        'view',
        'move',
        'text',
        'rectangle',
        'ellipse',
        'line',
        'arrow',
        'path',
        'sharpie'
      ]);
    }
    this.options = {
      imageBackgroundColor: [1, 1, 1],
      imageCropLimitToImage: false,
      stickerStickToImage: true,
      stickerEnableSelectImage: this.stickerEnableSelectImage,
      layoutVerticalToolbarPreference: 'bottom',
      // This will read the image data (required)
      imageReader: createDefaultImageReader(),

      // This will write the output image
      imageWriter: createDefaultImageWriter({
        //format: 'imageData',
        store: (state, options, onprogress) =>
          new Promise((resolve, reject) => {
            getBase64(state.dest)
              .then((b64) => {
                if (this.mode === AppPinturaComponent.MODE_IMAGE && !this.imageState) {
                  //modification du fond sans casser l'image générée ni le state
                  //TODO message car nouveau fond pas visible
                  this.onImageSave.emit(b64.toString());
                } else {
                  const imageStateStr = stringifyImageState(state.imageState);
                  this.state = parseImageState(imageStateStr);
                  this.onStateSave.emit(imageStateStr);
                }
                this.onSave.emit(b64.toString());
                AppPinturaComponent.pinturaHide.next(true);
                resolve(state);
              })
              .catch((err) => {
                reject(err);
              });
          })
      }),

      // The markup editor default options, tools, shape style controls
      ...markup_editor,

      // The finetune util controls
      ...plugin_finetune_defaults,

      // This handles complex shapes like arrows / frames
      shapePreprocessor: createDefaultShapePreprocessor(),

      // This will set a square crop aspect ratio
      //imageCropAspectRatio: 1,

      // The icons and labels to use in the user interface (required)
      locale: pintura_locale_fr,
      layoutVerticalUtilsPreference: 'bottom'
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('ngOnChanges');
    if (changes && changes['file'] && this.file) {
      setTimeout(() => {this.updateFile();});
    }
    if (changes && changes['mode'] && this.mode) {
      if (this.mode === AppPinturaComponent.MODE_IMAGE) {
        this.utils = AppPinturaComponent.UTILS_IMAGE;
        this.util = AppPinturaComponent.UTIL_CROP;
      } else if (this.mode === AppPinturaComponent.MODE_LAYER) {
        this.utils = AppPinturaComponent.UTILS_LAYER;
        this.util = AppPinturaComponent.UTIL_ANNOTATE;
      } else {
        this.utils = AppPinturaComponent.UTILS_FULL;
        this.util = AppPinturaComponent.UTIL_ANNOTATE;
      }
    }
    if (changes && changes['dataUrl'] && this.dataUrl !== this.src) {
      setTimeout(() => {this.paint();});
    }
  }
  updateFile() {
    getBase64(this.file).then((data) => {
      this.dataUrl = data.toString();
      this.paint();
    });
  }
  ngOnDestroy() {
    if (this.fileChangeSubscription) {
      this.fileChangeSubscription.unsubscribe();
      AppPinturaComponent.fileChange = null;
    }
    if (this.pinturaHideSubscription) {
      this.pinturaHideSubscription.unsubscribe();
      AppPinturaComponent.pinturaHide = null;
    }
    if (this._pinturaConfigSubscription) {
      this._pinturaConfigSubscription.unsubscribe();
    }
  }
  paint() {
    if (this.dataUrl) {
      this.src = this.dataImage ? this.dataImage : this.dataUrl;
      this.state = (this.mode === AppPinturaComponent.MODE_IMAGE && !this.imageState) ? null : parseImageState(this.imageState);
    }
  }
}
const stringifyImageState = (imageState: PinturaImageState): string => {
  return JSON.stringify(imageState, (k, v) => (v === undefined ? null : v));
};

const parseImageState = (str: string): PinturaImageState => {
  return str ? JSON.parse(str) : null;
};