import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityLdcm} from '../models';

@Injectable({
  providedIn: 'root'
})
export class LdcmService extends EntitySecureService {
  static LDCM_GROUP: string[] = ['documents', 'locals', 'generalInst', 'equipment'];
  static LDCM_LABELS: {[id: string]: string} = {
    documents: 'Documents',
    generalInst: 'Consignes',
    locals: 'Locaux',
    equipment: 'Matériel'
  };

  static ENTITY_TYPE: string = 'repo_ldcm';
  static ENTITY_FIELDS: string[] = ['name', 'comments', 'group', 'reliefOrg', 'checked'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['editLdcm'],
    CREATE: ['createLdcm'],
    UPDATE: ['editLdcm'],
    DELETE: ['deleteLdcm']
  }

  static ENTITY_URL = 'repository/ldcm';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityLdcm {
    return new EntityLdcm(data, clone);
  }

}
