import { Component } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { comparableString, LoggerService, EntityListComponent } from 'app/shared';
import { LogsService } from 'app/shared/services';
import { EntityLog } from 'app/shared/models';

@Component({
  selector: 'app-config-logs',
  templateUrl: './logList.component.html',
  styleUrls: ['./logList.component.scss']
})

export class LogListComponent extends EntityListComponent {

  entityList: EntityLog[];
  dataSource: MatTableDataSource<EntityLog> = new MatTableDataSource([]);

  displayedColumns = ['date', 'host', 'user', 'status', 'errorMsg']

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce log ?';
  addConfirmMessage = 'Nouveau log créé';

  selection = new SelectionModel<any>(true, []);

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: LogsService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }
  protected _init(data) {
    if (this._entityService.isSync()) {
      super._init(data);
    } else {
      this._entityService.startSync().then(() => {
        super._init(data);
      }).catch((err) => {
        this.log("Error start sync log", err);
      });
    }
  }
  protected _getDataSourceFilterPredicate() {
    return (data, filter: string) => {
      return (!filter || filter.trim() === ''
        || (data.host && comparableString(data.host).indexOf(comparableString(filter)) !== -1)
        || (data.user && comparableString(data.user).indexOf(comparableString(filter)) !== -1)
        || (data.status && comparableString(data.status).indexOf(comparableString(filter)) !== -1)
        || (data.errorMsg && comparableString(data.errorMsg).indexOf(comparableString(filter)) !== -1)
      );
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  removeSelectedRows() {
    this.selection.selected.forEach((item: EntityLog) => {
      this.removeElement(item);
    });
  }


}
