import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService } from 'app/shared';
import { WorkbookExternalCompanyService, EntityExternalCompany, EntityJob } from 'app/workbook-core';
import { WorkbookOfficeDialogComponent } from '../office/office-dialog.component';

@Component({
  selector: 'workbook-externalCompany-dialog',
  templateUrl: './externalCompany-dialog.component.html',
  styleUrls: ['./externalCompany-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookExternalCompanyDialogComponent extends WorkbookOfficeDialogComponent {

  public entity: EntityExternalCompany;
  public officeList: EntityExternalCompany[];
  public selectableOffices: EntityExternalCompany[];
  public jobs: EntityJob[];


  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<WorkbookExternalCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: WorkbookExternalCompanyService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
  }


}
