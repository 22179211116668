import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { EmailService } from '../email';
import { LoggerService } from '../logger';
import { PrintingService } from '../print';
import { DocumentService } from '../document';
import { EmailMessagesService, EntitySecureService } from '../services';
import { EntitySignable, SignableStatus, EntityDocumentMetadata } from '../models';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EntityEditableDialogComponent } from './entityEditableDialog.component';
import { EntitySignableFormComponent } from './entitySignableForm.component';


export abstract class EntitySignableDialogComponent extends EntityEditableDialogComponent {
  entityForm: EntitySignableFormComponent;
  entity: EntitySignable;
  constructor(
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EntityEditableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService) {
    super(snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
  }

  public validate(updateContent: boolean = true, updateSignAgreement: boolean = true): void {
    if (this.entityForm && this.entityForm.entity) {
      if (updateSignAgreement && this.entityForm.sign_agreement) {
        this.entityForm.entity.signAgreement = this.entityForm.sign_agreement;
      }
      super.validate(updateContent);
    }
  }
  public invalidate(): void {
    if (this.entityForm && this.entityForm.entity && (!this.entityForm.entity.signatures || this.entityForm.entity.signatures.length === 0)) {
      this.entityForm.entity.withoutSignatories = false;
      super.invalidate();
    } else {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer toutes les signatures et mettre à jour le document ?';

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this.entityForm.entity.withoutSignatories = false;
          super.invalidate();
          this.resetSignatures();
        } else {
          this._updateEntityFromForm();
        }
      });
    }
  }
  protected resetSignatures() {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.signatures = [];
    }

  }

  setSigned() {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = SignableStatus.SIGNED;
      this.save();
    }
  }
  setSigning() {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = SignableStatus.SIGNING;
      this.save();
    }
  }
  setOutdated() {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.status = SignableStatus.OUTDATED;
      this.save();
    }
  }

}
