import {NgZone} from '@angular/core';
import {first} from 'rxjs/operators';
import {AuthService} from 'app/shared/auth/auth.service';
import {DbLocalSyncService} from './dbLocalSync.service';

export class DbCommonService extends DbLocalSyncService {

  static DBCONFIG = 'couchDB';

  constructor(protected authService: AuthService, protected zone: NgZone) {
    super(zone);
  }
  protected _getDbPrefix() {
    return this.authService.getDbPrefix();
  }
  getDbConfig() {
    return (<typeof DbCommonService> this.constructor).DBCONFIG;
  }

  public load(): Promise<any> {
    return new Promise((resolve, ) => {
      this._init().then(() => {resolve(this)});
    });
  }
  /**
     * Initialize the Db connection with host
     * @protected
     */
  protected async _init() {
    const profile = await this.authService.userProfile$.pipe(first()).toPromise();
    this._syncDB(profile.app_metadata[this.getDbConfig()]);
  }
  pause() {
    if (this.replicator) {
      this.replicator.removeAllListeners();
      this.replicator.cancel()
    }
  }
  unpause() {
    this.reSyncDb();
  }
  /**
   * Used to pouchDB conflict
   */
  public async reSyncDb() {
    this.reSync();
  }

  public async updateLiveSync() {
    const start = new Date();
    const opt = {
      retry: true,
      live: true
    }
    console.log((new Date()).toLocaleTimeString() + ': ' + 'dbCommon updateLiveSync ' + this.dbName, opt);
    if (this.replicator) {
      this.replicator.removeAllListeners();
      this.replicator.cancel();
    }
    if (this.filter) {
      opt['filter'] = this.getFilter();
    }
    if (this.query_params && Object.keys(this.query_params).length) {
      opt['query_params'] = this.query_params;
    } else if (opt['query_params']) {
      delete opt['query_params'];
    }
    this.filterTooLong = false;
    if (opt['query_params'] && opt['query_params']['parents'] && opt['query_params']['parents'].length > DbLocalSyncService.PARENTS_LENGTH) {
      console.log('Trop de parent à synchroniser, suppression du filtre :' + opt['query_params']['parents'].split(',').length + ' parents / ' + opt['query_params']['parents'].length);
      delete opt['query_params']['parents'];
      delete opt['filter'];
      this.filterTooLong = true;
    }
    this._sync(this.__localPouchDb, this.__remotePouchDb, opt);
  }

  public async reSync() {
    const opt = {
      retry: true,
      live: false
    }
    this._sync(this.__localPouchDb, this.__remotePouchDb, opt);
  }
}
