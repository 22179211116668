import { Component, OnInit } from '@angular/core';
import {EmailDatasModel} from 'app/shared/email/';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  emailDatas: EmailDatasModel = new EmailDatasModel();
  typeSubjects: string[] = ['Demande d\'information', 'Problème technique', 'Autre'];

  constructor() { }

  ngOnInit() {
  }

}
