import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {EntitySecureService} from 'app/shared/services/entitySecure.service';
import {LoadingService} from './loading.service';
import {EntityConfigLogger} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ConfigLoggerService extends EntitySecureService {

  //autoInit = false;

  static LABEL_ONE = "!chargement du paramètrage Logger";
  static LABEL_ALL = "!chargement des paramètrages Logger ?";
  static LABEL_CHILDREN = "!chargement des paramètrages Logger de l'opération ?";
  static LABEL_SAVE = "!sauvegarde du paramètrage Logger";
  static LABEL_DELETE = "!suppression du paramètrage Logger";

  static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'config_logger';
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showConfigs', 'showConfigLog'],
    CREATE: ['editConfigs', 'editConfigLog'],
    UPDATE: ['editConfigs', 'editConfigSLog'],
    DELETE: []
  }

  static ENTITY_URL = 'config/logger';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityConfigLogger {
    return new EntityConfigLogger(data, clone);
  }

}
