import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoggerService, EntityFormComponent } from 'app/shared';
import { ActivityService, EntityAnalysable, PreventionPlanService, EntityWorkbookMeasure } from 'app/workbook-core';

@Component({
  selector: 'app-workbook-measure-scope',
  templateUrl: './measureScope.component.html',
  styleUrls: ['./measureScope.component.scss']
})
export class WorkbookActivityMeasureScopeComponent extends EntityFormComponent {
  static SCOPE_ACTIVITY = 0;
  static SCOPE_STEP = 1;
  static SCOPE_SCOPED = 2;

  autoSave = false;
  entity: EntityAnalysable;
  @Input() activityOEEx: string[] = [];
  @Input() stepOEEx: { [step: string]: string[] } = {};
  @Input() simple: boolean = true;
  @Input() measure_id: string;
  @Input() scoped: boolean = false;
  @Output() scopedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  measure: EntityWorkbookMeasure;
  scope: number = 0;
  prior: boolean = false;
  after: boolean = false;

  constructor(
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: ActivityService
  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity.measures && this.measure_id) {
      this.measure = this.entity.measures.find((m) => m._id === this.measure_id);
      this.prior = !!this.measure.prior;
      this.after = !!this.measure.after;
      if (this.scoped) {
        this.scope = WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED;
      } else if (this.measure.global) {
        this.scope = WorkbookActivityMeasureScopeComponent.SCOPE_STEP;
      } else {
        this.scope = WorkbookActivityMeasureScopeComponent.SCOPE_ACTIVITY;
      }
    }
  }
  onPriorChange() {
    this.measure.prior = this.prior;
  }
  onAfterChange() {
    this.measure.after = this.after;
  }
  onScopeChange() {
    this.measure.global = this.scope === WorkbookActivityMeasureScopeComponent.SCOPE_STEP;
    if ((!this.scoped && this.scope === WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED)
      || (this.scoped && this.scope !== WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED)) {
      this.scopedChange.emit((this.scope === WorkbookActivityMeasureScopeComponent.SCOPE_SCOPED));
    }
  }
}
