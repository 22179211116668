import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbLogsService} from 'app/shared/db';
import {EntitySecureService} from 'app/shared/services/entitySecure.service';
import {LoadingService} from './loading.service';
import {EntityLog} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class LogsService extends EntitySecureService {

  autoInit = false;

  static LABEL_ONE = "chargement du log";
  static LABEL_ALL = "chargement des logs";
  static LABEL_CHILDREN = "chargement des logs de l'opération ?";
  static LABEL_SAVE = "sauvegarde du log";
  static LABEL_DELETE = "suppression du log";

  static ENTITY_TYPE: string = 'log';
  static ENTITY_FIELDS: string[] = ['host', 'user', 'date', 'status', 'errorMsg'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showAdmin', 'showLogs'],
    CREATE: ['createLog',],
    UPDATE: ['editLog'],
    DELETE: ['removeLog', 'showLogs']
  }

  static ENTITY_URL = 'config/log';


  constructor(
    protected _dbService: DbLogsService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityLog {
    return new EntityLog(data, clone);
  }
  isSync() {
    return !this._dbService.writeOnly;
  }
  startSync() {
    this._dbService.writeOnly = false;
    return this._dbService.reSyncDb()
      /*
        .then(() => {
      this._dbService.syncComplete.toPromise().then((res) => {
        this.init();
      });
    })
    */
      ;
  }
  stopSync() {
    this._dbService.writeOnly = true;
    return this._dbService.reSyncDb();
  }
}
