import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule, NG_VALIDATORS} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
//import {BrowserModule} from '@angular/platform-browser';
//import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {ColorTwitterModule} from 'ngx-color/twitter'; // <color-twitter></color-twitter>
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {AngularPinturaModule} from '@pqina/angular-pintura';
import {DxDateBoxModule, DxValidatorModule, DxValidationSummaryModule} from 'devextreme-angular';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
/*
const maskConfig: Partial<IConfig> = {
  validation: true,
};
*/
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
//import {MatFileUploadModule} from 'angular-material-fileupload';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SignaturePadModule} from 'angular2-signaturepad';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgxPermissionsModule} from 'ngx-permissions';
import {
  AuthService,
  Auth0Service,
  AuthRoleService,
  AuthGuard,
  ProfileResolver,
  Auth0ConfigService,
  PermissionsService,
  Auth0UsersService
} from './auth';
import {
  //RepositoryResolver,
  DbFilteredService,
  DbConfigService,
  DbLogsService,
  DbDocsService
} from './db';
import {
  AboutDialogComponent,
  CanvasDialogComponent,
  CanvasWhiteboardModule,
  ConfirmDialogComponent,
  RgpdDialogComponent,
  ErrorDialogComponent,
  NavbarComponent,
  PopupDialogComponent,
  SignatureFieldComponent,
  SignatureDialogComponent, AttendanceDialogComponent,
  UploadComponent,
  UploadDialogComponent,
  DocumentInfoComponent, AppPinturaComponent,
  ExtraFieldsComponent, ExtraFieldComponent, ExtraValueComponent, ExtraValuesComponent,
  DocumentDialogComponent,
  DocumentMetadataDialogComponent,
  DocumentsUploadComponent,
  DocumentsRequestComponent,
  ContactDialogComponent,
  ShowScrollComponent,
  Error401Component,
  Error404Component,
  TimerComponent,
  AgreementsDialogComponent,
  BlinkComponent,
  UpgradeComponent,
  DownloadSignableComponent,
  TimeComponent,
  DateComponent
} from './components';
import {ConfigService/*, ConfigResolver*/} from './config';
import {EmailService} from './email';
import {LoggerConfigService, LogService} from './logger';
import {DocumentUploadedService, UploadService} from './upload';
import {DocumentService} from './document';
import {PrintingService, PrintConfigService, SignableService, ExcelService} from './print';
import {DisableControlDirective, DisableArchiveDirective, ScrollDirective, AutosizeDirective, AutocompleteOffDirective, UniqueNameValidatorDirective} from './directives';
import {LicencesService} from './licences';
import {UpgradeService} from './db/upgrade/upgrade.service';
import {
  LoadingService,
  DocumentFileService,
  DocumentMetadataService
} from './services';
import {MarkdownPipe} from './pipes';

@NgModule({
  declarations: [
    AboutDialogComponent,
    NavbarComponent,
    SignatureFieldComponent,
    SignatureDialogComponent, AttendanceDialogComponent,
    ConfirmDialogComponent,
    RgpdDialogComponent,
    UploadComponent,
    UploadDialogComponent,
    DocumentDialogComponent,
    DocumentInfoComponent, AppPinturaComponent,
    ExtraFieldsComponent, ExtraFieldComponent, ExtraValueComponent, ExtraValuesComponent,
    DocumentMetadataDialogComponent,
    DocumentsUploadComponent,
    DocumentsRequestComponent,
    CanvasDialogComponent,
    ErrorDialogComponent,
    PopupDialogComponent,
    DisableControlDirective,
    DisableArchiveDirective,
    ScrollDirective,
    AutosizeDirective,
    AutocompleteOffDirective, UniqueNameValidatorDirective,
    ContactDialogComponent,
    ShowScrollComponent,
    Error401Component,
    Error404Component,
    TimerComponent,
    AgreementsDialogComponent,
    BlinkComponent,
    UpgradeComponent,
    DownloadSignableComponent,
    TimeComponent,
    DateComponent,
    MarkdownPipe
  ],
  exports: [
    NgxPermissionsModule,
    NgxMaskModule,
    FontAwesomeModule,
    NgxDropzoneModule,
    // BrowserModule,
    //  BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // Material export to add them with import shared
    DragDropModule,
    ScrollingModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DxDateBoxModule, DxValidatorModule, DxValidationSummaryModule,
    //    MatFileUploadModule,
    ColorTwitterModule,
    NavbarComponent,
    SignatureFieldComponent,
    UploadComponent,
    DocumentDialogComponent,
    DocumentInfoComponent, AppPinturaComponent,
    ExtraFieldsComponent, ExtraFieldComponent, ExtraValueComponent, ExtraValuesComponent,
    DocumentMetadataDialogComponent,
    DocumentsUploadComponent,
    DocumentsRequestComponent,
    DisableControlDirective,
    DisableArchiveDirective,
    ScrollDirective,
    AutosizeDirective,
    AutocompleteOffDirective, UniqueNameValidatorDirective,
    ShowScrollComponent,
    TimerComponent,
    BlinkComponent,
    AboutDialogComponent,
    UpgradeComponent,
    DownloadSignableComponent,
    TimeComponent,
    DateComponent,
    MarkdownPipe
  ],
  entryComponents: [
    AboutDialogComponent,
    SignatureDialogComponent, AttendanceDialogComponent,
    ConfirmDialogComponent,
    RgpdDialogComponent,
    UploadDialogComponent,
    DocumentDialogComponent,
    DocumentMetadataDialogComponent,
    CanvasDialogComponent,
    ErrorDialogComponent,
    PopupDialogComponent,
    ContactDialogComponent,
    AgreementsDialogComponent,
    DownloadSignableComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DxDateBoxModule, DxValidatorModule, DxValidationSummaryModule,
    //    MatFileUploadModule,
    ColorTwitterModule,
    SignaturePadModule,
    MatProgressBarModule,
    FlexLayoutModule,
    CanvasWhiteboardModule,
    NgxDropzoneModule,
    AngularPinturaModule,
    NgxMaskModule.forRoot(),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    Auth0ConfigService,
    AuthService,
    Auth0Service,
    AuthRoleService,
    ConfigService,
    AuthGuard,
    DbFilteredService, DbConfigService, DbLogsService, DbDocsService,
    DocumentUploadedService,
    ProfileResolver,
    //ConfigResolver, RepositoryResolver,
    UploadService,
    LoadingService,
    DocumentService,
    DocumentFileService,
    DocumentMetadataService,
    EmailService,
    LogService,
    LoggerConfigService,
    PermissionsService,
    Auth0UsersService,
    PrintingService, PrintConfigService, SignableService, ExcelService,
    LicencesService,
    UpgradeService
    /*,
    {
      provide: NG_VALIDATORS,
      useExisting: UniqueNameValidatorDirective,
      multi: true
    }*/
  ],
})
export class SharedModule {}
