import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, EntityListPopupComponent, DocumentService, DocumentMetadataDialogComponent} from 'app/shared';
import {DocumentationService, EntityDocumentation, CategoryService, EntityCategory} from 'app/workbook-core';

@Component({
  selector: 'app-repository-documentation-list',
  templateUrl: './documentationList.component.html',
  styleUrls: ['./documentationList.component.scss']
})

export class DocumentationListComponent extends EntityListPopupComponent implements OnDestroy {

  entityList: EntityDocumentation[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document ?';
  addConfirmMessage = 'Nouveau document créé';
  displayedColumns = ['meta.icon', 'meta.name', 'config', 'delete']

  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  selectedDocument: EntityDocumentation;
  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: DocumentationService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
    this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
      if (list) {
        this.categoryList = list;
      }
    });
    //this.updateDataSource();
  }
  ngOnDestroy() {
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
  }
  /*
  updateDataSource(){
    if (this.entityList) {
      this.dataSource = this._getDataSource();
    }
  }
  */
  openDocumentDialog(document: EntityDocumentation = null) {
    if (this.rightU) {
      if (document) {
        this.selectedDocument = document;
      } else {
        this.selectedDocument = null;
      }
      const dialogRef = this.dialog.open(DocumentMetadataDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        width: '900px',
        maxHeight: '90%',
        data: {
          entity: document ? Object.assign({}, document.meta) : this._documentService.getNewDocumentMetada(),
          categoryList: this.categoryList,
          addOnly: true,
          uploadOnly: true,
          hideDates: true
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          const _entity: EntityDocumentation = (this.selectedDocument)
            ? this.selectedDocument
            : this._entityService.getNewEntity({meta: c});
          _entity.meta = c;
          this._entityService.save(_entity);
        }
      });
    }
  }

}
