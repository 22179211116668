import {Component, OnInit, OnDestroy, Input, ViewEncapsulation} from '@angular/core';
import {Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DOCUMENT} from '@angular/platform-browser';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {PermissionsService, AuthService, Auth0ConfigService} from '../../auth';
import {LoggerService} from '../../logger';
import {EmailDatasModel, EmailService} from '../../email';
import {LoadingService} from '../../services';
import {ContactDialogComponent} from '../contact-dialog';
import {RgpdDialogComponent} from '../rgpd-dialog';
import {PopupDialogComponent} from '../popup-dialog';
import {AboutDialogComponent} from '../about-dialog';
import {checkNewVersion} from '../../utils';
import {CommonComponent} from '../common.component';

@Component({
  selector: 'app-navbar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends CommonComponent implements OnInit, OnDestroy {
  protected _profileSubscription: Subscription;
  public isMobile: boolean;
  protected userEmail: string;
  public currentUser: {given_name, family_name} = {
    given_name: '',
    family_name: ''
  };
  public currentOrganization = '';
  public currentDate: Date;
  dialogRefContact: MatDialogRef<ContactDialogComponent>;
  dialogRefPopup: MatDialogRef<PopupDialogComponent>;
  dialogRefRgpd: MatDialogRef<RgpdDialogComponent>;
  supportEmail: string = 'support@trepied.co';

  @Input()
  public hasError: boolean;
  showLoadingMessage: boolean = true;
  msgList: string[] = [];
  progress = 0;
  maxProgress = 0;
  homelink: string[];

  offline: boolean = false;
  showSyncing: boolean = false;
  syncError: boolean = false;

  constructor(
    @Inject(DOCUMENT) private _document,
    protected _loadingService: LoadingService,
    private _auth: AuthService,
    private _permissionsService: PermissionsService,
    private config: Auth0ConfigService,
    private dialog: MatDialog,
    private email: EmailService,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected http: HttpClient
  ) {
    super(_logger);
    this.homelink = this._permissionsService.hasRole('PP_EEX') ? ['/', 'docs'] : ['/', 'dashboard'];
    this.isMobile = window.innerWidth < 720;

    this._loadingService.loading.subscribe((msgList) => {
      if (msgList) {
        this.msgList = Object.keys(msgList).map((k) => msgList[k]);
        if (this.msgList.length === 0 || this.msgList.length > this.maxProgress) {
          this.maxProgress = this.msgList.length;
        }
        if (this.maxProgress) {
          this.progress = 100 - Math.round(90 * this.msgList.length / this.maxProgress);
        }
      }
    });
    this._loadingService.offline.subscribe((offline: boolean) => {
      this.offline = offline;
    });
    this._loadingService.syncState.subscribe((state: number) => {
      this.showSyncing = (state === 1);
      this.syncError = (state === 3);
    });
  }

  ngOnDestroy() {
    if (this._profileSubscription) {
      this._profileSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this._profileSubscription = this._auth.userProfile$
      .subscribe((user) => {
        this.currentUser = {
          given_name: user.user_metadata && user.user_metadata.given_name ? user.user_metadata.given_name : 'Prénom',
          family_name: user.user_metadata && user.user_metadata.family_name ? user.user_metadata.family_name : 'Nom'
        };
        this.userEmail = user.email;
        this.homelink = this._permissionsService.hasRole('PP_EEX') ? ['/', 'docs'] : ['/', 'dashboard'];

      });

    if (this.config.data && this.config.data.customerName) {
      this.currentOrganization = this.config.data.customerName;
    }
    this.currentDate = new Date();
    setTimeout(() => {
      if (checkNewVersion()) {
        this.clickAbout();
      }
    });
  }
  hasPermission(name: string): boolean {
    return this._permissionsService.hasPermission(name);
  }
  clickContact() {
    this.dialogRefContact = this.dialog.open(ContactDialogComponent, {
      width: '60%'
    });
    this.dialogRefContact.afterClosed().subscribe(result => {
      if (result) {
        const email = result[0];
        const emailDatas = new EmailDatasModel();
        emailDatas.date = new Date();
        emailDatas.subject = email.subject;
        emailDatas.text = email.text;
        emailDatas.type = email.type;
        emailDatas.email = this.supportEmail;
        if (this.userEmail) {
          emailDatas.from_email = this.userEmail;
        }
        emailDatas.from_name = this.currentUser.given_name + ' ' + this.currentUser.family_name;
        emailDatas.to_name = 'Support Trepied.co';
        this.email.sendEmail(emailDatas, 'support').then((response) => {
          this._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
          if (response.status === 200) {
            this._snackBar('La demande au support a été envoyée');
          }
        }, (err) => {
          this._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
          this._snackBar('Erreur d\'envoi au support, contactez l\'administrateur');
        });
      }
    })
  }
  clickRGPD() {
    this.dialogRefRgpd = this.dialog.open(RgpdDialogComponent, {
      minWidth: '60%'
    });
  }
  clickCGU() {
    this.http.get('/assets/cgu.html', {responseType: 'text'}).subscribe(data => {
      this.dialogRefPopup = this.dialog.open(PopupDialogComponent, {
        minWidth: '60%'
      });
      const body = data.match(/<body[^>]*>[\s\S]*<\/body>/gi);
      this.dialogRefPopup.componentInstance.dataContainer.nativeElement.innerHTML = body;
      this.dialogRefPopup.componentInstance.popupTitle = 'Conditions Générales d\'Utilisation';
    });
  }

  clickAbout() {
    this.dialogRefPopup = this.dialog.open(AboutDialogComponent, {
      minWidth: '30%'
    });


  }

  clickSupport() {
    if (this._document.body) {
      //this._document.body.className = "show-jsd-widget";

      if (this._document.body.childNodes) {
        const iframe = Array.from(this._document.body.childNodes).find(node => {
          return node['id'] === "jsd-widget"
        });
        if (iframe && iframe['contentDocument']) {
          const iframeDocument = iframe['contentDocument'];
          const helpButton = iframeDocument.getElementById('help-button');
          if (helpButton) {
            helpButton.click();
          }
        }
      }
    }

  }

  private _snackBar(message: string): void {
    this.snackBar.open(message, '', {
      duration: 2000
    });
  }

  logout() {
    this._auth.logout();
  }
}
