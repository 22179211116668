import { Component, Input } from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import { EntityExternalCompany } from '../../repository/models/entityExternalCompany.model';
import { EntityActivity } from '../models/entityActivity.model';


@Component({
  selector: 'app-workbook-externalCompany-export',
  templateUrl: './externalCompanyExport.component.html'
})
export class ExternalCompanyExportComponent extends CommonComponent {

  @Input() externalCompany: EntityExternalCompany;
  @Input() sir: { [oeex_id: string]: EntityActivity[] } = {};
  @Input() activityStepList: string[] = [];
  @Input() riskSituationName: { [situation_id: string]: string } = {};
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }
  isSIR(company_id) {
    return this.sir && this.sir[company_id] && this.activityStepList && this.activityStepList.length
      && this.sir[company_id].filter((e) => (this.activityStepList.indexOf(e._id) !== -1)).length !== 0;
  }
}
