import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {LoggerService, EntitySimpleListComponent, ConfirmDialogComponent} from 'app/shared';
import {OrganizationService, EntityOrganization} from 'app/workbook-core';

@Component({
  selector: 'app-repository-organization-list',
  templateUrl: './organizationList.component.html',
  styleUrls: ['./organizationList.component.scss']
})

export class OrganizationListComponent extends EntitySimpleListComponent {

  entityList: EntityOrganization[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce type de structure ?';
  addConfirmMessage = 'Nouveau type de structure créé';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: OrganizationService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }


}
