import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { promiseAll, PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntityActivity } from '../models';
import { CompanyActivity } from '../../entityAnalysable.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService extends EntitySecureService {

  autoInit = false;
  onlyChildren = true;
  static LABEL_ONE = "chargement de l'activité";
  static LABEL_ALL = "chargement des activités";
  static LABEL_CHILDREN = "chargement des activités de l'opération";
  static LABEL_SAVE = "sauvegarde de l'activité";
  static LABEL_DELETE = "suppression de l'activité";

  static ENTITY_TYPE: string = 'activity';
  static ENTITY_FIELDS: string[] = ['name', 'group', 'dateStart', 'dateEnd', 'hourStart', 'hourEnd', 'companies', 'risks_situations', 'risks', 'situations', 'measures', 'smr', 'firePermit', 'confinedSpace'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP', 'showActivityPP'],
    CREATE: ['createActivityPP',],
    UPDATE: ['editActivityPP'],
    DELETE: ['removeActivityPP']
  }

  static ENTITY_URL = 'activity';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityActivity {
    return new EntityActivity(data, clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.dateStart).getTime() === new Date(b.dateStart).getTime())
        ? (a.hourStart ? a.hourStart.localeCompare(b.hourStart) : -1)
        : (new Date(a.dateStart).getTime() > new Date(b.dateStart).getTime()) ? 1 : -1)));
  }
  _updateCompanyName(activity_id: string, companies: CompanyActivity[]) {
    return this.get(activity_id).toPromise().then((e: EntityActivity) => {
      e.companies = companies;
      this.save(e);
    });
  }
  updateCompanyName(parent_id: string, company_id: string, name: string) {
    super.getCurrentChildren(parent_id).then((list: EntityActivity[]) => {
      if (list) {
        const toSave = [];
        list.forEach((e) => {
          if (e.companies && e.companies.length) {
            let modified = false
            e.companies.map((c) => {
              if (c.id === company_id && c.name !== name) {
                c.name = name;
                modified = true;
              }
              return c;
            });
            if (modified) {
              if (!toSave[e._id]) {
                toSave[e._id] = [];
              }
              toSave.push(this._updateCompanyName(e._id, e.companies));
            }

          }
        });
        if (Object.keys(toSave).length) {
          promiseAll(toSave).then((res) => {
            //console.log('updateCompanyName', res);
          });
        }
      }
    });
  }
}
