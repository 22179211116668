import {Entity} from './entity.model';

export class EntityComment extends Entity {
  public context: string;
  public text: string;
  public date: Date;
  public created_by: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.context = (data && data.context) ? data.context : '';
    this.text = (data && data.text) ? data.text : '';
    this.date = (data && data.date) ? data.date : new Date();
    this.created_by = (data && data.created_by) ? data.created_by : '';
  }
}
