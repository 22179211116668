import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { Entity } from '../models';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EntityEditChildListComponent } from './entityEditChildList.component';

export class EntityChildListComponent extends EntityEditChildListComponent implements OnInit {

  entity: Entity;
  protected _entitySubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (this.id) {
      if (this._entitySubscription) {
        this._entitySubscription.unsubscribe();
      }
      this._entitySubscription = this._entityService.getOne(this.id).subscribe((data) => {
        if (data) {
          //this._logger.error(this.constructor.name + '(EntityChildListComponent)', '_entityService.getOne', JSON.stringify(data));
          this._entityUpdate(data);
        }
      });
    }
  }

  onSyncStateChange(res) {
    if (this.entity && this.entity._id) {
      //console.log('EntityChildListComponent onSyncStateChange ' + this.entity._id, res);
      this._updatePermissions(this.entity._id);
    }
  }

  protected _entityUpdate(data) {
    // console.log('EntityChildListComponent._entityUpdate', data);
    this.entity = this._entityService.factory(data);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._entitySubscription) {
      this._entitySubscription.unsubscribe();
    }
  }
  protected _save() {
    return this._entityService.save(this.entity).catch((error) => {
      //this._logger.error('EntityChildListComponent', 'unable to save entity', error);
      this._logger.error(this.constructor.name + '(EntityChildListComponent)', 'unable to save entity', JSON.stringify(error));
      //console.log('EntityChildListComponent save error', [error, this.entity]);
      if (this.entity._id) {
        this._entityService.refreshOne(this.entity._id).then((e) => {
          // TODO : copyfrom ? diff ?
          this.entity = e;
        }).catch((err) => {
          //this._logger.error('EntityChildListComponent', 'unable to refreshOne after error', err);
          this._logger.error(this.constructor.name + '(EntityChildListComponent)', 'unable to refreshOne after error', JSON.stringify(err));
          //console.log('EntityChildListComponent refreshOne error', [err, this.entity]);
        });
      }
    });
  }

  removeElement(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().remove(entity).then((res) => {
          if (res['ok']) {
            entity._deleted = true;
            entity._rev = res['rev'];
            this._afterRemoveElement(entity);
          } else {
            //error message ?
          }
        }).catch((err) => {
          //error message ?
        });
      }
    });
  }
}
