import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {AuthGuard, PermissionsService} from 'app/shared';
import {PreventionPlanService} from './preventionPlan';

@Injectable()
export class WorkbookGuard extends AuthGuard {

  constructor(
    protected _entityService: PreventionPlanService,
    protected permissions: PermissionsService,
    permissionsService: NgxPermissionsService,
    rolesService: NgxRolesService,
    router: Router) {
    super(permissions, permissionsService, rolesService, router);
  }

  isAuthorizeTo(params: {[key: string]: any;}): boolean {
    return this.permissions.isAuthenticated() && this._entityService.isAuthorized(params['id'], this.permissions.getUserId());
  }
}
