import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import {promiseAll, PermissionsService, LoggerService, IUser, DocumentService} from 'app/shared';
import {OfficeService, EntityOffice, OrganizationService, EntityOrganization, CategoryService} from 'app/workbook-core';
import {AbstractOfficeComponent} from '../abstractOffice.component';

@Component({
  selector: 'app-repository-officeUsers',
  templateUrl: './officeUsers.component.html',
  styleUrls: ['../office.component.scss']
})

export class OfficeUsersComponent extends AbstractOfficeComponent {

  organizationListSubscriber: Subscription;
  organizationList: EntityOrganization[] = [];
  mainOfficeListSubscriber: Subscription;
  mainOfficeList: EntityOffice[] = [];
  nameOffices: {[id: string]: string} = {};
  userFilter: {[id: string]: string[]} = {};
  parentOffices: {[id: string]: string} = {};
  hierarchyOffices: {[id: string]: string[]} = {};
  childrenOffices: {[id: string]: string[]} = {};
  userCheck: {[id: string]: boolean} = {};
  userOffice: {[id: string]: boolean} = {};
  userContactOffice: {[id: string]: boolean} = {};
  userUncheck: boolean = false;
  constructor(
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: OfficeService,
    public organizationService: OrganizationService

  ) {
    super(_categoryService, _documentService, dialog, snackBar, _permissionService, _logger, _entityService);
    this.organizationListSubscriber = this.organizationService.getAll().subscribe((organizationList) => {
      if (organizationList) {
        this.organizationList = organizationList.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
    this.mainOfficeListSubscriber = this._entityService.getAll().subscribe((mainOfficeList) => {
      if (mainOfficeList) {
        this.nameOffices = {};
        this.parentOffices = {};
        this.hierarchyOffices = {};
        this.mainOfficeList = mainOfficeList.sort((a, b) => a.name.localeCompare(b.name));
        this.mainOfficeList.forEach((o) => {
          this.nameOffices[o._id] = o.name;
          if (o.parent_id) {
            this.parentOffices[o._id] = o.parent_id;
            if (!this.childrenOffices[o.parent_id]) {
              this.childrenOffices[o.parent_id] = [];
            }
            this.childrenOffices[o.parent_id].push(o._id);
          }
        });
        this.userContactOffice = {};
        this.mainOfficeList.forEach((o) => {
          this.addHierarchy(o._id);
        });
      }
    });
  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity.contacts && this.entity.contacts.length) {
      this.entity.contacts.forEach((c) => {
        if (c.user_id) {
          this.userContactOffice[c.user_id] = true;
        }
      });
    }
  }
  addHierarchy(id: string) {
    if (!this.hierarchyOffices[id]) {
      this.hierarchyOffices[id] = [id];
    }
    let _id = id;
    while (this.parentOffices[_id]) {
      _id = this.parentOffices[_id];
      this.hierarchyOffices[id].push(_id);
    }
  }

  usersLoaded() {
    this.userCheck = {};
    this.userOffice = {};
    this.userFilter = {};
    this.usersList.forEach((u) => {
      this.userOffice[u.user_id] = (
        !!u.user_metadata
        && !!u.user_metadata.syncFilter
        && u.user_metadata.syncFilter.length > 0
        && u.user_metadata.syncFilter.indexOf(this.id) !== -1
      );
      this.userCheck[u.user_id] = this.userOffice[u.user_id];
      this.userFilter[u.user_id] = (u.user_metadata
        && u.user_metadata.syncFilter) ? u.user_metadata.syncFilter : [];
    });
  }

  ngOnDestroy() {
    if (this.organizationListSubscriber) {
      this.organizationListSubscriber.unsubscribe();
    }
    if (this.mainOfficeListSubscriber) {
      this.mainOfficeListSubscriber.unsubscribe();
    }
  }
  saveUsers(): Promise<any> {
    const saver: Promise<any>[] = [];
    Object.keys(this.userCheck).forEach((id) => {
      if (this.userCheck[id] !== this.userOffice[id] && this.hierarchyOffices[this.id]) {
        const _user: IUser = this.usersList.find((u) => (u.user_id === id));
        if (_user && _user.user_metadata) {
          if (!_user.user_metadata.syncFilter) {
            _user.user_metadata.syncFilter = [];
          }
          if (this.userCheck[id]) {
            if (!_user.user_metadata.syncFilter) {
              _user.user_metadata.syncFilter = [];
            }
            if (_user.user_metadata.syncFilter.indexOf(this.id) === -1) {
              _user.user_metadata.syncFilter.push(this.id);
            }
            /*
              if (_user.user_metadata.syncFilter.indexOf(this.id) === -1) {
                this.hierarchyOffices[this.id].forEach((o_id)=>{
                  if (_user.user_metadata.syncFilter.indexOf(o_id)===-1){
                    _user.user_metadata.syncFilter.push(o_id);
                  }
                });
                */
            saver.push(this._permissionService.updateUserMetadata(_user));
          } else {
            const x = _user.user_metadata.syncFilter.indexOf(this.id);
            if (x !== -1) {
              _user.user_metadata.syncFilter.splice(x, 1);
            }
            /*
         const hierarchy=this.childrenOffices[this.id]?this.childrenOffices[this.id]:[];
              hierarchy.push(this.id);
              hierarchy.forEach((h_id)=>{
               const x = _user.user_metadata.syncFilter.indexOf(h_id);
              if (x !== -1) {
                _user.user_metadata.syncFilter.splice(x, 1);
      }
              });
              */
            saver.push(this._permissionService.updateUserMetadata(_user));
          }
        }
      }

    });
    return promiseAll(saver).then((e) => {
      this.refreshUserList();
    });
  }
  getOfficeNames(user_id) {
    return this.userFilter[user_id].map((o_id) => (this.nameOffices[o_id])).join('\n');
  }
  unselectAll() {
    Object.keys(this.userCheck).forEach((e) => {
      this.userCheck[e] = false;
    });
    setTimeout(() => {this.userUncheck = false;})
  }
}
