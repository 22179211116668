import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import {
  EntityActivity
} from '../models/entityActivity.model';
import {
  RiskEntry, EntityWorkbookMeasure
} from '../models/entityMeasure.model';
import {
  EntityStep
} from '../models/entityStep.model';
import { Implementation, Activity } from '../models/entityStep.model';
import { EntitySite } from '../../repository/models/entitySite.model'
import { EntityZone } from '../../repository/models/entityZone.model'
@Component({
  selector: 'app-implementation-export',
  templateUrl: './implementationExport.component.html'
})
export class ImplementationExportComponent extends CommonComponent implements OnChanges {

  @Input() activityFilter: string[] = null;
  @Input() locationFilter: string[] = null;
  @Input() implementation: Implementation;
  @Input() step: EntityStep;

  risks: { [activity_id: string]: { [risk_name: string]: RiskEntry } } = {};
  withPrior: { [activity_id: string]: boolean } = {};
  withInProgress: { [activity_id: string]: boolean } = {};
  withAfter: { [activity_id: string]: boolean } = {};

  locations: EntitySite[] = [];
  withEndemic: boolean = false;

  measuresCompanies: { [measure_id: string]: string[] } = {};
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['implementation']
      && this.implementation
      && this.implementation.step
      && this.implementation.step.activities
      && this.implementation.activities
      && this.implementation.risks) {
      this.withPrior = {};
      this.withAfter = {};
      this.implementation.step.activities.forEach((activity_id) => {
        if (this.implementation.risks[activity_id]) {
          this.withPrior[activity_id] = (this.withPrior[activity_id]
            || Object.keys(this.implementation.risks[activity_id]).findIndex((risk_name) => (this.implementation.risks[activity_id][risk_name].withPrior)) !== -1);
          this.withInProgress[activity_id] = (this.withInProgress[activity_id]
            || Object.keys(this.implementation.risks[activity_id]).findIndex((risk_name) => (this.implementation.risks[activity_id][risk_name].measures.findIndex((e) => (!e.prior && !e.after)) !== -1)) !== -1);
          this.withAfter[activity_id] = (this.withAfter[activity_id]
            || Object.keys(this.implementation.risks[activity_id]).findIndex((risk_name) => (this.implementation.risks[activity_id][risk_name].withAfter)) !== -1);
        }
      });
      this.updateLocationRisk(this.implementation.step);
    }
  }
  showActivity(id: string) {
    return !this.activityFilter || this.activityFilter.indexOf(id) !== -1;
  }
  showLocation(id: string) {
    return !this.locationFilter || this.locationFilter.indexOf(id) !== -1;
  }
  getStepLocations(): EntitySite[] {
    let returned: EntitySite[] = [];
    const step = (this.implementation && this.implementation.step) ? this.implementation.step : this.step;
    if (step) {
      const locations = step.getLocationsAnalysis();
      if (step && step.template_id) {
        const s = new EntitySite(step.site);
        const x = locations.findIndex((e) => (e._id === step.site._id));
        if (x !== -1) {
          s.zones = locations[x].zones.filter((z) => (z.measures.length && (z.name === '' || z._id === step.zone._id)));
        }
        if (s.zones.length) {
          returned = [s];
        }
      } else {
        returned = step.getLocationsAnalysis();
      }
    }
    return returned.filter((s) => (s.zones.length));
  }
  updateLocationRisk(step: EntityStep) {
    if (step) {
      const locations = step.getLocations();
      //this.locations_riskSituationName = {};
      locations.forEach((site: EntitySite) => {
        site.zones.forEach((zone: EntityZone) => {
          if (zone.measures) {
            const entry: { [risk_situation_id: string]: RiskEntry } = {};
            zone.risks_situations.forEach((risk_situation_id) => {
              const situation = zone.situations.find((s) => (s._id === risk_situation_id));
              const risk = zone.risks.find((s) => (s._id === (situation ? situation.risk_id : risk_situation_id)));
              if (risk) {
                //this.riskSituationName[situation ? situation._id : risk._id] = (risk.name + (situation ? ' - ' + situation.name : ''));
                entry[risk_situation_id] = new RiskEntry(
                  (risk.name + (situation ? ' - ' + situation.name : '')),
                  zone.measures.filter((measure) => (
                    measure.risk_id === risk._id
                    && (situation
                      ? measure.type === situation._id
                      : (measure.type === '' || measure.type === 'local' || measure.type === 'global')
                    )
                  )),
                  (situation && situation.sir),
                  (situation && situation.fp)
                );
                this.withPrior[zone._id] = this.withPrior[zone._id] || entry[risk_situation_id].withPrior;
                this.withInProgress[zone._id] = zone.measures.findIndex((e) => (!e.prior && !e.after)) !== -1;
                this.withAfter[zone._id] = this.withAfter[zone._id] || entry[risk_situation_id].withAfter;
              }
            });
            this.risks[zone._id] = entry;
          }
        });
      });
      this.locations = this.getStepLocations();
      this.withEndemic = this.locations.filter((s) => (
        s.zones.filter((z) => (
          z.measures.length > 0
        )).length > 0
      )).length > 0;
    }
  }
  getMeasureCompanies(measure: EntityWorkbookMeasure, activity: Activity) {
    if (measure.global) {
      const returned = [];
      this.implementation.step.activities.forEach((activity_id) => {
        if (this.implementation.activities[activity_id]) {
          this.implementation.activities[activity_id].companies.forEach((ca) => {
            if (this.implementation.companies[ca] && this.implementation.companies[ca].name && returned.indexOf(this.implementation.companies[ca].name) === -1) {
              returned.push(this.implementation.companies[ca].name);
            }
          });
        }
      });
      return returned;
    } else if (this.implementation.step.scopedMeasures && this.implementation.step.scopedMeasures[activity._id] && this.implementation.step.scopedMeasures[activity._id][measure._id]) {
      let test = this.implementation.step.scopedMeasures[activity._id][measure._id]
        .map((company_id) => (this.implementation.companies[company_id]));
      return this.implementation.step.scopedMeasures[activity._id][measure._id]
        .map((company_id) => (this.implementation.companies[company_id]))
        .filter((e) => !!e).map((e) => e.name);
    } else {
      return activity.companies.map((e) => this.implementation.companies[e].name);
    }
  }
  getLocationMeasureCompanies(measure: EntityWorkbookMeasure, zone: EntityZone) {
    if (measure.global) {
      const returned = [];
      this.implementation.step.activities.forEach((activity_id) => {
        const a = this.implementation.activities[activity_id];
        if (a) {
          a.companies.forEach((ca) => {
            const ca_name = this.implementation.companies[ca].name;
            if (returned.indexOf(ca_name) === -1) {
              returned.push(ca_name);
            }
          });
        }
      });
      return returned;
    } else if (this.implementation.step.scopedMeasures && this.implementation.step.scopedMeasures[zone._id] && this.implementation.step.scopedMeasures[zone._id][measure._id]) {
      let test = this.implementation.step.scopedMeasures[zone._id][measure._id]
        .map((company_id) => (this.implementation.companies[company_id]));
      return this.implementation.step.scopedMeasures[zone._id][measure._id]
        .map((company_id) => (this.implementation.companies[company_id]))
        .filter((e) => !!e).map((e) => e.name);
    } else {
      return zone.companies.map((e) => e.name);
    }
  }

  hasPrior(list: any[]) {
    return list.findIndex((e) => (e['prior'])) !== -1
  }
  hasDuring(list: any[]) {
    return list.findIndex((e) => (!e['prior'] && !e['after'])) !== -1
  }
  hasAfter(list: any[]) {
    return list.findIndex((e) => (e['after'])) !== -1
  }
}
