import {Component, Input, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, CdkDropList} from '@angular/cdk/drag-drop';
import {MatDialog, MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {LoggerService, ConfirmDialogComponent, comparableString} from 'app/shared';
import {AbstractWorkbookChildFormComponent, ZoneDialogComponent} from 'app/workbook-shared/';
import {
  WorkbookStepService, SiteService,
  EntityStep, EntitySite, EntityZone,
  PreventionPlanService
} from 'app/workbook-core';
import {SignablePreventionPlanDialogComponent} from '../signablePreventionPlan/signablePreventionPlan-dialog.component';

@Component({
  selector: 'app-prevention-plan-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})

export class PreventionPlanLocationComponent extends AbstractWorkbookChildFormComponent {

  entity: EntityStep;
  @Input() site_id: string;
  site: EntitySite;
  defaultLocationSite: boolean;

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette zone ?';

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];
  isLoading: boolean = false;
  siteSelection: boolean = false;

  protected _zoneChangeSubscription: Subscription;
  zoneList: {[site_id: string]: EntityZone[]}

  zonesDataSource: MatTableDataSource<EntityZone> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  zonesDisplayedColumns = ['rank', 'name', 'actions']

  constructor(
    protected _siteService: SiteService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredSites = this.getFilteredSites((this.entity && this.entity.site && this.entity.site.name) ? this.entity.site.name : '');
        this.zoneList = {};
        this.siteList.forEach((s) => {
          this.zoneList[s._id] = s.zones.sort((a, b) => a.name.localeCompare(b.name));
        });
      }
    });
  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.site_id) {
      this.site = this.entity.getLocations().find((s) => (s._id === this.site_id));
      this.defaultLocationSite = (this.site_id === this.entity.site._id);
      this.refreshDataSource();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {this._initSearchSite();});

  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._zoneChangeSubscription) {
      this._zoneChangeSubscription.unsubscribe();
    }
  }


  protected _beforeSave() {
    if (this.entity && this.entity.locations) {
      //console.log('beforeSave updateLocation');
      this.entity.updateLocation(this.site);
    }
    return this.entity;
  }
  protected _initSearchSite() {
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this.filteredSites = this.getFilteredSites((this.site && this.site.name) ? this.site.name : '');
      if (this.filteredSites && this.filteredSites.length > 200) {
        this.filteredSites = [];
      }
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(100)
          //   ,tap(() => this.isLoading = true)
          //  ,finalize(() => this.isLoading = false)
        )
        .subscribe(value => {
          if (this.siteSelection) {
            this.siteSelection = false;
          } else {
            if (this.site && this.site.repo_id) {
              const previousZones = this.site.zones.filter((e) => (!e.repo_id));
              this.site = new EntitySite(this.site);//, (this.entity && this.entity.site && this.entity.site._id === this.site._id)
              this.site.repo_id = '';
              this.site.zones = previousZones;
              if (!this.site.zones.length) {
                const stepZone = new EntityZone();
                this.site.zones.push(stepZone);
                //this.entity.updateLocation(this.site);
                this._save();
                this.refreshDataSource();
              }
            }
            this.site_id = this.site._id;
            this.filteredSites = this.getFilteredSites(value);
          }
        });
    }

  }
  /**
   * auto fills inputs for site data
   * @param site
   */
  public siteSelected(event, site: EntitySite) {
    //console.log('siteSelected', site);
    if (event && event.isUserInput && site) {
      const changeRepoSite = site._id !== this.site.repo_id;
      const repo_id = site._id;
      const keepLocalZones = this.site.zones.filter((e) => (!e.repo_id));
      if (this.entity && this.entity.site && (this.entity.site.repo_id === site._id || this.entity.site._id === this.site._id)) {
        //site par defaut
        this.site = new EntitySite(this.entity.site);
        this.site.repo_id = repo_id;
        //this.site.zones = [new EntityZone(this.entity.zone)];
      } else {
        const i = this.entity.locations.findIndex((e) => (e.repo_id === site._id));
        if (i !== -1) {
          //site existant
          this.site = this.entity.locations[i];
        } else {
          //site existant ajouté
          this.site = new EntitySite(site);
          //conserver l'id pour eviter de créer un nouveau
          this.site._id = this.site_id;
          this.site.repo_id = repo_id;
          this.site.zones = keepLocalZones;
        }
      }
      if (!this.site.zones.length) {
        //TODO prendre le toute zone du site du repo ?
        this.site.zones.push(new EntityZone());
      }
      //this.site_id = site._id;
      //this.entity.updateLocation(this.site);
      this.siteSelection = true;
      this._save();
      this.refreshDataSource();
    }
  }
  /*
  public zoneSelected(index: number, zone: EntityZone) {
    //console.log('zoneSelected', [event, zone]);
    if (this.site && this.site.zones && this.site.zones[index] && zone) {
      const repo_id = zone._id;
      this.site.zones[index] = new EntityZone(zone, true);
      this.site.zones[index].repo_id = repo_id;
    }
  }
*/

  protected getFilteredSites(value: string): EntitySite[] {
    let returned = [];
    if (this.entity && this.siteList) {
      returned = value ? this.siteList.filter(site => (comparableString(site.name).indexOf(comparableString(value)) !== -1)) : this.siteList;
    }
    return returned.length > 200 ? [] : returned;
  }
  protected getFilteredZones(value: string): EntityZone[] {
    let returned = [];
    if (this.site && this.site.repo_id && this.zoneList[this.site.repo_id]) {
      const existing = this.site.zones.map((e) => (e.name));
      returned = (value
        ? this.zoneList[this.site.repo_id].filter(zone => (
          this.site.zones.findIndex((z) => (z.name === zone.name)) === -1
          && comparableString(zone.name).indexOf(comparableString(value)) !== -1)
        )
        : this.zoneList[this.site.repo_id].filter(zone => (
          this.site.zones.findIndex((z) => (z.name === zone.name)) === -1
        ))
      );
    }
    return returned.length > 200 ? [] : returned;
  }
  addZone() {
    this.selectZone(null);
  }
  selectZone(zone: EntityZone) {

    const dialogRef = this.dialog.open(ZoneDialogComponent, {
      disableClose: false,
      width: '600px',
      data: {
        zone: zone,
        site: this.site,
        allowedZones: this.getFilteredZones(''),
        allowAllZones: ((zone && zone.name === '') || (this.site.zones.findIndex((e) => (e.name === '')) === -1))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const i = this.site.zones.findIndex((e) => (result._id === e._id));
        if (i !== -1) {
          this.site.zones[i] = result;
        } else {
          this.site.zones.push(result);
        }
        //this.entity.updateLocation(this.site);
        this._save();
        this.refreshDataSource();
      }
    }
    );
  }

  removeZone(zone) {
    if (this.site && this.site.zones) {
      const i = this.site.zones.findIndex((e) => (e._id === zone._id));
      if (i !== -1) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRef.componentInstance.confirmMessage = 'Êtes-vous sur de vouloir supprimer cette zone ?';
        if (zone.measures && zone.measures.length) {
          dialogRef.componentInstance.confirmMessage += '<br><b>Attention, cette zone comporte des mesures de prévention qui seront également supprimée</b>';
        }
        dialogRef.afterClosed().subscribe(result => {
          if (result) {

            this.site.zones.splice(i, 1);
            //this.entity.updateLocation(this.site);
            this._save();
            this.refreshDataSource();
          }
        });
      }
    }
  }
  refreshDataSource() {
    if (this.site && this.site.zones) {
      this.zonesDataSource = new MatTableDataSource(this.site.zones);
      setTimeout(() => {
        this.setDatasourceSort();
      });
    }
  }
  setDatasourceSort() {
    if (this.paginator) {
      this.zonesDataSource.paginator = this.paginator;
    }
    if (this.sort) {
      this.zonesDataSource.sort = this.sort;
    }
  }

  dropZone(event: CdkDragDrop<string[]>) {
    this.moveZone(event.previousIndex, event.currentIndex);
  }
  moveZone(previousIndex: number, currentIndex: number) {
    if (previousIndex !== currentIndex) {
      moveItemInArray(this.site.zones, previousIndex, currentIndex);
      if (this.autoSave) {
        this._save();
      }
      this.refreshDataSource();
    }
  }
}
