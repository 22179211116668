import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbFilteredService} from 'app/shared/db';
import {LoadingService} from './loading.service';
import {EntityDocumentMetadata} from 'app/shared/models';
import {DocumentMetaService} from './documentMeta.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentMetadataService extends DocumentMetaService {

  onlyChildren = true;
  static LABEL_ONE = "chargement du fichier";
  static LABEL_ALL = "chargement des fichiers ?";
  static LABEL_CHILDREN = "chargement des fichiers de l'opération";
  static LABEL_SAVE = "sauvegarde du fichier";
  static LABEL_DELETE = "suppression du fichier";

  static ENTITY_TYPE: string = 'doc_meta';
  static ENTITY_FIELDS: string[] = ['repo_id', 'context', 'data_id', 'name', 'displayName', 'description', 'mime', 'ext', 'size', 'icon', 'status', 'category', 'categoryPicto', 'dateEnd', 'createdAt', 'createdName', 'updatedAt', 'updatedName', 'uploadedAt', 'uploadedName', 'request', 'isInline'];
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['editPP'],
    UPDATE: ['editPP'],
    DELETE: ['editPP']
  }

  static ENTITY_URL = 'document';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityDocumentMetadata {
    return new EntityDocumentMetadata(data, clone);
  }

  beforeSave(entity: EntityDocumentMetadata, updatedAt: Date = null): EntityDocumentMetadata {
    const newUpload = entity && entity.data_id && !entity.uploadedAt;
    if (newUpload) {
      entity.uploadedId = this.getUserId();
      entity.uploadedName = this.getUserName();
      entity.uploadedAt = updatedAt ? new Date(updatedAt) : new Date();
    }
    return super.beforeSave(entity, newUpload ? entity.uploadedAt : updatedAt);
  };
  beforeSendRequest(entity: EntityDocumentMetadata, updatedAt: Date = null): EntityDocumentMetadata {
    entity.requestedId = this.getUserId();
    entity.requestedName = this.getUserName();
    entity.requestedAt = updatedAt ? new Date(updatedAt) : new Date();
    entity.requestedSent = [...entity.request];
    return entity;
  };
  getNewEntityFromFile(file: File, context = null, parent_id = null, data_id = null) {
    const data = this.getFromFile(file);
    if (context) {
      data['context'] = context;
    }
    if (parent_id) {
      data['parent_id'] = parent_id;
    }
    if (data_id) {
      data['data_id'] = data_id;
      data['context'] = context;
      data['uploadedId'] = this.getUserId(),
        data['uploadedName'] = this.getUserName()
    }
    return this.getNewEntity(data);
  }

}
