import {Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {
  LoggerService,
  EntityFormComponent,
  EntitySecureService
} from 'app/shared';
import {EntityWorkbook} from 'app/workbook-core';

export class AbstractWorkbookChildFormComponent extends EntityFormComponent {

  @Input() workbook: EntityWorkbook;

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      this.updateRights();
    }
  }
  updateRights() {
    const isModifiable = this.isModifiable();
    this.rightC = this.rightC && isModifiable;
    this.rightU = this.rightU && isModifiable;
    this.rightD = this.rightD && isModifiable;
  }
  public isModifiable() {
    return !!this.workbook && this.workbook.isModifiable() && (!this.workbook._id || this.isParentSync(this.workbook._id));
  }
  public _isArchived() {
    return this.workbook && this.workbook.status === 'Archivé';
  }
}
