import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthService} from './auth.service';
import {Auth0ConfigService} from './auth0-config.service';

@Injectable()
/**
 * Use to add Access Token and Domain in http request header
 */
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private config: Auth0ConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getAccessToken()}`,
        Domain: this.config.data.auth0.domain,
      }
    });

    return next.handle(request);
  }
}
