import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {LoggerService, EntityFormComponent} from 'app/shared';
import {DefaultVisibilityService, EntityDefaultVisibility, OrganizationService, EntityOrganization} from 'app/workbook-core';

@Component({
  selector: 'app-repository-defaultVisibility',
  templateUrl: './defaultVisibility.component.html',
  styleUrls: ['./defaultVisibility.component.scss']
})

export class DefaultVisibilityComponent extends EntityFormComponent {
  entity: EntityDefaultVisibility;
  organizationListSubscriber: Subscription;
  organizationList: EntityOrganization[] = [];

  constructor(
    protected organizationService: OrganizationService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DefaultVisibilityService

  ) {
    super(dialog, _logger, _entityService);
    this.organizationListSubscriber = this.organizationService.getAll().subscribe((organizationList) => {
      if (organizationList) {
        this.organizationList = organizationList.sort((a, b) => a.name.localeCompare(b.name));
      }
    })

  }
  ngOnDestroy() {
    if (this.organizationListSubscriber) {
      this.organizationListSubscriber.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);

  }

}
