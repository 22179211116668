import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { Entity } from '../models';
import { AbstractEntityDialogComponent } from './abstractEntityDialog.component';

export class EntityDialogComponent extends AbstractEntityDialogComponent {
  entity: Entity;

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<EntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data && data['entity']) {
      this.entity = data['entity'];
    }
  }

  onSyncStateChange(res) {
    if (this.entity && this.entity._id) {
      //console.log('EntityDialogComponent onSyncStateChange ' + this.entity._id, res);
      this._updatePermissions(this.entity._id);
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.entity);
    }
  }
}
