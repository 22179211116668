import {Component} from '@angular/core';
import {
  LoggerService
} from 'app/shared';
import {
  PreventionPlanService
} from '../services';
import {
  EntityPreventionPlan
} from '../models';
import {
  WorkbookExportComponent,
  EntityIcp,
  Ldcm
} from '../../workbook';


@Component({
  selector: 'app-prevention-plan-export',
  templateUrl: './preventionPlanExport.component.html',
  styleUrls: ['./preventionPlanExport.component.scss']
})
export class PreventionPlanExportComponent extends WorkbookExportComponent {

  entity: EntityPreventionPlan;

  icps: EntityIcp[] = [];
  ldcmLocals: {[icp_id: string]: Ldcm[]} = {};
  ldcmDocuments: {[icp_id: string]: Ldcm[]} = {};
  ldcmEquipments: {[icp_id: string]: Ldcm[]} = {};
  ldcmGeneralInst: {[icp_id: string]: Ldcm[]} = {};


  constructor(
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService) {
    super(_logger, _entityService);
  }

  _init(data) {
    super._init(data);
    if (this.entity) {
      this._entityService.getSignAgreement(this.entity)
        .then((sa) => {this.sign_agreement = sa;})
        .catch((error) => {this._logger.error('WorkbookExportComponent', 'Unable to retrieve signAgreement', error);});

      this._entityService.getCurrentIcpList(this.entity)
        .then((list) => {
          this.icps = [];
          list.forEach((_icp) => {
            this._entityService.icpService.get(_icp._id).toPromise().then((icp) => {
              this.icps.push(icp);
              if (icp.IPLdcm) {
                this.ldcmLocals[icp._id] = icp.IPLdcm.filter((l) => l.group === 'locals');
                this.ldcmDocuments[icp._id] = icp.IPLdcm.filter((l) => l.group === 'documents');
                this.ldcmEquipments[icp._id] = icp.IPLdcm.filter((l) => l.group === 'mandatInstruction');
                this.ldcmGeneralInst[icp._id] = icp.IPLdcm.filter((l) => l.group === 'generalInstruction');
              } else {
                this.ldcmLocals[icp._id] = [];
                this.ldcmDocuments[icp._id] = [];
                this.ldcmEquipments[icp._id] = [];
                this.ldcmGeneralInst[icp._id] = [];
              }
            })
              .catch((error) => {this._logger.error('WorkbookExportComponent', 'Unable to retrieve icp', JSON.stringify(error));});

          });
        })
        .catch((error) => {this._logger.error('WorkbookExportComponent', 'Unable to retrieve icpList', JSON.stringify(error));});
    }
  }

}
