import {Shape, ToolbarItem, ShapeToolOptions, ColorPalette, SizeCategories, LineEndStyle, TextAlign} from '@pqina/pintura';
import {Entity} from './entity.model';
import stickers from 'locale/pintura/fr_FR/sticker/stickers';

export class EntityPinturaConfig extends Entity {
  static STICKERS = stickers;
  public markupEditorToolStyles: {
    name: string,
    type: 'path' | 'rectangle' | 'ellipse' | 'line' | 'text',
    shape?: Shape,
    options?: ShapeToolOptions
  }[];

  public markupEditorToolbar: ToolbarItem[];

  public defaultColorOptions?: ColorPalette;
  public defaultFontSizeOptions?: number[];
  public defaultFontScaleOptions?: SizeCategories;
  public defaultLineHeightOptions?: number[];
  public defaultLineHeightScaleOptions?: SizeCategories;
  public defaultStrokeWidthOptions?: number[];
  public defaultStrokeScaleOptions?: SizeCategories;
  public defaultLineEndStyleOptions?: LineEndStyle[];
  public defaultFontFamilyOptions?: [string, string][];
  public defaultFontStyleOptions?: [string, string][];
  public defaultTextAlignOptions?: TextAlign[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.markupEditorToolStyles = (data && data.markupEditorToolStyles) ? data.markupEditorToolStyles : [];
    this.markupEditorToolbar = (data && data.markupEditorToolbar) ? data.markupEditorToolbar : [
      'view',
      'move',
      'text',
      'rectangle',
      'ellipse',
      'line',
      'arrow',
      'path',
      'sharpie'
    ];


    this.defaultColorOptions = (data && data.defaultColorOptions) ? data.defaultColorOptions : null;
    this.defaultFontSizeOptions = (data && data.defaultFontSizeOptions) ? data.defaultFontSizeOptions : null;
    this.defaultFontScaleOptions = (data && data.defaultFontScaleOptions) ? data.defaultFontScaleOptions : null;
    this.defaultLineHeightOptions = (data && data.defaultLineHeightOptions) ? data.defaultLineHeightOptions : null;
    this.defaultLineHeightScaleOptions = (data && data.defaultLineHeightScaleOptions) ? data.defaultLineHeightScaleOptions : null;
    this.defaultStrokeWidthOptions = (data && data.defaultStrokeWidthOptions) ? data.defaultStrokeWidthOptions : null;
    this.defaultStrokeScaleOptions = (data && data.defaultStrokeScaleOptions) ? data.defaultStrokeScaleOptions : null;
    this.defaultLineEndStyleOptions = (data && data.defaultLineEndStyleOptions) ? data.defaultLineEndStyleOptions : null;
    this.defaultFontFamilyOptions = (data && data.defaultFontFamilyOptions) ? data.defaultFontFamilyOptions : null;
    this.defaultFontStyleOptions = (data && data.defaultFontStyleOptions) ? data.defaultFontStyleOptions : null;
    this.defaultTextAlignOptions = (data && data.defaultTextAlignOptions) ? data.defaultTextAlignOptions : null;
  }
}
