import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {LoggerService, EntityEditComponent, ConfigEmailService} from 'app/shared';

@Component({
  selector: 'app-config-restriction-edit',
  templateUrl: './restrictionEdit.component.html',
  styleUrls: ['../config.scss']
})

export class RestrictionEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: ConfigEmailService

  ) {
    super(_route, _router, _logger, _entityService);
  }

}
