import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {LoggerService} from 'app/shared/logger';
import {UploadService} from 'app/shared/upload';
import {CommonComponent} from '../../common.component';
import {CanvasWhiteboardComponent} from '../ng2-canvas-whiteboard';

@Component({
  selector: 'app-canvas-dialog',
  templateUrl: './canvas-dialog.component.html',
  styleUrls: ['./canvas-dialog.component.scss'],
  viewProviders: [CanvasWhiteboardComponent],
  encapsulation: ViewEncapsulation.None
})
export class CanvasDialogComponent extends CommonComponent implements OnInit {

  url: string;
  mime: string;
  error: boolean;
  value: string = null;
  width: number;
  height: number;

  canvasOptions = {
    drawButtonEnabled: true,
    drawButtonClass: 'material-icons',
    drawButtonText: '',
    clearButtonEnabled: true,
    clearButtonClass: 'material-icons',
    clearButtonText: '',
    undoButtonText: '',
    undoButtonClass: 'material-icons',
    undoButtonEnabled: true,
    redoButtonText: '',
    redoButtonClass: 'material-icons',
    redoButtonEnabled: true,
    colorPickerEnabled: true,
    saveDataButtonEnabled: true,
    saveDataButtonClass: 'material-icons',
    saveDataButtonText: '',
    lineWidth: 2,
    // aspectRatio: 1.5,
    strokeColor: 'rgb(0,0,0)',
    shouldDownloadDrawing: true
  };

  constructor(
    public dialogRef: MatDialogRef<CanvasDialogComponent>,
    private uploadService: UploadService,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }

  ngOnInit() {
    this.width = document.getElementById('canvasWhiteboard').offsetWidth;
    this.height = document.getElementById('canvasWhiteboard').offsetHeight;
  }

  onSelectFile(event) { // called each time file input changes

    if (event.target.files && event.target.files[0]) {
      // const width = document.getElementById('canvasWhiteboard').offsetWidth;
      // const height = document.getElementById('canvasWhiteboard').offsetHeight;

      const fileAsBlob = new Blob([event.target.files[0]]);
      this.mime = event.target.files[0].type;
      this.url = window.URL.createObjectURL(fileAsBlob);
      // this.resizeImage(event.target.files[0], width, height).then(blob => {
      //     this.url = URL.createObjectURL(blob);
      // }, err => {
      //     console.error('Photo error', err);
      // });
    }
  }


  // TODO : test to resize picture in fonction of size screen
  // private resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
  //     return new Promise((resolve, reject) => {
  //         const image = new Image();
  //         image.src = URL.createObjectURL(file);
  //         image.onload = () => {
  //             const width = image.width;
  //             const height = image.height;
  //             console.log('width: ' + width);
  //             console.log('height: ' + height);
  //             if (width <= maxWidth && height <= maxHeight) {
  //                 resolve(file);
  //             }
  //
  //             let newWidth;
  //             let newHeight;
  //
  //             if (width > height) {
  //                 newHeight = height * (maxWidth / width);
  //                 newWidth = maxWidth;
  //             } else {
  //                 newWidth = width * (maxHeight / height);
  //                 newHeight = maxHeight;
  //             }
  //
  //             const canvas = document.createElement('canvas');
  //             canvas.width = newWidth;
  //             canvas.height = newHeight;
  //
  //             const context = canvas.getContext('2d');
  //
  //             context.drawImage(image, 0, 0, newWidth, newHeight);
  //
  //             canvas.toBlob(resolve, file.type);
  //         };
  //         image.onerror = reject;
  //     });
  // }


  onKey(event: KeyboardEvent) {
    this.value = (<HTMLInputElement> event.target).value;
  }

  onCanvasSave(error) {
    if (error) {
      this.error = true;
    } else {
      // const documentMetadata = this.uploadService.getDocumentMetadata();
      this.uploadService.getDocumentMetadataAsync().then((documentMetadata) => {
        const extension = this._generateDataType(documentMetadata.mime);
        documentMetadata.displayName = this.value === null ? '<sans Nom>' + extension : this.value + extension;
        this.dialogRef.close(documentMetadata);
      })
    }
  }

  private _generateDataType(returnedDataType: string): string {
    if (returnedDataType) {
      return '.' + returnedDataType.split('/')[1];
    }

    return '';
  }

  close() {
    this.dialogRef.close();
  }

}
