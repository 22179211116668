import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {comparableString, LoggerService, getBase64, EntityDocumentMetadata, EntityCompany, Contact, formattedDate} from 'app/shared';
import {
  PreventionPlanService,
  SecurityProtocolService,
  EntitySecurityProtocol,
  EntityPreventionPlan,
  EntityOffice, EntityExternalCompany, WorkbookOfficeService, WorkbookExternalCompanyService,
  SiteService, EntitySite, Illustrated, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from '../abstractSignableInfo.component';

@Component({
  selector: 'app-signable-securityProtocol-info',
  templateUrl: './securityProtocolInfo.component.html',
  styleUrls: ['./securityProtocolInfo.component.scss']
})
export class SecurityProtocolInfoComponent extends AbstractSignableInfoComponent {
  static FLAG_SUFFIX: string = 'ps';
  autoSave = false;
  public entity: EntitySecurityProtocol;
  workbook: EntityPreventionPlan;
  minDateStart: Date;
  maxDateEnd: Date;
  dateStartError: string = '';
  dateEndError: string = '';
  timeStartError: string = '';
  timeEndError: string = '';

  placeHolderStartDate: string = 'Début';
  placeHolderEndDate: string = 'Fin';
  oeexList: (EntityOffice | EntityExternalCompany)[] = [];
  contactList: {[oeex_id: string]: Contact[]} = {};

  protected _originatorChangeSubscription: Subscription;
  filteredOriginators: (EntityOffice | EntityExternalCompany)[] = [];
  protected _performerChangeSubscription: Subscription;
  filteredPerformers: (EntityOffice | EntityExternalCompany)[] = [];

  protected _originatorContactChangeSubscription: Subscription;
  filteredOriginatorContacts: (EntityOffice | EntityExternalCompany)[] = [];
  protected _performerContactChangeSubscription: Subscription;
  filteredPerformerContacts: (EntityOffice | EntityExternalCompany)[] = [];

  protected _oeexSubscription: Subscription;
  /*
  withDocument: boolean = false;
  protected _documentsSubscription: Subscription;
  documents: {[item: string]: EntityDocumentMetadata[]} = {};
*/
  newMerchandiseNature: string = '';
  newMerchandiseState: string = '';
  newMerchandisePackaging: string = '';

  newVehicle: string = '';
  newVehicleType: string = '';
  newEquipment: string = '';

  newProvidedEquipment: string = '';

  newWarningDevice: string = '';
  newInterventionDevice: string = '';
  newEmergency: string = '';

  newProtectiveGear: string = '';
  newInterdiction: string = '';
  newInstruction: string = '';

  newOriginatorInstruction: string = '';
  //newProductFeature: string = '';

  protected _siteChangeSubscription: Subscription;
  filteredSites: EntitySite[] = [];
  protected _siteListSubscription: Subscription;
  siteList: EntitySite[] = [];

  constructor(
    protected _siteService: SiteService,
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: SecurityProtocolService
  ) {
    super(_documentationService, _workbookService, dialog, _logger, _entityService);
    this._siteListSubscription = this._siteService.getAll().subscribe((list) => {
      if (list) {
        this.siteList = list;
      }
    });
  }

  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (changes && changes['workbook'] && this.workbook) {
      this.minDateStart = new Date(this.workbook.dateStart);
      this.maxDateEnd = new Date(this.workbook.dateEnd);
    }

  }
  protected _init(data) {
    super._init(data);
    /*
        if (this._documentsSubscription) {
          this._documentsSubscription.unsubscribe();
        }
        this._documentsSubscription = this._workbookService.getDocumentMetaDataList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            this.withDocument = list.length > 0;
            this.documents['Annexes'] = list.filter((e) => (this.workbook && e.context && e.context.indexOf(this._workbookService.getUrlWorkbook(this.workbook.documentType, this.workbook._id, 'document')) === 1));
          }
        });
    */
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    this._oeexSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
      if (list) {
        this.oeexList = list;
        this.contactList = {};
        this.oeexList.forEach((oeex) => {
          this.contactList[oeex._id] = oeex.contacts;//.filter((c) => (c.signatoryPermit));
        });
        if (this.entity && !this.entity._id) {
          let services = this.oeexList.filter((e) => (e.documentType === WorkbookOfficeService.ENTITY_TYPE));
          if (services.length === 1) {
            this.entity.originator = new EntityCompany(services[0]);
            if (services[0].contacts && services[0].contacts.length === 1) {
              this.entity.originatorContact = Object.assign({}, services[0].contacts[0]);
            }
          }
          let eexs = this.oeexList.filter((e) => (e.documentType === WorkbookExternalCompanyService.ENTITY_TYPE));
          if (eexs.length === 1) {
            this.entity.performer = new EntityCompany(eexs[0]);
            if (eexs[0].contacts && eexs[0].contacts.length === 1) {
              this.entity.performerContact = Object.assign({}, eexs[0].contacts[0]);
            }
          }
        }
      }
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    /*
    if (this._documentsSubscription) {
      this._documentsSubscription.unsubscribe();
    }
    */
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
    if (this._siteChangeSubscription) {
      this._siteChangeSubscription.unsubscribe();
    }
    if (this._siteListSubscription) {
      this._siteListSubscription.unsubscribe();
    }
    if (this._originatorChangeSubscription) {
      this._originatorChangeSubscription.unsubscribe();
    }
    if (this._performerChangeSubscription) {
      this._performerChangeSubscription.unsubscribe();
    }
    if (this._originatorContactChangeSubscription) {
      this._originatorContactChangeSubscription.unsubscribe();
    }
    if (this._performerContactChangeSubscription) {
      this._performerContactChangeSubscription.unsubscribe();
    }
  }

  switchDoc(event, doc) {
    if (this.entity) {
      if (event.checked) {
        if (this.entity.documents.findIndex((e) => (e._id === doc._id)) === -1) {
          this.entity.documents.push(doc);
        }
      } else {
        let i = this.entity.documents.findIndex((e) => (e._id === doc._id));
        if (i !== -1) {
          this.entity.documents.splice(i, 1)
        }
      }
    }
  }
  addProtectiveGear() {
    if (this.newProtectiveGear) {
      this.entity.protectiveGears.push(new Illustrated(this.newProtectiveGear, '', true));
      this.newProtectiveGear = '';
    }
  }
  /*
  addProductFeatures() {
    if (this.newProductFeature) {
      this.entity.productFeatures.push(new Illustrated(this.newProductFeature, '', true));
      this.newProductFeature = '';
    }
  }
  */
  addMerchandiseNature() {
    if (this.newMerchandiseNature) {
      this.entity.merchandiseNatures[this.newMerchandiseNature] = true;
      this.newMerchandiseNature = '';
    }
  }

  addMerchandiseState() {
    if (this.newMerchandiseState) {
      this.entity.merchandiseStates[this.newMerchandiseState] = true;
      this.newMerchandiseState = '';
    }
  }

  addMerchandisePackaging() {
    if (this.newMerchandisePackaging) {
      this.entity.merchandisePackagings[this.newMerchandisePackaging] = true;
      this.newMerchandisePackaging = '';
    }
  }


  addVehicle() {
    if (this.newVehicle) {
      this.entity.vehicles[this.newVehicle] = true;
      this.newVehicle = '';
    }
  }

  addVehicleType() {
    if (this.newVehicleType) {
      this.entity.vehicleTypes[this.newVehicleType] = true;
      this.newVehicleType = '';
    }
  }

  addEquipment() {
    if (this.newEquipment) {
      this.entity.equipments[this.newEquipment] = true;
      this.newEquipment = '';
    }
  }
  addProvidedEquipment() {
    if (this.newProvidedEquipment) {
      this.entity.providedEquipments[this.newProvidedEquipment] = true;
      this.newProvidedEquipment = '';
    }
  }
  addInterdiction() {
    if (this.newInterdiction) {
      this.entity.interdictions[this.newInterdiction] = true;
      this.newInterdiction = '';
    }
  }
  addInstruction() {
    if (this.newInstruction) {
      this.entity.instructions[this.newInstruction] = true;
      this.newInstruction = '';
    }
  }
  addOriginatorInstruction() {
    if (this.newOriginatorInstruction) {
      this.entity.originatorInstructions[this.newOriginatorInstruction] = true;
      this.newOriginatorInstruction = '';
    }
  }

  addWarningDevice() {
    if (this.newWarningDevice) {
      this.entity.warningDevices.push(this.newWarningDevice);
      this.newWarningDevice = '';
    }
  }
  removeWarningDevice(index) {
    if (index < this.entity.warningDevices.length) {
      this.entity.warningDevices.splice(index, 1);
    }
  }
  addInterventionDevice() {
    if (this.newInterventionDevice) {
      this.entity.interventionDevices.push(this.newInterventionDevice);
      this.newInterventionDevice = '';
    }
  }
  removeInterventionDevice(index) {
    if (index < this.entity.interventionDevices.length) {
      this.entity.interventionDevices.splice(index, 1);
    }
  }
  addEmergency() {
    if (this.newEmergency) {
      this.entity.emergency.push(this.newEmergency);
      this.newEmergency = '';
    }
  }
  removeEmergency(index) {
    if (index < this.entity.emergency.length) {
      this.entity.emergency.splice(index, 1);
    }
  }

  initSearchSite() {
    this.filteredSites = this.getFilteredByName(this.siteList, ((this.entity && this.entity.site) ? this.entity.site.name : ''));
    if (this.filteredSites && this.filteredSites.length > 200) {
      this.filteredSites = [];
    }
    if (!this._siteChangeSubscription && this.form && this.form.controls && this.form.controls['site_name']) {
      this._siteChangeSubscription = this.form.controls['site_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredSites = this.getFilteredByName(this.siteList, value);
          if (this.filteredSites && this.filteredSites.length > 200) {
            this.filteredSites = [];
          }
        });
    }
  }
  initSearchOriginator() {
    this.filteredOriginators = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.originator) ? this.entity.originator.name : ''));
    if (!this._originatorChangeSubscription && this.form && this.form.controls && this.form.controls['originator_name']) {
      this._originatorChangeSubscription = this.form.controls['originator_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredOriginators = this.getFilteredByName(this.oeexList, value);
        });
    }
  }
  initSearchPerformer() {
    this.filteredPerformers = this.getFilteredByName(this.oeexList, ((this.entity && this.entity.performer) ? this.entity.performer.name : ''));
    if (!this._performerChangeSubscription && this.form && this.form.controls && this.form.controls['performer_name']) {
      this._performerChangeSubscription = this.form.controls['performer_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredPerformers = this.getFilteredByName(this.oeexList, value);
        });
    }
  }
  initSearchOriginatorContact(signatoryPermit: boolean = false) {
    this.filteredOriginatorContacts = this.getFilteredByName(this.contactList[this.entity.originator._id], ((this.entity && this.entity.originatorContact) ? this.entity.originatorContact.name : ''), signatoryPermit);
    if (!this._originatorContactChangeSubscription && this.form && this.form.controls && this.form.controls['originatorContact_name']) {
      this._originatorContactChangeSubscription = this.form.controls['originatorContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredOriginatorContacts = this.getFilteredByName(this.contactList[this.entity.originator._id], value, signatoryPermit);
        });
    }
  }
  initSearchPerformerContact(signatoryPermit: boolean = false) {
    this.filteredPerformerContacts = this.getFilteredByName(this.contactList[this.entity.performer._id], ((this.entity && this.entity.performerContact) ? this.entity.performerContact.name : ''), signatoryPermit);
    if (!this._performerContactChangeSubscription && this.form && this.form.controls && this.form.controls['performerContact_name']) {
      this._performerContactChangeSubscription = this.form.controls['performerContact_name'].valueChanges
        .pipe(
          debounceTime(100)
        )
        .subscribe(value => {
          this.filteredPerformerContacts = this.getFilteredByName(this.contactList[this.entity.performer._id], value, signatoryPermit);
        });
    }
  }
  setCompanyAttribute(attribute: string, value: EntityOffice | EntityExternalCompany, event) {
    if (event && event.source && event.source.selected && attribute && this.entity && this.entity[attribute]) {
      this.entity[attribute] = new EntityCompany(value);
    }

  }
  setContactAttribute(attribute: string, value: Contact) {
    if (attribute && this.entity && this.entity[attribute]) {
      this.entity[attribute] = Object.assign(new Contact(), value);
    }

  }
  protected getFilteredByName(collection: any[], value: string = '', signatoryPermit: boolean = false): any[] {
    return (collection && collection.length)
      ? (value ?
        collection.filter(e => ((!signatoryPermit || e.signatoryPermit) && e.name && comparableString(e.name).indexOf(comparableString(value)) !== -1))
        : collection.filter(e => (!signatoryPermit || e.signatoryPermit)))
      : [];
  }
  public fileEvent(field: string, index: number, event) {

    if (field && event.target && event.target.files && event.target.files.length
      && this.entity && this.entity[field] && this.entity[field][index]) {
      const fileSelected: File = event.target.files[0];
      getBase64(fileSelected).then(
        data => {
          this.entity[field][index].logo = data.toString();
        });
    }
  }
}
