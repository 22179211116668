import {Injectable} from '@angular/core';
import {DbConfigService} from 'app/shared/db';
import {EntityService} from 'app/shared/services/entity.service';
import {LoadingService} from 'app/shared/services/loading.service';
import {EntityConfigServer} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class PrintConfigService extends EntityService {

  static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'config_server';

  static ENTITY_URL = 'config/server';

  constructor(
    protected _dbService: DbConfigService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _loadingService);
  }
  factory(data): EntityConfigServer {
    return new EntityConfigServer(data);
  }

}
