import {ViewChild, OnDestroy, AfterViewInit, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
import {FormGroup, NgForm, ValidationErrors} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ErrorDialogComponent} from './error';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {Entity} from '../models';
import {EntityComponent} from './entity.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';


export class EntityFormComponent extends EntityComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('form') form: NgForm;

  @Input() autoSave = true;
  @Output() onSave = new EventEmitter<Entity>();

  protected _formChange: Subscription;
  protected _firstChange = false;
  autocompleteoff: string;

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
    this._firstChange = true;
    this.autocompleteoff = (new Date()).getTime() + '-';
  }

  protected _initUpdateEntity(data) {
    super._initUpdateEntity(data);
    if (this.entity && this.entity._deleted && this.form) {
      this.rightU = false;
      this.autoSave = false;
    }
    this._initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['entity']) {
      this._firstChange = changes['entity']['firstChange'];
    }
  }
  /**
   * Load after View init the detection change form
   */
  ngAfterViewInit() {
    if (this.autoSave) {
      this._subscribeFormChange();
    }
  }
  protected _initForm() {
    if (this.id || (this.entity && this.entity._id)) {
      setTimeout(() => {
        if (!this.rightU) {
          this._disableForm();
        }
        this.initialFormValidation();
      });
    }
  }
  protected _disableForm() {
    if (this.form && this.form.controls) {
      Object.keys(this.form.controls).forEach(
        (fc: string) => {
          if (this.form.controls[fc]) {
            this.form.controls[fc].disable();
          }
        });
    }
  }
  initialFormValidation() {
    if (this.form && this.form.controls) {
      Object.keys(this.form.controls).forEach(
        (fc: string) => {
          if (this.form.controls[fc]) {
            if (this.form.controls[fc].enabled && !this.form.controls[fc].valid) {
              this.form.controls[fc].markAsTouched();
            } else {
              this.form.controls[fc].markAsUntouched();
              this.form.controls[fc].markAsPristine();
            }
          }
        });
    }
  }
  /**
   * After Destroy instance, unsubscribe detection change form
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    this._unsubscribeFormChange();
  }


  /**
   * Save data when admin form values change
   * @private
   */
  protected _subscribeFormChange() {
    if (this.form) {
      const that = this;
      this._formChange = this.form.control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe((test) => {
          // Avoid saving data each time the form is loaded
          if (that._firstChange) {
            that._firstChange = false;
          } else {
            //const invalidControls = this._findInvalidFormControls(this.form);
            //if (invalidControls.length === 0) {
            if (that.rightU && that.entity && that.entity._id && that.form.valid && that.form.dirty) {
              //console.log('autoSave', that.entity);
              that._save().then((e) => {
                that._afterSave(e);
              });
            } else {
              const invalidControls = that._findInvalidFormControls(this.form);
              if (invalidControls && invalidControls.length) {
                that._logger.info('invalidControls', invalidControls);
              }
              // TODO : check validation: not be used, lots of popup during write in input
              //  console.log(invalidControls);
              // const dialogRef = this.dialog.open(ErrorDialogComponent, {
              //   disableClose: true,
              //   width: '600px',
              // });
              // dialogRef.componentInstance.errorMessage = 'Erreur de saisie sur le champ, veuillez rectifier votre saisie pour la sauvegarder';
            }
          }
        });
    }
  }

  changeEntityAttribute(variable: string, index: number, value: string, attribute: string = null, subattribute: string = null, i: number = -1) {
    if (this.rightU
      && this.entity
      && this.entity[variable]
      && this.entity[variable][index]
      && (!subattribute
        || (this.entity[variable][index][subattribute]
          && (i === -1 || this.entity[variable][index][subattribute][i]))
      )
    ) {
      if (attribute) {
        if (subattribute) {
          if (i === -1) {
            this.entity[variable][index][subattribute][attribute] = value[attribute];
          } else {
            this.entity[variable][index][subattribute][i][attribute] = value[attribute];
          }
        } else {
          this.entity[variable][index][attribute] = value[attribute];
        }
      } else {
        this.entity[variable][index] = value;
      }
      this._save().then((e) => {
        this._afterSave(e);
      })
    }
  }
  protected _findInvalidFormControls(form: NgForm) {
    const invalid = [];
    const controls = form.controls;
    Object.keys(controls).forEach(key => {
      // controls[key].markAsDirty();
      if (controls[key].invalid && (controls[key].dirty || controls[key].touched)) {
        invalid.push(controls[key]);
      }
    });
    // for (const name in controls) {
    //   if (controls[name].invalid) {
    //     invalid.push(name);
    //   }
    // }
    return invalid;
  }
  getFormErrors(br: boolean = false) {
    const invalid = this._findInvalidFormControls(this.form);
    const errors: ValidationErrors = this.form.errors;
    Object.keys(errors).map((name) => (
      (errors[name] && errors[name].message) ? errors[name].message : name
    )).join(br ? '<br/>' : '\n');
  }
  /**
   * Could be use to get data after save
   * @param e
   * @private
   */
  protected _afterSave(e) {
    // this._firstChange = true;
    if (this.entity && e && e !== 'undefined'
      // && e._rev && this.entity._rev && e._rev !== this.entity._rev
    ) {
      this.initialFormValidation();
      this.onSave.emit(this.entity);
    }
  }

  /**
   * Unsubscribe detect admin form change
   * @private
   */
  protected _unsubscribeFormChange() {
    if (this._formChange) {
      this._formChange.unsubscribe();
    }
  }

  /**
   *
   * @param name
   * @param group
   */
  checkFormValidation(name: string, group?: string) {
    let input;
    if (group) {
      input = (<FormGroup> this.form.controls[group]).controls[name];
    } else {
      input = this.form.controls[name];
    }
    if (input && input.invalid) {
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      if (input.errors && input.errors.required) {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Le champ est obligatoire, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      } else if (input.errors && input.errors.pattern) {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Le format n\'est pas valide, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      } else {
        dialogRef.componentInstance.errorTitle = 'Erreur de saisie';
        dialogRef.componentInstance.errorMessage = 'Erreur de saisie du champ, veuillez rectifier votre saisie pour sauvegarder vos modifications';
      }
    }
  }
  remove() {
    if (this.entity && this.entity._id) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;

      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          this._entityService.remove(this.entity).then((e) => {
            this.entity._deleted = true;
            this.onSave.emit(this.entity);
          });
          //this.snackbar('Le métier a été supprimé');
        }
      });
    }
  }

  changeDateStart(startAttr: string = 'dateStart', endAttr: string = 'dateEnd') {
    if (this.entity && this.entity[startAttr] &&
      (!this.entity[endAttr]
        || (new Date(this.entity[startAttr])).getTime() > (new Date(this.entity[endAttr])).getTime())) {
      this.entity[endAttr] = new Date(this.entity[startAttr]);
    }
  }
}
