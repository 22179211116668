import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {PopupDialogComponent} from '../popup-dialog'
import {version} from '../../../../../package.json';
import {checkNewVersion, setVersion} from '../../utils';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent extends PopupDialogComponent {

  public version: string = version;
  public newVersion: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(dialogRef);
    this.newVersion = checkNewVersion();
    this.popupMessage = (this.newVersion ? 'Votre application a été mise à jour en version ' + version
      + '<br>Vous trouverez la liste des nouveautés dans la note correspondante : <a class="externalLink" href="https://trepied.co/category/releases" target="_BLANK">en savoir plus sur la nouvelle version</a>.' : 'trepied(.)co v' + this.version + '<a class="externalLink" href="https://trepied.co/category/releases" target="_BLANK">Voir l\'historique des versions</a>')
      + '<br><br>Copyright © 2016-2021 Trepied';
    this.popupTitle = this.newVersion ? 'Nouvelle version' : 'A propos de';
  }
  close() {
    if (this.newVersion) {
      setVersion();
    }
    this.dialogRef.close()
  }
}
