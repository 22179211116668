import { DbCommonService, PermissionsService } from 'app/shared';
import { LoadingService } from './loading.service';
import { EntityEditableService } from './entityEditable.service';

export abstract class EntitySignableService extends EntityEditableService {

  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
}
