import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService, SignableStatus } from 'app/shared';
import { EntityIcp, ICPState } from '../models';

@Injectable({
  providedIn: 'root'
})
export class IcpService extends EntitySignableService {

  autoInit = false;
  onlyChildren = true;

  static LABEL_ONE = "chargement de l'inspection";
  static LABEL_ALL = "chargement des inspections ?";
  static LABEL_CHILDREN = "chargement des inspections de l'opération";
  static LABEL_SAVE = "sauvegarde de l'inspection";
  static LABEL_DELETE = "suppression de l'inspection";

  static ENTITY_TYPE: string = 'icp';
  static ENTITY_FIELDS: string[] = ['name', 'code', 'editor', 'iPDate', 'iPZone', 'iPSite', 'iPSite_id', 'iPCity', 'iPHourStart', 'iPHourEnd', 'iPRemark', 'IPLdcm', 'iPOEex', 'steps', 'status', 'withoutSignatories', 'implementations', 'signatures', 'notifications'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showICP'],
    CREATE: ['createICP'],
    UPDATE: ['createICP'],
    DELETE: ['removeICP']
  }

  static ENTITY_URL = 'icp';

  static PREFIX = 'IC';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityIcp {
    return new EntityIcp(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.iPDate).getTime() === new Date(b.iPDate).getTime())
        ? (a.iPHourStart ? a.iPHourStart.localeCompare(b.iPHourStart) : 1)
        : (new Date(a.iPDate).getTime() > new Date(b.iPDate).getTime()) ? -1 : 1)));

  }
  getState(entity: EntityIcp, workbook_status, pdp_status) {
    let state = (workbook_status && workbook_status === 'archived') ? ICPState.ARCHIVED : ICPState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === SignableStatus.VALIDATED) {
        state = ICPState.PREPARED;
      } else if (entity.status === SignableStatus.SIGNING && !entity.withoutSignatories) {
        state = ICPState.SIGNING;
      } else {
        if (pdp_status && pdp_status === SignableStatus.SIGNED) {
          state = ICPState.VALID;
        } else if (entity.status === SignableStatus.OUTDATED || (pdp_status && pdp_status === SignableStatus.OUTDATED)) {
          state = ICPState.OUTDATED;
        } else {
          state = entity.withoutSignatories ? ICPState.VALIDATED : ICPState.SIGNED;
        }
      }
    }
    return state;
  }
}
