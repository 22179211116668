import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { WorkbookCreateDialogComponent } from '../create-dialog';
import { WorkbookDuplicateDialogComponent } from '../duplicate-dialog';
import { OfficeService, EntityOffice, DefaultVisibilityService, EntityDefaultVisibility, EntityWorkbook } from 'app/workbook-core';

@Component({
  selector: 'app-workbook-select',
  templateUrl: './workbookSelect.component.html',
  styleUrls: ['./workbookSelect.component.scss']
})
export class WorkbookSelectComponent implements OnDestroy {

  // public activeModulePp: boolean = false;
  // public activeModuleFp: boolean = false;
  public workbookType: string = 'OO';
  public workbookOccasional: string = 'OOS';
  userOffices: EntityOffice[];
  userOffice: EntityOffice;
  defaultAddOffices: { [id: string]: EntityOffice[] } = {};
  offices: { [id: string]: EntityOffice };
  defaultVisibility: EntityDefaultVisibility;
  protected _defaultVisibilitySubscription: Subscription;
  protected _officesSubscription: Subscription;
  protected _userOfficesSubscription: Subscription;
  protected _defaultVisibilityLoaded: boolean = false;
  protected _officesLoaded: boolean = false;
  protected _userOfficesLoaded: boolean = false;

  typeVal: string = 'occasional';
  //duplication
  id: string = '';
  workbook: EntityWorkbook;
  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _defaultVisibilityService: DefaultVisibilityService,
    protected _officeService: OfficeService,
    public dialogRef: MatDialogRef<WorkbookSelectComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
    //,protected _licence: LicencesService
  ) {
    this._spinnerService.show();
    if (data) {
      if (data['typeVal']) {
        this.typeVal = data['typeVal'];
      }
      if (data['id']) {
        this.id = data['id'];
      }
      if (data['workbook']) {
        this.workbook = data['workbook'];
      }
    }
    this.workbookType = (this.typeVal === 'annual') ? 'OP' : (this.typeVal === 'protocol' ? 'PS' : 'OO');
    this._defaultVisibilitySubscription = this._defaultVisibilityService.getSingleOne().subscribe((dv) => {
      if (dv) {
        this.defaultVisibility = dv;
        this._defaultVisibilityLoaded = true;
        this.afterLoad();
      }
    })
    this._officesSubscription = this._officeService.getAll().subscribe((list: EntityOffice[]) => {
      if (list) {
        this._officesLoaded = true;
        this.offices = {};
        const userid = this._officeService.getUserId();
        list.forEach((o) => {
          const _office = this._officeService.getNewEntity(o);
          _office.contacts = o.contacts.filter((c) => (c.user_id === userid || c.isDefault));
          _office.documents = o.documents.filter((e) => (e.isDefault));
          this.offices[o._id] = _office;
        });
        this.defaultAddOffices = {};
        const all = list.filter((e) => (e.defaultAdd && e.defaultAdd.indexOf('all') !== -1));
        if (all.length) {
          list.forEach((e) => {
            this.defaultAddOffices[e._id] = all.filter((x) => (x._id !== e._id)).map((x) => (x));
          });
        }
        list.forEach((e) => {
          if (e.defaultAdd && e.defaultAdd.length) {
            e.defaultAdd.forEach((x) => {
              if (x !== 'all') {
                if (!this.defaultAddOffices[x]) {
                  this.defaultAddOffices[x] = [];
                }
                if (this.defaultAddOffices[x].findIndex((y) => (y._id === e._id)) === -1) {
                  this.defaultAddOffices[x].push(e);
                }
              }
            });
          }
        });
        this.afterLoad();
      }
    });
    this._userOfficesSubscription = this._officeService.getUserContactsOffices().subscribe((list) => {
      if (list) {
        this._userOfficesLoaded = true;
        this.userOffices = list;
        if (this.userOffices.length) {
          this.userOffice = this.userOffices[0];
        }
        this.afterLoad();
      }
    });
  }
  afterLoad() {
    if (this._defaultVisibilityLoaded && this._officesLoaded && this._userOfficesLoaded) {
      if (this.id && this.typeVal && this.userOffices.length < 2) {
        this.duplicateWorkbook();
      }
      this._spinnerService.hide();
    }
  }
  ngOnDestroy() {
    if (this._defaultVisibilitySubscription) {
      this._defaultVisibilitySubscription.unsubscribe();
    }
    if (this._officesSubscription) {
      this._officesSubscription.unsubscribe();
    }
    if (this._userOfficesSubscription) {
      this._userOfficesSubscription.unsubscribe();
    }
  }
  getVisibility() {
    if (this.defaultVisibility) {
      let _default: string;
      let _field: string;
      if (this.workbookType === 'OO') {
        _default = this.defaultVisibility.getVisibility('occasional');
        _field = 'defaultVisibilityOccasional'
      } else if (this.workbookType === 'PS') {
        _default = this.defaultVisibility.getVisibility('protocol');
        _field = 'defaultVisibilityProtocol'
      } else {
        _default = this.defaultVisibility.getVisibility('annual');
        _field = 'defaultVisibilityAnnual'
      }
      if (this.userOffice && this.offices) {
        if (this.userOffice[_field]) {
          if (this.userOffice[_field].length) {
            if (this.userOffice[_field].indexOf('*') !== -1) {
              //tous specifié sur l'office
              return null;
            } else {
              //offices spécifiés
              return this.userOffice[_field];
            }
          } else {
            //aucun specifié sur l'office
            return [];
          }
        } else {
          //comportement par defaut avec office connu
          if (!_default) {
            //aucun
            return [];
          } else if (_default === '*') {
            //tous
            return null;
          } else if (_default === this.userOffice.job) {
            //service exact
            return [this.userOffice._id];
          } else {
            //remonter la hierarchie des services pour trouver le bon
            //office si non trouvé
            let returned: string[] = [this.userOffice._id];
            //aucun si non trouvé
            //let returned:string[]=[];
            let parentId = this.userOffice.main;
            while (parentId) {
              if (this.offices[parentId]) {
                if (this.offices[parentId].job === _default) {
                  returned = [parentId];
                  parentId = '';
                } else {
                  parentId = this.offices[parentId].main;
                }
              } else {
                parentId = '';
              }
            }
            return returned;
          }
        }

      } else {
        //utilisateur non rattaché
        //ne peut gérer que les config tous/aucun
        if (!_default) {
          //aucun
          return [];
        }
        //tous
        return null;

      }
    }
  }
  /*
  ngOnInit() {
    //this.activeModulePp = this._licence.hasValidLicence('pdp');
    //this.activeModuleFp = this._licence.hasValidLicence('fp');
  }
  */
  changeType() {
    this.workbookOccasional = 'OOS';
  }
  create() {
    const options = {
      type: (this.workbookType !== 'OO') ? 'annual' : 'occasional',
      simple: !((this.workbookType === 'OP') || (this.workbookOccasional === 'OOC')),
      userOffice: this.userOffice
    }
    if (this.userOffice && this.defaultAddOffices[this.userOffice._id]) {
      options['defaultAddOffices'] = this.defaultAddOffices[this.userOffice._id];
    }
    if (this.id) {
      this.duplicateWorkbook(options);
    } else {
      this.createWorkbook(options);
    }
  }
  createWorkbook(options = {}) {
    if (this.defaultVisibility) {
      options['offices'] = this.getVisibility();
    }
    this.dialog.open(WorkbookCreateDialogComponent, {
      data: { options: options },
      disableClose: true, minWidth: '600px', width: '1080px', panelClass: 'createPP'
    });
    this.dialogRef.close();
  }
  public duplicateWorkbook(options = {}): void {
    options['offices'] = this.getVisibility();
    this.dialog.open(WorkbookDuplicateDialogComponent, {
      data: { id: this.id, workbook: this.workbook, options: options },
      disableClose: true,
      //minWidth: '50%',
      minWidth: '640px'
      , width: '1080px'
    });
    this.dialogRef.close();
  }

}
