import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntitySituation} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SituationService extends EntitySecureService {
  static LABEL_ONE = "chargement de la situation";
  static LABEL_ALL = "chargement des situations";
  static LABEL_CHILDREN = "chargement des situations de l'opération ?";
  static LABEL_SAVE = "sauvegarde de la situation";
  static LABEL_DELETE = "suppression de la situation";

  static ENTITY_TYPE: string = 'repo_situation';
  static ENTITY_FIELDS: string[] = ['name', 'risk_id', 'sir', 'fp', 'measures'];// description

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showSituation'],
    CREATE: ['createSituation'],
    UPDATE: ['editSituation'],
    DELETE: ['removeSituation']
  }

  static ENTITY_URL = 'repository/pp/situation';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntitySituation {
    return new EntitySituation(data, clone);
  }

}
