import { Component, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  EntityEditDialogComponent,
  LoggerService,
  EmailService,
  DocumentMetadataDialogComponent,
  DocumentMetadataService,
  EmailMessagesService,
  EntityDocumentMetadata
} from 'app/shared';
import {
  WorkbookOfficeService,
  EntityOffice,
  WorkbookExternalCompanyService
} from 'app/workbook-core';

@Component({
  selector: 'app-documentEexMetadata-dialog',
  templateUrl: './documentEexMetadata-dialog.component.html',
  styleUrls: ['../../shared/components/document/documentMetadata-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class DocumentEexMetadataDialogComponent extends DocumentMetadataDialogComponent {
  selectedEex: EntityOffice;
  eexList: EntityOffice[];
  protected _eexSubscription: Subscription
  eexToSave: EntityOffice;
  _parent_id: string = '';
  constructor(
    protected _workbookOfficeService: WorkbookOfficeService,
    protected _workbookExternalCompanyService: WorkbookExternalCompanyService,
    public dialog: MatDialog,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(dialog, _spinnerService, snackBar, dialogRef, data, _emailMessagesService, _emailService, _logger, _entityService);
    if (data) {
      if (data["selectedEex"]) {
        this.selectedEex = data["selectedEex"];
        this.requestList = this.selectedEex.contacts ? this.selectedEex.contacts.filter((e) => (!!e.email)) : [];
        if (this.requestList && this.requestList.length && this.entity && !this.entity._id && this.entity.request && this.entity.request.length) {
          this.requestedList = this.requestList
            .filter((e) => (this.entity.request.indexOf(e.email) !== -1))
            .map((e) => (e.id));
        }
      }
      if (data['eexList']) {
        this.eexList = data['eexList'];
      }
      this.oeexSubscribe();
    }
  }
  oeexSubscribe() {
    if (this._eexSubscription) {
      this._eexSubscription.unsubscribe();
    }
    if (this.selectedEex) {
      if (this.parent_id) {
        this._parent_id = this.parent_id;
        this.parent_id = '';
      }
      if (this.selectedEex.documentType === 'wb_office') {
        this._eexSubscription = this._workbookOfficeService.getOne(this.selectedEex._id).subscribe((eex) => {
          if (eex) {
            this.eexToSave = eex;
          }
        });
      } else {
        this._eexSubscription = this._workbookExternalCompanyService.getOne(this.selectedEex._id).subscribe((eex) => {
          if (eex) {
            this.eexToSave = eex;
          }
        });
      }
    } else {
      if (!this.parent_id && this._parent_id) {
        this.parent_id = this._parent_id;
      }
    }
  }
  changeEex(event) {
    if (this.selectedEex) {
      this.oeexSubscribe();
      this.requestList = this.selectedEex.contacts.filter((e) => (!!e.email));
      this.addOnly = false;
    } else {
      if (this._eexSubscription) {
        this._eexSubscription.unsubscribe();
      }
      this.eexToSave = null;
      this.requestList = [];
      this.addOnly = true;
    }
  }
  protected _beforeSave(entity: EntityDocumentMetadata) {
    let _entity = super._beforeSave(entity);
    if (this.eexToSave && this.selectedEex) {
      this.upsertDocument(this._entityService.beforeSave(entity));
      if (this.selectedEex.documentType === 'wb_office') {
        this._workbookOfficeService.save(this.eexToSave);
      } else {
        this._workbookExternalCompanyService.save(this.eexToSave);
      }

    }
    return _entity;
  }
  upsertDocument(document: EntityDocumentMetadata): void {
    if (!this.eexToSave.documents) {
      this.eexToSave.documents = [];
    }
    if (document.parent_id) {
      document.parent_id = '';
    }
    let index = this.eexToSave.documents.findIndex((d) => (d._id === document._id));
    if (index === -1) {
      this.eexToSave.documents.push(document);
    } else {
      this.eexToSave.documents[index] = document;
    }
  }
}
