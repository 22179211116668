import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared';
import {AbstractWorkbookEditComponent} from 'app/workbook-shared';
import {IcpService, PreventionPlanService} from 'app/workbook-core';
import {WorkbookIcpComponent} from './icp.component';

@Component({
  selector: 'app-prevention-plan-step-edit',
  templateUrl: './icpEdit.component.html'
})

export class PreventionPlanIcpEditComponent extends AbstractWorkbookEditComponent {
  _routeQueryParamsSubscription: Subscription;
  entityForm: WorkbookIcpComponent;
  params = {};
  workbook;
  constructor(
    protected _preventionPlanService: PreventionPlanService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: IcpService

  ) {
    super(_route, _router, _logger, _entityService);
    this._routeQueryParamsSubscription = this._route.queryParams.subscribe(params => {
      this.params = params;
    });

  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (this.parent_id) {
      this._preventionPlanService.getOne(this.parent_id).subscribe((wb) => {
        if (wb) {
          this.workbook = wb;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._routeQueryParamsSubscription) {
      this._routeQueryParamsSubscription.unsubscribe();
    }
  }
  onIcpSave() {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity._deleted) {
      this._preventionPlanService.updateWorkbookInspection(
        this.workbook,
        this.entityForm.entity,
        [... this.entityForm.selectedTemplates, ...this.entityForm.selectedSteps]
      );
      this.backToParent();
    }
  }
  backToParent() {
    this._router.navigate(['../'], {relativeTo: this._route})
  }
}
