import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService} from 'app/shared/logger';
import {EntityFormComponent} from '../entityForm.component';
import {DocumentMetadataService} from 'app/shared/services';
import {DocumentService} from 'app/shared/document';
import {formattedDate} from 'app/shared/utils';
import {EntityDocumentMetadata, DocumentStatus, IDocumentCategory, Contact} from 'app/shared/models';

export abstract class DocumentsCommonComponent extends EntityFormComponent implements OnChanges {
  autoSave = false;
  public entity: EntityDocumentMetadata;
  @Input() categoryList: IDocumentCategory[] = [];
  @Input() context: string = '';
  @Input() multiple: boolean = false;
  showMetas: boolean[] = [];
  showDates: boolean[] = [];

  dateStartTxt: string[] = [];
  dateEndTxt: string[] = [];

  uploads: {file: File, entity: EntityDocumentMetadata}[] = [];
  documentStatus = DocumentStatus.getLabels();
  constructor(
    protected _router: Router,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(dialog, _logger, _entityService);
  }
  getEntityIndex(index: number) {
    return (this.uploads[index] && this.uploads[index].entity) ? this.uploads[index].entity : null;
  }
  updateCategory(index) {
    const e = this.getEntityIndex(index);
    if (e && e.category && this.categoryList && this.categoryList.length) {
      const selectedCategory = this.categoryList.find((c) => (c.name === e.category));
      if (selectedCategory) {
        e.category = selectedCategory.name;
        e.categoryPicto = selectedCategory.picto;
        this.showDates[index] = selectedCategory.limited;
      }
    } else {
      this.showDates[index] = !!(e && e._id && (e.dateStart || e.dateEnd));
    }
  }
  changeDateStartIndex(index: number, startAttr: string = 'dateStart', endAttr: string = 'dateEnd') {
    const e = this.getEntityIndex(index);
    if (e && e[startAttr]) {
      this.dateStartTxt[index] = formattedDate(new Date(e[startAttr]));
    } else {
      this.dateStartTxt[index] = '';
    }
  }
  changeDateEndIndex(index: number, startAttr: string = 'dateStart', endAttr: string = 'dateEnd') {
    const e = this.getEntityIndex(index);
    if (e && e[endAttr]) {
      this.dateEndTxt[index] = formattedDate(new Date(e[endAttr]));
    } else {
      this.dateEndTxt[index] = '';
    }
  }
  updateDateEndIndex(index: number) {
    const e = this.getEntityIndex(index);
    if (e) {
      if (this.dateEndTxt[index]) {
        const d = this.dateEndTxt[index].match('(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[0-9]|1[0-2])\/([1-2][0-9][0-9][0-9])')
        if (d && d.length === 4) {
          e.dateEnd = new Date(+d[3], +d[2] - 1, +d[1]);
        }
      } else {
        e.dateEnd = null;
      }
      setTimeout(() => {
        this.changeDateEndIndex(index);
      });
    }
  }
  updateDateStartIndex(index: number) {
    const e = this.getEntityIndex(index);
    if (e) {
      if (this.dateStartTxt[index]) {
        const d = this.dateStartTxt[index].match('(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[0-9]|1[0-2])\/([1-2][0-9][0-9][0-9])')
        if (d && d.length === 4) {
          e.dateStart = new Date(+d[3], +d[2] - 1, +d[1]);
        }
      } else {
        e.dateStart = null;
      }
      setTimeout(() => {
        this.changeDateStartIndex(index);
      });
    }
  }
}
