import {ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {fromEvent as observableFromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {Entity} from '../models';
import {AbstractEntityListComponent} from './abstractEntityList.component';

export class EntityListComponent extends AbstractEntityListComponent implements AfterViewInit {

  dataSource: MatTableDataSource<Entity> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  filterValue: string = '';
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  displayedColumns = ['name']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, snackBar, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entityList) {
      this.dataSource = this._getDataSource();
    }
  }
  ngAfterViewInit() {
    if (this.filter) {
      observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
        debounceTime(150),
        distinctUntilChanged())
        .subscribe(() => {
          this.applyFilter();
        });
    }
  }
  applyFilter() {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.filter = (this.filter && this.filter.nativeElement && this.filter.nativeElement.value) ? this.filter.nativeElement.value : ' ';
  }
  protected _getDataSource() {
    const ds = new MatTableDataSource(this._getDataSourceContent());

    if (ds) {
      if (this.paginator) {
        ds.paginator = this.paginator;
      }
      if (this.sort) {
        ds.sort = this.sort;
      }
      ds.filterPredicate = this._getDataSourceFilterPredicate();
    }
    ds.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    return ds;
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && (o[p] && (o[p] instanceof Date)) ? o[p].getTime() : ((typeof o[p] === 'string') ? o[p].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : o[p]), obj)
  )

  protected _getDataSourceFilterPredicate() {
    return (data, filter: string) => {
      return (!filter || filter.trim() === '' || JSON.stringify(data).toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1);
    }
  }

  protected _getDataSourceContent() {
    return this.entityList;
  }
  selectElement(element: Entity = null) {
    this._router.navigateByUrl(this._entityService.getUrlEntity(element));
  }
  addElement() {
    this._router.navigateByUrl(this._entityService.getUrlEntity());
  }
}
