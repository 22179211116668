import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService, EmailService, EmailMessagesService } from 'app/shared';
import { PreventionPlanService, CategoryService, SiteService, DocumentationService, OfficeService, ExternalCompanyService } from 'app/workbook-core';
import { WorkbookDocumentListComponent } from 'app/workbook-shared';

@Component({
  selector: 'app-prevention-plan-document-list',
  templateUrl: './documentList.component.html',
  styleUrls: ['./documentList.component.scss']
})

export class PreventionPlanDocumentListComponent extends WorkbookDocumentListComponent {

  constructor(
    protected _officeService: OfficeService,
    protected _externalCompanyService: ExternalCompanyService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _documentationService: DocumentationService,
    protected _siteService: SiteService,
    protected _categoryService: CategoryService,
    //protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_officeService, _externalCompanyService, _emailMessagesService, _emailService, _spinnerService, _documentationService, _siteService, _categoryService, dialog, snackBar, _route, _router, _logger, _entityService);
  }
}
