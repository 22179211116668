import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService, SignableStatus } from 'app/shared';
import { EntitySecurityProtocol, FPState } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SecurityProtocolService extends EntitySignableService {

  entity: EntitySecurityProtocol;
  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement du protocole de sécurité";
  static LABEL_ALL = "chargement des protocoles de sécurité ?";
  static LABEL_CHILDREN = "chargement des protocoles de sécurité de l'opération";
  static LABEL_SAVE = "sauvegarde du protocole de sécurité";
  static LABEL_DELETE = "suppression du protocole de sécurité";

  static ENTITY_TYPE: string = 'sps';
  static ENTITY_FIELDS: string[] = [
    'name',
    'code',
    'editor',
    'editorEmail',
    'editorDate',
    'editedAt',
    'status',
    'dateStart',
    'dateEnd',
    'point',
    'site',
    'step',
    'originator',
    'performer',
    'notifications'
  ];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showSecurityProtocol'],
    CREATE: ['createSecurityProtocol'],
    UPDATE: ['editSecurityProtocol'],
    DELETE: ['removeSecurityProtocol']
  }

  static ENTITY_URL = 'ps';
  static PREFIX = 'PS';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntitySecurityProtocol {
    return new EntitySecurityProtocol(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.editorDate).getTime() === new Date(b.editorDate).getTime())
        ? 0
        : (new Date(a.editorDate).getTime() > new Date(b.editorDate).getTime()) ? -1 : 1)));

  }

  getState(entity: EntitySecurityProtocol, workbook_status) {
    let state = (workbook_status && workbook_status === 'archived') ? FPState.ARCHIVED : FPState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === SignableStatus.VALIDATED) {
        state = FPState.PREPARED;
      } else if (entity.status === SignableStatus.SIGNING) {
        state = FPState.SIGNING;
      } else if (entity.status === SignableStatus.SIGNED) {
        state = FPState.VALID;
      } else if (entity.status === SignableStatus.OUTDATED) {
        state = FPState.OUTDATED;
      }

    }
    return state;
  }
}
