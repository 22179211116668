import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySingleService, LoadingService, EntityAgreement} from 'app/shared';

@Injectable({
  providedIn: 'root'
})
export class PreventionPlanAgreementService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement des dispositions légales";
  static LABEL_ALL = "chargement des dispositions légales ?";
  static LABEL_CHILDREN = "chargement dispositions légales de l'opération ?";
  static LABEL_SAVE = "sauvegarde des dispositions légales";
  static LABEL_DELETE = "suppression des dispositions légales";


  static ENTITY_TYPE: string = 'repo_pp_agreement';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showAgreements', 'showPPAgreement'],
    CREATE: ['showAgreements', 'createPPAgreement'],
    UPDATE: ['showAgreements', 'editPPAgreement'],
    DELETE: []
  }

  static ENTITY_URL = 'repository/pp/agreement';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityAgreement {
    return new EntityAgreement(data, clone);
  }

}
