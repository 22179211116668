import {Component, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';


import {
  promiseAll,
  comparableString, LoggerService,
  ConfirmDialogComponent,
  Operator, Contact,
  storageGet, storageSet, formattedDate, ExcelService, deepCompare
} from 'app/shared';
import {
  UserSyncComponent,
  PreventionPlanService, EntityWorkbook,
  IcpRecurrenceService,
  OfficeService, ExternalCompanyService, DocumentationService, SiteService, WorkbookOfficeService, WorkbookExternalCompanyService
} from 'app/workbook-core';
import {WorkbookSelectComponent} from 'app/workbook-shared';
import {WorkbooksCommonComponent, Todo, Event} from './workbooksCommon.component';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbooks',
  templateUrl: './workbooks.component.html',
  styleUrls: ['./workbooks.component.scss']
})

export class WorkbooksComponent extends WorkbooksCommonComponent {
  @Output() filterChange = new EventEmitter();
  displayedColumns = ['selector', 'dateStart', 'sync', /*'type',*/ 'site.name', 'description', 'editor', 'eex_info', 'eex_name', 'name', 'status', 'icpstates', 'pdpstates', 'actions' /*'archive', 'duplicate', 'view', 'rm'*/];
  _displayedColumns = {
    annual: ['selector', 'dateStart', 'sync', 'site.name', 'description', 'editor', 'eex_info', 'eex_name', 'name', 'status', 'icpstates', 'pdpstates', 'actions'],
    occasional: ['selector', 'dateStart', 'sync', 'site.name', 'description', 'editor', 'eex_info', 'eex_name', 'name', 'status', 'icpstates', 'pdpstates', 'actions'],
    protocol: ['selector', 'dateStart', 'sync', 'site.name', 'description', 'editor', 'eex_name', 'name', 'status', 'actions'],
    archived: ['selector', 'dateStart', 'sync', 'site.name', 'description', 'editor', 'eex_info', 'eex_name', 'name', 'status', 'actions']
  }
  _rmConfirmMessage = {
    annual: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce contrat ?',
    occasional: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer cette opération ?',
    protocol: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce protocole ?',
    archived: 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce document ?'
  }
  // wbDataSource: WorkbooksDataSource | null;
  rmConfirmMessage = 'Attention, cette action est irréversible, êtes-vous sûr de vouloir supprimer ce document ?';
  //annual/occasional/protocol
  typeVal: string = 'occasional';
  typeSimple: boolean = true;
  neutralColor: string = '#C4C4C4';
  // displayArchiveChecked: boolean = false;
  /** Based on the screen size, switch from standard to one column per row */
  cards = this._breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({matches}) => {
      if (matches) {
        return [
          {title: 'Card 1', cols: 1, rows: 1},
          {title: 'Card 2', cols: 1, rows: 1},
          {title: 'Card 3', cols: 1, rows: 1}
        ];
      }

      return [
        {title: 'Card 1', cols: 1, rows: 1},
        {title: 'Card 2', cols: 1, rows: 1},
        {title: 'Card 3', cols: 1, rows: 1},
      ];
    })
  );

  wbProgress: number = 0;
  wbPrepared: number = 0;
  wbClosed: number = 0;
  statusFilter;
  selectedStatus: string[];
  selectedTodo: number = null;
  selectedUpcomingStep: number = null;
  withProtocols: boolean = false;
  // documentsFiltered: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  SCOPEUSER: number;
  SCOPEOWNER: number;
  SCOPEOFFICE: number;
  SCOPEALL: number;
  SCOPESYNC: number;
  //scopeFilter: { [typeVal: string]: number }
  scope: number;
  TABTODO: number;
  TABINPROGRESS: number;
  TABUPCOMING: number;
  tab: number;


  todoInspectionCount: {[state: string]: number} = {};
  todoPdpCount: {[state: string]: number} = {};

  todoFiltered: Todo[] = [];
  inprogressFiltered: Todo[] = [];
  upcomingFiltered: Event[] = [];
  //showUpcoming: boolean = false;
  checkall: boolean = false;
  checked: EntityWorkbook[] = [];



  workbooksEexInfos: {
    [wb_id: string]: {
      cell: string,
      infos: {
        name: string,
        principal?: boolean,
        operators: MatTableDataSource<Operator>,
        contacts: MatTableDataSource<Contact>
      }[]
    }
  } = {};
  showWbEexsInfo: {[wb_id: string]: boolean} = {};
  displayedOperatorsColumns: string[] = ['name', 'extra'];

  showWbEexsAttribut: {[wb_id: string]: boolean} = {};
  showWbEuAttribut: {[wb_id: string]: boolean} = {};
  showEexOperatorsC: {[eex_name: string]: boolean} = {};
  showEexOperatorsO: {[eex_name: string]: boolean} = {};

  showEexOperators: {[eex_name: string]: boolean} = {};

  showPdpInfo: {[eex_name: string]: boolean} = {};
  showIcpInfo: {[eex_name: string]: boolean} = {};
  showIcpIntervention: {[eex_name: string]: boolean} = {};
  showWbUsers: {[wb_id: string]: boolean} = {};
  displayedContactsColumns: string[] = ['name', 'email'];
  showOfficeContacts: {[eex_name: string]: boolean} = {};
  migrate_workbooks_3_15_message: string = '';

  constructor(
    protected _excelService: ExcelService,
    //preload
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    //private _auth: AuthService,
    protected _cd: ChangeDetectorRef,
    protected _externalCompanyService: ExternalCompanyService,
    protected _officeService: OfficeService,
    protected _siteService: SiteService,
    protected _icpRecurrenceService: IcpRecurrenceService,
    private _breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService//WorkbookService
  ) {
    super(_cd, _externalCompanyService, _officeService, _siteService, _icpRecurrenceService, dialog, snackBar, _router, _logger, _entityService);
    this.SCOPEUSER = -1;
    this.SCOPEOWNER = 0;
    this.SCOPEOFFICE = 1;
    this.SCOPEALL = 2;
    this.SCOPESYNC = 3;

    this.TABTODO = 0;
    this.TABINPROGRESS = 1;
    this.TABUPCOMING = 2;
    //  this.displayArchiveChecked = !!storageGet('displayArchive');

    const configWbType = storageGet('config-wbType');
    if (configWbType) {
      this.typeVal = configWbType;
    } else {
      storageSet('config-wbType', this.typeVal);
    }
    const configWbTab = storageGet('config-wbTab');
    if (configWbTab) {
      this.tab = configWbTab;
    } else {
      this.tab = 0;
    }
    const configWbScope = storageGet('config-wbScope');
    if (configWbScope || configWbScope === 0) {
      this.scope = configWbScope;
    } else {
      this.scope = this.SCOPEOFFICE;
    }
    const configWbPageSize = storageGet('config-wbPageSize');
    if (configWbPageSize) {
      this.pageSize = configWbPageSize;
    }
    /*
    if (storageGet('config-wbScopeFilter')) {
      this.scopeFilter = storageGet('config-wbScopeFilter');
    } else {
      this.scopeFilter = {
        annual: this.SCOPEOFFICE,
        occasional: this.SCOPEOWNER,
        protocol: this.SCOPEOFFICE
      };
    }
    this.scope = this.scopeFilter[this.typeVal];
    */
    const configWbStatusFilter = storageGet('config-wbStatusFilter');
    if (configWbStatusFilter) {
      this.statusFilter = configWbStatusFilter;
      this.selectedStatus = Object.keys(this.statusFilter).filter((e) => (e !== 'all' && this.statusFilter[e]));
    } else {
      this.statusFilter = {all: true};
      this.selectedStatus = [];
    }
  }

  postInit() {
    if (!this.postInitialized) {
      this._documentationService.getAll();
      super.postInit();
    }
  }
  applyFilter() {
    super.applyFilter();
    this.checkall = false;
    this._countWorkbooks();
    this.filterTodoEvent();

    this._cd.markForCheck();
  }
  isSyncChild(id: string) {
    return this._entityService.isInSync(id) || this.isWbUserSelected(id);
  }
  isOnlineReadable(id: string) {
    return (id && !(this._entityService.isOffline() && !this._entityService.isSynced(id)));
  }
  isSyncable(id: string) {
    return (id && !this._entityService.isOffline() && this._entityService.isOutOfSync(id));
  }
  isChecked(id) {
    return this.checked.findIndex((e) => (e._id === id)) !== -1;
  }
  addSelectedWorkbooks() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir synchroniser les dossiers séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Valider';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        //this._spinnerService.show();
        const toAdd = this.checked
          .filter((wb) => (!this.isWbUserSelected(wb._id) && !this.isSyncChild(wb._id)))
          .map((wb) => (wb._id));
        if (toAdd.length > 0) {
          this._entityService.addUserWorkbooks(toAdd).then((res) => {
            //this.wbUserSelected = [...this.wbUserSelected, ...toAdd];
            //storageSet('config-wbUserSelected', this.wbUserSelected);
            //this._spinnerService.hide();
          }).catch((err) => {
            //this._spinnerService.hide();
          });
          this.checkall = false;
          this.checked = [];
          this.applyFilter();
        }
      }
    });
  }
  removeSelectedWorkbooks() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir désynchroniser les dossiers séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Valider';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        //this._spinnerService.show();
        const toRemove = this.checked
          .filter((wb) => (this.isWbUserSelected(wb._id)))
          .map((wb) => (wb._id));
        if (toRemove.length > 0) {
          this._entityService.removeUserWorkbooks(toRemove).then((res) => {
            //this.wbUserSelected = this.wbUserSelected.filter((id) => (toRemove.indexOf(id) === -1));
            //storageSet('config-wbUserSelected', this.wbUserSelected);
            // this._spinnerService.hide();
          }).catch((err) => {
            //this._spinnerService.hide();
          });
          this.checkall = false;
          this.checked = [];
          this.applyFilter();
        }
      }
    });
  }
  hasUserSyncable() {
    return this.hasPermission('syncAllDocuments') && this.checked.findIndex((wb) => (!this.isSynced(wb._id) && this.isSyncable(wb._id))) !== -1;
  }
  hasUserUnsyncable() {
    return this.hasPermission('syncAllDocuments') && this.checked.findIndex((wb) => (this.isWbUserSelected(wb._id))) !== -1;
  }
  hasArchivable() {
    return this.checked.findIndex((wb) => (this.isCompleted(wb))) !== -1;
  }
  hasUnarchivable() {
    return this.checked.findIndex((wb) => (this.isArchived(wb))) !== -1;
  }
  hasDeletable() {
    return this.checked.findIndex((wb) => (this.isCompleted(wb))) !== -1;
  }
  changeCheck(event) {
    if (event && event.source && event.source.value) {
      this.checkall = false;
      if (event.checked) {
        if (this.checked.findIndex((e) => (event.source.value._id && e._id === event.source.value._id)) === -1) {
          this.checked.push(event.source.value);
        }
      } else {
        const i = this.checked.findIndex((e) => (event.source.value._id && e._id === event.source.value._id));
        if (i !== -1) {
          this.checked.splice(i, 1);
        }
      }
    }
  }
  changeCheckall(event) {
    if (event) {
      if (event.checked) {
        this.checked = this.dataSource.filteredData;
      } else {
        this.checked = [];
      }
    }
  }

  _init(data) {
    super._init(data);
    this.updateWorbooksInfos();
    this.removeUselessUserWb();
    this.displayedColumns = this._displayedColumns[this.typeVal];
    this.rmConfirmMessage = this._rmConfirmMessage[this.typeVal];
    this.applyFilter();
    setTimeout(() => {
      this._countWorkbooks();
    });
  }
  updateIcpRecurrence() {
    super.updateIcpRecurrence();
    this._countWorkbooks();
  }
  updateWorbooksInfos() {
    if (this.entityList) {
      this.workbooksEexInfos = {};
      this.entityList.forEach((wb) => {
        this.workbooksEexInfos[wb._id] = {
          cell: this.getEexsCell(wb),
          infos: this.getEexsInfo(wb)
        }
      });
    }
  }
  removeUselessUserWb() {
    this.entityList
      .filter((wb: EntityWorkbook) => (this.isWbUserSelected(wb._id)))
      .forEach((wb: EntityWorkbook) => {
        if ((wb.users && wb.users.length && wb.users.indexOf(this.userId) !== -1) || !wb.offices || (wb.offices.length && this._entityService.matchingOffice(wb.offices))) {
          this._entityService.removeUserSelectedWorkbook(wb._id);
        }
      });
  }
  getShown(data): number[] {
    const shown: number[] = (this.userId === data.editorId)
      ? [this.SCOPEUSER, this.SCOPEOWNER]
      : ((data.users && data.users.indexOf(this.userId) !== -1)
        ? [this.SCOPEOWNER]
        : []);
    if (
      //visibilité tous
      !data.offices
      //matching offices
      || (data.offices.length && this.matchingUserContactsOffices(data.offices))
    ) {
      shown.push(this.SCOPEOFFICE);
    }

    if (data._id && this.isSyncChild(data._id)) {
      shown.push(this.SCOPESYNC);
    }
    return shown;
  }
  protected _getDataSourceFilterPredicate() {
    return (data: EntityWorkbook, filter: string) => {
      const _filterValue = comparableString(filter);
      return (((this.typeVal === 'archived')
        ? (data.status === EntityWorkbook.STATUS_ARCHIVE)
        : (
          (data.status !== EntityWorkbook.STATUS_ARCHIVE
            && (this.statusFilter['all'] || this.statusFilter[EntityWorkbook.STATUS_CODE[data.getStatus()]]))
          &&
          ((this.typeVal === 'protocol')
            ? (data.type === 'annual' && data.simple)
            : ((this.typeVal === 'annual')
              ? (data.type === 'annual' && !data.simple)
              : (data.type === this.typeVal)))))
        //        && (this.displayArchiveChecked || data.status !== EntityWorkbook.STATUS_ARCHIVE)
        && (
          this.scope === this.SCOPEALL
          ||
          ((this.scope === this.SCOPEUSER || this.scope === this.SCOPEOWNER)
            && (this.userId === data.editorId))
          ||
          ((this.scope === this.SCOPEOWNER)
            && (data.users && data.users.indexOf(this.userId) !== -1))
          ||
          ((this.scope === this.SCOPEOFFICE)
            && (
              //visibilité tous
              (!data.offices
                //matching offices
                || (data.offices.length && this.matchingUserContactsOffices(data.offices)))
            )
          )
          ||
          ((this.scope === this.SCOPESYNC)
            && (data._id && this.isSyncChild(data._id)))
        )
        && (!filter || filter.trim() === ''
          || (data.name && comparableString(data.name).indexOf(_filterValue) !== -1)
          || (data.point && comparableString(data.point).indexOf(_filterValue) !== -1)
          || (data.description && comparableString(data.description).indexOf(_filterValue) !== -1)
          || (data.eex_name && comparableString(data.eex_name).indexOf(_filterValue) !== -1)
          || (data.eexs && Object.keys(data.eexs).filter((e) => (comparableString(e).indexOf(_filterValue) !== -1)).length > 0)
          || (data.editor && comparableString(data.editor).indexOf(_filterValue) !== -1)
          || (data.reference && comparableString(data.reference).indexOf(_filterValue) !== -1)
          || (data.dateStart && formattedDate(data.dateStart).indexOf(filter.trim()) !== -1)
          || (data.status && data.status.toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1)
          || (data.site && data.site.name && comparableString(data.site.name).indexOf(_filterValue) !== -1)
          || (data.operators && data.operators.filter((o) => (comparableString(o.name + o.email).indexOf(_filterValue) !== -1)).length > 0)
        ));
    }
  }
  filterTodoEvent() {
    this.todoInspectionCount = {};
    this.todoPdpCount = {};
    this.todoFiltered = this.todoList.filter((e) => {
      if (this.isTodoEventVisible(e)) {
        if (e.state && e.state.name) {
          if (e.audit || !!e.icp_id || e.icp_id === '') {
            if (this.todoInspectionCount[e.state.name]) {
              this.todoInspectionCount[e.state.name]++;
            } else {
              this.todoInspectionCount[e.state.name] = 1;
            }
          } else if (!!e.pdp_id || e.pdp_id === '') {
            if (this.todoPdpCount[e.state.name]) {
              this.todoPdpCount[e.state.name]++;
            } else {
              this.todoPdpCount[e.state.name] = 1;
            }
          }
        }
        return true;
      }
      return false;
    });
    this.inprogressFiltered = this.inprogressList.filter((e) => (this.isTodoEventVisible(e)));
    this.upcomingFiltered = this.upcomingList.filter((e) => (this.isTodoEventVisible(e)));
  }
  isTodoEventVisible(element: Todo | Event) {
    const _filterValue = comparableString(this.filterValue);
    return ((this.scope === this.SCOPEALL || element.shown.indexOf(this.scope) !== -1)
      && (this.statusFilter['all'] || this.statusFilter[EntityWorkbook.STATUS_CODE[element.wb.getStatus()]])
      && (!this.filterValue || this.filterValue.trim() === ''
        || (element.wb.name && comparableString(element.wb.name).indexOf(_filterValue) !== -1)
        || (element.wb.point && comparableString(element.wb.point).indexOf(_filterValue) !== -1)
        || (element.wb.description && comparableString(element.wb.description).indexOf(_filterValue) !== -1)
        || (element.wb.eex_name && comparableString(element.wb.eex_name).indexOf(_filterValue) !== -1)
        || (element.wb.eexs && Object.keys(element.wb.eexs).filter((e) => (comparableString(e).indexOf(_filterValue) !== -1)).length > 0)
        || (element.wb.editor && comparableString(element.wb.editor).indexOf(_filterValue) !== -1)
        || (element.wb.reference && comparableString(element.wb.reference).indexOf(_filterValue) !== -1)
        || (element.wb.dateStart && formattedDate(element.wb.dateStart).indexOf(this.filterValue.trim()) !== -1)
        || (element.wb.status && comparableString(element.wb.status).indexOf(_filterValue) !== -1)
        || (element.wb.site && element.wb.site.name && comparableString(element.wb.site.name).indexOf(_filterValue) !== -1)
        || (element.wb.operators && element.wb.operators.filter((o) => (comparableString(o.name + o.email).indexOf(_filterValue) !== -1)).length > 0)
        || (element.text && comparableString(element.text).indexOf(_filterValue) !== -1)
        || (element.step_name && comparableString(element.step_name).indexOf(_filterValue) !== -1)
        || (element.state && comparableString(element.state.name && element.state.name).indexOf(_filterValue) !== -1)
      ));
  }

  /**
   *  change tab
   * @param event
   */
  public changeType(newVal: string = 'occasional') {
    if (newVal) {
      this.typeVal = newVal;
      //this.scope = this.scopeFilter[this.typeVal];
      this.displayedColumns = this._displayedColumns[this.typeVal]
      this.rmConfirmMessage = this._rmConfirmMessage[this.typeVal]
      storageSet('config-wbType', this.typeVal);
      this.checkall = false;
      this.checked = [];
      //this.statusFilter={all:true};
      this.applyFilter();
    }
  }
  /**
   *  change tab
   * @param event
   */
  public changeScope() {
    /*
    this.scopeFilter[this.typeVal] = this.scope;
    storageSet('config-wbScopeFilter', this.scopeFilter);
    */
    storageSet('config-wbScope', this.scope);
    this.applyFilter();
  }
  public onPageSizeChange(event) {
    if (event && event.pageSize) {
      storageSet('config-wbPageSize', event.pageSize);
    }
  }
  /**
   * For each pdp status count number of pdp
   * @private
   */
  protected _countWorkbooks() {
    this.withProtocols = this.entityList.findIndex(wb => (wb.type === 'annual' && wb.simple)) !== -1;
    this.wbPrepared = this.entityList.filter(wb => wb.getStatus() === EntityWorkbook.STATUS_DRAFT && this.filterWorkbookType(wb)
      && ((this.scope !== this.SCOPEOWNER)
        || (wb.users && wb.users.indexOf(this.userId) !== -1))
      && ((this.scope !== this.SCOPEOFFICE)
        || ((wb.users && wb.users.indexOf(this.userId) !== -1)
          || (!wb.offices)
          || (wb.offices && wb.offices.length && this.matchingUserContactsOffices(wb.offices))))).length
    this.wbProgress = this.entityList.filter(wb => wb.getStatus() === EntityWorkbook.STATUS_PROGRESS && this.filterWorkbookType(wb)
      && ((this.scope !== this.SCOPEOWNER)
        || (wb.users && wb.users.indexOf(this.userId) !== -1))
      && ((this.scope !== this.SCOPEOFFICE)
        || ((wb.users && wb.users.indexOf(this.userId) !== -1)
          || (!wb.offices)
          || (wb.offices && wb.offices.length && this.matchingUserContactsOffices(wb.offices))))).length
    this.wbClosed = this.entityList.filter(wb => wb.getStatus() === EntityWorkbook.STATUS_COMPLETE && this.filterWorkbookType(wb)
      && ((this.scope !== this.SCOPEOWNER)
        || (wb.users && wb.users.indexOf(this.userId) !== -1))
      && ((this.scope !== this.SCOPEOFFICE)
        || ((wb.users && wb.users.indexOf(this.userId) !== -1)
          || (!wb.offices)
          || (wb.offices && wb.offices.length && this.matchingUserContactsOffices(wb.offices))))).length
  }
  protected filterWorkbookType(wb: EntityWorkbook) {
    return (this.typeVal === 'occasional' && wb.type === 'occasional')
      || (this.typeVal === 'annual' && wb.type === 'annual' && !wb.simple)
      || (this.typeVal === 'protocol' && wb.type === 'annual' && wb.simple);
  }
  addSelectedWorkbook(document: EntityWorkbook, open: boolean = true) {
    if (this.isOnlineReadable(document._id)) {
      if (open) {
        if (this._entityService.isSynced(document._id)) {
          this._router.navigate(this.dynamicRoute(document));
        } else {
          this._spinnerService.show();
          this._entityService.loadChildrenRemote(document._id).then((res) => {
            //console.log('addSelectedWorkbook loadChildrenRemote', res);
            setTimeout(() => {
              this._spinnerService.hide();
              //console.log('addSelectedWorkbook loadChildrenRemote navigate');
              this._router.navigate(this.dynamicRoute(document));
            }, 250);

          }).catch((err) => {
            this._spinnerService.hide();
            //TODO Erreur / offline
          });
        }
      } else if (this.hasPermission('syncAllDocuments')) {
        const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          minWidth: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Souhaitez-vous synchroniser le contenu cette opération ?';
        dialogRefDel.componentInstance.cancelMessage = 'Non';
        dialogRefDel.componentInstance.buttonMessage = 'Oui';
        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this._entityService.addUserWorkbook(document._id).then((res) => {
              //fin de resynchro
            });
            /*
          this._spinnerService.show();
          this._entityService.loadChildrenRemote(document._id).then((res) => {
            this._spinnerService.hide();
          }).catch((err) => {
            this._spinnerService.hide();
            //TODO Erreur / offline
          });
          */
          }

        });
      }
    }
  }
  removeSelectedWorkbook(document: EntityWorkbook) {
    if (this.isWbUserSelected(document._id)) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        minWidth: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = 'Souhaitez-vous désynchroniser cette opération ?';
      dialogRefDel.componentInstance.cancelMessage = 'Non';
      dialogRefDel.componentInstance.buttonMessage = 'Oui';
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          //this._spinnerService.show();
          //const i = this.wbUserSelected.indexOf(document._id);
          //if (i !== -1) {
          //  this.wbUserSelected.splice(i, 1);
          //  storageSet('config-wbUserSelected', this.wbUserSelected);
          this._entityService.removeUserWorkbook(document._id).then((res) => {
            //this._spinnerService.hide();
          });
          //}
        }
      });
    }
  }
  isWbUserSelected(id) {
    return this._entityService.userWorkbooks && this._entityService.userWorkbooks.indexOf(id) !== -1;
  }

  public dynamicRoute(document) {
    return ['/' + this._entityService.getUrlWorkbook(document.documentType, document._id)];
  }

  public stepRoute(document, step_id: string) {
    if (document.simple) {
      return this.dynamicRoute(document);
    } else {
      return ['/' + this._entityService.getUrlWorkbookStep(document.documentType, document._id, step_id)];
    }
  }

  public icpRoute(document, icp_id: string) {
    return ['/' + this._entityService.getUrlIcp(document.documentType, document._id, icp_id)];
  }
  public pdpsRoute(document) {
    return ['/' + this._entityService.getUrlWorkbook(document.documentType, document._id, 'pdp')];
  }
  public auditsRoute(document) {
    return ['/' + this._entityService.getUrlWorkbook(document.documentType, document._id, 'vc')];
  }

  /**
   * open dialog to display pp in html
   * @param event
   * @param pp_id
   */
  public exportWorkbook(document: EntityWorkbook) {

    this._router.navigateByUrl(this._entityService.getUrlWorkbook(document.documentType, document._id, 'pdp'));
    /*
      // const dialogRef: MatDialogRef<WorkbookExportDialogComponent> =
      this.dialog.open(WorkbookExportDialogComponent, {
        disableClose: false,
        width: '890px',
        height: '90%',
        data: {id: document._id}
      });
      */
  }

  /**
   * Open dialog popup for create pdp
   */
  selectDocument() {
    this.dialog.open(WorkbookSelectComponent, {
      minWidth: '500px',
      autoFocus: false,
      panelClass: 'createPP',
      data: {typeVal: this.typeVal}
    });
  }
  /**
   * Duplicate pp object
   * @param event
   * @param pp_id
   */
  public _openDuplicateWorkbook(workbook: EntityWorkbook): void {
    this.dialog.open(WorkbookSelectComponent, {
      minWidth: '500px',
      autoFocus: false,
      panelClass: 'createPP',
      data: {id: workbook._id, workbook: workbook, typeVal: this.typeVal}
    });
  }
  public duplicateWorkbook(workbook: EntityWorkbook): void {
    if (!this._entityService.isSynced(workbook._id)) {
      if (!this._entityService.isOffline()) {
        this._spinnerService.show();
        this._entityService.loadChildrenRemote(workbook._id).then((res) => {
          setTimeout(() => {
            this._spinnerService.hide();
            this._openDuplicateWorkbook(workbook);
          }, 250);

        }).catch((err) => {
          this._spinnerService.hide();
          this._logger.error('(WorkbooksComponent) loadChildrenRemote (duplicateWorkbook)', 'unable to refreshOne after error', JSON.stringify(err));
        });
      } else {
        //offline
        this._logger.warn('(WorkbooksComponent) duplicateWorkbook', 'offline call');

      }
    } else {
      this._openDuplicateWorkbook(workbook);
    }
  }

  /**
   * Archive pp object
   * @param event
   * @param pp_id
   * @param unarchive
   */
  public archiveWorkbook(id: string, unarchive: boolean) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    let newStatus = EntityWorkbook.STATUS_ARCHIVE;
    if (unarchive) {
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir désarchiver ce document ?';
      dialogRefDel.componentInstance.buttonMessage = 'Désarchiver';
      newStatus = '';
    } else {
      dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir archiver ce document ?';
      dialogRefDel.componentInstance.buttonMessage = 'Archiver';
    }
    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        this._entityService.changeStatus(id, newStatus).then((wb) => {
          // console.log('after change status', wb);
          this.updateElement(wb);
          this._spinnerService.hide();
        }).catch((err) => {
          this._spinnerService.hide();
        });
      }
    });
  }


  /**
   * Open dialog popup for create pdp
   */
  editUserSync() {
    if (this._entityService.hasPermission('showSyncFilter')) {
      this._entityService.renewToken();
      const dialogRef = this.dialog.open(UserSyncComponent, {
        minWidth: '640px',
        autoFocus: false,
        panelClass: 'createPP'
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && this._entityService.hasPermission('editSyncFilter')) {
          this._entityService.setUserOffices(c);
          this._entityService.renewToken();
          //this._entityService.refreshProfile().then((profil) => {
          this._init(this.entityList);
          //});
        }
      });
    }
  }
  archiveAll() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir archiver les dossiers séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Archiver';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        this._entityService.pauseReplication();
        const all = this.checked
          .filter((wb) => (this.isCompleted(wb)))
          .map((wb) => (this._entityService.changeStatus(wb._id, EntityWorkbook.STATUS_ARCHIVE)));

        promiseAll(all).then((res) => {
          this.checkall = false;
          this.checked = [];
          this._spinnerService.hide();
          this._entityService.unpauseReplication();
        },
          (err) => {
            //TODO: errormessage?
            this._spinnerService.hide();
            this._entityService.unpauseReplication();
          });


      }
    });
  }

  unarchiveAll() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir désarchiver les dossiers séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Désarchiver';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        this._entityService.pauseReplication();
        const all: Promise<any>[] = this.checked
          .filter((wb) => (this.isArchived(wb)))
          .map((wb) => {
            return this._entityService.changeStatus(wb._id, '').then((wb) => {
              // console.log('after change status', wb);
              this.updateElement(wb);
            })
          });
        promiseAll(all).then((e) => {
          this.checkall = false;
          this.checked = [];
          this._spinnerService.hide();
          this._entityService.unpauseReplication();
        },
          (err) => {
            //TODO: errormessage?
            this._spinnerService.hide();
            this._entityService.unpauseReplication();
          });


      }
    });
  }
  deleteAll() {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      minWidth: '600px',
    });
    dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer les dossiers séléctionnés ?';
    dialogRefDel.componentInstance.buttonMessage = 'Supprimer';

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._spinnerService.show();
        this._entityService.pauseReplication();
        const all: Promise<any>[] = this.checked
          .filter((wb) => (this.isCompleted(wb)))
          .map((wb) => {
            return this._entityService.remove(wb).then((wb) => {
              // console.log('after change status', wb);
              //this.updateElement(wb);
            })
          });
        promiseAll(all).then((e) => {
          this.checkall = false;
          this.checked = [];
          this._spinnerService.hide();
          this._entityService.unpauseReplication();
        },
          (err) => {
            //TODO: errormessage?
            this._spinnerService.hide();
            this._entityService.unpauseReplication();
          });


      }
    });
  }
  editSelected() {
    if (this.checked && this.checked[0]) {
      this._router.navigateByUrl('/' + this._entityService.getUrlWorkbook('pp', this.checked[0]._id));
    }
  }
  duplicateSelected() {
    if (this.checked && this.checked[0]) {
      this.duplicateWorkbook(this.checked[0]);
    }

  }

  protected _beforeRemoveElement(entity) {
    super._beforeRemoveElement(entity);
    this._spinnerService.show();
  }

  protected _afterRemoveElement(entity) {
    super._afterRemoveElement(entity);
    this._spinnerService.hide();
  }
  changeStatusFilter() {
    this.statusFilter = {
      prepared: (this.selectedStatus.indexOf('prepared') !== -1),
      progress: (this.selectedStatus.indexOf('progress') !== -1),
      done: (this.selectedStatus.indexOf('done') !== -1),
      all: !this.selectedStatus.length
    }
    if (this.statusFilter['prepared'] && this.statusFilter['progress'] && this.statusFilter['done']) {
      this.statusFilter['all'] === true;
    }
    storageSet('config-wbStatusFilter', this.statusFilter);
    this.applyFilter();
  }
  setTab(t: number) {
    const tab = t ? t : 0;
    storageSet('config-wbTab', tab);
    setTimeout(() => {this.tab = tab;});
    this.selectedTodo = null;
    this.selectedUpcomingStep = null;
  }
  selectTodo(i: number = null) {
    this.selectedTodo = i;
    this.selectedUpcomingStep = null;
  }
  selectUpcomingStep(i: number = null) {
    this.selectedTodo = null;
    this.selectedUpcomingStep = i;
  }
  getStatusFilterText() {
    const returned: string[] = [];
    if (this.selectedStatus && this.selectedStatus.indexOf('prepared') !== -1) {
      returned.push(this.wbPrepared + ' dossier' + (((0 + this.wbPrepared) > 1) ? 's' : '') + ' en préparation');
    }
    if (this.selectedStatus && this.selectedStatus.indexOf('progress') !== -1) {
      returned.push(this.wbProgress + ' dossier' + (((0 + this.wbProgress) > 1) ? 's' : '') + ' en cours');
    }
    if (this.selectedStatus && this.selectedStatus.indexOf('done') !== -1) {
      returned.push(this.wbClosed + ' dossier' + (((0 + this.wbClosed) > 1) ? 's' : '') + ' terminés');
    }
    return returned.join(', ');
  }

  exportToExcel() {
    this._excelService.exportAsExcelFile({
      worbooks: this.getExport(this.entityList)
    }, 'export');
  }
  getExport(list): any[] {
    return this.entityList ? this.entityList.map((wb: EntityWorkbook) => {
      let infosPdP = '';
      let firstPdP = '';
      let firstPdPStatut: number = 0;
      let firstPdPDate: Date = null;
      if (wb.pdps) {
        Object.keys(wb.pdps).forEach((step_id) => {
          if (wb.pdps[step_id].editions && Object.keys(wb.pdps[step_id].editions).length) {
            infosPdP += (wb.pdps[step_id].template ? 'Chantier type: ' : '') + wb.pdps[step_id].name;
            Object.keys(wb.pdps[step_id].editions).forEach((id) => {
              infosPdP += '\n' + (wb.pdps[step_id].editions[id].name ? wb.pdps[step_id].editions[id].name + ' - ' : '') + formattedDate(wb.pdps[step_id].editions[id].date) + ' (' + this.getPdpStatusLabel(wb.pdps[step_id].editions[id].status) + ')';
              const d = new Date(wb.pdps[step_id].editions[id].date);
              if (wb.pdps[step_id].editions[id].status > 1 && (!firstPdPDate || firstPdPDate.getTime() > d.getTime())) {
                firstPdPDate = d;
                firstPdP = (wb.pdps[step_id].editions[id].name ? wb.pdps[step_id].editions[id].name : '');
                firstPdPStatut = wb.pdps[step_id].editions[id].status;
              }
            });
          }
        });
      }
      return {
        id: wb._id,
        status: wb.status,
        type: ((wb.type === 'occasional') ? (wb.simple ? 'Opération simple' : 'Opération') : (wb.simple ? 'Protocole' : 'Contrat')),
        code: wb.name,
        name: wb.description,
        reference: wb.reference,
        entreprise: wb.eex_name,
        responsable: wb.editor + ' (' + wb.editorEmail + ')',
        dateStart: formattedDate(wb.dateStart),
        dateEnd: formattedDate(wb.dateEnd),
        hourStart: wb.hourStart,
        hourEnd: wb.hourEnd,
        zone: wb.point,
        site: (wb.site ? wb.site.name : ''),
        address: (wb.site ? wb.site.address : ''),
        zipCode: (wb.site ? wb.site.zipCode : ''),
        city: (wb.site ? wb.site.city : ''),
        firstPdP: firstPdP,
        firstPdPStatut: firstPdPStatut ? this.getPdpStatusLabel(firstPdPStatut) : '',
        firstPdPDate: formattedDate(firstPdPDate),
        PdPs: infosPdP
      };
    }) : [];
  }

  showHideWbEexsInfo(id: string) {
    if (this.showWbEexsInfo[id]) {
      delete this.showWbEexsInfo[id];
    } else {
      this.showWbEexsInfo = {};
      this.showWbEexsInfo[id] = true;
    }
  }
  /*
  //z = premier caractère du workbook_id pour pouvoir passer la migration en 10 fois
  migrate_workbooks_3_15(z = '') {
    this._entityService.pauseReplication();
    let i = 0;
    let u = 0;
    let n = 0;
    let x = 0;
    this.migrate_workbooks_3_15_message = 'Chargement des intervenants...';
    this._entityService.getAllChildrenOEexList().toPromise().then((list) => {
      if (list) {

        this.migrate_workbooks_3_15_message += '<br/>' + list.length + ' intervenants chargés';
        const oeexs = {};
        list.forEach((e) => {
          if (!oeexs[e.parent_id]) {
            oeexs[e.parent_id] = [];
          }
          oeexs[e.parent_id].push(e);
        });
        const promises = [];
        this.entityList
          .filter((e: EntityWorkbook) => (e.status !== 'Archivé' && (!z || e._id[0] === z)))
          .forEach(async (e) => {
            i++;
            if (oeexs[e._id]) {
              const p = this.updateWorkbook_3_15(e, oeexs[e._id], i);
              if (p) {
                promises.push(p);
                u++;
              } else {
                n++;
              }
            } else {
              x++;
              this.migrate_workbooks_3_15_message += '<br/>' + e.name + ' sans oeexs';
            }
          });

        if (u) {
          this.migrate_workbooks_3_15_message += '<br/>' + u + '/' + this.entityList.length + ' à mettre à jour';
        }
        if (n) {
          this.migrate_workbooks_3_15_message += '<br/>' + n + '/' + this.entityList.length + ' à jour';
        }
        if (x) {
          this.migrate_workbooks_3_15_message += '<br/>' + x + '/' + this.entityList.length + ' sans oeexs';
        }

        promiseAll(promises, 1).then((all) => {
          this._entityService.unpauseReplication();
        });
      }
    });
  }

    async updateWorkbook_3_15(wb, oeexs, i) {
      const list: string[] = [];
      const operatorList: Operator[] = [];
      const eexs: {[eex_name: string]: string} = {};
      const office_contacts: {[oeex_name: string]: Contact[]} = {};
      const eex_contacts: {[oeex_name: string]: Contact[]} = {};
      if (wb && wb.createdId) {
        list.push(wb.createdId);
      }
      oeexs
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((o) => {
          if (o.contacts) {
            if (o.documentType === WorkbookOfficeService.ENTITY_TYPE) {
              office_contacts[o.name] = [];
              o.contacts
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach((c) => {
                  if (c.user_id && list.indexOf(c.user_id) === -1) {
                    list.push(c.user_id);
                  }
                  office_contacts[o.name].push(Object.assign(new Contact(), c));
                });
            } else {
              eexs[o.name] = o.repo_id;
              if (o.operators) {
                o.operators.forEach((operator) => {
                  operatorList.push(operator);
                });
              }
              eex_contacts[o.name] = [];
              o.contacts
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach((c) => {
                  if (c.user_id && list.indexOf(c.user_id) === -1) {
                    list.push(c.user_id);
                  }
                  eex_contacts[o.name].push(Object.assign(new Contact(), c));
                });
            }
          }
        });

      if (
        (JSON.stringify(wb.users.sort()) !== JSON.stringify(list.sort()))
        || (JSON.stringify(wb.eex_contacts) !== JSON.stringify(eex_contacts))
        || (JSON.stringify(wb.office_contacts) !== JSON.stringify(office_contacts))
        || (JSON.stringify(wb.operators.map((e) => (e.id)).sort()) !== JSON.stringify(list.sort()))
        || !wb.eexs
        || (Object.keys(wb.eexs).length !== Object.keys(eexs).length)
        || (Object.keys(wb.eexs).filter((e) => (wb.eexs[e] !== eexs[e])).length > 0)
      ) {
        return await this._entityService.getCurrentOne(wb._id).then(async (_wb) => {
          _wb.users = list;
          _wb.eex_contacts = eex_contacts;
          _wb.office_contacts = office_contacts;
          _wb.eexs = eexs;
          _wb.operators = operatorList;
          return this._entityService.save(_wb).then((s) => {
            this.migrate_workbooks_3_15_message += '<br/>' + i + '/' + this.entityList.length + ' ' + _wb.name + ' saved (' + s['id'] + '=>' + s['rev'] + ')';
            return s;
          });
        });
      } else {
        this.migrate_workbooks_3_15_message += '<br/>' + i + '/' + this.entityList.length + ' ' + wb.name + ' saved (' + wb['_id'] + '=>' + wb['_rev'] + ')';
        return null;
      }
    }
  */
}
