import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntitySignature} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SignatureService extends EntitySecureService {

  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement de la signature";
  static LABEL_ALL = "chargement des signatures";
  static LABEL_CHILDREN = "chargement des signatures de l'opération";
  static LABEL_SAVE = "sauvegarde de la signature";
  static LABEL_DELETE = "suppression de la signature";

  static ENTITY_TYPE: string = 'signature';
  static ENTITY_FIELDS: string[] = ['user_id', 'name', 'email', 'job', 'company', 'companyType', 'contact_id', 'company_id', 'data', 'display'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showPP'],
    CREATE: ['editPP'],
    UPDATE: ['editPP'],
    DELETE: []
  }

  static ENTITY_URL = 'signature';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntitySignature {
    return new EntitySignature(data, clone);
  }

}
