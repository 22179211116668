import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, ConfirmDialogComponent, SignableStatus } from 'app/shared';
import { WorkbookSignableListComponent, FirePermitInfoDialogComponent, FirePermitDialogComponent } from 'app/workbook-shared';
import {
  PreventionPlanService,
  EntityPreventionPlan,
  FirePermitService,
  EntityFirePermit, FPState,
  EntityStep, DocumentationService
} from 'app/workbook-core';

@Component({
  selector: 'app-signable-firepermit-list',
  templateUrl: './firePermitList.component.html',
  styleUrls: ['./firePermitList.component.scss']
})

export class FirePermitListComponent extends WorkbookSignableListComponent {

  entity: EntityPreventionPlan;
  entityList: EntityFirePermit[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette édition du plan de prévention ?';
  addConfirmMessage = 'Nouvelle édition du plan de prévention créée';

  stepList: { [step_id: string]: EntityStep } = {};
  protected _stepsSubscription: Subscription;
  states: { [icp_id: string]: number } = {};

  dataSource: MatTableDataSource<EntityFirePermit> = new MatTableDataSource([]);
  displayedColumns = ['name', 'editor', 'step', 'description', 'dateStart', 'status', 'actions']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';


  statusLabels = FPState.getLabels();
  statusDescriptions = FPState.getDescriptions();

  constructor(
    //preload
    protected _documentationService: DocumentationService,
    protected _childService: FirePermitService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list) => {
      if (list) {
        this.stepList = {};
        list
          .forEach((step) => {
            this.stepList[step._id] = step;
          });
      }
    });
    if (this.entityList) {
      this.refreshDataSource();
    }
  }

  refreshStates() {
    if (this.entityList && this.entity) {
      this.states = {};
      this.entityList.forEach((fp) => {
        this.states[fp._id] = this._getChildService().getState(fp, this.entity.status);
      });
    }
  }


  getState(fp: EntityFirePermit) {
    return fp && fp._id && this.states[fp._id] ? this.states[fp._id] : 0;

  }
  getLabelState(fp: EntityFirePermit) {
    return FPState.getLabel(this.getState(fp));

  }
  getDescriptionState(fp: EntityFirePermit) {
    return FPState.getDescription(this.getState(fp));

  }

  protected _getChildService() {
    return this._entityService.firePermitService;
  }

  /**
   * Create dialog popup inspection
   */
  public selectSignable(firePermit: EntityFirePermit = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(FirePermitInfoDialogComponent, {
      disableClose: true,
      width: '920px',
      maxWidth: '90vw',
      data: {
        id: firePermit ? firePermit._id : '',
        entity: (firePermit && !firePermit._id) ? firePermit : null,
        implementation: (firePermit && !firePermit._id && this.stepList && firePermit.step
          && this.stepList[firePermit.step] && this.stepList[firePermit.step].implementation) ? this.stepList[firePermit.step].implementation : null,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }
  public openDialog(firePermit = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(FirePermitDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        id: firePermit ? firePermit._id : '',
        entity: firePermit,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }

  public duplicateFirePermit(element) {
    this._getChildService().getCurrentOne(element._id).then((e) => {
      const newElement: EntityFirePermit = this._getChildService().getNewEntity(e, true);
      newElement.status = 0;
      newElement.signatures = [];
      newElement.dateStart = new Date();
      newElement.dateEnd = new Date();
      newElement.hourStart = '';
      newElement.hourEnd = '';
      newElement.content = '';
      newElement.createdAt = null;
      newElement.createdId = '';
      newElement.createdName = '';

      newElement.editor = '';
      newElement.editorEmail = '';
      this.selectSignable(newElement);
    });
  }

  outdateSignable(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = "Confirmer";
    dialogRefDel.componentInstance.confirmMessage = "Voulez-vous rendre cette édition obsolète ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().get(entity._id).toPromise().then((signable: EntityFirePermit) => {
          if (signable) {
            signable.status = SignableStatus.OUTDATED;
            this._getChildService().save(signable).then((e) => {
              this.updateElement(signable);
            });
          }
        });
      }
    });
  }
}
