import {Input, Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {UUID} from 'angular2-uuid';
import {promiseAll, LoggerService, PermissionsService, EntityFormComponent, DocumentService, IDocumentCategory, normalyzeFilename} from 'app/shared';
import {DocumentsRequestService, EntityDocumentsRequest} from 'app/workbook-core';

@Component({
  selector: 'app-repository-documentsRequest',
  templateUrl: './documentsRequest.component.html',
  styleUrls: ['./documentsRequest.component.scss']
})
export class DocumentsRequestComponent extends EntityFormComponent {
  autoSave: true;
  entity: EntityDocumentsRequest;
  @Input() categoryList: IDocumentCategory[] = [];
  showDocumentsRequest: boolean = true;
  showDocuments: boolean[] = [];

  templates: File[] = [];

  constructor(
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: DocumentsRequestService
  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    this.showDocuments = [];
    if (this.entity && this.entity.documents && this.entity.documents.length) {
      this.showDocuments = this.entity.documents.map((e) => (false));
      this.templates = this.entity.documents.map((e) => (null));
    }

  }

  protected _save() {
    if (this.templates.filter((f) => (!!f)).length > 0) {
      const templatePomises = this.templates.map((file) => (
        file ? this.saveTemplate(file) : new Promise((resolve) => {resolve(false);})
      ));

      return promiseAll(templatePomises)
        .then((result) => {
          this.entity.documents.forEach((d, index) => {
            if (result[index]) {
              d.template_id = result[index]._id;
              this.templates[index] = null;
            }
          });
        }).then(() => (super._save()));
    } else {
      return super._save();
    }
  }
  removeDocumentMetadata(index) {
    if (this.entity.documents && this.entity.documents.length > index) {
      this.entity.documents.splice(index, 1);
      if (this.showDocuments && this.showDocuments.length > index) {
        this.showDocuments.splice(index, 1);
        this.templates.splice(index, 1);
      }
      this._save();
    }
  }

  addDocumentMetadata() {
    if (this.entity.documents) {
      this.entity.documents.push(this._documentService.getNewDocumentMetada({_id: UUID.UUID(), name: 'Nouveau document demandé'}));
      if (this.showDocuments) {
        this.showDocuments.push(true);
        this.templates.push(null);
      }
      this._save();
    }
  }

  templateRemoveEvent(index: number) {
    if (this.entity.documents && this.entity.documents[index] && this.entity.documents[index].template_name) {
      this.templates[index] = null;
      this.entity.documents[index].template_id = '';
      this.entity.documents[index].template_name = '';
      this._save();
    }

  }
  templateChangeEvent(index: number, event) {
    if (event && event.addedFiles && event.addedFiles[0] && this.templates) {
      this.templates[index] = new File([event.addedFiles[0]], event.addedFiles[0].name, {type: event.addedFiles[0].type});
      this.entity.documents[index].template_id = '';
      this.entity.documents[index].template_name = this.templates[index].name;

      this._save();
    }

  }
  saveTemplate(file: File) {
    return this._documentService.uploadFile(file, file.name).toPromise();
  }
}
