import {AfterViewInit, ChangeDetectorRef, Directive, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

// DEPRECIATED CLASS

@Directive({
  selector: '[appDisableArchive]'
})
export class DisableArchiveDirective implements AfterViewInit  {

  status: boolean = false;

  @Input() set appDisableArchive( status: boolean ) {
    this.status = status;
  }

  constructor(private ngControl: NgControl, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (!this.status) {
      this.ngControl.control['enable']();
    } else {
      this.ngControl.control['disable']();
    }
    this.cdRef.detectChanges();
  }
}
