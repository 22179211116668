import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import {FormsModule} from '@angular/forms';
import {
  SharedModule,
  PermissionsService,
  DbFilteredService,
  ExploitingCompanyService,
  EmailMessagesService
} from 'app/shared';
import {
  // WorkbookCoreModule,
  SiteService,
  OfficeService,
  ExternalCompanyService,
  JobService,
  OrganizationService,
  CategoryService,
  RiskService,
  MeasureService,
  SituationService,
  LdcmService,
  // PreventionPlanService,
  PreventionPlanAgreementService,
  FirePermitContentService,
  PermitContentService,
  ProtocolContentService,
  IcpRecurrenceService,
  DefaultVisibilityService,
  DocumentationService,
  EmergencyInstructionsService,
  AuditCheckpointsService,
  DocumentsRequestService
} from 'app/workbook-core';
import { RepositoryRoutingModule } from './repository-routing.module';
import { RepositorySidenavComponent } from './sidenav';
import { RepositoryContactDialogComponent } from './contact-dialog';
import { RepositoryOperatorDialogComponent } from './operator-dialog';
import { SiteListComponent, SiteEditComponent, SiteDialogComponent, SiteComponent, RepoZoneDialogComponent } from './site';
import { OfficeListComponent, OfficeEditComponent, OfficeDialogComponent, OfficeComponent, OfficeUsersDialogComponent, OfficeUsersComponent } from './office';
import { ExternalCompanyListComponent, ExternalCompanyEditComponent, ExternalCompanyDialogComponent, ExternalCompanyComponent } from './externalCompany';
import { JobListComponent, JobComponent } from './job';
import { OrganizationListComponent, OrganizationComponent } from './organization';
import { CategoryListComponent, CategoryComponent } from './category';
import { LdcmListComponent, LdcmComponent } from './ldcm';
import { RiskListComponent, RiskComponent } from './prevention-plan/risk';
import { MeasureListComponent, MeasureComponent } from './prevention-plan/measure';
import { SituationListComponent, SituationComponent } from './prevention-plan/situation';
import { PreventionPlanAgreementEditComponent, PreventionPlanAgreementComponent } from './prevention-plan/preventionPlanAgreement';
import { FirePermitContentComponent, FirePermitContentEditComponent } from './firePermitContent';
import { PermitContentComponent, PermitContentEditComponent, PermitContentListComponent, PermitContentDialogComponent } from './permitContent';
import { ProtocolContentComponent, ProtocolContentEditComponent } from './protocolContent';
import { IcpRecurrenceComponent, IcpRecurrenceEditComponent } from './icpRecurrence';
import { DashboardComponent, DashboardEditComponent } from './dashboard';
import { DefaultVisibilityComponent, DefaultVisibilityEditComponent } from './defautVisibility';
import { DocumentationListComponent, DocumentationComponent } from './documentation';
import { EmergencyInstructionsComponent, EmergencyInstructionsEditComponent } from './emergencyInstructions';
import { AuditCheckpointsComponent, AuditCheckpointsEditComponent } from './auditCheckpoints';
import { DocumentsRequestDialogComponent, DocumentsUploadDialogComponent } from './document';
import { DocumentsRequestComponent, DocumentsRequestListComponent } from './documentsRequest';

@NgModule({
  imports: [
    CommonModule,
    //   FormsModule,
    SharedModule,
    //WorkbookCoreModule,
    RepositoryRoutingModule
  ],
  declarations: [
    EmergencyInstructionsComponent, EmergencyInstructionsEditComponent,
    AuditCheckpointsComponent, AuditCheckpointsEditComponent,
    RepositorySidenavComponent,
    JobListComponent, JobComponent,
    OrganizationListComponent, OrganizationComponent,
    CategoryListComponent, CategoryComponent,
    RiskListComponent, RiskComponent,
    MeasureListComponent, MeasureComponent,
    SituationListComponent, SituationComponent,
    LdcmListComponent, LdcmComponent,
    SiteListComponent, SiteEditComponent, SiteDialogComponent, SiteComponent, RepoZoneDialogComponent,
    OfficeListComponent, OfficeEditComponent, OfficeDialogComponent, OfficeComponent, OfficeUsersDialogComponent, OfficeUsersComponent,
    ExternalCompanyListComponent, ExternalCompanyEditComponent, ExternalCompanyDialogComponent, ExternalCompanyComponent,
    PreventionPlanAgreementEditComponent, PreventionPlanAgreementComponent,
    RepositoryContactDialogComponent, RepositoryOperatorDialogComponent,
    FirePermitContentComponent, FirePermitContentEditComponent,
    PermitContentComponent, PermitContentEditComponent, PermitContentListComponent, PermitContentDialogComponent,
    ProtocolContentComponent, ProtocolContentEditComponent,
    IcpRecurrenceComponent, IcpRecurrenceEditComponent,
    DashboardComponent, DashboardEditComponent,
    DefaultVisibilityComponent, DefaultVisibilityEditComponent,
    DocumentationListComponent, DocumentationComponent,
    DocumentsRequestDialogComponent, DocumentsUploadDialogComponent,
    DocumentsRequestComponent, DocumentsRequestListComponent
  ],
  entryComponents: [
    RepositoryContactDialogComponent, RepositoryOperatorDialogComponent,
    SiteDialogComponent, RepoZoneDialogComponent,
    OfficeDialogComponent, OfficeUsersDialogComponent,
    DocumentsRequestDialogComponent, DocumentsUploadDialogComponent,
    ExternalCompanyDialogComponent, PermitContentDialogComponent
  ],
  providers: [
    PermissionsService,
    DbFilteredService,
    JobService,
    OrganizationService,
    CategoryService,
    RiskService,
    MeasureService,
    SituationService,
    LdcmService,
    ExploitingCompanyService,
    SiteService,
    OfficeService,
    ExternalCompanyService,
    EmailMessagesService,
    PreventionPlanAgreementService,
    FirePermitContentService,
    PermitContentService,
    ProtocolContentService,
    IcpRecurrenceService,
    DefaultVisibilityService,
    DocumentationService,
    EmergencyInstructionsService,
    AuditCheckpointsService,
    DocumentsRequestService
    //    OfficeResolver,
    //    OfficeListResolver
  ]
})
export class RepositoryModule {
}
