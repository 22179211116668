import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, EntityFormComponent} from 'app/shared';
import {AuditCheckpointsService, EntityAuditCheckpoints, Checkpoint} from 'app/workbook-core';

@Component({
  selector: 'app-repository-auditCheckpoints',
  templateUrl: './auditCheckpoints.component.html',
  styleUrls: ['./auditCheckpoints.component.scss']
})

export class AuditCheckpointsComponent extends EntityFormComponent {
  entity: EntityAuditCheckpoints;
  newTitle: string = '';
  newDefault: boolean = false
  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: AuditCheckpointsService
  ) {
    super(dialog, _logger, _entityService);

  }
  protected _init(data) {
    super._init(data);
    this._initNew();
  }
  protected _initNew() {
    this.newTitle = '';
    this.newDefault = false
  }

  toggleEnabledInstructions(event) {
    this.entity.enabledInstructions = (event && !!event.checked);
    this._save().then((e) => {this._afterSave(e);});
  }
  toggleEnabled(event) {
    this.entity.enabled = (event && !!event.checked);
    this._save().then((e) => {this._afterSave(e);});
  }
  toggleRequireCheckpointLimit(event) {
    this.entity.requireCheckpointLimit = (event && !!event.checked);
    this._save().then((e) => {this._afterSave(e);});
  }
  toggleRequireCheckpointResponsable(event) {
    this.entity.requireCheckpointResponsable = (event && !!event.checked);
    this._save().then((e) => {this._afterSave(e);});
  }
  toggleDefault(event) {
    this.newDefault = (event && !!event.checked);
  }

  addCheckpoint() {
    if (this.rightU && this.entity && this.newTitle) {
      if (!this.entity.checkpoints) {
        this.entity.checkpoints = [];
      }
      this.entity.checkpoints.push(new Checkpoint({title: this.newTitle, default: !!this.newDefault}));
      this._save().then((e) => {this._afterSave(e); this._initNew();});
      this._initNew();
    }
  }
  removeCheckpoint(index: number) {
    if (this.rightU && this.entity && this.entity.checkpoints && this.entity.checkpoints.length > index) {
      this.entity.checkpoints.splice(index, 1);
      this._save().then((e) => {this._afterSave(e); this._initNew();});
    };
  }
}