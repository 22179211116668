import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {PermissionsService, LoggerService, SidenavComponent, LicencesService} from 'app/shared';

@Component({
  selector: 'app-repository-sidenav',
  templateUrl: '../../shared/components/sidenav/sidenav.component.html',
  styleUrls: ['../../shared/components/sidenav/sidenav.component.scss']
})
export class RepositorySidenavComponent extends SidenavComponent implements OnInit {
  name = 'sidenavrepo';
  title = "PARAMETRES DOCUMENTS";
  public generalSelected: boolean = false;
  public ppSelected: boolean = false;
  public fpSelected: boolean = false;
  public activeModulePp: boolean = false;
  public activeModuleFp: boolean = false;

  constructor(
    protected _permissionsService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _licence: LicencesService) {
    super(_permissionsService, _router, _route, _logger);
  }

  _init() {
    super._init();
    this.activeModulePp = this._licence.hasValidLicence('pdp');
    this.activeModuleFp = this._licence.hasValidLicence('fp');
    // To detect the route and expand right part
    if (this._route.snapshot.url[1]) {
      const path = this._route.snapshot.url[1].path;
      switch (path) {
        case 'pp':
          this.ppSelected = true;
          break;
        case 'fp':
          this.fpSelected = true;
          break;
        default:
          this.generalSelected = true;
          break;
      }
    }

  }

}
