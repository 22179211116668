import { EntityCompanyDocumentable, EntityMap } from 'app/shared';
import { UUID } from 'angular2-uuid';
import { EntityZone } from './entityZone.model';
import { EntityRisk } from './entityRisk.model';
import { EntitySituation } from './entitySituation.model';
import { EntityMeasure } from './entityMeasure.model';

export class EntitySite extends EntityCompanyDocumentable {

  public phone: string;
  public maps: EntityMap[];
  public zones: EntityZone[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.phone = (data && data.phone) ? data.phone : '';
    this.maps = (data && data.maps) ? data.maps.map((e) => new EntityMap(e)) : [];
    this.zones = (data && data.zones) ? data.zones.map((e) => new EntityZone(e)) : [];
  }
}