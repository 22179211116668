import {Component, Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService} from 'app/shared';
import {
  SecurityProtocolService, ProtocolContentService,
  EntityPreventionPlan,
  EntitySecurityProtocol, EntityProtocolContent,
  WorkbookStepService,
  EntityStep,
  EntitySite,
  Implementation,
  Activity, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableInfoDialogComponent} from '../abstractSignableInfo-dialog.component';
import {SecurityProtocolInfoComponent} from './securityProtocolInfo.component'

@Component({
  selector: 'app-signable-securityProtocol-info-dialog',
  templateUrl: './securityProtocolInfo-dialog.component.html',
  styleUrls: ['./securityProtocolInfo-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class SecurityProtocolInfoDialogComponent extends AbstractSignableInfoDialogComponent implements AfterViewInit {
  entityForm: SecurityProtocolInfoComponent;
  entity: EntitySecurityProtocol;
  workbook: EntityPreventionPlan;
  implementation: Implementation;
  selectedStep: string;
  activities: Activity[];

  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  stepList: {[step_id: string]: EntityStep} = {};
  protected _stepsSubscription: Subscription;

  protected _protocolContentSubscription: Subscription;
  protocolContent: EntityProtocolContent;

  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _protocolContentService: ProtocolContentService,
    protected _stepService: WorkbookStepService,
    public dialogRef: MatDialogRef<SecurityProtocolInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: SecurityProtocolService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        if (data['implementation']) {
          this.implementation = data['implementation'];
        }

      }
      if (!this.id) {
        if (this.implementation && this.implementation.step) {
          this.selectedStep = this.implementation.step._id;
          this.selectStep();
        } else if (this.workbook && this.workbook.simple) {
          this.selectStep();
        }
        this._protocolContentSubscription = this._protocolContentService.getSingleOne().subscribe((fpc: EntityProtocolContent) => {
          if (fpc) {
            this.protocolContent = fpc;
          }
          this.updateProtocolContent();
        });
      }

      this._stepsSubscription = this._stepService.getChildren(this.parent_id).subscribe((list) => {
        if (list) {
          this.stepList = {};
          let _templates = [];
          let _steps = [];
          list
            .forEach((e) => {
              this.stepList[e._id] = e;
              if (e.template) {
                _templates.push(e);
              } else {
                _steps.push(e);
              }
            });
          this.templates = _templates
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
          this.steps = _steps
            .sort((a, b) =>
              (this.workbook && this.workbook.type === 'annual') ?
                ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime())
                : ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
            );

          this.selectStep();
        }
      });
    }

  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.selectStep();
    this.updateProtocolContent();

  }
  ngOnDestroy() {
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._protocolContentSubscription) {
      this._protocolContentSubscription.unsubscribe();
    }
  }
  updateProtocolContent() {
    if (this.protocolContent && this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.title = this.protocolContent.title;
      this.entityForm.entity.summary = this.protocolContent.summary;

      this.protocolContent.warningDevices.forEach((e) => {
        if (this.entityForm.entity.warningDevices.indexOf(e) === -1) {
          this.entityForm.entity.warningDevices.push(e);
        }
      });
      this.protocolContent.interventionDevices.forEach((e) => {
        if (this.entityForm.entity.interventionDevices.indexOf(e) === -1) {
          this.entityForm.entity.interventionDevices.push(e);
        }
      });
      this.protocolContent.emergency.forEach((e) => {
        if (this.entityForm.entity.emergency.indexOf(e) === -1) {
          this.entityForm.entity.emergency.push(e);
        }
      });

      this.protocolContent.protectiveGears.forEach((e) => {
        if (this.entityForm.entity.protectiveGears.findIndex((x) => (x.name === e.name)) === -1) {
          this.entityForm.entity.protectiveGears.push(Object.assign({}, e));
        }
      });
      this.protocolContent.productFeatures.forEach((e) => {
        if (this.entityForm.entity.productFeatures.findIndex((x) => (x.name === e.name)) === -1) {
          this.entityForm.entity.productFeatures.push(Object.assign({}, e));
        }
      });

      this.protocolContent.merchandiseNatures.forEach((e) => {
        if (!this.entityForm.entity.merchandiseNatures[e]) {
          this.entityForm.entity.merchandiseNatures[e] = false;
        }
      });
      this.protocolContent.merchandiseStates.forEach((e) => {
        if (!this.entityForm.entity.merchandiseStates[e]) {
          this.entityForm.entity.merchandiseStates[e] = false;
        }
      });
      this.protocolContent.merchandisePackagings.forEach((e) => {
        if (!this.entityForm.entity.merchandisePackagings[e]) {
          this.entityForm.entity.merchandisePackagings[e] = false;
        }
      });

      this.protocolContent.vehicles.forEach((e) => {
        if (!this.entityForm.entity.vehicles[e]) {
          this.entityForm.entity.vehicles[e] = false;
        }
      });
      this.protocolContent.vehicleTypes.forEach((e) => {
        if (!this.entityForm.entity.vehicleTypes[e]) {
          this.entityForm.entity.vehicleTypes[e] = false;
        }
      });
      this.protocolContent.equipments.forEach((e) => {
        if (!this.entityForm.entity.equipments[e]) {
          this.entityForm.entity.equipments[e] = false;
        }
      });
      this.protocolContent.providedEquipments.forEach((e) => {
        if (!this.entityForm.entity.providedEquipments[e]) {
          this.entityForm.entity.providedEquipments[e] = false;
        }
      });
      this.protocolContent.interdictions.forEach((e) => {
        if (!this.entityForm.entity.interdictions[e]) {
          this.entityForm.entity.interdictions[e] = false;
        }
      });
      this.protocolContent.instructions.forEach((e) => {
        if (!this.entityForm.entity.instructions[e]) {
          this.entityForm.entity.instructions[e] = false;
        }
      });
      this.protocolContent.originatorInstructions.forEach((e) => {
        if (!this.entityForm.entity.originatorInstructions[e]) {
          this.entityForm.entity.originatorInstructions[e] = false;
        }
      });
    }
  }
  selectStep(clearStep: boolean = false) {
    if (this.workbook && this.workbook.simple) {
      if (this.steps && this.steps.length) {
        this.selectedStep = this.steps[0]._id;
      } else if (this.templates && this.templates.length) {
        this.selectedStep = this.templates[0]._id;
      }
    }
    if (this.entityForm && this.entityForm.entity && this.selectedStep && this.stepList[this.selectedStep]) {
      if (clearStep) {
        this.entityForm.entity.step = '';
      } else {
        this.entityForm.entity.step = this.selectedStep;
      }
      if (this.stepList[this.selectedStep]) {
        if (this.stepList[this.selectedStep].implementation) {
          this.implementation = this.stepList[this.selectedStep].implementation
        } else {
          this.implementation = Implementation.generate(this.stepList[this.selectedStep]);
        }
      }
      if (this.implementation) {
        if (this.implementation.step.site) {
          this.entityForm.entity.site = new EntitySite(this.implementation.step.site);
        }
        this.entityForm.entity.point = this.implementation.step.point ? this.implementation.step.point : '';
        this.entityForm.entity.dateStart = this.implementation.step.dateStart ? new Date(this.implementation.step.dateStart) : null;
        this.entityForm.entity.dateEnd = this.implementation.step.dateEnd ? new Date(this.implementation.step.dateEnd) : null;
        this.entityForm.entity.hourStart = this.implementation.step.hourStart ? this.implementation.step.hourStart : '';
        this.entityForm.entity.hourEnd = this.implementation.step.hourEnd ? this.implementation.step.hourEnd : '';

      }

    }
  }
  save() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.entity._id) {
      if (this.userProfile) {
        this.entityForm.entity.editor = this._entityService.getUserName();
        this.entityForm.entity.editorEmail = this.userProfile.email;
        this.entityForm.entity.editorDate = new Date();
      }
      if (this.workbook && this.workbook._id) {
        this._entityService.getNewCode(this.workbook._id).then((code) => {
          this.entityForm.entity.parent_code = this.workbook.code;
          this.entityForm.entity.code = code;
          this.entityForm.entity.name = this.entityForm.entity.getName();
          super.save();
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      }
    } else {
      super.save();
    }
  }
}
