import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoadingService} from '../../services/loading.service';

export abstract class AbstractErrorComponent implements OnDestroy {

  _loadingSubsciption: Subscription;
  loading: boolean = true;
  msgList: string[] = [];

  constructor(protected _loadingService: LoadingService) {

    this._loadingSubsciption = this._loadingService.loading.subscribe((loading) => {
      this.loading = !loading || (Object.keys(loading).length > 0);
      if (loading) {
        this.msgList = Object.keys(loading).map((k) => loading[k]);

      }
    });
  }

  ngOnDestroy() {
    if (this._loadingSubsciption) {
      this._loadingSubsciption.unsubscribe();
    }
  }
}
