import { Component, Input } from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import { EntityExternalCompany } from '../../repository/models/entityExternalCompany.model';
import { EntityActivity } from '../models/entityActivity.model';


@Component({
  selector: 'app-workbook-externalCompanies-export',
  templateUrl: './externalCompaniesExport.component.html'
})
export class ExternalCompaniesExportComponent extends CommonComponent {

  @Input() externalCompanies: EntityExternalCompany[] = [];
  @Input() sir: { [oeex_id: string]: EntityActivity[] } = {};
  @Input() riskSituationName: { [situation_id: string]: string } = {};
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

}
