import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatAutocompleteSelectedEvent, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { WorkbookStepService, EntitySite, EntityZone } from 'app/workbook-core';
import { LoggerService, AbstractEntityServiceComponent, comparableString } from 'app/shared';


@Component({
  selector: 'app-zone-dialog',
  templateUrl: './zone-dialog.component.html',
  styleUrls: ['./zone-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class ZoneDialogComponent extends AbstractEntityServiceComponent implements OnDestroy {

  filteredZones: EntityZone[];
  public newZone: boolean;
  public allZones: boolean;
  public allowAllZones: boolean;
  public allowedZones: EntityZone[];
  public zone: EntityZone;
  public site: EntitySite;
  public zoneNames: string[];
  constructor(
    public dialogRef: MatDialogRef<ZoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(_logger, _entityService);
    this.newZone = !(data && data.zone);
    this.site = (data && data.site);
    this.zone = new EntityZone((data && data.zone) ? data.zone : null);
    this.zoneNames = (data && data.site && data.site.zones) ? data.site.zones.map((e) => (e.name)).filter((e) => ((!e.name && this.zone && !this.zone.name) || (e.name && this.zone && this.zone.name && e.name !== this.zone.name))) : [];
    this.allowAllZones = (data && data.allowAllZones);
    this.allZones = (data && data.allowAllZones && data.zone && data.zone.name === '');
    this.allowedZones = (data && data.allowedZones && data.allowedZones.length)
      ? data.allowedZones.map((e) => (new EntityZone(e)))
      : [];
    this.refreshFilteredZones();
  }
  public refreshFilteredZones(modified: boolean = false) {
    if (modified && this.zone && this.zone.repo_id) {
      this.zone.repo_id = '';
    }
    this.filteredZones = this.getFilteredZones();
  }
  protected getFilteredZones(): EntityZone[] {
    let allowedZones: EntityZone[] = [];
    if (this.allowedZones && this.allowedZones.length && this.site && this.site.repo_id) {
      let selectedZones: string[] = this.site.zones.map((e) => (e.repo_id)).filter((e) => (!!e));
      allowedZones = ((this.allowedZones && this.allowedZones.length && this.zone)
        ? this.allowedZones.filter((zone) => (comparableString(zone.name).indexOf(comparableString(this.zone.name)) !== -1))
        : this.allowedZones).filter((e) => (e._id === this.zone.repo_id || selectedZones.indexOf(e._id) === -1));
    }
    return (allowedZones.length > 200 ? [] : allowedZones);
  }
  zoneSelected(event, zone: EntityZone) {
    const repo_id = zone._id
    this.zone = new EntityZone(zone, true);
    this.zone.repo_id = repo_id;
    this.allZones = (!this.zone.name && this.allowAllZones);
    this.refreshFilteredZones();
  }
  changeAllZone(event) {
    if (event && event.checked) {
      this.zone.name = '';
      this.refreshFilteredZones();
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.zone);
    }
  }

}
