import {EntityMeasure} from '../../repository/models/entityMeasure.model';

export class RiskEntry {
  name: string;
  requiresSIR: boolean;
  requiresFirePermit: boolean;
  measures: EntityWorkbookMeasure[];
  withPrior: boolean;
  withAfter: boolean;
  constructor(
    name: string = '',
    measures: EntityWorkbookMeasure[] = []
    , sir: boolean = false,
    fp: boolean = false
  ) {
    this.name = name;
    this.requiresSIR = sir;
    this.requiresFirePermit = fp;
    this.measures = measures;
    this.withPrior = measures.findIndex((e) => (e.prior)) !== -1;
    this.withAfter = measures.findIndex((e) => (e.after)) !== -1;
  }
}
export class EntityWorkbookMeasure extends EntityMeasure {


  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    //this.scope = data.scope ? data.scope.map((x) => x) : [];
  }
  /*
  static isMeasureScoped(measure: EntityWorkbookMeasure, step_id: string = null) {
    return measure && measure.scope
      && (step_id
        ? (measure.scope[step_id] && measure.scope[step_id].length > 0)
        : Object.keys(measure.scope).length > 0);
  }
  static getMeasureScope(measure, step_id = null) {
    return measure && measure.scope
      && (step_id
        ? measure.scope[step_id].join('\n')
        : Object.keys(measure.scope).map((e) => measure.scope[e].join('\n')).join('\n'));
  }
  isScoped(step_id = null): boolean {
    return EntityWorkbookMeasure.isMeasureScoped(this, step_id);
  }
  getScope(step_id = null) {
    return EntityWorkbookMeasure.getMeasureScope(this, step_id);
  }
  scopeAdd(step_id, scope: string[]) {
    this.scope[step_id] = scope;
  }
  scopeRemove(step_id, scope: string[]) {
    if (this.scope[step_id]) {
      delete this.scope[step_id];
    }
  }
  */
}
