import {Injectable} from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NgxPermissionsGuard, NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {PermissionsService} from './permissions.service';

@Injectable()
export class AuthGuard extends NgxPermissionsGuard implements CanActivate, CanActivateChild {

  constructor(
    protected permissions: PermissionsService,
    permissionsService: NgxPermissionsService,
    rolesService: NgxRolesService,
    router: Router
  ) {
    super(permissionsService, rolesService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (this.isAuthorizeTo(route.params)) {
      return super.canActivate(route, state);
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    // Observable ?
    // if (this.checkLogin()) {
    //  return super.canActivateChild(route, state);
    // }
    return this.canActivate(route, state);
  }

  isAuthorizeTo(params: { [key: string]: any; }): boolean {
    return this.permissions.isAuthenticated();
  }
}
