import {Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {AbstractEntityEditComponent} from './abstractEntityEdit.component';

export class AbstractEntityDialogComponent extends AbstractEntityEditComponent {

  dialogContainer;
  dialogScrolled: boolean = false;

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<AbstractEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);

  }
  save() {
    if (this.entityForm && this.entityForm.entity) {
      this._spinnerService.show();
      const _component = this;
      //console.log('AbstractEntityDialogComponent', 'try to save entity', this.entityForm.entity);
      this._entityService.save(this._beforeSave(this.entityForm.entity))
        .then((res) => {
          //console.log('AbstractEntityDialogComponent', 'entity saved', [res, this.entityForm.entity]);
          _component._afterSave(res);
        })
        .catch((error) => {
          // _component._logger.error('AbstractEntityDialogComponent', 'unable to save entity ' + (_component.entityForm.entity && _component.entityForm.entity._id ? _component.entityForm.entity._id : ''), error);
          _component._logger.error(this.constructor.name + '(AbstractEntityDialogComponent)', 'unable to save entity', JSON.stringify(error));
          //console.log('AbstractEntityDialogComponent save error', [error, this.entityForm.entity]);
          if (_component.entityForm.entity._id) {
            _component._entityService.refreshOne(this.entityForm.entity._id).then((e) => {
              // TODO : copyfrom ? diff ?
              _component.entityForm.entity = e;

              _component._spinnerService.hide();
            }).catch((err) => {
              //              _component._logger.error('AbstractEntityDialogComponent', 'unable to refreshOne after error ' + (_component.entityForm.entity && _component.entityForm.entity._id ? _component.entityForm.entity._id : ''), err);
              _component._logger.error(this.constructor.name + '(AbstractEntityDialogComponent)', 'unable to refreshOne after error', JSON.stringify(err));
              _component._spinnerService.hide();
              // console.log('AbstractEntityDialogComponent refreshOne error', [err, this.entityForm.entity]);
            });
          } else {
            _component._spinnerService.hide();
          }
        });
    }
  }
  protected _afterSave(res) {
    this._spinnerService.hide();
    this.dialogRef.close(this.entityForm.entity);
  }
  protected _beforeSave(entity) {
    return entity;
  }

  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.close();
    }
  }

  close(data = null) {
    this.dialogRef.close(data);
  }


  ngAfterViewInit() {
    //super.ngAfterViewInit();
    if (this.dialogRef._containerInstance && this.dialogRef._containerInstance['_elementRef'] && this.dialogRef._containerInstance['_elementRef']['nativeElement']) {
      this.dialogContainer = this.dialogRef._containerInstance['_elementRef']['nativeElement'];
      this.dialogContainer.onscroll = (event) => {
        if (event && event.type === 'scroll') {
          this.dialogScrolled = !!this.dialogContainer.scrollTop;
        }
      }
    }
  }
  scrollTop() {
    if (this.dialogContainer) {
      this.dialogContainer.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
