import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {Entity} from '../models';
import {EntityEditDialogComponent} from './entityEditDialog.component';
import {EntityListComponent} from './entityList.component';

export class EntityListPopupComponent extends EntityListComponent {
  protected _dialogComponent = EntityEditDialogComponent;
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }

  selectElement(element: Entity = null) {
    this.popupEntity(element ? element._id : '');
  }
  addElement() {
    this.popupEntity();
  }
  popupEntity(id: string = '', width = '80%') {
    const dialogRef = this.dialog.open(this._dialogComponent, {
      disableClose: true,
      minWidth: width,
      data: {
        id: id
      }
    });

    dialogRef.afterClosed().subscribe(e => {
      if (e && e !== 'undefined') {
        this._afterClose(e);
      }
    });
  }
  protected _afterClose(element) {
    this.updateElement(element);
  }
}
