import {Component, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatTableDataSource, MatDialog, MatSnackBar, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {fromEvent as observableFromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  promiseAll, getIcon, formatBytes, comparableString,
  LoggerService,
  SignableService,
  EntityCompany,
  EntityDocumentMetadata,
  Contact, Operator,
  EmailService,
  EmailDatasModel
} from 'app/shared';
import {EntityWorkbook} from 'app/workbook-core'
import {Workbook} from '../workbook.model';
import {CommonSignableComponent} from '../commonSignable.component';

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbook-list',
  templateUrl: './workbookList.component.html',
  styleUrls: ['./workbookList.component.scss']
})

export class WorkbookListComponent extends CommonSignableComponent implements OnDestroy {

  static STATE_SORT = [
    Workbook.STATUS_DRAFT,
    Workbook.STATUS_PROGRESS,
    Workbook.STATUS_COMPLETE,
    Workbook.STATUS_ARCHIVE
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  keywords: string[] = [];
  filteredKeywords: string[] = [];

  entityList: Workbook[] = [];

  selectedStatus: string[] = ['prepared', 'progress'];
  statusFilter: {
    prepared: boolean,
    progress: boolean,
    done: boolean,
    archived: boolean,
    all: boolean
  } = {
      prepared: true,
      progress: true,
      done: false,
      archived: false,
      all: false
    }
  wbProgress: number = 0;
  wbPrepared: number = 0;
  wbClosed: number = 0;

  companies: {[oeex_id: string]: EntityCompany} = {};

  files: File[] = [];
  requestedOeex: EntityCompany[] = [];
  requestedContact: {[oeex_id: string]: Contact[]} = {};
  selectedContact: {[doc_id: string]: string} = {};
  requestedDocuments: {[oeex_id: string]: EntityDocumentMetadata[]} = {};
  requestedDocumentCount: number = 0;

  contactOeex: {[contact_id: string]: EntityCompany} = {};
  //requestedDocuments: EntityDocumentMetadata[] = [];

  dataSource: MatTableDataSource<Workbook> = new MatTableDataSource([]);
  displayedColumns = ['icon', 'name', 'description', /*'dateStart', 'dateEnd', */'editor', 'eex_info', 'eex_name', 'reference', 'site', 'status', 'dateStart'];


  workbooksEexInfos: {
    [wb_id: string]: {
      cell: string,
      infos: {
        name: string,
        principal?: boolean,
        operators: MatTableDataSource<Operator>,
        contacts: MatTableDataSource<Contact>
      }[]
    }
  } = {};
  showWbEexsInfo: {[wb_id: string]: boolean} = {};
  showWbEexsAttribut: {[wb_id: string]: boolean} = {};
  showWbEuAttribut: {[wb_id: string]: boolean} = {};
  showEexOperatorsC: {[eex_name: string]: boolean} = {};
  showEexOperatorsO: {[eex_name: string]: boolean} = {};
  displayedOperatorsColumns: string[] = ['name', 'extra'];
  showEexOperators: {[eex_name: string]: boolean} = {};
  displayedContactsColumns: string[] = ['name', 'email'];
  showOfficeContacts: {[eex_name: string]: boolean} = {};

  tabIndex: number = 0;

  filterSubscription: Subscription;

  //showOperators: { [id: string]: boolean } = {};
  constructor(
    protected _cd: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    protected _emailService: EmailService,
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_route, dialog, _logger, _spinnerService, _signableService);
  }

  _apiCall() {
    return this._signableService.getWorkbookList();
  }

  protected _init(data) {
    if (data) {
      if (data["workbooks"]) {
        this.entityList = data["workbooks"];
        if (this.entityList.length) {
          this.updateWorbooksInfos();
          this.refreshDatasource();
          setTimeout(() => {this.initFilter()});
        } else {

        }
      }
      if (data["companies"] && data["companies"].length) {
        this.requestedOeex = [];
        this.requestedDocuments = {};
        this.requestedContact = {};
        this.selectedContact = {};
        this.companies = {};
        this.requestedDocumentCount = 0;
        data["companies"].forEach((oeex) => {
          this.companies[oeex._id] = oeex;
          if (oeex['documents'] && oeex['documents'].length) {
            const requestedDoc = oeex['documents'].filter((doc) => (!doc.data_id));
            if (requestedDoc && requestedDoc.length) {
              if (oeex._id) {
                if (oeex.contacts && oeex.contacts.length) {
                  const userContacts = oeex.contacts.filter((e) => (e.user_id && e.user_id === this.userId));
                  if (userContacts && userContacts.length) {
                    this.requestedContact[oeex._id] = userContacts;
                  }
                }
                if (this.requestedContact[oeex._id] && this.requestedContact[oeex._id].length && oeex.documents && oeex.documents.length) {
                  const userDocuments = oeex.documents.filter((e) => (!e.data_id));
                  if (userDocuments && userDocuments.length) {
                    userDocuments.forEach((doc) => {
                      //à defaut de selecteur, on prend le premier
                      if (this.requestedContact[oeex._id][0] && this.requestedContact[oeex._id][0]['id']) {
                        this.selectedContact[doc._id] = this.requestedContact[oeex._id][0]['id'];
                      }
                    });
                    this.requestedDocuments[oeex._id] = userDocuments;
                    this.requestedDocumentCount += userDocuments.length;
                  }
                }
                if (this.requestedContact[oeex._id] && this.requestedDocuments[oeex._id]) {
                  this.requestedOeex.push(oeex);
                }
              }
            }
          }
        });
        if (this._route.snapshot.url
          && this._route.snapshot.url[0]
          && this._route.snapshot.url[0].path
          && this._route.snapshot.url[0].path === 'documents') {
          this.tabIndex = 1;
        }
      }
    }
  }

  public dynamicRoute(workbook: Workbook) {
    return ['/' + this._signableService.getUrlSignables(workbook._id)];
  }
  ngAfterViewInit() {
    this.initFilter();
  }

  initFilter() {
    if (this.filter && this.loaded && !this.filterSubscription) {
      this.filterSubscription = observableFromEvent(this.filter.nativeElement, 'keyup').pipe(
        debounceTime(150),
        distinctUntilChanged())
        .subscribe(() => {
          this.applyFilter();
        });
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
  searchStelect(event) {
    setTimeout(() => {this.applyFilter();}, 100);
  }
  applyFilter() {

    if (!this.dataSource) {
      return;
    }
    this.filterKeywords();
    //const f = (this.filter && this.filter.nativeElement && this.filter.nativeElement.value) ? this.filter.nativeElement.value : ' ';
    this.dataSource.filter = (this.filter && this.filter.nativeElement && this.filter.nativeElement.value) ? this.filter.nativeElement.value : ' ';
    this.dataSource._updateChangeSubscription();

  }
  getStatusFilterText() {
    const returned: string[] = [];
    if (this.selectedStatus && this.selectedStatus.indexOf('prepared') !== -1) {
      returned.push(this.wbPrepared + ' dossier' + (((0 + this.wbPrepared) > 1) ? 's' : '') + ' en préparation');
    }
    if (this.selectedStatus && this.selectedStatus.indexOf('progress') !== -1) {
      returned.push(this.wbProgress + ' dossier' + (((0 + this.wbProgress) > 1) ? 's' : '') + ' en cours');
    }
    if (this.selectedStatus && this.selectedStatus.indexOf('done') !== -1) {
      returned.push(this.wbClosed + ' dossier' + (((0 + this.wbClosed) > 1) ? 's' : '') + ' terminés');
    }

    return returned.join(', ');
  }

  changeStatusFilter() {
    this.statusFilter = {
      prepared: (this.selectedStatus.indexOf('prepared') !== -1),
      progress: (this.selectedStatus.indexOf('progress') !== -1),
      done: (this.selectedStatus.indexOf('done') !== -1),
      archived: (this.selectedStatus.indexOf('archived') !== -1),
      all: !this.selectedStatus.length
    }
    if (this.statusFilter['prepared'] && this.statusFilter['progress'] && this.statusFilter['done'] && this.statusFilter['archived']) {
      this.statusFilter['all'] === true;
    }
    this.applyFilter();
  }




  refreshDatasource() {
    if (this.entityList) {
      this.dataSource = new MatTableDataSource(this.entityList.map((e) => (new Workbook(e))));
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'status': {
            return WorkbookListComponent.STATE_SORT.indexOf(item.getStatus());
          }
          default: {
            return item[property];
          }
        };
      }
      this.dataSource.filterPredicate = this._getDataSourceFilterPredicate();
      setTimeout(() => {
        this.setSort();
      });
    }
  }
  setSort() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }
  protected _getDataSourceFilterPredicate() {
    return (data, filter: string) => (
      (!filter || filter.trim() === '' || JSON.stringify(data).toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1)
      && (!!this.statusFilter['all'] || !!this.statusFilter[EntityWorkbook.STATUS_CODE[data.getStatus()]])
    )
  }
  fileChangeEvent(event: any, document_id: string) {
    if (event.addedFiles && event.addedFiles[0]) {
      this.files[document_id] = event.addedFiles[0];
    }
  }
  fileRemoveEvent(document_id: string) {
    if (this.files[document_id]) {
      this.files[document_id] = null;
    }
  }
  sendFile(document: EntityDocumentMetadata, oeex_id: string) {
    //console.log('sendFile', document._id);
    if (document._id && this.files[document._id] && this.selectedContact[document._id]) {
      this._spinnerService.show();
      this._signableService.upload(document._id, this.files[document._id], {oeex_id: oeex_id, userName: this._signableService.getUserName()})
        .then((res) => {
          this._spinnerService.hide();
          this.snackBar.open('Votre fichier a été enregistré', '', {
            duration: 5000
          });
          const company = this.companies[oeex_id];
          const contactId = this.selectedContact[document._id];
          const contact = this.requestedContact[oeex_id].find((e) => (e.id === contactId));
          if (document.notifications && document.notifications.length && company && contact) {
            const emailPromises = [];
            document.notifications.forEach((email) => {

              const emailDatas = new EmailDatasModel();
              emailDatas.date = new Date();
              emailDatas.subject = "Document déposé";
              emailDatas.text = 'Le document "' + document.name + '" demandé à ' + company.name + ' a été déposé par ' + contact.name;
              //emailDatas.signature = email.signature;
              emailDatas.email = email;
              emailDatas.to_name = '';
              //emailDatas.from_name = contact.name;
              //emailDatas.from_email = contact.email;
              emailDatas.reply_to = contact.email;
              emailPromises.push(this._emailService.sendEmail(emailDatas));
            });
            promiseAll(emailPromises).then((responses) => {
              //this._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
              this.snackBar.open('La notification a été envoyée', '', {
                duration: 5000
              });

            }, (err) => {
              this._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
              this._displayError('Erreur d\'envoi de la notification.');
            });
          }

          //refresh ?
          this._initFromRouteParams({});
        }).catch((err) => {
          this._spinnerService.hide();
          this._displayError('Une erreur s\'est produite lors de l\'envoi du fichier, veuillez réessayer et sinon contacter le support.');
        });
    }
  }
  public formatBytes(size: number): string {
    return formatBytes(size);
  }
  public getIcon(mime: string): string {
    return getIcon(mime);
  }
  getStatus(wb: EntityWorkbook) {
    return EntityWorkbook.getWorkbookStatus(wb)
  }
  getStatusColor(wb: EntityWorkbook) {
    return EntityWorkbook.STATUS_COLOR[this.getStatus(wb)];
  }
  public filterKeywords() {
    const filter = (this.filter && this.filter.nativeElement && this.filter.nativeElement.value && this.filter.nativeElement.value.trim() !== '')
      ? this.filter.nativeElement.value.trim()
      : '';
    this.filteredKeywords = this.keywords
      .filter((e) => (!filter || comparableString(e).indexOf(comparableString(this.filter.nativeElement.value.trim())) !== -1))
      .sort((a, b) => a.localeCompare(b));;
  }
  public addKeywords(wb) {
    if (wb && wb.operators && wb.operators.length) {
      wb.operators.forEach((op) => {
        if (this.keywords.indexOf(op.name) === -1) {
          this.keywords.push(op.name);
        }
      });
    }
    if (wb && wb.eex_contacts && Object.keys(wb.eex_contacts) && Object.keys(wb.eex_contacts).length) {
      Object.keys(wb.eex_contacts).forEach((eex) => {
        if (this.keywords.indexOf(eex) === -1) {
          this.keywords.push(eex);
        }
        if (wb.eex_contacts[eex] && wb.eex_contacts[eex].length) {
          wb.eex_contacts[eex].forEach((contact) => {
            if (contact.name && this.keywords.indexOf(contact.name) === -1) {
              this.keywords.push(contact.name);
            }
          });
        }
      });
    }
    if (wb && wb.office_contacts && Object.keys(wb.office_contacts) && Object.keys(wb.office_contacts).length) {
      Object.keys(wb.office_contacts).forEach((eex) => {
        if (this.keywords.indexOf(eex) === -1) {
          this.keywords.push(eex);
        }
        if (wb.office_contacts[eex] && wb.office_contacts[eex].length) {
          wb.office_contacts[eex].forEach((contact) => {
            if (contact.name && this.keywords.indexOf(contact.name) === -1) {
              this.keywords.push(contact.name);
            }
          });
        }
      });
    }
    this.filterKeywords();
  }
  updateWorbooksInfos() {
    if (this.entityList) {
      this.workbooksEexInfos = {};
      this.keywords = [];
      this.entityList.forEach((wb) => {
        this.addKeywords(wb);
        this.workbooksEexInfos[wb._id] = {
          cell: this.getEexsCell(wb),
          infos: this.getEexsInfo(wb)
        }
      });
    }
  }
  getEexsCell(wb) {
    if (wb.eexs) {
      const returned = Object.keys(wb.eexs).filter((e) => (e !== wb.eex_name)).sort((a, b) => a.localeCompare(b));
      return (!!wb.eexs
        ? (
          ('<b class="light">' + wb.eex_name + '</b>' + ((Object.keys(wb.eexs).length > 1) ? '<br>' : ''))
          + ((returned.length > 2) ? ('+ ' + returned.length + ' entreprises') : returned.join('<br>'))
        ) : (
          (returned.length > 3) ? (returned[0] + '<br>' + returned[1] + '<br>' + (returned.length - 2) + ' entreprises') : returned.join('<br>')
        )
      );
    }
    return '';
  }
  getEexsInfo(wb): {name: string, bold?: boolean, operators: MatTableDataSource<Operator>, contacts: MatTableDataSource<Contact>}[] {
    const eexList: {name: string, principal?: boolean, operators: MatTableDataSource<Operator>, contacts: MatTableDataSource<Contact>}[] = [];
    if (!!wb.eex_name) {
      const eex_contacts = (wb.eex_contacts && wb.eex_contacts[wb.eex_name]) ? wb.eex_contacts[wb.eex_name] : [];
      eexList.push({
        name: wb.eex_name,
        principal: true,
        operators: new MatTableDataSource(wb.operators ? wb.operators
          .map((o) => (new Operator(o)))
          .filter((e) => (e.company === wb.eex_name || (wb.eexs && wb.eexs[wb.eex_name] === e.company_repo_id)))
          .sort((a, b) => a.name.localeCompare(b.name))
          : []),
        contacts: new MatTableDataSource(eex_contacts)
      });
    }
    if (wb.eexs) {
      Object.keys(wb.eexs)
        .filter((e) => (e !== wb.eex_name))
        .sort((a, b) => a.localeCompare(b))
        .forEach((c) => {
          const eex_contacts = (wb.eex_contacts && wb.eex_contacts[c]) ? wb.eex_contacts[c] : [];
          eexList.push({
            name: c,
            operators: new MatTableDataSource(wb.operators ? wb.operators
              .filter((e) => (e.company === c || (wb.eexs && wb.eexs[c] === e.company_repo_id)))
              .sort((a, b) => a.name.localeCompare(b.name))
              : []),
            contacts: new MatTableDataSource(eex_contacts)
          });
        });
    }
    /*
    if (wb.eex_contacts&&wb.eex_contacts){
      Object.keys(wb.eex_contacts)
      .filter((eexname)=>(!wb.eexs||Object.keys(wb.eexs).indexOf(eexname)===-1))
        .forEach((eexname)=>{

        });
    }
    */
    return eexList;
  }
  showHideWbEexsInfo(id: string) {
    if (this.showWbEexsInfo[id]) {
      delete this.showWbEexsInfo[id];
    } else {
      this.showWbEexsInfo = {};
      this.showWbEexsInfo[id] = true;
    }
  }
}
