import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {ConfigModel} from 'app/shared/models/config.model';
import {ConfigService} from './config.service';

@Injectable()
export class ConfigResolver implements Resolve<ConfigModel> {
  constructor(private configService: ConfigService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ConfigModel> | Promise<any> | ConfigModel {

    return this.configService.getConfigLocal().then((data) => {
      return data;
    }).catch((error) => {
      console.error('Error to get config data', error);
    })
  }
}
