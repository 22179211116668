import {ActivatedRoute, Router} from '@angular/router';
import {EntitySecureService, EntityEditComponent, LoggerService} from 'app/shared';


export abstract class AbstractWorkbookEditComponent extends EntityEditComponent {
  // used to slide menu button
  menuState: string = 'out';
  menuTabIndex: number = 0;

  today: Date;

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(_route, _router, _logger, _entityService);
    this.today = new Date();
  }

  /**
   * Used to close slide menu
   */
  closeSlideMenu() {
    this.menuState = 'out';
  }
  /**
  * Open the right slide menu
  * @param {string} tabIndex
  */
  toggleMenu(tabIndex: number = 0) {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    if (tabIndex) {
      this.menuTabIndex = tabIndex;
    }
  }

}
