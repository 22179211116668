import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, ConfirmDialogComponent } from 'app/shared';
import { AbstractWorkbookEditComponent, WorkbookStepDialogComponent } from 'app/workbook-shared';
import { WorkbookStepService, PreventionPlanService, EntityWorkbook } from 'app/workbook-core';
import { PreventionPlanLocationComponent } from './location.component';

@Component({
  selector: 'app-prevention-plan-location-edit',
  templateUrl: './locationEdit.component.html'
})

export class PreventionPlanLocationEditComponent extends AbstractWorkbookEditComponent {
  entityForm: PreventionPlanLocationComponent;
  protected _workbookSubscription: Subscription;
  workbook: EntityWorkbook
  site_id: string;
  constructor(
    public dialog: MatDialog,
    protected _workbookService: PreventionPlanService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService

  ) {
    super(_route, _router, _logger, _entityService);
  }
  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (params['site_id']) {
      this.site_id = params['site_id'];
    }
    if (this.parent_id) {
      this._workbookSubscription = this._workbookService.getOne(this.parent_id).subscribe((wb) => {
        if (wb) {
          this.workbook = wb;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._workbookSubscription) {
      this._workbookSubscription.unsubscribe();
    }
  }
}
