import {ViewChild} from '@angular/core';
import {LoggerService} from '../logger';
import {EntitySecureService} from '../services';
import {AbstractEntityServiceComponent} from './abstractEntityService.component';
import {EntityFormComponent} from './entityForm.component';

export class AbstractEntityEditComponent extends AbstractEntityServiceComponent {
  @ViewChild('entityForm') entityForm: EntityFormComponent;
  constructor(
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }
  getformErrors() {
    if (this.entityForm && this.entityForm.form && !this.entityForm.form.valid) {
      return this.entityForm.getFormErrors();
    }
    return '';
  }
}
