import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService,
  EmailMessages, SignableStatus
} from 'app/shared';
import {
  PreventionPlanService,
  SignablePreventionPlanService
} from '../services';
import {
  EntitySignablePreventionPlan
} from '../models';
import {
  EntityIcp, Implementation, EntityStep
} from '../../workbook';
import {
  EmergencyInstructionsService,
  EntityEmergencyInstructions, EmergencyCase, EmergencyContact,
  AttendanceGroup
} from '../../repository';
import {AbstractPdpExportComponent} from './abstractPdPExport.component';

@Component({
  selector: 'app-pdp-export',
  templateUrl: './pdpExport.component.html',
  styleUrls: ['./pdpExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PdpExportComponent extends AbstractPdpExportComponent {

  attendanceGroup: string;

  SIGN_NOTIFY = EmailMessages.SIGN_PDP_CONTACT;
  SIGNUP_CHECKER: string = 'signupCheckerPP';

  entity: EntitySignablePreventionPlan;

  icpList: {[icp_id: string]: EntityIcp} = {};
  icpIds: string[] = [];
  implementations: {[step_id: string]: Implementation} = {};
  allSteps: EntityStep[] = [];
  implementationStepIds: string[] = [];

  protected _icpsSubscription: Subscription;

  protected _emergencyInstructionsSubscription: Subscription;
  emergencyInstructions: EntityEmergencyInstructions;
  newEmergencyCase: EmergencyCase = new EmergencyCase();
  newContacts: EmergencyContact[] = [];
  newInstructions: string[] = [];


  constructor(
    protected _emergencyInstructionsService: EmergencyInstructionsService,
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: SignablePreventionPlanService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);
    this.attendanceGroup = 'Émargement';
    this.operatorGroups = [
      new AttendanceGroup({
        name: this.attendanceGroup,
        mClass: '',
        editionClass: '',
        isUnique: true,
        nominative: true,
        required: true,
        signature: true,
        checkpoints: ['signer']
      })
    ];
  }
  hasDraftIcp() {
    return this.icpIds.filter((icpid) => (
      !(this.icpList[icpid].status === SignableStatus.SIGNING && this.icpList[icpid].withoutSignatories)
      && !(this.icpList[icpid].status === SignableStatus.SIGNED)
    )).length > 0
  }
  refreshSignableStatus(withSignatories) {
    super.refreshSignableStatus(true);
  }
  initEmergencyInstructionsNew() {
    this.newContacts = [];
    this.newInstructions = [];
    if (this.entity && this.entity.emergencyInstructions && this.entity.emergencyInstructions.emergencyCases) {
      this.entity.emergencyInstructions.emergencyCases.forEach((ec) => {
        this.newContacts.push(new EmergencyContact());
        this.newInstructions.push('');
      });
    }
  }

  _init(data) {
    super._init(data);
    if (this.entity) {
      if (!this.entity.emergencyInstructions && this.emergencyInstructions && this.emergencyInstructions.enabled) {
        this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
        this.initEmergencyInstructionsNew();
      } else {
        if (!this._emergencyInstructionsSubscription) {
          this._emergencyInstructionsSubscription = this._emergencyInstructionsService.getSingleOne().subscribe((data) => {
            if (data) {
              this.emergencyInstructions = data;
              if (this.entity && !this.entity.emergencyInstructions && this.emergencyInstructions.enabled) {
                this.entity.emergencyInstructions = new EntityEmergencyInstructions(this.emergencyInstructions);
                this.initEmergencyInstructionsNew();
              }
            }
          });
        }
        this.initEmergencyInstructionsNew();
      }
      if (!this._icpsSubscription) {
        this._icpsSubscription = this._preventionPlanService.getIcpList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            this.icpList = {};
            this.icpIds = [];
            list
              .sort((b, a) => ((new Date(b.iPDate)).getTime() - (new Date(a.iPDate)).getTime()))
              .filter((icp) => (this.entity.icps.indexOf(icp._id) !== -1))
              .forEach((o) => {
                this.icpIds.push(o._id);
                this.icpList[o._id] = o;
                if (o.implementations && Object.keys(o.implementations).length) {
                  Object.keys(o.implementations).forEach((step_id) => {
                    if (!this.entity.steps || this.entity.steps.indexOf(step_id) === -1) {
                      this.addImplementation(o.implementations[step_id]);
                    }
                  });
                }
              });
            this.refreshStepIdList();
          }
        });
      }
      if (!this._stepsSubscription) {
        this._stepsSubscription = this._preventionPlanService.getStepList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            this.activityStepList = [];
            this.stepList = {};
            this.allSteps = list.sort((b, a) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()));

            this.allSteps.filter((e) => ((this.entity.steps && this.entity.steps.length > 0 && this.entity.steps.indexOf(e._id) !== -1)
              || (this.entity.implementations && Object.keys(this.entity.implementations).indexOf(e._id) !== -1)
            ))
              .forEach((o) => {
                if (o.scopedMeasures) {
                  Object.keys(o.scopedMeasures).forEach((a_id) => {
                    Object.keys(o.scopedMeasures[a_id]).forEach((m_id) => {
                      o.scopedMeasures[a_id][m_id].forEach((oeex_id) => {
                        if (this.oeexStepList.indexOf(oeex_id) === -1) {
                          this.oeexStepList.push(oeex_id);
                        }
                      });
                    });
                  });
                }
                if (o.activities && o.activities.length) {
                  o.activities.forEach((a) => {
                    if (this.activityStepList.indexOf(a) === -1) {
                      this.activityStepList.push(a);
                    }
                  });
                }
                if (o.template) {
                  this.templates.push(o);

                } else {
                  this.steps.push(o);
                }
                this.stepList[o._id] = o;
                this.updateLocationRisk(o);

              });
            this.refreshOeexList();
            this.refreshStepIdList();

          }
        });
      }

      this.isValid = this.entity.isValid();
      this.isAttendanceManager = this._entityService.getUserId() === this.entity.editor || this.hasPermission('attendanceSignablePdP');

    }
  }
  refreshOeexList(active = []) {
    if (this.entity) {
      if (this.entity.steps && this.entity.steps.length) {
        this.entity.steps.forEach((step_id) => {
          if (this.stepList[step_id]) {
            const impl = Implementation.generate(this.stepList[step_id], this.activities, this.risks);
            this.addImplementation(impl, true);
          }
        });
      }
      if (this.entity.icps && this.entity.icps.length) {
        this.entity.icps.forEach((icp_id) => {
          if (this.icpList[icp_id]) {
            this.icpList[icp_id].steps.forEach((step_id) => {
              if (this.stepList[step_id]) {
                const impl = Implementation.generate(this.stepList[step_id], this.activities, this.risks);
                this.addImplementation(impl, true);
              }
            });
          }
        });
      }
    }
    super.refreshOeexList(active);
    const operators = [];
    this.externalCompanyList.forEach((eex) => {
      //TODO ajouter les operateurs inconnus ayant déjà signé ?
      if (eex.operators && eex.operators.length) {
        eex.operators.forEach((op) => {
          operators.push(op);
        });
      }
    });
    this.operators[this.attendanceGroup] = new MatTableDataSource(operators);

    this.attendances = {}
    if (operators.length) {
      operators.forEach((o) => {
        this.attendances[o.id] = this.entity.attendances.filter((e) => (e.id === o.id));
      });
    }
  }

  showOeex(id: string) {
    return (this.entity.signatures.filter((sign) => (sign.company_id === id)).length > 0) || (Object.keys(this.implementations).filter((step_id) =>
      (Object.keys(this.implementations[step_id].companies).indexOf(id) !== -1)
    ).length > 0)
    //return this.activeOeexList.indexOf(id) !== -1;
  }
  refreshStepIdList() {
    this.implementationStepIds = this.allSteps.map((e) => (e._id))
      .filter((step_id) => (!!this.implementations[step_id]));
    this.implementationStepIds.forEach((step_id) => {
      this.updateLocationRisk(this.implementations[step_id].step);
    });
  }
  addImplementation(implementation: Implementation, force = false) {
    if (implementation && implementation.step && implementation.step._id && (force || !this.implementations[implementation.step._id])) {
      this.implementations[implementation.step._id] = implementation;
      /*
      if (implementation.activities) {
        Object.keys(implementation.activities).forEach((a) => {
          if (this.activityStepList.indexOf(a) === -1) {
            this.activityStepList.push(a);
          }
        });
      }
      */
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._icpsSubscription) {
      this._icpsSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._emergencyInstructionsSubscription) {
      this._emergencyInstructionsSubscription.unsubscribe();
    }
  }

  toggleEmergencyInstructions(event) {
    if (event && event.checked) {
      this.entity.emergencyInstructions.enabled = true;
    } else {
      this.entity.emergencyInstructions.enabled = false;
    }
    this._save().then((e) => {this._afterSave(e); this.initEmergencyInstructionsNew();});
  }


  addEmergencyCase() {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.newEmergencyCase) {
      if (!this.entity.emergencyInstructions.emergencyCases) {
        this.entity.emergencyInstructions.emergencyCases = [];
      }
      this.entity.emergencyInstructions.emergencyCases.push(this.newEmergencyCase);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
      this.newEmergencyCase = new EmergencyCase();
    };
  }
  removeEmergencyCase(index: number) {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.entity.emergencyInstructions.emergencyCases && this.entity.emergencyInstructions.emergencyCases.length > index) {
      this.entity.emergencyInstructions.emergencyCases.splice(index, 1);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
    };
  }

  addContact(index: number) {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.newEmergencyCase && this.entity.emergencyInstructions.emergencyCases[index]) {
      if (!this.entity.emergencyInstructions.emergencyCases[index].contacts) {
        this.entity.emergencyInstructions.emergencyCases[index].contacts = [];
      }
      this.entity.emergencyInstructions.emergencyCases[index].contacts.push(this.newContacts[index]);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
      this.newContacts[index] = new EmergencyContact();
    };
  }
  removeContact(index: number, indexContact: number) {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.entity.emergencyInstructions.emergencyCases && this.entity.emergencyInstructions.emergencyCases[index] && this.entity.emergencyInstructions.emergencyCases[index].contacts && this.entity.emergencyInstructions.emergencyCases[index].contacts[indexContact]) {
      this.entity.emergencyInstructions.emergencyCases[index].contacts.splice(indexContact, 1);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
    };
  }
  addInstruction(index: number) {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.newEmergencyCase && this.entity.emergencyInstructions.emergencyCases[index]) {
      if (!this.entity.emergencyInstructions.emergencyCases[index].instructions) {
        this.entity.emergencyInstructions.emergencyCases[index].instructions = [];
      }
      this.entity.emergencyInstructions.emergencyCases[index].instructions.push(this.newInstructions[index]);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
      this.newInstructions[index] = '';
    };
  }
  removeInstruction(index: number, indexInstruction: number) {
    if (this.rightU && this.entity && this.entity.emergencyInstructions && this.entity.emergencyInstructions.emergencyCases && this.entity.emergencyInstructions.emergencyCases[index] && this.entity.emergencyInstructions.emergencyCases[index].instructions && this.entity.emergencyInstructions.emergencyCases[index].instructions[indexInstruction]) {
      this.entity.emergencyInstructions.emergencyCases[index].instructions.splice(indexInstruction, 1);
      //this._save().then((e) => { this._afterSave(e); this._initEmergencyInstructionsNew(); });
    };
  }
}
