import {Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared';
import {
  EntityZone, EntitySite, WorkbookStepService,
  PreventionPlanService,
  RiskService,
  MeasureService,
  EntityStep
} from 'app/workbook-core';
import {AbstractAnalysableComponent} from '../abstractAnalysable.component';
import {WorkbookRisksDialogComponent} from '../activity/risks-dialog.component';
import {WorkbookMeasureScopeDialogComponent} from '../activity/measureScope-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbook-zone',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss'],
  animations: [
    trigger('collapse', [
      state('fold', style({
        height: 0, overflow: 'hidden'
      })),
      state('unfold', style({
        height: '*', overflow: 'hidden'
      })),
      transition('unfold <=> fold', animate('400ms ease-in-out'))
    ])
  ]
})

export class WorkbookLocalisationComponent extends AbstractAnalysableComponent {
  @Input() entity: EntityZone;
  @Input() site: EntitySite;
  step: EntityStep;
  protected _stepSubscription: Subscription;

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette zone ?';


  constructor(
    protected _cd: ChangeDetectorRef,
    protected _workbookService: PreventionPlanService,
    protected _riskService: RiskService,
    protected _measureService: MeasureService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _logger: LoggerService,
    protected _entityService: WorkbookStepService
  ) {
    super(_cd, _workbookService, _riskService, _measureService, dialog, snackBar, _logger, _entityService);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['entity'] && changes['entity']['firstChange']) {
      changes['entity']['firstChange'] = false;
    }
    super.ngOnChanges(changes);
    if (changes && changes['step_id']) {
      if (this._stepSubscription) {
        this._stepSubscription.unsubscribe();
      }
      this._stepSubscription = this._entityService.getOne(this.step_id).subscribe((data) => {
        if (data) {
          this.step = data;
          this.refreshSteps();
          this.updateMaskRisk();
          this.refreshRisks();
        }
      });
    }
  }
  protected _save() {
    this.step.updateZone(this.entity);
    return this._entityService.save(this.step).then((res) => {
      // console.log('localisation save step');
    })
      .catch((error) => {
        console.error('localisation error save step', error);
      });
  }
  getFilteredSteps(): EntityStep[] {
    return (this.step) ? [this.step] :
      (this.stepList && this.stepList.length && this.selectedSteps) ? this.stepList.filter((step) => (this.selectedSteps.indexOf(step._id) !== -1)) : [];

  }
  openRisksDialog(risk_id: string = null, situation_id: string = null) {
    const risksDialogRef = this.dialog.open(WorkbookRisksDialogComponent, {
      disableClose: true,
      width: '1200px',
      height: '90vh',
      data: {
        entity: new EntityZone(this.entity),
        workbook: this.workbook,
        parent_id: this.parent_id,
        selectedRisk: risk_id,
        selectedSituation: situation_id,
        zone: this.entity.name + (this.site ? ' - ' + this.site.name : ''),
        global: true
      }
    });

    risksDialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined' && this.step) {
        this.entity.copyFrom(c);
        this.refreshMeasureScopeList();
        this.entity.measures.forEach((m) => {
          if (!m.global && !this.isScoped(m)) {
            m.global = true;
          }
        });
        this._save().then((res) => {
          this.refreshRisks();
        });
        //        this.onSave.emit(this.entity);

      }
    });
  }
  /*
  getScopeList(measure, global: boolean = false): { [step: string]: string[] } {
    const x = super.getScopeList(measure, global);
    const returned: { [step: string]: string[] } = {}
    if (this.step_id && this.entity && this.entity._id && this.step && measure && measure._id
      && this.step.scopedMeasures
      && this.step.scopedMeasures[this.entity._id]
      && this.step.scopedMeasures[this.entity._id][measure._id]) {
      returned[this.step_id] = this.step.scopedMeasures[this.entity._id][measure._id];
    }
    return returned;
  }
  */
  openMeasureScopeDialog(measure) {
    const dialogRef = this.dialog.open(WorkbookMeasureScopeDialogComponent, {
      disableClose: true,
      minWidth: '900px',
      data: {
        entity: this.entity,
        parent_id: this.workbook._id,
        measure_id: measure._id,
        activityOEEx: null,//this.entity.companies.map((e) => (e.name)).sort(),
        stepOEEx: this.getScopeList(measure, true),
        zoneStep: this.step_id
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      const c = r[0];
      const newScope = r[1];
      if (c && c !== 'undefined') {
        /*
        this.entity.copyFrom(c);
        if (newScope) {
          Object.keys(newScope).forEach((step_id) => {
            let _step = this.steps.find((s) => (s._id === step_id));
            if (_step && _step.scopedMeasures) {
              // console.log(newScope[step_id]);
              if (newScope[step_id]) {
                if (!_step.scopedMeasures[this.entity._id]) {
                  _step.scopedMeasures[this.entity._id] = {};
                }
                _step.scopedMeasures[this.entity._id][measure._id] = newScope[step_id];
                //ajout des scopes
              } else {
                //suppression des scopes
                if (_step.scopedMeasures[this.entity._id] && _step.scopedMeasures[this.entity._id][measure._id]) {
                  delete _step.scopedMeasures[this.entity._id][measure._id];
                  if (Object.keys(_step.scopedMeasures[this.entity._id]).length === 0) {
                    delete _step.scopedMeasures[this.entity._id];
                  }
                }
              }
            }
          });
        }
        this.refreshSteps();
        this.refreshRisks();
        */
        this._save().then((res) => {
          // this.refreshRisks();
        });
        //this.onSave.emit(this.entity);
      }
    });
  }

}
