import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep} from 'app/workbook-core';

@Component({
  selector: 'app-workbook-step-implementation-dialog',
  styleUrls: ['./stepImplementation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './stepImplementation-dialog.component.html',
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookStepImplementationDialogComponent {
  step: EntityStep;
  constructor(
    protected _router: Router,
    public dialogRef: MatDialogRef<WorkbookStepImplementationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _workbookService: PreventionPlanService
  ) {
    if (data) {
      // if (data['workbook']) {
      //   this.workbook = data['workbook'];
      // }
      if (data['step']) {
        this.step = data['step'];
      }
    }
  }
  openPdp(id) {
    this.dialogRef.close(id);
  }
  openIcp(id) {
    if (this.step) {
      this._router.navigate(['/' + this._workbookService.getUrlIcp('pp', this.step.parent_id, id)]);
      this.dialogRef.close();
    }
  }
}
