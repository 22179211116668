import { Inject, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subscription } from 'rxjs';
import { CommonComponent, LoggerService } from 'app/shared';
import { PermitContentService, EntityPermitContent } from 'app/workbook-core';

@Component({
  selector: 'app-workbook-permitContentChoice',
  templateUrl: './permitContentChoice-dialog.component.html',
  styleUrls: ['./permitContentChoice-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class PermitContentChoiceDialogComponent extends CommonComponent implements OnDestroy {

  permitContents: EntityPermitContent[] = [];
  selectedPermitContent: EntityPermitContent;
  protected _permitContentSubscription: Subscription;

  constructor(
    private _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<PermitContentChoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _permitContentService: PermitContentService) {
    super(_logger);

    this._spinnerService.show();
    this._permitContentSubscription = this._permitContentService.getAll().subscribe((list) => {
      if (list) {

        this._spinnerService.hide();
        this.permitContents = list;
        if (this.permitContents.length) {
          this.selectedPermitContent = this.permitContents[0];
          if (this.permitContents.length === 1) {
            this.onSubmit();
          }
        }
      }
    });
  }
  ngOnDestroy() {
    if (this._permitContentSubscription) {
      this._permitContentSubscription.unsubscribe();
    }
  }

  onSubmit() {

    this._spinnerService.show();
    this._permitContentService.getOne(this.selectedPermitContent._id).subscribe((pc) => {
      if (pc) {
        this._spinnerService.hide();
        this.dialogRef.close(pc);
      }
    });
  }
}
