import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityEditComponent, LoggerService} from 'app/shared';
import {ExternalCompanyService} from 'app/workbook-core';

@Component({
  selector: 'app-repository-externalCompany-edit',
  templateUrl: './externalCompanyEdit.component.html',
  styleUrls: ['../repository.scss']
})

export class ExternalCompanyEditComponent extends EntityEditComponent {

  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: ExternalCompanyService

  ) {
    super(_route, _router, _logger, _entityService);
  }
}
