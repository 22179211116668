import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material';
import {formattedDate, LoggerService, } from 'app/shared';
import {ActivityService, EntityActivity, EntityStep, CompanyActivity, PreventionPlanService, EntityExternalCompany} from 'app/workbook-core';
import {AbstractWorkbookChildFormComponent} from '../abstractWorkbookChildForm.component';

@Component({
  selector: 'app-workbook-activity-info',
  templateUrl: './activityInfo.component.html',
  styleUrls: ['./activityInfo.component.scss']
})
export class WorkbookActivityInfoComponent extends AbstractWorkbookChildFormComponent implements OnDestroy {

  autoSave = false;
  entity: EntityActivity;

  @Input() maxDateEnd: Date;
  @Input() minDateStart: Date;
  @Input() step_id: string;
  @Input() default_step_id: string;

  @Input() groups: string[] = [];
  @Input() showGroup: boolean = false;

  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  template_steps: {[id: string]: EntityStep[]} = {};
  selectedSteps: {[id: string]: boolean} = {};
  protected _stepsSubscription: Subscription;
  completed_steps: {[id: string]: boolean} = {};

  protected _companiesSubscription: Subscription;
  companies: CompanyActivity[] = [];
  companiesSelected: CompanyActivity[] = [];
  // newCompaniesSelected: CompanyActivity[] = [];

  protected _activitiesSubscription: Subscription;
  activitieUniqueNames: string[] = [];

  constructor(
    protected _workbookService: PreventionPlanService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: ActivityService
  ) {
    super(dialog, _logger, _entityService);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._activitiesSubscription) {
      this._activitiesSubscription.unsubscribe();
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._companiesSubscription) {
      this._companiesSubscription.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity.parent_id) {
      if (!this._activitiesSubscription) {
        this._activitiesSubscription = this._entityService.getChildren(this.entity.parent_id).subscribe((list: EntityActivity[]) => {
          if (list) {
            this.activitieUniqueNames = list
              .filter((a) => (a._id !== this.entity._id))
              .map((a) => (a.group + '-' + a.name));
          }
        });
      }
      if (!this._stepsSubscription) {
        this._stepsSubscription = this._workbookService.stepService.getChildren(this.entity.parent_id).subscribe((list: EntityStep[]) => {
          if (list) {
            this.templates = [];
            this.steps = [];
            this.completed_steps = {};
            this.template_steps = {};
            this.selectedSteps = {};
            list.sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()))
              .forEach((e: EntityStep) => {
                this.completed_steps[e._id] = e.isComplete();
                if (e.template_id) {
                  if (!this.template_steps[e.template_id]) {
                    this.template_steps[e.template_id] = [];
                  }
                  this.template_steps[e.template_id].push(e);
                } else {
                  this.selectedSteps[e._id] = e.activities.indexOf(this.entity._id) > -1;
                  if (e.template) {
                    this.templates.push(e);
                  } else
                    this.steps.push(e);
                }
              });
            if (this.workbook && this.workbook.type === 'annual') {
              this.steps = this.steps.sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()))
            }
            if (this.workbook && this.workbook.simple && this.steps.length === 1 && !this.entity._id) {
              this.selectedSteps[this.steps[0]._id] = true;
            }
            if (this.step_id && !this.entity._id) {
              this.selectedSteps[this.step_id] = true;
            }
            if (this.default_step_id && !this.entity._id) {
              this.selectedSteps[this.default_step_id] = true;
            }
          }
        });
      }
      if (!this._companiesSubscription) {
        this._companiesSubscription = this._workbookService.getOEexList(this.entity.parent_id).subscribe((list) => {
          if (list) {
            this.companies = list.map((company) => new CompanyActivity({id: company._id, name: company.name}));
            if (!this.entity._id && this.companies && this.companies.length === 1 && this.entity.companies.length === 0) {
              this.entity.companies.push(this.companies[0]);
            }
            if (this.entity.companies && this.entity.companies.length) {
              //this.newCompaniesSelected = this.companies.filter((ca) => (this.entity.companies.findIndex((e) => (e.id === ca.id)) !== -1));
              this.companiesSelected = this.entity.companies.map((e) => (e));//.filter((ca) => (this.companies.findIndex((e) => (e.id === ca.id)) !== -1));
              const deletedCompanies = this.companiesSelected.filter((c) => (this.companies.findIndex((ec) => (ec.id === c.id)) === -1));
              if (deletedCompanies.length) {
                this.companies = [...this.companies, ...deletedCompanies];
              }
            }
          }
        });
      }
      if (this.companies) {
        if (this.entity.companies.length) {
          //this.newCompaniesSelected = this.companies.filter((ca) => (this.entity.companies.findIndex((e) => (e.id === ca.id)) !== -1));
          this.companiesSelected = this.entity.companies.filter((ca) => (this.companies.findIndex((e) => (e.id === ca.id)) !== -1));
        }
      } else {
        this.companiesSelected = this.entity.companies;
      }
    }
  }

  isCompanySelected(company: CompanyActivity): boolean {
    return this.entity.companies.findIndex((c) => (c.id === company.id)) !== -1;
  }
  switchCompany(company: CompanyActivity, event): void {
    if (event && event.checked) {
      this.entity.companies.push(company);
    } else {
      const i = this.entity.companies.findIndex((c) => (c.id === company.id));
      if (i !== -1) {
        this.entity.companies.splice(i, 1);
      }
    }
    this.entity.companies.sort((a, b) => a.name.localeCompare(b.name));
  }
  /**
   * Add EE / EU in activity list
   * @param companies
   */
  onChangeCompany(companies: CompanyActivity[]): void {
    companies.forEach((company) => {
      const index = this.companiesSelected.findIndex(comp => comp.id === company.id);
      if (index === -1) {
        this.companiesSelected.push(company);
        this.entity.companies = this.companiesSelected;
      }
    });
    this.companiesSelected.forEach((companySelected) => {
      const index = companies.findIndex(comp => comp.id === companySelected.id);
      if (companySelected && index === -1) {
        const removeIndex = this.companiesSelected.findIndex(comp => comp.id === companySelected.id);
        this.companiesSelected.splice(removeIndex, 1);
        this.entity.companies = this.companiesSelected;
      }
    })
  }

  onChangeSize(event: CompanyActivity) {
    const index = this.companiesSelected.findIndex(comp => comp.id === event.id);
    if (index !== -1) {
      this.companiesSelected[index] = event;
      this.entity.companies = this.companiesSelected;
    }
  }

  compareFn(ee1: CompanyActivity, ee2: CompanyActivity): boolean {
    return ee1 && ee2 ? ee1.id === ee2.id : ee1 === ee2;
  }
  getTemplateStepInfo(template_id: string) {
    return this.template_steps[template_id]
      ? this.template_steps[template_id]
        .sort((a, b) => ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime()))
        .map((s) => (formattedDate(s.dateStart) + '-' + s.name))
        .join('\n')
      : '';
  }
  hasStepSelected() {
    return Object.keys(this.selectedSteps).filter((step_id) => (!!this.selectedSteps[step_id])).length > 0
  }
  stepSelectedNumber() {
    const steps = this.steps.map((e) => (e._id))
    return Object.keys(this.selectedSteps).filter((step_id) => (steps.indexOf(step_id) !== -1 && !!this.selectedSteps[step_id])).length;
  }
  templateSelectedNumber() {
    const steps = this.templates.map((e) => (e._id))
    return Object.keys(this.selectedSteps).filter((step_id) => (steps.indexOf(step_id) !== -1 && !!this.selectedSteps[step_id])).length;
  }
  hasCompanySelected() {
    return this.entity && this.entity.companies && this.entity.companies.length > 0;
  }
}