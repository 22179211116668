import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractAuth0Service} from './absctractAuth0.service';
import {Auth0ConfigService} from './auth0-config.service';

@Injectable()
export class Auth0Service extends AbstractAuth0Service {
  access_token: string;
  id_token: string;

  constructor(
    protected config: Auth0ConfigService,
    protected http: HttpClient
  ) {
    super();
    // if (this.config.data && this.config.data.auth0) {
    //   this.load(config.data.auth0);
    // }
  }

  protected _setSession(authResult): void {
    // Set the time that the access token will expire at
    this.access_token = authResult.accessToken;
    this.id_token = authResult.idToken;

  }
  protected _afterAthentication(authResult): void {}

  public getAccessToken() {
    return this.access_token;
  }

  public getIdToken() {
    return this.id_token;
  }

  protected getAPICall(endpoint, params = {}): Observable<any> {
    return this.getAPI(endpoint, params = {}, this.getAccessToken());
  }

  protected getAPI(endpoint, params = {}, access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + access_token
      }),
      params: params
    };
    const url = `https://${this.auth0Config.domain}/api/v2/${endpoint}`;
    return this.http.get(url, httpOptions);
  }

  protected patchAPICall(endpoint, data: any, params = {}): Observable<any> {
    return this.patchAPI(endpoint, data, params = {}, this.getAccessToken());
  }

  protected patchAPI(endpoint, data: any, params = {}, access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token}),
      params: params
    };
    const url = `https://${this.auth0Config.domain}/api/v2/${endpoint}`;
    return this.http.patch(url, data, httpOptions);
  }

  protected postAPICall(endpoint, data: any, params = {}): Observable<any> {
    return this.postAPI(endpoint, data, params = {}, this.getAccessToken());
  }

  protected postAPI(endpoint, data: any, params = {}, access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + access_token}),
      params: params
    };
    const url = `https://${this.auth0Config.domain}/api/v2/${endpoint}`;
    return this.http.post(url, data, httpOptions);
  }

  protected deleteAPICall(endpoint, params = {}): Observable<any> {
    return this.deleteAPI(endpoint, params = {}, this.getAccessToken());
  }
  protected deleteAPI(endpoint, params = {}, access_token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + access_token
      }),
      params: params
    };
    const url = `https://${this.auth0Config.domain}/api/v2/${endpoint}`;
    return this.http.delete(url, httpOptions);
  }
  getTicket(endpoint, userId, result_url = '', ttl = 2592000, access_token = null, data = {}): Observable<any> {
    if (!result_url) {
      result_url = window.location.origin;
    }
    data['result_url'] = result_url;
    data['user_id'] = userId;
    data['ttl_sec'] = ttl;
    return access_token ? this.postAPI('tickets/' + endpoint, data, {}, access_token) : this.postAPICall('tickets/' + endpoint, data);

  }

  public checkSession(audience, scopes) {
    return new Promise<any>((resolve, reject) => {
      this.webAuth.checkSession(
        {
          audience: audience,
          scope: scopes.join(' ')
        }, function (err, authResult) {
          if (authResult && authResult.accessToken && authResult.idToken) {
            resolve(authResult);
          } else if (err) {
            console.error('Error parse auth', err);
            reject(err);
          } else {
            resolve(false);
          }
        });
    })
  }
}
