import {Component, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';


import {LoggerService, DocumentFileService} from 'app/shared';
import {
  PreventionPlanService, EntityWorkbook,
  IcpRecurrenceService,
  OfficeService, ExternalCompanyService, OrganizationService, SiteService
} from 'app/workbook-core';
import {StatsService} from './stats.service';
import {WorkbooksStatsCommonComponent} from './workbooksStatsCommon.component';



@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-workbooks-statsStep',
  templateUrl: './workbooksStats.component.html',
  styleUrls: ['./workbooksStats.component.scss']
})

export class WorkbooksStatsStepComponent extends WorkbooksStatsCommonComponent {

  activeNav = 2;

  availableRows: string[] = [
    'step_name',
    'step_start',
    'step_end',
    'wb_code',
    'wb_type',
    'wb_name',
    'wb_ref',
    'wb_status',
    'wb_editor',
    'wb_created',
    'wb_start',
    'wb_end',
    'wb_site',
    'wb_eex'
  ];
  availableCols: string[] = [
    'step_name',
    'step_start',
    'step_end',
    'wb_code',
    'wb_type',
    'wb_name',
    'wb_ref',
    'wb_status',
    'wb_editor',
    'wb_created',
    'wb_start',
    'wb_end',
    'wb_site',
    'wb_eex'
  ];
  availableDatas: string[] = [
    'step_quantity',
    'step_icp_quantity',
    'step_pdp_quantity'
  ];
  availableChartTypes = StatsService.ALL_CHARTS;//['bar', 'stackedbar', 'line', 'spline', 'fullstackedbar'];
  chartType = 'bar';
  fieldsRows: string[] = ['extra_0', 'wb_type'];
  fieldsCols: string[] = ['step_start'];
  fieldsDatas: string[] = ['step_quantity'];

  displayedSourceColumns: string[] = [
    'wb_code',
    'wb_type',
    'wb_status',
    'wb_editor',
    'wb_dates',
    'step',
    'step_icp_quantity',
    'step_pdp_quantity'
  ];

  constructor(
    protected _documentFileService: DocumentFileService,
    protected _organizationService: OrganizationService,
    public statsService: StatsService,
    //preload
    protected _spinnerService: Ng4LoadingSpinnerService,
    //private _auth: AuthService,
    protected _cd: ChangeDetectorRef,
    protected _externalCompanyService: ExternalCompanyService,
    protected _officeService: OfficeService,
    protected _siteService: SiteService,
    protected _icpRecurrenceService: IcpRecurrenceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService//WorkbookService
  ) {
    super(_documentFileService, _organizationService, statsService, _spinnerService, _cd, _externalCompanyService, _officeService, _siteService, _icpRecurrenceService, dialog, snackBar, _router, _logger, _entityService);
  }
  //_init(data) {
  //  super._init(data);
  //}

  getSource(list: EntityWorkbook[]) {
    const src = [];
    list.forEach((wb) => {
      const wbObj = {
        wb_code: wb.name,
        wb_name: wb.description,
        wb_type: (wb.type === 'annual') ? (wb.simple ? 'Protocole' : 'Périodique') : 'Occasionnel',
        wb_status: wb.status,
        wb_editor: wb.editor,
        wb_ref: wb.reference,
        wb_created: new Date(wb.createdAt),
        wb_start: new Date(wb.dateStart),
        wb_end: new Date(wb.dateEnd),
        wb_site: wb.site ? wb.site.name : '',
        wb_eex: Object.keys(wb.eexs)
      };
      this.organizationList.forEach((oz, index) => {
        wbObj['extra_' + index] = (
          this.userOffices[wb.editorId] && this.userOffices[wb.editorId].length
          && this.parentFields[this.userOffices[wb.editorId][0]] && this.parentFields[this.userOffices[wb.editorId][0]][oz])
          ? this.parentFields[this.userOffices[wb.editorId][0]][oz]
          : 'Aucun';
      });
      if (wb.inspections) {
        Object.keys(wb.inspections).forEach((step_id) => {
          const stepObj = Object.assign({}, wbObj);
          stepObj['step_name'] = wb.inspections[step_id].name;
          stepObj['step_start'] = wb.inspections[step_id].dateStart;
          stepObj['step_end'] = wb.inspections[step_id].dateEnd;
          stepObj['step_icp_quantity'] = this.getStepIcpQuantity(wb.inspections[step_id].scheduled);
          if (wb.pdps && wb.pdps[step_id] && wb.pdps[step_id].editions) {
            stepObj['step_pdp_quantity'] = this.getStepPdPQuantity(wb.pdps[step_id].editions);
          }
          src.push(stepObj);
        });
      }
    });
    return src;
  }

  getStepIcpQuantity(scheduled: {
    [icp_id: string]: {
      name: string,
      date: Date,
      status: number,
      steps: string[]
    }
  }) {
    let all: string[] = [];
    Object.keys(scheduled).forEach((icp_id) => {
      if (scheduled[icp_id].status > 0) {
        all.push(scheduled[icp_id].name);
      }
    });
    return all.length;
  }
  getStepPdPQuantity(editions: {
    [pdp_id: string]: {
      name: string,
      date: Date,
      status: number,
      steps: string[],
      icps: string[]
    }
  }) {
    let all: string[] = [];
    Object.keys(editions).forEach((pdp_id) => {
      if (editions[pdp_id].status > 0) {
        all.push(editions[pdp_id].name);
      }
    });
    return all.length;
  }
}
