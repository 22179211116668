import {UUID} from 'angular2-uuid';
import {EntitySignable, SignableStatus, formattedDate, Contact, EntityDocumentMetadata} from 'app/shared';
import {EntitySite, EntityOffice} from '../../repository';

export class AuditState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Obsolète',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est validée',
    'L\'edition a été signée',
    'L\'edition est obsolète, une edition plus récente a été validée',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return AuditState.LABELS;
  }
  static getDescriptions() {
    return AuditState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return AuditState.LABELS[status] ? AuditState.LABELS[status] : 'state' + status;
  }
  static getDescription(status: number) {
    return AuditState.DESCRIPTIONS[status] ? AuditState.DESCRIPTIONS[status] : 'state: ' + status;
  }
}

export class AuditStatus extends SignableStatus {
  static SIGNING: number = 2;
  static SIGNED: number = 3;
  static OUTDATED: number = 4;
  static LABELS: string[] = [
    'En préparation',
    'Préparé',
    'À signer',
    'Signé',
    'Cloturé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est cloturée'
  ];

  static getLabels(withSignatories: boolean = true) {
    return AuditStatus.LABELS;
  }
  static getDescriptions(withSignatories: boolean = true) {
    return AuditStatus.DESCRIPTIONS;
  }
  static getLabel(status: number, withSignatories: boolean = true) {
    return AuditStatus.LABELS[status] ? AuditStatus.LABELS[status] : 'status' + status;
  }
  static getDescription(status: number, withSignatories: boolean = true) {
    return AuditStatus.DESCRIPTIONS[status] ? AuditStatus.DESCRIPTIONS[status] : 'status: ' + status;
  }
}
export class AuditCheckpointCompliance {
  static NC: number = 0;
  static COMPLIANT: number = 1;
  static IMPROPER: number = 2;
}
export class AuditSignatory {
  public id: string;
  public contact: Contact;
  public company: EntityOffice;
  public user_id: string;
  public local: boolean;
  constructor(data: any = {}) {
    this.id = (data && data.id) ? data.id : UUID.UUID();
    this.contact = (data && data.contact) ? Object.assign(new Contact(), data.contact) : null;
    this.company = (data && data.company) ? new EntityOffice(data.company) : null;
    this.user_id = (data && data.user_id) ? data.user_id : '';
    this.local = !!(data && data.local);
  }
}
export class AuditCheckpoint {
  id: string;
  activity_id: string;
  activity_name: string;
  activity_group: string;
  risk_name: string;
  measure_id: string;
  company_id: string;
  auditCheckpoint_id: string;
  //default: boolean;
  name: string;
  //action a mettre en place
  description: string;
  responsable: string;
  //Avant le
  limit: Date;
  prior: boolean;
  after: boolean;
  compliance: number;
  //  audited: string[];
  remark: string;
  document: EntityDocumentMetadata;
  constructor(data: any = {}) {
    this.id = (data && data.id) ? data.id : UUID.UUID();
    this.activity_id = (data && data.activity_id) ? data.activity_id : '';
    this.activity_name = (data && data.activity_name) ? data.activity_name : '';
    this.activity_group = (data && data.activity_group) ? data.activity_group : '';
    this.risk_name = (data && data.risk_name) ? data.risk_name : '';
    this.measure_id = (data && data.measure_id) ? data.measure_id : '';
    this.company_id = (data && data.company_id) ? data.company_id : '';
    this.auditCheckpoint_id = (data && data.auditCheckpoint_id) ? data.auditCheckpoint_id : '';
    //this.default = (data && !!data.default);
    //measure
    this.name = (data && data.name) ? data.name : 'Nouveau point de contrôle';
    this.prior = !!(data && data.prior);
    this.after = !!(data && data.after);
    //conformité
    this.compliance = (data && data.compliance) ? data.compliance : AuditCheckpointCompliance.NC;
    //commentaire
    this.remark = (data && data.remark) ? data.remark : '';
    //action
    this.description = (data && data.description) ? data.description : '';
    this.responsable = (data && data.responsable) ? data.responsable : '';
    this.limit = (data && data.limit) ? new Date(data.limit) : null;

    //this.audited = (data && data.audited) ? data.audited.map((e) => (e)) : [];
    this.document = (data && data.document) ? Object.assign({}, data.document) : null;

  }
}
export class EntityAudit extends EntitySignable {
  static PREFIX: string = 'VC';
  public editor: string;
  public editorEmail: string;
  public editorDate: Date;
  public date: Date;
  public hourStart: string;
  public hourEnd: string;

  public site: EntitySite;
  public zone: string;
  //Auditeur pendant travaux
  public auditor: EntityOffice;
  public auditorContact: Contact;
  public oEex: EntityOffice[];

  public remark: string;
  public step: string;
  public step_name: string;
  public pdp_id: string;
  public pdp_name: string;

  //public activities: string[];
  //public implementation: Implementation;

  public danger: boolean;
  public dangerRemark: string;
  public dangerExplanation: string;
  public dangerDescription: string;
  public checkpoints: AuditCheckpoint[];
  public locationCheckpoints: AuditCheckpoint[];
  //opérateurs audités
  public auditSignatories: AuditSignatory[];
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.editor = (data && data.editor) ? data.editor : '';
    this.editorEmail = (data && data.editorEmail) ? data.editorEmail : '';
    this.editorDate = (data && data.editorDate) ? new Date(data.editorDate) : null;
    this.date = (data && data.date) ? new Date(data.date) : null;
    this.hourStart = (data && data.hourStart) ? data.hourStart : '';
    this.hourEnd = (data && data.hourEnd) ? data.hourEnd : '';
    this.site = (data && data.site) ? Object.assign({}, data.site) : new EntitySite();
    this.zone = (data && data.zone) ? data.zone : '';
    this.auditor = (data && data.auditor) ? new EntityOffice(data.auditor) : new EntityOffice();
    this.auditorContact = (data && data.auditorContact) ? Object.assign({}, data.auditorContact) : new Contact();

    this.oEex = (data && data.oEex) ? data.oEex.map((e) => Object.assign({}, e)) : [];
    this.remark = (data && data.remark) ? data.remark : '';
    //this.activities = (data && data.activities) ? data.activities.map((e) => e) : [];
    this.step = (data && data.step) ? data.step : '';
    this.step_name = (data && data.step_name) ? data.step_name : '';
    this.pdp_id = (data && data.pdp_id) ? data.pdp_id : '';
    this.pdp_name = (data && data.pdp_name) ? data.pdp_name : '';
    //this.implementation = (data && data.implementation) ? Object.assign({}, data.implementation) : null;
    this.danger = !!(data && data.danger);
    this.dangerRemark = (data && data.dangerRemark) ? data.dangerRemark : '';
    this.dangerExplanation = (data && data.dangerExplanation) ? data.dangerExplanation : '';
    this.dangerDescription = (data && data.dangerDescription) ? data.dangerDescription : '';
    this.checkpoints = (data && data.checkpoints) ? data.checkpoints.map((e) => new AuditCheckpoint(e)) : [];
    this.locationCheckpoints = (data && data.locationCheckpoints) ? data.locationCheckpoints.map((e) => new AuditCheckpoint(e)) : [];
    this.auditSignatories = (data && data.auditSignatories) ? data.auditSignatories.map((e) => new AuditSignatory(e)) : [];
  }
  getTitle() {
    return "Visite de contrôle du " + formattedDate(this.date);
  }
  getSynthesis() {
    const synthesis: {[oeex_id: string]: {name: string, count: number, compliant: number, improper: number, nc: number}} = {};
    [...this.checkpoints, ...this.locationCheckpoints].forEach((cp) => {
      if (!synthesis[cp.company_id]) {
        const oeex = this.oEex.find((o) => (o._id === cp.company_id));
        synthesis[cp.company_id] = {
          name: oeex ? oeex.name : '',
          count: 0,
          compliant: 0,
          improper: 0,
          nc: 0
        }
      }
      synthesis[cp.company_id].count++;
      if (cp.compliance === AuditCheckpointCompliance.COMPLIANT) {
        synthesis[cp.company_id].compliant++;
      }
      if (cp.compliance === AuditCheckpointCompliance.IMPROPER) {
        synthesis[cp.company_id].improper++;
      }
      if (cp.compliance === AuditCheckpointCompliance.NC) {
        synthesis[cp.company_id].nc++;
      }
    });
    return synthesis;
  }

  getInlineDocuments(): EntityDocumentMetadata[] {
    return [
      ...[...this.checkpoints, ...this.locationCheckpoints]
        .filter((e) => (e.document && e.document.data_id))
        .map((e) => (e.document)),
      ...super.getInlineDocuments()
    ];
  }
}
