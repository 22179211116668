import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//import {FormsModule} from '@angular/forms';
import {SharedModule} from 'app/shared';
import {WorkbookCoreModule} from 'app/workbook-core';
//import {WorkbookSharedModule} from 'app/workbook-shared';
import {WorkbookListComponent} from './workbook';
import {SignablesComponent, SignableComponent} from './signable';
import {SignablesToolbarComponent} from './toolbar';
import {DownloadComponent} from './download.component';
import {DownloadCheckpointComponent} from './downloadCheckpoint.component';
import {WorkbookDownloadComponent} from './workbookDownload.component';
import {SignablesRoutingModule} from './signables-routing.module';

@NgModule({
  imports: [
    CommonModule,
    //    FormsModule,
    SharedModule,
    WorkbookCoreModule,
    //    WorkbookSharedModule,
    SignablesRoutingModule
  ],
  declarations: [
    SignablesComponent
    , SignableComponent
    , WorkbookListComponent
    , SignablesToolbarComponent
    , DownloadComponent, DownloadCheckpointComponent
    , WorkbookDownloadComponent
  ],
  entryComponents: [
  ],
  providers: [

  ]
})
export class SignablesModule {}
