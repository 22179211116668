import { Injectable, NgZone } from '@angular/core';
import { DbCommonService } from './dbCommon.service';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable()
export class DbLogsService extends DbCommonService {

  static DBCONFIG = 'couchDBLogs';
  writeOnly = true;
  selector = {
    "$not": {
      "_id": {
        "$regex": "^_design"
      }
    }
  };

  constructor(protected authService: AuthService, protected zone: NgZone) {
    super(authService, zone);
    this._init().catch((error) => {
      console.error(error);
    });

  }
}
