import {Entity} from 'app/shared';

export class EntitySituation extends Entity {
  public name: string;
  public description: string;
  public risk_id: string;
  public sir: boolean;
  public fp: boolean;
  public measures: string[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.description = (data && data.description) ? data.description : '';
    this.risk_id = (data && data.risk_id) ? data.risk_id : '';
    this.sir = (data && data.sir) ? data.sir : 0;
    this.fp = (data && data.fp) ? data.fp : 0;
    this.measures = (data && data.measures) ? data.measures.map((e) => (e)) : [];
  }
}
