import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import {UpgradeService} from '../../db/upgrade/upgrade.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit {

  constructor(protected _upgrade: UpgradeService,
    protected _snackbar: MatSnackBar) { }

  ngOnInit() {
    setTimeout(() => {
      this._upgrade.available().subscribe((upgrade) => {
        if (upgrade) {
          const snack = this._snackbar.open('Mise à jour de l\'application disponible', 'Mettre à jour');
          snack
            .onAction()
            .subscribe(() => {
              window.location.reload();
            });
          setTimeout(() => {
            snack.dismiss();
          }, 20000);
        }
      });
    });
  }

}
