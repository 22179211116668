import { Component } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoggerService, PermissionsService, getBase64, EntityFormComponent } from 'app/shared';
import { ExploitingCompanyService } from 'app/shared/services';
import { EntityExploitingCompany } from 'app/shared/models';

@Component({
  selector: 'app-config-company',
  templateUrl: './exploitingCompany.component.html',
  styleUrls: ['./exploitingCompany.component.scss']
})
export class ExploitingCompanyComponent extends EntityFormComponent {

  entity: EntityExploitingCompany;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: ExploitingCompanyService

  ) {
    super(dialog, _logger, _entityService);
  }

  _getCreateUserPromise(email, password = '', meta = {}, sendmail = true) {
    return this._permissionService.createExternalUser(email, password, meta, sendmail, ['PP_EEX']);
  }
  /**
   * Get the logo (base64) in config database
   * @param $event
   */
  public fileEvent(fileChangeEvent) {
    const maxHeight = 200;
    const maxWidth = 350;
    const that = this;
    const fileSelected: File = fileChangeEvent.target.files[0];
    getBase64(fileSelected).then(
      data => {
        let img = new Image();

        img.src = data.toString();
        img.onload = function(event) {
          let loadedImage = event.currentTarget as HTMLImageElement;
          let width = loadedImage.width;
          let height = loadedImage.height;

          if (width <= maxWidth && height <= maxHeight) {
            that.setLogo(data.toString(), fileChangeEvent);
          } else {
            let newWidth = width;
            let newHeight = height;
            if (width > maxWidth) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
            }
            if (newHeight > maxHeight) {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
            }
            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            let context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(function(blob) {
              getBase64(blob).then(
                (newdata) => {
                  that.snackBar.open('Image trop grande: Votre image a été redimensionnée (' + Math.round(newWidth) + 'x' + Math.round(newHeight) + ')', '', {
                    duration: 5000
                  });
                  that.setLogo(newdata.toString(), fileChangeEvent)
                });
            }, fileSelected.type);
          }
        }
      });
  }
  setLogo(logo: string, event) {
    this.entity.logo = logo;
    this._save();
    if (event && event.srcElement && event.srcElement.value) {
      event.srcElement.value = null;
    }
  }
}
