import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {SafeStyle} from '@angular/platform-browser';
import {
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService, EmailMessages,
  Contact, ISignature
} from 'app/shared';
import {
  PreventionPlanService
} from '../services';
import {
  SignablePermitService, PermitState,
  EntitySignablePermit, Implementation, PermitStatus
} from '../../workbook';
import {AttendanceGroup} from '../../repository';
import {AbstractPdpExportComponent} from './abstractPdPExport.component';

@Component({
  selector: 'app-signablePermit-export',
  templateUrl: './signablePermitExport.component.html',
  styleUrls: ['./signablePermitExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignablePermitExportComponent extends AbstractPdpExportComponent {

  SIGN_NOTIFY = EmailMessages.SIGN_SFP_CONTACT;
  entity: EntitySignablePermit;

  signableStatus = PermitStatus.LABELS;
  signableStatusDescritpions = PermitStatus.DESCRIPTIONS;

  implementation: Implementation;
  contributorSignup: {[group: string]: ISignature[]} = {};

  displayedOperatorsColumns: string[] = ['name', 'phone', 'extra', 'attendances', 'actions'];

  showPermitContents: {[groupName: string]: boolean} = {};

  contentPictos: {[groupName: string]: {[name: string]: string}} = {};

  permitContentContentEditionStyle: SafeStyle = '';
  permitContentContentGroupEditionStyle: SafeStyle[] = [];

  constructor(
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: SignablePermitService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (this.entity) {

      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._preventionPlanService.stepService.getOne(this.entity.step).subscribe((data) => {
        if (data) {
          this.implementation = data.implementation;
        }
      });
      this.operators = {};
      this.operatorGroups = [];
      this.attendances = {}
      if (this.entity.operators && Object.keys(this.entity.operators).length
        && this.entity.permitContent && this.entity.permitContent.operatorGroups && this.entity.permitContent.operatorGroups.length) {
        Object.keys(this.entity.operators).forEach((k) => {
          const attendanceGroup = this.entity.permitContent.operatorGroups.find((e: AttendanceGroup) => (e.name === k));
          if (attendanceGroup) {
            this.operators[k] = new MatTableDataSource(this.entity.operators[k]);
            this.operatorGroups.push(attendanceGroup);
            this.entity.operators[k].forEach((o) => {
              this.attendances[o.id] = this.entity.attendances.filter((e) => (e.id === o.id));
            });

          }
        });
      }
      if (this.entity.permitContent) {

        if (this.entity.permitContent.contentEditionStyle) {
          this.permitContentContentEditionStyle = this.bypassSecurityTrustStyle(this.entity.permitContent.contentEditionStyle);
        }
        if (this.entity.permitContent.contentGroups && this.entity.permitContent.contentGroups.length) {

          this.contentPictos = {};
          this.entity.permitContent.contentGroups.forEach((e) => {
            this.permitContentContentGroupEditionStyle.push(e.editionStyle ? this.bypassSecurityTrustStyle(e.editionStyle) : '');
            if (e.contents && e.contents.length) {

              this.contentPictos[e.name] = {}
              e.contents.forEach((c) => {
                if (c.picto) {
                  this.contentPictos[e.name][c.value] = c.picto;
                }
              });
            }
          });
        }
      }
      if (this.entity.permitContents) {
        Object.keys(this.entity.permitContents).forEach((group_name: string) => {
          this.showPermitContents[group_name] =
            !!this.entity.permitContents[group_name] &&
            !!Object.keys(this.entity.permitContents[group_name]).filter((e) => (!!this.entity.permitContents[group_name][e])).length;
        });
      }
      this.isValid = PermitState.isValid(this.entity.status, this.entity.dateStart, this.entity.dateEnd);
      this.isAttendanceManager = this.entity.isAttendanceManager(this.getUserId());
    }
  }
  refreshSignatories() {
    this.contributorSignup = this.getContributorSignup();
    // this.operatorSignup = this.getOperatorSignup();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }

  showOeex(id: string) {
    return this.entity &&
      Object.keys(this.entity.contributors).filter((k) => (this.entity.contributors[k].filter((c) =>
        (c.company && c.company._id === id)
      ).length > 0)).length > 0;
  }
  showInstructions(list) {
    return !!(list && list.length && list.filter((e) => (e.status)).length > 0);
  }
  _isSignatory(contact: Contact) {
    return contact.signatoryPermit;
  }
  getContributorSignup() {
    let existing: {[group: string]: ISignature[]} = {};
    if (this.entity && this.entity.permitContent && this.entity.contributors && this.entity.permitContent.contributorGroups && this.entity.signatures) {
      const signatoryGroups = this.entity.permitContent.contributorGroups.filter((e) => (e.isSignatory)).map((e) => (e.name));
      if (this.entity.signatures.length) {
        if (Object.keys(this.entity.contributors).length) {
          Object.keys(this.entity.contributors).forEach((contributorGroupName) => {
            if (!existing[contributorGroupName]) {
              existing[contributorGroupName] = [];
            }
            existing[contributorGroupName] = this.entity.signatures.filter((s) => (s.companyType === contributorGroupName));
          });
        }
      }
      if (Object.keys(this.entity.contributors).length) {
        Object.keys(this.entity.contributors)
          .filter((contributorGroupName) => (signatoryGroups.indexOf(contributorGroupName) !== -1))
          .forEach((contributorGroupName) => {
            this.entity.contributors[contributorGroupName].forEach((contributor) => {
              if (contributor.contact && contributor.company && (contributor.contact.name || contributor.contact.job) && (!existing[contributorGroupName] || existing[contributorGroupName].findIndex((e) => ((e.name === contributor.contact.name) && (e.job === contributor.contact.job))) === -1)) {
                if (!existing[contributorGroupName]) {
                  existing[contributorGroupName] = [];
                }
                existing[contributorGroupName].push(this._preventionPlanService.signatureService.getNewEntity({
                  parent_id: this.entity ? this.entity.parent_id : '',
                  company_id: (contributor.company && contributor.company._id) ? contributor.company._id : '',
                  company: (contributor.company && contributor.company.name) ? contributor.company.name : '',
                  companyType: contributorGroupName,
                  contact_id: (contributor.contact && contributor.contact.id) ? contributor.contact.id : '',
                  email: contributor.contact.email,
                  name: contributor.contact.name,
                  job: contributor.contact.job,
                  user_id: contributor.contact.user_id
                }));
              }
            });
          });
      }
      return existing;
    }
  }
}
