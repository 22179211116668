import {UploadServerModel, UploadDbModel} from 'app/shared/upload/upload.model';
import {UserRole} from 'app/shared/auth/userRole.model';
import {EmailConfigModel} from './email.model';
import {TrepiedServerModel} from './trepied.model';

export class ConfigModel {
  public _id: string;
  public _rev: string;
  public documentType: string;
  public logging: boolean;
  public logDb: boolean;
  public logLevel: string;
  public uploadDb: UploadDbModel;
  public uploadServer: UploadServerModel;
  public trepiedServer: TrepiedServerModel;
  public email: EmailConfigModel;
  public permissions: UserRole[];

  constructor(configData: any = {}) {
    if (configData) {
      this._id = configData._id;
      this._rev = configData._rev;
      this.documentType = configData.documentType || 'config';
      this.logging = configData.logging || false;
      this.logDb = configData.logDb || false;
      this.logLevel = configData.logLevel || 'error';
      this.uploadDb = configData.uploadDb || new UploadDbModel();
      this.uploadServer = configData.uploadServer || new UploadServerModel();
      this.trepiedServer = configData.trepiedServer || new TrepiedServerModel();
      this.email = configData.email || new EmailConfigModel();
      this.permissions = configData.permissions || [];
    }
  }
}
