import { OnInit } from '@angular/core';
import { AuthService } from 'app/shared/auth';
import { LoggerService } from 'app/shared/logger';
import { CommonComponent } from './common.component';
import { IUser } from '../auth/user.model';

export abstract class AbstractAuthComponent extends CommonComponent implements OnInit {

  public userProfile: IUser;

  protected constructor(
    protected _auth: AuthService,
    protected _logger: LoggerService
  ) {
    super(_logger);
  }

  protected abstract _init()

  ngOnInit() {
    this._auth.retrieveProfile().then(
      (userProfile) => {
        this.userProfile = userProfile;
        this._init();
      },
      (err) => {
        this._logger.error('AbstractAuthComponent', 'Error retrieve profile', JSON.stringify(err));
      });
  }
  /**
   * Get the user id
   */
  getUserId() {
    return this._auth.getUserId();
  }
  getUserOffices() {
    return (this.userProfile
      && this.userProfile.user_metadata
      && this.userProfile.user_metadata.syncFilter) ? this.userProfile.user_metadata.syncFilter : [];
  }
}
