import {Injectable} from '@angular/core';
import {Auth0ConfigService} from './auth0-config.service';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';


@Injectable()
export class Auth0UsersService {

  protected token = '';

  constructor(
    protected config: Auth0ConfigService,
    private auth: AuthService,
    private http: HttpClient
  ) {
  }

  getUsersList(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getAccessToken()
      })
    };
    const url = `https://${this.config.data.auth0.domain}/api/v2/users?search_engine=v3&fields=user_id%2Cemail%2Cusername%2Cname%2Cnickname&include_fields=true`;
    return this.http.get(url, httpOptions);
  }
}
