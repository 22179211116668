import {Injectable} from '@angular/core';
import {DbConfigService} from 'app/shared/db';
import {EntityService} from 'app/shared/services/entity.service';
import {LoadingService} from 'app/shared/services/loading.service';
import {EntityConfigLogger} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class LoggerConfigService extends EntityService {

  //autoInit = false;

  static LABEL_ONE = "chargement du paramètrage Logger";
  static LABEL_ALL = "chargement des paramètrages Logger ?";
  static LABEL_CHILDREN = "chargement des paramètrages Logger de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètrage Logger";
  static LABEL_DELETE = "suppression du paramètrage Logger";


  static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'config_logger';

  static ENTITY_URL = 'config/logger';

  constructor(
    protected _dbService: DbConfigService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _loadingService);
  }
  factory(data): EntityConfigLogger {
    return new EntityConfigLogger(data);
  }

}
