import { Injectable, NgZone } from '@angular/core';
import { DbCommonService } from './dbCommon.service';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DbDataService extends DbCommonService {
  filter = 'app/only_parents';
  skipCheckBrokenSync = true;
  constructor(protected authService: AuthService, protected zone: NgZone) {
    super(authService, zone);
  }
}
