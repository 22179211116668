import { Injectable, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, timeout, map } from 'rxjs/operators';
import { PrintConfigService } from './printConfig.service';
import { LoggerService } from 'app/shared/logger/logger.service';
import { Auth0ConfigService, AuthService } from 'app/shared/auth/';
import { TrepiedServerModel } from '../models/trepied.model';


@Injectable()
export class PrintingService {

  configTrepiedServer: TrepiedServerModel;

  constructor(private _http: HttpClient,
    private _auth: AuthService,
    private _auth0Config: Auth0ConfigService,
    private _printConfig: PrintConfigService,
    private _logger: LoggerService) {
    this._printConfig.getSingleOne().subscribe((data) => {
      if (data) {
        this.configTrepiedServer = data;
      }
    },
      (e) => {
        this._logger.error('PrintingService', 'unable to get config', JSON.stringify(e));
      });
  }


  public generatePdf(printEl: ElementRef): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/html')
      .append('Authorization', `Bearer ${this._auth.getAccessToken()}`)
      .append('Domain', this._auth0Config.data.auth0.domain);

    const bodyHtml = printEl.nativeElement.innerHTML;
    return this._http.post(this.configTrepiedServer.url + '/generatepdf', bodyHtml, { headers, responseType: 'blob' }).pipe(
      timeout(50000),
      retry(this.configTrepiedServer.retry))
      .pipe(map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' })
        }), catchError(this.handleError));
  }

  private handleError(error: any) {
    const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(errMsg);
  }
}
