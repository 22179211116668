import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {RiskService, EntityRisk} from 'app/workbook-core';

@Component({
  selector: 'app-repository-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss']
})
export class RiskComponent extends EntityFormComponent {

  entity: EntityRisk;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: RiskService

  ) {
    super(dialog, _logger, _entityService);
  }

}
