import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {EntitySingleService} from 'app/shared/services/entitySingle.service';
import {LoadingService} from './loading.service';
import {EntityExtraConfig} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ExtraConfigService extends EntitySingleService {

  static LABEL_ONE = "chargement du paramètrage extra";
  static LABEL_ALL = "chargement des paramètrages extra ?";
  static LABEL_CHILDREN = "chargement des paramètrages extra de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètrage extra";
  static LABEL_DELETE = "suppression du paramètrage extra";

  static ENTITY_TYPE: string = 'extra_config';
  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showConfigs', 'showExtraConfig'],
    CREATE: ['editConfigs', 'editExtraConfig'],
    UPDATE: ['editConfigs', 'editExtraConfig'],
    DELETE: []
  }

  static ENTITY_URL = 'config/extra';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityExtraConfig {
    return new EntityExtraConfig(data, clone);
  }

}
