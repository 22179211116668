import {
  formattedDate,
  LoggerService,
  EntityComponent,
  EntityExploitingCompany
} from 'app/shared';
import {
  EntityOffice,
  EntityExternalCompany,
  RiskService, EntityRisk
} from '../../repository';
import {
  EntityWorkbook,
  EntityStep,
  EntityActivity,
  EntitySignature,
  EntityWorkbookMeasure, RiskEntry
} from '../models';
import {
  WorkbookService
} from '../services';

export class WorkbookExportComponent extends EntityComponent {

  entity: EntityWorkbook;

  exploitingCompany: EntityExploitingCompany;
  sign_agreement: string = '';

  steps: EntityStep[] = [];
  offices: EntityOffice[] = [];
  externalCompanies: EntityExternalCompany[] = [];
  activities: EntityActivity[] = [];
  risks: { [activity_id: string]: { [risk_name: string]: RiskEntry } } = {};
  signatoriesEex: EntitySignature[] = [];
  signatoriesOffice: EntitySignature[] = [];

  withSubcompanies: boolean = false;

  constructor(
    // protected _riskService: RiskService,
    protected _logger: LoggerService,
    protected _entityService: WorkbookService) {

    super(_logger, _entityService);
  }

  _init(data) {
    super._init(data);
    if (this.entity) {
      this._entityService.getExploitingCompany(this.entity).then((data) => {
        if (data) {
          this.exploitingCompany = data;
        }
      });
      this.sign_agreement = this.entity.signAgreement.split('\n').join('<br>');
      //this._riskService.getList().toPromise().then((riskList: EntityRisk[]) => {
      this._entityService.getCurrentStepList(this.entity._id)
        .then((list) => {
          this.steps = (this.entity.type === 'annual')
            ? list.filter((e) => e.template)
            : list;
        });
      this._entityService.getCurrentActivityList(this.entity._id)
        .then((list) => {
          this.activities = list;
          this.activities.forEach((activity: EntityActivity) => {
            if (activity && activity.measures) {
              const entry: { [risk_name: string]: RiskEntry } = {};
              activity.risks.forEach((risk) => {
                entry[risk._id] = new RiskEntry(risk.name, activity.measures.filter((measure) => (measure.risk_id === risk._id)));
              });
              /*


              activity.measures.forEach((measure: EntityWorkbookMeasure) => {
                if (measure.risk_id) {
                  const risk: EntityRisk = activity.risks.find((risk: EntityRisk) => (risk._id === measure.risk_id));
                  if (risk) {
                    if (!entry[risk.name]) {
                      entry[risk.name] = new RiskEntry(risk.requiresFirePermit);
                    }
                    entry[risk.name].measures.push(measure);
                  }
                }
              });
              */
              this.risks[activity._id] = entry;
            }
          });
        })
        .catch((error) => { this._logger.error('WorkbookExportComponent', 'Unable to retrieve activities', JSON.stringify(error)); });
      // })
      //   .catch((error) => {this._logger.error('WorkbookExportComponent', 'Unable to retrieve risks', error);});
      this._entityService.getCurrentOfficeList(this.entity._id)
        .then((list) => { this.offices = list; })
        .catch((error) => { this._logger.error('WorkbookExportComponent', 'Unable to retrieve offices', JSON.stringify(error)); });
      this._entityService.getCurrentExternalCompanyList(this.entity._id)
        .then((list) => {
          this.externalCompanies = list;
          this.externalCompanies.forEach((eex) => {
            this.withSubcompanies = this.withSubcompanies || !!eex.parent_id;
          })
        })
        .catch((error) => { this._logger.error('WorkbookExportComponent', 'Unable to retrieve externalCompanies', JSON.stringify(error)); });
      this._entityService.getCurrentSignatures(this.entity._id)
        .then((list) => {
          this.signatoriesOffice = list.filter((e) => (e.companyType === 'EE'));
          this.signatoriesEex = list.filter((e) => (e.companyType !== 'EE'));
        })
        .catch((error) => { this._logger.error('WorkbookExportComponent', 'Unable to retrieve signatories', JSON.stringify(error)); });

    }
  }

  /**
   * Format the date for export
   * @param d
   */
  public formattedDate(d: Date = new Date) {
    return formattedDate(d);
  }
  getStepLocations(step: EntityStep) {
    return step.getLocations();
  }

  getStepActivities(step: EntityStep) {
    if (step && step.activities) {
      return step.activities
        .map((activity_id) => this.activities.find((e) => e._id === activity_id))
        .filter((e) => (!!e));
    }
    return [];

  }
  getMeasureCompanies(measure: EntityWorkbookMeasure, activity: EntityActivity, step: EntityStep) {
    if (measure.global) {
      const returned = [];
      this.steps.forEach((s: EntityStep) => {
        s.activities.forEach((activity_id) => {
          const a = this.activities.find((e) => (e._id === activity_id));
          if (a) {
            a.companies.forEach((ca) => {
              if (returned.indexOf(ca.name) === -1) {
                returned.push(ca.name);
              }
            });
          }
        });
      });
      return returned;
    } else if (step.scopedMeasures && step.scopedMeasures[activity._id] && step.scopedMeasures[activity._id][measure._id]) {
      const oeex = [...this.offices, ...this.externalCompanies];
      return step.scopedMeasures[activity._id][measure._id]
        .map((company_id) => (oeex.find((e) => (e._id === company_id))))
        .filter((e) => !!e).map((e) => e.name);
    } else {
      return activity.companies.map((e) => e.name);
    }
  }

}
