import {Component, Input, OnInit} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {CommonComponent} from 'app/shared';


@Component({
  selector: 'app-show-scroll',
  templateUrl: './show-scroll.component.html',
  styleUrls: ['./show-scroll.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(800)
      ])
    ])
  ]
})
export class ShowScrollComponent implements OnInit {

  @Input() public next: string;

  constructor() {
    // console.log(this.next);
  }

  ngOnInit() {
    this.next = 'out';
  }


}
