import {Entity} from 'app/shared';
import {UUID} from 'angular2-uuid';

export class SubLdcm {
  public id: string;
  public name: string;
  public comments: string;
  public toggle: boolean;
  public checked: boolean;

  constructor(ppData: any) {
    if (ppData) {
      this.id = ppData.id || UUID.UUID();
      this.name = ppData.name ? ppData.name : '';
      this.toggle = !!ppData.toggle;
      this.checked = !!ppData.checked;
      this.comments = (ppData.comments && !this.toggle) ? ppData.comments : '';
    }
  }
}
export class EntityLdcm extends Entity {
  public name: string;
  public group: string;  // Locals, Documents, etc...
  public reliefOrg: SubLdcm[];
  public comments: string;
  public checked: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    if (data) {
      this.name = data.name ? data.name : '';
      this.group = data.group ? data.group : '';
      this.reliefOrg = data.reliefOrg ? data.reliefOrg.map((ldcmo: SubLdcm) => (new SubLdcm(ldcmo))) : [];
      this.comments = (data.comments && this.reliefOrg.length === 0) ? data.comments : '';
      this.checked = !!data.checked;
    }
  }
}
