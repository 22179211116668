import { Injectable, } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import PouchDB from 'pouchdb';
import { DbReadService } from './dbRead.service';

@Injectable()
export class DbReadWriteService extends DbReadService {

  constructor() {
    super();
  }

  /**
   * Save document attached
   * @param docId
   * @param rev
   * @param name
   * @param mime
   * @param attachment
   */
  saveAttachment(docId, rev, name, mime, attachment) {
    return this._saveAttachment(docId, rev, name, mime, attachment);
  };


  /**
   * save data
   * @param obj
   */
  save(obj) {
    return this._save(obj);
  };

  /**
   * Remove data
   * @param obj
   */
  remove(obj) {
    obj['_deleted'] = true;
    return this._save(obj);
  }

  /**
   * remove document attached
   * @param docId
   * @param rev
   * @param name
   */
  removeAttachment(docId, rev, name) {
    return this._removeAttachment(docId, rev, name);
  }

  /**
   * save data
   * @param obj
   * @param dbSuffix
   */
  protected _save(obj, dbSuffix: string = '') {
    if (obj['_revisions']) {
      delete obj['_revisions'];
    }
    if (obj['_conflicts']) {
      delete obj['_conflicts'];
    }
    if (obj['_rev_tree']) {
      delete obj['_rev_tree'];
    }
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap(db => db.put(obj)));
  };

  /**
   * TODO : test avec delta-pouchdb
   * create a new doc every time
   * save data
   * @param obj
   * @param dbSuffix
   */
  /* protected _save(obj, dbSuffix: string = '') {
     if (!obj.$id) {
       delete obj._id;
     }
     return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
       mergeMap(db => db.save(obj)));
   };*/

  /**
   * Remove data
   * @param obj
   * @param dbSuffix
   */
  protected _remove(obj, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap(db => db.remove(obj)));
  }

  /**
   * Save document attached in a couchDB document
   * @param docId
   * @param rev
   * @param name
   * @param mime
   * @param attachment
   * @param dbSuffix
   * @private
   */
  protected _saveAttachment(docId, rev, name, mime, attachment, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap(db => db.putAttachment(docId, name, rev, attachment, mime)));
  };

  /**
   * remove a document attached in a couchDB document
   * @param docId
   * @param rev
   * @param name
   * @param dbSuffix
   * @private
   */
  protected _removeAttachment(docId, rev, name, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap(db => db.removeAttachment(docId, name, rev)));
  }

  public createIndex(name: string, indexes: string[], dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap(db => db.createIndex({
        index: {
          fields: indexes,
          name: name
        }
      })));
  }
  protected _find(query, dbSuffix: string = '') {
    return (this[this.dbPrefix + dbSuffix] as ReplaySubject<PouchDB>).pipe(first(),
      mergeMap((db: PouchDB) => {
        //        console.log('query:', query);
        return db.find(query)
      }),
      map((res: any) => {
        if (res && res.warning) {
          console.warn('db.find:', [query, res]);
          if (res.warning === "no matching index found, create an index to optimize query time") {
            this.createIndex('index_parent_id_documentType', ['parent_id', 'documentType']).subscribe((res) => {
              if (res && res['result'] && res['result'] !== 'exists') {
                console.log('createIndex parent/documentType:', res);
              }
            });
          }
        }
        return res.docs;
      }));
  }
}
