import {Entity} from './entity.model';

export class EntityCompany extends Entity {
  public name: string;
  public address: string;
  public zipCode: string;
  public city: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.address = (data && data.address) ? data.address : '';
    this.zipCode = (data && data.zipCode) ? ('' + data.zipCode) : '';
    this.city = (data && data.city) ? data.city : '';
  }
}
