import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PermissionsService, DbFilteredService, EntitySignableService, LoadingService, SignableStatus } from 'app/shared';
import { EntityAudit, AuditCheckpoint, AuditState } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuditService extends EntitySignableService {

  autoInit = false;
  onlyChildren = true;

  static LABEL_ONE = "chargement de l'inspection";
  static LABEL_ALL = "chargement des inspections ?";
  static LABEL_CHILDREN = "chargement des inspections de l'opération";
  static LABEL_SAVE = "sauvegarde de l'inspection";
  static LABEL_DELETE = "suppression de l'inspection";

  static ENTITY_TYPE: string = 'audit';
  static ENTITY_FIELDS: string[] = ['name', 'code', 'editor', 'date', 'zone', 'site', 'hourStart', 'hourEnd', 'remark', 'oEex', 'step', 'status', 'withoutSignatories', 'implementations', 'signatures', 'auditor', 'auditorContact', 'auditSignatories', 'notifications'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showAudit'],
    CREATE: ['createAudit'],
    UPDATE: ['editAudit'],
    DELETE: ['removeAudit']
  }

  static ENTITY_URL = 'audit';

  static PREFIX = 'VC';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityAudit {
    return new EntityAudit(this.before_factory(data), clone);
  }
  getChildList(parent_id: string) {
    return super.getChildList(parent_id)
      .pipe(map(list => list.sort((a, b) => (new Date(a.date).getTime() === new Date(b.date).getTime())
        ? (a.hourStart ? a.hourStart.localeCompare(b.hourStart) : 1)
        : (new Date(a.date).getTime() > new Date(b.date).getTime()) ? -1 : 1)));

  }
  getState(entity: EntityAudit, workbook_status) {
    let state = (workbook_status && workbook_status === 'archived') ? AuditState.ARCHIVED : AuditState.DRAFT;
    if (!state && entity && entity.status) {
      if (entity.status === SignableStatus.VALIDATED) {
        state = AuditState.PREPARED;
      } else if (entity.status === SignableStatus.SIGNING && !entity.withoutSignatories) {
        state = AuditState.SIGNING;
      } else {
        if (entity.status === SignableStatus.OUTDATED) {
          state = AuditState.OUTDATED;
        } else {
          state = entity.withoutSignatories ? AuditState.VALIDATED : AuditState.SIGNED;
        }
      }
    }
    return state;
  }
}
