import {ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, EntityChildListComponent} from 'app/shared';
import {PreventionPlanService, EntityWorkbook} from 'app/workbook-core';


export abstract class WorkbookChildListComponent extends EntityChildListComponent {

  entity: EntityWorkbook
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cet élément ?';
  addConfirmMessage = 'Nouvel élément créé';

  @ViewChild('bottom') bottom: ElementRef;

  // used to slide menu button
  menuState: string = 'out';
  menuTabIndex: number = 0;

  today: Date;

  protected constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
    this.today = new Date();
  }
  protected _init(data) {
    super._init(data);
    this.updateRights();
  }
  updateRights() {
    const isModifiable = this.isModifiable();
    this.rightC = this.rightC && isModifiable;
    this.rightU = this.rightU && isModifiable;
    this.rightD = this.rightD && isModifiable;
    // console.log('isModifiable', [this.entity, isModifiable, this.rightC, this.rightU, this.rightD]);

  }
  public _isArchived() {
    return this.entity && this.entity.status === 'Archivé';
  }
  public isModifiable() {
    return this.entity && this.entity.isModifiable() && (!this.entity._id || this.isParentSync(this.entity._id));
  }
  /**
   * Used to close slide menu
   */
  closeSlideMenu() {
    this.menuState = 'out';
  }
  /**
  * Open the right slide menu
  * @param {string} tabIndex
  */
  toggleMenu(tabIndex: number = 0) {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    if (tabIndex) {
      this.menuTabIndex = tabIndex;
    }
  }
}
