import { Injectable, NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import { DbCommonService } from './dbCommon.service';
import { AuthService } from 'app/shared/auth/auth.service';

// DEBUGGING: enable/Disable pouchDB debug mode
// PouchDB.debug.enable('pouchdb:api');
// PouchDB.debug.disable();

@Injectable()
export class DbChildrenService extends DbCommonService {
  filter = 'app/children';
  parents: string[] = [];
  skipCheckBrokenSync: boolean = true;

  constructor(
    protected authService: AuthService,
    protected zone: NgZone
  ) {
    super(authService, zone);
  }
  setParents(parents: string[] = []) {
    const array2Sorted = parents.slice().sort();
    if (this.parents.length !== parents.length || !this.parents.slice().sort().every(function(value, index) {
      return value === array2Sorted[index];
    })) {
      this.parents = parents;
      this.query_params['parents'] = this.parents.join(',');
      this.reSyncDb();
    }
  }
}
