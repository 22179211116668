import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {PermissionsService, LoggerService, LicencesService} from 'app/shared';
import {WorkbookSidenavComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-prevention-plan-sidenav',
  templateUrl: '../../workbook-shared/sidenav/sidenav.component.html',
  styleUrls: ['../../workbook-shared/sidenav/sidenav.component.scss']
})
export class PreventionPlanSidenavComponent extends WorkbookSidenavComponent {
  name = 'sidenavpp';
  title = "PLAN DE PREVENTION";
  public activeModulePp: boolean = false;
  public activeModuleFp: boolean = false;


  constructor(
    protected _permissionsService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _licence: LicencesService) {
    super(_permissionsService, _router, _route, _logger, _licence);
  }

  _init() {
    super._init();
    this.activeModulePp = this._licence.hasValidLicence('pdp');
    this.activeModuleFp = this._licence.hasValidLicence('fp');
  }

}
