import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { comparableString, LoggerService } from 'app/shared';
import { WorkbookExternalCompanyService, EntityExternalCompany, JobService, EntityJob } from 'app/workbook-core';
import { WorkbookOfficeInfoComponent } from '../office';

@Component({
  selector: 'app-workbook-externalCompany-info',
  templateUrl: './externalCompanyInfo.component.html',
  styleUrls: ['./externalCompanyInfo.component.scss']
})
export class WorkbookExternalCompanyInfoComponent extends WorkbookOfficeInfoComponent implements OnChanges {

  autoSave = false;
  @Input() officeList: EntityExternalCompany[];
  public entity: EntityExternalCompany;
  public selectableOffices: EntityExternalCompany[];

  jobListSubscriber: Subscription;
  jobList: EntityJob[] = [];

  constructor(
    protected _jobService: JobService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: WorkbookExternalCompanyService
  ) {
    super(dialog, _logger, _entityService);
    this.jobListSubscriber = this._jobService.getAll().subscribe((list) => {
      if (list) {
        this.jobList = list.sort((a, b) => a.name.localeCompare(b.name));
      }
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.refreshSelectableOffices();
  }
  ngOnDestroy() {
    if (this.jobListSubscriber) {
      this.jobListSubscriber.unsubscribe();
    }
  }

  refreshSelectableOffices() {
    //eviter le premier appel de l'autocomplete qui passe office dans name...
    if (this.entity) {
      this.selectableOffices = this.officeList.filter((c: EntityExternalCompany) => {
        if (this.entity && this.entity.name && c && c.name) {
          return (
            // non filtré par name
            (!this.entity.name || comparableString(c.name).indexOf(comparableString(this.entity.name)) !== -1)
            // non filtré par job
            && (!this.entity.job || comparableString(c.job).indexOf(comparableString(this.entity.job)) !== -1)
            // non filtré par city
            && (!this.entity.city || comparableString(c.city).indexOf(comparableString(this.entity.city)) !== -1)
            // non filtré par phone
            && (!this.entity.zipCode || c.zipCode.indexOf(this.entity.zipCode) !== -1)
          );
        } else {
          return true;
        }
      }
      );
      if (this.selectableOffices.length > 200) {
        this.selectableOffices = [];
      }
    }
  }
}
