import {ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {Subscription} from 'rxjs';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityActivity, EntityStep, EntityOffice, EntitySite, EntityZone} from 'app/workbook-core';
import {WorkbookChildListComponent} from '../abstractWorkbookChildList.component';
import {WorkbookActivityDialogComponent} from './activity-dialog.component';
import {WorkbookActivityComponent} from './activity.component';

export class WorkbookActivityListComponent extends WorkbookChildListComponent {
  @ViewChildren(WorkbookActivityComponent) activityForms: QueryList<WorkbookActivityComponent>;
  entityList: EntityActivity[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette activité ?';
  addConfirmMessage = 'Nouvelle activité créée';

  activityRanking: {[id_activity: string]: number} = {};
  //  allTemplates: boolean = true;
  //  allSteps: boolean = true;
  allStepsTemplates: boolean = true;
  allCompanies: boolean = true;

  activitiesWithoutStep: string[] = [];
  withActiveSteps: boolean = false;
  filteredList: string[] = [];
  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  selectedSteps: string[] = [];
  completedSteps: {[step_id: string]: boolean} = {};
  protected _stepsSubscription: Subscription;
  allSteps: {[step_id: string]: EntityStep} = {};

  companies: EntityOffice[] = [];
  selectedCompanies: string[] = [];
  protected _companiesSubscription: Subscription;

  isCollapsed: {[id: string]: boolean} = {};
  collapseAll: boolean = false;
  allTextCollapse: string = 'Masquer les risques';
  isEndemicCollapsed: {[id: string]: boolean} = {};
  collapseAllEndemic: boolean = false;
  allEndemicTextCollapse: string = 'Masquer les risques';

  locations: {[step_id: string]: {[site_id: string]: EntityZone[]}} = {};
  sites: {[step_id: string]: EntitySite[]} = {};

  tabIndex: number = 0;
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (data) {
      this.filter();
      const newCollapse: {[id: string]: boolean} = {};
      this.entityList.forEach((e) => {
        newCollapse[e._id] = !!this.isCollapsed[e._id];
      });
      this.isCollapsed = newCollapse;
    }
    this._cd.markForCheck();
  }

  protected _getChildService() {
    return this._entityService.activityService;
  }
  protected _entityUpdate(data) {
    super._entityUpdate(data);
    if (this.entity) {
      if (!this._stepsSubscription) {
        this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list: EntityStep[]) => {
          if (list) {
            this._updateSteps(list.filter((e) => (!e.template_id)));
            this.filter();
          }
        });
      }
      if (!this._companiesSubscription) {
        this._companiesSubscription = this._entityService.getOEexList(this.entity._id).subscribe((list) => {
          if (list) {
            this.companies = list;
          }
        });
      }
    }
    this._cd.markForCheck();
  }
  protected _updateLocation(step: EntityStep, site: EntitySite, zone: EntityZone) {
    if (step && step._id && site && site._id && zone && zone._id) {
      if (!this.sites[step._id]) {
        this.sites[step._id] = []
      }
      const s = this.sites[step._id].findIndex((e) => (e._id === site._id));
      if (s === -1) {
        this.sites[step._id].push(site);
      } else {
        this.sites[step._id][s] = site;
      }
      if (!this.locations[step._id]) {
        this.locations[step._id] = {}
      }

      if (!this.locations[step._id][site._id]) {
        this.locations[step._id][site._id] = [];
      }
      const z = this.locations[step._id][site._id].findIndex((e) => (e._id === zone._id));
      if (z === -1) {
        this.locations[step._id][site._id].push(zone);
      } else {
        this.locations[step._id][site._id][z] = zone;
      }
      this.isEndemicCollapsed[zone._id] = !!this.isEndemicCollapsed[zone._id];
      this._cd.markForCheck();
    }
  }
  protected _updateStepLocations(step: EntityStep) {
    if (step && step.site/* && step.zone*/) {
      //this._updateLocation(step, step.site, step.zone);
      if (step.site.zones && step.site.zones.length) {
        step.site.zones.forEach((z) => {
          this._updateLocation(step, step.site, z);
        });
      }
      if (step.template && step.locations && step.locations.length) {
        step.locations.forEach((site) => {
          if (site.zones && site.zones.length) {
            site.zones.forEach((zone) => {
              this._updateLocation(step, site, zone);
            });
          }
        });
      }
      this._cd.markForCheck();
    }
  }
  protected _updateSteps(list: EntityStep[]) {
    if (list) {
      this.templates = [];
      this.steps = [];
      this.completedSteps = {};
      this.allSteps = {};
      const now = (new Date()).getTime();
      list
        .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()))
        .forEach((e) => {
          this.allSteps[e._id] = e;
          this.completedSteps[e._id] = e.isComplete();//(now > (new Date(e.dateEnd)).getTime());
          if (e.template) {
            this.templates.push(e);
            this._updateStepLocations(e);
          } else {//if (!e.template_id) {
            this.steps.push(e);
            /*
            if (this.entity && this.entity.simple) {
              this.selectedSteps = [this.entity._id];
            }
            */
            this._updateStepLocations(e);
          }
          this.withActiveSteps = this.withActiveSteps || (!e.template_id && !this.completedSteps[e._id]);
        });
      this.updateRights();
      this._cd.markForCheck();
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._companiesSubscription) {
      this._companiesSubscription.unsubscribe();
    }
  }
  /**
     * Open popup dialog to create a activity
     * @param {Activity} activity
     */
  addActivity(entity: EntityActivity = null) {
    let groups: string[] = [];
    if (this.entityList && this.entityList.length) {
      this.entityList.forEach((activity) => {
        if (activity && activity.group && groups.indexOf(activity.group) === -1) {
          groups.push(activity.group);
        }
      });
    }
    const defaultstep = this.selectedSteps.filter((e) => (!this.completedSteps[e]));
    const dialogRef = this.dialog.open(WorkbookActivityDialogComponent, {
      disableClose: true,
      minWidth: '600px',
      width: '900px',
      data: {
        entity: entity ? this._entityService.activityService.getNewEntity(entity, true) : null,
        parent_id: this.entity._id,
        workbook: this.entity,
        groups: groups,
        default_step_id: ((defaultstep.length === 1) ? defaultstep[0] : null),
        showGroup: (entity && !!entity.group)
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      //this._entityUpdate(c);
    });
  }
  /**
   * Fold/Unfold the risks part for all activities
   */
  maskRisks() {
    this.collapseAll = !this.collapseAll;
    this.allTextCollapse = this.collapseAll ? 'Tout afficher' : 'Tout masquer';
    Object.keys(this.isCollapsed).forEach((id) => {
      this.isCollapsed[id] = this.collapseAll;
    });
  }
  maskEndemicRisks() {
    this.collapseAllEndemic = !this.collapseAllEndemic;
    this.allEndemicTextCollapse = this.collapseAllEndemic ? 'Tout afficher' : 'Tout masquer';
    Object.keys(this.isEndemicCollapsed).forEach((id) => {
      this.isEndemicCollapsed[id] = this.collapseAllEndemic;
    });
  }
  isHidden(id) {
    if (this.selectedSteps.length || this.selectedCompanies.length) {
      return this.filteredList.indexOf(id) === -1;
    }
    return false;
  }
  filter() {
    this._spinnerService.show();
    this.filteredList = [];
    const activityRanking = {};
    this.allStepsTemplates = true;
    //    this.allSteps = true;
    //    this.allTemplates = true;
    const selectedActivities = [];
    [...this.templates, ...this.steps].forEach((step) => {
      step.activities.forEach((id) => {
        if (selectedActivities.indexOf(id) === -1) {
          selectedActivities.push(id);
        }
      });
    });
    this.activitiesWithoutStep = this.entityList.map((a) => (a._id)).filter((a_id) => (selectedActivities.indexOf(a_id) === -1));
    if (this.selectedSteps.length) {
      this.selectedSteps.forEach((id) => {
        if (id === '') {
          this.filteredList = [...this.filteredList, ...this.activitiesWithoutStep];
        } else {

          this.allStepsTemplates = false;
          let step = this.steps.find((e) => (e._id === id));
          if (!step) {
            step = this.templates.find((e) => (e._id === id));
            // this.allTemplates = false;
          }                    // this.allSteps = false;
          //}

          if (step && step.activities && step.activities.length) {
            this.filteredList = [...this.filteredList, ...step.activities];
            step.activities.forEach((id, i) => {
              if (!activityRanking[id] || activityRanking[id] > i) {
                activityRanking[id] = i + 1;
              }
            });
          }
        }
      });
    } else {
      [...this.templates, ...this.steps].forEach((s) => {
        s.activities.forEach((id, i) => {
          if (!activityRanking[id] || activityRanking[id] > i) {
            activityRanking[id] = i + 1;
          }
        });
      });
    }
    this.entityList = this.entityList.sort((a, b) => (
      !activityRanking[a._id]
        ? (activityRanking[b._id] ? 1 : 0)
        : (!activityRanking[b._id] ? -1 : (activityRanking[a._id] - activityRanking[b._id])
        )));
    this.allCompanies = (this.selectedCompanies.length === 0);
    if (this.selectedCompanies.length) {
      let fl = [];
      this.selectedCompanies.forEach((id) => {
        fl = [...fl, ...this.entityList.filter((elem) => (elem.companies.findIndex((x) => (x.id === id)) > -1)).map((e) => (e._id))];
      });
      if (this.selectedSteps.length) {
        this.filteredList = this.filteredList.filter(value => -1 !== fl.indexOf(value));
      } else {
        this.filteredList = fl;
      }
    }
    this.updateRights();
    this._cd.markForCheck();
    setTimeout(() => {this._spinnerService.hide()});
  }

  unselectedCompanies(e) {
    e.stopPropagation();
    this.selectedCompanies = [];
    this.filter();
    return false;
  }
  /*
  unselectedTemplates(e) {
    e.stopPropagation();
    this.selectedSteps = this.selectedSteps.filter((id) => {
      return this.templates.findIndex((step) => (step._id === id)) === -1;
    });
    this.filter();
    return false;
  }
  unselectedSteps(e) {
    e.stopPropagation();
    this.selectedSteps = this.selectedSteps.filter((id) => {
      return this.steps.findIndex((step) => (step._id === id)) === -1;
    });
    this.filter();
    return false;
  }
  */
  unselectedAllSteps(e) {
    e.stopPropagation();
    this.selectedSteps = [];
    this.filter();
    return false;
  }

  onSplit(data: {activity: EntityActivity, risk_id: string, situation_id: string}) {
    this.filter();
    setTimeout(() => {this.openRisksDialog(data.activity, data.risk_id, data.situation_id)});
  }

  openRisksDialog(activity: EntityActivity, risk_id: string = '', situation_id: string = '') {
    if (this.activityForms) {
      const i = this.activityForms.toArray().findIndex((x) => (x.entity && x.entity._id === activity._id));
      if (i !== -1) {
        this.activityForms.toArray()[i].openRisksDialog(risk_id, situation_id);
      }
    }
  }
  getActivityTooltip() {
    let msg = '';
    if (this.entity && this.entity.isComplete()) {
      msg = 'Ce dossier est terminé';
    } else if (!this.rightC) {
      msg = 'Vous n\'avez pas les droits pour créer une nouvelle activité';
    } else if (this.withActiveSteps) {
      if ((!this.selectedCompanies || !this.selectedCompanies.length) && (!this.selectedSteps || !this.selectedSteps.length)) {
        msg = (this.templates.length ? ' un chantier type' : '')
          + (this.templates.length && this.steps.length ? ',' : '')
          + (this.steps.length && !(this.entity && this.entity.simple) ? ' une intervention' : '')
          + (this.steps.length && this.entity && this.entity.type === 'annual' ? ' spécifique' : '')
          + (!(this.entity && this.entity.simple) && (this.templates.length || this.steps.length) ? ' ou' : '')
          + ((!this.entity.simple) ? ' un intervenant pour pouvoir y ajouter une nouvelle activité.' : '');
        if (msg) {
          msg = 'Veuillez sélectionner'
            + msg
        }
      } else if (!this.companies.length && !this.entity.simple) {
        msg = 'Vous devez saisir au moins un intervenant pour pouvoir créer des activités.';
      }
    } else {
      msg = 'Aucune intervention' + ((this.entity && this.entity.type === 'annual') ? ' ou chantier type' : '') + ' sélectionnable';
    }
    return msg;
  }
  updateZone(event) {
    //console.log('updateZone', event)
  }
}
