import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MatAutocompleteSelectedEvent, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {SiteService, EntitySite, EntityZone, WorkbookStepService, EntityStep} from 'app/workbook-core';
import {LoggerService, AbstractEntityServiceComponent, comparableString} from 'app/shared';


@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class LocationDialogComponent extends AbstractEntityServiceComponent implements OnDestroy {

  protected _stepSubscription: Subscription;
  public stepRepoSites: string[] = [];
  public stepZones: {[site_id: string]: string[]} = {};
  public site: EntitySite;
  protected _sitesSubscription: Subscription;
  public filteredSiteList: EntitySite[] = [];
  public siteList: EntitySite[] = [];
  public zoneList: {[site_id: string]: EntityZone[]} = {};
  //public selectableSites: EntitySite[] = [];
  allZones: boolean = true;
  zone: string = '';

  constructor(
    protected _stepService: WorkbookStepService,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: SiteService
  ) {
    super(_logger, _entityService);
    this.site = new EntitySite((data && data.site) ? data.site : {});
    this._sitesSubscription = this._entityService.getAll().subscribe((list: EntitySite[]) => {
      if (list) {
        this.siteList = list.sort((a, b) => a.name.localeCompare(b.name));
        this.siteList.forEach((s) => {
          this.zoneList[s._id] = s.zones;
        });
        this.updateFilteredSiteList();
      }
    });
    if (data && data.step_id) {
      this._stepSubscription = this._stepService.getOne(data.step_id).subscribe((step: EntityStep) => {
        if (step) {
          this.stepRepoSites = [];
          this.stepZones = {};
          step.getLocations().forEach((s) => {
            if (s.repo_id) {
              this.stepRepoSites.push(s.repo_id);
            }
            this.stepZones[s._id] = s.zones.map((e) => (e._id));
          });
          this.updateFilteredSiteList();
        }
      });
    }

  }
  ngOnDestroy() {
    if (this._sitesSubscription) {
      this._sitesSubscription.unsubscribe();
    }
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
  }
  siteNameChange() {
    if (this.site && this.site.repo_id) {
      this.site.repo_id = '';
      this.site.zones = [new EntityZone(this.zone)];
    }
    this.updateFilteredSiteList();

  }
  updateFilteredSiteList() {
    if (this.site && this.siteList && this.siteList.length) {
      this.filteredSiteList = this.getFilteredSites(this.site.name);
    }
  }
  protected getFilteredSites(value: string): EntitySite[] {
    const returned = value
      ? this.siteList.filter(site => (this.stepRepoSites.indexOf(site._id) === -1 && comparableString(site.name).indexOf(comparableString(value)) !== -1))
      : this.siteList.filter(site => (this.stepRepoSites.indexOf(site._id) === -1));
    return returned.length > 200 ? [] : returned;
  }
  protected getFilteredZones(value: string): EntityZone[] {
    if (this.site && this.site.repo_id && this.zoneList[this.site._id]) {
      return value ? this.zoneList[this.site._id].filter(zone => (comparableString(zone.name).indexOf(comparableString(value)) !== -1)) : (this.zoneList[this.site._id].length > 200 ? [] : this.zoneList[this.site._id]);
    }
    return [];
  }
  public siteSelected(event, site: EntitySite) {
    //console.log('siteSelected', [event, site]);
    if (event && event.isUserInput && site) {
      const entitySite: EntitySite = this._entityService.getNewEntity(site);
      entitySite.zones = [];
      entitySite.repo_id = site._id;
      setTimeout(() => {this.site = entitySite;});

    }
  }
  isZoneSelected(zone_id) {
    return this.site && this.site._id
      && (
        (this.stepZones && this.stepZones[this.site._id] && this.stepZones[this.site._id].indexOf(zone_id) !== -1)
        || (this.site.zones && this.site.zones.findIndex((zone) => (zone_id === zone.repo_id)) !== -1)
      );

  }
  zoneSelected(event, zone: EntityZone) {
    if (event) {
      this.zoneSwitch(zone, event.checked);
    }
  }
  zoneSwitch(zone: EntityZone, checked: boolean) {
    //console.log('zoneSelected', [event, zone]);
    if (this.site && this.site.zones && zone) {
      const i = this.site.zones.findIndex((e) => (e.repo_id === zone._id));
      if (checked && i === -1) {
        const z = new EntityZone(zone, true);
        z.repo_id = zone._id;
        this.site.zones.push(z);
      } else if (!checked && i !== -1) {
        //TODO alerte si analyse de risque ?
        this.site.zones.splice(i, 1);
      }
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (this.site && this.site.zones && !this.site.zones.length) {
      this.site.zones.push(new EntityZone({name: this.zone}));
    }
    if (form.valid) {
      this.dialogRef.close(this.site);
    }
  }
  isSelectAll() {
    return this.site && this.site._id
      && this.zoneList && this.zoneList[this.site._id] && this.zoneList[this.site._id].length
      && (this.zoneList[this.site._id].filter((repo_zone) => (
        this.site.zones && (this.site.zones.findIndex((zone) => (repo_zone._id === zone.repo_id)) === -1)
      ))
        .length === 0);
  }
  zoneSelectAll(event) {
    if (this.site && this.site.repo_id && this.zoneList[this.site.repo_id] && this.zoneList[this.site.repo_id].length) {
      if (event.checked) {
        this.zoneList[this.site.repo_id]
          .filter((zone) => (this.site.zones.findIndex((e) => (e.repo_id === zone._id)) === -1))
          .forEach((zone) => {
            const z = new EntityZone(zone, true);
            z.repo_id = zone._id;
            this.site.zones.push(z);
          });
      } else {
        this.site.zones = [];
      }
    }
  }

}
