import { EntitySignable, formattedDate } from 'app/shared';
import { EntityOffice } from '../../repository';
import { Ldcm } from './ldcm.model';
import { Implementation } from './entityStep.model';
import { EntityEmergencyInstructions } from '../../repository/models/entityEmergencyInstructions.model';

export class ICPState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Obsolète',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications des activités, risques et mesures se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications des activités, risques et mesures ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est validée, en attente de signature du plan de prévention',
    'L\'edition a été signée par l\'ensemble des signataires, en attente de signature du plan de prévention',
    'L\'edition est valide, le plan de prévention est signé',
    'L\'edition est obsolète, une edition plus récente a été validée',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return ICPState.LABELS;
  }
  static getDescriptions() {
    return ICPState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return ICPState.LABELS[status] ? ICPState.LABELS[status] : 'state' + status;
  }
  static getDescription(status: number) {
    return ICPState.DESCRIPTIONS[status] ? ICPState.DESCRIPTIONS[status] : 'state: ' + status;
  }
}
export class EntityIcp extends EntitySignable {
  static PREFIX: string = 'IC';
  public editor: string;
  public editorEmail: string;
  public iPDate: Date;
  public iPHourStart: string;
  public iPHourEnd: string;
  // infos issues de pp1
  public iPAddress: string;
  public iPZipCode: string;
  public iPCity: string;
  public iPPhone: string;
  public iPSite: string;
  public iPSite_id: string;
  public iPZone: string;
  // office et eex avec uniquement contacts convoqués
  public iPOEex: EntityOffice[];
  //consgnes/documents...
  public IPLdcm: Ldcm[];
  public iPRemark: string;
  public steps: string[];

  public implementations: { [step_id: string]: Implementation };
  public emergencyInstructions: EntityEmergencyInstructions;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.editor = (data && data.editor) ? data.editor : '';
    this.editorEmail = (data && data.editorEmail) ? data.editorEmail : '';
    this.iPDate = (data && data.iPDate) ? new Date(data.iPDate) : null;
    this.iPHourStart = (data && data.iPHourStart) ? data.iPHourStart : '';
    this.iPHourEnd = (data && data.iPHourEnd) ? data.iPHourEnd : '';
    this.iPAddress = (data && data.iPAddress) ? data.iPAddress : '';
    this.iPZipCode = (data && data.iPZipCode) ? data.iPZipCode : '';
    this.iPCity = (data && data.iPCity) ? data.iPCity : '';
    this.iPPhone = (data && data.iPPhone) ? data.iPPhone : '';
    this.iPSite = (data && data.iPSite) ? data.iPSite : '';
    this.iPSite_id = (data && data.iPSite_id) ? data.iPSite_id : '';
    this.iPZone = (data && data.iPZone) ? data.iPZone : '';
    this.iPOEex = (data && data.iPOEex) ? data.iPOEex.map((e) => Object.assign({}, e)) : [];
    this.IPLdcm = (data && data.IPLdcm) ? data.IPLdcm.map((e) => Object.assign({}, e)) : [];
    this.iPRemark = (data && data.iPRemark) ? data.iPRemark : '';
    this.steps = (data && data.steps) ? data.steps.map((e) => e) : [];
    //this.implementations = (data && data.implementations) ? Object.assign({}, data.implementations) : null;
    //console.log('icp implementation0', (data && data.implementations) ? Object.assign({}, data.implementations) : null);
    if (data && data.implementations) {
      this.implementations = {};
      Object.keys(data.implementations).forEach((x) => {
        this.implementations[x] = new Implementation(data.implementations[x]);
      });
      //console.log('icp implementation', this.implementations);
    } else {
      this.implementations = null;
    }
    this.emergencyInstructions = (data && data.emergencyInstructions) ? new EntityEmergencyInstructions(data.emergencyInstructions) : null;

  }
  getTitle() {
    return "Inspection commune du " + formattedDate(this.iPDate);
  }


}
