import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoggerService, EntityFormComponent, copyToClipboard } from 'app/shared';
import { EmailMessagesService } from 'app/shared/services';
import { EntityEmailMessages } from 'app/shared/models';

@Component({
  selector: 'app-config-emails',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})

export class EmailComponent extends EntityFormComponent {

  @ViewChild('textEmail', { read: ElementRef }) textEmail: ElementRef;

  entity: EntityEmailMessages;
  messageActiveIndex: number = -1;

  newCodesList: any[] = [];
  codesList: any[] = [
    { id: '@DOC', type: 'DOC', description: 'Numéro de référence du document' },
    { id: '@DOCTYPE', type: 'DOC', description: 'Type du document' },
    { id: '@DOCDESC', type: 'DOC', description: 'Description du document' },
    { id: '@DOCEEX', type: 'DOC', description: 'Entreprise principale' },
    { id: '@LINK', type: 'EEX', description: 'Url pour consulter/signer l\'édition' },
    { id: '@ICPDATE', type: 'ICP', description: 'Date de l\'Inspection Commune Préalable' },
    { id: '@ICPTIME', type: 'ICP', description: 'Plage horaire de l\'Inspection Commune Préalable' },
    { id: '@ICPSTARTTIME', type: 'ICP', description: 'Heure de début de l\'Inspection Commune Préalable' },
    { id: '@ICPENDTIME', type: 'ICP', description: 'Heure de fin de l\'Inspection Commune Préalable' },
    { id: '@ICPEEXATTENDEES', type: 'ICP', description: 'Liste des entreprises convoquées (et leurs représentants)' },
    { id: '@ICPOFFICEATTENDEES', type: 'ICP', description: 'Liste des services convoqués (et leurs représentants)' },
    { id: '@ICPCONSIGNES', type: 'ICP', description: 'Liste des consignes' },
    { id: '@ICPDOCUMENTS', type: 'ICP', description: 'Liste des documents' },
    { id: '@ICPLOCAUX', type: 'ICP', description: 'Liste des locaux' },
    { id: '@ICPPLACE', type: 'ICP', description: 'Adresse de l\'Inspection Commune Préalable' },
    { id: '@ICPZONE', type: 'ICP', description: 'Lieu de l\'Inspection Commune Préalable' },
    { id: '@SIGNATORY', type: 'SIGNATORY', description: 'Nom du signataire du document' },
    { id: '@SIGNDATE', type: 'SIGNATORY', description: 'Date de la signature du document' },
    { id: '@URL', type: 'USER', description: 'Url pour la création d\'un compte utilisateur' },
    { id: '@PERMITNAME', type: 'EEX', description: 'Type de permis' },
    { id: '@EDITIONNAME', type: 'EEX', description: 'Nom de l\'edition' },
    { id: '@EDITIONDESC', type: 'EEX', description: 'Description de l\'edition' },
    { id: '@EDITIONSTARTDATE', type: 'DEED', description: 'Date de début' },
    { id: '@EDITIONENDDATE', type: 'DEED', description: 'Date de fin' },
    { id: '@EDITIONSTARTTIME', type: 'DEED', description: 'Heure de début' },
    { id: '@EDITIONENDTIME', type: 'DEED', description: 'Heure de fin' },
    { id: '@EDITIONS', type: 'EEX', description: 'Url pour la consultation et le dépôt de documents par les EE' },
    { id: '@DOCUMENTNAME', type: 'EEX', description: 'Nom du document demandé' },
    { id: '@DOCUMENTDESC', type: 'EEX', description: 'Description du document demandé' },
    { id: '@DOCUMENTS', type: 'EEX', description: 'Liste des documents demandés' },
    { id: '@UPLOADS', type: 'EEX', description: 'Lien vers le depot de documents (EEx)' },
    { id: '@HOME', type: '*', description: 'Lien vers l\'application' },
    // { id: '@OPERATIONS', type: '*', description:'Lien vers le dashboard' },
    { id: '@EUNAME', type: '*', description: 'Nom de l\'EU' }
  ];

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EmailMessagesService

  ) {
    super(dialog, _logger, _entityService);
  }

  onChange(value: number = -1): void {
    if (this.entity && this.entity.messages && this.entity.messages[value] && this.entity.messages[value].type) {
      this.newCodesList = this.codesList.filter((code) => {
        return (this.entity.messages[value].type.includes(code.type) || code.type === '*')
      });
    }
  }
  onCopy(event: Event, value) {
    event.preventDefault();
    event.stopPropagation();
    copyToClipboard(value);
  }
  onClick(event: Event, value) {
    event.preventDefault();
    event.stopPropagation();
    //this.textEmail.nativeElement.value = this.textEmail.nativeElement.value.substring(0, startPos) + value + this.textEmail.nativeElement.value.substring(startPos);
    if (this.entity && this.entity.messages && this.entity.messages[this.messageActiveIndex]) {
      const startPos = this.textEmail.nativeElement.selectionStart;
      this.entity.messages[this.messageActiveIndex].text = this.textEmail.nativeElement.value.substring(0, startPos) + value + this.textEmail.nativeElement.value.substring(startPos);
      this.form.controls['text'].markAsDirty();
      //this.form.control.markAsDirty();
    }
    /*
    if (this.autoSave) {
      this._save().then((e) => {
        this._afterSave(e);
      });;
    }
    */
  }

}
