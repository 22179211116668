import {UUID} from 'angular2-uuid';
import {Risk} from './risk.model';

export class ActivityCompany {
  public id: string;
  public name: string;
  public size: number;

  constructor(intData) {
    this.id = intData.id || UUID.UUID();
    this.name = intData.name;
    this.size = intData.size;
  }
}

export class ActivityModel {

  public id: string;
  public name: string;
  public phase: string;
  public dateStart: Date;
  public dateEnd: Date;
  public companies: ActivityCompany[];
  public opStage: string;
  public hourStart: Date;
  public hourEnd: Date;
  public manning: number;
  public risks: Risk[];
  public comments: string;
  public confinedSpace: boolean;

  constructor(intData) {
    this.id = intData.id || UUID.UUID();
    this.name = intData.name;
    this.phase = intData.phase;
    this.dateStart = intData.dateStart ? new Date(intData.dateStart) : null;
    this.dateEnd = intData.dateEnd ? new Date(intData.dateEnd) : null;
    this.hourStart = intData.hourStart;
    this.hourEnd = intData.hourEnd;
    this.companies = intData.companies || [];
    this.opStage = intData.opStage;
    this.manning = intData.manning;
    this.risks = intData.risks || [];
    this.confinedSpace = intData.risks || false;
  }
}
