import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  static ALL_CHARTS = [
    'bar',
    'area',
    'line',
    'spline',
    'bubble',
    // 'candlestick',
    'stackedbar',
    'fullstackedbar',
    'fullstackedline',
    'fullstackedspline',
    'splinearea',
    'fullstackedsplinearea',
    'fullstackedarea',
    // 'rangearea',
    'rangebar',
    'scatter'
  ];
  static ALL_PARAMS = [
    'wb_code',
    'wb_type',
    'wb_name',
    'wb_ref',
    'wb_editor',
    'wb_created',
    'wb_start',
    'wb_end',
    'wb_site',
    'wb_eex',
    'step_start',
    'step_end',
    'office_name',
    'office_job',
    'contact_name',
    'contact_email',
    'contact_job',
    'contact_user'
  ];
  static ALL_VALUES = [
    'wb_quantity',
    'wb_icp_quantity',
    'wb_pdp_quantity',
    'wb_step_quantity',
    'step_icp_quantity',
    'step_pdp_quantity',
    'wb_quantity',
    'oo_quantity',
    'op_quantity',
    'ps_quantity',
    'icp_quantity',
    'pdp_quantity'
  ]
  static GRAPH_LABEL = {
    'fr':
    {
      'area': 'Zone',
      'bar': 'Bâtons',
      'bubble': 'Bulles',
      'fullstackedarea': 'Zones complètes',
      'fullstackedbar': 'Bâtons complets',
      'fullstackedline': 'Lignes complètes',
      'fullstackedspline': 'Courbes complètes',
      'fullstackedsplinearea': 'Zones complètes (courbes)',
      'line': 'Lignes',
      'graph_rangearea': 'Plages (zones)',
      'rangebar': 'Plages (bâtons)',
      'scatter': 'Dispersion',
      'spline': 'Courbes',
      'splinearea': 'Zones (courbes)',
      'stackedbar': 'Bâtons empilés'
    }
  }
  chartTypes: any[] = [
    {value: 'bar', label: StatsService.GRAPH_LABEL.fr.bar},
    {value: 'area', label: StatsService.GRAPH_LABEL.fr.area},
    {value: 'line', label: StatsService.GRAPH_LABEL.fr.line},
    {value: 'spline', label: StatsService.GRAPH_LABEL.fr.spline},
    {value: 'bubble', label: StatsService.GRAPH_LABEL.fr.bubble},
    //{value: 'candlestick', label: StatsService.GRAPH_LABEL.fr.candlestick},
    {value: 'stackedbar', label: StatsService.GRAPH_LABEL.fr.stackedbar},
    {value: 'fullstackedbar', label: StatsService.GRAPH_LABEL.fr.fullstackedbar},
    {value: 'fullstackedline', label: StatsService.GRAPH_LABEL.fr.fullstackedline},
    {value: 'fullstackedspline', label: StatsService.GRAPH_LABEL.fr.fullstackedspline},
    {value: 'splinearea', label: StatsService.GRAPH_LABEL.fr.splinearea},
    {value: 'fullstackedsplinearea', label: StatsService.GRAPH_LABEL.fr.fullstackedsplinearea},
    {value: 'fullstackedarea', label: StatsService.GRAPH_LABEL.fr.fullstackedarea},
    //{value: 'rangearea', label: StatsService.GRAPH_LABEL.fr.rangearea},
    {value: 'rangebar', label: StatsService.GRAPH_LABEL.fr.rangebar},
    {value: 'scatter', label: StatsService.GRAPH_LABEL.fr.scatter}
  ];
  selectRows: any[] = [
    {value: 'wb_code', label: 'Code dossier'},
    {value: 'wb_type', label: 'Type dossier'},
    {value: 'wb_name', label: 'Nom dossier'},
    {value: 'wb_status', label: 'Statut dossier'},
    {value: 'wb_ref', label: 'Ref. dossier'},
    {value: 'wb_editor', label: 'Editeur dossier'},
    {value: 'wb_created', label: 'Création dossier'},
    {value: 'wb_start', label: 'Début dossier'},
    {value: 'wb_end', label: 'Fin dossier'},
    {value: 'wb_site', label: 'Sites'},
    {value: 'wb_eex', label: 'Entreprises'},
    {value: 'step_name', label: 'Nom chantier'},
    {value: 'step_start', label: 'Début chantier'},
    {value: 'step_end', label: 'Fin chantier'},
    {value: 'office_name', label: 'Service'},
    {value: 'office_job', label: 'Type de structure'},
    {value: 'contact_name', label: 'Nom'},
    {value: 'contact_email', label: 'Email'},
    {value: 'contact_job', label: 'Métier'},
    {value: 'contact_user', label: 'Utilisateur'}
  ];
  selectCols: any[] = [
    {value: 'wb_code', label: 'Code dossier'},
    {value: 'wb_type', label: 'Type dossier'},
    {value: 'wb_status', label: 'Statut dossier'},
    {value: 'wb_name', label: 'Nom dossier'},
    {value: 'wb_ref', label: 'Ref. dossier'},
    {value: 'wb_editor', label: 'Editeur dossier'},
    {value: 'wb_created', label: 'Création dossier'},
    {value: 'wb_start', label: 'Début dossier'},
    {value: 'wb_end', label: 'Fin dossier'},
    {value: 'wb_site', label: 'Sites'},
    {value: 'wb_eex', label: 'Entreprises'},
    {value: 'step_name', label: 'Nom chantier'},
    {value: 'step_start', label: 'Début chantier'},
    {value: 'step_end', label: 'Fin chantier'},
    {value: 'office_name', label: 'Service'},
    {value: 'office_job', label: 'Type de structure'},
    {value: 'contact_name', label: 'Nom'},
    {value: 'contact_email', label: 'Email'},
    {value: 'contact_job', label: 'Métier'},
    {value: 'contact_user', label: 'Utilisateur'}
  ];
  selectDatas: any[] = [
    {value: 'wb_quantity', label: 'Nombre dossiers'},
    {value: 'wb_icp_quantity', label: 'Nombre inspections'},
    {value: 'wb_pdp_quantity', label: 'Nombre PdP'},
    {value: 'wb_step_quantity', label: 'Nombre chantiers'},
    {value: 'step_quantity', label: 'Nombre chantiers'},
    {value: 'step_icp_quantity', label: 'Nombre inspections'},
    {value: 'step_pdp_quantity', label: 'Nombre PdP'},
    {value: 'oo_quantity', label: 'Occasionnels'},
    {value: 'op_quantity', label: 'Périodiques'},
    {value: 'ps_quantity', label: 'Protocoles'},
    {value: 'icp_quantity', label: 'Inspections'},
    {value: 'pdp_quantity', label: 'PdP'}
  ];
  fields: {[index: string]: any};


  constructor() {
    this.fields = {
      wb_code: {
        caption: "Code dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_code"
      },
      wb_type: {
        caption: "Type dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_type"
      },
      wb_status: {
        caption: "Statut dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_status"
      },
      wb_name: {
        caption: "Nom dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_name"
      },
      wb_ref: {
        caption: "Ref. dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_ref"
      },
      wb_editor: {
        caption: "Editeur dossier",
        dataType: "string",
        width: 120,
        dataField: "wb_editor"
      },
      wb_created: {
        caption: "Création dossier",
        dataField: "wb_created",
        dataType: "date"
      },
      wb_start: {
        caption: "Début dossier",
        dataField: "wb_start",
        dataType: "date"
      },
      wb_end: {
        caption: "Fin dossier",
        dataField: "wb_end",
        dataType: "date"
      },
      wb_site: {
        caption: "Sites",
        dataType: "string",
        dataField: "wb_site"
      },
      wb_eex: {
        caption: "Entreprises",
        dataType: "string",
        dataField: "wb_eex"
      },
      wb_quantity: {
        caption: "Nombre dossiers",
        dataType: "number",
        dataField: "wb_name",
        summaryType: "count"
      },
      wb_icp_quantity: {
        caption: "Nombre inspections",
        dataField: "wb_icp_quantity",
        dataType: "number",
        summaryType: "sum"
      },
      wb_pdp_quantity: {
        caption: "Nombre PdP",
        dataField: "wb_pdp_quantity",
        dataType: "number",
        summaryType: "sum"
      },
      wb_step_quantity: {
        caption: "Nombre chantiers",
        dataField: "wb_step_quantity",
        dataType: "number",
        summaryType: "sum"
      },
      step_name: {
        caption: "Nom chantier",
        dataType: "string",
        dataField: "step_name"
      },
      step_quantity: {
        caption: "Nombre chantiers",
        dataType: "number",
        dataField: "step_name",
        summaryType: "count"
      },
      step_start: {
        caption: "Début chantier",
        dataField: "step_start",
        dataType: "date"
      },
      step_end: {
        caption: "Fin chantier",
        dataField: "step_end",
        dataType: "date"
      },
      step_icp_quantity: {
        caption: "Nombre inspections",
        dataField: "step_icp_quantity",
        dataType: "number",
        summaryType: "sum"
      },
      step_pdp_quantity: {
        caption: "Nombre PdP",
        dataField: "step_pdp_quantity",
        dataType: "number",
        summaryType: "sum"
      },
      office_name: {
        caption: "Service",
        dataType: "string",
        dataField: "office_name"
      },
      office_job: {
        caption: "Type de structure",
        dataType: "string",
        dataField: "office_job"
      },
      contact_name: {
        caption: "Nom",
        dataType: "string",
        dataField: "contact_name"
      },
      contact_email: {
        caption: "Email",
        dataType: "string",
        dataField: "contact_email"
      },
      contact_job: {
        caption: "Métier",
        dataType: "string",
        dataField: "contact_job"
      },
      contact_user: {
        caption: "Utilisateur",
        dataType: "boolean",
        dataField: "contact_user"
      },
      oo_quantity: {
        caption: "Occasionnels",
        dataType: "string",
        dataField: "oo_quantity"
      },
      op_quantity: {
        caption: "Périodiques",
        dataType: "string",
        dataField: "op_quantity"
      },
      ps_quantity: {
        caption: "Protocoles",
        dataType: "string",
        dataField: "ps_quantity"
      },
      icp_quantity: {
        caption: "Inspections",
        dataType: "string",
        dataField: "icp_quantity"
      },
      pdp_quantity: {
        caption: "PdP",
        dataType: "string",
        dataField: "pdp_quantity"
      }

    };
  }
  setExtraFields(fields: string[], width: number = 120): string[] {
    const returned: string[] = [];
    Object.keys(this.fields).filter((k) => (k.indexOf('extra_') === 0)).forEach((k) => {
      delete this.fields[k];
    })
    fields.forEach((k, index) => {
      returned.push('extra_' + index);
      this.fields['extra_' + index] = {
        caption: k,
        width: width,
        dataField: 'extra_' + index
      };
    });
    return returned;
  }
  getSelectableFields(available: string[]) {
    return available.map((e) => (this.fields[e] ? this.fields[e] : null)).filter((e) => (e !== null));
  }
  getChartTypes(available: string[]) {
    return this.getFilteredList(this.chartTypes, available);
  }
  /*
	getSelectRows(available: string[]) {
		return this.getFilteredList(this.selectRows, available);
	}
	getSelectCols(available: string[]) {
		return this.getFilteredList(this.selectCols, available);
	}
	getSelectDatas(available: string[]) {
		return this.getFilteredList(this.selectDatas, available);
	}
  */
  getFilteredList(list: any[], available: string[]) {
    return list.filter(el => available.indexOf(el.value) > -1);
  }
  getField(element: string, area: string = '') {
    var field: any = null;
    if (this.fields[element]) {
      field = Object.assign({}, this.fields[element]);
      //if (area!==''){
      field['area'] = area;
      //}
    }
    //console.log('ReportingService:getField(' + element + ',' + area + ')', field);
    return field;
  }

  getFields(rows: string[], cols: string[], datas: string[], others: string[]) {
    var returned: any[] = [];
    var i: number = 0;
    var field: any;
    while (i < rows.length) {
      field = this.getField(rows[i], 'row');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < cols.length) {
      field = this.getField(cols[i], 'column');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < datas.length) {
      field = this.getField(datas[i], 'data');
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    i = 0;
    while (i < others.length) {
      field = this.getField(others[i]);
      if (field !== null) {
        returned.push(field);
      }
      i++;
    }
    //console.log('ReportingService:getFields', rows, cols, datas, others, returned);
    return returned;
  }

}
