import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {EntitySingleService} from 'app/shared/services/entitySingle.service';
import {LoadingService} from './loading.service';
import {EntityConfigEmail, Entity} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ConfigEmailService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement du paramètrage Email";
  static LABEL_ALL = "chargement des paramètrages Email ?";
  static LABEL_CHILDREN = "chargement des paramètrages Email de l'opération ?";
  static LABEL_SAVE = "sauvegarde du paramètrage Email";
  static LABEL_DELETE = "suppression du paramètrage Email";

  static ENTITY_TYPE: string = 'config_email';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showConfigs', 'showConfigEmail'],
    CREATE: ['editConfigs', 'editConfigEmail'],
    UPDATE: ['editConfigs', 'editConfigEmail'],
    DELETE: []
  }

  static ENTITY_URL = 'config/server';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityConfigEmail {
    return new EntityConfigEmail(data, clone);
  }
  protected _refreshEntity(doc: Entity) {
    super._refreshEntity(doc);
    this._permissionsService.email.updateConfigEmail(doc);
  }
}
