import {Entity} from 'app/shared';
import {UUID} from 'angular2-uuid';
export class Checkpoint {
  id: string;
  default: boolean;
  title: string;
  constructor(data: any) {
    this.id = (data && data.id) ? data.id : UUID.UUID();
    this.default = (data && !!data.default);
    this.title = (data && data.title) ? data.title : 'Nouveau point de contrôle';
  }
}
export class EntityAuditCheckpoints extends Entity {
  enabled: boolean;
  requireCheckpointLimit: boolean;
  requireCheckpointResponsable: boolean;
  checkpoints: Checkpoint[];
  enabledInstructions: boolean;
  instructions: string;
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.enabled = (data && !!data.enabled);
    this.requireCheckpointLimit = (data && !!data.requireCheckpointLimit);
    this.requireCheckpointResponsable = (data && !!data.requireCheckpointResponsable);
    this.checkpoints = (data && data.checkpoints) ? data.checkpoints.map((checkpoint) => (new Checkpoint(checkpoint))) : [];
    this.enabledInstructions = (data && !!data.enabledInstructions);
    this.instructions = (data && data.instructions) ? data.instructions : '';
  }
}
