import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { AbstractEntityDialogComponent } from './abstractEntityDialog.component';

export class EntityEditDialogComponent extends AbstractEntityDialogComponent {
  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (!this._entityService.isEntitySingle() && data) {
      if (data['id']) {
        this.id = data['id'];
      }
      if (data['parent_id']) {
        this.parent_id = data['parent_id'];
        this._updatePermissions(this.parent_id);
      }
      if (data['workbook'] && data['workbook']['_id']) {
        this._updatePermissions(data['workbook']['_id']);
      }
    }
  }
}
