import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
  LoadingService, EmailMessages, EntityEmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService, EntityCompany
} from 'app/shared';
import {
  PreventionPlanService,
  FirePermitService,
  EntityFirePermit, FPStatus,
  FirePermitExportComponent, DocumentationService
} from 'app/workbook-core';
import { AbstractSignableDialogComponent } from '../abstractSignable-dialog.component';


@Component({
  selector: 'app-signable-firePermit-dialog',
  templateUrl: './firePermit-dialog.component.html',
  styleUrls: ['./firePermit-dialog.component.scss']
})
export class FirePermitDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  static FLAG_SUFFIX: string = 'pf';
  SIGNATORY_RIGHT: string = 'signatoryPermit';

  SIGNING_NOTIFY = EmailMessages.SIGN_SFP_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_SFP;

  entity: EntityFirePermit;
  entityForm: FirePermitExportComponent;

  signableStatus = FPStatus.LABELS;

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FirePermitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: FirePermitService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
  }

  addSpecifictEmailRecipient(entity: EntityFirePermit) {
    if (entity.originator && entity.originatorContact
      && this.usersList.findIndex((u) => (
        u.name === entity.originator.name
        && u.email === entity.originatorContact.email
        && u.company === entity.originator.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.originator, entity.originatorContact, true));
    }
    if (entity.performer && entity.performerContact
      && this.usersList.findIndex((u) => (
        u.name === entity.performer.name
        && u.email === entity.performerContact.email
        && u.company === entity.performer.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.performer, entity.performerContact, true));
    }
    if (entity.verifier && entity.verifierContact
      && this.usersList.findIndex((u) => (
        u.name === entity.verifier.name
        && u.email === entity.verifierContact.email
        && u.company === entity.verifier.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.verifier, entity.verifierContact, true));
    }
    if (entity.supervisor && entity.supervisorContact
      && this.usersList.findIndex((u) => (
        u.name === entity.supervisor.name
        && u.email === entity.supervisorContact.email
        && u.company === entity.supervisor.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.supervisor, entity.supervisorContact, true));
    }
    if (entity.operators && entity.operators.length) {
      entity.operators.forEach((operator) => {
        if (entity.performer && this.usersList.findIndex((u) => (
          u.name === operator.name
          && u.email === operator.email
          && u.company === entity.performer.name
        )) === -1) {
          this.usersList.push(this.createEmailRecipient(entity.performer, operator, true));
        }
      });
    }
  }

  getDefaultEmailSubject() {
    return ('Permis feu' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? (' ' + this.entityForm.entity.name) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }

  _getSignatoriesOffice() {
    let signatories = [];
    if (this.entityForm && this.entityForm.originatorSignup) {
      signatories = this.entityForm.originatorSignup;
    }
    return signatories;
  }
  _getSignatoriesEex() {
    let signatories = [];
    if (this.entityForm) {
      if (this.entityForm.performerSignup) {
        signatories = [...signatories, ...this.entityForm.performerSignup];
      }
      if (this.entityForm.supervisorSignup) {
        signatories = [...signatories, ...this.entityForm.supervisorSignup];
      }
      if (this.entityForm.verifierSignup) {
        signatories = [...signatories, ...this.entityForm.verifierSignup];
      }
      if (this.entityForm.operatorSignup) {
        signatories = [...signatories, ...this.entityForm.operatorSignup];
      }
    }
    return signatories;
  }
  getUnsigned(): EntityCompany[] {
    const unsigned: EntityCompany[] = [];
    if (this.entityForm && this.entityForm.entity) {
      let performerSelected: boolean = false;
      if (this.entityForm.entity.originator && this.entityForm.entity.originator.name
        && this.entityForm.entity.originatorContact && this.entityForm.entity.originatorContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.originator._id
          && s.contact_id === this.entityForm.entity.originatorContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.originator);
      }
      if (this.entityForm.entity.performer && this.entityForm.entity.performer.name
        && this.entityForm.entity.performerContact && this.entityForm.entity.performerContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.performer._id
          && s.contact_id === this.entityForm.entity.performerContact.id
        )) === -1) {
        performerSelected = true;
        unsigned.push(this.entityForm.entity.performer);
      }
      if (this.entityForm.entity.supervisor && this.entityForm.entity.supervisor.name
        && this.entityForm.entity.supervisorContact && this.entityForm.entity.supervisorContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.supervisor._id
          && s.contact_id === this.entityForm.entity.supervisorContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.supervisor);
      }
      if (this.entityForm.entity.verifier && this.entityForm.entity.verifier.name
        && this.entityForm.entity.verifierContact && this.entityForm.entity.verifierContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.verifier._id
          && s.contact_id === this.entityForm.entity.verifierContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.verifier);
      }
      if (this.entityForm.entity.operators && this.entityForm.entity.operators.length) {
        this.entityForm.entity.operators.forEach((op) => {
          if (!performerSelected && op.name) {
            performerSelected = true;
            unsigned.push(this.entityForm.entity.performer);
          }
        });
      }

    }
    return unsigned;
  }
}
