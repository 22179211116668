import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import {AuthService, LoggerService, CommonComponent, TreeElement, IUser} from 'app/shared';
import {Subscription} from 'rxjs';
import {EntityOffice, OfficeService} from '../repository';

@Component({
  selector: 'app-user-sync',
  templateUrl: './userSync.component.html',
  styleUrls: ['./userSync.component.scss']
})
export class UserSyncComponent extends CommonComponent {
  protected _profileSubscription: Subscription;
  user_id: string;
  userMeta: {
    family_name: string,
    given_name: string,
    phone_number: string,
    syncFilter: string[]
  } = {
      family_name: '',
      given_name: '',
      phone_number: '',
      syncFilter: []
    };
  syncFilter: {[id: string]: boolean}
  offices: EntityOffice[] = [];
  level: {[id: string]: number} = {};
  protected _officesSubscription: Subscription;
  officeScope: {[id: string]: string[]};
  scopedOffice: {[id: string]: boolean};
  rightU: boolean = false;
  constructor(
    protected _officeService: OfficeService,
    public dialogRef: MatDialogRef<UserSyncComponent>,
    private auth: AuthService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(_logger);
    this.scopedOffice = {};
    if (data && data['user_id'] && data['user_metadata']) {
      this.user_id = data['user_id'];
      this.userMeta = {
        family_name: !!data['user_metadata']['family_name'] ? data['user_metadata']['family_name'] : '',
        given_name: !!data['user_metadata']['given_name'] ? data['user_metadata']['given_name'] : '',
        phone_number: !!data['user_metadata']['phone_number'] ? data['user_metadata']['phone_number'] : '',
        syncFilter: !!data['user_metadata']['syncFilter'] ? data['user_metadata']['syncFilter'].map(e => e) : null
      };
      this.rightU = this._officeService.hasPermission('editUser');
      this.refreshScopedOffice();
    } else {
      this.rightU = this._officeService.hasPermission('editSyncFilter');
      //this.auth.retrieveProfile().then((profile) => {
      this._profileSubscription = this.auth.userProfile$
        .subscribe((profile) => {
          this.userMeta = {
            family_name: !!profile.user_metadata ? profile.user_metadata.family_name : '',
            given_name: !!profile.user_metadata ? profile.user_metadata.given_name : '',
            phone_number: !!profile.user_metadata ? profile.user_metadata.phone_number : '',
            syncFilter: !!profile.user_metadata && profile.user_metadata.syncFilter ? profile.user_metadata.syncFilter.map(e => e) : null
          };
          this.refreshScopedOffice();
        });
    }
    this._officesSubscription = this._officeService.getAll().subscribe((list) => {
      if (list) {
        this.offices = TreeElement.orderTreeView(list, 'main');
        this.level = TreeElement.getLevels(list, 'main');
        this.officeScope = OfficeService.getOfficeScope(list);
        this.refreshScopedOffice();
      }
    });

  }
  refreshScopedOffice() {
    this.scopedOffice = {};
    if (this.officeScope && this.userMeta && this.userMeta.syncFilter) {
      this.userMeta.syncFilter.forEach((id) => {
        if (this.officeScope[id]) {
          this.officeScope[id].forEach((scoped_id) => {
            this.scopedOffice[scoped_id] = true;
          });
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._profileSubscription) {
      this._profileSubscription.unsubscribe();
    }
  }
  onSubmit() {
    if (this.rightU) {
      const savePromise = this.user_id
        ? this.auth.updateUserProfile(this.user_id, this.userMeta)
        : this.auth.updateProfile(this.userMeta);
      savePromise.then((e) => {
        this.dialogRef.close(this.userMeta.syncFilter);
      });
    } else {
      this.dialogRef.close();
    }
  }
  switchOffice(office_id, event) {
    if (this.userMeta && this.rightU) {
      if (!this.userMeta.syncFilter) {
        this.userMeta.syncFilter = [];
      }
      if (event.checked) {
        if (this.userMeta.syncFilter.indexOf(office_id) === -1) {
          this.userMeta.syncFilter.push(office_id);
        }
      } else {
        let i = this.userMeta.syncFilter.indexOf(office_id);
        if (i !== -1) {
          this.userMeta.syncFilter.splice(i, 1)
        }
      }
      this.refreshScopedOffice();
    }

  }
}
