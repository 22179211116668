import {EntityCodable} from './entityCodable.model';
import {EntityDocumentMetadata} from './entityDocumentMetadata.model';

export class EditableStatus {
  static DRAFT: number = 0;
  static VALIDATED: number = 1;
  static VALID: number = 2;
  static OUTDATED: number = 4;
  static LABELS: string[] = [
    'En préparation',
    'Préparé',
    'Validé',
    '',
    'Obsolète'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est validée'
  ];
  static getStatusLabel(status: number) {
    return EditableStatus.LABELS[status] ? EditableStatus.LABELS[status] : 'status' + status;
  }
  static getStatusDescription(status: number) {
    return EditableStatus.DESCRIPTIONS[status] ? EditableStatus.DESCRIPTIONS[status] : 'status: ' + status;
  }
}
export interface IEditable {
  name: string;
  status: number;
  content: string;
  editedAt: Date;

  isValidated();
  invalidate();
  validate(content: string);
}

export abstract class EntityEditable extends EntityCodable implements IEditable {

  static PREFIX: string = 'ED';
  status: number;
  content: string;
  content_lz: boolean;
  editedAt: Date;
  documents: EntityDocumentMetadata[];

  isValid() {
    return this.status === EditableStatus.VALID;
  }
  isValidated() {
    return this.status !== EditableStatus.DRAFT;
  }
  invalidate() {
    this.status = EditableStatus.DRAFT;
  }
  validate(content: string = null) {
    if (content) {
      this.status = EditableStatus.VALIDATED;
      this.editedAt = new Date();
      this.content = content;
    }
  }
  getDocumentWithContext(context: string) {
    return this.documents ?
      this.documents.filter((e) => (e.context.indexOf(context) !== -1))
      : [];
  }
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.status = (data && data.status) ? data.status : 0;
    this.content_lz = data && data.content && !!data.content_lz;
    this.content = (data && data.content) ? data.content : '';
    this.editedAt = (data && data.editedAt) ? new Date(data.editedAt) : null;
    this.documents = (data && data.documents) ? data.documents.map((e) => e) : [];

  }

  static getFileNameOf(entity: EntityEditable): string {
    return EntityCodable.getFileNameOf(entity) + ((!entity.isValid() && entity.status !== EditableStatus.OUTDATED) ? ('-' + entity.status) : '');
  }
  getInlineDocuments(): EntityDocumentMetadata[] {
    return this.documents.filter((e) => (e.isInline));
  }
}
