import {Component, Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService} from 'app/shared';
import {
  FirePermitService, FirePermitContentService,
  EntityPreventionPlan,
  EntityFirePermit, EntityFirePermitContent,
  WorkbookStepService,
  EntityStep,
  EntitySite,
  Implementation,
  Activity, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableInfoDialogComponent} from '../abstractSignableInfo-dialog.component';
import {FirePermitInfoComponent} from './firePermitInfo.component'

@Component({
  selector: 'app-signable-firePermit-info-dialog',
  templateUrl: './firePermitInfo-dialog.component.html',
  styleUrls: ['./firePermitInfo-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class FirePermitInfoDialogComponent extends AbstractSignableInfoDialogComponent implements AfterViewInit {
  entityForm: FirePermitInfoComponent;
  entity: EntityFirePermit;
  workbook: EntityPreventionPlan;
  implementation: Implementation;
  selectedStep: string;
  activities: Activity[];

  templates: EntityStep[] = [];
  steps: EntityStep[] = [];
  stepList: {[step_id: string]: EntityStep} = {};
  protected _stepsSubscription: Subscription;

  protected _firePermitContentSubscription: Subscription;
  firePermitContent: EntityFirePermitContent;

  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _firePermitContentService: FirePermitContentService,
    protected _stepService: WorkbookStepService,
    public dialogRef: MatDialogRef<FirePermitInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: FirePermitService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['entity']) {
        if (data['implementation']) {
          this.implementation = data['implementation'];
        }

      }
      if (!this.id) {
        if (this.implementation && this.implementation.step) {
          this.selectedStep = this.implementation.step._id;
          this.selectStep();
        } else if (this.workbook && this.workbook.simple) {
          this.selectStep();
        }
        this._firePermitContentSubscription = this._firePermitContentService.getSingleOne().subscribe((fpc: EntityFirePermitContent) => {
          if (fpc) {
            this.firePermitContent = fpc;
          }
          this.updateFirePermitContent();
        });
      }

      this._stepsSubscription = this._stepService.getChildren(this.parent_id).subscribe((list: EntityStep[]) => {
        if (list) {
          this.stepList = {};
          let _templates: EntityStep[] = [];
          let _steps: EntityStep[] = [];
          list
            .forEach((e) => {
              this.stepList[e._id] = e;
              if (e.template) {
                _templates.push(e);
              } else {
                _steps.push(e);
              }
            });
          this.templates = _templates//.filter((e) => (e.implementation))
            .sort((a, b) => ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime()));
          this.steps = _steps//.filter((e) => (e.implementation || (e.template_id && this.stepList[e.template_id] && this.stepList[e.template_id].implementation)))
            .sort((a, b) =>
              (this.workbook && this.workbook.type === 'annual') ?
                ((new Date(b.dateStart)).getTime() - (new Date(a.dateStart)).getTime())
                : ((new Date(a.dateStart)).getTime() - (new Date(b.dateStart)).getTime())
            );

          this.selectStep();
        }
      });
    }

  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.id && this.entityForm && this.entityForm.entity && this.entityForm.entity.step) {
      this.selectedStep = this.entityForm.entity.step;
    }
    this.selectStep();
    this.updateFirePermitContent();

  }
  ngOnDestroy() {
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    if (this._firePermitContentSubscription) {
      this._firePermitContentSubscription.unsubscribe();
    }
  }
  updateFirePermitContent() {
    if (this.firePermitContent && this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.title = this.firePermitContent.title;
      this.entityForm.entity.summary = this.firePermitContent.description;

      if (!this.id) {
        this.firePermitContent.warningDevices.forEach((e) => {
          if (this.entityForm.entity.warningDevices.indexOf(e) === -1) {
            this.entityForm.entity.warningDevices.push(e);
          }
        });
        this.firePermitContent.interventionDevices.forEach((e) => {
          if (this.entityForm.entity.interventionDevices.indexOf(e) === -1) {
            this.entityForm.entity.interventionDevices.push(e);
          }
        });
        this.firePermitContent.emergency.forEach((e) => {
          if (this.entityForm.entity.emergency.indexOf(e) === -1) {
            this.entityForm.entity.emergency.push(e);
          }
        });
      }
      this.firePermitContent.safetyInstructions.forEach((e) => {
        const i = this.entityForm.entity.safetyInstructions.findIndex((x) => (x.group === e.group));
        if (i === -1) {
          this.entityForm.entity.safetyInstructions.push(Object.assign({}, e));
        } else {
          e.instructions.forEach((instruction) => {
            const j = this.entityForm.entity.safetyInstructions[i].instructions.findIndex((x) => (x.name === instruction.name));
            if (j === -1) {
              this.entityForm.entity.safetyInstructions[i].instructions.push(Object.assign({}, instruction));
            }
          });

        }
      });

      this.firePermitContent.hotSpotWorks.forEach((e) => {
        if (!this.entityForm.entity.hotSpotWorks[e]) {
          this.entityForm.entity.hotSpotWorks[e] = false;
        }
      });
      this.firePermitContent.equipments.forEach((e) => {
        if (!this.entityForm.entity.equipments[e]) {
          this.entityForm.entity.equipments[e] = false;
        }
      });
      this.firePermitContent.attestations.forEach((e) => {
        if (!this.entityForm.entity.attestations[e]) {
          this.entityForm.entity.attestations[e] = false;
        }
      });
    }
  }
  selectStep() {
    if (this.workbook && this.workbook.simple) {
      if (this.steps && this.steps.length) {
        this.selectedStep = this.steps[0]._id;
      } else if (this.templates && this.templates.length) {
        this.selectedStep = this.templates[0]._id;
      }
    }
    if (this.entityForm && this.entityForm.entity && this.selectedStep && this.stepList[this.selectedStep]) {
      this.entityForm.entity.step = this.selectedStep;
      this.activities = [];
      if (this.stepList[this.selectedStep]) {
        if (this.stepList[this.selectedStep].implementation) {
          this.implementation = this.stepList[this.selectedStep].implementation
        } else {
          this.implementation = null;
        }
      }
      if (this.implementation) {
        if (this.implementation.step.site) {
          this.entityForm.entity.site = new EntitySite(this.implementation.step.site);
        }
        this.entityForm.entity.point = this.implementation.step.point ? this.implementation.step.point : '';
        this.activities = this.implementation.step.activities ?
          this.implementation.step.activities
            .filter((activity_id) => (this.implementation.activities[activity_id] && this.implementation.activities[activity_id].firePermit))
            .map((activity_id) => (this.implementation.activities[activity_id]))
          : [];

      }

    }
  }
  save() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.entity._id) {
      if (this.userProfile) {
        this.entityForm.entity.editor = this._entityService.getUserName();
        this.entityForm.entity.editorEmail = this.userProfile.email;
        this.entityForm.entity.editorDate = new Date();
      }
      if (this.workbook && this.workbook._id) {
        this._entityService.getNewCode(this.workbook._id).then((code) => {
          this.entityForm.entity.parent_code = this.workbook.code;
          this.entityForm.entity.code = code;
          this.entityForm.entity.name = this.entityForm.entity.getName();
          super.save();
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      }
    } else {
      super.save();
    }
  }
}
