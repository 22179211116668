import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatAutocompleteSelectedEvent, MAT_DIALOG_DATA } from '@angular/material';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Contact, formattedDate } from 'app/shared';
import { EntityOffice, OfficeService } from 'app/workbook-core';


@Component({
  selector: 'app-step-accident-dialog',
  templateUrl: './accident-dialog.component.html',
  styleUrls: ['./accident-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class StepAccidentDialogComponent {
  accident: {
    date: Date,
    text: string
  }
  index: number;
  dateTxt: string = '';
  constructor(
    public dialogRef: MatDialogRef<StepAccidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.accident = {
      date: (data['accident'] && data['accident']['date'] ? new Date(data['accident']['date']) : new Date()),
      text: (data['accident'] && data['accident']['text'] ? data['accident']['text'] : '')
    };
    this.dateTxt = formattedDate(this.accident.date);
    this.index = data['index'];
  }

  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(this.accident);
    }
  }
  onDelete() {
    this.dialogRef.close('delete');
  }

  changeAccidentDate() {
    if (this.accident && this.accident.date) {
      this.dateTxt = formattedDate(new Date(this.accident.date));
    } else {
      this.dateTxt = '';
    }
  }
  updateAccidentDate() {
    if (this.accident) {
      if (this.dateTxt) {
        const d = this.dateTxt.match('(0?[1-9]|[1-2][0-9]|3[0-1])\/(0?[0-9]|1[0-2])\/([1-2][0-9][0-9][0-9])')
        if (d && d.length === 4) {
          this.accident.date = new Date(+d[3], +d[2] - 1, +d[1]);
        } else {
          this.changeAccidentDate()
        }
      } else {
        this.accident.date = null;
      }
    }
  }
}
