import { Injectable } from '@angular/core';
import { PermissionsService, DbFilteredService, EntitySecureService, LoadingService } from 'app/shared';
import { EntityAuditCheckpoints } from '../models/entityAuditCheckpoints.model';

@Injectable({
  providedIn: 'root'
})
export class AuditCheckpointsService extends EntitySecureService {

  autoInit = false;

  static LABEL_ONE = "chargement du point de controle (visite)";
  static LABEL_ALL = "chargement des points de controle (visite)";
  static LABEL_CHILDREN = "chargement des points de controle (visite) de l'opération ?";
  static LABEL_SAVE = "sauvegarde des points de controle (visite)";
  static LABEL_DELETE = "suppression des points de controle (visite)";

  static ENTITY_SINGLE: boolean = true;
  static ENTITY_TYPE: string = 'repo_checkpoint';
  static ENTITY_FIELDS: string[] = [];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showAuditCheckpoints'],
    CREATE: ['editAuditCheckpoints'],
    UPDATE: ['editAuditCheckpoints'],
    DELETE: ['editAuditCheckpoints']
  }

  static ENTITY_URL = 'repository/auditCheckpoint';


  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityAuditCheckpoints {
    return new EntityAuditCheckpoints(data, clone);
  }

}
