import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoggerService,
  EmailDatasModel,
  EmailRecipient,
  EntityDialogComponent
} from 'app/shared';
import {
  PreventionPlanService,
  EntityWorkbook
} from 'app/workbook-core'


@Component({
  selector: 'app-notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent extends EntityDialogComponent {

  entity: EntityWorkbook;
  emailDatas: EmailDatasModel = new EmailDatasModel();
  // private _userPPList: UserPPList = {} as any;
  public usersPPList: EmailRecipient[] = [];
  public recipientSelectedValue: EmailRecipient[] = [];

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<NotifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (this.entity) {
      this._entityService.getCurrentEmailRecipientList(this.entity).then((res) => {
        this.usersPPList = [];
        const emails: string[] = [];
        res.forEach((contact) => {
          if (contact.email && emails.indexOf(contact.email) === -1) {
            emails.push(contact.email);
            this.usersPPList.push(contact);
          }
        });
      });
      this.emailDatas.subject = 'Informations Plan de prévention ' + this.entity.name;
      this.emailDatas.signature = 'Cordialement,\n';
    }
  }
}


