import {Entity} from './entity.model';

export class EntitySimple extends Entity {
  public name: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
  }
}
