import * as auth0 from 'auth0-js';

export abstract class AbstractAuth0Service {
  protected webAuth;

  protected auth0Config;

  protected constructor(
  ) {}
  protected abstract _setSession(authResult): void;
  protected abstract _afterAthentication(authResult): void;

  public load(config, scope: string[] = ['openid'], audience: string = '', responseType: string[] = ['token', 'code', 'id_token']): Promise<any> {
    this.auth0Config = config;
    return new Promise((resolve) => {
      this.webAuth = new auth0.WebAuth(this.getWebAuthConfig(config, scope, audience, responseType));
      resolve(this);
    });
  }

  protected getWebAuthConfig(config, scope: string[], audience: string, responseType: string[]) {
    // console.log('abstractAuth0.getWebAuthConfig:', config);
    let _uri = unescape(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + escape('uri').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    if (!_uri) {
      _uri = window.location.pathname;
    }
    return {
      clientID: config.clientID,
      domain: config.domain,
      responseType: responseType.join(' '),
      audience: audience ? audience : 'https://' + config.domain + '/api/v2/',
      redirectUri: window.location.origin + ((_uri && _uri !== '/') ? '?uri=' + _uri : ''),
      scope: scope.join(' ')
    };
  }

  public login(prompt: boolean = true) {
    this.webAuth.authorize({prompt: prompt});
  }

  public handleAuthentication(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          window.location.hash = '';
          this._setSession(authResult);
          this._afterAthentication(authResult);
          resolve(true);
        } else if (err) {
          console.error('Error parse auth', err);
          reject(err);
        } else {
          resolve(false);
        }
      });
    })
  }

  public getManagement(domain, token) {
    return new auth0.Management({
      domain: domain,
      token: token,
      scope: 'read:users'
    });
  }
  /**
   * Logout the app auth0
   */
  public logout(): void {
    // Go back to the home route
    // this.webAuth.authorize();
    this.webAuth.logout({
      returnTo: window.location.origin
    });
  }

  public async renewToken() {
    await this.webAuth.checkSession({}, (err, result) => {
      if (err) {
        console.error('renewToken: ', err);
        this.login();
      } else {
        this._setSession(result);
      }
    });
    this.webAuth.checkSession({}, (err, result) => {
      if (err) {
        console.error('renewToken: ', err);
      } else {
        this._setSession(result);
      }
    });
  }
}
