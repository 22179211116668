import {Component, Inject, OnDestroy, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {NgForm} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoadingService, ConfirmDialogComponent, EmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService,
  SignableStatus, formattedDate
} from 'app/shared';
import {
  PreventionPlanService,
  IcpService,
  EntityIcp, Implementation,
  IcpExportComponent,
  SignablePreventionPlanService, DocumentationService, EntitySignablePreventionPlan
} from 'app/workbook-core';

import {AbstractSignableDialogComponent} from 'app/workbook-shared';

@Component({
  selector: 'workbook-icp-signable-dialog',
  templateUrl: './icpSignable-dialog.component.html',
  styleUrls: ['./icpSignable-dialog.component.scss']
})
export class WorkbookIcpSignableDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  @ViewChild('emergencyform') emergencyform: NgForm;
  @ViewChildren(NgForm) forms: QueryList<NgForm>;
  static FLAG_SUFFIX: string = 'icp';
  SIGNATORY_RIGHT: string = 'signatory';

  SIGNING_NOTIFY = EmailMessages.SIGN_ICP_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_ICP;

  entityForm: IcpExportComponent;
  entity: EntityIcp;

  protected _pdpSubscription: Subscription;
  inProgressPdp: EntitySignablePreventionPlan[];
  linkedPdp: EntitySignablePreventionPlan[];
  //protected _icpRecurrenceSubscription: Subscription;
  //icpRecurrence: EntityIcpRecurrence;
  protected _formChange: Subscription;
  protected _firstChange = true;

  constructor(
    //protected _icpRecurrenceService: IcpRecurrenceService,
    protected _documentationService: DocumentationService,
    protected _signablePreventionPlanService: SignablePreventionPlanService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkbookIcpSignableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: IcpService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
    if (this.workbook && this.workbook._id && this.entity && this.entity._id) {
      this._pdpSubscription = this._signablePreventionPlanService.getChildren(this.workbook._id).subscribe((pdpList: EntitySignablePreventionPlan[]) => {
        if (pdpList) {
          this.linkedPdp = pdpList.filter((pdp) => (pdp.icps.indexOf(this.entity._id) !== -1) && pdp.status !== SignableStatus.DRAFT);
          this.inProgressPdp = pdpList.filter((pdp) => (pdp.icps.indexOf(this.entity._id) !== -1 && pdp.status !== SignableStatus.DRAFT && pdp.status !== SignableStatus.OUTDATED));
        }
      });
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.forms.changes.subscribe((elements) => {
      this._subscribeFormChange();
    });
  }
  getDefaultEmailSubject() {
    return ('Inspection commune ' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.iPDate) ? ('du ' + formattedDate(this.entityForm.entity.iPDate)) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }
  changeStatus(event) {
    super.changeStatus(event);
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity.status < 2) {
      this.entityForm.entity.withoutSignatories = false;
    }
  }
  public invalidateEditable(): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.implementations = {};
      super.invalidateEditable();
    }
  }
  public validate(updateContent: boolean = true): void {
    if (this.entityForm && this.entityForm.entity) {
      if (updateContent) {
        this.entityForm.entity.implementations = {};
        this.entityForm.entity.steps
          .forEach((step_id) => {
            if (this.entityForm.stepList[step_id]) {
              this.entityForm.entity.implementations[step_id] = Implementation.generate(
                this.entityForm.stepList[step_id],
                this.entityForm.activities,
                this.entityForm.risks,
                [...this.entityForm.offices, ...this.entityForm.externalCompanies],
                this.entityForm.entity._id,
                this.entityForm.entity.name,
                '',
                '',
                this.entityForm.entity.iPOEex
              );
            }
          });
        //console.log('save ICP implementations', this.entityForm.entity.implementations);
      }
      super.validate(updateContent);
    }
  }
  setSigned() {
    if (this.entityForm
      && this.entityForm.entity
      && (!this.entityForm.entity.signatures || this.entityForm.entity.signatures.length === 0)) {
      //passage à signé sans signature
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous faire une inspection sans signataire (signature uniquement du plan de prévention) ?';
      dialogRef.componentInstance.buttonMessage = 'Oui';
      dialogRef.componentInstance.cancelMessage = 'Annuler';
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.entityForm.entity.withoutSignatories = true;
          super.setSigning();
        } else {
          this._updateEntityFromForm();
        }
      });
    } else {
      super.setSigned();
    }
  }
  setSigning() {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.withoutSignatories = false;
      if (this.entityForm.signatoriesOffice.length === 0 && this.entityForm.signatoriesEex.length === 0) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous faire une inspection sans signataire (signature uniquement du plan de prévention) ?';
        dialogRef.componentInstance.buttonMessage = 'Oui';
        dialogRef.componentInstance.cancelMessage = 'Annuler';
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.entityForm.entity.withoutSignatories = true;
            super.setSigning();
          } else {
            this._updateEntityFromForm();
          }
          /*
          else {
            this._notify = this.SIGNING_NOTIFY;
          }
          */
        });
      } else {
        this._notify = this.SIGNING_NOTIFY;
        super.setSigning();
      }
    }
  }
  protected _subscribeFormChange() {
    if (!this._formChange && this.emergencyform) {
      const that = this;
      this._formChange = this.emergencyform.control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe((test) => {
          // console.log(this._findInvalidControls(this.form));
          // Avoid saving data each time the form is loaded
          if (that._firstChange) {
            that._firstChange = false;
          } else {
            //const invalidControls = this._findInvalidFormControls(this.form);
            //if (invalidControls.length === 0) {
            if (that.rightU && that.entity && that.entity._id && that.emergencyform.valid && that.emergencyform.dirty) {
              //console.log('autoSave', that.entity);
              that.save();
            }
          }
        });
    }
  }
  protected _optionalAfterSave(_notify) {
    if (this.entityForm && this.entityForm.steps && this.entityForm.templates) {
      this._workbookService.updateWorkbookInspection(
        this.workbook,
        this.entity,
        [...this.entityForm.steps, ...this.entityForm.templates])
    }
    /*
    if (_notify === 8) {
      this.optionalCreatePdP();
    }
    */
  }

  optionalCreatePdP(showSnackbar: boolean = true) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.buttonMessage = 'Oui';
    dialogRef.componentInstance.cancelMessage = 'Non';
    dialogRef.componentInstance.confirmMessage = 'Souhaitez-vous créer une édition du plan de prévention pour cette inspection ?';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newPdP =
          this._signablePreventionPlanService.getNewEntity({
            parent_id: this.entityForm.entity.parent_id,
            editor: this._entityService.getUserName(),
            editorEmail: this.userProfile.email,
            editorDate: new Date(),
            icps: [this.entityForm.entity._id]
          });
        this._signablePreventionPlanService.getNewCode(this.entityForm.entity.parent_id).then((code) => {
          newPdP.parent_code = this.workbook.code;
          newPdP.code = code;
          newPdP.name = newPdP.getName();
          this._signablePreventionPlanService.save(newPdP).then((res) => {
            //TODO ouvrir popup?
            if (showSnackbar) {
              this.snackBar.open('L\'édition du plan de prévention a été créée', '', {
                duration: 2000
              });
            }
          });
        }).catch((error) => {
          this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
            duration: 2000
          });
        });
      }
    });
  }
}
