import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityDocumentation} from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService extends EntitySecureService {

  autoInit: boolean = false;

  static LABEL_ONE = "chargement de la documentation";
  static LABEL_ALL = "chargement des documentations";
  static LABEL_CHILDREN = "chargement des documentations de l'opération ???";
  static LABEL_SAVE = "sauvegarde de la documentation";
  static LABEL_DELETE = "suppression de la documentation";

  static ENTITY_TYPE: string = 'repo_document';
  static ENTITY_FIELDS: string[] = ['meta', 'oo', 'oo_pdp', 'oo_icp', 'oo_pf', 'oo_ps', 'op', 'op_pdp', 'op_icp', 'op_pf', 'op_ps', 'ps', 'ps_ps'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showRepoDocument'],
    CREATE: ['createRepoDocument'],
    UPDATE: ['editRepoDocument'],
    DELETE: ['removeRepoDocument']
  }

  static ENTITY_URL = 'repository/document';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityDocumentation {
    return new EntityDocumentation(data, clone);
  }

}
