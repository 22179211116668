import {ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ldcm} from 'app/shared/models';
import {LdcmService, EntityLdcm} from 'app/workbook-core';

@Component({
  selector: 'app-ldcm-dialog',
  templateUrl: './ldcm-dialog.component.html',
  styleUrls: ['./ldcm-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class LdcmDialogComponent implements OnDestroy {

  ldcm: Ldcm[];
  selectedGroup: string = '';
  selectedLdcm: Ldcm[] = [];
  selected: string[] = [];
  ldcmList: Ldcm[] = [];
  formNotValid: boolean = false;
  nextInBottom: string = 'out';
  nextInBottomInit: boolean = false;
  protected _ldcmSubscription: Subscription;
  /*
    // TODO version 1.x put the list in DB
    listInitLdcm = [
      {name: 'Sanitaires / Douches', group: 'locals'},
      {name: 'Vestiaire', group: 'locals'},
      {name: 'Local de réunion', group: 'locals'},
      {name: 'Local de restauration', group: 'locals'},
      {name: 'Lieu de stationnement', group: 'locals'},
      {name: 'Lieu de stockage de produits', group: 'locals'},
      {name: 'Eau potable', group: 'locals'},
      {name: 'Autre(s)', group: 'locals'},

      {name: 'Plan du site et des voies de circulation', group: 'documents'},
      {name: 'Limite de secteur d\'intervention', group: 'documents'},
      {name: 'Procédure d’évacuation des locaux ', group: 'documents'},
      {name: 'Consignes en cas d’incident ou d’accident', group: 'documents'},
      {name: 'Consignes d’utilisation du défibrillateur', group: 'documents'},
      {
        name: 'Organisation des secours', group: 'documents', reliefOrg: [
          {name: 'Extincteur', toggle: true},
          {name: 'Poudre', toggle: true},
          {name: 'CO2', toggle: true},
          {name: 'Eau pulvérisée', toggle: true},
          {name: 'Localisation', toggle: false},
          {name: 'Téléphone d’urgence', toggle: false},
          {name: 'Secouristes', toggle: false}
        ]
      },
      {name: 'Zone réservée à l’entreprise extérieure / stockage', group: 'documents'},
      {name: 'Autre(s)', group: 'documents'},

      {name: 'Permis de feu', group: 'mandatInstruction'},
      {name: 'Coupure réseau', group: 'mandatInstruction'},
      {name: 'Consignation électrique', group: 'mandatInstruction'},
      {name: 'Utilisation de produits dangereux', group: 'mandatInstruction'},
      {name: 'Travaux à risques particuliers', group: 'mandatInstruction'},
      {name: 'DICT (Déclaration d’Intention de Commencement de Travaux)', group: 'mandatInstruction'},
      {name: 'Permission de voiries et arrêté de circulation', group: 'mandatInstruction'},
      {name: 'Plan de retrait et d’élimination de l’amiante', group: 'mandatInstruction'},
      {name: 'Autre(s)', group: 'mandatInstruction'},

      {name: 'Port d’EPI', group: 'generalInstruction'},
      {name: 'Port de PTI', group: 'generalInstruction'},
      {name: 'Port de tout autre équipement de protection', group: 'generalInstruction'},
      {name: 'Balisage de la zone de travail', group: 'generalInstruction'},
      {name: 'Attestation secouriste', group: 'generalInstruction'},
      {name: 'Attestation permis', group: 'generalInstruction'},
      {name: 'Autorisation de conduite', group: 'generalInstruction'},
      {name: 'Titres d’habilitations électriques nécessaires à l’intervention', group: 'generalInstruction'},
      {name: 'Soudage (installation sous pression)', group: 'generalInstruction'},
      {name: 'Autre(s)', group: 'generalInstruction'}
    ];
    listTitle = {
      locals: 'Locaux mis à dispositions',
      documents: 'Documents remis et commentés',
      mandatInstruction: 'Consignes Obligatoires',
      generalInstruction: 'Consignes générales'
    }
    */
  listTitle: {[id: string]: string} = {};
  constructor(
    public dialogRef: MatDialogRef<LdcmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef,
    protected _ldcmService: LdcmService
  ) {

    LdcmService.LDCM_GROUP.forEach((g) => {
      this.listTitle[g] = LdcmService.LDCM_LABELS[g];
    });
    if (data.group) {
      this.selectedLdcm = data.IPLdcm ? data.IPLdcm : [];
      this.selected = this.selectedLdcm.filter((l) => l.group === data.group).map(e => e.name);
      this.selectedGroup = data.group;
    }
    this._ldcmSubscription = this._ldcmService.getAll().subscribe((list) => {
      if (list) {
        this.ldcmList = [];
        list.forEach((ldcm) => {
          if (ldcm.group === this.selectedGroup) {
            const _ldcm = new Ldcm(ldcm);
            _ldcm.checked = this.selectedLdcm.findIndex((e) => ((e.name === ldcm.name) && (e.group === ldcm.group))) !== -1;
            this.ldcmList.push(_ldcm);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this._ldcmSubscription) {
      this._ldcmSubscription.unsubscribe();
    }
  }
  toggleLdcm(ldcmLocal) {
    if (ldcmLocal.checked) {
      this.selected.push(ldcmLocal.name);
    } else {
      const i = this.selected.indexOf(ldcmLocal.name);
      if (i > -1) {
        this.selected.splice(i, 1);
      }
    }
  }
  compareFn(ee1: Ldcm, ee2: Ldcm): boolean {
    return ee1 && ee2 ? ee1.name === ee2.name : ee1 === ee2;
  }

  // TODO : duplicate code with create-pp.component & ldcm-dialog
  /**
   *  Init the scroll detection component
   * @param event
   */
  public handleInit(event: any) {
    if (event.hasScrollBar) {
      this.nextInBottom = 'in';
      this.nextInBottomInit = true;
      this.cdRef.detectChanges();
    } else {
      this.nextInBottom = 'out';
      this.nextInBottomInit = false;
      this.cdRef.detectChanges();
    }
  }

  /**
   * Get event scroll positions
   * @param event
   */
  public handleScroll(event: any) {
    if (event.isReachingBottom) {
      this.nextInBottom = 'out';
    }
    if (event.isReachingTop) {
      this.nextInBottom = 'in';
    }
  }

  /**
   * To launch scroll in view
   * @param el
   */
  public scrollTo(el) {
    el.scrollIntoView();
  }

  onSubmit(ldcmForm: NgForm) {
    const returned = [];
    this.selectedLdcm.forEach((ldcm) => {
      if (ldcm.group !== this.selectedGroup) {
        returned.push(ldcm);
      } else {
        let i = this.selected.indexOf(ldcm.name);
        // déjà sélectionner ou autre groupe => reprendre l'existant
        if (i > -1) {
          this.selected.splice(i, 1);
          returned.push(ldcm);
        }
      }
      // si du bon groupe et pas selectionné pas d'ajout = suppression
    });

    this.selected.forEach((name) => {
      const existing = this.ldcmList.find((e) => (e.name === name));
      if (existing) {
        returned.push(new Ldcm(existing));
      }
    });
    this.dialogRef.close(returned);
  }

}
