import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoggerService } from 'app/shared';
import { PreventionPlanService, EntityStep, EntitySite, EntityZone } from 'app/workbook-core';
import { AbstractWorkbookEditComponent } from 'app/workbook-shared';
import { PreventionPlanComponent } from '../preventionPlan.component';

@Component({
  selector: 'app-prevention-plan-edit',
  templateUrl: './preventionPlanEdit.component.html',
  styleUrls: ['./preventionPlanEdit.component.scss']
})

export class PreventionPlanEditComponent extends AbstractWorkbookEditComponent {
  entityForm: PreventionPlanComponent;
  step_id: string = '';
  protected _stepSubscription: Subscription;
  constructor(
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(_route, _router, _logger, _entityService);
  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (this.id) {
      this._stepSubscription = this._entityService.stepService.getChildren(this.id).subscribe((steps) => {
        if (steps && steps.length === 1) {
          this.step_id = steps[0]._id;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
  }
  sameZones(from: EntityZone[], to: EntityZone[]) {
    return from.length === to.length
      && from.filter((z, i) => (z._id !== to[i]._id || z.name !== to[i].name && z.repo_id !== to[i].repo_id)).length === 0;
  }
  onSimpleStepSave(step) {
    if (this.entityForm && this.entityForm.entity && this.entityForm.entity['simple']
      && (this.entityForm.entity['description'] !== step['name']
        || this.entityForm.entity['reference'] !== step['reference']
        //|| this.entityForm.entity['point'] !== step['point']
        || (!this.entityForm.entity['site'] && step['site'])
        || (this.entityForm.entity['site'] && !this.entityForm.entity['site']['zones'] && step['site'] && step['site']['zones'])
        || (this.entityForm.entity['site'] && this.entityForm.entity['site']['zones'] && step['site'] && step['site']['zones']
          && !this.sameZones(this.entityForm.entity['site']['zones'], step['site']['zones']))
        || (step['site']['name'] && (!this.entityForm.entity['site']['name'] || this.entityForm.entity['site']['name'] !== step['site']['name']))
        || (step['site']['phone'] && (!this.entityForm.entity['site']['phone'] || this.entityForm.entity['site']['phone'] !== step['site']['phone']))
        || (step['site']['address'] && (!this.entityForm.entity['site']['address'] || this.entityForm.entity['site']['address'] !== step['site']['address']))
        || (step['site']['zipCode'] && (!this.entityForm.entity['site']['zipCode'] || this.entityForm.entity['site']['zipCode'] !== step['site']['zipCode']))
        || (step['site']['city'] && (!this.entityForm.entity['site']['city'] || this.entityForm.entity['site']['city'] !== step['site']['city']))
        || new Date(this.entityForm.entity['dateStart']).getTime() !== new Date(step['dateStart']).getTime()
        || new Date(this.entityForm.entity['dateEnd']).getTime() !== new Date(step['dateEnd']).getTime()
        || this.entityForm.entity['hourStart'] !== step['hourStart']
        || this.entityForm.entity['hourEnd'] !== step['hourEnd']
        || this.entityForm.entity['workConditions'] !== step['workConditions']
      )) {
      this.entityForm.entity['description'] = step['name'];
      this.entityForm.entity['reference'] = step['reference'];
      this.entityForm.entity['site'] = step['site'];
      if (this.entityForm.entity['site'] && this.entityForm.entity['site']['zones'] && this.entityForm.entity['site']['zones'].length) {
        this.entityForm.entity['site']['zones'] = this.entityForm.entity['site']['zones'].map((e) => {
          delete e.risks;
          delete e.risks_situations;
          delete e.situations;
          delete e.measures;
          delete e.companies;
          return e;
        });
        this.entityForm.entity['zone'] = this.entityForm.entity['site']['zones'][0];
        this.entityForm.entity['point'] = this.entityForm.entity['zone']['name'];
      } else {
        this.entityForm.entity['point'] = step['point'] ? step['point'] : '';
        this.entityForm.entity['zone'] = step['zone'] ? step['zone'] : null;
      }
      this.entityForm.entity['dateStart'] = step['dateStart'];
      this.entityForm.entity['dateEnd'] = step['dateEnd'];
      this.entityForm.entity['hourStart'] = step['hourStart'];
      this.entityForm.entity['hourEnd'] = step['hourEnd'];
      this.entityForm.entity['workConditions'] = step['workConditions'];
      this.entityForm.entity.updateStep(step);
      this.save();
    }
  }
  backToList() {
  }
}
