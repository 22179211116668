import { Component, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { EntityDocumentMeta } from '../../models/entityDocumentMetadata.model';

@Component({
  selector: 'app-workbook-download-signable',
  templateUrl: './downloadSignable.component.html',
  styleUrls: ['./downloadSignable.component.scss']
})
export class DownloadSignableComponent {
  choice: string = 'pdf';
  documents: EntityDocumentMeta[];
  constructor(
    public dialogRef: MatDialogRef<DownloadSignableComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
    //,protected _licence: LicencesService
  ) {
    if (data) {
      if (data['documents']) {
        this.documents = data['documents'];
      }
    }
  }
  submit() {
    this.dialogRef.close(this.choice);
  }
}

