import {Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityIcp} from 'app/workbook-core';
import {WorkbookActivityListComponent} from 'app/workbook-shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-icp-activity-list',
  templateUrl: './icpActivityList.component.html',
  styleUrls: ['../../workbook-shared/activity/activityList.component.scss']
})

export class PreventionPlanIcpActivityListComponent extends WorkbookActivityListComponent {
  icp: EntityIcp;
  icp_id: string;
  constructor(
    protected _cd: ChangeDetectorRef,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_cd, _spinnerService, dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _initFromRouteParams(params) {
    super._initFromRouteParams(params);
    if (params['icp_id']) {
      this.icp_id = params['icp_id'];
      this._entityService.icpService.getCurrentOne(this.icp_id).then((data) => {
        if (data) {
          this.icp = data;
          this.filter();
        }
      });
    }

  }
  isHidden(id) {
    return super.isHidden(id) || this.activitiesWithoutStep.indexOf(id) !== -1;
  }
  filter() {
    if (this.icp) {
      this.steps = this.steps.filter((e) => (this.icp.steps && this.icp.steps.indexOf(e._id) !== -1));
      this.templates = this.templates.filter((e) => (this.icp.steps && this.icp.steps.indexOf(e._id) !== -1));
      super.filter();
    }
  }

  getActivityTooltip() {
    let msg = '';
    if (this.entity && this.entity.isComplete()) {
      msg = 'Ce dossier est terminé';
    } else if (!this.rightC) {
      msg = 'Vous n\'avez pas les droits pour créer une nouvelle activité';
    } else if (this.withActiveSteps) {
      if (!this.companies.length) {
        msg = 'Vous devez saisir au moins un intervenant pour pouvoir créer des activités.';
      } else if (this.selectedSteps && this.selectedSteps.filter((s_id) => (this.completedSteps[s_id])).length) {
        msg = 'Le chantier est terminé';
      }
    } else {
      msg = 'Aucun chantier sélectionnable';
    }
    return msg;
  }

}
