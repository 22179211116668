import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { forkJoin } from 'rxjs';
import { LoggerService } from 'app/shared/logger';
import { DocumentService } from 'app/shared/document';
import { CommonComponent } from '../common.component';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent extends CommonComponent {

  progress;
  hasError: boolean = false;
  errorMessage: string;
  canBeClosed = false;
  primaryButtonText = 'Charger';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  fileSizeMax: number = 10; // 1Mo

  parent_id: string;
  context: string;

  @ViewChild('file') file;
  public files: Set<File> = new Set();

  constructor(
    public dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _documentService: DocumentService,
    protected _logger: LoggerService
  ) {
    super(_logger);
    if (data) {
      if (data.parent_id) {
        this.parent_id = data.parent_id;
      }
      if (data.context) {
        this.context = data.context;
      }
    }
  }


  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 10))) {
        const fileSize = files[key].size / 1024 / 1024; // in MB
        if (fileSize >= this.fileSizeMax) {
          this.hasError = true
          this.errorMessage = 'Erreur : Le fichier ' + files[key].name + ' que vous essayez de charger a une taille trop grande : ' + fileSize.toPrecision(2) + ' Mo (' + this.fileSizeMax + ' Mo maximum)';
          return;
        }
        this.files.add(files[key]);
      }
    }
  }

  closeDialog() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      return this.dialogRef.close(this.files);
    }

    // set the component state to "uploading"
    this.uploading = true;

    // Control each file size
    // const fileSize = file.size / 1024 / 1024; // in MB
    // if (fileSize >= this.fileSizeMax) {
    //   console.log('Erreur taille ');
    //   return progress.complete();
    // }

    // start the upload and save the progress map
    this.progress = this._documentService.uploadFiles(this.files, this.context, this.parent_id);


    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in this.progress) {
      if (this.progress.hasOwnProperty(key)) {
        allProgressObservables.push(this.progress[key].progress);
      }
    }
    // Adjust the state variables

    // The OK-button should have the text "Finish" now
    this.primaryButtonText = 'Terminé';

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    forkJoin(allProgressObservables).subscribe(end => {
      // ... the dialog can be closed again...
      this.canBeClosed = true;
      this.dialogRef.disableClose = false;

      // ... the upload was successful...
      this.uploadSuccessful = true;

      // ... and the component is no longer uploading
      this.uploading = false;
    },
      error => {
        this._logger.error('UploadDialogComponent', 'err in component', JSON.stringify(error));
      }
    );
  }
}
