import { ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, MatSort, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import {
  formattedDate, promiseAll,
  PermissionsService,
  LoggerService,
  ConfirmDialogComponent,
  Contact,
  EntitySecureService,
  EntityDocumentMetadata,
  DocumentService,
  DocumentMetadataDialogComponent
} from 'app/shared';
import { EntityOffice, EntityCategory, CategoryService, PreventionPlanService, EntityStep, EntityActivity } from 'app/workbook-core';
import { AbstractWorkbookChildFormComponent } from './abstractWorkbookChildForm.component';
import { WorkbookContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { WorkbookOfficeDialogComponent } from './office/office-dialog.component';
import { WorkbookDocumentsUploadDialogComponent } from './document/documentsUpload-dialog.component';
import { WorkbookDocumentsRequestDialogComponent } from './document/documentsRequest-dialog.component';


export class AbstractWorkbookOfficeComponent extends AbstractWorkbookChildFormComponent {

  entity: EntityOffice;
  internal: boolean = true;

  selectedDocument: EntityDocumentMetadata;
  displayedDocumentColumns: string[] = ['categoryPicto', 'name', 'category', 'date', 'download', 'actions'];
  documentDataSource: MatTableDataSource<EntityDocumentMetadata> = new MatTableDataSource([]);
  @ViewChild('documentSort') documentSort: MatSort;

  selectedContact: Contact;
  displayedContactColumns: string[] = ['name', 'job', 'phone', 'email', 'signatoryPdP', 'signatory', 'signatoryPermit', 'chsct', 'userActions', 'actions'];
  contactDataSource: MatTableDataSource<Contact> = new MatTableDataSource([]);
  @ViewChild('contactSort') contactSort: MatSort;

  userListSub: Subscription;
  usersList = [];

  mainOffice: string = '';
  protected _mainOfficeSubscription: Subscription;

  categoryList: EntityCategory[] = [];
  protected _categorySubscription: Subscription;

  protected _activitySubscription: Subscription;
  activities: EntityActivity[];
  protected _stepSubscription: Subscription;
  steps: EntityStep[];

  constructor(
    protected _preventionPlanService: PreventionPlanService,
    protected _categoryService: CategoryService,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _permissionsService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService

  ) {
    super(dialog, _logger, _entityService);
    this.userListSub = this._permissionsService.auth.usersList$.subscribe((list) => {
      if (list) {
        this.usersList = list;
      }
    });
    this.refreshUserList();
    // console.log('AbstractOfficeComponent');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.userListSub) {
      this.userListSub.unsubscribe();
    }
    if (this._categorySubscription) {
      this._categorySubscription.unsubscribe();
    }
    if (this._mainOfficeSubscription) {
      this._mainOfficeSubscription.unsubscribe();
    }

    if (this._activitySubscription) {
      this._activitySubscription.unsubscribe();
    }
    if (this._stepSubscription) {
      this._stepSubscription.unsubscribe();
    }
  }
  protected _init(data) {
    super._init(data);
    if (this.entity) {
      if (!this._categorySubscription) {
        this._categorySubscription = this._categoryService.getAll().subscribe((list) => {
          if (list) {
            this.categoryList = list;
          }
        });
      }
      this.updateDocumentDataSource();
      this.updateContactDataSource();
      this.mainOffice = '';
      if (this._mainOfficeSubscription) {
        this._mainOfficeSubscription.unsubscribe();
      }
      if (this.entity.main) {
        this._mainOfficeSubscription = this._entityService.getOne(this.entity.main).subscribe((mainOffice) => {
          if (mainOffice && mainOffice.name) {
            this.mainOffice = mainOffice.name;
          }
        });
      }

      if (this.entity.parent_id) {
        if (!this._activitySubscription) {
          this._activitySubscription = this._preventionPlanService.activityService.getChildren(this.entity.parent_id).subscribe((list: EntityActivity[]) => {
            if (list) {
              this.activities = list.filter((a) => (a.companies && a.companies.findIndex((c) => (c.id === this.entity._id)) !== -1));
            }
          });
        }
        if (!this._stepSubscription) {
          this._stepSubscription = this._preventionPlanService.stepService.getChildren(this.entity.parent_id).subscribe((list: EntityStep[]) => {
            if (list) {
              this.steps = list.filter((s) => (JSON.stringify(s.scopedMeasures).indexOf(this.entity._id) !== -1));
            }
          });
        }
      }
    }
  }
  public openDialog() {
    if (this.rightU) {
      const dialogRef = this.dialog.open(WorkbookOfficeDialogComponent, {
        disableClose: true,
        minWidth: '960px',
        data: {
          id: this.entity._id
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        if (c && c !== 'undefined' && !!c._rev && !!this.entity._rev) {
          if (c._rev !== this.entity._rev) {
            this.entity.copyFrom(c);
          }
          this.updateContactDataSource();
          this.onSave.emit(this.entity);
        }
      });
    }
  }


  /**
   * Refresh user List
   */
  refreshUserList() {
    if (this.hasPermission('showUsersPP')) {
      this._permissionsService.auth.refreshUsersList().then((res) => {
        //        this.usersList = auth0UserList;
      },
        error => {
          this._logger.error('AbstractOfficeComponent', 'error get users list', JSON.stringify(error));
        }
      );
    }
  }

  protected setDocumentSort() {
    if (this.documentSort) {
      this.documentDataSource.sort = this.documentSort;
    }
  }
  protected updateDocumentDataSource() {
    this.documentDataSource = new MatTableDataSource((this.entity && this.entity.documents) ? this.entity.documents : []);
    setTimeout(() => {
      this.setDocumentSort();
    });
  }
  openDocumentDialog(document: EntityDocumentMetadata = null) {
    if (this.rightU) {
      if (document) {
        this.selectedDocument = document;
      } else {
        this.selectedDocument = null;
      }
      const dialogRef = this.dialog.open(DocumentMetadataDialogComponent, {
        disableClose: true,
        minWidth: '900px',
        maxHeight: '90%',
        data: {
          entity: this._documentService.getNewDocumentMetada(document ? document : null),
          categoryList: this.categoryList,
          requestList: this.entity.contacts.filter((e) => (!!e.email)),
          workbook: this.workbook
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          this.upsertDocument(c);
        }
      });
    }
  }

  /**
   * Get document uploaded from DB
   * @param {string} id
   */
  public getDocFromDb(docMeta) {
    this._documentService.downloadDocumentFromMetadata(docMeta);
  }

  upsertDocument(document: EntityDocumentMetadata): void {
    if (!this.entity.documents) {
      this.entity.documents = [];
    }
    let index = -1;
    if (this.selectedDocument) {
      this.updateSelectedDocument(document);
      index = this.entity.documents.indexOf(this.selectedDocument);
    } else {
      this.selectedDocument = document;
    }
    if (index === -1) {
      this.entity.documents.push(this.selectedDocument);
    }
    this.updateDocumentDataSource();
    if (this.autoSave && this.form.valid) {
      this._save();
    } else if (this.form && !this.form.dirty) {
      this.form.control.markAsDirty();
    }
  }

  updateSelectedDocument(document: EntityDocumentMetadata) {
    this.selectedDocument.name = document.name;
    this.selectedDocument.ext = document.ext;
    this.selectedDocument.status = document.status;
    this.selectedDocument.description = document.description;
    this.selectedDocument.category = document.category;
    this.selectedDocument.categoryPicto = document.categoryPicto;
    this.selectedDocument.dateStart = document.dateStart;
    this.selectedDocument.dateEnd = document.dateEnd;
    this.selectedDocument.createdAt = document.createdAt;
    this.selectedDocument.createdName = document.createdName;
    this.selectedDocument.createdId = document.createdId;
    this.selectedDocument.updatedAt = document.updatedAt;
    this.selectedDocument.updatedName = document.updatedName;
    this.selectedDocument.updatedId = document.updatedId;
    this.selectedDocument.requestedAt = document.requestedAt;
    this.selectedDocument.requestedName = document.requestedName;
    this.selectedDocument.requestedId = document.requestedId;
    this.selectedDocument.requestedSent = document.requestedSent.map((e) => (e));
    this.selectedDocument.request = document.request.map((e) => (e));
    this.selectedDocument.notifications = document.notifications.map((e) => (e));
    //if (!this.selectedDocument.data_id && document.data_id) {
    this.selectedDocument.data_id = document.data_id;
    this.selectedDocument.imageState = document.imageState;
    this.selectedDocument.data_origin = document.data_origin;
    this.selectedDocument.displayName = document.displayName;
    this.selectedDocument.mime = document.mime;
    this.selectedDocument.ext = document.ext;
    this.selectedDocument.icon = document.icon;
    this.selectedDocument.uploadedAt = document.uploadedAt;
    this.selectedDocument.uploadedName = document.uploadedName;
    this.selectedDocument.uploadedId = document.uploadedId;
    this.selectedDocument.context = document.context;

    //}
  }

  removeDocument(document: EntityDocumentMetadata) {
    if (this.entity && this.entity.documents) {
      const index = this.entity.documents.indexOf(document);
      if (index > -1) {
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce document ?';
        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.documents.splice(index, 1);
            this.updateDocumentDataSource();
            if (this.autoSave && this.form.valid) {
              this._save();
            } else if (this.form && !this.form.dirty) {
              this.form.control.markAsDirty();
            }
          }
        });
      }

    }
  }

  protected setContactSort() {
    if (this.contactSort) {
      this.contactDataSource.sort = this.contactSort;
    }
  }
  protected updateContactDataSource() {
    this.contactDataSource = new MatTableDataSource((this.entity && this.entity.contacts) ? this.entity.contacts : []);
    setTimeout(() => {
      this.setContactSort();
    });
  }
  clearContactForm() {
    this.selectedContact = new Contact();
  }
  selectContact(contact: Contact = new Contact()) {
    if (this.rightU) {
      this.selectedContact = contact;
      const dialogRef = this.dialog.open(WorkbookContactDialogComponent, {
        disableClose: true,
        minWidth: '600px',
        data: {
          internal: this.internal,
          contact: Object.assign({}, this.selectedContact),
          repo_id: this.entity.repo_id,
          selected_emails: this.entity.contacts.map((e) => e.email),
          usersList: this.usersList
        }
      });

      dialogRef.afterClosed().subscribe(c => {
        // Add intervention to the intervention list
        if (c && c !== 'undefined') {
          this.upsertContact(c);
        }
      });
    }
  }
  /**
   * Create new sub company
   * @param externalCompanyId
   */
  upsertContact(contact): void {
    let existingUser = true;
    this.updateSelectedContact(contact);
    // userlist vide = pas de droits showusers ou scope insuffisant
    if (this.usersList.length && !this.selectedContact.user_id && this.selectedContact.email) {
      const _existingUser = this.usersList.find(e => e.email.toLowerCase() === this.selectedContact.email.toLowerCase());
      if (_existingUser) {
        this.selectedContact.user_id = _existingUser.user_id;
      } else {
        existingUser = false;
      }
    }
    if (!this.entity.contacts) {
      this.entity.contacts = [];
    }
    const index = this.entity.contacts.indexOf(this.selectedContact);
    if (index === -1) {
      this.entity.contacts.push(this.selectedContact);
    }
    this.updateContactDataSource();
    if (!existingUser && this.selectedContact.email) {
      const family_name_array = this.selectedContact.name ? this.selectedContact.name.split(' ') : [];
      let given_name = '';
      if (family_name_array.length > 1) {
        family_name_array.shift();
        given_name = this.selectedContact.name.split(' ')[0];
      }
      const meta = {
        'family_name': family_name_array.join(' '),
        'given_name': given_name,
        'phone_number': this.selectedContact.phone
      };
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRef.componentInstance.confirmMessage = 'Votre contact a bien été ajouté.<br>'
        + 'Souhaitez-vous dès maintenant lui créer un compte, et donc lui envoyer une invitation à rejoindre l\'application ?<br>' +
        'Vous avez aussi la possibilité de faire cela plus tard, dans ce cas cliquez sur “Non”.';
      dialogRef.componentInstance.cancelMessage = 'Non';
      dialogRef.componentInstance.buttonMessage = 'Oui';

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._getCreateUserPromise(this.selectedContact.email, '', meta).then(
            (u) => {
              this.refreshUserList();
              this.selectedContact.user_id = u.user_id;
              this.updateContactDataSource();
              this.snackBar.open('Utilisateur créé', '', {
                duration: 2000
              });
              this._save().then((e) => {
                this._afterSave(e);
              });
            },
            (error) => {
              if (error && error.status && error.status === 409) {
                this.refreshUserList();
              }
              this._logger.error('ExternalCompaniesComponent', 'error to add contact', JSON.stringify(error));
              this.snackBar.open('Erreur', 'L\'utilisateur n\'a pas pu être créé', {
                duration: 2000
              });
            })
        } else {
          this._save().then((e) => {
            this._afterSave(e);
          });
        }
      });
    } else {
      this._save().then((e) => {
        this._afterSave(e);
      });
    }
  }

  _getCreateUserPromise(email, password = '', meta = {}, sendmail = true) {
    return this._permissionsService.createExternalUser(email, password, meta, sendmail, ['PP_CHOP']);
  }
  updateSelectedContact(contact: Contact) {
    this.selectedContact.user_id = contact.user_id;
    this.selectedContact.name = contact.name;
    this.selectedContact.email = contact.email;
    this.selectedContact.phone = contact.phone;
    this.selectedContact.fax = contact.fax;
    this.selectedContact.job = contact.job;
    this.selectedContact.signatoryPermit = contact.signatoryPermit;
    this.selectedContact.signatory = contact.signatory;
    this.selectedContact.signatoryPdP = contact.signatoryPdP;
    this.selectedContact.chsct = contact.chsct;
    this.selectedContact.repo_id = contact.repo_id;
    this.selectedContact.isDefault = contact.isDefault;
  }
  /**
  * Delete sub company
  * @param {string} externalCompanyId
  * @param {number} index
  */
  removeContact(contact: Contact) {
    if (this.entity && this.entity.contacts) {
      const index = this.entity.contacts.indexOf(contact);
      if (index > -1) {
        // this.rmContact(this.externalCompanySelected.id, index);
        const dialogRefDel = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        dialogRefDel.componentInstance.confirmMessage = 'Êtes-vous sûr de vouloir supprimer ce contact ?';

        dialogRefDel.afterClosed().subscribe(result => {
          if (result) {
            this.entity.contacts.splice(index, 1);
            this.updateContactDataSource();
            this._save().then((e) => {
              this._afterSave(e);
            });
          }
        });
      }

    }
  }

  /**
   * create user
   * @param user
   * @param clearForm
   */
  createUser(user, clearForm = false) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRef.componentInstance.confirmMessage = 'Voulez-vous créer l\'utilisateur ? <br>Attention si vous confirmez, un email sera envoyé immédiatement à l’utilisateur avec un lien pour rejoindre l’application.<br>' +
      'Vous avez la possibilité de créer l’utilisateur du contact ultérieurement, pour cela cliquez sur Annuler.';
    dialogRef.componentInstance.buttonMessage = 'Confirmer';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const existingUser = this.usersList.find(e => e.email.toLowerCase() === user.email.toLowerCase());
        if (existingUser) {
          user.user_id = existingUser.user_id;
          this.snackBar.open('Utilisateur existant ajouté', '', {
            duration: 2000
          });
          if (clearForm) {
            setTimeout(() => { this.clearContactForm(); });
          }
        } else {
          const family_name_array = user.name ? user.name.split(' ') : [];
          let given_name = '';
          if (family_name_array.length > 0) {
            family_name_array.shift();
            given_name = user.name.split(' ')[0];
          }
          const meta = {
            'family_name': family_name_array.join(' '),
            'given_name': given_name,
            'phone_number': user.phone
          };
          this._getCreateUserPromise(user.email, '', meta).then((u) => {
            this.refreshUserList();
            user.user_id = u.user_id;
            this.snackBar.open('Utilisateur créé', '', {
              duration: 2000
            });
            this._save().then((e) => {
              if (clearForm) {
                setTimeout(() => { this.clearContactForm(); });
              }
              this._afterSave(e);
            });

          }, (error) => {
            if (error && error.status && error.status === 409) {
              this.refreshUserList();
            }
            this._logger.error('ExternalCompaniesComponent', 'error to create user', JSON.stringify(error));
            this.snackBar.open('Erreur', 'L\'utilisateur n\'a pas pu être créé', {
              duration: 2000
            });
            if (clearForm) {
              setTimeout(() => { this.clearContactForm(); });
            }
          });
        }
        this._save().then((e) => {
          this._afterSave(e);
        });
      } else {
        if (clearForm) {
          setTimeout(() => { this.clearContactForm(); });
        }
      }
    });
  }

  /**
   * Send confirmation email
   * @param user
   */
  sendConfirmEmail(user) {
    this._permissionsService.emailVerificationTicket(user.user_id, user, true).then((e) => {
      this.snackBar.open('Le message a été envoyé', '', {
        duration: 2000
      });
    });
  }

  userExists(user_id: string) {
    const test1 = !!user_id;
    const test2 = !this.usersList.length;
    const test = !!user_id && (!this.usersList.length || this.usersList.findIndex(e => e.user_id === user_id) !== -1);
    return !!user_id && (!this.usersList.length || this.usersList.findIndex(e => e.user_id === user_id) !== -1);
  }


  getUserInfo(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return user ? user.name + (user.name !== user.email ? ' (' + user.email + ')' : '') : '';
  }
  getUserTooltip(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return user ? (user.email_verified ? 'Dernière connexion/modification: \n' : ((user.created_at === user.updated_at) ? 'Compte créé: \n' : 'Dernière modification: \n')) + formattedDate(user.updated_at, true) : '';
  }
  getEmailVerified(user_id: string) {
    const user = this.usersList.find(e => e.user_id === user_id);
    return (user && !!user.email_verified);
  }

  public addDocumentsDialog() {
    const dialogRef = this.dialog.open(WorkbookDocumentsUploadDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        parent_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        multiple: true,
        selectedEex: this.entity,
        workbook: this.workbook
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.updateDocumentDataSource();
        this.onSave.emit(this.entity);
      }
    });

  }
  public requestDocumentsDialog() {
    const dialogRef = this.dialog.open(WorkbookDocumentsRequestDialogComponent, {
      disableClose: true,
      width: '900px',
      maxHeight: '90%',
      data: {
        parent_id: (this.entity && this.entity._id) ? this.entity._id : null,
        categoryList: this.categoryList,
        multiple: true,
        selectedEex: this.entity,
        workbook: this.workbook
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        if (c._rev !== this.entity._rev) {
          this.entity.copyFrom(c);
        }
        this.updateDocumentDataSource();
        this.onSave.emit(this.entity);
      }
    });

  }
  remove() {
    if (this.entity && this.entity._id) {
      const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
        disableClose: false,
        width: '600px',
      });
      dialogRefDel.componentInstance.confirmMessage = this.rmConfirmMessage;
      if (this.activities && this.activities.length) {
        dialogRefDel.componentInstance.confirmMessage += '<br>cet intervenant est affecté aux activités suivantes:';
        this.activities.forEach((a) => {
          dialogRefDel.componentInstance.confirmMessage += '<br> - ' + a.name;
        });
      }
      if (this.steps && this.steps.length) {
        dialogRefDel.componentInstance.confirmMessage += '<br>cet intervenant est affecté à la mise en place de mesures';
        if (!this.workbook.simple) {
          dialogRefDel.componentInstance.confirmMessage += ' pour les interventions suivantes:';
          this.steps.forEach((a) => {
            dialogRefDel.componentInstance.confirmMessage += '<br> - ' + a.name;
          });
        }
      }
      dialogRefDel.afterClosed().subscribe(result => {
        if (result) {
          const stepsSaver = this.steps.map((s) => (this._preventionPlanService.stepService.getCurrentOne(s._id).then((step: EntityStep) => {
            Object.keys(step.scopedMeasures).forEach((x) => {
              Object.keys(step.scopedMeasures[x]).forEach((y) => {
                if (step.scopedMeasures[x][y] && step.scopedMeasures[x][y].length) {
                  const z = step.scopedMeasures[x][y].indexOf(this.entity._id);
                  if (z !== -1) {
                    step.scopedMeasures[x][y].splice(z, 1);
                    if (step.scopedMeasures[x][y].length === 0) {
                      delete step.scopedMeasures[x][y];
                    }
                    if (Object.keys(step.scopedMeasures[x]).length === 0) {
                      delete step.scopedMeasures[x];
                    }
                  }
                }
              });
            });
            this._preventionPlanService.stepService.save(step);
          })
          ));
          const activitiesSaver = this.activities.map((a) => (this._preventionPlanService.activityService.getCurrentOne(a._id).then((activity: EntityActivity) => {
            const i = activity.companies.findIndex((e) => (e.id === this.entity._id));
            if (i !== -1) {
              activity.companies.splice(i, 1);
            }
            this._preventionPlanService.activityService.save(activity);
          })
          ));
          promiseAll([...activitiesSaver, ...stepsSaver])
            .then((x) => (
              this._entityService.remove(this.entity).then((e) => {
                this.entity._deleted = true;
                this.onSave.emit(this.entity);
              })
            ))
            .catch((errors) => {
              this.log('Error saving modified activities/steps', errors);
            });

          //this.snackbar('Le métier a été supprimé');
        }
      });
    }
  }
}
