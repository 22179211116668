import {
  DocumentMetadata
} from 'app/shared/upload';

import {ActivityCompany} from './activity.model';
import {ActivityModel} from './activity.model';
import {Comment} from './comment.model';
import {Icp} from './icp.model';
import {Measure} from './measure.model';
import {ExploitingCompanyModel} from './exploiting-company.model';
import {ExternalCompany, Office} from './external-company.model';
import {Signature} from './signature.model';

export class Pp1 {
  public editor: string;
  public editorEmail: string;
  public dateStart: Date;
  public dateEnd: Date;
  public address: string;
  public zipCode: string;
  public city: string;
  public phone: string;
  public point: string;
  public site: string;
  public description: string;
  public hourStart: Date;
  public hourEnd: Date;
  public workConditions: string;
  public comments: Comment[];
  public documentMetadata: DocumentMetadata[];

  constructor(ppData: any) {
    if (ppData) {
      this.editor = ppData.editor;
      this.editorEmail = ppData.editorEmail;
      this.dateStart = new Date(ppData.dateStart);
      this.dateEnd = new Date(ppData.dateEnd);
      this.address = ppData.address;
      this.zipCode = ppData.zipCode;
      this.phone = ppData.phone;
      this.city = ppData.city;
      this.point = ppData.point;
      this.site = ppData.site;
      this.description = ppData.description;
      this.hourStart = ppData.hourStart ? new Date(ppData.hourStart) : null;
      this.hourEnd = ppData.hourEnd ? new Date(ppData.hourEnd) : null;
      this.workConditions = ppData.workConditions;
      this.comments = ppData.comments || [];
      this.documentMetadata = ppData.documentMetadata || [];
    }
  }
}

export class Pp2 {
  public exploitingCompany: ExploitingCompanyModel;
  public offices: Office[];
  public externalCompanies: ExternalCompany[];
  public comments: Comment[];
  public documentMetadata: DocumentMetadata[];

  constructor(ppData: any) {
    if (ppData) {
      this.exploitingCompany = ppData.exploitingCompany || new ExploitingCompanyModel();
      this.offices = !!ppData.offices && ppData.offices.length > 0 ? ppData.offices : [];
      this.externalCompanies = !!ppData.externalCompanies && ppData.externalCompanies.length > 0 ? ppData.externalCompanies : [];
      this.comments = ppData.comments || [];
      this.documentMetadata = ppData.documentMetadata || [];
    }
  }
}

export class Pp3 {
  public icp: Icp[];
  public comments: Comment[];
  public documentMetadata: DocumentMetadata[];

  constructor(ppData: any) {
    if (ppData) {
      this.icp = ppData.icp || [];
      this.comments = ppData.comments || [];
      this.documentMetadata = ppData.documentMetadata || [];
    }
  }
}

export class Pp4 {
  public activities: ActivityModel[];
  public localMeasures: Measure[];
  public comments: Comment[];
  public documentMetadata: DocumentMetadata[];

  constructor(ppData: any) {
    if (ppData) {
      this.activities = ppData.activities ? ppData.activities.map(int => new ActivityModel(int)) : [];
      this.localMeasures = ppData.localMeasures || [];
      this.comments = ppData.comments || [];
      this.documentMetadata = ppData.documentMetadata || [];
    }
  }
}

export class Pp5 {
  public signatures: Signature[];
  public comments: Comment[];
  public documentMetadata: DocumentMetadata[];

  constructor(ppData: any) {
    if (ppData) {
      this.signatures = ppData.signatures || [];
      this.comments = ppData.comments || [];
      this.documentMetadata = ppData.documentMetadata || [];
    }
  }
}

export class PlanPrevention {
  public _id: string;
  public _rev: string;
  public _conflicts: string;
  // public oldId: string;
  public name: string;
  // status : in_preparation, in_progress, completed, archived
  public status: string;
  public statusColor: string;
  // annual, occasional
  public type: string;
  public documentType: string;
  public documentsLinked: string[];
  public pp1: Pp1;
  public pp2: Pp2;
  public pp3: Pp3;
  public pp4: Pp4;
  public pp5: Pp5;
  public users: string[];
  public annexes: DocumentMetadata[];
  public created_at: Date;
  public updated_at: Date;
  public created_by: string;
  public updated_by: string;

  constructor(ppData: any) {
    if (ppData) {
      // Step-one
      this._id = ppData._id;
      this._rev = ppData._rev;
      this._conflicts = ppData._conflicts;  // used to get conflict couchDB
      // this.oldId = ppData.oldId;
      this.name = ppData.name;
      this.status = ppData.status || 'Préparation';
      this.statusColor = ppData.statusColor || '#f9b233';
      this.type = ppData.type || 'occasional';
      this.documentType = ppData.documentType || 'pp';
      this.documentsLinked = ppData.documentsLinked || [];
      this.pp1 = ppData.pp1 || new Pp1(ppData);
      this.pp2 = ppData.pp2 || new Pp2(ppData);
      this.pp3 = ppData.pp3 || new Pp3(ppData);
      this.pp4 = ppData.pp4 || new Pp4(ppData);
      this.pp5 = ppData.pp5 || new Pp5(ppData);
      this.users = ppData.users || [];
      this.annexes = ppData.annexes || [];
    }
  }

  addSignature(signature: Signature) {
    if (!this.pp5.signatures) {
      this.pp5.signatures = []
    }
    this.pp5.signatures.push(signature);
  }

  removeSignature(signature: Signature) {
    const removeIndex = this.pp5.signatures
      .map(function (item) {return item.id;})
      .indexOf(signature.id);
    this.pp5.signatures.splice(removeIndex, 1);
  }

  hasSignatures(): boolean {
    return this.pp5.signatures && this.pp5.signatures.length > 0;
  }

  /**
   * extract the list of companies recorded in pp
   * @returns {any[]}
   */
  get companies() {
    const companies = [];
    if (this.pp2.exploitingCompany.name) {
      const company = new ActivityCompany({});
      company.id = this.pp2.exploitingCompany.id;
      company.name = this.pp2.exploitingCompany.name;
      companies.push(company);
    }
    if (this.pp2.offices) {
      this.pp2.offices.forEach((office) => {
        if (office.name) {
          const company = new ActivityCompany({});
          company.id = office.id;
          company.name = office.name;
          companies.push(company);
        }
      });
    }
    if (this.pp2.externalCompanies) {
      this.pp2.externalCompanies.forEach((externalCompany) => {
        if (externalCompany.name) {
          const company = new ActivityCompany({});
          company.id = externalCompany.id;
          company.name = externalCompany.name;
          companies.push(company);
        }

        if (externalCompany.subCompanies) {
          externalCompany.subCompanies.forEach(subCompany => {
            if (subCompany.name) {
              const company = new ActivityCompany({});
              company.id = subCompany.id;
              company.name = subCompany.name;
              companies.push(company);
            }
          })
        }
      });
    }
    return companies;
  }
}
