import { DbCommonService, PermissionsService } from 'app/shared';
import { LoadingService } from './loading.service';
import { EntitySecureService } from './entitySecure.service';
import { EntityOwned } from 'app/shared/models';

export abstract class EntityOwnedService extends EntitySecureService {


  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  beforeSave(entity, updatedAt: Date = null) {
    entity.updatedId = this.getUserId();
    entity.updatedName = this.getUserName();
    entity.updatedAt = updatedAt ? new Date(updatedAt) : new Date();
    if (!entity._id || !entity.createdId) {
      entity.createdId = entity.updatedId;
      entity.createdName = entity.updatedName;
      entity.createdAt = entity.updatedAt;
    }
    return entity;
  }
  _saveAt(entity: EntityOwned, updatedAt: Date = null) {
    return super._save(this.beforeSave(entity, updatedAt));
  }
  _save(entity: EntityOwned) {
    return this._saveAt(entity);
  }
}
