import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, EntityFormComponent, EntityPinturaConfig, getBase64FromUrl} from 'app/shared';
import {PermitContentService, EntityPermitContent, ContributorGroup, AttendanceGroup, ContentGroup, ContentGroupItem} from 'app/workbook-core';


@Component({
  selector: 'app-repository-permitContent',
  templateUrl: './permitContent.component.html',
  styleUrls: ['./permitContent.component.scss']
})

export class PermitContentComponent extends EntityFormComponent {
  entity: EntityPermitContent;

  stickers: {group_name: string, list: {img: string, txt: string}[]};

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: PermitContentService

  ) {
    super(dialog, _logger, _entityService);
    this.stickers = EntityPinturaConfig.STICKERS.map((element) => (
      (element[0] && element[1] && element[1].length)
        ? {
          group_name: element[0],
          list: element[1].map((sticker) => (
            {
              img: sticker.thumb ? sticker.thumb : '',
              txt: sticker.alt ? sticker.alt : ''
            }
          ))
        }
        : null
    )
    );

  }
  protected _init(data) {
    super._init(data);
  }
  toggleModifyImplementation(event) {
    this.entity.allowModifyImplementation = (event && !!event.checked);
    if (this.entity._id) {
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  toggleImplementation(event) {
    this.entity.allowImplementation = (event && !!event.checked);
    if (this.entity._id) {
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  addContributorGroup() {
    this.entity.contributorGroups.push(new ContributorGroup())
    if (this.entity._id) {
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  removeContributorGroup(i: number) {
    if (this.entity && this.entity.contributorGroups && this.entity.contributorGroups[i]) {
      this.entity.contributorGroups.splice(i, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  addOperatorGroup() {
    this.entity.operatorGroups.push(new AttendanceGroup())
    if (this.entity._id) {
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  removeOperatorGroup(i: number) {
    if (this.entity && this.entity.operatorGroups && this.entity.operatorGroups[i]) {
      this.entity.operatorGroups.splice(i, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  addCheckpoint(i: number) {
    if (this.entity && this.entity.operatorGroups && this.entity.operatorGroups[i]) {
      this.entity.operatorGroups[i].checkpoints.push('Nouveau point de contrôle');
    }
  }
  removeCheckpoint(i: number, j: number) {
    if (this.entity && this.entity.operatorGroups && this.entity.operatorGroups[i] && this.entity.operatorGroups[i].checkpoints && this.entity.operatorGroups[i].checkpoints[j]) {
      this.entity.operatorGroups[i].checkpoints.splice(j, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  addContentGroup() {
    this.entity.contentGroups.push(new ContentGroup())
    if (this.entity._id) {
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  removeContentGroup(i: number) {
    if (this.entity && this.entity.contentGroups && this.entity.contentGroups[i]) {
      this.entity.contentGroups.splice(i, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  addContent(i: number) {
    if (this.entity && this.entity.contentGroups && this.entity.contentGroups[i]) {
      this.entity.contentGroups[i].contents.push(new ContentGroupItem('Nouveau contenu'));
    }
  }
  removeContent(i: number, j: number) {
    if (this.entity && this.entity.contentGroups && this.entity.contentGroups[i] && this.entity.contentGroups[i].contents && this.entity.contentGroups[i].contents[j]) {
      this.entity.contentGroups[i].contents.splice(j, 1);
      this._save().then((e) => {this._afterSave(e);});
    }
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  getUniqueGroup(groupAttribute: string, index: number = -1, attribute: string = 'name'): string[] {
    let returned = [];
    if (this.entity && this.entity[groupAttribute] && this.entity[groupAttribute].length) {
      returned = this.entity[groupAttribute].map((e) => (e[attribute]));
      returned.splice(index, 1);
    }
    return returned;
  }
  getUniqueContent(groupAttribute: string, index: number = -1, attribute: string = 'contents', i: number = -1): string[] {
    let returned = [];
    if (this.entity && this.entity[groupAttribute] && this.entity[groupAttribute][index] && this.entity[groupAttribute][index][attribute] && this.entity[groupAttribute][index][attribute].length) {
      returned = this.entity[groupAttribute][index][attribute].map((e) => (e));
      returned.splice(i, 1);
    }
    return returned;
  }
  onPictoChange(event, i, j) {
    if (this.entity
      && this.entity.contentGroups
      && this.entity.contentGroups[i]
      && this.entity.contentGroups[i].contents
      && this.entity.contentGroups[i].contents[j]
    ) {
      if (event.value && event.value.img) {
        const _that = this;
        getBase64FromUrl(event.value.img).then((imgdata) => {
          this.entity.contentGroups[i].contents[j].picto = imgdata.toString();
          if (event.value.txt && !this.entity.contentGroups[i].contents[j].value || this.entity.contentGroups[i].contents[j].value === 'Nouveau contenu') {
            this.entity.contentGroups[i].contents[j].value = event.value.txt;
          }
        });
      } else {
        this.entity.contentGroups[i].contents[j].picto = '';
      }
      this._save().then((e) => {this._afterSave(e);});
    }
  }
}
