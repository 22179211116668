import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import {
  LoggerService, DocumentService,
  EmailMessagesService,
  EmailService,
  EmailDatasModel, EntityEmailMessages, EmailMessages,
  Contact, Signature
} from 'app/shared';
import {
  PreventionPlanService,
  SignablePreventionPlanService
} from '../services';;
import {
  SecurityProtocolService,
  EntitySecurityProtocol, EntityStep, Implementation
} from '../../workbook';
import { AbstractPdpExportComponent } from './abstractPdPExport.component';

@Component({
  selector: 'app-securityProtocol-export',
  templateUrl: './securityProtocolExport.component.html',
  styleUrls: ['./securityProtocolExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecurityProtocolExportComponent extends AbstractPdpExportComponent {

  SIGN_NOTIFY = EmailMessages.SIGN_SPS_CONTACT;
  entity: EntitySecurityProtocol;
  implementation: Implementation;
  withVehicles: boolean = false;
  withVehicleTypes: boolean = false;
  withEquipments: boolean = false;
  withProvidedEquipments: boolean = false;
  withMerchandiseNatures: boolean = false;
  withMerchandiseStates: boolean = false;
  withMerchandisePackagings: boolean = false;
  withOriginatorInstructions: boolean = false;
  withInstructions: boolean = false;
  withInterdictions: boolean = false;
  originatorSignup: Signature[] = [];
  performerSignup: Signature[] = [];
  constructor(
    public snackBar: MatSnackBar,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: SecurityProtocolService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);
  }
  _init(data) {
    super._init(data);
    if (this.entity) {

      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._preventionPlanService.stepService.getOne(this.entity.step).subscribe((data) => {
        if (data) {
          this.implementation = data.implementation;
        }
      });
      Object.keys(this.entity.vehicles).forEach((e) => { this.withVehicles = this.withVehicles || this.entity.vehicles[e] });
      Object.keys(this.entity.vehicleTypes).forEach((e) => { this.withVehicleTypes = this.withVehicleTypes || this.entity.vehicleTypes[e] });
      Object.keys(this.entity.equipments).forEach((e) => { this.withEquipments = this.withEquipments || this.entity.equipments[e] });
      Object.keys(this.entity.providedEquipments).forEach((e) => { this.withProvidedEquipments = this.withProvidedEquipments || this.entity.providedEquipments[e] });
      Object.keys(this.entity.merchandiseNatures).forEach((e) => { this.withMerchandiseNatures = this.withMerchandiseNatures || this.entity.merchandiseNatures[e] });
      Object.keys(this.entity.merchandiseStates).forEach((e) => { this.withMerchandiseStates = this.withMerchandiseStates || this.entity.merchandiseStates[e] });
      Object.keys(this.entity.merchandisePackagings).forEach((e) => { this.withMerchandisePackagings = this.withMerchandisePackagings || this.entity.merchandisePackagings[e] });
      Object.keys(this.entity.originatorInstructions).forEach((e) => { this.withOriginatorInstructions = this.withOriginatorInstructions || this.entity.originatorInstructions[e] });
      Object.keys(this.entity.instructions).forEach((e) => { this.withInstructions = this.withInstructions || this.entity.instructions[e] });
      Object.keys(this.entity.interdictions).forEach((e) => { this.withInterdictions = this.withInterdictions || this.entity.interdictions[e] });

    }

  }
  refreshSignatories() {
    this.originatorSignup = this.getOriginatorSignup();
    this.performerSignup = this.getPerformerSignup();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }

  showOeex(id: string) {
    return this.entity && [
      this.entity.originator._id,
      this.entity.performer._id
    ].indexOf(id) !== -1;
  }

  _isSignatory(contact: Contact) {
    return true;
    //    return contact.signatoryPermit;
  }
  getOriginatorSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'ORIGINATOR'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.originator) ? this.entity.originator._id : '',
      company: (this.entity && this.entity.originator) ? this.entity.originator.name : '',
      companyType: 'ORIGINATOR',
      contact_id: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.id : '',
      email: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.email : '',
      name: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.name : '',
      job: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.job : '',
      user_id: (this.entity && this.entity.originatorContact) ? this.entity.originatorContact.user_id : ''
    })];
  }
  getPerformerSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.filter((s) => (s.companyType === 'PERFORMER'));
      if (existing && existing.length) {
        return existing;
      }
    }
    return [this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.performer) ? this.entity.performer._id : '',
      company: (this.entity && this.entity.performer) ? this.entity.performer.name : '',
      companyType: 'PERFORMER',
      contact_id: (this.entity && this.entity.performerContact) ? this.entity.performerContact.id : '',
      email: (this.entity && this.entity.performerContact) ? this.entity.performerContact.email : '',
      name: (this.entity && this.entity.performerContact) ? this.entity.performerContact.name : '',
      job: (this.entity && this.entity.performerContact) ? this.entity.performerContact.job : '',
      user_id: (this.entity && this.entity.performerContact) ? this.entity.performerContact.user_id : ''
    })];
  }
  getActiveList(list) {
    return (list && list.length) ? list.filter((e) => (e.selected)) : [];
  }
  hasActiveList(list) {
    return !!(list && list.length && list.filter((e) => (e.selected)).length);
  }
}
