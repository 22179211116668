import {Directive, OnChanges} from '@angular/core';
import {Validator, AbstractControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[uniqueNameValidator][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: UniqueNameValidatorDirective,
    multi: true
  }]
  , inputs: ['uniqueNameList', 'uniqueNamePrefix', 'uniqueNameSuffix']
})
export class UniqueNameValidatorDirective implements Validator, OnChanges {
  uniqueNameList: string[];
  uniqueNamePrefix: string = '';
  uniqueNameSuffix: string = '';
  control: AbstractControl;
  ngOnChanges() {
    if (this.control) {
      this.control.updateValueAndValidity();
    }
  }
  validate(control: AbstractControl): {[key: string]: any} | null {
    this.control = control;
    const value = this.uniqueNamePrefix + control.value + this.uniqueNameSuffix;
    if (this.uniqueNameList && this.uniqueNameList.length && this.uniqueNameList.indexOf(value) !== -1) {
      return {'uniqueNameInvalid': true};
    } else {
      return null;
    }
  }
}