import {Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityActivity} from 'app/workbook-core';
import {WorkbookActivityListComponent} from 'app/workbook-shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-prevention-plan-activity-list',
  templateUrl: './activityList.component.html',
  styleUrls: ['../../workbook-shared/activity/activityList.component.scss']
})

export class PreventionPlanActivityListComponent extends WorkbookActivityListComponent {

  constructor(
    protected _cd: ChangeDetectorRef,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(_cd, _spinnerService, dialog, snackBar, _route, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
  }

  onSplit(data: {activity: EntityActivity, risk_id: string, situation_id: string}) {
    if (data && data.activity) {
      this.selectedSteps = [...this.templates, ...this.steps]
        .filter((s) => (!s.template_id && s.activities.indexOf(data.activity._id) !== -1))
        .map((e) => (e._id));
      super.onSplit(data);
    }
  }
  getActivityTooltip() {
    if (this.selectedSteps && this.selectedSteps.filter((s_id) =>
      (this.steps.findIndex((s) => (s._id === s_id && !s.isComplete())) === -1)
    ).length) {
      return 'Le chantier est terminé';
    }
    return super.getActivityTooltip();
  }

}
