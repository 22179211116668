import {Component, Input} from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import {
  EntityOffice
} from '../../repository/models/entityOffice.model';

@Component({
  selector: 'app-workbook-office-export',
  templateUrl: './officeExport.component.html'
})
export class OfficeExportComponent extends CommonComponent {

  @Input() office: EntityOffice;

  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

}
