import {NgModule, ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WorkbookGuard, PreventionPlanService, WorkbookStepService} from 'app/workbook-core';
import {PreventionPlanEditComponent} from './preventionPlanEdit';
import {PreventionPlanOfficeListComponent} from './office';
import {PreventionPlanExternalCompanyListComponent} from './externalCompany';
import {PreventionPlanIcpListComponent, PreventionPlanIcpEditComponent, PreventionPlanIcpActivityListComponent} from './icp';
import {PreventionPlanActivityListComponent} from './activity';
import {PreventionPlanDocumentListComponent} from './document';
import {PreventionPlanStepEditComponent, PreventionPlanStepListComponent, PreventionPlanStepActivityListComponent} from './step';
import {PreventionPlanLocationEditComponent} from './location';
import {SignablePreventionPlanListComponent} from './signablePreventionPlan';
import {FirePermitListComponent} from './firePermit';
import {SignablePermitListComponent} from './signablePermit';
import {AuditListComponent} from './audit';
import {SecurityProtocolListComponent} from './securityProtocol';




const routes: Routes = [
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/info',
    component: PreventionPlanEditComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 0,
        group: 'Contrat'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/info',
    component: PreventionPlanEditComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 1,
        group: 'Opération'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: PreventionPlanService.ENTITY_URL + '/:parent_id/step/:id/site/:site_id',
    component: PreventionPlanLocationEditComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: WorkbookStepService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: PreventionPlanService.ENTITY_URL + '/:parent_id/step/:id',
    component: PreventionPlanStepEditComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: WorkbookStepService.ENTITY_PERMISSION.UPDATE,
        redirectTo: '/401'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/step',
    component: PreventionPlanStepListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 4,
        group: 'Interventions'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/pdp',
    component: SignablePreventionPlanListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 7,
        group: 'Plans de prévention'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/fp',
    component: FirePermitListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showFirePermit'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 8,
        group: 'Permis feu'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/permit',
    component: SignablePermitListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showSignablePermit'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 9,
        group: 'Permis'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/sp',
    component: SecurityProtocolListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showSecurityProtocol'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 10,
        group: 'Protocoles'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/vc',
    component: AuditListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showAudit'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 11,
        group: 'Visites de contrôle'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/office',
    component: PreventionPlanOfficeListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showEePP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        order: 1,
        title: 'Entreprise utilisatrice',
        orderGroup: 2,
        group: 'Intervenants'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/externalCompany',
    component: PreventionPlanExternalCompanyListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showEexPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        order: 2,
        title: 'Entreprises extérieures',
        orderGroup: 2,
        group: 'Intervenants'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: PreventionPlanService.ENTITY_URL + '/:parent_id/icp/:id',
    component: PreventionPlanIcpEditComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showICP'],
        redirectTo: '/401'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: PreventionPlanService.ENTITY_URL + '/:id/icp/:icp_id/activity',
    component: PreventionPlanIcpActivityListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showRisksPP', 'createActivityPP', 'editActivityPP'],
        redirectTo: '/401'
      }
    }
  },
  {// TODO: Pour memoire :ne fonctionne pas si static héritée
    path: PreventionPlanService.ENTITY_URL + '/:id/step/:step_id/activity',
    component: PreventionPlanStepActivityListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showRisksPP', 'createActivityPP', 'editActivityPP'],
        redirectTo: '/401'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/icp',
    component: PreventionPlanIcpListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showICP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 6,
        group: 'Inspections communes'
      }
    }
  },
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/activity',
    component: PreventionPlanActivityListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showRisksPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 5,
        group: 'Analyse des risques'
      }
    }
  },
  /*
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/signature',
    component: PreventionPlanSignatureListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showSignPP']
      },
      sidenavpp: {
        orderGroup: 6,
        group: 'Signatures'
      }
    }
  },
  */
  {
    path: PreventionPlanService.ENTITY_URL + '/:id/document',
    component: PreventionPlanDocumentListComponent,
    canActivate: [WorkbookGuard],
    data: {
      permissions: {
        only: ['showAnnexesPP'],
        redirectTo: '/401'
      },
      sidenavpp: {
        orderGroup: 3,
        group: 'Documentation'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PreventionPlanRoutingModule {
}

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
