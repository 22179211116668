import {Inject, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {promiseAll, AuthService, LoggerService, DocumentMetadataService, EntityDocumentMetadata} from 'app/shared';
import {PreventionPlanService, EntityWorkbook, EntityStep, DocumentationService, EntityDocumentation, EntityOffice, EntityZone} from 'app/workbook-core';
import {AbstractWorkbookDialogComponent} from '../abstractWorkbook-dialog.component';

@Component({
  selector: 'app-workbook-create',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookCreateDialogComponent extends AbstractWorkbookDialogComponent implements OnDestroy {

  entity: EntityWorkbook;
  documentations: EntityDocumentation[];
  userOffice: EntityOffice;
  protected _documentationSubscription: Subscription;

  defaultAddOffices: EntityOffice[];
  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _cdRef: ChangeDetectorRef,
    protected _auth: AuthService,
    public dialogRef: MatDialogRef<WorkbookCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _workbookService: PreventionPlanService) {
    super(snackBar, _spinnerService, _cdRef, dialogRef, data, _router, _logger, _workbookService);
    if (data && data['options']) {
      this.entity = this._workbookService.getNewEntity(data['options']);
      this._workbookService.getCurrentExploitingCompany().then((ec) => {
        this.entity.exploitingCompany = ec;
      });
      this.title = (this.title ? this.title + ' : ' : '')
        + (this.entity.type === 'annual'
          ? (this.entity.simple ? 'Protocole de sécurité(hors plan de prévention)' : 'Contrat périodique (avec chantiers types)')
          : (this.entity.simple ? 'Opération simple (intervention unique)' : 'Opération (plusieurs interventions)'));
      this._documentationSubscription = this._documentationService.getAll().subscribe((data) => {
        if (data) {
          this.documentations = data;
        }
      });
      if (data['options']['userOffice']) {
        const repo_id = data['options']['userOffice']['_id'];
        this.userOffice = this._workbookService.officeService.getNewEntity(data['options']['userOffice'], true);
        this.userOffice.repo_id = repo_id;
        this.userOffice.contacts = this.userOffice.contacts.filter((c) => (!!c.isDefault || c.user_id === this._workbookService.getUserId()));
        this.userOffice.documents = this.userOffice.documents.filter((d) => (!!d.isDefault));

      }
      if (data['options']['defaultAddOffices']) {
        this.defaultAddOffices = data['options']['defaultAddOffices'].map((x) => {
          const _repo_id = x._id;
          const y: EntityOffice = this._workbookService.officeService.getNewEntity(x, true);
          y.repo_id = _repo_id;
          y.contacts = y.contacts.filter((c) => (!!c.isDefault));
          y.documents = y.documents.filter((d) => (!!d.isDefault));
          return y;
        });
      }
    }
  }
  ngOnDestroy() {
    if (this._documentationSubscription) {
      this._documentationSubscription.unsubscribe();
    }
  }


  _afterSave(res) {
    if (res && res.ok && res.id) {
      this._workbookService.addNewParent(res.id);
      if (this.userOffice) {
        this.userOffice.parent_id = res.id;
        this._workbookService.officeService.save(this.userOffice);
      }
      if (this.defaultAddOffices && this.defaultAddOffices.length) {
        promiseAll(this.defaultAddOffices.map((dao) => {
          dao.parent_id = res.id;
          return this._workbookService.officeService.save(dao)
        }))
          .then((e) => {
            //console.log('documentations added');
          })
          .catch((err) => {
            console.error('Error adding default offices');
          });
      }
      if (this.documentations && this.documentations.length) {
        const docToAdd = this.documentations.filter((d) => (
          d.meta &&
          (this.entity.type === 'annual' && !this.entity.simple && d.op)
          || (this.entity.type === 'annual' && this.entity.simple && d.ps)
          || (this.entity.type !== 'annual' && d.oo)
        ));
        if (docToAdd.length) {
          const _all = docToAdd.map((e) => {
            const toAdd: EntityDocumentMetadata = this._documentMetadataService.getNewEntity(e.meta, true);
            toAdd.repo_id = e.meta._id;
            toAdd.parent_id = res.id;
            toAdd.context = '/' + this._entityService.getUrlWorkbook(this.entity.documentType, this.entity._id, 'document');
            return this._documentMetadataService.save(toAdd);
          });
          promiseAll(_all)
            .then((e) => {
              //console.log('documentations added');
            })
            .catch((err) => {
              console.error('Error adding documentations');
            });
        }
      }
      if (this.entity.simple) {
        const step: EntityStep = this._workbookService.stepService.getNewEntity({
          parent_id: res.id,
          name: this.entity.description,
          site: this.entity.site,
          point: this.entity.point,
          zone: new EntityZone(this.entity.zone),
          dateStart: this.entity.dateStart,
          dateEnd: this.entity.dateEnd,
          hourStart: this.entity.hourStart,
          hourEnd: this.entity.hourEnd,
          workConditions: this.entity.workConditions
        });
        this._workbookService.stepService.save(step).then((e) => {
          this.entity.updateStep(step);

          this._entityService.save(this.entity).then((e) => {
            super._afterSave(res);
          })
        });
      } else {
        super._afterSave(res);
      }
    }
  }
}
