import {Entity} from 'app/shared';

export class EntityOrganization extends Entity {
  public name: string;
  public description: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.description = (data && data.description) ? data.description : '';
  }
}
