import { OnInit, OnChanges, OnDestroy, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from '../logger';
import { EntitySecureService } from '../services';
import { Entity } from '../models';
import { AbstractEntityServiceComponent } from './abstractEntityService.component';

export class EntityComponent extends AbstractEntityServiceComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: string;
  @Input() parent_id: string;
  @Input() entity: Entity;
  @Output() onEntityLoaded = new EventEmitter<Entity>();
  protected _entitySubscription: Subscription;
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cet élément ?';
  addConfirmMessage = 'Nouvel élément créé';

  constructor(
    protected _logger: LoggerService,
    protected _entityService: EntitySecureService
  ) {
    super(_logger, _entityService);
  }

  protected _init(data) {
    if (data
      && (!this.entity
        || (data._rev && this.entity && this.entity._rev && this.entity._rev !== data._rev))) {
      this._initUpdateEntity(data);
      if (this.entity.parent_id) {
        this._updatePermissions(this.entity.parent_id);
      }
    }
  }

  protected _initUpdateEntity(data) {
    this.entity = this._entityService.factory(data);
  }

  ngOnInit() {
    //this._initEntity();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['id']/*  &&!changes['id']['firstChange']*/) {
      this._initEntity();
    }
    if (changes && changes['entity'] && !changes['entity']['firstChange']) {
      this._init(this.entity);
    }
  }
  protected _initEntity() {

    if (!this.entity && this._entityService.isEntitySingle()) {
      if (this._entitySubscription) {
        this._entitySubscription.unsubscribe();
      }
      const that = this;
      this._entitySubscription = this._entityService.getSingleOne().subscribe((data) => {
        if (data && !(that.entity && that.entity._rev && data._rev && data._rev === that.entity._rev)) {
          that._init(data);
          this._afterEntityLoaded();
        }
      });
    } else {
      if (this.id) {
        if (this._entitySubscription) {
          this._entitySubscription.unsubscribe();
        }
        const that = this;
        this._entitySubscription = this._entityService.getOne(this.id).subscribe((data) => {
          if (data && !(that.entity && that.entity._rev && data._rev && data._rev === that.entity._rev)) {
            that._init(data);
            this._afterEntityLoaded();
          }
        });
      } else {
        this._init(this.entity ? this.entity : this._entityService.getNewEntity({ parent_id: this.parent_id }));
        this._afterEntityLoaded();
      }
    }
  }
  protected _afterEntityLoaded() {
    setTimeout(() => { this.onEntityLoaded.emit(this.entity); });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    // this._entityService.detach(this.entity);
    if (this._entitySubscription) {
      this._entitySubscription.unsubscribe();
    }
  }

  protected _beforeSave(entity) {
    return entity;
  }
  /**
  *  Save current entity
  * @protected
  */
  protected _save() {
    return this._entityService.save(this._beforeSave(this.entity))
      .catch((error) => {
        this._logger.error(this.constructor.name + '(EntityComponent)', 'unable to save entity (' + this.entity._id + '/' + this.entity._rev + ')', JSON.stringify(error));
        //console.log('EntityComponent save error', [error, this.entity]);
        if (error && error.status && error.status === 409 && this.entity._id) {
          this._entityService.refreshOne(this.entity._id).then((e) => {
            this._logger.error(this.constructor.name + '(EntityComponent)', 'refresh entity (' + e._id + '/' + e._rev + ')', JSON.stringify({ from: this.entity, to: e }));
            // TODO : copyfrom ? diff ?
            this.entity = e;
          })
            .catch((err) => {
              this._logger.error(this.constructor.name + '(EntityComponent)', 'unable to refreshOne after error', JSON.stringify(err));
              //console.log('EntityComponent refreshOne error', [err, this.entity]);
            });
        }
      });
  }

  /**
  *  Save current entity
  * @protected
  */
  protected _remove() {
    return this._entityService.remove(this.entity).catch((error) => {
      this._logger.error('EntityFormComponent', 'unable to delete entity', JSON.stringify(error));
    });
  }

}
