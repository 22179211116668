import {Injectable} from '@angular/core';
import {PermissionsService, DbFilteredService, EntitySecureService, LoadingService} from 'app/shared';
import {EntityOffice} from '../../repository';

@Injectable({
  providedIn: 'root'
})
export class WorkbookOfficeService extends EntitySecureService {

  onlyChildren = true;
  autoInit = false;

  static LABEL_ONE = "chargement du service de l'opération";
  static LABEL_ALL = "chargement des services de l'opération ?";
  static LABEL_CHILDREN = "chargement des services de l'opération";
  static LABEL_SAVE = "sauvegarde du service de l'opération";
  static LABEL_DELETE = "suppression du service de l'opération";

  static ENTITY_TYPE: string = 'wb_office';
  static ENTITY_FIELDS: string[] = ['name', 'address', 'zipCode', 'city', 'main', 'contacts', 'documents'];

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showEePP'],
    CREATE: ['createEePP', 'editEePP'],
    UPDATE: ['editEePP'],
    DELETE: ['removeEePP', 'editEePP']
  }

  static ENTITY_URL = 'office';

  constructor(
    protected _dbService: DbFilteredService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityOffice {
    return new EntityOffice(data, clone);
  }

}
