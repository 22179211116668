import { DbCommonService } from '../db/dbCommon.service';
import { PermissionsService } from '../auth/permissions.service';
import { lz_compress, lz_decompress } from '../utils/utils';
import { LoadingService } from './loading.service';
import { EntityCodableService } from './entityCodable.service';
import { EntityEditable } from '../models/editable.model';

export abstract class EntityEditableService extends EntityCodableService {


  constructor(
    protected _dbService: DbCommonService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);

  }
  public updateCache(docs: EntityEditable[]) {
    return super.updateCache(docs.map((e) => (this.before_factory(e))));
  }
  beforeSave(entity: EntityEditable, updatedAt: Date = null) {
    if (entity.content) {
      //console.log('compress :', entity.content);
      entity.content = lz_compress(entity.content);
      entity.content_lz = true;
      //console.log('compressed :', entity.content);
    } else {
      entity.content_lz = false;
    }
    return super.beforeSave(entity, updatedAt);
  }

  before_factory(data) {
    if (data && data['content_lz'] && data['content']) {
      //console.log('decompress :', data['content']);
      data['content'] = lz_decompress(data['content']);
      //console.log('decompressed :', data['content']);

    }
    data['content_lz'] = false;
    return data;
  }
}
