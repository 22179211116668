import {Injectable, NgZone} from '@angular/core';
import {first} from 'rxjs/operators';
import {AuthService} from 'app/shared/auth/auth.service';
import {DbReadWriteService} from './dbReadWrite.service';

@Injectable()
export class DbRemoteCommonService extends DbReadWriteService {

  static DBCONFIG = 'couchDB';

  constructor(protected authService: AuthService, protected zone: NgZone) {
    super();
    this._init().catch((error) => {
      console.error(error);
    });
  }
  protected _getDbPrefix() {
    return this.authService.getDbPrefix();
  }
  getDbConfig() {
    return (<typeof DbRemoteCommonService> this.constructor).DBCONFIG;
  }

  /**
     * Initialize the Db connection with host
     * @protected
     */
  protected async _init() {
    const profile = await this.authService.userProfile$.pipe(first()).toPromise();
    this.connect(profile.app_metadata[this.getDbConfig()]);
  }

}
