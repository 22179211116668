import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  LoadingService, EmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService, EntityCompany
} from 'app/shared';
import {
  PreventionPlanService,
  AuditService,
  EntityAudit, AuditStatus,
  AuditExportComponent, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableDialogComponent} from '../abstractSignable-dialog.component';


@Component({
  selector: 'app-signable-audit-dialog',
  templateUrl: './audit-dialog.component.html',
  styleUrls: ['./audit-dialog.component.scss']
})
export class AuditDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  static FLAG_SUFFIX: string = 'vc';
  SIGNATORY_RIGHT: string = 'signatoryPermit';

  SIGNING_NOTIFY = EmailMessages.SIGN_SVC_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_SVC;

  entity: EntityAudit;
  entityForm: AuditExportComponent;

  signableStatus = AuditStatus.LABELS;

  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AuditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: AuditService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
  }

  addSpecifictEmailRecipient(entity: EntityAudit) {
    if (entity.auditSignatories && entity.auditSignatories.length) {
      entity.auditSignatories.forEach((auditSignatory) => {
        if (auditSignatory.contact
          && auditSignatory.company
          && this.usersList.findIndex((u) => (
            u.name === auditSignatory.contact.name
            && u.email === auditSignatory.contact.email
            && u.company === auditSignatory.company.name
          )) === -1) {
          this.usersList.push(this.createEmailRecipient(auditSignatory.company, auditSignatory.contact, true));
        }
      });
    }
    if (entity.auditor
      && entity.auditorContact
      && this.usersList.findIndex((u) => (
        u.name === entity.auditorContact.name
        && u.email === entity.auditorContact.email
        && u.company === entity.auditor.name
      )) === -1
    ) {
      this.usersList.push(this.createEmailRecipient(entity.auditor, entity.auditorContact));
    }
  }
  getDefaultEmailSubject() {
    return ('Visite de contrôle' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? (' ' + this.entityForm.entity.name) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }

  _getSignatoriesOffice() {
    let signatories = [];
    if (this.entityForm && this.entityForm.auditorSignup) {
      signatories = [this.entityForm.auditorSignup];
    }
    return signatories;
  }
  _getSignatoriesEex() {
    let signatories = [];
    if (this.entityForm) {
      if (this.entityForm.auditSignup) {
        signatories = [...signatories, ...this.entityForm.auditSignup];
      }
    }
    return signatories;
  }
  getUnsigned(): EntityCompany[] {
    const unsigned: EntityCompany[] = [];
    if (this.entityForm && this.entityForm.entity) {
      if (this.entityForm.entity.auditor && this.entityForm.entity.auditor.name
        && this.entityForm.entity.auditorContact && this.entityForm.entity.auditorContact.name
        && this.entityForm.entity.signatures.findIndex((s) => (
          s.company_id === this.entityForm.entity.auditor._id
          && s.contact_id === this.entityForm.entity.auditorContact.id
        )) === -1) {
        unsigned.push(this.entityForm.entity.auditor);
      }
      /*
      if (this.entityForm.entity.audited && this.entityForm.entity.audited.length) {
        this.entityForm.entity.audited
        .filter((a) => {!a.signedAt})
        .forEach((a) => {
            unsigned.push(a);
        });
      }
      */
    }
    return unsigned;
  }
}
