import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep} from 'app/workbook-core';
import {WorkbookChildListComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-prevention-plan-step-list',
  templateUrl: './stepList.component.html',
  styleUrls: ['./stepList.component.scss']
})

export class PreventionPlanStepListComponent extends WorkbookChildListComponent {
  entityList: EntityStep[];
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _getChildService() {
    return this._entityService.stepService;
  }
  _init(data) {
    super._init(data);
    if (this.entityList && this.entity) {
      if (this.entity.updateSteps(this.entityList, true)) {
        this._entityService.save(this.entity);
      }
    }
  }

  protected _afterRemoveElement(entity) {
    super._afterRemoveElement(entity);
    this.entity.updateStep(entity);
  }
}
