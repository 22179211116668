import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {
  LoggerService, DocumentService, formattedDate,
  EmailMessagesService,
  EmailService, EmailMessages,
  Signature, SignableStatus
} from 'app/shared';
import {
  PreventionPlanService
} from '../services';
import {
  AuditService,
  EntityAudit, Implementation, AuditStatus, AuditCheckpoint
} from '../../workbook';
import {AuditCheckpointsService, EntityAuditCheckpoints} from '../../repository';
import {AbstractPdpExportComponent} from './abstractPdPExport.component';

@Component({
  selector: 'app-audit-export',
  templateUrl: './auditExport.component.html',
  styleUrls: ['./auditExport.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditExportComponent extends AbstractPdpExportComponent {

  SIGN_NOTIFY = EmailMessages.SIGN_SVC_CONTACT;
  entity: EntityAudit;

  signableStatus = AuditStatus.LABELS;
  signableStatusDescritpions = AuditStatus.DESCRIPTIONS;

  implementation: Implementation;
  auditorSignup: Signature;
  auditSignup: Signature[] = [];
  checkpointActivities: {id: string, name: string, group: string}[] = [];
  checkpointLocations: {id: string, name: string, group: string}[] = [];
  checkpoints: {[activity_name: string]: {[risk_name: string]: AuditCheckpoint[]}} = {};
  otherCheckpoints: AuditCheckpoint[];

  showActions: boolean = false;
  oeexActions: {[oeex_id: string]: {[activity_name: string]: AuditCheckpoint[]}} = {};
  oeexOtherActions: {[oeex_id: string]: {[checkpoint_name: string]: string}} = {};

  _auditCheckpointsSubscription: Subscription;
  auditCheckpoints: EntityAuditCheckpoints;

  synthesis: {[oeex_id: string]: {name: string, count: number, compliant: number, improper: number}} = {};

  constructor(
    public snackBar: MatSnackBar,
    protected _auditCheckpointsService: AuditCheckpointsService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _preventionPlanService: PreventionPlanService,
    public dialog: MatDialog,
    protected _documentService: DocumentService,
    protected _logger: LoggerService,
    protected _entityService: AuditService) {
    super(snackBar, _emailMessagesService, _emailService, _preventionPlanService, dialog, _documentService, _logger, _entityService);

    this._auditCheckpointsSubscription = this._auditCheckpointsService.getSingleOne().subscribe((ac) => {
      if (ac) {
        this.auditCheckpoints = ac;
      }
    });
  }
  _init(data) {
    super._init(data);
    if (this.entity) {
      this.checkpointActivities = [];
      this.otherCheckpoints = [];
      this.checkpoints = {};
      this.oeexActions = {};
      this.showActions = false;
      this.entity.checkpoints.filter((e) => (!!e.activity_id))
        .forEach((e) => {
          if (!this.checkpoints[e.activity_id]) {
            this.checkpointActivities.push({id: e.activity_id, name: e.activity_name, group: e.activity_group});
            this.checkpoints[e.activity_id] = {};
          }
          if (!this.checkpoints[e.activity_id][e.risk_name]) {
            this.checkpoints[e.activity_id][e.risk_name] = []
          }
          this.checkpoints[e.activity_id][e.risk_name].push(e);
          if (!!e.description) {
            if (!this.oeexActions[e.company_id]) {
              this.oeexActions[e.company_id] = {}
            }
            if (!this.oeexActions[e.company_id][e.activity_name]) {
              this.oeexActions[e.company_id][e.activity_name] = []
            }
            this.oeexActions[e.company_id][e.activity_name].push(e);
            this.showActions = true;
          }
        });
      this.entity.locationCheckpoints.filter((e) => (!!e.activity_id))
        .forEach((e) => {
          if (!this.checkpoints[e.activity_id]) {
            this.checkpointLocations.push({id: e.activity_id, name: e.activity_name, group: e.activity_group});
            this.checkpoints[e.activity_id] = {};
          }
          if (!this.checkpoints[e.activity_id][e.risk_name]) {
            this.checkpoints[e.activity_id][e.risk_name] = []
          }
          this.checkpoints[e.activity_id][e.risk_name].push(e);
          if (!!e.description) {
            if (!this.oeexActions[e.company_id]) {
              this.oeexActions[e.company_id] = {}
            }
            if (!this.oeexActions[e.company_id][e.activity_name]) {
              this.oeexActions[e.company_id][e.activity_name] = []
            }
            this.oeexActions[e.company_id][e.activity_name].push(e);
            this.showActions = true;
          }
        });
      this.otherCheckpoints = this.entity.checkpoints.filter((e) => (!e.activity_id));
      this.oeexOtherActions = {};
      this.otherCheckpoints.forEach((e) => {
        if (!!e.description) {
          if (!this.oeexOtherActions[e.company_id]) {
            this.oeexOtherActions[e.company_id] = {}
          }
          this.oeexOtherActions[e.company_id][e.name] = e.description;
          this.showActions = true;
        }
      });
      if (this._stepsSubscription) {
        this._stepsSubscription.unsubscribe();
      }
      this._stepsSubscription = this._preventionPlanService.stepService.getOne(this.entity.step).subscribe((data) => {
        if (data) {
          this.implementation = data.implementation;
        }
      });
      this.updateSynthesis();
    }

  }
  refreshSignatories() {
    this.auditorSignup = this.getAuditorSignup();
    this.auditSignup = this.getAuditSignup();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
  }
  updateSynthesis() {
    this.synthesis = this.entity.getSynthesis();
  }

  percentage(x: number, y: number) {
    return Math.round(100 * x / y);
  }

  showOeex(id: string) {
    return this.entity && [
      this.entity.auditor._id
    ].indexOf(id) !== -1;
  }
  showInstructions(list) {
    return !!(list && list.length && list.filter((e) => (e.status)).length > 0);
  }

  getAuditorSignup() {
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      const existing = this.entity.signatures.find((s) => (s.companyType === 'AUDITOR'));
      if (existing) {
        return existing;
      }
    }
    return this._preventionPlanService.signatureService.getNewEntity({
      parent_id: this.entity ? this.entity.parent_id : '',
      company_id: (this.entity && this.entity.auditor) ? this.entity.auditor._id : '',
      company: (this.entity && this.entity.auditor) ? this.entity.auditor.name : '',
      companyType: 'AUDITOR',
      contact_id: (this.entity && this.entity.auditorContact) ? this.entity.auditorContact.id : '',
      email: (this.entity && this.entity.auditorContact) ? this.entity.auditorContact.email : '',
      name: (this.entity && this.entity.auditorContact) ? this.entity.auditorContact.name : '',
      job: (this.entity && this.entity.auditorContact) ? this.entity.auditorContact.job : '',
      user_id: (this.entity && this.entity.auditorContact) ? this.entity.auditorContact.user_id : ''
    });

  }
  getAuditSignup() {
    let returned = [];
    if (this.entity && this.entity.signatures && this.entity.signatures.length) {
      returned = this.entity.signatures.filter((s) => (s.companyType === 'OPERATOR'));
    }

    if (this.entity && this.entity.status < SignableStatus.SIGNED) {
      this.entity.auditSignatories
        .filter((e) => (!(this.entity && this.entity.auditor && e.company && e.company._id && e.company._id === this.entity.auditor._id && e.contact && this.entity.auditorContact && e.contact.id === this.entity.auditorContact.id)))
        .forEach((o) => {
          if (this.entity.signatures.findIndex((s) => (s.companyType === 'OPERATOR' && s.contact_id === o.contact.id && s.company_id === o.company._id)) === -1) {
            returned.push(
              this._preventionPlanService.signatureService.getNewEntity({
                parent_id: this.entity ? this.entity.parent_id : '',
                company_id: (o.company) ? o.company._id : '',
                company: (o.company) ? o.company.name : '',
                companyType: 'OPERATOR',
                contact_id: (o.contact) ? o.contact.id : '',
                email: (o.contact) ? o.contact.email : '',
                name: (o.contact) ? o.contact.name : '',
                job: (o.contact) ? o.contact.job : '',
                user_id: (o.contact) ? o.contact.user_id : ''
              }));
          }
        });
    }
    return returned;

  }
}
