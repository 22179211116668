import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {comparableString, normalyzePhone, promiseAll, LoggerService, EntityListPopupComponent, ExcelService, ConfirmDialogComponent, sanitizeString} from 'app/shared';
import {SiteService, EntitySite, EntityZone} from 'app/workbook-core';
import {SiteDialogComponent} from './siteDialog.component';

@Component({
  selector: 'app-repository-site-list',
  templateUrl: './siteList.component.html',
  styleUrls: ['./siteList.component.scss']
})

export class SiteListComponent extends EntityListPopupComponent {
  protected _dialogComponent = SiteDialogComponent;

  entityList: EntitySite[];
  dataSource: MatTableDataSource<EntitySite>;
  displayedColumns: string[] = ['name', 'address', 'zipCode', 'city', 'delete'];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce site ?';
  addConfirmMessage = 'Nouveau site créé';

  pageSize = 50;

  constructor(
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: SiteService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
  }

  selectElement(element: EntitySite = null) {
    this._router.navigateByUrl(this._entityService.getUrlEntity(element));
  }

  protected _getDataSourceFilterPredicate() {
    return (data: EntitySite, filter: string) => {
      return (!filter || filter.trim() === '' ||
        (data && (
          data.name && comparableString(data.name).indexOf(comparableString(filter)) !== -1
          || data.address && comparableString(data.address).indexOf(comparableString(filter)) !== -1
          || data.zipCode && comparableString(data.zipCode).indexOf(comparableString(filter)) !== -1
          || data.city && comparableString(data.city).indexOf(comparableString(filter)) !== -1
          || (data.zones && data.zones.length && data.zones.findIndex((z) => (
            (z.name && comparableString(z.name).indexOf(comparableString(filter)) !== -1)
          )) !== -1)
        )));
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        if (list && list.length) {
          const attributes = {
            name: 'name',
            address: 'address',
            zipCode: 'zipCode',
            city: 'city',
            phone: 'phone',
            zone: 'zone'
          }
          const attributes_alias = {
            'nom': 'name',
            'site': 'name',
            'adresse': 'address',
            'cp': 'cityCode',
            'ville': 'city',
            'telephone': 'phone',
          }
          if (list[0]) {
            Object.keys(list[0]).forEach((attr) => {
              if (attributes_alias[comparableString(attr)]) {
                attributes[attributes_alias[comparableString(attr)]] = attr;
              }
            });
          }
          const _siteUpdates: Promise<EntitySite>[] = [];
          const _siteUpdateZones: {[site_id: string]: {name: string, zones: EntityZone[]}} = {};
          const importList: EntitySite[] = [];
          list.forEach((element) => {
            const zone = new EntityZone({name: (element[attributes.zone] ? element[attributes.zone] : '')});
            const i = importList.findIndex((e) => (e.name === element[attributes.name]));
            let change: boolean = false;
            if (i === -1) {

              let _site: EntitySite;
              let _siteUpdate: Promise<EntitySite>;
              const j = this.entityList.findIndex((e) => (e.name === element[attributes.name]));
              if (j === -1) {
                _site = this._entityService.getNewEntity({
                  name: sanitizeString(element[attributes.name]),
                  address: element[attributes.address] ? element[attributes.address] : '',
                  zipCode: element[attributes.zipCode] ? element[attributes.zipCode] : '',
                  city: element[attributes.city] ? element[attributes.city] : '',
                  phone: element[attributes.phone] ? normalyzePhone(element[attributes.phone]) : ''
                });
                _site.zones.push(zone);
                importList.push(_site);
              } else {
                _siteUpdate = this._entityService.getCurrentOne(this.entityList[j]._id);
                _siteUpdates.push(_siteUpdate);
                if (!_siteUpdateZones[this.entityList[j]._id]) {
                  _siteUpdateZones[this.entityList[j]._id] = {name: element[attributes.name], zones: []};
                }
                change = true;
                _siteUpdateZones[this.entityList[j]._id].zones.push(zone);

              }
              if (_site && _site.zones.findIndex((e) => (e.name === zone.name)) === -1) {
                change = true;
                _site.zones.push(zone);
                if (change) {
                  importList.push(_site);
                }
              }
            } else if (importList[i].zones.findIndex((e) => (e.name === zone.name)) === -1) {
              importList[i].zones.push(zone);
            }
          });
          if (importList.length || Object.keys(_siteUpdateZones).length) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: false,
              width: '1600px',
            });

            dialogRef.componentInstance.confirmMessage = '<p>Vous allez importer les sites suivants :</p><ul>';
            importList.forEach((site) => {
              dialogRef.componentInstance.confirmMessage += '<li><div><b>' + site.name + '</b>(' + site.zones.length + ')' + (site.phone ? (' - ' + site.phone) : '') + '<br>' + site.address + ' ' + site.zipCode + ' ' + site.city + ' ' + '</div>';
              if (site.zones && site.zones.length) {
                dialogRef.componentInstance.confirmMessage += '<ul>';
                site.zones.forEach((c) => {
                  dialogRef.componentInstance.confirmMessage += '<li>' + (c.name ? c.name : 'Toutes les zones') + '</li>';
                });
                dialogRef.componentInstance.confirmMessage += '</ul>';
              }
              dialogRef.componentInstance.confirmMessage += '</li>';
            });
            dialogRef.componentInstance.confirmMessage += '</ul>';
            if (Object.keys(_siteUpdateZones).length) {
              dialogRef.componentInstance.confirmMessage += '<p>Vous allez ajouter/mettre à jour les zones suivantes :</p><ul>';
              Object.keys(_siteUpdateZones).forEach((o_id) => {
                dialogRef.componentInstance.confirmMessage += '<b>' + _siteUpdateZones[o_id].name + '(' + o_id + ')</b>';
                if (_siteUpdateZones[o_id].zones && _siteUpdateZones[o_id].zones.length) {
                  dialogRef.componentInstance.confirmMessage += '<ul>';
                  _siteUpdateZones[o_id].zones.forEach((c) => {
                    dialogRef.componentInstance.confirmMessage += '<li>' + (c.name ? c.name : 'Toutes les zones') + '</li>';
                  });
                  dialogRef.componentInstance.confirmMessage += '</ul>';
                }
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';
            }
            dialogRef.componentInstance.confirmMessage += '<p>Souhaitez vous procéder à l\'enregistrement de ces sites ?</p>';
            dialogRef.componentInstance.cancelMessage = 'Non';
            dialogRef.componentInstance.buttonMessage = 'Oui';
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this._spinner.show();
                const sitesSaver: Promise<any>[] = importList.map((s) => (this._entityService.save(s)));

                promiseAll(sitesSaver).then((result) => {

                  promiseAll(_siteUpdates.map((_op) => {
                    return _op.then((o) => {
                      if (o.zones && _siteUpdateZones[o._id] && _siteUpdateZones[o._id].zones && _siteUpdateZones[o._id].zones.length) {
                        _siteUpdateZones[o._id].zones.forEach((_c) => {
                          if (o.zones.findIndex((_d) => (_c.name === _d.name)) === -1) {
                            o.zones.push(_c);
                          }
                        });
                      }
                      return this._entityService.save(o);
                    })
                  })).then((res) => {

                    this._spinner.hide();
                    this.snackBar.open('Importation terminée', '', {
                      duration: 2000
                    });

                  }).catch((err) => {
                    this._logger.error('siteList', 'import update', JSON.stringify(err));
                    this._spinner.hide();
                    this.snackBar.open('Erreur lors de l\'importation des zones dans des sites existants', '', {
                      duration: 2000
                    });
                  });


                }).catch((err) => {
                  this._logger.error('SiteList', 'import site', JSON.stringify(err));
                  this._spinner.hide();
                  this.snackBar.open('Erreur lors de l\'importation des sites', '', {
                    duration: 2000
                  });
                });
              }
            });
          }
        }
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
      }).catch((err) => {
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
        console.error(err);
      });
    }
  }

}
