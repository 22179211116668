import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from 'app/shared';
import {Error404Component, Error401Component} from 'app/shared/components';
import {ProfileComponent} from './profile/profile.component';

// Route Configuration
export const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './workbooks/workbooks.module#WorkbooksModule'
  },
  {
    path: 'pp',
    loadChildren: './preventionPlan/preventionPlan.module#PPModule'
  },
  {
    path: 'doc',
    loadChildren: './signables/signables.module#SignablesModule'
  },
  {
    path: 'config',
    loadChildren: './config/config.module#ConfigModule'
  },
  {
    path: 'repository',
    //loadChildren: () => import('./repository/repository.module').then(m => m.RepositoryModule)
    loadChildren: './repository/repository.module#RepositoryModule'
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
      data: {
        permissions: {
          only: ['showProfile']
        }
      }
    }
  },
  {
    path: '401', component: Error401Component
  },
  {
    path: '**', component: Error404Component
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: false/*, enableTracing: true*/});
