import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, getBase64, EntityFormComponent} from 'app/shared';
import {ProtocolContentService, EntityProtocolContent, Illustrated} from 'app/workbook-core';

@Component({
  selector: 'app-repository-protocolContent',
  templateUrl: './protocolContent.component.html',
  styleUrls: ['./protocolContent.component.scss']
})

export class ProtocolContentComponent extends EntityFormComponent {
  entity: EntityProtocolContent;
  newMerchandiseNature: string = '';
  newMerchandiseState: string = '';
  newMerchandisePackaging: string = '';
  newVehicle: string = '';
  newVehicleType: string = '';
  newEquipment: string = '';
  newProvidedEquipment: string = '';
  newWarningDevice: string = '';
  newInterventionDevice: string = '';
  newEmergency: string = '';

  newProtectiveGear: string = '';
  newInterdiction: string = '';
  newInstruction: string = '';
  newProductFeature: string = '';
  newOriginatorInstruction: string = '';

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: ProtocolContentService

  ) {
    super(dialog, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
  }
  addMerchandiseNature() {
    if (this.rightU && this.entity && this.newMerchandiseNature) {
      if (!this.entity.merchandiseNatures) {
        this.entity.merchandiseNatures = [];
      }
      this.entity.merchandiseNatures.push(this.newMerchandiseNature);
      this._save().then((e) => {this._afterSave(e);});
      this.newMerchandiseNature = '';
    };
  }
  removeMerchandiseNature(index: number) {
    if (this.rightU && this.entity && this.entity.merchandiseNatures && this.entity.merchandiseNatures.length > index) {
      this.entity.merchandiseNatures.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addMerchandiseState() {
    if (this.rightU && this.entity && this.newMerchandiseState) {
      if (!this.entity.merchandiseStates) {
        this.entity.merchandiseStates = [];
      }
      this.entity.merchandiseStates.push(this.newMerchandiseState);
      this._save().then((e) => {this._afterSave(e);});
      this.newMerchandiseState = '';
    };
  }
  removeMerchandiseState(index: number) {
    if (this.rightU && this.entity && this.entity.merchandiseStates && this.entity.merchandiseStates.length > index) {
      this.entity.merchandiseStates.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addMerchandisePackaging() {
    if (this.rightU && this.entity && this.newMerchandisePackaging) {
      if (!this.entity.merchandisePackagings) {
        this.entity.merchandisePackagings = [];
      }
      this.entity.merchandisePackagings.push(this.newMerchandisePackaging);
      this._save().then((e) => {this._afterSave(e);});
      this.newMerchandisePackaging = '';
    };
  }
  removeMerchandisePackaging(index: number) {
    if (this.rightU && this.entity && this.entity.merchandisePackagings && this.entity.merchandisePackagings.length > index) {
      this.entity.merchandisePackagings.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addVehicle() {
    if (this.rightU && this.entity && this.newVehicle) {
      if (!this.entity.vehicles) {
        this.entity.vehicles = [];
      }
      this.entity.vehicles.push(this.newVehicle);
      this._save().then((e) => {this._afterSave(e);});
      this.newVehicle = '';
    };
  }
  removeVehicle(index: number) {
    if (this.rightU && this.entity && this.entity.vehicles && this.entity.vehicles.length > index) {
      this.entity.vehicles.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }

  addVehicleType() {
    if (this.rightU && this.entity && this.newVehicleType) {
      if (!this.entity.vehicleTypes) {
        this.entity.vehicleTypes = [];
      }
      this.entity.vehicleTypes.push(this.newVehicleType);
      this._save().then((e) => {this._afterSave(e);});
      this.newVehicleType = '';
    };
  }
  removeVehicleType(index: number) {
    if (this.rightU && this.entity && this.entity.vehicleTypes && this.entity.vehicleTypes.length > index) {
      this.entity.vehicleTypes.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }

  addEquipment() {
    if (this.rightU && this.entity && this.newEquipment) {
      if (!this.entity.equipments) {
        this.entity.equipments = [];
      }
      this.entity.equipments.push(this.newEquipment);
      this._save().then((e) => {this._afterSave(e);});
      this.newEquipment = '';
    };
  }
  removeEquipment(index: number) {
    if (this.rightU && this.entity && this.entity.equipments && this.entity.equipments.length > index) {
      this.entity.equipments.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addProvidedEquipment() {
    if (this.rightU && this.entity && this.newProvidedEquipment) {
      if (!this.entity.providedEquipments) {
        this.entity.providedEquipments = [];
      }
      this.entity.providedEquipments.push(this.newProvidedEquipment);
      this._save().then((e) => {this._afterSave(e);});
      this.newProvidedEquipment = '';
    };
  }
  removeProvidedEquipment(index: number) {
    if (this.rightU && this.entity && this.entity.providedEquipments && this.entity.providedEquipments.length > index) {
      this.entity.providedEquipments.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }

  addWarningDevice() {
    if (this.rightU && this.entity && this.newWarningDevice) {
      if (!this.entity.warningDevices) {
        this.entity.warningDevices = [];
      }
      this.entity.warningDevices.push(this.newWarningDevice);
      this._save().then((e) => {this._afterSave(e);});
      this.newWarningDevice = '';
    };
  }
  removeWarningDevice(index: number) {
    if (this.rightU && this.entity && this.entity.warningDevices && this.entity.warningDevices.length > index) {
      this.entity.warningDevices.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addInterventionDevice() {
    if (this.rightU && this.entity && this.newInterventionDevice) {
      if (!this.entity.interventionDevices) {
        this.entity.interventionDevices = [];
      }
      this.entity.interventionDevices.push(this.newInterventionDevice);
      this._save().then((e) => {this._afterSave(e);});
      this.newInterventionDevice = '';
    };
  }
  removeInterventionDevice(index: number) {
    if (this.rightU && this.entity && this.entity.interventionDevices && this.entity.interventionDevices.length > index) {
      this.entity.interventionDevices.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addEmergency() {
    if (this.rightU && this.entity && this.newEmergency) {
      if (!this.entity.emergency) {
        this.entity.emergency = [];
      }
      this.entity.emergency.push(this.newEmergency);
      this._save().then((e) => {this._afterSave(e);});
      this.newEmergency = '';
    };
  }
  removeEmergency(index: number) {
    if (this.rightU && this.entity && this.entity.emergency && this.entity.emergency.length > index) {
      this.entity.emergency.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }


  addProtectiveGear() {
    if (this.rightU && this.entity && this.newProtectiveGear) {
      if (!this.entity.protectiveGears) {
        this.entity.protectiveGears = [];
      }
      this.entity.protectiveGears.push(new Illustrated(this.newProtectiveGear));
      this._save().then((e) => {this._afterSave(e);});
      this.newProtectiveGear = '';
    };
  }
  removeProtectiveGear(index: number) {
    if (this.rightU && this.entity && this.entity.protectiveGears && this.entity.protectiveGears.length > index) {
      this.entity.protectiveGears.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }


  addInterdiction() {
    if (this.rightU && this.entity && this.newInterdiction) {
      if (!this.entity.interdictions) {
        this.entity.interdictions = [];
      }
      this.entity.interdictions.push(this.newInterdiction);
      this._save().then((e) => {this._afterSave(e);});
      this.newInterdiction = '';
    };
  }
  removeInterdiction(index: number) {
    if (this.rightU && this.entity && this.entity.interdictions && this.entity.interdictions.length > index) {
      this.entity.interdictions.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  addInstruction() {
    if (this.rightU && this.entity && this.newInstruction) {
      if (!this.entity.instructions) {
        this.entity.instructions = [];
      }
      this.entity.instructions.push(this.newInstruction);
      this._save().then((e) => {this._afterSave(e);});
      this.newInstruction = '';
    };
  }
  removeInstruction(index: number) {
    if (this.rightU && this.entity && this.entity.instructions && this.entity.instructions.length > index) {
      this.entity.instructions.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }


  addProductFeature() {
    if (this.rightU && this.entity && this.newProductFeature) {
      if (!this.entity.productFeatures) {
        this.entity.productFeatures = [];
      }
      this.entity.productFeatures.push(new Illustrated(this.newProductFeature));
      this._save().then((e) => {this._afterSave(e);});
      this.newProductFeature = '';
    };
  }
  removeProductFeature(index: number) {
    if (this.rightU && this.entity && this.entity.productFeatures && this.entity.productFeatures.length > index) {
      this.entity.productFeatures.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }
  public fileEvent(field: string, index: number, event) {

    if (field && event.target && event.target.files && event.target.files.length
      && this.entity && this.entity[field] && this.entity[field][index]) {
      const fileSelected: File = event.target.files[0];
      getBase64(fileSelected).then(
        data => {
          this.entity[field][index].logo = data.toString();
          this._save().then((e) => {this._afterSave(e);});
        });
    }
  }
  addOriginatorInstruction() {
    if (this.rightU && this.entity && this.newOriginatorInstruction) {
      if (!this.entity.originatorInstructions) {
        this.entity.originatorInstructions = [];
      }
      this.entity.originatorInstructions.push(this.newOriginatorInstruction);
      this._save().then((e) => {this._afterSave(e);});
      this.newOriginatorInstruction = '';
    };
  }
  removeOriginatorInstruction(index: number) {
    if (this.rightU && this.entity && this.entity.originatorInstructions && this.entity.originatorInstructions.length > index) {
      this.entity.originatorInstructions.splice(index, 1);
      this._save().then((e) => {this._afterSave(e);});
    };
  }

}
