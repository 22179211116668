import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-agreements-dialog',
  templateUrl: './agreements-dialog.component.html',
  styleUrls: ['./agreements-dialog.component.scss']
})
export class AgreementsDialogComponent {

  public agreement: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.agreement = data;
  }

}
