import {BehaviorSubject} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {formattedDate} from '../utils/utils';
export class LoadingService {
  public loading: BehaviorSubject<any> = new BehaviorSubject({});
  protected _loading: {[id: string]: string} = {};
  protected _timer: {[id: string]: Date} = {};
  protected _name: {[id: string]: string} = {};
  public offline: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public syncState: BehaviorSubject<number> = new BehaviorSubject(2);

  constructor(
  ) {
    window.addEventListener('online', () => {
      console.log('came online');
      if (this.offline.value) {
        this.offline.next(false);
      }
    });
    window.addEventListener('offline', () => {
      console.log('came offline');
      if (!this.offline.value) {
        this.offline.next(true);
      }
    });
  }
  setSyncState(state: number) {
    this.syncState.next(state);
  }
  addLoad(msg: string, parent_id: string = '') {
    //let id = msg + (parent_id ? ' (' + parent_id + ')' : '');//UUID.UUID();
    let id = UUID.UUID();
    //this._loading[id] = id;//msg +(parent_id?' ('+parent_id+')':'');
    this._loading[id] = msg;// + (parent_id ? ' (' + parent_id + ')' : '');
    this.loading.next(this._loading);
    this._timer[id] = new Date();
    this._name[id] = msg;
    console.log(this._loading[id] + (parent_id ? ' (' + parent_id + ')' : '') + ' - loading start : ' + formattedDate(this._timer[id], true, true));
    return id;
  }
  removeLoad(id) {
    if (id && this._loading[id]) {
      delete this._loading[id];
      this.loading.next(this._loading);
      console.log(this._name[id] + ' - loaded : ' + Math.abs(new Date().getTime() - this._timer[id].getTime()));
      if (Object.keys(this._loading).length) {
        console.log('loading ' + Object.keys(this._loading).length + ' (' + Object.keys(this._loading).map((e) => (this._name[e])).join(', ') + ')');
      } else {
        console.log(formattedDate(new Date(), true, true) + ' - loaded !');
      }
      delete this._timer[id];
      delete this._name[id];
    }
  }

}
