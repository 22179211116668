import {LOCALE_ID, Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {DateTimeCommonComponent} from './datetimeCommon.component';

@Component({
  selector: 'app-time',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [
    {provide: LOCALE_ID, useValue: "fr-FR"}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeComponent extends DateTimeCommonComponent implements OnChanges {

  type = 'time';
  displayFormat = 'HH:mm';
  pickerType = '';

  @Input() timeMin: string | null;
  @Input() timeMax: string | null;
  @Input() time: string | null;
  @Output() timeChange = new EventEmitter<string | null>();

  initChange: boolean = true;

  constructor(protected _cd: ChangeDetectorRef) {
    super(_cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    //if (changes && changes['time']) {
    this.init();
    //}
    super.ngOnChanges(changes);
  }
  init() {
    if (this.time) {
      this.value = new Date();
      const t = this.time.split(':');
      if (t.length === 2) {
        this.value.setHours(Number(t[0]), Number(t[1]), 0, 0);
      }
    }
    /*
      else if (this.required && !this.time && !this.value) {
      this.value = new Date();
      const hours = this.value.getHours();
      const minutes = this.value.getMinutes();
      this.time = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
      this.timeChange.emit(this.time);
    }
    */

  }
  checkErrorRequired() {
    if (this.isValid && this.required && !this.time) {
      this.isValid = false;
    }

  }
  checkError() {
    if (this.time) {
      let _isValid = true;
      const _t = this.time.split(':');
      if (_t.length === 2) {
        const _t0 = Number(_t[0]);
        const _t1 = Number(_t[1]);
        if (this.timeMin) {
          const t = this.timeMin.split(':');
          if (t.length === 2) {
            const t0 = Number(t[0]);
            const t1 = Number(t[1]);
            _isValid = _t0 > t0 || (_t0 === t0 && _t1 >= t1);
          }
        }
        if (this.timeMax) {
          const t = this.timeMax.split(':');
          if (t.length === 2) {
            const t0 = Number(t[0]);
            const t1 = Number(t[1]);
            _isValid = _t0 < t0 || (_t0 === t0 && _t1 <= t1);
          }
        }

      } else {
        _isValid = false;
      }
      if (this.isValid !== _isValid) {
        this.isValid = _isValid;
        this.field.isValid = this.isValid;
      }
    }
    super.checkError();
  }

  emitValue(value) {
    let v = '';
    if (value) {
      const hours = new Date(value).getHours();
      const minutes = new Date(value).getMinutes();
      v = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes
    }
    this.timeChange.emit(v);
  }
}