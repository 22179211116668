import {Component, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService} from 'app/shared';
import {PreventionPlanService, EntityStep} from 'app/workbook-core';
import {AbstractWorkbookStepListComponent} from 'app/workbook-shared';

@Component({
  selector: 'app-step-template-list',
  templateUrl: './stepTemplateList.component.html',
  styleUrls: ['./stepList.component.scss']
})

export class WorkbookStepTemplateListComponent extends AbstractWorkbookStepListComponent {


  displayedTemplateColumns: string[] = ['name', 'site_name', 'site_zipCode', 'site_city', 'actions', 'delete'];
  templateDataSource: MatTableDataSource<EntityStep> = new MatTableDataSource([]);
  @ViewChild('templateSort') templateSort: MatSort;

  constructor(
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }

  protected _init(data) {
    super._init(data);
    if (this.entity && this.entity.type === 'annual') {
      this.updateTemplateDataSource();
    }
  }

  protected setTemplateSort() {
    if (this.templateSort) {
      this.templateDataSource.sort = this.templateSort;
    }
  }
  protected updateTemplateDataSource() {
    this.templateDataSource = new MatTableDataSource(this.templateList);
    setTimeout(() => {
      this.setTemplateSort();
    });
  }

  _afterCreateStep(c) {
    this._spinnerService.show();
    this.updateElement(c);
    this.selectedStepIndex = this.entityList.findIndex((e) => (e._id === c._id));
    setTimeout(() => {this._spinnerService.hide();});
  }
  cloneStep(step: EntityStep) {
    this.addStep(step.template, this._getChildService().getNewEntity(step), true, (!step.template && !step.template_id));
  }
}
