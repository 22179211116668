import { Entity, EntityDocumentMetadata } from 'app/shared';

export class EntityDocumentation extends Entity {
  meta: EntityDocumentMetadata;
  oo: boolean;
  oo_pdp: boolean;
  oo_icp: boolean;
  oo_pf: boolean;
  oo_ps: boolean;
  op: boolean;
  op_pdp: boolean;
  op_icp: boolean;
  op_pf: boolean;
  op_ps: boolean;
  ps: boolean;
  ps_ps: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.meta = (data && data.meta) ? Object.assign({}, data.meta) : null;
    this.oo = !!(data && data.oo);
    this.oo_pdp = !!(data && data.oo_pdp);
    this.oo_icp = !!(data && data.oo_icp);
    this.oo_pf = !!(data && data.oo_pf);
    this.oo_ps = !!(data && data.oo_ps);
    this.op = !!(data && data.op);
    this.op_pdp = !!(data && data.op_pdp);
    this.op_icp = !!(data && data.op_icp);
    this.op_pf = !!(data && data.op_pf);
    this.op_ps = !!(data && data.op_ps);
    this.ps = !!(data && data.ps);
    this.ps_ps = !!(data && data.ps_ps);
  }
}
