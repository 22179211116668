import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { LoggerService, ConfirmDialogComponent, SignableStatus } from 'app/shared';
import { WorkbookSignableListComponent, AuditInfoDialogComponent, AuditDialogComponent } from 'app/workbook-shared';
import {
  PreventionPlanService,
  EntityPreventionPlan,
  AuditService,
  EntityAudit, AuditState,
  EntityStep, DocumentationService
} from 'app/workbook-core';

@Component({
  selector: 'app-signable-audit-list',
  templateUrl: './auditList.component.html',
  styleUrls: ['./auditList.component.scss']
})

export class AuditListComponent extends WorkbookSignableListComponent {

  entity: EntityPreventionPlan;
  entityList: EntityAudit[];
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette visite de contrôle ?';
  addConfirmMessage = 'Nouvelle visite de contrôle créée';

  stepList: { [step_id: string]: EntityStep } = {};
  protected _stepsSubscription: Subscription;
  states: { [icp_id: string]: number } = {};

  dataSource: MatTableDataSource<EntityAudit> = new MatTableDataSource([]);
  displayedColumns = ['name', 'editor', 'step', 'description', 'date', 'status', 'actions']
  // dialogRefDel: MatDialogRef<ConfirmDialogComponent>;
  _urlBaseElement = '/';


  statusLabels = AuditState.getLabels();
  statusDescriptions = AuditState.getDescriptions();

  constructor(
    //preload
    protected _documentationService: DocumentationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService
  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
  }
  protected _init(data) {
    super._init(data);
    if (this.entityList && this.entity) {
      let modified = this.entity.removeDeletedAudit(this.entityList.map((e) => (e._id)));
      this.entityList.forEach((audit: EntityAudit) => {
        modified = this.entity.updateIcp(audit, modified)
      });
      if (modified) {
        this._entityService.save(this.entity);
      }
    }
    if (this._stepsSubscription) {
      this._stepsSubscription.unsubscribe();
    }
    this._stepsSubscription = this._entityService.stepService.getChildren(this.entity._id).subscribe((list) => {
      if (list) {
        this.stepList = {};
        list
          .forEach((step) => {
            this.stepList[step._id] = step;
          });
      }
    });
    if (this.entityList) {
      this.refreshDataSource();
    }
  }

  refreshStates() {
    if (this.entityList && this.entity) {
      this.states = {};
      this.entityList.forEach((fp) => {
        this.states[fp._id] = this._getChildService().getState(fp, this.entity.status);
      });
    }
  }


  getState(fp: EntityAudit) {
    return fp && fp._id && this.states[fp._id] ? this.states[fp._id] : 0;

  }
  getLabelState(fp: EntityAudit) {
    return AuditState.getLabel(this.getState(fp));

  }
  getDescriptionState(fp: EntityAudit) {
    return AuditState.getDescription(this.getState(fp));

  }
  protected _getChildService() {
    return this._entityService.auditService;
  }

  /**
   * Create dialog popup inspection
   */
  public selectSignable(audit: EntityAudit = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(AuditInfoDialogComponent, {
      disableClose: true,
      width: '920px',
      maxWidth: '90vw',
      data: {
        id: audit ? audit._id : '',
        selectedStep: (audit && audit._id) ? audit.step : '',
        entity: (audit && !audit._id) ? audit : null,
        step: (audit && this.stepList && audit.step
          && this.stepList[audit.step] && this.stepList[audit.step].implementation) ? this.stepList[audit.step] : null,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }
  public openDialog(audit = null) {
    // icp.iPOEex = this._planPreventionService.getUpdatedIPOEex(this.pp, icp);
    // TODO : if no externalCompanies then popup message not dialog for create inspection
    const dialogRef = this.dialog.open(AuditDialogComponent, {
      disableClose: true,
      width: '890px',
      height: '90%',
      data: {
        id: audit ? audit._id : '',
        entity: audit,
        parent_id: this.id ? this.id : '',
        workbook: this.entity
      }
    });
    dialogRef.afterClosed().subscribe(c => {
      if (c && c !== 'undefined') {
        this.updateElement(c);
        this.refreshDataSource();
      }
    });
  }

  public duplicateAudit(element) {
    this._getChildService().getCurrentOne(element._id).then((e) => {
      const newElement: EntityAudit = this._getChildService().getNewEntity(e, true);
      newElement.status = 0;
      newElement.signatures = [];
      newElement.date = null;
      newElement.hourStart = '';
      newElement.hourEnd = '';
      newElement.content = '';
      newElement.createdAt = null;
      newElement.createdId = '';
      newElement.createdName = '';

      newElement.editor = '';
      newElement.editorEmail = '';
      this.selectSignable(newElement);
    });
  }

  outdateSignable(entity) {
    const dialogRefDel: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      width: '600px',
    });
    dialogRefDel.componentInstance.buttonMessage = "Confirmer";
    dialogRefDel.componentInstance.confirmMessage = "Voulez-vous rendre cette édition obsolète ?";

    dialogRefDel.afterClosed().subscribe(result => {
      if (result) {
        this._getChildService().get(entity._id).toPromise().then((signable: EntityAudit) => {
          if (signable) {
            signable.status = SignableStatus.OUTDATED;
            this._getChildService().save(signable).then((e) => {
              this.updateElement(signable);
            });
          }
        });
      }
    });
  }
}
