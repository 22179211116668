import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService} from 'app/shared/logger';
import {DocumentsCommonComponent} from './documentsCommon.component';
import {DocumentMetadataService} from 'app/shared/services';
import {DocumentService} from 'app/shared/document';
import {EntityDocumentMetadata, Contact} from 'app/shared/models';

@Component({
  selector: 'app-documents-request',
  templateUrl: './documentsRequest.component.html',
  styleUrls: ['./documentInfo.component.scss']
})
export class DocumentsRequestComponent extends DocumentsCommonComponent implements OnChanges {
  @Input() requestList: Contact[] = [];
  @Input() requestedList: string[] = [];
  @Input() notificationList: Contact[] = [];
  @Input() documentsRequest: any[] = [];
  selectedDocumentsRequest: string = '';
  entities: EntityDocumentMetadata[] = [];

  templates: File[] = [];

  constructor(
    protected _router: Router,
    protected _documentService: DocumentService,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: DocumentMetadataService
  ) {
    super(_router, _documentService, dialog, _logger, _entityService);
  }
  getEntityIndex(index: number) {
    return (this.entities[index]) ? this.entities[index] : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes && changes['notificationList'] && this.userProfile) {
      const i = this.notificationList.findIndex((c) => (c.email && this.userProfile && this.userProfile.email && c.email === this.userProfile.email));
      if (i === -1) {
        const c = new Contact();
        c.email = this.userProfile.email;
        c.name = this._entityService.getUserName();
        if (this.userProfile.user_metadata.phone_number) {
          c.phone = this.userProfile.user_metadata.phone_number
        }

        this.notificationList.push(c);
      }
    }
  }
  toggleRequested(c: Contact, event) {
    const i = this.requestedList.indexOf(c.id);
    if (i === -1 && event.checked) {
      this.requestedList.push(c.id);
    } else if (i !== -1 && !event.checked) {
      this.requestedList.splice(i, 1);
    }
    const j = this.entity.request.indexOf(c.email);
    if (j === -1 && event.checked) {
      this.entity.request.push(c.email);
    } else if (j !== -1 && !event.checked) {
      this.entity.request.splice(j, 1);
    }
  }

  toggleNotification(c: Contact, event) {
    const i = this.entity.notifications.indexOf(c.email);
    if (i === -1 && event.checked) {
      this.entity.notifications.push(c.email);
    } else if (i !== -1 && !event.checked) {
      this.entity.notifications.splice(i, 1);
    }
  }


  public removeElement(i) {
    if (i !== -1) {
      this.entities.splice(i, 1);
      this.showDates.splice(i, 1);
      this.showMetas.splice(i, 1);
      this.dateStartTxt.splice(i, 1);
      this.dateEndTxt.splice(i, 1);
      this.templates.splice(i, 1);
    }
  }
  public addElement(name: string = '') {
    const elementToAdd = this._entityService.getNewEntity({
      name: name,
      context: this.context
    });
    this.entities.push(elementToAdd);
    this.showDates.push(false);
    this.showMetas.push(true);
    this.dateStartTxt.push('');
    this.dateEndTxt.push('');
    this.templates.push(null);

  }

  public addDocumentsRequest(name: string = '') {
    if (this.selectedDocumentsRequest) {
      const elementToAdd = this.documentsRequest.find((e) => (e._id === this.selectedDocumentsRequest));
      if (elementToAdd && elementToAdd.documents && elementToAdd.documents.length) {
        elementToAdd.documents.forEach((d) => {
          this.entities.push(d);
          this.showDates.push(false);
          this.showMetas.push(true);
          this.dateStartTxt.push('');
          this.dateEndTxt.push('');
          this.templates.push(null);
        });
      }
    }
  }
  templateRemoveEvent(index: number) {
    if (this.templates && this.entities && this.entities[index] && this.entities[index].template_name) {
      this.templates[index] = null;
      this.entities[index].template_id = '';
      this.entities[index].template_name = '';
    }

  }
  templateChangeEvent(index: number, event) {
    if (event && event.addedFiles && event.addedFiles[0] && this.templates) {
      this.templates[index] = event.addedFiles[0];
      this.entities[index].template_id = '';
      this.entities[index].template_name = event.addedFiles[0].name;
    }

  }
  saveTemplate(file: File) {
    return this._documentService.uploadFile(file, file.name).toPromise();
  }
}
