import {Component, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {MatTableDataSource, MatDialog, MatSnackBar, MatSort} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  Operator, Attendance,
  promiseAll, LoggerService, EmailService, getIcon, formatBytes,
  EntitySignable, EmailDatasModel,
  SignableService, EntityCompany, Contact,
  EntityDocumentMetadata, SignableState, SignableStatus,
} from 'app/shared';
import {Workbook} from '../workbook.model'
import {CommonSignableComponent} from '../commonSignable.component';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-signables',
  templateUrl: './signables.component.html',
  styleUrls: ['./signables.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SignablesComponent extends CommonSignableComponent implements OnDestroy {
  static STATE_SORT = [
    Workbook.STATUS_DRAFT,
    Workbook.STATUS_PROGRESS,
    Workbook.STATUS_COMPLETE,
    Workbook.STATUS_ARCHIVE
  ];
  @ViewChild('sort') sort: MatSort;
  @ViewChild('sortSignup') sortSignup: MatSort;

  //@Output() filterChange = new EventEmitter();
  entityList: EntitySignable[] = [];
  entity: Workbook;

  operatorsCount: number = 0;
  //operators: { [oeex_id: string]: Operator[] } = {};

  displayedOperatorsColumns: string[] = ['name', 'phone', 'extra', 'attendances'];
  operators: {[oeex_name: string]: MatTableDataSource<Operator>} = {};
  operatorSignables: {
    [id: string]: {
      signable: EntitySignable,
      attendances: Attendance[]
    }[]
  } = {};
  showAttendances: {[index: string]: boolean} = {};

  companies: {[oeex_id: string]: EntityCompany} = {};
  lastPdp: {[signable_id: string]: EntitySignable} = {};
  states: {[signable_id: string]: number} = {};

  files: File[] = [];
  requestedOeex: EntityCompany[] = [];
  requestedContact: {[oeex_id: string]: Contact[]} = {};
  selectedContact: {[doc_id: string]: string} = {};
  requestedDocuments: {[oeex_id: string]: EntityDocumentMetadata[]} = {};
  requestedDocumentCount: number = 0;
  dataSource: MatTableDataSource<EntitySignable> = new MatTableDataSource([]);
  dataSourceSignup: MatTableDataSource<EntitySignable> = new MatTableDataSource([]);
  displayedColumns = ['icon', 'name', 'editor', 'state'];

  tabIndex: number = 0;

  constructor(
    public snackBar: MatSnackBar,
    protected _emailService: EmailService,
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_route, dialog, _logger, _spinnerService, _signableService);
  }

  protected _apiCall(data): Observable<any> {
    if (data['id']) {
      return this._signableService.getSignables(data['id']);
    } else {
      return Observable.throw('no id');
    }
  }

  protected _initError(data) {
    super._initError(data);

    if (data['workbook']) {
      this.entity = data['workbook'];
    }
  }
  protected _init(data) {
    if (data) {
      if (data["workbook"] && data["workbook"]["name"]) {
        this.name = data["workbook"]["name"];
        this.entity = data["workbook"];
        this.workbook = this.entity;
      }
      if (data["signables"]) {
        this.entityList = data["signables"];
        this.refreshDatasource();
      }
      if (data["companies"] && data["companies"].length) {

        this.requestedOeex = [];
        this.requestedDocuments = {};
        this.requestedContact = {};
        this.selectedContact = {};
        this.companies = {};
        this.operatorsCount = 0;
        this.operators = {};
        const _operators: {[oeex_name: string]: Operator[]} = {};
        this.requestedDocumentCount = 0;
        if (data["operators"]) {
          data["operators"].forEach((op: Operator) => {
            if (op.company) {
              if (!_operators[op.company]) {
                _operators[op.company] = [];
              }
              _operators[op.company].push(op);
              this.operatorsCount++;
            }
          });
          Object.keys(_operators).forEach((k) => {
            this.operators[k] = new MatTableDataSource(_operators[k]);
            _operators[k].forEach((o) => {
              this.operatorSignables[o.id] = this.getOperatorSignables(o);
            });
          });
        }
        data["companies"].forEach((oeex) => {
          this.companies[oeex._id] = oeex;
          if (oeex['documents'] && oeex['documents'].length) {
            const requestedDoc = oeex['documents'].filter((doc) => (!doc.data_id));
            if (requestedDoc && requestedDoc.length) {
              if (oeex._id) {
                if (oeex.contacts && oeex.contacts.length) {
                  const userContacts = oeex.contacts.filter((e) => (e.user_id && e.user_id === this.userId));
                  if (userContacts && userContacts.length) {
                    this.requestedContact[oeex._id] = userContacts;
                  }
                }
                if (this.requestedContact[oeex._id] && this.requestedContact[oeex._id].length && oeex.documents && oeex.documents.length) {
                  const userDocuments = oeex.documents.filter((e) => (!e.data_id));
                  if (userDocuments && userDocuments.length) {
                    userDocuments.forEach((doc) => {
                      //à defaut de selecteur, on prend le premier
                      if (this.requestedContact[oeex._id][0] && this.requestedContact[oeex._id][0]['id']) {
                        this.selectedContact[doc._id] = this.requestedContact[oeex._id][0]['id'];
                      }
                    });
                    this.requestedDocuments[oeex._id] = userDocuments;
                    this.requestedDocumentCount += userDocuments.length;
                  }
                }
                if (this.requestedContact[oeex._id] && this.requestedDocuments[oeex._id]) {
                  this.requestedOeex.push(oeex);
                }
              }
            }
          }
        });
        if (this._route.snapshot.url
          && this._route.snapshot.url[2]
          && this._route.snapshot.url[2].path
          && this._route.snapshot.url[2].path === 'documents') {
          this.tabIndex = 1;
        }
      }
    }
  }
  getOperatorSignables(op: Operator): {
    signable: EntitySignable,
    attendances: Attendance[]
  }[] {
    return this.entityList
      .filter((signable: EntitySignable) => (
        (signable.documentType === 'pp' && this.states[signable._id] === SignableState.VALID)
        ||
        (signable.attendances && signable.attendances.filter((a) => (a.id === op.id)).length > 0)
        ||
        (!!signable["operators"] && Object.keys(signable["operators"]).filter((op_group: string) => (
          Array.isArray(signable["operators"][op_group]) && signable["operators"][op_group].filter((o) => (o.id === op.id)).length > 0
        )).length > 0)
      ))
      .map((signable: EntitySignable) => (
        {
          signable: signable,
          attendances: signable.attendances.filter((a) => (a.id === op.id))
        }
      ));

  }
  refreshDatasource() {
    if (this.entityList && this.entity) {
      this.lastPdp = {};
      this.entityList
        .filter((e) => (e.documentType === 'pdp'))
        .forEach((pdp) => {
          if (pdp['icps'] && pdp['icps'].length) {
            pdp['icps'].forEach((icp_id) => {
              if (!this.lastPdp[icp_id]
                || this.lastPdp[icp_id].status < pdp.status
                || (this.lastPdp[icp_id].status === pdp.status
                  && new Date(this.lastPdp[icp_id].editedAt).getTime() < new Date(pdp.editedAt).getTime())
              ) {
                this.lastPdp[icp_id] = pdp;
              }
            });
          }
        });
      this.states = {};
      this.entityList.forEach((signable) => {
        this.states[signable._id] = this._signableService.getState(signable, this.entity.status, this.lastPdp[signable._id] ? this.lastPdp[signable._id].status : 0);
      });
      this.dataSource = new MatTableDataSource(
        this.entityList
          .filter((e) => (!(e.status === SignableStatus.SIGNING && !e.withoutSignatories) || (e.signatures && e.signatures.findIndex((e) => (e.user_id === this.userId && !!e.data)) !== -1)))
          .map((e) => (new EntitySignable(e)))
      );
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'state': {
            return this.getState(item);
          }
          default: {
            return item[property];
          }
        };
      }
      this.dataSourceSignup = new MatTableDataSource(this.entityList.filter((e) => (e.status === SignableStatus.SIGNING && !e.withoutSignatories && (!e.signatures || e.signatures.findIndex((e) => (e.user_id === this.userId && !!e.data)) === -1))));
      setTimeout(() => {
        this.setSort();
      });
    }
  }
  setSort() {
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    if (this.sortSignup) {
      this.dataSourceSignup.sort = this.sortSignup;
    }
  }

  public dynamicRoute(signable: EntitySignable) {
    return ['/' + this._signableService.getUrlSignable(signable._id)];
  }


  getState(signable: EntitySignable) {
    return signable && signable._id && this.states[signable._id] ? this.states[signable._id] : 0;

  }
  getLabelState(signable: EntitySignable) {
    return SignableState.getLabel(this.getState(signable));

  }
  getDescriptionState(signable: EntitySignable) {
    return SignableState.getDescription(this.getState(signable));

  }

  fileRemoveEvent(document_id: string) {
    if (this.files[document_id]) {
      this.files[document_id] = null;
    }
  }
  fileChangeEvent(event: any, document_id: string) {
    if (event.addedFiles && event.addedFiles[0]) {
      this.files[document_id] = new File([event.addedFiles[0]], event.addedFiles[0].name, {type: event.addedFiles[0].type});
    }
  }
  sendFile(document: EntityDocumentMetadata, oeex_id: string) {
    //console.log('sendFile', document._id);
    if (document && document._id && this.files[document._id] && this.selectedContact && this.selectedContact[document._id]) {
      this._spinnerService.show();
      this._signableService.upload(document._id, this.files[document._id], {oeex_id: oeex_id, userName: this._signableService.getUserName()})
        .then((res) => {
          this._spinnerService.hide();
          this.snackBar.open('Votre fichier a été enregistré', '', {
            duration: 5000
          });
          const company = this.companies[oeex_id];
          const contactId = this.selectedContact[document._id];
          const contact = this.requestedContact[oeex_id].find((e) => (e.id === contactId));
          if (document.notifications && document.notifications.length && company && contact) {
            const emailPromises = [];
            document.notifications.forEach((email) => {

              const emailDatas = new EmailDatasModel();
              emailDatas.date = new Date();
              emailDatas.subject = "Document déposé";
              emailDatas.text = 'Le document "' + document.name + '" demandé à ' + company.name + ' a été déposé par ' + contact.name;
              //emailDatas.signature = email.signature;
              emailDatas.email = email;
              emailDatas.to_name = '';
              //emailDatas.from_name = contact.name;
              //emailDatas.from_email = contact.email;
              emailDatas.reply_to = contact.email;
              emailPromises.push(this._emailService.sendEmail(emailDatas));
            });
            promiseAll(emailPromises).then((responses) => {
              //this._logger.info('EmailService', 'SUCCESS!', response.status, response.text);
              this.snackBar.open('La notification a été envoyée', '', {
                duration: 5000
              });

            }, (err) => {
              this._logger.error('EmailService', 'Send email failed !', JSON.stringify(err));
              this._displayError('Erreur d\'envoi de la notification.');
            });
          }

          //refresh ?
          this._initFromRouteParams((this.entity ? {id: this.entity._id} : {}));
        }).catch((err) => {
          this._logger.error('sendFile signableService.upload', 'upload error', JSON.stringify(err));
          this._spinnerService.hide();
          this._displayError('Une erreur s\'est produite lors de l\'envoi du fichier, veuillez réessayer et sinon contacter le support.');
        });
    }

  }

  public formatBytes(size: number): string {
    return formatBytes(size);
  }
  public getIcon(mime: string): string {
    return getIcon(mime);
  }

  public getFilenameExt(filename) {
    return filename.substr(filename.lastIndexOf('.') + 1);
  }
}
