import { Entity } from './entity.model';

export class EntityRole extends Entity {

  static ROLES_ORDER = ['PP_EEX', 'PP_CHOP', 'PP_SUPERVISOR', 'PP_ADMIN', 'PP_ADMIN_TEPIED'];

  public name: string;
  public description: string;
  public permissions: string[];
  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.name = (data && data.name) ? data.name : '';
    this.description = (data && data.description) ? data.description : '';
    this.permissions = (data && data.permissions) ? data.permissions.map((e) => (e)) : [];
  }
  hasPermission(perm) {
    return this.permissions.indexOf(perm) !== -1;
  }
  clearPermissions() {
    this.permissions = [];
  }
  addPermission(perm) {
    if (!this.hasPermission(perm)) {
      this.permissions.push(perm);
    }
  }
  removePermission(perm) {
    if (!this.hasPermission(perm)) {
      this.permissions.splice(this.permissions.indexOf(perm), 1);
    }
  }
}
