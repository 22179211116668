import {Component, Inject, OnDestroy, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {NgForm} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatDialogRef} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {
  promiseAll, LoadingService, EmailMessages,
  LoggerService,
  PrintingService,
  EmailMessagesService,
  EmailService,
  DocumentService,
  ConfirmDialogComponent
} from 'app/shared';
import {
  PreventionPlanService,
  EntityStep,
  SignablePreventionPlanService,
  EntitySignablePreventionPlan, Implementation,
  PdpExportComponent, DocumentationService
} from 'app/workbook-core';
import {AbstractSignableDialogComponent} from 'app/workbook-shared';


@Component({
  selector: 'app-signable-prevention-plan-dialog',
  templateUrl: './signablePreventionPlan-dialog.component.html',
  styleUrls: ['./signablePreventionPlan-dialog.component.scss']
})
export class SignablePreventionPlanDialogComponent extends AbstractSignableDialogComponent implements OnDestroy {
  @ViewChild('emergencyform') emergencyform: NgForm;
  @ViewChildren(NgForm) forms: QueryList<NgForm>;

  static FLAG_SUFFIX: string = 'pdp';

  SIGNATORY_RIGHT: string = 'signatoryPdP';

  SIGNING_NOTIFY = EmailMessages.SIGN_PDP_REQUEST;
  SIGNED_NOTIFY = EmailMessages.SIGN_PDP;

  entity: EntitySignablePreventionPlan;
  entityForm: PdpExportComponent;

  protected _formChange: Subscription;
  protected _firstChange = true;


  constructor(
    protected _documentationService: DocumentationService,
    protected _workbookService: PreventionPlanService,
    protected _loadingService: LoadingService,
    public snackBar: MatSnackBar,
    protected _documentService: DocumentService,
    protected _emailMessagesService: EmailMessagesService,
    protected _emailService: EmailService,
    protected _printingService: PrintingService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SignablePreventionPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected _logger: LoggerService,
    protected _entityService: SignablePreventionPlanService) {
    super(_documentationService, _workbookService, _loadingService, snackBar, _documentService, _emailMessagesService, _emailService, _printingService, _spinnerService, dialog, dialogRef, data, _logger, _entityService);
  }
  getDefaultEmailSubject() {
    return ('Plan de prévention' + ((this.entityForm && this.entityForm.entity && this.entityForm.entity.name) ? (' ' + this.entityForm.entity.name) : '') + (this.workbook ? (' - ' + this.workbook.description) : ''));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.forms.changes.subscribe((elements) => {
      this._subscribeFormChange();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._formChange) {
      this._formChange.unsubscribe();
    }
  }
  protected _subscribeFormChange() {
    if (!this._formChange && this.emergencyform) {
      const that = this;
      this._formChange = this.emergencyform.control.valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe((test) => {
          // console.log(this._findInvalidControls(this.form));
          // Avoid saving data each time the form is loaded
          if (that._firstChange) {
            that._firstChange = false;
          } else {
            //const invalidControls = this._findInvalidFormControls(this.form);
            //if (invalidControls.length === 0) {
            if (that.rightU && that.entity && that.entity._id && that.emergencyform.valid && that.emergencyform.dirty) {
              //console.log('autoSave', that.entity);
              that.save();
            }
          }
        });
    }
  }
  protected _optionalAfterSave(_notify) {
    if (this.entityForm) {
      this.entityForm.initEmergencyInstructionsNew();
      if (this.entityForm.steps && this.entityForm.templates) {
        this._workbookService.updateWorkbookPdP(
          this.workbook,
          this.entity,
          [...this.entityForm.steps, ...this.entityForm.templates])
      }
    }
    /*
    if (_notify === 8) {
      this.optionalCreatePdP();
    }
    */
  }

  public invalidateEditable(): void {
    if (this.entityForm && this.entityForm.entity) {
      this.entityForm.entity.implementations = {};
      super.invalidateEditable();
    }
  }
  setSigning() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.hasDraftIcp()) {
      super.setSigning();
    }
  }
  setSigned() {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.hasDraftIcp()) {
      super.setSigned();
    }
  }

  public validate(updateContent: boolean = true): void {
    if (this.entityForm && this.entityForm.entity && !this.entityForm.hasDraftIcp()) {
      if (updateContent) {
        this.entityForm.entity.implementations = {};

        this.entityForm.entity.icps
          .forEach((icp_id) => {
            if (this.entityForm.icpList[icp_id]) {
              if (this.entityForm.icpList[icp_id].status && this.entityForm.icpList[icp_id].implementations) {
                Object.keys(this.entityForm.icpList[icp_id].implementations).forEach((step_id) => {
                  this.entityForm.entity.implementations[step_id] = this.entityForm.icpList[icp_id].implementations[step_id];
                  this.entityForm.entity.implementations[step_id].pdp_id = this.entityForm.entity._id;
                  this.entityForm.entity.implementations[step_id].pdp_name = this.entityForm.entity.name;
                  this.entityForm.entity.implementations[step_id].icp_id = icp_id;
                  this.entityForm.entity.implementations[step_id].icp_name = this.entityForm.icpList[icp_id].name;
                });
              } else {
                this.entityForm.icpList[icp_id].steps.forEach((step_id) => {
                  if (this.entityForm.stepList[step_id]) {
                    this.entityForm.entity.implementations[step_id] = Implementation.generate(
                      this.entityForm.stepList[step_id],
                      this.entityForm.activities,
                      this.entityForm.risks,
                      [...this.entityForm.offices, this.entityForm.externalCompanies],
                      '',
                      '',
                      this.entityForm.entity._id,
                      this.entityForm.entity.name
                    );
                  }
                });
              }
            }
          });
        this.entityForm.entity.steps
          .forEach((step_id) => {
            if (this.entityForm.stepList[step_id]) {
              this.entityForm.entity.implementations[step_id] = Implementation.generate(
                this.entityForm.stepList[step_id],
                this.entityForm.activities,
                this.entityForm.risks,
                [...this.entityForm.offices, this.entityForm.externalCompanies],
                '',
                '',
                this.entityForm.entity._id,
                this.entityForm.entity.name
              );
            }
          });
        //console.log('save PdP implementations', this.entityForm.entity.implementations);
      }
      super.validate(updateContent);
    }
  }
  protected _setSigned() {
    if (this.entityForm && this.entityForm.entity) {
      const now = (new Date()).getTime();
      const pdpSteps = Object.keys(this.entityForm.entity.implementations);
      if (pdpSteps.length) {
        let stepList = '<ul>';
        pdpSteps.forEach((step_id) => {
          const x = this.entityForm.allSteps.findIndex((s) => (s._id === step_id));
          if (x !== -1 && this.entityForm.allSteps[x]) {
            stepList += '<li>' + this.entityForm.allSteps[x].name;
            if (this.entityForm.allSteps[x].template) {
              const templatedList = this.entityForm.allSteps
                .filter((s: EntityStep) => (s.template_id === step_id && s.dateStart && s.dateStart.getTime() > now));

              if (templatedList.length) {
                stepList += '<ul>';
                templatedList.forEach((s: EntityStep) => {
                  stepList += '<li>' + s.name + '</li>';
                });
                stepList += '</ul>';
              }
            }
            stepList += '</li>';
          }
        });
        stepList += '</ul>';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          disableClose: false,
          width: '600px',
        });
        // console.log('unisgned', [...unsignedOffices, ...unsignedEexs]);
        dialogRef.componentInstance.confirmMessage = 'En validant ce nouveau plan de prévention, vous allez mettre à jour les mesures à mettre en oeuvre pour les chantiers suivants :'
          + stepList
          + 'Êtes-vous sûr de vouloir valider ce plan de prévention ?';
        dialogRef.componentInstance.buttonMessage = 'Confirmer';

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._spinnerService.show();
            const stepsSaver = [];
            const stepsLoader = [];
            pdpSteps.forEach((step_id) => {
              stepsLoader.push(this._workbookService.stepService.getCurrentOne(step_id).then((step: EntityStep) => {
                step['implementation'] = this.entityForm.entity.implementations[step_id];
                stepsSaver.push(this._workbookService.stepService.save(step));
              }));
              if (this.entityForm.entity.implementations[step_id].step && this.entityForm.entity.implementations[step_id].step.template) {
                this.entityForm.allSteps
                  .filter((s: EntityStep) => (s.template_id === step_id && s.dateStart && s.dateStart.getTime() > now))
                  .forEach((s: EntityStep) => {
                    stepsLoader.push(this._workbookService.stepService.getCurrentOne(s._id).then((templatedStep: EntityStep) => {
                      templatedStep['implementation'] = this.entityForm.entity.implementations[step_id];
                      stepsSaver.push(this._workbookService.stepService.save(templatedStep));
                    }));
                  });
              }
            });
            if (stepsLoader.length) {
              promiseAll(stepsLoader).then((la) => {
                if (stepsSaver.length) {
                  promiseAll(stepsSaver).then((sa) => {
                    this._spinnerService.hide();
                    super._setSigned();
                  }).catch((err) => {
                    this._logger.error('SignablePreventionPlanDialogComponent', 'error saving steps', JSON.stringify(err))
                    this._spinnerService.hide();
                  });
                } else {
                  this._spinnerService.hide();
                  super._setSigned();
                }
              }).catch((err) => {
                this._logger.error('SignablePreventionPlanDialogComponent', 'error loading steps', JSON.stringify(err))
                this._spinnerService.hide();
              });
            } else {
              this._spinnerService.hide();
              super._setSigned();
            }
          } else {
            this._updateEntityFromForm();
          }
        });
      }
    }
  }

}
