import { OnDestroy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonSignableComponent } from './commonSignable.component'
import {
  LoggerService,
  SignableService
} from 'app/shared';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-download',
  template: ''
})

export class WorkbookDownloadComponent extends CommonSignableComponent implements OnDestroy {
  constructor(
    protected _route: ActivatedRoute,
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _signableService: SignableService
  ) {
    super(_route, dialog, _logger, _spinnerService, _signableService);

  }
  protected _apiCall(data): Observable<any> {
    if (data['id'] && data['workbook']) {
      this._signableService.configLoaded.subscribe((res) => {
        if (res) {
          this.getWorkbookDocumentById(data['workbook'], data['id']);
        }
      });
    } else {
      return Observable.throw('no id');
    }
  }
  protected _init(data) {
    if (data) {

    }
  }
}
