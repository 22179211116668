import {Inject, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService, EntityEditDialogComponent, EntitySignable, EntitySignableService, EntityDocumentMetadata} from 'app/shared';
import {EntityPreventionPlan} from 'app/workbook-core';
import {AbstractSignableInfoComponent} from './abstractSignableInfo.component';

export abstract class AbstractSignableInfoDialogComponent extends EntityEditDialogComponent implements AfterViewInit {
  workbook: EntityPreventionPlan;
  entity: EntitySignable;
  entityForm: AbstractSignableInfoComponent;

  constructor(
    //protected _documentMetadataService: DocumentMetadataService,
    //protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    public dialogRef: MatDialogRef<AbstractSignableInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: EntitySignableService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['workbook']) {
        this.workbook = data['workbook'];
      }
      if (data['entity']) {
        this.entity = data['entity'];
      }
    }
  }
}
