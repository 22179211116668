import {Injectable, NgZone} from '@angular/core';
import {DbRemoteCommonService} from './dbRemoteCommon.service';
import {AuthService} from 'app/shared/auth/auth.service';

@Injectable()
export class DbDocsService extends DbRemoteCommonService {

  static DBCONFIG = 'couchDBDocs';

  constructor(protected authService: AuthService, protected zone: NgZone) {
    super(authService, zone);
  }
}
