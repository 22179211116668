import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  @Input() divClass: string;
  @Input() inputClass: string;
  @Input() placeholder: string;
  @Input() i18nPlaceholder: string;
  @Input() inputName: string;
  @Input() model: any;
  @Input() inputRequired: boolean;
  @Input() disableTimer: boolean = false;
  @Input() timerArchived: boolean = false;
  @Input() permission: string;

  @ViewChild('timer') timer: ElementRef;
  @ViewChild('newTimer') newTimer: ElementRef;
  @ViewChild('hour') hourSelect: ElementRef;
  @ViewChild('minute') minuteSelect: ElementRef;
  @ViewChild('time') time: ElementRef;

  @Output() changeState: EventEmitter<string> = new EventEmitter<string>();

  public hours: string;
  public minutes: string;


  @HostListener('keyup', ['$event']) onKeyDown(event: any) {
    let e;
    event.srcElement ? e = event.srcElement : e = event.target;
    if (e.maxLength === e.value.length) {
      event.preventDefault();
      let nextControl: any = e.nextElementSibling;
      // Searching for next similar control to set it focus
      while (true) {
        if (nextControl) {
          if (nextControl.type === e.type) {
            nextControl.focus();
            return;
          } else {
            nextControl = nextControl.nextElementSibling;
          }
        } else {
          return;
        }
      }
    }
  }

  constructor(protected permissionsService: PermissionsService) {}

  ngOnInit() {
    this.checkTimer();
    if (this.permission) {
      this.checkPermission();
    }
  }

  checkPermission() {
    const authorized = this.permissionsService.hasPermission(this.permission);
    if (authorized) {
      this.timerArchived ? this.disableTimer = true : this.disableTimer = false;
    } else {
      this.disableTimer = true;
    }
  }

  checkTimer() {
    if (this.time.nativeElement.type === 'text') {
      this.timer.nativeElement.style.display = 'none';
      this.newTimer.nativeElement.style.display = 'block';
      if (this.model) {
        const time = this.model.toString().split(':');
        this.hours = time[0];
        this.minutes = time[1];
      }
    } else {
      this.newTimer.nativeElement.style.display = 'none';
    }
  }

  onChangeTime() {
    const time = this.time.nativeElement.value;
    //if ( time ) {
    this.changeState.emit(time);
    //}
  }

  onChangeTimefallback() {
    const hour = this.hourSelect.nativeElement.value;
    const minute = this.minuteSelect.nativeElement.value;
    if (hour && minute) {
      const time = (hour < 10 && hour.length === 1 ? '0' + hour : hour) + ':' + (minute < 10 && minute.length === 1 ? '0' + minute : minute);
      this.changeState.emit(time);
    }
  }
}
