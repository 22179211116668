import {Injectable} from '@angular/core';
import {PermissionsService} from '../auth';
import {DbConfigService} from '../db';
import {EntitySingleService} from '../services/entitySingle.service';
import {LoadingService} from './loading.service';
import {EntityEmailMessages} from '../models';

@Injectable({
  providedIn: 'root'
})
export class EmailMessagesService extends EntitySingleService {

  autoInit = false;

  static LABEL_ONE = "chargement du message";
  static LABEL_ALL = "chargement des messages";
  static LABEL_CHILDREN = "chargement des messages de l'opération ?";
  static LABEL_SAVE = "sauvegarde du message";
  static LABEL_DELETE = "suppression du message";

  static ENTITY_TYPE: string = 'emails';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showEmails'],
    CREATE: ['createEmail', 'editEmails'],
    UPDATE: ['editEmails'],
    DELETE: []
  }

  static ENTITY_URL = 'config/emails';
  static RGPD_URL = 'config/rgpd';

  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityEmailMessages {
    return new EntityEmailMessages(data, clone);
  }

}
