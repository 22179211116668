import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {DbConfigService} from 'app/shared/db/dbConfig.service';
import {ConfigModel} from 'app/shared/models/config.model';

@Injectable()
export class ConfigService {

  constructor(protected db: DbConfigService) {
  }

  /**
   * Get the local config application
   */
  public getConfigLocal() {
    return this._getConfigLocal().toPromise() as Promise<ConfigModel>;
  }

  /**
   * Get the local config application
   * @private
   */
  private _getConfigLocal() {
    return this.db.get('config').pipe(
      map((configData: any) => new ConfigModel(configData)));
  }

  /**
   * Save config Locally on PouchDB
   * @param configData
   * @returns {Promise<any>}
   */
  public saveConfigLocal(configData) {
    return this.db.save(configData)
      .toPromise() as Promise<ConfigModel>;
  };
}
