import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {DocumentMetadataService, LoggerService} from 'app/shared';
import {IcpService, EntityWorkbook, EntityIcp, LdcmService, EntityLdcm, Ldcm, DocumentationService} from 'app/workbook-core';
import {AbstractSignableInfoDialogComponent} from '../abstractSignableInfo-dialog.component';
import {WorkbookIcpInfoComponent} from './icpInfo.component';

@Component({
  selector: 'app-workbook-icp-dialog',
  templateUrl: './icp-dialog.component.html',
  // styleUrls: ['./icp-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class WorkbookIcpDialogComponent extends AbstractSignableInfoDialogComponent {
  entityForm: WorkbookIcpInfoComponent;
  workbook: EntityWorkbook;
  selectedSteps: string[];
  entity: EntityIcp;
  duplication: boolean = false;
  defaultLldcm: Ldcm[] = [];
  protected _ldcmSubscription: Subscription;

  constructor(
    public snackBar: MatSnackBar,
    protected _documentMetadataService: DocumentMetadataService,
    protected _documentationService: DocumentationService,
    protected _spinnerService: Ng4LoadingSpinnerService,
    protected _ldcmService: LdcmService,
    public dialogRef: MatDialogRef<WorkbookIcpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _logger: LoggerService,
    protected _entityService: IcpService
  ) {
    super(_spinnerService, dialogRef, data, _logger, _entityService);
    if (data) {
      if (data['selectedSteps']) {
        this.selectedSteps = data['selectedSteps'];
      }
      if (data['duplication']) {
        this.duplication = data['duplication'];
      }
    }
    this._ldcmSubscription = this._ldcmService.getAll().subscribe((list) => {
      if (list) {
        this.defaultLldcm = list.filter((ldcm: EntityLdcm) => (!!ldcm.checked));
      }
    });

  }
  save() {
    if (this.workbook
      && this.entityForm
      && this.entityForm.entity
      && this.entityForm.entity.parent_id
      && !this.entityForm.entity._id) {
      this._entityService.getNewCode(this.entityForm.entity.parent_id).then((code) => {
        this.entityForm.entity.parent_code = this.workbook.code;
        this.entityForm.entity.code = code;
        this.entityForm.entity.name = this.entityForm.entity.getName();
        if (!this.entityForm.entity.IPLdcm) {
          this.entityForm.entity.IPLdcm = [];
        }
        this.defaultLldcm.forEach((ldcm) => {
          if (this.entityForm.entity.IPLdcm.findIndex((e) => (e.id === ldcm.id)) === -1) {
            this.entityForm.entity.IPLdcm.push(new Ldcm(ldcm));
          }
        })
        super.save();
      }).catch((error) => {
        this.snackBar.open('Erreur', 'Le code n\'a pas pu être généré, veuillez rééssayer. si le problème persiste, contactez le support', {
          duration: 2000
        });
      });
    } else {
      super.save();
    }
  }
}
