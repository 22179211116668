import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoggerService, Contact} from 'app/shared';
import {PreventionPlanService, OfficeService, EntityOffice, WorkbookOfficeService} from 'app/workbook-core';
import {WorkbookChildListComponent} from '../abstractWorkbookChildList.component';
import {WorkbookOfficeDialogComponent} from './office-dialog.component';

export class WorkbookOfficeListComponent extends WorkbookChildListComponent {
  // liste des offices du workbook
  entityList: EntityOffice[];
  // liste des offices du repo
  officeList: EntityOffice[];
  protected _officeSubscription: Subscription;
  oeexList: EntityOffice[];
  oeexListLoaded = false;
  protected _oeexSubscription: Subscription;
  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer ce service ?';
  addConfirmMessage = 'Nouveau service créé';

  constructor(
    protected _officeService: OfficeService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: PreventionPlanService

  ) {
    super(dialog, snackBar, _route, _router, _logger, _entityService);
    this._officeSubscription = this._officeService.getAll().subscribe((list) => {
      if (list) {
        this.officeList = list.sort((a, b) => a.name.localeCompare(b.name));
      }
    });

  }
  protected _entityUpdate(data) {
    super._entityUpdate(data);
    if (this.entity && this.entity._id) {
      if (this._oeexSubscription) {
        this._oeexSubscription.unsubscribe();
      }
      this._oeexSubscription = this._entityService.getOEexList(this.entity._id).subscribe((list) => {
        //this._entityService.getCurrentOEex(this.entity._id).then((list) => {
        if (list) {
          this.oeexList = list;
          this.oeexListLoaded = true;
          //rattrapage droits utilisateur
          this.updateWorkbook();
        }
      });
    }
  }
  protected _init(data) {
    super._init(data);
    this.entityList.sort((a, b) => a.name.localeCompare(b.name));
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._officeSubscription) {
      this._officeSubscription.unsubscribe();
    }
    if (this._oeexSubscription) {
      this._oeexSubscription.unsubscribe();
    }
  }

  protected _getChildService() {
    return this._entityService.officeService;
  }

  getOfficeDialog(office: EntityOffice = null) {
    const dialogRef = this.dialog.open(WorkbookOfficeDialogComponent, {
      disableClose: true,
      minWidth: '960px',
      data: {
        id: office ? office._id : '',
        parent_id: this.id ? this.id : '',
        officeList: this._getOfficeList()
      }
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c) {
        if (!office || !office._id) {
          this._afterCreateElement(c);
        }
        this.updateElement(c);
      }
    });
  }
  protected _afterCreateElement(element: EntityOffice) {
  }
  protected _getOfficeList() {
    if (this.entityList && this.officeList) {
      const alreadyAdded = this.entityList.map(e => e.repo_id).filter(e => e);
      return this.officeList.filter(e => alreadyAdded.indexOf(e._id) === -1);
    } else {
      return [];
    }
  }
  updateElement(element: EntityOffice) {
    this._entityService.activityService.updateCompanyName(element.parent_id, element._id, element.name);
    this.updateWorkbook();
    super.updateElement(element);
  }

  updateWorkbook(modified: boolean = false) {
    modified = this.updateUsers() || modified;
    if (modified) {
      this._save();
    }
  }
  updateUsers() {
    let returned = false;
    if (this.oeexListLoaded && this.oeexList) {
      const list: string[] = [];
      const office_contacts: {[oeex_name: string]: Contact[]} = {};
      const eex_contacts: {[oeex_name: string]: Contact[]} = {};
      if (this.entity && this.entity.createdId) {
        list.push(this.entity.createdId);
      }
      this.oeexList
        .filter((o) => (!o._deleted))
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((o) => {
          if (o.contacts) {
            if (o.documentType === WorkbookOfficeService.ENTITY_TYPE) {
              office_contacts[o.name] = [];
              o.contacts
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach((c) => {
                  if (c.user_id && list.indexOf(c.user_id) === -1) {
                    list.push(c.user_id);
                  }
                  office_contacts[o.name].push(Object.assign(new Contact(), c));
                });
            } else {
              eex_contacts[o.name] = [];
              o.contacts
                .sort((a, b) => a.name.localeCompare(b.name))
                .forEach((c) => {
                  if (c.user_id && list.indexOf(c.user_id) === -1) {
                    list.push(c.user_id);
                  }
                  eex_contacts[o.name].push(Object.assign(new Contact(), c));
                });
            }
          }
        });
      if (JSON.stringify(this.entity.users.sort()) !== JSON.stringify(list.sort())) {
        this.entity.users = list;
        returned = true;
      }
      if (JSON.stringify(this.entity.eex_contacts) !== JSON.stringify(eex_contacts)) {
        this.entity.eex_contacts = eex_contacts;
        returned = true;
      }
      if (JSON.stringify(this.entity.office_contacts) !== JSON.stringify(office_contacts)) {
        this.entity.office_contacts = office_contacts;
        returned = true;
      }
    }
    return returned;
  }
}
