import {Component, Inject, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {NgForm} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  PermissionsService,
  IUser
} from 'app/shared';
@Component({
  selector: 'app-user-email-dialog',
  templateUrl: './userEmail-dialog.component.html',
  styleUrls: ['./userEmail-dialog.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({opacity: 1})),
      state('out', style({opacity: 0})),
      transition('* <=> *', [
        animate(600)
      ])
    ])
  ]
})
export class UserEmailDialogComponent implements AfterViewInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('emailInput') emailInput: ElementRef;

  public user: IUser;
  public usersList: IUser[];
  public currentEmail: string;
  public duplicateEmail: boolean = false;
  public sendVerificationMail: boolean = false;
  public changePassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UserEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    protected _permissionsService: PermissionsService
  ) {
    this.user = data['user'];
    this.usersList = data['usersList'] ? data['usersList'] : [];
    if (this.user) {
      this.currentEmail = this.user ? this.user.email : '';
      this.user.email = '';
      this.sendVerificationMail = this.changePassword = !this.user['email_verified'];

    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    }, 250);
  }
  checkDuplicate(event) {
    this.duplicateEmail = this.usersList.findIndex((u) => u.email === this.user.email) !== -1;
    if (this.form && this.form.controls && this.form.controls['email'] && this.form.controls['email'].valid && this.duplicateEmail) {
      this.form.controls['email'].setErrors({'duplicate': true});
    }
  }
  /**
   * Submit form
   * @param form
   */
  onSubmit(form: NgForm) {
    if (form.valid) {
      if (this.user) {
        if (this.user['name']
          && this.user['user_metadata']
          && this.user['user_metadata']['family_name']
          && this.user['name'] !== this.user['user_metadata']['family_name']) {
          this.user['name'] = this.user['user_metadata']['family_name']
        }
        if (this.user['nickname']
          && this.user['user_metadata']
          && this.user['user_metadata']['given_name']
          && this.user['nickname'] !== this.user['user_metadata']['given_name']) {
          this.user['nickname'] = this.user['user_metadata']['given_name']
        }
        this._permissionsService.updateUserData(this.user).then((user) => {
          if (this.sendVerificationMail) {
            const sendMailPromise = this.changePassword ?
              this._permissionsService.sendConfirmAndPassword(user) :
              this._permissionsService.sendConfirm(user);
            sendMailPromise.then((u) => {
              this.dialogRef.close(user);
            });
          } else {
            this.dialogRef.close(user);
          }
        });
      }
    }
  }
}
