import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Subscription} from 'rxjs';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {LoggerService, EntitySimpleListComponent, ExcelService, ConfirmDialogComponent, sanitizeString, promiseAll} from 'app/shared';
import {SituationService, RiskService, EntitySituation, EntityRisk, MeasureService, EntityMeasure} from 'app/workbook-core';

@Component({
  selector: 'app-repository-situation-list',
  templateUrl: './situationList.component.html',
  styleUrls: ['./situationList.component.scss']
})

export class SituationListComponent extends EntitySimpleListComponent implements OnDestroy {

  entityList: EntitySituation[];

  rmConfirmMessage = 'Êtes-vous sûr de vouloir supprimer cette situation ?';
  addConfirmMessage = 'Nouvelle situation créée';


  measuresSubscriber: Subscription;
  measureIds: {[name: string]: string} = {};
  riskIds: {[name: string]: string} = {};

  riskListSubscriber: Subscription;
  riskList: EntityRisk[] = [];
  risks: {[id: string]: string} = {};
  selectedRisk: string = '';

  constructor(
    protected _measureService: MeasureService,
    protected _spinner: Ng4LoadingSpinnerService,
    protected _excelService: ExcelService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    protected _router: Router,
    protected _logger: LoggerService,
    protected _entityService: SituationService,
    protected _riskService: RiskService

  ) {
    super(dialog, snackBar, _router, _logger, _entityService);
    this.riskListSubscriber = this._riskService.getAll().subscribe((riskList) => {
      if (riskList) {
        this.riskList = riskList.sort((a, b) => (this.numalphaCompare(a.name, b.name)));
        this.riskList.forEach((risk: EntityRisk) => {
          this.risks[risk._id] = risk.name;
          this.riskIds[sanitizeString(risk.name)] = risk._id;

        });
        this.filter(this.value);
      }
    });
    this.measuresSubscriber = this._measureService.getAll().subscribe((list) => {
      if (list) {
        this.measureIds = {};
        list.forEach((measure: EntityMeasure) => {
          this.measureIds[sanitizeString(measure.name)] = measure._id;
        });
      }
    });
  }
  ngOnDestroy() {
    if (this.riskListSubscriber) {
      this.riskListSubscriber.unsubscribe();
    }
    if (this.measuresSubscriber) {
      this.measuresSubscriber.unsubscribe();
    }
  }


  addElement() {
    if (this.value && !this.valueExists && this.selectedRisk) {
      const newEntity = this._entityService.getNewEntity({name: this.value, risk_id: this.selectedRisk});
      this._entityService.save(newEntity).then(() => {
        this.updateElement(newEntity);
        //   this.snackBar('La mesure a été ajoutée');
      });
      this.value = '';
    }
  }
  /**
   * Filter measure
   * @param value
   */
  filter(value: string = '') {
    this.entityList.sort((a, b) => {
      return a.risk_id && this.risks[a.risk_id]
        ? b.risk_id && this.risks[b.risk_id]
          ? this.risks[a.risk_id].localeCompare(this.risks[b.risk_id]) === 0
            ? a.name.localeCompare(b.name)
            : this.risks[a.risk_id].localeCompare(this.risks[b.risk_id])
          : -1
        : 1
    });
    this.valueExists = false;
    if (value) {
      value = value.toLowerCase();
      let i = 0;
      this.listHidden = this.entityList.map((e) => {
        const name = e.name.toLowerCase();
        const sameRisk = !this.selectedRisk || this.selectedRisk === e.risk_id;
        this.valueExists = this.valueExists
          || (value === name && this.selectedRisk === e.risk_id);
        i++;
        return (!sameRisk || name.indexOf(value) === -1) ? 0 : i;
      });
    } else {
      this.listHidden = this.entityList.map((e, index) => (this.selectedRisk && this.selectedRisk !== e.risk_id) ? 0 : index + 1);
    }
  }
  // TODO : test in v1.1 optimization ngFor with trackBy
  public trackByFn(index, item: EntitySituation) {
    if (!item) {return null;}
    return item._id;
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this._excelService.parseExcelFile(fileInput.target.files[0]).then((list: any[]) => {
        if (list && list.length) {
          const importList: {[situation_name: string]: EntitySituation} = {};
          const importListRisk: {[situation_name: string]: string[]} = {};
          list.forEach((element) => {
            if (element['situation_name'] && element['measure_name'] && element['risk_name']
              && this.measureIds[sanitizeString(element['measure_name'])]
              && this.riskIds[sanitizeString(element['risk_name'])]) {
              const measure_id: string = this.measureIds[sanitizeString(element['measure_name'])];
              const risk_id: string = this.riskIds[sanitizeString(element['risk_name'])];
              let _rn = sanitizeString(element['situation_name']);
              if (!importList[_rn]) {
                importList[_rn] = this._entityService.getNewEntity({
                  name: _rn,
                  risk_id: risk_id,
                  measures: [measure_id]
                });
              } else {
                importList[_rn].measures.push(measure_id);
              }
            }
          });
          if (Object.keys(importList).length) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              disableClose: false,
              width: '1600px',
            });

            dialogRef.componentInstance.confirmMessage = '<p>Vous allez importer les situations suivantes :</p><ul>';
            Object.keys(importList).forEach((situation_name) => {
              dialogRef.componentInstance.confirmMessage += '<b>' + situation_name + '</b>';
              dialogRef.componentInstance.confirmMessage += '<ul>';
              importList[situation_name].measures.forEach((m) => {
                dialogRef.componentInstance.confirmMessage += '<li>' + m + '</li>';
              });
              dialogRef.componentInstance.confirmMessage += '</ul>';

            });
            dialogRef.componentInstance.confirmMessage += '</ul><p>Souhaitez vous procéder à l\'enregistrement de ces situations ?</p>';
            dialogRef.componentInstance.cancelMessage = 'Non';
            dialogRef.componentInstance.buttonMessage = 'Oui';
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this._spinner.show();
                const situationsSaver: Promise<any>[] = [];
                Object.keys(importList).forEach((situation_name) => {
                  const situation = this.entityList.find((e) => (sanitizeString(e.name) === situation_name));
                  if (situation) {

                  } else {
                    situationsSaver.push(
                      this._entityService.save(importList[situation_name])
                    );
                  }
                });

                promiseAll(situationsSaver).then((result) => {
                  this._spinner.hide();
                  this.snackBar.open('Importation terminée', '', {
                    duration: 2000
                  });
                }).catch((err) => {
                  this._logger.error('SituationList', 'import situation', JSON.stringify(err));
                  this._spinner.hide();
                  this.snackBar.open('Erreur lors de l\'importation des situations', '', {
                    duration: 2000
                  });
                });
              }
            });
          }
        }
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
      }).catch((err) => {
        if (fileInput && fileInput.srcElement && fileInput.srcElement.value) {
          fileInput.srcElement.value = '';
        }
        console.error(err);
      });
    }
  }
}
