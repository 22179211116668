import {formattedDateFile} from 'app/shared';
import {Illustrated} from '../../repository/models/entityProtocolContent.model';
import {EntityDeed} from './entityDeed.model';

export class SPState {
  static DRAFT: number = 0;
  static PREPARED: number = 1;
  static SIGNING: number = 2;
  static VALIDATED: number = 3;
  static SIGNED: number = 4;
  static VALID: number = 5;
  static OUTDATED: number = 6;
  static ARCHIVED: number = 7;

  static LABELS: string[] = [
    'Brouillon',
    'Préparé',
    'À signer',
    'Validé',
    'Signé',
    'Actif',
    'Obsolète',
    'Archivé'
  ];
  static DESCRIPTIONS: string[] = [
    'Le contenu de l\'édition est dynamique, les modifications se reportent dans le contenu de l\'édition',
    'Le contenu de l\'édition est statique, les modifications ne se reportent pas dans le contenu de l\'édition',
    'L\'edition est en cours de signature',
    'L\'edition est signée',
    'L\'edition a été signée par l\'ensemble des signataires',
    'L\'edition est valide',
    'L\'edition est obsolète',
    'L\'edition est archivée, l\'opération est archivée'
  ];

  static getLabels() {
    return SPState.LABELS;
  }
  static getDescriptions() {
    return SPState.DESCRIPTIONS;
  }
  static getLabel(status: number) {
    return SPState.LABELS[status] ? SPState.LABELS[status] : 'state' + status;
  }
  static getDescription(status: number) {
    return SPState.DESCRIPTIONS[status] ? SPState.DESCRIPTIONS[status] : 'state: ' + status;
  }
}
export class EntitySecurityProtocol extends EntityDeed {

  static PREFIX: string = 'PS';

  public loading: boolean;
  public unloading: boolean;
  public performing: string;//EU / EE

  //liste des natures de marchandise selectionnables
  public merchandiseNatures: {[name: string]: boolean};
  //liste des etats de marchandise selectionnables
  public merchandiseStates: {[name: string]: boolean};
  //liste des conditionnements de marchandise selectionnables
  public merchandisePackagings: {[name: string]: boolean};

  //liste des vehicules selectionnables
  public vehicles: {[name: string]: boolean};
  //liste des types de vehicules selectionnables
  public vehicleTypes: {[name: string]: boolean};
  //liste du materiel selectionnables
  public equipments: {[name: string]: boolean};

  //liste du materiel selectionnables
  public providedEquipments: {[name: string]: boolean};

  public warningDevices: string[];
  public interventionDevices: string[];
  public emergency: string[];

  public protectiveGears: Illustrated[];
  public interdictions: {[name: string]: boolean};
  public instructions: {[name: string]: boolean};

  public dangerousProduct: boolean;
  public productName: string;

  public productFeatures: Illustrated[];

  public specificPrecautions: string;

  public originatorInstructions: {[name: string]: boolean};
  public generalProvisions: string;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);

    this.loading = !!(data && data.loading);
    this.unloading = !!(data && data.unloading);
    this.performing = (data && data.performing && data.performing === 'EU') ? 'EU' : 'EE';

    //liste des natures de marchandise selectionnables
    this.merchandiseNatures = (data && data.merchandiseNatures) ? Object.assign({}, data.merchandiseNatures) : {};
    //liste des etats de marchandise selectionnables
    this.merchandiseStates = (data && data.merchandiseStates) ? Object.assign({}, data.merchandiseStates) : {};
    //liste des conditionnements de marchandise selectionnables
    this.merchandisePackagings = (data && data.merchandisePackagings) ? Object.assign({}, data.merchandisePackagings) : {};

    //liste des vehicules selectionnables

    this.vehicles = (data && data.vehicles) ? Object.assign({}, data.vehicles) : {};
    //liste des types de vehicules selectionnablesthis.merchandisePackaging = (data && data.merchandisePackaging) ? data.merchandisePackaging) : {};
    this.vehicleTypes = (data && data.vehicleTypes) ? Object.assign({}, data.vehicleTypes) : {};

    this.equipments = (data && data.equipments) ? Object.assign({}, data.equipments) : {};

    this.providedEquipments = (data && data.providedEquipments) ? Object.assign({}, data.providedEquipments) : {};

    this.warningDevices = (data && data.warningDevices) ? data.warningDevices.map((e) => (e)) : [];
    this.interventionDevices = (data && data.interventionDevices) ? data.interventionDevices.map((e) => (e)) : [];
    this.emergency = (data && data.emergency) ? data.emergency.map((e) => (e)) : [];


    this.protectiveGears = (data && data.protectiveGears) ? data.protectiveGears.map((e) => (Object.assign({}, e))) : [];
    this.interdictions = (data && data.interdictions) ? Object.assign({}, data.interdictions) : {};
    this.instructions = (data && data.instructions) ? Object.assign({}, data.instructions) : {};

    this.dangerousProduct = !!(data && data.dangerousProduct);
    this.productName = (data && data.productName) ? data.productName : '';


    this.productFeatures = (data && data.productFeatures) ? data.productFeatures.map((e) => (Object.assign({}, e))) : [];

    this.specificPrecautions = (data && data.specificPrecautions) ? data.specificPrecautions : '';

    this.originatorInstructions = (data && data.originatorInstructions) ? Object.assign({}, data.originatorInstructions) : {};

    this.generalProvisions = (data && data.generalProvisions) ? data.generalProvisions : '';


  }

  getTitle() {
    return "Protocole de sécurité " + this.name;
  }
  getFileName() {
    return this.name + formattedDateFile(this.updatedAt) + (this.isValid() ? ('-' + this.status) : '');
  }

}
