import {Message} from './message.model'


export class EmailConfigModel {
  provider: string;
  userId: string;
  serviceId: string;
  templateId: string;
}

export class EmailModel {
  fromName: string;
  messages: Message[];


}
