import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import {
  LoggerService,
  CommonComponent
} from 'app/shared';
import {
  EntityIcp
} from '../models/entityIcp.model';
import {
  Ldcm
} from '../models/ldcm.model';

@Component({
  selector: 'app-ic-info-export',
  templateUrl: './icInfoExport.component.html'
})
export class IcInfoExportComponent extends CommonComponent implements OnChanges {

  @Input() icp: EntityIcp;

  ldcmLocals: Ldcm[] = [];
  ldcmDocuments: Ldcm[] = [];
  ldcmEquipments: Ldcm[] = [];
  ldcmGeneralInst: Ldcm[] = [];
  constructor(protected _logger: LoggerService) {
    super(_logger);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes['icp']) {

      if (this.icp.IPLdcm) {
        this.ldcmLocals = this.icp.IPLdcm.filter((l) => l.group === 'locals');
        this.ldcmDocuments = this.icp.IPLdcm.filter((l) => l.group === 'documents');
        this.ldcmEquipments = this.icp.IPLdcm.filter((l) => l.group === 'equipment');
        this.ldcmGeneralInst = this.icp.IPLdcm.filter((l) => l.group === 'generalInst');
      } else {
        this.ldcmLocals = [];
        this.ldcmDocuments = [];
        this.ldcmEquipments = [];
        this.ldcmGeneralInst = [];
      }
    }
  }
}
