import {Component, Input, Output, EventEmitter, OnChanges, ViewChild} from '@angular/core';
import {ExtraFieldCommonComponent} from './extraFieldCommon.component'

@Component({
  selector: 'app-extra-field',
  templateUrl: './extraField.component.html',
  styleUrls: ['./extraField.component.scss']
})
export class ExtraFieldComponent extends ExtraFieldCommonComponent implements OnChanges {
  @ViewChild('field') field;

  @Output() valueChange = new EventEmitter<string | number | Date | null>();
  @Input() disabled: boolean = false;
  required: boolean = false;
  txtType: string = 'text';//'text'|'email'|'number'|'tel'|'url'
  txtPattern: string;
  numberStep: number = null;
  numberMin: number = null;
  numberMax: number = null;

  dateError: string = '';
  @Input() error: string = '';
  @Output() errorChange = new EventEmitter<string>();

  init() {
    super.init();
    if (this.extraField) {
      if (this.extraField.required) {
        this.required = true;
      }
      if (this.extraField.type === 'text' && this.extraField.options) {
        if (this.extraField.options && this.extraField.options.pattern) {
          this.txtPattern = this.extraField.options.pattern as string;
        }
        if (this.extraField.options && this.extraField.options.type) {
          this.txtType = this.extraField.options.type as string;
        }
      }
      if (this.extraField.type === 'number') {
        if (this.extraField.options && this.extraField.options.min) {
          this.numberMin = this.extraField.options.min as number;
        }
        if (this.extraField.options && this.extraField.options.max) {
          this.numberMax = this.extraField.options.max as number;
        }
        if (this.extraField.options && this.extraField.options.step) {
          this.numberStep = this.extraField.options.step as number;
        }
      }
      if (!this.value && this.extraField.options && this.extraField.options.default) {
        if (this.extraField.type === 'radio'
          || (this.extraField.type === 'text' && this.extraField.required)) {
          this.value = this.extraField.options.default as string;
        } else if (this.extraField.type === 'number' && this.extraField.required) {
          this.value = this.extraField.options.default as number;
        } else if (this.extraField.type === 'checkbox') {
          this.value = !!this.extraField.options.default ? 1 : 0;
        }
        this.updateChanges(false);
      }
      setTimeout(() => {this.checkError();});
    }
  }
  updateChanges(init: boolean = true) {
    this.valueChange.emit(this.value);
    this.checkError();
    if (init) {
      this.init();
    }
  }

  checkError() {
    if (this.field && this.field.nativeElement
      && ((this.field.nativeElement.validationMessage && this.error !== this.field.nativeElement.validationMessage)
        || (!this.field.nativeElement.validationMessage && !!this.error)
        || (this.extraField.required && !this.value))) {
      const error = this.field.nativeElement.validationMessage ? this.field.nativeElement.validationMessage : this.value ? '' : 'Veuillez renseigner ce champ';
      if (error !== this.error) {
        this.error = error;
        this.errorChange.emit(this.error);
      }
    } else if (!!this.dateError) {
      this.error = this.dateError;
      this.errorChange.emit(this.error);
    }
  }
}