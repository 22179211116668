import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, getBase64, EntityFormComponent} from 'app/shared';
import {ConfigServerService} from 'app/shared/services';
import {EntityConfigServer} from 'app/shared/models';

@Component({
  selector: 'app-config-server',
  templateUrl: './configServer.component.html',
  styleUrls: ['./configServer.component.scss']
})
export class ConfigServerComponent extends EntityFormComponent {

  entity: EntityConfigServer;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _logger: LoggerService,
    protected _entityService: ConfigServerService

  ) {
    super(dialog, _logger, _entityService);
  }

}
