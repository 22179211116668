import {Subscription, BehaviorSubject, Observable, empty} from 'rxjs';

import {mergeMap, map} from 'rxjs/operators';
import {DbCommonService} from '../db/dbCommon.service';
import {LoadingService} from './loading.service';
import {EntityBaseService} from './entityBase.service';
import {Entity} from '../models/entity.model';

export abstract class EntityService extends EntityBaseService {
  autoInit: boolean = true;
  initializing: boolean = false;
  initialized: boolean = false;
  //serviceName = '';
  static LABEL_ONE = "chargement de l'élément";
  static LABEL_ALL = "chargement des éléments";
  static LABEL_CHILDREN = "chargement des éléments liés";
  static LABEL_SAVE = "sauvegarde de l'élément";
  static LABEL_DELETE = "suppression de l'élément";

  // liste complete
  protected _entityList: BehaviorSubject<any[]> = new BehaviorSubject(null);
  protected _changeEntityListSubscription: Subscription;
  protected _loadedChildrenRemoteSubscription: Subscription;

  // tableau des entités "demandées"
  protected _entity: {[id: string]: BehaviorSubject<any>} = {};

  protected _childrenList: {[parent_id: string]: BehaviorSubject<any[]>} = {};
  //protected _changeChildrenListSubscription: {[parent_id: string]: Subscription} = {};
  //outOfSyncChildren: { [parent_id: string]: boolean } = {};
  syncStateChange: BehaviorSubject<number>;
  protected _syncStateChangeSubscriptions: {[parent_id: string]: Subscription} = {};
  protected _remoteChildrenLoaded: {[parent_id: string]: boolean} = {};
  constructor(
    protected _dbService: DbCommonService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService);
    this.syncStateChange = this._dbService.syncStateChange;
    //   if (this.autoInit) {
    setTimeout(() => {
      this.init();
    });
    // }
  }

  init(force = false) {
    //console.debug('init ' + this.getEntityType(), [this.autoInit, this.onlyChildren]);
    this.subscribeChangesEntityList();
    this.subscribeSyncStateChange();
    if (this.onlyChildren) {
      this.subscribeLoadedChildrenRemote();
    }
  }
  protected subscribeChangesEntityList() {
    if (!this._changeEntityListSubscription) {
      const _that = this;
      let _lid;
      //console.debug('subscribeChangesEntityList call ' + '(' + this.getEntityType() + ')');
      if (this.autoInit) {
        if (this.isEntitySingle()) {
          //console.debug('subscribeChangesEntityList load single ' + '(' + this.getEntityType() + ')');
          this.getSingleOne();
        } else if (!this.onlyChildren) {
          //console.debug('subscribeChangesEntityList load all ' + '(' + this.getEntityType() + ')');
          this.initializing = true;
          _lid = _that._loadingService.addLoad(this._getLabelAll());
        }
      }
      this._changeEntityListSubscription = this.getChangesEntityListObserver().subscribe((list) => {
        console.log('subscribeChangesEntityList result ' + _that.getEntityType() + ' : ' + list.length, [this.autoInit, this.initialized]);
        if (this.autoInit && !this.isEntitySingle() && !_that.onlyChildren) {
          //console.debug('subscribeChangesEntityList loaded all ' + '(' + this.getEntityType() + ')');
          //pour onlychildren, toujours null
          if (list) {
            _that._setEntityList(list);
          }
          _that._loadingService.removeLoad(_lid);
          _that.initializing = false;
          _that.initialized = true;
          //this._entityList.complete();
        }
      });
    }
  }

  subscribeSyncStateChange() {
    if (!this._syncStateChangeSubscriptions['all'] && !this.onlyChildren && !this._entitySingle) {
      const _that = this;
      this._syncStateChangeSubscriptions['all'] = this._dbService.syncStateChange.subscribe((syncComplete) => {
        if (syncComplete === 0 && (this.autoInit || this.initialized || this.initializing)) {
          //console.debug('subscribeSyncStateChange syncing complete call ' + '(' + this.getEntityType() + ')', [this.autoInit, this.initialized]);
          this.initializing = true;
          let _lid = _that._loadingService.addLoad(this._getLabelAll() + ' (syncState)');
          _that.getList().toPromise().then((list) => {
            if (_that._entityList && list) {
              //console.debug('subscribeSyncStateChange syncing complete result ' + '(' + this.getEntityType() + ')');
              _that._setEntityList(list);
            }
          }).catch((err) => {
            console.error('error subscribeSyncStateChange syncing complete result ', err);
          }).finally(() => {
            _that._loadingService.removeLoad(_lid);
            _that.initializing = false;
            _that.initialized = true;
          });
        }
        //else {
        //console.log('subscribeSyncStateChange syncing complete not called ' + '(' + this.getEntityType() + ')', [syncComplete, this.autoInit, this.initializing, this.initialized]);
        //}
      });
    }
    //else {
    //  console.log('no subscribeSyncStateChange ' + '(' + this.getEntityType() + ')', [!this._syncStateChangeSubscriptions['all'], !this.onlyChildren, !this._entitySingle]);
    //}
  }

  getSyncStateChange() {
    return this._dbService.syncStateChange;
  }
  // toutes les entité avec parent_id = ''
  getAll(): BehaviorSubject<any[]> {
    if (!this.autoInit && !this.initializing && !this.initialized) {
      //console.debug('getAll init ' + '(' + this.getEntityType() + ')');
      this.initializing = true;
      let _lid = this._loadingService.addLoad(this._getLabelAll());
      this.getList().toPromise().then((list) => {
        if (this._entityList && list) {
          //console.debug('getAll init result ' + '(' + this.getEntityType() + ')');
          this._setEntityList(list);
        }
      }).catch((err) => {
        console.error('error getAll init result ', err);
      }).finally(() => {
        this._loadingService.removeLoad(_lid);
        this.initializing = false;
        this.initialized = true;
      });
    }
    return this._entityList;
  }

  getObjectRemote(id): Observable<any> {
    return this._dbService.getRemote(id);
  }
  getRemote(id): Observable<any> {
    return this.getObjectRemote(id)
      .pipe(map(data => this.getNewEntity(data)));
  }

  getAllRemote(): Observable<any> {
    return this._dbService.getList(this.getEntityTypes(), this.getEntityFields())
      .pipe(map(list => list.map(data => this.factory(data))))
  }
  getAllChildrenRemote(): Observable<any> {
    return this._dbService.getListRemote('ALL_CHILDREN', this.getEntityTypes(), this.getEntityFields())
      .pipe(map(list => list.map(data => this.factory(data))))
  }

  getOne(id: string): BehaviorSubject<any> {
    if (!this._entity[id]) {
      let _lid = this._loadingService.addLoad(this._getLabelOne());
      //this._entity[id] = this.get(id);
      this._entity[id] = new BehaviorSubject(null);
      this.get(id).toPromise().then((e) => {
        this._loadingService.removeLoad(_lid);
        if (e && this._entity[id]) {
          //TODO => !InSync / outOfSync
          this._entity[id].next(e);
        }
      }).catch((err) => {
        console.error('error getOne ' + id, err);
        this._loadingService.removeLoad(_lid);
      });
    } else if (this._entity[id].value && this._entity[id].value['parent_id']
      && (this.isOutOfSync(this._entity[id].value['parent_id']) || this.isSyncing(this._entity[id].value['parent_id']))) {
      this.loadChildrenRemote(this._entity[id].value['parent_id'])
        .catch((err) => {
          console.error('error loadChildrenRemote ' + this._entity[id].value['parent_id'], err);
        });
    }
    return this._entity[id];
  }
  getCurrentOne(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getOne(id).subscribe((data) => {
        if (data) {
          resolve(data);
        }
      }, (err) => {
        reject(err);
      });
    });


  }
  getCurrentChildren(parent_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getChildren(parent_id).subscribe((data) => {
        if (data) {
          resolve(data);
        }
      }, (err) => {
        reject(err);
      });
    });
  }
  refreshOne(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let _lid = this._loadingService.addLoad(this._getLabelOne());
      this.get(id).toPromise()
        .then((e) => {
          this._loadingService.removeLoad(_lid);
          if (!this._entity[id]) {
            this._entity[id] = new BehaviorSubject(e);
          } else {
            this._entity[id].next(e);
          }
          resolve(e);
        })
        .catch((err) => {
          console.error('error refreshOne ' + this.getEntityTypes() + ' : ' + id);
          this._loadingService.removeLoad(_lid);
          reject(err);
        });
    });
  }
  getSingleOne(): BehaviorSubject<any> {
    return this.getSingleOneByType(this.getEntityType());
  }
  getSingleOneByType(docType: string): BehaviorSubject<any> {
    if (!this._entity[docType]) {
      let _lid = this._loadingService.addLoad(this._getLabelOne());
      this._entity[docType] = new BehaviorSubject(null);
      this._getSingle(docType).toPromise().then((e) => {
        this._loadingService.removeLoad(_lid);
        if (this._entity[docType]) {
          this._entity[docType].next(e);
        }
      }).catch((err) => {
        console.error('error getSingleOneByType ' + this.getEntityTypes() + ' : ' + docType);
        this._loadingService.removeLoad(_lid);
      });
    }
    return this._entity[docType];
  }

  loadChildrenRemote(parent_id: string): Promise<any[] | null> {
    //this._outOfSyncChildren[parent_id] = this.isOutOfSync(parent_id);
    return this._dbService.loadChildrenRemote(parent_id);
  }

  getChildListRemote(parent_id: string): Observable<any[]> {
    return this._dbService.getListRemote(parent_id, this._entityTypes, this._entityFields)
      .pipe(map(list => list.map(data => this.factory(data))))

  }

  getChildren(parent_id: string): BehaviorSubject<any[]> {
    const _that = this;
    let _lid = this._loadingService.addLoad(this._getLabelChildren(), parent_id);
    if (!this._childrenList[parent_id]) {
      this._childrenList[parent_id] = new BehaviorSubject(null);
      if (this.isOutOfSync(parent_id)) {
        //console.debug('getChildren outOfSync ' + parent_id + '(' + this.getEntityTypes() + ')');
        //non synchronisé
        this.loadChildrenRemote(parent_id).then((res) => {
          _that._loadingService.removeLoad(_lid);
        }).catch((err) => {
          console.error('error loadChildrenRemote ' + parent_id, err);
          _that._loadingService.removeLoad(_lid);
        });
      } else {
        if (this.isSyncing(parent_id)) {
          //console.debug('getChildren syncing ' + parent_id + '(' + this.getEntityTypes() + ')');
          //en cours de synchro
          this.loadChildrenRemote(parent_id).then((res) => {
            //console.debug('getChildren loadChildrenRemote ' + parent_id + '(' + this.getEntityTypes() + ')');
            _that._loadingService.removeLoad(_lid);
          }).catch((err) => {
            console.error('error loadChildrenRemote ' + parent_id, err);
            _that._loadingService.removeLoad(_lid);
          });
          if (!_that._syncStateChangeSubscriptions[parent_id]) {
            //console.log('getChildren syncing waiting for complete ' + parent_id + '(' + this.getEntityTypes() + ')');
            _that._syncStateChangeSubscriptions[parent_id] = _that._dbService.syncStateChange.subscribe((syncComplete) => {
              if (syncComplete === 0) {
                //console.log('getChildren syncing complete call ' + parent_id + '(' + this.getEntityTypes() + ')');
                this.getChildList(parent_id).toPromise().then((children) => {
                  if (_that._childrenList[parent_id] && children) {
                    //console.log('getChildren syncing complete result ' + parent_id + '(' + this.getEntityTypes() + ')');
                    _that._childrenList[parent_id].next(children);
                    //_that._loadingService.removeLoad(_lid);
                  }
                }).finally(() => {
                  _that._loadingService.removeLoad(_lid);
                });;
              }
            });
          }

        } else {
          //synchro
          //console.log('getChildren call ' + parent_id + '(' + this.getEntityTypes() + ')');
          this.getChildList(parent_id).toPromise().then((children) => {
            if (_that._childrenList[parent_id] && children) {
              //console.log('getChildren result ' + parent_id + '(' + this.getEntityTypes() + ')');
              _that._childrenList[parent_id].next(children);
            }
          }).finally(() => {
            _that._loadingService.removeLoad(_lid);
          });
          if (this._syncStateChangeSubscriptions[parent_id] && !this.isSyncing(parent_id)) {
            //_that._loadingService.removeLoad(_lid);
            this._syncStateChangeSubscriptions[parent_id].unsubscribe();
            delete this._syncStateChangeSubscriptions[parent_id];
            //console.log('getChildren(2) outOfSync (unsubscribe syncComplete) ' + parent_id + '(' + this.getEntityTypes() + ')');
          }
        }
      }
    } else if (this.isOutOfSync(parent_id)) {

      //console.debug('getChildren outOfSync (2) ' + parent_id + '(' + this.getEntityTypes() + ')');
      this.loadChildrenRemote(parent_id)
        .finally(() => {
          _that._loadingService.removeLoad(_lid);
        });
    } else {
      _that._loadingService.removeLoad(_lid);
    }
    return this._childrenList[parent_id];
  }
  detach(entity: Entity) {
    if (entity && entity._id) {
      if (this.isEntitySingle() && this._entity[entity.documentType]) {
        delete this._entity[entity.documentType];
      } else if (this._entity[entity._id]) {
        delete this._entity[entity._id];
      }
      if (this._childrenList[entity._id]) {

      }
    }
  }

  protected subscribeLoadedChildrenRemote() {
    if (!this._loadedChildrenRemoteSubscription) {
      this._loadedChildrenRemoteSubscription = this._dbService.loadedChildrenRemote.asObservable()
        .subscribe((loadedChildren) => {
          if (loadedChildren && loadedChildren['parent_id'] && loadedChildren['docs']) {// && (this.isOutOfSync(loadedChildren['parent_id']) || this.isSyncing(loadedChildren['parent_id']))) {
            this.updateLoadedChildrenCache(
              loadedChildren['parent_id'],
              loadedChildren['docs']
                .filter((e) => (e._id && e._rev && e.documentType && !e._deleted && this.getEntityTypes().indexOf(e.documentType) !== -1))
                .map(data => this.factory(data)));
          }
        });
    }

  }
  public updateLoadedChildrenCache(parent_id: string, docs: Entity[]) {
    const list = [];
    docs.forEach((doc) => {
      if (doc._deleted && this._entity[doc._id]) {
        delete this._entity[doc._id];
      } else {
        list.push(doc);
        if (!this._entity[doc._id]) {
          //console.log('updateLoadedChildrenCache create this._entity[' + doc._id + ']', doc);
          this._entity[doc._id] = new BehaviorSubject(doc);
        } else if (!this._entity[doc._id].value || !this._entity[doc._id].value._rev || this._entity[doc._id].value._rev !== doc._rev) {
          //console.log('updateLoadedChildrenCache update this._entity[' + doc._id + ']', doc);
          this._entity[doc._id].next(doc);
        }
      }
    });
    if (this._childrenList[parent_id]) {
      //console.log('updateLoadedChildrenCache childrenList[' + parent_id + ']', list);
      this._childrenList[parent_id].next(list);
    } else {
      this._childrenList[parent_id] = new BehaviorSubject(list);
    }
    this._remoteChildrenLoaded[parent_id] = true;
  }
  public updateCache(docs: Entity[]) {
    //true si liste vide = initialisation
    let affectedList: boolean = !docs.length;
    const deletedList: Entity[] = docs.filter((e) => (e._deleted));
    //   console.info('updateCache:' + docs.filter((e) => (e._id && e.documentType && !e._deleted && this.getEntityTypes().indexOf(e.documentType) !== -1)).length + '/' + docs.length, this.getEntityTypes());
    docs = docs.filter((e) => (e._id && e.documentType && !e._deleted && this.getEntityTypes().indexOf(e.documentType) !== -1));
    if (docs && docs.length) {
      //const parents: string[] = [];
      affectedList = true;
      docs
        /* .filter((e) => {
         // console.log('test', [e, this.getEntityTypes(), this.getEntityTypes().indexOf(e.documentType)]);
         return (e._id && e.documentType && !e._deleted && this.getEntityTypes().indexOf(e.documentType) !== -1);
       })*/
        .forEach((doc: Entity) => {
          //console.info('updateCache modified:', doc);
          this._refreshEntity(doc);
          // if (doc.parent_id && parents.indexOf(doc.parent_id) === -1) {
          //   this._refreshChildrenList(doc.parent_id);
          // }
        });
    }
    if (deletedList && deletedList.length) {
      deletedList.forEach((doc: Entity) => {
        if (!doc.documentType) {
          this.getDeleted(doc).toPromise()
            .then((deletedDoc: Entity) => {
              if (deletedDoc && deletedDoc.documentType && this.getEntityTypes().indexOf(deletedDoc.documentType) !== -1) {
                //console.info('updateCache unknown deleted:', deletedDoc)
                //envoi du deleted complet => à traiter dans les components
                this._refreshEntity(deletedDoc);
                /*
                if (!affectedList) {
                  let _lid = this._loadingService.addLoad(this._getLabelAll());
                  //finalement mettre à jour la liste
                  this.getList().toPromise().then((l) => {
                    this._loadingService.removeLoad(_lid);
                    this._setEntityList(l);
                  });
                }
                */
                //    if (deletedDoc.parent_id) {
                //      this._refreshChildrenList(deletedDoc.parent_id);
                //    }
              }
            }).catch((err) => {
              console.error('EntityService - get deleted (' + this.getEntityTypes() + ')', [doc, err]);
            });
        } else {
          const affected = this.getEntityTypes().indexOf(doc.documentType) !== -1
          affectedList = affectedList || affected;
          if (affected) {
            //console.info('updateCacheknown deleted:', doc);
            this._refreshEntity(doc);
            // if (doc.parent_id) {
            //   this._refreshChildrenList(doc.parent_id);
            // }
          }
        }
      });
    }
    if (affectedList && docs.length) {
      //console.debug('updateCache ' + this.getEntityType(), docs);
    }
    return affectedList;

  }
  protected getChangesEntityListObserver() {
    return this._dbService.changes
      .pipe(mergeMap((docs: Entity[]) => (this.updateCacheFromChange(docs))));
  }
  updateCacheFromChange(docs: Entity[]) {
    const upd = this.updateCache(docs);
    //console.debug('updateCacheFromChange ' + this.getEntityType(), docs);
    return (this.autoInit && !this.initialized && !this.isEntitySingle() && !this.onlyChildren) ? this.getList() : empty();
  }
  save(entity: Entity) {
    return super.save(entity).then((e) => {
      this.updateCache([entity]);
      return e;
    }) as Promise<any>;
  };
  remove(entity: Entity) {
    return super.remove(entity).then((e) => {
      entity._deleted = true;
      if (e['rev']) {
        entity._rev = e['rev'];
      }
      this.updateCache([entity]);
      return e;
    });
  }
  protected _refreshEntity(doc: Entity) {
    if (doc && doc.documentType && this.isEntitySingle()) {
      //cas single (cache par documenttype)
      if (this._entity[doc.documentType]) {
        if (this._entity[doc.documentType].value
          && doc && doc._rev
          && (!this._entity[doc.documentType].value._id || (this._entity[doc.documentType].value._rev && this._entity[doc.documentType].value._rev !== doc._rev))) {
          const _doc = this.getNewEntity(doc);
          //console.info(this.getEntityTypes() + '_refreshEntity _getSingle', [this._entity[doc.documentType].value, _doc]);
          this._entity[doc.documentType].next(_doc);
        } else {
          //console.info(this.getEntityTypes() + '_refreshEntity _getSingle no change', [this._entity[doc.documentType].value, doc]);
        }
      } else {
        //console.log(this.serviceName + ' _refreshEntity _getSingle create unused cache ?', doc);
        this._entity[doc.documentType] = new BehaviorSubject(doc);
      }
    } else {
      //cache entity
      if (this._entity[doc._id] && this._entity[doc._id].value && this._entity[doc._id].value._rev
        && doc && doc._rev
        //la revision la plus haute => conflit ?
        && this._entity[doc._id].value._rev !== doc._rev) {
        const _doc = this.getNewEntity(doc);
        //console.info(this.getEntityTypes() + '_refreshEntity entity', [this._entity[doc._id].value, _doc]);
        this._entity[doc._id].next(_doc);
      } else {
        //console.log('pas de maj cache _entity[' + doc._id + ']:' + ((this._entity[doc._id] && this._entity[doc._id].value) ? this._entity[doc._id].value['_rev'] : 'unknown') + '<' + doc._rev, doc);
      }
      //children
      if (doc.parent_id && this._childrenList[doc.parent_id] && this._childrenList[doc.parent_id].value) {
        const i = this._childrenList[doc.parent_id].value.findIndex((e) => (doc._id === e._id));
        if (i === -1) {
          if (!doc._deleted) {
            const val = this._childrenList[doc.parent_id].value.map((e) => (e));
            val.push(this.getNewEntity(doc));
            //console.info(this.getEntityTypes() + '_refreshEntity _childrenList add', [doc, this._childrenList[doc.parent_id].value, val]);
            this._childrenList[doc.parent_id].next(val);
          }  //sinon deleted d'un inconnu => rien à faire
        } else
          if (doc._deleted || !(doc._rev && this._childrenList[doc.parent_id].value[i]['_rev']) || this._childrenList[doc.parent_id].value[i]['_rev'] !== doc._rev) {
            if (doc._deleted) {
              const val = this._childrenList[doc.parent_id].value.filter((e) => (e._id !== doc._id));
              //console.info(this.getEntityTypes() + '_refreshEntity _childrenList delete', [doc, this._childrenList[doc.parent_id].value, val]);
              this._childrenList[doc.parent_id].next(val);
            } else {
              const val = this._childrenList[doc.parent_id].value.map((e) => ((e._id === doc._id) ? this.getNewEntity(doc) : e));
              //console.info(this.getEntityTypes() + '_refreshEntity _childrenList update', [doc, this._childrenList[doc.parent_id].value, val]);
              this._childrenList[doc.parent_id].next(val);
            }
          } else {
            //console.log('pas de maj cache _childrenList[' + doc.parent_id + ']:' + this._childrenList[doc.parent_id].value[i]['_rev'] + '!==' + doc._rev, doc);
          }
      }
      //list
      if (!this.onlyChildren && !doc.parent_id && this._entityList && this._entityList.value) {
        const i = this._entityList.value.findIndex((e) => (doc._id === e._id));
        if (i === -1) {
          if (!doc._deleted) {
            const val = this._entityList.value.map((e) => (e));
            val.push(this.getNewEntity(doc));
            //console.info(this.getEntityTypes() + '_refreshEntity _entityList add', [doc, this._entityList.value, val]);
            this._setEntityList(val);
          }  //sinon deleted d'un inconnu => rien à faire
        } else
          if (doc._deleted || !(doc._rev && this._entityList.value[i]['_rev']) || this._entityList.value[i]['_rev'] !== doc._rev) {
            if (doc._deleted) {
              const val = this._entityList.value.filter((e) => (e._id !== doc._id));
              //console.info(this.getEntityTypes() + '_refreshEntity _entityList delete', [doc, this._entityList.value, val]);
              this._setEntityList(val);
            } else {
              const val = this._entityList.value.map((e) => ((e._id === doc._id) ? this.getNewEntity(doc) : e));
              //console.info(this.getEntityTypes() + '_refreshEntity _entityList update', [doc, this._entityList.value, val]);
              this._setEntityList(val);
            }
          } else {
            //console.log('pas de maj cache _entityList:' + this._entityList.value[i]['_rev'] + '!==' + doc._rev, doc);
          }
      }

    }
  }
  _setEntityList(val) {
    //console.debug('_setEntityList ' + this.getEntityType(), val ? val.length : 'vide');
    this._entityList.next(val);
  }
  /*
    protected _refreshChildrenList(parent_id: string) {
      if (this._childrenList[parent_id]) {
        console.log('_refreshChildrenList getChildList', parent_id);
        let _lid = this._loadingService.addLoad(this._getLabelChildren());
        this.getChildList(parent_id).toPromise().then((children) => {
          this._loadingService.removeLoad(_lid);
          if (this._childrenList[parent_id]) {
            this._childrenList[parent_id].next(children);
          }
        }).catch((error) => {
          console.log('_refreshChildrenList getChildList error', [error, parent_id]);
        });
      }
    }
    */
  /**
    * Resync PouchDB (used in conflit)
    */
  reSyncDb() {
    return this._dbService.reSyncDb() as Promise<any>;
  }

  //non synchronisé
  isOffline() {
    return this._loadingService.offline.value;
  }
  //non synchronisé
  isOutOfSync(parent_id: string) {
    return this._dbService.isOutOfSync(parent_id);
  }
  //à synchroniser
  isInSync(parent_id: string) {
    return this._dbService.isInSync(parent_id);
  }
  //synchronisé
  isSynced(parent_id: string) {
    return this._dbService.isSynced(parent_id);
  }
  //en cours de synchro
  isSyncing(parent_id: string) {
    return this._dbService.isSyncing(parent_id);
  }
  protected _getLabel(txt: string) {
    if (txt.indexOf('?') !== -1) {
      console.warn('inutile (' + this.getEntityTypes() + ') :' + txt)
    }
    return txt;
  }
  protected _getLabelOne() {
    return this._getLabel((<typeof EntityService> this.constructor).LABEL_ONE);
  }
  protected _getLabelAll() {
    return this._getLabel((<typeof EntityService> this.constructor).LABEL_ALL);
  }
  protected _getLabelChildren() {
    return this._getLabel((<typeof EntityService> this.constructor).LABEL_CHILDREN);
  }
  protected _getLabelSave() {
    return this._getLabel((<typeof EntityService> this.constructor).LABEL_SAVE);
  }
  protected _getLabelDelete() {
    return this._getLabel((<typeof EntityService> this.constructor).LABEL_DELETE);
  }
}
