import {Injectable} from '@angular/core';
import {PermissionsService} from 'app/shared/auth';
import {DbConfigService} from 'app/shared/db';
import {Entity} from 'app/shared/models/entity.model';
import {EntitySingleService} from 'app/shared/services/entitySingle.service';
import {LoadingService} from './loading.service';
import {EntityExploitingCompany} from 'app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class ExploitingCompanyService extends EntitySingleService {

  static LABEL_ONE = "chargement de l'entreprise utilisatrice";
  static LABEL_ALL = "chargement des l'entreprise utilisatrice ?";
  static LABEL_CHILDREN = "chargement des l'entreprises utilisatrices de l'opération ?";
  static LABEL_SAVE = "sauvegarde de l'entreprise utilisatrice";
  static LABEL_DELETE = "suppression de l'entreprise utilisatrice";

  static ENTITY_TYPE: string = 'company';

  // droits:
  static ENTITY_PERMISSION = {
    READ: ['showConfigs', 'showEE'],
    CREATE: ['createEE'],
    UPDATE: ['editEE'],
    DELETE: []
  }

  static ENTITY_URL = 'config/company';


  constructor(
    protected _dbService: DbConfigService,
    protected _permissionsService: PermissionsService,
    protected _loadingService: LoadingService
  ) {
    super(_dbService, _permissionsService, _loadingService);
  }
  factory(data, clone: boolean = false): EntityExploitingCompany {
    return new EntityExploitingCompany(data, clone);

  }
  protected _refreshEntity(doc: Entity) {
    super._refreshEntity(doc);
    this._permissionsService.email.updateCompany(doc);
  }

}
