import {LOCALE_ID, Component, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {DateTimeCommonComponent} from './datetimeCommon.component';

@Component({
  selector: 'app-date',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [
    {provide: LOCALE_ID, useValue: "fr-FR"}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateComponent extends DateTimeCommonComponent {

  type = 'date';
  displayFormat = "dd/MM/yyyy";
  pickerType = 'calendar';


  constructor(protected _cd: ChangeDetectorRef) {
    super(_cd);
  }
}