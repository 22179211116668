import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DbReadService} from 'app/shared/db/dbRead.service';
import {storageGet, storageSet, storageRemove} from 'app/shared/utils';

export function configServiceFactory(configService: Auth0ConfigService): Function {
  return () => configService.load();
}

@Injectable()
export class Auth0ConfigService extends DbReadService {

  protected config: any = null;
  protected configName: string = null;
  protected dev_mode: boolean = false;
  private dbConfig = {
    host: '535cf543-5df9-45f2-a72d-43a348044c0c-bluemix.cloudant.com',
    name: 'configs'
  };

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  /**
   * Display logs in development mode
   * @param text
   * @param obj
   */
  protected logDevMode(text: string, obj: any = null) {
    if (this.dev_mode) {
      obj ? console.log(text, obj) : console.log(text);
    }
  }

  /**
   * Set log dev mode
   */
  protected setLogDevMode() {
    this.config.dev_mode ? this.dev_mode = this.config.dev_mode : this.dev_mode = false;
  }

  /**
   * Get the domain name based on url and load the init config for auth0
   * DEPRECIATED IN v1.1
   */
  // load(): Promise<any> {
  //
  //   return new Promise((resolve, reject) => {
  //
  //     // TODO : in version 1.x, add domain crypted in readable config
  //     const hostname = window.location.hostname;
  //     let subdomain = null;
  //     if (this.isSubdomain(hostname)) {
  //       subdomain = hostname.split('.')[0];
  //     }
  //     this.configName = subdomain ? subdomain : 'demo';
  //     const localConfig = storageGet(`config-${this.configName}`);
  //
  //     if (!localConfig) {
  //       this.logDevMode('retrieving remoteConfig');
  //       this.getConfig().then((data: any) => {
  //         this.config = data;
  //         this.config.dev_mode ? this.dev_mode = this.config.dev_mode : this.dev_mode = false;
  //         this.logDevMode('load new config', this.config);
  //         this.setLicences();
  //         let configName = null;
  //         this.config.domain ? configName = this.config.domain.split('.')[0] : configName = this.configName;
  //         storageSet(`config-${configName}`, this.config);
  //         resolve(this.config);
  //       })
  //         .catch((err: any) => {
  //           console.error('remoteConfig not found', err);
  //           reject();
  //         });
  //     } else {
  //       // TODO : not work in v1.0 due to localstorage clear with logout
  //       this.config = localConfig;
  //       this.config.dev_mode ? this.dev_mode = this.config.dev_mode : this.dev_mode = false;
  //       this.logDevMode('localConfig found', localConfig);
  //       this.setLicences();
  //       resolve(this.config);
  //     }
  //   });
  // }

  /**
   *  Get the domain name based on url and load the init config for auth0
   */
  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      const hostname = window.location.hostname;
      let subDomain = null;
      if (this.isSubdomain(hostname)) {
        subDomain = hostname.split('.')[0];
      }
      this.configName = subDomain ? subDomain : 'demo';

      this.getConfig().then((data: any) => {
        // TODO : in version 1.x, add datas crypted in readable config
        this.config = data;
        this.setLogDevMode();
        this.logDevMode('Retrieving remoteConfig');
        this.setLicences();
        this.setVersion();
        let configName = this.config.domain ? this.config.domain.split('.')[0] : this.configName;
        this.logDevMode('Load new config ' + configName, this.config);
        storageSet(`config-${configName}`, this.config);
        resolve(this.config);
      })
        .catch((err: any) => {
          // Offline mode
          const localConfig = storageGet(`config-${this.configName}`);
          if (localConfig) {
            this.config = localConfig;
            this.setLogDevMode();
            this.logDevMode('Local config found', localConfig);
            this.setLicences();
            this.setVersion();
            resolve(this.config);
          } else {
            console.error('Remote and local config not found, try DB', err);
            this.getConfigFromDB().then((data: any) => {
              this.config = data;
              this.setLogDevMode();
              this.logDevMode('Retrieving remoteConfig');
              this.setLicences();
              this.setVersion();
              let configName = this.config.domain ? this.config.domain.split('.')[0] : this.configName;
              this.logDevMode('Load new config ' + configName, this.config);
              storageSet(`config-${configName}`, this.config);
              resolve(this.config);
            })
              .catch((err: any) => {
                reject(err);
              });
          }
        });
    });
  }

  /**
   * Get licence data
   */
  protected setLicences() {
    const licence = this.config.licence;
    this.logDevMode('load licence from config', licence);
    storageSet(`licence`, licence.modules);
  }

  /**
   * Get licence data
   */
  protected setVersion() {
    const version = this.config.version;
    this.logDevMode('load app version from config', version);
    storageSet(`version`, version);
  }

  /**
   * Get config data
   */
  protected getConfigFromDB() {
    this.connect(this.dbConfig);
    return this.get(this.configName).toPromise();
  }
  protected getConfig() {
    const url = `/api/config`;
    return this.http.get(url).toPromise();
  }
  /**
   * Get init auth0 config
   */
  get data(): any {
    return this.config;
  }

  /**
   * Reinit auth0 config
   */
  public reinit(): Promise<any> {
    storageRemove(`config-${this.configName}`);
    return this.load().then(function () {
      this.logDevMode('Reinit auth0 config ok');
    }, function (err) {
      console.error('Reinit error', err);
    });
  }

  /**
   * Check if url is a sub domain
   * @param url
   */
  private isSubdomain = function (url) {
    const regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
    return !!url.match(regex);
  }
}
