import { EntitySimple, EntityDocumentMetadata } from 'app/shared';

export class EntityDocumentsRequest extends EntitySimple {
  documents: EntityDocumentMetadata[];

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.documents = (data && data.documents) ? data.documents.map((e) => (e)) : [];
  }
}
