import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {DbConfigService} from '../db/dbConfig.service';
import {ConfigModel, EntityRole, EntityEmailMessages} from '../models';
import {LoggerService} from '../logger/logger.service';
import {storageGet} from '../utils/utils';
import {PermissionsConfig} from './permissionConfig.model';
import {EntityConfigEmail} from '../models/entityConfigEmaill.model';
import {UserRole} from './userRole.model';
import {AuthService} from './auth.service';
import {EmailDatasModel, EmailService} from 'app/shared/email';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class PermissionsService {
  static ADMIN_ONLY = ['editEE', 'editConfigs', 'editEmails', 'editUser', 'removeUser', 'editPermissions']
  static LABEL_LOADING = 'chargement des permissions';
  rolesPermissions: {[name: string]: string[]} = {};
  permissionsConfig: PermissionsConfig[];
  configModel: BehaviorSubject<ConfigModel> = new BehaviorSubject(new ConfigModel());
  configEmail: EntityConfigEmail;
  private _loadingId: string;
  constructor(
    public sanitizer: DomSanitizer,
    public auth: AuthService,
    private ngxPermissionsService: NgxPermissionsService,
    //protected config: ConfigService,
    public email: EmailService,
    protected logger: LoggerService,
    protected db: DbConfigService,
    protected _loadingService: LoadingService
  ) {
    this._loadingId = this._loadingService.addLoad(PermissionsService.LABEL_LOADING);
  }
  protected _getConfigRoles(): Promise<EntityRole[]> {
    return this.db.getList(['config_role'], ['_id', 'name', 'permissions'])
      .pipe(map(list => list.map(data => new EntityRole(data)))).toPromise();
  }

  protected loadConfigEmail(): Promise<EntityConfigEmail> {
    return this.db.getSingle('config_email')
      .pipe(map((data) => {
        return (data) ? new EntityConfigEmail(data) : null;
      })).toPromise();

  }
  hasIdpExternal() {
    return this.configEmail && this.configEmail.idpExternal;
  }
  hasIdpInternal() {
    return this.configEmail && this.configEmail.idpInternal;
  }
  protected updateUserPermissions(roles: UserRole[]) {
    if (roles) {
      roles.forEach((e: UserRole) => {
        this.rolesPermissions[e.name] = e.permissions;
      });
      this.logger.info('PermissionsService', 'init', this.rolesPermissions);
      this.updateFromRoles(this.auth.getRoles());
    }
  }
  public init(permissionsConfig: PermissionsConfig[]): Promise<string[]> {
    this.permissionsConfig = permissionsConfig;
    // console.log('PermissionsService start init', permissionsConfig);
    return new Promise<string[]>(resolve => {
      this._getConfigRoles().then((roles) => {
        //console.log('PermissionsService init roles', roles);
        this.updateUserPermissions(roles);
        // this.config.getConfigLocal().then((configModel) => {
        // console.log('PermissionsService init configModel', configModel);
        //this.updateUserPermissions(configModel.permissions);
        this.loadConfigEmail().then((config) => {
          if (config) {
            this.configEmail = config;
          }
          this._loadingService.removeLoad(this._loadingId);
          resolve(this.getPermissions());
        }).catch((e) => {
          this.logger.error('PermissionsService', 'unable to get configEmail', JSON.stringify(e));
          this._loadingService.removeLoad(this._loadingId);
          resolve(this.getPermissions());
        });
      })
        .catch((e) => {
          this.logger.error('PermissionsService', 'unable to get config', JSON.stringify(e));
        })
    });
  }

  /**
   * check if the user is connected by get the user email
   * @param userEmail
   */
  public isUserConnected(userEmail: string): boolean {
    const profileItem = storageGet('profile');
    const email = profileItem.email;
    if (userEmail && userEmail.indexOf(email) >= 0) {
      return true;
    }
    return false;
  }

  protected updateFromRoles(roles: string[]) {
    this.ngxPermissionsService.flushPermissions();
    roles.forEach((role) => {
      if (this.rolesPermissions[role] && this.rolesPermissions[role].length) {
        this.ngxPermissionsService.addPermission(this.rolesPermissions[role]);
      }
    });
    //console.log('updateFromRoles [' + roles.join(',') + ']', this.getPermissions());
    this.logger.info('PermissionsService', 'updateFromRoles', this.getPermissions(), roles);
  }

  /**
   * Async function check if user has a specific permission
   * @param right
   */
  async getPermission(right: string) {
    return await this.ngxPermissionsService.hasPermission(right);
  }

  /**
   * Get the list of permissions
   */
  getPermissions(): string[] {
    return Object.keys(this.ngxPermissionsService.getPermissions());
  }

  /**
   * check if user has a specific permission
   * @param right
   */
  hasPermission(right: string) {
    const p = this.getPermissions();
    const i = p.indexOf(right);
    return (i !== -1);
    //    return (this.getPermissions().indexOf(right) !== -1);
  }
  hasOnePermission(rights: string[]) {
    let found: boolean = false;
    rights.forEach(right => {found = found || (this.getPermissions().indexOf(right) !== -1)});
    return found;
  }
  canActivate(guardParam) {
    let ok = false;
    if (guardParam && guardParam['only'] && guardParam['only'].length) {
      guardParam['only'].forEach(r => {
        ok = ok || this.hasPermission(r);
      })
    }
    return ok;
  }
  /**
   * Check if there are some permissions
   */
  canConnect() {
    return (this.getPermissions().length > 0);
  }

  /**
   * The user is authenticated with auth0
   */
  isAuthenticated() {
    return this.auth.isAuthenticated();
  }

  /**
   * check if user has a specific role
   * @param role
   */
  hasRole(role: string) {
    return this.getRoles().indexOf(role) !== -1;
  }

  /**
   * Get the user id
   */
  getUserId() {
    return this.auth.getUserId();
  }
  /**
   * Get the user id
   */
  getUserEmail() {
    return this.auth.getUserEmail();
  }

  /**
   * Get the user id
   */
  getSyncFilter() {
    return this.auth.getSyncFilter();
  }

  /**
   * Get the users list
   */
  getUserList() {

    return new Promise((resolve, reject) => {
      this.auth.refreshUsersList().then((res) => {
        resolve(this.auth.getUsers());
      });
    });
    //return this.auth.getUsersList().toPromise();
  }
  /**
   * Get the users list
   */
  getUserById(id: string) {
    return this.auth.getUserById(id).toPromise();
  }

  /**
   * Get Roles
   */
  getRoles() {
    return this.auth.getRoles();
  }

  /**
   * Get the list of roles
   * @param access_token
   */
  getRolesList(access_token = null) {
    return this.auth.getRolesList(access_token);
  }

  /**
   * Add a role to user
   * @param userId
   * @param roles
   */
  addUserRoles(userId, roles) {
    return this.auth.addUserRoles(userId, roles);
  }

  /**
   * Check auth0 session
   * @param audience
   * @param scopes
   */
  checkSession(audience, scopes) {
    return this.auth.checkSession(audience, scopes);
  }

  /**
   * Update user data
   * @param user
   */
  updateUserData(user) {
    return this.auth.updateUserData(user).toPromise();
  }

  /**
   * Update user metadatas
   * @param user
   */
  updateUserMetadata(user) {
    return this.auth.updateUserMetadata(user).toPromise();
  }

  /**
   * Delete user datas
   * @param userId
   */
  deleteUser(userId) {
    return this.auth.deleteUser(userId);
  }

  sendConfirm(user) {
    return new Promise<any>((resolve, reject) => {
      if (user && user['user_id']) {
        this.auth.emailVerificationTicket(user['user_id']).subscribe((ticket) => {
          if (ticket && ticket['ticket']) {
            // console.log('emailVerif:', ticket['ticket']);
            this._sendEmail(4, user.email, user.nickname ? user.nickname + ' ' + user.name : user.name, ticket['ticket']);
            // TODO: envoyer le mail avec le lien
            resolve(user);
          } else {
            // console.log('error passwordChangeTicket:', ticket);
            reject(ticket);
          }
        },
          (err) => {
            // console.log('error emailVerificationTicket:', err);
            reject(err);
          });
      } else {
        reject(null);
      }
    });
  }
  sendConfirmAndPassword(user) {
    return new Promise<any>((resolve, reject) => {
      if (user && user['user_id']) {
        this.auth.passwordChangeTicket(user['user_id']).subscribe((pswticket) => {
          if (pswticket && pswticket['ticket']) {
            // console.log('passwordChange:', pswticket['ticket']);
            this.auth.emailVerificationTicket(user['user_id'], pswticket['ticket']).subscribe((ticket) => {
              if (ticket && ticket['ticket']) {
                // console.log('emailVerif:', ticket['ticket']);
                this._sendEmail(4, user.email, user.nickname ? user.nickname + ' ' + user.name : user.name, ticket['ticket']);
                // TODO: envoyer le mail avec le lien
                resolve(user);
              } else {
                // console.log('error passwordChangeTicket:', ticket);
                reject(ticket);
              }
            },
              (err) => {
                // console.log('error emailVerificationTicket:', err);
                reject(err);
              });
          }
        },
          (err) => {
            // console.log('error passwordChangeTicket:', err);
            reject(err);
          }
        );
      } else {
        reject(null);
      }
    });
  }
  /**
   * Create user
   * @param email
   * @param password
   * @param meta
   * @param roles
   * @param sendmail
   */
  createUser(email, password = '', meta = {}, roles = [], sendmail = true) {
    return new Promise<any>((resolve, reject) => {
      this.auth.createUser(email, password, meta, roles).then(
        (user) => {
          // console.log('user created:', user);
          if (sendmail) {
            if (this.hasIdpInternal()) {
              //    this.sendConfirm(user)
              //      .then((u) => {resolve(u);})
              //      .catch((e) => {reject(e);});
            } else {
              this.sendConfirmAndPassword(user)
                .then((u) => {resolve(u);})
                .catch((e) => {reject(e);});
            }
          } else {
            resolve(user);
          }
        })
        .catch((err) => {
          // console.log('error createUser:', err);
          reject(err);
        }
        );
    });
  }

  /**
   * Create new external user
   * @param email
   * @param password
   * @param meta
   * @param sendmail
   */
  createExternalUser(email, password = '', meta = {}, sendmail = true, roles = ['PP_EEX']) {
    return new Promise<any>((resolve, reject) => {
      this.createUser(email, password, meta, roles, false).then(
        (user) => {
          // console.log('user created:', user);
          if (sendmail) {
            if (this.hasIdpExternal()) {
              //     this.sendConfirm(user)
              //       .then((u) => {resolve(u);})
              //       .catch((e) => {reject(e);});
            } else {
              this.sendConfirmAndPassword(user)
                .then((u) => {resolve(u);})
                .catch((e) => {reject(e);});
            }
          } else {
            resolve(user);
          }
        },
        (err) => {
          // console.log('error createExternalUser:', err);
          reject(err);
        }
      );
    });
  }

  /**
   * Change password
   * @param userId
   * @param sendMail
   * @param callbackUrl
   */
  passwordChangeTicket(userId, sendMail = false, callbackUrl = '') {
    return new Promise<any>((resolve, reject) => {
      this.auth.passwordChangeTicket(userId, callbackUrl).subscribe((ticket) => {
        if (ticket && ticket['ticket']) {
          // console.log('passwordChange:', ticket['ticket']);
          if (sendMail) {
            // TODO: envoyer le mail avec le lien
            // this._sendEmail(6, user.email, ticket['ticket']);
          }
          resolve(ticket['ticket']);
        } else {
          reject(ticket);
        }
      },
        (err) => {reject(err)}
      );
    });
  }
  // Si email pas vérifié, passwordChange à faire normalement (sauf si on passe le callbackUrl)
  //

  /**
   * @param userId
   * @param user
   * @param sendMail
   * @param callbackUrl
   */
  emailVerificationTicket(userId, user, sendMail = false, callbackUrl = '') {
    return new Promise<any>((resolve, reject) => {
      if (callbackUrl) {
        this.auth.emailVerificationTicket(userId, callbackUrl).subscribe((ticket) => {
          if (ticket && ticket['ticket']) {
            if (sendMail) {
              // TODO: envoyer le mail avec le lien
              this._sendEmail(5, user.email, user.nickname ? user.nickname + ' ' + user.name : user.name, ticket['ticket']);
            }
            resolve(ticket['ticket']);
          } else {
            reject(ticket);
          }
        },
          (err) => {reject(err)}
        );
      } else {
        this.passwordChangeTicket(userId).then((passChangeUrl) => {
          this.auth.emailVerificationTicket(userId, passChangeUrl).subscribe((ticket) => {
            if (ticket && ticket['ticket']) {
              if (sendMail) {
                // TODO: envoyer le mail avec le lien
                this._sendEmail(5, user.email, user.nickname ? user.nickname + ' ' + user.name : user.name, ticket['ticket']);
              }
              resolve(ticket['ticket']);
            } else {
              reject(ticket);
            }
          },
            (err) => {reject(err)});
        });
      }
    });
  }

  /**
   * Send email to user (new user, change password)
   * @param type
   * @param email
   * @param to_name
   * @param url
   */
  private _sendEmail(type: number, email: string, to_name: string, url: string): void {
    this.email.getEmailDatas(type).then(emailObj => {
      const emailDatas = new EmailDatasModel();
      emailDatas.email = email;
      emailDatas.to_name = to_name;
      emailDatas.date = new Date();
      emailDatas.from_name = emailObj.fromName;
      if (emailObj.fromEmail) {
        emailDatas.from_email = emailObj.fromEmail;
      }
      emailDatas.signature = emailObj.signature;
      emailDatas.rgpdMail = emailObj.rgpdMail;
      emailDatas.dpoName = emailObj.dpoName;
      emailDatas.dpoMail = emailObj.dpoMail;

      emailDatas.subject = EntityEmailMessages.modifyEmailText(this.email.getCompanyName(), emailObj.subject, null, null, null, url);
      emailDatas.text = EntityEmailMessages.modifyEmailText(this.email.getCompanyName(), emailObj.text, null, null, null, url);
      emailDatas.usertext = EntityEmailMessages.modifyEmailText(this.email.getCompanyName(), emailObj.usertext, null, null, null, url);
      this.email.sendEmail(emailDatas).catch((err) => {
        this.logger.info('PermissionsService', 'error sendEmail:', err);
      });
    });

  }
  public getProfile() {
    return this.auth.userProfile;//retrieveProfile();
  }

  public getUserName(): string {
    return this.auth.userProfile
      ? (this.auth.userProfile.user_metadata
        && (this.auth.userProfile.user_metadata.given_name || this.auth.userProfile.user_metadata.family_name))
        ? this.auth.userProfile.user_metadata.given_name
        + ((this.auth.userProfile.user_metadata.given_name && this.auth.userProfile.user_metadata.family_name) ? ' ' : '')
        + this.auth.userProfile.user_metadata.family_name
        : (this.auth.userProfile.name ? this.auth.userProfile.name : this.auth.userProfile.email)
      : '';
  }
  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }
  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }
  bypassSecurityTrustScript(value: string): SafeScript {
    return this.sanitizer.bypassSecurityTrustScript(value);
  }
  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
