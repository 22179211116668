import {Component} from '@angular/core';
import {MatDialog} from '@angular/material';
import {LoggerService, PermissionsService, EntityFormComponent} from 'app/shared';
import {OrganizationService, EntityOrganization} from 'app/workbook-core';

@Component({
  selector: 'app-repository-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends EntityFormComponent {

  entity: EntityOrganization;

  constructor(
    public dialog: MatDialog,
    protected _permissionService: PermissionsService,
    protected _logger: LoggerService,
    protected _entityService: OrganizationService

  ) {
    super(dialog, _logger, _entityService);
  }

}
