import {UUID} from 'angular2-uuid';
import {EntitySimple} from 'app/shared';
import {EntityRisk} from './repository/models/entityRisk.model';
import {EntitySituation} from './repository/models/entitySituation.model';
import {EntityWorkbookMeasure, RiskEntry} from './workbook/models/entityMeasure.model';
export class CompanyActivity {
  public id: string;
  public parent_id: string;
  public name: string;
  public role: string;
  public size: number;

  constructor(intData) {
    this.id = intData.id || UUID.UUID();
    this.parent_id = intData.parent_id || '';
    this.name = intData.name || '';
    this.role = intData.role || '';
    this.size = intData.size || 0;
  }
}
export class EntityAnalysable extends EntitySimple {

  public companies: CompanyActivity[];
  public risks: EntityRisk[];
  public situations: EntitySituation[];
  public risks_situations: string[];
  public measures: EntityWorkbookMeasure[];
  public confinedSpace: boolean;

  copyFrom(data: any = {}, clone = false) {
    super.copyFrom(data, clone);
    this.updateFrom(data);
    this.update_risks_situations();
  }
  updateFrom(data: any = {}) {
    this.companies = (data && data.companies) ? data.companies.map((e) => new CompanyActivity(e)) : [];
    this.risks = (data && data.risks) ? data.risks.map((e) => new EntityRisk(e)) : [];
    this.situations = (data && data.situations) ? data.situations.map((e) => new EntitySituation(e)) : [];
    this.risks_situations = (data && data.risks_situations) ? data.risks_situations.map(e => e) : [];
    this.measures = (data && data.measures) ? data.measures.map((e) => {
      if ('local' !== e['type'] && !e['repo_id']) {
        e['repo_id'] = e['_id'];
      }
      //if (!e['documentType']) {
      e['documentType'] = 'wb_measure';
      //}
      return new EntityWorkbookMeasure(e)
    }) : [];
    this.confinedSpace = (data && data.confinedSpace);
  }

  update_risks_situations() {
    //supprimer les risques inutilisés
    if (this.risks.findIndex((r) => (
      (this.measures.length === 0 || this.measures.findIndex((m) => (m.risk_id === r._id)) === -1)
      && (this.situations.length === 0 || this.situations.findIndex((s) => (s.risk_id === r._id)) === -1))
    ) !== -1) {
      this.risks = this.risks.filter((r) => (!(this.measures.findIndex((m) => (m.risk_id === r._id)) === -1
        && this.situations.findIndex((s) => (s.risk_id === r._id)) === -1)));
    }
    //ajouter les risques manquants à risks_situations
    this.risks
      .map((r) => (r._id))
      .filter((r_id) => (this.risks_situations.indexOf(r_id) === -1
        && this.measures.findIndex((m) => (m.risk_id === r_id && (m.type === '' || m.type === 'local' || m.type === 'global'))) !== -1))
      .forEach((r_id) => {
        this.risks_situations.push(r_id);
      });
    if (this.risks_situations.length) {
      this.risks_situations = this.risks_situations.filter((id) => (!(
        (this.risks.findIndex((r) => (r._id === id)) === -1
          || this.measures.findIndex((m) => (m.risk_id === id && (m.type === '' || m.type === 'local' || m.type === 'global'))) === -1)
        && this.situations.findIndex((s) => (s._id === id)) === -1
      )));
      if (this.measures.length && this.measures.findIndex((m) => (m.type !== '' && m.type !== 'local' && m.type !== 'global' && this.situations.findIndex((s) => (s._id === m.type)) === -1)) !== -1) {
        this.measures = this.measures.filter((m) => (m.type === '' || m.type === 'local' || m.type === 'global' || this.situations.findIndex((s) => (s._id === m.type)) !== -1));
      }
      this.situations.forEach((s) => {
        if (this.risks_situations.indexOf(s.risk_id) === -1
          && this.measures.findIndex((m) => (m.risk_id === s.risk_id && (m.type === '' || m.type === 'local' || m.type === 'global'))) !== -1) {
          this.risks_situations.push(s.risk_id);
        }
        if (this.risks_situations.indexOf(s._id) === -1) {
          this.risks_situations.push(s._id);
        }
      });
    } else {
      this.risks.forEach((r) => {
        //this.risks_situations.push(r._id);
        this.situations
          .filter((s) => (s.risk_id === r._id))
          .forEach((s) => {
            this.risks_situations.push(s._id);
          });
      });
      if (this.measures.length && this.measures.findIndex((m) => (m.type !== '' && m.type !== 'local' && m.type !== 'global' && this.situations.findIndex((s) => (s._id === m.type)) === -1)) === -1) {
        this.measures = this.measures.filter((m) => (m.type === '' || m.type === 'local' || m.type === 'global' || this.situations.findIndex((s) => (s._id === m.type)) !== -1));
      }

    }
  }

  mergeAnalysis(z: EntityAnalysable) {
    z.risks.forEach((e) => {
      if (this.risks.findIndex((x) => (x._id === z._id)) === -1) {
        this.risks.push(e);
      }
    });
    z.situations.forEach((e) => {
      if (this.situations.findIndex((x) => (x._id === z._id)) === -1) {
        this.situations.push(e);
      }
    });
    z.risks_situations.forEach((e) => {
      if (this.risks_situations.indexOf(e) === -1) {
        this.risks_situations.push(e);
      }
    });
    z.measures.forEach((e) => {
      if (this.measures.findIndex((x) => (x._id === z._id)) === -1) {
        this.measures.push(e);
      }
    });
    this.confinedSpace = this.confinedSpace || z.confinedSpace;
  }
}
