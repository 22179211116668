import {MatDialog} from '@angular/material';
import {LoggerService} from '../logger';
import {EntityOwnedService} from '../services';
import {EntityOwned} from '../models';
import {EntityFormComponent} from './entityForm.component';

export class EntityOwnedFormComponent extends EntityFormComponent {

  entity: EntityOwned;

  constructor(
    public dialog: MatDialog,
    protected _logger: LoggerService,
    protected _entityService: EntityOwnedService

  ) {
    super(dialog, _logger, _entityService);
  }
}
